import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectTurnPopupDialogComponent } from './perfect-turn-popup-dialog.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { SassHelperModule } from '../../_helpers/sass-helper/sass-helper.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { GridModule } from '@progress/kendo-angular-grid';
import { CenteredMessageModule } from '../centered-message/centered-message.module';

@NgModule({
	declarations: [PerfectTurnPopupDialogComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		SassHelperModule,
		MatTabsModule,
		MatCardModule,
		MatExpansionModule,
		GaugesModule,
		GridModule,
		CenteredMessageModule,
	],
	exports: [PerfectTurnPopupDialogComponent],
})
export class PerfectTurnPopupDialogModule {}
