import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaHeatComponent } from './pca-heat.component';



@NgModule({
  declarations: [
    PcaHeatComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [PcaHeatComponent]
})
export class PcaHeatModule { }
