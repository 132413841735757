<div  class="content" style="padding: 0 0 0 0 !important" >
	<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading System Tables...'" [vehicle]="'plane'"></lib-loading-spinner>
	<!-- Initial Recursive Table Listing -->
	<div *ngIf="selectedEntity != null" class="content" style="height:100%" >
        <div class="card" style="height:calc(100vh - 95px); width: calc(100%)">
            <div class="card-header rdn-destination-height" [ngClass]="(rdnService.editingForm$ | async ) || global.RDN.editingForm ? 'opaque-backdrop' : ''" >
            <!-- <div class="card-header rdn-destination-height" >   -->
                <h3 style="display: inline; vertical-align: bottom;" class="card-title"><strong>{{ selectedEntity.label }}</strong></h3>
                <button class="btn btn-icon btn-simple btn-twitter pull-right" tooltip="Back to System Table List" (click)="backToSystemTableList()">
                    <i class="tim-icons icon-minimal-left"> </i>
                </button>
            </div>
            <div class="card-body" style="height:75%"  >
                <div class="table-responsive" style="height:calc(100% - 10px)" >
                    <lib-recursive-data-navigator [options]="selectedEntity"></lib-recursive-data-navigator>
                </div>
            </div>
        </div>
	</div>
</div>
