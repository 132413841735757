import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaEstopComponent } from './pca-estop.component';



@NgModule({
  declarations: [
    PcaEstopComponent
  ],
  imports: [
    CommonModule
  ], exports: [PcaEstopComponent]
})
export class PcaEstopModule { }
