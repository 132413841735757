import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from 'projects/shared-lib/src/lib/services/authentication.service';
import { CacheUtilityService } from 'projects/shared-lib/src/lib/services/cache-utility.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { SecurityService } from 'projects/shared-lib/src/lib/services/security.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';

import { filter } from 'rxjs/operators';


import swal from "sweetalert2";
import { IGlobal } from 'projects/shared-lib/src/lib/_models/global.model';

@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	public global: IGlobal = Global;

	public resetPasswordForm: FormGroup;
	private newPassword: FormControl = null;
	private newPasswordConfirmation: FormControl = null;
	public passwordsMatch: boolean = false;
	public url: string = window.origin;
	private authenticationSubscription$: any;
	private activatedRoute$: any;
	private currentUserIsBeingAuthenticated: boolean = false;
	private loginProcessLoading: boolean = false;
	private currentUserIsLoggedIn: boolean = false;

	mouseoverLogin = false;
	public deviceWidth: number;

	private passwordToken: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dataService: DataService,
		private authenticationService: AuthenticationService,
		private zone: NgZone,
		private cacheUtilityService: CacheUtilityService,
		private securityService: SecurityService
	) {}

	ngOnInit() {
		this.passwordToken = this.activatedRoute.snapshot.paramMap.get('pwt');
		// if (this.passwordToken == undefined) {
		//   this.activatedRoute$ = this.activatedRoute.params.subscribe((params) => {
		//     this.passwordToken = params["pwt"];
		//   });
		// }

		console.log(
			'ForgotPasswordComponent: this.passwordToken = ' +
				this.passwordToken
		);
		this.newPassword = new FormControl('', Validators.required);
		this.newPasswordConfirmation = new FormControl('', Validators.required);
		this.resetPasswordForm = new FormGroup({
			newPassword: this.newPassword,
			newPasswordConfirmation: this.newPasswordConfirmation,
		});
		this.setupResetPasswordForm();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		this.removeResetPasswordForm();
		this.authenticationSubscription$ &&
			this.authenticationSubscription$.unsubscribe();
		this.activatedRoute$ && this.activatedRoute$.unsubscribe();
	}

	setupResetPasswordForm() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.add('login-page');
	}

	removeResetPasswordForm() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('login-page');
	}

	validateNewPassword() {
		return this.newPassword.valid || this.newPassword.untouched;
	}

	validateNewPasswordConfirmation() {
		this.passwordsMatch =
			this.newPassword.value == this.newPasswordConfirmation.value;
		return (
			this.newPasswordConfirmation.valid ||
			this.newPasswordConfirmation.untouched
		);
	}

	resetPassword(formValues: any) {
		console.log('formValues = %O', formValues);
		this.dataService
			.ChangePassword(this.passwordToken, formValues.newPassword)
			.then((data: any) => {
				console.log('ChangePassword: data = %O', data);
				var userRecord = data.body.first();
				this.sendAlertToUser(userRecord.Email);
				console.log(
					'username = ' +
						userRecord.username +
						', password = ' +
						formValues.newPassword
				);
				var time0 = performance.now();
				this.authenticationService
					.loginWithUsernameAndPassword(
						userRecord.Username,
						formValues.newPassword
					)
					.then(
						(accessToken: any) => {
							var time = performance.now() - time0;
							console.log(
								"time for webAPI to authenticate user = " + time
							);
							//console.log("user.Id = " + user.Id);
							console.log("user access token = " + accessToken);
							if (!accessToken) {
								// -- login was invalid, so fire error about incorrect username / password.
								this.invalidPasswordAlert();
								this.currentUserIsBeingAuthenticated = false;
								this.loginProcessLoading = false;
							} else {
								this.currentUserIsLoggedIn = true;
								// --login was valid, so navigate to the main layout.
								this.securityService.processLoggedInUser(
									accessToken
								);
							}
						},
						(err: Error) => {
							Global.User.DebugMode &&
								console.log(
									`user was not authorized. Error: ${err.message}`
								);
							this.router.navigate(["user/login"]);
						}
					);
			});
	}

	sendAlertToUser(email: string) {
		swal.fire({
			title: 'Password Change',
			position: 'center',
			html: 'Your iOPS password has been changed.',
		});
	}

	invalidPasswordAlert() {
		swal.fire({
			title: 'Incorrect',

			position: 'top',
			text: 'Username or Password is not valid.',
		});
	}
}
