import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastThousandGridComponent } from './last-thousand-grid.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import {
	GridModule,
	ExcelModule,
	PDFModule,
} from '@progress/kendo-angular-grid';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [LastThousandGridComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		GridModule,
		ExcelExportModule,
		PDFExportModule,
		ExcelModule,
		PDFModule,
		ButtonModule,
		KendoGridParentModule,
	],
	exports: [LastThousandGridComponent],
})
export class LastThousandGridModule {}
