import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveDataComponent } from './live-data.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  declarations: [
    LiveDataComponent
  ],
  imports: [
    CommonModule, MatProgressBarModule
  ],
  exports: [LiveDataComponent]
})
export class LiveDataModule { }
