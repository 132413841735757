		<div class="card card-tasks">
			<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="loadingMessage" [vehicle]="'truck'"></lib-loading-spinner>
			<div class="card-header">
				<h4 class="card-title d-inline" style="padding-left: 15px">{{ title }}</h4>
                <button mat-button style="display: inline; float: right; padding-top: 5px;" (click)="closeDialogWindow()" matTooltip="Close" [matTooltipPosition]="'below'">
                    <div>
                        <i class="tim-icons icon-simple-remove"></i>
                    </div>
                </button>
				<h5 class="card-subtitle" style="padding-left: 15px; margin-top: 5px">{{ subTitle }}</h5>
			</div>
			<div *ngIf="this.addingNewDashboard == true" class="card-body" style="height: calc(100%-75px); overflow-y: scroll;">
				<mat-vertical-stepper>
					<mat-step label="Select Type">

					  <mat-button-toggle-group #group="matButtonToggleGroup" aria-label="Font Style">
						<mat-button-toggle value="Template">Template</mat-button-toggle>
						<mat-button-toggle value="Custom">Custom</mat-button-toggle>
					  </mat-button-toggle-group>
					  <div style="margin-top: 20px;">
						<button class="btn btn-primary" mat-button matStepperNext [disabled]="group.value == null">Next</button>
					  </div>
					</mat-step>
					<mat-step label="Create Dashboard">
						<div style="height: 100" *ngIf="isLoading === false && group.value == 'Template'">
							
							<lib-dynamic-form [questions]="editFieldsTemplate" [options]="formOptions" (submittedValues)="submitForm($event)" ></lib-dynamic-form>
							<div style="margin: 10px;">
								<button class="btn btn-primary" mat-button matStepperPrevious>Prev</button>
							</div>
						</div>
						<div style="height: 100" *ngIf="isLoading === false && group.value == 'Custom'">
							
							<lib-dynamic-form [questions]="editFieldsCustom" [options]="formOptions" (submittedValues)="submitForm($event)" ></lib-dynamic-form>
							<div style="margin: 10px;">
								<button class="btn btn-primary" mat-button matStepperPrevious>Prev</button>
							</div>
						</div>
					</mat-step>
				</mat-vertical-stepper>


			</div>

			<div *ngIf="this.addingNewDashboard == false" class="card-body" style="height: calc(100%-75px); overflow-y: scroll;">

				<div style="height: 100" *ngIf="isLoading === false">
					<lib-dynamic-form [questions]="editFieldsCustom" [options]="formOptions" (submittedValues)="submitForm($event)" ></lib-dynamic-form>
				</div>

			</div>
		</div>

