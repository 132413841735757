

<div class="content" style="padding: 0 0 0 0 !important; height: calc(100%);">



	<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading Issues...'" [vehicle]="'plane'"></lib-loading-spinner>
	<div *ngIf="viewIssuesList" class="content" style="height: 100%">

		<div class="card" [style.height]="global.isMobile ? 'calc(100%)' : 'calc(100vh - 95px)'" style="width: calc(100%)">
			<div class="card-header" style="height: 80px">
				<h3 *ngIf="!global.User.isAdmin" style="display: inline; vertical-align: bottom" class="card-title">
					{{ issuesListTitle }}<strong>{{ currentLoggedInUserDisplay }}</strong>
				</h3>
				<h3 *ngIf="global.User.isAdmin" style="display: inline; vertical-align: bottom" class="card-title">
					{{ issuesListTitle }}
				</h3>
				<button class="btn btn-primary btn-round btn-icon mr-1 pull-right" matTooltip="New Issue" [matTooltipPosition]="'below'" (click)="navigate('newIssue')">
					<i class="tim-icons icon-simple-add"> </i>
				</button>
			</div>
			<div class="card-body" style="height: 75%">
				<div class="table-responsive" style="height: calc(100% - 3px)">
					<kendo-grid [data]="gridViewIssues" [columnMenu]="true" [groupable]="global.isMobile ? false : true" [group]="issuesGridSettings.state.group" [filter]="issuesGridSettings.state.filter" [style.height.%]="100" [skip]="issuesGridSettings.state.skip" [scrollable]="'scrollable'" [navigable]="true" filterable="menu" [sort]="issuesGridSettings.state.sort" [sortable]="sortSettings" [reorderable]="true" [resizable]="true" [pageable]="{ pageSizes: true }" [pageSize]="issuesGridSettings.state.take" (pageChange)="pageChangeIssues($event)" (groupChange)="groupChangeIssues($event)" (sortChange)="sortChangeIssues($event)" (pageChange)="pageChangeIssues($event)" (filterChange)="filterChangeIssues($event)" #issuesGrid>
						<ng-template kendoGridColumnMenuTemplate let-service="service">
							<kendo-grid-columnmenu-sort [service]="service"> </kendo-grid-columnmenu-sort>
							<kendo-grid-columnmenu-lock [service]="service"> </kendo-grid-columnmenu-lock>
							<kendo-grid-columnmenu-chooser [service]="service"> </kendo-grid-columnmenu-chooser>
							<kendo-grid-columnmenu-filter [service]="service"> </kendo-grid-columnmenu-filter>
							<kendo-grid-columnmenu-item
								icon="k-icon k-i-filter-clear"
								text="Clear Filter"
								(itemClick)="
									filterChangeIssues({
										logic: 'and',
										filters: []
									});
									service.close()
								"
							>
							</kendo-grid-columnmenu-item>
						</ng-template>

						<kendo-grid-column *ngFor="let column of issuesGridSettings.columnsConfig" title="{{ column.title }}" field="{{ column.field }}" [width]="column._width" [minResizableWidth]="column.minResizableWidth" [filter]="column.filter" [hidden]="column.hidden" [locked]="column.locked">
							<ng-template kendoGridCellTemplate let-dataItem *ngIf="column.filter === 'date'">
								{{ dataItem[column.field] | date : "MM/dd/yyyy hh:mm a" }}
							</ng-template>
							<ng-template kendoGridCellTemplate let-dataItem *ngIf="column.field === 'Resolved'">
								<mat-icon *ngIf="dataItem.Resolved === true" style="color: green">check_circle</mat-icon>

								<mat-icon *ngIf="dataItem.Resolved === false" style="color: red">highlight_off</mat-icon>
							</ng-template>

							<ng-template kendoGridCellTemplate let-dataItem *ngIf="column.field === 'Actions'">
								<div style="white-space: nowrap" (click)="navigate(dataItem.Id)">
									<button class="btn issues-btn btn-sm" matTooltip="Edit this issue" [matTooltipPosition]="'above'" type="button">
										<i class="tim-icons icon-pencil"></i>
									</button>
									<div style="display: inline-block" matBadgeHidden="true" [matBadge]="1" matBadgeOverlap="true" matBadgeColor="warn">
										<button
											color="primary"
											class="btn issues-btn btn-sm"
											matTooltip="View the discussion attached to this Issue.  {{ dataItem.DiscussionCount > 0 ? dataItem.DiscussionCount + ' entr' + (dataItem.DiscussionCount > 1 ? 'ies' : 'y') + ' currently.' : '' }}"
											[matTooltipPosition]="'above'"
											type="button"
											[ngClass]="{
												'object-contains-content': dataItem.DiscussionCount >= 1
											}"
											style="padding-left: 7px; padding-right: 7px"
										>
											<i class="tim-icons icon-chat-33"></i>
										</button>
										<kendo-badge themeColor="error" *ngIf="(dataItem.CreatorUserId === global.User.currentUser.Id && dataItem.NotifyCreatorUser) || (dataItem.AssignedToUserId === global.User.currentUser.Id && dataItem.NotifyAssignedUser)">1</kendo-badge>
									</div>

									<button
										color="primary"
										class="btn issues-btn btn-sm"
										matTooltip="View files attached to this Issue.  {{ dataItem.CountOfFilesUploaded > 0 ? dataItem.CountOfFilesUploaded + ' file' + (dataItem.CountOfFilesUploaded > 1 ? 's' : '') + ' uploaded currently.' : '' }}"
										[matTooltipPosition]="'above'"
										type="button"
										[ngClass]="{
											'object-contains-content': dataItem.CountOfFilesUploaded >= 1
										}"
										style="margin-left: 8px"
									>
										<i class="tim-icons icon-notes"></i>
									</button>

								</div>

							</ng-template>
						</kendo-grid-column>
						<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
							<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
							<kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
							<kendo-pager-next-buttons></kendo-pager-next-buttons>
							<kendo-pager-info></kendo-pager-info>
							<kendo-pager-page-sizes [pageSizes]="[15, 30, 50]"></kendo-pager-page-sizes>
						</ng-template>
					</kendo-grid>
				</div>
			</div>
		</div>

	</div>

</div>
<router-outlet></router-outlet>



