<div class="content" style="padding: 0 0 0 0 !important">
	<div class="col-md-12" class="content">
		<div class="card form-horizontal">
			<div class="card-header">
				<div class="col-md-6 text-left">
					<h4 class="card-title">
						Generate Password
					</h4>
					<h5 class="card-title">Please enter a date to generate the password of the day.</h5>
				</div>
			</div>
			<form *ngIf="form" class="form-horizontal dynamic-form" (ngSubmit)="onSubmit()" [formGroup]="form" >
				<div class="card-body">
					<div [formGroup]="form" class="row col-sm-12 col-md-12" style="margin-top: 10px">
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="Date" title="Enter the Date.">Date</label>
						</div>
						<div class="col-sm-8 col-md-8">
							<div class="form-group"><kendo-datepicker ngDefaultControl format="MM/dd/yyyy" title="Enter the Date." id="Date" formControlName="Date" (click)="clearFormWhenChangingDate()"></kendo-datepicker></div>
						</div>
					</div>
					<div class="row col-sm-12 col-md-12">
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="Password" title="Password of the Day.">Password</label>
						</div>
						<div class="col-sm-8 col-md-8">
							<input class="form-control" title="Password for the day." formControlName="Password" id="Password" type="text" [value]='form.controls["Password"].value' />
						</div>
					</div>
					<div class="row col-sm-12 col-md-12">
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="JetwayAccess" title="Jetway Access for the day.">Jetway Access</label>
						</div>
						<div class="col-sm-8 col-md-8">
							<input class="form-control" title="Jetway Access for the day." formControlName="JetwayAccess" id="JetwayAccess" type="text" [value]='form.controls["JetwayAccess"].value' />
						</div>
					</div>
				</div>
				<div class="card-footer text-center" >
					<button class="btn" class="btn-danger" type="button" style="padding:5px" title="Submit Changes" (click)="onSubmit(true)">Submit</button>
				</div>
			</form>
		</div>
	</div>
</div>
