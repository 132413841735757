import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../app/_layout/navbar/user-settings/user-settings.component";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { SelectorService } from "projects/shared-lib/src/lib/services/selector.service";
import { SliderYesNoQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-slider-yes-no";
import { TextboxQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-textbox";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";

@Component({
	selector: "personal-information",
	templateUrl: "./personal-information.component.html",
	styleUrls: ["./personal-information.component.scss"]
})
export class PersonalInformationComponent implements OnInit {
	public global: IGlobal = Global;
	public isLoading: boolean = true;
	public editQuestions: any;
	public formOptions: any;

	public componentName: string = "personal-information: ";
	public personRecord: any;

	constructor(private dataService: DataService, private utilityService: UtilityService, private router: Router, private selectorService: SelectorService, public dialogRef: MatDialogRef<PersonalInformationComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	ngOnInit() {
		this.formOptions = {
			submitButtonText: "Save Changes",
			saveValuesPerField: false,
			saveStoredProcedureName: "API.User_CreateOrModifyUserAccount @UserId=" + Global.User.currentUser.Id,
		};

		this.initialize();
	}

	initialize() {
		if (Global.User.isLoggedIn) {
			this.dataService.SQLActionAsPromise("API.GetUserProfileRecord " + Global.User.currentUser.Id).then((data: any) => {
				console.log("data = %O", data);
				this.personRecord = data.first();

				this.setUpForm();
			});
		} else {
			this.router.navigate(["registration"]);
		}
	}

	setUpDynamicFormQuestions() {
		this.editQuestions = [
			new TextboxQuestion({
				key: "RequestedUsername",
				label: "Username",
				title: "Your Username for the iOPS Application.",
				type: "username",
				required: true,
				validateObject: false,
				order: 1,
				value: this.personRecord.Username
			}),
			new TextboxQuestion({
				key: "EmailAddress",
				label: "Email Address",
				title: "Your email address. This must be an accurate email address since you will receive notifications about your iOPS account through this email account.",
				order: 2,
				type: "person-email",
				required: true,
				validateObject: false,
				value: this.personRecord.Email
			}),
			new TextboxQuestion({
				key: "FirstName",
				label: "First Name",
				title: "Your First (or given) Name.",
				required: true,
				order: 3,
				value: this.personRecord.FirstName
			}),
			new TextboxQuestion({
				key: "MiddleName",
				label: "Middle Name",
				title: "Your Middle Name, if applicable.",
				required: false,
				order: 4,
				value: this.personRecord.MiddleName
			}),
			new TextboxQuestion({
				key: "LastName",
				label: "Last Name",
				title: "Your Last (or family) Name.",
				required: true,
				order: 5,
				value: this.personRecord.LastName
			}),
			new TextboxQuestion({
				key: "MobilePhone",
				label: "Cell Phone Number",
				title: "Your Cell Phone Number. This phone number must be accurate for two-factor authentication if you will be using your cell phone for communication.",
				order: 6,
				type: "phone-number",
				required: false,
				value: this.personRecord.MobilePhone
			}),
			new TextboxQuestion({
				key: "CellPhoneCarrierId",
				label: "Cell Carrier",
				title: "Please select the correct Cell Carrier for the cell phone. This is needed so texting can happen from the iOPS application to the mobile phone, where applicable.",
				order: 7,
				type: "generic-selector",
				setName: "CellPhoneCarrier",
				required: false,
				value: this.personRecord.CellPhoneCarrierId,
				visibleFields: [
					{
						field: "MobilePhone",
						operator: "!=",
						value: ""
					}
				]
			}),
			new TextboxQuestion({
				key: "ReportsToPersonId",
				label: "Reports to Person",
				title: "Your Supervisor or Manager. This person would be notified when escalating issues to the next level.",
				order: 8,
				type: "generic-selector",
				setName: Global.User.isAdmin ? "Person" : "PersonBySingleOrganization",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				value: this.personRecord.ReportsToPersonId
			}),
			new TextboxQuestion({
				key: "AlternateNotificationEmailPersonId",
				label: "Alternate Person",
				title: "Another Person to notify when sending out email or texts to you.  This typically will be a co-worker or someone you are training.  You can always clear this field later if you no longer want to notify this alternate contact.",
				order: 9,
				type: "generic-selector",
				setName: Global.User.isAdmin ? "Person" : "PersonBySingleOrganization",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				value: this.personRecord.AlternateNotificationEmailPersonId
			}),
			new TextboxQuestion({
				key: "OutOfOfficeNotificationEmailPersonId",
				label: "Out of Office Person",
				title: "Another Person that will need to be notified in iOPS of events you are currently watching when you are out of the office.",
				order: 10,
				type: "generic-selector",
				setName: Global.User.isAdmin ? "Person" : "PersonBySingleOrganization",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				value: this.personRecord.OutOfOfficeNotificationEmailPersonId
			}),
			new SliderYesNoQuestion({
				key: "OutOfOffice",
				label: "Out of Office",
				title: "Select whether or not you are out of the office. This will shift notifications to your Out of Office contact, if you have entered one previously, and it will shut off notifications while you have your account set as 'Out of Office' on iOPS.",
				order: 11,
				value: this.personRecord.OutOfOffice
			})
		]
	}

	setUpForm() {
		this.setUpDynamicFormQuestions();
		this.isLoading = false;
	}


	closeDialog(): void {
		this.dialogRef.close();
	}
	sendNotificationToUser() {
		this.utilityService.showToastMessageShared(
			{
				type: 'info',
				message: "Your iOPS account has been updated.",
				title: 'Account Updated'
			});	
		this.closeDialog();
	}

	submitForm(submittedValues: string) {
		Global.User.DebugMode && console.log("user-settings.component.ts: submitForm: submittedValues = %O", submittedValues);
		var fieldListAsString = submittedValues.replace("{", "").replace("}", "").replace(/:/g, "=").replace(/\"/g, "");
		Global.User.DebugMode && console.log("user-settings.component.ts: fieldListAsString = " + fieldListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode && console.log("submittedValuesObject = %O", submittedValuesObject);
		Global.User.DebugMode && console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var question = this.editQuestions.firstOrDefault((question:any) => { return question.key == key});

			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += "@" + key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				if (question.controlType == 'slider-yes-no' || question.controlType == 'slider' || question.controlType == 'slider-true-false') {
					fieldListAsString += value == 1 ? "1" : "0";
				}
				else {
					fieldListAsString += value ? value : "null";
				}
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		Global.User.DebugMode && console.log("fieldListAsString = " + fieldListAsString);
		this.dataService.SQLActionAsPromise(this.formOptions.saveStoredProcedureName + ", " + fieldListAsString).then((data: any) => {
			this.personRecord = data.first();
			this.sendNotificationToUser();
			var routeDestination = "/layout/" + (Global.User.currentUser.Dashboards != null ? "dashboard/" + Global.User.currentUser.Dashboards[0].Id : "dashboard-demo");
			console.log("routeDestination = " + routeDestination);
			this.router.navigate([routeDestination]);
		});
	}
}
