import { Component, OnInit, Inject, Output, EventEmitter, OnChanges, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import _ from "lodash";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { DashboardService } from "projects/shared-lib/src/lib/services/dashboard.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IGlobal } from "../../_models/global.model";

@Component({
	selector: 'lib-widget-settings-popup',
	templateUrl: './widget-settings-popup.component.html',
	styleUrls: ['./widget-settings-popup.component.scss'],
})
export class WidgetSettingsPopupComponent implements OnInit, OnDestroy {

	//fleet support
	selectFleets: boolean = false;
	selectFleetAssetType: boolean = false;
	selectFleetAsset: boolean = false;
	userFleets: Array<any>;
	FleetTypes: Array<any>;
	Fleet: any = {};
	allFleetAssets: Array<any>;
	fleetAssetId: any;

	//fleet or site
	selectTypes: boolean = false;
	userTypes: Array<any> = [ "Fleet", "Site" ];
	Type: any;

	selectGlobal: boolean = false;
	selectSites: boolean = false;
	selectSite: boolean = false;
	selectGSEAssetType: boolean = false;
	selectGSAssetType: boolean = false;
	selectTerminal: boolean = false;
	selectAsset: boolean = false;
	selectBHS: boolean = false;
	selectGate: boolean = false;
	selectGSAsset: boolean = false;
	selectGSEAsset: boolean = false;
	selectCentralUnit: boolean = false;
	userSites: Array<any>;
	GSEAssetTypes: Array<any>;
	GSAssetTypes: Array<any>;
	terminals: any;
	terminalSystem: any;
	gates: any;
	assets: any;
	GSAssets: any;
	GSEAssets: any;
	siteChange$: any;
	exampleVar: number;
	public theme: string;
	private componentName: string = 'widget-settings-popup';
	fullDataCacheSubscription: any;
	localWidgetObject: any;
	globalScopeSelected: boolean = false;
	widgetType: string;
	private global: IGlobal = Global;
	isLoadingFleets: boolean = true;

	private multiSiteLimitsForWidgetType = [
		{
			AngularDirectiveName: 'tGSTopFive',
			Limit: 3,
		},
	];

	constructor(
		@Inject(MAT_DIALOG_DATA) public dataSource: any,
		public dialogRef: MatDialogRef<WidgetSettingsPopupComponent>,
		public dataService: DataService,
		public toastr: ToastrService,
		private utilityService: UtilityService,
		private dashboardService: DashboardService
	) {}

	ngOnInit() {
		this.localWidgetObject = this.dataSource.widget;
		this.widgetType = this.dataSource.widgetType;


		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
		}

		// Admin can select Fleet or Sites
		switch (this.localWidgetObject.AngularDirectiveName) {
			case "tacticalEquipmentActiveAlarms":
			case "tacticalOperationalAlerts":
			case "tacticalUserActiveAlarms":
			case "outOfServiceAssets":
			case "availableAssets":
			case "assetsInUse":
			case "gseTopFiveAlarmTypes":
			case "gseTopFiveEquipmentAlarmTypes":
			case "gseData":
			case "gsePeakReportData":
			case "locateAllGSE":
			case "gseUserDefinedActiveAlarms":
			case "gseActiveAlarms":
			case "gseEquipmentNotUsedLast":
			case "tacticalGSELevels":
			case "tacticalFireTruckLevels":
			case "tGSETopFive":
			case "gseSummary":
			case "gseOverview":
			case "fuelLevel":
			case "rawTagDataForAsset":
			case "tacticalFireEngineOverview":
			case "alarmEmailSettingsForAsset":
			case "fireTruckOverview":
				case "fireTruckOverviewAerial":
					case "fireTruckOverviewDriveline":
			case "assetGeofencing":
				this.selectTypes = this.CanUserSeeFleets() ? true : false;

				break;


			default:
				this.selectTypes = false;
		}

		//
		// Fleet
		//
		this.selectFleets = false;
		this.selectFleetAssetType = false;
		this.selectFleetAsset = false;

		this.selectSites =
			this.selectGlobal =
			this.selectSite =
			this.selectGSEAsset =
			this.selectTerminal =
			this.selectGate =
			this.selectAsset =
			this.selectBHS =
			this.selectGSEAssetType =
				false;

		// parse the Vocational settings json data
		if(this.localWidgetObject.VocationalSettingsJSON) {
			var VocationalSettings = JSON.parse(this.localWidgetObject.VocationalSettingsJSON);
			console.log("VocationalSettings ", VocationalSettings)

			this.Fleet.FleetId = VocationalSettings.id;

			// asset or assettype
			if(VocationalSettings.child.type == "AssetType") {
				this.localWidgetObject.WidgetAssetTypeId = VocationalSettings.child.id;

				let assetType = this.dataService.cache.assetTypes.find(
					(assetType) => {
						return assetType.Id == VocationalSettings.child.id;
					}
				);
				this.localWidgetObject.WidgetAssetTypeName = assetType?.Name;
			}
			else if(VocationalSettings.child.type == "Asset") {
				this.fleetAssetId = VocationalSettings.child.id;

			}
		}

		// get the list of fleets available
		if(this.selectTypes == true) {
			this.dataService.GetFleets().subscribe((data: any) => {

				Global.User.DebugMode && console.log("GetFleets", data);
				this.userFleets = data;

				if(this.Fleet.FleetId) {
					let myFleet = data.find((f) => {
						return f.FleetId === this.Fleet.FleetId;
					});
					if(myFleet) {
						this.Fleet.FleetId = myFleet.FleetId;
						this.Fleet.FleetName = myFleet.FleetName;
					}
					else {
						this.Fleet.FleetId = null;
						this.Fleet.FleetName = "";
					}
				}
				Global.User.DebugMode && console.log(this.Fleet);

			});
		}

		// get the list of fleet types
		this.FleetTypes = this.dataService.cache.assetTypes.filter(
			(assetType) => {
				return assetType.AssetTypeGroupId == 3 || assetType.AssetTypeGroupId == 4;
			}
		);

		// get the list of fleets available if needed
		if(this.localWidgetObject.AngularDirectiveName == "gseSummary" ||
				this.localWidgetObject.AngularDirectiveName == "gseActiveAlarms" ||
				this.localWidgetObject.AngularDirectiveName == "rawTagDataForAsset" ||
				this.localWidgetObject.AngularDirectiveName == "gsePeakReportData"  ||
				this.localWidgetObject.AngularDirectiveName == "alarmEmailSettingsForAsset"
				) {

			this.dataService.GetFleetAssets().subscribe((data: any) => {
				console.log("widget-settings-popup: GetFleetAssets = %O", data);
				this.allFleetAssets = data;

				this.allFleetAssets.forEach(a => {
					a.Name = this.dataService.cache.assetsObject[a.AssetId] == null ? a.Name : this.dataService.cache.assetsObject[a.AssetId]?.Name;
				});

				this.allFleetAssets.sort((a, b) => {
					let fa = a.Name.toLowerCase(),
						fb = b.Name.toLowerCase();

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});


				this.assets = this.allFleetAssets.filter(a => {
					return a.FleetId == this.Fleet.FleetId;
				});

			});
		}


		this.userSites = [];

		let sites = this.dataService.cache.sites
			.where((s: any) => {
				return s.Active == true;
			})
			.toArray();



		// let sites = _.orderBy(sitesNotFiltered, ["Name"], ["asc"]);

		sites = sites.filter((site) => {
			return site.Name !== undefined && site.Active === true;
		});
		sites = _.orderBy(sites, 'Name', 'asc');
		if (Global.User.isAdmin == false) {
			if (
				(this.widgetType === 'Standard' &&
					this.localWidgetObject.WidgetTypeName.startsWith('GSE') ==
						false &&
					this.localWidgetObject.WidgetTypeName.startsWith(
						'Deicer'
					) == false &&
					this.localWidgetObject.WidgetTypeName.startsWith('Fuel') ==
						false
						&&
					this.localWidgetObject.WidgetTypeName.startsWith('Fleet') ==
						false) ||
				this.widgetType === 'Tactical'
			) {
				//-- this is to restrict the JBT Distribution Sites from appearing as selectable sites for non-GSE widgets, since these are not 'real' airport sites. --Kirk T. Sherer, July 19, 2022.
				sites = this.dataService.cache.sites
					.where((s: any) => {
						return (
							s.JBTDistributionSite == false && s.Active == true
						);
					})
					.toArray();
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							': this.userSites excluding JBT Distribution Sites = %O',
						this.userSites
					);
			}
			let userSitesTemp = sites.filter((site) => {
				return Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
					site.Id
				);
			});
			var SiteIdsGroupedByWidgetTypeId = Global.User.currentUser.Security.SiteIdsGroupedByWidgetTypeId;
			let siteIdGroupedbyWidgetTypeIdObject =
				SiteIdsGroupedByWidgetTypeId.find(
					(widgetType) => {
						return (
							widgetType.WidgetTypeId ===
							this.localWidgetObject.WidgetTypeId
						);
					}
				);
			this.userSites = userSitesTemp.filter((site) => {
				return siteIdGroupedbyWidgetTypeIdObject.SiteIdCollection.includes(
					site.Id
				);
			});
		} else {
			this.userSites = sites;
		}

		if (this.widgetType === 'Tactical') {
			let assetTypeIds = [
				86292, 86293, 86294, 86295, 86296, 86297, 86298, 86299, 86300,
			];
			this.GSEAssetTypes = this.dataService.cache.assetTypes.filter(
				(assetType) => {
					return assetTypeIds.includes(assetType.Id);
				}
			);
			this.GSAssetTypes = this.dataService.cache.assetTypes.filter(
				(assetType) => {
					return !assetTypeIds.includes(assetType.Id);
				}
			);
		}
		if (!_.isNil(this.localWidgetObject.SiteList)) {
			if (typeof this.localWidgetObject.SiteList === 'string') {
				this.localWidgetObject.SiteList =
					this.localWidgetObject.SiteList.split(',').map((item) => {
						return parseInt(item);
					});
			}

			// set select of Site
			this.SitesSelected();

			this.selectFleets = false;
			this.selectFleetAssetType = false;
			this.selectFleetAsset = false;

			this.Type = "Site";
		}
		else if (!_.isNil(this.localWidgetObject.VocationalSettingsJSON)) {

			// set select of Fleet Settings
			this.FleetSelected();

			this.selectSites =
				this.selectGlobal =
				this.selectSite =
				this.selectGSEAsset =
				this.selectTerminal =
				this.selectGate =
				this.selectAsset =
				this.selectBHS =
				this.selectGSEAssetType =
					false;

			this.Type = "Fleet";
		}
		else {
			// set select of Site
			this.SitesSelected();

			this.selectFleets = false;
			this.selectFleetAssetType = false;
			this.selectFleetAsset = false;

			this.Type = "Site";
		}

		if(!this.global.User.currentUser.OrganizationUsesAirportSites){
			this.userTypes = ["Fleet"]
			this.selectSite = false;
			this.selectSites = false;
		}
	}

	CanUserSeeFleets(): boolean {
		return Global.User.currentUser.IsSystemAdministrator === true || Global.User.currentUser.Organization.UsesAirportSites == false;
	}

	FleetSelected() {
		// set select of Fleet Settings
		switch (this.localWidgetObject.AngularDirectiveName) {
			case "locateAllGSE":
			case "gseOverview":
			case "fuelLevel":
			case "gseUserDefinedActiveAlarms":
			case "tacticalFireEngineOverview":
			case "fireTruckOverview":
			case "fireTruckOverviewAerial":
			case "fireTruckOverviewDriveline":
			case "assetGeofencing":
			case "tacticalFireTruckLevels":
				this.selectFleets = true;
				this.selectFleetAssetType = false;
				this.selectFleetAsset = false;
				break;
			case "gseSummary":
			case "gseActiveAlarms":
			case "rawTagDataForAsset":
			case "gsePeakReportData":
			case "alarmEmailSettingsForAsset":
				this.selectFleets = true;
				this.selectFleetAssetType = false;
				this.selectFleetAsset = true;
				break;
			default:
				this.selectFleets = true;
				this.selectFleetAssetType = true;
				this.selectFleetAsset = false;
		}
	}

	SitesSelected() {
		switch (this.localWidgetObject.AngularDirectiveName) {
			case "siteGateSummary": // case "Site All Gate Summary":
			case "reports": // case "Reports":
			case "siteActiveAlarms": // case "Site Active Alarms":
			case "siteActiveWarnings": // case "Site Active Warnings":
			case "gsTopFiveAlarmTypes": // case "gsTopFiveAlarmTypes":
			case "gsTopFiveAlarmTypesByEquipment": // case "gsTopFiveAlarmTypesByEquipment":
			case "gsEquipmentUsage": // case "gsEquipmentUsage":
			case "gsEquipmentHoursOfUsage": // case "gsEquipmentHoursOfUsage":
			case "gsEquipmentUtilizationSummary": // case "gsEquipmentUtilizationSummary":
			case "pcaDischargePerformance": // case "pcaDischargePerformance":
			case "gsFlightSchedule": // case "gsFlightSchedule":
			case "siteCameraView": // case "siteCameraView":
			case "cameraView": // case "cameraView":
			case "basicApexChart": // case "basicApexChart":
			case "weather": // case "weather":
			case "gseTopFiveAlarmTypes":
			case "gseTopFiveEquipmentAlarmTypes":
			case "wheelChairOverview":
			case "centralUnitsOverview":
			case "gsPerfectHookupOpportunity":
			case "sabelData":
			case "gseData":
			case "assetGeofencing":
				this.selectSite = true;
				this.selectGSEAsset =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "terminalOverview": //case "terminalOverview":
				this.selectSite = this.selectTerminal = true;
				this.selectGSEAsset =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "equipmentHealth": //case "equipmentHealth":
			case "canvas": //case "canvas":
			case "centralUnitsSummary": //case "centralUnitsSummary":
				this.selectSite =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
						true;
				this.selectBHS = this.selectGSEAsset = false;
				break;

			case "alarmEmailSettingsForAsset": //case "alarmEmailSettingsForAsset":
				this.selectSite =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectGSEAsset =
						true;
				this.selectBHS = false;
				break;

			case "pcaSummary": //case "pcaSummary":
			case "pbbSummary": //case "pbbSummary":
			case "gpuSummary": //case "gpuSummary":
			case "ahuSummary": //case "ahuSummary":
			case "gsServiceCounters": //case "gsServiceCounters":
			case "gsPerfectHookup": //case "gsPerfectHookup":
			case "gateSystemCameraView": //case "gateSystemCameraView":
			case "wheelChairSummary": //case "wheelChairSummary":
				this.selectSite = this.selectTerminal = this.selectGate = true;
				this.selectGSEAsset = this.selectAsset = false;
				break;

			case "siteActiveCommsLoss": // case "Site Active Comms Loss":
				this.selectGSEAsset =
					this.selectSite =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "tagGraph":
				if (
					this.localWidgetObject.AllowSettingsSelection.type ===
					"gate"
				) {
					this.selectGate = true;
					this.selectGSEAsset =
						this.selectSite =
						this.selectTerminal =
						this.selectAsset =
						this.selectBHS =
						this.selectCentralUnit =
							false;
				} else if (
					this.localWidgetObject.AllowSettingsSelection.type === "gse"
				) {
					this.selectGSEAsset = true;
					this.selectGate =
						this.selectSite =
						this.selectTerminal =
						this.selectAsset =
						this.selectBHS =
						this.selectCentralUnit =
							false;
				}

				break;
			case "gsePeakReportData":
			case "gseSummary":
				this.selectSite = this.selectGSEAsset = true;
				this.selectGate =
					this.selectTerminal =
					this.selectAsset =
					this.selectBHS =
					this.selectCentralUnit =
						false;
				break;
			case "rawTagDataForAsset":
				this.selectSite =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectGSEAsset =
						true;
				this.selectBHS = false;
				break;
			case "gseOverview":
			case "deicerOverview":
			case "fuelLevel":
			case "locateAllGSE":
			case "gseUserDefinedActiveAlarms":
			case "mobileConditionedOverview":
			case "fireTruckOverview":
			case "fireTruckOverviewAerial":
			case "fireTruckOverviewDriveline":
				this.selectSites = this.selectGlobal = true;
				this.selectSite =
					this.selectGSEAsset =
					this.selectGate =
					this.selectTerminal =
					this.selectAsset =
					this.selectBHS =
					this.selectCentralUnit =
						false;
				break;
			case "gseActiveAlarms":
				this.selectSites =
					this.selectGlobal =
					this.selectGSEAsset =
						true;
				this.selectSite =
					this.selectGate =
					this.selectTerminal =
					this.selectAsset =
					this.selectBHS =
					this.selectCentralUnit =
						false;
				break;

			case "outOfServiceAssets":
			case "assetsInUse":
			case "tacticalEquipmentActiveAlarms":
			case "tacticalOperationalAlerts":
			case "tacticalUserActiveAlarms":
			case "availableAssets":
			case "gseEquipmentNotUsedLast":
			case "tacticalGSELevels":
			case "tacticalFireEngineOverview":
				this.selectGlobal = false;
				this.selectSites = true;
				this.selectSite = false;
				this.selectGSEAssetType = true;
				this.selectGSAssetType = false;
				this.selectGSEAsset =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "tGSETopFive":
				this.selectGlobal = false;
				this.selectSites = true;
				this.selectSite = false;
				this.selectGSEAssetType = true;
				this.selectGSAssetType = false;
				this.selectGSEAsset = true;
				this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "tGSTopFive":
				this.selectGlobal = false;
				this.selectSites = true;
				this.selectSite = false;
				this.selectGSEAssetType = false;
				this.selectGSAssetType = true;
				this.selectGSAsset = true;
				this.selectGSEAsset =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "gsAssetsInUse":
			case "gsAvailableAssets":
			case "gsOutOfService":
			case "gsAlarmStatus":
			case "gsCommsLoss":
			case "equipmentCycleCountSummary":
			case "gsEquipmentNotUsedLast":
				this.selectGlobal = false;
				this.selectSites = true;
				this.selectSite = false;
				this.selectGSEAssetType = false;
				this.selectGSAssetType = true;
				this.selectGSEAsset =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;

			case "pbbDocking":
			case "pcaNotMeetingPerformance":
				this.selectGlobal = false;
				this.selectSites = true;
				this.selectSite = false;
				this.selectGSEAssetType = false;
				this.selectGSAssetType = false;
				this.selectGSEAsset =
					this.selectTerminal =
					this.selectGate =
					this.selectAsset =
					this.selectBHS =
						false;
				break;
			default:
		}

		if (this.selectGlobal === true && this.widgetType === 'Standard') {
			this.evaluateGlobalMode();
		}
		this.exampleVar = 1;
		this.siteChange$ = new BehaviorSubject(this.exampleVar);
		this.siteChange$.subscribe(
			(siteId: any) => {
				// if (this.localWidgetObject.WidgetSiteId) {
				Global.User.DebugMode &&
					console.log(
						this.componentName + ': this.exampleVar = %O',
						this.exampleVar
					);
				Global.User.DebugMode &&
					console.log(
						this.componentName + ': new site id',
						this.localWidgetObject.WidgetSiteId
					);
				// this.service.widgetSite = this.service.dataService.cache.sitesObject[siteId.toString()];
				// this.service.widgetSiteId = siteId;
				// this.service.widgetSiteName = this.service.widgetSite && this.service.widgetSite.Name;
				// Global.User.DebugMode && console.log(this.componentName + ": widget site changed: new widgetSite = %O", this.service.widgetSite);
				// this.service.saveWidgetObject("SiteId", siteId);
				// this.service.getAllCamerasForSite(siteId);
				// }
			},
			(err: Error) => console.log(`Error with siteChange$: ${err}`)
		);

		if (this.selectTerminal) {
			this.terminals = null;
			this.terminalSystem = null;
			// this.widget.WidgetResource.TerminalSystemId = null;
			// this.widget.WidgetResource.GateSystemId = null;
			this.gates = null;
			this.assets = null;
			this.GetTerminalsForWidgetSite();
		}

		if (this.selectGate) {
			this.GetGatesForWidgetTerminal();
		}
		if (this.selectAsset) {
			this.GetAssetsForWidgetGate();
		}

		if (this.selectGSAsset) {
			this.GetGSAssetsForUserFromAssetType();
		}

		if (this.selectGSEAsset) {
			this.GetGSEAssetsForUserFromAssetType();
		}



	}

	showSites() {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': this.userSites = %O',
				this.userSites
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': Global.User.PermittedSites = %O',
				Global.User.PermittedSites
			);
	}

	evaluateTypesList(type) {
		return this.Type == type;
	}

	//
	// Fleet
	//

	evaluateFleetsList(id) {
		return this.Fleet.FleetId == id;
	}

	broadcastFleetTypeChange(assetTypeId) {
		console.log("broadcastFleetTypeChange assetTypeId", assetTypeId);

		// this.evaluateGlobalMode();

		this.localWidgetObject.WidgetAssetId = null;
		this.localWidgetObject.WidgetAssetTypeId = assetTypeId;
		this.SaveWidgetResourceObjectIfChanged('Fleet');

		// this.GetGSAssetsForUserFromAssetType();
		// this.GetGSEAssetsForUserFromAssetType();
	}

	broadcastFleetAssetChange(assetId) {
		this.fleetAssetId = assetId;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': fleet asset id',
				this.fleetAssetId
			);
		this.SaveWidgetResourceObjectIfChanged('Fleet');
	}


	broadcastTypesChange(group) {
		console.log("broadcastTypesChange group", group.value);

		if(group.value == "Fleet") {
			// set select of Fleet
			this.FleetSelected();

			this.selectSites =
				this.selectGlobal =
				this.selectSite =
				this.selectGSEAsset =
				this.selectTerminal =
				this.selectGate =
				this.selectAsset =
				this.selectBHS =
				this.selectGSEAssetType =
					false;


		}
		else if(group.value == "Site") {
			// set select of Site
			this.SitesSelected();

			this.selectFleets = false;
			this.selectFleetAssetType = false;
			this.selectFleetAsset = false;
		}

		this.localWidgetObject.WidgetAssetTypeId = null;
		this.localWidgetObject.WidgetAssetTypeName = null;

	}

	broadcastFleetsChange(group) {
		console.log("broadcastFleetsChange group", group.value);
		if(group.value.length > 0) {
			let FleetId = group.value[0];
			this.Fleet = this.userFleets.find((f) => {
				return f.FleetId === FleetId;
			});

			this.assets = this.allFleetAssets?.filter(a => {
				return a.FleetId == FleetId;
			});
		}

		this.localWidgetObject.WidgetAssetTypeId = null;
		this.localWidgetObject.WidgetAssetId = null;

		this.SaveWidgetResourceObjectIfChanged('Fleet');
	}

	broadcastSitesChange(group) {
		if (this.widgetType === 'Standard') {
			if (this.selectGlobal === true) {
				this.evaluateGlobalMode();
			}

			this.terminals = null;
			this.localWidgetObject.WidgetTerminalSystemId = null;
			this.localWidgetObject.WidgetGateSystemId = null;
			this.localWidgetObject.WidgetAssetId = null;
			this.gates = null;
			this.assets = null;
			this.GSEAssets = null;

			this.SaveWidgetResourceObjectIfChanged('SiteList');

			if (this.selectGSEAsset) {
				this.GetGSEAssetsForUserFromAssetType();
			}
			// this.GetTerminalsForWidgetSite();
		} else {
			let foundLimit = this.multiSiteLimitsForWidgetType.find((limit) => {
				return (
					limit.AngularDirectiveName ===
					this.localWidgetObject.AngularDirectiveName
				);
			});

			if (!_.isNil(foundLimit) && group.value.length > foundLimit.Limit) {
				let newValue = group.value;
				newValue.pop();
				this.localWidgetObject.SiteList = newValue;
				// this.toastr.info(
				// 	'Cannot have more than ' +
				// 		foundLimit.Limit +
				// 		' site/sites selected at one time.'
				// );
				this.utilityService.showToastMessageShared({
					type: 'info',
					message:
						'Cannot have more than ' +
						foundLimit.Limit +
						' site/sites selected at one time.',
				});
			} else if (this.localWidgetObject.SiteList && this.userSites) {
				this.localWidgetObject.SiteList = group.value;
				this.terminals = null;
				this.localWidgetObject.WidgetTerminalSystemId = null;
				this.localWidgetObject.WidgetGateSystemId = null;
				this.localWidgetObject.WidgetAssetId = null;
				this.gates = null;
				this.assets = null;
				this.GSEAssets = null;

				this.SaveWidgetResourceObjectIfChanged('SiteList');
			}

			if (this.selectGSEAsset) {
				this.GetGSEAssetsForUserFromAssetType();
			}
			if (this.selectGSAsset) {
				this.GetGSAssetsForUserFromAssetType();
			}
			// this.GetTerminalsForWidgetSite();
		}
	}

	turnToGlobalMode(shouldWe) {
		if (shouldWe === false) {
			this.localWidgetObject.SiteList = null;
			this.globalScopeSelected = false;
		} else {
			let siteList: any[] = this.userSites.map((asset) => {
				return asset.Id;
			});
			this.globalScopeSelected = true;
			this.localWidgetObject.SiteList = siteList;
		}
		this.SaveWidgetResourceObjectIfChanged('SiteList');
	}

	evaluateGlobalMode() {
		if (this.widgetType === 'Standard') {
			let isGlobal = true;
			this.userSites.every((asset) => {
				if (
					!_.isNil(this.localWidgetObject.SiteList) &&
					this.localWidgetObject.SiteList.includes(asset.Id)
				) {
					isGlobal = true;
				} else {
					isGlobal = false;
				}
				return isGlobal;
			});
			this.globalScopeSelected = isGlobal;
		} else {
			if (
				_.isNil(this.localWidgetObject.WidgetSiteId) &&
				_.isNil(this.localWidgetObject.WidgetAssetTypeId)
			) {
				this.globalScopeSelected = true;
			} else {
				this.globalScopeSelected = false;
			}
		}
	}

	broadcastSiteChange(siteId) {
		if (this.widgetType === 'Standard') {
			this.localWidgetObject.WidgetSiteId = siteId;
			if (this.localWidgetObject.WidgetSiteId && this.userSites) {
				this.terminals = null;
				this.localWidgetObject.WidgetTerminalSystemId = null;
				this.localWidgetObject.WidgetGateSystemId = null;
				this.localWidgetObject.WidgetAssetId = null;
				this.gates = null;
				this.assets = null;
				this.GSEAssets = null;
				Global.User.DebugMode &&
					console.log(
						this.componentName + ': widget site id',
						this.localWidgetObject.WidgetSiteId
					);
				Global.User.DebugMode &&
					console.log(
						this.componentName + ': widget terminal id',
						this.localWidgetObject.WidgetTerminalSystemId
					);

				Global.User.DebugMode &&
					console.log(
						this.componentName + ': widget gate id',
						this.localWidgetObject.WidgetGateSystemId
					);
				this.SaveWidgetResourceObjectIfChanged('Site');
			}

			if (this.selectGSEAsset) {
				this.GetGSEAssetsForUserFromAssetType();
			}
			this.GetTerminalsForWidgetSite();
		} else {
			this.localWidgetObject.WidgetSiteId = siteId;
			this.evaluateGlobalMode();
			if (this.userSites) {
				this.SaveWidgetResourceObjectIfChanged('Site');
			}
		}
	}

	broadcastAssetTypeChange(assetTypeId) {
		this.localWidgetObject.WidgetAssetTypeId = assetTypeId;
		this.evaluateGlobalMode();

		this.localWidgetObject.WidgetAssetId = null;

		this.SaveWidgetResourceObjectIfChanged('AssetType');
		// }

		// this.GetTerminalsForWidgetSite();

		this.GetGSAssetsForUserFromAssetType();
		this.GetGSEAssetsForUserFromAssetType();
	}

	broadcastTerminalChange(terminalId) {
		this.localWidgetObject.WidgetTerminalSystemId = terminalId;
		this.localWidgetObject.WidgetGateSystemId = null;
		if (this.widgetType === 'Standard') {
			this.localWidgetObject.WidgetAssetId = null;
		}
		this.gates = null;
		this.assets = null;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': widget terminal id',
				this.localWidgetObject.WidgetTerminalSystemId
			);

		Global.User.DebugMode &&
			console.log(
				this.componentName + ': widget gate id',
				this.localWidgetObject.WidgetGateSystemId
			);
		this.SaveWidgetResourceObjectIfChanged('Terminal');

		this.GetGatesForWidgetTerminal();
	}

	broadcastGateChange(gateId) {
		this.localWidgetObject.WidgetGateSystemId = gateId;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': widget gate id',
				this.localWidgetObject.WidgetGateSystemId
			);
		if (this.widgetType === 'Standard') {
			this.localWidgetObject.WidgetAssetId = null;
			this.assets = null;
		}

		this.SaveWidgetResourceObjectIfChanged('Gate');
		this.GetAssetsForWidgetGate();
	}

	broadcastGSChange(gsId) {
		this.localWidgetObject.WidgetAssetId = gsId;
		this.SaveWidgetResourceObjectIfChanged('GS');
	}

	broadcastGSEChange(gseId) {
		if (this.widgetType === 'Standard') {
			if (this.localWidgetObject.AllowSettingsSelection) {
				this.localWidgetObject.AllowSettingsSelection.assetId = gseId;
			}
			this.localWidgetObject.WidgetAssetId = gseId;
			this.localWidgetObject.WidgetTerminalSystemId = null;
			this.localWidgetObject.WidgetGateSystemId = null;
			this.terminals = null;
			this.gates = null;
			this.assets = null;
			this.SaveWidgetResourceObjectIfChanged('GSE');
		} else {
			this.localWidgetObject.WidgetAssetId = gseId;
			this.SaveWidgetResourceObjectIfChanged('GSE');
		}
	}

	broadcastAssetChange(assetId) {
		this.localWidgetObject.WidgetAssetId = assetId;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': widget asset id',
				this.localWidgetObject.WidgetAssetId
			);
		this.SaveWidgetResourceObjectIfChanged('Asset');
	}

	GetTerminalsForWidgetSite() {
		if (this.localWidgetObject.WidgetSiteId) {
			this.terminals = this.dataService.cache.systems.filter((s) => {
				return (
					s.SiteId == this.localWidgetObject.WidgetSiteId &&
					s.Type == 'Terminal'
				);
			});
			if (this.terminals.length === 1) {
				this.localWidgetObject.WidgetTerminalSystemId =
					this.terminals[0].Id;
			}
			this.GetGatesForWidgetTerminal();
		}
	}

	GetGatesForWidgetTerminal() {
		if (
			this.widgetType === 'Standard' &&
			this.localWidgetObject.AngularDirectiveName === 'tagGraph'
		) {
			//look up the zone for the provided gateSystemId
			let foundParentSystem = this.dataService.cache.systems.find(
				(system) => {
					return (
						system.Id === this.localWidgetObject.WidgetGateSystemId
					);
				}
			);

			if (
				foundParentSystem !== undefined &&
				foundParentSystem.ParentSystem !== undefined
			) {
				this.localWidgetObject.WidgetTerminalSystemId =
					foundParentSystem.ParentSystem.Id;
			}
		}
		this.gates = this.dataService.cache.systems
			.filter((s) => {
				if (
					!isNaN(s.TypeId) &&
					this.localWidgetObject.AngularDirectiveName ==
						'centralUnitsSummary'
				) {
					return s.TypeId === 15; //15 = Central 400HZ Converters(C4C1)
				} else if (
					!isNaN(s.TypeId) &&
					this.localWidgetObject.AngularDirectiveName ==
						'rawTagDataForAsset'
				) {
					return s.TypeId === 3 || s.TypeId === 15; // 3= Gate Systems , 15 = Central 400HZ Converters
				} else if (!isNaN(s.TypeId)) {
					if (
						this.localWidgetObject.AngularDirectiveName ==
						'gpuSummary'
					)
						return s.TypeId === 3 && s.GPUPresent;
					// 3 = Gate System , return Gates that have GPU Asset
					else if (
						this.localWidgetObject.AngularDirectiveName ==
						'pcaSummary'
					)
						return s.TypeId === 3 && s.PCAPresent;
					// 3 = Gate System , return Gates that have PCA Asset
					else if (
						this.localWidgetObject.AngularDirectiveName ==
						'pbbSummary'
					)
						return s.TypeId === 3 && s.PBBPresent;
					// 3 = Gate System , return Gates that have PBB Asset
					else return s.TypeId === 3;
				}
			})
			.filter((s) => {
				return (
					s.ParentSystemId ==
					this.localWidgetObject.WidgetTerminalSystemId
				);
			})
			.filter((s) => {
				if (
					!isNaN(s.TypeId) &&
					(this.localWidgetObject.AngularDirectiveName ==
						'rawTagDataForAsset' ||
						this.localWidgetObject.AngularDirectiveName ==
							'centralUnitsSummary')
				) {
					return this.dataService.cache.assets.some((a) => {
						return a.ParentSystemId == s.Id;
					});
				} else if (!isNaN(s.TypeId)) {
					return this.dataService.cache.assets.some((a) => {
						if (
							this.localWidgetObject.AngularDirectiveName ==
								'gpuSummary' ||
							this.localWidgetObject.AngularDirectiveName ==
								'pcaSummary' ||
							this.localWidgetObject.AngularDirectiveName ==
								'pbbSummary'
						) {
							return (
								a.ParentSystemId == s.Id &&
								(a.Name == 'PCA' ||
									a.Name == 'PBB' ||
									a.Name == 'GPU')
							);
						} else if (
							this.localWidgetObject.AngularDirectiveName ==
							'ahuSummary'
						) {
							return a.ParentSystemId == s.Id && a.Name == 'AHU';
						} else {
							return (
								a.ParentSystemId == s.Id &&
								(a.Name == 'PCA' ||
									a.Name == 'PBB' ||
									a.Name == 'GPU' ||
									'AHU')
							);
						}
					});
				}
			})
			.sort((g1, g2) =>
				this.utilityService
					.GetGateNameSortValue(g1.Name.toString())
					.localeCompare(
						this.utilityService.GetGateNameSortValue(
							g2.Name.toString()
						),
						'en',
						{ numeric: true }
					)
			);

		if (this.gates.length == 1) {
			this.localWidgetObject.WidgetGateSystemId = this.gates[0].Id;
		}

		if (this.gates.length < 0) {
			this.selectGate = false;
			this.selectAsset = true;
		}

		// if (vm.gates.length == 1) {
		//   vm.gateSystem = vm.gates[0];
		//   vm.widget.WidgetResource.GateSystemId = vm.gateSystem.Id;
		// }

		this.GetAssetsForWidgetGate();
	}

	GetAssetsForWidgetGate() {
		this.assets = this.dataService.cache.assets
			.filter((a) => {
				if (this.localWidgetObject.WidgetGateSystemId) {
					if (this.widgetType === 'Standard') {
						//Display the asset only if the ParentSystem has it Present in the bit field
						if (a.Name == 'GPU')
							return (
								a.ParentSystemId ==
									this.localWidgetObject.WidgetGateSystemId &&
								a.ParentSystem.GPUPresent
							);
						else if (a.Name == 'PCA')
							return (
								a.ParentSystemId ==
									this.localWidgetObject.WidgetGateSystemId &&
								a.ParentSystem.PCAPresent
							);
						else if (a.Name == 'PBB')
							return (
								a.ParentSystemId ==
									this.localWidgetObject.WidgetGateSystemId &&
								a.ParentSystem.PBBPresent
							);
						else
							return (
								a.ParentSystemId ==
								this.localWidgetObject.WidgetGateSystemId
							);
					} else {
						return (
							a.ParentSystemId ==
							this.localWidgetObject.WidgetGateSystemId
						);
					}
				}
			})

			.sort((a) => {
				return a.Name;
			});
		if (this.assets.length == 1) {
			this.localWidgetObject.WidgetAssetId = this.assets[0].Id;
		}
	}

	GetGSEAssetsForUserFromAssetType() {
		if (
			this.localWidgetObject.AngularDirectiveName == 'gsePeakReportData'
		) {
			this.GSEAssets = this.dataService.cache.assets
				.filter((a) => {
					return (
						a.SiteId == this.localWidgetObject.WidgetSiteId &&
						(a.AssetTypeId == 86292 ||
							a.AssetTypeId == 86293 ||
							a.AssetTypeId == 86294 ||
							a.AssetTypeId == 86295)
					);
				})
				.sort((a) => {
					return a.Name;
				});
		} else {
			if (
				this.localWidgetObject.WidgetAssetTypeId ||
				this.localWidgetObject.SiteList?.length > 0 ||
				this.localWidgetObject.WidgetSiteId
			) {
				this.GSEAssets = this.dataService.cache.assets;
				if (this.localWidgetObject.WidgetAssetTypeId) {
					this.GSEAssets = this.GSEAssets.filter((a) => {
						return (
							parseInt(a.AssetTypeId) ===
							parseInt(this.localWidgetObject.WidgetAssetTypeId)
						);
					});
				}
				if (this.localWidgetObject.SiteList?.length > 0) {
					this.GSEAssets = this.GSEAssets.filter((a) => {
						return (
							[
								86292, 86293, 86294, 86295, 86296, 86297, 86298,
								86299, 86300,
							].includes(parseInt(a.AssetTypeId)) &&
							this.localWidgetObject.SiteList.includes(a.Site?.Id)
						);
					});
				}
				if (this.localWidgetObject.WidgetSiteId) {
					this.GSEAssets = this.GSEAssets.filter((a) => {
						return (
							[
								86292, 86293, 86294, 86295, 86296, 86297, 86298,
								86299, 86300,
							].includes(parseInt(a.AssetTypeId)) &&
							a.Site?.Id === this.localWidgetObject.WidgetSiteId
						);
					});
				}

				this.GSEAssets.sort((a) => {
					return a.Name;
				});
			} else {
				this.GSEAssets = [];
			}
		}
	}

	GetGSAssetsForUserFromAssetType() {
		if (
			this.localWidgetObject.SiteList?.length > 0 ||
			this.localWidgetObject.WidgetSiteId
		) {
			this.GSAssets = this.dataService.cache.assets;
			if (this.localWidgetObject.WidgetAssetTypeId) {
				this.GSAssets = this.GSAssets.filter((a) => {
					return (
						parseInt(a.AssetTypeId) ===
						parseInt(this.localWidgetObject.WidgetAssetTypeId)
					);
				});
			}
			if (this.localWidgetObject.SiteList?.length > 0) {
				this.GSAssets = this.GSAssets.filter((a) => {
					return (
						this.GSAssetTypes.find(
							(x) => x.Id == parseInt(a.AssetTypeId)
						) &&
						this.localWidgetObject.SiteList.includes(a.Site?.Id)
					);
				});
			}
			if (this.localWidgetObject.WidgetSiteId) {
				this.GSAssets = this.GSAssets.filter((a) => {
					return (
						this.GSAssetTypes.includes(parseInt(a.AssetTypeId)) &&
						a.Site?.Id === this.localWidgetObject.WidgetSiteId
					);
				});
			}

			this.GSAssets.sort((a, b) => {
				//							{{ gs.Site.Name }} {{ gs.ParentSystem.Name }} {{ gs.Name }}</mat-button-toggle>
				return (
					a.Site.Name.localeCompare(b.Site.Name) ||
					a.ParentSystem.Name.localeCompare(
						b.ParentSystem.Name,
						'en',
						{ numeric: true }
					) ||
					a.Name.localeCompare(b.Name)
				);
			});
		} else {
			this.GSAssets = [];
		}
		console.log('GS Assets ', this.GSAssets);
	}

	SaveWidgetResourceObjectIfChanged(SettingSaved: string) {
		let statement =
			'API.DashboardUpdateWidgetRecordSettings ' +
			'@ParentDashboardId = ' +
			this.localWidgetObject.Id +
			', @Id = ' +
			this.localWidgetObject.WidgetId;


		// save fleet info as JSON
		if (SettingSaved === 'Fleet') {
			const fleetConfig = {
				type: "Fleet",
				id: this.Fleet.FleetId,
				child: {
					type: this.selectFleetAssetType === true ? "AssetType" : "Asset",
					id:  this.selectFleetAssetType === true ? this.localWidgetObject.WidgetAssetTypeId : this.fleetAssetId,
				}
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));
			statement = statement + ", @VocationalSettingsJSON = '" + stringFleetConfig + "'";
			if(fleetConfig?.child?.type === 'Asset' && fleetConfig?.child?.id !== null && fleetConfig?.child?.id !== undefined){
				statement = statement + ', @widgetAssetSystemId = ' + fleetConfig.child.id;

			}

			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				let returnedWidget = data.first();

				this.ProcessSavedData(data, SettingSaved);

			});

			return;
		}


		if (this.widgetType === 'Standard') {
			if (_.isNil(this.dataSource.widget.SiteList)) {
				statement = statement + ', @widgetSiteList = ' + null;
			} else if (!_.isNil(this.dataSource.widget.SiteList)) {
				if (typeof this.dataSource.widget.SiteList === 'string')
					this.localWidgetObject.SiteListAsString =
						this.dataSource.widget.SiteList;
				else
					this.localWidgetObject.SiteListAsString =
						this.dataSource.widget.SiteList.join();
				statement =
					statement +
					", @widgetSiteList = '" +
					this.localWidgetObject.SiteListAsString +
					"'";
			}
			if (this.localWidgetObject.WidgetSiteId === undefined) {
				this.localWidgetObject.WidgetSiteId = null;
			}
			if (this.localWidgetObject.WidgetTerminalSystemId === undefined) {
				this.localWidgetObject.WidgetTerminalSystemId = null;
			}

			if (this.localWidgetObject.WidgetGateSystemId === undefined) {
				this.localWidgetObject.WidgetGateSystemId = null;
			}
			if (this.localWidgetObject.WidgetAssetId === undefined) {
				this.localWidgetObject.WidgetAssetId = null;
			}
			if (this.localWidgetObject.SelectedTabIndex === undefined) {
				this.localWidgetObject.SelectedTabIndex = 0;
			}
			statement =
				statement +
				', @widgetSiteId = ' +
				this.localWidgetObject.WidgetSiteId;
			statement =
				statement +
				', @widgetTerminalSystemId = ' +
				this.localWidgetObject.WidgetTerminalSystemId;

			statement =
				statement +
				', @widgetGateSystemId = ' +
				this.localWidgetObject.WidgetGateSystemId;
			statement =
				statement +
				', @widgetAssetSystemId = ' +
				this.localWidgetObject.WidgetAssetId +
				', @selectedTabIndex = ' +
				this.localWidgetObject.SelectedTabIndex;

			if (
				!_.isNil(this.localWidgetObject.TimeZoneId) &&
				Global.User.isLoggedInAsDifferentUser == false
			) {
				statement =
					statement +
					', @widgetTimeZoneId = ' +
					this.localWidgetObject.TimeZoneId;
			}
			if (!_.isNil(this.localWidgetObject.TimeScopeId)) {
				statement =
					statement +
					', @widgetTimeScopeId = ' +
					this.localWidgetObject.TimeScopeId;
			}
		} else {
			statement =
				statement +
				', @widgetSiteId = ' +
				this.localWidgetObject.WidgetSiteId +
				', @widgetAssetTypeId = ' +
				this.localWidgetObject.WidgetAssetTypeId +
				', @selectedTabIndex = ' +
				this.localWidgetObject.SelectedTabIndex;
			if (_.isNil(this.localWidgetObject.SiteList)) {
				statement =
					statement +
					', @widgetSiteList = ' +
					this.localWidgetObject.SiteList;
			} else if (!_.isNil(this.localWidgetObject.SiteList)) {
				if (typeof this.localWidgetObject.SiteList === 'string') {
					this.localWidgetObject.SiteList =
						this.localWidgetObject.SiteList.split(',').map(
							(item) => {
								return parseInt(item);
							}
						);
				}
				this.localWidgetObject.SiteListAsString =
					this.localWidgetObject.SiteList.join();
				statement =
					statement +
					", @widgetSiteList = '" +
					this.localWidgetObject.SiteListAsString +
					"'";
			}

			if (!_.isNil(this.localWidgetObject.WidgetTerminalSystemId)) {
				statement =
					statement +
					', @widgetTerminalSystemId = ' +
					this.localWidgetObject.WidgetTerminalSystemId;
			}

			if (!_.isNil(this.localWidgetObject.WidgetGateSystemId)) {
				statement =
					statement +
					', @widgetGateSystemId = ' +
					this.localWidgetObject.WidgetGateSystemId;
			}
			if (!_.isNil(this.localWidgetObject.WidgetAssetId)) {
				statement =
					statement +
					', @widgetAssetSystemId = ' +
					this.localWidgetObject.WidgetAssetId;
			}
			if (
				!_.isNil(this.localWidgetObject.TimeZoneId) &&
				Global.User.isLoggedInAsDifferentUser == false
			) {
				statement =
					statement +
					', @widgetTimeZoneId = ' +
					this.localWidgetObject.TimeZoneId;
			}
			if (!_.isNil(this.localWidgetObject.TimeScopeId)) {
				statement =
					statement +
					', @widgetTimeScopeId = ' +
					this.localWidgetObject.TimeScopeId;
			}
		}

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			let returnedWidget = data.first();

			// Force Site Time when logged in as another user
			if (Global.User.isLoggedInAsDifferentUser == true) {
				returnedWidget.TimeZoneId = 2;
			}

			if (this.localWidgetObject.AngularDirectiveName === 'tagGraph') {
				if (SettingSaved === 'GSE') {
					let statementForTagGraph =
						'API.TagGraphSwitchAsset ' +
						'@widgetid = ' +
						this.localWidgetObject.WidgetId +
						', @targetAssetId = ' +
						this.localWidgetObject.AllowSettingsSelection.assetId;
					this.dataService
						.SQLActionAsPromise(statementForTagGraph)
						.then((tagGraphReturnedData: any) => {
							console.log('Tags switched for new gate');
							this.ProcessSavedData(data, SettingSaved);
						});
				} else {
					let statementForTagGraph =
						'API.TagGraphSwitchGate ' +
						'@widgetid = ' +
						this.localWidgetObject.WidgetId +
						', @targetGateSystemId = ' +
						this.localWidgetObject.WidgetGateSystemId;
					this.dataService
						.SQLActionAsPromise(statementForTagGraph)
						.then((tagGraphReturnedData: any) => {
							console.log('Tags switched for new gate');
							this.ProcessSavedData(data, SettingSaved);
						});
				}
			} else {
				this.ProcessSavedData(data, SettingSaved);
			}
		});
	}

	evaluateSitesList(id) {
		return !_.isNil(this.dataSource.widget.SiteList)
			? this.dataSource.widget.SiteList.includes(id)
			: false;
	}

	ProcessSavedData(data, SettingSaved) {
		this.localWidgetObject = data.first();
		let returnedWidget = data.first();

		// Force Site Time when logged in as another user
		if (
			this.widgetType === 'Standard' &&
			Global.User.isLoggedInAsDifferentUser == true
		) {
			returnedWidget.TimeZoneId = 2;
		}

		//
		// Fleet
		//
		if (SettingSaved === 'Fleet') {

			var VocationalSettings = JSON.parse(returnedWidget.VocationalSettingsJSON);
			console.log("VocationalSettings ", VocationalSettings)
			let fleetId = VocationalSettings.id;
			returnedWidget.WidgetAssetTypeId = VocationalSettings.child.id;

			let assetType = this.dataService.cache.assetTypes.find(
				(assetType) => {
					return assetType.Id == VocationalSettings.child.id;
				}
			);
			returnedWidget.WidgetAssetTypeName = assetType?.Name;
		}

		returnedWidget.cols = returnedWidget.Width;
		returnedWidget.rows = returnedWidget.Height;
		returnedWidget.x = returnedWidget.Col;
		returnedWidget.y = returnedWidget.Row;
		let dashboardId = returnedWidget.Id;
		let widgetId = data.first().WidgetId;
		let dashboardIndexInGlobalDashboardsArray =
			Global.User.currentUser.Dashboards.findIndex(
				(obj) => obj.Id == dashboardId
			);
		let widgetIndexinGlobalDashboardArray =
			Global.User.currentUser.Dashboards[
				dashboardIndexInGlobalDashboardsArray
			].widgets.findIndex((obj) => obj.WidgetId == widgetId);
		Global.User.currentUser.Dashboards[
			dashboardIndexInGlobalDashboardsArray
		].widgets[widgetIndexinGlobalDashboardArray] = returnedWidget;
		Global.User.DebugMode &&
			console.log(this.componentName + ': data updated: %0', data);
		this.toastr.success(SettingSaved + ' settings have been changed!');
		Global.User.DebugMode && console.log(data);
		this.dashboardService._editedWidget.next(data);
	}

	onCancel() {
		this.dialogRef.close();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.siteChange$) {
			this.siteChange$.unsubscribe();
		}
		this.selectSite = false;
		this.selectTerminal = false;
		this.selectAsset = false;
		this.selectBHS = false;
		this.selectGate = false;
		this.userSites = [];
	}
}
