import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TacticalDashboardOverviewGaugeComponent } from './tactical-dashboard-overview-gauge.component';
import { GaugesModule } from "@progress/kendo-angular-gauges";




@NgModule({
  declarations: [
    TacticalDashboardOverviewGaugeComponent
  ],
  imports: [
    CommonModule,
    GaugesModule
  ],
  exports: [TacticalDashboardOverviewGaugeComponent]
})
export class TacticalDashboardOverviewGaugeModule { }
