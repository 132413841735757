import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TacticalDashboardIntervalPopupConfirmationSettingsComponent } from './tactical-dashboard-interval-popup-confirmation-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';

@NgModule({
	declarations: [TacticalDashboardIntervalPopupConfirmationSettingsComponent],
	imports: [
		CommonModule,
		DragDropModule,
		MatButtonModule,
		MatTooltipModule,
		MatSelectModule,
		MatCheckboxModule,
		FormsModule,
		ReactiveFormsModule,
		GridModule,
	],
	exports: [TacticalDashboardIntervalPopupConfirmationSettingsComponent],
})
export class TacticalDashboardIntervalPopupConfirmationSettingsModule {}
