import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaDischargeModeComponent } from './pca-discharge-mode.component';



@NgModule({
  declarations: [
    PcaDischargeModeComponent
  ],
  imports: [
    CommonModule
  ], exports: [PcaDischargeModeComponent]
})
export class PcaDischargeModeModule { }
