import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotesComponent } from 'projects/shared-lib/src/lib/components/release-notes/release-notes.component';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { SignalRCoreService } from 'projects/shared-lib/src/lib/services/signalr-core.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { IGlobal } from 'projects/shared-lib/src/lib/_models/global.model';
import { SignalRTestComponent } from 'projects/shared-lib/src/lib/components/signalr-test/signalr-test.component';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
	test: Date = new Date();
	public global: IGlobal = Global;
	public appVersion: string; // <-- Our application version.
	public environmentName: string; // <-- current environment name.  Production will not have an environment name displayed. --Kirk T. Sherer, January 6, 2021.
	public countOfSignalRObservations: number =
		Global.SignalR.countOfObservations;
	public signalRCounterIsNotNumeric: boolean = false;
	public testEnvironment: boolean =
		document.URL.indexOf('localhost') > 0 ||
		document.URL.indexOf('test') > 0
			? true
			: false;
	public hubConnectionState: string;
	public localCountOfSignalRMessages: number;

	constructor(
		public signalRCore: SignalRCoreService,
		private dataService: DataService,
		public dialog: MatDialog,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.environmentName = Global.Application.Environment.Name;
		this.appVersion = Global.Application.Environment.Version;
		//console.log("footer-component: Initializing... current Global.SignalR.countOfObservations = " + Global.SignalR.countOfObservations);
		this.signalRCore.countOfSignalRObservations$.subscribe(
			(barney: number) => {
				if (isNaN(barney) == true) {
					this.signalRCounterIsNotNumeric = true;
				} else {
					this.signalRCounterIsNotNumeric = false;
				}
				// console.log("countOfObservations: " + barney);
				// this.updateLocalCounter(barney);
				
			}
		);

		this.signalRCore.hubConnectionState$.subscribe((state: string) => {
			this.hubConnectionState = state;
		});
	}

	public wait(ms: number) {
		return new Promise( resolve => setTimeout(resolve, ms) );
	}
	
	public async updateLocalCounter(barney: number) {
		//await this.wait(10000);
		this.localCountOfSignalRMessages = barney;
	}

	showSignalRTest() {
		const dialogRef = this.dialog.open(SignalRTestComponent, {
			width: '50%',
			height: '90%',
			position: { right: "0" },

			hasBackdrop: false,
			disableClose: true,
			autoFocus: false,
		});
		dialogRef.afterClosed().subscribe((result) => {
			Global.User.DebugMode && console.log('The dialog was closed');
		});
	}
}


