<div style="height: 100%;">
	<form *ngIf="form" class="form-horizontal dynamic-form" (ngSubmit)="onSubmit()" [formGroup]="form" >
		<div *ngFor="let question of questions">
			<lib-dynamic-form-question [question]="question" [form]="form" [formOptions]="options" [saveOnChange]="options.saveValuesPerField" [saveStoredProcedureName]="options.saveStoredProcedureName" [saveApiUrl]="options.saveApiUrl" [deleteApiUrl]="options.deleteApiUrl" [signalRGroupToNotify]="options.signalRGroupToNotify" [signalRNotifyCode]="options.signalRNotifyCode" [dataCacheObject]="options.dataCacheObject" [isPartOfInternalDialog]="isInternalDialog" [formHasDynamicPropertyFields]="hasDynamicPropertyFields" (notifyParentFormIsLoaded)="formHasBeenLoaded()" (notifyParent)="checkFields(question)" [listOfQuestions]="questions" (notifyParentQuestionsHaveChanged)="reloadQuestions(question)" (readonly)="question.editable == false"></lib-dynamic-form-question>
		</div>
		<div *ngIf="options.allowAddNewQuestion"  >
			<div class="col-sm-4 col-md-4 col-form-label">
				<input id="newQuestion" class="form-control" formControlName="newQuestion" form="form" placeholder="Enter new Field Name..." title="Enter new Field Name here..." (change)="addNewQuestion($event)" />
			</div>
		</div>
		<div *ngIf="!options.saveValuesPerField" class="card-footer text-center" >
			<!-- form.valid = {{form.valid}}, processingForm = {{processingForm}}, internalDialogIsVisible$ = {{internalDialogIsVisible$|async}}, global.RDN.internalDialog = {{global.RDN.internalDialog}}, isInternalDialog = {{isInternalDialog}} -->
			<button class="btn" [ngClass]="options.submitButtonClass ? options.submitButtonClass : 'btn-danger'" type="button" title="options.submitButtonTitle" [disabled]="!form.valid || processingForm || ((internalDialogIsVisible$ | async ) || global.RDN.internalDialog) && !isInternalDialog" (click)="onSubmit(true)">{{ options.submitButtonText ? options.submitButtonText : "Submit" }}</button>
		</div>
	</form>
</div>

