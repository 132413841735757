
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraViewComponent } from './camera-view.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { IonicModule } from '@ionic/angular';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AngularResizeEventModule } from 'angular-resize-event';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { PipeModule } from "../../_helpers/pipes/pipe.module";
import { NavigationModule } from '../navigation/navigation.module';
import { VideoPlayerModule } from '../video-player/video-player.module';


@NgModule({
    declarations: [CameraViewComponent],
    exports: [CameraViewComponent],
    imports: [
        CommonModule,
        CenteredMessageModule,
        MatListModule,
        MatSidenavModule,
        DateTimePickerModule,
        LoadingSpinnerModule,
        IonicModule,
        InputsModule,
        LabelModule,
        FormsModule,
        ReactiveFormsModule,
        DropDownsModule,
        AngularResizeEventModule,
        CdkAccordionModule,
        PipeModule,
		NavigationModule,
		VideoPlayerModule
    ]
})
export class CameraViewModule { }
