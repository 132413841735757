<div *ngIf="root" class="flex-container navigation-tab" id="navigation-content" >
	<div *ngFor="let fred of options; let index = index;">
		<div id="tab" [ngClass]="index > 0 ? 'navigation-tab-margin' : ''">
			<div class="navigation-tab-label" (click)="openCloseNavigation(fred)">
				<div class="vertical-text">{{fred.name}}</div>
			</div>
			<div id="nav" [ngClass]="fred.opened ? 'nav-visible' : 'nav-hidden'" class="navigation-content" >
				<input *ngIf="fred.search" class="search-text" type='text' [ngModel]='searchText' placeholder="Enter text to search..." (ngModelChange)="searchForText($event, fred)" >
				<lib-navigation id="marky" [options]="fred.children" (selected)="checkSelectedItem($event)"></lib-navigation>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!root">
	<cdk-accordion class="accordion" >
        <cdk-accordion-item [expanded]="item.selected" [style.width]="item.width" *ngFor="let item of options; let index = index;" #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
            <div *ngIf="item.children != null">
                <div *ngIf="item.id == 10">
                    <div  class="accordion-item-header" [ngClass]="accordionItem.expanded ? 'accordion-item-selected' : ''" (click)="accordionChange($event, accordionItem, item)"  >
                        &nbsp;&nbsp;{{ item.name }}
                    </div>
                    <div class="accordion-item-body" role="region" [ngClass]="accordionItem.expanded ? 'accordion-item-visible' : 'accordion-item-hidden'" [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                        <lib-navigation id="kirky" [options]="item.children" (selected)="checkSelectedItem($event)"></lib-navigation>
                    </div>
                </div>
                <div *ngIf="item.id == 11">
                    <div  class="accordion-child-item-header" [ngClass]="accordionItem.expanded ? 'accordion-child-item-selected' : ''" (click)="accordionChange($event, accordionItem, item)"  >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}
                    </div>
                    <div class="accordion-child-item-body" role="region" [ngClass]="accordionItem.expanded ? 'accordion-child-item-visible' : 'accordion-child-item-hidden'" [attr.id]="'accordion-child-body-' + index" [attr.aria-labelledby]="'accordion-child-header-' + index">
                        <lib-navigation id="kirky" [options]="item.children" (selected)="checkSelectedItem($event)"></lib-navigation>
                    </div>
                </div>
                <div *ngIf="item.id == 12">
                    <div  class="accordion-grandchild-item-header" [ngClass]="accordionItem.expanded ? 'accordion-grandchild-item-selected' : ''" (click)="accordionChange($event, accordionItem, item)"  >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}
                    </div>
                    <div class="accordion-grandchild-item-body" role="region" [ngClass]="accordionItem.expanded ? 'accordion-grandchild-item-visible' : 'accordion-grandchild-item-hidden'" [attr.id]="'accordion-grandchild-body-' + index" [attr.aria-labelledby]="'accordion-grandchild-header-' + index">
                        <lib-navigation id="kirky" [options]="item.children" (selected)="checkSelectedItem($event)"></lib-navigation>
                    </div>
                </div>
                <div *ngIf="item.id == 13">
                    <div  class="accordion-great-grandchild-item-header" [ngClass]="accordionItem.expanded ? 'accordion-great-grandchild-item-selected' : ''" (click)="accordionChange($event, accordionItem, item)"  >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}
                    </div>
                    <div class="accordion-great-grandchild-item-body" role="region" [ngClass]="accordionItem.expanded ? 'accordion-great-grandchild-item-visible' : 'accordion-great-grandchild-item-hidden'" [attr.id]="'accordion-great-grandchild-body-' + index" [attr.aria-labelledby]="'accordion-great-grandchild-header-' + index">
                        <lib-navigation id="kirky" [options]="item.children" (selected)="checkSelectedItem($event)"></lib-navigation>
                    </div>
                </div>
            </div>
            <div *ngIf="item.children == null" >
                <div *ngIf="item.id == 10">
                    <div class="accordion-item-header" [ngClass]="accordionItem.expanded ? 'accordion-item-selected' : ''" (click)="accordionItemSelected($event, accordionItem, item)">
                        &nbsp;&nbsp;{{ item.name }}
                    </div>
                </div>
                <div *ngIf="item.id == 11">
                    <div class="accordion-child-item-header" [ngClass]="accordionItem.expanded ? 'accordion-child-item-selected' : ''" (click)="accordionItemSelected($event, accordionItem, item)">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}
                    </div>
                </div>
                <div *ngIf="item.id == 12">
                    <div class="accordion-grandchild-item-header" [ngClass]="accordionItem.expanded ? 'accordion-grandchild-item-selected' : ''" (click)="accordionItemSelected($event, accordionItem, item)">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}
                    </div>
                </div>
                <div *ngIf="item.id == 13">
                    <div class="accordion-great-grandchild-item-header" [ngClass]="accordionItem.expanded ? 'accordion-great-grandchild-item-selected' : ''" (click)="accordionItemSelected($event, accordionItem, item)">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}
                    </div>
                </div>
            </div>
        </cdk-accordion-item>
    </cdk-accordion>
</div>
