import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetTimeScopeSelectionPopupComponent } from './widget-time-scope-selection-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
	declarations: [WidgetTimeScopeSelectionPopupComponent],
	imports: [
		CommonModule,
		DragDropModule,
		MatButtonModule,
		MatTooltipModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
	],
	exports: [WidgetTimeScopeSelectionPopupComponent],
})
export class WidgetTimeScopeSelectionPopupModule {}
