<div class="card card-tasks" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header" cdkDragHandle style="cursor: default; padding-top: 0px; min-height: 0px">
        <div style="padding-top: 15px; display: inline-block">
            <h6 class="title  d-inline" >
                Widget Split Percent Settings for {{localWidgetObject.DynamicTitle}}
             </h6>
        </div>

        <button class="btn btn-fill btn-primary" type="button" (click)="submitAndClose()" style="padding: 3px 6px; margin: 0px 10px">
			<i style="font-size: 10px">Submit and Close</i>
		</button>

        <button mat-button style="display: inline; float: right; padding-top: 5px;" (click)="onCancel()" matTooltip="Close" [matTooltipPosition]="'below'">
            <div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>



	</div>
	<div class="card-body" style="overflow-y: scroll">
           <div class="container" style="padding-top: 10px">
			<mat-form-field appearance="fill">
				<mat-label>Select a width for the first panel</mat-label>
				<mat-select [(value)]="split1Percent">
				  <mat-option [value]="percentOption" *ngFor="let percentOption of arrayonethroughonehundred">{{percentOption}}%</mat-option>

				</mat-select>
			  </mat-form-field>
           </div>
		   <div class="container" style="padding-top: 10px">
			<p>Based on your selection, the other panel will be {{100 - split1Percent}} of the total area.</p>
		</div>






	</div>
</div>
