<div class="card card-tasks" id="card-whole" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header" style="display: flex; flex-direction: row; cursor: move;"  cdkDragHandle>
		<!-- <h6 class="title d-inline"  >Widget Settings for : {{ this.dataSource.WidgetName }}  {{this.dataSource.WidgetSiteName !== null ?  ' / ' + this.dataSource.WidgetSiteName : ''}}  {{this.dataSource.WidgetTerminalName !== null ?  ' / ' + this.dataSource.WidgetTerminalName : ''}}  {{this.dataSource.WidgetZoneName !== null ?  ' / ' + this.dataSource.WidgetZoneName : ''}}  {{this.dataSource.WidgetGateSystemName !== null ?  ' / ' + this.dataSource.WidgetGateSystemName : ''}}  {{this.dataSource.WidgetAssetName !== null ?  ' / ' + this.dataSource.WidgetAssetName : ''}}</h6> -->
        <h6 class="title  d-inline" style="padding-top: 15px; display: inline-block">
           {{ localWidgetObject.WidgetName }}
            <span *ngIf="localWidgetObject.WidgetSiteName != null"
                >:&nbsp;&nbsp;<span style="color: lightseagreen"
                    ><strong>{{ localWidgetObject.WidgetSiteName }} </strong></span
                ></span
            >


        </h6>

        <button class="btn btn-fill btn-primary" type="submit" (click)="addYAxis()" style="padding: 3px 6px; margin: 0px">
			<i style="font-size: 10px">Add Y Axis</i>
		</button>
        <button [disabled]="unsavedChanges.length === 0" class="btn btn-fill btn-primary" type="submit" (click)="saveChanges()" style=" margin-left: 10px; padding: 3px 6px; margin-top:  0px; margin-right: 0px; margin-bottom: 0px;">
			<i style="font-size: 10px">Save Changes and Re-flow</i>
		</button>

		<div style=" margin-left: auto; padding-top: 10px; cursor: pointer;"  (click)="onCancel()"
			tooltip="Close" placement="bottom" >
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div class="card-body" style="overflow-y: scroll">
		<div class="table-responsive table-container">
            <table class="table" >
                <thead class="text-primary">
                    <tr>

                        <th>Tag Name</th>
                        <th>Series Type</th>
                        <th>Y Axis Instance</th>
                        <th style="z-index: 2" class="text-middle">Actions</th>
                    </tr>
                </thead>
                <tbody class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
                    <tr style="cursor: move;" class="example-box" *ngFor="let tag of localWidgetObject.TagGraphWidgetTags; let i = index" cdkDrag>

                        <td class="thirdWidth">
                            {{ tag.JBTStandardObservationName !== null? tag.JBTStandardObservationName : tag.tagName }}
                        </td>
                        <td>
                            <mat-radio-group aria-label="Select an option" [ngModel]="tag.SeriesType">
                                <mat-radio-button  *ngFor="let chartOption of chartOptions" [value]="chartOption" (change)="chartTypeChange($event, tag)" style="margin-right: 10px">
                                    <i *ngIf="chartOption === 'area'" class="fa fa-area-chart" aria-hidden="true"></i>
                                    <i *ngIf="chartOption === 'line'" class="fa fa-line-chart" aria-hidden="true"></i>

                                  </mat-radio-button>
                              </mat-radio-group>
                        </td>
                        <td>
                            <mat-form-field appearance="fill">

                                <mat-select (selectionChange)="yAxisChanged($event, tag)" [ngModel]="tag.YAxisOrdinal">
                                  <mat-option *ngFor="let yAxis of yAxises" [value]="yAxis.YAxisOrdinal">
                                    {{yAxis.label}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td>

                        <td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
                            <input [(colorPicker)]="tag.HexColor" [style.background]="tag.HexColor" (colorPickerClose)="colorChanged($event, tag)"/>
                            <button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDelete(tag)">
                                <i class="tim-icons danger-color icon-trash-simple"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
	</div>
</div>
