import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaCompPressureComponent } from './pca-comp-pressure.component';
import { GaugesModule } from '@progress/kendo-angular-gauges';



@NgModule({
  declarations: [
    PcaCompPressureComponent
  ],
  imports: [
    CommonModule, GaugesModule
  ], exports: [PcaCompPressureComponent]
})
export class PcaCompPressureModule { }
