import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaybackControllerComponent } from './playback-controller.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';



@NgModule({
  declarations: [
    PlaybackControllerComponent
  ],
  imports: [
    CommonModule,
	MatInputModule,
	MatFormFieldModule,
	MatSliderModule,
	SliderModule,
	FormsModule,
	ReactiveFormsModule,
	MatIconModule,
	MatTooltipModule,
	MatSlideToggleModule,
	MatSelectModule,
	MatMenuModule,
	MatButtonModule,
	MatCheckboxModule
  ],
  exports: [
	PlaybackControllerComponent
  ]
})
export class PlaybackControllerModule { }
