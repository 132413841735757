import { Component, ContentChildren, QueryList, OnInit, NgZone, Renderer2, AfterViewInit, OnDestroy } from "@angular/core";

import { CdkDragDrop, CdkDrag, moveItemInArray } from "@angular/cdk/drag-drop";
import _ from "lodash";
import { Subject, Subscription, fromEvent } from "rxjs";
import { SidebarComponent } from "../../../../../desktop/src/app/_layout/sidebar/sidebar.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import swal from "sweetalert2";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { State, process } from "@progress/kendo-data-query";
import { tap, take } from "rxjs/operators";
import { RowClassArgs } from "@progress/kendo-angular-grid";
import { request } from "http";

const tableRow = (node) => node.tagName.toLowerCase() === "tr";

const closest = (node, predicate) => {
	while (node && !predicate(node)) {
		node = node.parentNode;
	}

	return node;
};

@Component({
	templateUrl: "./canvas-tabs-list.component.html",
	styleUrls: ["./canvas-tabs-list.component.scss"]
})
export class CanvasTabsListComponent implements OnInit, AfterViewInit, OnDestroy {
	public static returned: Subject<any> = new Subject();

	public canvasTabs: any;
	cdkDragData: any;
	private swalWithBootstrapButtons: any;
	private updatingCanvasTabsList: boolean = false;
	private finishedWithUpdatingCanvasTabsList: boolean = true;
	private componentName: string = "canvas-tabs-list: ";
	public state: State = {
		skip: 0
	};
	public gridData: any;

	private currentSubscription: Subscription;

	constructor(private dataService: DataService, public dialog: MatDialog, private router: Router, private toastr: ToastrService, private utilityService: UtilityService, private renderer: Renderer2, private zone: NgZone) {
		CanvasTabsListComponent.returned.subscribe((res) => {
			this.getCanvasTabsListForUser();
		});
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
	}

	ngOnInit() {
		this.getCanvasTabsListForUser();
	}

	ngAfterViewInit() {
		this.currentSubscription = this.handleDragAndDrop();
	}

	public dataStateChange(state: State): void {
		this.state = state;
		this.gridData = process(this.canvasTabs, this.state);
		if (this.currentSubscription) {
			this.currentSubscription.unsubscribe();

		}
		this.zone.onStable.pipe(take(1)).subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
	}

	public rowCallback(context: RowClassArgs) {
		return {
			dragging: context.dataItem.dragging
		};
	}

	private handleDragAndDrop(): Subscription {
		const sub = new Subscription(() => { });
		let draggedItemIndex;

		const tableRows = Array.from(document.querySelectorAll(".k-grid tr"));
		tableRows.forEach((item) => {
			this.renderer.setAttribute(item, "draggable", "true");
			const dragStart = fromEvent<DragEvent>(item, "dragstart");
			const dragOver = fromEvent(item, "dragover");
			const dragEnd = fromEvent(item, "dragend");

			sub.add(
				dragStart
					.pipe(
						tap(({ dataTransfer }) => {
							try {
								const dragImgEl = document.createElement("span");
								dragImgEl.setAttribute("style", "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;");
								document.body.appendChild(dragImgEl);
								dataTransfer.setDragImage(dragImgEl, 0, 0);
							} catch (err) {
								// IE doesn't support setDragImage
							}
							try {
								// Firefox won't drag without setting data
								dataTransfer.setData("application/json", "");
							} catch (err) {
								// IE doesn't support MIME types in setData
							}
						})
					)
					.subscribe(({ target }) => {
						const row: HTMLTableRowElement = <HTMLTableRowElement>target;
						draggedItemIndex = row.rowIndex;
						const dataItem = this.gridData.data[draggedItemIndex];
						dataItem.dragging = true;
					})
			);

			sub.add(
				dragOver.subscribe((e: any) => {
					e.preventDefault();
					const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
					const dropIndex = closest(e.target, tableRow).rowIndex;
					const dropItem = this.gridData.data[dropIndex];

					draggedItemIndex = dropIndex;
					this.zone.run(() => this.gridData.data.splice(dropIndex, 0, dataItem));
				})
			);

			sub.add(
				dragEnd.subscribe((e: any) => {
					e.preventDefault();
					const dataItem = this.gridData.data[draggedItemIndex];
					dataItem.dragging = false;

					let tabRanking = 1;
					var countOfCanvasTabs = 0;
					var newCanvasTabList = null;
					this.updatingCanvasTabsList = true;
					var finishedWithUpdate = false;
					var requestJSON: any = [];
					this.gridData.data.forEach((d: any) => {
						d.CanvasTemplateTabRanking = tabRanking;
						tabRanking++;
						var sqlStatement = "API.Canvas_UpdateCanvasTemplateRanking @CanvasTemplateId=" + d.Id + ", @CanvasTemplateTabRanking=" + d.CanvasTemplateTabRanking;
						var canvasTabsSQLObject = {
							label: "Canvas-" + d.CanvasTemplateTabRanking,
							sqlStatement: sqlStatement
						};
						requestJSON.push(canvasTabsSQLObject);
						countOfCanvasTabs++;
					});
					Global.User.DebugMode && console.log(this.componentName + "CanvasTab List drag-and-drop requestJSON = %O", requestJSON);
					this.dataService.SQLMultiAction(requestJSON).then((data: any) => {
						Global.User.DebugMode && console.log(this.componentName + "SQLMultiAction for requestJSON = %O", data);
						var collections: any = data;
						this.utilityService.showToastMessageShared({
							type: 'success',
							message:
								'You have successfully re-ordered the canvas tabs for all users. ',
							title: 'Canvas Tabs',

						})
						collections.forEach((collection: any) => {
							if (collection.label == "CanvasTabs-" + countOfCanvasTabs) {
								newCanvasTabList = collection.data; //-- get last update of the canvasTabs for the final list.
							}
						});
					});
				})
			);
		});

		return sub;
	}



	getCanvasTabsListForUser() {
		this.canvasTabs = this.dataService.cache.canvasTemplates.filter((p: any) => {
			return (
				p.CanvasTemplateTypeId == 1
			);
		});

		if(this.canvasTabs.length > 0){
			this.canvasTabs.forEach((record) => {
				var user = this.dataService.cache.people.filter((p: any) => {
					return (
						p.UserId == record.CreatorUserId
					);
				});
				record.CreatorName = user[0].FullName;
			});
		}
			this.canvasTabs.sort(
				(a, b) => a.CanvasTemplateTabRanking - b.CanvasTemplateTabRanking
			);
		this.gridData = process(this.canvasTabs, this.state);
	}

	updateMenuItem(canvasTabs: any) {
		Global.CurrentMenuItem = canvasTabs.Name;
	}

	ngOnDestroy() {
		if (this.currentSubscription) {
			this.currentSubscription.unsubscribe();

		}
	}
}
