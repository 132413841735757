<div class="content">
	<div class="row">
		<div class="col-md-12">
			<div class="card card-plain" id="workArea">
				<div class="card-header" id="workAreaCardHeader">
					<h4 class="card-title" style="display: inline-block">Location of all Ground Support Equipment</h4>

                    <button class="btn btn-fill btn-primary" type="submit" (click)="toggleZonesOnMap()" style="padding: 3px 6px; margin: 0px 0px 0px 10px">
                        <i style="font-size: 10px">Toggle Geofences</i>
                    </button>
                    <div style="display: inline; float: right; padding-right: 10px; ">
                        <!-- <lib-live-data [live]="true"></lib-live-data> -->

                    </div>

				</div>
				<div class="card-body" id="workAreaCardBody">
					<div class="resizeContainer">
                        <lib-locate-all-gse-widget #locateAllGSEChild style="width: 100% !important"></lib-locate-all-gse-widget>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
