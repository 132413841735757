<sass-helper></sass-helper>
<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Faults Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="
		!isDataLoading &&
		widgetObject?.SiteList !== null &&
		widgetObject?.SiteList?.length !== 0
	"
	class="container-fluid"
	style="height: 100%"
>
	<div style="height: 10%; width: 100%" #operationalActiveAlarmsContainer>
		<div
			class="row justify-content-center"
			style="height: 100%; display: flex; flex-wrap: wrap"
		>
			<div
				[ngClass]="
					evaluateSelectedFilters().length === 0
						? 'selectedFilter'
						: ''
				"
				class="major-alert-gse-container"
				(click)="filterTableData('All', dataGrid)"
				tooltip="Click to show All Alerts "
			>
				<span
					[style.color]="gridData.length > 0 ? '#02FA03' : 'grey'"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>{{ gridData.length }}
				</span>

				<span
					[style.color]="gridData.length > 0 ? '#02FA03' : 'grey'"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>Total</span
				>
			</div>
			<div
				[ngClass]="
					evaluateSelectedFilters().includes('Critical') === true
						? 'selectedFilter'
						: ''
				"
				class="minor-alert-gse-container"
				(click)="filterTableData('Critical', dataGrid)"
				tooltip="Click to show Critical Alerts "
			>
				<span
					[style.color]="
						alarmTypeCounts.Criticals > 0
							? 'rgba(255, 0, 0, 0.5)'
							: 'grey'
					"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>{{ alarmTypeCounts.Criticals }}
				</span>
				<span
					[style.color]="
						alarmTypeCounts.Criticals > 0
							? 'rgba(255, 0, 0, 0.5)'
							: 'grey'
					"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>Critical</span
				>
			</div>
			<div
				[ngClass]="
					evaluateSelectedFilters().includes('Alarm') === true
						? 'selectedFilter'
						: ''
				"
				class="minor-alert-gse-container"
				(click)="filterTableData('Alarm', dataGrid)"
				tooltip="Click to show Fault Alerts "
			>
				<span
					[style.color]="
						alarmTypeCounts.Alarms > 0
							? 'rgba(219, 45, 45, 0.8)'
							: 'grey'
					"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>{{ alarmTypeCounts.Alarms }}</span
				>
				<span
					[style.color]="
						alarmTypeCounts.Alarms > 0
							? 'rgba(219, 45, 45, 0.8)'
							: 'grey'
					"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>Alarms</span
				>
			</div>
			<div
				[ngClass]="
					evaluateSelectedFilters().includes('Warning') === true
						? 'selectedFilter'
						: ''
				"
				class="minor-alert-gse-container"
				(click)="filterTableData('Warning', dataGrid)"
				tooltip="Click to show Warning Alerts "
			>
				<span
					[style.color]="
						alarmTypeCounts.Warnings > 0 ? 'yellow' : 'grey'
					"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>{{ alarmTypeCounts.Warnings }}
				</span>
				<span
					[style.color]="
						alarmTypeCounts.Warnings > 0 ? 'yellow' : 'grey'
					"
					[style.font-size]="
						operationalActiveAlarmsContainer.offsetWidth / 30 <
						operationalActiveAlarmsContainer.offsetHeight / 2
							? operationalActiveAlarmsContainer.offsetWidth /
									30 +
							  'px'
							: operationalActiveAlarmsContainer.offsetHeight /
									2 +
							  'px'
					"
					class="alert-count-number"
					>Warnings</span
				>
			</div>
		</div>
	</div>
	<div class="row" style="height: 90%">
		<div class="alert-list">
			<div
				(resized)="onResized($event)"
				[style.height.%]="100"
				class="card card-tasks card-body"
			>
				<div class="parent-table-container">
					<lib-kendo-grid-parent
							[gridData]="gridData"
							[gridSettings]="gridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							[gridContextMenuItems]="['Open Summary for Asset',
							'Show Location of Asset']"
							(onRightClickSelectEmitter)="onRightClickSelect($event)"
							*ngIf="gridSettings !== undefined"
							#tagDataGrid

						>
						</lib-kendo-grid-parent>
				</div>
			</div>
		</div>
	</div>
</div>

<div
	*ngIf="!isDataLoading && !widgetObject?.SiteList"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Site from the widget settings."
	>
	</lib-centered-message>
</div>
