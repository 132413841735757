<div class="content" #parentContentPunchout >
		<div id="cardUserEngagement" [style.height.px]="parentHeight-100" *ngIf="parentHeight !== undefined">
			<div class="card-header">
				<div class="col-md-6 text-left">
					<h4 class="card-title">
						User Engagement Report
					</h4>
					<h3></h3>
					<div style="display: flex; justify-content: space-between;">
						<!-- <div style="display: inline-block">
							<mat-form-field style="display: inline-block">
							  <mat-select
								placeholder="Site (required)"
								[formControl]="toppings"
								[(value)]="selectedToppings"
								multiple
							  >
								<mat-option *ngFor="let topping of toppingList" [value]="topping"
								  >{{topping}}</mat-option
								>
							  </mat-select>
							</mat-form-field>
							<p>{{selectedToppings | json}}</p>
						  </div> -->
						  <div style="display: inline-block">
							<mat-form-field style="display: inline-block">
							  <mat-select
								placeholder="Organization (required)"
								multiple
							  >
								<mat-option (onSelectionChange)="change($event)" *ngFor="let org of listOfOrganizations" [value]="org"
								  >{{org.SelectorName}}</mat-option
								>
							  </mat-select>
							</mat-form-field>

						  </div>
						  <div style="display: inline-block" *ngIf="userListfromDB.length > 0">
							<mat-form-field style="display: inline-block">
							  <mat-select
								placeholder="User (optional) "
								multiple
							  >
								<mat-option (onSelectionChange)="changeUser($event)" *ngFor="let user of userListfromDB" [value]="user"
								  >{{user.UsernameDisplay}}</mat-option
								>
							  </mat-select>
							</mat-form-field>

						  </div>
						  <!-- <div style="display: inline-block" *ngIf="userList.length > 0">
							<mat-form-field style="display: inline-block">
							  <mat-select
								placeholder="App Type"
								[formControl]="toppings"
								[(value)]="selectedToppings"
								multiple
							  >
								<mat-option *ngFor="let topping of toppingList" [value]="topping"
								  >{{topping}}</mat-option
								>
							  </mat-select>
							</mat-form-field>

						  </div> -->
						  <div style="display: inline-block" *ngIf="userListfromDB.length > 0">
							<mat-form-field style="display: inline-block">
								<mat-label>Start Date (required)</mat-label>
								<input matInput [matDatepicker]="picker1" [formControl]="startDate">
								<mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
								<mat-datepicker #picker1></mat-datepicker>
							</mat-form-field>

						</div>
						<div style="display: inline-block" *ngIf="userListfromDB.length > 0">
							<mat-form-field style="display: inline-block">
								<mat-label>End Date (required)</mat-label>
								<input matInput [matDatepicker]="picker2" [formControl]="endDate">
								<mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
								<mat-datepicker #picker2></mat-datepicker>
							</mat-form-field>

						</div>
						<div *ngIf="userListfromDB.length > 0" style="display: inline-block">
							<button  class="btn btn-fill btn-primary" type="submit" (click)="getUserActivity()" style="padding: 3px 6px; margin: 0px 0px 0px 10px">
								<i style="font-size: 10px">Activity</i>
							</button>
						</div>

					</div>
				</div>
			</div>
				<div class="card-body userEngagementBody">
					<lib-kendo-grid-parent
							[gridSettings]="kendoGrids.activity.gridSettings"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							#activityGrid
					>

					</lib-kendo-grid-parent>
					<div *ngIf="noUserActivity">
						<p> No User Activity for the selected Users/Timeframe</p>
					</div>
				</div>



		</div>
</div>
