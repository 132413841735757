<lib-loading-spinner *ngIf="widgetObject.WidgetSiteId && isDataLoading" [loadingMessage]="'Loading Flight Schedule...'" [vehicle]="'plane'"></lib-loading-spinner>

<mat-tab-group
	*ngIf="!isDataLoading && flightEvents.length > 0"
	animationDuration="0ms"
>
<mat-tab label="Schedule">

<div *ngIf="!isDataLoading && flightEvents.length > 0" class="card card-tasks" id="card-whole">


    <div [style.height.%]="100" style="display: inline-block" class="card-body" id="card-body-id">
        <kendo-scheduler #scheduler [kendoSchedulerBinding]="filteredFlightEvents" [group]="kendoGroup" [resources]="kendoResources" [loading]="isSchedulerLoading" [timezone]="timeZoneType !== 'User Time' ? kendoTimeZone : ''" startTime="00:00" style="height: 100%;" (dateChange)="schedulerDateChanged($event)">
            <ng-template kendoSchedulerToolbarTemplate>
                <ul kendoSchedulerToolbarNavigation></ul>
                <button (click)="toggleFilter()" class="btn btn-secondary">{{ showAirlineCodeFilter ? 'Save Filter' : 'Show Filter'}}</button>
                    <kendo-multiselect #multiselect *ngIf="showAirlineCodeFilter"
                        [data]="carriersList"
                        [(ngModel)]="selectedCarriers"
                        [textField]="'DisplayText'"
                        [valueField]="'AirlineCode'"
                        (valueChange)="valueChange($event)"
                        [checkboxes]="checkboxes"
                        [autoClose]="false"
                    ></kendo-multiselect>
					<i  class="fa fa-info-circle" aria-hidden="true"
						[matTooltip]="toolTipInfo"
						[matTooltipPosition]="'before'"
						matTooltipClass="multiline-tooltip"
						style="margin-left: auto; margin-right: 5px">
					</i>
            </ng-template>
            <ng-template kendoSchedulerEventTemplate let-event="event" let-resources="resources">
                <div [ngClass]="{ 'arrival-present': event.dataItem.hasArrival, 'departure-present': event.dataItem.hasDeparture }" style="cursor: pointer;" mat-button [matMenuTriggerFor]="infoWindow" [style.height.%]="100" [style.width.%]="100" [style.backgroundColor]="event.dataItem.color">
                    <span style="color:#212427;">{{ event.title }}</span>
                </div>

                <mat-menu #infoWindow="matMenu" >
                    <p [style.color]="global.Theme === 'light' ? '#212427' : '#fafafa'" style="padding: 0px 8px; white-space: pre-line; ">{{ event.dataItem.status }}</p>
                </mat-menu>
            </ng-template>
            <kendo-scheduler-timeline-view [currentTimeMarker]="kendoTimeMarkerSettings">
            </kendo-scheduler-timeline-view>
        </kendo-scheduler>
    </div>
</div>

</mat-tab>





<mat-tab label="Data">


	<!-- <lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Data...'"
	[vehicle]="'plane'"
	></lib-loading-spinner> -->


<div *ngIf="!isDataLoading" class="parent-table-container card card-tasks">

	<lib-kendo-grid-parent
			[gridData]="gridData"
			[gridSettings]="gridSettings"
			[widgetObject]="widgetObject"
			[style.height.%]="100"
			filterable="menu"
			[sortable]="true"
			[reorderable]="true"
			[resizable]="true"
			#tagDataGrid

		>
	</lib-kendo-grid-parent>

</div>



</mat-tab>

</mat-tab-group>


<div *ngIf="flightEvents.length == 0 || !hasData" class="card card-tasks center" id="card-whole">
    <lib-centered-message *ngIf="!widgetObject.WidgetSiteId" centeredMessage="Please select a Site from the widget settings."></lib-centered-message>
    <lib-centered-message *ngIf="!isDataLoading && !hasData && widgetObject.WidgetSiteId" centeredMessage="No flight data available for this site."></lib-centered-message>
</div>

