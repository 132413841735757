<div class="content" style="height: 100%">
	<div class="card card-tasks widget-card" [ngClass]="{ 'drop-area': !readOnly }" id="pasteTarget" dropZone (filesDropped)="onDropEvent($event)" (filesHovered)="dropzoneState($event)">
		<div class="card-header">
			<div style="padding-top: 0px; display: inline-block">
				<h3	class="card-title" (click)="onClick()" [ngClass]="{ 'drop-area-hover': dropzoneActive && !readOnly }">{{ listTitle }}</h3>
				<h6 *ngIf="!readOnly" class="card-subtitle" [ngClass]="{ 'drop-area-hover': dropzoneActive }" (click)="onClick()">Drag and Drop files here.&nbsp;&nbsp;&nbsp; Click here to open File Explorer.</h6>
			</div>

			<button mat-button [matMenuTriggerFor]="fileUploadDropdown" style="display: inline; float: right; padding-top: 5px; border: none">
				<i class="tim-icons icon-settings-gear-63"> </i>
			</button>
			<mat-menu #fileUploadDropdown="matMenu">
				<button (click)="closeWindow()" mat-menu-item>Close</button>
				<button *ngIf="files.length > 0 && !readOnly" (click)="removeAllFiles($event)" style="background-color: red;" mat-menu-item>Remove All Files</button>

			</mat-menu>
		</div>
		<div class="card-body file-upload" nv-file-drop="" uploader="uploader" style="overflow-y: hidden !important">
			<div class="table-responsive">
				<table class="table tablesorter">
					<thead class="text-primary text-header">
						<tr>
							<th class="text-center file-upload-field">Type/Thumbnail</th>
							<th class="file-upload-field">Last Modified Date</th>
							<th class="file-upload-field">File Name</th>
							<th class="file-upload-field">Description</th>
							<th class="file-upload-field">Progress</th>
							<th class="text-right file-upload-field">Size</th>
							<th *ngIf="!readOnly" class="text-right file-upload-field" style="padding-right: 10px">Actions</th>
						</tr>
					</thead>
					<tbody cdkDropList #new="cdkDropList" [cdkDropListData]="newFiles">
						<input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display: none" />
						<tr *ngFor="let file of files" (click)="viewFile(file)" class="view-file">
							<td class="text-center">
								<div class="photo">
									<div *ngIf="file.iconFile">
										<img alt="photo" [src]="file.iconFile" title="{{ file.name }}" />
									</div>
									<div *ngIf="file.thumbnailImage">
										<img alt="photo" [src]="file.thumbnailImage" title="{{ file.name }}" />
									</div>
									<div *ngIf="file.thumbnailUrl && !file.thumbnailImage">
										<img alt="photo" [src]="file.thumbnailUrl" title="{{ file.name }}" />
									</div>
								</div>
							</td>
							<td>
								{{ file.data.lastModifiedDate | date : "MM/dd/yyyy hh:mm a z" }}
							</td>
							<td *ngIf="!file.edit">{{ file.data.name }}</td>
							<td *ngIf="file.edit">
								<input type="text" [id]="file.data.name" [value]="file.data.name" title="Enter a name for this file." (change)="updateFileName(file, $event)" />
							</td>
							<td *ngIf="!file.edit">{{ file.data.description }}</td>
							<td *ngIf="file.edit">
								<input type="text" [id]="file.data.description" [value]="file.data.description" title="Enter a description for this file." (change)="updateFileDescription(file, $event)" />
							</td>

							<td class="text-center">
								<div style="font-size: 0.6rem" *ngIf="file.uploading">
									sent:
									<mat-progress-bar mode="determinate" color="primary" [value]="file.uploadProgress"></mat-progress-bar>
								</div>
								<div style="font-size: 0.6rem" *ngIf="file.uploading">
									received:
									<mat-progress-bar mode="determinate" color="warn" [value]="file.downloadProgress"></mat-progress-bar>
								</div>
							</td>
							<td class="text-right">
								{{ file.data.size | fileSize }}
							</td>

							<td *ngIf="!readOnly" class="text-right" style="padding-right: 10px">
								<button class="btn btn-danger btn-link btn-sm" style="margin-right: 5px" tooltip="Delete" type="button" (click)="deleteFile(file, $event)">
									<i class="tim-icons icon-simple-remove"> </i>
								</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<button style="z-index: 1" class="btn btn-link btn-sm" tooltip="Edit this file" type="button" (click)="editFile(file, $event)">
									<i class="tim-icons icon-pencil"></i>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
