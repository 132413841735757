import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogbookComponent } from './logbook.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';



@NgModule({
  declarations: [
    LogbookComponent
  ],
  imports: [
    CommonModule,
	MatButtonModule,
	MatTooltipModule,
	KendoGridParentModule
  ]
})
export class LogbookModule { }
