import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators, AbstractControl, FormControl, AsyncValidatorFn, ValidationErrors, ControlContainer } from "@angular/forms";

// Datepicker module

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import swal from "sweetalert2";

import { HttpClient } from "@angular/common/http";
import { QuestionBase } from "../../../_models/dynamic-fields/questions/question-base";
import { Global } from "../../../_constants/global.variables";
import { DataService } from "../../../services/data.service";
import { UtilityService } from "../../../services/utility.service";
import { IGlobal } from "../../../_models/global.model";

import { DiffResults } from "ngx-text-diff/lib/ngx-text-diff.model";
import { SignalRCoreService } from "../../../services/signalr-core.service";

const misc: any = {
	sidebar_mini_active: true
};

@Component({
	selector: "lib-dynamic-form-question",
	templateUrl: "./dynamic-form-question.component.html",
	styleUrls: ["./dynamic-form-question.component.scss"]
})
export class DynamicFormQuestionComponent implements OnInit {
	@Input() public question: QuestionBase<string>;
	@Input() public form: FormGroup;
	@Input() public formOptions: any;
	@Input() public saveOnChange: boolean;
	@Input() public saveStoredProcedureName: string;
	@Input() public saveApiUrl: string;
	@Input() public deleteApiUrl: string;
	@Input() public signalRGroupToNotify: string;
	@Input() public signalRNotifyCode: string;
	@Input() public dataCacheObject: any;
	@Input() public isPartOfInternalDialog: boolean = false;
	@Input() public formHasDynamicPropertyFields: boolean = false;
	@Input() public listOfQuestions: Array<QuestionBase<string>>;
	@Output() public returnValue: any;
	@Output() public notifyParent: EventEmitter<any> = new EventEmitter();
	@Output() public notifyParentFormIsLoaded: EventEmitter<any> = new EventEmitter();
	@Output() public notifyParentQuestionsHaveChanged: EventEmitter<any> = new EventEmitter();

	private toggleButton: any;
	public selectedSite: any;
	public selectedColor: string;
	public permittedSites: any = Global.User.PermittedSites;

	public sidebarColor: string = "blue";
	public state: boolean = true;
	public dashboardColor: boolean = true;
	public isCollapsed: boolean = true;
	public darkTheme: boolean = true;
	public lastSliderSelection: boolean = false;
	public darkTheme$: Subject<any>;
	public value: Date = new Date();
	public selectedButton: any;
	public global: IGlobal = Global;
	public dateTimeFormat: string = "MM/dd/yyyy HH:mm";
	public dateFormat: string = "MM/dd/yyyy";
	public dateFormControl: FormControl;
	public internalDialogIsVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public multiSelectAll: boolean = false;
	public dynamicForm: FormGroup;
	public fieldNeedsToBeSaved: boolean;
	public usernameExists: boolean = false;
	public lastUsernameEntered: string;
	public editingUsername: string = "";

	public emailAddressExists: boolean = false;
	public lastEmailAddressEntered: string;
	public editingEmailAddress: string = "";
	public emailAddressIsInvalid: boolean = false;
	public componentName: string = "dynamic-form-question: ";
	public numberOfQuestions: number = 0;
	public questionIndex: number = 0;

	public assetNameExists: boolean = false;
	public lastAssetNameEntered: string;
	public editingAssetName: string = "";
	public asyncProcessingInProgress: boolean = false;

	constructor(private dataService: DataService, public utilityService: UtilityService, private httpClient: HttpClient, public signalR: SignalRCoreService) { }

	ngOnInit() {
		var service = this;
			Global.User.DebugMode && console.log(service.componentName + "formOptions = %O", this.formOptions);
		//console.log(service.componentName + "listOfQuestions = %O", service.listOfQuestions);
		service.numberOfQuestions = service.listOfQuestions.length;
		service.questionIndex = service.listOfQuestions.indexOf(service.question);
		
		if (service.question.buttonList && service.question.buttonList.storedProcedure != null && service.question.buttonList.parameters != null) { 
			service.question.listOfValues = null; //-- need to initialize the list of values to null for each subsequent call to this dynamic field question. --Kirk T. Sherer, June 20, 2023. 
		}
		//console.log(service.componentName + "question.key = " + service.question.key + ", value: " + service.question.value + ", index = " + service.questionIndex);

		if (service.question.editable == false) {
			service.question.controlType = "read-only"; //-- make the controlType read-only when the user doesn't have the permissions to edit the field, if applicable. --Kirk T. Sherer, June 6, 2023. 
		}

		if (service.question.controlType == "dropdown") {
			Global.User.DebugMode && console.log(service.componentName + "this.question.key = " + service.question.key + ", this.question.selected = " + service.question.selected);
			Global.User.DebugMode && console.log(service.componentName + "this.question: %O", service.question);
			//Global.User.DebugMode && console.log(this.componentName + "this.form: %O", service.form);

			service.form.controls[service.question.key].setValue(service.question.selected == "" ? service.question.value : service.question.selected, { onlySelf: true });
		}

		if (service.question.type == "username" && service.question.value != null) {
			service.editingUsername = service.question.value;
			console.log(service.componentName + "this.editingUsername = " + service.editingUsername);
		}

		if (service.question.type == "person-email" && service.question.value != null) {
			service.editingEmailAddress = service.question.value;
			console.log(service.componentName + "this.editingEmailAddress = " + service.editingEmailAddress);
		}

		if (service.question.fieldValidation != null) {
			service.question.fieldValidation.forEach((row: any) => {
				var validatingFieldValue = service.form.get(row.field).value;
				var currentQuestionValue = service.form.controls[service.question.key].value;
				switch (service.question.controlType) {
					case "slider":
					case "slider-yes-no":
					case "checkbox":
					case "true-false-button":
						if (validatingFieldValue == "false" || validatingFieldValue == "0" || validatingFieldValue == 0) {
							validatingFieldValue = false;
						} else {
							validatingFieldValue = true;
						}
						if (currentQuestionValue == "false" || currentQuestionValue == "0" || currentQuestionValue == 0) {
							currentQuestionValue = false;
						} else {
							currentQuestionValue = true;
						}
						break;
					default:
						break;
				}

				var validatingFieldValueTrueFalse = validatingFieldValue == true || validatingFieldValue != undefined ? true : false;
				var currentQuestionValueTrueFalse = currentQuestionValue == true || currentQuestionValue != undefined ? true : false;

				switch (row.operator) {
					case "==":
						row.questionValidated = validatingFieldValue == currentQuestionValue;
						row.operatorInEnglish = "equal to";
						break;
					case "!=":
						row.questionValidated = validatingFieldValue != currentQuestionValue;
						row.operatorInEnglish = "not equal to";
						break;
					case ">":
						row.questionValidated = !isNaN(Number(validatingFieldValue)) && Number(validatingFieldValue) > +currentQuestionValue;
						row.operatorInEnglish = "greater than";
						break;
					case "<":
						row.questionValidated = !isNaN(Number(validatingFieldValue)) && Number(validatingFieldValue) < +currentQuestionValue;
						row.operatorInEnglish = "less than";
						break;
					default: //-- if we have a value for both the requirement and the actual field, since there's no operator defined, then check to see if they are equal to the true/false that there is a value for each. --Kirk T. Sherer, August 20, 2021.
						row.questionValidated = validatingFieldValueTrueFalse == currentQuestionValueTrueFalse;
						row.operatorInEnglish = "equal to";
						break;
				}

				if (!row.questionValidated && currentQuestionValue != null && validatingFieldValue != null) {
					swal.fire({
						title: "Retry",
						position: "center",
						html: "The <strong>" + row.field + "</strong> is not " + row.operatorInEnglish + " the <strong>" + this.question.key + "</strong>. Please try again."
					});
				}
			});
		}

		if (service.question.visibleFields != null) {
			service.question.visibleFields.forEach((row: any) => {
				var visibleFieldValue = row.value;
				var actualVisibleFieldValue = null;
				var visibleFieldQuestionObject = service.listOfQuestions.firstOrDefault((question:any) => { return question.key == row.field });
				try {
					const control: any = service.form.get(row.field); // 'control' is a FormControl
					var actualVisibleFieldValue = control.value;
					if (typeof control.value == "object" && control.value != null) {
						//--  && visibleCondition.type == "Id"
						actualVisibleFieldValue = control.value["Id"];
					}
					else {
						actualVisibleFieldValue = control.value == '' ? null : control.value;
					}
				} catch (error) {
					//-- if we arrived here, that either means the row.field was not defined in the editable fields in the RDN Service, or there's no value to retrieve for this field.  Set the local variable = null.
					console.log(service.componentName + " value for '" + row.field + "' doesn't exist. Check RDN Service for the listOfQuestions for this data set and make sure '" + row.field + "' is defined: %O", service.listOfQuestions);
					actualVisibleFieldValue = null;
				}

				switch (visibleFieldQuestionObject.controlType) {
					case "slider":
					case "slider-yes-no":
					case "checkbox":
					case "true-false-button":
						if (visibleFieldValue == "false" || visibleFieldValue == "0" || visibleFieldValue == 0 || visibleFieldValue == null) {
							visibleFieldValue = false;
						} else {
							if (!isNaN(Number(visibleFieldValue)) && Number(visibleFieldValue)) {
								visibleFieldValue = +visibleFieldValue;
							} else {
								visibleFieldValue = true;
							}
						}
						break;
					default:
						break;
				}

				switch (visibleFieldQuestionObject.controlType) {
					case "slider":
					case "slider-yes-no":
					case "checkbox":
					case "true-false-button":
						if (actualVisibleFieldValue == "false" || actualVisibleFieldValue == "0" || actualVisibleFieldValue == 0 || actualVisibleFieldValue == null) {
							actualVisibleFieldValue = false;
						} else {
							if (!isNaN(Number(actualVisibleFieldValue)) && Number(actualVisibleFieldValue)) {
								actualVisibleFieldValue = +actualVisibleFieldValue;
							} else {
								actualVisibleFieldValue = true;
							}
						}
						break;
					default:
						break;
				}
				var visibleFieldValueTrueFalse = visibleFieldValue == true || visibleFieldValue != undefined ? true : false;
				var actualVisibleFieldValueTrueFalse = actualVisibleFieldValue == true || actualVisibleFieldValue != undefined ? true : false;
				switch (row.operator) {
					case "==":
						row.questionShouldBeVisible = actualVisibleFieldValue == visibleFieldValue;
						break;
					case "!=":
						row.questionShouldBeVisible = actualVisibleFieldValue != visibleFieldValue;
						break;
					case ">":
						row.questionShouldBeVisible = !isNaN(Number(actualVisibleFieldValue)) && Number(actualVisibleFieldValue) > +visibleFieldValue;
						break;
					case "<":
						row.questionShouldBeVisible = !isNaN(Number(actualVisibleFieldValue)) && Number(actualVisibleFieldValue) < +visibleFieldValue;
						break;
					default: //-- if we have a value for both the requirement and the actual field, since there's no operator defined, then set the questionShouldBeVisible to the true/false that there is a value for each. --Kirk T. Sherer, August 20, 2021.
						row.questionShouldBeVisible = actualVisibleFieldValueTrueFalse == visibleFieldValueTrueFalse;
						break;
				}
			});
			var areAnyVisibleFieldsSetToFalse = this.question.visibleFields
				.where((data: any) => {
					return data.questionShouldBeVisible == false;
				})
				.toArray();
			service.question.visible = areAnyVisibleFieldsSetToFalse.length == 0; //-- only allow the field if all entries in the visible fields list are set to true (to show the field.)
		}

		if (service.question.controlType == "uib-button-multi-select") {
			//-- this is a normal multi-select button group.  Just run the function one time for the current question. --Kirk T. Sherer, October 12, 2021.
			service.buildMultiSelectButtonGroup(service.question);
		}

		if (service.question.controlType == "uib-button") {
			//-- this is a normal single-select button group.  Just run the function one time for the current question. --Kirk T. Sherer, October 12, 2021.
			service.buildSingleSelectButtonGroup(service.question);
		}

		if (service.question.type == "username") {
			service.form.controls[service.question.key].setAsyncValidators([service.isValidUsername(), service.isValidUsernameNotInList()]);
		}

		if (service.question.key == "Email") {
			if (service.question.type == "person-email") {
				service.form.controls[service.question.key].setAsyncValidators([service.isValidEmailAddressNotInList(service.question.value)]);
			}
		}

		if (service.question.type == "asset") {
			service.form.controls[service.question.key].setAsyncValidators([service.isValidAssetName(), service.isValidAssetNameNotInList()]);
		}

		if ((service.question.type == "datetime" || service.question.type == "date") && service.question.value != null) {
			// service.dynamicForm = service.form;
			// service.dateFormControl = service.dynamicForm.controls[service.question.key];
			// service.form.controls[service.question.key];
			//console.log(service.componentName + "Date field: '" + service.question.key + "', value: '" + service.question.value + "'");
			service.form.controls[service.question.key].setValue(new Date(service.question.value));
			// service.dynamicForm = new FormGroup({
			// 	question: new FormControl(service.form.controls[service.question.key])
			// });

			// service.form = service.dynamicForm;
		}

		if (service.question.key == "Active" && service.question.value == null) {
			service.form.controls[service.question.key].setValue(true); //-- initialize the Active field to a true condition since in most cases the Active field should be set to true. --Kirk T. Sherer, September 7, 2021.
		}

		if (service.question.controlType == "slider-yes-no" || service.question.controlType == "slider" || service.question.controlType == "checkbox" || service.question.controlType == "true-false-button") {
			if (service.question.value == null || service.question.value == "0" || service.question.value == "false") {
				service.form.controls[service.question.key].setValue(false); //-- set the value to false since the sliders, checkboxes, and true-false-button types are all trying to use a true or a false. --Kirk T. Sherer, April 6, 2022.
			} else {
				service.form.controls[service.question.key].setValue(true); //-- set the value to true since the sliders, checkboxes, and true-false-button types are all trying to use a true or a false. --Kirk T. Sherer, April 6, 2022.
			}
		}

		//Global.User.DebugMode && console.log(this.componentName + "service.form = %O", service.form);

		this.internalDialogIsVisible$ = new BehaviorSubject(Global.RDN.internalDialog);
		this.internalDialogIsVisible$.subscribe((internalDialogIsVisible: boolean) => {
			//Global.User.DebugMode && console.log(service.componentName + internalDialogIsVisible ? "internal dialog is visible..." : "internal dialog is not visible...");
		});
		// if (service.question.type == "email") {
		// 	Global.User.DebugMode && console.log(service.componentName + "service.question.key = " + service.question.key + ", service.question.selected = " + service.question.selected);
		// 	Global.User.DebugMode && console.log(service.componentName + "service.question: %O", service.question);
		// 	Global.User.DebugMode && console.log(service.componentName + "service.form: %O", service.form);
		// }

		if (service.questionIndex == service.numberOfQuestions - 1) {
			service.notifyParentFormIsLoaded.emit(true);
		}
	}

	buildMultiSelectButtonGroup(question: any) {
		var service = this;
		if (question.buttonList && question.buttonList.storedProcedure != null && question.buttonList.parameters != null) {
			var parameterList = "";
			var countOfParameters = 0;
			question.buttonList.parameters.forEach((parameter: any) => {
				var parameterName = parameter.name;
				var parameterNameIsIdField = parameterName.slice(-2) == "Id";

				console.log(service.componentName + "!isNaN(Number(parameter.value)) = " + !isNaN(Number(parameter.value)));
				console.log(service.componentName + "this.form = %O", service.form);
				console.log(service.componentName + "parameter.value = " + parameter.value);

				var numericValueOfParameter = Number(parameter.value);
				var isParameterValueANumber = !isNaN(numericValueOfParameter);
				var parameterValue;

				if (parameterName == "@AdminUserId") {
					parameterValue = Global.User.currentUser.Id; //-- if we're dealing with an AdminUserId, that means we're trying to edit something as an administrator of some sort.  Send over the current user's ID number for evaluation, since the RDN Service retains the original UserId of the first username that logged in. --Kirk T. Sherer, June 20, 2023. 
				}
				else {
					if (parameter.formKeyField != undefined) {
						parameterValue = service.form.controls[parameter.formKeyField].value;
					} else {
						parameterValue = isParameterValueANumber ? parameter.value : service.form.controls[parameter.value].value;
					}
	
					if (typeof parameterValue == "object") {
						parameterValue = parameterValue != null ? parameterValue.Id : null; //-- get only the Id value since the parameterValue coming back is a complete record object. Id is the value we need.
					}
				}

				console.log(service.componentName + "parameterValue after if statements = " + parameterValue);

				if ((parameterNameIsIdField && !isNaN(Number(parameterValue)) && Number(parameterValue) != 0) || (!parameterNameIsIdField && isNaN(Number(parameterValue)))) {
					parameterList += (question.buttonList.parameters.length > countOfParameters && parameterList != "" ? "," : "") + parameterName + "=" + (!parameterNameIsIdField || isNaN(Number(parameterValue)) ? "'" + parameterValue + "'" : parameterValue);
					countOfParameters++;
				}
			});

			var sql = question.buttonList.storedProcedure + " " + parameterList;
			if (question.buttonList.canExecuteStoredProcedureWithoutParameters || countOfParameters > 0) {
				service.asyncProcessingInProgress = true;
				service.dataService.SQLActionAsPromise(sql).then((data: any) => {
					service.determineSelectedButtons(question, data);
					service.asyncProcessingInProgress = false;
				});
			} else {
				question.listOfValues = null;
				question.multiSelectedAll = false;
			}
		}
		console.log(service.componentName + "question = %O", question);
		console.log(service.componentName + "this.form.controls[question.key].invalid = " + service.form.controls[question.key].invalid + ", this.form.controls[question.key].touched = " + service.form.controls[question.key].touched);
		console.log(service.componentName + "this.form.controls[question.key].errors = %O", service.form.controls[question.key].errors);
	}

	determineSelectedButtons(question: any, data: any) {
		if (data.length > 0) {
			var anySelected = data
				.where((d: any) => {
					return d.IsSelected == 1;
				})
				.toArray();
			//var organizationId = data.first((d: any) => { return d }).OrganizationId;

			var allSelected = anySelected.length == data.length ? true : false;

			if (anySelected.length == 0 && question.buttonList.selectAllOnInitialLoad) {
				//-- No sites have been selected for this user, so set them all to 'selected' since we're starting from a point of giving users access to see all sites.  Then we'll remove the sites they shouldn't see. --Kirk T. Sherer, August 16, 2021.
				data.forEach((d: any) => {
					d.IsSelected = 1;
				});
				question.multiSelectedAll = true;
			} else {
				question.multiSelectedAll = allSelected;
			}
			question.listOfValues = data;
			if (question.type == "gate-system") {
				question.listOfValues = data
					.orderBy((d: any) => {
						return d.SiteName;
					})
					.thenBy((s: any) => {
						return this.utilityService.GetGateNameSortValue(s.Name);
					})
					.toArray();
			}
		} else {
			question.listOfValues = null;
			question.multiSelectedAll = false;
		}
	}

	buildSingleSelectButtonGroup(question: any) {
		if (question.buttonList && question.buttonList.storedProcedure != null && question.buttonList.parameters != null) {
			var parameterList = "";
			var countOfParameters = 0;
			question.buttonList.parameters.forEach((parameter: any) => {
				var parameterName = parameter.name;
				var parameterNameIsIdField = parameterName.slice(-2) == "Id";

				console.log(this.componentName + "!isNaN(Number(parameter.value)) = " + !isNaN(Number(parameter.value)));
				console.log(this.componentName + "this.form = %O", this.form);

				var parameterValue = !isNaN(Number(parameter.value)) ? parameter.value : this.form.controls[parameter.value].value;
				if (typeof parameterValue == "object") {
					parameterValue = parameterValue.Id; //-- get only the Id value since the parameterValue coming back is a complete record object. Id is the value we need.
				}

				if ((parameterNameIsIdField && !isNaN(Number(parameterValue)) && Number(parameterValue) != 0) || (!parameterNameIsIdField && isNaN(Number(parameterValue)))) {
					parameterList += (question.buttonList.parameters.length > countOfParameters && parameterList != "" ? "," : "") + parameterName + "=" + (!parameterNameIsIdField || isNaN(Number(parameterValue)) ? "'" + parameterValue + "'" : parameterValue);
					countOfParameters++;
				}
			});

			var sql = question.buttonList.storedProcedure + " " + parameterList;
			if (question.buttonList.canExecuteStoredProcedureWithoutParameters || countOfParameters > 0) {
				this.dataService.SQLActionAsPromise(sql).then((data: any) => {
					if (data.length > 0) {
						question.listOfValues = data;
					} else {
						question.listOfValues = null;
					}
				});
			} else {
				question.listOfValues = null;
			}
		}
		else {
			if (question.type == "fleet") {
				var newData = question.listOfValues.select((item:any) => {
					var newItem = {
						Id: item.FleetId,
						Name: item.FleetName
					}
					return newItem;
				}).toArray();
	
				question.listOfValues = newData;
			}
			console.log("question.listOfValues = %O", question.listOfValues);
		}
		console.log(this.componentName + "question = %O", question);
		console.log(this.componentName + "this.form.controls[question.key].invalid = " + this.form.controls[question.key].invalid + ", this.form.controls[question.key].touched = " + this.form.controls[question.key].touched);
		console.log(this.componentName + "this.form.controls[question.key].errors = %O", this.form.controls[question.key].errors);
	}

	get isValid() {
		return this.form.controls[this.question.key].valid;
	}

	selectSite(event: Event, site: any): void {
		this.selectedSite = site;
		this.question.value = site.Id;
		//console.log(this.componentName + "site = %O", site);
		//console.log(this.componentName + "this.question.value = " + this.question.value);
		this.form.get("@Site_Id").setValue(this.question.value);
		this.saveValue(event, this.question);
	}

	selectButton(event: Event, button: any) {
		event.preventDefault();
		this.selectedButton = button;
		console.log(this.componentName + "button = %O", button);
		if (this.question.value == button.Id) {
			//--the question already has a value of the button's Id value. The user is trying to un-select the button.
			//--Just clear out the question.value since they may be setting the overall field to a null value. --Kirk T. Sherer, June 8, 2021.
			this.question.value = null;
		} else {
			this.question.value = button.Id;
		}
		console.log(this.componentName + "this.question.value = " + this.question.value);
		this.form.get(this.question.key).setValue(this.question.value);
		this.saveValue(event, this.question);
	}

	multiSelectButton(event: Event, button: any) {
		event.preventDefault();
		this.selectedButton = button;
		console.log(this.componentName + "multiSelectButton invoked. button.IsSelected before change: " + button.IsSelected);
		button.IsSelected = button.IsSelected == 1 ? 0 : 1;
		console.log(this.componentName + "button.IsSelected after change: " + button.IsSelected);
		var selectedArray = this.question.listOfValues
			.where((data: any) => {
				return data.IsSelected == 1;
			})
			.toArray();
		var selectedList = selectedArray
			.select((data: any) => {
				return data.Id;
			})
			.toArray()
			.join(",");
		var allSelected = this.question.listOfValues.length == selectedArray.length ? true : false;
		this.question.multiSelectedAll = allSelected;

		console.log(this.componentName + "selectedList = %O", selectedList);
		this.form.get(this.question.key).setValue(selectedList);
		var newValue = this.form.get(this.question.key).value;
		console.log(this.componentName + "newValue = " + newValue);
		this.saveValue(event, this.question, newValue);
	}

	multiSelectAllButton(event: Event, question: any) {
		event.preventDefault();
		console.log(this.componentName + "multiSelectAllButton invoked. listOfValues before change: " + question.listOfValues);
		var changingSelectedButtonsToValue = question.multiSelectedAll ? 0 : 1; //-- values of each button will be a one (1) for true or a zero (0) for false.
		question.multiSelectedAll = !question.multiSelectedAll; //-- have to set this so that the button for 'Select All' changes color and the name of the button changes to 'Select None' if Select All was invoked and vice versa. --Kirk T. Sherer, August 17, 2021.

		question.listOfValues.forEach((button: any) => {
			button.IsSelected = changingSelectedButtonsToValue;
		});
		console.log(this.componentName + "listOfValues after change: " + question.listOfValues);

		var selectedList = question.listOfValues
			.where((data: any) => {
				return data.IsSelected == 1;
			})
			.select((data: any) => {
				return data.Id;
			})
			.toArray()
			.join(",");
		console.log(this.componentName + "selectedList = %O", selectedList);
		this.form.get(question.key).setValue(selectedList);
		var newValue = this.form.get(question.key).value;
		console.log(this.componentName + "newValue = " + newValue);
		this.saveValue(event, question);
	}

	selectTrueFalseButton(event: Event, value: string): void {
		event.preventDefault();
		this.question.value = value;
		this.form.controls[this.question.key].setValue(this.question.value);
		this.saveValue(event, this.question);
	}

	selectDate(event: Event, dateQuestion: any): void {
		this.form.get(dateQuestion.key).setValue(dateQuestion.value);
		//console.log(this.componentName + "dateQuestion = %O", dateQuestion);
		this.saveValue(event, dateQuestion);
	}

	sliderSelect(event: Event, obj: any) {
		event.preventDefault();
		console.log(this.componentName + "obj.key = " + obj.key + ", obj.value = " + obj.value);
		this.saveValue(event, obj);
	}

	get emailAddressText() {
		return this.form.controls[this.question.key];
	}

	checkEmailAddress(event: Event, obj: any) {
		event.stopPropagation();
		event.preventDefault();
		// const regularExpression = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
		const regularExpression = / + obj.pattern + /;
		console.log(this.componentName + "event = %O", event);
		console.log(this.componentName + "regularExpression = " + regularExpression);
		//const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		console.log(this.componentName + "email test = " + regularExpression.test(String(event).toLowerCase()));
		return regularExpression.test(String(event).toLowerCase());
	}

	get usernameText() {
		return this.form.controls[this.question.key];
	}

	get assetNameAsText() {
		return this.form.controls[this.question.key];
	}

	async defaultSaveValueFunction(changedObject: any, obj: any, newValue?: any) {
		if (obj.controlType == "slider" || obj.controlType == "slider-yes-no" || obj.controlType == "checkbox" || obj.controlType == "true-false-button") {
			//-- if this is a checkbox or a slider, we're dealing with a boolean variable.  Send over a 1 for true and 0 for false. --Kirk T. Sherer, June 5, 2020.
			if (changedObject.control != undefined) {
				changedObject.value = !changedObject.control.checked ? 1 : 0;
			} else {
				changedObject.value = obj.value;
			}
			if (obj.key == "debugMode") {
				this.dataService.updateDebugMode(changedObject.value);
			}
		} else {
			if (newValue != undefined) {
				changedObject.value = newValue;
			}
			else {
				if (obj.value != undefined && (changedObject.value == undefined || changedObject.value == "")) {
					if (obj.allowBlankValue) {
						changedObject.value = null; //-- the object is set to allow a blank (null) value, so go ahead and set it back to null. ---Kirk T. Sherer, August 30, 2022.
					} else {
						changedObject.value = obj.value; //-- the value can't be blank for this object, so reset back to the original value.
					}
				}
			}
		}

		console.log(this.componentName + "object = %O", obj);
		console.log(this.componentName + "changedObject.value = " + changedObject.value);
		console.log(this.componentName + "this.saveOnChange = " + this.saveOnChange + ", this.saveStoredProcedureName = " + this.saveStoredProcedureName + ", this.saveApiUrl = " + this.saveApiUrl);

		var changedObjectValue = changedObject.value;
		
		if (obj.controlType == "checkbox" || obj.controlType == "slider" || obj.controlType == "slider-yes-no" || obj.controlType == "true-false-button") {
			obj.type = "checkbox";
		}
		//-- have to set the form value so that the overall Dynamic Form can react to the change to the form. --Kirk T. Sherer, August 20, 2021.
		switch (obj.controlType) {
			case "datetime":
			case "date":
				if (changedObjectValue != null) {
					this.form.controls[obj.key].setValue(new Date(changedObjectValue));
				} else {
					this.form.controls[obj.key].setValue(null);
				}
				break;
			case "slider":
			case "slider-yes-no":
			case "checkbox":
			case "true-false-button":
				var checkboxValue = changedObjectValue == 1 ? true : false;
				this.form.controls[obj.key].setValue(checkboxValue);
				console.log(this.componentName + "object changed to " + checkboxValue + ": %O", obj);
				break;
			case "number":		
				if (changedObjectValue != null) {		
					this.form.controls[obj.key].setValue(+changedObjectValue);
				}
				else {
					this.form.controls[obj.key].setValue(null);
				}
				break;
			default:
				this.form.controls[obj.key].setValue(changedObjectValue);
				break;
		}
		var dataCacheChangedObjectValue = this.form.controls[obj.key].value;
		console.log(this.componentName + "changedObjectValue = " + changedObjectValue);
		if (isNaN(changedObject.value) && changedObjectValue.indexOf("'") > 0) {
			changedObjectValue = this.utilityService.updateStringToSQLSyntax(changedObjectValue);
		}

		if (obj.controlType != "uib-button-multi-select") {
			this.notifyParent.emit(changedObjectValue); //-- notify overall Dynamic Form (parent)... uib-button-multi-select will handle this on its own.
		}

		if (this.saveOnChange && this.saveStoredProcedureName) {
			var sqlStatement = this.saveStoredProcedureName + ", '" + obj.key + "'," + changedObjectValue;

			if (obj.controlType != "number") {
				sqlStatement = this.saveStoredProcedureName + ", '" + obj.key + "','" + changedObjectValue + "'";
			}

			console.log(this.componentName + "sqlStatement = " + sqlStatement);
			this.signalR.notifyOtherClientsInGroup(this.signalRGroupToNotify, this.signalRNotifyCode, this.dataCacheObject);

			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				if (data && data.length > 0) {
					// var message = data.where((d: any) => {
					// 	d.msg != null
					// }).toArray();
					var errorMessage = data.first((d: any) => {	return d; }).msg;
					if (errorMessage) {
						//-- error was sent back from the stored procedure. Display error in alert message. --Kirk T. Sherer, April 20, 2021.
						swal.fire({
							title: "Error",
							position: "center",
							html: errorMessage
						});
						if (obj.controlType == "slider" || obj.controlType == "slider-true-false" || obj.controlType == "slider" || obj.controlType == "true-false-button") {
							this.form.controls[obj.key].setValue(false);
							console.log(this.componentName + "object changed back to false: %O", obj);
						}
					} else {
						console.log(this.componentName + obj.key + " has been updated. data = %O", data);
						if (this.signalRGroupToNotify != null) {
							this.signalR.notifyOtherClientsInGroup(this.signalRGroupToNotify, this.signalRNotifyCode, data);
						}
						if (this.dataCacheObject != null) {
							this.dataCacheObject[obj.key] = dataCacheChangedObjectValue;
							console.log("dataCacheObject after update = %O", this.dataCacheObject);
						}
					}
				}
			});
		}

		if (this.saveOnChange && this.saveApiUrl) {
			this.signalR.notifyOtherClientsInGroup(this.signalRGroupToNotify, this.signalRNotifyCode, this.dataCacheObject);
			var url = this.saveApiUrl + "propertyName=" + obj.key + "&value=" + changedObjectValue;

			this.dataService.CallThingMethod(url, "POST").then((data: any) => {
				console.log(this.componentName + obj.key + " has been updated to '" + changedObjectValue + "'. data = %O", data);
				this.utilityService.showToastMessageShared({
					type: 'info',
					message: "'" + obj.label + "' has been updated to '" + changedObjectValue + "'.",
					title: 'Save Event',
				});
			});
		}

		return true;
	}

	deleteProperty(event: Event, obj: any) {
		var service = this;
		this.signalR.notifyOtherClientsInGroup(this.signalRGroupToNotify, this.signalRNotifyCode, this.dataCacheObject);
		var url = this.deleteApiUrl + "propertyName=" + obj.key;

		this.dataService.CallThingMethod(url, "POST").then((data: any) => {
			console.log(this.componentName + obj.key + " has been updated. data = %O", data);
			this.notifyParentQuestionsHaveChanged.emit(obj); //-- notify the parent dynamic form that we no longer have the obj that was just deleted from the form.--Kirk T. Sherer, March 11, 2024. 
			this.utilityService.showToastMessageShared({
				type: 'info',
				message: "'" + obj.label + "' property has been DELETED.",
				title: 'Save Event',
			});
		});
	}

	saveValue(event: Event, obj: any, newValue?: any) {
		event.stopPropagation();
		event.preventDefault();
		console.log(this.componentName + "saveValue invoked... event.target = %O", event.target);
		var changedObject: any = event.target;
		console.log(this.componentName + "changedObject = %O", changedObject);
		console.log(this.componentName + "obj = %O", obj);

		console.log("obj = %O", obj);
		if (obj.onChangeFunction != null) {
			console.log("onChangeFunction = " + obj.onChangeFunction);
			if (typeof obj.onChangeFunction == 'function') 
			{
				//-- call saveValue function first, then run the dynamic on change function: 
				this.defaultSaveValueFunction(changedObject, obj).then((data:any) => {
					obj.onChangeFunction.call({ key: obj.key, newValue: changedObject.value, oldValue: obj.value });
				});
			}
			else 
			{
				console.log("function does NOT exist.");
			}
		}
		else {
			this.defaultSaveValueFunction(changedObject, obj, newValue);
		}
		
	}

	getChangedEventObject(changedSelectorObject: any, question: any) {
		console.log(this.componentName + "changedSelectorObject.value = " + changedSelectorObject?.value + ", isNaN(changedSelectorObject.value) = " + isNaN(changedSelectorObject?.value) + ", Number(changedSelectorObject.value) = " + Number(changedSelectorObject?.value));

		if (this.fieldNeedsToBeSaved == undefined) {
			this.fieldNeedsToBeSaved = true;
		}

		if (typeof changedSelectorObject == "object" && this.fieldNeedsToBeSaved) {
			//-- since this is coming from a selector, the original text for searching the selector list will be just the text the user typed.  No need to save any of that since
			//-- the user hasn't selected the record they are searching for yet. Only when we receive an object will we know for sure they have selected one from the selector
			//-- list and then we can save the ID number of the selected record. --Kirk T. Sherer, August 19, 2021.
			console.log(this.componentName + "changedSelectorObject.value = " + changedSelectorObject.value + ", isNaN(changedSelectorObject.value) = " + isNaN(changedSelectorObject.value) + ", isNumeric(changedSelectorObject.value) = " + Number(changedSelectorObject.value));
			this.saveValueFromSelector(changedSelectorObject, question); //-- go ahead and save the value.
		} else {
			//-- this value is from a file upload field, more than likely.  Just update the question.value to the changedSelectorObject since it's just a string at that point. --Kirk T. Sherer, November 1, 2021.
			this.form.controls[question.key].setValue(changedSelectorObject); //-- have to set the form value so that the overall Dynamic Form can react to the change to the form. --Kirk T. Sherer, August 20, 2021.
			this.notifyParent.emit(changedSelectorObject); //-- notify overall Dynamic Form (parent)...
		}

		if (changedSelectorObject && this.saveOnChange && this.saveStoredProcedureName) {
			var changedObjectValue = changedSelectorObject;
			if (changedSelectorObject.Id != undefined) {
				changedObjectValue = changedSelectorObject.Id;
			}

			var sqlStatement = this.saveStoredProcedureName + ", '" + question.key + "'," + changedObjectValue;

			if (question.controlType != "number") {
				sqlStatement = this.saveStoredProcedureName + ", '" + question.key + "','" + changedObjectValue + "'";
			}

			console.log(this.componentName + "sqlStatement = " + sqlStatement);

			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				if (data && data.length > 0) {
					// var message = data.where((d: any) => {
					// 	d.msg != null
					// }).toArray();
					var errorMessage = data.first((d: any) => {
						return d;
					}).msg;
					if (errorMessage) {
						//-- error was sent back from the stored procedure. Display error in alert message. --Kirk T. Sherer, April 20, 2021.
						swal.fire({
							title: "Error",
							position: "center",
							html: errorMessage
						});
					} else {
						console.log(this.componentName + question.key + " has been updated. data = %O", data);

						this.fieldNeedsToBeSaved = false;
					}
				}
			});
		}
	}

	saveValueFromSelector(changedSelectorObject: any, question: any) {
		console.log(this.componentName + "changedSelectorObject.value = " + changedSelectorObject.value + ", isNaN(changedSelectorObject.value) = " + isNaN(changedSelectorObject.value) + ", isNumeric(changedSelectorObject.value) = " + Number(changedSelectorObject.value));

		console.log(this.componentName + "this.saveValuePerField = " + this.saveOnChange + ", this.saveStoredProcedureName = " + this.saveStoredProcedureName);
		console.log(this.componentName + "changedSelectorObject = %O", changedSelectorObject);

		console.log(this.componentName + "this.saveOnChange = " + this.saveOnChange + ", this.saveStoredProcedureName = " + this.saveStoredProcedureName);

		this.form.controls[question.key].setValue(changedSelectorObject); //-- have to set the form value so that the overall Dynamic Form can react to the change to the form. --Kirk T. Sherer, August 20, 2021.
		this.notifyParent.emit(changedSelectorObject); //-- notify overall Dynamic Form (parent)...
	}

	keydown(event: Event) {
		console.log(this.componentName + "user typed some keys... %O", event);
	}

	cancelMenu() {
		console.log(this.componentName + "cancelMenu invoked...");
	}

	async checkUsername(event: Event, enteredUsername: string): Promise<boolean> {
		var service = this;
		var requestedUsername = enteredUsername;

		if (event != null) {
			event.stopPropagation();
			event.preventDefault();
			var changedObject: any = event.target;
			requestedUsername = changedObject.value;
		}

		requestedUsername = requestedUsername.split(" ").join("").split("'").join(""); //--remove blanks and single quotes from username.

		console.log(service.componentName + "checking this username: " + requestedUsername);

		if (this.editingUsername == requestedUsername) {
			//-- we're leaving the username field without entering anything, so just send back what the last result was of whether or not the username exists. --Kirk T. Sherer, September 7, 2021.
			return this.usernameExists;
		} else {
			//.replace(/\s/g, "")
			var sql = "API.RDN_Validate_UsernameIsAvailable @username='" + requestedUsername + "'";
			return this.dataService.SQLActionAsPromise(sql).then((data: any) => {
				console.log(service.componentName + "sql = " + sql + ", data = %O", data);
				service.usernameExists = data.first().Invalid == 1 ? true : false;
				console.log(service.componentName + "this.usernameExists = " + service.usernameExists);
				service.lastUsernameEntered = requestedUsername;
				return service.usernameExists;
			});
		}
	}

	isValidUsername(): AsyncValidatorFn {
		window.event.stopPropagation();
		window.event.preventDefault();

		var service = this;

		console.log(service.componentName + "isValidUsername(): editingUsername = " + service.editingUsername);
		return (isValidUsernameControl: AbstractControl): Observable<ValidationErrors> => {
			let bReturn: boolean = true;
			if (isValidUsernameControl.value == "" || isValidUsernameControl.value.length <= 2) {
				bReturn = true;
			} else {
				bReturn = false;
			}

			let err: ValidationErrors = { invalid: true };
			return bReturn ? of(err) : of(null);
		};
	}

	isValidUsernameNotInList(): AsyncValidatorFn {
		window.event.stopPropagation();
		window.event.preventDefault();
		var service = this;

		console.log(service.componentName + "isValidUsernameNotInList(): editingUsername = " + service.editingUsername);
		let bReturn: boolean = false;
		let err: ValidationErrors = { exists: null };
		console.log(service.componentName + "this.usernameExists after invoking isValidUsernameNotInList() = " + service.usernameExists);
		return (isValidUsernameNotInListControl: AbstractControl): Observable<ValidationErrors> => {
			var promiseHasReturned = false;
			if (service.editingUsername == null || (service.editingUsername != null && service.editingUsername != isValidUsernameNotInListControl.value)) {
				console.log(service.componentName + "before checkUserName(), editingUserName = " + service.editingUsername + ", service.form.controls['Username'].value = " + isValidUsernameNotInListControl.value);
				console.log(service.componentName + "before checkUserName(), isValidUsernameNotInListControl = %O", isValidUsernameNotInListControl);

				service.checkUsername(null, isValidUsernameNotInListControl.value).then((usernameExists: boolean) => {
					service.usernameExists = usernameExists;
					service.lastUsernameEntered = isValidUsernameNotInListControl.value;

					promiseHasReturned = true;
					bReturn = usernameExists;
					let err: ValidationErrors = { exists: bReturn };
					// console.log(this.componentName + "err = %O", err);
					// console.log(this.componentName + "this.form.valid = " + this.form.valid);
					if (!usernameExists) {
						isValidUsernameNotInListControl.setErrors(null);
					} else {
						isValidUsernameNotInListControl.setErrors({
							invalid: true
						});
					}
					return of(err);
				});
				if (!promiseHasReturned) {
					return bReturn ? of(err) : of(null);
				}
				console.log(service.componentName + "this.usernameExists = " + service.usernameExists);
			} else {
				return of(null); //-- no need for checking for existing username since we're already editing one. --Kirk T. Sherer, September 9, 2021.
			}
		};
	}

	async checkAssetName(event: Event, enteredAssetName: string): Promise<boolean> {
		var service = this;
		var requestedAssetName = enteredAssetName;

		if (event != null) {
			event.stopPropagation();
			event.preventDefault();
			var changedObject: any = event.target;
			requestedAssetName = changedObject.value;
		}

		requestedAssetName = requestedAssetName.split(" ").join("").split("'").join(""); //--remove blanks and single quotes from asset name.

		console.log(service.componentName + "checking this asset name: " + requestedAssetName);

		if (this.editingAssetName == requestedAssetName) {
			//-- we're leaving the asset name field without entering anything, so just send back what the last result was of whether or not the asset name exists. --Kirk T. Sherer, May 18, 2023.
			return this.assetNameExists;
		} else {
			//.replace(/\s/g, "")
			var sql = "API.RDN_Validate_AssetNameIsAvailable @assetName='" + requestedAssetName + "'";
			return this.dataService.SQLActionAsPromise(sql).then((data: any) => {
				console.log(service.componentName + "sql = " + sql + ", data = %O", data);
				service.assetNameExists = data.first().Invalid == 1 ? true : false;
				console.log(service.componentName + "this.assetNameExists = " + service.assetNameExists);
				service.lastAssetNameEntered = requestedAssetName;
				return service.assetNameExists;
			});
		}
	}

	isValidAssetName(): AsyncValidatorFn {
		window.event.stopPropagation();
		window.event.preventDefault();

		var service = this;

		if (service.form.controls["Id"].value != null && service.form.controls["AssetName"].touched == false && (this.editingAssetName == null || this.editingAssetName == '')) {
			this.editingAssetName = service.form.controls["AssetName"].value;
		}
		console.log(service.componentName + "isValidAssetName(): editingAssetName = " + service.editingAssetName);
		return (isValidAssetNameControl: AbstractControl): Observable<ValidationErrors> => {
			let bReturn: boolean = true;
			if (isValidAssetNameControl.value == "" || isValidAssetNameControl.value.length <= 2) {
				bReturn = true;
			} else {
				bReturn = false;
			}

			let err: ValidationErrors = { invalid: true };
			return bReturn ? of(err) : of(null);
		};
	}

	isValidAssetNameNotInList(): AsyncValidatorFn {
		window.event.stopPropagation();
		window.event.preventDefault();
		var service = this;

		if (service.form.controls["Id"].value != null && service.form.controls["AssetName"].touched == false && (this.editingAssetName == null || this.editingAssetName == '')) {
			this.editingAssetName = service.form.controls["AssetName"].value;
		}

		console.log(service.componentName + "isValidAssetNameNotInList(): editingAssetName = " + service.editingAssetName);
		let bReturn: boolean = false;
		let err: ValidationErrors = { exists: null };
		console.log(service.componentName + "this.assetNameExists after invoking isValidAssetNameNotInList() = " + service.assetNameExists);
		return (isValidAssetNameNotInListControl: AbstractControl): Observable<ValidationErrors> => {
			var promiseHasReturned = false;
			if (service.editingAssetName == null || (service.editingAssetName != null && service.editingAssetName.toUpperCase() != isValidAssetNameNotInListControl.value.toUpperCase())) {
				console.log(service.componentName + "before checkAssetName(), editingAssetName = " + service.editingAssetName + ", service.form.controls['AssetName'].value = " + isValidAssetNameNotInListControl.value);
				console.log(service.componentName + "before checkAssetName(), isValidAssetNameNotInListControl = %O", isValidAssetNameNotInListControl);

				service.checkAssetName(null, isValidAssetNameNotInListControl.value).then((assetNameExists: boolean) => {
					service.assetNameExists = assetNameExists;
					service.lastAssetNameEntered = isValidAssetNameNotInListControl.value;

					promiseHasReturned = true;
					bReturn = assetNameExists;
					let err: ValidationErrors = { exists: bReturn };
					// console.log(this.componentName + "err = %O", err);
					// console.log(this.componentName + "this.form.valid = " + this.form.valid);
					if (!assetNameExists) {
						isValidAssetNameNotInListControl.setErrors(null);
					} else {
						isValidAssetNameNotInListControl.setErrors({
							invalid: true
						});
					}
					return of(err);
				});
				if (!promiseHasReturned) {
					return bReturn ? of(err) : of(null);
				}
				console.log(service.componentName + "this.assetNameExists = " + service.assetNameExists);
			} else {
				return of(null); //-- no need for checking for existing Asset Name since we're already editing one. --Kirk T. Sherer, September 9, 2021.
			}
		};
	}


	public onCompareResults(event: DiffResults) {
		console.log("diffResults = %O", event);
	}

	async checkPersonEmailAddress(event: Event, obj: any): Promise<boolean> {
		if (event != null) {
			event.stopPropagation();
			event.preventDefault();
		}
		console.log(this.componentName + "obj = %O", obj);

		this.lastEmailAddressEntered = obj.value == null ? this.question.value : obj.value;

		console.log(this.componentName + "this.lastEmailAddressEntered = " + this.lastEmailAddressEntered);

		if (typeof obj == "object") {
			//-- we're leaving the email field without entering anything, so just send back what the last result was of whether or not the email already exists. --Kirk T. Sherer, April 11, 2022.
			return this.emailAddressExists;
		} else {
			//-- obj is just a string that was entered.  Compare to the existing email address if one is present before running it through SQL Server for validation. --Kirk T. Sherer, September 15, 2022.
			if (this.lastEmailAddressEntered?.toLowerCase() == obj?.toLowerCase()) {
				//-- user is just updating the characters in the email address. Don't force a validation.
				return false; //-- sending back 'false' since this was a check to see if the email address already existed.  We know it exists, but it's an update to the same email address. --Kirk T. Sherer, September 15, 2022.
			} else {
				var sql = "API.RDN_Validate_Person_EmailAddressIsAvailable @emailAddress='" + obj.replace(/\s/g, "") + "'";
				return this.dataService.SQLActionAsPromise(sql).then((data: any) => {
					console.log(this.componentName + "sql = " + sql + ", data = %O", data);
					this.emailAddressExists = data.first().Invalid == 1 ? true : false;
					console.log(this.componentName + "this.emailAddressExists = " + this.emailAddressExists);
					return this.emailAddressExists;
				});
			}
		}
	}

	isValidEmailAddressNotInList(originalEmailAddress?: string): AsyncValidatorFn {
		let bReturn: boolean = false;
		let err: ValidationErrors = { exists: null };
		console.log(this.componentName + "this.emailAddressExists after invoking isValidEmailAddressNotInList(" + originalEmailAddress + ") = " + this.emailAddressExists);
		return (control: AbstractControl): Observable<ValidationErrors> => {
			console.log(this.componentName + "control.value = " + control.value);
			var promiseHasReturned = false;
			if (this.editingEmailAddress == null || (this.editingEmailAddress != null && this.editingEmailAddress != control.value)) {
				this.checkPersonEmailAddress(null, control.value).then((emailAddressExists: boolean) => {
					this.emailAddressExists = emailAddressExists;
					this.lastEmailAddressEntered = control.value;
					console.log(this.componentName + "this.lastEmailAddressEntered = " + this.lastEmailAddressEntered);

					promiseHasReturned = true;
					bReturn = emailAddressExists;
					let err: ValidationErrors = { exists: bReturn };
					// console.log(this.componentName + "err = %O", err);
					// console.log(this.componentName + "this.form.valid = " + this.form.valid);
					if (!emailAddressExists) {
						this.form.controls["Email"].setErrors(null);
					} else {
						this.form.controls["Email"].setErrors({
							invalid: true
						});
					}
					return of(err);
				});
				if (!promiseHasReturned) {
					return bReturn ? of(err) : of(null);
				}
				console.log(this.componentName + "this.emailAddressExists = " + this.emailAddressExists);
			} else {
				return of(null); //-- no need for checking for existing person's email address since we're already editing one. --Kirk T. Sherer, April 11, 2022.
			}
		};
	}
}
