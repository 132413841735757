import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'lib-scroll-to-widget',
	templateUrl: './scroll-to-widget.component.html',
	styleUrls: ['./scroll-to-widget.component.scss'],
})
export class ScrollToWidgetComponent implements OnInit {
	theme: string;
	widgetControl = new FormControl(null, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<ScrollToWidgetComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public widgets: any
	) {}

	ngOnInit(): void {
		console.log(this.widgets);
		if (Global.Theme === 'light') {
			this.theme = 'light';
		} else if (Global.Theme === 'dark') {
			this.theme = 'dark';
		}
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		this.dialogRef.close(this.widgetControl);
	}
}
