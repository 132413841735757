import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import swal from 'sweetalert2';
import _ from 'lodash';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: 'lib-tag-graph-popup-settings',
	templateUrl: './tag-graph-popup-settings.component.html',
	styleUrls: ['./tag-graph-popup-settings.component.scss'],
})
export class TagGraphPopupSettingsComponent implements OnInit {
	private swalWithBootstrapButtons: any;
	public unsavedChanges = [];
	public chartOptions = ['line', 'area'];
	public yAxises: any[] = [{ YAxisOrdinal: 0, label: 'Individual Chart' }];

	constructor(
		@Inject(MAT_DIALOG_DATA) public localWidgetObject: any,
		public dialogRef: MatDialogRef<TagGraphPopupSettingsComponent>,
		private dataService: DataService,
		public toastr: ToastrService,
		private dashboardService: DashboardService,
		private utilityService: UtilityService
	) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-success',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		console.log(this.localWidgetObject.TagGraphWidgetTags);
		let yAxisesAlreadyDeclared = [];
		this.localWidgetObject.TagGraphWidgetTags.forEach((WidgetTag) => {
			yAxisesAlreadyDeclared.push(WidgetTag.YAxisOrdinal);
		});
		yAxisesAlreadyDeclared = _.uniq(yAxisesAlreadyDeclared);
		// if (yAxisesAlreadyDeclared.every(yaxisAlreadyDeclared => yaxisAlreadyDeclared === yAxisesAlreadyDeclared[0]) &&yAxisesAlreadyDeclared[0] === 0) {
		//     // means all of the values for all of the tags is 0 meaning all individual charts, so we are going to have one drag and drop table since all of them are
		// }
		yAxisesAlreadyDeclared = _.sortBy(yAxisesAlreadyDeclared);
		yAxisesAlreadyDeclared.forEach((yAxis) => {
			if (yAxis !== 0 && yAxis !== null) {
				this.yAxises.push({ YAxisOrdinal: yAxis, label: yAxis });
			}
		});
	}

	chartTypeChange(event, tag) {
		console.log(event);
		let seriesTypeChange = {
			Id: tag.Id,
			SeriesType: event.value,
		};
		let seriesTypeChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === seriesTypeChange.Id
		);
		if (seriesTypeChanges !== -1) {
			this.unsavedChanges[seriesTypeChanges].SeriesType = event.value;
		} else {
			this.unsavedChanges.push(seriesTypeChange);
		}
	}

	colorChanged(event, tag) {
		console.log(event);
		console.log(tag);
		let colorChange = {
			Id: tag.Id,
			HexColor: event,
		};
		//discover if change has already been made before save
		let colorChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === colorChange.Id
		);
		if (colorChanges !== -1) {
			this.unsavedChanges[colorChanges].HexColor = event;
		} else {
			this.unsavedChanges.push(colorChange);
		}
	}

	public onEventLog(event: string, data: any): void {
		console.log(event, data);
	}

	onCancel() {
		this.dialogRef.close();
	}

	saveChanges() {
		this.unsavedChanges.forEach((uc, index) => {
			let sqlStatement =
				'API.UpdateHexColorForWidgetGraphTag @WidgetId = ' +
				this.localWidgetObject.WidgetId +
				', @TagGraphInstanceId = ' +
				uc.Id;
			if (!_.isNil(uc.HexColor)) {
				sqlStatement =
					sqlStatement + ", @HexColor = '" + uc.HexColor + "'";
			}
			if (!_.isNil(uc.SeriesType)) {
				sqlStatement =
					sqlStatement + ", @SeriesType = '" + uc.SeriesType + "'";
			}
			if (!_.isNil(uc.Ordinal)) {
				sqlStatement = sqlStatement + ', @Ordinal = ' + uc.Ordinal;
			}
			if (!_.isNil(uc.YAxisOrdinal)) {
				sqlStatement =
					sqlStatement + ', @YAxisOrdinal = ' + uc.YAxisOrdinal;
			}
			this.dataService.SQLActionAsPromise(sqlStatement).then((data) => {
				console.log(data);
				if (index === this.unsavedChanges.length - 1) {
					//finished with color changes, now save series type changes
					this.localWidgetObject.refreshComponent = true;
					this.unsavedChanges = [];
					setTimeout(() => {
						this.localWidgetObject.refreshComponent = false;
						this.dashboardService._reloadedWidget.next(this.localWidgetObject);
						this.dialogRef.close();


					}, 500);
				}
			});
		});
	}

	addYAxis() {
		if (this.yAxises.length === 1) {
			this.yAxises.push({ YAxisOrdinal: 1, label: 1 });
		} else {
			//we need to compute the highest yaxis ordinal from the list, then create a new object in that list with 1 higher ordinal number
			let highestYAxisOrdinal = 0;
			this.yAxises.forEach((yAxis) => {
				if (yAxis.YAxisOrdinal !== null) {
					highestYAxisOrdinal = parseInt(yAxis.YAxisOrdinal) + 1;
				}
			});
			this.yAxises.push({
				YAxisOrdinal: highestYAxisOrdinal,
				label: highestYAxisOrdinal,
			});
		}
	}

	yAxisChanged(event, tag) {
		console.log(event);
		console.log(tag);
		let yAxisChange = {
			Id: tag.Id,
			YAxisOrdinal: event.value,
		};
		//discover if change has already been made before save
		let yAxisChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === yAxisChange.Id
		);
		if (yAxisChanges !== -1) {
			this.unsavedChanges[yAxisChanges].YAxisOrdinal = event.value;
		} else {
			this.unsavedChanges.push(yAxisChange);
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		let unchangedTagGraphWidgetTags = _.cloneDeep(
			this.localWidgetObject.TagGraphWidgetTags
		);
		moveItemInArray(
			this.localWidgetObject.TagGraphWidgetTags,
			event.previousIndex,
			event.currentIndex
		);
		this.localWidgetObject.TagGraphWidgetTags.forEach(
			(tagInstance, index) => {
				tagInstance.Ordinal = index + 1;
			}
		);
		console.log(this.localWidgetObject.TagGraphWidgetTags);
		if (
			!_.isEqual(
				unchangedTagGraphWidgetTags,
				this.localWidgetObject.TagGraphWidgetTags
			)
		) {
			unchangedTagGraphWidgetTags.forEach((unchangedTag) => {
				this.localWidgetObject.TagGraphWidgetTags.forEach(
					(potentiallyChangedTag) => {
						if (
							unchangedTag.Id === potentiallyChangedTag.Id &&
							unchangedTag.Ordinal !==
								potentiallyChangedTag.Ordinal
						) {
							//means ordinal was changed
							let ordinalChangesIndex =
								this.unsavedChanges.findIndex(
									(tagChange) =>
										tagChange.Id ===
										potentiallyChangedTag.Id
								);
							if (ordinalChangesIndex !== -1) {
								this.unsavedChanges[
									ordinalChangesIndex
								].Ordinal = potentiallyChangedTag.Ordinal;
							} else {
								this.unsavedChanges.push({
									Id: potentiallyChangedTag.Id,
									Ordinal: potentiallyChangedTag.Ordinal,
								});
							}
						}
					}
				);
			});
		}
	}

	onDelete(tag: any) {
		let htmlString = '';
		if (tag.JBTStandardObservationName !== null) {
			htmlString =
				'This will remove the <strong>' +
				tag.JBTStandardObservationName +
				' : ' +
				'</strong> from the Tag Graph: ' +
				this.localWidgetObject.WidgetName;
		} else {
			htmlString =
				'This will remove the <strong>' +
				tag.tagName +
				' : ' +
				'</strong> from the Tag Graph.' +
				this.localWidgetObject.WidgetName;
		}
		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				html: htmlString,
				showCancelButton: true,
				confirmButtonText: 'Delete Tag',
				cancelButtonText: 'Cancel',
				reverseButtons: false,
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					let statement =
						'API.DeleteTagFromWidgetTagGraphById @WidgetId = ' +
						this.localWidgetObject.WidgetId +
						', @TagGraphTagId = ' +
						tag.Id;
					this.dataService
						.SQLActionAsPromise(statement)
						.then((data) => {
							console.log(data);
							let message = '';
							if (tag.JBTStandardObservationName !== null) {
								message =
									"'" +
									tag.JBTStandardObservationName +
									"' has been deleted.";
							} else {
								message =
									"'" + tag.tagName + "' has been deleted.";
							}
							// this.toastr.success(message);
							this.utilityService.showToastMessageShared({
								type: 'success',
								message: message,
							});
							this.localWidgetObject.TagGraphWidgetTags =
								this.localWidgetObject.TagGraphWidgetTags.filter(
									(tagGraphTag) => {
										return tag.Id != tagGraphTag.Id;
									}
								);
							this.localWidgetObject.refreshComponent = true;
							setTimeout(() => {
								this.localWidgetObject.refreshComponent = false;
							}, 500);
						});
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "'" + tag.tagName + "' has NOT been deleted.",
						title: 'Dashboards',
					});
					// this.toastr.info(
					// 	"'" + tag.tagName + "' has NOT been deleted.",
					// 	'Dashboards'
					// );
				}
			});
	}
}
