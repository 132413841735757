import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'go-to-v1',
    templateUrl: './go-to-v1.component.html',
    styleUrls: ['./go-to-v1.component.scss']
})
export class GoToV1Component implements OnInit {

    constructor() { }

    ngOnInit() {
        console.log("GoToV1Component: here");
    }

}
