import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaCondensorfansComponent } from './pca-condensorfans.component';



@NgModule({
  declarations: [
    PcaCondensorfansComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [PcaCondensorfansComponent]
})
export class PcaCondensorfansModule { }
