import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { UtilityService } from "../../services/utility.service";
import { GridSettings } from "../../_models/grid-settings.interface";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";

@Component({
  selector: 'lib-user-engagement-report',
  templateUrl: './user-engagement-report.component.html',
  styleUrls: ['./user-engagement-report.component.scss']
})

export class UserEngagementReportComponent implements AfterViewInit {
	@ViewChild('parentContentPunchout') parentContentPunchout: any;
	parentHeight: any;

	public listOfOrganizations: any;
	public orgListSelected: Array<any>;
	public userListfromDB: Array<any>;
	public userListSelected: Array<any>;
	public userActivity: Array<any>;
	public noUserActivity: boolean = false;
	@ViewChild("activityGrid") activityGrid: KendoGridParentComponent;
	startDate = new FormControl(new Date().toISOString());
	endDate = new FormControl(new Date().toISOString());


	public kendoGrids = {
		activity: {
			data: [],
			groupable: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 30
				},
				columnsConfig: [
					{
						field: "Person",
						title: "Person",
						filterable: true,
						_width: 100,
						minResizableWidth: 150
					},
					{
						field: "LogDate",
						title: "Log Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 50
					},
					{
						field: "UserEventType",
						title: "User Event Type",
						filterable: true,
						_width: 50
					},
					{
						field: 'Description',
						title: 'Description',
						filterable: true,
						_width: 200,
						minResizableWidth: 200
					},
				],
			} as GridSettings,
		},
	};

	constructor(
		public dataService: DataService,
		private utilityService: UtilityService
	) {

	}

  ngAfterViewInit(): void {
	this.parentHeight = this.parentContentPunchout.nativeElement.clientHeight;
	  this.listOfOrganizations = this.dataService.cache.organizations;
	  this.listOfOrganizations.sort((a, b) => a.Mnemonic.localeCompare(b.Mnemonic));
	  this.orgListSelected = new Array();
	  this.userListfromDB = new Array();
	  this.userListSelected = new Array();
	  this.userActivity = new Array();

	}

	change(event) {

		if (event.isUserInput && event.source.selected) {
			console.log(event.source.value);
			this.orgListSelected.push(event.source.value.Id);
			this.getUserListFromDB();
		}
		if (event.isUserInput && !event.source.selected) {
			let index = this.orgListSelected.findIndex((o: any) => {
				return o == event.source.value.Id;
			});
			this.orgListSelected.splice(index, 1);
			this.getUserListFromDB();
		}

	}
	changeUser(event) {

		if (event.isUserInput && event.source.selected) {
			console.log(event.source.value);
			this.userListSelected.push(event.source.value.UserId);
		}
		if (event.isUserInput && !event.source.selected) {
			let index = this.userListSelected.findIndex((o: any) => {
				return o == event.source.value.UserId;
			});
			this.userListSelected.splice(index, 1);
		}

	}

	getUserListFromDB() {
		var orgList = this.orgListSelected.toString();
		var currentUserId = Global.User.currentUser.Id;
		this.userListSelected = [];
		let statement =
			'Security.UserEngagement_ListOfUsersByOrganization @ListOfOrganizationIds = ' +
			"'" + orgList + "'" +
			', @UserId = ' +
			currentUserId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.userListfromDB = data;
		});
	}

	getUserActivity() {
		console.log(this);
		var userList;
		if (this.userListSelected.length > 0)
			userList = this.userListSelected.toString();
		else {
			var allUsersInSelectedOrg = new Array();
			this.userListfromDB.forEach((u: any) => {
				allUsersInSelectedOrg.push(u.UserId)
			});
			userList = allUsersInSelectedOrg.toString();
		}

		var currentUserId = Global.User.currentUser.Id;
		var startDateTime = this.utilityService.ConvertDateTimeToUTCDateTimeAndFormatForSQLParameter(this.startDate.value);
		var endDateTime = this.utilityService.ConvertDateTimeToUTCDateTimeAndFormatForSQLParameter(this.endDate.value);

		let statement =
			'Security.UserEngagement_UserActivityByListOfUsers @ListOfUsers = ' +
			"'" + userList + "'" +
			', @UserId = ' +
			currentUserId +
			', @StartDate = ' +
			"'" + startDateTime + "'" +
			', @EndDate = ' +
			"'" + endDateTime + "'";
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.userActivity = [];
			if (data.length == 0)
				this.noUserActivity = true;

			else {
				this.noUserActivity = false;
				data.forEach((u) => {
					u.DateTime = this.utilityService.ConvertDateTimeToUTCDateTimeAndFormatForSQLParameter(u.LogDate);
				})
				this.userActivity = data;
				if(this.activityGrid != undefined){
					this.activityGrid.gridDataSubject.next(this.userActivity);

				}


			}
		});
  }

}
