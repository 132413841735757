import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';

@Component({
	selector: 'lib-state-selector',
	templateUrl: './state-selector.component.html',
	styleUrls: ['./state-selector.component.scss'],
})
export class StateSelectorComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public question: any;
	@Output() public returnValue: any;
	public states: any;
	public filteredStates$: Observable<any[]>;
	public stateName: string;

	constructor(private dataService: DataService) {}

	ngOnInit() {
		console.log('this.formGroup = %O', this.formGroup);
		console.log('this.question = %O', this.question);

		this.states = this.dataService.cache.states;

		this.filteredStates$ = this.formGroup.controls[
			this.question.key
		].valueChanges.pipe(
			startWith(''),
			tap((value) => {
				console.log('value = %O', value);
			}),
			map((value: any) =>
				typeof value === 'string' ? value : value.Name
			),
			map((name: any) =>
				name ? this.statesFilter(name) : this.states.slice()
			)
		);

		if (this.question.value != null) {
			//-- this is bringing up an edited record, so get the record that matches on the Id field. --Kirk T. Sherer, May 26, 2021.
			var state = this.states.first((option: any) => {
				return option.Id == this.question.value;
			});
			this.formGroup.controls[this.question.key].setValue(state);
		}
	}

	public displayFn(state: any): string {
		return state && state.Name ? state.Name : '';
	}

	private statesFilter(value: string): any {
		const filterValue = value.toLowerCase();
		return this.states.filter((option: any) => {
			if (option.Name != undefined) {
				return option.Name.toLowerCase().includes(filterValue);
			}
		});
	}
}
