import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditWidgetComponent } from './edit-widget.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [EditWidgetComponent],
	imports: [CommonModule, ReactiveFormsModule],
	exports: [EditWidgetComponent],
})
export class EditWidgetModule {}
