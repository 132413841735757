import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MulticheckFilterComponent } from './multicheck-filter.component';

@NgModule({
	declarations: [MulticheckFilterComponent],
	imports: [CommonModule],
	exports: [MulticheckFilterComponent],
})
export class MulticheckFilterModule {}
