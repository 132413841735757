import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import _ from 'lodash';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: "lib-widget-interval-selection-popup",
	templateUrl: "./widget-interval-selection-popup.component.html",
	styleUrls: ["./widget-interval-selection-popup.component.scss"],
})
export class WidgetIntervalSelectionPopupComponent implements OnInit {
	intervals: any;
	selectedIntervalId: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public localWidgetObject: any,
		public dialogRef: MatDialogRef<WidgetIntervalSelectionPopupComponent>,
		private dataService: DataService,
		public toastr: ToastrService,
		private dashboardService: DashboardService,
		private utilityService: UtilityService
	) {}

	ngOnInit() {
		this.intervals =
			Global.User.currentUser.TacticalDashboardUpdateIntervals;

		this.selectedIntervalId = this.localWidgetObject.UpdateIntervalId;
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		if (
			this.selectedIntervalId !== this.localWidgetObject.UpdateIntervalId
		) {
			let statement =
				"API.DashboardUpdateWidgetRecordSettings " +
				"@ParentDashboardId = " +
				this.localWidgetObject.Id +
				", @Id = " +
				this.localWidgetObject.WidgetId;
			//standard dashboard

			//  tactical dashboard
			if (!_.isNil(this.localWidgetObject.WidgetAssetTypeId)) {
				statement +
					", @widgetSiteId = " +
					this.localWidgetObject.WidgetSiteId +
					", @widgetAssetTypeId = " +
					this.localWidgetObject.WidgetAssetTypeId;
			}
			if (_.isNil(this.localWidgetObject.SiteList)) {
				statement =
					statement +
					", @widgetSiteList = " +
					this.localWidgetObject.SiteList;
			} else if (!_.isNil(this.localWidgetObject.SiteList)) {
				if (typeof this.localWidgetObject.SiteList === "string") {
					statement =
						statement +
						", @widgetSiteList = '" +
						this.localWidgetObject.SiteList +
						"'";
				} else {
					this.localWidgetObject.SiteListAsString =
						this.localWidgetObject.SiteList.join();
					statement =
						statement +
						", @widgetSiteList = '" +
						this.localWidgetObject.SiteListAsString +
						"'";
				}
			}
			if (!_.isNil(this.localWidgetObject.WidgetSiteId)) {
				statement =
					statement +
					", @widgetSiteId = " +
					this.localWidgetObject.WidgetSiteId;
			}
			if (!_.isNil(this.localWidgetObject.WidgetTerminalSystemId)) {
				statement =
					statement +
					", @widgetTerminalSystemId = " +
					this.localWidgetObject.WidgetTerminalSystemId;
			}

			if (!_.isNil(this.localWidgetObject.WidgetGateSystemId)) {
				statement =
					statement +
					", @widgetGateSystemId = " +
					this.localWidgetObject.WidgetGateSystemId;
			}
			if (!_.isNil(this.localWidgetObject.WidgetAssetId)) {
				statement =
					statement +
					", @widgetAssetSystemId = " +
					this.localWidgetObject.WidgetAssetId;
			}

			if (!_.isNil(this.localWidgetObject.TimeScopeId)) {
				statement =
					statement +
					", @widgetTimeScopeId = " +
					this.localWidgetObject.TimeScopeId;
			}

			if (
				!_.isNil(this.localWidgetObject.TimeZoneId) &&
				Global.User.isLoggedInAsDifferentUser == false
			) {
				statement =
					statement +
					", @widgetTimeZoneId = " +
					this.localWidgetObject.TimeZoneId;
			}

			statement =
				statement +
				", @widgetUpdateIntervalId = " +
				this.selectedIntervalId +
				", @selectedTabIndex = " +
				this.localWidgetObject.SelectedTabIndex;

			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				this.localWidgetObject = data.first();
				let returnedWidget = data.first();

				// Force Site Time when logged in as another user
				if (Global.User.isLoggedInAsDifferentUser == true) {
					returnedWidget.TimeZoneId = 2;
				}

				let dashboardId = returnedWidget.Id;
				let widgetId = data.first().WidgetId;
				let dashboardIndexInGlobalDashboardsArray =
					Global.User.currentUser.Dashboards.findIndex(
						(obj) => obj.Id == dashboardId
					);
				let widgetIndexinGlobalDashboardArray =
					Global.User.currentUser.Dashboards[
						dashboardIndexInGlobalDashboardsArray
					].widgets.findIndex((obj) => obj.WidgetId == widgetId);
				Global.User.currentUser.Dashboards[
					dashboardIndexInGlobalDashboardsArray
				].widgets[widgetIndexinGlobalDashboardArray].UpdateIntervalId =
					returnedWidget.UpdateIntervalId;
				let chosenUpdateInterval = this.intervals.find(
					(ts) => ts.Id === this.selectedIntervalId
				);
				// this.toastr.success(
				// 	'Update Interval Settings have been changed to ' +
				// 		chosenUpdateInterval.Description +
				// 		' for ' +
				// 		this.localWidgetObject.WidgetName
				// );
				this.utilityService.showToastMessageShared({
					type: "success",
					message:
						"Update Interval Settings have been changed to " +
						chosenUpdateInterval.Description +
						" for " +
						this.localWidgetObject.WidgetName,
				});

				Global.User.DebugMode && console.log(data);
				let dataInArray = [];
				dataInArray.push(returnedWidget);

				this.dashboardService._widgetTimeChanged.next({
					TimeChangeType: "update interval",
					data: dataInArray,
				});
				this.dialogRef.close();
			});
		}
	}
}
