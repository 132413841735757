import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'lib-material-input',
	templateUrl: './material-input.component.html',
	styleUrls: ['./material-input.component.scss'],
})
export class MaterialInputComponent implements OnInit {
	@Input() devLabel: string;
	@Input() devPlaceholder: string;
	@Output() searchcriteria = new EventEmitter<String>();
	public searchword: string;

	constructor() {}

	ngOnInit(): void {}

	searchThis() {
		this.searchcriteria.emit(this.searchword);
	}
}
