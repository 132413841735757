<div
	style="float: right"
	[ngStyle]="{ 'padding-top': !global.isMobile ? '5px' : '0px' }"
>
	<div class="d-inline-block">
		<button mat-button [matMenuTriggerFor]="widgetDropdown">
			<i class="tim-icons icon-settings-gear-63"> </i>
		</button>
		<mat-menu #widgetDropdown="matMenu">
			<button mat-menu-item [matMenuTriggerFor]="settings">
				Widget Settings
			</button>
			<!-- <button
				mat-menu-item
				[matMenuTriggerFor]="information"
				*ngIf="global.isMobile"
			>
				Information
			</button> -->
			<button *ngIf="widgetObject.WidgetId" (click)="refreshComponent()" mat-menu-item>
				Reload Widget
			</button>
			<button *ngIf="widgetObject.WidgetId" (click)="onDelete()" style="background-color: red" mat-menu-item>Delete</button>
		</mat-menu>

		<mat-menu #settings="matMenu">
			<button
				[disabled]="!canUserSeeWidgetSettings()"
				*ngIf="
					widgetObject.AngularDirectiveName !== 'canvas' &&
					widgetObject.AngularDirectiveName !==
						'siteActiveCommsLoss' &&
					widgetObject.AllowSettingsSelection !== false &&
					widgetObject.DashboardTypeId !== 2 && widgetObject.WidgetId
				"
				(click)="openWidgetSettings()"
				mat-menu-item
			>
				Site Settings
			</button>

			<button
				[disabled]="!canUserSeeWidgetSettings()"
				*ngIf="widgetObject.DashboardTypeId === 2 && widgetObject.WidgetId"
				(click)="openWidgetSettings()"
				mat-menu-item
			>
				Site Settings
			</button>
			<button
				(click)="changeWidgetHeight('taller', widgetObject, $event)"
				mat-menu-item
				*ngIf="global.isMobile && widgetObject.WidgetId"
			>
				Make Widget Taller
			</button>
			<button
				(click)="changeWidgetHeight('shorter', widgetObject, $event)"
				mat-menu-item
				*ngIf="global.isMobile && widgetObject.WidgetId"
			>
				Make Widget Shorter
			</button>
			<button
				(click)="changeScrollMode(widgetObject)"
				mat-menu-item
				*ngIf="global.isMobile && widgetObject.WidgetId"
			>
				Widget Scroll Lock:
				{{ widgetObject.scrollmode === "none" ? "On" : "Off" }}
			</button>

			<button
				[disabled]="!canUserSeeWidgetSettings()"
				(click)="openEditWidgetName()"
				class="settingsButton"
				mat-menu-item
				*ngIf="widgetObject.WidgetId"
			>
				Edit Widget Name
			</button>

			<button
				*ngIf="widgetObject.WidgetId &&
					dashboardService.evaluateAngularDirectiveNameForTimeZoneOption(
						'widget',
						widgetObject
					)
				"
				[disabled]="!canUserSeeWidgetSettings()"
				(click)="openTimeZoneSettings()"
				mat-menu-item
			>
				Time Zone:
				{{
					dashboardService.determineIfTimeZoneIsLocal(
						widgetObject
					) === false
						? "Site Time"
						: "User Time"
				}}
			</button>
			<button
				*ngIf="widgetObject.WidgetId &&
					widgetObject.DashboardTypeId == 1 &&
					dashboardService.evaluateAngularDirectiveNameForTimeScopeOption(
						'widget',
						widgetObject
					) && currentDashboard.TimeScopeId != null
				"
				[disabled]="!canUserSeeWidgetSettings()"
				(click)="openTimeScopeSettings()"
				mat-menu-item
			>
				Time Scope:
				{{
					dashboardService.determineWhatTimeScopeForWidget(
						widgetObject
					)
				}}
			</button>
			<button
			*ngIf="

				widgetObject.AngularDirectiveName === 'gseSummary'

			"
			[disabled]="!canUserSeeWidgetSettings()"
			(click)="goToPunchoutScreen()"
			mat-menu-item
		>
			Go to Punchout Screen for Asset
		</button>

			<button
			*ngIf="


					widgetObject.AngularDirectiveName === 'siteCameraView'
			"
			[disabled]="!canUserSeeWidgetSettings()"
			(click)="toggleSidenavPanel()"
			mat-menu-item
		>
			Toggle Sidenav Panel
		</button>
			<button
				*ngIf="widgetObject.DashboardTypeId == 2 && widgetObject.WidgetId"
				(click)="openTimeScopeSettings()"
				mat-menu-item
			>
				Time Scope:
				{{
					dashboardService.determineWhatTimeScopeForWidget(
						widgetObject
					)
				}}
			</button>
			<button
				*ngIf="widgetObject.DashboardTypeId == 2 && widgetObject.WidgetId"
				(click)="openIntervalSettings()"
				mat-menu-item
			>
				Update Interval:
				{{
					dashboardService.determineWhatUpdateIntervalForTacticalWidget(
						widgetObject
					)
				}}
			</button>

			<button
				[disabled]="!canUserSeeWidgetSettings()"
				*ngIf="widgetObject.AngularDirectiveName == 'canvas'"
				(click)="openTemplatePopup()"
				mat-menu-item
			>
				Select Model Template to Edit
			</button>
			<button
				[disabled]="!canUserSeeWidgetSettings()"
				*ngIf="widgetObject.AngularDirectiveName == 'canvas'"
				(click)="openGSETemplatePopup()"
				mat-menu-item
			>
				Create/Edit Fleet Summary Template
			</button>

			<button
				[disabled]="!canUserSeeWidgetSettings()"
				(click)="addTagsToWidgetChart()"
				*ngIf="
					(widgetObject.WidgetTypeName === 'Chart' ||
						widgetObject.AngularDirectiveName === 'tagGraph') &&
					dataService.tempTagsToChart.length > 0
				"
				mat-menu-item
			>
				<span *ngIf="dataService.tempTagsToChart.length >= 1"
					>Add Tags to Chart</span
				>
			</button>
			<button
				(click)="invertDraggableSetting(widgetObject)"
				*ngIf="!global.isMobile && widgetObject.WidgetId"
				mat-menu-item
			>
				<span *ngIf="widgetObject.dragEnabled === false"
					>Allow Dragging</span
				>
				<span
					*ngIf="
						widgetObject.dragEnabled === true ||
						widgetObject.dragEnabled === null
					"
					>Disable Dragging</span
				>
			</button>
			<button
				(click)="invertResizeSetting(widgetObject)"
				*ngIf="!global.isMobile && widgetObject.WidgetId"
				mat-menu-item
			>
				<span *ngIf="widgetObject.resizeEnabled === false"
					>Allow Resizing</span
				>
				<span
					*ngIf="
						widgetObject.resizeEnabled === true ||
						widgetObject.resizeEnabled === null
					"
					>Disable Resizing</span
				>
			</button>
			<button
				(click)="toggleLegendHidden(widgetObject)"
				*ngIf="global.isMobile && widgetObject.WidgetId"
				mat-menu-item
			>
				<span>Toggle Legend</span>

			</button>
			<button
				(click)="saveSelectedTabIndex(widgetObject, $event)"
				*ngIf="(widgetObject.DashboardTypeId === 2 
				|| widgetObject.AngularDirectiveName == 'gseSummary' 
				|| widgetObject.AngularDirectiveName == 'gseOverview' 
				|| widgetObject.AngularDirectiveName == 'fireTruckOverview' 
				|| widgetObject.AngularDirectiveName == 'fireTruckOverviewAerial' 
				|| widgetObject.AngularDirectiveName == 'fireTruckOverviewDriveline' 
				|| widgetObject.AngularDirectiveName == 'mobileConditionedOverview' 
				|| widgetObject.AngularDirectiveName == 'fuelLevel' 
				|| widgetObject.AngularDirectiveName == 'deicerOverview' )  
				&& widgetObject.WidgetId"
				mat-menu-item
			>
				<span>
					Save Selected Tab
					<mat-icon *ngIf="selectedTabSaved" style="color: green">
						&nbsp;check
					</mat-icon>
				</span>
			</button>
			<button
				*ngIf="widgetObject.DashboardTypeId == 2 && widgetObject.WidgetId"
				(click)="navigateToFirstTabForTacticalDashboardWidgets(widgetObject)"
				mat-menu-item
			>
				Navigate to First Tab
			</button> 
			<button
				*ngIf="widgetObject.AngularDirectiveName == 'locateAllGSE'"
				(click)="toggleGeofencesForLocateAllGSE()"
				mat-menu-item
			>
				Toggle Geofences
			</button>
			<button
				*ngIf="widgetObject.AngularDirectiveName == 'locateAllGSE' || widgetObject.AngularDirectiveName == 'gseSummary' "
				(click)="configureSplitViewPercents()"
				mat-menu-item
			>
				Configure Split View Percents
			</button>
			<button
				*ngIf="widgetObject.AngularDirectiveName == 'tagGraph' && widgetObject.WidgetId"
				(click)="loadTagSettingsForWidget()"
				mat-menu-item
			>
				Tag Settings
			</button>
		</mat-menu>

		<!-- <mat-menu #information="matMenu">
			<button mat-menu-item>About Widget</button>

		</mat-menu> -->

		<!-- <mat-menu #fish="matMenu">
		  <button mat-menu-item>Baikal oilfish</button>
		  <button mat-menu-item>Bala shark</button>
		  <button mat-menu-item>Ballan wrasse</button>
		  <button mat-menu-item>Bamboo shark</button>
		  <button mat-menu-item>Banded killifish</button>
		</mat-menu>

		<mat-menu #amphibians="matMenu">
		  <button mat-menu-item>Sonoran desert toad</button>
		  <button mat-menu-item>Western toad</button>
		  <button mat-menu-item>Arroyo toad</button>
		  <button mat-menu-item>Yosemite toad</button>
		</mat-menu>

		<mat-menu #reptiles="matMenu">
		  <button mat-menu-item>Banded Day Gecko</button>
		  <button mat-menu-item>Banded Gila Monster</button>
		  <button mat-menu-item>Black Tree Monitor</button>
		  <button mat-menu-item>Blue Spiny Lizard</button>
		  <button mat-menu-item disabled>Velociraptor</button>
		</mat-menu> -->

		<!-- <button mat-button [matMenuTriggerFor]="menu">
			<i class="tim-icons icon-settings-gear-63"> </i>
		</button>
		<mat-menu #menu="matMenu">





		</mat-menu> -->
	</div>
</div>
