<div class="linear-gauge-container">
	<h4>{{titleText}}</h4>
	<div *ngIf="pressureValue != undefined">
		<h5>{{pressureValue}}</h5>
		<kendo-lineargauge [pointer]="{ value: pressureValue, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
			<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
			<kendo-lineargauge-scale [minorUnit]="10" [majorUnit]="100" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="0" [max]="700" [line]="{ color: gaugeTextColor }">
				<kendo-lineargauge-scale-ranges>
					<kendo-lineargauge-scale-range [from]="0" [to]="36" color="LightGrey"> </kendo-lineargauge-scale-range>
					<kendo-lineargauge-scale-range [from]="36" [to]="650" color="LightGreen"> </kendo-lineargauge-scale-range>
					<kendo-lineargauge-scale-range [from]="650" [to]="700" color="Red"> </kendo-lineargauge-scale-range>
				</kendo-lineargauge-scale-ranges>
			</kendo-lineargauge-scale>
		</kendo-lineargauge>
	</div>
</div>
