import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StringFilterCellComponent } from "@progress/kendo-angular-grid";
import { RecursiveDataNavigatorService } from "projects/shared-lib/src/lib/services/recursive-data-navigator.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";

@Component({
	selector: "rdn-destination",
	templateUrl: "./rdn-destination.component.html",
	styleUrls: ["./rdn-destination.component.scss"]
})
export class RdnDestinationComponent implements OnInit {
	public isLoading: boolean = false;
	public selectedEntity: any;
	public setName: string;
	public global: IGlobal = Global;

	constructor(private activatedRoute: ActivatedRoute, private router: Router, public rdnService: RecursiveDataNavigatorService, public utilityService: UtilityService) { }

	ngOnInit() {
		this.setName = this.activatedRoute.snapshot.paramMap.get("setName");
		
		var requestedRDNSet = Global.AdminTableEditorStates.firstOrDefault((set: any) => {	return set.setName == this.setName;	});
		if (requestedRDNSet != null) {
			Global.User.DebugMode && console.log("this.setName sent to this component = %O", requestedRDNSet);
			this.selectedEntity = requestedRDNSet;
			var rdnSet = this.rdnService.structure.firstOrDefault((s: any) => { return s.setName == this.selectedEntity.setName; });
			if (rdnSet == null) {
				rdnSet = this.rdnService.structure.firstOrDefault((s: any) => { 
					return s.sets?.firstOrDefault((set:any) => { return set.Name == this.selectedEntity.setName })
				});
				if (rdnSet == null) {
					this.selectedEntity = null; //-- this is so the RDN doesn't actually start up since the selectedEntity is what is passed to the RDN component. 
					this.returnToSystemTableListAndShowToasterMessage(requestedRDNSet); //-- going back to list since the requested set name doesn't exist in the RDN Service. 
				}
			}
			else {
				Global.User.DebugMode && console.log("rdn-destination: Global.RDN = %O", Global.RDN);
			}
		}
		else {
			//-- check the grouping of System Tables to see if we have any that have children (i.e. sub-groups). If so, then check those groups to see if the children have the setName being sent from the System Tables component.
			var arrayOfGroupingsWithChildRecords = Global.AdminTableEditorStates.where((tableEntry:any) => { return tableEntry.children != undefined	}).toArray();
			if (arrayOfGroupingsWithChildRecords != null && arrayOfGroupingsWithChildRecords.length > 0)
			{
				var childSet = null;
				arrayOfGroupingsWithChildRecords.forEach((group:any) => {
					childSet = group.children.firstOrDefault((set: any) => { return set.setName == this.setName; });
					if (childSet != null) {
						Global.User.DebugMode && console.log("this.setName sent to this component = %O", childSet);
						this.selectedEntity = childSet;
					}
				});

				if (this.selectedEntity == null) {
					//-- no such set name exists in the RDN currently.  Send back a toaster message indicating this for the logged-in administrative user.
					this.returnToSystemTableListAndShowToasterMessage(requestedRDNSet); //-- use the one that was originally sent to us at the start of this function. 
				}
				else {
					//--check the RDN service to insure that the requested RDN set really exists in the RDN Service. 
					var rdnSet = this.rdnService.structure.firstOrDefault((s: any) => { return s.setName == this.selectedEntity.setName; });
					if (rdnSet == null) {
						rdnSet = this.rdnService.structure.firstOrDefault((s: any) => { 
							return s.sets?.firstOrDefault((set:any) => { return set.Name == this.selectedEntity.setName })
						});
						if (rdnSet == null) {
							requestedRDNSet = this.selectedEntity;
							this.selectedEntity = null; //-- this is so the RDN doesn't actually start up since the selectedEntity is what is passed to the RDN component. 
							this.returnToSystemTableListAndShowToasterMessage(requestedRDNSet); //-- going back to list since the requested set name doesn't exist in the RDN Service. 
						}					
					}
					else {
						Global.User.DebugMode && console.log("rdn-destination: Global.RDN = %O", Global.RDN);
					}
				}
			}
			else {
				//--if the user came here with a set name that does not exist, then just go back to the list of system tables. --Kirk T. Sherer, November 27, 2023. 
				this.returnToSystemTableListAndShowToasterMessage(requestedRDNSet);
			}
		}
		
	}

	backToSystemTableList() {
		this.router.navigate(["layout/tables"]);
	}

	returnToSystemTableListAndShowToasterMessage(set: any) {
		this.utilityService.showToastMessageShared({
			type: "info",
			message: "'" + set.label + "' does not exist in the RDN.",
			title: "Recursive Data Navigator"
		});
		this.backToSystemTableList();
	}
}
