<div class="content" style="height: calc(100%)">
	<div
		class="card"
		[style.height]="'calc(100vh - 95px)'"
		style="width: calc(100%)"
	>
		<div class="card-header" style="height: 80px">
			<h3
				style="display: inline; vertical-align: bottom"
				class="card-title"
			>
				Canvas Designer
			</h3>

			<div style="float: right" [ngStyle]="{ 'padding-top': '5px' }">
				<div class="d-inline-block">
					<button mat-button [matMenuTriggerFor]="widgetDropdown">
						<i class="tim-icons icon-settings-gear-63"> </i>
					</button>
					<mat-menu #widgetDropdown="matMenu">
						<button mat-menu-item [matMenuTriggerFor]="settings">
							Widget Settings
						</button>
					</mat-menu>

					<mat-menu #settings="matMenu">
						<button (click)="openTemplatePopup()" mat-menu-item>
							Select Model Template to Edit
						</button>
						<button (click)="openGSETemplatePopup()" mat-menu-item>
							Create/Edit Fleet Summary Template
						</button>
					</mat-menu>
				</div>
			</div>
		</div>
		<div class="card-body" style="height: 75%">
			<lib-canvas
				*ngIf="selectedTemplate"
				[widgetObject]="widgetObject"
			></lib-canvas>
		</div>
	</div>
</div>
