import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { BehaviorSubject } from "rxjs";
import * as moment from "moment";
import { QuestionBase } from "../../../_models/dynamic-fields/questions/question-base";
import { IGlobal } from "../../../_models/global.model";
import { Global } from "../../../_constants/global.variables";
import { QuestionControlService } from "../../../services/question-control.service";
import { RecursiveDataNavigatorService } from "../../../services/recursive-data-navigator.service";
import { UtilityService } from "../../../services/utility.service";
import { DataService } from "../../../services/data.service";
import { SignalRCoreService } from "../../../services/signalr-core.service";
import { TextboxQuestion } from "../../../_models/dynamic-fields/questions/question-textbox";
import swal from 'sweetalert2';

@Component({
	selector: "lib-dynamic-form",
	templateUrl: "./dynamic-form.component.html",
	styleUrls: ["./dynamic-form.component.scss"]
})
export class DynamicFormComponent implements OnInit {
	@Input() public questions: QuestionBase<string>[] = [];
	@Input() public options: any;
	@Input() public entityName: string;
	@Input() public isInternalDialog: boolean;
	@Input() public hasDynamicPropertyFields: boolean;
	@Output() public notifyParentQuestionsHaveChanged: EventEmitter<any> = new EventEmitter();
	@Output() submittedValues = new EventEmitter<string>();

	form: FormGroup;
	payLoad = "";
	public service: any;
	public global: IGlobal = Global;
	public internalDialogIsVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public processingForm: boolean = false;
	public formHasBeenUpdated$: EventEmitter<any> = new EventEmitter();
	public formIsLoaded: boolean = false;
	public componentName: string = "dynamic-form: ";
	private gseAssetTypeIds: Array<number> = [86292, 86293, 86294, 86295, 86296, 86297, 86299, 86300];
	private fleetAssetTypeIds: Array<number> = [86313, 86314];

	public numberOfQuestions: number = 0;
	public numberOfNewQuestions: number = 0;
	public uniqueId: string;

	constructor(private questionControlService: QuestionControlService, public rdnService: RecursiveDataNavigatorService, public utilityService: UtilityService, public dataService: DataService, public signalR: SignalRCoreService) { }

	ngOnInit() {
		this.entityName != null && console.log(this.componentName + "<--- EDITING FORM for " + this.entityName + " --->");
		console.log(this.componentName + "this.questions = %O", this.questions);
		console.log(this.componentName + "this.options = %O", this.options);
		this.numberOfQuestions = this.questions.length;

		if (!this.options.saveValuesPerField) {
			this.options.saveValuesPerField = false;
		}
		this.form = this.questionControlService.toFormGroup(this.questions);
		console.log(this.componentName + "dynamic-form: this.form = %O", this.form);
		this.internalDialogIsVisible$ = new BehaviorSubject(Global.RDN.internalDialog);
		this.internalDialogIsVisible$.subscribe((internalDialogIsVisible: boolean) => {
			console.log(internalDialogIsVisible ? "internal dialog is visible..." : "internal dialog is not visible...");
		});
	}

	public formHasBeenLoaded() {
		console.log(this.componentName + "dynamic form has been loaded...");
		this.formIsLoaded = true;
		console.log(this.componentName + "dynamic-form: this.form = %O", this.form);
	}

	public reloadQuestions(removedQuestion: any) {
		console.log(this.componentName + "questions have changed. removedQuestion: %O", removedQuestion);
		console.log(this.componentName + "listOfQuestions: %O", this.questions);
		this.questions = this.questions.where((question:any) => { return question.key != removedQuestion.key }).toArray();
		this.questions.forEach((question:any) => {
            console.log("question.key = " + question.key + ", form.control = %O", this.form.controls[question.key]);
            question.value = this.form.controls[question.key].value; //--must get whatever the value is on the form currently, since original question array doesn't have the same thing.--Kirk T. Sherer, March 12, 2024. 
        });
		this.form = this.questionControlService.toFormGroup(this.questions);
		this.numberOfQuestions = this.questions.length;
		this.notifyParentQuestionsHaveChanged.emit(this.questions);
	}

	addNewQuestion(event: any) {
		console.log("new question name = " + event.target.value + ", event = %O", event);
		
		var newQuestionNameEntered = event.target.value;

		var questionName = this.utilityService.ToPascalCase(newQuestionNameEntered.replace("'",""));
		questionName = questionName.replace(/\s+/g, '');
		
		var fieldOrder = this.numberOfQuestions + 10 + this.numberOfNewQuestions + 1; 

		var questionNameDisplay = questionName.replace(/([A-Z])/g, ' $1').trim();
		var questionExists = this.questions.firstOrDefault((question:any) => { return question.key == questionName });
		if (!questionExists) {
			var newTextField = new TextboxQuestion({
				key: questionName,
				label: questionNameDisplay,
				title: "Please fill in the appropriate value for this " + questionNameDisplay + " field.",
				order: fieldOrder,
				type: "text",
				required: false,
				isDynamicProperty: true
			});
			this.questions.forEach((question:any) => {
				console.log("question.key = " + question.key + ", form.control = %O", this.form.controls[question.key]);
				question.value = this.form.controls[question.key].value; //--must get whatever the value is on the form currently, since original question array doesn't have the same thing.--Kirk T. Sherer, March 12, 2024. 
			});
			
			this.questions.push(newTextField); //-- adding a new field for the property that is not defined in the RDN service.
			console.log("this.questions = %O", this.questions);
			this.form = this.questionControlService.toFormGroup(this.questions);
	
			// this.questions.push(newTextField); //-- adding a new field for the property that is not defined in the RDN service.
			// this.form = this.questionControlService.toFormGroup(this.questions);
			console.log(this.componentName + "dynamic-form: this.form after adding new question = %O", this.form);
			this.numberOfNewQuestions += 1;
			this.notifyParentQuestionsHaveChanged.emit(this.questions);
		}
		else {
			swal.fire({
				html: "'<strong>" + questionNameDisplay + "</strong>' already exists on this form.",
			});
		}
		event.preventDefault();
		event.stopPropagation();
		event.target.value = "";
	}

	checkFields(changedQuestion: any) {
		var service = this;
		if (this.formIsLoaded) {
			console.log(this.componentName + "<--- " + changedQuestion.key + " --->")
			console.log(this.componentName + "changedQuestion = %O", changedQuestion);
			console.log(this.componentName + "this.form.controls[" + changedQuestion.key + "].value = %O", service.form.controls[changedQuestion.key].value);
			console.log(this.componentName + "this.form.get(" + changedQuestion.key + ").value = %O", service.form.get(changedQuestion.key).value);

			service.questions.forEach((question: any) => {
				if (question.visibleFields != null) {
					question.visibleFields.forEach((visibleCondition: any) => {

						var visibleFieldValue = visibleCondition.value;

						switch (question.controlType) {
							case "slider":
							case "slider-yes-no":
							case "checkbox":
							case "true-false-button":
								if (visibleFieldValue == "false" || visibleFieldValue == "0" || visibleFieldValue == 0)
  							    {
									visibleFieldValue = false;
								}
								else
								{
									if (!isNaN(Number(visibleFieldValue)) && Number(visibleFieldValue))
									{
										visibleFieldValue = +visibleFieldValue;
									}
									else
									{
										visibleFieldValue = true;
									}
								}
								break;
							default:
								break;
						}
						const control: any = service.form.get(visibleCondition.field); // 'control' is a FormControl
						var actualVisibleFieldValue = control?.value;
						if (typeof control?.value == "object" && control?.value != null) { //--  && visibleCondition.type == "Id"
							actualVisibleFieldValue = control.value["Id"];
						}
						switch (question.controlType) {
							case "slider":
							case "slider-yes-no":
							case "checkbox":
							case "true-false-button":
								if (actualVisibleFieldValue == "false" || actualVisibleFieldValue == "0" || actualVisibleFieldValue == 0)
								{
									actualVisibleFieldValue = false;
								}
								else
								{
									if (!isNaN(Number(actualVisibleFieldValue)) && Number(actualVisibleFieldValue))
										{
											actualVisibleFieldValue = +actualVisibleFieldValue;
										}
										else
										{
											actualVisibleFieldValue = true;
										}
								}
								break;
							default:
								break;
						}
						var visibleFieldValueTrueFalse = visibleFieldValue == true || visibleFieldValue != undefined ? true : false;
						var actualVisibleFieldValueTrueFalse = actualVisibleFieldValue == true || actualVisibleFieldValue != undefined ? true : false;
						switch (visibleCondition.operator) {
							case "==":
								visibleCondition.questionShouldBeVisible = actualVisibleFieldValue == visibleFieldValue || +actualVisibleFieldValue == +visibleFieldValue;
								break;
							case "!=":
								visibleCondition.questionShouldBeVisible = actualVisibleFieldValue != visibleFieldValue;
								break;
							case ">":
								visibleCondition.questionShouldBeVisible = !isNaN(Number(actualVisibleFieldValue)) && Number(actualVisibleFieldValue) > +visibleFieldValue;
								break;
							case "<":
								visibleCondition.questionShouldBeVisible = !isNaN(Number(actualVisibleFieldValue)) && Number(actualVisibleFieldValue) < +visibleFieldValue;
								break;
							case "contains":
								switch (visibleCondition.valuesToCheck) {
									case "gseAssetTypeIds":
										visibleCondition.questionShouldBeVisible = service.gseAssetTypeIds.contains(actualVisibleFieldValue);
										break;
									case "fleetAssetTypeIds":
										visibleCondition.questionShouldBeVisible = service.fleetAssetTypeIds.contains(actualVisibleFieldValue);
										break;
									default:
										visibleCondition.questionShouldBeVisible = actualVisibleFieldValueTrueFalse == visibleFieldValueTrueFalse;
										break;
								}
								break;
							default: //-- if we have a value for both the requirement and the actual field, since there's no operator defined, then set the questionShouldBeVisible to the true/false that there is a value for each. --Kirk T. Sherer, August 20, 2021.
								visibleCondition.questionShouldBeVisible = actualVisibleFieldValueTrueFalse == visibleFieldValueTrueFalse;
								break;
						}
					});

					var areAnyVisibleFieldsSetToFalse = question.visibleFields.where((data: any) => { return data.questionShouldBeVisible == false;	}).toArray();
					question.visible = areAnyVisibleFieldsSetToFalse.length == 0; //-- only allow the field if all entries in the visible fields list are set to true (to show the field.)
				}

				if (question.controlType == "uib-button-multi-select") {
					if (question.listOfValues != null) {
						var data = question.listOfValues;

						if (data.length > 0) {
							var anySelected = data.where((d: any) => { return d.IsSelected == 1 }).toArray();

							var allSelected = anySelected.length == data.length ? true : false;

							if (anySelected.length == 0 && question.buttonList.selectAllOnInitialLoad) {
								data.forEach((d: any) => {
									d.IsSelected = 1;
								});
								question.multiSelectedAll = true;
							}
							else {
								question.multiSelectedAll = allSelected;
							}
							question.listOfValues = data;
						}
						else {
							question.listOfValues = null;
							question.multiSelectedAll = false;
						}
					}
					else {
						if (question.buttonList && question.buttonList.storedProcedure != null && question.buttonList.parameters != null) {
							var parameterList = "";
							var countOfParameters = 0;
							question.buttonList.parameters.forEach((parameter: any) => {
								var parameterField = parameter.field == null ? parameter.name : parameter.field;
								var parameterFieldIsNumeric = parameterField.slice(-2) == "Id";
								var parameterValue = null;

								if (parameterField == "@AdminUserId") {
									parameterValue = Global.User.currentUser.Id; //-- if we're dealing with an AdminUserId, that means we're trying to edit something as an administrator of some sort.  Send over the current user's ID number for evaluation, since the RDN Service retains the original UserId of the first username that logged in. --Kirk T. Sherer, June 20, 2023.
								}
								else {
									parameterValue = service.form.get(parameter.value).value;
									if (typeof parameterValue == "object") {
										parameterValue = parameterValue.Id; //-- get only the Id value since the parameterValue coming back is a complete record object. Id is the value we need.
									}
								}

								if ((parameterFieldIsNumeric && !isNaN(Number(parameterValue)) && Number(parameterValue) != 0) || (!parameterFieldIsNumeric && isNaN(Number(parameterValue)))) {
									parameterList += (question.buttonList.parameters.length > countOfParameters && parameterList != "" ? "," : "") + parameterField + "=" + (!parameterFieldIsNumeric || isNaN(Number(parameterValue)) ? "'" + parameterValue + "'" : parameterValue);
									countOfParameters++;
								}
							});
							var sql = question.buttonList.storedProcedure + " " + parameterList;
							if (question.buttonList.canExecuteStoredProcedureWithoutParameters || countOfParameters > 0) {
								service.dataService.SQLActionAsPromise(sql).then((data: any) => {
									// if (question.key == "ListOfAuthorizedSites") {
									if (data.length > 0) {
										var anySelected = data
											.where((d: any) => {
												return d.IsSelected == 1;
											})
											.toArray();

										var allSelected = anySelected.length == data.length ? true : false;

										if (anySelected.length == 0 && question.buttonList.selectAllOnInitialLoad) {
											data.forEach((d: any) => {
												d.IsSelected = 1;
											});
											question.multiSelectedAll = true;
										} else {
											question.multiSelectedAll = allSelected;
										}
										question.listOfValues = data;
									} else {
										question.listOfValues = null;
										question.multiSelectedAll = false;
									}
									// }
									// else {
									// 	question.listOfValues = data;
									// 	question.multiSelectedAll = false;
									// }
								});
							} else {
								question.listOfValues = null;
								question.multiSelectedAll = false;
							}
						}
					}
				}
			});
			service.notifyParentQuestionsHaveChanged.emit(this.questions);
		}
	}

	onSubmit(submitButtonPressed?: boolean) {
		var service = this;
		console.log(this.componentName + "this.options.saveValuesPerField = " + service.options.saveValuesPerField + ", submitButtonPressed = " + submitButtonPressed);
		if (service.options.saveValuesPerField || submitButtonPressed) {
			var checkAdveezForExistingAssetRecord: boolean = false;
			service.processingForm = submitButtonPressed;
			console.log(this.componentName + "onSubmit invoked... this.form.value = %O", service.form.value);
			//var submittedValues = this.form.value;
			console.log(this.componentName + "this.form.getRawValue() = %O", service.form.getRawValue());
			var payLoad = {};

			console.log(this.componentName + "this.form.controls = %O", service.form.controls);
			for (const field in service.form.controls) {
				// 'field' is a string

				const control: any = service.form.get(field); // 'control' is a FormControl
				console.log(this.componentName + "control = %O", control);
				if (typeof control.value == "object" && control.value != null && field == "Id") {
					payLoad[field] = control.value["Id"];
				} else {
					service.questions.forEach((question: any) => {
						if (field == question.key) {
							switch (question.controlType) {
								case "date":
									payLoad[field] = moment.isDate(control.value) ? service.utilityService.DateTimeInMilliseconds(control.value) : null;
									break;
								case "datetime":
									payLoad[field] = moment.isDate(control.value) ? service.utilityService.DateTimeInMilliseconds(control.value) : null;
									break;
								case "number":
									payLoad[field] = isNaN(control.value) ? null : +control.value;
									break;
								case "slider":
								case "slider-yes-no":
								case "true-false-button":
									//-- these are all boolean fields in SQL Server. Save a blank, text 0, numeric 0, or boolean false value as numeric 0 (bit=0 is false in SQL), else numeric 1 (bit=1 is true in SQL). --Kirk T. Sherer, January 18, 2023.
									payLoad[field] = control.value == "" || control.value == "0" || control.value == 0 || control.value == false ? 0 : 1;
									break;
								case "textbox":
									if (typeof control.value == "object" && control.value != null) {
										payLoad[field] = control.value["Id"]; //-- it's an ID from a lookup table and the control.value is equal to the entire row for the lookup record. --Kirk T. Sherer, July 1, 2021.
									} else {
										payLoad[field] = control.value == "" ? null : control.value;
									}
									break;
								default:
									payLoad[field] = control.value == "" && (question.value == "" || question.value == null) ? null : (question.value != null ? question.value : control.value);
									break;
							}
						}
					});
				}

				//-- If we're dealing with an Asset add/edit situation, there should be an AssetTypeId defined. If the Id number of the Asset Type is in the 'gseAssetTypeIds' list, then
				//-- check the Adveez webAPI to determine if we've added it yet or not.  If not, go ahead and add it to their side and retrieve the gse_id that they assigned to the new
				//-- asset.  If they have it already, then just retrieve the gse_id returned from the asset query on their side. --Kirk T. Sherer, December 19, 2022.
				if (field == "AssetTypeId") {
					if (service.gseAssetTypeIds.contains(payLoad["AssetTypeId"]) && service.entityName == "Asset") {
						checkAdveezForExistingAssetRecord = true;
					}
				}

			}
			console.log(this.componentName + "payload = %O", payLoad);

			// var listOfFields = this.form.getRawValue();
			// listOfFields.forEach((field:any) => {
			// 	console.log(this.componentName + "field = %O", field);
			// 	// if (typeof field == 'object') {
			// 	// 		//--if we made it here with an entire object, then get the Id value of the object since it came from a selector. --Kirk T. Sherer, May 25, 2021.
			// 	// 		console.log(this.componentName + "value = %O", value);
			// 	// 		var idFieldValue = value['Id'];
			// 	// }
			// });

			if (checkAdveezForExistingAssetRecord) {
				try {
					var assetModelId = null;
					var assetEnergyTypeId = null;

					if (payLoad["AssetModelId"] != null) {
						assetModelId = payLoad["AssetModelId"]
						assetEnergyTypeId = service.dataService.cache.assetModelsObject[assetModelId].AssetEnergyTypeId;
					}

					payLoad["AssetEnergyTypeId"] = assetEnergyTypeId != null ? assetEnergyTypeId : 2; //-- defaulting to 2 = diesel if there is no energy type ID defined yet.

					this.dataService.checkAdveezForExistingAssetRecord(payLoad["AssetName"], payLoad["ModemNumber"]).then((data:any) => {
						console.log(this.componentName + "data returned from Adveez = %O", data);

						payLoad["AdveezGSEId"] = data != null ? data.gse_id : null;
						payLoad["ModemNumber"] = data != null ? data.asset_uid : payLoad["ModemNumber"];
						if (data?.asset_uid != null) {
							this.form.get("ModemNumber").setValue(payLoad["ModemNumber"]);
						}

						this.dataService.addOrUpdateAdveezRecord(payLoad["AssetName"], payLoad["AdveezGSEId"], payLoad["AssetEnergyTypeId"], payLoad["ModemNumber"], payLoad["PreferredName"]).then((data:any) => {
							console.log(this.componentName + "data returned from adding or updating Adveez for this asset = %O", data);

							if (data == "Internal Server Error") {
								this.utilityService.showToastMessageShared({
									type: "error",
									title: "Adveez webAPI Error",
									message: "'Internal Server Error'. Adveez' LocalEez will not be updated."
								})
							} 
							else {
								payLoad["AdveezGSEId"] = data != null ? data.gse_id : (payLoad["AdveezGSEId"] != null ? payLoad["AdveezGSEId"] : null);
							}
							

							console.log(this.componentName + "payLoad = %O", payLoad);
							//-- need to wait until we hear from Adveez to determine if this name exists or not on their side before submitting the values back to the calling page.
							service.submitValues(payLoad);
						});

					 });
				}
				catch (e) {
					service.submitValues(payLoad); //-- go ahead and submit values without the Adveez GSE Id value.
				}
			}
			else {
				service.submitValues(payLoad);
			}
		}
	}

	submitValues(payLoad:any) {
		this.payLoad = JSON.stringify(payLoad);
		//console.log(this.componentName + "this.payLoad = %O", this.payLoad);
		this.submittedValues.emit(this.payLoad);
		if (this.options.signalRGroupToNotify != null) {
			this.signalR.notifyOtherClientsInGroup(this.options.signalRGroupToNotify, "Issue Update", payLoad);
		}

		//console.log(this.componentName + "this.submittedValues.emit(this.payLoad) = %O", this.payLoad);
		if (this.options.clearFieldsAfterSubmit == true) {
			this.form.reset(); //-- reset the form.  Otherwise if this is false, the user wants to retain the values entered on the form. --Kirk T. Sherer, October 14, 2020.
			this.processingForm = false; //-- have to reset this so that the submit button is ready to use again. --Kirk T. Sherer, July 22, 2021.
		}
	}

	calculateSlopeAndIntercept() {
		var minimumInput = this.form.get("MinimumInput").value;
		var maximumInput = this.form.get("MaximumInput").value;
		var minimumOutput = this.form.get("MinimumOutput").value;
		var maximumOutput = this.form.get("MaximumOutput").value;
		if (minimumInput != null &&	maximumInput != null && minimumOutput != null && maximumOutput != null) {
			this.form.get("Intercept").setValue(minimumOutput);
			this.form.get("Slope").setValue((maximumOutput - minimumOutput) / (maximumInput - minimumInput));
		}
	}
}
