<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading WheelChair Lift Overview Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="!isDataLoading && this.assetsWithTags.length > 0"
	class="card card-tasks"
	id="card-whole"
>
	<div
		(resized)="onResized($event)"
		[style.height.%]="100"
		style="display: inline-block"
		class="card-body"
		id="card-body-id"
	>

	<lib-kendo-grid-parent
		[gridData]="assetDataArray"
		[gridSettings]="gridSettings"
		[widgetObject]="widgetObject"
		(callSave)="saveGridSettings()"
		(openWCLSummaryEmitter)="openWCLSummary($event)"
		[style.height.%]="100"
		filterable="menu"
		[sortable]="true"
		[reorderable]="true"
		[resizable]="true"
		#tagDataGrid

	>
	</lib-kendo-grid-parent>

	</div>
</div>
<div
	*ngIf="this.assetsWithTags.length == 0"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Site doesn't have WCL Units"
	></lib-centered-message>
</div>
