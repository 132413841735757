import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import * as moment from "moment";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";

@Component({
	selector: "lib-potd",
	templateUrl: "./potd.component.html",
	styleUrls: ["./potd.component.scss"],
})
export class PotdComponent implements OnInit {
	@Output() submittedValues = new EventEmitter<string>();

	public fields: Array<any>;
	public formOptions: any;
	public webAPIResponse: boolean = false;
	private currentDate: any;
	public passwordValue: string;
	public jetwayAccessValue: string;
	public form: FormGroup;
	public processingForm: boolean = false;
	payLoad = "";
	public componentName: string = "potd (shared-lib): ";

	constructor(
		public utilityService: UtilityService,
		private http: HttpClient,
		private dataService: DataService
	) {
		if (Global.Data.dataServerIsLocal) {
		}
	}

	ngOnInit() {
		Global.User.DebugMode &&
			console.log(this.componentName + "intializing POTD...");

		this.currentDate = moment(new Date()).format("MM/DD/YYYY");
		console.log("this.currentDate = " + this.currentDate);
		this.formOptions = {
			submitButtonText: "Generate Password",
			functionToExecute: this.submitParametersAndExecute,
		};

		this.form = new FormGroup({
			Date: new FormControl(),
			Password: new FormControl({ value: "", disabled: true }),
			JetwayAccess: new FormControl({ value: "", disabled: true }),
		});

		this.form.controls["Date"].setValue(new Date(this.currentDate));
	}

	submitParametersAndExecute(submittedValues: string) {
		console.log("POTD: submittedValues = %O", submittedValues);
		// var parameterListAsString = values.replace('{','').replace('}','').replace(/:/g,'=').replace(/\"/g,'');
		// console.log("reports.component.ts: parameterListAsString = " + parameterListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		console.log("submittedValuesObject = %O", submittedValuesObject);
		var dateSelected = submittedValuesObject.Date;

		Global.User.DebugMode &&
			console.log("dataServerIsLocal = " + Global.Data.dataServerIsLocal);

		var time0 = performance.now();
		this.httpPost(dateSelected).then((dataSentBackFromWebApi: any) => {
			var time = performance.now() - time0;
			console.log(
				"time for webAPI to respond with result: " +
					time +
					" milliseconds."
			);
			console.log(
				"data sent back from webAPI: " + dataSentBackFromWebApi
			);
			this.webAPIResponse = true;
			var potd = JSON.parse(dataSentBackFromWebApi);
			console.log("potd: %O", potd);
			this.webAPIResponse = true;
			this.form.controls["Password"].setValue(potd.Password);
			this.form.controls["JetwayAccess"].setValue(potd.JetwayAccess);

			this.passwordValue = potd.Password;
			this.jetwayAccessValue = potd.JetwayAccess;
			this.dataService.LogEvent(
				13,
				"Viewed Password of the Day from the webAPI."
			);
		});
	}

	httpPost = function (datetimeString: string) {
		let httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: Global.User.currentUser.ODataAccessToken,
			}),
		};

		Global.User.DebugMode &&
			console.log("dataServerIsLocal = " + Global.Data.dataServerIsLocal);

		const http$ = this.http
			.post(this.dataService.potdUrl, datetimeString, httpOptions)
			.toPromise();
		return http$;
	};

	clearFormWhenChangingDate() {
		this.webAPIResponse = false;
		this.form.reset(); //-- reset the form.  Otherwise if this is false, the user wants to retain the values entered on the form. --Kirk T. Sherer, October 14, 2020.
		this.processingForm = false; //-- have to reset this so that the submit button is ready to use again. --Kirk T. Sherer, July 22, 2021.
	}

	onSubmit(submitButtonPressed?: boolean) {
		Global.User.DebugMode &&
			console.log("submitButtonPressed = " + submitButtonPressed);
		if (submitButtonPressed) {
			this.processingForm = submitButtonPressed;
			Global.User.DebugMode &&
				console.log(
					"onSubmit invoked... this.form.value = %O",
					this.form.value
				);
			//var submittedValues = this.form.value;
			Global.User.DebugMode &&
				console.log(
					"this.form.getRawValue() = %O",
					this.form.getRawValue()
				);
			var payLoad = {};
			Global.User.DebugMode &&
				console.log("this.form.controls = %O", this.form.controls);
			for (const field in this.form.controls) {
				// 'field' is a string

				const control: any = this.form.get(field); // 'control' is a FormControl
				Global.User.DebugMode && console.log("control = %O", control);
				if (field == "Date") {
					payLoad[field] = moment.isDate(control.value)
						? this.utilityService.DateTimeInMilliseconds(
								control.value
						  )
						: null;
				}
			}
			console.log("payload = %O", payLoad);
			this.payLoad = JSON.stringify(payLoad);
			Global.User.DebugMode &&
				console.log("this.payLoad = %O", this.payLoad);
			this.submittedValues.emit(this.payLoad);
			this.submitParametersAndExecute(this.payLoad);
		}
	}
}
