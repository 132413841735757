import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadListMobileComponent } from './file-upload-list-mobile.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipeModule } from 'projects/shared-lib/src/lib/_helpers/pipes/pipe.module';
import { IonicModule } from '@ionic/angular';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	declarations: [FileUploadListMobileComponent],
	imports: [
		CommonModule,
		DragDropModule,
		PipeModule,
		IonicModule,
		MatProgressBarModule,
	],
	exports: [FileUploadListMobileComponent],
})
export class FileUploadListMobileModule {}
