<div class="content" style="padding: 0 0 0 0 !important">
	<!-- <div id="xxx" class="text-center">
    viewReportsList: {{viewReportsList}}<br />
    addingNewReport: {{addingNewReport}}<br />
    newStoredProceduresExist: {{newStoredProceduresExist}}<br />
    editingReport: {{editingReport}}<br />
    loadingEditForm: {{loadingEditForm}}<br />
    executingReport: {{executingReport}}<br />
    isLoadingParameters: {{isLoadingParameters}}<br />
    displayReport: {{displayReport}}<br />
    isLoading: {{isLoading}}<br />
  </div> -->

	<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading Reports...'" [vehicle]="'plane'"></lib-loading-spinner>
	<!-- Report List -->
	<div *ngIf="viewReportsList" class="content">
		<div class="row">
			<div class="col-md-12 mb-5">
				<div class="card">
					<div class="card-header">
						<div class="col-md-4 text-left">
							<!-- <mat-form-field>
                <input
                  class="search-filter"
                  matInput
                  (keyup)="applyFilter($event.target.value)"
                />
                <mat-placeholder class="placeholder"
                  >Enter a string to search</mat-placeholder
                >
              </mat-form-field> -->
						</div>
						<h3 class="card-title col-md-4 text-center">Reports</h3>
						<div class="col-md-4 text-right">
							<button class="btn btn-primary btn-round btn-icon mr-1 pull-right" tooltip="New Report" (click)="edit()">
								<i class="tim-icons icon-simple-add"> </i>
							</button>
						</div>
					</div>
					<div class="card-body universal-reporting">
						<div class="table-responsive">
							<kendo-grid [data]="gridViewReports" [filter]="filterT" [style.height.%]="100" [skip]="0" [navigable]="true" filterable="menu" [sort]="reportsState.sort"  [sortable]="true" [reorderable]="true" [resizable]="true" (sortChange)="sortChangeReports($event)" (pageChange)="pageChangeReports($event)" (filterChange)="filterChangeReports($event)" #grid>
								<ng-template kendoGridColumnMenuTemplate let-service="service">
									<kendo-grid-columnmenu-sort [service]="service"> </kendo-grid-columnmenu-sort>
									<kendo-grid-columnmenu-lock [service]="service"> </kendo-grid-columnmenu-lock>
									<kendo-grid-columnmenu-chooser [service]="service"> </kendo-grid-columnmenu-chooser>
									<kendo-grid-columnmenu-filter [service]="service"> </kendo-grid-columnmenu-filter>
									<kendo-grid-columnmenu-item icon="k-icon k-i-filter-clear" text="Clear Filter" (itemClick)="filterChange({ logic: 'and', filters: [] }); service.close()"> </kendo-grid-columnmenu-item>
								</ng-template>
								<kendo-grid-column field="ReportName" title="ReportName" [filterable]="true"></kendo-grid-column>
								<kendo-grid-column field="Category" title="Category">
									<ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
										<lib-multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></lib-multicheck-filter>
									</ng-template>
								</kendo-grid-column>
								<kendo-grid-column field="CreatedBy" title="CreatedBy">
									<ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
										<lib-multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></lib-multicheck-filter>
									</ng-template>
									></kendo-grid-column
								>
								<kendo-grid-column field="CreationDate" title="CreationDate" [filterable]="true"></kendo-grid-column>
								<kendo-grid-column field="LastModifiedBy" title="LastModifiedBy" [filterable]="true"></kendo-grid-column>
								<kendo-grid-column field="LastModifiedDate" title="LastModifiedDate" [filterable]="true"></kendo-grid-column>
								<kendo-grid-column field="Action" title="Action" [filterable]="false">
									<ng-template kendoGridCellTemplate let-report>
										<div style="white-space: nowrap">
											<button class="btn btn-info btn-link btn-sm" tooltip="Execute" type="button" (click)="execute(report)">
												<i class="tim-icons icon-triangle-right-17"></i>
											</button>
											<button class="btn btn-info btn-link btn-sm" tooltip="Edit" type="button" (click)="edit(report)">
												<i class="tim-icons icon-pencil"></i>
											</button>
											<button class="btn btn-danger btn-link btn-sm" tooltip="Delete" type="button" (click)="delete(report)">
												<i class="tim-icons icon-simple-remove"></i>
											</button>
										</div>
									</ng-template>
								</kendo-grid-column>
							</kendo-grid>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Display Report -->
	<div class="col-md-12" *ngIf="displayReport && !isLoading" class="content">
		<div class="card">
			<div class="card-header">
				<div class="col-md-4 text-left">
					<!-- <mat-form-field>
            <input
              class="search-filter"
              matInput
              (keyup)="applyFilter($event.target.value)"
            />
            <mat-placeholder class="placeholder"
              >Enter a string to search</mat-placeholder
            >
          </mat-form-field> -->
				</div>
				<h4 class="card-title col-md-4 text-center">
					<strong>{{ selectedReport.ReportName }}</strong>
				</h4>
				<div class="col-md-4 text-right">
					<button class="btn btn-icon btn-simple btn-twitter">
						<i class="tim-icons icon-minimal-left" (click)="reportList()"> </i>
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="table-responsive table-container">
					<kendo-grid [data]="gridView" [columnMenu]="true" [skip]="0" [navigable]="true" [filter]="state.filter" [style.height.%]="100" [filterable]="true" [sort]="state.sort" [sortable]="true" [reorderable]="true" [resizable]="true" (filterChange)="filterChange($event)" (pageChange)="pageChange($event)" (sortChange)="sortChange($event)" #grid>
						<ng-template kendoGridColumnMenuTemplate let-service="service">
							<kendo-grid-columnmenu-sort [service]="service"> </kendo-grid-columnmenu-sort>
							<kendo-grid-columnmenu-lock [service]="service"> </kendo-grid-columnmenu-lock>
							<kendo-grid-columnmenu-chooser [service]="service"> </kendo-grid-columnmenu-chooser>
							<kendo-grid-columnmenu-filter [service]="service"> </kendo-grid-columnmenu-filter>
							<kendo-grid-columnmenu-item icon="k-icon k-i-filter-clear" text="Clear Filter" (itemClick)="filterChange({ logic: 'and', filters: [] }); service.close()"> </kendo-grid-columnmenu-item>
						</ng-template>
						<ng-template kendoGridToolbarTemplate>
							<button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
						</ng-template>
						<kendo-grid-column *ngFor="let header of reportHeader" field="{{ header.name }}" title="{{ header.name }}"></kendo-grid-column>
						<kendo-grid-excel fileName="{{ selectedReport.ReportName }}.xlsx"></kendo-grid-excel>
					</kendo-grid>
				</div>
			</div>
		</div>
	</div>
	<!-- Execute Report Form -->
	<div class="col-md-12" *ngIf="executingReport && !isLoadingParameters" class="content">
		<div class="card form-horizontal">
			<div class="card-header">
				<div class="col-md-6 text-left">
					<h4 class="card-title">
						Report Parameters: &nbsp;&nbsp;<strong>{{ selectedReport.ReportName }}</strong>
					</h4>
					<h5 class="card-title">Please enter the parameter values to execute this report.</h5>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-icon btn-simple btn-twitter">
						<i class="tim-icons icon-minimal-left" (click)="reportList()"> </i>
					</button>
				</div>
			</div>
			<div class="card-body">
				<lib-dynamic-form [questions]="parameterQuestions" [options]="executeFormOptions" (submittedValues)="submitParametersAndExecute($event)"></lib-dynamic-form>
			</div>
		</div>
	</div>
	<!-- Edit Form -->
	<div class="col-md-12" *ngIf="editingReport && !loadingEditForm" class="content">
		<div class="card">
			<div class="card-header">
				<div class="col-md-6 text-left">
					<h4 class="card-title">
						{{ editHeader
						}}<span *ngIf="selectedReport != null"
							>&nbsp;&nbsp;<strong>{{ selectedReport.ReportName }}</strong></span
						>
					</h4>
					<h5 class="card-title">{{ editSubHeader }}</h5>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-icon btn-simple btn-twitter">
						<i class="tim-icons icon-minimal-left" (click)="reportList()"> </i>
					</button>
				</div>
			</div>
			<div class="card-body">
				<div *ngIf="(addingNewReport && newStoredProceduresExist) || !addingNewReport">
					<lib-dynamic-form [questions]="editQuestions" [options]="editFormOptions" (submittedValues)="submitEditedReport($event)"></lib-dynamic-form>
				</div>
				<div *ngIf="addingNewReport && !newStoredProceduresExist">
					<div class="row">
						<h3>No new stored procedures have been defined. Please go to SQL Server to create a new URPT report.</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
