import { Component, OnInit } from '@angular/core';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { IGlobal } from 'projects/shared-lib/src/lib/_models/global.model';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { filter } from 'rxjs';
import { UtilityService } from '../../services/utility.service';


@Component({
  selector: 'lib-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.scss']
})
export class LogbookComponent implements OnInit {
	public global: IGlobal = Global;
	public gridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: []
			}
		},
		columnsConfig: [

			{
			  field: "Id",
			  title: "Id",
			  _width: 75,
			},
			{
				field: "CreatorName",
				title: "Creator",
				_width: 100,
			},
			{
				field: "AssetName",
				title: "Asset",
				_width: 150,
			},
			{
			  field: "Description",
			  title: "Description",
			  _width: 300,
			},
			{
				field: "LastModifiedDate",
				title: "Last Modified Date",
				_width: 75
			},
			{
				field: "EditNote",
				title: "Actions",
				_width: 100,
			},

		  ],
	}

	public logsArray: any;
	signalRSubscription: any;


  constructor(
	public dataService: DataService,
	public signalRCore: SignalRCoreService,
	public utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
	this.utilityService.updateCurrentMenuItem('Logbook');

	this.initialize();
	this.signalRSubscription = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) =>
									msg.code == "dataService.cache.entityLogs Added" ||
									msg.code == "dataService.cache.entityLogs Modified" ||
									msg.code == "dataService.cache.entityLogs Deleted"
		)).subscribe(
		(msg: any) => {
			this.initialize();
		});
  }

  initialize() {
	Global.User.DebugMode && console.log(this.dataService.cache.entityLogs);
	this.logsArray = this.dataService.cache.entityLogs
		.where((log: any) => {
			var logOrganizationId = this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == log.CreatorUserId })?.OrganizationId;
			return (Global.User.currentUser.Organization.Id == logOrganizationId && (log.IsPrivate == null || log.IsPrivate == false))
					|| (log.IsPrivate == true && log.CreatorUserId == Global.User.currentUser.Id)
		})
		.select((visiblelog: any) => {
			var EntityLogTypeName = this.dataService.cache.entityLogTypesObject[visiblelog.EntityLogTypeId]?.Name;
			var assetName;

			if(EntityLogTypeName == "Asset") {

				if(this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "PBB" ||
					this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "GPU" ||
					this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "PCA" ||
					this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "AHU"
				) {
					assetName = this.dataService.cache.assetsObject[visiblelog.EntityId].Site.Name + " " +
									this.dataService.cache.assetsObject[visiblelog.EntityId].ParentSystem.Name + " " +
									this.dataService.cache.assetsObject[visiblelog.EntityId].Name;
				}
				else {
					assetName = this.dataService.cache.assetsObject[visiblelog.EntityId].Name;
				}
			}


			var output = {
				Id: visiblelog.Id,
				Description: visiblelog.Description,
				CreationDate: visiblelog.CreationDate,
				CreatorUserId: visiblelog.CreatorUserId,
				CreatorName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.CreatorUserId })?.FullName,
				LastModifiedDate: visiblelog.LastModifiedDate,
				LastModifiedName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.LastModifiedUserId })?.FullName,
				EntityLogTypeName: EntityLogTypeName,
				AssetName: assetName,
				CountOfFilesUploaded: visiblelog.CountOfFilesUploaded,
				CountOfLogComments: visiblelog.CountOfLogComments,
				EntityId: visiblelog.EntityId,
				EntityLogTypeId: visiblelog.EntityLogTypeId,
				IsPrivate: visiblelog.IsPrivate,
				Keywords: visiblelog.Keywords,
			};

			return output;
		}).toArray();


	Global.User.DebugMode && console.log(this.logsArray);
  }

	getCountOfFilesUploadedForEntity(entity: any, entityId: number, entityType: string) {
		var sqlStatement = "API.FileImageManagement 'Count', '" + entityType + "', " + entityId + ", @UserId = " + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var listOfFiles = data;
			entity.CountOfFilesUploaded = listOfFiles.length > 0
					? listOfFiles.first().CountOfFilesUploaded
					: 0;
		});
	}


  ngOnDestroy() {
	Global.User.DebugMode && console.log('ngOnDestroy invoked...');

	if (this.signalRSubscription !== undefined) {
		this.signalRSubscription.unsubscribe();
	}

}

}
