import {
	Component,
	AfterViewInit,
	ChangeDetectorRef,
	ViewEncapsulation,
	EventEmitter,
	Input,
	ViewChild,
	OnDestroy,
	NgZone,
} from '@angular/core';


import { process } from '@progress/kendo-data-query';

import { GridSettings } from '../../_models/grid-settings.interface';
import { RowClassArgs, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import _ from 'lodash';
import { filter } from 'rxjs/operators';
import { SassHelperComponent } from '../../_helpers/sass-helper/sass-helper.component';

import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import * as $ from 'jquery';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

declare var Highcharts: any;

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-site-active-comms-loss',
	templateUrl: './site-active-comms-loss.component.html',
	styleUrls: ['./site-active-comms-loss.component.scss'],
})
export class SiteActiveCommsLossComponent implements AfterViewInit, OnDestroy {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	@ViewChild('alertGrid') alertGrid;
	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	Highcharts: typeof Highcharts = Highcharts;
	Chart: any;
	ChartData = { Alarms: [], Categories: [], TimeStamps: [] };
	alertData: any = [];
	alarmTagIds = [];
	alarmTypeCounts = { Alarms: 0 };
	jbtStandardObsLookup: any;
	siteNameLookUp: any;
	isDataLoading: boolean = true;
	userSiteIds = [];
	isChartDataLoading: boolean;
	chartUpdateIntervalSet: boolean;
	chartTimeRecentlyUpdated: boolean;
	theme: any;
	majorFontSize: any;
	minorFontSize: any;
	bodyTextColor: string;
	signalRSubscription: any;
	updateDataInterval: NodeJS.Timeout;
	updateChartInterval: NodeJS.Timeout;
	commsLossRunTimeUpdateInterval: NodeJS.Timeout;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	pageSize: number;
	userSites: Array<any>;

	alertGridSettings: GridSettings = {
		gridData: this.alertData,
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'Name',
				title: 'Site',
				filterable: true,
				_width: 80,
				minResizableWidth: 60,
			},
			{
				field: 'Gate',
				title: 'Gate',
				filterable: true,
				_width: 80,
				minResizableWidth: 60,
			},
			{
				field: 'Asset',
				title: 'Asset',
				filterable: true,
				_width: 80,
				minResizableWidth: 60,
			},
			{
				field: 'JavascriptDate',
				title: 'Date',
				filterable: false,
				_width: 150,
				minResizableWidth: 125,
			},
			{
				field: 'CommsLossTime',
				title: 'Duration',
				filterable: true,
				_width: 80,
				minResizableWidth: 60,
			},
			{
				field: 'Value',
				title: 'Value',
				filterable: true,
				_width: 100,
			},
		],
	};
	fullDataCacheSubscription: any;
	assetIds: any;
	componentName: string = "site-active-comms-loss: ";
	public guid: string;

	constructor(
		private dataService: DataService,
		private ref: ChangeDetectorRef,
		private signalRCore: SignalRCoreService,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private zone: NgZone
	) {}

	ngAfterViewInit() {
		this.isDataLoading = true;
		this.isChartDataLoading = true;
		this.chartUpdateIntervalSet = false;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
				this.bodyTextColor =
					this.theme === 'dark'
						? 'white'
						: this.sassHelper.readProperty('body-text-color-dark');
				if (this.Chart) {
					this.createChart();
				}
			});

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe(
				(data) => {
					if (
						this.Chart &&
						this.widgetObject.WidgetId == data.item.WidgetId
					) {
						this.Chart.setSize(
							$('.alert-chart').width(),
							$('.alert-chart').height(),
							false
						);

						// Resize the major and minor divs
						this.majorFontSize = this.generateHeight(
							'.major-alert-container',
							2
						);
						this.minorFontSize = this.generateHeight(
							'.minor-alert-container',
							2
						);
					}
				}
			);
		}
	}

	ngOnInit(): void {
		this.guid = this.dataService.guid();
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.updateChartInterval) {
			clearInterval(this.updateChartInterval);
		}
		if (this.commsLossRunTimeUpdateInterval) {
			clearInterval(this.commsLossRunTimeUpdateInterval);
		}
	}

	generateHeight(name, ratio) {
		let returnedHeight = $(name).height();
		let fontSizeCalculationAsNumber = returnedHeight / ratio;
		return fontSizeCalculationAsNumber.toString() + 'px';
	}

	onResized(event) {
		this.pageSize = ((event.newRect.height - 120) / 36) * 3;
		//console.log("PageSize = " + this.pageSize);
	}

	saveGridSettings() {
		this.kendoSettingsService
			.saveGridSettings(
				[
					{
						gridObject: this.tagDataGrid.kendoGridParent,
						gridState: this.alertGridSettings.state,
					},
				],
				this.widgetObject.WidgetId
			)
			.then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
	}

	mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		Global.User.DebugMode && console.log(state);
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
			gridData:
				this.dataService.cache !== undefined
					? process(this.alertData, state)
					: emptyArray,
		};
	}

	initialize() {
		this.theme = Global.Theme;
		this.bodyTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let sites = this.dataService.cache.sites;
		// Get the SItes the user has access to
		sites = sites.filter((site) => {
			return site.Name !== undefined && site.Active === true;
		});
		sites = _.orderBy(sites, 'Name', 'asc');
		if (!Global.User.isAdmin) {
			this.userSites = sites.filter((site) => {
				return Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
					site.Id
				);
			});
		} else {
			this.userSites = sites;
		}
		this.userSiteIds = this.userSites.map((a) => a.Id).toArray();
		//this.createChart();

		// Get the jbtStandardObservationsObject from the cache to look up readable names for the tags
		this.jbtStandardObsLookup =
			this.dataService.cache.jbtStandardObservationsObject;
		// Get the sitesObject from the cache to look up readable names for the sites
		this.siteNameLookUp = this.dataService.cache.sitesObject;
		let siteAssets = this.dataService.cache.assets.filter((t) =>
			this.userSiteIds.includes(t.SiteId)
		);
		let assetIds = siteAssets.map((a) => a.Id.toString()).join();
		this.assetIds = siteAssets.map((a) => a.Id);

		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				assetIds,
				false,
				[4331].toString()
			)
			.subscribe((data) => {
				//console.log(data);
				this.formatTagData(data);
			});

		// Reload saved Kendo Grid Settings
		if (this.widgetObject.WidgetKendoUIJson) {
			let jsonObjectParsed =
				this.kendoSettingsService.parseReturnedSettingsForDates(
					this.widgetObject.WidgetKendoUIJson
				);
			//the first item in the array is the alertgridsettings for the first tab of data for this widget.

			let returnedParsedObject = jsonObjectParsed[0];
			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
			this.alertGridSettings = this.mapGridSettings(
				this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
					this.alertGridSettings,
					returnedParsedObject
				)
			);
		}
		this.widgetObject.isDisplayDataLive = true;
	}

	formatTagData(tags: any) {
		// Get all the critical, alarm, and warning tags
		let allAlertTags = tags.map((t) => ({
			TagId: t.Id,
			TagName: t.Name,
			Gate: t.Asset?.ParentSystem?.Name,
			Asset: t.Asset?.Name,
			JBTStandardObservationId: t.JBTStandardObservationId,
			Name: this.siteNameLookUp[t.SiteId]?.Name,
			Severity: this.getSeverityLevel(t),
			JavascriptDate: new Date(t.DateInMilliseconds),
			Value: t.Value,
			RecentlyUpdated: false,
			CommsLossTime: Math.abs(
				moment
					.utc()
					.diff(moment.utc(new Date(t.DateInMilliseconds)), 'minutes')
			),
		}));
		// console.log(allAlertTags)

		this.alertData = allAlertTags.filter((t) => t.Value == '1');
		this.alertData = _.orderBy(this.alertData, 'JavascriptDate', 'desc');
		//console.log(this.alertData);

		this.alarmTypeCounts.Alarms = this.alertData.reduce(
			(count, tag) => count + (tag.Severity == 'Alarm'),
			0
		);
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.alertData);
		}

		this.isDataLoading = false;
		this.chartTimeRecentlyUpdated = false;
		this.majorFontSize = this.generateHeight('.major-alert-container', 2);
		this.minorFontSize = this.generateHeight('.minor-alert-container', 2);

		this.getChartData();
		this.getSignalRUpdates();

		this.commsLossRunTimeUpdateInterval = setInterval(() => {
			this.alertData.forEach((row) => {
				row.CommsLossTime = Math.abs(
					moment.utc().diff(moment.utc(row.JavascriptDate), 'minutes')
				);
			});
		}, 1000 * 60); // Update the runtime values every minute
	}



	getChartData() {
		// Calculate last hour and 5 minute intervals
		let hourAgo = moment().subtract(1, 'hour');
		hourAgo.minutes(Math.floor(hourAgo.minutes() / 5) * 5);

		for (let i = 0; i < 13; i++) {
			let timeStamp = hourAgo.add(5, 'minutes');

			this.ChartData.Categories.push(timeStamp.format('LT'));
			this.ChartData.TimeStamps.push(new Date(timeStamp.valueOf()));
			this.ChartData.Alarms.push(0);
		}

		// Get Active Alarms for past hour
		let statement = `GetActiveCommsLossForLastHour @LastHourCutoff = '${hourAgo.format(
			'YYYY-MM-DD HH:mm'
		)}'`;

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			console.log(data);
			let apiDataTags = data;

			this.ChartData.TimeStamps.forEach((time, index) => {
				if (index != 12) {
					var tagsInRange = apiDataTags.filter(
						(t) =>
							moment.utc(t.StartDate).isSameOrBefore(time) &&
							(moment.utc(t.EndDate).isSameOrAfter(time) ||
								t.EndDate == null)
					);

					this.ChartData.Alarms[index] = tagsInRange.reduce(
						(count, tag) =>
							count + (tag.EffectiveSeverityLevel == 'Alarm'),
						0
					);
				} // Push the current Alarm Counts
				else {
					this.ChartData.Alarms[index] = this.alarmTypeCounts.Alarms;
				}
			});

			this.isChartDataLoading = false;
			console.log(this.ChartData);
			this.createChart();
		});
	}

	createChart() {
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');

		var chartOptions = {
			chart: {
				type: 'area',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
			},
			loading: {
				labelStyle: {
					color: innerChartTextColor,
				},
				style: {
					backgroundColor:
						this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				},
			},
			animation: false,
			credits: { enabled: false },
			title: {
				text: 'Last Hour Comms Loss Counts',
				style: { fontSize: '1.25em', color: outerChartTextColor },
			},
			xAxis: {
				categories: this.ChartData.Categories,
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
				title: {
					text: '',
				},
			},
			tooltip: {
				shared: true,
				crosshairs: true,
				headerFormat:
					'<span style="font-size: 12px;font-weight: bold">{point.key}</span><br/>',
			},
			plotOptions: {
				area: {
					stacking: 'normal',
					dataLabels: {
						enabled: false,
					},
				},
			},
			legend: { enabled: false },
			series: [
				{
					name: 'Comms Loss',
					data: this.ChartData.Alarms,
					color: 'rgba(219, 45, 45, 0.8)',
				},
			],
		};
		this.zone.runOutsideAngular(() => {
			this.Chart = Highcharts.chart(
				`siteCommsLoss${this.widgetObject.WidgetId}`,
				chartOptions
			);
		});

		if (this.isChartDataLoading) {
			this.Chart.showLoading();
		} else {
			this.Chart.hideLoading();
			this.updateChartInterval = setInterval(() => {
				this.updateChartTimes();
			}, 1000 * 60);
		}
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = _.uniq(this.assetIds.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix)).join(",");
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log("this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid,
										 WidgetName: this.widgetObject.WidgetName,  
										 TagNamePrefix: tagNamePrefixesString.split(",") })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateAlarmGridData(tag);
				});
			});	
	}

	updateAlarmGridData(tagObj: any) {
		let alarmTagIndex = this.alertData.findIndex(
			(t) => t.TagId == tagObj.TagId
		);
		if (alarmTagIndex != -1) {
			let alarmTag = this.alertData[alarmTagIndex];
			if (tagObj.Value == 1) {
				alarmTag.JavascriptDate = tagObj.JavascriptDate;
				alarmTag.RecentlyUpdated = true;

				if (alarmTagIndex != 0) {
					this.alertData.splice(alarmTagIndex, 1);
					this.alertData.unshift(alarmTag);
				}

				// Update Grid
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.alertData);
				}

				// Change recently updated property back to false after 2 seconds
				setTimeout(() => {
					let newIndex = this.alertData.findIndex(
						(t) => t.TagId == tagObj.TagId
					);
					if (newIndex != -1) {
						this.alertData[newIndex].RecentlyUpdated = false;
						this.ref.detectChanges();
					}
				}, 2000);

				//console.log(`Comms Loss: ${alarmTag.Gate} | ${alarmTag.Asset} | "${alarmTag.Name}" was updated.`);
				this.ref.detectChanges();
			} else {
				// remove inactive alarm from list
				this.alertData.splice(alarmTagIndex, 1);
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.alertData);
				}

				// Update Counts
				this.updateCountsAndChart(alarmTag.Severity, -1);

				//console.log(`Comms Loss: ${alarmTag.Gate} | ${alarmTag.Asset} | "${alarmTag.Name}" was removed.`);
				this.ref.detectChanges();
			}
		} else {
			if (tagObj.Value == '1') {
				let newTag = {
					TagId: tagObj.TagId,
					TagName: tagObj.Name,
					Gate: tagObj.Asset.ParentSystem.Name,
					Asset: tagObj.Asset.Name,
					JBTStandardObservationId: tagObj.JBTStandardObservationId,
					Name: this.siteNameLookUp[tagObj.SiteId]?.Name,
					Severity: this.getSeverityLevel(tagObj),
					JavascriptDate: new Date(tagObj.JavascriptDate),
					Value: tagObj.Value,
					RecentlyUpdated: true,
					CommsLossTime: Math.abs(
						moment
							.utc()
							.diff(
								moment.utc(new Date(tagObj.JavascriptDate)),
								'minutes'
							)
					),
				};

				// Update Kendo Grid
				this.alertData.unshift(newTag);
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.alertData);
				}

				// Update Counts
				this.updateCountsAndChart(newTag.Severity, 1);

				// Change recently updated property back to false after 2 seconds
				setTimeout(() => {
					let newIndex = this.alertData.findIndex(
						(t) => t.TagId == newTag.TagId
					);
					if (newIndex != -1) {
						this.alertData[newIndex].RecentlyUpdated = false;
						this.ref.detectChanges();
					}
				}, 2000);

				//console.log(`New Comms Loss: ${newTag.Gate} | ${newTag.Asset} | "${tagObj.JBTStandardObservation.Name}" was started.`);
			}
		}
	}

	updateCountsAndChart(alarmType: string, opCode: number) {
		switch (alarmType) {
			case 'Alarm':
				this.alarmTypeCounts.Alarms += opCode;
				if (!this.isChartDataLoading) {
					this.Chart.series[0].data[
						this.ChartData.Alarms.length - 1
					].update(this.alarmTypeCounts.Alarms);
				}
				break;
		}
	}

	updateChartTimes() {
		if (moment().minutes() % 5 == 0 && !this.chartTimeRecentlyUpdated) {
			// Add current values and generate new timestamp for next 5 minutes
			let timeStamp = moment(
				this.ChartData.TimeStamps[this.ChartData.TimeStamps.length - 1]
			).add(5, 'minutes');

			this.ChartData.TimeStamps.push(new Date(timeStamp.valueOf()));
			this.ChartData.Categories.push(timeStamp.format('LT'));

			this.Chart.series[0].addPoint(
				this.alarmTypeCounts.Alarms,
				true,
				true
			);

			this.chartTimeRecentlyUpdated = true;
			console.log('Chart Times Updated');

			setTimeout(() => {
				this.chartTimeRecentlyUpdated = false;
			}, 1000 * 60 * 3);
		}
	}

	getSeverityLevel(tagObj: any): string {
		let tagSeverity =
			tagObj.IsCritical == true
				? 'Critical'
				: tagObj.IsAlarm == true
				? 'Alarm'
				: tagObj.IsWarning == true
				? 'Warning'
				: '';

		if (tagSeverity == '') {
			let severityLvl =
				this.jbtStandardObsLookup[tagObj.JBTStandardObservationId]
					?.JBTStandardObservationSeverityLevelId;
			tagSeverity =
				severityLvl == 1
					? 'Critical'
					: severityLvl == 2
					? 'Warning'
					: severityLvl == 3
					? 'Alarm'
					: 'Informational';
		}
		return tagSeverity;
	}
}
