import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetStateViewComponent } from './asset-state-view.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ChartsModule, SparklineModule } from '@progress/kendo-angular-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [AssetStateViewComponent],
	imports: [
		CommonModule,
		ProgressBarModule,
		ChartsModule,
		SparklineModule,
		MatTooltipModule,
		ClipboardModule,
		NgbModule,
		KendoGridParentModule
	],
	exports: [AssetStateViewComponent],
})
export class AssetStateViewModule { }
