import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from './dynamic-form-component/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form-question-component/dynamic-form-question.component';
import { CellPhoneCarrierSelectorComponent } from './cell-phone-carrier-selector/cell-phone-carrier-selector.component';
import { StateSelectorComponent } from './state-selector-component/state-selector.component';
import { GenericSelectorComponent } from './generic-selector-component/generic-selector.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadFieldComponent } from './file-upload-field-component/file-upload-field.component';
import { DropzoneModule } from '../../_directives/dropzone/dropzone.module';
import { NgxTextDiffModule } from 'ngx-text-diff';
import { EditorModule } from '@progress/kendo-angular-editor';

@NgModule({
	declarations: [
		DynamicFormComponent,
		DynamicFormQuestionComponent,
		CellPhoneCarrierSelectorComponent,
		StateSelectorComponent,
		GenericSelectorComponent,
		FileUploadFieldComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatInputModule,
		MatFormFieldModule,
		MatOptionModule,
		DateInputsModule,
		LabelModule,
		ButtonsModule,
		MatProgressBarModule,
		PipeModule,
		DragDropModule,
		DropzoneModule,
		NgxTextDiffModule,
		EditorModule
	],
	exports: [DynamicFormComponent],
})
export class DynamicFormModule {}
