import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaBlowerComponent } from './pca-blower.component';



@NgModule({
  declarations: [
    PcaBlowerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [PcaBlowerComponent]
})
export class PcaBlowerModule { }
