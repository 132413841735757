import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardEditComponent } from './dashboard-edit.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { MatDialogModule } from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@NgModule({
	declarations: [DashboardEditComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		MatTooltipModule,
		MatButtonModule,
		ReactiveFormsModule,
		FormsModule,
		DynamicFormModule,
		MatDialogModule,
		MatStepperModule,
		MatButtonToggleModule,
	],
	exports: [DashboardEditComponent],
})
export class DashboardEditModule {}
