import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import _ from 'lodash';
import swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import { SelectorService } from '../../services/selector.service';
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import * as moment from "moment";
import { SignalRCoreService } from 'projects/shared-lib/src/lib/services/signalr-core.service';
import { formatPercent } from '@angular/common';
import { HeaderRowOutlet } from '@angular/cdk/table';

@Component({
	selector: 'lib-alert-management--popup',
	templateUrl: './alert-management-popup.component.html',
	styleUrls: ['./alert-management-popup.component.scss'],
})
export class AlertManagementPopupComponent implements OnInit {

	private swalWithBootstrapButtons: any;

	widgetObject: any;
	alertLevel: any;
	userAlerts = [];

	public userAlert: any;
	alarmUserAlert: any
	criticalUserAlert: any;
	alertUserAlert: any;

	public alertObject: any = {
		siteId: 0,
		siteName: '',
		gateSystemId: 0,
		gateSystemName: '',
		category: '',
		Email: 0,
		days: [],
		hours: [],
		alarmType: [],
		alarmTagType: '', // tag or stdObs
		groupingId: 0,		// aka JbtStandardObservationSiteId
	};

	public messages = [];

	alertCategories: Array<any>;
	alertCriticalCategory: any;
	alertCategory: any;


	public dataCollections: any = {
		daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		hoursOfDay: _.range(0, 24),
		hoursOfDay00to11: _.range(0, 12).filter((digit) => {
			return digit < 12;
		}),
		hoursOfDay12to23: _.range(12, 24).filter((digit) => {
			return digit < 24;
		}),
		category: ['criticalSite', 'allPBBCriticalSite', 'allPCACriticalSite', 'allGPUCriticalSite', 'criticalGateSystem', 'criticalAsset',
					'site', 'allPBBSite', 'allPCASite',  'allGPUSite', 'gateSystem',  'asset',
					'tag', 'JbtStandardObservation', 'allGSECriticalSite', 'allGSESite', 'CriticalGSEAssetType', 'GSEAssetType', 'CriticalGSEAssetModel', 'GSEAssetModel']
	};

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public inputObject: any,
		public dataService: DataService,
		private utilityService: UtilityService,
		public dialogRef: MatDialogRef<AlertManagementPopupComponent>,
		private signalRCore: SignalRCoreService,
	) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-success',
			},
			buttonsStyling: false,
		});
	}

	getCompanionCapitalizedCategory(capitalizedCategory) : string {
		var returnValue;

		switch(capitalizedCategory) {
			case 'AllGSECriticalSiteId':
				returnValue = 'AllGSESiteId';
				break;
			case 'AllGSESiteId':
				returnValue = 'AllGSECriticalSiteId';
				break;

			case 'AllPBBCriticalSiteId':
				returnValue = 'AllPBBSiteId';
				break;
			case 'AllPBBSiteId':
				returnValue = 'AllPBBCriticalSiteId';
				break;

			case 'AllPCACriticalSiteId':
				returnValue = 'AllPCASiteId';
				break;
			case 'AllPCASiteId':
				returnValue = 'AllPCACriticalSiteId';
				break;

			case 'AllGPUCriticalSiteId':
				returnValue = 'AllGPUSiteId';
				break;
			case 'AllGPUSiteId':
				returnValue = 'AllGPUCriticalSiteId';
				break;

			default:
				returnValue = capitalizedCategory.startsWith('Critical') ? capitalizedCategory.slice(8) : 'Critical' + capitalizedCategory;

		}

		return returnValue;
	}

	setUserAlertBasedOnContext() {
		if(this.alertLevel == "site") {
			this.setUserAlertBasedOnContextAtTheSiteLevel();
		}
		else if(this.alertLevel == "fleet") {
			this.setUserAlertBasedOnContextAtTheFleetLevel();
		}
		else if(this.alertLevel == "gate") {
			this.setUserAlertBasedOnContextAtTheGateLevel();
		}
		else if(this.alertLevel == "asset") {
			this.setUserAlertBasedOnContextAtTheAssetLevel();
		}
		else if(this.alertLevel == "tag") {
			this.setUserAlertBasedOnContextAtTheTagLevel();
		}
		else {
			this.setUserAlertBasedOnContextForTheEtu();
		}
	}


	setUserAlertBasedOnContextForTheEtu() {
		let capitalizedCategory = this.inputObject.dataItem.capitalizedCategory + "Id";
		this.alertObject.groupingId = this.inputObject.dataItem.etu.JbtStandardObservationSiteId;

		if(this.inputObject.dataItem.alertType == 'Critical') {
			this.alertObject.alarmType.push("criticalOnly");
			this.criticalUserAlert = this.inputObject.dataItem.etu;
			this.setAlertFromSelection(this.criticalUserAlert);

			// see if there is an alarm alert
			//let alarmCategory = capitalizedCategory.substring(8);
			let alarmCategory = this.getCompanionCapitalizedCategory(capitalizedCategory);
			let userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				//a.JbtStandardObservationSiteId == this.alertObject.groupingId &&
				a[alarmCategory] == this.inputObject.dataItem.id);

			if(userAlert?.length > 0) {
				this.alertObject.alarmType.push("allAlarms");
				this.alarmUserAlert = userAlert[0];
				this.setAlertFromSelection(this.alarmUserAlert);
			}
		}
		else {
			this.alertObject.alarmType.push("allAlarms");
			this.alarmUserAlert = this.inputObject.dataItem.etu;
			this.setAlertFromSelection(this.alarmUserAlert);

			//check for critical alert
			//let criticalCategory = "Critical" + capitalizedCategory;
			let criticalCategory = this.getCompanionCapitalizedCategory(capitalizedCategory);
			let foundUserAlert = this.dataService.cache.emailTagUsers.filter((a) => {
				let temp = a[criticalCategory];
				return a.UserId == Global.User.currentUser.Id &&
						//a.JbtStandardObservationSiteId == this.alertObject.groupingId &&
						temp == this.inputObject.dataItem.id
			});

			if(foundUserAlert?.length > 0) {
				this.alertObject.alarmType.push("criticalOnly");
				this.criticalUserAlert = foundUserAlert[0];
				this.setAlertFromSelection(this.criticalUserAlert);
			}
		}

	}

	setUserAlertBasedOnContextAtTheAssetLevel() {
		this.alertObject.category = this.inputObject.item.Name;

		// check for critical asset 
		let userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
			a.UserId == Global.User.currentUser.Id &&
			a.CriticalAssetId == this.inputObject.item.Id);

		if(userAlert.length > 0) {
			// set type of alarm to critical
			this.alertObject.alarmType.push("criticalOnly");
			this.criticalUserAlert = userAlert[0];
			this.setAlertFromSelection(this.criticalUserAlert);
		}

		// check cache for existing alarms
		userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
			a.UserId == Global.User.currentUser.Id &&
			a.AssetId == this.inputObject.item.Id);

		if(userAlert.length > 0) {
			// set type to alarm
			this.alertObject.alarmType.push("allAlarms");
			this.alarmUserAlert = userAlert[0];
			this.setAlertFromSelection(this.alarmUserAlert);
		}

		// check cache for existing alerts
		userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
			a.UserId == Global.User.currentUser.Id &&
			a.AlertAssetId == this.inputObject.item.Id);

		if(userAlert.length > 0) {
			// set type to alarm
			this.alertObject.alarmType.push("alerts");
			this.alertUserAlert = userAlert[0];
			this.setAlertFromSelection(this.alertUserAlert);
		}

	}

	setUserAlertBasedOnContextAtTheTagLevel() {
		this.alertObject.category = this.inputObject.item.ShortTagName;

		let userAlert = [];

		if(this.inputObject.item.TagId != null) {
			userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				a.TagId == this.inputObject.item.TagId);
		}

		if(userAlert.length > 0) {
			// set type of alarm to critical
			this.alertObject.alarmTagType = "tag";
			this.userAlert = userAlert[0];
			this.setAlertFromSelection(this.userAlert);
		}
		else {
			// check cache for existing alarms
			if(this.alertObject.fleetId) {
				userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
					a.UserId == Global.User.currentUser.Id &&
					a.GroupThingId == this.alertObject.fleetId &&
					a.JbtStandardObservationId == this.inputObject.item.JBTStandardObservationId);
			}
			else if(this.inputObject.widget.WidgetSiteId) {
				userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
					a.UserId == Global.User.currentUser.Id &&
					a.JbtStandardObservationSiteId == this.inputObject.widget.WidgetSiteId &&
					a.JbtStandardObservationId == this.inputObject.item.JBTStandardObservationId);
			} else {
				userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
					a.UserId == Global.User.currentUser.Id &&
					a.GroupThingId == this.alertObject.fleetId &&
					a.JbtStandardObservationId == this.inputObject.item.JBTStandardObservationId);
			}


			if(userAlert.length > 0) {
				// set type to all
				this.alertObject.alarmTagType = "stdObs";
				this.userAlert = userAlert[0];
				this.setAlertFromSelection(this.userAlert);
			}
		}

	}

	setUserAlertBasedOnContextAtTheGateLevel() {

		let gate = this.dataService.cache.systems.filter((g) =>
			g.SiteId == this.alertObject.siteId &&
			g.Name == this.inputObject.item.gate);

		if(gate.length > 0) {
			this.alertObject.gateSystemId = gate[0].Id;
			this.alertObject.gateSystemName = gate[0].Name;
		}

		// check cache for existing critical
		let userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
			a.UserId == Global.User.currentUser.Id &&
			a.CriticalGateSystemId == this.alertObject.gateSystemId);

		if(userAlert.length > 0) {
			// set type of alarm to critical
			this.alertObject.alarmType.push("criticalOnly");
			this.criticalUserAlert = userAlert[0];
			this.setAlertFromSelection(this.criticalUserAlert);
		}

		// check cache for existing alarms
		userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
			a.UserId == Global.User.currentUser.Id &&
			a.GateSystemId == this.alertObject.gateSystemId);

		if(userAlert.length > 0) {
			// set type to alarm
			this.alertObject.alarmType.push("allAlarms");
			this.alarmUserAlert = userAlert[0];
			this.setAlertFromSelection(this.alarmUserAlert);
		}


	}

	setUserAlertBasedOnContextAtTheSiteLevel() {

		// get the alert for the category
		let userAlert;

		if(this.inputObject.widget.WidgetSiteId) {

			this.alertObject.siteId = this.inputObject.widget.WidgetSiteId;
			this.alertObject.siteName = this.dataService.cache.sitesObject[this.alertObject.siteId].Name;

			// check cache for existing critical
			userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				a.CriticalSiteId == this.alertObject.siteId);

			if(userAlert.length > 0) {
				// set type of alarm to critical
				this.alertObject.alarmType.push("criticalOnly");
				this.criticalUserAlert = userAlert[0];
				this.setAlertFromSelection(this.criticalUserAlert);
			}

			// check cache for existing alarms
			userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				a.SiteId == this.alertObject.siteId);

			if(userAlert.length > 0) {
				// set type to alarm
				this.alertObject.alarmType.push("allAlarms");
				this.alarmUserAlert = userAlert[0];
				this.setAlertFromSelection(this.alarmUserAlert);
			}

		}
		else if(this.inputObject.widget.SiteList) {
			// iterate over site list
			this.inputObject.widget.SiteList.forEach(siteId => {

				// check cache for existing critical alerts
				userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
					a.UserId == Global.User.currentUser.Id &&
					a.CriticalSiteId == siteId);

				if(userAlert.length > 0) {
					// set type of alarm to critical
					this.alertObject.alarmType.push("criticalOnly");
					//this.userAlerts[siteId] = userAlert[0]; DO I NEED TO SAVE A LIST???
					this.criticalUserAlert = userAlert[0];
					this.setAlertFromSelection(this.criticalUserAlert);

					// set message for display
					let siteName = this.dataService.cache.sitesObject[siteId].Name;
					this.messages.push("Critical Only alerts for " + siteName);
				}


				// check cache for existing alerts
				userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
					a.UserId == Global.User.currentUser.Id &&
					a.SiteId == siteId);

				if(userAlert.length > 0) {
					// set type to all
					this.alertObject.alarmType.push("allAlarms");
					//this.userAlerts[siteId] = userAlert[0]; DO I NEED TO SAVE A LIST???
					this.alarmUserAlert = userAlert[0];
					this.setAlertFromSelection(this.alarmUserAlert);

					// set message for display
					let siteName = this.dataService.cache.sitesObject[siteId].Name;
					this.messages.push("Alarms for " + siteName);
				}


			});

		}

	}

	setUserAlertBasedOnContextAtTheFleetLevel() {

		// get the alert for the category
		let userAlert;

		if(this.alertObject.fleetId) {
			// check cache for existing critical alerts
			userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				a.CriticalThingId == this.alertObject.fleetId);

			if(userAlert.length > 0) {
				// set type of alarm to critical
				this.alertObject.alarmType.push("criticalOnly");
				this.criticalUserAlert = userAlert[0];
				this.setAlertFromSelection(this.criticalUserAlert);
			}

			// check cache for existing alerts
			userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				a.ThingId == this.alertObject.fleetId);

			if(userAlert.length > 0) {
				// set type to all
				this.alertObject.alarmType.push("allAlarms");
				this.alarmUserAlert = userAlert[0];
				this.setAlertFromSelection(this.alarmUserAlert);
			}

			// check cache for existing alerts
			userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
				a.UserId == Global.User.currentUser.Id &&
				a.AlertThingId == this.inputObject.item.Id);

			if(userAlert.length > 0) {
				// set type to alarm
				this.alertObject.alarmType.push("alerts");
				this.alertUserAlert = userAlert[0];
				this.setAlertFromSelection(this.alertUserAlert);
			}

		}


	}

	setAlertFromSelection(userAlert) {
		if(userAlert != undefined) {
			// delivery
			this.alertObject.Email = userAlert.Email == 1 ? true : false;
			this.alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;
			this.alertObject.MinutesBetweenNotification = userAlert.MinutesBetweenNotification;

			// set days
			if(userAlert.SendAllDays == 1) {
				this.alertObject.DaysSelected = "allDays";
			}
			else if(userAlert.SendMon == 1 || userAlert.SendTue == 1 || userAlert.SendWed == 1 ||
					userAlert.SendThu == 1 || userAlert.SendFri == 1 || userAlert.SendSat == 1 ||
					userAlert.SendSun == 1) {

				this.alertObject.DaysSelected = "workWeekDays";

				this.alertObject.days['Mon'] = userAlert.SendMon;
				this.alertObject.days['Tue'] = userAlert.SendTue;
				this.alertObject.days['Wed'] = userAlert.SendWed;
				this.alertObject.days['Thu'] = userAlert.SendThu;
				this.alertObject.days['Fri'] = userAlert.SendFri;
				this.alertObject.days['Sat'] = userAlert.SendSat;
				this.alertObject.days['Sun'] = userAlert.SendSun;

			}
			else {
				this.alertObject.DaysSelected = "none";
			}



			// set hours
			if(userAlert.SendAllHours == 1) {
				this.alertObject.HoursSelected = "allHours";
			}
			else {
				this.alertObject.HoursSelected = "workHours";

				// get the stored UTC hours
				window['hour0'] = userAlert.SendHour00;
				window['hour1'] = userAlert.SendHour01;
				window['hour2'] = userAlert.SendHour02;
				window['hour3'] = userAlert.SendHour03;
				window['hour4'] = userAlert.SendHour04;
				window['hour5'] = userAlert.SendHour05;
				window['hour6'] = userAlert.SendHour06;
				window['hour7'] = userAlert.SendHour07;
				window['hour8'] = userAlert.SendHour08;
				window['hour9'] = userAlert.SendHour09;
				window['hour10'] = userAlert.SendHour10;
				window['hour11'] = userAlert.SendHour11;
				window['hour12'] = userAlert.SendHour12;
				window['hour13'] = userAlert.SendHour13;
				window['hour14'] = userAlert.SendHour14;
				window['hour15'] = userAlert.SendHour15;
				window['hour16'] = userAlert.SendHour16;
				window['hour17'] = userAlert.SendHour17;
				window['hour18'] = userAlert.SendHour18;
				window['hour19'] = userAlert.SendHour19;
				window['hour20'] = userAlert.SendHour20;
				window['hour21'] = userAlert.SendHour21;
				window['hour22'] = userAlert.SendHour22;
				window['hour23'] = userAlert.SendHour23;

				// convert from utc to user time for display
				let offset = this.alertObject.UserTimeZoneOffset;
				for (let i = 0; i < 24; i++) {
					let utc = i+offset;
					if( utc > 23 ) {
						utc = utc - 24;
					}
					this.alertObject.hours[i] = window['hour' + utc]
				}
			}
		}
		else {
			this.alertObject.Email = false;
			this.alertObject.TextMessage = false;
			this.alertObject.DaysSelected = "none";
			this.alertObject.HoursSelected = "none";
		}
	}

	initialize() {

		this.setUserAlertBasedOnContext();


		// if(this.inputObject.dataItem.etu != undefined) {
		// 	// no widget object - get etu directly
		// 	if(this.inputObject.dataItem.alertType == 'Critical') {
		// 		this.criticalUserAlert = this.inputObject.dataItem.etu;
		// 	}
		// 	else {
		// 		this.alarmUserAlert = this.inputObject.dataItem.etu;
		// 	}
		// }
		// else {
		// 	this.setUserAlertBasedOnContext();
		// }
	}

	ngOnInit() {

		this.widgetObject = this.inputObject.widget;
		this.alertLevel = this.inputObject.alertLevel;

		this.alertObject.siteId = this.inputObject.widget?.WidgetSiteId;

		if(this.inputObject.widget?.VocationalSettingsJSON != null) {
			var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
			Global.User.DebugMode && console.log("VocationalSettings ", VocationalSettings);
			this.alertObject.fleetId = VocationalSettings.id;

			this.dataService.GetFleets().subscribe((data: any) => {
				if(data.length > 0) {
					let myFleet = data.find((fleet) => {
						return ( fleet.FleetId === this.alertObject.fleetId );
					});
					this.alertObject.fleetName = myFleet?.FleetName;
				}
			});
		}
		else if(this.alertObject.siteId != null) {
			this.alertObject.siteName = this.inputObject.widget.WidgetSiteName;

			this.alertObject.gateSystemId = this.inputObject.widget.WidgetGateSystemId;
			this.alertObject.gateSystemName = this.inputObject.widget.WidgetGateSystemName;

		}
		else if(this.inputObject.widget?.SiteList?.length > 0) {
				// set the names
			let namesList = [];
			this.inputObject.widget.SiteList.forEach(siteId => {
				// append name to display
				namesList.push(this.dataService.cache.sitesObject[siteId].Name);
			});
			this.alertObject.siteName = namesList.join(',');
		}


		this.alertObject.UserTimeZoneOffset = this.utilityService.GetUserTimeZoneOffsetHours();

		if(this.inputObject.dataItem) {
			this.alertObject.category = this.inputObject.dataItem.category;
			this.messages.push(this.inputObject.dataItem.text)
		}
		this.initialize();

	}

	private addNewAlert() : any {
		let jsDate = new Date();
		var userAlert = { UserId: Global.User.currentUser.Id,
							CreatorUserId: Global.User.currentUser.Id,
							CreationDate: moment(jsDate).format("YYYY/MM/DD HH:mm:ss"),
							LastModifiedUserId: Global.User.currentUser.Id,
							UserTimeZoneOffset: this.alertObject.UserTimeZoneOffset };

		this.dataService.cache.emailTagUsers.push(userAlert);

		return userAlert;
	}

	private updateUserAlertBasedOnContextAtTheSiteLevel(alarmType, userAlert) {
		// update the alert for the category

		if(alarmType == "criticalOnly") {
			userAlert.CriticalSiteId = this.alertObject.siteId;
			userAlert.SiteId = null;
		}
		else if(alarmType == "allAlarms") {
			userAlert.CriticalSiteId = null;
			userAlert.SiteId = this.alertObject.siteId;
		}

	}

	private updateUserAlertBasedOnContextAtTheFleetLevel(alarmType, userAlert) {

		if(alarmType == "criticalOnly") {
			// set critical alarms
			userAlert.CriticalThingId = this.alertObject.fleetId;
			userAlert.ThingId = null;
			userAlert.AlertThingId = null;
		}
		else if (alarmType == "allAlarms") {
			// set  alarms
			userAlert.CriticalThingId = null;
			userAlert.AlertThingId = null;
			userAlert.ThingId = this.alertObject.fleetId;
		}
		else if (alarmType == "alerts") {
			// set  alerts
			userAlert.CriticalThingId = null;
			userAlert.ThingId = null;
			userAlert.AlertThingId = this.alertObject.fleetId;
		}

	}

	private updateUserAlertBasedOnContextAtTheGateLevel(alarmType, userAlert) {
		// set the alertNotification flag on the incoming tag
		this.inputObject.item.alertNotification = true;

		// update the alert for the category
		if(alarmType == "criticalOnly") {
			userAlert.CriticalGateSystemId = this.alertObject.gateSystemId;
			userAlert.GateSystemId = null;
		}
		else if(alarmType == "allAlarms") {
			userAlert.CriticalGateSystemId = null;
			userAlert.GateSystemId = this.alertObject.gateSystemId;
		}

	}

	private updateUserAlertBasedOnContextAtTheTagLevel(alarmType, userAlert) {
		// set the alertNotification flag on the incoming tag
		this.inputObject.item.alertNotification = true;

		if(alarmType == "tag") {
			userAlert.TagId = this.inputObject.item.TagId;
			userAlert.JbtStandardObservationId = null;
			userAlert.JbtStandardObservationSiteId = null;
		}
		else if(alarmType == "stdObs") {
			userAlert.JbtStandardObservationId = this.inputObject.item.JBTStandardObservationId;

			// set the grouping id to the site or fleet
			if(this.alertObject.fleetId) {
				userAlert.GroupThingId = this.alertObject.fleetId;
			}
			if(this.inputObject.widget.WidgetSiteId) {
				userAlert.JbtStandardObservationSiteId = this.inputObject.widget.WidgetSiteId;
			} else {

			}

			userAlert.TagId = null;
		}
	}

	private updateUserAlertBasedOnContextAtTheAssetLevel(alarmType, userAlert) {
		// set the alertNotification flag on the incoming tag
		this.inputObject.item.alertNotification = true;

		if(alarmType == "criticalOnly") {
			userAlert.CriticalAssetId = this.inputObject.item.Id;
			userAlert.AssetId = null;
			userAlert.AlertAssetId = null;
		}
		else if(alarmType == "allAlarms") {
			userAlert.AssetId = this.inputObject.item.Id;
			userAlert.CriticalAssetId = null;
			userAlert.AlertAssetId = null;
		}
		else if (alarmType == "alerts") {
			// set  alerts
			userAlert.AlertAssetId = this.inputObject.item.Id;
			userAlert.CriticalThingId = null;
			userAlert.ThingId = null;
		}
	}

	private updateUserAlertBasedOnContextEtu(alarmType, userAlert) {

		let capitalizedCategory = this.inputObject.dataItem.capitalizedCategory + "Id";
		userAlert.JbtStandardObservationSiteId = this.alertObject.groupingId;

		if(alarmType == "criticalOnly") {

			let criticalCategory;
			if (capitalizedCategory.startsWith("Critical")) {
				criticalCategory = capitalizedCategory;
			}
			else {
				criticalCategory = "Critical" + capitalizedCategory;
			}

			userAlert[criticalCategory] = this.inputObject.dataItem.id;
		}
		else if(alarmType == "allAlarms") {
			let alarmCategory;
			if (capitalizedCategory.startsWith("Critical")) {
				// remove critical
				alarmCategory = capitalizedCategory.substring(8);
			}
			else {
				alarmCategory = capitalizedCategory;
			}

			userAlert[alarmCategory] = this.inputObject.dataItem.id;
		}
	}

	private updateUserAlertBasedOnContext(alarmType, userAlert) {
		if(this.alertLevel == "site") {
			this.updateUserAlertBasedOnContextAtTheSiteLevel(alarmType, userAlert);
		}
		else if(this.alertLevel == "fleet") {
			this.updateUserAlertBasedOnContextAtTheFleetLevel(alarmType, userAlert);
		}
		else if(this.alertLevel == "gate") {
			this.updateUserAlertBasedOnContextAtTheGateLevel(alarmType, userAlert);
		}
		else if(this.alertLevel == "asset") {
			this.updateUserAlertBasedOnContextAtTheAssetLevel(alarmType, userAlert);
		}
		else if(this.alertLevel == "tag") {
			this.updateUserAlertBasedOnContextAtTheTagLevel(alarmType, userAlert);
		}
		else {
			this.updateUserAlertBasedOnContextEtu(alarmType, userAlert);
		}
	}

	validate() {
		if(this.alertObject.alarmType == undefined && this.alertObject.alarmTagType == undefined) {
			return false;
		}
		if(this.alertObject.email == 0 && this.alertObject.TextMessage == 0) {
			return false;
		}
		if(this.alertObject.DaysSelected == undefined) {
			return false;
		}
		if(this.alertObject.DaysSelected == undefined) {
			return false;
		}
	}

	public clickHour(hourOfDay) {
		Global.User.DebugMode && console.log('clickHour', hourOfDay);
	}

	public clickDay(dayOfWeek) {
		Global.User.DebugMode && console.log('clickDay', dayOfWeek);
	}

	private deleteEmailTagUser(userAlert) {
		//let statement = "API.AlertManagementDeleteAlarmRecord " + "@Id = " + userAlertId;
		let statement = "API.AlertManagementDeleteAlarmRecord " + "@Id = " + userAlert.Id + ", @UserId = " + userAlert.UserId;

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode && console.log('data after delete:', data);
			userAlert = null;
		});
	}

	public removeAlert() {

		// ask user Are You Sure?
		this.swalWithBootstrapButtons
		.fire({
			title: "Are you sure?",
			text: "You are trying to delete the current user alert notification settings.",
			showCancelButton: true,
			confirmButtonText: "Delete Alert Setting",
			cancelButtonText: "Cancel",
			reverseButtons: false
		})
		.then((result: any) => {
			if (result.value) {

				if(this.alertLevel == "tag") {
					let userAlert = this.userAlert;
					// remove the alert
					this.deleteEmailTagUser(userAlert);
				} else {
					this.alertObject.alarmType.forEach(element => {
						let userAlert;
						if(element == "criticalOnly") {
							// set critical alarms
							userAlert = this.criticalUserAlert;
						}
						else if (element == "allAlarms") {
							// set  alarms
							userAlert = this.alarmUserAlert;
						}
						else if (element == "alerts") {
							// set  alerts
							userAlert = this.alertUserAlert;
						}
						else {
							//error
						}

						// remove the alert
						this.deleteEmailTagUser(userAlert);
					});
				}

				// close dlg
				this.closeWindow(false);

				// inform user
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "Alert has been deleted.",
					title: "Alert Settings"
				});
			} else {

				// inform user
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "Alert has NOT been deleted.",
					title: "Alert Settings"
				});
			}



		});



	}

	public OnToggleChanged(value) {
		// toggle selection changed
		Global.User.DebugMode && console.log(value)

		// if nothing selected then clear out any current selections
		if(value.length == 0) {
			this.setAlertFromSelection(undefined);
		}

		value.forEach(element => {
			if(element == "criticalOnly") {
				// set critical alarms
				this.setAlertFromSelection(this.criticalUserAlert)
			}
			else if (element == "allAlarms") {
				// set  alarms
				this.setAlertFromSelection(this.alarmUserAlert)
			}
			else {
				this.setAlertFromSelection(this.userAlert)
			}
		});

	}

	public saveChanges() {
		//console.log("userAlert to save=", this.userAlert);
		//console.log("alertObject to save=", this.alertObject);

		let isValid = this.validate();

		if(isValid == false) {
			return;
		}


		if(this.alertLevel == "site" && this.alertObject.siteId == null) {

			// save alert foreach site
			this.inputObject.widget.SiteList.forEach(siteId => {

				this.alertObject.siteId = siteId;

				this.alertObject.alarmType.forEach(element => {
					let userAlert;
					if(element == "criticalOnly") {
						// set critical alarms
						userAlert = this.criticalUserAlert;
					}
					else if (element == "allAlarms") {
						// set  alarms
						userAlert = this.alarmUserAlert;
					}
					else if (element == "alerts") {
						// set  alarms
						userAlert = this.alertUserAlert;
					}
					else {
						// error
					}

					let isNewAlert = false;
					if(userAlert == undefined) {
						// add new alert
						userAlert = this.addNewAlert();
						isNewAlert = true;
					}


					this.InitializeAllCategories(userAlert);
					// set the right context category
					this.updateUserAlertBasedOnContext(element, userAlert);
					this.UpdateAlertFromSettings(userAlert)
					this.BuildAndExecSqlStatement(isNewAlert, userAlert);

				});
			});
		}
		else {

			if(this.alertLevel == "tag") {
				let userAlert = this.userAlert;
				let isNewAlert = false;
					if(userAlert == undefined) {
						// add new alert
						userAlert = this.addNewAlert();
						isNewAlert = true;
					}


					this.InitializeAllCategories(userAlert);
					// set the right context category
					this.updateUserAlertBasedOnContext(this.alertObject.alarmTagType, userAlert);
					this.UpdateAlertFromSettings(userAlert)
					this.BuildAndExecSqlStatement(isNewAlert, userAlert);
			}
			else {


				this.alertObject.alarmType.forEach(element => {
					let userAlert;
					if(element == "criticalOnly") {
						// set critical alarms
						userAlert = this.criticalUserAlert;
					}
					else if (element == "allAlarms") {
						// set  alarms
						userAlert = this.alarmUserAlert;
					}
					else if (element == "alerts") {
						// set  alarms
						userAlert = this.alertUserAlert;
					}
					else {
						//error
					}

					let isNewAlert = false;
					if(userAlert == undefined) {
						// add new alert
						userAlert = this.addNewAlert();
						isNewAlert = true;
					}


					this.InitializeAllCategories(userAlert);
					// set the right context category
					this.updateUserAlertBasedOnContext(element, userAlert);
					this.UpdateAlertFromSettings(userAlert)
					this.BuildAndExecSqlStatement(isNewAlert, userAlert);

				});
			}


		}


		this.closeWindow(false);
	}


	public closeDialogWindow(): void {
		this.closeWindow(false);
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			this.dialogRef.close();
		}
	}

	InitializeAllCategories(userAlert) {
		// set all to null before setting the specific category
		userAlert.CriticalSiteId = userAlert.SiteId = userAlert.CriticalGateSystemId = userAlert.GateSystemId = userAlert.CriticalAssetId = userAlert.AlertAssetId = userAlert.AssetId
			= userAlert.TagId = userAlert.AllPBBCriticalSiteId = userAlert.AllPBBSiteId = userAlert.AllPCACriticalSiteId = userAlert.AllPCASiteId = userAlert.AllGPUCriticalSiteId = userAlert.AllGPUSiteId
			= userAlert.JbtStandardObservationId = userAlert.JbtStandardObservationSiteId = userAlert.AllGSECriticalSiteId = userAlert.AllGSESiteId
			= userAlert.CriticalGSEAssetTypeId = userAlert.GSEAssetTypeId = userAlert.CriticalGSEAssetModelId = userAlert.GSEAssetModelId
			= userAlert.CriticalThingId = userAlert.AlertThingId = userAlert.ThingId = userAlert.GroupThingId
			= null;

	}


	UpdateAlertFromSettings(userAlert) {

		// set to null if needed
		if(this.alertObject.MinutesBetweenNotification == 0 || this.alertObject.MinutesBetweenNotification == undefined) {
			userAlert.MinutesBetweenNotification = null;
		}
		else {
			userAlert.MinutesBetweenNotification = this.alertObject.MinutesBetweenNotification;
		}

		if(this.alertObject.MinutesToEscalation == 0 || this.alertObject.MinutesToEscalation == undefined) {
			userAlert.MinutesToEscalation = null;
		}


		// set delivery methods
		userAlert.Email = this.alertObject.Email == true ? 1 : 0;
		userAlert.TextMessage = this.alertObject.TextMessage == true ? 1 : 0;



		//
		// set DAYS
		//
		if (this.alertObject.DaysSelected == "allDays") {
			userAlert.SendMon = userAlert.SendTue = userAlert.SendWed = userAlert.SendThu = userAlert.SendFri = userAlert.SendSun = userAlert.SendSat = 0;
			userAlert.SendAllDays = 1;
		}
		else if (this.alertObject.DaysSelected == "workWeekDays") {
			userAlert.SendMon = this.alertObject.days['Mon'] == true ? 1 : 0
			userAlert.SendTue = this.alertObject.days['Tue'] == true ? 1 : 0
			userAlert.SendWed = this.alertObject.days['Wed'] == true ? 1 : 0
			userAlert.SendThu = this.alertObject.days['Thu'] == true ? 1 : 0
			userAlert.SendFri = this.alertObject.days['Fri'] == true ? 1 : 0
			userAlert.SendSat = this.alertObject.days['Sat'] == true ? 1 : 0
			userAlert.SendSun = this.alertObject.days['Sun'] == true ? 1 : 0
			userAlert.SendAllDays = 0;
		}

		//
		// set HOURS
		//
		if (this.alertObject.HoursSelected == "allHours") {
			userAlert.SendHour00 = userAlert.SendHour01 = userAlert.SendHour02 = userAlert.SendHour03 = userAlert.SendHour04 = userAlert.SendHour05 = userAlert.SendHour06
			= userAlert.SendHour07 = userAlert.SendHour08 = userAlert.SendHour09 = userAlert.SendHour10 = userAlert.SendHour11 = userAlert.SendHour12 = userAlert.SendHour13
			= userAlert.SendHour14 = userAlert.SendHour15 = userAlert.SendHour16 = userAlert.SendHour17 = userAlert.SendHour18 = userAlert.SendHour19 = userAlert.SendHour20
			= userAlert.SendHour21 = userAlert.SendHour22 = userAlert.SendHour23 = 0;
			userAlert.SendAllHours = 1;
		}
		else if (this.alertObject.HoursSelected == "workHours") {
			userAlert.SendAllHours = 0;

			for (let i = 0; i < 24; i++) {
				window['hour' + i] = 0;
			}

			let offset = this.alertObject.UserTimeZoneOffset;

			for (let i = 0; i < 24; i++) {
				let utc = i+offset;
				if( utc > 23 ) {
					utc = utc - 24;
				}
				window['hour' + utc] = this.alertObject.hours[i];
			}

			userAlert.SendHour00 = window['hour0'] == true ? 1 : 0
			userAlert.SendHour01 = window['hour1'] == true ? 1 : 0
			userAlert.SendHour02 = window['hour2'] == true ? 1 : 0
			userAlert.SendHour03 = window['hour3'] == true ? 1 : 0
			userAlert.SendHour04 = window['hour4'] == true ? 1 : 0
			userAlert.SendHour05 = window['hour5'] == true ? 1 : 0
			userAlert.SendHour06 = window['hour6'] == true ? 1 : 0
			userAlert.SendHour07 = window['hour7'] == true ? 1 : 0
			userAlert.SendHour08 = window['hour8'] == true ? 1 : 0
			userAlert.SendHour09 = window['hour9'] == true ? 1 : 0
			userAlert.SendHour10 = window['hour10'] == true ? 1 : 0
			userAlert.SendHour11 = window['hour11'] == true ? 1 : 0
			userAlert.SendHour12 = window['hour12'] == true ? 1 : 0
			userAlert.SendHour13 = window['hour13'] == true ? 1 : 0
			userAlert.SendHour14 = window['hour14'] == true ? 1 : 0
			userAlert.SendHour15 = window['hour15'] == true ? 1 : 0
			userAlert.SendHour16 = window['hour16'] == true ? 1 : 0
			userAlert.SendHour17 = window['hour17'] == true ? 1 : 0
			userAlert.SendHour18 = window['hour18'] == true ? 1 : 0
			userAlert.SendHour19 = window['hour19'] == true ? 1 : 0
			userAlert.SendHour20 = window['hour20'] == true ? 1 : 0
			userAlert.SendHour21 = window['hour21'] == true ? 1 : 0
			userAlert.SendHour22 = window['hour22'] == true ? 1 : 0
			userAlert.SendHour23 = window['hour23'] == true ? 1 : 0
		}
		else {
			userAlert.SendAllHours = 0;
		}

		userAlert.LastModifiedUserId = Global.User.currentUser.Id;

		let jsDate = new Date();
		userAlert.LastModifiedDate = moment(jsDate).format("YYYY/MM/DD HH:mm:ss");
	}

	BuildAndExecSqlStatement(isNewAlert, userAlert) {

		let statement = isNewAlert ?
								"API.AlertManagementInsertNewAlarmRecord @CreatorUserId = " +
									userAlert.CreatorUserId +
									", @CreationDate = '" +
									userAlert.CreationDate + "'"
								:
								"API.AlertManagementEditExistingAlarmRecord @Id= " + userAlert.Id

			statement = statement +
							", @UserId = " +
							userAlert.UserId +
							", @Email = " +
							userAlert.Email +
							", @TextMessage = " +
							userAlert.TextMessage +
							", @CriticalSiteId = " +
							userAlert.CriticalSiteId +
							", @SiteId = " +
							userAlert.SiteId +
							", @CriticalGateSystemId = " +
							userAlert.CriticalGateSystemId +
							", @GateSystemId = " +
							userAlert.GateSystemId +
							", @CriticalAssetId = " +
							userAlert.CriticalAssetId +
							", @AssetId = " +
							userAlert.AssetId +
							", @TagId = " +
							userAlert.TagId +
							", @JbtStandardObservationId = " +
							userAlert.JbtStandardObservationId +
							", @JbtStandardObservationSiteId = " +
							userAlert.JbtStandardObservationSiteId +
							", @AllPBBCriticalSiteId = " +
							userAlert.AllPBBCriticalSiteId +
							", @AllPBBSiteId = " +
							userAlert.AllPBBSiteId +
							", @AllPCACriticalSiteId = " +
							userAlert.AllPCACriticalSiteId +
							", @AllPCASiteId = " +
							userAlert.AllPCASiteId +
							", @AllGPUCriticalSiteId = " +
							userAlert.AllGPUCriticalSiteId +
							", @AllGPUSiteId = " +
							userAlert.AllGPUSiteId +
							", @AllGSECriticalSiteId = " +
							userAlert.AllGSECriticalSiteId +
							", @AllGSESiteId = " +
							userAlert.AllGSESiteId +
							", @CriticalGSEAssetModelId = " +
							userAlert.CriticalGSEAssetModelId +
							", @GSEAssetModelId = " +
							userAlert.GSEAssetModelId +
							", @CriticalGSEAssetTypeId = " +
							userAlert.CriticalGSEAssetTypeId +
							", @GSEAssetTypeId = " +
							userAlert.GSEAssetTypeId +
							', @LastModifiedUserId = ' +
							userAlert.LastModifiedUserId +
							", @LastModifiedDate = '" +
							userAlert.LastModifiedDate +
							"', @MinutesBetweenNotification = " +
							userAlert.MinutesBetweenNotification +
							", @MinutesToEscalation = " +
							userAlert.MinutesToEscalation +
							", @SendAllHours = " +
							userAlert.SendAllHours +
							", @SendAllDays = " +
							userAlert.SendAllDays +
							", @SendHour00 = " +
							userAlert.SendHour00 +
							", @SendHour01 = " +
							userAlert.SendHour01 +
							", @SendHour02 = " +
							userAlert.SendHour02 +
							", @SendHour03 = " +
							userAlert.SendHour03 +
							", @SendHour04 = " +
							userAlert.SendHour04 +
							", @SendHour05 = " +
							userAlert.SendHour05 +
							", @SendHour06 = " +
							userAlert.SendHour06 +
							", @SendHour07 = " +
							userAlert.SendHour07 +
							", @SendHour08 = " +
							userAlert.SendHour08 +
							", @SendHour09 = " +
							userAlert.SendHour09 +
							", @SendHour10 = " +
							userAlert.SendHour10 +
							", @SendHour11 = " +
							userAlert.SendHour11 +
							", @SendHour12 = " +
							userAlert.SendHour12 +
							", @SendHour13 = " +
							userAlert.SendHour13 +
							", @SendHour14 = " +
							userAlert.SendHour14 +
							", @SendHour15 = " +
							userAlert.SendHour15 +
							", @SendHour16 = " +
							userAlert.SendHour16 +
							", @SendHour17 = " +
							userAlert.SendHour17 +
							", @SendHour18 = " +
							userAlert.SendHour18 +
							", @SendHour19 = " +
							userAlert.SendHour19 +
							", @SendHour20 = " +
							userAlert.SendHour20 +
							", @SendHour21 = " +
							userAlert.SendHour21 +
							", @SendHour22 = " +
							userAlert.SendHour22 +
							", @SendHour23 = " +
							userAlert.SendHour23 +
							", @SendSun = " +
							userAlert.SendSun +
							", @SendMon = " +
							userAlert.SendMon +
							", @SendTue = " +
							userAlert.SendTue +
							", @SendWed = " +
							userAlert.SendWed +
							", @SendThu = " +
							userAlert.SendThu +
							", @SendFri = " +
							userAlert.SendFri +
							", @SendSat = " +
							userAlert.SendSat +
							", @UserTimeZoneOffset = " +
							userAlert.UserTimeZoneOffset +
							", @CriticalThingId = " +
							userAlert.CriticalThingId +
							", @ThingId = " +
							userAlert.ThingId +
							", @AlertAssetId = " +
							userAlert.AlertAssetId +
							", @AlertThingId = " +
							userAlert.AlertThingId +
							", @GroupThingId = " +
							userAlert.GroupThingId;

						console.log("alert update statement=", statement);

						this.dataService.SQLActionAsPromise(statement).then((data: any) => {
							//console.log('modified data:', data);
							if(data.length > 0) {
								userAlert.Id = data[0].Id;
							}

							this.signalRCore.notifySpecificClient(Global.SignalR.ClientId, 'email-tag-users', data);

						});
	}
}
