import { Component, OnInit, Output, EventEmitter, OnDestroy, Optional, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { of, from } from "rxjs";
import { map, filter, tap, groupBy, mergeMap, reduce, toArray, switchMap } from "rxjs/operators";
import _ from "lodash";

import { process } from "@progress/kendo-data-query";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
@Component({
	selector: 'lib-add-template-to-canvas',
	templateUrl: './add-template-to-canvas.component.html',
	styleUrls: ['./add-template-to-canvas.component.scss'],
})
export class AddTemplateToCanvasComponent implements OnInit, OnDestroy {
	displayedColumns: string[] = ['name', 'description'];
	dataSource: Object[];
	widgetTypeGroups: any;
	isLoading: boolean;
	theme: string;

	@Output() canvasWidgetClicked = new EventEmitter<any>();
	colorChangedSubscription: any;
	constructor(
		public dialogRef: MatDialogRef<AddTemplateToCanvasComponent>,
		public dataService: DataService,
		@Optional() @Inject(MAT_DIALOG_DATA) public canvasTemplateData: any
	) { }

	consoleElement(element) {
		console.log(element);
	}
	canUserAddWidget(widgetTypeId) {
		if (
			Global.User.isAdmin ||
			Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(
				widgetTypeId
			)
		) {
			return true;
		} else {
			return false;
		}

		// return true;
	}

	canUserAddWidgetText(element) {
		if (
			Global.User.isAdmin ||
			Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(
				element.WidgetTypeId
			)
		) {
			return true;
		} else {
			return false;
		}
		// return false;
	}
	ngOnInit() {
		var service = this;
		console.log(service.canvasTemplateData);
		if (Global.Theme === 'light') {
			service.theme = 'light';
		} else if (Global.Theme === 'dark') {
			service.theme = 'dark';
		}

		service.colorChangedSubscription =
			service.dataService.colorChanged$.subscribe((data: any) => {
				Global.User.DebugMode && console.log(data);
				if (data === 'light') {
					service.theme = 'light';
				} else {
					service.theme = 'dark';
				}

			});
		service.isLoading = true;
		service.widgetTypeGroups = [];
		var widgetTypeTabGroupsWithWidgetTypes = [];

		widgetTypeTabGroupsWithWidgetTypes =
			service.dataService.cache.canvasWidgetTypeTabGroups
				.select((group: any) => {
					var newGroup: any = {};
					(newGroup.Id = group.Id),
						(newGroup.Name = group.Name),
						(newGroup.Ordinal = group.Ordinal),
						(newGroup.WidgetTypeTabGroup = group),
						(newGroup.CanvasWidgetTypes =
						service.dataService.cache.canvasWidgetTypes
								.where((wt: any) => {
									return wt.CanvasWidgetTypeTabGroupId == group.Id;
								})
								.select((wt: any) => {
									var newWidgetType = {
										Id: wt.Id,
										Name: wt.Name,
										Description: wt.Description,
										//DashboardTypeId: wt.DashboardTypeId,
										V2Compatible: wt.V2Compatible,
										CanvasWidgetType: wt,
										//Visible: false,
										IsAvailableToAll: wt.IsAvailableToAll,
										CanvasTemplateTypeId: wt.CanvasTemplateTypeId,
									};
									return newWidgetType;
								})
								.where((wt: any) => {
									return (
										//wt.Visible == true &&
										wt.IsAvailableToAll == true &&
										(service.canvasTemplateData.AngularDirectiveName == 'canvas' ? (wt.CanvasTemplateTypeId == 1 || wt.CanvasTemplateTypeId == 2) : wt.CanvasTemplateTypeId == 2)
									);
								})
								.toArray());
					return newGroup;
				})

				.orderBy((g: any) => {
					return g.Ordinal;
				})
				.toArray();

		var widgetTypeGroupsFromDataCache = widgetTypeTabGroupsWithWidgetTypes
			.where((group: any) => {
				return (
					group.CanvasWidgetTypes.length > 0
				);
			})
			.toArray();

		//-- changing the widget type groups and list of dashboard widgets to come from the data cache rather than the stored procedure...
		this.widgetTypeGroups = widgetTypeGroupsFromDataCache;

		this.isLoading = false;
	}



	addWidgetToCanvas(widgetToAdd: any) {
		console.log(widgetToAdd);

		let clonedCanvasWidget = {
			AngularDirectiveName: widgetToAdd.CanvasWidgetType.AngularDirectiveName,
			CategoryPath: widgetToAdd.CanvasWidgetType.CategoryPath,
			CreationDate: widgetToAdd.CanvasWidgetType.CreationDate,
			DashboardTypeId: widgetToAdd.CanvasWidgetType.DashboardTypeId,
			DataTypeCode: widgetToAdd.CanvasWidgetType.DataTypeCode,
			DateCompleted: widgetToAdd.CanvasWidgetType.DateCompleted,
			Description: widgetToAdd.CanvasWidgetType.Description,
			DeveloperPersonId: widgetToAdd.CanvasWidgetType.DeveloperPersonId,
			DevelopmentPriority: widgetToAdd.CanvasWidgetType.DevelopmentPriority,
			EstimatedDateOfCompletion:
				widgetToAdd.CanvasWidgetType.EstimatedDateOfCompletion,
			HasSettings: widgetToAdd.CanvasWidgetType.HasSettings,
			InitialHeight: widgetToAdd.CanvasWidgetType.InitialHeight,
			InitialWidth: widgetToAdd.CanvasWidgetType.InitialWidth,
			IsAvailableToAdmin: widgetToAdd.CanvasWidgetType.IsAvailableToAdmin,
			IsAvailableToAll: widgetToAdd.CanvasWidgetType.IsAvailableToAll,
			IsHiddenSystemType: widgetToAdd.CanvasWidgetType.IsHiddenSystemType,
			Mnemonic: widgetToAdd.CanvasWidgetType.Mnemonic,
			Ordinal: widgetToAdd.CanvasWidgetType.Ordinal,
			CanvasWidgetTypeId: widgetToAdd.CanvasWidgetType.Id,
			CanvasTemplateId: this.canvasTemplateData.TemplateId,
			WidgetName: widgetToAdd.CanvasWidgetType.Name,
			Name: widgetToAdd.CanvasWidgetType.Name,
			CanvasWidgetTypeTabGroupId: widgetToAdd.CanvasWidgetType.CanvasWidgetTypeTabGroupId,
			WidgetTypeTabGroupName:
				widgetToAdd.CanvasWidgetType.WidgetTypeTabGroupName,
			WidgetTypeTabGroupOrdinal:
				widgetToAdd.CanvasWidgetType.WidgetTypeTabGroupOrdinal,
			WidgetTypeTabGroupV2Compatible:
				widgetToAdd.CanvasWidgetType.WidgetTypeTabGroupV2Compatible,
			WidgetTypeV2Compatible: widgetToAdd.CanvasWidgetType.V2Compatible,
			CreatorUserId: Global.User.currentUser.Id,
		};

		this.canvasWidgetClicked.emit(clonedCanvasWidget);
		console.log(this.widgetTypeGroups);
	}

	onCancel() {
		this.dialogRef.close();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
	}
}
