import {
	Component,
	OnInit,
	Inject,
	Output,
	EventEmitter,
	Input,
	Optional,
	ViewChild,
	IterableDiffers,
	OnDestroy,
} from '@angular/core';


import _ from 'lodash';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { CacheUtilityService } from '../../services/cache-utility.service';
import { Global } from '../../_constants/global.variables';
import * as $ from 'jquery';



@Component({
	selector: 'lib-terminal-overview',
	templateUrl: './terminal-overview.component.html',
	styleUrls: ['./terminal-overview.component.scss'],
})
export class TerminalOverviewComponent implements OnInit, OnDestroy {
	private fullDataCacheExists: boolean = false;
	public fullDataCacheExists$: any;
	fullDataCache$: BehaviorSubject<false>;
	differ: any;
	newHeight: number;
	newWidth: number;
	oldHeight: number;
	oldWidth: number;
	terminalSystemId: any;
	terminalSystem: any;
	timerId: any;
	public service: any;
	@Input() widgetObject: any;
	public widgetSite: any;
	public widgetSiteName: string;
	public systemId: number;
	public baseImageSource: any;
	public terminalGraphics: any;
	public systemInfo: any;
	isDataLoading: boolean;
	fullDataCacheSubscription: Subscription;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public dataSource: any,
		private dataService: DataService,
		private cacheUtilityService: CacheUtilityService,
		@Optional() public dialogRef: MatDialogRef<TerminalOverviewComponent>,
		differs: IterableDiffers
	) {
		this.differ = differs.find([]).create(null);
		this.service = this;
	}

	ngOnInit() {
		if (Global.FullDataCacheExists === false) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
			Global.User.DebugMode &&
				console.log(
					'Terminal Overview: going to initialize since cache exists...'
				);
		}
	}
	initialize() {
		if (
			this.dataService != undefined &&
			this.dataService.cache != undefined &&
			this.widgetObject != undefined &&
			this.widgetObject.WidgetSiteId
		) {
			this.widgetSite =
				this.dataService.cache.sitesObject[
					this.widgetObject.WidgetSiteId
				];
			this.widgetSiteName = this.widgetSite && this.widgetSite.Name;
			if (
				this.widgetSite != undefined &&
				this.widgetSite.Systems != undefined
			) {
				this.terminalSystemId = this.widgetSite.Systems.filter((t) => {
					return t.TypeId == 1;
				});
				this.baseImageSource =
					'https://iopspro.com/staticImages/assets/' + this.terminalSystemId[0].BaseUnitImageURL;
				if (this.terminalSystemId != undefined) {
					this.systemId = this.terminalSystemId[0].Id;
					this.terminalSystem = this.dataService.cache.systems.filter(
						(t) => {
							return t.Id == this.systemId;
						}
					);
					this.dataService
						.GetTerminalOverviewGraphicsAndTagsForTerminalSystem(
							this.systemId
						)
						.subscribe((data: any) => {
							this.terminalGraphics = data;
							this.terminalGraphics.forEach((tg: any) => {
								//tg.showImage = this.checkshowImage(tg);
								'https://iopspro.com/staticImages/assets/' + tg.ImageURL;
							});
						});
				}
			}
		}
	}

	onDelete() {}
	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');

	}
	onCancel() {
		this.dialogRef.close();
	}
}
