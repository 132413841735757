<div class="content">
	<div class="resizeContainer">
		<div class="container-fluid" style="height: 100% !important">
			<div class="jumbotron h-100 text-center m-0 d-flex flex-column justify-content-center">
				<div class="container">
					<h1 style="font-size: 5vh !important">Welcome to iOPSPro!</h1>
					<p class="lead">To get started, add a new dashboard by clicking the button below.</p>
				</div>

				<div class="container justify-content-center" style="height: 40px; margin-top: 30px">
					<div class="row justify-content-center">
						<div class="downArrow bounce justify-content-center">
							<i class="tim-icons icon-minimal-down"></i>
						</div>
					</div>
				</div>
				<div class="container">
					<p class="lead">
						<button class="btn btn-primary btn-lg" (click)="addNewDashboardModal()" role="button">Add Dashboard</button>
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
