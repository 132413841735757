import {
	Component,
	OnInit,
	Inject,
	Output,
	EventEmitter,
	Input,
	Optional,
	ViewChild,
	IterableDiffers,
	NgZone,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';

import { ISignalRNotification } from '../../_models/signalr-notification.model';
import _ from 'lodash';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { process, State } from '@progress/kendo-data-query';

import Chart from 'chart.js';

import {
	GridDataResult,
	DataStateChangeEvent,
	PageChangeEvent,
} from '@progress/kendo-angular-grid';

import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialog,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { GridSettings } from '../../_models/grid-settings.interface';
import { ColumnSettings } from '../../_models/column-settings.interface';
import { DataService } from '../../services/data.service';
import { CacheUtilityService } from '../../services/cache-utility.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DashboardService } from '../../services/dashboard.service';
import { GseService } from '../../services/gse.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import * as $ from 'jquery';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { MapsService } from '../../services/maps.service';
import { IGlobal } from '../../_models/global.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';
import { ITag } from '../../_models/tag.model';

declare const google: any;
declare const markerClusterer:any;


@Component({
	selector: 'lib-gse-active-alarms',
	templateUrl: './gse-active-alarms.component.html',
	styleUrls: ['./gse-active-alarms.component.scss'],
})
export class GseActiveAlarmsComponent implements OnInit, OnDestroy {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	displayedColumns: string[] = [
		'JBTStandardObservationname',
		'Severity',
		'Asset',
		'JavascriptDate',
	];

	private centerLatitude: number = 30.3092613;
	private centerLongitude: number = -95.4679269;
	private zoomLevel: number = 18;
	valuePicked = 0;
	isFullScreen = false;
	hasBeenRedrawn = false;
	isDataLoading: boolean;
	timeZoneType: any;
	alarmsData = new MatTableDataSource<any>();
	searchboxDisabled: boolean;
	newHeight: number;
	newWidth: number;
	oldHeight: number;
	oldWidth: number;
	timerId: any;
	dataFromService: any;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('operationalActiveAlarmsContainer')
	operationalActiveAlarmsContainer: any;
	fullDataCache$: BehaviorSubject<false>;
	differ: any;
	originalDataArray: any;
	siteChange$: any;
	private fullDataCacheExists: boolean = false;
	public fullDataCacheExists$: any;
	public service: any;
	@Input() widgetObject: any;
	public widgetSite: any;
	public widgetSiteName: string;
	public widgetGroupSettings: any;

	public gridSettings: any = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'JBTStandardObservation.Name',
				title: 'Name',
				filterable: true,
				_width: 300,
			},
			{
				field: 'SiteName',
				title: 'Site Name',
				filterable: true,
				_width: 150,
			},
			{
				field: 'CurrentAlarmType',
				title: 'Severity',
				filterable: true,
				_width: 150,
			},
			{
				field: 'Asset.Name',
				title: 'Asset',
				filterable: true,
				_width: 300,
			},
			{
				field: 'Asset.AssetTypeName',
				title: 'Asset Type',
				filterable: true,
				_width: 100,
				hidden: true,
			},
			{
				field: 'JavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 300,
			},
			{
				field: 'SiteLocalJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 300,
			},
			{
				field: 'UTCJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 300,
			},
			{
				field: 'Value',
				title: 'Value',
				filterable: true,
				_width: 100,
			},
			{
				field: 'ValueWhenActive',
				title: 'Value When Active',
				filterable: true,
				_width: 100,
			},
		],
	};
	gseSummaryModalSubscription: Subscription;
	mapOptions: any;
	public canvas: any;
	public ctx;
	public datasets: any;
	public data: any;
	public myChartData;
	public clicked: boolean = true;
	public clicked1: boolean = false;
	public clicked2: boolean = false;

	tagGraphSingleModalSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	signalRObservationFormattedTagsSubscription: any;
	siteList: string[];
	sites: any;
	map: any;
	markers: any[];
	markerclusterer: any;
	fullDataCacheSubscription: Subscription;
	alarmTypeCounts = { Alarms: 0, Warnings: 0, Criticals: 0 };
	gridData: any[];
	displaySiteSelectionMessage: boolean = false;
	signalRTagUpdateSubscription: any;
	assetlistForUserSites: any = [];
	punchoutTimeZoneChangedSubscription: Subscription;
	public global: IGlobal = Global;

	public guid: string;
	public componentName: string = "gse-active-alarms: ";

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public dataSource: any,
		private dataService: DataService,
		@Optional() public dialogRef: MatDialogRef<GseActiveAlarmsComponent>,
		private cacheUtilityService: CacheUtilityService,
		differs: IterableDiffers,
		private kendoSettingsService: KendoSettingsService,
		zone: NgZone,
		private ref: ChangeDetectorRef,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		public gseService: GseService,
		private signalRCore: SignalRCoreService,
		private mapsService: MapsService
	) {

	}

	evaluateSelectedFilters(): any {
		if (
			this.gridSettings.state.filter.filters.length > 0 &&
			this.gridSettings.state.filter.filters[0].filters.length > 0
		) {
			let arrayToReturn = [];
			this.gridSettings.state.filter.filters[0].filters.forEach(
				(filter) => {
					return arrayToReturn.push(filter.value);
				}
			);

			return arrayToReturn;
		} else {
			return [];
		}
	}

	startAlarmRetrievalProcess(): void {
		// console.log(this.gridSettings);
		if (
			this.dataService != undefined &&
			this.dataService.cache != undefined &&
			this.dataService.cache.tags.length > 0
		) {
			// this.createDummyChart();
			let alarmData = this.getAlarmData();
			this.alarmTypeCounts = {
				Alarms: 0,
				Warnings: 0,
				Criticals: 0,
			};
			alarmData.forEach((row) => {
				if (row.Severity === 'Warning') {
					this.alarmTypeCounts.Warnings =
						this.alarmTypeCounts.Warnings + 1;
				}
				if (row.Severity === 'Alarm') {
					this.alarmTypeCounts.Alarms =
						this.alarmTypeCounts.Alarms + 1;
				}
				if (row.Severity === 'Critical') {
					this.alarmTypeCounts.Criticals =
						this.alarmTypeCounts.Criticals + 1;
				}
			});
			this.gridData = alarmData;
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.gridData);
			}

			this.timeZoneType = this.dashboardService.determineTimeZoneType(
				this.widgetObject
			);
			this.changeColumnsForTimeZone();

			//console.log("here once alarms",this.alarmsData.data);
			console.log(
				'Active Faults: going to initialize since cache exists...'
			);
		}

		this.evaluateRedraw();
		this.ref.detectChanges();
	}

	private getAlarmData() {
		//console.log("service cachee",this.dataService.cache.tags);
		console.log(this.widgetObject);
		let alarmData = [];
		if (Global.User.isAdmin) {
			alarmData = this.dataService.cache.tags.filter((t: any) => {
				if (this.widgetObject.WidgetAssetId != undefined) {
					return (
						t.Severity != 'Informational' &&
						t.Value == t.ValueWhenActive &&
						this.siteList?.includes(t.SiteId) &&
						t.AssetId == this.widgetObject.WidgetAssetId
					);
				} else {
					return (
						t.Severity != 'Informational' &&
						t.Value == t.ValueWhenActive &&
						this.siteList?.includes(t.SiteId)
					);
				}
			});
		} else {
			alarmData = this.dataService.cache.tags.filter((t) => {
				if (this.widgetObject.WidgetAssetId != undefined) {
					return (
						t.Severity != 'Informational' &&
						t.Value == t.ValueWhenActive &&
						this.siteList?.includes(t.SiteId) &&
						t.AssetId == this.widgetObject.WidgetAssetId
					);
				} else {
					return (
						t.Severity != 'Informational' &&
						t.Value == t.ValueWhenActive &&
						this.siteList?.includes(t.SiteId)
					);
				}
			});
		}

		alarmData.forEach((tag) => {
			if (
				(tag.IsAlarm === true ||
					tag.IsCritical === true ||
					tag.IsWarning === true) &&
				tag.Value !== '0'
			) {
				//correct order to do since multiple can be true. Want the user to see correct priority
				if (tag.IsWarning === true && tag.Value !== '0') {
					tag.CurrentAlarmType = 'Warning';
				}
				if (tag.IsAlarm === true && tag.Value !== '0') {
					tag.CurrentAlarmType = 'Alarm';
				}

				if (tag.IsCritical === true && tag.Value !== '0') {
					tag.CurrentAlarmType = 'Critical';
				}
			} else {
				//1 is alarm, 3 is critical,
				if (
					tag.JBTStandardObservation
						.JBTStandardObservationSeverityLevelId === 1 &&
					tag.Value !== '0'
				) {
					tag.CurrentAlarmType = 'Alarm';
				}

				if (
					tag.JBTStandardObservation
						.JBTStandardObservationSeverityLevelId === 3 &&
					tag.Value !== '0'
				) {
					tag.CurrentAlarmType = 'Critical';
				}
			}
		});
		return alarmData;
	}

	filterTableData(type: string) {
		if (type === 'All') {
			this.gridSettings.state.filter = {
				filters: [
					{
						filters: [],
						logic: 'and',
					},
				],
				logic: 'and',
			};
		} else {
			this.gridSettings.state.filter = {
				filters: [
					{
						filters: [
							{
								field: 'CurrentAlarmType',
								operator: 'contains',
								value: type,
							},
						],
						logic: 'and',
					},
				],
				logic: 'and',
			};
		}
		this.tagDataGrid.gridDataSubject.next(this.gridData);
	}

	saveGridSettings() {
		if (this.widgetObject !== undefined) {
			this.kendoSettingsService
				.saveGridSettings(
					[
						{
							gridObject: this.tagDataGrid.kendoGridParent,
							gridState: this.gridSettings.state,
						},
					],
					this.widgetObject.WidgetId
				)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	getData(rowNum) {
		this.dataFromService = 'Test';
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		console.log(this.widgetObject);
		if (!_.isNil(this.widgetObject.SiteList)) {
			if (typeof this.widgetObject.SiteList === 'string') {
				this.siteList = this.widgetObject.SiteList.split(',').map(
					(item) => {
						return parseInt(item);
					}
				);
			} else {
				this.siteList = this.widgetObject.SiteList;
			}
		}
		if (
			_.isNil(this.widgetObject.SiteList) ||
			this.widgetObject.SiteList.length < 1
		) {
			this.displaySiteSelectionMessage = true;
			this.isDataLoading = false;
			return;
		} else {
			this.displaySiteSelectionMessage = false;
		}
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.finishInitializingWidget();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
			//console.log("CAcheeee",this.dataService);
		} else {
			this.finishInitializingWidget();
		}

		this.punchoutTimeZoneChangedSubscription = this.dataService.punchoutTimeZoneChanged$.subscribe((data: any) => {
			if (this.dashboardService.currentDashboard.Name == 'PunchOut Screen') {
				this.timeZoneType =
					this.dashboardService.determineTimeZoneType(
						this.widgetObject
					);
				this.changeColumnsForTimeZone();
			}
		});

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {

						console.log(data);
					let foundGSEActiveAlarmWidget = data.find(
							(widgetThatWasChanged) => {
								return (
									widgetThatWasChanged.WidgetId ===
									this.widgetObject.WidgetId
								);
							}
						);

					if (!_.isNil(foundGSEActiveAlarmWidget)) {
						this.timeZoneType =
						this.dashboardService.determineTimeZoneType(
							foundGSEActiveAlarmWidget
							);
						console.log('Widget Time Zone Changed');
						this.changeColumnsForTimeZone();
					}

				});
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.punchoutTimeZoneChangedSubscription !== undefined) {
			this.punchoutTimeZoneChangedSubscription.unsubscribe();
		}

	}

	public onRightClickSelect({ dataItem, item }) {
		if (item === 'Open Summary for Asset') {
			this.openSummaryWidget(dataItem, false);
		} else if (item === 'Show Location of Asset') {
			this.openSummaryWidget(dataItem, true);
		}
	}

	private openSummaryWidget(dataItem, showMap) {
		// Global.User.DebugMode && console.log(widgetObject);
		const gseSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '95%' : '60%',
			height: Global.isMobile ? '95%' : '80%',
			data: {
				widgetObject: dataItem.Asset,
				shouldShowMap: showMap,
				WidgetName: 'GSE-Summary',
			},

			maxWidth: '100vw',
			maxHeight: '100vh',
			// height: '100%',
			// width: '100%'
		});
		this.gseSummaryModalSubscription = gseSummaryModal
			.afterClosed()
			.subscribe((result) => {
				Global.User.DebugMode && console.log('The modal was closed');
				this.gseSummaryModalSubscription.unsubscribe();
			});
	}

	changeColumnsForTimeZone() {
		const localDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'JavascriptDate'
		);
		const siteDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'SiteLocalJavascriptDate'
		);
		const utcDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'UTCJavascriptDate'
		);

		localDateCol.hidden = this.timeZoneType !== 'User Time';
		siteDateCol.hidden = this.timeZoneType !== 'Site Time';
		utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
		localDateCol.includeInChooser = !localDateCol.hidden;
		siteDateCol.includeInChooser = !siteDateCol.hidden;
		utcDateCol.includeInChooser = !utcDateCol.hidden;
	}

	ngAfterViewInit() {}

	private finishInitializingWidget() {
		let assetListForUserSites = [];
		this.sites = [];
		var sqlStatement = this.dataService.GetSitesWithGSE();
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			data.forEach((element) => {
				if (this.siteList.includes(element.iopsSiteId)) {
					this.sites.push(element);
				}
			});
			assetListForUserSites = this.dataService.cache.assets.filter(
				(asset) => {
					if (asset.Site !== undefined) {
						const allGSEAssetIds = [
							86292, 86293, 86294, 86295, 86296, 86297,
						];
						return (
							this.siteList.includes(asset.Site.Id) &&
							allGSEAssetIds.includes(asset.AssetTypeId)
						);
					}
				}
			);
			if (this.widgetObject.WidgetAssetId != undefined) {
				assetListForUserSites = assetListForUserSites.filter((a) => {
					return a.Id == this.widgetObject.WidgetAssetId;
				});
			} else {
			}

			this.assetlistForUserSites = assetListForUserSites.map((a) => {
				return parseInt(a.Id);
			});
			let siteAssetIds: string = assetListForUserSites
				.map((a) => a.Id.toString())
				.join();

			this.getSignalRUpdates();

			let allObsIdsForAlarms = [];
			this.dataService.cache.jbtStandardObservationSeverityLevels.forEach(
				(level: any) => {
					if (level.Name !== 'Informational') {
						level.JBTStandardObservations.forEach((obs: any) => {
							allObsIdsForAlarms.push(obs.Id);
						});
					}
				}
			);
			let allObsIdsForAlarmsString = allObsIdsForAlarms.join();
			// Global.User.DebugMode && console.log(siteAssetIds);
			// need a list of all the asset id's for all teh sites as a string, comma seperated.
			this.signalRObservationFormattedTagsSubscription = this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
					siteAssetIds,
					false,
					allObsIdsForAlarmsString
				)
				.subscribe((x: any) => {
					this.isDataLoading = false;
					this.widgetObject.isDisplayDataLive = true;
					if (this.widgetObject.WidgetKendoUIJson) {
						let jsonDataObjectParsed =
							this.kendoSettingsService.parseReturnedSettingsForDates(
								this.widgetObject.WidgetKendoUIJson
							);

						//the first item in the array is the gridSettings for the first tab of data for this widget.

						let returnedParsedObject = jsonDataObjectParsed[0];
						//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
						this.gridSettings = this.mapGridSettings(
							this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
								this.gridSettings,
								returnedParsedObject
							)
						);
						console.log(this.gridSettings);
					}
					this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
						[this.gridSettings.columnsConfig],
						this.widgetObject.WidgetId
					);

					this.startAlarmRetrievalProcess();

				});
		});
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = _.uniq(this.assetlistForUserSites.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix )).join();
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid,
										 WidgetName: this.widgetObject.WidgetName,
										 TagNamePrefix: tagNamePrefixesString.split(",")  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);
				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					let severityList = ['Warning', 'Alarm', 'Critical'];
					if (
						tag !== undefined &&
						this.assetlistForUserSites.includes(tag.AssetId) &&
						severityList.includes(tag.Severity)
					) {
						this.startAlarmRetrievalProcess();
					}
				});
			});
	}

	public mapGridSettings(gridSettings: GridSettings): GridSettings {
		const state = gridSettings.state;
		// this.mapDateFilter(state.filter);
		let example = gridSettings.columnsConfig.sort(
			(a, b) => a.orderIndex - b.orderIndex
		);

		console.log(example);
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
		};
	}

	tabHasChanged(e) {
		Global.User.DebugMode && console.log(e);
		// if (e.index == 1) {
		//   this.searchboxDisabled = true;
		// } else {
		//   this.searchboxDisabled = false;
		// }
	}

	animationRedraw() {
		if (this.valuePicked == 1) {
			// this.drawMap();
			this.hasBeenRedrawn = true;
			let mapContainer = $('#card-body-id').height();
			if (this.widgetObject !== undefined) {
				$('#regularMap' + this.widgetObject.WidgetId).height(
					mapContainer - 50
				);
			}
			this.drawMap();
			// this.hasBeenRedrawn = true;
			// let mapContainer = $("#card-body-id").height();
			// if (this.dataObject !== undefined) {
			//   $("#regularMap").height(mapContainer - 50);
			// } else if (this.widgetObject !== undefined) {
			//   $("#regularMap" + this.widgetObject.WidgetId).height(mapContainer - 50);
			// }
		}
	}

	drawMap() {
		this.evaluateRedraw();
		if (this.map === undefined) {
			let legend = this.gseService.createLegendForGseMaps(
				'legend' + this.widgetObject.WidgetId
			);

			var centerPointLatLng = new google.maps.LatLng(
				this.centerLatitude,
				this.centerLongitude
			);
			this.map = new google.maps.Map(
				document.getElementById(
					'regularMap' + this.widgetObject.WidgetId
				),
				this.mapsService.loadMapConfiguration({center: {lat: this.centerLatitude, lng: this.centerLongitude}, zoom: this.zoomLevel})
			);
			this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
				legend
			);
			const styleControl = document.getElementById(
				'style-selector-control' + this.widgetObject.WidgetId
			) as HTMLElement;
			this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
				styleControl
			);
			(
				document.getElementById(
					'hide-poi' + this.widgetObject.WidgetId
				) as HTMLElement
			).addEventListener('click', () => {
				legend.hidden = true;
				//get legend by id and apply hiddden style
			});
			(
				document.getElementById(
					'show-poi' + this.widgetObject.WidgetId
				) as HTMLElement
			).addEventListener('click', () => {
				legend.hidden = false;

				//get legend by id and apply hiddden style
			});
		} else {
			for (let i = 0; i < this.markers.length; i++) {
				this.markers[i].setMap(null);
				this.markers[i] = null;
			}
			this.markerclusterer.clearMarkers();
			this.markerclusterer = null;
		}

		let bounds = new google.maps.LatLngBounds();

		let uniqueArray = _.uniqBy(this.gridSettings.gridData.data, 'AssetId');
		this.markers = [];
		console.log(uniqueArray);
		uniqueArray.forEach((tag: any) => {
			let lat: any = _.head(
				tag.Asset.Tags.filter(
					(t) => t.JBTStandardObservationId == 54260
				)
			);
			let long: any = _.head(
				tag.Asset.Tags.filter(
					(t) => t.JBTStandardObservationId == 54261
				)
			);
			var gseLatLng = new google.maps.LatLng(lat.Value, long.Value);
			bounds.extend(gseLatLng);
			let listOfAlarmsAsFormatedHtml =
				this.findAllActiveAlarmsFromTagAndFormatForMap(tag);
			var infoWindow = new google.maps.InfoWindow({
				content: `<div class='firstHeading' style='font-size: 12px'><b><span style='color:teal;'> ${tag.SiteName} ${tag.Asset.Name}</div></b>
        <b><div style="font-weight: bold">Active Faults: </div></b>
        ${listOfAlarmsAsFormatedHtml.htmlString}
        </div>`,
			});

			let evaluationObject: any =
				this.gseService.determineAssetImageFromAssetTypeForMap(
					tag.Asset
				);
			if (evaluationObject !== undefined) {
				if (evaluationObject.alarmType === 'Critical') {
					var marker = new google.maps.Marker({
						position: gseLatLng,
						title: tag.Asset.Name,
						infowindow: infoWindow,
						icon: evaluationObject.icon,
						animation: google.maps.Animation.BOUNCE,
					});
				} else {
					var marker = new google.maps.Marker({
						position: gseLatLng,
						title: tag.Asset.Name,
						infowindow: infoWindow,
						icon: evaluationObject.icon,
					});
				}
			} else {
				var marker = new google.maps.Marker({
					position: gseLatLng,
					title: tag.Asset.Name,
					infowindow: infoWindow,
				});
			}

			google.maps.event.addListener(marker, 'click', function (evt) {
				infoWindow.open(this.map, marker);
			});
			listOfAlarmsAsFormatedHtml.allAlarmsForAsset;
			google.maps.event.addListener(infoWindow, 'domready', () => {
				listOfAlarmsAsFormatedHtml.allAlarmsForAsset.forEach((tag) => {
					let tagDiv = document.getElementById(
						tag.JBTStandardObservation.Name + tag.AssetId
					);
					if (tagDiv !== null) {
						google.maps.event.addDomListener(
							tagDiv,
							'click',
							(evt) => {
								this.openTagGraphSingle(
									tag,
									tag.JBTStandardObservationId
								);
							}
						);
					}
				});
				// this.tagOptions.forEach((tag) => {
				//   let tagDiv = document.getElementById(tag.tagName + assetObject.Id);
				//   if (tagDiv !== null) {
				//     google.maps.event.addDomListener(tagDiv, "click", (evt) => {
				//       this.openTagGraphSingle(assetObject, tag.standardObservationId);
				//     });
				//   }
				// });
			});

			infoWindow.open(this.map, marker);
			this.markers.push(marker);
		});

		this.markerclusterer = new markerClusterer.MarkerClusterer({map: this.map, markers:  this.markers, renderer: this.mapsService.renderer});

		this.map.fitBounds(bounds);
		this.ref.detectChanges();
	}

	openTagGraphSingle(tag, observationId): void {
		console.log(tag);
		const tagGraphSingleModal = this.dialog.open(
			DialogModalParentComponent,
			{
				width: '95%',
				height: '80%',
				data: {
					TagId: tag.TagId,
					widgetNameDisplay: 'Tag Graph',
					WidgetName: 'tag-graph',
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(
						this.widgetObject
					),
				},
				maxWidth: '100vw',
				maxHeight: '100vh',
			}
		);
		this.tagGraphSingleModalSubscription = tagGraphSingleModal
			.afterClosed()
			.subscribe((result) => {
				// if (this.debugMode) {
				//   console.log("The modal was closed");
				// }
			});
	}

	private findAllActiveAlarmsFromTagAndFormatForMap(tag) {
		let allCurrentAlarmsForAsset = this.gridSettings.gridData.data.filter(
			(gridRow) => {
				return gridRow.AssetId === tag.AssetId;
			}
		);
		if (allCurrentAlarmsForAsset.length > 0) {
			let returnedString = '';
			allCurrentAlarmsForAsset.forEach((tag) => {
				returnedString =
					returnedString +
					`<div id='${tag.JBTStandardObservation.Name}${tag.AssetId}'style='font-size: 12px; cursor: pointer'> ${tag.JBTStandardObservation.Name}</div>`;
			});
			return {
				htmlString: returnedString,
				allAlarmsForAsset: allCurrentAlarmsForAsset,
			};
		}
	}



	saveWidgetObject(fieldName: string, fieldValue: string) {
		this.dataService
			.SQLActionAsPromise(
				'API.Widget_UpdateRecordByIdAndFieldName ' +
					this.widgetObject.WidgetId +
					", '" +
					fieldName +
					"', '" +
					fieldValue +
					"'"
			)
			.then((data: any) => console.log('data updated: %O', data));
	}
	onResized(event) {
		this.newHeight = event.newRect.height;
		this.oldHeight = event.oldHeight;
		this.newWidth = event.newRect.width;
		this.oldWidth = event.oldWidth;
		this.evaluateRedraw();
	}
	fullScreen() {
		this.isFullScreen = !this.isFullScreen;
		console.log(this.isFullScreen);
		if (this.isFullScreen) {
			this.dialogRef.updateSize('100%', '100%');
		} else {
			this.dialogRef.updateSize('95%', '80%');
		}
	}
	evaluateRedraw() {
		if (this.widgetObject !== undefined) {
			let cardHeight = $('#card-whole').height();
			if (this.newHeight !== this.oldHeight) {
				let height10 = this.newHeight * 0.1;
				let height = this.newHeight - (height10 + 30);
				$('.map-container' + this.widgetObject.WidgetId).height(height);
				$('#regularMap' + this.widgetObject.WidgetId).height(height);
			}
		}
	}

	drawGraphics() {}

	onDelete() {}

	onCancel() {
		this.dialogRef.close();
	}
}
