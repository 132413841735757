<div class="card card-tasks" id="card-whole">
	<div class="card-header">
		<h4 class="card-title d-inline" style="padding-left: 15px">Asset Model Template Selection</h4>
		<div style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" tooltip="Close" placement="bottom" (click)="closeDialogWindow()">
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div class="card-body">
		<div>
			<form
			[formGroup]="form"
			(submit)="submitForm(form)"
		>
				<div class="row col-sm-12 col-md-12">
					<div class="col-sm-2 col-md-2 col-form-label">
						<label for="subjectId">Please select an Asset Model</label>
					</div>
					<div class="col-sm-10 col-md-10">
						<div class="form-group">
							<select required class="form-control" id="assetModelId" formControlName="assetModelId" (change)="checkForAssetModelTemplate($event)">
								<option class="form-control" *ngFor="let model of assetModelsByOrganization" [value]="model.AssetModelId">
									{{ model.AssetModelName }}
								</option>
							</select>
						</div>
					</div>
					<div class="card card-tasks center" *ngIf="showAssetModelTemplateMsg === true">
                        <h4>{{templateRetrievalMessage}}</h4>
					</div>
				</div>

				<div class="card-footer text-center">
					<button class="btn btn-danger" type="submit" [disabled]="form.invalid">Edit/Create Template</button>
				</div>
			</form>


		</div>

	</div>
</div>
