<div *ngIf="!isDataLoading" >
	<div title="Click for more Details" style="cursor: pointer" (click)="openCentralUnitSummary(parentAsset.Id)" class="row no-gutters" style="height: 100%;">
		<div class="row box-stat-section-left">
			<div class="form-control" title="Click for more Details" style="cursor: pointer" (click)="openCentralUnitSummary(parentAsset.Id)">&nbsp;&nbsp;&nbsp;{{parentAsset.WidgetAssetName}}</div>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<div class="fullCircle"  style="cursor: pointer; font-size:x-small;white-space:nowrap;padding:0;margin:0;padding-left: 5px;padding-top: 5px;" [ngClass]="{ 'unit-on': assetTags.UnitStatus, 'unit-off': !assetTags.UnitStatus}">{{ assetTags.UnitStatus == true ? 'On' : 'Off'}}</div>
		</div>
	</div>
	<div class="d-flex flex-row box-stat">
		<div (click)="openTagGraphSingle(HzOut)" tooltip = "Click to Chart" placement="bottom" placement="bottom" class="col box-stat-content" *ngIf="assetTags.HzOut != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">HzOut<br><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HzOut}}</span></div>
		<div (click)="openTagGraphSingle(CurrentUnbalance)" tooltip = "Click to Chart" placement="bottom" class="col box-stat-content" *ngIf="assetTags.CurrentUnbalance != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Current Unbalance <br> <span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.CurrentUnbalance}}</span></div>
		<div (click)="openTagGraphSingle(VoltageUnbalance)" tooltip = "Click to Chart" placement="bottom" class="col box-stat-content" *ngIf="assetTags.VoltageUnbalance != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Voltage Unbalance <br> <span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.VoltageUnbalance}}</span></div>
	</div>
	<div class="d-flex flex-row box-stat" style="text-align: center;">
		<div class="col box-stat-content">
			<h4>Amps Out</h4>
			<div class="linear-gauge-section">
				<div (click)="openTagGraphSingle(AmpsOutA)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.AmpsOutA != undefined" class="linear-gauge-container">
					<h4>A</h4>
					<kendo-lineargauge [pointer]="{ value: assetTags.AmpsOutA, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
						<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
						<kendo-lineargauge-scale [minorUnit]="10" [majorUnit]="100" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="0" [max]="500" [line]="{ color: gaugeTextColor }">
							<kendo-lineargauge-scale-ranges>
								<kendo-lineargauge-scale-range [from]="0" [to]="50" color="CornflowerBlue"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="50" [to]="460" color="LightGreen"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="460" [to]="500" color="Red"> </kendo-lineargauge-scale-range>
							</kendo-lineargauge-scale-ranges>
						</kendo-lineargauge-scale>
					</kendo-lineargauge>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.AmpsOutA }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> </h5>
					</div>
				<div (click)="openTagGraphSingle(AmpsOutB)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.AmpsOutB != undefined" class="linear-gauge-container">
					<h4>B</h4>
					<kendo-lineargauge [pointer]="{ value: assetTags.AmpsOutB, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
						<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
						<kendo-lineargauge-scale [minorUnit]="10" [majorUnit]="100" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="0" [max]="500" [line]="{ color: gaugeTextColor }">
							<kendo-lineargauge-scale-ranges>
								<kendo-lineargauge-scale-range [from]="0" [to]="50" color="CornflowerBlue"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="50" [to]="460" color="LightGreen"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="460" [to]="500" color="Red"> </kendo-lineargauge-scale-range>
							</kendo-lineargauge-scale-ranges>
						</kendo-lineargauge-scale>
					</kendo-lineargauge>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.AmpsOutB }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> </h5>
				</div>
				<div (click)="openTagGraphSingle(AmpsOutC)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.AmpsOutC != undefined" class="linear-gauge-container">
					<h4>C</h4>
					<kendo-lineargauge [pointer]="{ value: assetTags.AmpsOutC, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
						<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
						<kendo-lineargauge-scale [minorUnit]="10" [majorUnit]="100" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="0" [max]="500" [line]="{ color: gaugeTextColor }">
							<kendo-lineargauge-scale-ranges>
								<kendo-lineargauge-scale-range [from]="0" [to]="50" color="CornflowerBlue"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="50" [to]="460" color="LightGreen"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="460" [to]="500" color="Red"> </kendo-lineargauge-scale-range>
							</kendo-lineargauge-scale-ranges>
						</kendo-lineargauge-scale>
					</kendo-lineargauge>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.AmpsOutC }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> </h5>
				</div>
			</div>
			<div class="col">
				<div (click)="openTagGraphSingle(AmpsOutAvg)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.AmpsOutAvg != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">AVG<br> <span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpsOutAvg}}</span></div>
			</div>
		</div>
		<div class="col box-stat-content">
			<h4>Volts Out</h4>
			<div class="linear-gauge-section">
				<div (click)="openTagGraphSingle(VoltsOutA)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.VoltsOutA != undefined" class="linear-gauge-container">
					<h4>A</h4>
					<kendo-lineargauge [pointer]="{ value: assetTags.VoltsOutA, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
						<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
						<kendo-lineargauge-scale [minorUnit]="5" [majorUnit]="25" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="500" [max]="650" [line]="{ color: gaugeTextColor }">
							<kendo-lineargauge-scale-ranges>
								<kendo-lineargauge-scale-range [from]="500" [to]="516" color="Red"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="516" [to]="633" color="LightGreen"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="633" [to]="650" color="CornflowerBlue"> </kendo-lineargauge-scale-range>
							</kendo-lineargauge-scale-ranges>
						</kendo-lineargauge-scale>
					</kendo-lineargauge>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltsOutA }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> </h5>
				</div>
				<div (click)="openTagGraphSingle(VoltsOutB)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.VoltsOutB != undefined" class="linear-gauge-container">
					<h4>B</h4>
					<kendo-lineargauge [pointer]="{ value: assetTags.VoltsOutB, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
						<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
						<kendo-lineargauge-scale [minorUnit]="5" [majorUnit]="25" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="500" [max]="650" [line]="{ color: gaugeTextColor }">
							<kendo-lineargauge-scale-ranges>
								<kendo-lineargauge-scale-range [from]="500" [to]="516" color="Red"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="516" [to]="633" color="LightGreen"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="633" [to]="650" color="CornflowerBlue"> </kendo-lineargauge-scale-range>
							</kendo-lineargauge-scale-ranges>
						</kendo-lineargauge-scale>
					</kendo-lineargauge>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltsOutB }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> </h5>
				</div>
				<div (click)="openTagGraphSingle(VoltsOutC)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.VoltsOutC != undefined" class="linear-gauge-container">
					<h4>C</h4>
					<kendo-lineargauge [pointer]="{ value: assetTags.VoltsOutC, shape: 'arrow', color: theme == 'dark' ? 'White' : 'DimGray', size: 20 }" [scale]="{ vertical: true }">
						<kendo-lineargauge-scale-labels [color]="gaugeTextColor"></kendo-lineargauge-scale-labels>
						<kendo-lineargauge-scale [minorUnit]="5" [majorUnit]="25" [majorTicks]="{ visible: true, color: gaugeTextColor }" [minorTicks]="{ visible: true, color: gaugeTextColor }" [min]="500" [max]="650" [line]="{ color: gaugeTextColor }">
							<kendo-lineargauge-scale-ranges>
								<kendo-lineargauge-scale-range [from]="500" [to]="516" color="Red"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="516" [to]="633" color="LightGreen"> </kendo-lineargauge-scale-range>
								<kendo-lineargauge-scale-range [from]="633" [to]="650" color="CornflowerBlue"> </kendo-lineargauge-scale-range>
							</kendo-lineargauge-scale-ranges>
						</kendo-lineargauge-scale>
					</kendo-lineargauge>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltsOutC }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> </h5>
				</div>
			</div>
			<div class="col">
				<div (click)="openTagGraphSingle(VoltsOutAvg)" tooltip = "Click to Chart" placement="bottom" *ngIf="assetTags.VoltsOutAvg != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">AVG<br> <span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.VoltsOutAvg}}</span></div>
			</div>
		</div>
	</div>

	<div class="d-flex flex-row box-stat-section-left">
		<div (click)="openTagGraphSingle(AmpsKwTotal)" tooltip = "Click to Chart" placement="bottom" class="col box-stat-content" *ngIf="assetTags.AmpsKwTotal != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">kW Total <br><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpsKwTotal}}</span></div>
		<div (click)="openTagGraphSingle(VoltsKwTotal)" tooltip = "Click to Chart" placement="bottom" class="col box-stat-content" *ngIf="assetTags.VoltsKwTotal != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">kVA Total <br> <span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.VoltsKwTotal}}</span></div>
	</div>

</div>




