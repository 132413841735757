import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasGsePopupSettingsComponent } from './canvas-gse-popup-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
	declarations: [CanvasGsePopupSettingsComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
	],
	exports: [CanvasGsePopupSettingsComponent],
})
export class CanvasGsePopupSettingsModule {}
