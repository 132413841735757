import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';


@Component({
	selector: 'lib-edit-widget',
	templateUrl: './edit-widget.component.html',
	styleUrls: ['./edit-widget.component.scss'],
})
export class EditWidgetComponent implements OnInit, OnDestroy {
	public widgetForm: FormGroup;
	genericFormGroup: FormGroup;
	assetModelFormGroup: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<EditWidgetComponent>,
		@Inject(MAT_DIALOG_DATA) public dataSource: any,
		public dataService: DataService,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		console.log(this.dataSource);
		if (this.dataSource.title === 'Re-Name Canvas Template') {
			this.widgetForm = new FormGroup({
				canvasTemplateName: new FormControl(this.dataSource.data),
			});

			console.log(this.widgetForm);
		}
		else if (this.dataSource.title === 'Re-Name Asset') {
			if (this.dataSource.data.AssetTypeId !== undefined) {
				if (this.dataSource.data.displayName !== null) {
					this.widgetForm = new FormGroup({
						widgetName: new FormControl(
							this.dataSource.data.displayName
						),
					});
				} else {
					this.widgetForm = new FormGroup({
						widgetName: new FormControl(this.dataSource.data.Name),
					});
				}
			}
		} else if (this.dataSource.title === 'Re-Name Widget') {
			this.widgetForm = new FormGroup({
				widgetName: new FormControl(this.dataSource.data.WidgetName),
			});

			console.log(this.widgetForm);
		} else if (this.dataSource.title === 'Change Asset Model') {
			if (
				this.dataSource.data.AssetModelId !== undefined &&
				this.dataSource.data.AssetModelId !== null &&
				this.dataSource.data.AssetModelId !== 0
			) {
				let index = this.dataService.cache.assetModels.findIndex(
					(assetModel) => {
						return (
							this.dataSource.data.AssetModelId === assetModel.Id
						);
					}
				);
				this.assetModelFormGroup = new FormGroup({
					assetModel: new FormControl(
						this.dataService.cache.assetModels[index].Id
					),
				});
			} else {
				this.assetModelFormGroup = new FormGroup({
					assetModel: new FormControl(''),
				});
			}
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
	}

	onCancel() {
		this.dialogRef.close();
	}

	onSubmit(widgetForm: FormGroup) {
		console.log(this.widgetForm);
		// console.log(widgetForm.value);
		if (this.dataSource.title === 'Re-Name Canvas Template') {
			this.dialogRef.close(this.widgetForm.value.canvasTemplateName);
		}
		else if (this.dataSource.title === 'Re-Name Asset') {
			if (this.dataSource.data.AssetTypeId !== undefined) {
				this.dataSource.data.newAssetName =
					this.widgetForm.value.widgetName;
				this.dialogRef.close(this.dataSource);
			} else {
				this.dialogRef.close(this.widgetForm.value.widgetName);
			}
		} else if (this.dataSource.title === 'Re-Name Widget') {
			if (this.dataSource.data.AssetTypeId !== undefined) {
				this.dataSource.data.newAssetName =
					this.widgetForm.value.widgetName;
				this.dialogRef.close(this.dataSource);
			} else {
				this.dialogRef.close(this.widgetForm.value.widgetName);
			}
		} else if (this.dataSource.title === 'Change Asset Model') {
			console.log(this.assetModelFormGroup.value.assetModel);
			let assetModel = this.dataService.cache.assetModels.find(
				(assetModel) => {
					return (
						parseInt(this.assetModelFormGroup.value.assetModel) ===
						assetModel.Id
					);
				}
			);

			console.log(assetModel);
			this.dialogRef.close({
				asset: this.dataSource.data,
				assetModel: assetModel,
			});
		}
	}
}
