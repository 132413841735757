import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import _ from 'lodash';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';

@Component({
	selector: 'lib-dashboard-timezone-popup-confirmation-settings',
	templateUrl:
		'./dashboard-timezone-popup-confirmation-settings.component.html',
	styleUrls: [
		'./dashboard-timezone-popup-confirmation-settings.component.scss',
	],
})
export class DashboardTimezonePopupConfirmationSettingsComponent
	implements OnInit
{
	constructor(
		@Inject(MAT_DIALOG_DATA) public inputObject: any,
		public dialogRef: MatDialogRef<DashboardTimezonePopupConfirmationSettingsComponent>,
		private dataService: DataService,
		public toastr: ToastrService,
		private dashboardService: DashboardService
	) {}

	ngOnInit() {
		this.inputObject.dashboardWidgets.forEach((widget) => {
			let currentlyAssignedTimeZone = this.inputObject.timeZones.find(
				(tz) => tz.Id === widget.TimeZoneId
			);
			if (!_.isNil(currentlyAssignedTimeZone)) {
				widget.TimeZoneDescription =
					currentlyAssignedTimeZone.Description;
				if (
					currentlyAssignedTimeZone.Id ===
					this.inputObject.selectedTimezoneObject.Id
				) {
					widget.isTimeZoneSelected = true;
				} else {
					widget.isTimeZoneSelected = false;
				}
			} else {
				widget.isTimeZoneSelected = true;
				widget.TimeZoneDescription = '';
			}
		});
		console.log(this.inputObject);
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		this.dialogRef.close(this.inputObject);
	}

	selectAllAndClose() {
		this.inputObject.dashboardWidgets.forEach((widget) => {
			widget.isTimeZoneSelected = true;
		});
		this.submitAndClose();
	}
}
