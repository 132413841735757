<div class="card card-tasks">
	<div class="card-header" style="cursor: default; padding-top: 0px; min-height: 0px">
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">Select Canvas Widget Template to Add to Summary Template</h6>
		</div>

		<button mat-button style="display: inline; float: right; padding-top: 5px" (click)="onCancel()" matTooltip="Close" [matTooltipPosition]="'below'">
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body" style="overflow: hidden !important">
		<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading Canvas Widget Templates...'" [vehicle]="'plane'"></lib-loading-spinner>
		<div *ngIf="!isLoading" style="width: 100%; height: 100%">
			<mat-tab-group animationDuration="0ms">
				<mat-tab *ngFor="let widgetGroup of widgetTypeGroups; let index = index" [label]="widgetGroup.Name">
					<kendo-grid [data]="widgetGroup.CanvasWidgetTypes" [style.height.%]="100">
						<kendo-grid-column field="WidgetTypeName" title="Name">
							<ng-template kendoGridCellTemplate let-dataItem
								><div (click)="addWidgetToCanvas(dataItem)" style="cursor: pointer">
									{{ dataItem.Name }}
								</div></ng-template
							>
						</kendo-grid-column>
						<kendo-grid-column field="WidgetTypeDescription" title="Description">
						<ng-template kendoGridCellTemplate let-dataItem
							><div (click)="addWidgetToCanvas(dataItem)" style="cursor: pointer">
								{{ dataItem.Description }}
							</div></ng-template
						>
					</kendo-grid-column>
					</kendo-grid>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
</div>
