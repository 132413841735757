import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridContextMenuComponent } from './grid-context-menu.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';



@NgModule({
  declarations: [
    GridContextMenuComponent
  ],
  imports: [
    CommonModule, GridModule, PopupModule
  ],
  exports: [GridContextMenuComponent]
})
export class GridContextMenuModule { }
