import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingCoursesComponent } from './training-courses.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { TrainingLessonsModule } from '../training-lessons/training-lessons.module';

@NgModule({
	declarations: [TrainingCoursesComponent],
	imports: [CommonModule, GridModule, TrainingLessonsModule],
	exports: [TrainingCoursesComponent],
})
export class TrainingCoursesModule {}
