import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CentralunitsSummaryComponent } from './centralunits-summary.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { GridModule } from '@progress/kendo-angular-grid';
import { MatTabsModule } from '@angular/material/tabs';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { MatBadgeModule } from '@angular/material/badge';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
	declarations: [CentralunitsSummaryComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		GridModule,
		MatTabsModule,
		CenteredMessageModule,
		MatBadgeModule,
		KendoGridParentModule,
		NavigationModule
	],
	exports: [CentralunitsSummaryComponent],
})
export class CentralunitsSummaryModule {}
