import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModalParentComponent } from './dialog-modal-parent.component';


import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

import { LiveDataModule } from '../live-data/live-data.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetSettingsModule } from '../widget-settings/widget-settings.module';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
// import { TagGraphModule } from '../tag-graph/tag-graph.module';
// import { GseSummaryModule } from '../gse-summary/gse-summary.module';
// import { GpuSummaryModule } from '../gpu-summary/gpu-summary.module';
// import { PcaSummaryModule } from '../pca-summary/pca-summary.module';
// import { PbbSummaryModule } from '../pbb-summary/pbb-summary.module';
// import { WheelchairSummaryModule } from '../wheelchair-summary/wheelchair-summary.module';
// import { CentralunitsSummaryModule } from '../centralunits-summary/centralunits-summary.module';
// import { GsTopFiveAlarmsSummaryModule } from '../gs-top-five-alarms-summary/gs-top-five-alarms-summary.module';
// import { GsTopFiveEquipmentAlarmsSummaryModule } from '../gs-top-five-equipment-alarms-summary/gs-top-five-equipment-alarms-summary.module';
// import { GseTopFiveEquipmentAlarmsSummaryModule } from '../gse-top-five-equipment-alarms-summary/gse-top-five-equipment-alarms-summary.module';
// import { GseTopFiveAlarmsSummaryModule } from '../gse-top-five-alarms-summary/gse-top-five-alarms-summary.module';
// import { KendoUiGenericPopupModule } from '../kendo-ui-generic-popup/kendo-ui-generic-popup.module';

@NgModule({
	declarations: [DialogModalParentComponent],
	imports: [
		CommonModule,
		LiveDataModule,
		// TagGraphModule,
		// GseSummaryModule,
		// PcaSummaryModule,
		// PbbSummaryModule,
		// GpuSummaryModule,
		// WheelchairSummaryModule,
		// CentralunitsSummaryModule,
		// GsTopFiveAlarmsSummaryModule,
		// GsTopFiveEquipmentAlarmsSummaryModule,
		// GseTopFiveEquipmentAlarmsSummaryModule,
		// GseTopFiveAlarmsSummaryModule,
		// KendoUiGenericPopupModule,
		FormsModule,
		ReactiveFormsModule,
		MatRadioModule,
		MatTooltipModule,
		MatButtonModule,
		WidgetSettingsModule,
		MatIconModule,
		MatBadgeModule
	],
	exports: [DialogModalParentComponent],
})
export class DialogModalParentComponentModule {}
