import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerIssuesItemComponent } from '../customer-issues-item/customer-issues-item.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'lib-dialog-entry',
	templateUrl: './dialog-entry.component.html',
	styleUrls: ['./dialog-entry.component.scss'],
})
export class DialogEntryComponent implements OnInit, OnDestroy {
	constructor(public dialog: MatDialog, private router: Router) {
		console.log('constructor dialog-entry');
		this.openDialog();
	}
	openDialog(): void {
		const dialogRef = this.dialog.open(CustomerIssuesItemComponent, {
			width: '95%',
			height: '95%',
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.router.navigate(['/layout/issues']);
		});
	}

	ngOnInit(): void {
		console.log('dialog-entry.component.ts');
	}

	ngOnDestroy(): void {
		console.log('dialog-entry.component.ts');
	}
}
