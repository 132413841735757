import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDashboardComponent } from './shared-dashboard.component';
import { GridsterModule } from 'angular-gridster2';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WidgetSettingsModule } from '../widget-settings/widget-settings.module';
import { LiveDataModule } from '../live-data/live-data.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '../../_directives/directives.module';
import { MatButtonModule } from '@angular/material/button';
import {
	DatePickerModule,
	DateInputsModule,
} from '@progress/kendo-angular-dateinputs';
import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
	declarations: [SharedDashboardComponent],
	imports: [
		CommonModule,
		GridsterModule,
		MatFormFieldModule,
		MatSelectModule,
		MatTooltipModule,
		MatSlideToggleModule,
		WidgetSettingsModule,
		LiveDataModule,
		TooltipModule,
		FormsModule,
		IonicModule,
		DirectivesModule,
		MatButtonModule,
		DatePickerModule,
		DateInputsModule,
		ReactiveFormsModule,
		FormsModule,
		TourMatMenuModule,
		MatIconModule,
		MatBadgeModule
	],
	exports: [SharedDashboardComponent],
})
export class SharedDashboardModule {}
