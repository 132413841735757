import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KendoGridParentComponent } from './kendo-grid-parent.component';
import {
	ExcelModule,
	GridModule,
	PDFModule,
} from '@progress/kendo-angular-grid';
import { PipeModule } from 'projects/shared-lib/src/lib/_helpers/pipes/pipe.module';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { GridContextMenuModule } from '../grid-context-menu/grid-context-menu.module';
import { SparklineModule } from '@progress/kendo-angular-charts';
import { PerfectTurnModule } from '../perfect-turn/perfect-turn.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { IconsModule } from '@progress/kendo-angular-icons';
import { MatBadgeModule } from '@angular/material/badge';
import { BadgeModule } from '@progress/kendo-angular-indicators';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [KendoGridParentComponent],
	imports: [
		CommonModule,
		GridModule,
		PipeModule,
		ExcelExportModule,
		PDFExportModule,
		ExcelModule,
		PDFModule,
		ProgressbarModule,
		GridContextMenuModule,
		SparklineModule,
		PerfectTurnModule,
		MatTooltipModule,
		MatIconModule,
		IconsModule,
		MatBadgeModule,
		BadgeModule,
		NgbModule,

	],
	exports: [KendoGridParentComponent],
})
export class KendoGridParentModule {}
