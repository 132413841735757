<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Faults Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="
		!isDataLoading &&
		(widgetObject?.SiteList !== null &&
		widgetObject?.SiteList?.length !== 0 ||
		widgetObject?.VocationalSettingsJSON !== null)

	"
	class="card card-tasks"
	id="card-whole"
>
	<!-- <h5 class="card-category">Total Alarms:</h5>
  <h3 class="card-title">
    <i class="tim-icons icon-bell-55 text-primary"> </i>
    {{ this.gridSettings.gridData.data.length }}
  </h3>
  <div class="chart-area"><canvas id="chartLineRed"> </canvas></div> -->

	<div
		(resized)="onResized($event)"
		style="display: inline-block"
		style="width: 100%; height: 100%"
	>
		<div style="height: 10%; width: 100%" #operationalActiveAlarmsContainer>
			<div
				class="row justify-content-center noMargin"
				style="height: 100%; display: flex; flex-wrap: wrap"
				*ngIf="gridData !== undefined"
			>
				<div
					[ngClass]="
						evaluateSelectedFilters().length === 0
							? 'selectedFilter'
							: ''
					"
					class="major-alert-gse-container"
					(click)="filterTableData('All')"
					tooltip="Click to show All Alerts "
				>
					<span
						[style.color]="gridData.length > 0 ? '#02FA03' : 'grey'"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>{{ gridData.length }}
					</span>

					<span
						[style.color]="gridData.length > 0 ? '#02FA03' : 'grey'"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>Total</span
					>
				</div>
				<div
					[ngClass]="
						evaluateSelectedFilters().includes('Critical') === true
							? 'selectedFilter'
							: ''
					"
					class="minor-alert-gse-container"
					(click)="filterTableData('Critical')"
					tooltip="Click to show Critical Alerts "
				>
					<span
						[style.color]="
							alarmTypeCounts.Criticals > 0
								? 'rgba(255, 0, 0, 0.5)'
								: 'grey'
						"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>{{ alarmTypeCounts.Criticals }}
					</span>
					<span
						[style.color]="
							alarmTypeCounts.Criticals > 0
								? 'rgba(255, 0, 0, 0.5)'
								: 'grey'
						"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>Critical</span
					>
				</div>
				<div
					[ngClass]="
						evaluateSelectedFilters().includes('Alarm') === true
							? 'selectedFilter'
							: ''
					"
					class="minor-alert-gse-container"
					(click)="filterTableData('Alarm')"
					tooltip="Click to show Fault Alerts "
				>
					<span
						[style.color]="
							alarmTypeCounts.Alarms > 0
								? 'rgba(219, 45, 45, 0.8)'
								: 'grey'
						"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>{{ alarmTypeCounts.Alarms }}</span
					>
					<span
						[style.color]="
							alarmTypeCounts.Alarms > 0
								? 'rgba(219, 45, 45, 0.8)'
								: 'grey'
						"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>Alarms</span
					>
				</div>
				<div
					[ngClass]="
						evaluateSelectedFilters().includes('Warning') === true
							? 'selectedFilter'
							: ''
					"
					class="minor-alert-gse-container"
					(click)="filterTableData('Warning')"
					tooltip="Click to show Warning Alerts "
				>
					<span
						[style.color]="
							alarmTypeCounts.Warnings > 0 ? 'yellow' : 'grey'
						"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>{{ alarmTypeCounts.Warnings }}
					</span>
					<span
						[style.color]="
							alarmTypeCounts.Warnings > 0 ? 'yellow' : 'grey'
						"
						[style.font-size]="
							operationalActiveAlarmsContainer.offsetWidth / 30 <
							operationalActiveAlarmsContainer.offsetHeight / 2
								? operationalActiveAlarmsContainer.offsetWidth /
										30 +
								  'px'
								: operationalActiveAlarmsContainer.offsetHeight /
										2 +
								  'px'
						"
						class="alert-count-number"
						>Warnings</span
					>
				</div>
			</div>
		</div>
		<div class="row noMargin" style="height: 90%">
			<mat-tab-group
				[(selectedIndex)]="valuePicked"
				(animationDone)="animationRedraw()"
				(selectedTabChange)="tabHasChanged($event)"
				animationDuration="0ms"
				style="width: 100%"
			>
				<mat-tab label="Alarm">
					<div style="width: 100%; height: 100%">
						<lib-kendo-grid-parent
							[gridData]="gridData"
							[gridSettings]="gridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							[gridContextMenuItems]="['Open Summary for Asset',
							'Show Location of Asset']"
							(onRightClickSelectEmitter)="onRightClickSelect($event)"
							*ngIf="gridSettings !== undefined"
							#tagDataGrid

						>
						</lib-kendo-grid-parent>



					</div>
				</mat-tab>
				<mat-tab label="Map">
					<div
						*ngIf="widgetObject !== undefined"
						class="map-container{{ widgetObject.WidgetId }}"
					>
						<div id="regularMap{{ widgetObject.WidgetId }}"></div>
						<div
							class="gseLegend"
							id="legend{{ widgetObject?.WidgetId }}" [style.background]="global.Theme === 'light' ? '#fff': '#27304c'"
						>
							<h3 >Legend</h3>
						</div>
						<div
							id="style-selector-control{{
								widgetObject?.WidgetId
							}}"
							class="map-control"
							style="
								border-color: black;
								border: 1px solid black;
								background-color: white;
								padding: 5px;
								border-radius: 5px;
							"
						>
							<input
								type="radio"
								name="show-hide"
								id="hide-poi{{ widgetObject?.WidgetId }}"
								class="selector-control"
							/>
							<label for="hide-poi" style="color: black"
								>Hide Legend</label
							>
							<input
								type="radio"
								name="show-hide"
								id="show-poi{{ widgetObject?.WidgetId }}"
								class="selector-control"
								checked="checked"
								style="margin-left: 10px"
							/>
							<label for="show-poi" style="color: black"
								>Show Legend</label
							>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
</div>

<div
	*ngIf="!isDataLoading && !widgetObject?.SiteList && !widgetObject?.VocationalSettingsJSON"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Site or Fleet from the widget settings."
	>
	</lib-centered-message>
</div>
