import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericOverviewMapComponent } from './generic-overview-map.component';

@NgModule({
	declarations: [GenericOverviewMapComponent],
	imports: [CommonModule],
	exports: [GenericOverviewMapComponent],
})
export class GenericOverviewMapModule {}
