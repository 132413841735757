<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Perfect Turn Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<sass-helper></sass-helper>
<div *ngIf="!widgetObject && gateName">
	{{ timeToNextArrival | date : "hh:mm a" }}
</div>
<div
	*ngIf="!isDataLoading && widgetObject && widgetObject?.WidgetGateSystemId && widgetObject.WidgetSiteId !== 5"
	class="card card-tasks"
>
	<mat-tab-group
		animationDuration="0ms"
		[(selectedIndex)]="selectedMatTabIndex"
		(selectedTabChange)="matTabHasChanged($event)"
	>
		<mat-tab label="Current" style="position: relative" *ngIf="this.dashboardService.currentDashboard.TimeScopeId">
		<div style="height: 100%; overflow-y: scroll">
			<div
				*ngIf="state.turn != 'Disconnected'"
				class="plane-grid-container"
				style="float: left;"
			>
				<h1 class="pt-gate" style="display: block; margin: auto; color: limegreen">{{ gateName }}</h1>

				<img class="pt-plane"
					style="
						height: 48px;
						width: 48px;
						display: block;
						margin: auto;
					"
					[src]="global.imagesUrl + 'assets/img/airplane.png'"
				/>
				<h5 class="pt-summary"  style="color: limegreen">{{ state.turn }}</h5>
			</div>
			<div
				*ngIf="state.turn == 'Disconnected'"
				style="float: left; padding: 10px"
			>
				<h1 class="pt-gate" style="display: block; margin: auto; color: limegreen">{{ gateName }}</h1>

				<span
					style="
						height: 48px;
						width: 48px;
						font-size: large;
						color: limegreen;
						margin: auto;
					"
					>{{ timeToNextArrival | date : "hh:mm a" }}</span
				>
				<!-- <h5 style="color: limegreen">Next Arrival</h5> -->
			</div>

			<h2 class="Heading">
				Hookup Flight
				{{ hookupFlightInfo.FlightNumber }}
			</h2>
			<h5 class="SubHeading" [style.color]="bodyTextColor">
				Tail:
				<span class="hookup-time-number">{{
					hookupFlightInfo.TailNumber
				}}</span>
				Aircraft:
				<span class="hookup-time-number">{{
					hookupFlightInfo.AircraftType
				}}</span>
			</h5>




			<!-- Dock TO Undock DATA CACHE-->
			<mat-card
					[ngClass]="
						theme === 'dark'
							? 'hookup-details'
							: 'hookup-details-light'
					"
					>
				<div class="grid-container-large">

					<div class="pt-button1">
						<span [ngClass]="
							state.pbb == 'Docked'
									? 'AssetOn'
									: state.pbb == 'Moving'
										? 'AssetOnAlt'
										: 'AssetOff'
								" (click)="openPopup('PBB')">PBB</span>
					</div>

					<div class="text1">

						<span [ngClass]="
							theme === 'dark' ? 'TitleLight' : 'Title'
						">
							{{pbb.duration.negative}}{{ pbb.duration.hours }}:{{ pbb.duration.minutes }}:{{ pbb.duration.seconds }}
						</span>
						<i *ngIf="pbb.duration.totalMS < (3 * 60 * 1000) || pbb.duration.negative == '-'"
							class="fa fa-check-circle" aria-hidden="true" title="pbb.duration UNDER 3 mins"
							style="color: limegreen; margin-right: 5px">
						</i>
						<i *ngIf="pbb.duration.totalMS > (3 * 60 * 1000) && pbb.duration.negative == ''"
							class="fa fa-minus-circle" aria-hidden="true" title="pbb.duration OVER 3 mins"
							style="color: red; margin-right: 5px">
						</i>
					</div>

					<div class="pt-button2">
						<span [ngClass]="
							state.gpu == 'Switchover'
									? 'AssetOn'
									: state.gpu == 'ON'
										? 'AssetOnAlt'
										: 'AssetOff'

							" (click)="openPopup('GPU')">GPU</span>
					</div>

					<div class="text2">

						<span [ngClass]="
							theme === 'dark' ? 'TitleLight' : 'Title'
						">
							{{gputotal.duration.negative}}{{ gputotal.duration.hours }}:{{ gputotal.duration.minutes }}:{{ gputotal.duration.seconds }}
						</span>
						<i *ngIf="(gpu.duration.totalMS+gpuon.duration.totalMS) < (3 * 60 * 1000) || gpu.duration.negative == '-'"
							class="fa fa-check-circle" aria-hidden="true" title="gpu.duration UNDER 3 mins"
							style="color: limegreen; margin-right: 5px">
						</i>
						<i *ngIf="(gpu.duration.totalMS+gpuon.duration.totalMS) > (3 * 60 * 1000) && gpu.duration.negative == ''"
							class="fa fa-minus-circle" aria-hidden="true" title="gpu.duration OVER 3 mins"
							style="color: red; margin-right: 5px">
						</i>

					</div>

					<div class="pt-button3">
						<span [ngClass]="
							turn.pcaUnitOn?.Value=='1' && turn.BridgeMode?.Value == '0'
								? 'AssetOn'
								: 'AssetOff'
								" (click)="openPopup('PCA')">{{siteAirUnitName}}</span>
					</div>

					<div class="text3">

						<span [ngClass]="
							theme === 'dark' ? 'TitleLight' : 'Title'
						">
							{{pca.duration.negative}}{{ pca.duration.hours }}:{{ pca.duration.minutes }}:{{ pca.duration.seconds }}
						</span>

						<i *ngIf="pca.duration.totalMS < (3 * 60 * 1000) || pca.duration.negative == '-'"
							class="fa fa-check-circle" aria-hidden="true" title="pca.duration UNDER 3 mins"
							style="color: limegreen; margin-right: 5px">
						</i>
						<i *ngIf="pca.duration.totalMS > (3 * 60 * 1000) && pca.duration.negative == ''"
							class="fa fa-minus-circle" aria-hidden="true" title="pca.duration OVER 3 mins"
							style="color: red; margin-right: 5px">
						</i>

					</div>

					<div class="summary1">
						<span [ngClass]="
							theme === 'dark' ? 'TitleLight' : 'Title'
						">
							Dock Duration
						</span>
					</div>
					<div class="summary2">
						<span class="TurnDocked" title="dock.duration">
							{{dock.duration.negative}}{{dock.duration.hours}}:{{dock.duration.minutes}}:{{dock.duration.seconds}}
						</span>
					</div>

				</div>
			</mat-card>

<!-- <mat-accordion class="headers-align">

	<mat-expansion-panel>
		<mat-expansion-panel-header  collapsedHeight="50px" expandedHeight="70px">
			<mat-panel-title>
				PBB
			</mat-panel-title>
			<mat-panel-description>
				<span class="Docked" title="">
					{{ turn.docked?.Value == '1' ? 'Docked' : 'Undocked' }}
				</span>
			</mat-panel-description>

		</mat-expansion-panel-header>

			<mat-card
				[ngClass]=" theme === 'dark' ? 'hookup-details' : 'hookup-details-light' "
			>
				<div class="grid-container">
					<div class="left">
						<div
							id="progress-medium"
							[ngStyle]="{
								background: pbb.progressBackground
							}"
						>
							<span
								id="progress-value-medium"
								title="pbb.duration"
								>
								{{pbb.duration.totalMS | date : "mm:ss"}}
							</span>
						</div>
					</div>
					<div class="main">


						<p>
							<span
								*ngIf="turn.isMoving != null"
								title="PBB MoveStart"
								>
								Move Start
							</span>
							<span
								*ngIf="turn.isMoving != null"
								[ngClass]="
									theme === 'dark' ? 'TitleLight' : 'Title'
								"
								title="PBB MoveStart"
								>
								{{ currentTurn.PBBMoveStart | date : "hh:mm:ss a" }}

							</span>
						</p>

						<p>
							<span
								*ngIf="turn.docked != null"
								title="PBB Docked"
								>
								Docked
							</span>

							<span
								*ngIf="turn.docked != null"
								[ngClass]="
									theme === 'dark' ? 'TitleLight' : 'Title'
								"
								title="PBB Docked"
								>
								{{ currentTurn.PBBDocked | date : "hh:mm:ss a" }}
							</span>
						</p>

						<p>
							<span
								*ngIf="currentTurn.PBBUndocked != null"
								title="PBB UNDocked"
								>
								Undocked
							</span>

							<span
								*ngIf="currentTurn.PBBUndocked != null"
								[ngClass]="
									theme === 'dark' ? 'TitleLight' : 'Title'
								"
								title="PBB UNDocked"
								>
								{{ currentTurn.PBBUndocked | date : "hh:mm:ss a" }}
							</span>
						</p>

					</div>
					<div class="right">


						<p>
							<span
								*ngIf="timers.docked != null"
								[ngClass]="
									theme === 'dark'
										? 'DurationLight'
										: 'Duration'
								"
								title="pbb.duration"
								>
								{{pbb.duration.negative}}{{ pbb.duration.hours }}:{{ pbb.duration.minutes }}:{{ pbb.duration.seconds }}
							</span>
						</p>


					</div>
				</div>
			</mat-card>
	</mat-expansion-panel>



	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>
				GPU
			</mat-panel-title>
			<mat-panel-description style="display: flex; justify-content: space-between;">
				<span class="Docked" title="GPU Status">
					{{ state.gpu }}
				</span>
				<span title="Avg Amps Out">
					Avg Amps Out
					<span class="Docked" title="Avg Amps Out">
						{{ this.tags.GPU[1942]?.Value | number : "1.1-1" }}
					</span>
				</span>
			</mat-panel-description>

		</mat-expansion-panel-header>


			<mat-card
				[ngClass]="
				(state.gpu == 'ON' ? 'hookup-details-caution' :
				state.gpu == 'Switchover' ? 'hookup-details-good' :
				'hookup-details') + (theme === 'dark' ? '' : '-light')
				"
			>
				<div class="grid-container">
					<div class="left">
						<div
							id="progress-medium"
							[ngStyle]="{
								background: gpu.progressBackground
							}"
						>
							<span
								id="progress-value-medium"
								title="gpu.duration+gpuon.duration"
								>
								{{(gpu.duration.totalMS+gpuon.duration.totalMS) | date : "mm:ss"}}
								</span
							>
						</div>
					</div>
					<div class="main">

						<p>
							<span
								*ngIf="currentTurn.PBBDocked != null"
								title="PBB Docked"
								>
								Docked
							</span>
							<span
								*ngIf="currentTurn.PBBDocked != null"
								[ngClass]="
									theme === 'dark' ? 'TitleLight' : 'Title'
								"
								title="GPU On"
								>
								{{ currentTurn.PBBDocked | date : "hh:mm:ss a" }}
							</span>
						</p>

						<p>
							<span
								*ngIf="currentTurn.GPUOn != null"
								title="GPU On"
							>
								ON
							</span>
							<span
								*ngIf="currentTurn.GPUOn != null"
								[ngClass]="
									theme === 'dark' ? 'TitleLight' : 'Title'
								"
								title="GPU On"
							>
							{{ currentTurn.GPUOn | date : "hh:mm:ss a" }}

							</span>
						</p>

						<p *ngIf="turn.AircraftOnGPUPower != null">
							<span
								title="AircraftOnGPUPower"
							>
							{{turn.AircraftOnGPUPower.Value == '1' ? 'Switchover' : ''}}
							</span>
							<span
								[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
								title="AircraftOnGPUPower"
							>
							{{ turn.AircraftOnGPUPower?.DateInMilliseconds | date : "hh:mm:ss a" }}
							</span>
						</p>

						<p *ngIf="turn.GPUPowerOn != null">
							<span
								title="GPUPowerOn"
							>
							{{turn.GPUPowerOn.Value == '1' ? 'Switchover' : ''}}
							</span>
							<span
								[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
								title="GPUPowerOn"
							>
							{{ turn.GPUPowerOn?.DateInMilliseconds | date : "hh:mm:ss a" }}
							</span>
						</p>

						<p *ngIf="turn.AmpsOutAverage != null">
							<span
								title="AmpsOutAverage"
							>
							Switchover

							</span>
							<span
								[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
								title=""
							>
							{{ currentTurn.GPUSwitchover | date : "hh:mm:ss a" }}
							</span>
						</p>
					</div>
					<div class="right">

						<p>
							<span
								[ngClass]="
									theme === 'dark'
										? 'DurationLight'
										: 'Duration'
								"
								title="gpu.duration"
								>
								{{gpu.duration.negative}}{{ gpu.duration.hours }}:{{ gpu.duration.minutes }}:{{ gpu.duration.seconds }}
							</span>
						</p>

						<p>
							<span
								[ngClass]="
									theme === 'dark'
										? 'DurationLight'
										: 'Duration'
								"
								title="gpuon.duration"
								>
								{{gpuon.duration.negative}}{{ gpuon.duration.hours }}:{{ gpuon.duration.minutes }}:{{ gpuon.duration.seconds }}
							</span>
						</p>




					</div>
				</div>
			</mat-card>
	</mat-expansion-panel>



	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>
				{{ siteAirUnitName }}
			</mat-panel-title>
			<mat-panel-description style="display: flex; justify-content: space-between;">
				<span class="Docked" title="pcaUnitOn">
					{{ turn.pcaUnitOn?.Value=='1' && turn.BridgeMode?.Value == '0' ? 'Aircraft' : 'OFF' }}
				</span>
				<span title="Discharge Temp (&deg;F)">
					Disch Temp
					<span class="Docked" title="Discharge Temp (&deg;F)">
						{{ this.tags.PCA[2736]?.Value  | number : "1.1-1" }} &deg;F
					</span>
				</span>
			</mat-panel-description>

		</mat-expansion-panel-header>


			<mat-card
				[ngClass]="
					(turn.pcaUnitOn?.Value=='1' && turn.BridgeMode?.Value == '0'
						? 'hookup-details-good'
						: 'hookup-details') + (theme === 'dark' ? '' : '-light')
				"
			>
				<div class="grid-container">
					<div class="left">
						<div
							id="progress-medium"
							[ngStyle]="{
								background: pca.progressBackground
							}"
						>
							<span
								id="progress-value-medium"
								title="pcatotal.duration"
								>
								{{pca.duration.negative}}{{pca.duration.minutes}}:{{pca.duration.seconds}}
								</span
							>
						</div>
					</div>
					<div class="main">


						<p>
							<span
								*ngIf="turn.docked != null"
								title="PBB Docked"
								>
								Docked
							</span>
							<span
								*ngIf=" turn.docked != null "
								[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
								title="PBB Docked"
								>
								{{ currentTurn.PBBDocked | date : "hh:mm:ss a" }}
							</span>
						</p>

						<p>
							<span
								*ngIf="currentDock.PCAVentilatingAircraftMS > 0"
								title="{{ siteAirUnitName }} Ventilating Aircraft"
								>
								Connected
							</span>
							<span
								*ngIf="currentDock.PCAVentilatingAircraftMS > 0"
								[ngClass]="
									theme === 'dark' ? 'TitleLight' : 'Title'
								"
								title="{{ siteAirUnitName }} OFF"
							>
								{{	currentTurn.PCAVentilatingAircraft | date : "hh:mm:ss a" }}
							</span>
						</p>
					</div>
					<div class="right">


						<p *ngIf="turn.docked != null">
							<span
								[ngClass]=" theme === 'dark' ? 'DurationLight' : 'Duration' "
								title="pca.duration"
								>
								{{pca.duration.negative}}{{ pca.duration.hours }}:{{ pca.duration.minutes }}:{{ pca.duration.seconds }}
							</span>
						</p>


					</div>
				</div>
			</mat-card>

	</mat-expansion-panel>
</mat-accordion> -->


<div  *ngIf="PBBvisible" [@slideInOut] class="override">
	<mat-card id="override"
	[ngClass]=" theme === 'dark' ? 'hookup-details' : 'hookup-details-light' "
>
<mat-card-title>
	PBB
</mat-card-title>
<mat-card-subtitle>
	<span class="Docked" title="">
		{{ turn.docked?.Value == '1' ? 'Docked' : 'Undocked' }}
	</span>
</mat-card-subtitle>
	<div class="grid-container">
		<div class="left">
			<div
				id="progress-medium"
				[ngStyle]="{
					background: pbb.progressBackground
				}"
			>
				<span
					id="progress-value-medium"
					title="pbb.duration"
					>
					{{pbb.duration.totalMS | date : "mm:ss"}}
				</span>
			</div>
		</div>
		<div class="main">


			<p>
				<span
					*ngIf="turn.isMoving != null"
					title="PBB MoveStart"
					>
					Move Start
				</span>
				<span
					*ngIf="turn.isMoving != null"
					[ngClass]="
						theme === 'dark' ? 'TitleLight' : 'Title'
					"
					title="PBB MoveStart"
					>
					{{ currentTurn.PBBMoveStart | date : "hh:mm:ss a" }}

				</span>
			</p>

			<p>
				<span
					*ngIf="turn.docked != null"
					title="PBB Docked"
					>
					Docked
				</span>

				<span
					*ngIf="turn.docked != null"
					[ngClass]="
						theme === 'dark' ? 'TitleLight' : 'Title'
					"
					title="PBB Docked"
					>
					{{ currentTurn.PBBDocked | date : "hh:mm:ss a" }}
				</span>
			</p>

			<p>
				<span
					*ngIf="currentTurn.PBBUndocked != null"
					title="PBB UNDocked"
					>
					Undocked
				</span>

				<span
					*ngIf="currentTurn.PBBUndocked != null"
					[ngClass]="
						theme === 'dark' ? 'TitleLight' : 'Title'
					"
					title="PBB UNDocked"
					>
					{{ currentTurn.PBBUndocked | date : "hh:mm:ss a" }}
				</span>
			</p>

		</div>
		<div class="right">


			<p>
				<span
					*ngIf="timers.docked != null"
					[ngClass]="
						theme === 'dark'
							? 'DurationLight'
							: 'Duration'
					"
					title="pbb.duration"
					>
					{{pbb.duration.negative}}{{ pbb.duration.hours }}:{{ pbb.duration.minutes }}:{{ pbb.duration.seconds }}
				</span>
			</p>


		</div>
	</div>
</mat-card>


</div>

<div  *ngIf="GPUvisible" [@slideInOut]>
	<mat-card
	[ngClass]="
	(state.gpu == 'ON' ? 'hookup-details-caution' :
	state.gpu == 'Switchover' ? 'hookup-details-good' :
	'hookup-details') + (theme === 'dark' ? '' : '-light')
	"
>
<mat-card-title>
	GPU
</mat-card-title>
<mat-card-subtitle>
	<span class="Docked" title="GPU Status">
		{{ state.gpu }}
	</span>
	<span title="Avg Amps Out">
		Avg Amps Out
		<span class="Docked" title="Avg Amps Out">
			{{ this.tags.GPU[1942]?.Value | number : "1.1-1" }}
		</span>
	</span>
</mat-card-subtitle>
	<div class="grid-container">
		<div class="left">
			<div
				id="progress-medium"
				[ngStyle]="{
					background: gpu.progressBackground
				}"
			>
				<span
					id="progress-value-medium"
					title="gpu.duration+gpuon.duration"
					>
					{{(gpu.duration.totalMS+gpuon.duration.totalMS) | date : "mm:ss"}}
					</span
				>
			</div>
		</div>
		<div class="main">

			<p>
				<span
					*ngIf="currentTurn.PBBDocked != null"
					title="PBB Docked"
					>
					Docked
				</span>
				<span
					*ngIf="currentTurn.PBBDocked != null"
					[ngClass]="
						theme === 'dark' ? 'TitleLight' : 'Title'
					"
					title="GPU On"
					>
					{{ currentTurn.PBBDocked | date : "hh:mm:ss a" }}
				</span>
			</p>

			<p>
				<span
					*ngIf="currentTurn.GPUOn != null"
					title="GPU On"
				>
					ON
				</span>
				<span
					*ngIf="currentTurn.GPUOn != null"
					[ngClass]="
						theme === 'dark' ? 'TitleLight' : 'Title'
					"
					title="GPU On"
				>
				{{ currentTurn.GPUOn | date : "hh:mm:ss a" }}

				</span>
			</p>

			<p *ngIf="turn.AircraftOnGPUPower != null">
				<span
					title="AircraftOnGPUPower"
				>
				{{turn.AircraftOnGPUPower.Value == '1' ? 'Switchover' : ''}}
				</span>
				<span
					[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
					title="AircraftOnGPUPower"
				>
				{{ turn.AircraftOnGPUPower?.DateInMilliseconds | date : "hh:mm:ss a" }}
				</span>
			</p>

			<p *ngIf="turn.GPUPowerOn != null">
				<span
					title="GPUPowerOn"
				>
				{{turn.GPUPowerOn.Value == '1' ? 'Switchover' : ''}}
				</span>
				<span
					[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
					title="GPUPowerOn"
				>
				{{ turn.GPUPowerOn?.DateInMilliseconds | date : "hh:mm:ss a" }}
				</span>
			</p>

			<p *ngIf="turn.AmpsOutAverage != null">
				<span
					title="AmpsOutAverage"
				>
				Switchover

				</span>
				<span
					[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
					title=""
				>
				{{ currentTurn.GPUSwitchover | date : "hh:mm:ss a" }}
				</span>
			</p>
		</div>
		<div class="right">

			<p>
				<span
					[ngClass]="
						theme === 'dark'
							? 'DurationLight'
							: 'Duration'
					"
					title="gpu.duration"
					>
					{{gpu.duration.negative}}{{ gpu.duration.hours }}:{{ gpu.duration.minutes }}:{{ gpu.duration.seconds }}
				</span>
			</p>

			<p>
				<span
					[ngClass]="
						theme === 'dark'
							? 'DurationLight'
							: 'Duration'
					"
					title="gpuon.duration"
					>
					{{gpuon.duration.negative}}{{ gpuon.duration.hours }}:{{ gpuon.duration.minutes }}:{{ gpuon.duration.seconds }}
				</span>
			</p>

			<!-- <p>
				<span
					[ngClass]="
						theme === 'dark'
							? 'DurationLight'
							: 'Duration'
					"
					title="gpuswitchover.duration"
					>
					{{gpuswitchover.duration.negative}}{{ gpuswitchover.duration.hours }}:{{ gpuswitchover.duration.minutes }}:{{ gpuswitchover.duration.seconds }}
				</span>
			</p> -->


		</div>
	</div>
</mat-card>
</div>

<div  *ngIf="PCAvisible" [@slideInOut]>
	<mat-card
	[ngClass]="
		(turn.pcaUnitOn?.Value=='1' && turn.BridgeMode?.Value == '0'
			? 'hookup-details-good'
			: 'hookup-details') + (theme === 'dark' ? '' : '-light')
	"
>
<mat-card-title>
	{{ siteAirUnitName }}
</mat-card-title>
<mat-card-subtitle>
	<span class="Docked" title="pcaUnitOn">
		{{ turn.pcaUnitOn?.Value=='1' && turn.BridgeMode?.Value == '0' ? 'Aircraft' : 'OFF' }}
	</span>
	<span title="Discharge Temp (&deg;F)">
		Disch Temp
		<span class="Docked" title="Discharge Temp (&deg;F)">
			{{ this.tags.PCA[2736]?.Value  | number : "1.1-1" }} &deg;F
		</span>
	</span>
</mat-card-subtitle>
	<div class="grid-container">
		<div class="left">
			<div
				id="progress-medium"
				[ngStyle]="{
					background: pca.progressBackground
				}"
			>
				<span
					id="progress-value-medium"
					title="pcatotal.duration"
					>
					{{pca.duration.negative}}{{pca.duration.minutes}}:{{pca.duration.seconds}}
					</span
				>
			</div>
		</div>
		<div class="main">


			<p>
				<span
					*ngIf="turn.docked != null"
					title="PBB Docked"
					>
					Docked
				</span>
				<span
					*ngIf=" turn.docked != null "
					[ngClass]=" theme === 'dark' ? 'TitleLight' : 'Title' "
					title="PBB Docked"
					>
					{{ currentTurn.PBBDocked | date : "hh:mm:ss a" }}
				</span>
			</p>

			<p>
				<span
					*ngIf="currentDock.PCAVentilatingAircraftMS > 0"
					title="{{ siteAirUnitName }} Ventilating Aircraft"
					>
					Connected
				</span>
				<span
					*ngIf="currentDock.PCAVentilatingAircraftMS > 0"
					[ngClass]="
						theme === 'dark' ? 'TitleLight' : 'Title'
					"
					title="{{ siteAirUnitName }} OFF"
				>
					{{	currentTurn.PCAVentilatingAircraft | date : "hh:mm:ss a" }}
				</span>
			</p>
		</div>
		<div class="right">


			<p *ngIf="turn.docked != null">
				<span
					[ngClass]=" theme === 'dark' ? 'DurationLight' : 'Duration' "
					title="pca.duration"
					>
					{{pca.duration.negative}}{{ pca.duration.hours }}:{{ pca.duration.minutes }}:{{ pca.duration.seconds }}
				</span>
			</p>


		</div>
	</div>
</mat-card>
</div>


</div>

</mat-tab>

		<mat-tab label="Statistics">
			<div class="container-fluid" style="height: 100%">
				<div style="height: 20%; width: 100%">
					<div
						*ngIf="!isDataLoading && hasData"
						class="row no-gutters"
						style="border-bottom: 1px solid"
					>
						<div
							class="col-6 no-gutters hookup-details-container"
							style="height: 100%; border-right: 1px solid"
						>
							<h4>Hookups</h4>
							<p>
								Success Rate:
								<span
									class="hookup-time-number"
									[style.color]="bodyTextColor"
									>{{
										statistics.hookupSuccessPercent
											? statistics.hookupSuccessPercent +
											  "%"
											: "N/A"
									}}</span
								>
							</p>
							<p>
								AVG Time:
								<span
									class="hookup-time-number"
									[style.color]="bodyTextColor"
									>{{
										statistics.hookupAvgTime
											? statistics.hookupAvgTime
											: "N/A"
									}}</span
								>
							</p>
						</div>
						<div
							class="col-6 no-gutters hookup-details-container"
							style="height: 100%"
						>
							<h4>Disconnects</h4>
							<p>
								Success Rate:
								<span
									class="hookup-time-number"
									[style.color]="bodyTextColor"
									>{{
										statistics.disconnectSuccessPercent
											? statistics.disconnectSuccessPercent +
											  "%"
											: "N/A"
									}}</span
								>
							</p>
							<p>
								AVG Time:
								<span
									class="hookup-time-number"
									[style.color]="bodyTextColor"
									>{{
										statistics.disconnectAvgTime
											? statistics.disconnectAvgTime
											: "N/A"
									}}</span
								>
							</p>
						</div>
					</div>

					<div style="text-align: center; padding:5px;">
						<h4>
							Total Events {{allPastEvents.length}}
						</h4>
					</div>
				</div>



				<div class="row" style="height: 30%; text-align: center">
					<div
						id="gsPerfectHookupHistoric{{ widgetObject.WidgetId }}"
						style="width: 100%; height: 100%"
					>
						<div
							*ngIf="
								(!isDataLoading && !hasData) ||
								(allPastEvents.length == 0 && !hasData)
							"
							[style.color]="bodyTextColor"
						>
							No data available for the selected timescope.
						</div>
					</div>
				</div>
				<div class="row" style="height: 50%">
					<div
						*ngIf="!isDataLoading && hasData"
						class="parent-table-container"
					>
						<kendo-grid
							#eventGrid
							[kendoGridBinding]="allPastEvents"
							[sortable]="true"
							[scrollable]="
								widgetObject !== undefined
									? widgetObject.scrollmode
									: 'scrollable'
							"
							[columnMenu]="{
								lock:
									widgetObject === undefined
										? true
										: widgetObject.scrollmode ===
										  'scrollable'
										? true
										: false
							}"
							[pageable]="
								pastEventsGridSettings.state.take !== undefined
									? { pageSizes: true }
									: false
							"
							[pageSize]="pastEventsGridSettings.state.take"
							[skip]="pastEventsGridSettings.state.skip"
							[resizable]="true"
							[reorderable]="true"
							[style.height.%]="100"
							(cellClick)="createHistoricChart($event)"
						>
							<ng-template kendoGridNoRecordsTemplate
								>No events have occured.</ng-template
							>
							<kendo-grid-column
								[style]="{ cursor: 'pointer' }"
								*ngFor="
									let column of pastEventsGridSettings.columnsConfig
								"
								title="{{ column.title }}"
								field="{{ column.field }}"
								[width]="column._width"
								[minResizableWidth]="column.minResizableWidth"
								[filter]="column.filter"
								[hidden]="column.hidden"
								[includeInChooser]="column.includeInChooser"
							>
								<ng-template
									kendoGridCellTemplate
									let-dataItem
									*ngIf="column.filter === 'date'"
								>
									{{
										dataItem[column.field]
											| date : "M/d/yy, h:mm:ss a"
									}}
								</ng-template>
							</kendo-grid-column>
							<ng-template
								kendoPagerTemplate
								let-totalPages="totalPages"
								let-currentPage="currentPage"
							>
								<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
								<kendo-pager-numeric-buttons
									[buttonCount]="5"
								></kendo-pager-numeric-buttons>
								<kendo-pager-next-buttons></kendo-pager-next-buttons>
								<!-- <kendo-pager-input></kendo-pager-input> -->
								<kendo-pager-info></kendo-pager-info>
								<kendo-pager-page-sizes
									[pageSizes]="[15, 30, 50]"
								></kendo-pager-page-sizes>
							</ng-template>
						</kendo-grid>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
<div
	*ngIf="!isDataLoading && widgetObject && widgetObject.WidgetSiteId == 5"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Must have at least 2 Gate Assets registered to display Chart and Statistics."
	></lib-centered-message>
</div>

<div
	*ngIf="!isDataLoading && widgetObject && !widgetObject?.WidgetGateSystemId && widgetObject.WidgetSiteId != 5"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Gate System from the widget settings."
	></lib-centered-message>
</div>
