<!-- Edit Issue -->
<div class="card card-tasks" id="card-whole">
	<div class="card-header user-settings">
		<h4 class="card-title d-inline">{{ editHeader }}</h4>
		<button mat-button style="display: inline; float: right; padding-top: 5px;" (click)="closeDialog()" matTooltip="Close" [matTooltipPosition]="'below'">
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
		<h5 class="card-subtitle" style="margin-top: 5px;">{{ editSubHeader }}</h5>
	</div>
	<div class="card-body user-settings">
		<form [formGroup]="issueForm" autocomplete="off" (ngSubmit)="submitIssue(issueForm.value)">
			<!-- Subject -->
			<div class="row col-sm-12 col-md-12">
				<div class="col-sm-2 col-md-2 col-form-label">
					<label for="subjectId">Subject</label>
				</div>
				<div class="col-sm-10 col-md-10">
					<div class="form-group">
						<select class="form-control" title="Enter the subject of this issue." id="subjectId" formControlName="subjectId">
							<option class="form-control" *ngFor="let subject of subjects" [value]="subject.Id">{{ subject.Name }}</option>
						</select>
					</div>
				</div>
			</div>
			<!-- Description -->
			<div class="row col-sm-12 col-md-12">
				<div class="col-sm-2 col-md-2 col-form-label">
					<label for="description">Description</label>
				</div>
				<div class="col-sm-10 col-md-10">
					<div class="form-group">
						<textarea class="form-control" title="Please enter the description of the issue here." formControlName="description" id="description" rows="3" cols="150" placeholder="{{ !validateDescription() || mouseoverRegistration ? 'Description is required' : '' }}" style="resize: both !important;"></textarea>
					</div>
				</div>
			</div>

			<div class="card-footer text-center">
				<button class="btn btn-danger" type="submit" [disabled]="issueForm.invalid">Submit Issue</button>
				<button class="btn" type="button" (click)="closeDialog()">Cancel</button>
			</div>
		</form>
	</div>
</div>
