import {
	Directive,
	Component,
	Input,
	ViewContainerRef,
	TemplateRef,
	AfterViewInit,
	OnInit,
} from '@angular/core';
import { DashboardService } from '../services/dashboard.service';

@Directive({
	selector: '[appInView]',
})
export class InViewDirective implements AfterViewInit, OnInit {
	alreadyRendered: boolean; // cheking if visible already

	constructor(
		private vcRef: ViewContainerRef,
		private tplRef: TemplateRef<any>,
		private dashboardService: DashboardService
	) {
		console.log('constructor');
	}

	ngOnInit() {
		console.log('initialized');
	}
	ngAfterViewInit() {
		const commentEl = this.vcRef.element.nativeElement; // template
		const elToObserve = commentEl.parentElement;
		this.setMinWidthHeight(elToObserve);

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					this.renderContents(entry.isIntersecting, elToObserve);
				});
			},
			{ threshold: [0, 0.1, 0.9, 1] }
		);
		observer.observe(elToObserve);
	}

	renderContents(isInView, elToObserve) {
		//check only elements that are widgets
		if (elToObserve.id.includes('widget')) {
			let widgetId = +elToObserve.id.split('-')[1];
			this.dashboardService.inViewEmitter(isInView, widgetId);
		}

		if (isInView && !this.alreadyRendered) {
			this.vcRef.clear();
			this.vcRef.createEmbeddedView(this.tplRef);
			this.alreadyRendered = true;
		}
	}

	setMinWidthHeight(el) {
		// prevent issue being visible all together
		const style = window.getComputedStyle(el);
		const [width, height] = [parseInt(style.width), parseInt(style.height)];
		!width && (el.style.minWidth = '40px');
		!height && (el.style.minHeight = '40px');
	}
}
