import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-live-data',
  templateUrl: './live-data.component.html',
  styleUrls: ['./live-data.component.scss']
})
export class LiveDataComponent implements OnInit {
  @Input() live: boolean;

  constructor() { }

  ngOnInit() {
    //console.log(this.live);
  }

}
