<div *ngIf="!isLoading" class="content">
	<div class="row">
		<div class="card" >
			<div class="card-header">
				<h4 class="card-title d-inline" style="padding-left: 15px">{{ title }}</h4>
                <button mat-button style="display: inline; float: right; padding-top: 5px;" (click)="closeDialogWindow()" matTooltip="Close" [matTooltipPosition]="'below'">
                    <div>
                        <i class="tim-icons icon-simple-remove"></i>
                    </div>
                </button>
				<h5 class="card-subtitle" style="padding-left: 15px; margin-top: 5px">{{ subTitle }}</h5>
			</div>
			<div *ngIf="!isLoading">
				<div style="overflow-y: auto !important; height: 500px">
					<lib-dynamic-form [questions]="editQuestions" [options]="formOptions" (submittedValues)="submitForm($event)"></lib-dynamic-form>
				</div>
			</div>
		</div>
	</div>
</div>
