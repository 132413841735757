import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaPressuresComponent } from './pca-pressures.component';



@NgModule({
  declarations: [
    PcaPressuresComponent
  ],
  imports: [
    CommonModule
  ], exports: [PcaPressuresComponent]
})
export class PcaPressuresModule { }
