<div
	*ngIf="widgetObject !== undefined && !widgetObject?.WidgetSiteId && !widgetObject?.VocationalSettingsJSON"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a site or fleet from the widget settings."
	>
	</lib-centered-message>
</div>
<div
	class="panel-body"
	style="width: 100%; height: 100%; padding: 0"
	[hidden]="!this.showWidgetBody"
>
	<div
		style="
			overflow-y: scroll;
			height: 100%;
			margin-top: 15px;
			padding-bottom: 5px;
			padding-top: 5px;
			position: relative;
			font-family: Segoe UI;
			font-size: 0.8em;
		"
		id="processButtonSelectionsDiv{{ widgetObject.WidgetId }}"
	>
		<!-- <div style="flex-basis: 70px; min-width: 70px; background: linear-gradient(to bottom, #bebebe, #efefef); overflow-x: hidden; overflow-y: hidden; margin-right: 3px">
			<label style="margin: 0; background: #337ab7; color: white; width: 100%; text-align: center; padding: 5px; font-size: 0.9em; margin-bottom: 4px">Site</label>
			<div style="flex-basis: 80px; width: 100%; min-width: 50px; height: 100%; background: linear-gradient(to bottom, #bebebe, #efefef); overflow-x: hidden; overflow-y: auto; margin-right: 3px; padding-left: 2px; padding-right: 2px">
				<button *ngFor="let site of sites" class="btn mr-1" (click)="selectSite($event, site.Id)">
					{{ site.Name }}
				</button>
			</div>
		</div>
		<div style="flex-basis: 70px; min-width: 70px; background: linear-gradient(to bottom, #bebebe, #efefef); overflow-x: hidden; overflow-y: hidden; margin-right: 3px">
			<label style="margin: 0; background: #337ab7; color: white; width: 100%; text-align: center; padding: 5px; font-size: 0.9em; margin-bottom: 4px">Gate</label>
			<div style="flex-basis: 80px; width: 100%; min-width: 50px; height: 100%; background: linear-gradient(to bottom, #bebebe, #efefef); overflow-x: hidden; overflow-y: auto; margin-right: 3px; padding-left: 2px; padding-right: 2px">
				<button *ngFor="let gateSystem of gates" class="btn mr-1" (click)="selectGateSystem($event, gateSystem.Id)">
					{{ gateSystem.Name }}
				</button>
			</div>
		</div>
		<div style="flex-basis: 60px; min-width: 60px; background: linear-gradient(to bottom, #bebebe, #efefef); overflow-x: hidden; overflow-y: auto; margin-right: 15px">
			<label style="margin: 0; background: #337ab7; color: white; width: 100%; text-align: center; padding: 5px; font-size: 0.9em; margin-bottom: 4px">Asset</label>
			<button *ngFor="let asset of assets" class="btn mr-1" (click)="selectAsset($event, asset.Id)">
				{{ asset.Name }}
			</button>
		</div> -->
		<!--         <div id="alarmEmailsInnerBlock{{widgetObject.WidgetId}}" style="border: none; overflow-y: auto; display: block; width: 100%; " ng-click="ProcessButtonSelections()"> -->
		<div
			id="alarmEmailsInnerBlock{{ widgetObject.WidgetId }}"
			style="display: block; width: 100%"
			(click)="ProcessButtonSelections()"
		>
			<!--Larger Scope Items-->
			<div
				style="
					padding-left: 10px;
					padding-right: 10px;
					width: 100%;
					display: block;
				"
				*ngIf="buttonSelections !== undefined"
			>
				<h6 style="text-align: center;">{{oneAlertEnabled}}</h6>

				<mat-accordion class="headers-align">


					<!-- <mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Alert Control Panel
							</mat-panel-title>
							<mat-panel-description>
								<div>
									{{alertSummaryCount}} Alerts are enabled
								</div>

								<i  class="fa fa-info-circle" aria-hidden="true"
									[matTooltip]="alertSummaryInfo"
									[matTooltipPosition]="'below'"
									matTooltipClass="multiline-tooltip"
									style="margin-right: 5px">
								</i>

							</mat-panel-description>
						</mat-expansion-panel-header>

						<div>

							<lib-alert-control-panel></lib-alert-control-panel>

						</div>

					</mat-expansion-panel> -->






					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Settings Summary
							</mat-panel-title>
							<mat-panel-description>
								<div>
									{{alertSummaryCount}} Alerts are enabled
								</div>

								<i  class="fa fa-info-circle" aria-hidden="true"
									[matTooltip]="alertSummaryInfo"
									[matTooltipPosition]="'below'"
									matTooltipClass="multiline-tooltip"
									style="margin-right: 5px">
								</i>

							</mat-panel-description>
						</mat-expansion-panel-header>

						<div>
							<div *ngFor=" let summary of alertSummary ">
								<p>
									<span [ngClass]="summary.alertType">{{summary.category}}</span>
									<span [ngClass]="summary.notificationActive">{{summary.text}}</span>
								</p>
							</div>
						</div>

					</mat-expansion-panel>


<!--
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								GSE Asset Types
							</mat-panel-title>
							<mat-panel-description>
								<div>
								</div>
							</mat-panel-description>
						</mat-expansion-panel-header>

						<div>

							<div *ngFor=" let assetType of gseAssetTypes ">
								<div class="row">

								<div class="column-one">
										<div   id="ck-button" >
											<label class="medium">
												<input type="checkbox" title="assettypes"/>
												<span>{{assetType.Name}}</span>
											</label>
										</div>
								</div>
								<div class="column-two">
										<div *ngFor=" let assetModel of assetType.models " >

												<div   id="ck-button" >
													<label class="medium">
														<input type="checkbox" title="assetmodels"/>
														<span>{{assetModel.SelectorName}}</span>
													</label>
												</div>

										</div>

								</div>

								</div>
							</div>

						</div>











					</mat-expansion-panel> -->


					<mat-expansion-panel *ngFor=" let buttonSelectionInstance of buttonSelections | keyvalue : valueOrder " >
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div>
									{{
										buttonSelectionInstance.key ===
										"criticalSite"
											? "All Site: Critical"
										: buttonSelectionInstance.key ===
										"allGPUCriticalSite"
											? "All GPU: Critical"
										: buttonSelectionInstance.key ===
										"criticalGateSystem"
											? "Individual Gate System: Critical"

										: buttonSelectionInstance.key === "criticalAsset" && widgetObject?.WidgetSiteId != null  ? "Individual Asset: Critical"
										: buttonSelectionInstance.key === "criticalAsset" && widgetObject?.VocationalSettingsJSON != null  ? "Individual Asset: Red"
										: buttonSelectionInstance.key === "alertAsset" ? "Individual Asset: Alert"
										: buttonSelectionInstance.key === "asset" && widgetObject?.WidgetSiteId != null ? "Individual Asset: Alarm"
										: buttonSelectionInstance.key === "asset" && widgetObject?.VocationalSettingsJSON != null ? "Individual Asset: Amber"

										: buttonSelectionInstance.key ===
										"allGPUSite"
											? "All GPUs"
										: buttonSelectionInstance.key ===
										"allPBBCriticalSite"
											? "All PBB: Critical"
										: buttonSelectionInstance.key ===
										"allPBBSite"
											? "All PBB"
										: buttonSelectionInstance.key ===
										"allPCACriticalSite"
											? "All PCA: Critical"
										: buttonSelectionInstance.key ===
										"allPCASite"
											? "All PCA"
										: buttonSelectionInstance.key ===
										"site"
											? "All Site"
										: buttonSelectionInstance.key ===
										"tag"
											? "Individual Tag"
										: buttonSelectionInstance.key === "JbtStandardObservation" && widgetObject?.WidgetSiteId != null  ? "Individual Tag Site"
										: buttonSelectionInstance.key === "JbtStandardObservation" && widgetObject?.VocationalSettingsJSON != null  ? "Individual Tag Fleet"
										: buttonSelectionInstance.key ===
										"gateSystem"
											? "Individual Gate System"
										: buttonSelectionInstance.key ===
											"allGSECriticalSite"
												? "All GSE: Critical"
										: buttonSelectionInstance.key ===
											"allGSESite"
												? "All GSE"
										: buttonSelectionInstance.key ===
											"CriticalGSEAssetType"
												? "GSE Asset Type: Critical"
										: buttonSelectionInstance.key ===
											"GSEAssetType"
												? "GSE Asset Type"
										: buttonSelectionInstance.key ===
											"CriticalGSEAssetModel"
												? "GSE Asset Model: Critical"
										: buttonSelectionInstance.key ===
											"GSEAssetModel"
												? "GSE Asset Model"
										: buttonSelectionInstance.key ===
											"CriticalThing"
												? "Fleet: Red"
										: buttonSelectionInstance.key ===
											"AlertThing"
												? "Fleet: Alert"
										: buttonSelectionInstance.key ===
											"Thing"
												? "Fleet: Amber"
										: buttonSelectionInstance.key

									}}
								</div>
							</mat-panel-title>
							<mat-panel-description>

								<div>
									{{validation[buttonSelectionInstance.key].description}}
								</div>

								<mat-icon
									[ngClass]="validation[buttonSelectionInstance.key].emailOrText == ''
									&& validation[buttonSelectionInstance.key].days == ''
									&& validation[buttonSelectionInstance.key].hours == ''
									&& person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
									*ngIf="buttonSelectionInstance.value.enabled[ widgetObject.WidgetSiteId ]
											|| buttonSelectionInstance.value.enabled[ widgetObject.WidgetGateSystemId ]
											|| buttonSelectionInstance.value.enabled[ widgetObject.WidgetAssetId ]
											|| buttonSelectionInstance.value.enabled[ myFleet.FleetId ]">
									{{validation[buttonSelectionInstance.key].emailOrText == ''
										&& validation[buttonSelectionInstance.key].days == ''
										&& validation[buttonSelectionInstance.key].hours == ''
										&& person.CellPhoneCarrierId != undefined ? 'check_circle_outline' : 'help_outline'}}
								</mat-icon>
							</mat-panel-description>
						</mat-expansion-panel-header>
						<div *ngIf="buttonSelectionInstance.key !== 'tag'
								&& buttonSelectionInstance.key !== 'JbtStandardObservation'
								&& buttonSelectionInstance.key !== 'CriticalGSEAssetType' && buttonSelectionInstance.key !== 'GSEAssetType'
								&& buttonSelectionInstance.key !== 'CriticalGSEAssetModel' && buttonSelectionInstance.key !== 'GSEAssetModel'
								&& buttonSelectionInstance.key !== 'gateSystem'  && buttonSelectionInstance.key !== 'criticalGateSystem'
								&& buttonSelectionInstance.key !== 'asset'  && buttonSelectionInstance.key !== 'criticalAsset' && buttonSelectionInstance.key !== 'alertAsset'
								&& buttonSelectionInstance.key !== 'CriticalThing' && buttonSelectionInstance.key !== 'AlertThing'  && buttonSelectionInstance.key !== 'Thing'"
								>
							Enable Alerts
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]="
									buttonSelectionInstance.value.enabled[
										widgetObject.WidgetSiteId
									]
								"
							/>



							<ng-container
								*ngIf="
									buttonSelectionInstance.value.enabled[
										widgetObject.WidgetSiteId
									]
								"
							>

								<div class="form-group" style="display:flex; flex-direction: row;">
									<div class="col-sm-2 col-md-2 col-form-label">
										<label for="minutestowait">Alert Wait minutestowait</label>
									</div>
									<div class="col-sm-10 col-md-10">
										<input type="text" class="form-control" id="minutestowait" name="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
												placeholder="Minutes Between Notification (minimum 15)"
												[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetSiteId]">
										<div>
											<span style="color:'red';">
												{{buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetSiteId] != null &&
													buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetSiteId] != '' &&
													buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetSiteId] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
											</span>
										</div>
									</div>
								</div>


								<mat-accordion class="headers-align">

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												Escalation
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[widgetObject.WidgetSiteId]">
													check_circle_outline
												</mat-icon >
											</mat-panel-description >
										</mat-expansion-panel-header>

										<div>
											<h4>My Escalations</h4>
											<div class="indent" *ngIf="alternateEmailForwards.length == 0">
												<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
											</div>
											<div class="indent" *ngFor=" let person of alternateEmailForwards">
												{{person.text}}
											</div>
										</div>

										<div class="form-group" style="display:flex; flex-direction: row;">
											<div class="col-sm-2 col-md-2 col-form-label">
												<label for="minutestowait">Alert Escalation Wait</label>
											</div>
											<div class="col-sm-10 col-md-10">
												<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
														placeholder="Minutes to notification to my escalations"
														[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[widgetObject.WidgetSiteId]">
											</div>
										</div>
									</mat-expansion-panel>



									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Email
											</mat-panel-title>
											<mat-panel-description>
												<span class='warning'>
													{{validation[buttonSelectionInstance.key].emailOrText}}</span>
												<mat-icon
													[ngStyle]="{
														color: 'green'
													}"
													*ngIf="
														buttonSelectionInstance
															.value.email[
															widgetObject
																.WidgetSiteId
														]
													"
													>check_circle_outline</mat-icon
												></mat-panel-description
											>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.email[
													widgetObject.WidgetSiteId
												]
											"
											title="Email"
										/>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Text Message</mat-panel-title
											>
											<mat-panel-description
												>
												<span class='warning' *ngIf=" buttonSelectionInstance.value.textMessage[ widgetObject.WidgetSiteId ]
																				&& person.CellPhoneCarrierId == undefined">
													No Carrier found. Go to Personal Information to add your carrier.
												</span>
												<mat-icon
													[ngClass]="person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
													*ngIf=" buttonSelectionInstance.value.textMessage[ widgetObject.WidgetSiteId ] ">
													check_circle_outline
												</mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.textMessage[
													widgetObject.WidgetSiteId
												]
											"
											title="Text"
										/>
									</mat-expansion-panel>

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Send On All Days
											</mat-panel-title>
											<mat-panel-description>
												<span class='warning'>
													{{validation[buttonSelectionInstance.key].days}}
												</span>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf=" buttonSelectionInstance.value.allDays[ widgetObject.WidgetSiteId ] " >
													check_circle_outline
												</mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input class="form-check-input" type="checkbox"
											[(ngModel)]=" buttonSelectionInstance.value.allDays[ widgetObject.WidgetSiteId ] "
											title="All Days" />
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												Select Days To Send
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ widgetObject.WidgetSiteId ]
																&&
																(buttonSelectionInstance.value.days['Mon'][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.days['Tue'][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.days['Wed'][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.days['Thu'][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.days['Fri'][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.days['Sat'][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.days['Sun'][widgetObject.WidgetSiteId] == true
																)
																">
													check_circle_outline </mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div *ngIf="buttonSelectionInstance.value.allDays[widgetObject.WidgetSiteId]">
											<h6>Unselect Send On All Days to select specific days</h6>
										</div>
										<div *ngIf="!buttonSelectionInstance.value.allDays[ widgetObject.WidgetSiteId ] " >
											<div *ngFor=" let dayOfWeek of dataCollections.daysOfWeek " class="form-check-inline" >
												<div *ngIf=" buttonSelectionInstance.value.days[ dayOfWeek ] !== undefined " id="ck-button" >
													<label>
														<input type="checkbox"
														[(ngModel)]="buttonSelectionInstance.value.days[dayOfWeek][this.widgetObject.WidgetSiteId]"
														title="Days" (click)="clickDay(buttonSelectionInstance.key, this.widgetObject.WidgetSiteId, dayOfWeek)"/>
														<span>{{
															dayOfWeek
														}}</span>
													</label>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Send All Hours</mat-panel-title
											>
											<mat-panel-description>
												<span class='warning'>{{validation[buttonSelectionInstance.key].hours}}</span>
												<mat-icon
													[ngStyle]="{
														color: 'green'
													}"
													*ngIf="
														buttonSelectionInstance
															.value.allHours[
															widgetObject
																.WidgetSiteId
														]
													"
													>check_circle_outline</mat-icon
												>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.allHours[
													widgetObject.WidgetSiteId
												]
											"
											title="Email on all days"
										/>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Select Hours To Send (UTC time)
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ widgetObject.WidgetSiteId ]
																&&
																(buttonSelectionInstance.value.hours[0][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[1][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[2][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[3][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[4][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[5][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[6][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[7][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[8][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[9][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[10][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[11][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[12][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[13][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[14][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[15][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[16][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[17][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[18][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[19][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[20][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[21][widgetObject.WidgetSiteId] == true  ||
																buttonSelectionInstance.value.hours[22][widgetObject.WidgetSiteId] == true ||
																buttonSelectionInstance.value.hours[23][widgetObject.WidgetSiteId] == true
																)
																">
													check_circle_outline </mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div *ngIf="buttonSelectionInstance.value.allHours[widgetObject.WidgetSiteId]">
											<h6>Unselect Send All Hours to select specific hours</h6>
										</div>
										<div [hidden]=" !!buttonSelectionInstance.value.allHours[ widgetObject.WidgetSiteId ] " >
											<div
												*ngFor="
													let hour of dataCollections.hoursOfDay00to11
												"
												class="form-check-inline"
											>
												<div
													*ngIf="
														buttonSelectionInstance
															.value.hours[
															hour
														] !== undefined
													"
													id="ck-button"
												>
													<label>
														<input
															type="checkbox"
															[(ngModel)]="
																buttonSelectionInstance
																	.value
																	.hours[
																	hour
																][
																	this
																		.widgetObject
																		.WidgetSiteId
																]
															"
															(click)="clickHour(buttonSelectionInstance.key, this.widgetObject.WidgetSiteId, hour)"
														/><span>{{
															hour
														}}</span>
													</label>
												</div>
											</div>
											<div
												[hidden]="
													!!buttonSelectionInstance
														.value.allHours[
														widgetObject
															.WidgetSiteId
													]
												"
												style="margin-top: 6px"
											>
												<div
													*ngFor="
														let hour of dataCollections.hoursOfDay12to23
													"
													class="form-check-inline"
												>
													<div
														*ngIf="
															buttonSelectionInstance
																.value.hours[
																hour
															] !== undefined
														"
														id="ck-button"
													>
														<label>
															<input
																type="checkbox"
																[(ngModel)]="
																	buttonSelectionInstance
																		.value
																		.hours[
																		hour
																	][
																		this
																			.widgetObject
																			.WidgetSiteId
																	]
																"
																(click)="clickHour(buttonSelectionInstance.key, this.widgetObject.WidgetSiteId, hour)"
															/><span>{{
																hour
															}}</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</ng-container>
						</div>




						<div *ngIf="widgetObject.WidgetGateSystemId == null && (buttonSelectionInstance.key === 'gateSystem' || buttonSelectionInstance.key === 'criticalGateSystem')">
							<lib-centered-message centeredMessage="To enable alerts for a gate, select a gate from Widget Settings." >
							</lib-centered-message>

						</div>
						<div *ngIf="widgetObject.WidgetGateSystemId && (buttonSelectionInstance.key === 'gateSystem' || buttonSelectionInstance.key === 'criticalGateSystem')">
							Enable Alerts
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]="
									buttonSelectionInstance.value.enabled[
										widgetObject.WidgetGateSystemId
									]
								"
							/>

							<ng-container
								*ngIf="
									buttonSelectionInstance.value.enabled[
										widgetObject.WidgetGateSystemId
									]
								"
							>

								<div class="form-group" style="display:flex; flex-direction: row;">
									<div class="col-sm-2 col-md-2 col-form-label">
										<label for="minutestowait">Alert Wait</label>
									</div>
									<div class="col-sm-10 col-md-10">
										<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
												placeholder="Minutes Between Notification (minimum 15)"
												[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetGateSystemId]">
										<div>
											<span style="color:'red';">
												{{buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetGateSystemId] != null &&
													buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetGateSystemId] != '' &&
													buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetGateSystemId] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
											</span>
										</div>
									</div>
								</div>





								<mat-accordion class="headers-align">

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												Escalation
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[widgetObject.WidgetGateSystemId]">
													check_circle_outline
												</mat-icon >
											</mat-panel-description >
										</mat-expansion-panel-header>

										<div>
											<h4>My Escalations</h4>
											<div class="indent" *ngIf="alternateEmailForwards.length == 0">
												<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
											</div>
											<div class="indent" *ngFor=" let person of alternateEmailForwards">
												{{person.text}}
											</div>
										</div>

										<div class="form-group" style="display:flex; flex-direction: row;">
											<div class="col-sm-2 col-md-2 col-form-label">
												<label for="minutestowait">Alert Escalation Wait</label>
											</div>
											<div class="col-sm-10 col-md-10">
												<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
														placeholder="Minutes to escalation"
														[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[widgetObject.WidgetGateSystemId]">

											</div>
										</div>

									</mat-expansion-panel>

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Email
											</mat-panel-title>
											<mat-panel-description>

												<span class='warning'>
													{{validation[buttonSelectionInstance.key].emailOrText}}</span>
												<mat-icon
													[ngStyle]="{
														color: 'green'
													}"
													*ngIf="
														buttonSelectionInstance
															.value.email[
															widgetObject
																.WidgetGateSystemId
														]
													"
													>check_circle_outline</mat-icon
												></mat-panel-description
											>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.email[
													widgetObject.WidgetGateSystemId
												]
											"
											title="Email"
										/>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Text Message</mat-panel-title
											>
											<mat-panel-description
												>
												<span class='warning' *ngIf="buttonSelectionInstance.value.textMessage[widgetObject.WidgetGateSystemId] && person.CellPhoneCarrierId == undefined">
													No Carrier found. Go to Personal Information to add your carrier.
												</span>
												<mat-icon
													[ngClass]="person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
													*ngIf=" buttonSelectionInstance.value.textMessage[ widgetObject.WidgetGateSystemId ] ">
													check_circle_outline
												</mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.textMessage[
													widgetObject.WidgetGateSystemId
												]
											"
											title="Text"
										/>
									</mat-expansion-panel>

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Send On All Days
											</mat-panel-title>
											<mat-panel-description>
												<span class='warning'>
													{{validation[buttonSelectionInstance.key].days}}
												</span>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf=" buttonSelectionInstance.value.allDays[ widgetObject.WidgetGateSystemId ] " >
													check_circle_outline
												</mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input class="form-check-input" type="checkbox"
											[(ngModel)]=" buttonSelectionInstance.value.allDays[ widgetObject.WidgetGateSystemId ] "
											title="All Days" />
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												Select Days To Send
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ widgetObject.WidgetGateSystemId ]
																&&
																(buttonSelectionInstance.value.days['Mon'][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.days['Tue'][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.days['Wed'][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.days['Thu'][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.days['Fri'][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.days['Sat'][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.days['Sun'][widgetObject.WidgetGateSystemId] == true
																)
																">
													check_circle_outline </mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div *ngIf="buttonSelectionInstance.value.allDays[widgetObject.WidgetGateSystemId]">
											<h6>Unselect Send On All Days to select specific days</h6>
										</div>
										<div *ngIf=" !buttonSelectionInstance.value.allDays[ widgetObject.WidgetGateSystemId ] " >
											<div *ngFor=" let dayOfWeek of dataCollections.daysOfWeek " class="form-check-inline" >
												<div *ngIf=" buttonSelectionInstance.value.days[ dayOfWeek ] !== undefined " id="ck-button" >
													<label>
														<input type="checkbox"
														[(ngModel)]="buttonSelectionInstance.value.days[dayOfWeek][this.widgetObject.WidgetGateSystemId]"
														title="Days" (click)="clickDay(buttonSelectionInstance.key, this.widgetObject.WidgetGateSystemId, dayOfWeek)"/>
														<span>{{
															dayOfWeek
														}}</span>
													</label>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Send All Hours</mat-panel-title
											>
											<mat-panel-description>
												<span class='warning'>{{validation[buttonSelectionInstance.key].hours}}</span>
												<mat-icon
													[ngStyle]="{
														color: 'green'
													}"
													*ngIf="
														buttonSelectionInstance
															.value.allHours[
															widgetObject
																.WidgetGateSystemId
														]
													"
													>check_circle_outline</mat-icon
												>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.allHours[
													widgetObject.WidgetGateSystemId
												]
											"
											title="Email on all days"
										/>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Select Hours To Send (UTC time)
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ widgetObject.WidgetGateSystemId ]
																&&
																(buttonSelectionInstance.value.hours[0][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[1][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[2][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[3][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[4][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[5][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[6][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[7][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[8][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[9][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[10][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[11][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[12][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[13][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[14][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[15][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[16][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[17][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[18][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[19][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[20][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[21][widgetObject.WidgetGateSystemId] == true  ||
																buttonSelectionInstance.value.hours[22][widgetObject.WidgetGateSystemId] == true ||
																buttonSelectionInstance.value.hours[23][widgetObject.WidgetGateSystemId] == true
																)
																">
													check_circle_outline </mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div *ngIf="buttonSelectionInstance.value.allHours[widgetObject.WidgetGateSystemId]">
											<h6>Unselect Send All Hours to select specific hours</h6>
										</div>
										<div
											[hidden]="
												!!buttonSelectionInstance.value
													.allHours[
													widgetObject.WidgetGateSystemId
												]
											"
										>
											<div
												*ngFor="
													let hour of dataCollections.hoursOfDay00to11
												"
												class="form-check-inline"
											>
												<div
													*ngIf="
														buttonSelectionInstance
															.value.hours[
															hour
														] !== undefined
													"
													id="ck-button"
												>
													<label>
														<input
															type="checkbox"
															[(ngModel)]="
																buttonSelectionInstance
																	.value
																	.hours[
																	hour
																][
																	this
																		.widgetObject
																		.WidgetGateSystemId
																]
															"
															(click)="clickHour(buttonSelectionInstance.key, this.widgetObject.WidgetGateSystemId, hour)"
														/><span>{{
															hour
														}}</span>
													</label>
												</div>
											</div>
											<div
												[hidden]="
													!!buttonSelectionInstance
														.value.allHours[
														widgetObject
															.WidgetGateSystemId
													]
												"
												style="margin-top: 6px"
											>
												<div
													*ngFor="
														let hour of dataCollections.hoursOfDay12to23
													"
													class="form-check-inline"
												>
													<div
														*ngIf="
															buttonSelectionInstance
																.value.hours[
																hour
															] !== undefined
														"
														id="ck-button"
													>
														<label>
															<input
																type="checkbox"
																[(ngModel)]="
																	buttonSelectionInstance
																		.value
																		.hours[
																		hour
																	][
																		this
																			.widgetObject
																			.WidgetGateSystemId
																	]
																"
																(click)="clickHour(buttonSelectionInstance.key, this.widgetObject.WidgetGateSystemId, hour)"
															/><span>{{
																hour
															}}</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</ng-container>
						</div>


						<div *ngIf="widgetObject.WidgetAssetId == null && (buttonSelectionInstance.key === 'asset'  || buttonSelectionInstance.key === 'criticalAsset' || buttonSelectionInstance.key === 'alertAsset')">
							<lib-centered-message centeredMessage="To enable alerts for an asset, select an asset from Widget Settings." >
							</lib-centered-message>
						</div>
						<div *ngIf="widgetObject.WidgetAssetId && (buttonSelectionInstance.key === 'asset' || buttonSelectionInstance.key === 'criticalAsset' || buttonSelectionInstance.key === 'alertAsset')">
							Enable Alerts
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]="
									buttonSelectionInstance.value.enabled[
										widgetObject.WidgetAssetId
									]
								"
							/>

							<ng-container
								*ngIf="
									buttonSelectionInstance.value.enabled[
										widgetObject.WidgetAssetId
									]
								"
							>

								<div class="form-group" style="display:flex; flex-direction: row;">
									<div class="col-sm-2 col-md-2 col-form-label">
										<label for="minutestowait">Alert Wait</label>
									</div>
									<div class="col-sm-10 col-md-10">
										<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
												placeholder="Minutes Between Notification (minimum 15)"
												[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetAssetId]">
										<div>
											<span style="color:'red';">
												{{buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetAssetId] != null &&
													buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetAssetId] != '' &&
													buttonSelectionInstance.value.MinutesBetweenNotification[widgetObject.WidgetAssetId] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
											</span>
										</div>
									</div>
								</div>


								<mat-accordion class="headers-align">

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												Escalation
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[widgetObject.WidgetAssetId]">
													check_circle_outline
												</mat-icon >
											</mat-panel-description >
										</mat-expansion-panel-header>

										<div>
											<h4>My Escalations</h4>
											<div class="indent" *ngIf="alternateEmailForwards.length == 0">
												<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
											</div>
											<div class="indent" *ngFor=" let person of alternateEmailForwards">
												{{person.text}}
											</div>
										</div>

										<div class="form-group" style="display:flex; flex-direction: row;">
											<div class="col-sm-2 col-md-2 col-form-label">
												<label for="minutestowait">Alert Escalation Wait</label>
											</div>
											<div class="col-sm-10 col-md-10">
												<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
														placeholder="Minutes to escalation"
														[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[widgetObject.WidgetAssetId]">

											</div>
										</div>

									</mat-expansion-panel>


									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Email
											</mat-panel-title>
											<mat-panel-description>
												<span class='warning'>
													{{validation[buttonSelectionInstance.key].emailOrText}}</span>
												<mat-icon
													[ngStyle]="{
														color: 'green'
													}"
													*ngIf="
														buttonSelectionInstance
															.value.email[
															widgetObject
																.WidgetAssetId
														]
													"
													>check_circle_outline</mat-icon
												></mat-panel-description
											>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.email[
													widgetObject.WidgetAssetId
												]
											"
											title="Email"
										/>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Text Message</mat-panel-title
											>
											<mat-panel-description
												>
												<span class='warning' *ngIf=" buttonSelectionInstance.value.textMessage[ widgetObject.WidgetAssetId ]
																				&& person.CellPhoneCarrierId == undefined ">
													No Carrier found. Go to Personal Information to add your carrier.
												</span>
												<mat-icon
													[ngClass]="person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
													*ngIf=" buttonSelectionInstance.value.textMessage[ widgetObject.WidgetAssetId ] ">
													check_circle_outline
												</mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.textMessage[
													widgetObject.WidgetAssetId
												]
											"
											title="Text"
										/>
									</mat-expansion-panel>

									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Send On All Days
											</mat-panel-title>
											<mat-panel-description>
												<span class='warning'>
													{{validation[buttonSelectionInstance.key].days}}
												</span>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf=" buttonSelectionInstance.value.allDays[ widgetObject.WidgetAssetId ] " >
													check_circle_outline
												</mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input class="form-check-input" type="checkbox"
											[(ngModel)]=" buttonSelectionInstance.value.allDays[ widgetObject.WidgetAssetId ] "
											title="All Days" />
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title>
												Select Days To Send
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ widgetObject.WidgetAssetId ]
																&&
																(buttonSelectionInstance.value.days['Mon'][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.days['Tue'][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.days['Wed'][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.days['Thu'][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.days['Fri'][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.days['Sat'][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.days['Sun'][widgetObject.WidgetAssetId] == true
																)
																">
													check_circle_outline </mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div *ngIf="buttonSelectionInstance.value.allDays[widgetObject.WidgetAssetId]">
											<h6>Unselect Send On All Days to select specific days</h6>
										</div>
										<div *ngIf=" !buttonSelectionInstance.value.allDays[ widgetObject.WidgetAssetId ] " >
											<div *ngFor=" let dayOfWeek of dataCollections.daysOfWeek " class="form-check-inline" >
												<div *ngIf=" buttonSelectionInstance.value.days[ dayOfWeek ] !== undefined " id="ck-button" >
													<label>
														<input type="checkbox"
														[(ngModel)]="buttonSelectionInstance.value.days[dayOfWeek][this.widgetObject.WidgetAssetId]"
														title="Days" (click)="clickDay(buttonSelectionInstance.key, this.widgetObject.WidgetAssetId, dayOfWeek)"/>
														<span>{{
															dayOfWeek
														}}</span>
													</label>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Send All Hours</mat-panel-title
											>
											<mat-panel-description>
												<span class='warning'>{{validation[buttonSelectionInstance.key].hours}}</span>
												<mat-icon
													[ngStyle]="{
														color: 'green'
													}"
													*ngIf="
														buttonSelectionInstance
															.value.allHours[
															widgetObject
																.WidgetAssetId
														]
													"
													>check_circle_outline</mat-icon
												>
											</mat-panel-description>
										</mat-expansion-panel-header>
										Enable
										<input
											class="form-check-input"
											type="checkbox"
											[(ngModel)]="
												buttonSelectionInstance.value
													.allHours[
													widgetObject.WidgetAssetId
												]
											"
											title="Email on all days"
										/>
									</mat-expansion-panel>
									<mat-expansion-panel>
										<mat-expansion-panel-header>
											<mat-panel-title
												>Select Hours To Send (UTC time)
											</mat-panel-title>
											<mat-panel-description>
												<mat-icon [ngStyle]="{ color: 'green' }"
													*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ widgetObject.WidgetAssetId ]
																&&
																(buttonSelectionInstance.value.hours[0][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[1][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[2][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[3][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[4][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[5][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[6][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[7][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[8][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[9][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[10][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[11][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[12][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[13][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[14][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[15][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[16][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[17][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[18][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[19][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[20][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[21][widgetObject.WidgetAssetId] == true  ||
																buttonSelectionInstance.value.hours[22][widgetObject.WidgetAssetId] == true ||
																buttonSelectionInstance.value.hours[23][widgetObject.WidgetAssetId] == true
																)
																">
													check_circle_outline </mat-icon>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div *ngIf="buttonSelectionInstance.value.allHours[widgetObject.WidgetAssetId]">
											<h6>Unselect Send All Hours to select specific hours</h6>
										</div>
										<div
											[hidden]="
												!!buttonSelectionInstance.value
													.allHours[
													widgetObject.WidgetAssetId
												]
											"
										>
											<div
												*ngFor="
													let hour of dataCollections.hoursOfDay00to11
												"
												class="form-check-inline"
											>
												<div
													*ngIf="
														buttonSelectionInstance
															.value.hours[
															hour
														] !== undefined
													"
													id="ck-button"
												>
													<label>
														<input
															type="checkbox"
															[(ngModel)]="
																buttonSelectionInstance
																	.value
																	.hours[
																	hour
																][
																	this
																		.widgetObject
																		.WidgetAssetId
																]
															"
															(click)="clickHour(buttonSelectionInstance.key, this.widgetObject.WidgetAssetId, hour)"
														/><span>{{
															hour
														}}</span>
													</label>
												</div>
											</div>
											<div
												[hidden]="
													!!buttonSelectionInstance
														.value.allHours[
														widgetObject
															.WidgetAssetId
													]
												"
												style="margin-top: 6px"
											>
												<div
													*ngFor="
														let hour of dataCollections.hoursOfDay12to23
													"
													class="form-check-inline"
												>
													<div
														*ngIf="
															buttonSelectionInstance
																.value.hours[
																hour
															] !== undefined
														"
														id="ck-button"
													>
														<label>
															<input
																type="checkbox"
																[(ngModel)]="
																	buttonSelectionInstance
																		.value
																		.hours[
																		hour
																	][
																		this
																			.widgetObject
																			.WidgetAssetId
																	]
																"
																(click)="clickHour(buttonSelectionInstance.key, this.widgetObject.WidgetAssetId, hour)"
															/><span>{{
																hour
															}}</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</ng-container>
						</div>





<!-- START FLEET -->

<div *ngIf="myFleet == null && (buttonSelectionInstance.key === 'Thing' || buttonSelectionInstance.key === 'CriticalThing' || buttonSelectionInstance.key === 'AlertThing')">
	<lib-centered-message centeredMessage="To enable alerts for a fleet, select a fleet from Widget Settings." >
	</lib-centered-message>
</div>
<div *ngIf="myFleet && (buttonSelectionInstance.key === 'Thing' || buttonSelectionInstance.key === 'CriticalThing'  || buttonSelectionInstance.key === 'AlertThing')">
	Enable Alerts
	<input
		class="form-check-input"
		type="checkbox"
		[(ngModel)]="
			buttonSelectionInstance.value.enabled[
			myFleet.FleetId
			]
		"
	/>

	<ng-container
		*ngIf="
			buttonSelectionInstance.value.enabled[
			myFleet.FleetId
			]
		"
	>

		<div class="form-group" style="display:flex; flex-direction: row;">
			<div class="col-sm-2 col-md-2 col-form-label">
				<label for="minutestowait">Alert Wait</label>
			</div>
			<div class="col-sm-10 col-md-10">
				<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
						placeholder="Minutes Between Notification (minimum 15)"
						[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[myFleet.FleetId]">
				<div>
					<span style="color:'red';">
						{{buttonSelectionInstance.value.MinutesBetweenNotification[myFleet.FleetId] != null &&
							buttonSelectionInstance.value.MinutesBetweenNotification[myFleet.FleetId] != '' &&
							buttonSelectionInstance.value.MinutesBetweenNotification[myFleet.FleetId] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
					</span>
				</div>
			</div>
		</div>


		<mat-accordion class="headers-align">

			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Escalation
					</mat-panel-title>
					<mat-panel-description>
						<mat-icon [ngStyle]="{ color: 'green' }"
							*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[myFleet.FleetId]">
							check_circle_outline
						</mat-icon >
					</mat-panel-description >
				</mat-expansion-panel-header>

				<div>
					<h4>My Escalations</h4>
					<div class="indent" *ngIf="alternateEmailForwards.length == 0">
						<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
					</div>
					<div class="indent" *ngFor=" let person of alternateEmailForwards">
						{{person.text}}
					</div>
				</div>

				<div class="form-group" style="display:flex; flex-direction: row;">
					<div class="col-sm-2 col-md-2 col-form-label">
						<label for="minutestowait">Alert Escalation Wait</label>
					</div>
					<div class="col-sm-10 col-md-10">
						<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
								placeholder="Minutes to escalation"
								[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[myFleet.FleetId]">

					</div>
				</div>

			</mat-expansion-panel>


			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title
						>Email
					</mat-panel-title>
					<mat-panel-description>
						<span class='warning'>
							{{validation[buttonSelectionInstance.key].emailOrText}}</span>
						<mat-icon
							[ngStyle]="{
								color: 'green'
							}"
							*ngIf="buttonSelectionInstance.value.email[myFleet.FleetId]"
							>check_circle_outline</mat-icon
						></mat-panel-description
					>
				</mat-expansion-panel-header>
				Enable
				<input
					class="form-check-input"
					type="checkbox"
					[(ngModel)]="
						buttonSelectionInstance.value
							.email[
							myFleet.FleetId
						]
					"
					title="Email"
				/>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title
						>Text Message</mat-panel-title
					>
					<mat-panel-description
						>
						<span class='warning' *ngIf=" buttonSelectionInstance.value.textMessage[ myFleet.FleetId ]
														&& person.CellPhoneCarrierId == undefined ">
							No Carrier found. Go to Personal Information to add your carrier.
						</span>
						<mat-icon
							[ngClass]="person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
							*ngIf=" buttonSelectionInstance.value.textMessage[ myFleet.FleetId ] ">
							check_circle_outline
						</mat-icon>
					</mat-panel-description>
				</mat-expansion-panel-header>
				Enable
				<input
					class="form-check-input"
					type="checkbox"
					[(ngModel)]="buttonSelectionInstance.value.textMessage[myFleet.FleetId]"
					title="Text"
				/>
			</mat-expansion-panel>

			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title
						>Send On All Days
					</mat-panel-title>
					<mat-panel-description>
						<span class='warning'>
							{{validation[buttonSelectionInstance.key].days}}
						</span>
						<mat-icon [ngStyle]="{ color: 'green' }"
							*ngIf=" buttonSelectionInstance.value.allDays[ myFleet.FleetId ] " >
							check_circle_outline
						</mat-icon>
					</mat-panel-description>
				</mat-expansion-panel-header>
				Enable
				<input class="form-check-input" type="checkbox"
					[(ngModel)]=" buttonSelectionInstance.value.allDays[ myFleet.FleetId ] "
					title="All Days" />
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Select Days To Send
					</mat-panel-title>
					<mat-panel-description>
						<mat-icon [ngStyle]="{ color: 'green' }"
							*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ myFleet.FleetId ]
										&&
										(buttonSelectionInstance.value.days['Mon'][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.days['Tue'][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.days['Wed'][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.days['Thu'][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.days['Fri'][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.days['Sat'][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.days['Sun'][myFleet.FleetId] == true
										)
										">
							check_circle_outline </mat-icon>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div *ngIf="buttonSelectionInstance.value.allDays[myFleet.FleetId]">
					<h6>Unselect Send On All Days to select specific days</h6>
				</div>
				<div *ngIf=" !buttonSelectionInstance.value.allDays[ myFleet.FleetId ] " >
					<div *ngFor=" let dayOfWeek of dataCollections.daysOfWeek " class="form-check-inline" >
						<div *ngIf=" buttonSelectionInstance.value.days[ dayOfWeek ] !== undefined " id="ck-button" >
							<label>
								<input type="checkbox"
								[(ngModel)]="buttonSelectionInstance.value.days[dayOfWeek][myFleet.FleetId]"
								title="Days" (click)="clickDay(buttonSelectionInstance.key, myFleet.FleetId, dayOfWeek)"/>
								<span>{{
									dayOfWeek
								}}</span>
							</label>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title
						>Send All Hours</mat-panel-title
					>
					<mat-panel-description>
						<span class='warning'>{{validation[buttonSelectionInstance.key].hours}}</span>
						<mat-icon
							[ngStyle]="{
								color: 'green'
							}"
							*ngIf="buttonSelectionInstance.value.allHours[myFleet.FleetId]"
							>check_circle_outline</mat-icon
						>
					</mat-panel-description>
				</mat-expansion-panel-header>
				Enable
				<input
					class="form-check-input"
					type="checkbox"
					[(ngModel)]="
						buttonSelectionInstance.value
							.allHours[
							myFleet.FleetId
						]
					"
					title="Email on all days"
				/>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title
						>Select Hours To Send (UTC time)
					</mat-panel-title>
					<mat-panel-description>
						<mat-icon [ngStyle]="{ color: 'green' }"
							*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ myFleet.FleetId ]
										&&
										(buttonSelectionInstance.value.hours[0][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[1][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[2][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[3][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[4][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[5][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[6][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[7][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[8][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[9][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[10][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[11][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[12][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[13][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[14][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[15][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[16][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[17][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[18][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[19][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[20][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[21][myFleet.FleetId] == true  ||
										buttonSelectionInstance.value.hours[22][myFleet.FleetId] == true ||
										buttonSelectionInstance.value.hours[23][myFleet.FleetId] == true
										)
										">
							check_circle_outline </mat-icon>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div *ngIf="buttonSelectionInstance.value.allHours[myFleet.FleetId]">
					<h6>Unselect Send All Hours to select specific hours</h6>
				</div>
				<div
					[hidden]="
						!!buttonSelectionInstance.value
							.allHours[
							myFleet.FleetId
						]
					"
				>
					<div
						*ngFor="
							let hour of dataCollections.hoursOfDay00to11
						"
						class="form-check-inline"
					>
						<div
							*ngIf="
								buttonSelectionInstance
									.value.hours[
									hour
								] !== undefined
							"
							id="ck-button"
						>
							<label>
								<input
									type="checkbox"
									[(ngModel)]="buttonSelectionInstance.value.hours[hour][myFleet.FleetId]"
									(click)="clickHour(buttonSelectionInstance.key, myFleet.FleetId, hour)"
								/><span>{{
									hour
								}}</span>
							</label>
						</div>
					</div>
					<div
						[hidden]="!!buttonSelectionInstance.value.allHours[myFleet.FleetId] "
						style="margin-top: 6px"
					>
						<div
							*ngFor="
								let hour of dataCollections.hoursOfDay12to23
							"
							class="form-check-inline"
						>
							<div
								*ngIf="
									buttonSelectionInstance
										.value.hours[
										hour
									] !== undefined
								"
								id="ck-button"
							>
								<label>
									<input
										type="checkbox"
										[(ngModel)]="buttonSelectionInstance.value.hours[hour][myFleet.FleetId] "
										(click)="clickHour(buttonSelectionInstance.key, myFleet.FleetId, hour)"
									/><span>{{
										hour
									}}</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</ng-container>
</div>


<!-- END FLEET -->



						<div *ngIf="widgetObject.WidgetAssetId == null && buttonSelectionInstance.key === 'tag'">
							<lib-centered-message centeredMessage="To enable alerts for a tag, select an asset from Widget Settings." >
							</lib-centered-message>
						</div>
						<div *ngIf="buttonSelectionInstance.key === 'tag'">

							<mat-expansion-panel *ngFor="let tag of alarmTags">
								<mat-expansion-panel-header>
									<mat-panel-title>
										<div>
											{{
												tag.JBTStandardObservation.Name
											}}
										</div>
									</mat-panel-title>
									<mat-panel-description>
										<div>
											{{validation[buttonSelectionInstance.key].tagDescription[tag.JBTStandardObservationId]}}
										</div>
										<mat-icon
											[ngClass]="validation[buttonSelectionInstance.key].emailOrText[tag.JBTStandardObservationId] == ''
														&& validation[buttonSelectionInstance.key].days[tag.JBTStandardObservationId] == ''
														&& validation[buttonSelectionInstance.key].hours[tag.JBTStandardObservationId] == ''
														&& person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
											*ngIf="buttonSelectionInstance.value.enabled[tag.JBTStandardObservationId]">
											check_circle_outline
										</mat-icon>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<div

								>
									Enable Alerts
									<input
										class="form-check-input"
										type="checkbox"
										[(ngModel)]="
											buttonSelectionInstance.value
												.enabled[
												tag.JBTStandardObservationId
											]
										"
									/>

									<ng-container
										*ngIf="
											buttonSelectionInstance.value
												.enabled[
												tag.JBTStandardObservationId
											]
										"
									>

										<div class="form-group" style="display:flex; flex-direction: row;">
											<div class="col-sm-2 col-md-2 col-form-label">
												<label for="minutestowait">Alert Wait</label>
											</div>
											<div class="col-sm-10 col-md-10">
												<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
														placeholder="Minutes Between Notification (minimum 15)"
														[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[tag.JBTStandardObservationId]">
												<div>
													<span style="color:'red';">
														{{buttonSelectionInstance.value.MinutesBetweenNotification[tag.JBTStandardObservationId] != null &&
															buttonSelectionInstance.value.MinutesBetweenNotification[tag.JBTStandardObservationId] != '' &&
																buttonSelectionInstance.value.MinutesBetweenNotification[tag.JBTStandardObservationId] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
													</span>
												</div>
											</div>
										</div>


										<mat-accordion class="headers-align">

											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title>
														Escalation
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[tag.JBTStandardObservationId]">
															check_circle_outline
														</mat-icon >
													</mat-panel-description >
												</mat-expansion-panel-header>

												<div>
													<h4>My Escalations</h4>
													<div class="indent" *ngIf="alternateEmailForwards.length == 0">
														<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
													</div>
													<div class="indent" *ngFor=" let person of alternateEmailForwards">
														{{person.text}}
													</div>
												</div>

												<div class="form-group" style="display:flex; flex-direction: row;">
													<div class="col-sm-2 col-md-2 col-form-label">
														<label for="minutestowait">Alert Escalation Wait</label>
													</div>
													<div class="col-sm-10 col-md-10">
														<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
																placeholder="Minutes to escalation"
																[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[tag.JBTStandardObservationId]">

													</div>
												</div>

											</mat-expansion-panel>


											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Email
													</mat-panel-title>
													<mat-panel-description
														>
														<span class='warning'>
															{{validation[buttonSelectionInstance.key].emailOrText[tag.JBTStandardObservationId]}}</span>
														<mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.email[
																	tag.JBTStandardObservationId
																]
															"
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.email[
															tag.JBTStandardObservationId
														]
													"
													title="Email"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Text
														Message</mat-panel-title
													>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.textMessage[
																	tag.JBTStandardObservationId
																]
															"
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.textMessage[
															tag.JBTStandardObservationId
														]
													"
													title="Text"
												/>
											</mat-expansion-panel>

											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Send On All Days
													</mat-panel-title>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.allDays[
																	tag.JBTStandardObservationId
																]
															"
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.allDays[
															tag.JBTStandardObservationId
														]
													"
													title="All Days"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Select Days To Send
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ tag.JBTStandardObservationId ]
																		&&
																		(buttonSelectionInstance.value.days['Mon'][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.days['Tue'][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.days['Wed'][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.days['Thu'][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.days['Fri'][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.days['Sat'][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.days['Sun'][tag.JBTStandardObservationId] == true
																		)
																		">
															check_circle_outline </mat-icon>
													</mat-panel-description>
												</mat-expansion-panel-header>
												<div *ngIf="buttonSelectionInstance.value.allDays[tag.JBTStandardObservationId]">
													<h6>Unselect Send On All Days to select specific days</h6>
												</div>
												<div
													*ngIf="
														!buttonSelectionInstance
															.value.allDays[
															tag.JBTStandardObservationId
														]
													"
												>
													<div
														*ngFor="
															let dayOfWeek of dataCollections.daysOfWeek
														"
														class="form-check-inline"
													>
														<div
															*ngIf="
																buttonSelectionInstance
																	.value.days[
																	dayOfWeek
																] !== undefined
															"
															id="ck-button"
														>
															<label>
																<input
																	type="checkbox"
																	[(ngModel)]="
																		buttonSelectionInstance
																			.value
																			.days[dayOfWeek][tag.JBTStandardObservationId]
																	"
																	title="Days"
																	(click)="clickDay(buttonSelectionInstance.key, tag.JBTStandardObservationId, dayOfWeek)"
																/><span>{{
																	dayOfWeek
																}}</span>
															</label>
														</div>
													</div>
												</div>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Send All
														Hours</mat-panel-title
													>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.allHours[
																	tag.JBTStandardObservationId
																]
															"
															>check_circle_outline</mat-icon
														>
													</mat-panel-description>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.allHours[
															tag.JBTStandardObservationId
														]
													"
													title="Email on all days"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Select Hours To Send (UTC time)
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ tag.JBTStandardObservationId ]
																		&&
																		(buttonSelectionInstance.value.hours[0][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[1][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[2][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[3][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[4][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[5][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[6][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[7][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[8][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[9][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[10][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[11][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[12][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[13][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[14][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[15][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[16][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[17][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[18][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[19][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[20][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[21][tag.JBTStandardObservationId] == true  ||
																		buttonSelectionInstance.value.hours[22][tag.JBTStandardObservationId] == true ||
																		buttonSelectionInstance.value.hours[23][tag.JBTStandardObservationId] == true
																		)
																		">
															check_circle_outline </mat-icon>
													</mat-panel-description>
												</mat-expansion-panel-header>
												<div *ngIf="buttonSelectionInstance.value.allHours[tag.JBTStandardObservationId]">
													<h6>Unselect Send All Hours to select specific hours</h6>
												</div>
												<div
													[hidden]="
														!!buttonSelectionInstance
															.value.allHours[
															tag.JBTStandardObservationId
														]
													"
												>
													<div
														*ngFor="
															let hour of dataCollections.hoursOfDay00to11
														"
														class="form-check-inline"
													>
														<div
															*ngIf="
																buttonSelectionInstance
																	.value
																	.hours[
																	hour
																] !== undefined
															"
															id="ck-button"
														>
															<label>
																<input
																	type="checkbox"
																	[(ngModel)]="
																		buttonSelectionInstance
																			.value
																			.hours[hour][tag.JBTStandardObservationId]
																	"
																	(click)="clickHour(buttonSelectionInstance.key, tag.JBTStandardObservationId, hour)"
																/><span>{{
																	hour
																}}</span>
															</label>
														</div>
													</div>
													<div
														[hidden]="
															!!buttonSelectionInstance
																.value.allHours[
																tag.JBTStandardObservationId
															]
														"
														style="margin-top: 6px"
													>
														<div
															*ngFor="
																let hour of dataCollections.hoursOfDay12to23
															"
															class="form-check-inline"
														>
															<div
																*ngIf="
																	buttonSelectionInstance
																		.value
																		.hours[
																		hour
																	] !==
																	undefined
																"
																id="ck-button"
															>
																<label>
																	<input
																		type="checkbox"
																		[(ngModel)]="
																			buttonSelectionInstance
																				.value
																				.hours[
																				hour
																			][

																					tag.JBTStandardObservationId
																			]
																		"
																		(click)="clickHour(buttonSelectionInstance.key, tag.JBTStandardObservationId, hour)"
																	/><span>{{
																		hour
																	}}</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</mat-expansion-panel>
										</mat-accordion>
									</ng-container>
								</div>
							</mat-expansion-panel>
						</div>



						<div *ngIf="buttonSelectionInstance.key === 'JbtStandardObservation'">

							<mat-expansion-panel *ngFor="let so of alarmStandardObservationsForSite">
								<mat-expansion-panel-header>
									<mat-panel-title>
										<div>
											{{
												so.Name
											}}
										</div>
									</mat-panel-title>
									<mat-panel-description>
										<div>
											{{validation[buttonSelectionInstance.key].tagDescription[so.Id]}}
										</div>
										<mat-icon
											[ngClass]="validation[buttonSelectionInstance.key].emailOrText[so.Id] == ''
														&& validation[buttonSelectionInstance.key].days[so.Id] == ''
														&& validation[buttonSelectionInstance.key].hours[so.Id] == ''
														&& person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
											*ngIf="buttonSelectionInstance.value.enabled[so.Id]">
											check_circle_outline
										</mat-icon>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<div>
									Enable Alerts
									<input
										class="form-check-input"
										type="checkbox"
										[(ngModel)]="
											buttonSelectionInstance.value
												.enabled[
												so.Id
											]
										"
									/>

									<ng-container
										*ngIf="
											buttonSelectionInstance.value
												.enabled[
												so.Id
											]
										"
									>

										<div class="form-group" style="display:flex; flex-direction: row;">
											<div class="col-sm-2 col-md-2 col-form-label">
												<label for="minutestowait">Alert Wait</label>
											</div>
											<div class="col-sm-10 col-md-10">
												<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
														placeholder="Minutes Between Notification (minimum 15)"
														[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[so.Id]">
												<div>
													<span style="color:'red';">
														{{buttonSelectionInstance.value.MinutesBetweenNotification[so.Id] !=null &&
															buttonSelectionInstance.value.MinutesBetweenNotification[so.Id] !='' &&
															buttonSelectionInstance.value.MinutesBetweenNotification[so.Id] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
													</span>
												</div>
											</div>
										</div>


										<mat-accordion class="headers-align">

											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title>
														Escalation
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[so.Id]">
															check_circle_outline
														</mat-icon >
													</mat-panel-description >
												</mat-expansion-panel-header>

												<div>
													<h4>My Escalations</h4>
													<div class="indent" *ngIf="alternateEmailForwards.length == 0">
														<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
													</div>
													<div class="indent" *ngFor=" let person of alternateEmailForwards">
														{{person.text}}
													</div>
												</div>

												<div class="form-group" style="display:flex; flex-direction: row;">
													<div class="col-sm-2 col-md-2 col-form-label">
														<label for="minutestowait">Alert Escalation Wait</label>
													</div>
													<div class="col-sm-10 col-md-10">
														<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
																placeholder="Minutes to escalation"
																[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[so.Id]">

													</div>
												</div>

											</mat-expansion-panel>


											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Email
													</mat-panel-title>
													<mat-panel-description
														>
														<span class='warning'>
															{{validation[buttonSelectionInstance.key].emailOrText[so.Id]}}</span>
														<mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.email[
																	so.Id
																]
															"
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.email[
															so.Id
														]
													"
													title="Email"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Text
														Message</mat-panel-title
													>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.textMessage[
																	so.Id
																]
															"
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.textMessage[
															so.Id
														]
													"
													title="Text"
												/>
											</mat-expansion-panel>

											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Send On All Days
													</mat-panel-title>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.allDays[
																	so.Id
																]
															"
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.allDays[
															so.Id
														]
													"
													title="All Days"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Select Days To Send
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ so.Id ]
																		&&
																		(buttonSelectionInstance.value.days['Mon'][so.Id] == true ||
																		buttonSelectionInstance.value.days['Tue'][so.Id] == true  ||
																		buttonSelectionInstance.value.days['Wed'][so.Id] == true  ||
																		buttonSelectionInstance.value.days['Thu'][so.Id] == true  ||
																		buttonSelectionInstance.value.days['Fri'][so.Id] == true ||
																		buttonSelectionInstance.value.days['Sat'][so.Id] == true ||
																		buttonSelectionInstance.value.days['Sun'][so.Id] == true
																		)
																		">
															check_circle_outline </mat-icon>
													</mat-panel-description>
												</mat-expansion-panel-header>
												<div *ngIf="buttonSelectionInstance.value.allDays[so.Id]">
													<h6>Unselect Send On All Days to select specific days</h6>
												</div>
												<div
													*ngIf="
														!buttonSelectionInstance
															.value.allDays[
															so.Id
														]
													"
												>
													<div
														*ngFor="
															let dayOfWeek of dataCollections.daysOfWeek
														"
														class="form-check-inline"
													>
														<div
															*ngIf="
																buttonSelectionInstance
																	.value.days[
																	dayOfWeek
																] !== undefined
															"
															id="ck-button"
														>
															<label>
																<input
																	type="checkbox"
																	[(ngModel)]="
																		buttonSelectionInstance
																			.value
																			.days[
																			dayOfWeek
																		][
																			this.so.Id
																		]
																	"
																	title="Days"
																	(click)="clickDay(buttonSelectionInstance.key, so.Id, dayOfWeek)"
																/><span>{{
																	dayOfWeek
																}}</span>
															</label>
														</div>
													</div>
												</div>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Send All
														Hours</mat-panel-title
													>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf="
																buttonSelectionInstance
																	.value
																	.allHours[
																	so.Id
																]
															"
															>check_circle_outline</mat-icon
														>
													</mat-panel-description>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]="
														buttonSelectionInstance
															.value.allHours[
															so.Id
														]
													"
													title="Email on all days"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Select Hours To Send (UTC time)
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ so.Id ]
																		&&
																		(buttonSelectionInstance.value.hours[0][so.Id] == true ||
																		buttonSelectionInstance.value.hours[1][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[2][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[3][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[4][so.Id] == true ||
																		buttonSelectionInstance.value.hours[5][so.Id] == true ||
																		buttonSelectionInstance.value.hours[6][so.Id] == true ||
																		buttonSelectionInstance.value.hours[7][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[8][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[9][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[10][so.Id] == true ||
																		buttonSelectionInstance.value.hours[11][so.Id] == true ||
																		buttonSelectionInstance.value.hours[12][so.Id] == true ||
																		buttonSelectionInstance.value.hours[13][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[14][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[15][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[16][so.Id] == true ||
																		buttonSelectionInstance.value.hours[17][so.Id] == true ||
																		buttonSelectionInstance.value.hours[18][so.Id] == true ||
																		buttonSelectionInstance.value.hours[19][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[20][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[21][so.Id] == true  ||
																		buttonSelectionInstance.value.hours[22][so.Id] == true ||
																		buttonSelectionInstance.value.hours[23][so.Id] == true
																		)
																		">
															check_circle_outline </mat-icon>
													</mat-panel-description>
												</mat-expansion-panel-header>
												<div *ngIf="buttonSelectionInstance.value.allHours[so.Id]">
													<h6>Unselect Send All Hours to select specific hours</h6>
												</div>
												<div
													[hidden]="
														!!buttonSelectionInstance
															.value.allHours[
															so.Id
														]
													"
												>
													<div
														*ngFor="
															let hour of dataCollections.hoursOfDay00to11
														"
														class="form-check-inline"
													>
														<div
															*ngIf="
																buttonSelectionInstance
																	.value
																	.hours[
																	hour
																] !== undefined
															"
															id="ck-button"
														>
															<label>
																<input
																	type="checkbox"
																	[(ngModel)]="
																		buttonSelectionInstance
																			.value
																			.hours[hour][so.Id]
																	"
																	(click)="clickHour(buttonSelectionInstance.key, so.Id, hour)"
																/><span>{{
																	hour
																}}</span>
															</label>
														</div>
													</div>
													<div
														[hidden]="
															!!buttonSelectionInstance
																.value.allHours[
																so.Id
															]
														"
														style="margin-top: 6px"
													>
														<div
															*ngFor="
																let hour of dataCollections.hoursOfDay12to23
															"
															class="form-check-inline"
														>
															<div
																*ngIf="
																	buttonSelectionInstance
																		.value
																		.hours[
																		hour
																	] !==
																	undefined
																"
																id="ck-button"
															>
																<label>
																	<input
																		type="checkbox"
																		[(ngModel)]="
																			buttonSelectionInstance
																				.value
																				.hours[
																				hour
																			][

																			so.Id
																			]
																		"
																		(click)="clickHour(buttonSelectionInstance.key, so.Id, hour)"
																	/><span>{{
																		hour
																	}}</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</mat-expansion-panel>
										</mat-accordion>
									</ng-container>
								</div>
							</mat-expansion-panel>
						</div>






						<div *ngIf="buttonSelectionInstance.key === 'CriticalGSEAssetType' || buttonSelectionInstance.key === 'GSEAssetType'">

							<mat-expansion-panel *ngFor="let type of gseAssetTypes">
								<mat-expansion-panel-header>
									<mat-panel-title>
										<div>
											{{ type.Name }}
										</div>
									</mat-panel-title>
									<mat-panel-description>
										<div>
											{{validation[buttonSelectionInstance.key].tagDescription[type.Id]}}
										</div>
										<div *ngIf="type.countOfModelsEnabled > 0">
											Models Enabled
										</div>
										<mat-icon
											[ngClass]="validation[buttonSelectionInstance.key].emailOrText[type.Id] == ''
														&& validation[buttonSelectionInstance.key].days[type.Id] == ''
														&& validation[buttonSelectionInstance.key].hours[type.Id] == ''
														&& person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
											*ngIf="buttonSelectionInstance.value.enabled[type.Id]">
											check_circle_outline
										</mat-icon>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<div>
									Enable Alerts
									<input
										class="form-check-input"
										type="checkbox"
										[(ngModel)]=" buttonSelectionInstance.value .enabled[ type.Id ] "/>

									<ng-container *ngIf=" buttonSelectionInstance.value .enabled[ type.Id ] " >

										<div class="form-group" style="display:flex; flex-direction: row;">
											<div class="col-sm-2 col-md-2 col-form-label">
												<label for="minutestowait">Alert Wait</label>
											</div>
											<div class="col-sm-10 col-md-10">
												<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
														placeholder="Minutes Between Notification (minimum 15)"
														[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[type.Id]">
												<div>
													<span style="color:'red';">
														{{buttonSelectionInstance.value.MinutesBetweenNotification[type.Id] !=null &&
															buttonSelectionInstance.value.MinutesBetweenNotification[type.Id] !='' &&
															buttonSelectionInstance.value.MinutesBetweenNotification[type.Id] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
													</span>
												</div>
											</div>
										</div>


										<mat-accordion class="headers-align">

											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title>
														Escalation
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[type.Id]">
															check_circle_outline
														</mat-icon >
													</mat-panel-description >
												</mat-expansion-panel-header>

												<div>
													<h4>My Escalations</h4>
													<div class="indent" *ngIf="alternateEmailForwards.length == 0">
														<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
													</div>
													<div class="indent" *ngFor=" let person of alternateEmailForwards">
														{{person.text}}
													</div>
												</div>

												<div class="form-group" style="display:flex; flex-direction: row;">
													<div class="col-sm-2 col-md-2 col-form-label">
														<label for="minutestowait">Alert Escalation Wait</label>
													</div>
													<div class="col-sm-10 col-md-10">
														<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
																placeholder="Minutes to escalation"
																[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[type.Id]">

													</div>
												</div>

											</mat-expansion-panel>


											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Email
													</mat-panel-title>
													<mat-panel-description>
														<span class='warning'>
															{{validation[buttonSelectionInstance.key].emailOrText[type.Id]}}</span>
														<mat-icon [ngStyle]="{ color: 'green' }" *ngIf=" buttonSelectionInstance .value .email[ type.Id ] " >
															check_circle_outline
														</mat-icon>
													</mat-panel-description>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]=" buttonSelectionInstance .value.email[ type.Id ] "
													title="Email"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Text
														Message</mat-panel-title
													>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf=" buttonSelectionInstance .value .textMessage[ type.Id ] "
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]=" buttonSelectionInstance .value.textMessage[ type.Id ] "
													title="Text"
												/>
											</mat-expansion-panel>

											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Send On All Days
													</mat-panel-title>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf=" buttonSelectionInstance .value .allDays[ type.Id ] "
															>check_circle_outline</mat-icon
														></mat-panel-description
													>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]=" buttonSelectionInstance .value.allDays[ type.Id ] "
													title="All Days"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Select Days To Send
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ type.Id ]
																		&&
																		(buttonSelectionInstance.value.days['Mon'][type.Id] == true ||
																		buttonSelectionInstance.value.days['Tue'][type.Id] == true  ||
																		buttonSelectionInstance.value.days['Wed'][type.Id] == true  ||
																		buttonSelectionInstance.value.days['Thu'][type.Id] == true  ||
																		buttonSelectionInstance.value.days['Fri'][type.Id] == true ||
																		buttonSelectionInstance.value.days['Sat'][type.Id] == true ||
																		buttonSelectionInstance.value.days['Sun'][type.Id] == true
																		)
																		">
															check_circle_outline </mat-icon> -->
													</mat-panel-description>
												</mat-expansion-panel-header>
												<div *ngIf="buttonSelectionInstance.value.allDays[type.Id]">
													<h6>Unselect Send On All Days to select specific days</h6>
												</div>
												<div
													*ngIf=" !buttonSelectionInstance .value.allDays[ type.Id ] "
												>
													<div
														*ngFor="
															let dayOfWeek of dataCollections.daysOfWeek
														"
														class="form-check-inline"
													>
														<div
															*ngIf=" buttonSelectionInstance .value.days[ dayOfWeek ] !== undefined "
															id="ck-button"
														>
															<label>
																<input
																	type="checkbox"
																	[(ngModel)]=" buttonSelectionInstance .value .days[ dayOfWeek ][ type.Id ] "
																	title="Days"
																	(click)="clickDay(buttonSelectionInstance.key, type.Id, dayOfWeek)"
																/><span>{{
																	dayOfWeek
																}}</span>
															</label>
														</div>
													</div>
												</div>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Send All
														Hours</mat-panel-title
													>
													<mat-panel-description
														><mat-icon
															[ngStyle]="{
																color: 'green'
															}"
															*ngIf=" buttonSelectionInstance .value .allHours[ type.Id ] "
															>check_circle_outline</mat-icon
														>
													</mat-panel-description>
												</mat-expansion-panel-header>
												Enable
												<input
													class="form-check-input"
													type="checkbox"
													[(ngModel)]=" buttonSelectionInstance .value.allHours[ type.Id ] "
													title="Email on all days"
												/>
											</mat-expansion-panel>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Select Hours To Send (UTC time)
													</mat-panel-title>
													<mat-panel-description>
														<mat-icon [ngStyle]="{ color: 'green' }"
															*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ type.Id ]
																		&&
																		(buttonSelectionInstance.value.hours[0][type.Id] == true ||
																		buttonSelectionInstance.value.hours[1][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[2][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[3][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[4][type.Id] == true ||
																		buttonSelectionInstance.value.hours[5][type.Id] == true ||
																		buttonSelectionInstance.value.hours[6][type.Id] == true ||
																		buttonSelectionInstance.value.hours[7][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[8][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[9][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[10][type.Id] == true ||
																		buttonSelectionInstance.value.hours[11][type.Id] == true ||
																		buttonSelectionInstance.value.hours[12][type.Id] == true ||
																		buttonSelectionInstance.value.hours[13][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[14][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[15][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[16][type.Id] == true ||
																		buttonSelectionInstance.value.hours[17][type.Id] == true ||
																		buttonSelectionInstance.value.hours[18][type.Id] == true ||
																		buttonSelectionInstance.value.hours[19][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[20][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[21][type.Id] == true  ||
																		buttonSelectionInstance.value.hours[22][type.Id] == true ||
																		buttonSelectionInstance.value.hours[23][type.Id] == true
																		)
																		">
															check_circle_outline </mat-icon>
													</mat-panel-description>
												</mat-expansion-panel-header>
												<div *ngIf="buttonSelectionInstance.value.allHours[type.Id]">
													<h6>Unselect Send All Hours to select specific hours</h6>
												</div>
												<div
													[hidden]=" !!buttonSelectionInstance .value.allHours[ type.Id ] "
												>
													<div
														*ngFor="
															let hour of dataCollections.hoursOfDay00to11
														"
														class="form-check-inline"
													>
														<div
															*ngIf=" buttonSelectionInstance .value .hours[ hour ] !== undefined "
															id="ck-button"
														>
															<label>
																<input
																	type="checkbox"
																	[(ngModel)]=" buttonSelectionInstance .value .hours[hour][type.Id] "
																	(click)="clickHour(buttonSelectionInstance.key, type.Id, hour)"
																/><span>{{
																	hour
																}}</span>
															</label>
														</div>
													</div>
													<div
														[hidden]=" !!buttonSelectionInstance .value.allHours[ type.Id ] "
														style="margin-top: 6px"
													>
														<div
															*ngFor="
																let hour of dataCollections.hoursOfDay12to23
															"
															class="form-check-inline"
														>
															<div
																*ngIf=" buttonSelectionInstance .value .hours[ hour ] !== undefined "
																id="ck-button"
															>
																<label>
																	<input
																		type="checkbox"
																		[(ngModel)]=" buttonSelectionInstance .value .hours[ hour ][ type.Id ] "
																		(click)="clickHour(buttonSelectionInstance.key, type.Id, hour)"
																	/><span>{{
																		hour
																	}}</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</mat-expansion-panel>
										</mat-accordion>
									</ng-container>
								</div>
							</mat-expansion-panel>
						</div>


<!-- GSE MODELS -->
<div *ngIf="buttonSelectionInstance.key === 'CriticalGSEAssetModel' || buttonSelectionInstance.key === 'GSEAssetModel'">
	<mat-expansion-panel *ngFor="let type of gseAssetTypes">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<div>
					{{ type.Name }}
				</div>
			</mat-panel-title>
			<mat-panel-description>
				<div>
					{{ type.models ? type.models.length : 0 }} Models / {{ type.countOfModelsEnabled }} Enabled
				</div>
				<div *ngIf="type.isTypeEnabled==1">
					Type Enabled
				</div>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<mat-expansion-panel *ngFor="let model of type.models">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<div>
						{{
							model.SelectorName
						}}
					</div>
				</mat-panel-title>
				<mat-panel-description>
					<div>
						{{validation[buttonSelectionInstance.key].tagDescription[model.Id]}}
					</div>
					<mat-icon
						[ngClass]="validation[buttonSelectionInstance.key].emailOrText[model.Id] == ''
									&& validation[buttonSelectionInstance.key].days[model.Id] == ''
									&& validation[buttonSelectionInstance.key].hours[model.Id] == ''
									&& person.CellPhoneCarrierId != undefined ? 'success' : 'warning'"
						*ngIf="buttonSelectionInstance.value.enabled[model.Id]">
						check_circle_outline
					</mat-icon>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<div>
				Enable Alerts
				<input
					class="form-check-input"
					type="checkbox"
					[(ngModel)]=" buttonSelectionInstance.value .enabled[ model.Id ] "
				/>

				<ng-container
					*ngIf=" buttonSelectionInstance.value .enabled[ model.Id ] "
				>

					<div class="form-group" style="display:flex; flex-direction: row;">
						<div class="col-sm-2 col-md-2 col-form-label">
							<label for="minutestowait">Alert Wait</label>
						</div>
						<div class="col-sm-10 col-md-10">
							<input type="text" class="form-control" id="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
									placeholder="Minutes Between Notification (minimum 15)"
									[(ngModel)]="buttonSelectionInstance.value.MinutesBetweenNotification[model.Id]">
							<div>
								<span style="color:'red';">
									{{buttonSelectionInstance.value.MinutesBetweenNotification[model.Id] !=null &&
										buttonSelectionInstance.value.MinutesBetweenNotification[model.Id] !='' &&
										buttonSelectionInstance.value.MinutesBetweenNotification[model.Id] < 15 ? 'Minimum of 15 minutes is required.' : ''}}
								</span>
							</div>
						</div>
					</div>


					<mat-accordion class="headers-align">

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									Escalation
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon [ngStyle]="{ color: 'green' }"
										*ngIf=" buttonSelectionInstance.value.MinutesToEscalation[model.Id]">
										check_circle_outline
									</mat-icon >
								</mat-panel-description >
							</mat-expansion-panel-header>

							<div>
								<h4>My Escalations</h4>
								<div class="indent" *ngIf="alternateEmailForwards.length == 0">
									<p>No current escalations. Go to Alert Escalation Settings to add people from your organization.</p>
								</div>
								<div class="indent" *ngFor=" let person of alternateEmailForwards">
									{{person.text}}
								</div>
							</div>

							<div class="form-group" style="display:flex; flex-direction: row;">
								<div class="col-sm-2 col-md-2 col-form-label">
									<label for="minutestowait">Alert Escalation Wait</label>
								</div>
								<div class="col-sm-10 col-md-10">
									<input type="text" class="form-control" id="minutestoescalation" maxlength="4" size="4" pattern="^[0-9]$"
											placeholder="Minutes to escalation"
											[(ngModel)]="buttonSelectionInstance.value.MinutesToEscalation[model.Id]">

								</div>
							</div>

						</mat-expansion-panel>


						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title
									>Email
								</mat-panel-title>
								<mat-panel-description
									>
									<span class='warning'>
										{{validation[buttonSelectionInstance.key].emailOrText[model.Id]}}</span>
									<mat-icon
										[ngStyle]="{ color: 'green' }"
										*ngIf=" buttonSelectionInstance .value .email[ model.Id ] "
										>check_circle_outline</mat-icon
									></mat-panel-description
								>
							</mat-expansion-panel-header>
							Enable
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]=" buttonSelectionInstance .value.email[ model.Id ] "
								title="Email"
							/>
						</mat-expansion-panel>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title >Text Message</mat-panel-title >
								<mat-panel-description
									><mat-icon
										[ngStyle]="{ color: 'green' }"
										*ngIf=" buttonSelectionInstance .value .textMessage[ model.Id ] "
										>check_circle_outline</mat-icon
									></mat-panel-description
								>
							</mat-expansion-panel-header>
							Enable
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]=" buttonSelectionInstance .value.textMessage[ model.Id ] "
								title="Text"
							/>
						</mat-expansion-panel>

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title
									>Send On All Days
								</mat-panel-title>
								<mat-panel-description
									><mat-icon
										[ngStyle]="{ color: 'green' }"
										*ngIf=" buttonSelectionInstance .value .allDays[ model.Id ] "
										>check_circle_outline</mat-icon
									></mat-panel-description
								>
							</mat-expansion-panel-header>
							Enable
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]=" buttonSelectionInstance .value.allDays[ model.Id ] "
								title="All Days"
							/>
						</mat-expansion-panel>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title
									>Select Days To Send
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon [ngStyle]="{ color: 'green' }"
										*ngIf="!buttonSelectionInstance.value.allDays && !buttonSelectionInstance.value.allDays[ model.Id ]
													&&
													(buttonSelectionInstance.value.days['Mon'][model.Id] == true ||
													buttonSelectionInstance.value.days['Tue'][model.Id] == true  ||
													buttonSelectionInstance.value.days['Wed'][model.Id] == true  ||
													buttonSelectionInstance.value.days['Thu'][model.Id] == true  ||
													buttonSelectionInstance.value.days['Fri'][model.Id] == true ||
													buttonSelectionInstance.value.days['Sat'][model.Id] == true ||
													buttonSelectionInstance.value.days['Sun'][model.Id] == true
													)
													">
										check_circle_outline </mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div *ngIf="buttonSelectionInstance.value.allDays[model.Id]">
								<h6>Unselect Send On All Days to select specific days</h6>
							</div>
							<div
								*ngIf=" !buttonSelectionInstance .value.allDays[ model.Id ] "
							>
								<div
									*ngFor=" let dayOfWeek of dataCollections.daysOfWeek "
									class="form-check-inline"
								>
									<div
										*ngIf=" buttonSelectionInstance .value.days[ dayOfWeek ] !== undefined "
										id="ck-button"
									>
										<label>
											<input
												type="checkbox"
												[(ngModel)]=" buttonSelectionInstance .value .days[ dayOfWeek ][ model.Id ] "
												title="Days"
												(click)="clickDay(buttonSelectionInstance.key, model.Id, dayOfWeek)"
											/><span>{{
												dayOfWeek
											}}</span>
										</label>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title >Send All Hours</mat-panel-title >
								<mat-panel-description
									><mat-icon
										[ngStyle]="{ color: 'green' }"
										*ngIf=" buttonSelectionInstance .value .allHours[ model.Id ] "
										>check_circle_outline</mat-icon
									>
								</mat-panel-description>
							</mat-expansion-panel-header>
							Enable
							<input
								class="form-check-input"
								type="checkbox"
								[(ngModel)]=" buttonSelectionInstance .value.allHours[ model.Id ] "
								title="Email on all days"
							/>
						</mat-expansion-panel>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title
									>Select Hours To Send (UTC time)
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon [ngStyle]="{ color: 'green' }"
										*ngIf="!buttonSelectionInstance.value.allHours && !buttonSelectionInstance.value.allHours[ model.Id ]
													&&
													(buttonSelectionInstance.value.hours[0][model.Id] == true ||
													buttonSelectionInstance.value.hours[1][model.Id] == true  ||
													buttonSelectionInstance.value.hours[2][model.Id] == true  ||
													buttonSelectionInstance.value.hours[3][model.Id] == true  ||
													buttonSelectionInstance.value.hours[4][model.Id] == true ||
													buttonSelectionInstance.value.hours[5][model.Id] == true ||
													buttonSelectionInstance.value.hours[6][model.Id] == true ||
													buttonSelectionInstance.value.hours[7][model.Id] == true  ||
													buttonSelectionInstance.value.hours[8][model.Id] == true  ||
													buttonSelectionInstance.value.hours[9][model.Id] == true  ||
													buttonSelectionInstance.value.hours[10][model.Id] == true ||
													buttonSelectionInstance.value.hours[11][model.Id] == true ||
													buttonSelectionInstance.value.hours[12][model.Id] == true ||
													buttonSelectionInstance.value.hours[13][model.Id] == true  ||
													buttonSelectionInstance.value.hours[14][model.Id] == true  ||
													buttonSelectionInstance.value.hours[15][model.Id] == true  ||
													buttonSelectionInstance.value.hours[16][model.Id] == true ||
													buttonSelectionInstance.value.hours[17][model.Id] == true ||
													buttonSelectionInstance.value.hours[18][model.Id] == true ||
													buttonSelectionInstance.value.hours[19][model.Id] == true  ||
													buttonSelectionInstance.value.hours[20][model.Id] == true  ||
													buttonSelectionInstance.value.hours[21][model.Id] == true  ||
													buttonSelectionInstance.value.hours[22][model.Id] == true ||
													buttonSelectionInstance.value.hours[23][model.Id] == true
													)
													">
										check_circle_outline </mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div *ngIf="buttonSelectionInstance.value.allHours[model.Id]">
								<h6>Unselect Send All Hours to select specific hours</h6>
							</div>
							<div
								[hidden]=" !!buttonSelectionInstance .value.allHours[ model.Id ] "
							>
								<div
									*ngFor="
										let hour of dataCollections.hoursOfDay00to11
									"
									class="form-check-inline"
								>
									<div
										*ngIf=" buttonSelectionInstance .value .hours[ hour ] !== undefined "
										id="ck-button"
									>
										<label>
											<input
												type="checkbox"
												[(ngModel)]=" buttonSelectionInstance .value .hours[hour][model.Id] "
												(click)="clickHour(buttonSelectionInstance.key, model.Id, hour)"
											/><span>{{
												hour
											}}</span>
										</label>
									</div>
								</div>
								<div
									[hidden]=" !!buttonSelectionInstance .value.allHours[ model.Id ] "
									style="margin-top: 6px"
								>
									<div
										*ngFor="
											let hour of dataCollections.hoursOfDay12to23
										"
										class="form-check-inline"
									>
										<div
											*ngIf=" buttonSelectionInstance .value .hours[ hour ] !== undefined "
											id="ck-button"
										>
											<label>
												<input
													type="checkbox"
													[(ngModel)]=" buttonSelectionInstance .value .hours[ hour ][ model.Id ] "
													(click)="clickHour(buttonSelectionInstance.key, model.Id, hour)"
												/><span>{{
													hour
												}}</span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</ng-container>
			</div>
		</mat-expansion-panel>
	</mat-expansion-panel>
</div>



					</mat-expansion-panel>


				</mat-accordion>




				<!--
					 <div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalSite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All Critical Faults at {{ site.Name }}{{ site.Id }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.criticalSite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.criticalSite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.criticalSite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalSite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalSite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalSite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.criticalSite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.criticalSite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input type="checkbox" [(ngModel)]="buttonSelections.criticalSite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalSite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.criticalSite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.criticalSite.hours[hour] !== undefined" id="ck-button">
															<label>
																<input type="checkbox" [(ngModel)]="buttonSelections.criticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{ hour }}</span>
															</label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.criticalSite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.criticalSite.hours[hour] !== undefined" id="ck-button">
																<label>
																	<input type="checkbox" [(ngModel)]="buttonSelections.criticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{ hour }}</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>

				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All of {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.site.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.site.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.site.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.site.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.site.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.site.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.site.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.site.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.site.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.site.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All PBB Critical Faults at {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.allPBBCriticalSite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPBBCriticalSite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPBBCriticalSite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.allPBBCriticalSite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.allPBBCriticalSite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.allPBBCriticalSite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.allPBBCriticalSite.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.allPBBCriticalSite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.allPBBCriticalSite.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBCriticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All PBB Faults at {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.allPBBSite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPBBSite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPBBSite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.allPBBSite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.allPBBSite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.allPBBSite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.allPBBSite.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.allPBBSite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.allPBBSite.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPBBSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All PCA Critical Faults at {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.allPCACriticalSite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPCACriticalSite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPCACriticalSite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.allPCACriticalSite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.allPCACriticalSite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.allPCACriticalSite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.allPCACriticalSite.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.allPCACriticalSite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.allPCACriticalSite.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCACriticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All PCA Faults at {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.allPCASite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPCASite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.allPCASite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.allPCASite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.allPCASite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.allPCASite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.allPCASite.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.allPCASite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.allPCASite.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allPCASite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All GPU Critical Faults at {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.allGPUCriticalSite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.allGPUCriticalSite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.allGPUCriticalSite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.allGPUCriticalSite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.allGPUCriticalSite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.allGPUCriticalSite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.allGPUCriticalSite.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.allGPUCriticalSite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.allGPUCriticalSite.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUCriticalSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.enabled[widgetObject.WidgetSiteId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All GPU Faults at {{ site.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.allGPUSite.enabled[widgetObject.WidgetSiteId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.allGPUSite.allDays[widgetObject.WidgetSiteId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.allGPUSite.allHours[widgetObject.WidgetSiteId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.email[widgetObject.WidgetSiteId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.textMessage[widgetObject.WidgetSiteId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.allDays[widgetObject.WidgetSiteId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.allGPUSite.allDays[widgetObject.WidgetSiteId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.allGPUSite.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.days[dayOfWeek][this.widgetObject.WidgetSiteId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.allHours[widgetObject.WidgetSiteId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.allGPUSite.allHours[widgetObject.WidgetSiteId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.allGPUSite.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.allGPUSite.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.allGPUSite.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.allGPUSite.hours[hour][this.widgetObject.WidgetSiteId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.enabled[widgetObject.WidgetGateSystemId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All Critical Faults on Gate {{ gateSystem.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.criticalGateSystem.enabled[widgetObject.WidgetGateSystemId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.criticalGateSystem.allDays[widgetObject.WidgetGateSystemId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.criticalGateSystem.allHours[widgetObject.WidgetGateSystemId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.email[widgetObject.WidgetGateSystemId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.textMessage[widgetObject.WidgetGateSystemId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.allDays[widgetObject.WidgetGateSystemId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.criticalGateSystem.allDays[widgetObject.WidgetGateSystemId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.criticalGateSystem.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.days[dayOfWeek][this.widgetObject.WidgetGateSystemId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.allHours[widgetObject.WidgetGateSystemId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.criticalGateSystem.allHours[widgetObject.WidgetGateSystemId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.criticalGateSystem.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.hours[hour][this.widgetObject.WidgetGateSystemId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.criticalGateSystem.allHours[widgetObject.WidgetGateSystemId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.criticalGateSystem.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalGateSystem.hours[hour][this.widgetObject.WidgetGateSystemId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.enabled[widgetObject.WidgetGateSystemId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All Faults on Gate {{ gateSystem.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.gateSystem.enabled[widgetObject.WidgetGateSystemId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.gateSystem.allDays[widgetObject.WidgetGateSystemId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.gateSystem.allHours[widgetObject.WidgetGateSystemId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.email[widgetObject.WidgetGateSystemId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.textMessage[widgetObject.WidgetGateSystemId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.allDays[widgetObject.WidgetGateSystemId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.gateSystem.allDays[widgetObject.WidgetGateSystemId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.gateSystem.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.days[dayOfWeek][this.widgetObject.WidgetGateSystemId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.allHours[widgetObject.WidgetGateSystemId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.gateSystem.allHours[widgetObject.WidgetGateSystemId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.gateSystem.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.hours[hour][this.widgetObject.WidgetGateSystemId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.gateSystem.allHours[widgetObject.WidgetGateSystemId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.gateSystem.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.gateSystem.hours[hour][this.widgetObject.WidgetGateSystemId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.enabled[widgetObject.WidgetAssetId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All Critical Faults on Gate {{ gateSystem.Name }} {{ asset.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.criticalAsset.enabled[widgetObject.WidgetAssetId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.criticalAsset.allDays[widgetObject.WidgetAssetId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.criticalAsset.allHours[widgetObject.WidgetAssetId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.email[widgetObject.WidgetAssetId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.textMessage[widgetObject.WidgetAssetId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.allDays[widgetObject.WidgetAssetId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.criticalAsset.allDays[widgetObject.WidgetAssetId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.criticalAsset.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.days[dayOfWeek][this.widgetObject.WidgetAssetId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.allHours[widgetObject.WidgetAssetId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.criticalAsset.allHours[widgetObject.WidgetAssetId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.criticalAsset.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.hours[hour][this.widgetObject.WidgetAssetId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.criticalAsset.allHours[widgetObject.WidgetAssetId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.criticalAsset.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.criticalAsset.hours[hour][this.widgetObject.WidgetAssetId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>


				<div style="width: 100%">
					<table class="table table-condensed" style="margin-bottom: 5px">
						<tr class="no-select">
							<td nowrap style="border-top: none">
								<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.enabled[widgetObject.WidgetAssetId]" />
								<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
							</td>
							<td *ngIf="site !== undefined" style="font-size: 1.5em; font-weight: bold; width: 130px; border-top: none" nowrap>All Faults on Gate {{ gateSystem.Name }} {{ asset.Name }}</td>
							<td style="width: 100%; padding: 5px; border-top: none">
								<table [hidden]="!buttonSelections.asset.enabled[widgetObject.WidgetAssetId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px; border-top: none">
									<thead style="font-size: 0.75em">
										<tr>
											<th>Email?</th>
											<th>Text Msg?</th>
											<th>All Days?</th>
											<th>
												<div [hidden]="!!buttonSelections.asset.allDays[widgetObject.WidgetAssetId]">Send on...</div>
											</th>
											<th>All Hours?</th>
											<th>
												<div [hidden]="!!buttonSelections.asset.allHours[widgetObject.WidgetAssetId]">Send during the following selected hours...</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.email[widgetObject.WidgetAssetId]" title="Email" />
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.textMessage[widgetObject.WidgetAssetId]" title="Text Msg" />
												</div>
											</td>
											<td style="width: 60px; max-width: 60px; min-width: 60px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.allDays[widgetObject.WidgetAssetId]" title="Email on all days" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 260px">
												<div *ngIf="!buttonSelections.asset.allDays[widgetObject.WidgetAssetId]">
													<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
														<div *ngIf="buttonSelections.asset.days[dayOfWeek] !== undefined" id="ck-button">
															<label>
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.days[dayOfWeek][this.widgetObject.WidgetAssetId]" title="Days" /><span>{{ dayOfWeek }}</span>
															</label>
														</div>
													</div>
												</div>
											</td>
											<td style="width: 50px; max-width: 50px; min-width: 50px">
												<div class="form-check-inline">
													<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.allHours[widgetObject.WidgetAssetId]" title="All Hours" />
												</div>
											</td>
											<td nowrap style="font-size: 0.8em; width: 500px; max-width: 500px; min-width: 290px">
												<div [hidden]="!!buttonSelections.asset.allHours[widgetObject.WidgetAssetId]">
													<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
														<div *ngIf="buttonSelections.asset.hours[hour] !== undefined" id="ck-button">
                                                            <label>
                                                                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.hours[hour][this.widgetObject.WidgetAssetId]" /><span>{{hour}}</span>
                                                            </label>
														</div>
													</div>
													<div [hidden]="!!buttonSelections.asset.allHours[widgetObject.WidgetAssetId]" style="margin-top: 6px">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
															<div *ngIf="buttonSelections.asset.hours[hour] !== undefined" id="ck-button">
                                                                <label>
                                                                                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.hours[hour][this.widgetObject.WidgetAssetId]" /><span>{{hour}}</span>
                                                                </label>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</table>
				</div>

				<div style="width: 100%; padding-left: 10px; padding-right: 10px">

					<table class="table table-hover table-responsive table-condensed">
						<div style="text-align: center; background-color: cornsilk; font-weight: bold; height: 40px">
							<i class="fa fa-arrow-down" style="margin-right: 10px"></i>
							Individual Alarm Tags
							<i class="fa fa-arrow-down" style="margin-left: 10px"></i>
						</div>
						<thead style="font-size: 0.8em">
							<tr>
								<th style="cursor: pointer; width: 30px">
									<div>&nbsp;</div>
								</th>

								<th style="cursor: pointer" (click)="SetSortField('JBTStandardObservation.Name')">
									<div>
										JBT Std Obs Name

										<span>
											<i class="fa fa-sort-numeric-desc" title="Click to sort in ascending order"></i>
										</span>
										<span>

											<i class="fa fa-sort-numeric-asc" title="Click to sort in ascending order"></i>
										</span>
									</div>
								</th>
								<th style="cursor: pointer" (click)="SetSortField('Value')">
									<div>When to Send Emails to You</div>
								</th>
							</tr>
						</thead>
						<tbody style="font-size: 0.75em" *ngIf="widgetObject.displaySettings !== undefined">
							<tr *ngFor="let tag of alarmTags" (click)="ReportTagClicked(tag)" class="no-select" style="padding-left: 10px; padding-right: 10px">

								<td nowrap>
									<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.tag.enabled[tag.TagId]" />
									<label class="form-check-label" aria-hidden="true" title="Notify me by email for this alarm"></label>
								</td>
								<td nowrap>{{ tag.JBTStandardObservation.Name }}</td>
								<td style="width: 100%; padding: 5px">
									<table [hidden]="!buttonSelections.tag.enabled[tag.TagId]" class="table table-condensed" style="background-color: beige; margin-bottom: 5px">
										<thead style="font-size: 0.9em">
											<tr>
												<th>Email?</th>
												<th>Text Msg?</th>
												<th>All Days?</th>
												<th>
													<div [hidden]="!!buttonSelections.tag.allDays[tag.TagId]">Send on...</div>
												</th>
												<th>All Hours?</th>
												<th>
													<div [hidden]="!!buttonSelections.tag.allHours[tag.TagId]">Send during the following selected hours...</div>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td style="width: 50px; max-width: 50px; min-width: 50px">
													<div class="form-check-inline">
														<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.email[tag.TagId]" title="Email" />
													</div>
												</td>
												<td style="width: 50px; max-width: 50px; min-width: 50px">
													<div class="form-check-inline">
														<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.asset.textMessage[tag.TagId]" title="Text Msg" />
													</div>
												</td>
												<td style="width: 60px; max-width: 60px; min-width: 60px">
													<div class="form-check-inline">
														<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.tag.allDays[tag.TagId]" title="Email on all days" />
													</div>
												</td>
												<td nowrap style="font-size: 0.8em; width: 260px; max-width: 260px; min-width: 260px">
													<div *ngIf="!buttonSelections.tag.allDays[tag.TagId]">
														<div *ngFor="let dayOfWeek of buttonSelections.dataCollections.daysOfWeek" class="form-check-inline">
															<div *ngIf="buttonSelections.site.days[dayOfWeek] !== undefined" id="ck-button">
																<label class="form-check-label" for="flexCheckDefault" style="color: black">{{ dayOfWeek }}</label>
																<label>
																	<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.tag.days[dayOfWeek][tag.TagId]" title="Days" />
																</label>
															</div>
														</div>
													</div>
												</td>
												<td style="width: 50px; max-width: 50px; min-width: 50px">
													<div class="form-check-inline">
														<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.tag.allHours[tag.TagId]" title="All Hours" />
													</div>
												</td>
												<td nowrap style="font-size: 0.8em; width: 290px; max-width: 290px; min-width: 290px">
													<div [hidden]="!!buttonSelections.tag.allHours[tag.TagId]">
														<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay00to11" class="form-check-inline">
															<div *ngIf="buttonSelections.tag.allHours[tag.TagId] !== undefined">
																<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.tag.allHours[tag.TagId]" />
																<label class="form-check-label" for="flexCheckDefault" style="color: black">{{ hour }}</label>
															</div>
														</div>
														<div [hidden]="!!buttonSelections.site.allHours[widgetObject.WidgetSiteId]" style="margin-top: 6px">
															<div *ngFor="let hour of buttonSelections.dataCollections.hoursOfDay12to23" class="form-check-inline">
																<div *ngIf="buttonSelections.tag.allHours[tag.TagId] !== undefined">
																	<input class="form-check-input" type="checkbox" [(ngModel)]="buttonSelections.tag.allHours[tag.TagId]" />
																	<label class="form-check-label" for="flexCheckDefault" style="color: black">{{ hour }}</label>
																</div>
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			-->
			</div>
		</div>
	</div>
</div>
