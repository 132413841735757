import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { WeatherService } from '../../services/weather.service';
import { Global } from '../../_constants/global.variables';


@Component({
	selector: 'lib-current-weather',
	templateUrl: './current-weather.component.html',
	styleUrls: ['./current-weather.component.scss'],
})
export class CurrentWeatherComponent implements OnInit, OnDestroy {
	@Input() parentWidgetObject: any;

	public isDataLoading: boolean = true;

	public loc$: Observable<string>;
	public loc: string;
	public currentWeather: any;
	public msg: string;

	public weatherInterval: NodeJS.Timeout;
	public lastRetrievedDate: Date;
	private fullDataCacheSubscription: any;
	private updateInterval: number = 900000;

	constructor(
		public weatherService: WeatherService,
		public dataService: DataService
	) {}

	ngOnInit() {
		this.isDataLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe(() => {
					this.getCurrentWeatherObservation();
					this.fullDataCacheSubscription.unsubscribe();
				});
		} else {
			Global.User.DebugMode &&
				console.log(
					'this.dataService.cache = %O',
					this.dataService.cache
				);
			this.getCurrentWeatherObservation();
		}

		this.weatherInterval = setInterval(() => {
			this.getCurrentWeatherObservation();
		}, this.updateInterval);
	}

	getCurrentWeatherObservation() {
		let site = this.dataService.cache.sites.find((site) => {
			return this.parentWidgetObject.WidgetSiteName === site.Name;
		});
		if (site === undefined) {
			this.isDataLoading = false;
			return;
		} else {
			let statement = 'API.GetWeatherObservationBySiteId ' + site.Id;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				this.currentWeather = data.first();
				Global.User.DebugMode &&
					console.log(
						'API.GetWeatherObservationBySiteId ' +
							site.Id +
							' data = %O',
						this.currentWeather
					);
				this.currentWeather.ObservationDate = new Date(
					this.currentWeather.ObservationDateMS
				);
				this.isDataLoading = false;
				Global.User.DebugMode &&
					console.log('this.isDataLoading = ' + this.isDataLoading);
			});
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.weatherInterval) {
			clearInterval(this.weatherInterval);
		}
	}
}
