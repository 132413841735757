import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { FileUploadService } from "../../../services/file-upload.service";
import { map, catchError, filter } from "rxjs/operators";
import { HttpEventType, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { of } from "rxjs";
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Global } from "../../../_constants/global.variables";
import { QuestionBase } from "../../../_models/dynamic-fields/questions/question-base";
import { DataService } from "../../../services/data.service";
import { UtilityService } from "../../../services/utility.service";
import { SignalRCoreService } from "../../../services/signalr-core.service";

@Component({
	selector: "lib-file-upload-field",
	templateUrl: "./file-upload-field.component.html",
	styleUrls: ["./file-upload-field.component.scss"],
})
export class FileUploadFieldComponent implements OnInit, AfterViewInit {
	@Input() public mode: string;
	@Input() public question: QuestionBase<string>;
	@Input() public formGroup: FormGroup;
	@Input() saveOnChange: boolean;
	@Input() saveStoredProcedureName: string;
	@Input() public fileImageLibraryControlObject: any;

	@ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
	files = [];
	@Output("dropZone") fileDrop = new EventEmitter<Array<File>>();
	@Output() public notifyParent: EventEmitter<any> = new EventEmitter();

	public listTitle: string = "File Uploads";
	public listSubtitle: string = "Upload file(s) here.";
	public iconmenu: string =
		"doc,docx,xls,xlsm,xlsx,msg,pdf,ppt,pptx,js,css,mp4,mp3,wav,zip,txt,json,config,cs,aspx,html,tif,tiff,vsd,chm,asp,sql,hl7,xml,linq,rdl,wma,msi,exe,reg,csv,webm,avi";

	public uploadReordinal: number = 100000;
	public newFiles: any;
	public canBeClosed: boolean = true;
	public showCancelButton: boolean = true;
	public dropzoneActive: boolean = false;
	public currentUpload: any;
	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.ODataAccessToken;

	private fileIndex: number = 0;
	private swalWithBootstrapButtons: any;
	private currentImageKey: string;
	private replacingFile: boolean = false;
	private existingFileToReplace: any;
	private componentName: string = "file-upload-field: ";
	private filesUploaded$: any;

	constructor(
		private fileUploadService: FileUploadService,
		private elementRef: ElementRef,
		private toastr: ToastrService,
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private utilityService: UtilityService,
		private sanitizer: DomSanitizer,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		Global.User.DebugMode &&
			console.log(this.componentName + "question = %O", this.question);
		Global.User.DebugMode &&
			console.log(this.componentName + "formGroup = %O", this.formGroup);
		Global.User.DebugMode &&
			console.log(
				this.componentName + "saveOnChange = " + this.saveOnChange
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"saveStoredProcedureName = " +
					this.saveStoredProcedureName
			);

		var service = this;
		setTimeout(function () {
			service.addEventListenerToDropArea(service);
		}, 200);

		if (this.question.value != null) {
			this.loadFile();
		}
	}

	ngAfterViewInit() {
		var service = this;
		service.addEventListenerToDropArea(service);
	}

	addEventListenerToDropArea(service: any) {
		const dropZone = document.body;
		Global.User.DebugMode &&
			console.log(service.componentName + "dropZone = %O", dropZone);
		dropZone.addEventListener(
			"drop",
			function (event) {
				Global.User.DebugMode &&
					console.log(service.componentName + "event = %O", event);

				event.preventDefault();
				event.stopPropagation();

				var items = event.dataTransfer.items;
				for (var i = 0; i < items.length; i++) {
					// webkitGetAsEntry is where the magic happens
					var item = items[i];
					Global.User.DebugMode &&
						console.log(service.componentName + "item = %O", item);
					if (item) {
						service.traverseFileTree(item);
					}
				}

				service.uploadFiles(service);
			},
			false
		);
	}

	onDropEvent(items: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + "onDropEvent invoked... %O",
				items
			);
		this.numberOfFilesCheck(items);

		var selectedFile = items[0];

		event.preventDefault();
		event.stopPropagation();

		var service = this;

		const fileUpload = this.fileUpload.nativeElement;
		fileUpload.onchange = () => {
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				this.files.push({ data: file, inProgress: false, progress: 0 });
			}
		};

		this.fileUpload.nativeElement.value = "";
		var fileObject: any = {};

		// webkitGetAsEntry is where the magic happens
		var item = selectedFile;
		Global.User.DebugMode &&
			console.log(this.componentName + "item = %O", item);

		var fileObject: any = {};
		fileObject.inProgress = false;
		fileObject.progress = 0;
		fileObject.data = item;
		Global.User.DebugMode &&
			console.log(this.componentName + "fileObject = %O", fileObject);

		this.files = [];
		this.uploadFile(fileObject, this);
	}

	changeDetectorReference() {
		this.changeDetector.detectChanges();
	}

	closeDialog() {
		Global.User.DebugMode &&
			console.log(this.componentName + "trying to close dialog...");
	}

	dropzoneState(event: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + "files hovered over drop zone...%O",
				event
			);
		this.dropzoneActive = event;
	}

	uploadFile(file: any, service: any) {
		const formData = new FormData();
		console.log(
			service.componentName + "uploadFile invoked --> file = %O",
			file
		);

		formData.append("file", file.data);
		file.inProgress = true;
		file.uploading = true;
		var filename = file.data.name;
		if (filename.indexOf(".") == -1) {
			filename = filename + ".png";
		}

		file.name = filename;
		file.lastModifiedDate = new Date(file.data.lastModifiedDate);
		file.size = file.data.size / 1000;
		file = service.setFileImage(file);
		Global.User.DebugMode &&
			console.log(service.componentName + "file = %O", file);

		service.fileUploadService
			.upload(formData)
			// .pipe(
			// map((event:any) => {
			// }),
			// catchError((error: HttpErrorResponse) => {
			// 	file.inProgress = false;
			// 	return of(`${file.data.name} upload failed.`);
			// }))
			.subscribe((event: HttpEvent<any>) => {
				var Sent = event.type === HttpEventType.Sent;
				var DownloadProgress =
					event.type === HttpEventType.DownloadProgress;
				var UploadProgress =
					event.type === HttpEventType.UploadProgress;
				var Response = event.type === HttpEventType.Response;
				var ResponseHeader =
					event.type === HttpEventType.ResponseHeader;
				var User = event.type === HttpEventType.User;

				switch (event.type) {
					case HttpEventType.UploadProgress:
						//-- if we get this status, we're sending the file to the server and this is the upload progress to the server. --Kirk T. Sherer, January 21, 2021
						file.uploadProgress = Math.round(
							(event.loaded * 100) / event.total
						);
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									"uploadProgress event -> %O",
								event
							);
						break;
					case HttpEventType.Response:
						//-- if we get this status, the server has the file and now we can add its image key to the list of image keys for the collection. --Kirk T. Sherer, January 21, 2021
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									"uploadFile: event.type = " +
									event.type +
									", event -> %O",
								event
							);
						if (typeof event === "object") {
							if (event.body) {
								file.inProgress = false;
								Global.User.DebugMode &&
									console.log(
										service.componentName +
											"event.body = %O",
										event.body
									);
								if (service.currentImageKeyList == undefined) {
									service.currentImageKeyList = "";
								}
								service.currentImageKeyList +=
									(service.currentImageKeyList != ""
										? ","
										: "") + event.body.ImageKey;
								file.name = event.body.Filename;
								var fileExtension = event.body.Filename.split(
									"."
								)
									.last()
									.toLowerCase();
								if (
									service.iconmenu.indexOf(
										fileExtension.toLowerCase()
									) > -1
								) {
									//This is an icon file display. Assign the right one.
									file.iconFile =
										Global.imagesUrl +
										"assets/img/FileUpload/icon-" +
										fileExtension +
										".png";
								} else {
									file.iconFile = null;
								}

								file.lastModifiedDate =
									event.body.lastModifiedDate;
								file.size = event.body.OriginalSize;
								file.imageKey = event.body.ImageKey;
								var defaultVideoFileName =
									event.body.ImageKey + "." + fileExtension;
								file.isVideoServerImage =
									event.body.IsVideoServerImage;
								file.videoServerFilename = event.body
									.VideoServerFilename
									? event.body.VideoServerFilename
									: event.body.IsVideoServerImage
									? defaultVideoFileName
									: null;
								file.thumbnailImage = event.body.ThumbnailBase64
									? this.sanitizer.bypassSecurityTrustResourceUrl(
											"data:image/png;base64," +
												event.body.ThumbnailBase64
									  )
									: null;

								if (service.fileImageLibraryControlObject) {
									service.fileImageLibraryControlObject.addUploadFunction(
										file.imageKey
									);
								}
								service.setFileImage(file);
								service.files = [];
								service.files.push(file);
								var sqlStatement =
									service.question.saveStoredProcedureName +
									", '" +
									service.question.key +
									"', '" +
									file.imageKey +
									"'";
								Global.User.DebugMode &&
									console.log(
										service.componentName +
											"sqlStatement = " +
											sqlStatement
									);
								if (
									service.question.saveOnChange &&
									service.question.saveStoredProcedureName
								) {
									service.saveFile(
										sqlStatement,
										file,
										service.replacingFile
									);
								} else {
									service.question.value = file.imageKey;
									Global.User.DebugMode &&
										console.log(
											service.componentName +
												"this.question = %O",
											service.question
										);
								}
								service.notifyParent.emit(file.imageKey); //-- uploading a file doesn't trigger a change event, so we have to update the dynamic form question component so it can notify the overall form. --Kirk T. Sherer, November 1, 2021.

								Global.User.DebugMode &&
									console.log(
										service.componentName +
											"list after update from webAPI = %O",
										service.files
									);

								service.changeDetectorReference();
							}
							return event;
						}
						break;
					case HttpEventType.DownloadProgress:
						//-- if we get this status, this is the progress from the server for the file being uploaded. --Kirk T. Sherer, January 21, 2021
						file.downloadProgress = Math.round(
							(event.loaded * 100) / event.total
						);
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									"downloadProgress event -> %O",
								event
							);
						break;
					case HttpEventType.ResponseHeader:
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									"uploadFile: event.type is not Response or UploadProgress.  It is 'HttpEventType.ResponseHeader', event -> %O",
								event
							);
						break;
					case HttpEventType.Sent:
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									"uploadFile: event.type is not Response or UploadProgress.  It is 'HttpEventType.Sent', event -> %O",
								event
							);
						break;
					// default:
					// 	Global.User.DebugMode && console.log(service.componentName + "uploadFile: event.type is not Response or UploadProgress.  It is '" + event.type + "', event -> %O", event);
					// 	break;
				}
			});
	}

	saveFile(sqlStatement: string, file: any, replacingFile?: boolean) {
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			if (data && data.length > 0) {
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							this.question.key +
							" has been updated. data = %O",
						data
					);
				if (replacingFile == null || !replacingFile) {
					this.utilityService.showToastMessageShared({
						type: "info",
						message:
							"'" +
							file.name +
							"' file has been " +
							(replacingFile == null ? "DELETE" : "REPLACE") +
							"D.",
						title: this.listTitle,
					});
					//   this.toastr.info("'" + file.name + "' file has been " + (replacingFile == null ? "DELETE" : "REPLACE") + "D.", this.listTitle);
				} else {
					if (this.existingFileToReplace) {
						this.replacingFile = false;
						this.existingFileToReplace = null;
					}
				}
			}
		});
	}

	deleteFile(file: any, replacingFile?: boolean) {
		event.stopPropagation();
		var service = this;
		Global.User.DebugMode &&
			console.log(service.componentName + "this.files = %O", this.files);
		var listOfCurrentFiles = this.files;

		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success",
			},
			buttonsStyling: false,
		});
		Global.User.DebugMode &&
			console.log(
				service.componentName +
					"Marking " +
					file.name +
					" file for deletion..."
			);
		Global.User.DebugMode &&
			console.log(service.componentName + "file = %O", file);

		this.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html:
					"You are trying to " +
					(replacingFile == null ? "delete" : "replace") +
					" the '<strong>" +
					file.name +
					"</strong>' file for the " +
					this.question.label +
					" field. Cancel if you would rather keep it.",
				showCancelButton: true,
				confirmButtonText:
					(replacingFile == null ? "Delete" : "Replace") + " File",
				cancelButtonText: "Cancel",
				reverseButtons: false,
			})
			.then((result: any) => {
				if (result.value) {
					// logic for deleting file goes here.
					this.actuallyRemoveExistingFile(file, replacingFile);
					this.files = [];
				} else {
					this.utilityService.showToastMessageShared({
						type: "info",
						message:
							"'" +
							file.name +
							"' has NOT been " +
							(replacingFile == null ? "delete" : "replace") +
							"d.",
						title: this.listTitle,
					});
					//   this.toastr.info("'" + file.name + "' has NOT been " + (replacingFile == null ? "delete" : "replace") + "d.", this.listTitle);
				}
			});
	}

	private actuallyRemoveExistingFile(file: any, replacingFile?: boolean) {
		var sqlStatement =
			this.saveStoredProcedureName +
			", '" +
			this.question.key +
			"', null";
		Global.User.DebugMode &&
			console.log(this.componentName + "sqlStatement = " + sqlStatement);

		if (
			this.question.saveOnChange &&
			this.question.saveStoredProcedureName
		) {
			this.saveFile(sqlStatement, file, replacingFile);
		} else {
			this.question.value = ""; //-- trying to clear out the image key value for this file.
		}
		this.notifyParent.emit(""); //-- uploading a file doesn't trigger a change event, so we have to update the dynamic form question component so it can notify the overall form. --Kirk T. Sherer, November 1, 2021.
	}

	public viewFile(file: any) {
		if (!file.edit) {
			Global.User.DebugMode &&
				console.log(
					this.componentName + "trying to view the file... file = %O",
					file
				);
			var url = file.viewUrl;

			var intHSize = window.screen.availWidth * 0.7;
			var intVSize = window.screen.availHeight * 0.7;
			var intTop = (window.screen.availHeight - intVSize) / 2;
			var intLeft = (window.screen.availWidth - intHSize) / 2;
			var sngWindow_Size_Percentage = 0.85;

			var strFeatures = "";
			intHSize =
				window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
			intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
			intTop = (window.screen.availHeight - intVSize) / 2;
			intLeft = (window.screen.availWidth - intHSize) / 2;
			strFeatures =
				"top=" +
				intTop +
				",left=" +
				intLeft +
				",height=" +
				intVSize +
				",width=" +
				intHSize +
				", scrollbars=yes, resizable=yes, location=no";

			var SRWindow = window.open(url, "SRWindow", strFeatures);
		}
	}

	private loadFile() {
		var service = this;
		if (this.question.value != "") {
			this.currentImageKey = this.question.value;
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						"this.currentImageKey = " +
						this.currentImageKey
				);

			this.fileUploadService
				.list(service.currentImageKey)
				.pipe(
					map((event) => {
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": ByImageKeyList: event -> %O",
								event
							);
						return event;
					}),
					catchError((error: HttpErrorResponse) => {
						return of(`${error} file retrieval failed.`);
					})
				)
				.subscribe((event: any) => {
					if (typeof event === "object" && event.body != undefined) {
						Global.User.DebugMode &&
							console.log(
								service.componentName + "event.body = %O",
								event.body
							);
						if (event.body.value != undefined) {
							var currentFiles = event.body.value;
							const formData = new FormData();

							currentFiles.forEach((f: any) => {
								Global.User.DebugMode &&
									console.log("f = %O", f);
								service
									.constructFileRecordForList(f)
									.then((file: any) => {
										service.files.push(file);
										Global.User.DebugMode &&
											console.log(
												service.componentName +
													"this.files = %O",
												service.files
											);
									});
							});
						}
					}
				});
		}
	}

	private setFileImage(file: any) {
		Global.User.DebugMode &&
			console.log(this.componentName + "setFileImage: file = %O", file);
		var includeThumbnail = false;
		var filename = file.data.name;

		if (filename == "Pasted Image") {
			includeThumbnail = true;
		} else if (filename.indexOf(".") == -1) {
			//-- user didn't name the file with a file extension.  Add the appropriate file extension so we can get the correct thumbnail picture, if applicable. --Kirk T. Sherer, February 5, 2021.
			if (file.IsVideoServerImage) {
				filename = file.videoServerFilename;
			} else {
				filename = filename + ".png";
			}
		}

		var fileExtension = filename.split(".").last().toLowerCase();
		// Global.User.DebugMode && console.log("fileExtension = " + fileExtension);
		if (
			fileExtension == "jpg" ||
			fileExtension == "png" ||
			fileExtension == "jpeg" ||
			fileExtension == "gif" ||
			fileExtension == "bmp"
		) {
			includeThumbnail = true;
		}
		// Global.User.DebugMode && console.log("this.iconmenu = " + this.iconmenu);
		// Global.User.DebugMode && console.log("this.iconmenu.indexOf(fileExtension.toLowerCase()) = " + this.iconmenu.indexOf(fileExtension.toLowerCase()));

		//Assign an icon for the file
		if (this.iconmenu.indexOf(fileExtension.toLowerCase()) > -1) {
			//This is an icon file display. Assign the right one.
			file.iconFile =
				Global.imagesUrl +
				"assets/img/FileUpload/icon-" +
				fileExtension +
				".png";
		} else {
			file.iconFile = null;
		}

		// Global.User.DebugMode && console.log("file.iconFile = " + file.iconFile);

		if (file.imageKey != null) {
			if (file.isVideoServerImage) {
				var url =
					this.videoKeyURL +
					file.videoServerFilename +
					"?accessToken=" +
					encodeURIComponent(this.accessToken);
				file.thumbnailUrl = null;
				file.viewUrl = url;
			} else {
				file.thumbnailUrl =
					this.dataService.imageKeyURL + file.imageKey;
				file.viewUrl = file.thumbnailUrl;
			}
		}

		// Global.User.DebugMode && console.log("file.thumbnailUrl = " + file.thumbnailUrl);

		return file;
	}

	private uploadFiles() {
		this.fileUpload.nativeElement.value = "";
		Global.User.DebugMode &&
			console.log(this.componentName + "this.files = %O", this.files);

		this.numberOfFilesCheck(this.files);

		if (this.files.length > 1) {
			this.files.forEach((file) => {
				if (!file.imageKey && !file.inProgress) {
					Global.User.DebugMode &&
						console.log(
							this.componentName + "uploadFiles: file = %O",
							file
						);
					this.uploadFile(file, this);
				}
			});
		} else {
			var file = this.files.first();
			this.uploadFile(file, this);
		}
	}

	numberOfFilesCheck(files: any) {
		var existingFileCount = 0;
		var newFileCount = 0;
		var existingFile: any = null;
		if (files.length > 1) {
			files.forEach((file: any) => {
				if (file.imageKey) {
					existingFileCount++;
					existingFile = file;
				} else {
					newFileCount++;
				}
			});
		} else {
			existingFile = files.first();
			newFileCount = 1;
		}

		if (existingFileCount == 1 && newFileCount >= 1) {
			//-- user is replacing the current file for this field.  Mark existing file as needing replacement. --Kirk T. Sherer, June 10, 2020.
			this.replacingFile = true;
			this.existingFileToReplace = existingFile;
		}

		if (newFileCount > 1) {
			swal.fire({
				title: "Too many files",
				html: "You are trying to upload multiple files when this is a single field for the file upload.  Keeping the first file and discarding the rest.",
				showCancelButton: false,
				confirmButtonText: "Ok",
			});
		}
	}

	onClick() {
		const fileUpload = this.fileUpload.nativeElement;
		fileUpload.onchange = () => {
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				this.files.push({ data: file, inProgress: false, progress: 0 });
			}
			this.uploadFiles();
		};

		fileUpload.click();
	}

	pasteTargetOnClickEvent(event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + "pasteTargetOnClickEvent: event = %O",
				event
			);
		//-- save all modified descriptions in the file upload list here. --Kirk T. Sherer, April 27, 2020.
	}

	traverseFileTree(fileObject: any, path?: string) {
		//-- use this function whenever you can figure out how to use the createReader and readEntries functions on a directory object.--Kirk T. Sherer, May 21, 2020.
		var service = this;
		path = path || "";
		Global.User.DebugMode &&
			console.log(
				service.componentName + "traverseFileTree fileObject = %O",
				fileObject
			);

		if (fileObject?.data?.type != "") {
			////console.log("Adding to queue in new routine");
			this.uploadFile(fileObject, service);
		} else {
			// Get folder contents
			var dirReader = fileObject?.data?.createReader();
			dirReader?.readEntries(function (entries) {
				for (var i = 0; i < entries.length; i++) {
					service.traverseFileTree(
						entries[i],
						path + fileObject.data.name + "/"
					);
				}
			});
		}
	}

	private async constructFileRecordForList(f: any) {
		const formData = new FormData();
		var file: any = {};
		file.data = {};
		file.data.lastModified =
			this.utilityService.JavascriptDateTimeInMilliseconds(f.CreateDate);
		file.data.lastModifiedDate = new Date(f.CreateDate);
		file.data.name = f.Filename;
		file.data.description = f.Description;
		file.data.size = f.OriginalSize;
		file.data.type = "";
		file.data.webkitRelativePath = "";

		formData.append("file", file.data);
		file.inProgress = false;
		file.name = f.Filename;
		file.lastModifiedDate = new Date(f.CreateDate);
		file.size = f.OriginalSize;
		file.imageKey = f.ImageKey;
		var fileExtension = f.Filename.split(".").last().toLowerCase();
		var defaultVideoFileName = f.ImageKey + "." + fileExtension;
		file.isVideoServerImage =
			f.IsVideoServerImage == null ? false : f.IsVideoServerImage;
		file.videoServerFilename = f.VideoServerFilename
			? f.VideoServerFilename
			: f.IsVideoServerImage
			? defaultVideoFileName
			: null;
		file.thumbnailImage = f.ThumbnailBase64
			? this.sanitizer.bypassSecurityTrustResourceUrl(
					"data:image/png;base64," + f.ThumbnailBase64
			  )
			: null;
		file = this.setFileImage(file);
		Global.User.DebugMode &&
			console.log(this.componentName + "file = %O", file);
		return file;
	}

	public editFile(file: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();

		Global.User.DebugMode &&
			console.log(this.componentName + "Editing file = %O", file);
		event.stopPropagation();
		file.edit = true;
	}

	updateFileName(file: any, event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + "Edit file change result = %O",
				event
			);
		var changedObject: any = event.target;
		Global.User.DebugMode &&
			console.log(
				this.componentName + "changedObject = %O",
				changedObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"changedObject.value = " +
					changedObject.value
			);
		var newFileName = changedObject.value;
		var originalFileName = file.data.name;
		var originalExtension = originalFileName
			.split(".")
			.last()
			.toLowerCase();
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"newFileName = " +
					newFileName +
					", originalFileName = " +
					originalFileName +
					", originalExtension = " +
					originalExtension
			);

		if (newFileName.indexOf(".") == -1) {
			newFileName = newFileName + "." + originalExtension; //-- if user removed the extension, put it back. --Kirk T. Sherer, February 5, 2021.
		}

		if (newFileName != originalFileName) {
			file.data.name = newFileName;
			var sqlStatement =
				"API.FileImage_UpdateFileName @ImageKey='" +
				file.imageKey +
				"', @FileName='" +
				newFileName +
				"'";
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								": " +
								sqlStatement +
								" data = %O",
							data
						);
					if (data && data.length > 0) {
						this.utilityService.showToastMessageShared({
							type: "info",
							message:
								"The '" +
								originalFileName +
								"' file has been renamed to '" +
								newFileName +
								"'.",
							title: this.listTitle,
						});
						//   this.toastr.info("The '" + originalFileName + "' file has been renamed to '" + newFileName + "'.", this.listTitle);
					}
				});
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message: "File '" + originalFileName + "' remains unchanged.",
				title: this.listTitle,
			});
			//   this.toastr.info("File '" + originalFileName + "' remains unchanged.", this.listTitle);
		}
		file.edit = false;
	}

	updateFileDescription(file: any, event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"updateFileDescription invoked... event = %O",
				event
			);
		var changedObject: any = event.target;
		Global.User.DebugMode &&
			console.log(
				this.componentName + "changedObject = %O",
				changedObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"changedObject.value = " +
					changedObject.value
			);
		var newFileDescription = changedObject.value;
		var originalFileDescription = file.data.description;

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"newFileDescription = " +
					newFileDescription +
					", originalFileDescription = " +
					originalFileDescription
			);

		if (newFileDescription != originalFileDescription) {
			file.data.description = newFileDescription;
			var sqlStatement =
				"API.FileImage_UpdateFileDescription @ImageKey='" +
				file.imageKey +
				"', @Description='" +
				newFileDescription +
				"'";
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName + sqlStatement + " data = %O",
							data
						);
					if (data && data.length > 0) {
						this.utilityService.showToastMessageShared({
							type: "info",
							message:
								"The '" +
								file.data.name +
								"' file description has been updated.",
							title: this.listTitle,
						});
						//   this.toastr.info("The '" + file.data.name + "' file description has been updated.");
					}
				});
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message:
					"The '" +
					file.data.name +
					"' file description remains unchanged.",
				title: this.listTitle,
			});
			//   this.toastr.info("The '" + file.data.name + "' file description remains unchanged.", this.listTitle);
		}
		file.edit = false;
	}
}
