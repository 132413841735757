import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import _ from 'lodash';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { IGlobal } from '../../_models/global.model';
import { FormControl, FormGroup } from '@angular/forms';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: 'lib-dashboard-custom-timescope-popup-confirmation-settings',
	templateUrl:
		'./dashboard-custom-timescope-popup-confirmation-settings.component.html',
	styleUrls: [
		'./dashboard-custom-timescope-popup-confirmation-settings.component.scss',
	],
})
export class DashboardCustomTimescopePopupConfirmationSettingsComponent
	implements OnInit
{
	public global: IGlobal = Global;
	form: FormGroup;
	maxDate: Date;

	constructor(
		@Inject(MAT_DIALOG_DATA) public inputObject: any,
		public dialogRef: MatDialogRef<DashboardCustomTimescopePopupConfirmationSettingsComponent>,
		private dataService: DataService,
		public toastr: ToastrService,
		private utilityService: UtilityService
	) {}

	ngOnInit() {
		this.maxDate = new Date();
		this.form = new FormGroup({
			startDateTime: new FormControl(
				new Date(this.inputObject.dashboardObject.CustomStartDateMS)
			),
			endDateTime: new FormControl(
				new Date(this.inputObject.dashboardObject.CustomEndDateMS)
			),
		});
		console.log(this.inputObject);
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		console.log(this.form.value);
		let startDateMS = this.form.value.startDateTime.getTime();
		let endDateMS = this.form.value.endDateTime.getTime();
		if (startDateMS > endDateMS) {
			this.utilityService.showToastMessageShared({
				type: 'error',
				message:
					'Please make sure the start date is before the end date.',
				title: 'Improper Date Range',
			});
			this.toastr.error(
				'Please make sure the start date is before the end date.'
			);
			return;
		} else if (startDateMS === endDateMS) {
			this.utilityService.showToastMessageShared({
				type: 'error',
				message:
					'Please make sure the start date is before the end date.',
				title: 'Improper Date Range',
			});
			return;
		} else {
			this.dialogRef.close({
				startDateMS: startDateMS,
				endDateMS: endDateMS,
			});
		}
		// this.dialogRef.close(this.inputObject);
	}
}
