<lib-loading-spinner *ngIf="isDataLoading" [loadingMessage]="'Loading Weather...'" [vehicle]="'plane'"></lib-loading-spinner>

<div style="overflow-y: scroll; height: calc(100%)">
	<div *ngIf="!isDataLoading && currentWeather !== undefined" class="container">
		<div class="row">
			<div class="col-12">
				<h4 *ngIf="!isDataLoading">
					Weather last retrieved:
					{{ currentWeather.ObservationDate | date: "medium" }}
				</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h1>
					<i class="wi wi-owm-{{ currentWeather.IconId }}"></i>
					{{ currentWeather.TemperatureFahrenheit | number: "1.0-0" }}<i class="wi wi-fahrenheit"></i>
				</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h4>
					Feels like
					{{ currentWeather.HeatIndexFahrenheit | number: "1.0-0" }}<i class="wi wi-fahrenheit"></i> ({{ currentWeather.Description }})
				</h4>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<h4>
					Low
					{{ currentWeather.LowTemperatureFahrenheit | number: "1.0-0" }}<i class="wi wi-fahrenheit"></i> High {{ currentWeather.HighTemperatureFahrenheit | number: "1.0-0" }}<i class="wi wi-fahrenheit"></i>
				</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h4>
					{{ currentWeather.WindMPH }} mph &nbsp;
					{{ weatherService.getCardinalDirectionFromDegrees(currentWeather.WindDirectionDegrees) }}
					&nbsp;
					<i
						class="wi wi-wind-direction"
						[ngStyle]="{
							transform: 'rotate(' + currentWeather.WindDirectionDegrees + 'deg)'
						}"
					></i>
				</h4>
				<!-- <h4>
          Clouds:
          {{ currentWeather.clouds?.all }}%
        </h4> -->
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h4>Pressure: {{ currentWeather.PressureMillibars }}mb</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<!-- <h4>
          Sunrise: {{ currentWeather.sys?.sunrise * 1000 | date: "short" }}
        </h4>
        <h4>Sunset: {{ currentWeather.sys?.sunset * 1000 | date: "short" }}</h4> -->
				<h4>Visibility: {{ currentWeather.VisibilityMeters }}m</h4>
				<h4>UV Index: {{ currentWeather.UVIndex }}</h4>
			</div>
		</div>
	</div>
</div>
