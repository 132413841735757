<div class="wrapper">
  <div class=" navbar-minimize-fixed d-inline" (click)="minimizeSidebar()">
    <button
      class=" minimize-sidebar btn btn-link btn-just-icon"
      placement="right"
    >
      <i class=" tim-icons icon-align-center visible-on-sidebar-regular"> </i>
      <i class=" tim-icons icon-bullet-list-67 visible-on-sidebar-mini"> </i>
    </button>
  </div>
  <div class="sidebar sidebar-wrapper"><app-sidebar></app-sidebar></div>
  <div class="main-panel main-navbar" style="overflow: hidden !important;">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-footer *ngIf="router.url != '/maps/full-screen'"></app-footer>
  </div>
</div>
