<div class="full-page login-page">
	<div class="content">
		<div class="container">
			<div class="col-lg-4 col-md-6 ml-auto mr-auto">
				<div class="card card-login card-white">
					<div class="card-header login-form">
						<img alt=""[src]="global.imagesUrl + 'assets/img/iOPSProLogo.png'" width="150px" height="100px" style="margin-left: 95px;" />
					</div>
					<div class="card-body">
						<form class="form" [formGroup]="resetPasswordForm" autocomplete="off" (ngSubmit)="resetPassword(resetPasswordForm.value)" novalidate>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<i class="tim-icons icon-lock-circle"> </i>
									</div>
								</div>
								<input formControlName="newPassword" class="form-control" placeholder="New Password{{ !validateNewPassword() || mouseoverLogin ? ' is required' : '' }}" id="newPassword" type="password" />
							</div>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<i class="tim-icons icon-lock-circle"> </i>
									</div>
								</div>
								<input formControlName="newPasswordConfirmation" class="form-control" placeholder="Confirm New Password{{ !validateNewPasswordConfirmation() || mouseoverLogin ? ' is required' : '' }}" id="newPasswordConfirmation" type="password" />
							</div>
						</form>
					</div>
					<div class="card-footer">
						<div *ngIf="resetPasswordForm.valid" class="text-center">
							<div class="link footer-link">Passwords {{ passwordsMatch ? "" : "DO NOT " }}match.</div>
						</div>
						<span (mouseenter)="mouseoverLogin = true" (mouseleave)="mouseoverLogin = false">
							<button class="btn btn-success btn-lg btn-block mb-3" type="submit" (click)="resetPassword(resetPasswordForm.value)" [disabled]="resetPasswordForm.invalid || !passwordsMatch">
								Change Password
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
