import { Component, EventEmitter, Input, OnInit, Optional, Output, OnDestroy } from "@angular/core";
import { Global } from "../../_constants/global.variables";
import { IGlobal } from "../../_models/global.model";
import * as moment from 'moment';


@Component({
	selector: "lib-playback-controller",
	templateUrl: "./playback-controller.component.html",
	styleUrls: ["./playback-controller.component.scss"],
})
export class PlaybackControllerComponent implements OnInit {
	@Input() liveStream: boolean = false;
	@Input () public sliderValue: any = 1;
	@Input() data: any;
	@Output() newIndex = new EventEmitter<any>();

	@Output() newToggle = new EventEmitter<any>();

	public global: IGlobal = Global;
	@Input() public showMap : boolean = false;
	public showTable: boolean = true;
	public showMapLegend: boolean = false;
	public showTooltip: boolean = false;
	public showLegend: boolean = false;
	public showPlotLines: boolean = true;
	public show24HrTime: boolean = true;
	public showPlotBands: boolean = false;

	public showYAxisTitles: boolean = true;



	control = "Pause";
	@Input() currentDataLength = 0;
	playbackInterval: any;

	constructor() {}

	ngOnInit(): void {}

	setPoint(item: any) {
		this.controlPlayback("Pause");

		this.sliderValue = item.point.index;
	}



	formatLabel (){

		let ct = this.data[this.sliderValue - 1]?.Date.toLocaleString();
		let mt = moment(this.data[this.sliderValue - 1]?.Date).format('MM/DD/YYYY HH:mm:ss');
		if(this.show24HrTime){
			return mt
		} else {
			return ct
		}
		// return date + ' : ' + cd;

	  }

	//   formatLabel(value: number): string {
	// 	if (value >= 1000) {
	// 	  return Math.round(value / 1000) + 'k';
	// 	}

	// 	return `${value}`;
	//   }

	dragStart(event){
		this.controlPlayback("Pause");
	}

	toggleChange(event, type){
		if(type === "map"){
			this.showMap = event.checked;
		} else if(type === "table"){
			this.showTable = event.checked;
		} else if(type === 'mapLegend'){
			this.showMapLegend = event.checked;
		} else if(type === 'tooltip'){
			this.showTooltip = event.checked;
		} else if (type === 'legend'){
			this.showLegend = event.checked;
		} else if (type === 'plotLines'){
			this.showPlotLines = event.checked;
		} else if (type === 'plotBands'){
			this.showPlotBands = event.checked;
		} else if (type === 'yAxisTitles'){
			this.showYAxisTitles = event.checked;
		} else if (type == 'show24HrTime'){
			this.show24HrTime = event.checked;
		}
		this.newToggle.emit({map: this.showMap, table: this.showTable, mapLegend: this.showMapLegend, tooltip: this.showTooltip, legend: this.showLegend, plotLines: this.showPlotLines, plotBands: this.showPlotBands, yAxisTitles: this.showYAxisTitles, show24HrTime: this.show24HrTime});
	}

	onSliderChange(event){
		if(event !== undefined ){


			this.newIndex.emit(this.sliderValue);
		}

	}

	controlPlayback(control: any) {
		this.control = control;
		if(this.playbackInterval !== undefined){
			clearInterval(this.playbackInterval);
			this.playbackInterval = undefined;

		}
		if (this.control == "Play" || this.control == "Rewind") {
			if (this.playbackInterval === undefined) {
				this.playbackInterval = setInterval(() => {
					if(this.sliderValue !== this.currentDataLength ){
						if (this.control == "Play") {
							this.sliderValue++;

						} else if (this.control == "Rewind") {
							this.sliderValue--;

						}



						this.newIndex.emit(this.sliderValue);

					} else if(this.sliderValue == this.currentDataLength){
						 if (this.control == "Rewind") {
							this.sliderValue--;




							this.newIndex.emit(this.sliderValue);

						} else if( this.liveStream === false){
							this.controlPlayback("Pause");
						}

					}



					if( this.sliderValue == 1){
						this.controlPlayback("Pause");
					}


					// }
					// )
				}, 1000);
			}
		}

		if (this.control == "Pause") {

			if (this.playbackInterval !== undefined) {

				this.playbackInterval = undefined;
			}
		}
	}

	ngOnDestroy() {
		if (this.playbackInterval !== undefined) {
			clearInterval(this.playbackInterval);
		}
	}
}
