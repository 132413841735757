import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetSummaryParentComponent } from './asset-summary-parent.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MatTabsModule } from '@angular/material/tabs';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { LastThousandGridModule } from '../last-thousand-grid/last-thousand-grid.module';
import { FileUploadListMobileModule } from '../file-upload-list-mobile/file-upload-list-mobile.module';
import { MatBadgeModule } from '@angular/material/badge';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { TagGraphModule } from '../tag-graph/tag-graph.module';
import { GseActiveAlarmsModule } from '../gse-active-alarms/gse-active-alarms.module';
import { TagViewModule } from '../tag-view/tag-view.module';
import { ChartViewModule } from '../chart-view/chart-view.module';
import { AssetInfoViewModule } from '../asset-info-view/asset-info-view.module';
import { AssetStateViewModule } from '../asset-state-view/asset-state-view.module';
import { GseOperationalAlarmsModule } from '../gse-operational-alarms/gse-operational-alarms.module';
import { GridsterModule } from 'angular-gridster2';
import { GenericGridsterParentModule } from '../generic-gridster-parent/generic-gridster-parent.module';
import { GenericOverviewMapModule } from '../generic-overview-map/generic-overview-map.module';

@NgModule({
	declarations: [AssetSummaryParentComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		MatTabsModule,
		GridModule,
		FormsModule,
		ReactiveFormsModule,
		CenteredMessageModule,
		BreadcrumbsModule,
		LastThousandGridModule,
		FileUploadListMobileModule,
		MatBadgeModule,
		DynamicFormModule,
		KendoGridParentModule,
		PipeModule,
		TagGraphModule,
		GseActiveAlarmsModule,
		TagViewModule,
		AssetInfoViewModule,
		AssetStateViewModule,
		GseOperationalAlarmsModule,
		ChartViewModule,
		GridsterModule,
		GenericGridsterParentModule,
		GenericOverviewMapModule,
	],
	exports: [AssetSummaryParentComponent],
})
export class AssetSummaryParentModule {}
