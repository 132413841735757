import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaStatisticsContentComponent } from './ninja-statistics-content.component';
import { MatTabsModule } from '@angular/material/tabs';
import { GridModule } from '@progress/kendo-angular-grid';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { SassHelperModule } from '../../_helpers/sass-helper/sass-helper.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [NinjaStatisticsContentComponent],
	imports: [
		CommonModule,
		MatTabsModule,
		LoadingSpinnerModule,
		GridModule,
		MatSliderModule,
		ReactiveFormsModule,
		FormsModule,
		ChartsModule,
		SassHelperModule,
		KendoGridParentModule,
	],
	exports: [NinjaStatisticsContentComponent],
})
export class NinjaStatisticsContentModule {}
