/* eslint-disable no-mixed-spaces-and-tabs */
import { Component, OnInit, Input, Optional, ViewChild, ChangeDetectorRef, ViewEncapsulation, EventEmitter, OnDestroy, ElementRef, NgZone } from "@angular/core";

import { process, State } from "@progress/kendo-data-query";

import { GridSettings } from "../../_models/grid-settings.interface";
import { RowClassArgs, PageChangeEvent } from "@progress/kendo-angular-grid";
import { filter } from "rxjs/operators";
import _ from "lodash";

import { Subscription } from "rxjs";

import { FormGroup, FormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DisplayGrid, GridsterConfig, GridsterItem, GridType } from "angular-gridster2";
import { LastThousandGridComponent } from "../../components/last-thousand-grid/last-thousand-grid.component";
import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { NumberQuestion } from "../../_models/dynamic-fields/questions/question-number";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";
import { UtilityService } from "../../services/utility.service";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { MatDialog } from "@angular/material/dialog";
import { ITag } from "../../_models/tag.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";
import { IncomingDataService } from "../../services/incoming-data.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "lib-pca-summary",
	templateUrl: "./pca-summary.component.html",
	styleUrls: ["./pca-summary.component.scss"]
})
export class PcaSummaryComponent implements OnInit, OnDestroy {
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild("tagDataRowForCalculation") tagDataRowForCalculation: ElementRef;
	@ViewChild("eventDataRowForCalculation")
	eventDataRowForCalculation: ElementRef;
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	@ViewChild("alarmDataGrid") alarmDataGrid: KendoGridParentComponent;
	@ViewChild("last1000EventsGrid") last1000EventsGrid;
	@ViewChild("last1000AlarmsGrid") last1000AlarmsGrid;
	@ViewChild("hourMeterReadingEventsGrid")
	hourMeterReadingEventsGrid: KendoGridParentComponent;

	@Input() private pcaSummaryCreatedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	pcaSummaryCreatedSubscription: Subscription;
	tagDataRowHeight: any = 43;
	eventDataRowHeight: any = 26;
	assetTagIds = [];
	quickViewTagIds = [];
	alarmTagIds = [];
	assetObj: any;
	siteUTCTimeOffset: Number;
	timeZoneType: any;
	assetBaseImageURL: string;
	fullDataCache$: any;
	theme: string;
	gaugeTextColor: string;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	fromDashboard: boolean;
	defaultRightWidth = 40;
	defaultLeftWidth = 60;
	defaultWidth = 100;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	assetGraphics: any;
	finalGraphicsArray = [];
	graphicsJBTStandardIds = [];
	widgetGroupSettings: IWidgetSignalRGroupObject;
	selectedMatTabIndex = 0;
	tagDataPageSize: number;
	eventDataPageSize: number;
	estopTagId: any;
	dischargeTempId: any;
	ambientTempId: any;
	dischModeId: any;
	operationModeId: any;
	rhTempId: any;
	overnightModeId: any;
	blowerId: any;
	smokeDetectorId: any;
	phaseMonitorId: any;
	blowerVFDFaultId: any;
	dirtyFilterId: any;
	condensatePumpId: any;
	fan1Id: any;
	fan2Id: any;
	fan3Id: any;
	fan4Id: any;
	heat1Id: any;
	heat2Id: any;
	heat3Id: any;
	heat4Id: any;
	heat5Id: any;
	comp1Id: any;
	comp2Id: any;
	comp3Id: any;
	comp4Id: any;
	primaryCompressure1PressureId: any;
	primaryCompressure1SuctionId: any;
	primaryCompressure2PressureId: any;
	primaryCompressure2SuctionId: any;
	primaryCompressure3PressureId: any;
	primaryCompressure3SuctionId: any;
	assetModelTemplate: any[];
	templateRetrievalMessage: string;
	currentUserOrganizationId: any;
	pcaAssetTemplate: Array<GridsterItem>;
	partsInfoByAssetModelId: Array<any>;
	showAssetModelTemplate: boolean;
	options: GridsterConfig;
	fullDataCacheSubscription: Subscription;
	widgetIsInViewSubscription: Subscription;
	showAssetModelTemplateDoesnotExist: boolean;
	isWidgetInView: boolean = false;
	parentContainerSize: any;
	countOfAlarms: any;
	alertStatus: any;
	public widgetInstance: number = Math.random();
	private componentName: string = "pca-summary: ";
	private quickViewIds = [3848, 3967, 3912, 13917, 56880, 15458, 56891, 3909, 3598, 3913, 13933, 12576, 15397, 56892, 3910, 3968, 1872, 13901, 56896, 56895, 56893, 12542, 3969, 56746, 13860, 56577, 56749, 56894, 57450, 57452, 57454, 57456, 57458, 57460, 57462, 57451, 57453, 57455, 57457, 57459, 57461, 57463, 3818, 13827, 3787, 13813, 3845, 13981, 56733, 13872, 57464, 57466, 57465, 57467, 2263, 4084, 2857, 15220, 2736, 15215, 56565, 15219, 3846, 2257, 12374, 13760, 57471, 1835, 4704, 3883, 3884, 3885, 12539];

	//Hour Meter Reading Fields
	public editHeader: string;
	public editSubHeader: string;
	public hourMeterReadingQuestions: QuestionBase<any>[];
	public hourMeterReadingFields: QuestionBase<any>[];
	public isHourMeterReadingFormLoading: boolean;
	public hourMeterReadingFormOptions: any = {
		submitButtonText: "Save Entry",
		submitButtonClass: "btn-success",
		submitButtonTitle: "Press this button to save the Hour Meter Reading entry.",
		saveValuesPerField: false,
		clearFieldsAfterSubmit: true,
		saveStoredProcedureName: "API.GS_HourMeterReading"
	};
	public hourMeterReadingEvents: any;
	currentHourMeterReadingValue: any;

	//-- Maintenance Mode / Out of Service fields
	public isMaintenanceFormLoading: boolean = true;
	public maintenanceHeader: string = "Maintenance";
	public maintenanceSubHeader: string = "Please enter the fields listed for this maintenance event.";
	public maintenanceFields: Array<any>;
	public maintenanceFormOptions: any = {
		submitButtonText: "",
		saveValuesPerField: true,
		saveStoredProcedureName: "API.Asset_UpdateMaintenanceModeOrOutOfService"
	};

	public maintenanceForm: FormGroup;
	private maintenanceMode: FormControl;
	private outOfService: FormControl;
	public outOfServiceReasonId: FormControl;
	public maintenanceModeReasonId: FormControl;

	public maintenanceModeReasons: Array<any>;
	public outOfServiceReasons: Array<any>;
	private fullAssetRecord: any;

	private checkAlarmNotificationsInterval;

	public widgetTabs = [
		{ textLabel: "Quick View", rendered: true },
		{ textLabel: "Data", rendered: false },
		{ textLabel: "Alerts", rendered: false },
		{ textLabel: "Last 1000 Events", rendered: false },
		{ textLabel: "Last 1000 Faults", rendered: false },
		{ textLabel: "Statistics", rendered: false },
		{ textLabel: "Overview", rendered: false },
		{ textLabel: "Maintenance", rendered: false }
	];

	public kendoGrids = {
		tags: {
			data: [],
			groupable: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 200,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "Favorite",
						title: "Favorite",
						filterable: true,
						filter: 'boolean',
						_width: 50,
					},
					{
						field: 'alertNotification',
						title: 'Alarm',
						filterable: true,
						filter: 'boolean',
						_width: 40,
						hidden: false,
						minResizableWidth: 40,
					},
				],
			} as GridSettings,
		},
		activeAlerts: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 50,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						_width: 100,
						minResizableWidth: 125
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "ValueWhenActive",
						title: "Value When Active",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		},
		last1000Events: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 125,
						_width: 100
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 100
					}
				]
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				alarmsOnly: false,
				gridInView: false,
				exportTitle: ""
			}
		},
		last1000Alarms: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 125,
						_width: 50
					},
					{
						field: "JavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "JavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						filter: "numeric",
						_width: 0
					},
					{
						field: "Duration",
						title: "Duration (secs)",
						filterable: true,
						_width: 100
					},
					{
						field: "DurationinMins",
						title: "Duration (mins)",
						filterable: true,
						_width: 100
					}
				]
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				currentAlarms: undefined,
				alarmsOnly: true,
				gridInView: false,
				exportTitle: ""
			}
		}
	};

	hourMeterReadingEventsGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			take: 15
		},

		columnsConfig: [
			{
				field: "Reading",
				title: "Reading",
				filterable: true,
				_width: 50
			},
			{
				field: "Date",
				title: "Date",
				filterable: true,
				filter: "date",
				_width: 100
			},
			{
				field: "CreatorUser",
				title: "Creator User",
				filterable: true,
				_width: 100
			}
		]
	};

	dashboardTimeZoneChangedSubscription: Subscription;
	selectedMatTabLabel: any;
	assetId: any;
	tagGraphSingleModalSubscription: any;
	public gridContextMenuItems = ["Quick Trend of Tag"];
	public guid: string;

	constructor(public dataService: DataService, 
				private signalRCore: SignalRCoreService, 
				private ref: ChangeDetectorRef, 
				private kendoSettingsService: KendoSettingsService, 
				private dashboardService: DashboardService, 
				private toastr: ToastrService, 
				private utilityService: UtilityService, 
				private dialog: MatDialog,
				private zone: NgZone,
				private incomingDataService: IncomingDataService
			) {

			}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					if (Global.Theme === "light") {
						this.theme = "light";
					} else if (Global.Theme === "dark") {
						this.theme = "dark";
					}
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			if (Global.Theme === "light") {
				this.theme = "light";
			} else if (Global.Theme === "dark") {
				this.theme = "dark";
			}
			this.initialize();
		}

		if (this.fromDashboard === true) {
			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
				if (data.widgetId === this.widgetObject.WidgetId) {
					if (this.tagDataRowForCalculation !== undefined) {
						this.tagDataRowHeight = this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}
					if (this.eventDataRowForCalculation !== undefined) {
						this.eventDataRowHeight = this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}
					console.log(this.widgetObject.isWidgetInView);
					if (this.isWidgetInView === false && data.isInView === true) {
						// this.loadDataForSelectedTab();
					}
					this.isWidgetInView = data.isInView;
				}
			});
		} else {
			this.isWidgetInView = true;
		}

		this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((theme: any) => {
			this.theme = theme;
			this.gaugeTextColor = theme === "dark" ? "#eceaea" : "#858585";
		});

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					console.log("Widget Time Zone Changed");
					this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);

					Object.values(this.kendoGrids).forEach((grid) => {
						// Adjust visible and hidden columns
						const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
						const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
						const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

						localDateCol.hidden = this.timeZoneType !== "User Time";
						siteDateCol.hidden = this.timeZoneType !== "Site Time";
						utcDateCol.hidden = this.timeZoneType !== "UTC Time";
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;

						const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
						const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
						const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
						if (localEndDateCol != undefined) {
							localEndDateCol.hidden = this.timeZoneType !== 'User Time';
							localEndDateCol.includeInChooser = !localEndDateCol.hidden;
						}
						if (siteEndDateCol != undefined) {
							siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
							siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
						}
						if (utcEndDateCol != undefined) {
							utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
							utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
						}
					});
				}
				//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
				this.initialize();
			});
		}
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
		if (this.dashboardTimeZoneChangedSubscription !== undefined) {
			this.dashboardTimeZoneChangedSubscription.unsubscribe();
		}

		if (this.checkAlarmNotificationsInterval) {
			clearInterval(this.checkAlarmNotificationsInterval);
		}
	}

	sliderSelect(event: Event, obj: any) {
		console.log("obj = %O", obj);
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
	}

	saveGridSettings() {
		if (this.fromDashboard) {
			let hiddenColsArray = [];
			let gridObjectsArray = [];
			if (this.tagDataGrid) {
				hiddenColsArray.push(this.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "tagDataGrid",
					gridObject: this.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.tags.gridSettings.state
				});
			}

			if (this.alarmDataGrid) {
				hiddenColsArray.push(this.alarmDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "alarmDataGrid",
					gridObject: this.alarmDataGrid.kendoGridParent,
					gridState: this.kendoGrids.activeAlerts.gridSettings.state
				});
			}

			if (this.hourMeterReadingEventsGrid) {
				hiddenColsArray.push(this.hourMeterReadingEventsGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "hourMeterReadingEventsGrid",
					gridObject: this.hourMeterReadingEventsGrid.kendoGridParent,
					gridState: this.hourMeterReadingEventsGridSettings.state
				});
			}

			if (this.last1000EventsGrid) {
				hiddenColsArray.push(this.last1000EventsGrid.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "last1000EventsGrid",
					gridObject: this.last1000EventsGrid.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.last1000Events.gridSettings.state
				});
			}
			if (this.last1000AlarmsGrid) {
				hiddenColsArray.push(this.last1000AlarmsGrid.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "last1000AlarmsGrid",
					gridObject: this.last1000AlarmsGrid.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.last1000Alarms.gridSettings.state
				});
			}

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(hiddenColsArray, this.widgetObject.WidgetId);
			this.widgetObject.WidgetKendoUIJson = this.kendoSettingsService.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId).then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
		}
	}

	mapGridSettings(gridSettings: GridSettings) {
		if (this.fromDashboard) {
			const state = gridSettings.state;
			console.log(state);
			let emptyArray: any = [];
			return {
				state,
				columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
				gridData: this.dataService.cache !== undefined ? process(this.kendoGrids.tags.data, state) : emptyArray
			};
		}
	}

	initialize() {
		Global.User.DebugMode && console.log(this.componentName + "initialize invoked...");

		if (this.fromDashboard && !this.widgetObject.WidgetGateSystemId) {
			this.isDataLoading = false;
			return;
		}

		this.selectedMatTabLabel = this.widgetTabs[0].textLabel;
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.fromDashboard ? this.widgetObject : this.dataObject);

		this.cacheJbtStandardObsLookup = this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetId !== undefined) {
				this.finishInitializingWidget();
			} else if (this.pcaSummaryCreatedEvent) {
				this.pcaSummaryCreatedSubscription = this.pcaSummaryCreatedEvent.subscribe((data) => {
					if (data.WidgetId === this.widgetObject.WidgetId) {
						this.finishInitializingWidget();
					}
				});
			}
		} else {
			this.assetObj = this.dataObject.assetObj;
			this.kendoGrids.last1000Events.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${this.assetObj.SiteName}_${this.assetObj.GateName}_${this.assetObj.Type}`;
			this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${this.assetObj.SiteName}_${this.assetObj.GateName}_${this.assetObj.Type}`;

			// Get the Global Theme and set the text colors for the gauges
			this.theme = Global.Theme;
			this.gaugeTextColor = this.theme === "dark" ? "#eceaea" : "#858585";

			this.finishInitializingWidget();
		}
	}

	finishInitializingWidget() {
		Global.User.DebugMode && console.log(this.componentName + "finishInitializingWidget invoked...");
		if (!this.assetObj) {
			this.assetObj = this.dataService.cache.assets.find((a) => a.SiteId == this.widgetObject.WidgetSiteId && a.ParentSystemId == this.widgetObject.WidgetGateSystemId && a.Name == "PCA");
			this.kendoGrids.last1000Events.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${this.assetObj.Site.Name}_${this.assetObj.ParentSystem.Name}_${this.assetObj.AssetTypeName}`;
			this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${this.assetObj.Site.Name}_${this.assetObj.ParentSystem.Name}_${this.assetObj.AssetTypeName}`;
		}
		this.assetId = this.assetObj.Id;

		this.siteUTCTimeOffset = this.dataService.cache.sitesObject[this.widgetObject ? this.widgetObject.WidgetSiteId : this.assetObj.SiteId].UTCTimeOffset;
		this.kendoGrids.last1000Events.config.siteUTCTimeOffset = this.siteUTCTimeOffset;
		this.kendoGrids.last1000Alarms.config.siteUTCTimeOffset = this.siteUTCTimeOffset;

		//console.log("Getting tags....");
		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(this.assetObj.Id.toString()).subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + "dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O", data);
			this.formatTagDataForGrid(data);
		});

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetKendoUIJson) {
				// Reload saved Kendo Grid Settings
				let gridColumnConfigs = [];
				let jsonObjectParsed = this.kendoSettingsService.parseReturnedSettingsForDates(this.widgetObject.WidgetKendoUIJson);
				let tagDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "tagDataGrid");
				let alarmDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "alarmDataGrid");
				let hourMeterReadingEventsGridSettings = jsonObjectParsed.find((grid) => grid.name == "hourMeterReadingEventsGrid");
				let last1000EventsGridSettings = jsonObjectParsed.find((grid) => grid.name == "last1000EventsGrid");
				let last1000AlarmsGridSettings = jsonObjectParsed.find((grid) => grid.name == "last1000AlarmsGrid");

				if (tagDataGridSettings) {
					let mergedTemplateAndSavedColumnsForData = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.tags.gridSettings, tagDataGridSettings);
					this.kendoGrids.tags.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForData);
					gridColumnConfigs.push(this.kendoGrids.tags.gridSettings.columnsConfig);
				}

				if (alarmDataGridSettings) {
					let mergedTemplateAndSavedColumnsForAlarms = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.activeAlerts.gridSettings, alarmDataGridSettings);
					this.kendoGrids.activeAlerts.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForAlarms);
					gridColumnConfigs.push(this.kendoGrids.activeAlerts.gridSettings.columnsConfig);
				}

				if (hourMeterReadingEventsGridSettings) {
					let mergedTemplateAndSavedColumnsForHourMeter = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.hourMeterReadingEventsGridSettings, hourMeterReadingEventsGridSettings);
					this.hourMeterReadingEventsGridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForHourMeter);
					gridColumnConfigs.push(this.hourMeterReadingEventsGridSettings.columnsConfig);
				}

				if (last1000EventsGridSettings) {
					let mergedTemplateAndSavedColumnsForLast1000Events = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.last1000Events.gridSettings, last1000EventsGridSettings);
					this.kendoGrids.last1000Events.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForLast1000Events);
					gridColumnConfigs.push(this.kendoGrids.last1000Events.gridSettings.columnsConfig);
				}

				if (last1000AlarmsGridSettings) {
					let mergedTemplateAndSavedColumnsForLast1000Alarms = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.last1000Alarms.gridSettings, last1000AlarmsGridSettings);
					this.kendoGrids.last1000Alarms.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForLast1000Alarms);
					gridColumnConfigs.push(this.kendoGrids.last1000Alarms.gridSettings.columnsConfig);
				}

				Object.values(this.kendoGrids).forEach((grid) => {
					// Adjust visible and hidden columns
					const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
					const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
					const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

					localDateCol.hidden = this.timeZoneType !== "User Time";
					siteDateCol.hidden = this.timeZoneType !== "Site Time";
					utcDateCol.hidden = this.timeZoneType !== "UTC Time";
					localDateCol.includeInChooser = !localDateCol.hidden;
					siteDateCol.includeInChooser = !siteDateCol.hidden;
					utcDateCol.includeInChooser = !utcDateCol.hidden;

					const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
					const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
					const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
					if (localEndDateCol != undefined) {
						localEndDateCol.hidden = this.timeZoneType !== 'User Time';
						localEndDateCol.includeInChooser = !localEndDateCol.hidden;
					}
					if (siteEndDateCol != undefined) {
						siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
						siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
					}
					if (utcEndDateCol != undefined) {
						utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
						utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
					}
				});

				this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(gridColumnConfigs, this.widgetObject.WidgetId);
			}
		}

		Object.values(this.kendoGrids).forEach((grid) => {
			// Adjust visible and hidden columns
			const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
			const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
			const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

			localDateCol.hidden = this.timeZoneType !== "User Time";
			siteDateCol.hidden = this.timeZoneType !== "Site Time";
			utcDateCol.hidden = this.timeZoneType !== "UTC Time";
			localDateCol.includeInChooser = !localDateCol.hidden;
			siteDateCol.includeInChooser = !siteDateCol.hidden;
			utcDateCol.includeInChooser = !utcDateCol.hidden;

			const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
			const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
			const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
			if (localEndDateCol != undefined) {
				localEndDateCol.hidden = this.timeZoneType !== 'User Time';
				localEndDateCol.includeInChooser = !localEndDateCol.hidden;
			}
			if (siteEndDateCol != undefined) {
				siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
				siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
			}
			if (utcEndDateCol != undefined) {
				utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
				utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
			}
		});

		// Get the Global Theme and set the text colors for the gauges
		this.theme = Global.Theme;
		this.gaugeTextColor = this.theme === "dark" ? "#eceaea" : "#858585";

		if (this.dataObject !== undefined) {
			this.dataObject.isDisplayDataLive = true;
		} else if (this.widgetObject !== undefined) {
			this.widgetObject.isDisplayDataLive = true;
		}

		//Get AssetModelId
		if (!this.assetObj.AssetModelId) {
			let assetObj = this.dataService.cache.assets.find((a) => a.Id == this.assetObj.Id);
			this.assetObj.AssetModelId = assetObj?.AssetModelId;
		}
	}

	buildSummaryInformation() {
		this.showAssetModelTemplateDoesnotExist = false;
		this.showAssetModelTemplate = false;
		this.assetModelTemplate = [];
		this.currentUserOrganizationId = this.dataService.cache.people.filter((p: any) => {
			return p.UserId == Global.User.currentUser.Id;
		})[0].OrganizationId;
		this.templateRetrievalMessage = "Searching for existing template...";
		// Verify if the PCA Asset has Model assigned to it
		if (this.assetObj.AssetModelId != null && parseInt(this.assetObj.AssetModelId) < 1) {
			this.templateRetrievalMessage = "Asset Model doesnt exist for " + (this.widgetObject ? this.widgetObject.WidgetSiteName : this.assetObj.SiteName) + " Gate - " + (this.widgetObject ? this.widgetObject.WidgetGateSystemName : this.assetObj.GateName) + " PCA. Please contact System Admin";
			this.showAssetModelTemplateDoesnotExist = true;
		}

		//SQL Statment to retrive the Asset Template Info
		else {
			let statement = `GetAssetModelTemplateByAssetModelIdAndOrganizationId @OrganizationId =  ${this.currentUserOrganizationId} ,@AssetModelId = ${this.assetObj.AssetModelId}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode && console.log("API.GetAssetModelTemplateByAssetModelIdAndOrganizationId data = %O", data);
				if (data.length > 0) {
					this.buildSummaryAssetTemplateInformation(data);
				} else {
					var modelName = this.dataService.cache.assetModels.filter((p: any) => {
						return p.Id == this.assetObj.AssetModelId;
					})[0].Model;
					this.templateRetrievalMessage = "Template does not exist for asset model : " + modelName + " Please create one in Canvas Widget";
					this.showAssetModelTemplateDoesnotExist = true;
				}
			});
		}
	}

	buildSummaryAssetTemplateInformation(assetModelTemplate: any[]) {
		if (this.estopTagId === undefined) {
			let estopTag = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 12384 && t.AssetId === this.assetObj.Id;
			});
			if (estopTag !== undefined) {
				this.estopTagId = estopTag.TagId;
			}
		}
		if (this.dischargeTempId === undefined) {
			let dischargeTemp = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 2736 && t.AssetId === this.assetObj.Id;
			});
			if (dischargeTemp !== undefined) {
				this.dischargeTempId = dischargeTemp.TagId;
			}
		}
		if (this.ambientTempId === undefined) {
			let ambientTemp = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 4084 && t.AssetId === this.assetObj.Id;
			});
			if (ambientTemp !== undefined) {
				this.ambientTempId = ambientTemp.TagId;
			}
		}
		if (this.rhTempId === undefined) {
			let rhTemp = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 2263 && t.AssetId === this.assetObj.Id;
			});
			if (rhTemp !== undefined) {
				this.rhTempId = rhTemp.TagId;
			}
		}
		if (this.dischModeId === undefined) {
			let dischMode = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 15215 && t.AssetId === this.assetObj.Id;
			});
			if (dischMode !== undefined) {
				this.dischModeId = dischMode.TagId;
			}
		}
		if (this.operationModeId === undefined) {
			let operationMode = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 15219 && t.AssetId === this.assetObj.Id;
			});
			if (operationMode !== undefined) {
				this.operationModeId = operationMode.TagId;
			}
		}
		if (this.overnightModeId === undefined) {
			let overnightMode = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3846 && t.AssetId === this.assetObj.Id;
			});
			if (overnightMode !== undefined) {
				this.overnightModeId = overnightMode.TagId;
			}
		}
		if (this.blowerId === undefined) {
			let blower = this.dataService.cache.tags.find((t) => {
				return (t.JBTStandardObservationId === 12374 || t.JBTStandardObservationId === 13760) && t.AssetId === this.assetObj.Id;
			});
			if (blower !== undefined) {
				this.blowerId = blower.TagId;
			}
		}
		if (this.smokeDetectorId === undefined) {
			let smokeDetector = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 15227 && t.AssetId === this.assetObj.Id;
			});
			if (smokeDetector !== undefined) {
				this.smokeDetectorId = smokeDetector.TagId;
			}
		}
		if (this.phaseMonitorId === undefined) {
			let phaseMonitor = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 2246 && t.AssetId === this.assetObj.Id;
			});
			if (phaseMonitor !== undefined) {
				this.phaseMonitorId = phaseMonitor.TagId;
			}
		}
		if (this.blowerVFDFaultId === undefined) {
			let blowerVFDFault = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 15210 && t.AssetId === this.assetObj.Id;
			});
			if (blowerVFDFault !== undefined) {
				this.blowerVFDFaultId = blowerVFDFault.TagId;
			}
		}
		if (this.dirtyFilterId === undefined) {
			let dirtyFilter = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 2900 && t.AssetId === this.assetObj.Id;
			});
			if (dirtyFilter !== undefined) {
				this.dirtyFilterId = dirtyFilter.TagId;
			}
		}
		if (this.condensatePumpId === undefined) {
			let condensatePump = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3972 && t.AssetId === this.assetObj.Id;
			});
			if (condensatePump !== undefined) {
				this.condensatePumpId = condensatePump.TagId;
			}
		}
		if (this.fan1Id === undefined) {
			let fan1 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3787 && t.AssetId === this.assetObj.Id;
			});
			if (fan1 !== undefined) {
				this.fan1Id = fan1.TagId;
			}
		}
		if (this.fan2Id === undefined) {
			let fan2 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3818 && t.AssetId === this.assetObj.Id;
			});
			if (fan2 !== undefined) {
				this.fan2Id = fan2.TagId;
			}
		}
		if (this.fan3Id === undefined) {
			let fan3 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3845 && t.AssetId === this.assetObj.Id;
			});
			if (fan3 !== undefined) {
				this.fan3Id = fan3.TagId;
			}
		}
		if (this.fan4Id === undefined) {
			let fan4 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 12541 && t.AssetId === this.assetObj.Id;
			});
			if (fan4 !== undefined) {
				this.fan4Id = fan4.TagId;
			}
		}
		if (this.heat1Id === undefined) {
			let heat1 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 4704 && t.AssetId === this.assetObj.Id;
			});
			if (heat1 !== undefined) {
				this.heat1Id = heat1.TagId;
			}
		}
		if (this.heat2Id === undefined) {
			let heat2 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3883 && t.AssetId === this.assetObj.Id;
			});
			if (heat2 !== undefined) {
				this.heat2Id = heat2.TagId;
			}
		}
		if (this.heat3Id === undefined) {
			let heat3 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3884 && t.AssetId === this.assetObj.Id;
			});
			if (heat3 !== undefined) {
				this.heat3Id = heat3.TagId;
			}
		}
		if (this.heat4Id === undefined) {
			let heat4 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3885 && t.AssetId === this.assetObj.Id;
			});
			if (heat4 !== undefined) {
				this.heat4Id = heat4.TagId;
			}
		}
		if (this.heat5Id === undefined) {
			let heat5 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 12539 && t.AssetId === this.assetObj.Id;
			});
			if (heat5 !== undefined) {
				this.heat5Id = heat5.TagId;
			}
		}
		if (this.comp1Id === undefined) {
			let comp1 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3967 && t.AssetId === this.assetObj.Id;
			});
			if (comp1 !== undefined) {
				this.comp1Id = comp1.TagId;
			}
		}
		if (this.comp2Id === undefined) {
			let comp2 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3598 && t.AssetId === this.assetObj.Id;
			});
			if (comp2 !== undefined) {
				this.comp2Id = comp2.TagId;
			}
		}
		if (this.comp3Id === undefined) {
			let comp3 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3968 && t.AssetId === this.assetObj.Id;
			});
			if (comp3 !== undefined) {
				this.comp3Id = comp3.TagId;
			}
		}
		if (this.comp4Id === undefined) {
			let comp4 = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3969 && t.AssetId === this.assetObj.Id;
			});
			if (comp4 !== undefined) {
				this.comp4Id = comp4.TagId;
			}
		}
		if (this.primaryCompressure1PressureId === undefined) {
			let primaryCompressure1Pressure = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3848 && t.AssetId === this.assetObj.Id;
			});
			if (primaryCompressure1Pressure !== undefined) {
				this.primaryCompressure1PressureId = primaryCompressure1Pressure.TagId;
			}
		}
		if (this.primaryCompressure1SuctionId === undefined) {
			let primaryCompressure1Suction = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3912 && t.AssetId === this.assetObj.Id;
			});
			if (primaryCompressure1Suction !== undefined) {
				this.primaryCompressure1SuctionId = primaryCompressure1Suction.TagId;
			}
		}
		if (this.primaryCompressure2PressureId === undefined) {
			let primaryCompressure2Pressure = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3909 && t.AssetId === this.assetObj.Id;
			});
			if (primaryCompressure2Pressure !== undefined) {
				this.primaryCompressure2PressureId = primaryCompressure2Pressure.TagId;
			}
		}
		if (this.primaryCompressure2SuctionId === undefined) {
			let primaryCompressure2Suction = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3913 && t.AssetId === this.assetObj.Id;
			});
			if (primaryCompressure2Suction !== undefined) {
				this.primaryCompressure2SuctionId = primaryCompressure2Suction.TagId;
			}
		}
		if (this.primaryCompressure3PressureId === undefined) {
			let primaryCompressure3Pressure = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 3910 && t.AssetId === this.assetObj.Id;
			});
			if (primaryCompressure3Pressure !== undefined) {
				this.primaryCompressure3PressureId = primaryCompressure3Pressure.TagId;
			}
		}
		if (this.primaryCompressure3SuctionId === undefined) {
			let primaryCompressure3Suction = this.dataService.cache.tags.find((t) => {
				return t.JBTStandardObservationId === 1872 && t.AssetId === this.assetObj.Id;
			});
			if (primaryCompressure3Suction !== undefined) {
				this.primaryCompressure3SuctionId = primaryCompressure3Suction.TagId;
			}
		}

		this.options = {
			gridType: GridType.Fit,
			displayGrid: DisplayGrid.Always,
			pushItems: true
		};
		let statement = `GetAssetModelTemplateComponentsByPCAOverviewTemplateId @PCAOverviewTemplateId = ${assetModelTemplate[0].Id}`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode && console.log("API.GetAssetModelTemplateComponentsByPCAOverviewTemplateId data = %O", data);
			this.partsInfoByAssetModelId = data;
			this.pcaAssetTemplate = [];
			this.partsInfoByAssetModelId.forEach((t) => {
				this.pcaAssetTemplate.push({
					Id: t.Id,
					cols: t.Width,
					rows: t.Height,
					y: t.Row,
					x: t.Col,
					JBTStandardObservationId: t.JBTStandardObservationId,
					PCAOverviewWidgetTypeId: t.PCAOverviewWidgetTypeId,
					PCAOverviewTemplateId: t.PCAOverviewTemplateId,
					AssetModelId: t.AssetModelId,
					OrganizationId: t.OrganizationId
				});
			});
			Global.User.DebugMode && console.log(": this.pcaAssetTemplate = %O", this.pcaAssetTemplate);
			this.showAssetModelTemplate = true;
		});
	}

	checkAlarmNotifications(tags: any) {

		tags.forEach(t => {

			let userAlert = this.dataService.cache.emailTagUsers.find((a) =>
								a.UserId == Global.User.currentUser.Id &&
								(a.TagId == t.TagId ||
									(a.JbtStandardObservationId == t.JBTStandardObservationId && a.JbtStandardObservationSiteId == t.SiteId )
								));

			t.alertNotification = userAlert == undefined ? false : true;

			// set the title with alert info
			if(userAlert != undefined) {

				let title = "Alert notifications set for " + t.JBTStandardObservation.Name;
				let alertObject = this.GetAlertNotificationDetails(userAlert);

				if(alertObject.Email == true && alertObject.TextMessage == true) {
					title += " email and text delivery";
				}
				else {
					title += alertObject.Email == true ? " email delivery" : "";
					title += alertObject.TextMessage == true ? " text delivery" : "";
				}

				title += " on " + alertObject.DaysSelected;
				title += " during "+ alertObject.HoursSelected;

				t.alertNotificationTitle = title;
			}
			else {
				t.alertNotificationTitle = "Click to set alert management settings.";
			}

		});

	}


	GetAlertNotificationDetails(userAlert) : any {

		let alertObject: any = {
			siteId: 0,
			siteName: '',
			gateSystemId: 0,
			gateSystemName: '',
			category: '',
			Email: 0,
		};


		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if(userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		}
		else {
			alertObject.DaysSelected = "select days";
		}


		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if(userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		}
		else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}

	formatTagDataForGrid(tags: any) {
		Global.User.DebugMode && console.log(this.componentName + "formatTagDataForGrid invoked...");
		console.log(this.dataService.cache.assetsObject[this.assetObj.Id].Tags);
		this.buildHourMeterReadingFormQuestions();
		this.checkAlarmNotifications(tags);
		this.checkAlarmNotificationsInterval = setInterval(() => {
			this.checkAlarmNotifications(tags);
		}, 10000);

		Global.User.DebugMode && console.log(this.componentName + "formattedTags = %O", this.dataService.cache.assetsObject[this.assetObj.Id].Tags);

		this.assetTagIds = this.dataService.cache.assetsObject[this.assetObj.Id].Tags.map((t) => t.TagId);

		this.alarmTagIds = this.dataService.cache.assetsObject[this.assetObj.Id].Tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm" || t.Severity == "Warning").map((t) => t.TagId);

		//Quick View tags
		let quickViewTags = this.dataService.cache.assetsObject[this.assetObj.Id].Tags.filter((t) => this.quickViewIds.includes(t.JBTStandardObservationId));
		this.quickViewTagIds = quickViewTags.map((t) => t.TagId);

		this.kendoGrids.last1000Events.config.assetTagIds = this.dataService.cache.assetsObject[this.assetObj.Id].Tags.filter((t) => t.Severity == "Informational" || t.Severity == "Warning").map((t) => t.TagId);
		this.kendoGrids.last1000Alarms.config.assetTagIds = this.dataService.cache.assetsObject[this.assetObj.Id].Tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm").map((t) => t.TagId);
		// this.kendoGrids.last1000Alarms.config.currentAlarms = this.kendoGrids.activeAlerts.data.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm");

		// Get Alarm Tags from Tag Data

		this.countOfAlarms = this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags.length;
		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags);

		this.isDataLoading = false;
		this.getSignalRUpdates();
	}

	alertsStatus(activeAlertsData: any) {
		//Critical
		if (activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 3).length > 0)
			return 3;
		//Alarm
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 1).length > 0
		)
			return 1;
		//Warning
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 2).length > 0
		)
			return 2;
	}

	getSignalRUpdates() {
		let assetObjectInCache = this.dataService.cache.assetsObject[this.assetObj.Id];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + "this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid,
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					if (tag.Id === 11633677) {
						console.log("system 2 liquid received");
					}
					this.updateTagGridData();
					this.updateAlarmGridData();
					this.updateGraphics(tag);
				});
			});
	}

	updateAlarmGridData() {
		if (this.alarmDataGrid) {
			this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
		}

		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags);
	}

	updateGraphics(tagObj: any) {
		let graphicObj = this.finalGraphicsArray.find((graphic) => graphic.VisibleValues.some((visValue) => visValue.JBTStandardObservationId == tagObj.JBTStandardObservationId));
		if (graphicObj) {
			let visValueObj = graphicObj.VisibleValues.find((visValue) => visValue.JBTStandardObservationId == tagObj.JBTStandardObservationId);
			graphicObj.Visible = tagObj.Value == visValueObj.ValueWhenVisible ? true : false;

			Global.User.DebugMode && console.log(`${this.componentName}: Graphic Vsible Value with JBT Std Id ${visValueObj.JBTStandardObservationId} was updated to: ${graphicObj.Visible}`);
		}
	}

	updateTagGridData() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
		}
	}

	matTabHasChanged(e) {
		console.log(e);
		this.selectedMatTabLabel = e.tab.textLabel;
		this.widgetTabs.find((tab) => tab.textLabel == this.selectedMatTabLabel).rendered = true;
		this.kendoGrids.last1000Events.config.gridInView = this.selectedMatTabLabel === "Last 1000 Events" ? true : false;
		this.kendoGrids.last1000Alarms.config.gridInView = this.selectedMatTabLabel === "Last 1000 Faults" ? true : false;

		switch (this.selectedMatTabLabel) {
			case "Data":
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
				}
				break;

			case "Alerts":
				if (this.alarmDataGrid) {
					this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
				}
				break;

			case "Last 1000 Events":
				if (this.kendoGrids.last1000Events.isRendered) {
					this.last1000EventsGrid.updateDataAfterTabSwitch();
				} else {
					this.kendoGrids.last1000Events.isRendered = true;
				}
				break;
			case "Last 1000 Faults":
				if (this.kendoGrids.last1000Alarms.isRendered) {
					this.last1000AlarmsGrid.updateDataAfterTabSwitch();
				} else {
					this.kendoGrids.last1000Alarms.isRendered = true;
				}
				break;

			case "Statistics":
				this.buildHourMeterReadingFormQuestions();
				break;

			case "Overview":
				this.buildSummaryInformation();
				break;

			case "Maintenance":
				this.buildMaintenanceQuestions();
				break;
		}
	}

	buildMaintenanceQuestions() {
		if (this.assetObj !== undefined) {
			this.isMaintenanceFormLoading = true;
			this.fullAssetRecord = this.dataService.cache.assetsObject[this.assetObj.Id];

			//console.log("this.fullAssetRecord = %O", this.fullAssetRecord);
			this.maintenanceMode = new FormControl(this.fullAssetRecord.MaintenanceMode);
			this.outOfService = new FormControl(this.fullAssetRecord.OutOfService);
			this.maintenanceModeReasonId = new FormControl(this.fullAssetRecord.MaintenanceModeReasonId);
			this.outOfServiceReasonId = new FormControl(this.fullAssetRecord.OutOfServiceReasonId);

			this.maintenanceForm = new FormGroup({
				maintenanceMode: this.maintenanceMode,
				outOfService: this.outOfService,
				maintenanceModeReasonId: this.maintenanceModeReasonId,
				outOfServiceReasonId: this.outOfServiceReasonId
			});

			this.maintenanceModeReasons = this.dataService.cache.assetMaintenanceModeReasons;
			this.outOfServiceReasons = this.dataService.cache.assetOutOfServiceReasons;

			this.maintenanceForm.valueChanges.subscribe((val) => {
				Global.User.DebugMode && console.log(this.componentName + "" + "val = %O", val);

				if ((!this.maintenanceForm.value.maintenanceMode || this.maintenanceForm.value.maintenanceMode == "") && (!this.maintenanceForm.value.outOfService || this.maintenanceForm.value.outOfService == "")) {
					this.saveValue(null, this.maintenanceForm); //-- have to save the asset since the maintenance mode and out of service have both been set to false. --Kirk T. Sherer, October 21, 2020.
				}
			});

			this.isMaintenanceFormLoading = false;
		}
	}

	saveValue(event: Event, obj: any) {
		var service = this;
		if (event) {
			event.stopPropagation();
		}
		//		console.log("saveValue invoked... event.target = %O", event.target);
		//var changedObject: any = event.target;
		//		console.log("changedObject = %O", changedObject);

		//		console.log("obj = %O", obj);
		var sqlStatement = this.maintenanceFormOptions.saveStoredProcedureName + " @UserId=" + Global.User.currentUser.Id + ", @AssetId=" + this.assetObj.Id + ", @MaintenanceMode=" + (obj.value.maintenanceMode ? "1" : "0") + ", @OutOfService=" + (obj.value.outOfService ? "1" : "0") + ", @MaintenanceModeReasonId=" + (obj.value.maintenanceModeReasonId == "" || !obj.value.maintenanceMode ? "null" : obj.value.maintenanceModeReasonId) + ", @OutOfServiceReasonId=" + (obj.value.outOfServiceReasonId == "" || !obj.value.outOfService ? "null" : obj.value.outOfServiceReasonId);
		Global.User.DebugMode && console.log(this.componentName + "" + "SQL Statement = " + sqlStatement);

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//-- SECTION FOR SENDING OUT SIGNALR MESSAGE IMMEDIATELY BEFORE SENDING THE STORED PROCEDURE TO UPDATE THE ACTUAL ASSET RECORD AND CREATE THE OBSERVATIONS.
		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		var assetInCache = this.dataService.cache.assetsObject[this.assetObj.Id];
		assetInCache.Tags.forEach((tag:ITag) => {
			console.log("Id: " + tag.Id + ", Name: " + tag.Name + ", SimpleName: " + tag.SimpleName);
		});
		//-- Find the out of service and maintenance mode tags for this asset and build a set of SignalR messages so we can update the application immediately while the stored procedure is updating SQL Server.
		var maintenanceModeOutOfServiceTags = assetInCache.Tags.where((tag:ITag) => {

			return (tag.SimpleName?.toLowerCase().indexOf("is in maintenance mode") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("maintenance mode reason") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("is out of service") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("out of service reason") > -1)
 	    } ).toArray();

		//-- creating SignalR message for each of the four tags.
		if (maintenanceModeOutOfServiceTags.length > 0) {
			var signalRDataObject = "";
			maintenanceModeOutOfServiceTags.forEach((tag:ITag) => {
				signalRDataObject += "i!" + tag.Id + "~d!" + this.utilityService.DateTimeInMilliseconds(new Date()) + "~v!";
				var tagValue = "";
				switch (tag.SimpleName?.toLowerCase())
				{
					case "is in maintenance mode":
						tagValue = obj.value.maintenanceMode ? "1" : "0";
						break;
					case "maintenance mode reason":
						tagValue = obj.value.maintenanceModeReasonId == "" || !obj.value.maintenanceMode ? "" : obj.value.maintenanceModeReasonId;
						break;
					case "is out of service":
						tagValue = obj.value.outOfService ? "1" : "0";
						break;
					case "out of service reason":
						tagValue = obj.value.outOfServiceReasonId == "" || !obj.value.outOfService ? "" : obj.value.outOfServiceReasonId;
						break;
				}
				signalRDataObject += tagValue + "\r\n";
			});
			if (signalRDataObject != "") {
				console.log("Inserting SignalR Messages about changes to asset's maintenance mode / out of service into Incoming Data Array: %O", signalRDataObject);
				this.incomingDataService.incomingDataArray.push({
					code: "o", 
					object: signalRDataObject, 
					groupName: this.assetObj.TagNamePrefix
				});
			}
		}

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log(service.componentName + "" + "data = %O", data);
			//-- update data cache since leaving the Maintenance tab and coming back to it will reload what the asset was originally set to in the data cache. --Kirk T. Sherer, April 22, 2023.
			var currentAsset = service.dataService.cache.assetsObject[service.assetObj.Id];
			currentAsset.MaintenanceMode = obj.value.maintenanceMode;
			currentAsset.MaintenanceModeReasonId = obj.value.maintenanceMode == false ? null : obj.value.maintenanceModeReasonId;
			currentAsset.OutOfService = obj.value.outOfService;
			currentAsset.OutOfServiceReasonId = obj.value.outOfService == false ? null : obj.value.outOfServiceReasonId;

			service.utilityService.showToastMessageShared({
				type: "success",
				message: "Asset maintenance updated."
			});
			// this.toastr.success('Asset maintenance updated.');
		});
	}

	buildHourMeterReadingFormQuestions() {
		this.isHourMeterReadingFormLoading = true;

		this.editHeader = "Hour Meter Reading";
		this.editSubHeader = "Please enter the Hour Meter Reading";
		this.hourMeterReadingFields = [];

		var reading = new NumberQuestion({
			key: "@Reading",
			label: "Hour Meter Reading",
			value: null,
			required: true,
			title: "Please enter the Hour meter reading.",
			order: 1
		});

		this.hourMeterReadingFields.push(reading);

		Global.User.DebugMode && console.log("pca-summary: this.hourMeterReadingFields = %O", this.hourMeterReadingFields);

		this.isHourMeterReadingFormLoading = false;

		this.getPastHistoryOfHourMeterReadingEvents();
	}

	submitHourMeterReadingEvent(submittedValues: string) {
		Global.User.DebugMode && console.log("pca-summary: HourMeterReading event invoked... submittedValues: %O", submittedValues);
		var fieldListAsString = submittedValues.replace("{", "").replace("}", "").replace(/:/g, "=").replace(/\"/g, "");
		Global.User.DebugMode && console.log("pca-summary: fieldListAsString = " + fieldListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode && console.log("pca-summary: submittedValuesObject = %O", submittedValuesObject);
		Global.User.DebugMode && console.log("pca-summary: Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				fieldListAsString += value ? value : "null";
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		Global.User.DebugMode && console.log("fieldListAsString = " + fieldListAsString);

		var saveStoredProcedure = this.hourMeterReadingFormOptions.saveStoredProcedureName;
		this.dataService.SQLActionAsPromise(saveStoredProcedure + " @AssetId=" + this.assetObj.Id + ", @UserId = " + Global.User.currentUser.Id + ", " + fieldListAsString).then((data: any) => {
			console.log("pca-summary: " + saveStoredProcedure + " return value = %O", data);
			var currentHourMeterReadingEvent = data.first();
			this.getPastHistoryOfHourMeterReadingEvents(currentHourMeterReadingEvent);
		});
	}

	getPastHistoryOfHourMeterReadingEvents(lastEvent?: any, hourMeterReadingEvents?: any) {
		if (!this.hourMeterReadingEvents || (!lastEvent && !hourMeterReadingEvents)) {
			//-- if we don't have hourMeterReading events populated yet, then go get the list of hourMeterReading events again from SQL Server.
			this.dataService.SQLActionAsPromise("API.GS_HourMeterReading_ListOfPastReadingsByAssetId " + this.assetObj.Id).then((data: any) => {
				var hourMeterReadingEvents = data;
				var hourMeterReadingEventsSorted = _.orderBy(hourMeterReadingEvents, ["Id"], ["desc"]);
				this.hourMeterReadingEvents = hourMeterReadingEventsSorted;
				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				Global.User.DebugMode && console.log("pca-summary: this.hourMeterReadingEventsGridSettings.gridData = %O", this.hourMeterReadingEventsGridSettings.gridData);
				if (this.hourMeterReadingEvents != undefined && this.hourMeterReadingEvents.length > 0) {
					let hourMeterTagRecord = this.dataService.cache.tags.find((t) => {
						return t.JBTStandardObservationId === 56885 && t.AssetId === this.assetObj.Id;
					});
					if (hourMeterTagRecord != undefined && (hourMeterTagRecord.Value != null || hourMeterTagRecord.Value != "")) this.currentHourMeterReadingValue = hourMeterTagRecord.Value;
				}
			});
		} else {
			if (hourMeterReadingEvents) {
				this.hourMeterReadingEventsGridSettings.gridData = process(hourMeterReadingEvents, this.hourMeterReadingEventsGridSettings.state);
				Global.User.DebugMode && console.log("pca-summary: this.hourMeterReadingEventsGridSettings.gridData = %O", this.hourMeterReadingEventsGridSettings.gridData);
			} else {
				Global.User.DebugMode && console.log("pca-summary: this.hourMeterReadingEvents = %O", this.hourMeterReadingEvents);
				Global.User.DebugMode && console.log("pca-summary: lastEvent = %O", lastEvent);

				var hourMeterReadingEvents = this.hourMeterReadingEvents;
				hourMeterReadingEvents.push(lastEvent);
				var hourMeterReadingEventsSorted = _.orderBy(hourMeterReadingEvents, ["Id"], ["desc"]);
				this.hourMeterReadingEvents = hourMeterReadingEventsSorted;
				this.hourMeterReadingEventsGridSettings.gridData = process(hourMeterReadingEventsSorted, this.hourMeterReadingEventsGridSettings.state);
				if (lastEvent != undefined && lastEvent.Reading > 0) {
					this.currentHourMeterReadingValue = lastEvent.Reading;
				}
			}
		}
	}

	public onRightClickSelect({ dataItem, item }): void {
		if (item === "Quick Trend of Tag") {
			const tagGraphSingleModal = this.dialog.open(DialogModalParentComponent, {
				width: "95%",
				height: "80%",
				data: {
					TagId: dataItem.TagId,
					widgetNameDisplay: "Tag Graph",
					WidgetName: "tag-graph",
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(this.widgetObject)
				},
				maxWidth: "100vw",
				maxHeight: "100vh"
			});
			this.tagGraphSingleModalSubscription = tagGraphSingleModal.afterClosed().subscribe((result) => {
				this.tagGraphSingleModalSubscription.unsubscribe();
			});
		}
	}
}
