<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Retrieving Test Observation Data...'" ></lib-loading-spinner>
<div *ngIf="!isLoading" class="card card-tasks" id="card-whole" >
	<div class="card-header user-settings">
		<h5 class="title d-inline" style="font-size: x-large">Test Observation Delay</h5>
		<div style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" tooltip="Close" placement="bottom" (click)="closeDialog()">
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div *ngIf="!editingForm" class="card-body user-settings" [style.width.px]="signalRContainerWidth"  style="overflow-y: auto; margin-bottom:15px !important; margin-left:10px !important; ">
		<div style="font-size: x-small">
			Start Date: <strong>{{ statisticalRow.FromDate }}</strong>
		</div>
		<div style="font-size: x-small">
			End Date: <strong>{{ statisticalRow.CurrentDate }}</strong> 
		</div>
		<br />
		<div style="font-size: x-small">
			Test Tag: <strong>{{ statisticalRow.TestTag }}</strong> 
		</div>
		<br />
		<div *ngIf="recordCount == 0">No test data available for time period.</div>
		<div *ngIf="recordCount != 0">
			<div style="font-size: x-small">
				Total Observations: <strong>{{ statisticalRow.TotalCount }}</strong>
			</div>
			<div style="font-size: x-small">
				Missing: <strong>{{ statisticalRow.MissingPercentage }}%</strong> ({{ statisticalRow.MissingCount }})
			</div>
			<div style="font-size: x-small">
				Duplicate: <strong>{{ statisticalRow.DuplicatePercentage }}%</strong> ({{ statisticalRow.DuplicateCount }})
			</div>
			<div style="font-size: x-small">
				Total Outliers: <strong>{{ statisticalRow.OutlierPercentage }}%</strong> ({{ statisticalRow.OutlierCount }})
			</div>
			<br />
			<span style="text-decoration: underline;font-size: x-small">Delays</span>
			<div style="font-size: x-small">
				Max: <strong>{{ statisticalRow.MaxDelay }}</strong> seconds<br />
				Min: <strong>{{ statisticalRow.MinDelay }}</strong> seconds<br />
				Avg: <strong>{{ statisticalRow.AverageDelay }}</strong> seconds
			</div>
			<br />
			<span style="text-decoration: underline;font-size: x-small">Statistics</span>
			<div style="font-size: x-small">
				Less than 0.1 seconds:   <strong>{{ statisticalRow.LessThanTenthOfASecondPercentage }}%</strong> ({{statisticalRow.LessThanTenthOfASecond }})<br />
				Between 0.1  and 0.25 seconds: <strong>{{ statisticalRow.LessThanQuarterOfASecondPercentage }}%</strong> ({{statisticalRow.LessThanQuarterOfASecond }})<br />
				Between 0.25 and 0.5  seconds: <strong>{{ statisticalRow.LessThanHalfASecondPercentage }}%</strong> ({{statisticalRow.LessThanHalfASecond }})<br />
				Between 0.5  and 1.0  seconds: <strong>{{ statisticalRow.LessThanOneSecondPercentage }}%</strong> ({{statisticalRow.LessThanOneSecond }})<br />
				Between 1.0  and 2.0  seconds: <strong>{{ statisticalRow.LessThanTwoSecondsPercentage }}%</strong> ({{statisticalRow.LessThanTwoSeconds }})<br />
				Between 2.0  and 5.0  seconds: <strong>{{ statisticalRow.LessThanFiveSecondsPercentage }}%</strong> ({{statisticalRow.LessThanFiveSeconds }})<br />
				Between 5.0  and 10   seconds: <strong>{{ statisticalRow.LessThanTenSecondsPercentage }}%</strong> ({{statisticalRow.LessThanTenSeconds }})<br />
				Greater than 10 seconds: <strong>{{ statisticalRow.GreaterThanTenSecondsPercentage }}%</strong> ({{statisticalRow.GreaterThanTenSeconds }})<br />
			</div>
		</div>
		<br />
		<button class="btn btn-success" type="button" title="Restart Test" (click)="startTest()">Restart Observation Test</button>
		<br />
		<button class="btn" type="button" title="Edit Parameters" (click)="edit()">Edit Test Parameters</button>
	</div>
	<div *ngIf="editingForm" class="card-body user-settings" [style.width.px]="signalRContainerWidth"  style="overflow-y: auto; margin-bottom:15px !important; margin-left:10px !important; ">
		<lib-dynamic-form [questions]="editQuestions" [options]="editFormOptions" (submittedValues)="submitChangesAndExecuteTest($event)"></lib-dynamic-form>
	</div>
</div>