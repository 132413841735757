<div *ngIf="!isLoading && displayNoUpdateIntervalMessage === true && displayNoTimeScopeMessage === false"
  class="card card-tasks center" id="card-whole">
  <lib-centered-message centeredMessage="Please select an update interval for the dashboard to see data.">
  </lib-centered-message>
</div>
<div *ngIf="!isLoading && displayNoUpdateIntervalMessage === false && displayNoTimeScopeMessage === true"
  class="card card-tasks center" id="card-whole">
  <lib-centered-message centeredMessage="Please select a time scope for the dashboard to see data.">
  </lib-centered-message>
</div>
<div *ngIf="!isLoading && displayNoUpdateIntervalMessage === true && displayNoTimeScopeMessage === true"
  class="card card-tasks center" id="card-whole">
  <lib-centered-message
    centeredMessage="Please select a time scope and an update interval for the dashboard to see data.">
  </lib-centered-message>
</div>
<div *ngIf="!widgetObject?.SiteList">
	<div class="card-header tactical-widget-card-header-short drag-handler">
		<span *ngIf="global.isMobile == false" style="display: flex; justify-content: center; align-items: center;">
			{{ widgetObject.WidgetName }}
		</span>
	</div>

  <div class="flex-container" style="position: relative;">
	<lib-tactical-navigation [options]="optionsSettingsOnly" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-tactical-navigation>
	<div style="padding-top: 200px;" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">
		<lib-centered-message centeredMessage="Please select a Site from the widget settings."> </lib-centered-message>
	</div>
  </div>
</div>

<div
*ngIf="!isLoading && displayNoUpdateIntervalMessage === false && displayNoTimeScopeMessage === false && widgetObject?.SiteList"
  class="card card-tasks" id="card-whole"
  >

<div
  *ngIf="!isLoading && displayNoUpdateIntervalMessage === false && displayNoTimeScopeMessage === false && widgetObject?.SiteList"
  class="card-header tactical-widget-card-header drag-handler">

  <div style="width:100%">
	<span *ngIf="global.isMobile == false" style="display: flex; justify-content: center; align-items: center;">
		{{ widgetObject.WidgetName }} {{ tab.textLabel }}

		<span class="material-symbols-outlined" (click)="initializeTour()" style="cursor: pointer;" title="Start Tour">
			info
		</span>
	</span>
  </div>

  <mat-card class="card-top">
	<div class="grid-container-top" [tourAnchor]="widgetObject.WidgetId + '-1'">

		<div class="grid-title-1" style="cursor: pointer;" (click)="editWidgetSettings()" title="click to edit widget settings">
			<div >
				<span [ngClass]="headerGridTitle">{{ (dataService.returnSiteListAsArrayOfNames(widgetObject.SiteList).length > 6)?
					(dataService.returnSiteListAsArrayOfNames(widgetObject.SiteList) | slice:0:6)+'...':
					(dataService.returnSiteListAsArrayOfNames(widgetObject.SiteList)) }}
				</span>
			</div>
			<div >
				<p class="grid-subtitle">site</p>
			</div>

		</div>

		<div *ngIf="!widgetObject.WidgetAssetName && widgetObject.WidgetAssetTypeName"  class="grid-title-2" style="cursor: pointer;" (click)="editWidgetSettings()" title="click to edit widget settings">
			<div >
				<span [ngClass]="headerGridTitle">{{widgetObject.WidgetAssetTypeName}}</span>
			</div>
			<div >
				<p class="grid-subtitle">asset type</p>
			</div>

		</div>

		<div *ngIf="widgetObject.WidgetAssetName"  class="grid-title-2" style="cursor: pointer;" (click)="editWidgetSettings()" title="click to edit widget settings">
			<div >
				<span [ngClass]="headerGridTitle">{{widgetObject.WidgetAssetName}}</span>
			</div>
			<div >
				<p class="grid-subtitle">asset</p>
			</div>

		</div>


		<div class="grid-title-3" style="cursor: pointer;" (click)="refreshWidget()" title="click to refresh">
			<div >
				<span [ngClass]="headerGridTitle">{{displayTimeAgoMessage}}</span>
			</div>
			<div>
				<p class="grid-subtitle">last update</p>
			</div>

		</div>

		<div class="grid-title-4" style="cursor: pointer;" (click)="changeWidgetInterval()" title="click to change update interval">
			<div >
				<span [ngClass]="headerGridTitle">{{ updateInterval.Description }}</span>
			</div>
			<div >
				<p class="grid-subtitle">update interval</p>
			</div>

		</div>

		<div class="grid-title-5" style="cursor: pointer;" (click)="changeWidgetTimescope()" title="click to change timescope">
			<div>
				<span [ngClass]="headerGridTitle">{{ timeScopeObject.queryStartDate | date :'shortDate'  }} - {{ timeScopeObject.queryEndDate | date :'shortDate'  }}</span>
			</div>
			<div>
				<p class="grid-subtitle">timescope</p>
			</div>

		</div>
	</div>

  </mat-card>
</div>

  <div (resized)="onResized($event)" style="display: inline-block" style="height: 100%; width: 100%; overflow:auto;">

	<div class="flex-container" style="position: relative; height: 100%;">
		<div [tourAnchor]="widgetObject.WidgetId + '-2'">
			<lib-tactical-navigation [navigationOpened]="navigationOpened" [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-tactical-navigation>
		</div>
		<div
		[ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">

		<div style="height: 100%;">

			<lib-loading-spinner *ngIf="tab.isLoading" [loadingMessage]="'Loading ' + tab.textLabel" [vehicle]="'truck'">
			</lib-loading-spinner>

		<div *ngIf="tab.textLabel != 'Summary'  &&  tab.chartData?.length > 0 " style="height: 50px !important; display: block">
			<lib-chart-type-slider *ngIf="widgetTabConfigurationsFinishedMapping === true"
			(newGraphTypeEvent)="changeGraphType($event)" [graphType]="tab.graphType" [tab] = "tab"
			(toggleLegendChangeEvent)="toggleLegendChange($event)" [legendVisible]="tab.legendVisible">
			</lib-chart-type-slider>
		</div>

		<div *ngIf="tab.textLabel === 'Summary' && tab.rendered" style="height: 100%;">
				<div *ngIf="tab.isLoading === false && summaryArray?.length === 0 && summaryArray1?.length === 0" class="card card-tasks center"
				  id="card-whole">
				  <lib-centered-message centeredMessage="No data for currently selected configuration.">
				  </lib-centered-message>
				</div>
				<div
					*ngIf="summaryArray.length > 0"
				  >

				  <div class="tactical-equipment-cycle-count-grid-container" [tourAnchor]="widgetObject.WidgetId + '-3'">

					<mat-card class="tactical-equipment-cycle-count-card-topleft">
						<div  style="cursor: pointer;" (click)="navigateToTab(2, 'Asset Cycle Count Historical')" title="Click to Navigate to Asset Cycle Count Historical">

							<div class="grid-card-container-center">
								<div [ngClass]="cardTileCenterTitleClass">
									<span>{{summaryArray[0]}}</span>
								</div>
								<div class="grid-card-subtitle-center">
									<p> highest cycle count </p>
								</div>
								<div class="grid-card-footer-center">
									<p [style.fontSize] = "fontSize1">{{summaryArray[1]}}</p>
								</div>
							</div>

						</div>
					</mat-card>


					<mat-card class="tactical-equipment-cycle-count-card-topright">

						<div  style="cursor: pointer;" (click)="navigateToTab(5, 'Asset Run Time Historical')" title="Click to Navigate to Asset Run Time Historical">

							<div class="grid-card-container-center">
								<div  [ngClass]="cardTileCenterTitleClass">
									<span>{{summaryArray1[0]}}</span>
								</div>
								<div class="grid-card-subtitle-center">
									<p> highest runtime minutes </p>
								</div>
								<div class="grid-card-footer-center">
									<p [style.fontSize] = "fontSize1">{{summaryArray1[1]}}</p>
								</div>
							</div>

						</div>
					</mat-card>


					<mat-card class="tactical-equipment-cycle-count-card-bottomleft">

						<div  style="cursor: pointer;" (click)="navigateToTab(1, 'Asset Cycle Count Today')" title="Click to Navigate to Asset Cycle Count Today">

							<div class="grid-card-container-center">
								<div [ngClass]="cardTileCenterTitleClass">
									<span>{{summaryArray[2]}}</span>
								</div>
								<div class="grid-card-subtitle-center">
									<p> highest cycle count </p>
								</div>
								<div class="grid-card-footer-center">
									<p [style.fontSize] = "fontSize1">{{summaryArray[3]}}</p>
								</div>
							</div>

						</div>
					</mat-card>


					<mat-card class="tactical-equipment-cycle-count-card-bottomright">

						<div  style="cursor: pointer;" (click)="navigateToTab(4, 'Asset Run Time Minutes Today')" title="Click to Navigate to Asset Run Time Minutes Today">

							<div class="grid-card-container-center">
								<div [ngClass]="cardTileCenterTitleClass">
									<span>{{summaryArray1[2]}}</span>
								</div>
								<div class="grid-card-subtitle-center">
									<p> highest runtime minutes </p>
								</div>
								<div class="grid-card-footer-center">
									<p [style.fontSize] = "fontSize1">{{summaryArray1[3]}}</p>
								</div>
							</div>

						</div>
					</mat-card>


				</div>
			</div>
		</div>

        <div *ngIf="tab.textLabel === 'Asset Cycle Count Today'" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div
            *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
            class="card card-tasks center" id="card-whole">
            <lib-centered-message centeredMessage="No data for currently selected configuration.">
            </lib-centered-message>
          </div>
          <kendo-chart *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0" [style.height.%]="100"
            [transitions]="false">
            <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>
            <kendo-chart-value-axis>
              <kendo-chart-value-axis-item [color]="axisItemColor">
              </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-series>
              <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                field="CycleCount" categoryField="GateNameFull" [type]="tab.graphType?.name" [stack]="true">
                <kendo-chart-series-item-tooltip>
                  <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                    <div>
                      <span><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span><br />
                      <span><strong> Runtime Minutes: {{ dataItem.RunTimeMinutes }} minutes</strong></span><br />
                      <span><strong> Cycle Count: {{ dataItem.CycleCount }}</strong></span><br />
                    </div>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }"> </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
            </kendo-chart-legend>
          </kendo-chart>
        </div>
        <div *ngIf="tab.textLabel === 'Asset Run Time Minutes Today'" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div
            *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
            class="card card-tasks center" id="card-whole">
            <lib-centered-message centeredMessage="No data for currently selected configuration.">
            </lib-centered-message>
          </div>
          <kendo-chart *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0" [style.height.%]="100"
            [transitions]="false">
            <kendo-chart-value-axis>
              <kendo-chart-value-axis-item [color]="axisItemColor">
              </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>
            <kendo-chart-series>
              <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                field="RunTimeMinutes" categoryField="GateNameFull" [type]="tab.graphType?.name" [stack]="true">
                <kendo-chart-series-item-tooltip>
                  <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                    <div>
                      <span><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span><br />
                      <span><strong> Runtime Minutes: {{ dataItem.RunTimeMinutes }} minutes</strong></span><br />
                      <span><strong> Cycle Count: {{ dataItem.CycleCount }}</strong></span><br />
                    </div>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }"> </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
            </kendo-chart-legend>
          </kendo-chart>
        </div>
        <div *ngIf="tab.textLabel === 'Asset Cycle Count Historical'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div style="height: 100%">
            <div
              *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
              class="card card-tasks center" id="card-whole">
              <lib-centered-message centeredMessage="No data for currently selected configuration.">
              </lib-centered-message>
            </div>
            <kendo-chart #chart (legendItemClick)="toggleAllSeries($event, 'assetCycleCountHistorical')"
              (seriesClick)="toggleSeries($event, 'assetCycleCountHistorical')" [style.height.%]="100"
              *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Cycle Count For Day' }" [color]="axisItemColor">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

              <kendo-chart-series>
                <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                  field="CycleCount" categoryField="DateOfObservationDateObject" [type]="tab.graphType?.name" #assetCycleCountHistorical>
                  <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                      <div>
                        <span><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span><br />
                        <span><strong> Runtime Minutes: {{ dataItem.RunTimeMinutes }} minutes</strong></span><br />
                        <span><strong> Cycle Count: {{ dataItem.CycleCount }}</strong></span><br />
                      </div>
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                  [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
              </kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>
        <div *ngIf="tab.textLabel === 'Asset Run Time Historical'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div style="height: 100%">
            <div
              *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
              class="card card-tasks center" id="card-whole">
              <lib-centered-message centeredMessage="No data for currently selected configuration.">
              </lib-centered-message>
            </div>
            <kendo-chart #chart (legendItemClick)="toggleAllSeries($event, 'assetRunTimeHistoical')"
              (seriesClick)="toggleSeries($event, 'assetRunTimeHistoical')" [style.height.%]="100"
              *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Run Time Minutes For Day' }" [color]="axisItemColor">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

              <kendo-chart-series>
                <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                  field="RunTimeMinutes" categoryField="DateOfObservationDateObject" [type]="tab.graphType?.name" #assetRunTimeHistoical>
                  <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                      <div>
                        <span><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span><br />
                        <span><strong> Runtime Minutes: {{ dataItem.RunTimeMinutes }} minutes</strong></span><br />
                        <span><strong> Cycle Count: {{ dataItem.CycleCount }}</strong></span><br />
                      </div>
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                  [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
              </kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>
        <div *ngIf="tab.textLabel === 'Asset Type Cycle Count Historical'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div style="height: 100%">
            <div
              *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
              class="card card-tasks center" id="card-whole">
              <lib-centered-message centeredMessage="No data for currently selected configuration.">
              </lib-centered-message>
            </div>
            <kendo-chart #chart (legendItemClick)="toggleAllSeries($event, 'assetCycleCountHistorical')" [style.height.%]="100"
              *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Cycle Count For Day' }" [color]="axisItemColor">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

              <kendo-chart-series>
                <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                  field="CycleCount" categoryField="DateOfObservationDateObject" [type]="tab.graphType?.name" #seriesItems>
                  <kendo-chart-series-item-tooltip>
                                        <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                                            <div>
                                                <span
                                                    ><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span
                                                ><br />
                                                <span
                                                    ><strong> Runtime Minutes: {{ dataItem.RunTimeMinutes }} minutes</strong></span
                                                ><br />
                                                <span
                                                    ><strong> Cycle Count: {{ dataItem.CycleCount }}</strong></span
                                                ><br />
                                            </div>
                                        </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                  [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
              </kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>
        <div *ngIf="tab.textLabel === 'Asset Type Run Time Historical'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div style="height: 100%">
            <div
              *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
              class="card card-tasks center" id="card-whole">
              <lib-centered-message centeredMessage="No data for currently selected configuration.">
              </lib-centered-message>
            </div>
            <kendo-chart #chart (legendItemClick)="toggleAllSeries($event, 'assetRunTimeHistoical')" [style.height.%]="100"
              *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Run Time Minutes For Day' }" [color]="axisItemColor">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

              <kendo-chart-series>
                <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                  field="RunTimeMinutes" categoryField="DateOfObservationDateObject"[type]="tab.graphType?.name" #seriesItems>
                  <kendo-chart-series-item-tooltip>
                                        <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                                            <div>
                                                <span
                                                    ><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span
                                                ><br />
                                                <span
                                                    ><strong> Runtime Minutes: {{ dataItem.RunTimeMinutes }} minutes</strong></span
                                                ><br />
                                                <span
                                                    ><strong> Cycle Count: {{ dataItem.CycleCount }}</strong></span
                                                ><br />
                                            </div>
                                        </ng-template>
					</kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                  [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
              </kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>


  </div>
</div>
