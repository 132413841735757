

<div class="card card-tasks">
	<div
		class="card-header"
		style="cursor: default; padding-top: 0px; min-height: 0px"
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">Select Widget to Scroll To</h6>
		</div>

		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body" style="overflow: hidden !important">
		<div style="width: 100%; height: 100%">
			<br/>
			<mat-form-field appearance="fill">
				<mat-label>Widget to Scroll To</mat-label>
				<mat-select [formControl]="widgetControl" required>
					<mat-option>--</mat-option>
					<mat-option *ngFor="let widget of widgets.widgets" [value]="widget.WidgetId">
						{{ widget.WidgetName }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="widgetControl.hasError('required')">Please choose a widget</mat-error>
			</mat-form-field>

			<br/>
			<button class="btn btn-fill btn-primary" type="button" [disabled]="widgetControl.hasError('required')" (click)="submitAndClose()" style="padding: 3px 6px; margin: 0px 10px">
                <i style="font-size: 10px">Submit and Close</i>
            </button>


		</div>

	</div>
</div>
