import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasComponent } from './canvas.component';
import { GridsterModule } from 'angular-gridster2';
import { PcaBlowerModule } from '../pca-blower/pca-blower.module';
import { PcaCondPumpModule } from '../pca-cond-pump/pca-cond-pump.module';
import { PcaCondensorfansModule } from '../pca-condensorfans/pca-condensorfans.module';
import { PcaDischargeModeModule } from '../pca-discharge-mode/pca-discharge-mode.module';
import { PcaEstopModule } from '../pca-estop/pca-estop.module';
import { PcaOperationModeModule } from '../pca-operation-mode/pca-operation-mode.module';
import { PcaPressuresModule } from '../pca-pressures/pca-pressures.module';
import { PcaOvernightModeModule } from '../pca-overnight-mode/pca-overnight-mode.module';
import { PcaCompPressureModule } from '../pca-comp-pressure/pca-comp-pressure.module';
import { PcaHeatModule } from '../pca-heat/pca-heat.module';
import { WidgetSettingsModule } from '../widget-settings/widget-settings.module';
import { GseActiveAlarmsModule } from 'projects/shared-lib/src/lib/components/gse-active-alarms/gse-active-alarms.module';
import { CenteredMessageModule } from "../centered-message/centered-message.module";
import { GaugesModule } from '@progress/kendo-angular-gauges';


@NgModule({
	declarations: [CanvasComponent],
	imports: [
		CommonModule,
		GridsterModule,
		PcaHeatModule,
		PcaBlowerModule,
		PcaCondPumpModule,
		PcaCondensorfansModule,
		PcaDischargeModeModule,
		PcaEstopModule,
		PcaOperationModeModule,
		PcaPressuresModule,
		PcaOvernightModeModule,
		PcaCompPressureModule,
		WidgetSettingsModule,
		GseActiveAlarmsModule,
		CenteredMessageModule,
		GaugesModule,
	],
	exports: [CanvasComponent]

})
export class CanvasModule {}
