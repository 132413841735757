import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasDesignerComponent } from './canvas-designer.component';
import { CanvasModule } from '../canvas/canvas.module';
import { CanvasGsePopupSettingsModule } from '../canvas-gse-popup-settings/canvas-gse-popup-settings.module';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    CanvasDesignerComponent
  ],
  imports: [
    CommonModule,
	CanvasModule,
	MatMenuModule,
	  MatButtonModule,
	  CanvasGsePopupSettingsModule

  ], exports: [CanvasDesignerComponent]
})
export class CanvasDesignerModule { }
