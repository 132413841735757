import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerIssuesListComponent } from './customer-issues-list.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { BadgeModule } from '@progress/kendo-angular-indicators';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	ExcelModule,
	GridModule,
	PDFModule,
} from '@progress/kendo-angular-grid';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [CustomerIssuesListComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		MatTooltipModule,
		GridModule,
		MatIconModule,
		MatBadgeModule,
		BadgeModule,
		DynamicFormModule,
		ReactiveFormsModule,
		FormsModule,
		ExcelModule,
		PDFModule,
		RouterModule,
	],
	exports: [CustomerIssuesListComponent],
})
export class CustomerIssuesListModule {}
