import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TacticalWidgetSummaryTilePopupComponent } from './tactical-widget-summary-tile-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@NgModule({
	declarations: [TacticalWidgetSummaryTilePopupComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		DragDropModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		ColorPickerModule,
		MatRadioModule,
		MatAutocompleteModule,
	],
	exports: [TacticalWidgetSummaryTilePopupComponent],
})
export class TacticalWidgetSummaryTilePopupModule { }
