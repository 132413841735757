

<kendo-grid
[data]="gridSettings.gridData"
[loading]="isLoading"

[navigable]="true"
kendoGridFocusable
>
<kendo-grid-column
  field="Ordinal"
  [width]="100"
  title="Lesson"
>
<ng-template

kendoGridCellTemplate
let-dataItem let-rowIndex="rowIndex"
> {{rowIndex + 1}}</ng-template>
</kendo-grid-column>

<kendo-grid-column
  field="Name"
  [width]="300"
  title="Training Lesson"
></kendo-grid-column>

<kendo-grid-column field="Description"> </kendo-grid-column>
<kendo-grid-column title="Link">
	<ng-template

	kendoGridCellTemplate
	let-dataItem
>
	<td>
		<button
			style="padding: 3px 6px; height: 25px"
			class="jbt-btn btn-sm"
			(click)="loadVideo(dataItem)"
			*ngIf="dataItem.viewUrl"
		>
		<i class="tim-icons icon-triangle-right-17"></i>
		</button>
	</td>
</ng-template>

</kendo-grid-column>

