import { Component, Input, AfterViewInit, ViewChild, OnInit, OnDestroy, EventEmitter, Output } from "@angular/core";

import _ from "lodash";
import * as moment from "moment-timezone";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { ZonedDate } from "@progress/kendo-date-math";
import { Global } from "../../_constants/global.variables";
import { DataService } from "../../services/data.service";
import { UtilityService } from "../../services/utility.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DashboardService } from "../../services/dashboard.service";
import { MultiSelectCheckableSettings } from "@progress/kendo-angular-dropdowns/common/models/checkboxes-settings";
import { Group } from "@progress/kendo-angular-scheduler";
import { IGlobal } from '../../_models/global.model';

import '@progress/kendo-date-math/tz/all';
import { GridSettings } from '../../_models/grid-settings.interface';
import { process, State } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IAsset } from "../../_models/asset.model";

@Component({
	selector: 'lib-flight-schedule',
	templateUrl: './flight-schedule.component.html',
	styleUrls: ['./flight-schedule.component.scss'],
})
export class FlightScheduleComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	public global: IGlobal = Global;

	public checkboxes: MultiSelectCheckableSettings = { enabled: true };
	@Input() widgetObject: any;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	@ViewChild('scheduler') flightSchedule: any;
	@ViewChild('multiselect') kendoMultiselect: any;
	@Output() callSave: any = new EventEmitter<any>(); //grid
	public componentName: string = 'flight-schedule: ';
	fullDataCacheSubscription: Subscription;
	signalRUpdateSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	isDataLoading: boolean = true;
	isSchedulerLoading: boolean;
	hasData: boolean = false;
	isDarkTheme: boolean;
	timeZoneType: any;
	showAirlineCodeFilter: boolean = false;
	lastFlightDataUpdate: Date;
	flightDateUpdatedTimer: string = 'now';
	flightDataUpdatedInterval: NodeJS.Timeout;
	scheduler24HrRefreshInterval: NodeJS.Timeout;
	initializationDate = new Date();
	loadedFlightDataDates = {};
	gateSystems = [];
	gateNames = [];
	gateSystemIds = [];
	perfectTurnEvents = [];
	flightEventIds = [];
	flightEvents = [];
	filteredFlightEvents = [];
	kendoResources = [];
	kendoGroup: Group;
	carriersList = [];
	selectedCarriers = [];
	kendoTimeZone = '';
	kendoTimeMarkerSettings = {
		enabled: true,
		localTimezone: false,
	};

	toolTipInfo: string;
	tagData = [];

	// grid
	gridData = [];
	gridShadowData = [];
	emptyRecordsMessage: String = 'No Data';
	rowHeight = 26;

	gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},

		columnsConfig: [
			{
				field: 'ArrivalFlight',
				title: 'Arrival Flight',
				filterable: true,
				_width: 100,
			},
			{
				field: 'ArrivalGate',
				title: 'Arrival Gate',
				filterable: true,
				_width: 100,
			},
			{
				field: 'ArrivalTailNumber',
				title: 'Arrival TailNumber',
				filterable: true,
				_width: 100,
			},
			{
				field: 'ArrivalAircraft',
				title: 'Arrival Aircraft',
				filterable: true,
				_width: 100,
			},
			{
				field: 'ArrivalScheduledTime',
				title: 'Arrival Scheduled Time',
				filterable: true,
				filter: 'date',
				_width: 150,
			},
			{
				field: 'ArrivalTime',
				title: 'Arrival  Time',
				filterable: true,
				filter: 'date',
				_width: 150,
			},
			{
				field: 'ArrivalFromSite',
				title: 'Arrival From Site',
				filterable: true,
				_width: 100,
			},
			{
				field: 'DepartureFlight',
				title: 'Departure Flight',
				filterable: true,
				_width: 100,
			},
			{
				field: 'DepartureGate',
				title: 'Departure Gate',
				filterable: true,
				_width: 100,
			},
			{
				field: 'DepartureTailNumber',
				title: 'Departure TailNumber',
				filterable: true,
				_width: 100,
			},
			{
				field: 'DepartureAircraft',
				title: 'Departure Aircraft',
				filterable: true,
				_width: 100,
			},
			{
				field: 'DepartureScheduledTime',
				title: 'Departure Scheduled Time',
				filterable: true,
				filter: 'date',
				_width: 150,
			},
			{
				field: 'DepartureTime',
				title: 'Departure Time',
				filterable: true,
				filter: 'date',
				_width: 150,
			},
			{
				field: 'DepartureToSite',
				title: 'Departure To Site',
				filterable: true,
				_width: 100,
			},
		],
	};

	configuration = {
		parentSelectedTabIndex: 0,
		assetId: undefined,
		siteUTCTimeOffset: undefined,
		assetTagIds: undefined,
		currentAlarms: undefined,
		alarmsOnly: true,
		gridInView: false,
		exportTitle: '',
	};

	constructor(
		private dataService: DataService,
		private utilityService: UtilityService,
		private signalRCore: SignalRCoreService,
		private dashboardService: DashboardService
	) {}

	ngOnInit() {}

	ngAfterViewInit() {
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					this.isDarkTheme = Global.Theme === 'dark' ? true : false;
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.timeZoneType =
							this.dashboardService.determineTimeZoneType(
								foundWidgetWithSameWidgetId
							);
						this.toggleDisplayTime();
						this.CreateGrid();
					}
				});
		}

		this.scheduler24HrRefreshInterval = setInterval(() => {
			// Setup Interval to check if current day has passed then refresh flight data
			if (
				moment().hour() == 0 ||
				moment().diff(moment(this.initializationDate), 'h') > 22
			) {
				// Start of the day
				console.log(
					`${this.componentName}: Re-initilizing widget for the start of the day`
				);
				this.initializationDate = new Date();
				this.initialize();
			}
		}, 10000 * 60); // Check for next day every hour

		// configure legend info
		let arr = [
			"Flight Schedule Legend Information",
			"bar end caps:",
			"left green end cap = flight has an arrival scheduled",
			"right green end cap = flight has a departure scheduled",
			"bar color:",
			"bar color lightseagreen = active turn event",
			"bar color light grey = no turn event",
		  ];
		  this.toolTipInfo = arr.join("\r\n");

	}

	ngOnDestroy() {
		Global.User.DebugMode &&
			console.log(`${this.componentName}: ngOnDestroy() invoked...`);

		this.signalRCore.leaveAdditionalGroup(
			`OAGFlightLeg_${this.widgetObject.WidgetSiteName}`
		);
		this.gateSystemIds.forEach((id) =>
			this.signalRCore.leaveAdditionalGroup(`PerfectTurn_${id}`)
		);

		if (this.signalRUpdateSubscription) {
			this.signalRUpdateSubscription.unsubscribe();
		}
		if (this.dashboardTimeZoneChangedSubscription) {
			this.dashboardTimeZoneChangedSubscription.unsubscribe();
		}
		if (this.flightDataUpdatedInterval) {
			clearInterval(this.flightDataUpdatedInterval);
		}
		if (this.scheduler24HrRefreshInterval) {
			clearInterval(this.scheduler24HrRefreshInterval);
		}
	}

	schedulerDateChanged(ev) {
		const dateText = moment(ev.dateRange.start)
			.startOf('day')
			.format('MM/DD/YYYY');
		const dateLoaded = this.loadedFlightDataDates[dateText];
		if (!dateLoaded) {
			console.log('Scheduler Date Changed', ev);
			this.getFlightEventData(ev.selectedDate);
			this.loadedFlightDataDates[ev.dateRange.shortText] = true;
		}
	}

	valueChange(value: any): void {
		this.filteredFlightEvents = this.flightEvents.filter((fe) =>
			value.some(
				(carrier) =>
					carrier.AirlineCode == fe.dataItem.arrival?.AirlineCode ||
					carrier == fe.dataItem.departure?.AirlineCode
			)
		);
	}

	toggleFilter() {
		if (this.showAirlineCodeFilter) {
			// save values if filter is closing
			this.widgetObject.DataFilterJSON =
				this.selectedCarriers.length > 0
					? JSON.stringify(
							this.selectedCarriers.map(
								(carrier) => carrier.AirlineCode
							)
					  )
					: null;
			this.dashboardService.addOrUpdateDashboardWidgetItem(
				this.widgetObject
			);
		}
		this.showAirlineCodeFilter = !this.showAirlineCodeFilter;
	}

	toggleDisplayTime() {
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);

		this.kendoTimeZone =
			this.timeZoneType !== 'UTC Time'
				? this.dataService.cache.sitesObject[
						this.widgetObject.WidgetSiteId
				  ].TimeZone
				: this.timeZoneType === 'UTC Time'
				? 'Etc/UTC'
				: '';

		if (this.timeZoneType === 'User Time') {
			this.flightEvents.forEach((fe) => {
				fe.start = new Date(fe.startDateMS);
				fe.end = new Date(fe.endDateMS);
				fe.status = `Arrival Flight: ${
					fe.dataItem.arrival
						? fe.dataItem.arrival.AirlineCode +
						  fe.dataItem.arrival.FlightNumber
						: 'N/A'
				}
					Arrival Gate: ${
						fe.dataItem.arrival &&
						fe.dataItem.arrival.ArrivalGateName
							? fe.dataItem.arrival.ArrivalGateName
							: 'N/A'
					}
                    Arrival TailNumber: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.TailNumber
							: 'N/A'
					}
                    Arrival Aircraft: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.AircraftType
							: 'N/A'
					}
					Arrival Scheduled Time: ${
						fe.dataItem.arrival
							? moment(
									fe.dataItem.arrival
										.ArrivalToGateScheduledUTCDateMS
							  ).format('M/D hh:mm a')
							: 'N/A'
					}
                    Arrival Time: ${
						fe.dataItem.arrival
							? moment(fe.start).format('M/D hh:mm a')
							: 'N/A'
					}
                    Arriving From Site: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.DepartureSiteName
							: 'N/A'
					}
                    Departure Flight: ${
						fe.dataItem.departure
							? fe.dataItem.departure.AirlineCode +
							  fe.dataItem.departure.FlightNumber
							: 'N/A'
					}
					Departure Gate: ${
						fe.dataItem.departure &&
						fe.dataItem.departure.DepartureGateName
							? fe.dataItem.departure.DepartureGateName
							: 'N/A'
					}
                    Departure TailNumber: ${
						fe.dataItem.departure
							? fe.dataItem.departure.TailNumber
							: 'N/A'
					}
                    Departure Aircraft: ${
						fe.dataItem.departure
							? fe.dataItem.departure.AircraftType
							: 'N/A'
					}
					Departure Scheduled Time: ${
						fe.dataItem.departure
							? moment(
									fe.dataItem.departure
										.DepartureFromGateScheduledUTCDate
							  ).format('M/D hh:mm a')
							: 'N/A'
					}
                    Departure Time: ${
						fe.dataItem.departure
							? moment(fe.end).format('M/D hh:mm a')
							: 'N/A'
					}
                    Departing To Site: ${
						fe.dataItem.departure
							? fe.dataItem.departure.ArrivalSiteName
							: 'N/A'
					}`;
			});
		} else if (this.timeZoneType === 'Site Time') {
			this.flightEvents.forEach((fe) => {
				fe.start = ZonedDate.fromLocalDate(
					new Date(fe.startDateMS),
					this.kendoTimeZone
				);
				fe.end = ZonedDate.fromLocalDate(
					new Date(fe.endDateMS),
					this.kendoTimeZone
				);
				fe.status = `Arrival Flight: ${
					fe.dataItem.arrival
						? fe.dataItem.arrival.AirlineCode +
						  fe.dataItem.arrival.FlightNumber
						: 'N/A'
				}
                    Arrival TailNumber: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.TailNumber
							: 'N/A'
					}
                    Arrival Aircraft: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.AircraftType
							: 'N/A'
					}
					Arrival Scheduled Time: ${
						fe.dataItem.arrival
							? moment
									.tz(
										fe.dataItem.arrival
											.ArrivalToGateScheduledUTCDateMS,
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Arrival Time: ${
						fe.dataItem.arrival
							? moment
									.tz(fe.startDateMS, this.kendoTimeZone)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Arriving From Site: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.DepartureSiteName
							: 'N/A'
					}
                    Departure Flight: ${
						fe.dataItem.departure
							? fe.dataItem.departure.AirlineCode +
							  fe.dataItem.departure.FlightNumber
							: 'N/A'
					}
                    Departure TailNumber: ${
						fe.dataItem.departure
							? fe.dataItem.departure.TailNumber
							: 'N/A'
					}
                    Departure Aircraft: ${
						fe.dataItem.departure
							? fe.dataItem.departure.AircraftType
							: 'N/A'
					}
					Departure Scheduled Time: ${
						fe.dataItem.departure
							? moment
									.tz(
										fe.dataItem.departure
											.DepartureFromGateScheduledUTCDate,
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Departure Time: ${
						fe.dataItem.departure
							? moment
									.tz(fe.endDateMS, this.kendoTimeZone)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Departing To Site: ${
						fe.dataItem.departure
							? fe.dataItem.departure.ArrivalSiteName
							: 'N/A'
					}`;
			});
		} else if (this.timeZoneType === 'UTC Time') {
			this.flightEvents.forEach((fe) => {
				fe.start = ZonedDate.fromLocalDate(
					new Date(fe.startDateMS),
					this.kendoTimeZone
				);
				fe.end = ZonedDate.fromLocalDate(
					new Date(fe.endDateMS),
					this.kendoTimeZone
				);
				fe.status = `Arrival Flight: ${
					fe.dataItem.arrival
						? fe.dataItem.arrival.AirlineCode +
						  fe.dataItem.arrival.FlightNumber
						: 'N/A'
				}
				Arrival Gate: ${
					fe.dataItem.arrival && fe.dataItem.arrival.ArrivalGateName
						? fe.dataItem.arrival.ArrivalGateName
						: 'N/A'
				}
                    Arrival TailNumber: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.TailNumber
							: 'N/A'
					}
                    Arrival Aircraft: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.AircraftType
							: 'N/A'
					}
					Arrival Scheduled Time: ${
						fe.dataItem.arrival
							? moment
									.tz(
										fe.dataItem.arrival
											.ArrivalToGateScheduledUTCDateMS,
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Arrival Time: ${
						fe.dataItem.arrival
							? moment
									.tz(fe.startDateMS, this.kendoTimeZone)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Arriving From Site: ${
						fe.dataItem.arrival
							? fe.dataItem.arrival.DepartureSiteName
							: 'N/A'
					}
                    Departure Flight: ${
						fe.dataItem.departure
							? fe.dataItem.departure.AirlineCode +
							  fe.dataItem.departure.FlightNumber
							: 'N/A'
					}
					Departure Gate: ${
						fe.dataItem.departure &&
						fe.dataItem.departure.DepartureGateName
							? fe.dataItem.departure.DepartureGateName
							: 'N/A'
					}
                    Departure TailNumber: ${
						fe.dataItem.departure
							? fe.dataItem.departure.TailNumber
							: 'N/A'
					}
                    Departure Aircraft: ${
						fe.dataItem.departure
							? fe.dataItem.departure.AircraftType
							: 'N/A'
					}
					Departure Scheduled Time: ${
						fe.dataItem.departure
							? moment
									.tz(
										fe.dataItem.departure
											.DepartureFromGateScheduledUTCDate,
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Departure Time: ${
						fe.dataItem.departure
							? moment
									.tz(fe.endDateMS, this.kendoTimeZone)
									.format('M/D hh:mm a')
							: 'N/A'
					}
                    Departing To Site: ${
						fe.dataItem.departure
							? fe.dataItem.departure.ArrivalSiteName
							: 'N/A'
					}`;
			});
		}
		this.filteredFlightEvents = this.flightEvents.filter((fe) =>
			this.selectedCarriers.some(
				(carrier) =>
					carrier.AirlineCode == fe.dataItem.arrival?.AirlineCode ||
					carrier == fe.dataItem.departure?.AirlineCode
			)
		);
	}

	initialize() {
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);

		Global.User.DebugMode &&
			console.log(this.componentName + ' initialize() invoked...');

		if (this.widgetObject.WidgetSiteId) {
			this.kendoTimeZone =
				this.timeZoneType !== 'UTC Time'
					? this.dataService.cache.sitesObject[
							this.widgetObject.WidgetSiteId
					  ].TimeZone
					: this.timeZoneType === 'UTC Time'
					? 'Etc/UTC'
					: '';

			this.getFlightEventData(null); //--asynchronous call that will determine if the site selected actually has flight schedule data.  If not, it will just set the 'hasData' = false. --Kirk T. Sherer, October 7, 2022.
		} else {
			this.isDataLoading = false;
			this.hasData = false;
		}
	}

	filterDataForSite(data): any[] {
		switch (this.widgetObject.WidgetSiteId) {
			case 2: // JFK
				return data.filter(
					(row) =>
						row.ArrivalTerminalName == '4' ||
						row.DepartureTerminalName == '4'
				);

			case 9: // PHX
				return data.filter((row) => row.AirlineCode == 'WN');

			default:
				return data;
		}
	}

	getFlightEventData(schedulerDate: Date) {
		var service = this;
		Global.User.DebugMode &&
			console.log(
				service.componentName + 'getFlightEventData() invoked...'
			);
		service.isSchedulerLoading = true;
		service.loadedFlightDataDates[
			moment(schedulerDate ?? new Date())
				.startOf('day')
				.format('MM/DD/YYYY')
		] = true;

		let statement =
			"GetFlightDataBySiteWithinTimescope @StartTime='" +
			moment(schedulerDate ?? new Date())
				.startOf('day')
				.format('YYYY/MM/DD HH:mm:ss') +
			"', @EndTime = '" +
			moment(schedulerDate ?? new Date())
				.add(1, 'day')
				.format('YYYY/MM/DD HH:mm:ss') +
			"', @SiteId=" +
			service.widgetObject.WidgetSiteId;
		service.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					service.componentName + 'API.' + statement + ' = %O',
					data
				);

			if (data.length > 0) {
				service.hasData = true;

				let filteredData = service.filterDataForSite(data);
				let arrivals = _.orderBy(
					filteredData.filter(
						(row) => row.ArrivalOrDeparture == 'Arrival'
					),
					['GateArrivalDateUTCMS'],
					['asc']
				);
				let departures = _.orderBy(
					filteredData.filter(
						(row) => row.ArrivalOrDeparture == 'Departure'
					),
					['GateDepartureDateUTCMS'],
					['asc']
				);
				let pariedDepartureIds = [];

				arrivals.forEach((arrival) => {
					if (!service.flightEventIds.contains(arrival.id)) {
						let departure;
						const subsequentDepartures = departures.filter(
							(departure) =>
								arrival.ArrivalGateName ==
									departure.DepartureGateName &&
								moment(
									departure.GateDepartureDateUTCMS
								).isAfter(moment(arrival.GateArrivalDateUTCMS))
						);
						if (subsequentDepartures.length > 1) {
							departure = subsequentDepartures.find(
								(departure) =>
									arrival.TailNumber == departure.TailNumber
							);
						} else {
							departure = subsequentDepartures[0];
						}
						const existingDeparture = service.flightEvents.find(
							(fe) => _.isEqual(fe.dataItem.departure, departure)
						);
						if (!existingDeparture) {
							let endDateMS = departure
								? departure.GateDepartureDateUTCMS
								: moment(arrival.GateArrivalDateUTC)
										.add(30, 'm')
										.valueOf();
							let schedulerStartDate =
								service.timeZoneType === 'User Time'
									? new Date(arrival.GateArrivalDateUTCMS)
									: ZonedDate.fromLocalDate(
											new Date(
												arrival.GateArrivalDateUTCMS
											),
											service.kendoTimeZone
									  );
							let schedulerEndDate =
								service.timeZoneType === 'User Time'
									? new Date(endDateMS)
									: ZonedDate.fromLocalDate(
											new Date(endDateMS),
											service.kendoTimeZone
									  );
							let flightEvent = {
								id: arrival.Id,
								start: schedulerStartDate,
								end: schedulerEndDate,
								title: `${arrival.AirlineCode}${
									arrival.FlightNumber
								}${
									departure
										? '-' +
										  departure.AirlineCode +
										  departure.FlightNumber
										: ''
								}`,
								description: '',
								dataItem: {
									arrival: arrival,
									departure: departure ? departure : null,
								},
								hasArrival: true,
								hasDeparture: departure ? true : false,
								startDateMS: arrival.GateArrivalDateUTCMS,
								endDateMS: endDateMS,
								certaintyLevel: arrival.CertaintyLevel,
								color: '',
								gateSysId: arrival.ArrivalGateSystemId
									? parseInt(arrival.ArrivalGateSystemId)
									: service.gateSystems.find(
											(system) =>
												system.text ==
												arrival.ArrivalGateName
									  )?.value ?? 1234,
								status: `Arrival Flight: ${
									arrival.AirlineCode + arrival.FlightNumber
								}
									Arrival Gate: ${arrival.ArrivalGateName ? arrival.ArrivalGateName : 'N/A'}
                                    Arrival TailNumber: ${arrival.TailNumber}
                                    Arrival Aircraft: ${arrival.AircraftType}
									Arrival Scheduled Time: ${
										service.timeZoneType === 'User Time'
											? moment(
													arrival.ArrivalToGateScheduledUTCDateMS
											  ).format('M/D hh:mm a')
											: moment
													.tz(
														arrival.ArrivalToGateScheduledUTCDateMS,
														service.kendoTimeZone
													)
													.format('M/D hh:mm a')
									}
                                    Arrival Time: ${
										service.timeZoneType === 'User Time'
											? moment(schedulerStartDate).format(
													'M/D hh:mm a'
											  )
											: moment
													.tz(
														arrival.GateArrivalDateUTCMS,
														service.kendoTimeZone
													)
													.format('M/D hh:mm a')
									}
                                    Arriving From Site: ${
										arrival.DepartureSiteName
									}
                                    Departure Flight: ${
										departure
											? departure.AirlineCode +
											  departure.FlightNumber
											: 'N/A'
									}
									Departure Gate: ${
										departure && departure.DepartureGateName
											? departure.DepartureGateName
											: 'N/A'
									}
                                    Departure TailNumber: ${
										departure ? departure.TailNumber : 'N/A'
									}
                                    Departure Aircraft: ${
										departure
											? departure.AircraftType
											: 'N/A'
									}
									Departure Scheduled Time: ${
										departure
											? service.timeZoneType ===
											  'User Time'
												? moment(
														departure.DepartureFromGateScheduledUTCDate
												  ).format('M/D hh:mm a')
												: moment
														.tz(
															departure.DepartureFromGateScheduledUTCDate,
															service.kendoTimeZone
														)
														.format('M/D hh:mm a')
											: 'N/A'
									}
                                    Departure Time: ${
										departure
											? service.timeZoneType ===
											  'User Time'
												? moment(
														schedulerEndDate
												  ).format('M/D hh:mm a')
												: moment
														.tz(
															endDateMS,
															service.kendoTimeZone
														)
														.format('M/D hh:mm a')
											: 'N/A'
									}
                                    Departing To Site: ${
										departure
											? departure.ArrivalSiteName
											: 'N/A'
									}`,
							};
							service.flightEvents.push(flightEvent);
							service.flightEventIds.push(arrival.Id);

							if (departure) {
								service.flightEventIds.push(departure.Id);
								pariedDepartureIds.push(departure.Id);
							}
						}
					}
				});

				let unpairedDepartues = filteredData.filter(
					(dep) =>
						dep.ArrivalOrDeparture == 'Departure' &&
						!service.flightEventIds.contains(dep.Id)
				);
				unpairedDepartues.forEach((departure) => {
					// Add times to the unpaired departures so they can appear on the flight scheduler
					let startDateMS = moment(departure.GateDepartureDateUTCMS)
						.subtract(30, 'm')
						.valueOf();
					let schedulerStartDate =
						service.timeZoneType === 'User Time'
							? new Date(startDateMS)
							: ZonedDate.fromLocalDate(
									new Date(startDateMS),
									service.kendoTimeZone
							  );
					let schedulerEndDate =
						service.timeZoneType === 'User Time'
							? new Date(departure.GateDepartureDateUTCMS)
							: ZonedDate.fromLocalDate(
									new Date(departure.GateDepartureDateUTCMS),
									service.kendoTimeZone
							  );
					let flightEvent = {
						id: departure.Id,
						start: schedulerStartDate,
						end: schedulerEndDate,
						title: `${
							departure.AirlineCode + departure.FlightNumber
						}`,
						description: '',
						dataItem: { arrival: null, departure: departure },
						hasArrival: false,
						hasDeparture: departure ? true : false,
						startDateMS: startDateMS,
						endDateMS: departure.GateDepartureDateUTCMS,
						certaintyLevel: departure.CertaintyLevel,
						color: '',
						gateSysId: departure.ArrivalGateSystemId
							? parseInt(departure.ArrivalGateSystemId)
							: 1234,
						status: `Arrival Flight: N/A
							Arrival Gate: N/A
                            Arrival TailNumber: N/A
                            Arrival Aircraft: N/A
                            Arrival Time: N/A
                            Arriving From Site: N/A
                            Departure Flight: ${
								departure.AirlineCode + departure.FlightNumber
							}
							Departure Gate: ${
								departure.DepartureGateName
									? departure.DepartureGateName
									: 'N/A'
							}
                            Departure TailNumber: ${departure.TailNumber}
                            Departure Aircraft: ${departure.AircraftType}
							Departure Scheduled Time: ${
								service.timeZoneType === 'User Time'
									? moment(
											departure.DepartureFromGateScheduledUTCDate
									  ).format('M/D hh:mm a')
									: moment
											.tz(
												departure.DepartureFromGateScheduledUTCDate,
												service.kendoTimeZone
											)
											.format('M/D hh:mm a')
							}
                            Departure Time: ${
								service.timeZoneType === 'User Time'
									? moment(schedulerEndDate).format(
											'M/D hh:mm a'
									  )
									: moment
											.tz(
												departure.GateDepartureDateUTCMS,
												service.kendoTimeZone
											)
											.format('M/D hh:mm a')
							}
                            Departing To Site: ${departure.ArrivalSiteName}`,
					};
					service.flightEvents.push(flightEvent);
					service.flightEventIds.push(departure.Id);
				});




				Global.User.DebugMode &&
					console.log(
						service.componentName + 'Flight Data:',
						service.flightEvents
					);
				service.filteredFlightEvents = _.cloneDeep(
					service.flightEvents
				);
				service.lastFlightDataUpdate = new Date();
				service.flightDataUpdatedInterval = setInterval(() => {
					service.flightDateUpdatedTimer = moment(
						service.lastFlightDataUpdate
					).fromNow();
					service.dashboardService.updatedFlightDataTimer(
						service.flightDateUpdatedTimer,
						service.widgetObject.WidgetId
					);
				}, 10000);

				service.loadPerfectTurnIntoDataCache(service.flightEvents,
					 		this);

				// !schedulerDate &&
				// 	service.getActivePerfectTurnEvents(
				// 		service.flightEvents,
				// 		this
				// 	);

				!schedulerDate && service.createCarrierList(filteredData);
				// !schedulerDate && service.toggleDisplayTime();

				Global.User.DebugMode &&
					console.log(
						service.componentName +
							'Attempting to get timezone information. kendoTimeZone= ' +
							service.kendoTimeZone
					);

				this.gateNames = this.dataService.cache.systems
					.filter(
						(system) =>
							system.SiteId === this.widgetObject.WidgetSiteId
					)
					.filter((system) => system.TypeId === 3)
					.map((system) => system.Name);

				this.gateSystems = this.dataService.cache.systems
					.filter(
						(system) =>
							system.SiteId === this.widgetObject.WidgetSiteId
					)
					.filter((system) => system.TypeId === 3)
					.sort((g1, g2) =>
						this.utilityService
							.GetGateNameSortValue(g1.Name)
							.localeCompare(
								this.utilityService.GetGateNameSortValue(
									g2.Name
								),
								'en',
								{ numeric: true }
							)
					)
					.map((gate) => ({
						text: gate.Name,
						value: gate.Id,
						color: 'LightGrey',
					}));

				this.gateSystems.push({
					text: 'No Gate',
					value: 1234,
					color: 'LightGrey',
				});

				this.gateSystemIds = this.dataService.cache.systems
					.filter(
						(system) =>
							system.SiteId === this.widgetObject.WidgetSiteId
					)
					.filter((system) => system.TypeId === 3)
					.map((system) => system.Id);

				this.kendoGroup = {
					resources: ['Gates'],
					orientation: 'vertical',
				};

				this.kendoResources = [
					{
						name: 'Gates',
						data: this.gateSystems,
						field: 'gateSysId',
						valueField: 'value',
						textField: 'text',
						colorField: 'color',
					},
				];
				service.getFlightDataUpdates();
				//service.getPerfectTurnUpdates();
				service.widgetObject.isDisplayDataLive = true;
				service.isDataLoading = false;
				service.isSchedulerLoading = false;

				Global.User.DebugMode &&
					console.log(
						service.componentName + 'hasData = ' + service.hasData
					);

				this.CreateGrid();

			} else {
				service.hasData = false;
				service.isDataLoading = false;
				service.isSchedulerLoading = false;
				Global.User.DebugMode &&
					console.log(
						service.componentName + 'hasData = ' + service.hasData
					);
			}
		});
	}

	createCarrierList(flightData) {
		let uniqueAirlineCodes = Array.from(
			new Set(flightData.map((row) => row.AirlineCode))
		); // Get unique set of Airline Codes
		Global.User.DebugMode &&
			console.log(
				this.componentName + 'uniqueAirlineCodes = %O',
				uniqueAirlineCodes
			);
		uniqueAirlineCodes.forEach((code) => {
			let airlineName = flightData.find(
				(row) => row.AirlineCode == code
			).AirlineName;
			this.carriersList.push({
				AirlineCode: code,
				AirlineName: airlineName,
				DisplayText: `${code} ${airlineName ? '- ' + airlineName : ''}`,
			});
		});
		if (this.widgetObject.DataFilterJSON) {
			let savedCarriers = JSON.parse(this.widgetObject.DataFilterJSON);
			this.selectedCarriers = this.carriersList.filter((carrier) =>
				savedCarriers.includes(carrier.AirlineCode)
			);
		} else {
			this.selectedCarriers = _.cloneDeep(this.carriersList);
		}
	}

	getActivePerfectTurnEvents(flightEvents: any, service: any) {
		Global.User.DebugMode &&
			console.log(
				service.componentName +
					'getActivePerfectTurnEvents() invoked...'
			);

		var statement =
			"GetIncompleteHookupsBySite @CutoffDate='" +
			moment(new Date()).startOf('day').format('YYYY/MM/DD HH:mm:ss') +
			"', @SiteId=" +
			service.widgetObject.WidgetSiteId;
		service.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					service.componentName + 'API.' + statement + ' = %O',
					data
				);
			if (data.length > 0) {
				data.forEach((row) => {
					let matchingFlightEvent = flightEvents.find(
						(ev) => ev.id == row.OAGFlightLegId
					);
					if (matchingFlightEvent) {
						matchingFlightEvent.color = '#20b2aa';
					}
				});
			}
			this.filteredFlightEvents = this.flightEvents.filter((fe) =>
				this.selectedCarriers.some(
					(carrier) =>
						carrier.AirlineCode ==
							fe.dataItem.arrival?.AirlineCode ||
						carrier == fe.dataItem.departure?.AirlineCode
				)
			);
		});
	}

	addGateEvent(gateEvent: any) {
		// This method results in duplicates because the updates come one-by-one instead of in a batch. Deprecating for now
		if (gateEvent.ArrivalOrDeparture == 'Arrival') {
			const arrival = gateEvent;
			let departure;
			const subsequentDepartures = this.flightEvents.filter(
				(departure) =>
					departure.ArrivalOrDeparture == 'Departure' &&
					arrival.ArrivalGateName == departure.DepartureGateName &&
					moment(departure.GateDepartureDateUTCMS).isAfter(
						moment(arrival.GateArrivalDateUTCMS)
					)
			);
			if (subsequentDepartures.length > 1) {
				departure = subsequentDepartures.find(
					(departure) => arrival.TailNumber == departure.TailNumber
				);
			} else {
				departure = subsequentDepartures[0];
			}
			let endDateMS = departure
				? departure.GateDepartureDateUTCMS
				: moment(arrival.GateArrivalDateUTC).add(1, 'h').valueOf();
			let schedulerStartDate =
				this.timeZoneType === 'User Time'
					? new Date(arrival.GateArrivalDateUTCMS)
					: ZonedDate.fromLocalDate(
							new Date(arrival.GateArrivalDateUTCMS),
							this.kendoTimeZone
					  );
			let schedulerEndDate =
				this.timeZoneType === 'User Time'
					? new Date(endDateMS)
					: ZonedDate.fromLocalDate(
							new Date(endDateMS),
							this.kendoTimeZone
					  );

			this.flightEvents.push({
				id: arrival.Id,
				start: schedulerStartDate,
				end: schedulerEndDate,
				title: `${arrival.AirlineCode}${arrival.FlightNumber}${
					departure
						? '-' +
						  departure.dataItem.departure.AirlineCode +
						  departure.dataItem.departure.FlightNumber
						: ''
				}`,
				dataItem: {
					arrival: arrival,
					departure: departure ? departure : null,
				},
				startDateMS: arrival.GateArrivalDateUTCMS,
				endDateMS: endDateMS,
				color: '',
				gateSysId: arrival.ArrivalGateSystemId
					? parseInt(arrival.ArrivalGateSystemId)
					: 1234,
				status: `Arrival Flight : ${
					arrival.AirlineCode + arrival.FlightNumber
				}
				Arrival Gate: ${arrival.ArrivalGateName ? arrival.ArrivalGateName : 'N/A'}
                Arrival TailNumber: ${arrival.TailNumber}
                Arrival Aircraft: ${arrival.AircraftType}
				Arrival Scheduled Time: ${
					this.timeZoneType === 'User Time'
						? moment(
								arrival.ArrivalToGateScheduledUTCDateMS
						  ).format('M/D hh:mm a')
						: moment
								.tz(
									arrival.ArrivalToGateScheduledUTCDateMS,
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Arrival Time: ${
					this.timeZoneType === 'User Time'
						? moment(schedulerStartDate).format('M/D hh:mm a')
						: moment
								.tz(
									arrival.GateArrivalDateUTCMS,
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Arriving From Site: ${arrival.DepartureSiteName}
                Departure Flight: ${
					departure
						? departure.dataItem.departure.AirlineCode +
						  departure.dataItem.departure.FlightNumber
						: 'N/A'
				}
				Departure Gate: ${
					departure && departure.dataItem.departure.DepartureGateName
						? departure.dataItem.departure.DepartureGateName
						: 'N/A'
				}
                Departure TailNumber: ${
					departure ? departure.dataItem.departure.TailNumber : 'N/A'
				}
                Departure Aircraft: ${
					departure
						? departure.dataItem.departure.AircraftType
						: 'N/A'
				}
				Departure Scheduled Time: ${
					departure
						? this.timeZoneType === 'User Time'
							? moment(
									departure.dataItem.departure
										.DepartureFromGateScheduledUTCDate
							  ).format('M/D hh:mm a')
							: moment
									.tz(
										departure.dataItem.departure
											.DepartureFromGateScheduledUTCDate,
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Departure Time: ${
					departure
						? this.timeZoneType === 'User Time'
							? moment(schedulerEndDate).format('M/D hh:mm a')
							: moment
									.tz(endDateMS, this.kendoTimeZone)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Departing To Site: ${
					departure
						? departure.dataItem.departure.ArrivalSiteName
						: 'N/A'
				}`,
			});
			Global.User.DebugMode &&
				console.log(
					`${this.componentName}: New Arrival added: ${gateEvent}`
				);
		} else {
			const departure = gateEvent;
			const arrival = this.flightEvents.find(
				(fe) =>
					fe.dataItem.arrival.ArrivalGateName ==
						departure.DepartureGateName &&
					moment(departure.GateDepartureDateUTCMS).isAfter(
						moment(fe.dataItem.arrival.GateArrivalDateUTCMS)
					)
			);
			if (arrival) {
				let schedulerEndDate =
					this.timeZoneType === 'User Time'
						? new Date(departure.GateDepartureDateUTCMS)
						: ZonedDate.fromLocalDate(
								new Date(departure.GateDepartureDateUTCMS),
								this.kendoTimeZone
						  );
				arrival.end = schedulerEndDate;
				arrival.title = `${arrival.dataItem.arrival.AirlineCode}${
					arrival.dataItem.arrival.FlightNumber
				}${
					departure
						? '-' + departure.AirlineCode + departure.FlightNumber
						: ''
				}`;
				arrival.dataItem.departure = departure;
				arrival.status = `Arrival Flight: ${
					arrival.dataItem.arrival.AirlineCode +
					arrival.dataItem.arrival.FlightNumber
				}
				Arrival Gate: ${arrival.ArrivalGateName ? arrival.ArrivalGateName : 'N/A'}
                Arrival TailNumber: ${arrival.dataItem.arrival.TailNumber}
                Arrival Aircraft: ${arrival.dataItem.arrival.AircraftType}
				Arrival Scheduled Time: ${
					this.timeZoneType === 'User Time'
						? moment(
								arrival.dataItem.arrival
									.ArrivalToGateScheduledUTCDateMS
						  ).format('M/D hh:mm a')
						: moment
								.tz(
									arrival.dataItem.arrival
										.ArrivalToGateScheduledUTCDateMS,
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Arrival Time: ${
					this.timeZoneType === 'User Time'
						? moment(arrival.startDateMS).format('M/D hh:mm a')
						: moment
								.tz(
									new Date(arrival.startDateMS),
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Arriving From Site: ${
					arrival.dataItem.arrival.DepartureSiteName
				}
                Departure Flight: ${
					departure
						? departure.AirlineCode + departure.FlightNumber
						: 'N/A'
				}
                Departure TailNumber: ${
					departure ? departure.TailNumber : 'N/A'
				}
                Departure Aircraft: ${
					departure ? departure.AircraftType : 'N/A'
				}
				Departure Scheduled Time: ${
					departure
						? this.timeZoneType === 'User Time'
							? moment(
									new Date(
										departure.DepartureFromGateScheduledUTCDate
									)
							  ).format('M/D hh:mm a')
							: moment
									.tz(
										new Date(
											departure.DepartureFromGateScheduledUTCDate
										),
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Departure Time: ${
					departure
						? this.timeZoneType === 'User Time'
							? moment(new Date(departure.endDateMS)).format(
									'M/D hh:mm a'
							  )
							: moment
									.tz(
										new Date(departure.endDateMS),
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Departing To Site: ${
					departure ? departure.ArrivalSiteName : 'N/A'
				}`;

				Global.User.DebugMode &&
					console.log(
						this.componentName + 'Arrival updated. Gate Event = %O',
						gateEvent
					);
			} else {
				let startDateMS = moment(departure.GateDepartureDateUTCMS)
					.subtract(1, 'h')
					.valueOf();
				let schedulerStartDate =
					this.timeZoneType === 'User Time'
						? new Date(startDateMS)
						: ZonedDate.fromLocalDate(
								new Date(startDateMS),
								this.kendoTimeZone
						  );
				let schedulerEndDate =
					this.timeZoneType === 'User Time'
						? new Date(departure.GateDepartureDateUTCMS)
						: ZonedDate.fromLocalDate(
								new Date(departure.GateDepartureDateUTCMS),
								this.kendoTimeZone
						  );
				this.flightEvents.push({
					id: departure.Id,
					start: schedulerStartDate,
					end: schedulerEndDate,
					title: `${departure.AirlineCode + departure.FlightNumber}`,
					dataItem: { arrival: null, departure: departure },
					startDateMS: startDateMS,
					endDateMS: departure.GateDepartureDateUTCMS,
					color: '',
					gateSysId: departure.ArrivalGateSystemId
						? parseInt(departure.ArrivalGateSystemId)
						: 1234,
					status: `Arrival Flight: N/A
                    Arrival TailNumber: N/A
                    Arrival Aircraft: N/A
                    Arrival Time: N/A
                    Arriving From Site: N/A
                    Departure Flight: ${
						departure.AirlineCode + departure.FlightNumber
					}
					Departure Gate: ${
						departure.DepartureGateName
							? departure.DepartureGateName
							: 'N/A'
					}
                    Departure TailNumber: ${departure.TailNumber}
                    Departure Aircraft: ${departure.AircraftType}
					Departure Scheduled Time: ${
						departure
							? this.timeZoneType === 'User Time'
								? moment(
										departure.DepartureFromGateScheduledUTCDate
								  ).format('M/D hh:mm a')
								: moment
										.tz(
											new Date(
												departure.DepartureFromGateScheduledUTCDate
											),
											this.kendoTimeZone
										)
										.format('M/D hh:mm a')
							: 'N/A'
					}
                    Departure Time: ${
						departure
							? this.timeZoneType === 'User Time'
								? moment(
										departure.GateDepartureDateUTCMS
								  ).format('M/D hh:mm a')
								: moment
										.tz(
											new Date(
												departure.GateDepartureDateUTCMS
											),
											this.kendoTimeZone
										)
										.format('M/D hh:mm a')
							: 'N/A'
					}
                    Departing To Site: ${departure.ArrivalSiteName}`,
				});
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'New Departure added. Gate Event: %O',
						gateEvent
					);
			}
		}
		this.filteredFlightEvents = this.flightEvents.filter((fe) =>
			this.selectedCarriers.some(
				(carrier) =>
					carrier.AirlineCode == fe.dataItem.arrival?.AirlineCode ||
					carrier == fe.dataItem.departure?.AirlineCode
			)
		);
	}

	updateGateEvent(gateEvent: any) {
		let arrivalToUpdate, departureToUpdate;
		if (gateEvent.ArrivalOrDeparture == 'Arrival') {
			arrivalToUpdate = this.flightEvents.find(
				(arrival) => arrival.id == gateEvent.Id
			);
			if (arrivalToUpdate) {
				let departure = arrivalToUpdate.dataItem.departure;
				arrivalToUpdate.dataItem.arrival = gateEvent;
				arrivalToUpdate.title = `${
					arrivalToUpdate.dataItem.arrival
						? arrivalToUpdate.dataItem.arrival.AirlineCode +
						  arrivalToUpdate.dataItem.arrival.FlightNumber
						: ''
				}${
					departure
						? '-' + departure.AirlineCode + departure.FlightNumber
						: ''
				}`;
				arrivalToUpdate.start =
					this.timeZoneType === 'User Time'
						? new Date(gateEvent.GateArrivalDateUTCMS)
						: ZonedDate.fromLocalDate(
								new Date(gateEvent.GateArrivalDateUTCMS),
								this.kendoTimeZone
						  );
				arrivalToUpdate.status = `Arrival Flight: ${
					arrivalToUpdate.dataItem.arrival.AirlineCode +
					arrivalToUpdate.dataItem.arrival.FlightNumber
				}
				Arrival Gate: ${
					arrivalToUpdate.dataItem.arrival.ArrivalGateName
						? arrivalToUpdate.dataItem.arrival.ArrivalGateName
						: 'N/A'
				}
                Arrival TailNumber: ${
					arrivalToUpdate.dataItem.arrival.TailNumber
				}
                Arrival Aircraft: ${
					arrivalToUpdate.dataItem.arrival.AircraftType
				}
				Arrival Scheduled Time: ${
					this.timeZoneType === 'User Time'
						? moment(
								arrivalToUpdate.dataItem.arrival
									.ArrivalToGateScheduledUTCDateMS
						  ).format('M/D hh:mm a')
						: moment
								.tz(
									arrivalToUpdate.dataItem.arrival
										.ArrivalToGateScheduledUTCDateMS,
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Arrival Time: ${
					this.timeZoneType === 'User Time'
						? moment(arrivalToUpdate.startDateMS).format(
								'M/D hh:mm a'
						  )
						: moment
								.tz(
									new Date(arrivalToUpdate.startDateMS),
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Arriving From Site: ${
					arrivalToUpdate.dataItem.arrival.DepartureSiteName
				}
                Departure Flight: ${
					departure
						? departure.AirlineCode + departure.FlightNumber
						: 'N/A'
				}
                Departure TailNumber: ${
					departure ? departure.TailNumber : 'N/A'
				}
                Departure Aircraft: ${
					departure ? departure.AircraftType : 'N/A'
				}
				Departure Scheduled Time: ${
					departure
						? this.timeZoneType === 'User Time'
							? moment(
									new Date(
										arrivalToUpdate.dataItem.departure.DepartureFromGateScheduledUTCDate
									)
							  ).format('M/D hh:mm a')
							: moment
									.tz(
										new Date(
											arrivalToUpdate.dataItem.departure.DepartureFromGateScheduledUTCDate
										),
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Departure Time: ${
					departure
						? this.timeZoneType === 'User Time'
							? moment(
									new Date(
										arrivalToUpdate.dataItem.departure.endDateMS
									)
							  ).format('M/D hh:mm a')
							: moment
									.tz(
										new Date(
											arrivalToUpdate.dataItem.departure.endDateMS
										),
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Departing To Site: ${
					departure ? departure.ArrivalSiteName : 'N/A'
				}`;
				if (
					arrivalToUpdate.gateSysId == 1234 &&
					gateEvent.ArrivalGateSystemId != 1234
				) {
					arrivalToUpdate.gateSysId = gateEvent.ArrivalGateSystemId;
				} else if (
					arrivalToUpdate.gateSysId == 1234 &&
					gateEvent.ArrivalGateName != null
				) {
					arrivalToUpdate.gateSysId =
						this.gateSystems.find(
							(system) => system.text == gateEvent.ArrivalGateName
						)?.value ?? 1234;
				}

				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'Gate: ' +
							gateEvent.ArrivalGateName +
							' ' +
							arrivalToUpdate.title +
							' was updated'
					);
			}
		} else {
			// Departure
			departureToUpdate = this.flightEvents.find(
				(dep) => dep.id == gateEvent.Id
			);
			if (!departureToUpdate) {
				departureToUpdate = this.flightEvents.find(
					(arrival) => arrival.dataItem.departure?.Id == gateEvent.Id
				);
			}
			if (departureToUpdate) {
				let arrival = departureToUpdate.dataItem.arrival;
				departureToUpdate.dataItem.departure = gateEvent;
				departureToUpdate.title = `${
					arrival ? arrival.AirlineCode + arrival.FlightNumber : ''
				}${
					'-' +
					departureToUpdate.dataItem.departure.AirlineCode +
					departureToUpdate.dataItem.departure.FlightNumber
				}`;
				departureToUpdate.end =
					this.timeZoneType === 'User Time'
						? new Date(gateEvent.GateDepartureDateUTCMS)
						: ZonedDate.fromLocalDate(
								new Date(gateEvent.GateDepartureDateUTCMS),
								this.kendoTimeZone
						  );
				departureToUpdate.status = `Arrival Flight: ${
					arrival ? arrival.AirlineCode + arrival.FlightNumber : 'N/A'
				}
                Arrival TailNumber: ${arrival ? arrival.TailNumber : 'N/A'}
                Arrival Aircraft: ${arrival ? arrival.AircraftType : 'N/A'}
				Arrival Scheduled Time: ${
					arrival
						? this.timeZoneType === 'User Time'
							? moment(
									departureToUpdate.dataItem.arrival
										.ArrivalToGateScheduledUTCDateMS
							  ).format('M/D hh:mm a')
							: moment
									.tz(
										departureToUpdate.dataItem.arrival
											.ArrivalToGateScheduledUTCDateMS,
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Arrival Time: ${
					arrival
						? this.timeZoneType === 'User Time'
							? moment(
									new Date(
										departureToUpdate.dataItem.arrival.startDateMS
									)
							  ).format('M/D hh:mm a')
							: moment
									.tz(
										new Date(
											departureToUpdate.dataItem.arrival.startDateMS
										),
										this.kendoTimeZone
									)
									.format('M/D hh:mm a')
						: 'N/A'
				}
                Arriving From Site: ${
					arrival ? arrival.DepartureSiteName : 'N/A'
				}
                Departure Flight: ${
					departureToUpdate.dataItem.departure.AirlineCode +
					departureToUpdate.dataItem.departure.FlightNumber
				}
				Departure Gate: ${
					departureToUpdate.dataItem.departure.DepartureGateName
						? departureToUpdate.dataItem.departure.DepartureGateName
						: 'N/A'
				}
                Departure TailNumber: ${
					departureToUpdate.dataItem.departure.TailNumber
				}
                Departure Aircraft: ${
					departureToUpdate.dataItem.departure.AircraftType
				}
				Departure Scheduled Time: ${
					this.timeZoneType === 'User Time'
						? moment(
								new Date(
									departureToUpdate.DepartureFromGateScheduledUTCDate
								)
						  ).format('M/D hh:mm a')
						: moment
								.tz(
									new Date(
										departureToUpdate.DepartureFromGateScheduledUTCDate
									),
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Departure Time: ${
					this.timeZoneType === 'User Time'
						? moment(new Date(departureToUpdate.endDateMS)).format(
								'M/D hh:mm a'
						  )
						: moment
								.tz(
									new Date(departureToUpdate.endDateMS),
									this.kendoTimeZone
								)
								.format('M/D hh:mm a')
				}
                Departing To Site: ${
					departureToUpdate.dataItem.departure.ArrivalSiteName
				}`;
				if (
					departureToUpdate.gateSysId == 1234 &&
					gateEvent.DepartureGateSystemId != 1234
				) {
					departureToUpdate.gateSysId =
						gateEvent.DepartureGateSystemId;
				} else if (
					departureToUpdate.gateSysId == 1234 &&
					gateEvent.DepartureGateName != null
				) {
					departureToUpdate.gateSysId =
						this.gateSystems.find(
							(system) =>
								system.text == gateEvent.DepartureGateName
						)?.value ?? 1234;
				}

				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'Gate: ' +
							gateEvent?.DepartureGateName +
							' ' +
							departureToUpdate.title +
							' was updated'
					);
			}
		}
		this.lastFlightDataUpdate = new Date(
			gateEvent.LastModifiedDateUTCMilliseconds
		);
	}

	deleteGateEvent(gateEvent: any) {
		let eventToDeleteIndex = this.flightEvents.findIndex(
			(ev) => ev.id == gateEvent.Id
		);
		this.flightEvents = this.flightEvents.splice(eventToDeleteIndex, 1);
		Global.User.DebugMode &&
			console.log(
				this.componentName + 'Event Deleted. Gate Event = %O',
				gateEvent
			);
	}

	getFlightDataUpdates() {
		var service = this;
		Global.User.DebugMode &&
			console.log(
				service.componentName + 'getFlightDataUpdates() invoked...'
			);

		service.signalRCore.joinGroup(
			`OAGFlightLeg_${service.widgetObject.WidgetSiteName}`
		); // Create subsciption for AircraftDockToUndock table updates
		service.signalRUpdateSubscription = this.signalRCore.broadcastMessages$
			.pipe(
				filter(
					(msg: any) =>
						msg.code == 'SQL.OAGFlightLeg.Insert' ||
						msg.code == 'SQL.OAGFlightLeg.Update' ||
						msg.code == 'SQL.OAGFlightLeg.Delete'
				)
			)
			.subscribe((update) => {
				let signalRData =
					service.dataService.GetJsonFromSignalR(update);
				if (service.gateNames.contains(signalRData.ArrivalGateName)) {
					switch (signalRData.DatabaseOperation) {
						// case 'Insert':
						//     service.addGateEvent(signalRData);
						//     break;

						case 'Update':
							service.updateGateEvent(signalRData);
							break;

						case 'Delete':
							service.deleteGateEvent(signalRData);
							break;
					}
				}
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'New Flight Data: %O',
						signalRData
					);
			});
	}

	loadPerfectTurnIntoDataCache(flightEvents: any, service: any) {
		let siteObject = this.dataService.cache.sites.find((site) => {
			return site.Id == this.widgetObject.WidgetSiteId;
		});

		var assetIds = siteObject.Assets.select((asset: IAsset) => {
			return asset.Id;
		})
			.toArray()
			.join(',');

		var standardObservationIds =
			this.dataService.perfectTurnStandardObservationIds.PBB +
			',' +
			this.dataService.perfectTurnStandardObservationIds.PCA +
			',' +
			this.dataService.perfectTurnStandardObservationIds.GPU;

		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(assetIds, false, standardObservationIds).subscribe((data) => {
			if (data.length > 0) {

				//Global.User.currentUser.Username == 'kendemerchant' && console.log("data=", data);
				this.tagData = data;

				this.updateScheduleWithPerfectTurn();


				let interval = setInterval(() => {
					//var t0 = performance.now()
					this.updateScheduleWithPerfectTurn();
					//var t1 = performance.now()
					//console.log("updateScheduleWithPerfectTurn took " + (t1 - t0) + " milliseconds.")
				}, 10000);

			}
		});

	}

	updateScheduleWithPerfectTurn() {

		this.tagData.forEach(tag => {
			//Global.User.currentUser.Username == 'kendemerchant' && console.log("tagsObject=", this.dataService.cache.tagsObject[tag.Id]);

			if(tag.Asset.Name == "PBB" && tag.JBTStandardObservationId == 12245) {

				var matchingGateEvent = this.filteredFlightEvents.find(
					(ge) =>
						(ge.dataItem.arrival?.ArrivalGateName == tag.Asset.ParentSystem.Name ||
						ge.dataItem.departure?.DepartureGateName == tag.Asset.ParentSystem.Name) &&
						ge.startDateMS < tag.DateInMilliseconds &&
						ge.endDateMS > tag.DateInMilliseconds
				);

				if(matchingGateEvent) {
					//Global.User.currentUser.Username == 'kendemerchant' && console.log("matchingGateEvent=", matchingGateEvent);

					// get pbb ismoving tag
					let isMovingTag = tag.Asset.Tags.find(
						(tag) => tag.JBTStandardObservationId == 54271
					);

					//this.CalculateCurrentTurnState(tag.Asset.ParentSystem.PerfectTurn); save for possible future use

					if(this.dataService.cache.tagsObject[tag.Id]?.Value == "1") {

						matchingGateEvent.color = '#20b2aa';
					   	//Global.User.currentUser.Username == 'kendemerchant' && console.log("matchingGateEvent=", matchingGateEvent);
					}
					else if(isMovingTag != null && this.dataService.cache.tagsObject[isMovingTag.Id]?.Value == "1") {
						matchingGateEvent.color = '#20b2aa'; // = '#2071b2'; possible to use a different color when pbb is moving vs. aircraft docked
					} else {
						matchingGateEvent.color = 'LightGrey';
					}
				}
			}
		});
	}

	// Determine the currect state of the turn based on the set of perfect turn tags
	CalculateCurrentTurnState(perfectTurnTags: any) {

		console.log(perfectTurnTags);

		let docked = perfectTurnTags.PBB.find((item) => item.JBTStandardObservationId === 12245);
		let isMoving = perfectTurnTags.PBB.find((item) => item.JBTStandardObservationId === 54271);

		let gpuUnitOn = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 12374);
		let AircraftOnGPUPower = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 15166);
		let GPUPowerOn = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 15898);
		let AmpsOutAverage = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 1942);

		let pcaUnitOn = perfectTurnTags.PCA.find((item) => item.JBTStandardObservationId === 12374);
		let BridgeMode = perfectTurnTags.PCA.find((item) => item.JBTStandardObservationId === 15220);

		// GPU
		if(AircraftOnGPUPower?.Value == '1' || GPUPowerOn?.Value == '1' || AmpsOutAverage?.Value > 6) {
			// GPU Switchover
			console.log("GPU Switchover=", AmpsOutAverage.TagName)

		}
		if(gpuUnitOn?.Value =='1') {
			// GPU UNIT ON
			console.log("GPU UNIT ON=", gpuUnitOn.TagName)
		}

		// PCA
		if(pcaUnitOn?.Value =='1' && BridgeMode?.Value =='0') {
			// PCA UNIT ON and Ventilating Aircraft
			console.log("PCA Ventilating Aircraft=", pcaUnitOn.TagName)

		}

	}


	getPerfectTurnUpdates() {
		var service = this;
		Global.User.DebugMode &&
			console.log(
				this.componentName + 'getPerfectTurnUpdates() invoked...'
			);

		service.gateSystemIds.forEach((id) =>
			service.signalRCore.joinGroup(`PerfectTurn_${id}`)
		); // Create subsciption for AircraftDockToUndock table updates for each of the gate systems
		service.signalRUpdateSubscription =
			service.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == 'AircraftDockToUndock'))
				.subscribe((update) => {
					let signalRData =
						service.dataService.GetJsonFromSignalR(update);
					service.processPerfectTurnUpdate(signalRData, service);
					Global.User.DebugMode &&
						console.log(
							this.componentName + 'New Perfect Turn Data: %O',
							signalRData
						);
				});
	}

	processPerfectTurnUpdate(update: any, service: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + 'processPerfectTurnUpdate() invoked...'
			);
		let matchingGateEvent = service.flightEvents.find(
			(ge) =>
				ge.dataItem.arrival?.Id == update?.OAGFlightLegId ||
				ge.dataItem.departure?.Id == update?.OAGFlightLegId
		);
		if (matchingGateEvent) {
			if (
				update.PBBDocked != 0 &&
				(update.PCAVentilatingAircraft == 0 ||
					update.GPUSwitchover == 0)
			) {
				matchingGateEvent.color = '#20b2aa';
			} else if (
				(update.PCAOFF != 0 || update.GPUOFF != 0) &&
				update.PBBUndocked == 0
			) {
				matchingGateEvent.color = '#20b2aa';
			} else {
				matchingGateEvent.color = 'LightGrey';
			}
			Global.User.DebugMode &&
				console.log(this.componentName + 'Perfect Turn Data updated');
		}
	}

	//
	// grid
	//

	// declare callback functions (onChange, onColumnresize etc)
	filterChange(filter) {
		this.gridSettings.state.filter = filter;
		this.gridSettings.gridData = process(
			this.gridData,
			this.gridSettings.state
		);
		this.saveState();
	}

	sortChange(sort): void {
		this.gridSettings.state.sort = sort;
		this.gridSettings.gridData = process(
			this.gridData,
			this.gridSettings.state
		);
		this.saveState();
	}

	pageChange(event: PageChangeEvent) {
		this.gridSettings.state.skip = event.skip;
		this.gridSettings.state.take = event.take;
		this.gridSettings.gridData = process(
			this.gridData,
			this.gridSettings.state
		);
	}

	onChange() {
		this.saveState();
	}

	columnLockedChange() {
		this.saveState();
	}

	onColumnResize() {
		this.saveState();
	}

	onReorder() {
		setTimeout(() => {
			this.saveState();
		}, 500);
	}

	// Reload grid settings

	columnResizeManual(params, grid, c) {
		let foundIndex = grid.columnList.columns._results.findIndex(
			(column) => {
				return column.field === c.field;
			}
		);
		console.log(foundIndex);

		let column = grid.columnList.columns._results[foundIndex];
		console.log(column._width);
		if (params === 'bigger') {
			grid.columnList.columns._results[foundIndex]._width =
				grid.columnList.columns._results[foundIndex]._width + 50;
		}

		if (params === 'smaller') {
			if (grid.columnList.columns._results[foundIndex]._width < 50) {
				grid.columnList.columns._results[foundIndex]._width = 40;
			} else {
				grid.columnList.columns._results[foundIndex]._width =
					grid.columnList.columns._results[foundIndex]._width - 50;
			}
		}

		this.saveState();
	}

	saveState() {
		// if (this.configuration.isFromWidget) { // don't save grid settings if pca summary is a popup (no widgetId) check isFromWidget
		this.callSave.next(this.componentName);
		//console.log(this.configuration.gridSettings);

		// }
	}

	CreateGrid() {
		this.gridData = [];

		this.flightEvents.forEach((fe) => {
			var ArrivalToGateScheduledDate;
			var GateArrivalDate;
			var DepartureFromGateScheduledDate;
			var GateDepartureDate;

			if (this.timeZoneType === 'User Time') {
				if (fe.dataItem.arrival) {
					ArrivalToGateScheduledDate = moment(
						fe.dataItem.arrival?.ArrivalToGateScheduledUTCDate
					).format('M/D hh:mm a');
					GateArrivalDate = moment(
						fe.dataItem.arrival?.GateArrivalDateUTC
					).format('M/D hh:mm a');
				}
				if (fe.dataItem.departure) {
					DepartureFromGateScheduledDate = moment(
						fe.dataItem.departure?.DepartureFromGateScheduledUTCDate
					).format('M/D hh:mm a');
					GateDepartureDate = moment(
						fe.dataItem.departure?.GateDepartureDateUTC
					).format('M/D hh:mm a');
				}
			} else if (this.timeZoneType === 'Site Time') {
				if (fe.dataItem.arrival) {
					ArrivalToGateScheduledDate = moment
						.tz(
							fe.dataItem.arrival?.ArrivalToGateScheduledUTCDate,
							this.kendoTimeZone
						)
						.format('M/D hh:mm a');
					GateArrivalDate = moment
						.tz(
							fe.dataItem.arrival?.GateArrivalDateUTC,
							this.kendoTimeZone
						)
						.format('M/D hh:mm a');
				}
				if (fe.dataItem.departure) {
					DepartureFromGateScheduledDate = moment
						.tz(
							fe.dataItem.departure
								?.DepartureFromGateScheduledUTCDate,
							this.kendoTimeZone
						)
						.format('M/D hh:mm a');
					GateDepartureDate = moment
						.tz(
							fe.dataItem.departure?.GateDepartureDateUTC,
							this.kendoTimeZone
						)
						.format('M/D hh:mm a');
				}
			} else {
				if (fe.dataItem.arrival) {
					ArrivalToGateScheduledDate = moment
						.utc(fe.dataItem.arrival?.ArrivalToGateScheduledUTCDate)
						.format('M/D hh:mm a');
					GateArrivalDate = moment
						.utc(fe.dataItem.arrival?.GateArrivalDateUTC)
						.format('M/D hh:mm a');
				}
				if (fe.dataItem.departure) {
					DepartureFromGateScheduledDate = moment
						.utc(
							fe.dataItem.departure
								?.DepartureFromGateScheduledUTCDate
						)
						.format('M/D hh:mm a');
					GateDepartureDate = moment
						.utc(fe.dataItem.departure?.GateDepartureDateUTC)
						.format('M/D hh:mm a');
				}
			}

			var data = {
				ArrivalFlight: fe.dataItem.arrival
					? fe.dataItem.arrival?.AirlineCode +
					  fe.dataItem.arrival?.FlightNumber
					: '',
				ArrivalGate: fe.dataItem.arrival?.ArrivalGateName,
				ArrivalTailNumber: fe.dataItem.arrival?.TailNumber,
				ArrivalAircraft: fe.dataItem.arrival?.AircraftType,
				ArrivalScheduledTime: ArrivalToGateScheduledDate,
				ArrivalTime: GateArrivalDate,
				ArrivalFromSite: fe.dataItem.arrival?.DepartureSiteName,

				DepartureFlight: fe.dataItem.departure
					? fe.dataItem.departure?.AirlineCode +
					  fe.dataItem.departure?.FlightNumber
					: '',
				DepartureGate: fe.dataItem.departure?.DepartureGateName,
				DepartureTailNumber: fe.dataItem.departure?.TailNumber,
				DepartureAircraft: fe.dataItem.departure?.AircraftType,
				DepartureScheduledTime: DepartureFromGateScheduledDate,
				DepartureTime: GateDepartureDate,
				DepartureToSite: fe.dataItem.departure?.ArrivalSiteName,
			};

			this.gridData.push(data);
		});

		this.tagDataGrid?.gridDataSubject.next(this.gridData);
	}
}
