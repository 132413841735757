<div class="full-page login-page login-background-{{global.DefaultThemeForApplication}}" [hidden]="loginProcessLoading">
	<!-- <mat-progress-bar *ngIf="loginProcessLoading" mode="indeterminate" style="display: inline-block; position: absolute; z-index: 999"></mat-progress-bar> -->

	<div class="content" style="overflow: scroll; height: calc(100vh);">
		<div class="container">
			<div class="col-lg-4 col-md-6 ml-auto mr-auto">
				<div class="card card-login card-white">
					<div class="card-header login-form">
						<img alt="" [src]="global.imagesUrl + 'assets/img/iOPSProLogo.png'" width="150px" height="100px" style="margin-left: 95px" />
					</div>
					<div class="card-body">
						<div *ngIf="changePasswordHasBeenRequested && usernameHasNotBeenValued">
							<form class="form" [formGroup]="resetPasswordForm" autocomplete="off" (ngSubmit)="resetPasswordByUsername(resetPasswordForm.value)">
								<div class="input-group">
									<div class="input-group-prepend">
										<div class="input-group-text">
											<i class="tim-icons icon-user-run"> </i>
										</div>
									</div>
									<input formControlName="resetUsername" class="form-control" placeholder="Username{{ !validateUsername() || mouseoverLogin ? ' is required' : '' }}" id="resetUsername" type="text" />
								</div>
								<div class="card-footer">
									<span (mouseenter)="mouseoverLogin = true" (mouseleave)="mouseoverLogin = false">
										<button class="btn btn-success btn-lg btn-block mb-3" type="submit" [disabled]="resetPasswordForm.invalid">Send Password Token</button>
									</span>
								</div>
							</form>
						</div>
						<div *ngIf="!changePasswordHasBeenRequested" >
							<form class="form" [formGroup]="loginForm" autocomplete="off" (ngSubmit)="login(loginForm.value)">
								<div class="input-group">
									<div class="input-group-prepend">
										<div class="input-group-text">
											<i class="tim-icons icon-user-run"> </i>
										</div>
									</div>
									<input formControlName="username" class="form-control" (keyup.enter)="validateUsername() && validatePassword() ? login(loginForm.value) : ''" placeholder="Username{{ !validateUsername() || mouseoverLogin ? ' is required' : '' }}" id="username" type="text" />
								</div>
								<div class="input-group">
									<div class="input-group-prepend">
										<div class="input-group-text">
											<i class="tim-icons icon-lock-circle"> </i>
										</div>
									</div>
									<input formControlName="password" class="form-control" (keyup.enter)="validateUsername() && validatePassword() ? login(loginForm.value) : ''" placeholder="Password{{ !validatePassword() || mouseoverLogin ? ' is required' : '' }}" id="password" type="password" />
								</div>
							</form>
							<div class="card-footer">
								<span (mouseenter)="mouseoverLogin = true" (mouseleave)="mouseoverLogin = false">
									<button class="btn btn-success btn-lg btn-block mb-3" type="submit" [disabled]="loginForm.invalid" title="Log into our new iOPS application" (keyup.enter)="validateUsername() && validatePassword() ? login(loginForm.value) : ''" (click)="login(loginForm.value)">Log In</button>
								</span>
								<div class="pull-left">
									<h6>
										<a class="link footer-link" [routerLink]="['/registration']" href="javascript:void(0)"> Request Account </a>
									</h6>
								</div>
								<div class="pull-right">
									<h6>
										<a (click)="sendPasswordToken()" class="link footer-link" href="javascript:void(0)"> Forgot Password? </a>
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
