import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import _ from 'lodash';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';

@Component({
	selector: 'lib-tactical-dashboard-interval-popup-confirmation-settings',
	templateUrl:
		'./tactical-dashboard-interval-popup-confirmation-settings.component.html',
	styleUrls: [
		'./tactical-dashboard-interval-popup-confirmation-settings.component.scss',
	],
})
export class TacticalDashboardIntervalPopupConfirmationSettingsComponent
	implements OnInit
{
	constructor(
		@Inject(MAT_DIALOG_DATA) public inputObject: any,
		public dialogRef: MatDialogRef<TacticalDashboardIntervalPopupConfirmationSettingsComponent>,
		private dataService: DataService,
		public toastr: ToastrService,
		private dashboardService: DashboardService
	) {}

	ngOnInit() {
		this.inputObject.dashboardWidgets.forEach((widget) => {
			let currentlyAssignedUpdateInterval =
				this.inputObject.updateIntervals.find(
					(tz) => tz.Id === widget.UpdateIntervalId
				);
			if (!_.isNil(currentlyAssignedUpdateInterval)) {
				widget.UpdateIntervalDescription =
					currentlyAssignedUpdateInterval.Description;
				if (
					currentlyAssignedUpdateInterval.Id ===
					this.inputObject.selectedUpdateIntervalObject.Id
				) {
					widget.isUpdateIntervalSelected = true;
				} else {
					widget.isUpdateIntervalSelected = false;
				}
			} else {
				widget.isUpdateIntervalSelected = true;
				widget.UpdateIntervalDescription = '';
			}
		});
		console.log(this.inputObject);
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		this.dialogRef.close(this.inputObject);
	}

	selectAllAndClose() {
		this.inputObject.dashboardWidgets.forEach((widget) => {
			widget.isUpdateIntervalSelected = true;
		});
		this.submitAndClose();
	}
}
