import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddWidgetToDashboardComponent } from './add-widget-to-dashboard.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { GridModule } from '@progress/kendo-angular-grid';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [AddWidgetToDashboardComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatTooltipModule,
		LoadingSpinnerModule,
		MatDialogModule,
		MatTabsModule,
		MatTableModule,
		GridModule,
		KendoGridParentModule,
	],
	exports: [AddWidgetToDashboardComponent],
})
export class AddWidgetToDashboardModule {}
