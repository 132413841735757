import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, HostListener, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { HttpEventType, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { FileUploadService } from "../../services/file-upload.service";
import { Global } from "../../_constants/global.variables";
import { UtilityService } from "../../services/utility.service";
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../../services/data.service";
import { DomSanitizer } from "@angular/platform-browser";
import { SignalRCoreService } from "../../services/signalr-core.service";

@Component({
	selector: "lib-file-upload-list",
	templateUrl: "./file-upload-list.component.html",
	styleUrls: ["./file-upload-list.component.scss"],
})
export class FileUploadListComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@Input() public fileImageLibraryControlObject: any;

	@ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
	files = [];
	@Output("dropZone") fileDrop = new EventEmitter<Array<File>>();

	public listTitle: string = "File Uploads";
	public listSubtitle: string = "Upload file(s) here.";
	public iconmenu: string =
		"doc,docx,xls,xlsm,xlsx,msg,pdf,ppt,pptx,js,css,mp4,mp3,wav,zip,txt,json,config,cs,aspx,html,tif,tiff,vsd,chm,asp,sql,hl7,xml,linq,rdl,wma,msi,exe,reg,csv,webm,avi";

	public uploadReordinal: number = 100000;
	public newFiles: any;
	public canBeClosed: boolean = true;
	public showCancelButton: boolean = true;
	public dropzoneActive: boolean = false;
	public currentUpload: any;
	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.ODataAccessToken;

	private fileIndex: number = 0;
	private fileImageLibraryData: Array<File>;
	private currentImageKeyList: string;
	private swalWithBootstrapButtons: any;
	public entityId: string;
	public entityType: string;
	public showSettings: boolean = false;
	private componentName: string = "file-upload";
	public readOnly: boolean = false;
	private filesUploaded$: any;

	constructor(
		public dialogRef: MatDialogRef<FileUploadListComponent>,
		private fileUploadService: FileUploadService,
		private elementRef: ElementRef,
		private utilityService: UtilityService,
		private toastr: ToastrService,
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private sanitizer: DomSanitizer,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		var service = this;
		setTimeout(function () {
			service.addEventListenerToDropArea(service);
		}, 200);

		if (this.fileImageLibraryControlObject) {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						": this.fileImageLibraryControlObject = %O",
					this.fileImageLibraryControlObject
				);
			this.listTitle = this.fileImageLibraryControlObject.listTitle;
			this.entityId = this.fileImageLibraryControlObject.entityId;
			this.entityType = this.fileImageLibraryControlObject.entityType;
			//Disable Video Upload Capability for Training Videos for Non-Admins
			this.readOnly = false; //this.fileImageLibraryControlObject.readOnly != null ? this.fileImageLibraryControlObject.readOnly : !Global.User.isAdmin;
			this.loadFileList();
		}
	}

	ngAfterViewInit() {
		var service = this;
		service.addEventListenerToDropArea(service);
		var groupToJoin =
			"UploadedFiles_" +
			service.fileImageLibraryControlObject.entityType +
			"_" +
			service.fileImageLibraryControlObject.entityId;
		Global.User.DebugMode &&
			console.log(
				service.componentName +
					": Joining " +
					groupToJoin +
					" SignalR group..."
			);
		service.signalRCore.joinAdditionalGroup(groupToJoin);
		service.filesUploaded$ = service.signalRCore.broadcastMessages$
			.pipe(
				filter(
					(msg: any) =>
						msg.code == "Files Uploaded" ||
						msg.code == "Files Changed"
				)
			)
			.subscribe((data: any) => {
				var sqlEntity = data.object == null ? data.code : data.object;
				Global.User.DebugMode &&
					console.log(
						service.componentName +
							": SignalR Message received. Code: " +
							data.code +
							", data.object received via SignalR: %O",
						data.object
					);
				Global.User.DebugMode &&
					console.log(
						service.componentName +
							": this.fileImageLibraryControlObject = %O",
						service.fileImageLibraryControlObject
					);

				if (
					service.fileImageLibraryControlObject.entityType ==
						data.object.entityType &&
					service.fileImageLibraryControlObject.entityId ==
						data.object.entityId &&
					service.fileImageLibraryControlObject.imageKeys !==
						data.object.imageKeys
				) {
					Global.User.DebugMode &&
						console.log(
							service.componentName +
								": imageKey list for this entity is not the same as what was received via SignalR.  Loading the file list again..."
						);

					service.fileImageLibraryControlObject.imageKeys =
						data.object.imageKeys; //-- set to the list of image keys we just received from this SignalR message.
					service.loadFileList(); //-- go get the file list since the image key list changed from another source. --Kirk T. Sherer, April 7, 2021.
				}
			});
	}

	ngOnDestroy() {
		this.signalRCore.leaveAdditionalGroup(
			"UploadedFiles_" +
				this.fileImageLibraryControlObject.entityType +
				"_" +
				this.fileImageLibraryControlObject.entityId
		);
		this.filesUploaded$.unsubscribe();
	}

	addEventListenerToDropArea(service: any) {
		const dropZone = document.body;
		Global.User.DebugMode &&
			console.log(this.componentName + ": dropZone = %O", dropZone);
		dropZone.addEventListener(
			"drop",
			function (event) {
				Global.User.DebugMode &&
					console.log(service.componentName + ": event = %O", event);

				event.preventDefault();
				event.stopPropagation();

				var items = event.dataTransfer.items;
				for (var i = 0; i < items.length; i++) {
					// webkitGetAsEntry is where the magic happens
					var item = items[i];
					Global.User.DebugMode &&
						console.log(
							service.componentName + ": item = %O",
							item
						);
					if (item) {
						service.traverseFileTree(item);
					}
				}

				service.uploadFiles(service);
			},
			false
		);
	}

	onDropEvent(items: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": onDropEvent invoked... %O",
				items
			);

		const fileUpload = this.fileUpload.nativeElement;
		fileUpload.onchange = () => {
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				this.files.push({ data: file, inProgress: false, progress: 0 });
			}
		};

		this.fileUpload.nativeElement.value = "";
		var fileObject: any = {};
		for (var i = 0; i < items.length; i++) {
			// webkitGetAsEntry is where the magic happens
			var item = items[i];
			Global.User.DebugMode &&
				console.log(this.componentName + ": item = %O", item);

			var fileObject: any = {};
			fileObject.inProgress = false;
			fileObject.uploadProgress = 0;
			fileObject.downloadProgress = 0;
			fileObject.data = item;

			this.compareIncomingFileToExistingFiles(fileObject);
		}

		this.uploadFiles(this);
	}

	compareIncomingFileToExistingFiles(fileObject: any) {
		var dataType = fileObject.data.type;
		if (dataType == "") {
			var fileExtension = fileObject.data.name
				.split(".")
				.last()
				.toLowerCase();
			if (fileExtension == "msg") {
				dataType = "application/msg";
			}
		}
		if (dataType != "") {
			//service.traverseFileTree(fileObject);
			var fileAlreadyExists: boolean = false;
			this.files.forEach((file: any) => {
				if (fileObject.data.name == file.data.name) {
					fileAlreadyExists = true;
				}
			});
			if (!fileAlreadyExists) {
				this.files.push(fileObject);
			} else {
				this.utilityService.showToastMessageShared({
					type: "info",
					message:
						"File '" + fileObject.data.name + "' already exists.",
					title: this.listTitle,
				});
				// this.toastr.info("File '" + fileObject.data.name + "' already exists.", this.listTitle);
			}
		}
	}

	closeDialog() {
		Global.User.DebugMode &&
			console.log(this.componentName + ": trying to close dialog...");
	}

	dropzoneState(event: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": files hovered over drop zone...%O",
				event
			);
		this.dropzoneActive = event;
	}

	private uploadFiles(service: any) {
		service.fileUpload.nativeElement.value = "";
		Global.User.DebugMode &&
			console.log(
				service.componentName +
					": uploadFiles invoked --> this.files = %O",
				service.files
			);

		this.files.forEach((file) => {
			if (!file.imageKey && !file.inProgress) {
				Global.User.DebugMode &&
					console.log(
						service.componentName + ": uploadFiles: file = %O",
						file
					);
				this.uploadFile(file, this);
			}
		});
	}

	onClick() {
		var newlyUploadedFiles = [];
		const fileUpload = this.fileUpload.nativeElement;
		fileUpload.onchange = () => {
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				newlyUploadedFiles.push({
					data: file,
					inProgress: false,
					progress: 0,
				});
			}
			if (newlyUploadedFiles.length > 0) {
				newlyUploadedFiles.forEach((file: any) => {
					this.compareIncomingFileToExistingFiles(file);
				});
			}

			this.uploadFiles(this);
		};

		fileUpload.click();
	}

	uploadFile(file: any, service: any) {
		const formData = new FormData();
		console.log(
			service.componentName + ": uploadFile invoked --> file = %O",
			file
		);

		formData.append("file", file.data);
		file.inProgress = true;
		file.uploading = true;
		var filename = file.data.name;
		if (filename.indexOf(".") == -1) {
			filename = filename + ".png";
		}
		// var fileAlreadyExists = false;
		// this.files.forEach((file: any) => {
		// 	if (filename == file.data.name) {
		// 		fileAlreadyExists = true;
		// 	}
		// });
		// if (!fileAlreadyExists) {
		// 	console.log("file doesn't exist yet")
		// } else {
		// 	console.log("file already exists... it shouldn't be uploaded again...");
		// }

		file.name = filename;
		file.lastModifiedDate = new Date(file.data.lastModifiedDate);
		file.size = file.data.size / 1000;
		file = service.setFileImage(file);
		Global.User.DebugMode &&
			console.log(service.componentName + ": file = %O", file);

		service.fileUploadService
			.upload(formData)
			// .pipe(
			// map((event:any) => {
			// }),
			// catchError((error: HttpErrorResponse) => {
			// 	file.inProgress = false;
			// 	return of(`${file.data.name} upload failed.`);
			// }))
			.subscribe(
				(event: HttpEvent<any>) => {
					var Sent = event.type === HttpEventType.Sent;
					var DownloadProgress =
						event.type === HttpEventType.DownloadProgress;
					var UploadProgress =
						event.type === HttpEventType.UploadProgress;
					var Response = event.type === HttpEventType.Response;
					var ResponseHeader =
						event.type === HttpEventType.ResponseHeader;
					var User = event.type === HttpEventType.User;

					//Global.User.DebugMode && console.log(service.componentName + ": HttpEventType.UploadProgress = " + UploadProgress + ", HttpEventType.Response = " + Response + ", HttpEventType.Sent = " + Sent + ", HttpEventType.DownloadProgress = " + DownloadProgress + ", HttpEventType.ResponseHeader = " + ResponseHeader + ", HttpEventType.User = " + User);
					switch (event.type) {
						case HttpEventType.UploadProgress:
							//-- if we get this status, we're sending the file to the server and this is the upload progress to the server. --Kirk T. Sherer, January 21, 2021
							file.uploadProgress = Math.round(
								(event.loaded * 100) / event.total
							);
							Global.User.DebugMode &&
								console.log(
									service.componentName +
										": uploadProgress event -> %O",
									event
								);
							break;
						case HttpEventType.Response:
							//-- if we get this status, the server has the file and now we can add its image key to the list of image keys for the collection. --Kirk T. Sherer, January 21, 2021
							Global.User.DebugMode &&
								console.log(
									service.componentName +
										": uploadFile: event.type = " +
										event.type +
										", event -> %O",
									event
								);
							if (typeof event === "object") {
								if (event.body) {
									file.inProgress = false;
									Global.User.DebugMode &&
										console.log(
											service.componentName +
												": event.body = %O",
											event.body
										);
									var imageKeyExists =
										service.fileImageLibraryControlObject.imageKeys.firstOrDefault(
											(f: any) => {
												f.imageKey ==
													event.body.ImageKey;
											}
										);
									if (!imageKeyExists) {
										//if (!service.currentImageKeyList || (service.currentImageKeyList && service.currentImageKeyList.indexOf(event.body.ImageKey) == -1)) {
										if (
											service.currentImageKeyList ==
											undefined
										) {
											service.currentImageKeyList = "";
										}
										service.currentImageKeyList +=
											(service.currentImageKeyList != ""
												? ","
												: "") + event.body.ImageKey;
										file.name = event.body.Filename;
										var fileExtension =
											event.body.Filename.split(".")
												.last()
												.toLowerCase();
										if (
											service.iconmenu.indexOf(
												fileExtension.toLowerCase()
											) > -1
										) {
											//This is an icon file display. Assign the right one.
											file.iconFile =
												Global.imagesUrl +
												"assets/img/FileUpload/icon-" +
												fileExtension +
												".png";
										} else {
											file.iconFile = null;
										}

										file.lastModifiedDate =
											event.body.lastModifiedDate;
										file.size = event.body.OriginalSize;
										file.imageKey = event.body.ImageKey;
										var defaultVideoFileName =
											event.body.ImageKey +
											"." +
											fileExtension;
										file.isVideoServerImage =
											event.body.IsVideoServerImage;
										file.videoServerFilename = event.body
											.VideoServerFilename
											? event.body.VideoServerFilename
											: event.body.IsVideoServerImage
											? defaultVideoFileName
											: null;
										file.thumbnailImage = event.body
											.ThumbnailBase64
											? this.sanitizer.bypassSecurityTrustResourceUrl(
													"data:image/png;base64," +
														event.body
															.ThumbnailBase64
											  )
											: null;

										if (
											service.fileImageLibraryControlObject
										) {
											service.fileImageLibraryControlObject.addUploadFunction(
												file.imageKey
											);
										}
										service.setFileImage(file);
										console.log(
											"list after update from webAPI = %O",
											service.files
										);
										service.changeDetectorReference();
									} else {
										console.log(
											"ImageKey '" +
												event.body.ImageKey +
												"' exists in currentImageKeyList. service.currentImageKeyList = " +
												service.currentImageKeyList
										);
										console.log(
											"list after processing from webAPI = %O",
											service.files
										);
									}
								}
								return event;
							}
							break;
						case HttpEventType.DownloadProgress:
							//-- if we get this status, this is the progress from the server for the file being uploaded. --Kirk T. Sherer, January 21, 2021
							file.downloadProgress = Math.round(
								(event.loaded * 100) / event.total
							);
							Global.User.DebugMode &&
								console.log(
									service.componentName +
										": downloadProgress event -> %O",
									event
								);
							break;
						case HttpEventType.ResponseHeader:
							Global.User.DebugMode &&
								console.log(
									service.componentName +
										": uploadFile: event.type is not Response or UploadProgress.  It is 'HttpEventType.ResponseHeader', event -> %O",
									event
								);
							break;
						case HttpEventType.Sent:
							Global.User.DebugMode &&
								console.log(
									service.componentName +
										": uploadFile: event.type is not Response or UploadProgress.  It is 'HttpEventType.Sent', event -> %O",
									event
								);
							break;
						// default:
						// 	Global.User.DebugMode && console.log(service.componentName + ": uploadFile: event.type is not Response or UploadProgress.  It is '" + event.type + "', event -> %O", event);
						// 	break;
					}
				},
				(err: HttpErrorResponse) =>
					console.error(
						service.componentName +
							": " +
							`Error with ${file.name} file upload: ${err.message}`
					),
				() => {
					Global.User.DebugMode &&
						console.log(
							service.componentName +
								": '" +
								file.name +
								"' has been uploaded."
						);
					this.signalRCore.LogActivity("Uploaded file: " + file.name + " to the " + service.listTitle + " list.");						
				}
			);
	}

	changeDetectorReference() {
		this.changeDetector.detectChanges();
	}

	selectDropdownMenu() {}

	public editFile(file: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();

		Global.User.DebugMode &&
			console.log(this.componentName + ": Editing file = %O", file);
		event.stopPropagation();
		file.edit = true;
	}

	public viewFile(file: any) {
		if (!file.edit) {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						": trying to view the file... file = %O",
					file
				);
			var url = file.viewUrl;

			var intHSize = window.screen.availWidth * 0.7;
			var intVSize = window.screen.availHeight * 0.7;
			var intTop = (window.screen.availHeight - intVSize) / 2;
			var intLeft = (window.screen.availWidth - intHSize) / 2;
			var sngWindow_Size_Percentage = 0.85;

			var strFeatures = "";
			intHSize =
				window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
			intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
			intTop = (window.screen.availHeight - intVSize) / 2;
			intLeft = (window.screen.availWidth - intHSize) / 2;
			strFeatures =
				"top=" +
				intTop +
				",left=" +
				intLeft +
				",height=" +
				intVSize +
				",width=" +
				intHSize +
				", scrollbars=yes, resizable=yes, location=no";

			var SRWindow = window.open(url, "SRWindow", strFeatures);
			this.signalRCore.LogActivity("Viewing file " + file.data.name + ", url: " + file.viewUrl + ", ImageKey: " + file.imageKey);
		}
	}

	deleteFile(file: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();

		var service = this;
		Global.User.DebugMode &&
			console.log(
				service.componentName + ": service.files = %O",
				service.files
			);
		var listOfCurrentFiles = service.files;

		service.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success",
			},
			buttonsStyling: false,
		});
		Global.User.DebugMode &&
			console.log(
				service.componentName +
					": Marking " +
					file.name +
					" file for deletion..."
			);
		Global.User.DebugMode &&
			console.log(service.componentName + ": file = %O", file);

		service.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html:
					"You are trying to delete the '<strong>" +
					file.name +
					"</strong>' file from the <strong>" +
					service.listTitle +
					"</strong>. Cancel if you would rather keep it.",
				showCancelButton: true,
				confirmButtonText: "Delete File",
				cancelButtonText: "Cancel",
				reverseButtons: false,
			})
			.then((result: any) => {
				if (result.value) {
					// logic for deleting file goes here.
					if (service.fileImageLibraryControlObject) {
						service.fileImageLibraryControlObject.removeUploadFunction(
							file.imageKey
						);
						listOfCurrentFiles.forEach(function (item, index) {
							if (item.imageKey == file.imageKey) {
								listOfCurrentFiles.splice(index, 1);
								service.files = listOfCurrentFiles;
							}
						});
						this.rebuildCurrentImageKeyList();
					}
					service.toastr.info(
						"'" + file.name + "' file has been DELETED.",
						service.listTitle
					);
					this.signalRCore.LogActivity("Deleted file: " + file.data.name + ", ImageKey: " + file.imageKey + " from the " + service.listTitle + " list.");
				} else {
					service.toastr.info(
						"'" + file.name + "' has NOT been deleted.",
						service.listTitle
					);
					this.signalRCore.LogActivity("Cancelled deletion of the file: " + file.data.name + ", ImageKey: " + file.imageKey + " from the " + service.listTitle + " list.");
				}
			});
	}

	closeWindow(): void {
		this.dialogRef.close();
	}
	removeAllFiles(event: Event) {
		event.stopPropagation();
		event.preventDefault();

		var service = this;
		Global.User.DebugMode &&
			console.log(
				service.componentName + ": service.files = %O",
				service.files
			);
		var listOfCurrentFiles = service.files;

		service.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success",
			},
			buttonsStyling: false,
		});
		Global.User.DebugMode &&
			console.log(
				service.componentName + ": Trying to remove all files..."
			);
		
		var listOfImageKeys = listOfCurrentFiles.select((f:any) => { return f.imageKey }).toArray().join(", ");
				
		service.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html:
					"You are trying to remove <strong>ALL</strong> files from the <strong>" +
					service.listTitle +
					"</strong>. Cancel if you would rather keep them.",
				showCancelButton: true,
				confirmButtonText: "Remove All Files",
				cancelButtonText: "Cancel",
				reverseButtons: false,
			})
			.then((result: any) => {
				if (result.value) {
					// logic for deleting files goes here.
					listOfCurrentFiles.forEach(function (item, index) {
						if (service.fileImageLibraryControlObject) {
							service.fileImageLibraryControlObject.removeUploadFunction(
								item.imageKey
							);
						}
					});

					service.files = [];
					service.fileImageLibraryControlObject.imageKeys = [];
					this.rebuildCurrentImageKeyList();
					service.toastr.info(
						"ALL files have been REMOVED.",
						service.listTitle
					);
					this.signalRCore.LogActivity("Removed ALL files from the " + service.listTitle + " list. Image Keys removed: " + listOfImageKeys);
				} else {
					service.toastr.info(
						"No files have been removed.",
						service.listTitle
					);
					this.signalRCore.LogActivity("Cancelled removing all files from the " + service.listTitle + " list. Image Keys in this list: " + listOfImageKeys);
				}
			});

		service.showSettings = false; //-- this closes the menu for deleting all files.
	}

	rebuildCurrentImageKeyList() {
		var imageKeyArray = this.fileImageLibraryControlObject.imageKeys;
		this.currentImageKeyList = "";
		var numberOfImageKeys = 0;
		imageKeyArray.forEach((record: any) => {
			this.currentImageKeyList += record.ImageKey;
			numberOfImageKeys++;
			if (numberOfImageKeys < imageKeyArray.length) {
				this.currentImageKeyList += ",";
			}
		});

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": this.currentImageKeyList = " +
					this.currentImageKeyList
			);
	}

	loadFileList() {
		var service = this;
		if (
			this.fileImageLibraryControlObject &&
			this.fileImageLibraryControlObject.imageKeys &&
			this.fileImageLibraryControlObject.imageKeys.length > 0
		) {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						": this.fileImageLibraryControlObject.imageKeys = %O",
					this.fileImageLibraryControlObject.imageKeys
				);
			var imageKeyArray = this.fileImageLibraryControlObject.imageKeys;
			this.rebuildCurrentImageKeyList();
			var reordinal = 0;

			this.fileImageLibraryData = [];
			// this.fileUploadService.list(service.currentImageKeyList).then((data:any) => {
			// 	console.log("data = %O", data);
			// });

			this.fileUploadService
				.listAsPromise(service.currentImageKeyList)
				.then((data: any) => {
					console.log("data = %O", data);
					if (typeof data === "object" && data.body != undefined) {
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": listAsPromise -> data.body = %O",
								data.body
							);
						if (data.body.value != undefined) {
							var unsortedList = data.body.value;
							var listOfFiles = unsortedList
								.orderBy((data: any) => {
									return data.CreateDate;
								})
								.toArray();

							var countOfFiles: number = 1;
							service.files = [];
							listOfFiles.forEach((f: any) => {
								Global.User.DebugMode &&
									console.log(
										service.componentName +
											": ---> file " +
											countOfFiles +
											" = %O",
										f
									);
								service
									.constructFileRecordForList(f)
									.then((file: any) => {
										service.files.push(file);
										//Global.User.DebugMode && console.log(service.componentName + ": this.files = %O", service.files);
									});
								countOfFiles++;
							});
							Global.User.DebugMode &&
								console.log(
									service.componentName + ": this.files = %O",
									service.files
								);
						}
					}
				});
			// this.fileUploadService
			// 	.list(service.currentImageKeyList)
			// 	.pipe(
			// 		map((event) => {
			// 			Global.User.DebugMode && console.log(service.componentName + ": ByImageKeyList: event -> %O", event);
			// 			return event;
			// 		}),
			// 		catchError((error: HttpErrorResponse) => {
			// 			return of(`${error} list failed.`);
			// 		})
			// 	)
			// 	.subscribe((event: any) => {
			// 		if (typeof event === "object" && event.body != undefined) {
			// 			Global.User.DebugMode && console.log(service.componentName + ": event.body = %O", event.body);
			// 			if (event.body.value != undefined) {
			// 				var unsortedList = event.body.value;
			// 				var listOfFiles = unsortedList
			// 					.orderBy((data: any) => {
			// 						return data.CreateDate;
			// 					})
			// 					.toArray();

			// 				var countOfFiles: number = 1;
			// 				service.files = [];
			// 				listOfFiles.forEach((f: any) => {
			// 					Global.User.DebugMode && console.log(service.componentName + ": ---> uploaded file = %O", f);
			// 					service.constructFileRecordForList(f).then((file: any) => {
			// 						service.files.push(file);
			// 						Global.User.DebugMode && console.log(service.componentName + ": this.files = %O", service.files);
			// 					});
			// 					countOfFiles++;
			// 				});
			// 				Global.User.DebugMode && console.log(service.componentName + ": this.files = %O", service.files);
			// 			}
			// 		}
			// 	});
		} else {
			service.files = [];
		}
		this.changeDetectorReference();
	}

	private async constructFileRecordForList(f: any) {
		const formData = new FormData();
		var file: any = {};
		file.data = {};
		file.data.lastModified =
			this.utilityService.JavascriptDateTimeInMilliseconds(f.CreateDate);
		file.data.lastModifiedDate = new Date(f.CreateDate);
		file.data.name = f.Filename;
		file.data.description = f.Description;
		file.data.size = f.OriginalSize;
		file.data.type = "";
		file.data.webkitRelativePath = "";

		formData.append("file", file.data);
		file.inProgress = false;
		file.name = f.Filename;
		file.lastModifiedDate = new Date(f.CreateDate);
		file.size = f.OriginalSize;
		file.imageKey = f.ImageKey;
		var fileExtension = f.Filename.split(".").last().toLowerCase();
		var defaultVideoFileName = f.ImageKey + "." + fileExtension;
		file.isVideoServerImage =
			f.IsVideoServerImage == null ? false : f.IsVideoServerImage;
		file.videoServerFilename = f.VideoServerFilename
			? f.VideoServerFilename
			: f.IsVideoServerImage
			? defaultVideoFileName
			: null;
		file.thumbnailImage = f.ThumbnailBase64
			? this.sanitizer.bypassSecurityTrustResourceUrl(
					"data:image/png;base64," + f.ThumbnailBase64
			  )
			: null;
		file = this.setFileImage(file);
		Global.User.DebugMode &&
			console.log(this.componentName + ": file = %O", file);
		return file;
	}

	updateFileName(file: any, event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": Edit file change result = %O",
				event
			);
		var changedObject: any = event.target;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": changedObject = %O",
				changedObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": changedObject.value = " +
					changedObject.value
			);
		var newFileName = changedObject.value;
		var originalFileName = file.data.name;
		var originalExtension = originalFileName
			.split(".")
			.last()
			.toLowerCase();
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": newFileName = " +
					newFileName +
					", originalFileName = " +
					originalFileName +
					", originalExtension = " +
					originalExtension
			);

		if (newFileName.indexOf(".") == -1) {
			newFileName = newFileName + "." + originalExtension; //-- if user removed the extension, put it back. --Kirk T. Sherer, February 5, 2021.
		}

		if (newFileName != originalFileName) {
			file.data.name = newFileName;
			var sqlStatement =
				"API.FileImage_UpdateFileName @ImageKey='" +
				file.imageKey +
				"', @FileName='" +
				newFileName +
				"'";
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								": " +
								sqlStatement +
								" data = %O",
							data
						);
					if (data && data.length > 0) {
						this.utilityService.showToastMessageShared({
							type: "info",
							message:
								"The '" +
								originalFileName +
								"' file has been renamed to '" +
								newFileName +
								"'.",
							title: this.listTitle,
						});
						//   this.toastr.info("The '" + originalFileName + "' file has been renamed to '" + newFileName + "'.", this.listTitle);
					}
				});
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message: "File '" + originalFileName + "' remains unchanged.",
				title: this.listTitle,
			});
			//   this.toastr.info("File '" + originalFileName + "' remains unchanged.", this.listTitle);
		}
		file.edit = false;
	}

	updateFileDescription(file: any, event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": updateFileDescription invoked... event = %O",
				event
			);
		var changedObject: any = event.target;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": changedObject = %O",
				changedObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": changedObject.value = " +
					changedObject.value
			);
		var newFileDescription = changedObject.value;
		var originalFileDescription = file.data.description;

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": newFileDescription = " +
					newFileDescription +
					", originalFileDescription = " +
					originalFileDescription
			);

		if (newFileDescription != originalFileDescription) {
			file.data.description = newFileDescription;
			var sqlStatement =
				"API.FileImage_UpdateFileDescription @ImageKey='" +
				file.imageKey +
				"', @Description='" +
				newFileDescription +
				"'";
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								": " +
								sqlStatement +
								" data = %O",
							data
						);
					if (data && data.length > 0) {
						this.utilityService.showToastMessageShared({
							type: "info",
							message:
								"The '" +
								file.data.name +
								"' file description has been updated.",
							title: this.listTitle,
						});
						//   this.toastr.info("The '" + file.data.name + "' file description has been updated.");
					}
				});
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message:
					"The '" +
					file.data.name +
					"' file description remains unchanged.",
				title: this.listTitle,
			});
			//   this.toastr.info("The '" + file.data.name + "' file description remains unchanged.", this.listTitle);
		}
		file.edit = false;
	}

	private setFileImage(file: any) {
		Global.User.DebugMode &&
			console.log(this.componentName + ": setFileImage: file = %O", file);
		var includeThumbnail = false;
		var filename = file.data.name;

		if (filename == "Pasted Image") {
			includeThumbnail = true;
		} else if (filename.indexOf(".") == -1) {
			//-- user didn't name the file with a file extension.  Add the appropriate file extension so we can get the correct thumbnail picture, if applicable. --Kirk T. Sherer, February 5, 2021.
			if (file.IsVideoServerImage) {
				filename = file.videoServerFilename;
			} else {
				filename = filename + ".png";
			}
		}

		var fileExtension = filename.split(".").last().toLowerCase();
		// Global.User.DebugMode && console.log("fileExtension = " + fileExtension);

		if (
			fileExtension == "jpg" ||
			fileExtension == "png" ||
			fileExtension == "jpeg" ||
			fileExtension == "gif" ||
			fileExtension == "bmp" ||
			fileExtension == "svg"
		) {
			includeThumbnail = true;
		}
		// Global.User.DebugMode && console.log(this.componentName + ": this.iconmenu = " + this.iconmenu);
		// Global.User.DebugMode && console.log(this.componentName + ": this.iconmenu.indexOf(fileExtension.toLowerCase()) = " + this.iconmenu.indexOf(fileExtension.toLowerCase()));

		//Assign an icon for the file
		if (this.iconmenu.indexOf(fileExtension.toLowerCase()) > -1) {
			//This is an icon file display. Assign the right one.
			file.iconFile =
				Global.imagesUrl +
				"assets/img/FileUpload/icon-" +
				fileExtension +
				".png";
		} else {
			file.iconFile = null;
		}

		// Global.User.DebugMode && console.log(this.componentName + ": file.iconFile = " + file.iconFile);

		if (file.imageKey != null) {
			if (file.isVideoServerImage) {
				var url =
					this.videoKeyURL +
					file.videoServerFilename +
					"?accessToken=" +
					encodeURIComponent(this.accessToken);
				file.thumbnailUrl = null;
				file.viewUrl = url;
			} else {
				file.thumbnailUrl =
					this.dataService.imageKeyURL + file.imageKey;
				file.viewUrl = file.thumbnailUrl;
			}
		}

		// Global.User.DebugMode && console.log(this.componentName + ": file.thumbnailUrl = " + file.thumbnailUrl);

		return file;
	}

	pasteTargetOnClickEvent(event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": pasteTargetOnClickEvent: event = %O",
				event
			);
		//-- save all modified descriptions in the file upload list here. --Kirk T. Sherer, April 27, 2020.
	}

	traverseFileTree(fileObject: any, path?: string) {
		//-- use this function whenever you can figure out how to use the createReader and readEntries functions on a directory object.--Kirk T. Sherer, May 21, 2020.
		var service = this;
		path = path || "";
		Global.User.DebugMode &&
			console.log(
				service.componentName + ": traverseFileTree fileObject = %O",
				fileObject
			);

		if (fileObject.data && fileObject.data?.type != "") {
			////Global.User.DebugMode && console.log(this.componentName + ": Adding to queue in new routine");
			service.uploadFile(fileObject, service);
		}
		// else {
		// 	// Get folder contents
		// 	var dirReader = fileObject.data.createReader();
		// 	dirReader.readEntries(function (entries) {
		// 		for (var i = 0; i < entries.length; i++) {
		// 			service.traverseFileTree(entries[i], path + fileObject.data.name + "/");
		// 		}
		// 	});
		// }
	}
}
