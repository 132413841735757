import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";

import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { environment, environment as Test } from "../environments/environment.test";
import { environment as Beta } from "../environments/environment.beta";
import { environment as Prod } from "../environments/environment.prod";

import { initializeLinq, IEnumerable, ArrayEnumerable } from "linq-to-typescript";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { IUser } from "projects/shared-lib/src/lib/_models/user.model";
import { IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService } from "ngx-ui-tour-md-menu";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";

declare global {
	interface Array<T> extends IEnumerable<T> {
		concat(items: IEnumerable<T>): IEnumerable<T>;
		concat(...items: Array<ReadonlyArray<T>>): ArrayEnumerable<T>;
		concat(...items: Array<T | ReadonlyArray<T>>): ArrayEnumerable<T>;
	}
	interface Uint8Array extends IEnumerable<number> {}
	interface Uint8ClampedArray extends IEnumerable<number> {}
	interface Uint16Array extends IEnumerable<number> {}
	interface Uint32Array extends IEnumerable<number> {}
	interface Int8Array extends IEnumerable<number> {}
	interface Int16Array extends IEnumerable<number> {}
	interface Int32Array extends IEnumerable<number> {}
	interface Float32Array extends IEnumerable<number> {}
	interface Float64Array extends IEnumerable<number> {}
	interface Map<K, V> extends IEnumerable<[K, V]> {}
	interface Set<T> extends IEnumerable<T> {}
	interface String extends IEnumerable<string> {}
}

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
	loading = false;

	constructor(private router: Router, private utilityService: UtilityService, public dataService: DataService, public tourService: TourService) {
		initializeLinq();
		
		Global.Initial = {
			href: window.location.href, //-- need full href to determine if we can travel to this route later after the user has been authenticated.
			route: window.location.href.indexOf("#") > -1 ? window.location.href.split("#").last() : null, //-- need the route so we can travel there after the user has been authenticated. --Kirk T. Sherer, November 17, 2023.
			baseUrl: window.location.href.indexOf("#") > -1 ? window.location.href.split("#").first() : window.location.href
		};
		
		if (document.URL.indexOf("localhost") > 0) {
			Global.Initial = {
				href: "https://test.iopspro.com/", //-- need full href to determine if we can travel to this route later after the user has been authenticated.
				route: window.location.href.indexOf("#") > -1 ? window.location.href.split("#").last() : null, //-- need the route so we can travel there after the user has been authenticated. --Kirk T. Sherer, November 17, 2023.
				baseUrl: "https://test.iopspro.com/"
			}
		}
		
		if (Global.iOPSLogoImage.indexOf("http") < 0) { //-- only set these images when we first come into the application.
			Global.LogosForApplicationTheme.Dark.iOPSLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Dark.iOPSLogo;
			Global.LogosForApplicationTheme.Dark.iOPSTinyLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Dark.iOPSTinyLogo;
			Global.LogosForApplicationTheme.Dark.CompanyTinyLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Dark.CompanyTinyLogo;
			Global.LogosForApplicationTheme.Dark.CompanyLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Dark.CompanyLogo;
			
			Global.LogosForApplicationTheme.Light.iOPSLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Light.iOPSLogo;
			Global.LogosForApplicationTheme.Light.iOPSTinyLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Light.iOPSTinyLogo;
			Global.LogosForApplicationTheme.Light.CompanyTinyLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Light.CompanyTinyLogo;
			Global.LogosForApplicationTheme.Light.CompanyLogo = Global.Initial.baseUrl + Global.LogosForApplicationTheme.Light.CompanyLogo;
	
			Global.iOPSLogoImage = Global.Initial.baseUrl + Global.iOPSLogoImage;
			Global.iOPSTinyLogoImage = Global.Initial.baseUrl + Global.iOPSTinyLogoImage;
			Global.CompanyTinyLogoImage = Global.Initial.baseUrl + Global.CompanyTinyLogoImage;
			Global.CompanyLogoImage = Global.Initial.baseUrl + Global.CompanyLogoImage;
			Global.imagesUrl = Global.Initial.baseUrl + Global.imagesUrl;
			Global.Data.thingApiUrl = Global.Data.thingApiIsLocal == true ? "http://localhost:5044" : Global.Initial.baseUrl + Global.Data.thingApiUrl;
		}

		var globalObject: IGlobal = Global;
		console.log("Global = %O", globalObject);
		router.events.subscribe((routerEvent: Event) => {
			if (this.router.url != "/authentication" && this.router.url != "/user/authentication" && this.router.url != "/user/login" && this.router.url != "/user/logout" && this.router.url != "/" && this.router.url != "/layout/user-settings" && localStorage.getItem("currentUser")) {
				this.checkRouterEvent(routerEvent);
				if (Global?.User?.currentUser?.Id != null) {
					var lastVisitedRoute: string = localStorage.getItem("lastVisitedRoute");
					var newlyVisitedRoute: string = this.router.url;
					localStorage.setItem("lastVisitedRoute", this.router.url);
					localStorage.setItem("lastVisitedRouteTitle", Global.CurrentMenuItem);

					if (lastVisitedRoute != newlyVisitedRoute) {
						//-- update SQL Server so we have the last route this user visited if we need to rebuild the local storage, but only if the routes are different.  No need to
						//-- continually update SQL Server with the same route. --Kirk T. Sherer, May 11, 2023.
						var sql: string = "Security.User_UpdateLastVisitedRoute @UserId=" + Global.User.currentUser.Id + ", @LastVisitedRoute='" + this.router.url + "', @LastVisitedRouteTitle='" + Global.CurrentMenuItem + "'";
						this.dataService.SQLActionAsPromise(sql).then((data: any) => {
							console.log("Route logged. URL: " + this.router.url);
						});
					}
				}
			} else {
				this.loading = false;
			}
			// console.log("this.router.url: " + this.router.url + ", loading: " + this.loading);
		});

		//Global.Data.dataServerIsLocal = false; //--only set this to true if you're testing a local version of the webAPI. 

		

		var environmentName = document.URL.indexOf("localhost") > 0 || document.URL.indexOf("test") > 0 ? (Global.Application?.Environment?.Name != null ? Global.Application.Environment.Name : "test") : document.URL.indexOf("beta") > 0 ? "beta" : "prod";
		var environmentId = null;
		var version = null;
		switch (environmentName) {
			case "test":
				environmentId = Test.environmentId;
				break;
			case "beta":
				environmentId = Beta.environmentId;
				break;
			case "prod":
				environmentId = Prod.environmentId;
				break;
		}

		Global.Application = {
			Environment: {
				Name: environmentName,
				Id: environmentId,
				Version: version
			},
			Type: "desktop"
		};

		console.log("app-component: Global = %O", Global);
	}

	checkRouterEvent(routerEvent: Event): void {
		if (routerEvent instanceof NavigationStart) {
			this.loading = true;
		} else {
			if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
				this.loading = false;
			}
		}
	}

	gm_authFailure() {
		console.error("Google Maps authentication error fired. Please investigate.");
	}

	ngOnInit() {
		var passwordToken = this.utilityService.GetQuerystringParameterByName("pwt");
		if (passwordToken) {
			console.log("passwordToken = " + passwordToken);
			Global.passwordToken = passwordToken;
			console.log("Global.passwordToken = " + Global.passwordToken);
			this.router.navigate(["/forgot-password/" + passwordToken]);
		}
	}
}
