import { QuestionBase } from './question-base';

export class SliderQuestion extends QuestionBase<string> {
  controlType = 'slider';
  type = 'boolean';

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
