import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';



@Directive({
  selector: "[appCustomProgressbar]",
})
export class CustomProgressbarDirective implements AfterViewInit {
  @Input() appProgressBarColor;
  styleEl: HTMLStyleElement = document.createElement("style");
  SavedColor: string;

  //generate unique attribule which we will use to minimise the scope of our dynamic style

  constructor(private el: ElementRef) {
    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.appendChild(this.styleEl);
  }

  ngAfterViewInit() {
    this.updateColor();
  }

  updateColor(): void {
    // update dynamic style with the uniqueAttr
    this.SavedColor = "";

    switch (Global.User.MenuColor) {
      case "dark-gray":
        this.SavedColor = "#696868";
        break;
      case "dark-red":
        this.SavedColor = "#7d0f0f";
        break;
      case "dark-blue":
        this.SavedColor = "#A5B6F3";
        break;
      case "dark-green":
        this.SavedColor = "#075013";
        break;
      case "light-gray":
        this.SavedColor = "#A4A3A3";
        break;
      case "light-red":
        this.SavedColor = "#FA5C5C";
        break;
	case "fire-red":
		this.SavedColor = "#Ce0B15"
		break;
      case "light-blue":
        this.SavedColor = "#A5B6F3";
        break;
      case "tan":
        this.SavedColor = "#D2B48C";
        break;

      case "yellow-gold":
        this.SavedColor = "#EDC025";
        break;
      case "bright-green":
        this.SavedColor = "#C0F10D";
        break;
    }

    this.styleEl.innerText = `
    .mat-progress-bar-fill::after {
      background-color: ${this.SavedColor};
    }
  `;
  }
}
