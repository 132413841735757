<footer class="footer">
	<div class="container-fluid">
		<ul class="nav">
			<li class="nav-item">
				<a class="nav-link" style="text-transform: none" href="https://oshkoshaerotech.com"> Oshkosh AeroTech </a>
			</li>
		</ul>

		<div class="copyright">
			&copy; {{ test | date : "yyyy" }}
			<a href="https://www.oshkoshcorp.com/" target="_blank"> Oshkosh Corporation </a>
			<span  style="cursor: pointer; font-size: 0.6rem !important">{{ environmentName != undefined && environmentName != "prod" ? environmentName + ": " : "" }}v{{ appVersion }}</span>
			<span *ngIf="testEnvironment" style="padding-left: 5px; font-size: 0.6rem !important">data: {{ global.Data.footerDataSource }}</span>
			<span *ngIf="global.User.isAdmin && global.User.DebugMode == true" (click)="showSignalRTest()" style="cursor: pointer; padding-left: 5px; font-size: 0.6rem !important">signalR: {{hubConnectionState.toLowerCase()}}, count={{ global.SignalR.countOfObservations == undefined || global.SignalR.countOfObservations == null || signalRCore.hubConnection?.state.toLowerCase() != "connected" || signalRCounterIsNotNumeric == true || global.SignalR.countOfObservations < 1 || global.SignalR.countOfObservations.toString() == 'NaN' ? "0" : (global.SignalR.countOfObservations | number : "1.0" : "en-US") }}</span> 
			<span *ngIf="global.User.isAdmin && global.User.DebugMode == false" (click)="showSignalRTest()" style="cursor: pointer; padding-left: 5px; font-size: 0.6rem !important">signalR: {{hubConnectionState.toLowerCase()}}</span>
		</div>
	</div>
</footer>
