import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartViewComponent } from './chart-view.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ChartsModule, SparklineModule } from '@progress/kendo-angular-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [ChartViewComponent],
	imports: [
		CommonModule,
		ProgressBarModule,
		ChartsModule,
		SparklineModule,
		MatTooltipModule,
		ClipboardModule,
		NgbModule,
	],
	exports: [ChartViewComponent],
})
export class ChartViewModule { }
