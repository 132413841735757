import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularResizeEventModule } from 'angular-resize-event';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { PipeModule } from "../../_helpers/pipes/pipe.module";


@NgModule({
	declarations: [NavigationComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		DynamicFormModule,
		DragDropModule,
		AngularResizeEventModule,
        CdkAccordionModule,
        PipeModule
	],
	exports: [NavigationComponent]
})
export class NavigationModule { }
