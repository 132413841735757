import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialInputComponent } from './material-input.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [MaterialInputComponent],
	imports: [CommonModule, MatInputModule, FormsModule],
	exports: [MaterialInputComponent],
})
export class MaterialInputModule {}
