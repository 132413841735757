import {
	Component,
	OnInit,
	Input,
	OnDestroy,
	InjectionToken,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";

const misc: any = {
	sidebar_mini_active: true,
};

import { ActivatedRoute, Router } from "@angular/router";

import { LocationStrategy } from "@angular/common";

import { Subject, BehaviorSubject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IMenuItem } from "projects/shared-lib/src/lib/_models/menuItem.model";
import { DashboardEditComponent } from "projects/shared-lib/src/lib/components/dashboard-edit/dashboard-edit.component";
import { TestObservationDelayComponent } from "projects/shared-lib/src/lib/components/test-observation-delay/test-observation-delay.component";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, OnDestroy {
	public imageUrl: string;
	public static dashboardUpdated$: Subject<any> = new Subject();
	menuItems: any[];
	newMenu: any[];
	isCollapsed: boolean;
	public iOPSLogoImage: string;
	public iOPSTinyLogoImage: string;
	public sidebarMiniSelected: boolean;
	isAdmin = false;
	myRoutes: IMenuItem[];
	userDashboardsAsMenuItems: IMenuItem[];
	baseHref: string;
	numberOfDashboards = 0;
	dashboardListChangedSubscriber: any;
	private sidebarMiniChanged$: any;
	private iOPSLogoChanged$: any;
	private checkUserLogin$: any;
	private needToBuildMenu$: any;

	private dashboardCreatedFromModal$: any;
	private path: string;
	private firstDashboard: any;

	public currentUrl: string = document.URL;
	public global: IGlobal = Global;

	constructor(
		private dataService: DataService,
		private activatedRoute: ActivatedRoute,
		private locationStrategy: LocationStrategy,
		private router: Router,
		public dialog: MatDialog,
		private ref: ChangeDetectorRef,
		private utilityService: UtilityService
	) {
		SidebarComponent.dashboardUpdated$.subscribe((res) => {
			this.getDashboardsForUserAgainAndBuildMenu();
		});
	}

	ngOnInit() {
		this.imageUrl =
			document.URL.indexOf("localhost") > 0
				? "https://localhost:4200"
				: document.URL.indexOf("test") > 0
				? "https://test.iopspro.com/"
				: document.URL.indexOf("beta") > 0
				? "https://beta.iopspro.com"
				: "https://iopspro.com/";
		this.iOPSLogoImage = Global.iOPSLogoImage;
		this.iOPSTinyLogoImage = Global.iOPSLogoImage;
		this.sidebarMiniSelected = Global.User.SidebarMini;
		this.dashboardCreatedFromModal$ =
			this.dataService.dashboardCreatedFromModal$.subscribe(() => {
				this.getDashboardsForUserAgainAndBuildMenu();
			});
		this.iOPSLogoChanged$ = this.dataService.iOPSLogoChanged$.subscribe(
			(image: any) => {
				this.iOPSLogoImage = Global.iOPSLogoImage;
				this.iOPSTinyLogoImage = Global.iOPSTinyLogoImage;
			}
		);

		this.sidebarMiniChanged$ =
			this.dataService.sidebarMiniChanged$.subscribe(
				(sidebar: boolean) => {
					Global.User.DebugMode &&
						console.log(
							"sidebarMiniChanged$ invoked... sidebar: " +
								sidebar +
								", Global.User.SidebarMini = " +
								Global.User.SidebarMini
						);
					this.sidebarMiniSelected = Global.User.SidebarMini;
				}
			);

		// this.menuItems = ROUTES.filter(menuItem => menuItem);
		this.dashboardListChangedSubscriber =
			this.dataService.dashboardList$.subscribe((data: any) => {
				Global.User.needToBuildMenu = true;
				this.dataService.needToBuildMenu$.next(
					Global.User.needToBuildMenu
				);
				//Global.User.DebugMode && console.log("Global.User.currentUser.Dashboards = %O", Global.User.currentUser.Dashboards);
				//Global.User.DebugMode && console.log("this.dashboardListChangedSubscriber data = %O", data);
				// this.getDashboardsForUserAgainAndBuildMenu();
			});
		this.checkUserLogin$ = new BehaviorSubject(Global.User.isLoggedIn);
		this.checkUserLogin$.subscribe(
			(isLoggedIn: boolean) => {
				if (isLoggedIn) {
					if (!Global.User.currentUser.Dashboards) {
						//Global.User.DebugMode && console.log( "Global.User.currentUser.Dashboards don't exist... have to get them again..." );
						this.getDashboardsForUserAgainAndBuildMenu();
					} else {
						//Global.User.DebugMode && console.log( "Global.User.currentUser.Dashboards exist... building menu..." );
						this.buildMenu();
					}
				}
			},
			(err) => console.error(`Error checking user login: ${err}`)
		);
		this.needToBuildMenu$ = this.dataService.needToBuildMenu$.subscribe(
			(needToBuildMenu: boolean) => {
				if (needToBuildMenu) {
					this.getDashboardsForUserAgainAndBuildMenu();
				}
			},
			(err) => console.error(`Error in needToBuildMenu$: ${err}`)
		);
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log("ngOnDestroy invoked...");
		if (this.iOPSLogoChanged$) {
			this.iOPSLogoChanged$.unsubscribe();
		}
		if (this.sidebarMiniChanged$) {
			this.sidebarMiniChanged$.unsubscribe();
		}
		if (this.checkUserLogin$) {
			this.checkUserLogin$.unsubscribe();
		}
		if (this.needToBuildMenu$) {
			this.needToBuildMenu$.unsubscribe();
		}
	}

	getDashboardsForUserAgainAndBuildMenu() {
		Global.User.needToBuildMenu = true;
		this.buildMenu();
	}

	addNewDashboardModal(): void {
		const dashboardEditModal = this.dialog.open(DashboardEditComponent, {
			data: 0,
			panelClass: "no-overflow",
		});

		dashboardEditModal.afterClosed().subscribe((result) => {
			//Global.User.DebugMode && console.log("The modal was closed");
			//if not submitted, then result is undefined
			//Global.User.DebugMode && console.log("addNewDashboardModal result = %O", result);
			if (result !== undefined) {
				this.getDashboardsForUserAgainAndBuildMenu();

				Global.CurrentMenuItem = result[0].Name;
				this.router.navigate(["layout/dashboard/" + result[0].Id]);
			} else {
				Global.CurrentMenuItem = "Welcome Screen";
			}
		});
	}

	startTestObservationDelay(): void {
		const dialogRef = this.dialog.open(TestObservationDelayComponent, {
			width: innerWidth > 1920 ? "36vw" : "46vw",
			height: "49vh",
		});
		dialogRef.afterClosed().subscribe((result) => {
			Global.User.DebugMode && console.log("The dialog was closed");
		});
	}

	buildMenu() {
		if (Global.User.currentUser && Global.User.needToBuildMenu) {
			Global.User.DebugMode && console.log("layout: Building menu...");
			this.userDashboardsAsMenuItems = [];

			// -- demo dashboard to display what the original purchased template looked like.  This can be removed once the site is going live. --Kirk T. Sherer, February 4, 2020.

			Global.User.DebugMode &&
				console.log(
					"Global.User.currentUser.Dashboards = %O",
					Global.User.currentUser.Dashboards
				);
			// var roles = Global.User.currentUser.Role.toArray();
			console.log("roles = %O", Global.User.currentUser.Role);

			// var isSiteAdministrator = Global.User.currentUser.Role.toArray().firstOrDefault((role: any) => {
			// 	return role.Administrator == true;
			// });
			// var isAuthorized = isSiteAdministrator == true;

			const dashboards = Global.User.currentUser.Dashboards;

			if (dashboards.length > 0) {
				const dashboards = Global.User.currentUser.Dashboards;
				//Global.User.DebugMode && console.log('sidebar component - dashboards = %O', dashboards);
				let countDashboards = 0;
				dashboards.forEach((dashboard: any) => {
					if (!dashboard.IsMobile) {
						//-- since this is the desktop version, only show the desktop dashboards.  Users can view the mobile-specific dashboards by hitting the play button on the dashboard list. --Kirk T. Sherer, September 15, 2020.
						//Global.User.DebugMode && console.log("dashboard = %O", dashboard);
						let newDashboard: any;
						countDashboards++;
						if (countDashboards == 1) {
							this.firstDashboard = dashboard; //-- this should be the first dashboard loaded when building the menu. --Kirk T. Sherer, March 23, 2021.
						}
						newDashboard = {};
						newDashboard.path = "dashboard";
						newDashboard.id = dashboard.Id;
						newDashboard.smallTitle = dashboard.Name
							? dashboard.Name.substr(0, 3)
							: countDashboards;
						newDashboard.title = dashboard.Name;
						newDashboard.type = "link";

						if (countDashboards <= 10) {
							this.userDashboardsAsMenuItems.push(newDashboard);
						}
					}
				});
				//Global.User.DebugMode && console.log('countDashboards = ' + countDashboards);
				this.numberOfDashboards = countDashboards;
			} else {
				this.numberOfDashboards = 0;
			}

			//Global.User.DebugMode && console.log("numberOfDashboards = " + this.numberOfDashboards);

			if (this.numberOfDashboards > 0) {
				// -- give user list of dashboards as a selection so they can choose which ones should be displayed on the menu. --Kirk T. Sherer, February 4, 2020.
				let dashboardListLink: any;
				dashboardListLink = {};
				dashboardListLink.path = "dashboard-list";
				dashboardListLink.smallTitle = "List";
				dashboardListLink.title = "My Dashboard List";
				dashboardListLink.type = "link";
				dashboardListLink.func = null;
				this.userDashboardsAsMenuItems.push(dashboardListLink);
			} else {
				// -- must have this 'add new dashboard' link for new users that don't have any dashboards yet. --Kirk T. Shere, April 21, 2020.
				//Global.User.DebugMode && console.log("user has no dashboards...");
				let demoDashboard: any;
				demoDashboard = {};
				demoDashboard.path = "dashboard-demo";
				demoDashboard.smallTitle = "Welcome";
				demoDashboard.title = "Welcome Screen";
				demoDashboard.type = "link";
				this.userDashboardsAsMenuItems.push(demoDashboard);

				var newDashboard: any;
				newDashboard = {};
				newDashboard.path = "dashboard-edit";
				newDashboard.smallTitle = "+";
				newDashboard.title = "<<< Add New Dashboard >>>";
				newDashboard.type = "func";
				newDashboard.func = "addNewDashboardModal";
				this.userDashboardsAsMenuItems.push(newDashboard);
			}


			if (Global.User.isAdmin) {
				this.myRoutes = [
					{
						path: "/layout",
						title: "Dashboard",
						type: "sub",
						icontype: "tim-icons icon-chart-pie-36",
						collapse: "dashboard",
						isCollapsed: false,
						smallTitle: "Dashbd",
						children: this.userDashboardsAsMenuItems,
					},
					{
						path: "/layout",
						title: "Advanced Modules",
						type: "sub",
						icontype: "tim-icons icon-molecule-40",
						collapse: "advanced",
						isCollapsed: true,
						children: [
							{
								path: "tables",
								title: "System Tables",
								type: "link",
								smallTitle: "Sys",
							},
							{
								path: "canvas-tabs-list",
								smallTitle: "Tabs",
								title: "Canvas Tabs List",
								type: "link",
							},
							{
								path: "testObs",
								title: "Test Observation Delay",
								type: "func",
								smallTitle: "TstObs",
								func: "startTestObservationDelay",
							},
							{
								path: "potd",
								title: "POTD",
								type: "link",
								smallTitle: "POTD",
							},
							{
								path: "user-engagement-report",
								title: "User Engagement Report",
								type: "link",
								smallTitle: "UER",
							},
						],
					},
					{
						path: "/layout/adminReports",
						title: "System Queries",
						type: "link",
						icontype: "tim-icons icon-paper",
						smallTitle: "Sys Qs",
					},
					{
						path: "/layout/customerReports",
						title: "Reports",
						type: "link",
						icontype: "tim-icons icon-paper",
						smallTitle: "Rpts",
					},
					{
						path: "assets",
						title: "Assets",
						type: "link",
						smallTitle: "Assets",
						icontype: "tim-icons icon-map-big",
					},
					{
						path: "canvas-designer",
						title: "Canvas Designer",
						type: "link",
						smallTitle: "CD",
						icontype: "tim-icons icon-map-big",
					},
					{
						path: "trainingCourses",
						title: "Training Courses",
						type: "link",
						smallTitle: "Training",
						icontype: "tim-icons icon-video-66",
					},
					{
						path: "/layout/logbook",
						title: "Logbook",
						type: "link",
						smallTitle: "Logbook",
						icontype: "tim-icons icon-notes",
					},
				];
			} else {
				if (Global.User.isOrgAdmin) {
					this.myRoutes = [
						{
							path: "/layout",
							title: "Dashboard",
							type: "sub",
							icontype: "tim-icons icon-chart-pie-36",
							collapse: "dashboard",
							isCollapsed: false,
							smallTitle: "Dashbd",
							children: this.userDashboardsAsMenuItems,
						},
						{
							path: "/layout",
							title: "Advanced Modules",
							type: "sub",
							icontype: "tim-icons icon-molecule-40",
							collapse: "advanced",
							isCollapsed: true,
							children: [
								{
									path: "tables",
									title: "System Tables",
									type: "link",
									smallTitle: "Sys",
								},
							],
						},
						{
							path: "/layout/customerReports",
							title: "Reports",
							type: "link",
							icontype: "tim-icons icon-paper",
							smallTitle: "Rpts",
						},
						{
							path: "assets",
							title: "Assets",
							type: "link",
							smallTitle: "Assets",
							icontype: "tim-icons icon-map-big",
						},
						{
							path: "canvas-designer",
							title: "Canvas Designer",
							type: "link",
							smallTitle: "CD",
							icontype: "tim-icons icon-map-big",
						},
						{
							path: "trainingCourses",
							title: "Training Courses",
							type: "link",
							smallTitle: "Training",
							icontype: "tim-icons icon-video-66",
						},
						{
							path: "/layout/logbook",
							title: "Logbook",
							type: "link",
							smallTitle: "Logbook",
							icontype: "tim-icons icon-notes",
						},
					];
				} else {
					if (
						Global.User.Menu.Privileges.POTD == true &&
						!Global.User.isSiteAdmin
					) {
						this.myRoutes = [
							{
								path: "/layout",
								title: "Dashboard",
								type: "sub",
								icontype: "tim-icons icon-chart-pie-36",
								collapse: "dashboard",
								isCollapsed: false,
								smallTitle: "Dashbd",
								children: this.userDashboardsAsMenuItems,
							},
							{
								path: "/layout",
								title: "Advanced Modules",
								type: "sub",
								icontype: "tim-icons icon-molecule-40",
								collapse: "advanced",
								isCollapsed: true,
								children: [
									{
										path: "potd",
										title: "POTD",
										type: "link",
										smallTitle: "POTD",
									},
								],
							},
							{
								path: "/layout/customerReports",
								title: "Reports",
								type: "link",
								icontype: "tim-icons icon-paper",
								smallTitle: "Rpts",
							},
							{
								path: "assets",
								title: "Assets",
								type: "link",
								smallTitle: "Assets",
								icontype: "tim-icons icon-map-big",
							},
							{
								path: "canvas-designer",
								title: "Canvas Designer",
								type: "link",
								smallTitle: "CD",
								icontype: "tim-icons icon-map-big",
							},
							{
								path: "trainingCourses",
								title: "Training Courses",
								type: "link",
								smallTitle: "Training",
								icontype: "tim-icons icon-video-66",
							},
							{
								path: "/layout/logbook",
								title: "Logbook",
								type: "link",
								smallTitle: "Logbook",
								icontype: "tim-icons icon-notes",
							},
						];
					} else {
						if (Global.User.isSiteAdmin) {
							this.myRoutes = [
								{
									path: "/layout",
									title: "Dashboard",
									type: "sub",
									icontype: "tim-icons icon-chart-pie-36",
									collapse: "dashboard",
									isCollapsed: false,
									smallTitle: "Dashbd",
									children: this.userDashboardsAsMenuItems,
								},
								{
									path: "/layout",
									title: "Advanced Modules",
									type: "sub",
									icontype: "tim-icons icon-molecule-40",
									collapse: "advanced",
									isCollapsed: true,
									children: [
										{
											path: "tables",
											title: "System Tables",
											type: "link",
											smallTitle: "Sys",
										},
									],
								},
								{
									path: "canvas-designer",
									title: "Canvas Designer",
									type: "link",
									smallTitle: "CD",
									icontype: "tim-icons icon-map-big",
								},
								{
									path: "trainingCourses",
									title: "Training Courses",
									type: "link",
									smallTitle: "Training",
									icontype: "tim-icons icon-video-66",
								},
								{
									path: "/layout/logbook",
									title: "Logbook",
									type: "link",
									smallTitle: "Logbook",
									icontype: "tim-icons icon-notes",
								},
							];
						} else {
							this.myRoutes = [
								{
									path: "/layout",
									title: "Dashboard",
									type: "sub",
									icontype: "tim-icons icon-chart-pie-36",
									collapse: "dashboard",
									isCollapsed: false,
									smallTitle: "Dashbd",
									children: this.userDashboardsAsMenuItems,
								},
								{
									path: "/layout/customerReports",
									title: "Reports",
									type: "link",
									icontype: "tim-icons icon-paper",
									smallTitle: "Rpts",
								},
								{
									path: "assets",
									title: "Assets",
									type: "link",
									smallTitle: "Assets",
									icontype: "tim-icons icon-map-big",
								},
								{
									path: "canvas-designer",
									title: "Canvas Designer",
									type: "link",
									smallTitle: "CD",
									icontype: "tim-icons icon-map-big",
								},
								{
									path: "trainingCourses",
									title: "Training Courses",
									type: "link",
									smallTitle: "Training",
									icontype: "tim-icons icon-video-66",
								},
								{
									path: "/layout/logbook",
									title: "Logbook",
									type: "link",
									smallTitle: "Logbook",
									icontype: "tim-icons icon-notes",
								},
							];
						}
					}
				}
			}

			//Global.User.DebugMode && console.log("this.userDashboardsAsMenuItems = %O", this.userDashboardsAsMenuItems);

			this.menuItems = this.myRoutes;
			//this.menuItems = this.myRoutes.filter((menuItem) => menuItem);
			Global.User.DebugMode &&
				console.log("this.menuItems = %O", this.menuItems);
			Global.User.Menu.Dashboards = this.userDashboardsAsMenuItems;
			Global.User.Menu.DesktopMenuItems = this.menuItems;
			Global.User.needToBuildMenu = false;
			//Global.User.DebugMode && console.log("this.myRoutes = %O", this.myRoutes);
		} else {
			Global.User.DebugMode && console.log("layout: menu already built.");
			this.userDashboardsAsMenuItems = Global.User.Menu.Dashboards;
			this.menuItems = Global.User.Menu.DesktopMenuItems;
		}
		this.ref.detectChanges();
		Global.User.DebugMode &&
			console.log("sidebar: user menu build completed.");
	}

	updateGlobalMenuTitle(menuItem: any, childItem?: any) {
		//Global.User.DebugMode && console.log("menuItem = %O", menuItem);
		//Global.User.DebugMode && console.log("childItem = %O", childItem);
		//Global.User.DebugMode && console.log("func = " + childItem.func);
		if (childItem && childItem.func) {
			//Global.User.DebugMode && console.log(childItem.func + " is a function.  Trying to execute " + childItem.func + "...");
			this.utilityService.updateCurrentMenuItem(childItem.title);
			//Global.User.DebugMode && console.log(`func: ${childItem.func}`);
			this[`${childItem.func}`](); // execute function if it truly is a function... --Kirk T. Sherer, April 22, 2020.
			//func(); // execute function if it truly is a function... --Kirk T. Sherer, April 22, 2020.
		} else {
			if (childItem) {
				this.utilityService.updateCurrentMenuItem(childItem.title);
				if (childItem.id != null) {
					//Global.User.DebugMode && console.log("Global.CurrentMenuItem = " + Global.CurrentMenuItem + ", routing to " +  menuItem.path + ", " + childItem.path + ", " + childItem.id );
				} else {
					//Global.User.DebugMode && console.log("Global.CurrentMenuItem = " +Global.CurrentMenuItem +", routing to " +menuItem.path +", " +childItem.path  );
				}
			} else {
				this.utilityService.updateCurrentMenuItem(menuItem.title);
				// Global.User.DebugMode && console.log("Global.CurrentMenuItem = " +
				//     Global.CurrentMenuItem +
				//     ", routing to " +
				//     menuItem.path
				// );
			}
		}
	}

	isActive(instruction: any[]): string {
		// Set the second parameter to true if you want to require an exact match.
		//Global.User.DebugMode && console.log("instruction = %O", instruction);
		// Global.User.DebugMode && console.log(
		//   "this.router.createUrlTree(instruction) = %O",
		//   this.router.createUrlTree(instruction)
		// );
		// Global.User.DebugMode && console.log(
		//   "this.router.isActive(this.router.createUrlTree(instruction), false) " +
		//     this.router.isActive(this.router.createUrlTree(instruction), false)
		// );

		if (
			this.router.isActive(this.router.createUrlTree(instruction), false)
		) {
			return "active";
		} else {
			return "";
		}
	}
}
