<div class="card card-tasks" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header" cdkDragHandle style="cursor: default; padding-top: 0px; min-height: 0px">
        <div style="padding-top: 15px; display: inline-block">
            <h6 class="title  d-inline" >
                Widget Time Zone Settings for {{localWidgetObject.WidgetName}}
             </h6>
        </div>

        <button class="btn btn-fill btn-primary" type="button" (click)="submitAndClose()" style="padding: 3px 6px; margin: 0px 10px">
			<i style="font-size: 10px">Submit and Close</i>
		</button>

        <button mat-button style="display: inline; float: right; padding-top: 5px;" (click)="onCancel()" matTooltip="Close" [matTooltipPosition]="'below'">
            <div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>



	</div>
	<div class="card-body" style="overflow-y: scroll">
        <div class="container" style="padding-top: 10px">

				<mat-form-field appearance="fill" style="display: inline">
					<mat-label>Select a Widget Timezone</mat-label>
                    <mat-select [(value)]="selectedTimeZoneId" >
						<mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.Id">
							{{ timeZone.Description }}
						</mat-option>
					</mat-select>
				</mat-form-field>



</div>
	</div>
</div>
