import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';




@Component({
  selector: "dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
  sub: any;
  dashboardId: number;
  currentDashboard: any;
  constructor(public dashboardService: DashboardService, private route: ActivatedRoute) {

  }
  ngOnInit() {
    console.log(this.dashboardService.currentDashboard);

  }

  ngOnDestroy() {

  }
}
