<div class="content" style="padding-right: 0px !important; padding-bottom: 0px">
	<div class="col-md-12" style="padding-left: 0; padding-right: 0">
		<div class="card">
			<div class="card-header">
				<h3 style="display: inline" class="card-title">My Dashboard List</h3>
				<button class="btn btn-primary btn-round btn-icon mr-1 pull-right" title="New Dashboard" placement="left" (click)="openDashboardEdit(0)">
					<i style="font-size: 10px" class="tim-icons icon-simple-add"> </i>
				</button>
			</div>
			<div class="card-body">
				<!-- <div class="table-responsive table-container">
					<table class="table">
						<thead class="text-primary">
							<tr>
								<th>Name</th>
								<th>Description</th>
								<th>Dashboard Type</th>
								<th>App Type</th>
								<th style="z-index: 2" class="text-middle">Actions</th>
							</tr>
						</thead>
						<tbody class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
							<tr class="example-box" *ngFor="let dashboard of dashboards; let i = index" cdkDrag>
								<td class="thirdWidth">{{ dashboard.Name }}</td>
								<td class="thirdWidth">
									{{ dashboard.Description == "null" ? "" : dashboard.Description }}
								</td>
								<td class="thirdWidth">{{ dashboard.DashboardType }}</td>
								<td class="thirdWidth">{{ dashboard.Type }}</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<button class="btn action-btn-link-icon" title="Go to Dashboard" type="button" (click)="updateMenuItem(dashboard)" [routerLink]="['/layout/dashboard/' + dashboard.Id]">
										<i class="tim-icons icon-triangle-right-17"></i>
									</button>
									<button style="z-index: 1" class="btn action-btn-link-icon" title="Edit this dashboard" type="button" (click)="openDashboardEdit(dashboard.Id)">
										<i class="tim-icons icon-pencil"></i>
									</button>
									<button style="z-index: 1" class="btn action-btn-link-icon" title="Copy this dashboard" type="button" (click)="openCopyDialog(dashboard)">
										<i class="tim-icons icon-single-copy-04"></i>
									</button>
									<button class="btn action-btn-link-icon" title="Delete this dashboard" type="button" (click)="onDelete(dashboard)">
										<i class="tim-icons danger-color icon-simple-remove"></i>
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div> -->
				<div class="table-responsive table-container">
					<kendo-grid
				[data]="gridData"
				[style.height.%]="100"

				[rowClass]="rowCallback"
				(dataStateChange)="dataStateChange($event)"
			>
				<kendo-grid-column field="Name" title="Name" > </kendo-grid-column>
				<kendo-grid-column field="Description" title="Description" > </kendo-grid-column>
				<kendo-grid-column field="Type" title="App Type" > </kendo-grid-column>
				<kendo-grid-column title="Actions" >
					<ng-template kendoGridCellTemplate let-dataItem>
						<button class="btn action-btn-link-icon" title="Go to Dashboard" type="button" (click)="updateMenuItem(dataItem)" [routerLink]="['/layout/dashboard/' + dataItem.Id]">
							<i class="tim-icons icon-triangle-right-17"></i>
						</button>
						<button style="z-index: 1" class="btn action-btn-link-icon" title="Edit this dashboard" type="button" (click)="openDashboardEdit(dataItem.Id)">
							<i class="tim-icons icon-pencil"></i>
						</button>
						<button style="z-index: 1" class="btn action-btn-link-icon" title="Copy this dashboard" type="button" (click)="openCopyDialog(dataItem)">
							<i class="tim-icons icon-single-copy-04"></i>
						</button>
						<button class="btn action-btn-link-icon" title="Delete this dashboard" type="button" (click)="onDelete(dataItem)">
							<i class="tim-icons danger-color icon-simple-remove"></i>
						</button>					</ng-template>
					 </kendo-grid-column>
				<!-- <kendo-grid-column field="Discontinued" title="Discontinued" [width]="120">
					<ng-template kendoGridCellTemplate let-dataItem>
						<input type="checkbox" [checked]="dataItem.Discontinued" disabled />
					</ng-template>
				</kendo-grid-column> -->
			</kendo-grid>
				</div>

			</div>
		</div>
	</div>
</div>
