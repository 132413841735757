import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CentralunitsOverviewComponent } from './centralunits-overview.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { CentralUnitModule } from '../central-unit/central-unit.module';
import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
	declarations: [CentralunitsOverviewComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		CenteredMessageModule,
		CentralUnitModule,
		NavigationModule
	],
	exports: [CentralunitsOverviewComponent],
})
export class CentralunitsOverviewModule {}


