import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { FormGroup, FormControl } from "@angular/forms";

import { Subject } from "rxjs";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { QuestionBase } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-base";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { SliderQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-slider";
import { FileUploadQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-file-upload";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import Swal from "sweetalert2";

export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: "app-user-settings",
	templateUrl: "./user-settings.component.html",
	styleUrls: ["./user-settings.component.scss"]
})
export class UserSettingsComponent implements OnInit {
	public userSettings: any;
	public form: FormGroup;
	public editFields: QuestionBase<any>[];
	public stateAbbreviations: any;
	public formOptions: any;
	public isFormLoading: boolean = true;
	public organizations: any;
	public global: IGlobal = Global;

	public state: boolean = true;
	public dashboardColor: boolean = true;
	public isCollapsed: boolean = true;
	public darkTheme: boolean = true;
	public lastSliderSelection: boolean = false;
	public darkTheme$: Subject<any>;
	public selectedColor: string = "dark-blue";
	public sidebarMini: boolean = false;

	constructor(public dialogRef: MatDialogRef<UserSettingsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dataService: DataService, public utilityService: UtilityService) {}

	ngOnInit() {
		this.darkTheme = Global.Theme == "dark" ? true : false;
		Global.User.DebugMode && console.log("Global = %O", Global);
		this.sidebarMini = Global.User.SidebarMini;
		this.selectedColor = Global.User.MenuColor;

		this.dataService.themeChanged$.subscribe((theme: string) => {
			this.darkTheme = theme == "dark" ? true : false;
		});

		this.formOptions = {
			submitButtonText: "Save Changes",
			saveValuesPerField: true,
			saveStoredProcedureName: "API.UserSettings_UpdateRecordByIdAndFieldName " + Global.User.currentUser.Id
		};

		this.form = new FormGroup({
			username: new FormControl(),
			givenName: new FormControl(),
			middleName: new FormControl(),
			familyName: new FormControl(),
			streetAddress1: new FormControl(),
			streetAddress2: new FormControl(),
			city: new FormControl(),
			state: new FormControl(),
			zipcode: new FormControl(),
			organization: new FormControl(),
			phone: new FormControl(),
			email: new FormControl(),
			debugMode: new FormControl(),
			returnToLastVisitedRoute: new FormControl(),
			picture: new FormControl()
		});

		this.buildEditForm();
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	selectColor(color: string, event: Event): void {
		//console.log("color chosen = " + color + ", $event = %O", event);
		this.selectedColor = color;
		//console.log("selectedColor = " + this.selectedColor);
		this.dataService.changeSidebarColor(color, true); //-- true meaning we're updating from the user settings...

		//-- if the selected color contains the word 'dark', change to the dark theme.  Otherwise, change to the light theme or else the user won't be able to see the text on the menu. --Kirk T. Sherer, May 7, 2020.
		var changeToDarkTheme: boolean = color.indexOf("dark") != -1;
		this.darkTheme = changeToDarkTheme;
		this.dataService.toggleDarkLightTheme(true, changeToDarkTheme);
	}

	changeTheme(event: Event) {
		var selectedColor = this.selectedColor;
		Global.User.DebugMode && console.log("selectedColor = " + selectedColor + ", current theme = " + Global.Theme);
		Global.User.DebugMode && console.log("changing theme....");
		Global.User.DebugMode && console.log("event =%O", event);
		this.dataService.toggleDarkLightTheme(true); //-- true meaning we're updating from the user settings...

		if (selectedColor.indexOf("dark") == -1 && Global.Theme == "light") {
			//-- user is trying to select a dark theme with a light colored menu (since we're currently on a 'light' theme).  Change to a dark colored menu before changing the theme. --Kirk T. Sherer, May 7, 2020.
			if (selectedColor.indexOf("light") != -1) {
				selectedColor = selectedColor.replace("light", "dark");
			} else {
				selectedColor = "dark-blue"; //-go back to default iOPS color since user had selected a non-light color and we're not sure which one to replace it with. --Kirk T. Sherer, May 7, 2020.
			}
			Global.User.DebugMode && console.log("selectedColor before changing = " + selectedColor);
			this.dataService.changeSidebarColor(selectedColor, true);
			this.selectedColor = selectedColor;
		} else {
			if (selectedColor.indexOf("light") == -1 && Global.Theme == "dark") {
				//-- user is trying to select a light theme with a dark colored menu (since we're currently on a 'dark' theme).  Change to a light colored menu before changing the theme. --Kirk T. Sherer, May 7, 2020.
				if (selectedColor.indexOf("dark") != -1) {
					if (selectedColor == "dark-green") {
						selectedColor = "bright-green";
					} else {
						selectedColor = selectedColor.replace("dark", "light");
					}
				} else {
					selectedColor = "light-blue"; //-go back to default iOPS color since user had selected a non-dark color and we're not sure which one to replace it with. --Kirk T. Sherer, May 7, 2020.
				}

				Global.User.DebugMode && console.log("selectedColor before changing = " + selectedColor);
				this.dataService.changeSidebarColor(selectedColor, true);
				this.selectedColor = selectedColor;
			}
		}
	}

	changeMenuSize(event: Event) {
		Global.User.DebugMode && console.log("changing menu size....");
		this.dataService.toggleSidebarMini(true); //-- true meaning we're updating from the user settings...
	}

	buildEditForm(): void {
		var service = this;
		this.stateAbbreviations = this.dataService.cache.stateAbbreviations;
		this.organizations = this.dataService.cache.organizations;
		Global.User.DebugMode && console.log("this.stateAbbreviations = %O", this.stateAbbreviations);
		Global.User.DebugMode && console.log("this.organizations = %O", this.organizations);

		this.dataService.SQLActionAsPromise("API.GetUserSettings " + Global.User.currentUser.Id).then((data: any) => {
			service.userSettings = data.first();
			Global.User.DebugMode && console.log("this.userSettings = %O", service.userSettings);

			var editQuestions: QuestionBase<any>[] = [
				new SliderQuestion({
					key: "DebugMode",
					label: "Debug Mode",
					value: service.userSettings.DebugMode,
					required: false,
					title: "Select this option if you want to see debug statements in the browser console.",
					visible: Global.User.currentUser.IsSystemAdministrator,
					order: 145,
					saveOnChange: service.formOptions.saveValuesPerField,
					saveStoredProcedureName: service.formOptions.saveStoredProcedureName,
					onChangeFunction: function () {
						console.log("<--- Changing Debug Mode to " + (this.newValue == 1 ? true : false) + " --->");
						Global.User.DebugMode = this.newValue == 1 ? true : false;
					}
				}),
				new SliderQuestion({
					key: "UseProductionDataOnTest",
					label: "Use Production Data?",
					value: service.userSettings.UseProductionDataOnTest,
					required: false,
					title: "Select this option if you want to use Production data on the Test site.  This replaces the need for a Beta site.",
					visible: document.URL.indexOf("localhost") > 0 || document.URL.indexOf("test") > 0,
					order: 155,
					saveOnChange: service.formOptions.saveValuesPerField,
					saveStoredProcedureName: service.formOptions.saveStoredProcedureName,
					onChangeFunction: function () {
						var environment = this.newValue == 1 ? "Production" : "Test";
						var original_UseProductionDataOnTest: string = localStorage.getItem("useProductionDataOnTest");
						var new_UseProductionDataOnTest: string = this.newValue.toString();
						if (original_UseProductionDataOnTest != new_UseProductionDataOnTest) {
							localStorage.setItem("useProductionDataOnTest", this.newValue.toString());
						}
	
						Swal.fire({
							title: "Reloading iOPS Application",
							html: "Changing the Data Source to <strong>" + environment + "</strong>. Will have to reload the iOPS application to apply the change to the data source.",
							showCancelButton: false,
							confirmButtonText: "OK",
							reverseButtons: false
						}).then(() => {
							window.location.reload();
						});
					}
				}),
				new SliderQuestion({
					key: "ReturnToLastVisitedRoute",
					label: "Return to Last Visited Route",
					value: service.userSettings.ReturnToLastVisitedRoute,
					required: false,
					title: "Select this option if you want to see return to the last route you visited when you were last using the application.",
					order: 165,
					saveOnChange: service.formOptions.saveValuesPerField,
					saveStoredProcedureName: service.formOptions.saveStoredProcedureName
				}),
				new FileUploadQuestion({
					key: "PictureImageKey",
					label: "Picture",
					value: service.userSettings.PictureImageKey,
					required: false,
					title: "Upload a picture of yourself if you would like.  This will be used in correspondence on the website.",
					order: 185,
					saveOnChange: service.formOptions.saveValuesPerField,
					saveStoredProcedureName: service.formOptions.saveStoredProcedureName
				})
			];

			service.editFields = editQuestions;
			Global.User.DebugMode && console.log("editQuestions = %O", editQuestions);
			service.isFormLoading = false;
		});
	}

	submitForm(submittedValues: string) {
		Global.User.DebugMode && console.log("user-settings.component.ts: submitForm: submittedValues = %O", submittedValues);
		var fieldListAsString = submittedValues.replace("{", "").replace("}", "").replace(/:/g, "=").replace(/\"/g, "");
		Global.User.DebugMode && console.log("user-settings.component.ts: fieldListAsString = " + fieldListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode && console.log("submittedValuesObject = %O", submittedValuesObject);
		Global.User.DebugMode && console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += "@" + key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				fieldListAsString += value ? value : "null";
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		Global.User.DebugMode && console.log("fieldListAsString = " + fieldListAsString);
		this.dataService.SQLActionAsPromise("API.UpdateUserSettings @iOPSUserId=" + Global.User.currentUser.Id + ", " + fieldListAsString).then((data: any) => {
			this.userSettings = data.first();
			var currentUser = localStorage.getItem("currentUser");
		});
	}
}
