<div
	class="card card-tasks widget-card"
	id="card-whole"
	cdkDrag
	cdkDragRootElement=".cdk-overlay-pane"
>
	<div
		class="card-header widget-card-header"
		style="cursor: default; padding-top: 0px; min-height: 0px"
		cdkDragHandle
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6
				class="title d-inline"
				style="padding-top: 15px; display: inline-block"
			>
				Custom Time Scope Settings
			</h6>

		</div>

		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body">
		<div style="height: 85%; width: 100%">
			<div style="display: inline; padding-right: 10px" >

				<form [formGroup]="form">
					<br>
					<div class="formGroup">
						<label  style="display: block">
Start Date

						</label>
						<kendo-datepicker  format="MM/dd/yyyy HH:mm" [max]="maxDate" formControlName="startDateTime"></kendo-datepicker>
					</div>
					<br>
					<div class="formGroup">
						<label  style="display: block">
End Date

						</label>
						<kendo-datepicker format="MM/dd/yyyy HH:mm" [max]="maxDate" formControlName="endDateTime"></kendo-datepicker>
					</div>
				</form>
			</div>

		</div>
		<div>
			<button
			class="btn btn-fill btn-primary"
			type="button"

			(click)="submitAndClose()"
			style=" margin: 0px 10px; float: right;"
		>
			<i style="font-size: 10px">Submit and Close</i>
		</button>

		</div>

	</div>
</div>
