import { Component, Input, OnInit } from "@angular/core";
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { IGlobal } from '../../_models/global.model';


@Component({
  selector: 'lib-centered-message',
  templateUrl: './centered-message.component.html',
  styleUrls: ['./centered-message.component.scss']
})
export class CenteredMessageComponent implements OnInit {
  public global: IGlobal = Global;
  @Input() centeredMessage;
  fullDataCacheSubscription: any;
  theme: any;
  colorChangedSubscription: any;


  constructor(public dataService: DataService) { }

  ngOnInit() {
    //console.log(global);

    if (!Global.FullDataCacheExists) {
      this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
        if (data === true) {
          if (Global.Theme === "light") {
            this.theme = "light";
          } else if (Global.Theme === "dark") {
            this.theme = "dark";
          }
          this.fullDataCacheSubscription.unsubscribe();
        }
      });
    } else {
      if (Global.Theme === "light") {
        this.theme = "light";
      } else if (Global.Theme === "dark") {
        this.theme = "dark";
      }
    }

    this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((data: any) => {
      Global.User.DebugMode && console.log("gse-summary: color changed data = %O", data);
      if (data === "light") {
        this.theme = "light";
      } else {
        this.theme = "dark";
      }
    });
  }

}
