import { Component, AfterViewInit, Input, EventEmitter, ViewChild, ViewChildren, OnInit, ElementRef } from "@angular/core";
import _ from "lodash";
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { UtilityService } from '../../services/utility.service';
import { IGlobal } from "../../_models/global.model";
import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';

@Component({
	selector: 'lib-gs-available-assets',
	templateUrl: './gs-available-assets.component.html',
	styleUrls: ['./gs-available-assets.component.scss'],
})
export class GsAvailableAssetsComponent implements AfterViewInit, OnInit {
	@ViewChildren('totalSeries')
	public totalSeries: any;

	@ViewChildren('totalSeriesAggregated')
	public totalSeriesAggregated: any;

	@ViewChild('chart')
	public chart: any;
	@ViewChild('childOverviewGauge')
	childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
	@Input() widgetObject: any;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	public global: IGlobal = Global;

	fullDataCacheSubscription: any;
	colorChangedSubscription: any;
	dashboardUpdateIntervalSubscription: any;
	dashboardTimeScopeChangedSubscription: any;
	selectedMatTabLabel: string;
	updateInterval: any;
	displayNoTimeScopeMessage: boolean = false;
	displayNoUpdateIntervalMessage: boolean = false;
	secondsAgoInterval: NodeJS.Timeout;
	lastUpdatedDate: Date;
	displayTimeAgoMessage: any;
	updateControllerInterval: NodeJS.Timeout;
	parentContainerSize: any;
	widgetTabsChartConfigurations: any;
	theme: string;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	percentCalculated: boolean = false;
	gaugeDisplayData: any;
	chartDataFinalIsReady: boolean = false;
	selectedTabIndex: number = 0;
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;
	public graphTypes: string[] = [
		'area',
		'bar',
		'column',
		'donut',
		'horizontalWaterfall',
		'line',
		'verticalArea',
		'verticalLine',
		'waterfall',
	];
	public graphType = this.graphTypes[0];
	summaryArray = [];
	summaryArray1 = [];
	sparklineData = [];
	fontSize1 = 'medium';
	fontSize2 = 'small';
	//cardClass1 = 'card-tile-lg';
	//cardClass2 = 'card-tile-md';
	//cardWideClass2 = 'card-wide-tile-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-md';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';
	headerGridTitle = 'grid-title-lg';
	contentHeight: any;
	widgetResizedSubscription: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};
	isLoading: boolean;
	public widgetTabs: any = [
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Summary',
			tooltip:
				'Summary of available assets (assets that are not in maintenance mode or out of service) out of the total amount of assets within the selected scope.Click on the info to navigate to corresponding tab for details.',
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Current Available Assets',
			graphType: this.graphTypes[3],
			tooltip:
				'Donut chart showing within the current scope settings (site, asset type) the amount of available assets (assets that are not in maintenance mode or out of service) out of the total amount of assets within the selected scope.',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [
				{ field: 'IsInMaintenanceMode' },
				{ field: 'IsOutOfService' },
			],
			tableStructure: [
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'IsInMaintenanceMode',
					title: 'In Maintenance Mode',
					_width: 300,
				},
				{
					field: 'IsOutOfService',
					title: 'Out Of Service',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Minutes Off For Day',
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed line chart showing within the current scope settings (site, asset type) the total amount of minutes an asset was not used during the 24 hour period of the date shown. ',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'ProperAssetName' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AssetNotUsedMinutes',
					title: 'Asset Not Used Minutes',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Minutes Off For Day Aggregated',
			graphType: this.graphTypes[3],
			tooltip:
				'Donut chart showing within the current scope settings (site, asset type) the total amount of minutes an asset was not used aggregated. ',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'ProperAssetName' }],
			tableStructure: [
				{
					field: "DateOfObservationDateObject",
					title: "Date",
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate'
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: "AssetNotUsedMinutes",
					title: "Asset Not Used Minutes",
					_width: 300
				}
			]
		},
	];
	noDataForSite: boolean = false;
	chartData: any;
	chartDataIsReady: boolean;
	chartDataFinal: GroupResult[];
	timeoutToExpire: NodeJS.Timeout;
	widgetTabConfigurationsFinishedMapping: boolean = false;
	WidgetTabSettings: any = [];

	public timeScopeObject: any;
	public tab: any;
	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: any;


	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];

	holeSize = 30;
	holeCenterTextClass = 'donut-center-text-md';
	donutClass = 'donut-md';
	public percentAssetsAvailable = [];
	public percentAssetsOutOfService = [];
	public percentAssetsInMaintenance = [];



	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		public toastr: ToastrService,
		private utilityService: UtilityService,
		private elem: ElementRef,
		public tourService: TourService,
	) {}

	ngOnInit() {
		this.tab = this.widgetTabs[0];
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		this.buildNavigationOptionsArray();
	}

	ngAfterViewInit() {

		var bounds = this.elem.nativeElement.getBoundingClientRect();
		this.cardResize(bounds);

		if(Global.isMobile == true) {
			this.contentHeight = 'calc(100% - 20px)';
		}
		else {
			this.contentHeight = 'calc(100% - 120px)';
		}

		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
			console.log(this);
		});

		this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId;
		});

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		}

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
				if (this.widgetObject.WidgetId == data.item.WidgetId) {
					this.cardResize(data);
					this.fontResize(data);
				}
			});
		}

		this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.selectedTabIndex = 0
			}
		});

		this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
			}
		});

		this.isLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		if (this.dashboardUpdateIntervalChanged) {
			this.dashboardUpdateIntervalSubscription =
				this.dashboardUpdateIntervalChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.initialize();
					}
				});
		}
		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}
		// this.chartDataIsReady = true;
	}



	openConditionalShadingSettingsPopup(tileNumber) {
		let tileConditionalShadingRecord =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
			});
		if(tileConditionalShadingRecord.length == 0) {
			tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
			tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
		}

		var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
			width: '50%',
			height: '40%',
			data: tileConditionalShadingRecord,
		});


		DialogRef.afterClosed().subscribe((result) => {
			let tileConditionalShadingRecordLatest =
				this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
					return p.WidgetId == this.widgetObject.WidgetId;
				});
			this.ConditionalShadingRecord = [];
			tileConditionalShadingRecordLatest.forEach((r) => {
				this.ConditionalShadingRecord.push(r);
			});

			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
			this.ConditionalColorSummaryTiles();

		});

	}

	private ConditionalColorSummaryTiles() {
		this.percentTextColor[1] = this.percentTextColor[2] = this.percentTextColor[3] = "lightseagreen";

		this.conditionalColorPercentAmount[1] = this.summaryArray[6];
		this.conditionalColorPercentAmount[2] = this.summaryArray[4];
		this.conditionalColorPercentAmount[3] = this.summaryArray[5];


		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.forEach((r) => {

				switch (r.Operator) {
					case 'Is greater than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is less than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded == r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is not equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded != r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
				}
			});
		}

		this.percentAssetsAvailable = [
			{
				kind: "assets in use",
				share: this.summaryArray[6] / 100,
				color: this.percentTextColor[1],
			},
			{
				kind: "other",
				share: 1 - this.summaryArray[6] / 100,
				color: "grey",
			},
		];


		this.percentAssetsOutOfService = [
			{
				kind: "assets in use",
				share: this.summaryArray[4] / 100,
				color: this.percentTextColor[2],
			},
			{
				kind: "other",
				share: 1 - this.summaryArray[4] / 100,
				color: "grey",
			},
		];


		this.percentAssetsInMaintenance = [
			{
				kind: "assets in use",
				share: this.summaryArray[5] / 100,
				color: this.percentTextColor[3],
			},
			{
				kind: "other",
				share: 1 - this.summaryArray[5] / 100,
				color: "grey",
			},
		];

	}


	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.fontSize1 = 'small';
			this.fontSize2 = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.fontSize1 = 'medium';
			this.fontSize2 = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.fontSize1 = 'large';
			this.fontSize2 = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.fontSize1 = 'x-large';
			this.fontSize2 = 'large';
	  }
  }



  cardResize(data: any) {

	if (data.height < 240 || data.width < 700) {
		// this.cardClass2 = 'card-tile-xs';
		// this.cardWideClass2 = 'card-wide-tile-xs';

		this.cardTileCenterTitleClass = 'grid-card-title-center-sm';

		this.holeSize = 50;
		this.holeCenterTextClass = 'donut-center-text-xs';
		this.donutClass = 'donut-sm';
	}

	else if ((data.height > 380 && data.height < 500) || (data.width > 700 && data.width < 800)) {
		// this.cardClass2 = 'card-tile-sm';
		// this.cardWideClass2 = 'card-wide-tile-md';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';
	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 800 && data.width < 900)) {
		// this.cardClass2 = 'card-tile-sm';
		// this.cardWideClass2 = 'card-wide-tile-md';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';
	}
	else if (data.height > 700 || data.width > 900) {
		// this.cardClass2 = 'card-tile-md';
		// this.cardWideClass2 = 'card-wide-tile-lg';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';
	}

	if (data.width < 400) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 400 && data.width < 600) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 600 && data.width < 800) {
		this.headerGridTitle = 'grid-title-lg';
	}
	else if (data.width > 800) {
		this.headerGridTitle = 'grid-title-lg';
	}

}


	startIntervals() {
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);

		if (this.updateInterval === undefined) {
			this.isLoading = false;
			this.displayNoUpdateIntervalMessage = true;
			return;
		} else {
			this.isLoading = true;
			this.displayNoUpdateIntervalMessage = false;
		}
		let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
		if (this.secondsAgoInterval !== undefined) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
		this.retrieveData();

		this.secondsAgoInterval = setInterval(() => {
			if (this.lastUpdatedDate !== undefined) {
				this.displayTimeAgoMessage =
					this.dashboardService.calculateTimeAgo(
						this.lastUpdatedDate
					);
				this.widgetObject.displayTimeAgoMessage =
					this.displayTimeAgoMessage;
			}
		}, 1000);
		if (this.updateControllerInterval !== undefined) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		this.updateControllerInterval = setInterval(() => {
			this.retrieveData();
		}, intervalUpdate);
	}

	destroyIntervals() {
		if (this.updateControllerInterval) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		if (this.secondsAgoInterval) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
	}

	initialize() {
		if (_.isNil(this.widgetObject.SiteList)) {
			this.isLoading = false;
			return;
		}
		let statement =
			'API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.widgetTabs.forEach((tab, index) => {
				if (this.widgetTabsChartConfigurations.length > 0) {
					let foundWidgetTabConfiguration =
						this.widgetTabsChartConfigurations.find(
							(configurationrecord) => {
								return (
									configurationrecord.WidgetTabIndex === index
								);
							}
						);
					if (foundWidgetTabConfiguration !== undefined) {
						tab.graphType = Global.tacticalDashboardGraphTypes.find(
							(graphType) =>
								graphType.id ==
								foundWidgetTabConfiguration.ChartTypeId
						);
					}
				}
			});
			this.widgetTabConfigurationsFinishedMapping = true;

			this.selectedTabIndex =
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0;
			this.selectedMatTabLabel =
				this.widgetTabs[
					this.widgetObject.SelectedTabIndex !== undefined
						? this.widgetObject.SelectedTabIndex
						: 0
				].textLabel;
			this.widgetTabs[
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0
			].tabHasBeenLoaded = true;
			this.tab = this.widgetTabs[this.selectedTabIndex];

			this.startIntervals();
		});
	}

	showDialogModalPopupGrid(tab) {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				groupable: tab.groupable,
				groupColumns: tab.groupable === true ? tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: tab.tableStructure,
				tableData: tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	retrieveData() {
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		if (this.timeScopeObject === undefined) {
			this.displayNoTimeScopeMessage = true;
			this.isLoading = false;
		} else {
			this.displayNoTimeScopeMessage = false;
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[1].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading =
					true;
				let statement =
					"API.TacticalDashboard_GS_CurrentOutOfServiceOrMaintenanceMode @accessToken= '" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					statement =
						statement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement =
						statement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						} else {
							this.widgetTabs[1].chartData = [
								{
									dataType: 'In Maintenance Mode',
									total: 0,
									tooltip: [],
								},
								{
									dataType: 'Out of Service',
									total: 0,
									tooltip: [],
								},
								{
									dataType: 'Is Available',
									total: 0,
									tooltip: [],
								},
							];
							let inMaintenanceMode = [];
							let outOfService = [];
							let isAvailable = [];

							data.forEach((asset) => {
								asset.ProperAssetName =
									asset.SiteName +
									' ' +
									asset.GateName +
									' ' +
									asset.AssetName;
								if (
									asset.IsInMaintenanceMode === false &&
									asset.IsOutOfService === false
								) {
									asset.IsAvailable = 1;
									isAvailable.push(asset);
								} else {
									asset.IsAvailable = 0;
									if (asset.IsInMaintenanceMode === true) {
										inMaintenanceMode.push(asset);
									} else if (asset.IsOutOfService === true) {
										outOfService.push(asset);
									}
								}
							});

							inMaintenanceMode.forEach((asset, index) => {
								this.widgetTabs[1].chartData[0].total = this.widgetTabs[1].chartData[0].total + 1;
								this.widgetTabs[1].chartData[0].tooltip.push( asset.ProperAssetName );
							});

							outOfService.forEach((asset, index) => {
								this.widgetTabs[1].chartData[1].total = this.widgetTabs[1].chartData[1].total + 1;
								this.widgetTabs[1].chartData[1].tooltip.push( asset.ProperAssetName );
							});

							isAvailable.forEach((asset, index) => {
								this.widgetTabs[1].chartData[2].total = this.widgetTabs[1].chartData[2].total + 1;

								if(this.widgetTabs[1].chartData[2].total < 6) {
									this.widgetTabs[1].chartData[2].tooltip.push( asset.ProperAssetName );
								}
								else if(this.widgetTabs[1].chartData[2].total == 6) {
									this.widgetTabs[1].chartData[2].tooltip.push("...");
								}
							});


							// this.h1Number = data.filter((asset) => {
							// 	return asset.IsAvailable === 1;
							// }).length;
							// this.totalAssetLength = data.length;
							// this.h4Percent = parseFloat(
							// 	(
							// 		(this.h1Number / this.totalAssetLength) *
							// 		100
							// 	).toFixed(1)
							// );

							// this.gaugeDisplayData = {
							// 	h1Number: this.h1Number,
							// 	h4Percent: this.h4Percent,
							// 	totalAssetLength: this.totalAssetLength,
							// };
							this.summaryArray[0] = data.length;// Total Asset Count
							this.summaryArray[1] = outOfService.length; // No. of Assets in Out of Service
							this.summaryArray[2] = inMaintenanceMode.length// No. of Assets in Maintenance Mode
							this.summaryArray[3] = isAvailable.length //No. of Assets Available
							if (outOfService.length === 0) {
								this.summaryArray[4] = 0.0 //Out Of Service Percent
							} else {
								let outOfServicePercent = parseFloat(
									(
										(outOfService.length /
											data.length) *
										100
									).toFixed(1)
								);
								this.summaryArray[4] = outOfServicePercent //Out Of Service Percent
							}
							if (inMaintenanceMode.length === 0) {
								this.summaryArray[5] = 0.0//Maintenance Mode Percent
							} else {
								let maintenanceModePercent = parseFloat(
									(
										(inMaintenanceMode.length /
											data.length) *
										100
									).toFixed(1)
								);
								this.summaryArray[5] = maintenanceModePercent //Maintenance Mode Percent
							}
							if (isAvailable.length === 0) {
								this.summaryArray[6] = 0.0 //Available Percent
							} else {
								let availablePercent = parseFloat(
									(
										(isAvailable.length /
											data.length) *
										100
									).toFixed(1)
								);
								this.summaryArray[6] = availablePercent //Available Percent
							}

							this.ConditionalColorSummaryTiles();

							this.widgetTabs[1].tableData = data;
							this.widgetTabs[1].chartDataIsReady = true;
							this.chartDataIsReady = true;
							this.percentCalculated = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						}
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[2].tabHasBeenLoaded === true ||
				this.widgetTabs[3].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[2].isLoading = this.widgetTabs[3].isLoading =
					true;
				let statement2 =
					'API.TacticalDashboard_GS_Asset_NotUsed_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					statement2 =
						statement2 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement2 =
						statement2 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}

				this.dataService
					.SQLActionAsPromise(statement2)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[0].isLoading = false;
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = data;
							this.widgetTabs[2].isLoading = false;
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = data;
							this.widgetTabs[3].isLoading = false;
						} else {
							this.chartDataFinalIsReady = false;
							console.log(data);
							data.forEach((record) => {
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
							});
							this.widgetTabs[2].tableData = this.widgetTabs[3].tableData = data;
							this.widgetTabs[2].chartData = groupBy(data, [
								{ field: 'ProperAssetName' },
							]) as GroupResult[];
							this.widgetTabs[3].chartData =
								this.widgetTabs[2].chartData;
							this.widgetTabs[3].chartData.forEach((asset) => {
								asset.MinutesNotUsedOverTimescope = 0;
								asset.items.forEach((dayRecord) => {
									asset.MinutesNotUsedOverTimescope =
										asset.MinutesNotUsedOverTimescope +
										dayRecord.AssetNotUsedMinutes;
								});
							});
							if (this.widgetTabs[2].chartData.length > 0) {
								this.widgetTabs[2].chartData.push({
									value: 'Toggle All Series',
									MinutesNotUsedOverTimescope: 0,
								});
							}
							// if (this.widgetTabs[3].chartData.length > 0) {
							//     this.widgetTabs[3].chartData.push({ value: "Toggle All Series" })
							// }
							let groupByDate = groupBy(data, [{ field: 'DateOfObservation' },]) as any[];
							groupByDate.forEach((asset) => {
								asset.MinutesNotUsedOverTimescope = 0;
								asset.items.forEach((dayRecord) => {
									asset.MinutesNotUsedOverTimescope =
										asset.MinutesNotUsedOverTimescope +
										dayRecord.AssetNotUsedMinutes;
								});
							});

							groupByDate.sort(
								(a, b) => new Date(b.DateOfObservation).getTime() - new Date(a.DateOfObservation).getTime()
							);
							this.createSparkLineChart(groupByDate);//Sparkline Data
							if (groupByDate.length > 0) {

							}

							let sortByAssetOnDurationMinutes = data;
							sortByAssetOnDurationMinutes.sort(
								(a, b) => b.AssetNotUsedMinutes - a.AssetNotUsedMinutes
							);
							if (sortByAssetOnDurationMinutes.length > 0) {
								this.summaryArray1[0] = 1440 - sortByAssetOnDurationMinutes[sortByAssetOnDurationMinutes.length - 1].AssetNotUsedMinutes; //Highest AssetOnDuration Minutes
								this.summaryArray1[1] = sortByAssetOnDurationMinutes[sortByAssetOnDurationMinutes.length - 1].DateOfObservation; //Highest AssetOnDuration DateOfObservation
								this.summaryArray1[2] = sortByAssetOnDurationMinutes[sortByAssetOnDurationMinutes.length - 1].ProperAssetName; //Highest AssetOnDuration Asset Name
							}

							this.widgetTabs[3].chartDataIsReady = true;
							this.widgetTabs[2].chartDataIsReady = true;
							this.widgetTabs[0].isLoading = this.widgetTabs[2].isLoading = this.widgetTabs[3].isLoading = false;
						}
					});
			}

			this.lastUpdatedDate = new Date();

			this.isLoading = false;


			// this.chartDataIsReady = true;

			// })
		}
	}



	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "100px" : "125px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Current Available",
						action: () => { service.navigateToTab(1, "Current Available Assets");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Minutes Off",
						action: () => { service.navigateToTab(2, "Total Minutes Off For Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Aggregated",
						action: () => { service.navigateToTab(3, "Total Minutes Off For Day Aggregated");  this.navigationOpened=false; }
					},


					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Current Available",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Minutes Off",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Aggregated",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
					// 		},


					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}


		service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item:any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item:any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item:any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}



	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.MinutesNotUsedOverTimescope,
					dateOfObs: a.value,
				})),
			},
		];
	}

	toggleLegendHidden(tab, index) {
		tab.legendVisible = !tab.legendVisible;
		let foundSetting = this.WidgetTabSettings.find((widgetTabSetting) => {
			return widgetTabSetting.TabIndex === index;
		});

		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId +
			', @TabIndex = ' +
			index +
			', @IsHidden = ' +
			!tab.legendVisible;
		if (!_.isNil(foundSetting)) {
			statement = statement + ', @Id = ' + foundSetting.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
		});
	}

	animationRedraw() {
		// if (this.selectedMatTabLabel === "Map") {
		// 	this.loadCorrectTabData(true)
		// }
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);
		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	navigateToTab(index, textLabel)  {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);


		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}


	tabHasChanged(event) {
		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = event.tab.textLabel;
		this.selectedTabIndex = event.index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}

	onResized(event) {
		// this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		// this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
		if (this.childOverviewGauge !== undefined) {
			this.childOverviewGauge.reFlow();
		}

		// Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
		// this.newHeight = event.newRect.height;
		// this.oldHeight = event.oldHeight;
		// this.newWidth = event.newRect.width;
		// this.oldWidth = event.oldWidth;
	}

	ngOnDestroy() {
		this.destroyIntervals();
	}

	showChartAsPopup(tab) {
		var popupData;
		if (tab.textLabel == 'Total Minutes Off For Day') {
			popupData = {
				categoryField: 'DateOfObservation',
				field: 'AssetNotUsedMinutes',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: 'Minutes Not Used',
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: true,
			}
		}
		else if (tab.textLabel == 'Total Minutes Off For Day Aggregated') {
			popupData = {
				categoryField: 'value',
				field: 'MinutesNotUsedOverTimescope',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: false,
			}
		}
		else if (tab.textLabel == 'Current Available Assets') {
			popupData = {
				categoryField: 'dataType',
				field: 'total',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: false,
			}
		}


		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}
	public labelContent(e: any): string {
		return e.category + ' - ' + e.value;
	}

	public toggleSeries(item: any, chart): void {
		let i = item.series.index;
		if (chart === 'totalSeries') {
			this.totalSeries._results[i].toggleVisibility();
		} else if (chart === 'totalSeriesAggregated') {
			this.totalSeriesAggregated._results[i].toggleVisibility();
		}
	}

	public toggleAllSeries(e, chart) {
		if (e.text === 'Toggle All Series') {
			if (chart === 'totalSeries') {
				this.totalSeries._results.forEach((series, index) => {
					this.totalSeries._results[index].toggleVisibility();
				});
			} else if (chart === 'totalSeriesAggregated') {
				this.totalSeriesAggregated._results.forEach((series, index) => {
					this.totalSeriesAggregated._results[
						index
					].toggleVisibility();
				});
			}
		}
	}

	initializeTour(): void {
		this.tourService.initialize([
			{
                anchorId: this.widgetObject.WidgetId + '-1',
                content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
                title: 'Settings',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-2',
                content: 'Navigate to different tabs & Widget Settings',
                title: 'Navigation',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-3',
                content: 'Click on various tiles for more information',
                title: 'Summary Info',
            },
		]);

		this.tourService.start();
	}

}
