import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';


@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit() {
		console.log("home page invoked...");
		console.log("HomePage: private router: Router injected...");
		if (!Global.User.isLoggedIn && !Global.User.isBeingAuthenticated) {
			console.log("HomePage: Navigating to /login...");
			this.router.navigate(["user/login"]);
		} else {
			if (Global.User.isLoggedIn) {
				if (Global.User.currentUser.Dashboards) {
					this.router.navigate(["layout/dashboard/" + Global.User.currentUser.Dashboards[0].Id]);
				} else {
					Global.CurrentMenuItem = "Welcome Screen";

					this.router.navigate(["layout/dashboard-demo"]); // -- go to demo dashboard since user doesn't have any dashboards yet. --Kirk T. Sherer, February 4, 2020.
				}
			} else {
				this.router.navigate(["authentication"]);
			}
		}
	}
}
