import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalRTestComponent } from './signalr-test.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [SignalRTestComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		KendoGridParentModule
	],
	exports: [SignalRTestComponent],
})
export class SignalRTestModule { }
