import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';

@Component({
	selector: 'lib-cell-phone-carrier-selector',
	templateUrl: './cell-phone-carrier-selector.component.html',
	styleUrls: ['./cell-phone-carrier-selector.component.scss'],
})
export class CellPhoneCarrierSelectorComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public question: any;
	@Output() public returnValue: any;
	public cellPhoneCarriers: any;
	public filteredCellPhoneCarriers$: Observable<any[]>;
	public cellPhoneCarrierName: string;

	constructor(private dataService: DataService) {}

	ngOnInit() {
		console.log('this.formGroup = %O', this.formGroup);
		console.log('this.question = %O', this.question);

		this.cellPhoneCarriers = this.dataService.cache.cellPhoneCarriers;

		this.filteredCellPhoneCarriers$ = this.formGroup.controls[
			this.question.key
		].valueChanges.pipe(
			startWith(''),
			tap((value) => {
				console.log('value = %O', value);
			}),
			map((value: any) =>
				typeof value === 'string' ? value : value.Name
			),
			map((name: any) =>
				name
					? this.cellPhoneCarriersFilter(name)
					: this.cellPhoneCarriers.slice()
			)
		);

		if (this.question.value != null) {
			//-- this is bringing up an edited record, so get the record that matches on the Id field. --Kirk T. Sherer, May 26, 2021.
			var cellPhoneCarrier = this.cellPhoneCarriers.first(
				(option: any) => {
					return option.Id == this.question.value;
				}
			);
			this.formGroup.controls[this.question.key].setValue(
				cellPhoneCarrier
			);
		}
	}

	public displayFn(cellPhoneCarrier: any): string {
		return cellPhoneCarrier && cellPhoneCarrier.Name
			? cellPhoneCarrier.Name
			: '';
	}

	private cellPhoneCarriersFilter(value: string): any {
		const filterValue = value.toLowerCase();
		return this.cellPhoneCarriers.filter((option: any) => {
			if (option.Name != undefined) {
				return option.Name.toLowerCase().includes(filterValue);
			}
		});
	}
}
