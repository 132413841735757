import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import _ from 'lodash';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: 'lib-widget-time-zone-selection-popup',
	templateUrl: './widget-time-zone-selection-popup.component.html',
	styleUrls: ['./widget-time-zone-selection-popup.component.scss'],
})
export class WidgetTimeZoneSelectionPopupComponent implements OnInit {
	timeZones: any;
	selectedTimeZoneId: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public localWidgetObject: any,
		public dialogRef: MatDialogRef<WidgetTimeZoneSelectionPopupComponent>,
		private dataService: DataService,
		public toastr: ToastrService,
		private dashboardService: DashboardService,
		private utilityService: UtilityService
	) {}

	ngOnInit() {
		this.timeZones = Global.User.currentUser.DashboardTimeZones;
		this.selectedTimeZoneId = this.localWidgetObject.TimeZoneId;
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		if (this.selectedTimeZoneId !== this.localWidgetObject.TimeZoneId) {
			let statement =
				'API.DashboardUpdateWidgetRecordSettings ' +
				'@ParentDashboardId = ' +
				this.localWidgetObject.Id +
				', @Id = ' +
				this.localWidgetObject.WidgetId;
			if (_.isNil(this.localWidgetObject.SiteList)) {
				statement =
					statement +
					', @widgetSiteList = ' +
					this.localWidgetObject.SiteList;
			} else if (!_.isNil(this.localWidgetObject.SiteList)) {
				if (typeof this.localWidgetObject.SiteList === 'string') {
					statement =
						statement +
						", @widgetSiteList = '" +
						this.localWidgetObject.SiteList +
						"'";
				} else {
					this.localWidgetObject.SiteListAsString =
						this.localWidgetObject.SiteList.join();
					statement =
						statement +
						", @widgetSiteList = '" +
						this.localWidgetObject.SiteListAsString +
						"'";
				}
			}
			if (!_.isNil(this.localWidgetObject.WidgetSiteId)) {
				statement =
					statement +
					', @widgetSiteId = ' +
					this.localWidgetObject.WidgetSiteId;
			}
			if (!_.isNil(this.localWidgetObject.WidgetTerminalSystemId)) {
				statement =
					statement +
					', @widgetTerminalSystemId = ' +
					this.localWidgetObject.WidgetTerminalSystemId;
			}

			if (!_.isNil(this.localWidgetObject.WidgetGateSystemId)) {
				statement =
					statement +
					', @widgetGateSystemId = ' +
					this.localWidgetObject.WidgetGateSystemId;
			}
			if (!_.isNil(this.localWidgetObject.WidgetAssetId)) {
				statement =
					statement +
					', @widgetAssetSystemId = ' +
					this.localWidgetObject.WidgetAssetId;
			}

			if (!_.isNil(this.localWidgetObject.TimeScopeId)) {
				statement =
					statement +
					', @widgetTimeScopeId = ' +
					this.localWidgetObject.TimeScopeId;
			}

			statement =
				statement +
				', @widgetTimeZoneId = ' +
				this.selectedTimeZoneId +
				', @selectedTabIndex = ' +
				this.localWidgetObject.SelectedTabIndex;

			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				this.localWidgetObject = data.first();
				let returnedWidget = data.first();
				let dashboardId = returnedWidget.Id;
				let widgetId = data.first().WidgetId;
				let dashboardIndexInGlobalDashboardsArray =
					Global.User.currentUser.Dashboards.findIndex(
						(obj) => obj.Id == dashboardId
					);
				let widgetIndexinGlobalDashboardArray =
					Global.User.currentUser.Dashboards[
						dashboardIndexInGlobalDashboardsArray
					].widgets.findIndex((obj) => obj.WidgetId == widgetId);
				Global.User.currentUser.Dashboards[
					dashboardIndexInGlobalDashboardsArray
				].widgets[widgetIndexinGlobalDashboardArray].TimeZoneId =
					returnedWidget.TimeZoneId;
				let chosenTimeZone = this.timeZones.find(
					(tz) => tz.Id === this.selectedTimeZoneId
				);
				this.utilityService.showToastMessageShared({
					type: 'success',
					message:
						'Time Zone Settings have been changed to ' +
						chosenTimeZone.Description +
						' for ' +
						this.localWidgetObject.WidgetName,
				});
				// this.toastr.success(
				// 	'Time Zone Settings have been changed to ' +
				// 		chosenTimeZone.Description +
				// 		' for ' +
				// 		this.localWidgetObject.WidgetName
				// );
				Global.User.DebugMode && console.log(data);
				let dataInArray = [];
				dataInArray.push(returnedWidget);

				this.dashboardService._widgetTimeChanged.next({
					TimeChangeType: 'time zone',
					data: dataInArray,
				});
				this.dialogRef.close();
			});
		}
	}
}
