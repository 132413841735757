import {
	Component,
	OnInit,
	Input,
	Optional,
	ViewChild,
	ChangeDetectorRef,
	EventEmitter,
	ElementRef,
	ViewEncapsulation,
	NgZone,
} from '@angular/core';

import _ from 'lodash';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { process } from '@progress/kendo-data-query';
import { PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { GridSettings } from '../../_models/grid-settings.interface';

import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

@Component({
	encapsulation: ViewEncapsulation.None,

	selector: 'lib-wheelchair-summary',
	templateUrl: './wheelchair-summary.component.html',
	styleUrls: ['./wheelchair-summary.component.scss'],
})
export class WheelchairSummaryComponent implements OnInit {
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;
	@Input() private wclSummaryCreatedEvent: EventEmitter<any>;
	@ViewChild('tagDataRowForCalculation') tagDataRowForCalculation: ElementRef;
	@ViewChild('eventDataRowForCalculation')
	eventDataRowForCalculation: ElementRef;
	tagDataRowHeight: any = 43;
	eventDataRowHeight: any = 26;
	wclSummaryCreatedSubscription: Subscription;
	tagData: any = [];
	eventData: any = [];
	assetTagIds = [];
	assetObj: any;
	fullDataCache$: any;
	theme: string;
	gaugeTextColor: string;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	fromDashboard: boolean;
	defaultRightWidth = 40;
	defaultLeftWidth = 60;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	selectedMatTabIndex = 0;
	tagDataPageSize: number;
	eventDataPageSize: number;
	fullDataCacheSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	widgetIsInViewSubscription: Subscription;
	isWidgetInView: boolean = false;
	parentContainerSize: any;
	private componentName: string = 'wheelchairlift-summary';
	private displaySignalRUpdates: boolean;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	tagGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'chart',
				title: 'Chart',
				filterable: false,
				_width: 60,
				hidden: false,
				minResizableWidth: 40,
			},
			{
				field: 'ShortTagName',
				title: 'Name',
				filterable: true,
				_width: 150,
				minResizableWidth: 200,
			},
			{
				field: 'JavascriptDate',
				title: 'Date',
				filterable: false,
				_width: 150,
				minResizableWidth: 125,
				filter: 'date',
			},
			{
				field: 'SiteLocalJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				includeInChooser: false,
				_width: 100,
			},
			{
				field: 'UTCJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				includeInChooser: false,
				_width: 100,
			},
			{
				field: 'Value',
				title: 'Value',
				filterable: true,
				_width: 150,
				minResizableWidth: 85,
			},
			{
				field: 'Favorite',
				title: 'Favorite',
				filterable: true,
				filter: 'boolean',
				_width: 150,
			},
		],
	};

	dashboardTimeZoneChangedSubscription: Subscription;
	timeZoneType: any;
	public guid: string;

	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		private ref: ChangeDetectorRef,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private toastr: ToastrService,
		private zone: NgZone
	) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		//Need to evaluate if the component is a widget or a popup. If it is a widget, we need to make sure it is not in view until the subscription tells us it is. If not, we can assume it is a popup, and that it is in fact in view.
		if (this.fromDashboard === true) {
			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription =
				this.dashboardService.widgetInViewChanged$.subscribe(
					(data: any) => {
						if (data.widgetId === this.widgetObject.WidgetId) {
							if (this.tagDataRowForCalculation !== undefined) {
								this.tagDataRowHeight =
									this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
							}

							if (this.eventDataRowForCalculation !== undefined) {
								this.eventDataRowHeight =
									this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
							}

							console.log(this.isWidgetInView);
							console.log(this.widgetObject.isWidgetInView);
							if (
								this.isWidgetInView === false &&
								data.isInView === true
							) {
								this.loadDataForSelectedTab();
							}
							this.isWidgetInView = data.isInView;
						}
					}
				);
		} else {
			this.isWidgetInView = true;
		}

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
				this.gaugeTextColor = theme === 'dark' ? '#eceaea' : '#858585';
			});
		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.timeZoneType =
							this.dashboardService.determineTimeZoneType(
								foundWidgetWithSameWidgetId
							);

						const localDateCol =
							this.tagGridSettings.columnsConfig.find(
								(col) => col.field == 'JavascriptDate'
							);
						const siteDateCol =
							this.tagGridSettings.columnsConfig.find(
								(col) => col.field == 'SiteLocalJavascriptDate'
							);
						const utcDateCol =
							this.tagGridSettings.columnsConfig.find(
								(col) => col.field == 'UTCJavascriptDate'
							);

						localDateCol.hidden = this.timeZoneType !== 'User Time';
						siteDateCol.hidden = this.timeZoneType !== 'Site Time';
						utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;
					}
					//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
					this.initialize();
				});
		}
		
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(
			((event.newRect.height - 120) / this.tagDataRowHeight) * 3
		);
		this.eventDataPageSize = Math.floor(
			((event.newRect.height - 120) / this.eventDataRowHeight) * 3
		);
		this.parentContainerSize = event.newRect.height;
		this.displaySignalRUpdates &&
			console.log(
				this.componentName + ': PageSize = ' + this.tagDataPageSize
			);
	}

	saveGridSettings() {
		if (this.fromDashboard) {
			let hiddenColsArray = [
				this.tagDataGrid.kendoGridParent.columnList.columns._results,
			];
			let gridObjectsArray = [
				{
					gridObject: this.tagDataGrid.kendoGridParent,
					gridState: this.tagGridSettings.state,
				},
			];

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				hiddenColsArray,
				this.widgetObject.WidgetId
			);
			this.kendoSettingsService
				.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	mapGridSettings(gridSettings: GridSettings) {
		if (this.fromDashboard) {
			const state = gridSettings.state;
			Global.User.DebugMode &&
				console.log(this.componentName + ': state = %O', state);
			let emptyArray: any = [];
			return {
				state,
				columnsConfig: gridSettings.columnsConfig.sort(
					(a, b) => a.orderIndex - b.orderIndex
				),
				gridData:
					this.dataService.cache !== undefined
						? process(this.tagData, state)
						: emptyArray,
			};
		}
	}

	initialize() {
		Global.User.DebugMode &&
			console.log(this.componentName + ': initialize invoked...');

		if (this.fromDashboard && !this.widgetObject.WidgetGateSystemId) {
			this.isDataLoading = false;
			return;
		}
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);

		this.cacheJbtStandardObsLookup =
			this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetGateSystemId) {
				if (this.widgetObject.WidgetId !== undefined) {
					this.finishInitializingWidget();
				} else if (this.wclSummaryCreatedEvent) {
					this.wclSummaryCreatedSubscription =
						this.wclSummaryCreatedEvent.subscribe((data) => {
							if (data.WidgetId === this.widgetObject.WidgetId) {
								this.finishInitializingWidget();
							}
						});
				}
			}
		} else {
			this.assetObj = this.dataObject.assetObj;
			// Get the Global Theme and set the text colors for the gauges
			this.theme = Global.Theme;
			this.gaugeTextColor = this.theme === 'dark' ? '#eceaea' : '#858585';
			this.finishInitializingWidget();
		}
	}

	finishInitializingWidget() {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': finishInitializingWidget invoked...'
			);

		if (!this.assetObj) {
			this.assetObj = this.dataService.cache.assets.find(
				(a) =>
					a.SiteId == this.widgetObject.WidgetSiteId &&
					a.ParentSystemId == this.widgetObject.WidgetGateSystemId &&
					a.Name == 'WCL'
			);
		}

		if (this.assetObj) {
			console.log('Getting tags....');
			this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
					this.assetObj.Id.toString()
				)
				.subscribe((data) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								': dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
							data
						);

					if (this.fromDashboard) {
						// Reload saved Kendo Grid Settings
						if (this.widgetObject.WidgetKendoUIJson) {
							let jsonObjectParsed =
								this.kendoSettingsService.parseReturnedSettingsForDates(
									this.widgetObject.WidgetKendoUIJson
								);

							//the first item in the array is the gridSettings for the first tab of data for this widget.
							let mergedTemplateAndSavedColumnsForData =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.tagGridSettings,
									jsonObjectParsed[0]
								);
							this.tagGridSettings = this.mapGridSettings(
								mergedTemplateAndSavedColumnsForData
							);
						}

						this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
							[this.tagGridSettings.columnsConfig],
							this.widgetObject.WidgetId
						);

						// Get the Global Theme and set the text colors for the gauges
						this.theme = Global.Theme;
						this.gaugeTextColor =
							this.theme === 'dark' ? '#eceaea' : '#858585';

						this.loadDataForSelectedTab();
					}
					const localDateCol =
						this.tagGridSettings.columnsConfig.find(
							(col) => col.field == 'JavascriptDate'
						);
					const siteDateCol = this.tagGridSettings.columnsConfig.find(
						(col) => col.field == 'SiteLocalJavascriptDate'
					);

					const utcDateCol = this.tagGridSettings.columnsConfig.find(
						(col) => col.field == 'UTCJavascriptDate'
					);

					localDateCol.hidden = this.timeZoneType !== 'User Time';
					siteDateCol.hidden = this.timeZoneType !== 'Site Time';
					utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
					localDateCol.includeInChooser = !localDateCol.hidden;
					siteDateCol.includeInChooser = !siteDateCol.hidden;
					utcDateCol.includeInChooser = !utcDateCol.hidden;
					if (this.dataObject !== undefined) {
						this.dataObject.isDisplayDataLive = true;
					} else if (this.widgetObject !== undefined) {
						this.widgetObject.isDisplayDataLive = true;
					}
					this.formatTagDataForGrid(data);
				});
		}
	}

	loadDataForSelectedTab() {
		switch (this.selectedMatTabIndex) {
			case 0: // Tag Data
				if (
					this.tagGridSettings.gridData === undefined ||
					this.tagGridSettings.gridData.length === 0
				) {
				} else {
					if (this.tagDataGrid) {
						this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
							this.assetObj.Id
						].Tags);
					}
				}
				break;
		}
	}

	formatTagDataForGrid(tags: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': formatTagDataForGrid invoked...'
			);

		this.isDataLoading = false;

		this.assetTagIds = this.dataService.cache.assetsObject[
			this.assetObj.Id
		].Tags.map((t) => t.TagId);

		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
							this.assetObj.Id
						].Tags);
		}
		this.getSignalRUpdates();
	}

	getSignalRUpdates() {
		let assetObjectInCache = this.dataService.cache.assetsObject[this.assetObj.Id];

		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName),
										 TagNamePrefix: [tagNamePrefixesString]   })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTagGridData();
				});
			});	
	}

	matTabHasChanged(e) {
		this.selectedMatTabIndex = e.index;

		switch (this.selectedMatTabIndex) {
			case 0:
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
							this.assetObj.Id
						].Tags);
				}
				break;
		}
	}

	updateTagGridData() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].Tags);
		}
	}
}
