import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import _ from 'lodash';
import swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import { SelectorService } from '../../services/selector.service';

@Component({
	selector: 'lib-tactical-widget-summary-tile-popup',
	templateUrl: './tactical-widget-summary-tile-popup.component.html',
	styleUrls: ['./tactical-widget-summary-tile-popup.component.scss'],
})
export class TacticalWidgetSummaryTilePopupComponent implements OnInit {
	public unsavedChanges = [];
	private swalWithBootstrapButtons: any;
	public operators = ['Is greater than', 'Is less than', 'Is equal to', 'Is not equal to'];
	public lastSearchTerm: any;
	public showAddNewRecord: boolean = false;
	public JBTStandardObservationId: any;
	public conditionColor = '#FF0000';
	public conditionOperator = 'Is greater than';
	public conditionValue;
	widgetId: any;
	summaryTileIndex: any;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public inputObject: any,
		private dataService: DataService,
		private utilityService: UtilityService,
		public dialogRef: MatDialogRef<TacticalWidgetSummaryTilePopupComponent>
	) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-success',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		this.widgetId = this.inputObject[0] ? this.inputObject[0].WidgetId : this.inputObject.WidgetId;
		this.summaryTileIndex = this.inputObject[0] ? this.inputObject[0].SummaryTileIndex : this.inputObject.SummaryTileIndex;

	}

	addConditionRecord() {
		this.showAddNewRecord = true;
	}

	addConditionRecordtoDB() {

		if (this.widgetId != null && this.summaryTileIndex != null && this.conditionValue != null) {
			let statement = "API.TacticalDashboard_AddTacticalDashboardWidgetConditionalShadingRecord "
				+ "@WidgetId = " +
				this.widgetId +
				", @SummaryTileIndex = " +
				this.summaryTileIndex +
				", @HexColor = " +
				this.conditionColor +
				", @Value = " +
				this.conditionValue;

			statement = statement + ", @Operator = '" + this.conditionOperator + "'";

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					this.utilityService.showToastMessageShared({
						type: 'success',
						message: "'" + this.conditionOperator + ' ' + this.conditionValue + ' ' + this.conditionColor + "' Condition has been added.",
					});
					// add record to inputObject
					this.inputObject[this.inputObject.length] = data[0];
					this.showAddNewRecord = false;
				});

		}
		else {
			this.utilityService.showToastMessageShared({
				type: 'error',
				message: "Please enter all the fields",
			});
		}

	}


	operatorChanged(event, record) {
		let operatorChange = {
			Id: record.Id,
			Operator: event.value,
		};
		//discover if change has already been made before save
		let operatorChanges = this.unsavedChanges.findIndex(
			(change) => change.Id === operatorChange.Id
		);
		if (operatorChanges !== -1) {
			this.unsavedChanges[operatorChanges].Operator = event.value;
		} else {
			this.unsavedChanges.push(operatorChange);
		}
	}

	colorChanged(event, record) {
		let colorChange = {
			Id: record.Id,
			HexColor: event,
		};
		//discover if change has already been made before save
		let colorChanges = this.unsavedChanges.findIndex(
			(change) => change.Id === colorChange.Id
		);
		if (colorChanges !== -1) {
			this.unsavedChanges[colorChanges].HexColor = event;
		} else {
			this.unsavedChanges.push(colorChange);
		}
	}

	valueChanged(event, record) {
		console.log(event);
		console.log(record);
		let maxValueChange = {
			Id: record.Id,
			Value: event.target.value,
		};
		//discover if change has already been made before save
		let maxValueChanges = this.unsavedChanges.findIndex(
			(change) => change.Id === maxValueChange.Id
		);
		if (maxValueChanges !== -1) {
			this.unsavedChanges[maxValueChanges].Value = event.target.value;
		} else {
			this.unsavedChanges.push(maxValueChange);
		}
	}

	onDelete(condition: any) {

		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				html: 'This will remove ID <strong>' + condition.Operator + ' ' + condition.Value + ' - ' + condition.HexColor +
					'</strong> from Conditional Shading ',
				showCancelButton: true,
				confirmButtonText: 'Delete Condition',
				cancelButtonText: 'Cancel',
				reverseButtons: false,
			})
			.then((result) => {
				if (result.value) {

					let statement =
						'API.TacticalDashboard_DeleteTacticalDashboardWidgetConditionalShadingRecord @Id = ' +
						condition.Id;
					this.dataService
						.SQLActionAsPromise(statement)
						.then((data) => {
							console.log(data);

							let message = condition.Operator + ' ' + condition.Value + ' - ' + condition.HexColor + " :  has been deleted.";
							this.utilityService.showToastMessageShared({
								type: 'success',
								message: message,
							});
							// remove record from inputObject
							this.inputObject = this.inputObject.filter(i => i.Id != condition.Id);
						});
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "'" + condition.Operator + "' has NOT been deleted.",
					});

				}
			});
	}

	saveChanges() {
		this.unsavedChanges.forEach((uc, index) => {
			let sqlStatement =
				'API.TacticalDashboard_UpdateTacticalDashboardWidgetConditionalShadingRecord @TacticalDashboardWidgetConditionalShadingId = ' +
				parseInt(uc.Id);
			if (!_.isNil(uc.HexColor)) {
				sqlStatement =
					sqlStatement + ", @HexColor = '" + uc.HexColor + "'";
			}
			if (!_.isNil(uc.Operator)) {
				sqlStatement =
					sqlStatement + ", @Operator = '" + uc.Operator + "'";
			}
			if (!_.isNil(uc.Value)) {
				sqlStatement = sqlStatement + ', @Value = ' + parseInt(uc.Value);
			}
			this.dataService.SQLActionAsPromise(sqlStatement).then((data) => {
				console.log(data);
				if (index === this.unsavedChanges.length - 1) {
					//finished with color changes, now save series type changes
					//this.localWidgetObject.refreshComponent = true;
					this.unsavedChanges = [];
					this.dialogRef.close();
				}
			});
		});
	}

	public closeDialogWindow(): void {
		this.closeWindow(false);
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			this.dialogRef.close();
		}
	}
}
