import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagGraphComponent } from './tag-graph.component';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import { SassHelperModule } from '../../_helpers/sass-helper/sass-helper.module';
import { MatTabsModule } from '@angular/material/tabs';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [TagGraphComponent],
	imports: [
		CommonModule,
		CenteredMessageModule,
		LoadingSpinnerModule,
		SassHelperModule,
		MatTabsModule,
		KendoGridParentModule,
	],
	exports: [TagGraphComponent],
})
export class TagGraphModule {}
