import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingLessonsComponent } from './training-lessons.component';
import { GridModule } from '@progress/kendo-angular-grid';

@NgModule({
	declarations: [TrainingLessonsComponent],
	imports: [CommonModule, GridModule],
	exports: [TrainingLessonsComponent],
})
export class TrainingLessonsModule {}
