import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import videojs from 'video.js';

@Component({
	selector: "lib-videojs-player",
	templateUrl: "./video-player.component.html",
	styleUrls: ["./video-player.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnDestroy, OnInit {
	@ViewChild("target", { static: true }) target: ElementRef;

	// See options: https://videojs.com/guides/options
	@Input() options: {
		fluid: boolean;
		aspectRatio: string;
		autoplay: boolean;
		sources: {
			src: string;
			type: string;
		}[];
	};

	player: any;

	public videojs: any;

	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		this.player = videojs(
			this.target.nativeElement,
			this.options,
			function onPlayerReady() {
				console.log('onPlayerReady', this);
			}
		);
	}

	// Dispose the player OnDestroy
	ngOnDestroy() {
		if (this.player) {
		  this.player.dispose();
		}
	}

}
