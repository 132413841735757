import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AssetSummaryViewComponent } from './asset-summary-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { AssetSummaryParentModule } from '../asset-summary-parent/asset-summary-parent.module';
import { RouterModule } from '@angular/router';
import { MaterialInputModule } from '../material-input/material-input.module';
import { GridsterModule } from 'angular-gridster2';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
	declarations: [AssetSummaryViewComponent],
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		MatTreeModule,
		MatIconModule,
		AssetSummaryParentModule,
		RouterModule,
		MaterialInputModule,
		GridsterModule,
		CommonModule,
		MatFormFieldModule,
		MatSelectModule
	],
	exports: [AssetSummaryViewComponent],
})
export class AssetSummaryViewModule {}
