import { Component, Input, OnInit, Optional } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';



@Component({
  selector: 'lib-pca-comp-pressure',
  templateUrl: './pca-comp-pressure.component.html',
  styleUrls: ['./pca-comp-pressure.component.scss']
})
export class PcaCompPressureComponent implements OnInit {

  @Optional() @Input() summaryInputTagId: any;
  @Optional() @Input() widgetInputTagId: any;
  @Optional() @Input() jbtStandardObservationIdInput: any;
  tagId: any;
  jbtStandardObservationId: any;
  fullDataCacheSubscription: any;
  theme: string;
  tagObject: any;
  titleText: any;
  pressureValue: any;
  gaugeTextColor: string;

  constructor(public dataService: DataService) { }

  ngOnInit() {
    if (this.summaryInputTagId !== undefined) {
      this.tagId = this.summaryInputTagId;
    } else if (this.widgetInputTagId !== undefined) {
      this.tagId = this.widgetInputTagId;
    } else if (this.jbtStandardObservationIdInput !== undefined) {
      this.jbtStandardObservationId = this.jbtStandardObservationIdInput;
    }

    if (!Global.FullDataCacheExists) {
      this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
        if (data === true) {
          if (Global.Theme === "light") {
            this.theme = "light";
          } else if (Global.Theme === "dark") {
            this.theme = "dark";
          }
          this.initialize();
          this.fullDataCacheSubscription.unsubscribe();
        }
      });
    } else {
      if (Global.Theme === "light") {
        this.theme = "light";
      } else if (Global.Theme === "dark") {
        this.theme = "dark";
      }
      this.initialize();
    }

  }

  initialize() {
    if (this.tagId != undefined)
      this.tagObject = this.dataService.cache.tagsObject[this.tagId];
    this.gaugeTextColor = "#858585";

    //Primary Compressure 1 Pressure
    if ((this.tagId != undefined && this.tagObject.JBTStandardObservationId == 3848) || this.jbtStandardObservationId == 3848) {
      this.titleText = 'Press Head 1';
      if (this.tagObject != undefined) this.pressureValue = this.tagObject.Value;
    }
    //Primary Compressure 1 Suction
    if ((this.tagId != undefined && this.tagObject.JBTStandardObservationId == 3912) || this.jbtStandardObservationId == 3912) {
      this.titleText = 'Press Suc 1';
      if (this.tagObject != undefined) this.pressureValue = this.tagObject.Value;
    }
    //Primary Compressure 2 Pressure
    if ((this.tagId != undefined && this.tagObject.JBTStandardObservationId == 3909) || this.jbtStandardObservationId == 3909) {
      this.titleText = 'Press Head 2';
      if (this.tagObject != undefined) this.pressureValue = this.tagObject.Value;
    }
    //Primary Compressure 2 Suction
    if ((this.tagId != undefined && this.tagObject.JBTStandardObservationId == 3913) || this.jbtStandardObservationId == 3913) {
      this.titleText = 'Press Suc 2';
      if (this.tagObject != undefined) this.pressureValue = this.tagObject.Value;
    }
    //Primary Compressure 3 Pressure
    if ((this.tagId != undefined && this.tagObject.JBTStandardObservationId == 3910) || this.jbtStandardObservationId == 3910) {
      this.titleText = 'Press Head 3';
      if (this.tagObject != undefined) this.pressureValue = this.tagObject.Value;
    }
    //Primary Compressure 3 Suction
    if ((this.tagId != undefined && this.tagObject.JBTStandardObservationId == 1872) || this.jbtStandardObservationId == 1872) {
      this.titleText = 'Press Suc 3';
      if (this.tagObject != undefined) this.pressureValue = this.tagObject.Value;
    }

  }

}

