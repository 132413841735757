import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../app/_layout/navbar/user-settings/user-settings.component";
import { MaterialModule } from '../../_other-modules/material.module';
import { UserModule } from '../user.module';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { Observable } from 'rxjs';
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { filter, map, startWith, switchMapTo } from "rxjs/operators";
import { SignalRCoreService } from 'projects/shared-lib/src/lib/services/signalr-core.service';


  const fadeSlide = trigger('fadeSlide', [
		transition(':enter', [
			  style({ opacity: 0, transform: 'translateX(250px)' }),
			  animate(1000, style({ opacity: 1, transform: 'translateX(0)' }))
		]),
		transition(':leave', [
			  animate(1000,style({ opacity: 0, transform: 'translateX(250px)' }))
		])
	]);

@Component({
	selector: "personal-alert-escalation",
	animations: [fadeSlide],
	templateUrl: "./personal-alert-escalation.component.html",
	styleUrls: ["./personal-alert-escalation.component.scss"]
})
export class PersonalAlertEscalationComponent implements OnInit {

	public isLoading: boolean = true;
	public personRecord: any;
	public inputPerson : FormControl;
	public alternateEmailForwards = [];
	public filteredAssociatedPeople$: Observable<any[]>;
	public associatedPeople = [];

	public global: IGlobal = Global;

	constructor(private signalRCore: SignalRCoreService, private dataService: DataService, private router: Router, public dialogRef: MatDialogRef<PersonalAlertEscalationComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	public displayFn(person: any): string {
		return person && person.FullName
			? person.FullName
			: '';
	}

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		if (Global.User.isLoggedIn) {
			this.dataService.SQLActionAsPromise("API.GetUserProfileRecord " + Global.User.currentUser.Id).then((data: any) => {
				console.log("data = %O", data);
				this.personRecord = data.first();

				this.setUpForm();
			});
		} else {
			this.router.navigate(["registration"]);
		}
	}

	setUpForm() {
		// Get list of people associated with this person
		var peopleInOrganization = this.dataService.cache.people.filter((p: any) => {
			return p.OrganizationId == this.personRecord.OrganizationId && p.FullName
		});

		// trim out any duplicates
		this.associatedPeople = peopleInOrganization.filter((value, index, self) => self.map(x => x.Id).indexOf(value.Id) == index)

		this.dataService.SQLActionAsPromise("API.PersonEmailForwardsForAlertManagement " + this.personRecord.PersonId).then((data: any) => {
			if (data.length > 0) {
				//console.log(this.dataService.cache.people);
				data.forEach((item) => {
					let person = this.dataService.cache.people.find((person) => {
						return person.Id == item.ForwardToPersonId;
					});

					if (person !== undefined) {
						this.alternateEmailForwards.push({
							text: person.FullNameWithUsername,
							person: person,
							Id: item.Id
						});

						// remove anyone already in the list
						this.associatedPeople.splice(this.associatedPeople.findIndex(p=> p.Id == item.ForwardToPersonId),1);

					}
				});
				console.log('escalations:', this.alternateEmailForwards);
			}
		});


		this.inputPerson = new FormControl('');

		this.filteredAssociatedPeople$ = this.inputPerson.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.associatedPeople.slice();
			}),
		);

		this.isLoading = false;
	}

	private _filter(name: string): any[] {
		const filterValue = name.toLowerCase();

		return this.associatedPeople.filter(option => option.FullName.toLowerCase().includes(filterValue));
	}

	public clickAddEscalationPerson()
	{
		let value = this.inputPerson.value;
		let newPersonToAddId = value.Id;

		if(newPersonToAddId)
		{
			let statement = 'API.AlertManagementInsertPersonEmailForward ' + this.personRecord.PersonId + ', ' + newPersonToAddId;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				// add to the list

				if (data.length > 0) {
					//console.log(this.dataService.cache.people);
					data.forEach((item) => {
						let person = this.dataService.cache.people.find((person) => {
							return person.Id == item.ForwardToPersonId;
						});

						if (person !== undefined) {
							this.alternateEmailForwards.push({
								text: person.FullNameWithUsername,
								person: person,
								Id: item.Id
							});
						}
					});
					console.log('added escalation:', this.alternateEmailForwards);

					// remove entry from selection box
					this.inputPerson.setValue('');

					// remove the new addition from the list
					this.associatedPeople.splice(this.associatedPeople.findIndex(p=> p.Id == newPersonToAddId),1);

					// notify add
					this.signalRCore.notifySpecificClient(Global.SignalR.ClientId, 'personal-alert-escalation', data);

				}
			});
		}
	}


	public clickRemoveEscalationPerson(personEmailForward: any)
	{
		console.log('person forward to remove:', personEmailForward.Id);

		let statement = 'API.AlertManagementDeletePersonEmailForward ' + personEmailForward.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			// remove from the my escalations list
			let index = this.alternateEmailForwards.findIndex(p => {return p.Id == personEmailForward.Id});
			this.alternateEmailForwards.splice(index, 1);

			// add to the selection list
			let person = this.dataService.cache.people.find((person) => {
				return person.Id == personEmailForward.person.Id;
			});

			this.associatedPeople.push(person);

			// notify delete
			this.signalRCore.notifySpecificClient(Global.SignalR.ClientId, 'personal-alert-escalation', data);
		});

	}


	closeDialog(): void {
		this.dialogRef.close();
	}

}
