import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Global } from "../_constants/global.variables";
import { DataService } from "./data.service";

@Injectable({
	providedIn: "root",
})
export class SelectorService {
	constructor(private http: HttpClient, private dataService: DataService) {}

	SQLActionAsObservable(sqlStatement: string) {
		//-- the selectors MUST have async pipes that communicate with SQL Server using Observables, and they must return the exact data without compression. --Kirk T. Sherer, June 28, 2021.
		var methodType = "post";

		var httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: Global.User.currentUser.ODataAccessToken,
		});

		//--returns an observable.  Usage is to call this function with a .subscribe function. --Kirk T. Sherer, February 11, 2020.

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		Global.User.DebugMode &&
			console.log("selector-service: sqlStatement = " + sqlStatement);

		const http$ = this.http
			.post(
				this.dataService.questionControlDataUrl,
				sqlStatement,
				httpOptions
			)
			.pipe(
				tap((data) => {
					Global.User.DebugMode &&
						console.log(
							"selector-service: this.SQLActionAsObservable data = %O",
							data
						);
				}),
				map((data: any) => data.body), //--don't need all the other stuff with the HTTPResponse, so just send back the body that will contain the data from the stored procedure call. --Kirk T. Sherer, January 6, 2020.
				catchError((err) => of([]))
			);
		return http$;
	}
}
