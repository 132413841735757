<ng-template #popTitle><h4 style="color: black"><b>{{tooltipInformationObject.title !== '' ? tooltipInformationObject.title: 'No Tag Found'}}</b></h4>
	<p style="color: black" *ngIf="tooltipInformationObject.title !== ''">Right Click To Copy To Clipboard</p>
	</ng-template>
<ng-template #popContent>
	<div *ngIf="tooltipInformationObject.title !== ''">
		<p style="color: black"><b>TagName:</b> </p>
		<p style="color: black">{{tooltipInformationObject.TagName}}</p>
		<p style="color: black"><b>TagId:</b> </p>
		<p style="color: black">{{tooltipInformationObject.TagId}}</p>
		<p style="color: black"><b>ListOfStdIdsUsed:</b> </p>
		<p style="color: black">{{tooltipInformationObject.ListOfStdIdsUsed}}</p>
		<p style="color: black"><b>StdId:</b> </p>
		<p style="color: black">{{tooltipInformationObject.StdId}}</p>
		<p style="color: black"><b>Datetime:</b> </p>
		<p style="color: black">{{tooltipInformationObject.Datetime | date :'MM/dd/yyyy&nbsp;HH:mm:ss.SSS' }}</p>
		<p style="color: black"><b>Value:</b> </p>
		<p style="color: black">{{tooltipInformationObject.Value}}</p>
		<p style="color: black"><b>ValueWhenActive:</b> </p>
		<p style="color: black">{{tooltipInformationObject.ValueWhenActive}}</p>
	</div>
	<div *ngIf="tooltipInformationObject.title === ''">
		<p style="color: black"><b>ListOfStdIdsUsed:</b> </p>
		<p style="color: black">{{tooltipInformationObject.ListOfStdIdsUsed}}</p>
	</div>

</ng-template>

<div *ngIf="!isDataLoading" class="box">
  <div class="d-flex flex-row">
  <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'unitStatus')" container="body" (mouseenter)="tooltipInformation('unitStatus')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave"  (click)="openTagGraphSingle(unitStatus)" class="col box-stat-content" *ngIf="assetTags.UnitStatus != undefined" [ngClass]="{ 'unit-on': assetTags.UnitStatus, 'unit-off': !assetTags.UnitStatus}" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          {{assetTags.Unit}}<br>{{assetTags.RunTime}}
      </div>
  <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'bridgeMode')" container="body" (mouseenter)="tooltipInformation('bridgeMode')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave"  (click)="openTagGraphSingle(bridgeMode)" class="col box-stat-content" *ngIf="assetTags.OutputStatus != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          Output<br>
          <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.OutputStatus}}</span>
      </div>
      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'operationMode')" container="body" (mouseenter)="tooltipInformation('operationMode')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(operationMode)" class="col box-stat-content" *ngIf="assetTags.OperationMode != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          Op Mode<br>
          <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.OperationMode}}</span>
      </div>
      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'dischMode')" container="body" (mouseenter)="tooltipInformation('dischMode')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(dischMode)" class="col box-stat-content" *ngIf="assetTags.DischMode != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          Disch Mode<br>
          <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.DischMode}}</span>
      </div>
      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'overnightMode')" container="body" (mouseenter)="tooltipInformation('overnightMode')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(overnightMode)" class="col box-stat-content" *ngIf="assetTags.OvernightMode != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          Overnight<br>
          <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.OvernightMode}}</span>
      </div>
  </div>
  <div class="d-flex flex-row" style="justify-content: normal !important;">
      <div class="d-flex flex-column">
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'dischargeTemp')" container="body" (mouseenter)="tooltipInformation('dischargeTemp')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(dischargeTemp)" class="col box-stat-bottom" *ngIf="assetTags.DischargeTemp != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Disch Temp <br>
              <kendo-sparkline #pcaCharts type="area" renderAs="canvas" [series]="assetTags.pcaDischTempChart" [resizeRateLimit]="0">
                  <kendo-chart-area background="transparent"> </kendo-chart-area>
                  <kendo-chart-tooltip>
                      <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                          <span>
                              {{ dataItem.obsTimeFormatted }}
                              <br />
                              <strong> {{ dataItem.y }} </strong>
                          </span>
                      </ng-template>
                  </kendo-chart-tooltip>
              </kendo-sparkline>
              <span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.DischargeTemp}}<span>&#8457;</span></span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ambTemp')" container="body" (mouseenter)="tooltipInformation('ambTemp')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ambTemp)" class="col box-stat-bottom" *ngIf="assetTags.AmbTemp != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Amb Temp <br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmbTemp}}<span>&#8457;</span></span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'deltaT')" container="body" (mouseenter)="tooltipInformation('deltaT')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(deltaT)" class="col box-stat-bottom" *ngIf="assetTags.DeltaT != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              DeltaT <br>
              <span [ngClass]="{ 'hmi-alarm': (assetTags.UnitStatus && assetTags.DischargeTemp > assetTags.AmbTemp) , 'hmi-blue': (assetTags.UnitStatus && assetTags.DischargeTemp < assetTags.AmbTemp) , 'hmi-off': !assetTags.UnitStatus}">{{assetTags.DeltaT}}<span>&#8457;</span></span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'bridgeAirTemp')" container="body" (mouseenter)="tooltipInformation('bridgeAirTemp')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(bridgeAirTemp)" class="col box-stat-bottom" *ngIf="assetTags.BridgeAirTemp != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Bridge Air Temp <br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.BridgeAirTemp}}<span>&#8457;</span></span>
          </div>
          <div  class="col box-stat-bottom" *ngIf="assetTags.UnitStatus != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Blower <br>
              <img (click)="openTagGraphSingle(unitStatus)" [style.height.px]="40" [src]="assetTags.UnitStatus ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/BlowerOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/BlowerOff.svg'" /><br>
              <span *ngIf="assetTags.BlowerSpeed != undefined" (click)="openTagGraphSingle(blowerSpeed)">
                  <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.BlowerSpeed"></kendo-progressbar>
              </span><br>
              <span *ngIf="assetTags.BlowerSpeed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}" (click)="openTagGraphSingle(blowerSpeed)">
                  {{assetTags.BlowerSpeed}}%
              </span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'dischPressure')" container="body" (mouseenter)="tooltipInformation('dischPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(dischPressure)" class="col box-stat-bottom" *ngIf="assetTags.DischPressure != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Disch Pressure<br>
              <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.DischPressure"></kendo-progressbar><br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.DischPressure}}</span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'unitCurrent')" container="body" (mouseenter)="tooltipInformation('unitCurrent')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(unitCurrent)" class="col box-stat-bottom" *ngIf="assetTags.UnitCurrent != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Current <br>
              <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.UnitCurrent"></kendo-progressbar><br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.UnitCurrent}}</span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ambientHumidity')" container="body" (mouseenter)="tooltipInformation('ambientHumidity')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ambientHumidity)" class="col box-stat-bottom" *ngIf="assetTags.AmbientHumidity != undefined"  [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Humidity <br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmbientHumidity}}%</span>
          </div>
      </div>
     <div class="d-flex flex-column basis" *ngIf="!viewHeatModule">
          <div class="d-flex flex-row">
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor1Status')" container="body" (mouseenter)="tooltipInformation('compressor1Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(compressor1Status)" class="col box-stat" *ngIf="assetTags.Compressor1Status != undefined">
                  Comp 1 <br>
                  <img [style.height.px]="50" [src]="assetTags.Compressor1Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOff.svg'" />
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor2Status')" container="body" (mouseenter)="tooltipInformation('compressor2Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(compressor2Status)" class="col box-stat" *ngIf="assetTags.Compressor2Status != undefined">
                  Comp 2 <br>
                  <img [style.height.px]="50" [src]="assetTags.Compressor2Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOff.svg'" />
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor3Status')" container="body" (mouseenter)="tooltipInformation('compressor3Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(compressor3Status)" class="col box-stat" *ngIf="assetTags.Compressor3Status != undefined">
                  Comp 3 <br>
                  <img [style.height.px]="50" [src]="assetTags.Compressor3Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOff.svg'" />
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor4Status')" container="body" (mouseenter)="tooltipInformation('compressor4Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(compressor4Status)" class="col box-stat" *ngIf="assetTags.Compressor4Status != undefined">
                  Comp 4 <br>
                  <img [style.height.px]="50" [src]="assetTags.Compressor4Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOff.svg'" />
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor5Status')" container="body" (mouseenter)="tooltipInformation('compressor5Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(compressor5Status)" class="col box-stat" *ngIf="assetTags.Compressor5Status != undefined">
                  Comp 5 <br>
                  <img [style.height.px]="50" [src]="assetTags.Compressor5Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOff.svg'" />
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor6Status')" container="body" (mouseenter)="tooltipInformation('compressor6Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(compressor6Status)" class="col box-stat" *ngIf="assetTags.Compressor6Status != undefined">
                  Comp 6 <br>
                  <img [style.height.px]="50" [src]="assetTags.Compressor6Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/CompressorOff.svg'" />
              </div>
          </div>
          <div class="d-flex flex-row">
              <div class="col" *ngIf="assetTags.Compressor1Status != undefined">
                  <div class="linear-gauge-section">
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor1SuctionPressure')" container="body" (mouseenter)="tooltipInformation('compressor1SuctionPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor1SuctionPressure != undefined" (click)="openTagGraphSingle(compressor1SuctionPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor1SuctionPressureColor"  [value]="assetTags.Compressor1SuctionPressure"></kendo-progressbar>
                          <h5>Suc.</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor1SuctionPressure}}</span></h5>
                      </div>
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor1HeadPressure')" container="body" (mouseenter)="tooltipInformation('compressor1HeadPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor1HeadPressure != undefined" (click)="openTagGraphSingle(compressor1HeadPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor1HeadPressureColor" [value]="assetTags.Compressor1HeadPressure"></kendo-progressbar>
                          <h5>Head</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor1HeadPressure}}</span></h5>
                      </div>
                  </div>
              </div>
              <div class="col" *ngIf="assetTags.Compressor2Status != undefined">
                  <div class="linear-gauge-section">
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor2SuctionPressure')" container="body" (mouseenter)="tooltipInformation('compressor2SuctionPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor2SuctionPressure != undefined" (click)="openTagGraphSingle(compressor2SuctionPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor2SuctionPressureColor"  [value]="assetTags.Compressor2SuctionPressure"></kendo-progressbar>
                          <h5>Suc.</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor2SuctionPressure}}</span></h5>
                      </div>
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor2HeadPressure')" container="body" (mouseenter)="tooltipInformation('compressor2HeadPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor2HeadPressure != undefined" (click)="openTagGraphSingle(compressor2HeadPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor2HeadPressureColor" [value]="assetTags.Compressor2HeadPressure"></kendo-progressbar>
                          <h5>Head</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor2HeadPressure}}</span></h5>
                      </div>
                  </div>
              </div>
              <div class="col" *ngIf="assetTags.Compressor3Status != undefined">
                  <div class="linear-gauge-section">
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor3SuctionPressure')" container="body" (mouseenter)="tooltipInformation('compressor3SuctionPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor3SuctionPressure != undefined" (click)="openTagGraphSingle(compressor3SuctionPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor3SuctionPressureColor" [value]="assetTags.Compressor3SuctionPressure"></kendo-progressbar>
                          <h5>Suc.</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor3SuctionPressure}}</span></h5>
                      </div>
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor3HeadPressure')" container="body" (mouseenter)="tooltipInformation('compressor3HeadPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor3HeadPressure != undefined" (click)="openTagGraphSingle(compressor3HeadPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor3HeadPressureColor" [value]="assetTags.Compressor3HeadPressure"></kendo-progressbar>
                          <h5>Head</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor3HeadPressure}}</span></h5>
                      </div>
                  </div>
              </div>
              <div class="col" *ngIf="assetTags.Compressor4Status != undefined">
                  <div class="linear-gauge-section">
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor4SuctionPressure')" container="body" (mouseenter)="tooltipInformation('compressor4SuctionPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor4SuctionPressure != undefined" (click)="openTagGraphSingle(compressor4SuctionPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor4SuctionPressureColor" [value]="assetTags.Compressor4SuctionPressure"></kendo-progressbar>
                          <h5>Suc.</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor4SuctionPressure}}</span></h5>
                      </div>
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor4HeadPressure')" container="body" (mouseenter)="tooltipInformation('compressor4HeadPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor4HeadPressure != undefined" (click)="openTagGraphSingle(compressor4HeadPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor4HeadPressureColor" [value]="assetTags.Compressor4HeadPressure"></kendo-progressbar>
                          <h5>Head</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor4HeadPressure}}</span></h5>
                      </div>
                  </div>
              </div>
              <div class="col" *ngIf="assetTags.Compressor5Status != undefined">
                  <div class="linear-gauge-section">
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor5SuctionPressure')" container="body" (mouseenter)="tooltipInformation('compressor5SuctionPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor5SuctionPressure != undefined" (click)="openTagGraphSingle(compressor5SuctionPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor5SuctionPressureColor" [value]="assetTags.Compressor5SuctionPressure"></kendo-progressbar>
                          <h5>Suc.</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor5SuctionPressure}}</span></h5>
                      </div>
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor5HeadPressure')" container="body" (mouseenter)="tooltipInformation('compressor5HeadPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor5HeadPressure != undefined" (click)="openTagGraphSingle(compressor5HeadPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor5HeadPressureColor" [value]="assetTags.Compressor5HeadPressure"></kendo-progressbar>
                          <h5>Head</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor5HeadPressure}}</span></h5>
                      </div>
                  </div>
              </div>
              <div class="col" *ngIf="assetTags.Compressor6Status != undefined">
                  <div class="linear-gauge-section">
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor6SuctionPressure')" container="body" (mouseenter)="tooltipInformation('compressor6SuctionPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor6SuctionPressure != undefined" (click)="openTagGraphSingle(compressor6SuctionPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor6SuctionPressureColor" [value]="assetTags.Compressor6SuctionPressure"></kendo-progressbar>
                          <h5>Suc.</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor6SuctionPressure}}</span></h5>
                      </div>
                      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'compressor6HeadPressure')" container="body" (mouseenter)="tooltipInformation('compressor6HeadPressure')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.Compressor6HeadPressure != undefined" (click)="openTagGraphSingle(compressor6HeadPressure)" class="linear-gauge-container">
                          <kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.Compressor6HeadPressureColor" [value]="assetTags.Compressor6HeadPressure"></kendo-progressbar>
                          <h5>Head</h5>
                          <h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.Compressor6HeadPressure}}</span></h5>
                      </div>
                  </div>
              </div>
          </div>
          <div class="d-flex flex-row">
              <div class="col box-stat" *ngIf="assetTags.Compressor1Status != undefined && (assetTags.CondenserFan1Status != undefined || assetTags.CondenserFan2Status != undefined || assetTags.CondenserFan3Status != undefined || assetTags.CondenserFan4Status != undefined || assetTags.CondenserFan5Status != undefined || assetTags.CondenserFan6Status != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
                  Cond. Fan <br>
                  <img [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan1')" container="body" (mouseenter)="tooltipInformation('condenserFan1')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(condenserFan1)" [style.height.px]="25" [src]="assetTags.CondenserFan1Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOff.svg'" />
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan1Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan1Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan1Status && assetTags.CondenserFan1Speed != undefined" (click)="openTagGraphSingle(condenserFan1Speed)">
                      <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.CondenserFan1Speed"></kendo-progressbar>
                  </span><br>
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan1Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan1Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan1Status && assetTags.CondenserFan1Speed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}" (click)="openTagGraphSingle(condenserFan1Speed)">
                      {{assetTags.CondenserFan1Speed}}
                  </span>
              </div>
              <div class="col box-stat" *ngIf="assetTags.Compressor2Status != undefined && (assetTags.CondenserFan1Status != undefined || assetTags.CondenserFan2Status != undefined || assetTags.CondenserFan3Status != undefined || assetTags.CondenserFan4Status != undefined || assetTags.CondenserFan5Status != undefined || assetTags.CondenserFan6Status != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
                  Cond. Fan <br>
                  <img [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan2')" container="body" (mouseenter)="tooltipInformation('condenserFan2')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(condenserFan2)" [style.height.px]="25" [src]="assetTags.CondenserFan2Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOff.svg'" />
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan2Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan2Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan2Status && assetTags.CondenserFan2Speed != undefined" (click)="openTagGraphSingle(condenserFan2Speed)" >
                      <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.CondenserFan2Speed"></kendo-progressbar>
                  </span><br>
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan2Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan2Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan2Status && assetTags.CondenserFan2Speed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}" (click)="openTagGraphSingle(condenserFan2Speed)" >
                      {{assetTags.CondenserFan2Speed}}
                  </span>
              </div>
              <div class="col box-stat" *ngIf="assetTags.Compressor3Status != undefined && (assetTags.CondenserFan1Status != undefined || assetTags.CondenserFan2Status != undefined || assetTags.CondenserFan3Status != undefined || assetTags.CondenserFan4Status != undefined || assetTags.CondenserFan5Status != undefined || assetTags.CondenserFan6Status != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
                  Cond. Fan <br>
                  <img [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan3')" container="body" (mouseenter)="tooltipInformation('condenserFan3')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(condenserFan3)" [style.height.px]="25" [src]="assetTags.CondenserFan3Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOff.svg'" />
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan3Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan3Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan3Status && assetTags.CondenserFan3Speed != undefined" (click)="openTagGraphSingle(condenserFan3Speed)" >
                      <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.CondenserFan3Speed"></kendo-progressbar>
                  </span><br>
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan3Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan3Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan3Status && assetTags.CondenserFan3Speed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}"  (click)="openTagGraphSingle(condenserFan3Speed)" >
                      {{assetTags.CondenserFan3Speed}}
                  </span>
              </div>
              <div class="col box-stat" *ngIf="assetTags.Compressor4Status != undefined && (assetTags.CondenserFan1Status != undefined || assetTags.CondenserFan2Status != undefined || assetTags.CondenserFan3Status != undefined || assetTags.CondenserFan4Status != undefined || assetTags.CondenserFan5Status != undefined || assetTags.CondenserFan6Status != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
                  Cond. Fan <br>
                  <img [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan4')" container="body" (mouseenter)="tooltipInformation('condenserFan4')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(condenserFan4)" [style.height.px]="25" [src]="assetTags.CondenserFan4Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOff.svg'" />
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan4Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan4Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan4Status && assetTags.CondenserFan4Speed != undefined" (click)="openTagGraphSingle(condenserFan4Speed)">
                      <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.CondenserFan4Speed"></kendo-progressbar>
                  </span><br>
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan4Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan4Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan4Status && assetTags.CondenserFan4Speed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}" (click)="openTagGraphSingle(condenserFan4Speed)">
                      {{assetTags.CondenserFan4Speed}}
                  </span>
              </div>
              <div class="col box-stat" *ngIf="assetTags.Compressor5Status != undefined && (assetTags.CondenserFan1Status != undefined || assetTags.CondenserFan2Status != undefined || assetTags.CondenserFan3Status != undefined || assetTags.CondenserFan4Status != undefined || assetTags.CondenserFan5Status != undefined || assetTags.CondenserFan6Status != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
                  Cond. Fan <br>
                  <img [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan5')" container="body" (mouseenter)="tooltipInformation('condenserFan5')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(condenserFan5)" [style.height.px]="25" [src]="assetTags.CondenserFan5Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOff.svg'" />
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan5Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan5Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan5Status && assetTags.CondenserFan5Speed != undefined" (click)="openTagGraphSingle(condenserFan5Speed)">
                      <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.CondenserFan5Speed"></kendo-progressbar>
                  </span><br>
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan5Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan5Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan5Status && assetTags.CondenserFan5Speed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}" (click)="openTagGraphSingle(condenserFan5Speed)">
                      {{assetTags.CondenserFan5Speed}}
                  </span>
              </div>
              <div class="col box-stat" *ngIf="assetTags.Compressor6Status != undefined && (assetTags.CondenserFan1Status != undefined || assetTags.CondenserFan2Status != undefined || assetTags.CondenserFan3Status != undefined || assetTags.CondenserFan4Status != undefined || assetTags.CondenserFan5Status != undefined || assetTags.CondenserFan6Status != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
                  Cond. Fan <br>
                  <img [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan6')" container="body" (mouseenter)="tooltipInformation('condenserFan6')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(condenserFan6)" [style.height.px]="25" [src]="assetTags.CondenserFan6Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/FanOff.svg'" />
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan6Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan6Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan6Status && assetTags.CondenserFan6Speed != undefined" (click)="openTagGraphSingle(condenserFan6Speed)">
                      <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.CondenserFan6Speed"></kendo-progressbar>
                  </span><br>
                  <span [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'condenserFan6Speed')" container="body" (mouseenter)="tooltipInformation('condenserFan6Speed')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" *ngIf="assetTags.CondenserFan6Status && assetTags.CondenserFan6Speed != undefined" [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}" (click)="openTagGraphSingle(condenserFan6Speed)">
                      {{assetTags.CondenserFan6Speed}}
                  </span>
              </div>
          </div>
          <div class="d-flex flex-row">
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'suctionTemp1')" container="body" (mouseenter)="tooltipInformation('suctionTemp1')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(suctionTemp1)" class="col box-stat" *ngIf="assetTags.Compressor1Status != undefined && (assetTags.SuctionTemp1 != undefined || assetTags.SuctionTemp2 != undefined || assetTags.SuctionTemp3 != undefined || assetTags.SuctionTemp4 != undefined || assetTags.SuctionTemp5 != undefined || assetTags.SuctionTemp6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Suction Temp<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuctionTemp1}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'suctionTemp2')" container="body" (mouseenter)="tooltipInformation('suctionTemp2')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(suctionTemp2)" class="col box-stat" *ngIf="assetTags.Compressor2Status != undefined && (assetTags.SuctionTemp1 != undefined || assetTags.SuctionTemp2 != undefined || assetTags.SuctionTemp3 != undefined || assetTags.SuctionTemp4 != undefined || assetTags.SuctionTemp5 != undefined || assetTags.SuctionTemp6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Suction Temp<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuctionTemp2}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'suctionTemp3')" container="body" (mouseenter)="tooltipInformation('suctionTemp3')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(suctionTemp3)" class="col box-stat" *ngIf="assetTags.Compressor3Status != undefined && (assetTags.SuctionTemp1 != undefined || assetTags.SuctionTemp2 != undefined || assetTags.SuctionTemp3 != undefined || assetTags.SuctionTemp4 != undefined || assetTags.SuctionTemp5 != undefined || assetTags.SuctionTemp6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Suction Temp<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuctionTemp3}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'suctionTemp4')" container="body" (mouseenter)="tooltipInformation('suctionTemp4')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(suctionTemp4)" class="col box-stat" *ngIf="assetTags.Compressor4Status != undefined && (assetTags.SuctionTemp1 != undefined || assetTags.SuctionTemp2 != undefined || assetTags.SuctionTemp3 != undefined || assetTags.SuctionTemp4 != undefined || assetTags.SuctionTemp5 != undefined || assetTags.SuctionTemp6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Suction Temp<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuctionTemp4}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'suctionTemp5')" container="body" (mouseenter)="tooltipInformation('suctionTemp5')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(suctionTemp5)" class="col box-stat" *ngIf="assetTags.Compressor5Status != undefined && (assetTags.SuctionTemp1 != undefined || assetTags.SuctionTemp2 != undefined || assetTags.SuctionTemp3 != undefined || assetTags.SuctionTemp4 != undefined || assetTags.SuctionTemp5 != undefined || assetTags.SuctionTemp6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Suction Temp<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuctionTemp5}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'suctionTemp6')" container="body" (mouseenter)="tooltipInformation('suctionTemp6')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(suctionTemp6)" class="col box-stat" *ngIf="assetTags.Compressor6Status != undefined && (assetTags.SuctionTemp1 != undefined || assetTags.SuctionTemp2 != undefined || assetTags.SuctionTemp3 != undefined || assetTags.SuctionTemp4 != undefined || assetTags.SuctionTemp5 != undefined || assetTags.SuctionTemp6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Suction Temp<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuctionTemp6}}<span>&#8457;</span></span>
              </div>
          </div>
          <div class="d-flex flex-row">
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'hotGas1')" container="body" (mouseenter)="tooltipInformation('hotGas1')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(hotGas1)" class="col box-stat" *ngIf="assetTags.Compressor1Status != undefined && (assetTags.HotGas1 != undefined || assetTags.HotGas2 != undefined || assetTags.HotGas3 != undefined || assetTags.HotGas4 != undefined || assetTags.HotGas5 != undefined || assetTags.HotGas6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Hot Gas<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HotGas1}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'hotGas2')" container="body" (mouseenter)="tooltipInformation('hotGas2')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(hotGas2)" class="col box-stat" *ngIf="assetTags.Compressor2Status != undefined && (assetTags.HotGas1 != undefined || assetTags.HotGas2 != undefined || assetTags.HotGas3 != undefined || assetTags.HotGas4 != undefined || assetTags.HotGas5 != undefined || assetTags.HotGas6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Hot Gas<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HotGas2}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'hotGas3')" container="body" (mouseenter)="tooltipInformation('hotGas3')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(hotGas3)" class="col box-stat" *ngIf="assetTags.Compressor3Status != undefined && (assetTags.HotGas1 != undefined || assetTags.HotGas2 != undefined || assetTags.HotGas3 != undefined || assetTags.HotGas4 != undefined || assetTags.HotGas5 != undefined || assetTags.HotGas6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Hot Gas<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HotGas3}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'hotGas4')" container="body" (mouseenter)="tooltipInformation('hotGas4')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(hotGas4)" class="col box-stat" *ngIf="assetTags.Compressor4Status != undefined && (assetTags.HotGas1 != undefined || assetTags.HotGas2 != undefined || assetTags.HotGas3 != undefined || assetTags.HotGas4 != undefined || assetTags.HotGas5 != undefined || assetTags.HotGas6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Hot Gas<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HotGas4}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'hotGas5')" container="body" (mouseenter)="tooltipInformation('hotGas5')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(hotGas5)" class="col box-stat" *ngIf="assetTags.Compressor5Status != undefined && (assetTags.HotGas1 != undefined || assetTags.HotGas2 != undefined || assetTags.HotGas3 != undefined || assetTags.HotGas4 != undefined || assetTags.HotGas5 != undefined || assetTags.HotGas6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Hot Gas<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HotGas5}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'hotGas6')" container="body" (mouseenter)="tooltipInformation('hotGas6')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(hotGas6)" class="col box-stat" *ngIf="assetTags.Compressor6Status != undefined && (assetTags.HotGas1 != undefined || assetTags.HotGas2 != undefined || assetTags.HotGas3 != undefined || assetTags.HotGas4 != undefined || assetTags.HotGas5 != undefined || assetTags.HotGas6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Hot Gas<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.HotGas6}}%</span>
              </div>
          </div>
          <div class="d-flex flex-row">
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'expansionValve1')" container="body" (mouseenter)="tooltipInformation('expansionValve1')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(expansionValve1)" class="col box-stat" *ngIf="assetTags.Compressor1Status != undefined && (assetTags.ExpansionValve1 != undefined || assetTags.ExpansionValve2 != undefined || assetTags.ExpansionValve3 != undefined || assetTags.ExpansionValve4 != undefined || assetTags.ExpansionValve5 != undefined || assetTags.ExpansionValve6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Expansion Valve<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.ExpansionValve1}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'expansionValve2')" container="body" (mouseenter)="tooltipInformation('expansionValve2')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(expansionValve2)" class="col box-stat" *ngIf="assetTags.Compressor2Status != undefined && (assetTags.ExpansionValve1 != undefined || assetTags.ExpansionValve2 != undefined || assetTags.ExpansionValve3 != undefined || assetTags.ExpansionValve4 != undefined || assetTags.ExpansionValve5 != undefined || assetTags.ExpansionValve6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Expansion Valve<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.ExpansionValve2}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'expansionValve3')" container="body" (mouseenter)="tooltipInformation('expansionValve3')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(expansionValve3)" class="col box-stat" *ngIf="assetTags.Compressor3Status != undefined && (assetTags.ExpansionValve1 != undefined || assetTags.ExpansionValve2 != undefined || assetTags.ExpansionValve3 != undefined || assetTags.ExpansionValve4 != undefined || assetTags.ExpansionValve5 != undefined || assetTags.ExpansionValve6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Expansion Valve<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.ExpansionValve3}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'expansionValve4')" container="body" (mouseenter)="tooltipInformation('expansionValve4')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(expansionValve4)" class="col box-stat" *ngIf="assetTags.Compressor4Status != undefined && (assetTags.ExpansionValve1 != undefined || assetTags.ExpansionValve2 != undefined || assetTags.ExpansionValve3 != undefined || assetTags.ExpansionValve4 != undefined || assetTags.ExpansionValve5 != undefined || assetTags.ExpansionValve6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Expansion Valve<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.ExpansionValve4}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'expansionValve5')" container="body" (mouseenter)="tooltipInformation('expansionValve5')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(expansionValve5)" class="col box-stat" *ngIf="assetTags.Compressor5Status != undefined && (assetTags.ExpansionValve1 != undefined || assetTags.ExpansionValve2 != undefined || assetTags.ExpansionValve3 != undefined || assetTags.ExpansionValve4 != undefined || assetTags.ExpansionValve5 != undefined || assetTags.ExpansionValve6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Expansion Valve<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.ExpansionValve5}}%</span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'expansionValve6')" container="body" (mouseenter)="tooltipInformation('expansionValve6')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(expansionValve6)" class="col box-stat" *ngIf="assetTags.Compressor6Status != undefined && (assetTags.ExpansionValve1 != undefined || assetTags.ExpansionValve2 != undefined || assetTags.ExpansionValve3 != undefined || assetTags.ExpansionValve4 != undefined || assetTags.ExpansionValve5 != undefined || assetTags.ExpansionValve6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Expansion Valve<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.ExpansionValve6}}%</span>
              </div>
          </div>
          <div class="d-flex flex-row">
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'superHeat1')" container="body" (mouseenter)="tooltipInformation('superHeat1')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(superHeat1)" class="col box-stat" *ngIf="assetTags.Compressor1Status != undefined && (assetTags.SuperHeat1 != undefined || assetTags.SuperHeat2 != undefined || assetTags.SuperHeat3 != undefined || assetTags.SuperHeat4 != undefined || assetTags.SuperHeat5 != undefined || assetTags.SuperHeat6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Super Heat<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuperHeat1}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'superHeat2')" container="body" (mouseenter)="tooltipInformation('superHeat2')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(superHeat2)" class="col box-stat" *ngIf="assetTags.Compressor2Status != undefined && (assetTags.SuperHeat1 != undefined || assetTags.SuperHeat2 != undefined || assetTags.SuperHeat3 != undefined || assetTags.SuperHeat4 != undefined || assetTags.SuperHeat5 != undefined || assetTags.SuperHeat6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Super Heat<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuperHeat2}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'superHeat3')" container="body" (mouseenter)="tooltipInformation('superHeat3')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(superHeat3)" class="col box-stat" *ngIf="assetTags.Compressor3Status != undefined && (assetTags.SuperHeat1 != undefined || assetTags.SuperHeat2 != undefined || assetTags.SuperHeat3 != undefined || assetTags.SuperHeat4 != undefined || assetTags.SuperHeat5 != undefined || assetTags.SuperHeat6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Super Heat<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuperHeat3}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'superHeat4')" container="body" (mouseenter)="tooltipInformation('superHeat4')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(superHeat4)" class="col box-stat" *ngIf="assetTags.Compressor4Status != undefined && (assetTags.SuperHeat1 != undefined || assetTags.SuperHeat2 != undefined || assetTags.SuperHeat3 != undefined || assetTags.SuperHeat4 != undefined || assetTags.SuperHeat5 != undefined || assetTags.SuperHeat6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Super Heat<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuperHeat4}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'superHeat5')" container="body" (mouseenter)="tooltipInformation('superHeat5')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(superHeat5)" class="col box-stat" *ngIf="assetTags.Compressor5Status != undefined && (assetTags.SuperHeat1 != undefined || assetTags.SuperHeat2 != undefined || assetTags.SuperHeat3 != undefined || assetTags.SuperHeat4 != undefined || assetTags.SuperHeat5 != undefined || assetTags.SuperHeat6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Super Heat<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuperHeat5}}<span>&#8457;</span></span>
              </div>
              <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'superHeat6')" container="body" (mouseenter)="tooltipInformation('superHeat6')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(superHeat6)" class="col box-stat" *ngIf="assetTags.Compressor6Status != undefined && (assetTags.SuperHeat1 != undefined || assetTags.SuperHeat2 != undefined || assetTags.SuperHeat3 != undefined || assetTags.SuperHeat4 != undefined || assetTags.SuperHeat5 != undefined || assetTags.SuperHeat6 != undefined)" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">Super Heat<br>
                  <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.SuperHeat6}}<span>&#8457;</span></span>
              </div>
          </div>
		  <button title="Switch to Heat View" class="jbt-btn-sm toggle-button" (click)="switchView(true)"><i class="tim-icons icon-refresh-02"></i></button>
     </div>
	 <div class="d-flex flex-column basis" *ngIf="viewHeatModule">
		<div class="d-flex flex-row">
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'heat1Status')" container="body" (mouseenter)="tooltipInformation('heat1Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(heat1Status)" class="col box-stat" *ngIf="assetTags.Heat1Status != undefined">
				<h4> Heat 1 </h4>
				<img [style.height.px]="100"  [src]="assetTags.Heat1Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOff.svg'" />
				<h4><span> {{ assetTags.Heat1Status ? 'On' : 'Off'}} </span></h4>
			</div>
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'heat2Status')" container="body" (mouseenter)="tooltipInformation('heat2Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(heat2Status)" class="col box-stat" *ngIf="assetTags.Heat2Status != undefined">
				<h4> Heat 2 </h4>
				<img [style.height.px]="100"  [src]="assetTags.Heat2Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOff.svg'" />
				<h4><span> {{ assetTags.Heat2Status ? 'On' : 'Off'}} </span></h4>
			</div>
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'heat3Status')" container="body" (mouseenter)="tooltipInformation('heat3Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(heat3Status)" class="col box-stat" *ngIf="assetTags.Heat3Status != undefined">
				<h4> Heat 3 </h4>
				<img [style.height.px]="100"  [src]="assetTags.Heat3Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOff.svg'" />
				<h4><span> {{ assetTags.Heat3Status ? 'On' : 'Off'}} </span></h4>
			</div>
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'heat4Status')" container="body" (mouseenter)="tooltipInformation('heat4Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(heat4Status)" class="col box-stat" *ngIf="assetTags.Heat4Status != undefined">
				<h4> Heat 4 </h4>
				<img [style.height.px]="100"  [src]="assetTags.Heat4Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOn.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOff.svg'" />
				<h4><span> {{ assetTags.Heat4Status ? 'On' : 'Off'}} </span></h4>
			</div>
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'heat5Status')" container="body" (mouseenter)="tooltipInformation('heat5Status')"  [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300"  triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(heat5Status)" class="col box-stat" *ngIf="assetTags.Heat5Status != undefined">
				<h4> Heat 5 </h4>
				<img [style.height.px]="100"  [src]="assetTags.Heat5Status ? global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOn.svg.svg' :global.imagesUrl + 'assets/img/gs-images/pca-quick-view/HeatOff.svg'" />
				<h4><span> {{ assetTags.Heat5Status ? 'On' : 'Off'}} </span></h4>
			</div>
		</div>
		<div class="d-flex flex-row"></div>
		<div class="d-flex flex-row"></div>
		<div class="d-flex flex-row"></div>
		<div class="d-flex flex-row"></div>
		<div class="d-flex flex-row"></div>
		<div class="d-flex flex-row"></div>
		<button title="Switch to Compressor View" class="jbt-btn-sm toggle-button" (click)="switchView(false)"><i class="tim-icons icon-refresh-02"></i></button>
   </div>
  </div>
</div>
