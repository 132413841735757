import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import PerfectScrollbar from "perfect-scrollbar";
import { ToastrService } from "ngx-toastr";



import { BehaviorSubject } from 'rxjs';
import { SecurityService } from 'projects/shared-lib/src/lib/services/security.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { UtilityService } from 'projects/shared-lib/src/lib/services/utility.service';

const misc: any = {
	sidebar_mini_active: true,
};

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
	private checkUserLogin$: any;

	constructor(
		public router: Router,
		public toastr: ToastrService,
		public securityService: SecurityService,
		public dataService: DataService,
		private utilityService: UtilityService
	) {}

	@HostListener('window:scroll', ['$event'])
	showNavbarButton = () => {
		const mainPanel: any = document.getElementsByClassName('main-panel')[0];
		const navbarMinimize: any = document.getElementsByClassName(
			'navbar-minimize-fixed'
		)[0];

		if (
			document.documentElement.scrollTop > 50 ||
			document.scrollingElement.scrollTop > 50 ||
			mainPanel.scrollTop > 50
		) {
			navbarMinimize.style.opacity = 1;
		} else if (
			document.documentElement.scrollTop <= 50 ||
			document.scrollingElement.scrollTop <= 50 ||
			mainPanel.scrollTop <= 50
		) {
			navbarMinimize.style.opacity = 0;
		}
	};

	ngOnInit() {
		if (!this.securityService.currentUserIsLoggedIn) {
			this.router.navigate(['authentication']);
		}

		const currentUser = this.securityService.getCurrentUser();

		Global.User.DebugMode &&
			console.log('LayoutComponent: currentUser = %O', currentUser);

		this.checkUserLogin$ = new BehaviorSubject(Global.User.isLoggedIn);
		this.checkUserLogin$.subscribe(
			(isLoggedIn: boolean) => {
				if (isLoggedIn) {
					this.dataService.initializeSiteWithUserPreferences();

					const mainPanel: any =
						document.getElementsByClassName('main-panel')[0];
					const sidebar: any =
						document.getElementsByClassName('sidebar-wrapper')[0];

					if (navigator.platform.indexOf('Win') > -1) {
						document.documentElement.className +=
							' perfect-scrollbar-on';
						document.documentElement.classList.remove(
							'perfect-scrollbar-off'
						);
						let ps = new PerfectScrollbar(mainPanel);
						ps = new PerfectScrollbar(sidebar);
						const tables: any =
							document.querySelectorAll('.table-responsive');
						for (let i = 0; i < tables.length; i++) {
							ps = new PerfectScrollbar(tables[i]);
						}
					}
					this.showNavbarButton();
					Global.User.DebugMode &&
						console.log('layout.component.ts misc = %O', misc);
				}
			},
			(err) =>
				Global.User.DebugMode &&
				console.log(`Error checking user login: ${err}`)
		);
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		this.checkUserLogin$ && this.checkUserLogin$.unsubscribe();
	}

	minimizeSidebar() {
		this.dataService.toggleSidebarMini();
	}

	showSidebarMessage(message) {
		this.utilityService.showToastMessageShared({
			type: 'info',
			message:
				'<span data-notify="icon" class="tim-icons icon-bell-55"></span>',
			title: message,
			override: {
				timeOut: 4000,
				closeButton: true,
				enableHtml: true,
				toastClass: 'alert alert-danger alert-with-icon',
				positionClass: 'toast-top-right',
			},
		});
		// this.toastr.show('<span data-notify="icon" class="tim-icons icon-bell-55"></span>', message, {
		// 	timeOut: 4000,
		// 	closeButton: true,
		// 	enableHtml: true,
		// 	toastClass: "alert alert-danger alert-with-icon",
		// 	positionClass: "toast-top-right",
		// });
	}
}
