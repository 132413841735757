import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import _ from 'lodash';
import { UtilityService } from 'projects/shared-lib/src/lib/services/utility.service';
import { EditWidgetComponent } from "../edit-widget/edit-widget.component";

@Component({
	selector: 'lib-canvas-gse-popup-settings',
	templateUrl: './canvas-gse-popup-settings.component.html',
	styleUrls: ['./canvas-gse-popup-settings.component.scss'],
})
export class CanvasGsePopupSettingsComponent implements OnInit {
	assetModelsByOrganization: Array<any>;
	templatesExistingForUser: Array<any>;
	assetTypes: Array<any>;
	assetModels: Array<any>;
	assets: Array<any>;
	templateSelected: boolean;
	selectedTemplateId: any;
	canvasTemplateName: any;
	canvasTemplateTypeId: any;
	currentUserOrganizationId: any;
	showNoAssetTemplateMsg: boolean;
	gseForm: FormGroup;
	templateRetrievalMessage: string;
	editWidgetComponentModal: MatDialogRef<EditWidgetComponent, any>;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogModalInputObject: any,
		private dataService: DataService,
		private utilityService: UtilityService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<CanvasGsePopupSettingsComponent>
	) { }

	ngOnInit() {
		console.log(this.dialogRef);
		console.log(this.dialogModalInputObject);

		this.canvasTemplateTypeId = this.dialogModalInputObject != undefined ? this.dialogModalInputObject.CanvasTemplateTypeId : 1;

		this.gseForm = new FormGroup({
			templateId: new FormControl(''),
			assetTypeId: new FormControl(''),
			assetModelId: new FormControl(''),
			assetId: new FormControl(''),
			templateName: new FormControl(''),
		});
		this.initialize();
	}

	initialize() {
		this.showNoAssetTemplateMsg = false;
		this.templateSelected = false;
		this.canvasTemplateName = null;
		this.selectedTemplateId = null;


		this.templatesExistingForUser = this.dataService.cache.canvasTemplates.filter(
			(p: any) => {
				return p.CanvasTemplateTypeId == this.canvasTemplateTypeId &&
						p.CreatorUserId == (Global.User.currentUser.Id).toString();
			})

		let assetTypeIds = [
			86292, 86293, 86294, 86295, 86296, 86297, 86298, 86299, 86300,86313,86314
		];
		let allAssetTypes = this.dataService.cache.assetTypes.toArray();
		let allAssets = this.dataService.cache.assets.toArray();
		let allAssetModels = this.dataService.cache.assetModels.toArray();
		this.assetTypes = allAssetTypes.filter((t) => assetTypeIds.includes(t.Id));
		this.assets = allAssets.filter((t) => assetTypeIds.includes(t.AssetTypeId));
		this.assetModels = allAssetModels.filter((t) => assetTypeIds.includes(t.AssetTypeId));
		this.assetTypes = _.orderBy(this.assetTypes, 'Name', 'asc');
		this.assetModels = _.orderBy(this.assetModels, 'Model', 'asc');
		this.assets = _.orderBy(this.assets, 'Name', 'asc');

		if (this.templatesExistingForUser.length == 0)
			this.showNoAssetTemplateMsg = true;
	}

	submitForm(gseForm) {
		console.log(gseForm);
		let obj = {
			templateId: gseForm.value.templateId ? parseInt(gseForm.value.templateId) : null,
			assetTypeId: gseForm.value.assetTypeId ? parseInt(gseForm.value.assetTypeId) : null,
			assetModelId: gseForm.value.assetModelId ? parseInt(gseForm.value.assetModelId) : null,
			assetId: gseForm.value.assetId ? parseInt(gseForm.value.assetId) : null,
			templateName: gseForm.value.templateName,
			userId: Global.User.currentUser.Id,
		};
		this.dialogRef.close(obj);
	}

	checkForTemplateSelected(event) {
		this.selectedTemplateId = event.target.value;
		this.templateSelected = true;
	}

	checkForCanvasTemplateName(event) {
		this.canvasTemplateName = event.target.value;
	}

	canvasTemplateToRename() {
		let canvasTemplateId = parseInt(this.selectedTemplateId);
		let templateName = this.templatesExistingForUser.find((t) => t.Id == canvasTemplateId).Name;
		this.editWidgetComponentModal = this.dialog.open(EditWidgetComponent, {
			width: "50%",
			height: "20%",
			data: { data: templateName, title: 'Re-Name Canvas Template' }
		});

		this.editWidgetComponentModal.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				let newTemplateName = result.split("'").join("''");
				let statement =
					'API.Canvas_UpdateCanvasTemplateRecord ' +
					'@CanvasTemplateId = ' +
					parseInt(this.selectedTemplateId) +
					", @Name = '" +
					newTemplateName + "'";
				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						this.utilityService.showToastMessageShared({
							type: 'success',
							message: 'Canvas Template Name Changed!',
						});
					});
			}
		});
	}
	canvasTemplateToDelete() {
		let canvasTemplateId = parseInt(this.selectedTemplateId);
		let templateName = this.templatesExistingForUser.find((t) => t.Id == canvasTemplateId).Name;
		this.dataService
			.SQLActionAsPromise(
				'Canvas_DeleteCanvasTemplateRecord @CanvasTemplateId =  ' +
				canvasTemplateId
			)
			.then((data: any) => {
				this.closeDialogWindow();
				let message = templateName + " - Canvas Template has been deleted.";
				this.utilityService.showToastMessageShared({
					type: 'success',
					message: message,
				});

			});


	}
	public closeDialogWindow(): void {
		this.closeWindow(false);
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			this.dialogRef.close();
		}
	}
}
