<div class="sidebar-wrapper sidebar-menu">
  <div class="logo" [style.background-color]="[149, 153, 157, 158].includes(global?.User?.currentUser?.Organization?.Id) ? 'white' : ''">
    <a href="{{imageUrl}}" class="simple-text logo-normal">
      <div>
        <img src="{{ [149, 153, 157, 158].includes(global?.User?.currentUser?.Organization?.Id) ? global.imagesUrl +  'assets/images/VocationalGraphics/Pierce_Clearsky_ColorBeta.png' : iOPSLogoImage }}" />
      </div>
    </a>
    <!-- <a *ngIf="sidebarMiniSelected" href="{{imageUrl}}" class="simple-text logo-normal">
      <div>
        <img src="{{ iOPSTinyLogoImage }}" class="sidebar-mini-image" />
      </div>
    </a> -->
  </div>
  <ul class="nav">
    <li *ngFor="let menuitem of menuItems" routerLinkActive="active">
      <!--If is a single link-->
      <a
        (click)="updateGlobalMenuTitle(menuitem)"
        [routerLink]="[menuitem.path]"
        routerLinkActive="active"
        *ngIf="menuitem.type === 'link'"
      >
        <i class="{{ menuitem.icontype }}"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it has a submenu-->
      <a
        data-toggle="collapse"
        routerLinkActive="active"
        *ngIf="menuitem.type === 'sub'"
        (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
        [attr.aria-expanded]="!menuitem.isCollapsed"
        [attr.aria-controls]="menuitem.collapse"
      >
        <i class="{{ menuitem.icontype }}"></i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub'"
        [collapse]="menuitem.isCollapsed"
        [isAnimated]="true"
      >
        <ul class="nav">
          <li
            *ngFor="let childitems of menuitem.children"
            routerLinkActive="active"
          >
            <!--If it's a single link with no Id -->
            <a
              (click)="updateGlobalMenuTitle(menuitem, childitems)"
              [routerLink]="[menuitem.path, childitems.path]"
              routerLinkActive="active"
              *ngIf="childitems.type === 'link' && childitems.id == null"
            >
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">{{ childitems.title }}</span>
            </a>
            <!--If it's a single function with no Id -->
            <a
              (click)="
                updateGlobalMenuTitle(menuitem, childitems)
              "
              routerLinkActive="active"
              *ngIf="childitems.type === 'func' && childitems.id == null"
            >
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">{{ childitems.title }}</span>
            </a>
            <!--If it's a single link with an Id -->
            <a
              (click)="updateGlobalMenuTitle(menuitem, childitems)"
              [routerLink]="[menuitem.path, childitems.path, childitems.id]"
              routerLinkActive="active"
              *ngIf="childitems.type === 'link' && childitems.id != null"
            >
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">{{ childitems.title }}</span>
            </a>
            <!--If it has a submenu-->
            <a
              data-toggle="collapse"
              (click)="childitems.isCollapsed = !childitems.isCollapsed"
              [attr.aria-expanded]="!childitems.isCollapsed"
              [attr.aria-controls]="childitems.collapse"
              *ngIf="childitems.type === 'sub'"
            >
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal"
                >{{ childitems.title }}<b class="caret"></b
              ></span>
            </a>
            <!--Display the submenu items-->
            <div
              id="{{ childitems.collapse }}"
              class="collapse"
              *ngIf="childitems.type === 'sub'"
              [collapse]="childitems.isCollapsed"
              [isAnimated]="true"
            >
              <ul class="nav">
                <li
                  *ngFor="let childitem of childitems.children"
                  routerLinkActive="active"
                >
                  <a
                    (click)="updateGlobalMenuTitle(menuitem, childitems)"
                    [routerLink]="[menuitem.path, childitems.path]"
                    routerLinkActive="active"
                    *ngIf="childitems.id == null"
                  >
                    <span class="sidebar-mini-icon">{{ childitem.smallTitle }}</span>
                    <span class="sidebar-normal">{{ childitem.title }}</span>
                  </a>
                  <a
                    (click)="updateGlobalMenuTitle(menuitem, childitems)"
                    [routerLink]="[
                      menuitem.path,
                      childitems.path,
                      childitems.id
                    ]"
                    routerLinkActive="active"
                    *ngIf="childitems.id != null"
                  >
                    <span class="sidebar-mini-icon">{{ childitem.smallTitle }}</span>
                    <span class="sidebar-normal">{{ childitem.title }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
