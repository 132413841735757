import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerIssuesItemComponent } from './customer-issues-item.component';
import {
	ExcelModule,
	GridModule,
	PDFModule,
} from '@progress/kendo-angular-grid';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { BadgeModule } from '@progress/kendo-angular-indicators';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
	declarations: [CustomerIssuesItemComponent],
	imports: [
		CommonModule,
		GridModule,
		MatTooltipModule,
		MatIconModule,
		MatBadgeModule,
		BadgeModule,
		DynamicFormModule,
		ReactiveFormsModule,
		FormsModule,
		ExcelModule,
		PDFModule,
		LoadingSpinnerModule,
	],
	exports: [CustomerIssuesItemComponent],
})
export class CustomerIssuesItemModule {}
