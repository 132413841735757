import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserEngagementReportComponent } from './user-engagement-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MaterialInputModule } from '../material-input/material-input.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CenteredMessageModule } from '../centered-message/centered-message.module';

@NgModule({
	declarations: [
		UserEngagementReportComponent
	],
	imports: [
		CommonModule,
	FormsModule,
	ReactiveFormsModule,
	KendoGridParentModule,
	MatFormFieldModule,
	MatSelectModule,
		MaterialInputModule,
		MatDatepickerModule,
		MatIconModule,
		MatInputModule,
		CenteredMessageModule
  ],
  exports: [
	UserEngagementReportComponent
  ]
})
export class UserEngagementReportModule { }
