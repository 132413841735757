import {
	Component,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
	EventEmitter,
	Optional,
} from '@angular/core';


import _ from 'lodash';
import { AnyARecord } from 'dns';

import { ToastrService } from 'ngx-toastr';
import { GseService } from '../../services/gse.service';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { UtilityService } from '../../services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { DashboardService } from '../../services/dashboard.service';
import Swal from 'sweetalert2';
import { MapsService } from '../../services/maps.service';
import { IGlobal } from '../../_models/global.model';
import { ITag } from '../../_models/tag.model';
import { anchorIcon } from '@progress/kendo-svg-icons';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';


declare const google: any;
declare const markerClusterer:any;


@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-generic-overview-map',
	templateUrl: './generic-overview-map.component.html',
	styleUrls: ['./generic-overview-map.component.scss'],
})
export class GenericOverviewMapComponent {
	@Optional() @Input() widgetObject: any;
	@Input() tagOptions: any;
	@Optional() @Input() gridSettings: any;
	@Input() assetList: any;

	@Input() selectedMatTabIndex: any = undefined;
	@Input() matTabIndexForMap: any = undefined;
	@Output() openTagGraphSingleTag: any = new EventEmitter<any>();
	@Optional() @Input() showOptionForGeographyZones: any = false;

	listOfPolygons: any = [];

	map: any;
	markerclusterer: any;
	markers: any[];
	mapOptions: any;
	mapDrawn: boolean = false;
	infoWindows: any[];
	zonesAndZonesInstances: any;
	swalWithBootstrapButtons: any;
	tagGraphSingleModalSubscription: any;
	public global: IGlobal = Global;
	@Optional() @Input() fleetId: any;
	timeZoneType: any;


	constructor(
		private gseService: GseService,
		private dataService: DataService,
		private toastr: ToastrService,
		private utilityService: UtilityService,
		private dialog: MatDialog,
		private dashboardService: DashboardService,
		private mapsService: MapsService
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
	}

	updateMapData(tagObj: ITag) {
		if (this.mapDrawn === true) {
			let infoWindowToUpdate = this.infoWindows.find((iw) => {
				return iw.asset.Id === tagObj.Asset.Id;
			});
			if (!_.isNil(infoWindowToUpdate)) {
				this.setContentForInfoWindow(infoWindowToUpdate, false);
				this.addMarkersAndInfoWindowsToMap(); //forces map to redraw markers for timezone update
			}
			if (
				tagObj.JBTStandardObservation?.Id === 54260 ||
				tagObj.JBTStandardObservation?.Id === 54261
			) {
				let marker = this.markers.find((marker) => {
					return marker.asset.Id === tagObj.Asset.Id;
				});
				if (!_.isNil(marker)) {
					this.setMarkerLocationForMap(marker);
				}
			}
		}
		//from here, we need to find the infowindow that needs to be updated and update only that infowindow.
	}

	setMarkerLocationForMap(marker) {
		let lat = this.dataService.cache.assetsObject[
			marker.asset.Id
		].Tags.find((t) => t.JBTStandardObservationId == 54260);
		let long = this.dataService.cache.assetsObject[
			marker.asset.Id
		].Tags.find((t) => t.JBTStandardObservationId == 54261);
		if (!_.isNil(lat?.Value) && !_.isNil(long?.Value)) {
			let oldLat = marker.getPosition().lat();
			let oldLong = marker.getPosition().lng();
			let highestSeverity = this.dataService.returnHighestSeverityLevel(lat.Asset.Id);
			let historicalIcon = {
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(10, 10),
				scaledSize : new google.maps.Size(15, 15),
				url: this.mapsService.returnPlaybackMarker(highestSeverity)
			}
			let historicalMarker = new google.maps.Marker({
				position: new google.maps.LatLng(oldLat, oldLong),
				icon: historicalIcon,
				map: this.map,
				title: lat.Asset.Name
			})
			let myLatlng = new google.maps.LatLng(lat.Value, long.Value);
			marker.setPosition(myLatlng);
		}
	}

	drawMap() {
		this.mapsService.loadGoogleMaps().then(() => {
			this.mapDrawn = false;
			if (this.map === undefined) {
				let mapClass = 'regularMapLocate'
				if(this.widgetObject !== undefined) {
					mapClass = 'regularMapLocate' + this.widgetObject.WidgetId;
				}
				this.map = new google.maps.Map(
					document.getElementById(
						mapClass
					),
					this.mapsService.loadMapConfiguration()
				);

				if(this.fleetId === undefined && this.widgetObject !== undefined) {
					var VocationalSettings = JSON.parse(
						this.widgetObject?.VocationalSettingsJSON
					);

					this.fleetId = VocationalSettings?.id;
				}
				if(this.fleetId === undefined) {
					let legend: any;
					let styleControl: any;
					if (this.widgetObject !== undefined) {
						legend = this.gseService.createLegendForGseMaps(
							'legend' + this.widgetObject.WidgetId
						);

						styleControl = document.getElementById(
							'style-selector-control' + this.widgetObject.WidgetId
						) as HTMLElement;
					} else {
						legend = this.gseService.createLegendForGseMaps('legend');
						this.map = new google.maps.Map(
							document.getElementById('regularMapLocate'),
							this.mapsService.mapConfiguration
						);
						styleControl = document.getElementById(
							'style-selector-control'
						) as HTMLElement;
					}
					this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
						legend
					);
					this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
						styleControl
					);
					if (this.widgetObject !== undefined) {
						(
							document.getElementById(
								'hide-poi' + this.widgetObject.WidgetId
							) as HTMLElement
						).addEventListener('click', () => {
							legend.hidden = true;
							//get legend by id and apply hiddden style
						});
						(
							document.getElementById(
								'show-poi' + this.widgetObject.WidgetId
							) as HTMLElement
						).addEventListener('click', () => {
							legend.hidden = false;
							//get legend by id and apply hiddden style
						});
					} else {
						(
							document.getElementById('hide-poi') as HTMLElement
						).addEventListener('click', () => {
							legend.hidden = true;
							//get legend by id and apply hiddden style
						});
						(
							document.getElementById('show-poi') as HTMLElement
						).addEventListener('click', () => {
							legend.hidden = false;
							//get legend by id and apply hiddden style
						});
					}
				}

			} else {
				for (let i = 0; i < this.markers.length; i++) {
					this.markers[i].setMap(null);
					this.markers[i] = null;
				}
				this.markerclusterer?.clearMarkers();
				this.markerclusterer = null;
			}

			this.addMarkersAndInfoWindowsToMap();
			
			this.mapDrawn = true;
			if (this.showOptionForGeographyZones === true) {
				let siteIdsAsString =
					Global.User.currentUser.Security.Aggregate.Collections.SiteIds.toString();
				var sqlStatement =
					"API.Geofencing_GetGeographyZonesByListOfSiteIds @siteIds= '" +
					siteIdsAsString +
					"'";
				// var sqlStatement = "API.GeofencingGetAllZonesBySiteId @SiteId=" + this.widgetObject.WidgetSiteId;
				// console.log(Global.User.currentUser.Security.Aggregate.Collections.SiteIds)
				console.log('sqlStatement = ' + sqlStatement);
				this.dataService
					.SQLActionAsPromise(sqlStatement)
					.then((data: any) => {
						this.zonesAndZonesInstances = data
							.groupBy((group) => group.GeographyZoneId)
							.select((n) => {
								n.group = n.toArray();
								return {
									GeographyZoneId: n.key,
									GeographyZoneName:
										n.group.firstOrDefault().GeographyZoneName,
									GeographyZoneDescription:
										n.group.firstOrDefault()
											.GeographyZoneDescription,
									GeographyZoneLatLonPairs:
										n.group.firstOrDefault()
											.GeographyZoneLatLonPairs,
									Types: n.group
										.groupBy(
											(typ: any) => typ.GeographyZoneTypeId
										)
										.select((t) => {
											t.group = t.toArray();
											return {
												GeographyZoneTypeId: t.key,
												GeographyZoneId:
													t.group.firstOrDefault()
														.GeographyZoneId,
												Id: t.group.firstOrDefault()
													.GeographyZoneToGeographyZoneTypeId,
												GeographyZoneTypeName:
													t.group.firstOrDefault()
														.GeographyZoneTypeName,
												GeographyZoneToGeographyZoneTypeName:
													t.group.firstOrDefault()
														.GeographyZoneToGeographyZoneTypeName,
												GeographyZoneToGeographyZoneTypeSpeedLimit:
													t.group.firstOrDefault()
														.GeographyZoneToGeographyZoneTypeSpeedLimit !==
													null
														? parseFloat(
																t.group.firstOrDefault()
																	.GeographyZoneToGeographyZoneTypeSpeedLimit
														  )
														: t.group.firstOrDefault()
																.GeographyZoneToGeographyZoneTypeSpeedLimit,
												GeographyZoneToGeographyZoneTypeSpeedLimitUnits:
													t.group.firstOrDefault()
														.GeographyZoneToGeographyZoneTypeSpeedLimitUnits,
												GeographyZoneToGeographyZoneTypeJBTStandardObservationSeverityLevel:
													t.group.firstOrDefault()
														.GeographyZoneToGeographyZoneTypeJBTStandardObservationSeverityLevel,
												Assets: t.group
													.where((ty) => {
														return (
															ty.GeographyZoneTypeId ==
															t.key
														);
													})
													.select((a) => {
														return {
															AssetId: a.AssetId,
															AssetName: a.AssetName,
														};
													})
													.toArray(),
											};
										})
										.toArray(),
								};
							})
							.toArray();
						console.log(this.zonesAndZonesInstances);
						this.zonesAndZonesInstances.forEach((zone) => {
							zone.Types = zone.Types.filter((zoneType) => {
								return zoneType.Id !== 0;
							});
						});
						if (this.widgetObject !== undefined) {
							this.widgetObject.isDisplayDataLive = true;
						}
					});
			} else {
				if (this.widgetObject !== undefined) {
					this.widgetObject.isDisplayDataLive = true;
				}
			}
		});


	}

	addMarkersAndInfoWindowsToMap(){
		let bounds = new google.maps.LatLngBounds();

		this.markers = [];
			this.infoWindows = [];
			this.assetList.forEach((gse, index) => {
				let lat = gse.Tags.find((t) => t.JBTStandardObservationId == 54260);
				let long = gse.Tags.find(
					(t) => t.JBTStandardObservationId == 54261
				);
				if (!_.isNil(lat?.Value) && !_.isNil(long?.Value)) {
					var gseLatLng = new google.maps.LatLng(lat.Value, long.Value);
					bounds.extend(gseLatLng);
					var infoWindow = new google.maps.InfoWindow();
					infoWindow.asset = gse;
					let evaluationObject: any =
						this.gseService.determineAssetImageFromAssetTypeForMap(gse);
					if (evaluationObject !== {}) {
						if (evaluationObject.alarmType === 'Critical') {
							if(this.fleetId){
								var marker: any = new google.maps.Marker({
									position: gseLatLng,
									title: gse.Name,
									infowindow: infoWindow,
									icon: evaluationObject.icon,
									// animation: google.maps.Animation.BOUNCE,
									asset: gse,
								});
							} else {
								var marker: any = new google.maps.Marker({
									position: gseLatLng,
									title: gse.Name,
									infowindow: infoWindow,
									icon: evaluationObject.icon,
									animation: google.maps.Animation.BOUNCE,
									asset: gse,
								});
							}

						} else {
							var marker: any = new google.maps.Marker({
								position: gseLatLng,
								title: gse.Name,
								infowindow: infoWindow,
								icon: evaluationObject.icon,
								asset: gse,
							});
						}
					} else {
						var marker: any = new google.maps.Marker({
							position: gseLatLng,
							title: gse.Name,
							infowindow: infoWindow,
							asset: gse,
						});
					}
					google.maps.event.addListener(marker, 'click', function (evt) {
						infoWindow.open(this.map, marker);
					});
					this.setContentForInfoWindow(infoWindow, true);
					// infoWindow.open(this.map, marker);
					this.markers.push(marker);
					this.infoWindows.push(infoWindow);
					google.maps.event.addListener(infoWindow, 'domready', () => {
						this.tagOptions.forEach((tag) => {
							let tagDiv = document.getElementById(
								tag.tagName + gse.Id
							);
							if (tagDiv !== null) {
								google.maps.event.addDomListener(
									tagDiv,
									'click',
									(evt) => {
										this.openTagGraphSingle({
											gse: gse,
											observationId:
												tag.standardObservationId,
										});
									}
								);
							}
						});
					});
				}
			});

			this.markerclusterer = new markerClusterer.MarkerClusterer({map: this.map, markers:  this.markers, renderer: this.mapsService.renderer});
			this.map.fitBounds(bounds);
	}

	showAssetOnMap(asset) {
		//can now use AssetId as I have attached it to each marker. Allows us to now create a bi-directional realtionship with the map and table to show and filter assets.
		//Need to explore a button on the map to zoom out to default map view for laptop users.
		console.log(asset);
		console.log(this.markers);
		this.map.setZoom(20);
		let markerIndex = this.markers.findIndex(
			(marker) => marker.title === asset.Name
		);
		this.map.panTo(this.markers[markerIndex].position);
		this.markers.forEach((marker, i) => {
			if (
				marker.infowindow !== undefined &&
				marker.title !== asset.Name
			) {
				marker.infowindow.close();
			} else if (marker.title === asset.Name) {
				new google.maps.event.trigger(marker, 'click');
			}
		});
	}

	public setMapOnAll(map: any | null) {
		for (let i = 0; i < this.markers.length; i++) {
			this.markers[i].setMap(map);
			this.markers[i] = null;
		}
	}

	getValue(element, observationId, needObject) {
		let tag = element.Tags.find(
			(element) => element.JBTStandardObservationId === observationId
		);
		if (tag === undefined) {
			return null;
		} else if (needObject === undefined) {
			return tag.Value;
		} else if (needObject === true) {
			return tag;
		}
	}

	openTagGraphSingle(passedTagObject, backupObservationId?): void {
		let tagObject: any;
		tagObject = this.getValue(
			passedTagObject.gse,
			passedTagObject.observationId,
			true
		);
		if (!_.isNil(backupObservationId)) {
			tagObject = this.getValue(
				passedTagObject.gse,
				backupObservationId,
				true
			);
		}
		if (tagObject === null) {
			this.swalWithBootstrapButtons
				.fire({
					title: 'Error',
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: 'Ok',
					reverseButtons: false,
				})
				.then(() => {});
		} else {
			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: '95%',
					height: '80%',
					data: {
						TagId: tagObject.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},
					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}

	getPathAsArrayFromSQLZoneInstance(latlongpairs) {
		let pathAsString = latlongpairs.split('|');
		let pathAsArray = [];
		pathAsString.forEach((pathInstance) => {
			let latLongArray = pathInstance.split(',');
			let latLongObject = {
				lat: parseFloat(latLongArray[0]),
				lng: parseFloat(latLongArray[1]),
			};
			pathAsArray.push(latLongObject);
		});
		return pathAsArray;
	}

	toggleZonesOnMap() {
		if (this.listOfPolygons.length === 0) {
			if (this.zonesAndZonesInstances.length > 0) {
				this.zonesAndZonesInstances.forEach((zoneIteration) => {
					// if (zonetype.zonesOfType !== undefined) {
					// 	zonetype.zonesOfType.forEach((zoneInstance) => {
					if (zoneIteration.GeographyZoneLatLonPairs !== undefined) {
						let pathAsArray =
							this.getPathAsArrayFromSQLZoneInstance(
								zoneIteration.GeographyZoneLatLonPairs
							);
						const infoWindow = new google.maps.InfoWindow({
							content: zoneIteration.GeographyZoneName,
						});

						console.log(pathAsArray);
						let zone = new google.maps.Polygon({
							paths: pathAsArray,
							strokeColor: '#FF0000',
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: '#FF0000',
							fillOpacity: 0.35,
							content: zoneIteration.GeographyZoneName,
							zoneInstanceObjectFromSql: zoneIteration,
							infoWindowForZoneInstance: infoWindow,
							// editable: true,
							// draggable: true
							map: this.map,
						});
						var bounds = new google.maps.LatLngBounds();
						for (var i = 0; i < pathAsArray.length; i++) {
							bounds.extend(pathAsArray[i]);
						}
						let zoneCenter = bounds.getCenter();
						zone.infoWindowForZoneInstance.setPosition(zoneCenter);

						google.maps.event.addListener(zone, 'click', (evt) => {
							zone.infoWindowForZoneInstance.open(this.map, zone);
						});
						// infoWindow.open(this.locateMap, zone);

						this.listOfPolygons.push(zone);
						// this.listOfPolygons[this.listOfPolygons.length - 1].setMap(this.mapForGeofencing);

						// console.log(this.listOfPolygons);
					}

					// 	});
					// }
				});
				let displayMessage =
					this.zonesAndZonesInstances.length +
					' geofences added to map.';
				// this.toastr.success(displayMessage);
				this.utilityService.showToastMessageShared({
					type: 'success',
					message: displayMessage,
					// title: 'Dashboards',
				});
			} else {
				this.utilityService.showToastMessageShared({
					type: 'info',
					message: 'No geofences to display on map',
				});
				// this.toastr.info('No geofences to display on map');
			}
		} else if (this.listOfPolygons.length > 0) {
			this.listOfPolygons.forEach((zone) => {
				zone.setMap(null);
			});
			this.listOfPolygons = [];
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Geofences removed from map.',
			});
			// this.toastr.success('Geofences removed from map.');
		}
	}

	setContentForInfoWindow(infoWindow, firstTimeSettingInfoWindow: boolean) {
		//lookup most recent information about GSE and use that to populate data.
		let gse = this.dataService.cache.assets.find((assetToFind) => {
			return assetToFind.Id === infoWindow.asset.Id;
		});



		if (!_.isNil(gse)) {
			let marker = this.markers.find((mrkr) => {
				return mrkr.asset.Id === infoWindow.asset.Id;
			});

			let contentStringForInfoWindow = `<div class='firstHeading' style='font-size: 12px'><b><span style='color:teal;'> ${
				this.fleetId === undefined ? gse.Site?.Name : ''
			} ${gse.Name}</div></b><div id='date${gse.Id}' style='cursor: pointer; color: black;'></div>${this.recreateTagString(gse)}</div>`;

			let evaluationObject: any =
				this.gseService.determineAssetImageFromAssetTypeForMap(gse);
			let evaluationObjectForUserDefinedAlarms: any =
				this.gseService.determineUserDefinedAlarmsImageFromAssetTypeForTable(
					gse,
					true
				);

			if (
				evaluationObjectForUserDefinedAlarms !== {} &&
				evaluationObjectForUserDefinedAlarms.alarmType !== '' &&
				evaluationObjectForUserDefinedAlarms.icon !== undefined
			) {
				if (
					evaluationObjectForUserDefinedAlarms.alarmType ===
					'Critical'
				) {
					contentStringForInfoWindow =
						contentStringForInfoWindow +
						`<div style="color: black;" >Most Severe Active User Defined Alert: </div><img class="blink-image" src="${evaluationObjectForUserDefinedAlarms.icon.url}" style="height: 30px" />`;
				} else {
					contentStringForInfoWindow =
						contentStringForInfoWindow +
						`<div style="color: black;" >Most Severe Active User Defined Alert: </div><img src="${evaluationObjectForUserDefinedAlarms.icon.url}" style="height: 30px" />`;
				}
				let stringOfHtmlCurrentAlarms =
					'<div style="color: black;" >List of Active User Defined Alerts:</div>';
				evaluationObjectForUserDefinedAlarms.currentListOfAlarms.forEach(
					(alert) => {
						stringOfHtmlCurrentAlarms =
							stringOfHtmlCurrentAlarms +
							`<div style="padding-left: 5px; color: black;">${alert.Value} - ${alert.JBTStandardObservation.Name}</div>`;
					}
				);
				contentStringForInfoWindow =
					contentStringForInfoWindow + stringOfHtmlCurrentAlarms;
			}

			if (
				firstTimeSettingInfoWindow === false &&
				evaluationObject !== {} &&
				evaluationObject.icon !== undefined
			) {
				marker.setIcon(evaluationObject.icon);
				if (evaluationObject.alarmType === 'Critical') {
					marker.setAnimation(google.maps.Animation.BOUNCE);
				}
			}

			infoWindow.setContent(contentStringForInfoWindow);
		}
		
	}

	recreateTagString(assetObj) {
		let gseTagString = '';
		this.tagOptions.forEach((tag) => {
			let tempString = this.getTagValueString(
				assetObj,
				tag.standardObservationId,
				tag.tagName,
				tag.percent
			);
			// gseTagString.concat(
			//   this.getTagValueString(assetObj, tag.standardObservationId, tag.tagName)
			// );
			gseTagString = gseTagString + tempString;
		});
		return gseTagString;
	}

	getTagValueString(assetObject, observationId, idName, percent) {
		let getTagValueReturn = this.getTagValue(assetObject, observationId);
		if (getTagValueReturn === null) {
			return '';
		} else {
			return `<div id='${idName}${assetObject.Id}' style='cursor: pointer; color: black;'>${getTagValueReturn} ${percent === true ? '%' : ''}</div>`;
		}
	}
 
	getTagValue(assetObject, observationId, needObject?) {
		let tag = assetObject.Tags.find(
			(tag) => tag.JBTStandardObservationId === observationId
		);
		if (tag == undefined) {
			return null;
		} else if(tag.JBTStandardObservation?.Id === 79870) {
			var dateForDisplay =this.checkUserTimeZoneAndSetLastRefreshTime(tag); // time for display
			return `${tag.JBTStandardObservation?.Name}: ${dateForDisplay} ${this.timeZoneType}`;
		} else if (needObject === undefined) {
			return `${tag.JBTStandardObservation?.Name}: ${tag.Value}`;
		} else if (needObject === true) {
			return tag;
		}
	}



	checkUserTimeZoneAndSetLastRefreshTime(lastRefreshTag: any) {
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);
		
		//JavascriptDate is user time
		//SiteLocalJavascriptDate is site time
		//UTCJavascriptDate is UTC time

		var dateFull;
		if (lastRefreshTag && lastRefreshTag!= undefined) {
			if (this.timeZoneType === 'User Time') {
				dateFull = lastRefreshTag.JavascriptDate;
			} else if (this.timeZoneType === 'Site Time') {
				dateFull = lastRefreshTag.SiteLocalJavascriptDate;
			} else if (this.timeZoneType === 'UTC Time') {
				dateFull = lastRefreshTag.UTCJavascriptDate;
			}
			if(dateFull)
				dateFull = moment(dateFull).format('MMM DD, YYYY HH:mm:ss');
		}
		return dateFull;
		
	}

	ngOnDestroy() {
		if (this.tagGraphSingleModalSubscription) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}

	testFunction() {
		let color = Global.Theme === 'light' ? '#fff !important': '#27304c !important'
		return color;
	}
}
