import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularResizeEventModule } from 'angular-resize-event';


@NgModule({
	declarations: [VideoPlayerComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		DynamicFormModule,
		DragDropModule,
		AngularResizeEventModule
	],
	exports: [VideoPlayerComponent]
})
export class VideoPlayerModule { }
