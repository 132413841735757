import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { AuthenticationService } from 'projects/shared-lib/src/lib/services/authentication.service';

@Component({
	templateUrl: "logout.component.html",
})
export class LogoutComponent implements OnInit {
	constructor(private authenticationService: AuthenticationService, private router: Router) {}

	ngOnInit() {
		this.logout();
	}

	logout() {
		this.authenticationService.logout();
		if (Global.isMobile) {
			this.router.navigate(["login"]);
		} else {
			this.router.navigate(["user/login"]);
		}
	}
}
