import { Component, Input, OnInit, Optional } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { IGlobal } from '../../_models/global.model';

@Component({
	selector: 'lib-pca-overnight-mode',
	templateUrl: './pca-overnight-mode.component.html',
	styleUrls: ['./pca-overnight-mode.component.scss'],
})
export class PcaOvernightModeComponent implements OnInit {
	public global: IGlobal = Global;

	@Optional() @Input() summaryInputTagId: any;
	@Optional() @Input() widgetInputTagId: any;
	tagId: any;
	fullDataCacheSubscription: any;
	theme: string;
	tagObject: any;

	constructor(public dataService: DataService) {}

	ngOnInit() {
		if (this.summaryInputTagId !== undefined) {
			this.tagId = this.summaryInputTagId;
		} else if (this.widgetInputTagId !== undefined) {
			this.tagId = this.widgetInputTagId;
		} else {
			this.tagId = undefined;
		}

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.initialize();
		}
	}

	initialize() {
		if (this.tagId != undefined)
			this.tagObject = this.dataService.cache.tagsObject[this.tagId];
		else this.tagObject = undefined;
	}
}
