import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Global } from "../../_constants/global.variables";
import { DataService } from "../../services/data.service";
import _ from "lodash";
import { IAsset } from "../../_models/asset.model";

@Component({
	selector: "lib-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit {
	@Input() public options: Array<any>;
	@Input() public navigationOpened: boolean;
	@Output() opened: EventEmitter<any> = new EventEmitter();
	@Output() selected: EventEmitter<any> = new EventEmitter();

	public items: Array<any> = [];
	public currentAccordionItem: any;
	private componentName: string = "navigation: ";
	public root: boolean = false;
	public searchEnabled: boolean = false;
	public searchText: string;
	public dataToSearch: any;
	public searchResults: any;
	public navigationWidth: string;
	public parentWidgetObject: any;
	public parentService: any;
	public originalOptionsArray: Array<any> = [];

	public navigationOpenedArray: Array<any> = [];

	constructor(private dataService: DataService) {
		this.searchForText = _.debounce(this.searchForText, 300);
	}

	ngOnInit() {
		if (Array.isArray(this.options)) {
			//this.root = this.options?.firstOrDefault().root;
			this.options?.forEach((item: any) => {
				if (item.root) {
					this.root = true;
				}
				if (item.search) {
					this.searchEnabled = true;
					this.dataToSearch = item.dataToSearch;
					this.navigationWidth = item.width;
					this.parentWidgetObject = item.widget;
					this.parentService = item.service;
					console.log("searchEnabled = " + this.searchEnabled + ", dataToSearch = %O", this.dataToSearch);
					item.children = this.dataToSearch
						.orderBy((asset: IAsset) => {
							return asset.Name;
						})
						.thenByDescending((asset: IAsset) => {
							return asset.AssetTypeId;
						})
						.take(20)
						.select((asset: IAsset) => {
							var newAsset = {
								id: 10,
								width: this.navigationWidth,
								name: asset.Name,
								selected: item.selected != null ? asset.Id == item.selected.Id : false,
								action: () => item.action(asset, this.parentWidgetObject, this.parentService)
							};
							return newAsset;
						})
						.toArray();
					var selectedItemInList = item.children.firstOrDefault((child: any) => {
						return child.name == item.selected?.Name;
					});
					if (item.selected != null && !selectedItemInList) {
						var selectedItem = this.dataToSearch
							.where((asset: IAsset) => {
								return asset.Id == item.selected.Id;
							})
							.select((asset: IAsset) => {
								var newAsset = {
									id: 10,
									width: this.navigationWidth,
									name: asset.Name,
									selected: true,
									action: () => item.action(asset, this.parentWidgetObject, this.parentService)
								};
								return newAsset;
							});
						item.children.unshift(selectedItem.first()); //-- place selected item, that isn't part of the main 20 items, at the top of the list.
						console.log("item.children = %O", item.children);
					}
				}
			});
			this.originalOptionsArray = this.options;
		}
	}

	searchForText(searchText: any, item: any) {
		Global.User.DebugMode && console.log(this.componentName + "searchText = %O", searchText);

		this.searchText = searchText;
		if (this.searchText == "" || this.searchText == null) {
			this.options = this.originalOptionsArray; //-- put the list back the way it initially started.
		}
		console.log("item = %O", item);
		item.children = this.dataToSearch
			.where((asset: IAsset) => {
				return asset.Name?.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || asset.OriginalName?.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || asset.Site?.Name.toLowerCase().startsWith(searchText.toLowerCase()) || asset.OwnerOrganizationName?.toLowerCase().startsWith(searchText.toLowerCase()) || asset.OperatorOrganizationName?.toLowerCase().startsWith(searchText.toLowerCase());
			})
			.orderBy((asset: IAsset) => {
				return asset.Name;
			})
			.thenByDescending((asset: IAsset) => {
				return asset.AssetTypeId;
			})
			.take(20)
			.select((asset: IAsset) => {
				var newAsset = {
					id: 10,
					width: this.navigationWidth,
					name: asset.Name,
					selected: false,
					action: () => item.action(asset, this.parentWidgetObject, this.parentService)
				};
				return newAsset;
			})
			.toArray();
	}

	ngOnChanges(changes) {
		if (changes.navigationOpened?.currentValue == false) {
			this.options?.forEach((item: any) => {
				item.opened = false;
			});
		}
	}

	clearPreviouslySelectedValues(item: any) {
		item.selected = false;
		if (item.children) {
			item.children.forEach((i: any) => {
				this.clearPreviouslySelectedValues(i);
			});
		}
	}

	public accordionChange(event: Event, accordionItem: any, item: any) {
		console.log(this.componentName + "itemName = " + item.name);
		this.clearPreviouslySelectedValues(item);

		if (this.currentAccordionItem) {
			console.log(this.componentName + "currentAccordionItem.expanded = " + this.currentAccordionItem.expanded);
			console.log(this.componentName + "currentAccordionItem = %O", this.currentAccordionItem);
		}

		this.currentAccordionItem = accordionItem;
		console.log(this.componentName + "toggling accordionItem...");

		accordionItem.toggle();

		event?.stopPropagation();
		event?.preventDefault();
		event?.stopImmediatePropagation();

		if (item.action) {
			console.log(this.componentName + "executing item.action...");
			if (this.searchEnabled) {
				item.action()();
			} else {
				item.action();
			}
		}
	}

	public accordionItemSelected(event: Event, accordionItem: any, item: any) {
		console.log(this.componentName + "accordionItemSelected invoked. item.name = " + item.name);
		this.clearPreviouslySelectedValues(item);

		if (this.currentAccordionItem) {
			console.log(this.componentName + "currentAccordionItem = %O", this.currentAccordionItem);
		}

		console.log(this.componentName + "emitting item to parent component... item: %O", item);
		this.selected.emit(item);

		if (this.currentAccordionItem != accordionItem) {
			this.currentAccordionItem = accordionItem;
			console.log(this.componentName + "toggling accordionItem...");

			accordionItem.toggle();
		}

		if (item.action) {
			console.log(this.componentName + "executing item.action...");
			if (this.searchEnabled) {
				item.action()();
			} else {
				item.action();
			}
		}
	}

	public openCloseNavigation(item: any) {
		console.log(this.componentName + "root level " + item.id + " touched... item = %O", item);
		console.log(this.componentName + "openCloseNavigation invoked. item.opened current status: = " + item.opened);
		item.opened = !item.opened;
		console.log(this.componentName + "openCloseNavigation invoked. item.opened changed status: = " + item.opened);
		this.opened.emit(item.opened);
	}

	public checkSelectedItem(selected: any) {
		console.log(this.componentName + "checkSelectedItem invoked. selected = %O", selected);
		this.selected.emit(selected);
	}

	getChangedEventObject(changedSelectorObject: any, question: any) {
		console.log(this.componentName + "changedSelectorObject.value = " + changedSelectorObject?.value + ", isNaN(changedSelectorObject.value) = " + isNaN(changedSelectorObject?.value) + ", Number(changedSelectorObject.value) = " + Number(changedSelectorObject?.value));

		// if (this.fieldNeedsToBeSaved == undefined) {
		// 	this.fieldNeedsToBeSaved = true;
		// }

		// if (typeof changedSelectorObject == "object" && this.fieldNeedsToBeSaved) {
		// 	//-- since this is coming from a selector, the original text for searching the selector list will be just the text the user typed.  No need to save any of that since
		// 	//-- the user hasn't selected the record they are searching for yet. Only when we receive an object will we know for sure they have selected one from the selector
		// 	//-- list and then we can save the ID number of the selected record. --Kirk T. Sherer, August 19, 2021.
		// 	console.log(this.componentName + "changedSelectorObject.value = " + changedSelectorObject.value + ", isNaN(changedSelectorObject.value) = " + isNaN(changedSelectorObject.value) + ", isNumeric(changedSelectorObject.value) = " + Number(changedSelectorObject.value));
		// 	this.saveValueFromSelector(changedSelectorObject, question); //-- go ahead and save the value.
		// } else {
		// 	//-- this value is from a file upload field, more than likely.  Just update the question.value to the changedSelectorObject since it's just a string at that point. --Kirk T. Sherer, November 1, 2021.
		// 	this.form.controls[question.key].setValue(changedSelectorObject); //-- have to set the form value so that the overall Dynamic Form can react to the change to the form. --Kirk T. Sherer, August 20, 2021.
		// 	this.notifyParent.emit(changedSelectorObject); //-- notify overall Dynamic Form (parent)...
		// }

		// if (changedSelectorObject && this.saveOnChange && this.saveStoredProcedureName) {
		// 	var changedObjectValue = changedSelectorObject;
		// 	if (changedSelectorObject.Id != undefined) {
		// 		changedObjectValue = changedSelectorObject.Id;
		// 	}

		// 	var sqlStatement = this.saveStoredProcedureName + ", '" + question.key + "'," + changedObjectValue;

		// 	if (question.controlType != "number") {
		// 		sqlStatement = this.saveStoredProcedureName + ", '" + question.key + "','" + changedObjectValue + "'";
		// 	}

		// 	console.log(this.componentName + "sqlStatement = " + sqlStatement);

		// 	this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
		// 		if (data && data.length > 0) {
		// 			// var message = data.where((d: any) => {
		// 			// 	d.msg != null
		// 			// }).toArray();
		// 			var errorMessage = data.first((d: any) => {
		// 				return d;
		// 			}).msg;
		// 			if (errorMessage) {
		// 				//-- error was sent back from the stored procedure. Display error in alert message. --Kirk T. Sherer, April 20, 2021.
		// 				swal.fire({
		// 					title: "Error",
		// 					position: "center",
		// 					html: errorMessage
		// 				});
		// 			} else {
		// 				console.log(this.componentName + question.key + " has been updated. data = %O", data);

		// 				this.fieldNeedsToBeSaved = false;
		// 			}
		// 		}
		// 	});
		// }
	}
}
