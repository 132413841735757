<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Summary Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="
		!isDataLoading && (!fromDashboard || widgetObject?.WidgetGateSystemId)
	"
	class="card card-tasks"
>
	<mat-tab-group
		animationDuration="0ms"
		(selectedTabChange)="matTabHasChanged($event)"
	>
		<mat-tab *ngFor="let tab of widgetTabs" [label]="tab.textLabel">
			<ng-template mat-tab-label>
				<span
					[ngClass]="{
						criticalAlarmBadge: alertStatus === 3,
							alarmBadge: alertStatus === 1,
							warningBadge: alertStatus === 2
						}"
					[matBadge]="
						tab.textLabel === 'Alerts' && countOfAlarms > 0
							? countOfAlarms
							: undefined
					"
				>
					{{ tab.textLabel }}
				</span>
			</ng-template>
			<div
				*ngIf="tab.textLabel === 'Quick View' && tab.rendered"
				(resized)="onResized($event)"
				[style.height.%]="100"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading && quickViewTagIds.length > 0"
				>
					<lib-pbb-quick-view
						[parentAsset]="assetObj"
						[parentTagIds]="quickViewTagIds"
						[widgetObject]="widgetObject"
					></lib-pbb-quick-view>
				</div>
			</div>
			<div
				*ngIf="tab.textLabel === 'Data' && tab.rendered"
				(resized)="onResized($event)"
				[style.height.%]="100"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<div *ngIf="!isDataLoading" class="parent-table-container">
						<lib-kendo-grid-parent
							[gridData]="
								dataService.cache.assetsObject[assetId].Tags
							"
							[gridSettings]="kendoGrids.tags.gridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							[gridContextMenuItems]="gridContextMenuItems"
							(onRightClickSelectEmitter)="onRightClickSelect($event)"
							#tagDataGrid
						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Options' && tab.rendered"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<div *ngIf="!isDataLoading" class="parent-table-container">
						<lib-kendo-grid-parent
							[gridData]="
								dataService.cache.assetsObject[assetId]
									.OptionTags
							"
							[gridSettings]="kendoGrids.options.gridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							#optionDataGrid
						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Move Prevention' && tab.rendered"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<div *ngIf="!isDataLoading" class="parent-table-container">
						<lib-kendo-grid-parent
							[gridData]="
								dataService.cache.assetsObject[assetId]
									.MovePreventionTags
							"
							[gridSettings]="
								kendoGrids.movePrevention.gridSettings
							"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							#movePreventionDataGrid
						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Alerts' && tab.rendered"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<div *ngIf="!isDataLoading" class="parent-table-container">
						<lib-kendo-grid-parent
							[gridData]="
								dataService.cache.assetsObject[assetId]
									.ActiveAlarmTags
							"
							[gridSettings]="
								kendoGrids.activeAlerts.gridSettings
							"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							[gridContextMenuItems]="gridContextMenuItems"
							(onRightClickSelectEmitter)="onRightClickSelect($event)"
							#alarmDataGrid
						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Last 1000 Events' && tab.rendered"
				(resized)="onResized($event)"
				class="subTabContainer"
			>
				<div class="asset-summary-left" [style.width.%]="defaultWidth">
					<lib-last-thousand-grid
						[widgetObject]="widgetObject"
						*ngIf="
							!isDataLoading &&
							kendoGrids.last1000Events.isRendered
						"
						#last1000EventsGrid
						[gridSettings]="kendoGrids.last1000Events.gridSettings"
						[configuration]="kendoGrids.last1000Events.config"
						[parentContainerSize]="parentContainerSize"
						(callSave)="saveGridSettings()"
					></lib-last-thousand-grid>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Last 1000 Faults' && tab.rendered"
				class="subTabContainer"
				(resized)="onResized($event)"
			>
				<div class="asset-summary-left" [style.width.%]="defaultWidth">
					<lib-last-thousand-grid
						[widgetObject]="widgetObject"
						*ngIf="
							!isDataLoading &&
							kendoGrids.last1000Alarms.isRendered
						"
						#last1000AlarmsGrid
						[gridSettings]="kendoGrids.last1000Alarms.gridSettings"
						[configuration]="kendoGrids.last1000Alarms.config"
						[parentContainerSize]="parentContainerSize"
						(callSave)="saveGridSettings()"
					></lib-last-thousand-grid>
				</div>
			</div>

			<div
				*ngIf="
					tab.textLabel === 'Elevator' &&
					tab.rendered &&
					hasElevatorRotunda
				"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<div *ngIf="!isDataLoading" class="parent-table-container">
						<lib-kendo-grid-parent
							[gridData]="
								dataService.cache.assetsObject[assetId]
									.ElevatorRotundaTags
							"
							[gridSettings]="
								kendoGrids.elevatorRotunda.gridSettings
							"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							#elevatorRotundaDataGrid
						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Statistics' && tab.rendered"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<lib-dynamic-form
						[hidden]="isHourMeterReadingFormLoading"
						[questions]="hourMeterReadingFields"
						[options]="hourMeterReadingFormOptions"
						(submittedValues)="submitHourMeterReadingEvent($event)"
					></lib-dynamic-form>
					<div
						*ngIf="
							this.hourMeterReadingEvents != undefined &&
							this.hourMeterReadingEvents.length > 0
						"
						class="linear-gauge-container"
					>
						<h5>
							Current Reading :
							<span class="hmi-on">{{
								currentHourMeterReadingValue
							}}</span>
						</h5>
						<br />
					</div>

					<lib-kendo-grid-parent
						[hidden]="isHourMeterReadingFormLoading"
						[gridData]="hourMeterReadingEvents"
						[gridSettings]="hourMeterReadingEventsGridSettings"
						[widgetObject]="widgetObject"
						(callSave)="saveGridSettings()"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						#hourMeterReadingEventsGrid
					>
					</lib-kendo-grid-parent>
				</div>
			</div>

			<div
				*ngIf="
					tab.textLabel === 'Cameras' &&
					tab.rendered &&
					systemHasCameras
				"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading && displaySystemCameras"
				>
					<div
						*ngIf="!isDataLoading && displaySystemCameras"
						class="parent-table-container"
					>
						<lib-camera-view
							[systemsObject]="parentSystem"
						></lib-camera-view>
					</div>
				</div>
			</div>

			<div
				*ngIf="tab.textLabel === 'Maintenance' && tab.rendered"
				class="subTabContainer"
			>
				<div
					class="asset-summary-left"
					[style.width.%]="defaultWidth"
					*ngIf="!isDataLoading"
				>
					<div
						*ngIf="!isMaintenanceFormLoading"
						class="parent-table-container"
					>
						<form
							class="form-horizontal"
							[formGroup]="maintenanceForm"
						>
							<div style="overflow-x: hidden; overflow-y: auto">
								<div
									*ngIf="
										maintenanceForm &&
										!maintenanceForm.value.outOfService
									"
									class="row col-sm-12 col-md-12"
								>
									<div
										class="col-sm-3 col-md-3 col-form-label"
									>
										<label for="MaintenanceMode{{widgetInstance}}"
											>Maintenance Mode</label
										>
									</div>
									<div class="col-sm-9 col-md-9">
										<input
											type="checkbox"
											id="MaintenanceMode{{widgetInstance}}"
											formControlName="maintenanceMode"
											class="form-control"
											class="jbt-slider-bar"
										/>
										<label
											class="dynamic-form-slider-switch"
											for="MaintenanceMode{{widgetInstance}}"
											title="Click here if the asset is going into Maintenance Mode."
											(click)="
												sliderSelect(
													$event,
													maintenanceForm
												)
											"
										></label>
										<div
											class="dynamic-form-slider-text"
											title="Click here if the asset is going into Maintenance Mode."
										>
											<div class="slider-text-on">ON</div>
											<div class="slider-text-off">
												OFF
											</div>
										</div>
									</div>
								</div>
								<div
									*ngIf="
										maintenanceForm &&
										!maintenanceForm.value.maintenanceMode
									"
									class="row col-sm-12 col-md-12"
								>
									<div
										class="col-sm-3 col-md-3 col-form-label"
									>
										<label for="OutOfService{{widgetInstance}}"
											>Out of Service</label
										>
									</div>
									<div class="col-sm-9 col-md-9">
										<input
											type="checkbox"
											id="OutOfService{{widgetInstance}}"
											formControlName="outOfService"
											class="jbt-slider-bar"
										/>
										<label
											class="dynamic-form-slider-switch"
											for="OutOfService{{widgetInstance}}"
											title="Click here if the asset is Out of Service."
											(click)="
												sliderSelect(
													$event,
													maintenanceForm
												)
											"
										></label>
										<div
											class="dynamic-form-slider-text"
											title="Click here if the asset is Out of Service."
										>
											<div class="slider-text-on">ON</div>
											<div class="slider-text-off">
												OFF
											</div>
										</div>
									</div>
								</div>
								<div
									*ngIf="
										maintenanceForm &&
										!maintenanceForm.value.outOfService &&
										maintenanceForm.value.maintenanceMode
									"
									class="row col-sm-12 col-md-12"
								>
									<div
										class="col-sm-3 col-md-3 col-form-label"
									>
										<label for="maintenanceModeReasonId{{widgetInstance}}"
											>Reason</label
										>
									</div>
									<div class="col-sm-9 col-md-9">
										<select
											class="form-control"
											title="Please select the reason this asset is going into Maintenance Mode."
											id="maintenanceModeReasonId{{widgetInstance}}"
											formControlName="maintenanceModeReasonId"
											(change)="
												saveValue(
													$event,
													maintenanceForm
												)
											"
										>
											<option
												class="form-control"
												*ngFor="
													let opt of maintenanceModeReasons
												"
												[value]="opt.Id"
											>
												{{ opt.Name }}
											</option>
										</select>
									</div>
								</div>
								<div
									*ngIf="
										maintenanceForm &&
										!maintenanceForm.value
											.maintenanceMode &&
										maintenanceForm.value.outOfService
									"
									class="row col-sm-12 col-md-12"
								>
									<div
										class="col-sm-3 col-md-3 col-form-label"
									>
										<label for="outOfServiceReasonId{{widgetInstance}}"
											>Reason</label
										>
									</div>
									<div class="col-sm-9 col-md-9">
										<select
											class="form-control"
											title="Please select the reason this asset is Out of Service."
											id="outOfServiceReasonId{{widgetInstance}}"
											formControlName="outOfServiceReasonId"
											(change)="
												saveValue(
													$event,
													maintenanceForm
												)
											"
										>
											<option
												class="form-control"
												*ngFor="
													let opt of outOfServiceReasons
												"
												[value]="opt.Id"
											>
												{{ opt.Name }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
<div
	*ngIf="!isDataLoading && fromDashboard && !widgetObject?.WidgetGateSystemId"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Gate System from the widget settings."
	></lib-centered-message>
</div>
