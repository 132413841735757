<div *ngIf="noAccessToThisWidget" style="height: 100%; width: 100%">
	<lib-centered-message *ngIf="noAccessToThisWidget" centeredMessage="You no longer have access to this widget. Please log an issue in iOPS Customer Support if this is not correct."></lib-centered-message>
</div>
<div *ngIf="global.isMobile == false" style="height: 100%; width: 100%" (resized)="onResized($event)">
	<lib-centered-message *ngIf="!selectedCamera && !viewAllSystemCameras" centeredMessage="Please select a camera from the Navigation tab on the left."></lib-centered-message>
	<div *ngIf="options.length > 0" class="flex-container" style="position: relative">
		<lib-navigation [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-navigation>
		<div *ngIf="selectedCamera && showIframe && !showHistoricalForm && !viewAllSystemCameras" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">
			<div *ngIf="selectedCamera.downloadAllowed" style="display: flex; flex-direction: column; height: 100%">
				<div style="flex: 1 1 auto; height: 95%">
					<iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ selectedCamera.Id }}" [src]="selectedCamera.trustedSourceURL" [hidden]="hideIFrame"></iframe>
				</div>
				<div style="flex: 1 1 auto; height: 5%; text-align: center">
					<button type="button" title="Download the video from this camera to your machine." (click)="downloadVideo(selectedCamera)" aria-label="Download" class="iops-btn-sm mr-1 download-button ng-star-inserted">
						<i class="tim-icons icon-cloud-download-93"></i>
					</button>
					<button type="button" aria-label="Calendar" title="Enter a previous date and time to view video from this camera." (click)="showDateTimeForm(selectedCamera)" class="iops-btn-sm mr-1 calendar-button ng-star-inserted">
						<i class="tim-icons icon-calendar-60"></i>
					</button>
				</div>
			</div>
			<div *ngIf="!selectedCamera.downloadAllowed" style="flex: 1 1 auto; height: 100%">
				<iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ selectedCamera.Id }}" [src]="selectedCamera.trustedSourceURL" [hidden]="hideIFrame"></iframe>
			</div>
		</div>
		<div *ngIf="viewAllSystemCameras" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'" style="overflow-y: auto">
			<div style="display: flex; flex-direction: row; flex-wrap: wrap">
				<div *ngFor="let camera of listOfAllCamerasToView; let cameraIndex = index">
					<div style="flex: 1 1 auto; height: 450px; width: 450px">
						<iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ camera.Id }}" [src]="camera.trustedSourceURL | sanitizedUrl"></iframe>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="showHistoricalForm" >
			<p *ngIf="selectedCamera && showIframe && !showHistoricalForm" class="clear-css-styling">
				<iframe class="camera-iframe" id="systemIFrame{{ selectedCamera.Id }}" [src]="selectedCamera.trustedSourceURL" [hidden]="hideIFrame"></iframe>
				<lib-centered-message *ngIf="hideIFrame" centeredMessage="Hiding video while resizing for performance. Please wait..."></lib-centered-message>
			</p>

			<form *ngIf="showHistoricalForm" class="form-horizontal" style="margin: 20px">
				<div style="overflow-x: hidden; overflow-y: auto">
					<p></p>
					<p>Start Date/Time:<kendo-datetimepicker [format]="dateTimeFormat" [(value)]="startDateTime"></kendo-datetimepicker></p>
					<p>Hours: <kendo-numerictextbox [format]="'n'" [(value)]="durationHours" [min]="0" [max]="5"></kendo-numerictextbox></p>
					<p>Minutes: <kendo-numerictextbox [format]="'n'" [(value)]="durationMinutes" [min]="0" [max]="60"></kendo-numerictextbox></p>
					<p>Download: <kendo-switch [(ngModel)]="downloadVideoFile"></kendo-switch></p>
				</div>
				<div class="card-footer text-center">
					<p><button class="btn btn-primary" (click)="executeDateTimeForm()">Play Video</button>&nbsp;<button class="btn" (click)="closeForm()">Cancel</button></p>
				</div>
			</form>
			<div *ngIf="videoBeingRetrieved">
				<lib-loading-spinner *ngIf="videoBeingRetrieved" [loadingMessage]="'Video being retrieved, please wait...'" [vehicle]="'airplane'"></lib-loading-spinner>
			</div>
			<div *ngIf="showVideoJSPlayer" >
				<lib-videojs-player [options]="playback.options"></lib-videojs-player>
			</div>
		</div>
	</div>
</div>
<div *ngIf="global.isMobile == true" style="height: 100%; width: 100%">
	<lib-centered-message *ngIf="isLoading" centeredMessage="Please select a camera from the Navigation tab on the left."></lib-centered-message>
	<ion-content fullscreen="false">
		<div *ngIf="options.length > 0" class="flex-container" style="position: relative">
			<lib-navigation [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-navigation>
			<div *ngIf="selectedCamera && showIframe && !showHistoricalForm && !viewAllSystemCameras" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">
				<div *ngIf="selectedCamera.downloadAllowed" style="display: flex; flex-direction: column; height: 100%">
					<div style="flex: 1 1 auto; height: 95%">
						<iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ selectedCamera.Id }}" [src]="selectedCamera.trustedSourceURL" [hidden]="hideIFrame"></iframe>
						<!-- <iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ selectedCamera.Id }}"><lib-video-player [options]="{autoplay: true, fluid: true, aspectRatio: '16:9', sources: [{ src: selectedCamera.playlistUrl, type: 'application/x-mpegURL' }]}"></lib-video-player></iframe> -->
					</div>
					<div style="flex: 1 1 auto; height: 5%; text-align: center">
						<ion-tab-button type="button" (click)="downloadVideo(selectedCamera)">
							<ion-icon name="cloud-download-outline" size="large"></ion-icon>
						</ion-tab-button>
						<ion-tab-button type="button" (click)="showDateTimeForm(selectedCamera)">
							<ion-icon name="calendar-number-outline" size="large"></ion-icon>
						</ion-tab-button>
					</div>
				</div>
				<div *ngIf="!selectedCamera.downloadAllowed" style="flex: 1 1 auto; height: 100%">
					<iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ selectedCamera.Id }}" [src]="selectedCamera.trustedSourceURL" [hidden]="hideIFrame"></iframe>
				</div>
			</div>
			<div *ngIf="viewAllSystemCameras" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'" style="overflow-y: auto">
				<div style="display: flex; flex-direction: row; flex-wrap: wrap">
					<div *ngFor="let camera of listOfAllCamerasToView; let cameraIndex = index">
						<div style="flex: 1 1 auto; height: 250px; width: 250px">
							<iframe class="camera-iframe" style="min-height: 180px; min-width: 180px" id="systemIFrame{{ camera.Id }}" [src]="camera.trustedSourceURL | sanitizedUrl"></iframe>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="showHistoricalForm" id="historicalForm" style="font-size: 0.9em !important; height:100%; width: 100%; position: relative; text-align: center; background-color: transparent;">
				<ion-content> 
					<ion-item>
						<ion-row>
							<ion-col>
								<ion-label position="fixed" style="vertical-align: bottom;">Start</ion-label>
							</ion-col>
							<ion-col>
								<ion-input position="fixed" type="date" [(ngModel)]="startDateTime" name="startDateTime"></ion-input>
							</ion-col>
						</ion-row>
					</ion-item>
					<ion-item>
						<ion-row>
							<ion-col>
								<ion-label position="fixed" style="vertical-align: bottom;">Hours</ion-label>
							</ion-col>
							<ion-col>
								<ion-input position="fixed" type="number" [(ngModel)]="durationHours" name="durationHours" min="0" max="5"></ion-input>
							</ion-col>
						</ion-row>
					</ion-item>
					<ion-item>
						<ion-row>
							<ion-col>
								<ion-label position="fixed" style="vertical-align: bottom;">Minutes</ion-label>
							</ion-col>
							<ion-col>
								<ion-input position="fixed" type="number" [(ngModel)]="durationMinutes" name="durationMinutes" min="0" max="60"></ion-input>
							</ion-col>
						</ion-row>
					</ion-item>
					<ion-item>
						<ion-row>
							<ion-col> 
								<ion-button expand="block" type="submit" style="width: 105px; margin-left: 80px" class="ion-button" color="primary" (click)="executeDateTimeForm()">Play Video</ion-button>
							</ion-col>
							<ion-col>
								<ion-button expand="block" type="submit" style="width: 105px; margin-left: 80px" class="ion-button" color="secondary" (click)="closeForm()">Cancel</ion-button>
							</ion-col>
						</ion-row>
					</ion-item>
				</ion-content>
			</div>
		</div>
	</ion-content>
</div>
