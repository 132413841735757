import { Component, AfterViewInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GridSettings } from '../../_models/grid-settings.interface';
import { process, State, GroupDescriptor } from '@progress/kendo-data-query';

@Component({
	selector: 'lib-training-courses',
	templateUrl: './training-courses.component.html',
	styleUrls: ['./training-courses.component.scss'],
})
export class TrainingCoursesComponent implements AfterViewInit {
	isLoading: boolean;
	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
		},

		columnsConfig: [],
	};
	constructor(private dataService: DataService) {}

	// 	reationDate
	// :
	// Thu Dec 08 2022 09:07:17 GMT-0600 (Central Standard Time) {}
	// CreationDateMS
	// :
	// 1670512037230
	// CreatorUser
	// :
	// "Dylan Richard"
	// Description
	// :
	// "This course will teach you the basics of IOPS showing you dashboard setup, including dashboard options, widget control and others."
	// Id
	// :
	// 1
	// LastModifiedDate
	// :
	// Fri Dec 09 2022 08:58:40 GMT-0600 (Central Standard Time) {}
	// LastModifiedDateMS
	// :
	// 1670597920130
	// LastModifiedUser
	// :
	// "Dylan Richard"
	// Name
	// :
	// "Introduction to IOPS "

	ngAfterViewInit(): void {
		this.isLoading = true;
		var sql = 'API.RDN_GetTrainingCourses';
		this.dataService.SQLActionAsPromise(sql).then((dataObject: any) => {
			if (dataObject) {
				console.log(dataObject);
				this.gridSettings.gridData = process(
					dataObject,
					this.gridSettings.state
				);
				this.isLoading = false;
			}
		});
	}
}
