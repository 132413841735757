import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GseOperationalAlarmsComponent } from './gse-operational-alarms.component';
import { SassHelperModule } from '../../_helpers/sass-helper/sass-helper.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridContextMenuModule } from '../grid-context-menu/grid-context-menu.module';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [GseOperationalAlarmsComponent],
	imports: [
		CommonModule,
		SassHelperModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		GridModule,
		GridContextMenuModule,
		CenteredMessageModule,
		KendoGridParentModule,
	],
	exports: [GseOperationalAlarmsComponent],
})
export class GseOperationalAlarmsModule {}
