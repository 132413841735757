import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Input,
	OnDestroy,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { WeatherService } from '../../services/weather.service';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';




@Component({
	selector: 'lib-forecast',
	templateUrl: './forecast.component.html',
	styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements OnInit, OnDestroy {
	@Input() parentWidgetObject: any;

	public panelOpenState = false;
	public isDataLoading: boolean = true;
	public loc$: Observable<string>;
	public loc: string;
	public currentWeather: any = <any>{};
	public forecast: any = <any>{};
	public msg: string;

	public weatherInterval: NodeJS.Timeout;
	private fullDataCacheSubscription: any;
	public site: any;
	private updateInterval: number = 900000;

	constructor(
		private store: Store<any>,
		public weatherService: WeatherService,
		public dataService: DataService,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit() {
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			Global.User.DebugMode &&
				console.log(
					'this.dataService.cache = %O',
					this.dataService.cache
				);
			this.initialize();
		}
	}

	initialize() {
		let site = this.dataService.cache.sites.find((site) => {
			return this.parentWidgetObject.WidgetSiteName === site.Name;
		});
		if (site === undefined) {
			this.site = site;
			this.isDataLoading = false;
			return;
		} else {
			this.searchForecast(site.GoogleLatitude, site.GoogleLongitude);
			this.weatherInterval = setInterval(() => {
				this.searchForecast(site.GoogleLatitude, site.GoogleLongitude);
			}, this.updateInterval);
		}
	}

	searchForecast(lat: number, long: number) {
		this.weatherService.getForecastLatLong(lat, long).subscribe(
			(res) => {
				this.forecast = res;
				Global.User.DebugMode &&
					console.log('this.forecast = %O', this.forecast);
				this.isDataLoading = false;
			},
			(err) => {}
		);
	}
	resultFound() {
		return true;
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.weatherInterval) {
			clearInterval(this.weatherInterval);
		}
	}
}
