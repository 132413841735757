import { Injectable } from '@angular/core';
import _ from "lodash";



@Injectable({
  providedIn: 'root'
})
export class ChartService {
	chart: any;

  constructor() { }

  updateHighchartLegendLabel(scope, signalRUpdate) {
	var chrt = !this.chart ? this : this.chart;
	chrt.update({
		legend: {
			labelFormatter: function () {
				if (this.xData === undefined || this.xData === null || this.xData.length === 0) {
					return "";
				}
				var lastVal = this.yData[this.yData.length - 1],
					chart = this.chart,
					xAxis = this.xAxis,

					stateChanges = 0,
					avg = 0,
					min,
					max;
				let points = this.xData.map((x, index) => {
					return {
						x: x,
						y: this.yData[index],
					};
				});
				let filteredPoints = points.filter((point) => {
					if(this.chart.xAxis[0].min !== undefined && this.chart.xAxis[0].max !== undefined){
								return point.x >= this.chart.xAxis[0].min && point.x <= this.chart.xAxis[0].max;
							}
				});


				let pointsOnlyValues = filteredPoints.map((point) => {
					return parseFloat(point.y);
				})

				min = Math.min(...pointsOnlyValues);
				max = Math.max(...pointsOnlyValues);
				avg = pointsOnlyValues.length > 0 ? pointsOnlyValues.reduce((a, b) => a + b) / pointsOnlyValues.length : 0;
				pointsOnlyValues.forEach((point, index) => {
					if (index !== 0 && point !== pointsOnlyValues[index - 1]) {
						stateChanges++;
					}
				});

				let diff = Math.abs(max - min);



				return (
					this.name +
					" (" +
					filteredPoints.length +
					" points) | " +
					"<span >Min: " +
					min +
					" | </span>" +
					"<span >Max: " +
					max +
					" | </span>" +
					"<span >Average: " +
					avg.toFixed(2) +
					" | </span>" +
					"<span >State Changes: " +
					stateChanges +
					" | </span>" +
					"<span >Diff: " +
					diff.toFixed(2) +
					" </span>"

				);


			},
		},
	});

}

GetInterpolatedData(seriesData) {
	var cronologicalListRough = seriesData.flatMap((series) => {
		return series.data.map((dataItem) => {
			return {
				tagId: series.tagId,
				dateMS: dataItem[0],
				value: dataItem[1],
			};
		});
	});
	var cronologicalList = _.sortBy(
		cronologicalListRough,
		(item) => item.dateMS
	);

	// .orderBy(function (item) { return item.dateMS });
	var currentBin = null;

	var tagIdsList = seriesData.map((item) => {
		return item.tagId;
	});
	var isRepeatDateMS = false;

	var bins = cronologicalList
		.map((clItem) => {
			isRepeatDateMS = false;

			if (!currentBin) {
				currentBin = {
					dateMS: clItem.dateMS,
				};
				tagIdsList.forEach((tagId) => {
					currentBin[tagId] =
						clItem.tagId == tagId ? clItem.value : null;
				});
			} else {
				if (currentBin.dateMS < clItem.dateMS) {
					currentBin = { ...currentBin };
					currentBin.dateMS = clItem.dateMS;
				} else {
					isRepeatDateMS = true;
				}
				tagIdsList.forEach((tagId) => {
					if (clItem.tagId == tagId) {
						currentBin[tagId] = clItem.value;
					}
				});
			}

			currentBin.isRepeatDateMS = isRepeatDateMS;

			return isRepeatDateMS ? null : currentBin;
			//return currentBin;
		})
		.filter((item) => {
			return item;
		});

	return bins;
}

IsDataDigital(observations) {
	//This will do a distinct on the data, and see if it consists entirely of 0 and 1
	var distinctDataRough = _.take(observations, 500);

	let distinctData = _.uniqBy(distinctDataRough, (a, b) => {
		return (a && (a[1] || 0)) == (b && (b[1] || 0));
	});

	//If there are two distinct values, and their sum = 1 then they have to be a zero and a one.
	if (
		distinctData.length == 2 &&
		distinctData[0][1] + distinctData[1][1] == 1
	) {

	} else {

		return false;
	}
}
}
