import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";


import swal from 'sweetalert2';
import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	UserSettingsComponent,
	DialogData,
} from '../user-settings/user-settings.component';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { UtilityService } from 'projects/shared-lib/src/lib/services/utility.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';

@Component({
  selector: "user-issues",
  templateUrl: "./issues.component.html",
  styleUrls: ["./issues.component.scss"],
})
export class IssuesComponent implements OnInit {
  public issueForm: FormGroup;
  private subjectId: FormControl = null;
  private description: FormControl = null;
  public mouseoverRegistration = false;
  public subjects: any;
  public editHeader: string = "Add New Issue";
  public editSubHeader: string = "Please enter the subject and the description of the issue you are having with the iOPS application.";

  constructor(public dialogRef: MatDialogRef<UserSettingsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dataService: DataService, private router: Router, private utilityService: UtilityService) { }

  ngOnInit() {
    Global.User.DebugMode && console.log("IssuesPage: currentUser = %O", Global.User.currentUser);

    this.subjectId = new FormControl("");
    this.description = new FormControl("", Validators.required);

    this.issueForm = new FormGroup({
      subjectId: this.subjectId,
      description: this.description,
    });

    this.getSubjects();
  }

  validateDescription() {
    return this.description.valid || this.description.untouched;
  }

  async sendNotificationToUser() {
    swal.fire({
      title: "Issue Submitted",
      position: "center",
      html: "Your issue has been submitted. Thank you for taking the time to log the issue.",
    });
  }

  getSubjects() {
    this.dataService.SQLActionAsPromise("API.User_GetUserIssueSubjects").then((data: any) => {
      console.log("subjects = %O", data);
      this.subjects = data;
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  submitIssue(values: any) {
    console.log("issueFormValues: %O", values);
    //--format parameter list to send to the function.
    var description = values.description;
    console.log("description = " + description);

    values.description = this.utilityService.updateStringToSQLSyntax(description); // -- replace all single quotes with two single quotes.

    var paramString = "API.User_SubmitIssue @UserId=" + Global.User.currentUser.Id + ", @Description='" + values.description + "'";

    if (values.subjectId) {
      paramString += ", @SubjectId=" + values.subjectId;
    }

    this.dataService.SQLActionAsPromise(paramString).then((data: any) => {
      if (data) {
        console.log("data = %O", data);

        this.sendNotificationToUser();
        this.closeDialog();
      }
    });
  }
}
