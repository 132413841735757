import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetSettingsPopupComponent } from './widget-settings-popup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
	declarations: [WidgetSettingsPopupComponent],
	imports: [
		CommonModule,
		MatTooltipModule,
		MatButtonModule,
		MatButtonToggleModule,
		DragDropModule,
		FormsModule,
		ReactiveFormsModule,
		MatProgressSpinnerModule,
	],
	exports: [WidgetSettingsPopupComponent],
})
export class WidgetSettingsPopupModule {}
