<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Map...'"
	[vehicle]="'truck'"
></lib-loading-spinner>

<div
	class="card card-tasks"
	id="card-whole"
	[hidden]="widgetObject && !widgetObject?.SiteList && !widgetObject?.VocationalSettingsJSON"
	style="width: 100% !important"
>
	<div
		(resized)="onResized($event)"
		style="display: inline-block"
		style="height: 100%"
	>
		<div
			[ngClass]="
				global.isMobile
					? 'locateResizeContainerMobile'
					: 'locateResizeContainer'
			"
			class="locateResizeContainer"
			id="locateResizeContainer{{ widgetObject?.WidgetId }}"
		>
			<!-- <div class="left-locate-column" [style.width.%]="defaultLeftWidth" appResizable [hidden]="isDataLoading"> -->
			<div
				[ngClass]="
					global.isMobile
						? 'left-locate-column-mobile'
						: 'left-locate-column'
				"
				[style.width.%]="global.isMobile ? 100 : split1Percent"
				[style.height.%]="!global.isMobile ? 100 : split1Percent"
				[hidden]="isDataLoading"
			>
			<lib-kendo-grid-parent
					[gridData]="gse"
					[gridSettings]="gridSettings"
					[widgetObject]="widgetObject"
					(callSave)="saveGridSettings($event)"
					[style.height.%]="100"
					filterable="menu"
					[sortable]="true"
					[reorderable]="true"
					[resizable]="true"
					[gridContextMenuItems]="['Open Summary for Asset', 'Open Punchout Screen for Asset']"
					(onRightClickSelectEmitter)="onRightClickSelect($event)"
					(openSummaryWidgetEmitter)="openSummaryWidget($event)"
					(showAssetOnMapEmitter)="showAssetOnMap($event)"
					(processedRawDataForGridWithoutPagingDataChangedEmitter)="buildMap(true)"
					*ngIf="isTableReadyForLoading === true"
					#tagDataGrid

					>
					</lib-kendo-grid-parent>


			</div>

			<div
				[ngClass]="
					global.isMobile
						? 'right-locate-column-mobile'
						: 'right-locate-column'
				"
				[style.width.%]="global.isMobile ? 100 : split2Percent"
				[style.height.%]="!global.isMobile ? 100 : split2Percent"

			>
				<lib-generic-overview-map
					*ngIf="childMapReadyToLoad === true"
					#locateAllGSEGenericMapChild
					[showOptionForGeographyZones]="true"
					[tagOptions]="tagOptions"
					[widgetObject]="widgetObject"
					[gridSettings]="gridSettings"
					[assetList]="gseForMap"

				></lib-generic-overview-map>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="widgetObject && !isDataLoading && !widgetObject.SiteList && !widgetObject?.VocationalSettingsJSON"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Site or Fleet from the widget settings."
	>
	</lib-centered-message>
</div>
