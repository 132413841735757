import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit, Input, Output, EventEmitter, Optional, ElementRef } from "@angular/core";

import { Subscription, BehaviorSubject, Subject, firstValueFrom } from "rxjs";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";

import _ from "lodash";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DisplaySetupService } from "../../services/display-setup.service";
import { UtilityService } from "../../services/utility.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";

import { IGlobal } from "../../_models/global.model";
import { ICamera } from "../../_models/camera.model";
import { ISite } from "../../_models/site.model";
import { IAsset } from "../../_models/asset.model";
import { ISystem } from "../../_models/system.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";

@Component({
	selector: "lib-camera-view",
	templateUrl: "./camera-view.component.html",
	styleUrls: ["./camera-view.component.scss"]
})
export class CameraViewComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild("drawer") drawer;
	@Input() autoFocus: boolean;
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() systemsObject: any;
	@Input() fixedTopGap: number;
	@Input() fixedInViewport: boolean;
	@Input() fixedBottomGap: number;
	@Input() disableRipple: boolean;
	@Input() multiple: boolean;

	public subscriptions: Subscription[] = [];
	tagGraphSingleModalSubscription: Subscription;
	public currentSelectedWidget: any;
	public cameras: any;
	public cameraSource: any;
	public widgetSiteId: number;
	public widgetSite: any;
	public widgetSiteName: string;

	public newHeight: number;
	private oldHeight: number;
	public newWidth: number;
	private oldWidth: number;
	private timerId: any;

	public selectedCamera: any;
	public selectedCameraId: number;
	public selectedSystemId: number;
	public previouslySelectedCameraId: number;

	public cameraModified$: any;
	public cameraAdded$: any;
	public selectedCamera$: any;
	public siteChange$: any;

	public headingExtraTitle: string;
	public service: any;
	public camInOurList: any;
	private fullDataCacheExists: boolean = false;
	public isLoading: boolean = true;
	public fullDataCacheExists$: any;
	public showIframe: boolean = false;
	private currentSiteId: number;
	public camerasExistForThisSite: boolean = false;
	public historicalQuestions: QuestionBase<any>[];
	historicalQuestionsFieldsAndValues: any;
	cameraObj: any;
	showHistoricalForm: boolean = false;
	executeFormOptions: any;
	startedViewingLiveCameraDateTime: any;
	startDateTime: any;
	public dateTimeFormat = "MM/dd/yyyy HH:mm";
	durationHours: number;
	durationMinutes: number;
	public downloadVideoFile = false;
	public global: IGlobal = Global;
	public componentName: string = "camera-view: ";
	public showVideoJSPlayer: boolean = false;
	public playback: any = {};

	public currentUserHasElevatedPrivilege: boolean = false;

	assetsForTheSite: any;
	assetIds: any;
	private autoLevelAlarm = [57434, 56295];
	public noAccessToThisWidget: boolean = false;

	@Output() dashboardWidgetDeleted = new EventEmitter<Object>();
	fullDataCacheSubscription: Subscription;
	signalRTagUpdateSubscription: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	assetIdsAsNumbers: any;
	sidenavToggle: Subscription;
	hideIFrame: boolean;
	hideIFrameTimeout: NodeJS.Timeout;
	private fromDashboard: boolean;
	public navigationOpened: boolean = false;
	public sitesWithCameras: Array<any> = [];
	public systemsWithCameras: Array<ISystem> = [];
	public assetsWithCameras: Array<IAsset> = [];
	public accordionMultiOpenedValue: boolean = false;
	public currentAccordionItem: any;
	public viewAllSystemCameras: boolean = false;
	public listOfAllCamerasToView: any;
	public options: any = [];
	public selected: any;
	public videoBeingRetrieved: boolean = false;
	public downloadingFile: boolean = false;
	public downloadUrl: any;

	constructor(private http: HttpClient, public dataService: DataService, private signalRCore: SignalRCoreService, private displaySetupService: DisplaySetupService, private sanitizer: DomSanitizer, private utilityService: UtilityService, public dialog: MatDialog, private dashboardService: DashboardService, private domSanitizer: DomSanitizer, private elRef: ElementRef) {
		this.service = this;
	}

	ngOnInit() {
		this.fromDashboard = this.widgetObject ? true : false;
		this.isLoading = true;
		this.selectedCamera$ = new BehaviorSubject(this.selectedCameraId);

		var service = this;
		Global.User.DebugMode && console.log(this.componentName + "Global.FullDataCacheExists = " + Global.FullDataCacheExists);

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			Global.User.DebugMode && console.log(this.componentName + "going to initialize since cache exists...");
			this.initialize();
		}

		//Start watching for selected camera Id changes
		this.selectedCamera$.subscribe(
			(cameraId: any) => {
				try {
					var selectedCamera: any = service.dataService.cache.camerasObject[cameraId];
					this.switchToNewlySelectedCamera(selectedCamera);
				} catch (error) { }
			},
			(err: Error) => console.error(`Error with selectedCamera$: ${err}`)
		);
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + "ngOnDestroy invoked...");
		this.selectedCamera$ && this.selectedCamera$.unsubscribe();
		if (!_.isNil(this.widgetGroupSettings)) {
			//this.signalRCore.leaveParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
		}
	}

	initialize() {
		Global.User.DebugMode && console.log(this.componentName + "CameraView invoked");
		Global.User.DebugMode && console.log(this.componentName + "initialize invoked...");
		var service = this;
		if (service.systemsObject) {
			//-- this came from a Summary widget.  Get the data object's set of cameras and build out the navigation only for that set of cameras at that System record.
			console.log(this.componentName + "systemsObject = %O", this.systemsObject);
			service.buildNavigationOptionsArray(this.systemsObject);
		}
		else {

			console.log(this.componentName + "widgetObject = %O", this.widgetObject);

			this.selectedCameraId = this.widgetObject.SelectedCameraId;

			this.selectedSystemId = this.widgetObject.SelectedCameraId != null ? null : this.widgetObject.SelectedSystemId;
			this.navigationOpened = this.selectedCameraId != null || this.selectedSystemId != null ? true : false;
			Global.User.DebugMode && console.log(this.componentName + "this.widgetObject = %O", this.widgetObject);
			var widget: any = this.widgetObject;
			service.displaySetupService.setWidgetPanelBodyDimensions(widget.WidgetId);

			this.widgetSiteName = this.widgetSite && this.widgetSite.Name;
			Global.User.DebugMode && console.log(this.componentName + "this.widgetSiteName = " + this.widgetSiteName);
			service.displaySetupService.setWidgetPanelBodyDimensions(widget.WidgetId);

			setTimeout(() => {
				console.log("building options for navigation...");
				service.buildNavigationOptionsArray();

				console.log("this.options for navigation = %O", service.options);
			}, 500);
		}
		//-- don't need to continue listening for Global.FullDataCacheExists change, so unsubscribe if observable exists. --Kirk T. Sherer, March 25, 2020.
		if (this.fullDataCacheExists$) {
			Global.User.DebugMode && console.log(this.componentName + "unsubscribing to fullDataCacheExists$...");
			this.fullDataCacheExists$.unsubscribe();
			this.fullDataCacheExists = true;
		}

	}

	ngAfterViewInit() {
		this.drawer && this.drawer.toggle();
	}

	private buildNavigationOptionsArray(parentSystemObject?: any) {
		var service = this;
		var navigationWidth = Global.isMobile ? "90px" : "105px";
		console.log("parentSystemObject = %O", parentSystemObject);
		if (parentSystemObject != undefined) {

			service.options = [
				{
					id: 1,
					width: navigationWidth,
					name: "Navigation",
					children: service.dataService.cache.systems.where((system: ISystem) => system.Id == parentSystemObject.Id)
						.select((system: ISystem) => {
							var newSystem = {
								id: 11,
								width: navigationWidth,
								name: "Gate " + system.Name,
								selected: true,
								children: (function (cameraList: Array<ICamera>) {
									var firstCamera = cameraList.first();

									var cameras = cameraList
										.select((camera: ICamera) => {
											var newCamera = {
												id: 12,
												width: navigationWidth,
												name: camera.SelectorButtonLabel,
												selected: Global.isMobile ? (firstCamera.Id == camera.Id ? true : false) : false,
												action: () => service.changeCamera(camera)
											};
											if (newCamera.selected) {
												service.changeCamera(camera);
											}
											return newCamera;
										})
										.toArray();
									if (system.Cameras.length > 1 && !Global.isMobile) {
										var viewAllCameraEntry = {
											id: 12,
											width: "100px",
											name: "View All " + system.Cameras.length,
											selected: true,
											action: () => service.viewAllCameras(null, system)
										};
										if (viewAllCameraEntry.selected) {
											service.viewAllCameras(null, system);
										}
										cameras.push(viewAllCameraEntry);
									}
									return cameras;
								})(system.Cameras)
							};
							return newSystem;
						})
						.toArray(),
					root: true,
					opened: false
				}
			]
		}
		else {

			var selectedCamera = service.dataService.cache.camerasObject[service.selectedCameraId];
			var selectedSystem = service.dataService.cache.systemsObject[service.selectedSystemId];

			service.options = [
				{
					id: 1,
					width: navigationWidth,
					name: "Settings",
					children: [
						{
							id: 10,
							width: navigationWidth,
							name: "Edit Name",
							action: () => service.widgetObject.editWidgetName(service.widgetObject)
						},
						{
							id: 10,
							width: navigationWidth,
							name: "Reload",
							action: () => service.dashboardService._reloadedWidget.next(service.widgetObject)
						},
						{
							id: 10,
							width: navigationWidth,
							name: "Delete",
							action: () => service.widgetObject.deleteFunction()
						}
					],
					root: true,
					opened: false
				},
				{
					id: 2,
					width: navigationWidth,
					name: "Navigation",
					children: Global.User.PermittedSites
						.where((site: ISite) => {
							return site.Cameras.length > 0 && site.Active == true;
						})
						.orderBy((site: ISite) => {
							return site.Name;
						})
						.select((site: ISite) => {
							var newSite = {
								id: 10,
								width: navigationWidth,
								name: site.Name,
								selected: selectedCamera?.AssignedSiteId == site.Id || selectedSystem?.Site?.Id == site.Id,
								children: site.Systems.where((system: ISystem) => {
									return system.Cameras.length > 0;
								})
									.select((system: ISystem) => {
										var newSystem = {
											id: 11,
											width: navigationWidth,
											name: system.Name,
											selected: selectedCamera?.AssignedSystemId == system.Id || selectedSystem?.Id == system.Id,
											children: (function (cameraList: Array<ICamera>) {
												var cameras = cameraList
													.select((camera: ICamera) => {
														var newCamera = {
															id: 12,
															width: navigationWidth,
															name: camera.SelectorButtonLabel,
															selected: service.selectedCameraId == camera.Id,
															action: () => service.changeCamera(camera)
														};
														if (newCamera.selected) {
															service.changeCamera(camera);
														}
														return newCamera;
													})
													.toArray();
												if (system.Cameras.length > 1 && !Global.isMobile) {
													var viewAllCameraEntry = {
														id: 12,
														width: navigationWidth,
														name: "View All " + system.Cameras.length,
														selected: service.selectedCameraId == null && service.selectedSystemId == system.Id,
														action: () => service.viewAllCameras(null, system)
													};
													if (viewAllCameraEntry.selected) {
														service.viewAllCameras(null, system);
													}
													cameras.push(viewAllCameraEntry);
												}
												return cameras;
											})(system.Cameras)
										};
										return newSystem;
									})
									.toArray()
							};
							return newSite;
						})
						.toArray(),
					root: true,
					opened: Global.isMobile ? false : this.selectedCameraId != null || this.selectedSystemId != null ? false : true //--only displaying the initial navigation menu if no camera has been selected. The camera already has where it's located in the video feed.
				},
			];

			if (Global.isMobile) {
				service.options = service.options.where((item: any) => { return item.id != 1 }).toArray(); //--removing Settings tab for mobile.
			}
		}

		service.isLoading = false;
	}
	public checkNavigation(opened: any) {
		console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public accordionChange(event: Event, accordionItem: any) {
		event.stopPropagation();
		event.preventDefault();

		console.log("event = %O", event);
		console.log("accordionItem = %O", accordionItem);
		console.log("accordionItem.accordion = %O", accordionItem.accordion);

		// if (this.currentAccordionItem) {
		// 		console.log("currentAccordionItem.expanded = " + this.currentAccordionItem.expanded);
		// 		console.log("currentAccordionItem = %O", this.currentAccordionItem);
		// }

		// if (this.currentAccordionItem && this.currentAccordionItem != accordionItem) {
		// 	this.currentAccordionItem && this.currentAccordionItem.toggle();

		// }

		//this.currentAccordionItem = accordionItem;

		accordionItem.toggle();
	}

	public viewAllCameras(event: Event, system: ISystem) {
		console.log("event = %O", event);

		console.log("user requested to view all cameras. System: %O", system);
		this.showHistoricalForm = false; //hide the Dynamic datetime form and Play Video
		if (this.widgetObject) {
			this.widgetObject.SelectedCameraId = null;
			this.widgetObject.SelectedSystemId = system.Id;
			this.widgetObject.WidgetGateSystemId = system.Id; //-- this setting is simply for setting the gate system in the widget header.
			this.widgetObject.WidgetSiteId = system.Site?.Id;
			//Save the selected Camera to Widget Record -- JI -- 08/25/2021
			this.service.saveWidgetObject("SelectedCameraId", null);
			this.service.saveWidgetObject("SelectedSystemId", system.Id);
			this.service.saveWidgetObject("SiteId", system.Site?.Id);
			this.widgetObject.siteChange();
			this.widgetObject.systemChange();
			console.log("this.widgetObject = %O", this.widgetObject);
		}
		this.selectedCamera = null;
		this.selectedCameraId = null;

		this.viewAllSystemCameras = true;
		var countOfCameras = 1;
		this.listOfAllCamerasToView = system.Cameras.select((camera: any) => {
			var videoDesktopUrl = camera.Id >= 100 ? Global.Video.serverLiveUrl.replace("001", "") : Global.Video.serverLiveUrl;
			var videoMobileUrl = camera.Id >= 100 ? Global.Video.serverMobileLiveUrl.replace("001", "") : Global.Video.serverMobileLiveUrl;

			var HLSSourceURL = videoDesktopUrl + "?CameraID=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&timestamp=" + new Date().getTime();
			if (Global.isMobile) {
				HLSSourceURL = videoMobileUrl + "?CameraID=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&timestamp=" + new Date().getTime();
			}

			var newObject = {
				trustedSourceURL: HLSSourceURL,
				Number: countOfCameras++
			};
			return newObject;
		}).toArray();
		console.log("listOfAllCamerasToView: %O", this.listOfAllCamerasToView);
	}

	transform(value: any): any {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
	}

	changeCamera(camera: any) {
		this.viewAllSystemCameras = false;
		this.startedViewingLiveCameraDateTime = new Date();
		var videoDesktopUrl = camera.Id >= 100 ? Global.Video.serverLiveUrl.replace("001", "") : Global.Video.serverLiveUrl;
		var videoMobileUrl = camera.Id >= 100 ? Global.Video.serverMobileLiveUrl.replace("001", "") : Global.Video.serverMobileLiveUrl;
		var videoPlaylistUrl = camera.Id >= 100 ? Global.Video.serverPlaylistUrl.replace("001", "") : Global.Video.serverPlaylistUrl;
		var videoMobilePlaylistUrl = camera.Id >= 100 ? Global.Video.serverMobilePlaylistUrl.replace("001", "") : Global.Video.serverMobilePlaylistUrl;

		var HLSSourceURL = videoDesktopUrl + "?CameraID=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&timestamp=" + new Date().getTime();
		var playlistUrl = videoPlaylistUrl + camera.Id + "/live.m3u8";
		if (Global.isMobile) {
			HLSSourceURL = videoMobileUrl + "?CameraID=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&timestamp=" + new Date().getTime();
			playlistUrl = videoMobilePlaylistUrl + camera.Id + "/live.m3u8";
			// 	camera.trustedSourceURL = this.getSafeResourceUrl(camera);
			// 	camera.sanitizedUrl = this.getSafeUrl(camera);
			// } else {
			// 	camera.trustedSourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(HLSSourceURL);
		}
		camera.trustedSourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(HLSSourceURL);
		camera.downloadAllowed = Global.User.isAdmin || Global.User.Privilege[camera.Site.Name].Write == true;
		camera.playlistUrl = this.sanitizer.bypassSecurityTrustResourceUrl(playlistUrl);
		this.switchToNewlySelectedCamera(camera);
	}

	public openCloseNavigation() {
		console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}

	onResized(event: any) {
		if (this.hideIFrameTimeout) {
			clearTimeout(this.hideIFrameTimeout);
		}
		this.hideIFrame = true;
		this.newHeight = event.newRect.height;
		this.oldHeight = event.oldHeight;
		this.newWidth = event.newRect.width;
		this.oldWidth = event.oldWidth;
		this.hideIFrameTimeout = setTimeout(() => {
			this.hideIFrame = false;
		}, 1000);
	}

	openSettingsIfNoSiteAndCloseIfSiteIsPresent() {
		console.log("Opening settings vm.widgetSite = %O", this.widgetSite);
		if (!this.widgetSite) {
			this.dataService.currentSelectedWidget = this.currentSelectedWidget;
			Global.User.DebugMode && console.log(this.componentName + "Should be going to Widget Settings since the widget site doesn't exist...");
		}
	}

	getSafeResourceUrl(camera: any, dateTime1?: any, dateTime2?: any): SafeResourceUrl {
		var url = this.buildCameraUrl(camera, dateTime1, dateTime2);
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}

	buildCameraUrl(camera: any, dateTime1?: any, dateTime2?: any): string {
		var sourceURL = camera.HLSSourceURL;
		if (Global.isMobile) {
			//-- if this is an iOS device, you have to use the /playlist instead of /play.
			sourceURL = sourceURL.replace("iopspro", "iopsmobile");
		}
		var url = sourceURL + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken);
		if (dateTime1 != undefined && dateTime2 != undefined) {
			url += "&Time1=" + dateTime1 + "&Time2=" + dateTime2 + "&SegmentLength=60";
		}
		camera.sourceUrl = url;
		return url;
	}

	getSafeUrl(camera: any, dateTime1?: any, dateTime2?: any): SafeUrl {
		var url = this.buildCameraUrl(camera, dateTime1, dateTime2);
		return this.domSanitizer.bypassSecurityTrustUrl(url);
	}

	switchToNewlySelectedCamera(camera: any) {
		if (camera) {
			this.showHistoricalForm = false; //hide the Dynamic datetime form and Play Video
			if (this.widgetObject) {
				this.widgetObject.SelectedCameraId = camera.Id;
				this.widgetObject.WidgetGateSystemId = camera.System?.Id; //-- this setting is simply for setting the gate system in the widget header.
				this.widgetObject.WidgetSiteId = camera.System?.Site?.Id;

				//Save the selected Camera to Widget Record -- JI -- 08/25/2021
				this.service.saveWidgetObject("SelectedCameraId", camera.Id);
				this.service.saveWidgetObject("SystemId", camera.System?.Id); //--this can't be the selectedSystem, since that's used for selecting all cameras at a gate system.
				this.service.saveWidgetObject("SiteId", camera.System?.Site?.Id);
				this.widgetObject.siteChange();
				this.widgetObject.systemChange();
			}
			this.selectedCamera = camera;
			this.selectedCameraId = camera.Id;
			this.selectedSystemId = null; //-- this has to be set to null so the widget knows we're only looking at one camera and not all cameras at a specific gate system. --Kirk T. Sherer, November 1, 2023.

			setTimeout(() => {
				this.showIframe = true;
			}, 200);

			this.signalRCore.LogActivity("Switched to the " + camera.Name + " camera for the " + this.widgetObject.WidgetTypeName + " widget.");

		}
	}

	showDateTimeForm(camera: any) {
		this.startDateTime = new Date();
		this.startDateTime.setMinutes(this.startDateTime.getMinutes() - 30);
		this.durationHours = 0;
		this.durationMinutes = 30;
		//Show the form
		this.showHistoricalForm = true;
		this.cameraObj = camera;
	}

	closeForm() {
		this.showHistoricalForm = false;
		this.showVideoJSPlayer = false;
	}

	executeDateTimeForm() {
		this.showVideoJSPlayer = false; //-- in case the historical video player is being displayed already, hide it again so the form can be resubmitted. --Kirk T. Sherer, April 25, 2024. 
		let currentDateTime = new Date();
		var startDate = new Date(this.startDateTime);
		let startHours = startDate.getHours();
		let startMinutes = startDate.getMinutes();
		//Set End Date/Time to Start Date/Time plus the Duration(Hours + Minutes)
		let endDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startHours + this.durationHours, startMinutes + this.durationMinutes);

		let startDateFormatted = startDate.toString();
		let endDateFormatted = endDateTime.toString();

		let diffInMilliSeconds = Math.abs(Date.parse(endDateFormatted) - Date.parse(startDateFormatted));
		let hours = diffInMilliSeconds / (1000 * 60 * 60);

		let sevenDays = new Date();
		sevenDays.setDate(currentDateTime.getDate() - 7);

		//Conditions to check against user selected startDate and Duration in the form
		if (this.startDateTime < sevenDays) {
			this.utilityService.showAlert("Please select a start date within the last seven (7) Days");
		} else if (hours > 5) {
			this.utilityService.showAlert("Please select a time range less than five (5) hours");
		} else if (currentDateTime < endDateTime) {
			this.utilityService.showAlert("Please select a Duration Range in the past");
		} else if (diffInMilliSeconds < 60000) {
			this.utilityService.showAlert("Please select at least 1 minute of Playback/Download");
		} else {
			//If user selected Download and PlayVideo in the form, this.downloadVideoFile == true.  Otherwise, it's false.
			this.downloadVideo(this.cameraObj, startDate, endDateTime, this.downloadVideoFile);
		}
	}

	async downloadVideo(camera: any, startDate?: any, endDate?: any, download?: boolean) {
		var downloadCameFromHistoricalEditForm = false;
		if (!startDate) {
			download = true;
			//-- If we didn't come in here with a startDate parameter, that means the user clicked the 'Download' button, meaning they want to download the current LIVE video from the point they started viewing it.
			//-- if that's less than 60 seconds, then we'll get 60 seconds prior to the date we started watching the live video. --Kirk T. Sherer, March 23, 2021.

			let currentDateTime = new Date();
			let diffInMilliSeconds = Math.abs(Date.parse(currentDateTime.toString()) - Date.parse(this.startedViewingLiveCameraDateTime.toString()));
			let minutes = diffInMilliSeconds / (1000 * 60);

			if (this.utilityService.DateTimeInMilliseconds(currentDateTime) - this.utilityService.DateTimeInMilliseconds(this.startedViewingLiveCameraDateTime) < 60000 || minutes <= 90) {
				//-- it's been less than 90 minutes since we started viewing the current live camera feed.  Make the start date 60 minutes earlier than the startedViewingLiveCameraDateTime.
				this.startedViewingLiveCameraDateTime.setMinutes(this.startedViewingLiveCameraDateTime.getMinutes() - 60);
			} else if (minutes > 90) {
				//-- if its more than 90 minutes since viewing live cam ,default to only 90 mins of download
				this.startedViewingLiveCameraDateTime.setMinutes(this.startedViewingLiveCameraDateTime.getMinutes() - 90);
			}
			startDate = this.startedViewingLiveCameraDateTime;
			endDate = currentDateTime;
		}
		else {
			downloadCameFromHistoricalEditForm = true;
		}

		this.videoBeingRetrieved = downloadCameFromHistoricalEditForm == true ? true : false;

		//-- converting startDate and endDate into milliseconds for URL being used for download, regardless of whether we're downloading a live video feed or one from the historical form. --Kirk T. Sherer, March 23, 2021.

		let downloadVideoStartDateInMilliseconds = this.utilityService.DateTimeInMilliseconds(startDate);
		let downloadVideoEndDateInMilliseconds = this.utilityService.DateTimeInMilliseconds(endDate);

		var videoDesktopUrl = camera.Id >= 100 ? Global.Video.serverDownloadUrl.replace("001", "") : Global.Video.serverDownloadUrl;
		var videoMobileUrl = camera.Id >= 100 ? Global.Video.serverMobileDownloadUrl.replace("001", "") : Global.Video.serverMobileDownloadUrl;
		var videoServerDesktopUrl = camera.Id >= 100 ? Global.Video.serverUrl.replace("001", "") : Global.Video.serverUrl;
		var videoServerMobileUrl = camera.Id >= 100 ? Global.Video.serverMobileUrl.replace("001", "") : Global.Video.serverMobileUrl;

		var videoUrl = Global.isMobile ? videoMobileUrl : videoDesktopUrl;
		var videoServerUrl = Global.isMobile ? videoServerMobileUrl : videoServerDesktopUrl;

		var viewingUrl = videoUrl + "?CameraId=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&Time1=" + downloadVideoStartDateInMilliseconds + "&Time2=" + downloadVideoEndDateInMilliseconds + "&SegmentLength=3&Delivery=body&GeneratePlaylist=1";
		if (downloadCameFromHistoricalEditForm)
			//8 days of the 60 segment length -- Notes from Grant
			var downloadUrl = videoUrl + "?CameraId=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&Time1=" + downloadVideoStartDateInMilliseconds + "&Time2=" + downloadVideoEndDateInMilliseconds + "&SegmentLength=60&Delivery=download";
		// last 90 minutes of the 3 segment length -- Notes from Grant
		else
			var downloadUrl = videoUrl + "?CameraId=" + camera.Id + "&accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken) + "&Time1=" + downloadVideoStartDateInMilliseconds + "&Time2=" + downloadVideoEndDateInMilliseconds + "&SegmentLength=3&Delivery=download";

		var httpHeaders = new HttpHeaders({
			"Content-Type": "text/plain",
			Authorization: Global.User.currentUser.ODataAccessToken
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as const,
			responseType: "text" as const
		};
		var time0 = performance.now();

		if (downloadCameFromHistoricalEditForm) {

			const httpGet = this.http.get(viewingUrl, httpOptions);
			var getResponse = await firstValueFrom(httpGet);

			var time = performance.now() - time0;

			console.log(this.componentName + "time for video server to construct the file and send back the URL: " + time + " milliseconds.");

			if (getResponse.body == null || time < 5000) {
				console.log("error constructing playback video on Video Server: %O", getResponse);
				this.utilityService.showToastMessageShared({
					type: "error",
					message: "Your requested video was unable to be retrieved. Please log an issue, if needed."
				});
				return null;
			}
			else {
				var returnUrl = videoServerUrl + getResponse.body.replace("m3u8", "mp4") + "?accessToken=" + encodeURIComponent(Global.User.currentUser.ODataAccessToken);
				this.playback.options = {
					autoplay: true,
					controls: false,
					sources: [
						{
							src: returnUrl,
							type: "video/mp4"
						}
					]
				};
				this.videoBeingRetrieved = false;
				this.showVideoJSPlayer = true;
				if (download) {
					this.downloadFullVideoFromServer(downloadUrl, camera, startDate, endDate, downloadVideoStartDateInMilliseconds, downloadVideoEndDateInMilliseconds);
				}
				else {
					this.downloadingFile = false;
				}
			}
		}
		else {
			//-- user is just downloading the current camera, 60-90 minutes back.
			this.downloadFullVideoFromServer(downloadUrl, camera, startDate, endDate, downloadVideoStartDateInMilliseconds, downloadVideoEndDateInMilliseconds);
		}
	}

	downloadFullVideoFromServer(downloadUrl: string, camera: any, startDate: any, endDate: any, downloadVideoStartDateInMilliseconds: number, downloadVideoEndDateInMilliseconds: number) {
		this.downloadingFile = true;
		this.utilityService.showToastMessageShared({
			type: "info",
			message: "Attempting to download your video from this camera..."
		});
		var videoFileName = "Camera" + " " + camera.Name + " " + moment(startDate).format("YYYY/MM/DD HH:mm") + " to " + moment(endDate).format("YYYY/MM/DD HH:mm") + ".mp4";

		var time0 = performance.now();
		this.http.get(downloadUrl, { responseType: "blob" }).subscribe((data: any) => {
			var time = performance.now() - time0;
			if (time > 5000) {
				const a = document.createElement("a");
				const objectUrl = URL.createObjectURL(data);
				a.href = objectUrl;
				a.download = videoFileName;
				a.click();
				URL.revokeObjectURL(objectUrl);
				console.log("Download process completed.");
				this.utilityService.showToastMessageShared({
					type: "success",
					message: "Your requested video has been downloaded.  Filename: " + videoFileName + "."
				});
				this.signalRCore.LogActivity("Downloaded video from the " + camera.Name + " camera for the " + this.widgetObject.WidgetTypeName + " widget. StartTimeInMS: " + downloadVideoStartDateInMilliseconds + ", EndTimeInMS: " + downloadVideoEndDateInMilliseconds);
			}
			else {
				this.utilityService.showToastMessageShared({
					type: "error",
					message: "Your requested video was not able to be downloaded. Please log an issue, if needed."
				});
			}
			this.downloadingFile = false;
			this.downloadVideoFile = false;
		}),
			(error: Error) => console.error(`Error in download: ${error}`),
			() => {
			};


	}

	widgetWasDeleted(event: boolean) {
		this.dashboardWidgetDeleted.emit(this.widgetObject);
		this.signalRCore.LogActivity("Deleted the " + this.widgetObject.WidgetTypeName + " widget.");
	}

	saveWidgetObject(fieldName: string, fieldValue: string) {
		this.dataService.SQLActionAsPromise("API.Widget_UpdateRecordByIdAndFieldName " + this.widgetObject.WidgetId + ", '" + fieldName + "', '" + fieldValue + "'").then((data: any) => {
			Global.User.DebugMode && console.log("data updated: %O", data);
			this.signalRCore.LogActivity("Updated '" + fieldName + "' = " + fieldValue + " for the " + this.widgetObject.WidgetTypeName + " (WidgetId: " + this.widgetObject.WidgetId + ") widget.");
		});
	}
}
