import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'lib-weather-widget',
	templateUrl: './weather-widget.component.html',
	styleUrls: ['./weather-widget.component.scss'],
})
export class WeatherWidgetComponent implements OnInit {
	@Input() widgetObject: any;

	constructor() {}

	ngOnInit() {
		if (this.widgetObject.WidgetSiteName !== undefined) {
			this.widgetObject.isDisplayDataLive = true;
		}
	}
}
