<div class="card card-tasks" id="card-whole" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header user-settings" style="cursor: default; padding-top: 0px; min-height: 0px"   cdkDragHandle>
        <div class="card-header user-settings">
			<h5 class="title d-inline" style="font-size: x-large">Personal Information for <strong>{{ global.User.currentUser.GivenName + " " + global.User.currentUser.FamilyName }}</strong></h5>
			<div style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" tooltip="Close" placement="bottom" (click)="closeDialog()">
				<i style="cursor:pointer" class="tim-icons icon-simple-remove"></i>
			</div>
		</div>
	</div>
	<div class="card-body user-settings" id="personal-information" style="overflow-y: auto;">
		<span class="fa fa-spinner spinner" *ngIf="isLoading"></span>
		<div *ngIf="!isLoading">
			<div>
				<lib-dynamic-form [questions]="editQuestions" [options]="formOptions" (submittedValues)="submitForm($event)"></lib-dynamic-form>
			</div>
		</div>
	</div>
</div>
