<lib-loading-spinner *ngIf="isDataLoading" [loadingMessage]="'Loading Summary Info...'" [vehicle]="'plane'"></lib-loading-spinner>
<div *ngIf="!isDataLoading && (!fromDashboard || widgetObject?.WidgetGateSystemId)" class="card card-tasks">
	<mat-tab-group animationDuration="0ms" (selectedTabChange)="matTabHasChanged($event)">
		<mat-tab *ngFor="let tab of widgetTabs" [label]="tab.textLabel">
			<ng-template mat-tab-label>
				<span
					[ngClass]="{
						criticalAlarmBadge: alertStatus === 3,
							alarmBadge: alertStatus === 1,
							warningBadge: alertStatus === 2
						}"
					[matBadge]="tab.textLabel === 'Alerts' && countOfAlarms > 0 ? countOfAlarms : undefined"
				>
					{{ tab.textLabel }}
				</span>
			</ng-template>
			<div *ngIf="tab.textLabel === 'Data' && tab.rendered" (resized)="onResized($event)" [style.height.%]="100" class="subTabContainer">
				<div class="asset-summary-left" [style.width.%]="defaultWidth" *ngIf="!isDataLoading">
					<div *ngIf="!isDataLoading" class="parent-table-container">

						<lib-kendo-grid-parent
							[gridData]="dataService.cache.assetsObject[assetId].Tags"
							[gridSettings]="kendoGrids.tags.gridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							[gridContextMenuItems]="gridContextMenuItems"
							(onRightClickSelectEmitter)="onRightClickSelect($event)"
							#tagDataGrid

						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>

			<div *ngIf="tab.textLabel === 'Quick View' && tab.rendered" (resized)="onResized($event)" [style.height.%]="100" class="subTabContainer">
				<div class="asset-summary-left" [style.width.%]="defaultWidth" *ngIf="!isDataLoading && quickViewTagIds.length > 0">
					<lib-pca-quick-view [parentAsset]="assetObj" [parentTagIds]="quickViewTagIds" [widgetObject]="widgetObject"></lib-pca-quick-view>
				</div>
			</div>

			<div *ngIf="tab.textLabel === 'Alerts' && tab.rendered" class="subTabContainer">
				<div class="asset-summary-left" [style.width.%]="defaultWidth" *ngIf="!isDataLoading">
					<div *ngIf="!isDataLoading" class="parent-table-container">

						<lib-kendo-grid-parent
							[gridData]="dataService.cache.assetsObject[assetId].ActiveAlarmTags"
							[gridSettings]="kendoGrids.activeAlerts.gridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							[gridContextMenuItems]="gridContextMenuItems"
							(onRightClickSelectEmitter)="onRightClickSelect($event)"
							#alarmDataGrid

					>
						</lib-kendo-grid-parent>
				</div>
				</div>
			</div>

			<div *ngIf="tab.textLabel === 'Last 1000 Events' && tab.rendered" class="subTabContainer" (resized)="onResized($event)">
				<div class="asset-summary-left" [style.width.%]="defaultWidth">
					<lib-last-thousand-grid [widgetObject]="widgetObject" *ngIf="!isDataLoading && kendoGrids.last1000Events.isRendered" #last1000EventsGrid [gridSettings]="kendoGrids.last1000Events.gridSettings" [configuration]="kendoGrids.last1000Events.config" [parentContainerSize]="parentContainerSize" (callSave)="saveGridSettings()"></lib-last-thousand-grid>
				</div>
			</div>

			<div *ngIf="tab.textLabel === 'Last 1000 Faults' && tab.rendered" class="subTabContainer" (resized)="onResized($event)">
				<div class="asset-summary-left" [style.width.%]="defaultWidth">
					<lib-last-thousand-grid [widgetObject]="widgetObject" *ngIf="!isDataLoading && kendoGrids.last1000Alarms.isRendered" #last1000AlarmsGrid [gridSettings]="kendoGrids.last1000Alarms.gridSettings" [configuration]="kendoGrids.last1000Alarms.config" [parentContainerSize]="parentContainerSize" (callSave)="saveGridSettings()"></lib-last-thousand-grid>
				</div>
			</div>

			<div *ngIf="tab.textLabel === 'Statistics' && tab.rendered" class="subTabContainer">
				<div class="asset-summary-left" [style.width.%]="defaultWidth" *ngIf="!isDataLoading">
					<lib-dynamic-form [hidden]="isHourMeterReadingFormLoading" [questions]="hourMeterReadingFields" [options]="hourMeterReadingFormOptions" (submittedValues)="submitHourMeterReadingEvent($event)"></lib-dynamic-form>
					<div *ngIf="this.hourMeterReadingEvents != undefined && this.hourMeterReadingEvents.length > 0" class="linear-gauge-container">
						<h5>
							Current Reading :
							<span class="hmi-on">{{ currentHourMeterReadingValue }}</span>
						</h5>
						<br />
					</div>

					<lib-kendo-grid-parent
						[hidden]="isHourMeterReadingFormLoading"
						[gridData]="hourMeterReadingEvents"
						[gridSettings]="hourMeterReadingEventsGridSettings"
						[widgetObject]="widgetObject"
						(callSave)="saveGridSettings()"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						#hourMeterReadingEventsGrid

					>
				</lib-kendo-grid-parent>
				</div>
			</div>

			<div *ngIf="tab.textLabel === 'Overview' && tab.rendered">
				<div *ngIf="showAssetModelTemplateDoesnotExist === true" class="card card-tasks center" id="card-whole">
					<div>{{ templateRetrievalMessage }}</div>
				</div>
				<gridster [options]="options" *ngIf="showAssetModelTemplate === true">
					<gridster-item [item]="item" *ngFor="let item of pcaAssetTemplate">
						<div class="button-holder"></div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 2 && item.JBTStandardObservationId == 4704">
							<lib-pca-heat [summaryInputTagId]="heat1Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-heat>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 2 && item.JBTStandardObservationId == 3883">
							<lib-pca-heat [summaryInputTagId]="heat2Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-heat>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 2 && item.JBTStandardObservationId == 3884">
							<lib-pca-heat [summaryInputTagId]="heat3Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-heat>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 2 && item.JBTStandardObservationId == 3885">
							<lib-pca-heat [summaryInputTagId]="heat4Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-heat>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 2 && item.JBTStandardObservationId == 12539">
							<lib-pca-heat [summaryInputTagId]="heat5Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-heat>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 3">
							<lib-pca-blower [summaryInputTagId]="blowerId"></lib-pca-blower>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 4">
							<lib-pca-cond-pump [summaryInputTagId]="condensatePumpId"></lib-pca-cond-pump>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 5 && item.JBTStandardObservationId == 3787">
							<lib-pca-condensorfans [summaryInputTagId]="fan1Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-condensorfans>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 5 && item.JBTStandardObservationId == 3818">
							<lib-pca-condensorfans [summaryInputTagId]="fan2Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-condensorfans>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 5 && item.JBTStandardObservationId == 3845">
							<lib-pca-condensorfans [summaryInputTagId]="fan3Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-condensorfans>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 5 && item.JBTStandardObservationId == 12541">
							<lib-pca-condensorfans [summaryInputTagId]="fan4Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-condensorfans>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 6">
							<lib-pca-discharge-mode [summaryInputTagId]="dischModeId"></lib-pca-discharge-mode>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 7">
							<lib-pca-estop [summaryInputTagId]="estopTagId"></lib-pca-estop>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 8">
							<lib-pca-operation-mode [summaryInputTagId]="operationModeId"></lib-pca-operation-mode>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 11 && item.JBTStandardObservationId == 3967">
							<lib-pca-pressures [summaryInputTagId]="comp1Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-pressures>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 11 && item.JBTStandardObservationId == 3598">
							<lib-pca-pressures [summaryInputTagId]="comp2Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-pressures>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 11 && item.JBTStandardObservationId == 3968">
							<lib-pca-pressures [summaryInputTagId]="comp3Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-pressures>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 11 && item.JBTStandardObservationId == 3969">
							<lib-pca-pressures [summaryInputTagId]="comp4Id" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-pressures>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 12">
							<lib-pca-overnight-mode [summaryInputTagId]="overnightModeId"></lib-pca-overnight-mode>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 13 && item.JBTStandardObservationId == 3848">
							<lib-pca-comp-pressure [summaryInputTagId]="primaryCompressure1PressureId" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-comp-pressure>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 13 && item.JBTStandardObservationId == 3912">
							<lib-pca-comp-pressure [summaryInputTagId]="primaryCompressure1SuctionId" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-comp-pressure>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 13 && item.JBTStandardObservationId == 3909">
							<lib-pca-comp-pressure [summaryInputTagId]="primaryCompressure2PressureId" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-comp-pressure>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 13 && item.JBTStandardObservationId == 3913">
							<lib-pca-comp-pressure [summaryInputTagId]="primaryCompressure2SuctionId" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-comp-pressure>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 13 && item.JBTStandardObservationId == 3910">
							<lib-pca-comp-pressure [summaryInputTagId]="primaryCompressure3PressureId" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-comp-pressure>
						</div>
						<div *ngIf="item.PCAOverviewWidgetTypeId == 13 && item.JBTStandardObservationId == 1872">
							<lib-pca-comp-pressure [summaryInputTagId]="primaryCompressure3SuctionId" [jbtStandardObservationIdInput]="item.JBTStandardObservationId"></lib-pca-comp-pressure>
						</div>
						<!-- Need to work on alarm template
							<div *ngIf="item.templateName == 'pca-alarms' && item.JBTStandardObservationId == 2246">
							  <app-pca-alarms></app-pca-alarms>
							</div> -->
					</gridster-item>
				</gridster>
			</div>

			<div *ngIf="tab.textLabel === 'Maintenance' && tab.rendered" class="subTabContainer">
				<div class="asset-summary-left" [style.width.%]="defaultWidth" *ngIf="!isDataLoading">
					<div *ngIf="!isMaintenanceFormLoading" class="parent-table-container">
						<form class="form-horizontal" [formGroup]="maintenanceForm">
							<div style="overflow-x: hidden; overflow-y: auto">
								<div *ngIf="maintenanceForm && !maintenanceForm.value.outOfService" class="row col-sm-12 col-md-12">
									<div class="col-sm-3 col-md-3 col-form-label">
										<label for="MaintenanceMode{{widgetInstance}}">Maintenance Mode</label>
									</div>
									<div class="col-sm-9 col-md-9">
										<input type="checkbox" id="MaintenanceMode{{widgetInstance}}" formControlName="maintenanceMode" class="form-control" class="jbt-slider-bar" />
										<label class="dynamic-form-slider-switch" for="MaintenanceMode{{widgetInstance}}" title="Click here if the asset is going into Maintenance Mode." (click)="sliderSelect($event, maintenanceForm)"></label>
										<div class="dynamic-form-slider-text" title="Click here if the asset is going into Maintenance Mode.">
											<div class="slider-text-on">ON</div>
											<div class="slider-text-off">OFF</div>
										</div>
									</div>
								</div>
								<div *ngIf="maintenanceForm && !maintenanceForm.value.maintenanceMode" class="row col-sm-12 col-md-12">
									<div class="col-sm-3 col-md-3 col-form-label">
										<label for="OutOfService{{widgetInstance}}">Out of Service</label>
									</div>
									<div class="col-sm-9 col-md-9">
										<input type="checkbox" id="OutOfService{{widgetInstance}}" formControlName="outOfService" class="jbt-slider-bar" />
										<label class="dynamic-form-slider-switch" for="OutOfService{{widgetInstance}}" title="Click here if the asset is Out of Service." (click)="sliderSelect($event, maintenanceForm)"></label>
										<div class="dynamic-form-slider-text" title="Click here if the asset is Out of Service.">
											<div class="slider-text-on">ON</div>
											<div class="slider-text-off">OFF</div>
										</div>
									</div>
								</div>
								<div *ngIf="maintenanceForm && !maintenanceForm.value.outOfService && maintenanceForm.value.maintenanceMode" class="row col-sm-12 col-md-12">
									<div class="col-sm-3 col-md-3 col-form-label">
										<label for="maintenanceModeReasonId{{widgetInstance}}">Reason</label>
									</div>
									<div class="col-sm-9 col-md-9">
										<select class="form-control" title="Please select the reason this asset is going into Maintenance Mode." id="maintenanceModeReasonId{{widgetInstance}}" formControlName="maintenanceModeReasonId" (change)="saveValue($event, maintenanceForm)">
											<option class="form-control" *ngFor="let opt of maintenanceModeReasons" [value]="opt.Id">
												{{ opt.Name }}
											</option>
										</select>
									</div>
								</div>
								<div *ngIf="maintenanceForm && !maintenanceForm.value.maintenanceMode && maintenanceForm.value.outOfService" class="row col-sm-12 col-md-12">
									<div class="col-sm-3 col-md-3 col-form-label">
										<label for="outOfServiceReasonId{{widgetInstance}}">Reason</label>
									</div>
									<div class="col-sm-9 col-md-9">
										<select class="form-control" title="Please select the reason this asset is Out of Service." id="outOfServiceReasonId{{widgetInstance}}" formControlName="outOfServiceReasonId" (change)="saveValue($event, maintenanceForm)">
											<option class="form-control" *ngFor="let opt of outOfServiceReasons" [value]="opt.Id">
												{{ opt.Name }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
<div *ngIf="!isDataLoading && fromDashboard && !widgetObject?.WidgetGateSystemId" class="card card-tasks center" id="card-whole">
	<lib-centered-message centeredMessage="Please select a Gate System from the widget settings."></lib-centered-message>
</div>
