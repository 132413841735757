import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaCondPumpComponent } from './pca-cond-pump.component';



@NgModule({
  declarations: [
    PcaCondPumpComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [PcaCondPumpComponent]
})
export class PcaCondPumpModule { }
