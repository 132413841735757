import { Component, OnInit, OnDestroy, NgZone, AfterViewInit } from "@angular/core";

import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import swal from "sweetalert2";

import { filter } from "rxjs/operators";
import { AuthenticationService } from "projects/shared-lib/src/lib/services/authentication.service";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { SecurityService } from "projects/shared-lib/src/lib/services/security.service";
import { CacheUtilityService } from "projects/shared-lib/src/lib/services/cache-utility.service";
import { RouteToExternalUrlService } from "projects/shared-lib/src/lib/services/route-to-external-url.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";

@Component({
	selector: "app-login",
	templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
	public loginForm: FormGroup;
	private username: FormControl;
	private password: FormControl;
	public mouseoverLogin = false;
	public resetPasswordForm: FormGroup;
	private resetUsername: FormControl = null;

	public usernameHasNotBeenValued: boolean = true;
	public changePasswordHasBeenRequested: boolean = false;
	public currentUserIsLoggedIn: boolean = false;
	public currentUserIsBeingAuthenticated: boolean = false;
	loginProcessLoading: boolean = false;
	public componentName: string = "login-component (desktop): ";
	public global: IGlobal = Global;

	constructor(private authenticationService: AuthenticationService, private router: Router, private dataService: DataService, private securityService: SecurityService, private cacheUtilityService: CacheUtilityService, private zone: NgZone, private routeExternal: RouteToExternalUrlService) {}

	ngOnInit() {
		console.log(this.componentName + "Global = %O", this.global);
		this.dataService.ApplicationLoadingMessage("", false);
		console.log(this.componentName + "window.location = %O", window.location);
		this.username = new FormControl("", Validators.required);
		this.password = new FormControl("", Validators.required);
		this.resetUsername = new FormControl("", Validators.required);
		this.loginForm = new FormGroup({
			username: this.username,
			password: this.password
		});
		this.resetPasswordForm = new FormGroup({
			resetUsername: this.resetUsername
		});

		this.setupLoginForm();
	}

	ngAfterViewInit() {
		this.global = Global;
		console.log(this.componentName + "Global = %O", this.global);
	}

	ngOnDestroy() {
		console.log(this.componentName + "ngOnDestroy invoked...");
		this.removeLoginForm();
	}

	setupLoginForm() {
		const body = document.getElementsByTagName("body")[0];
		body.classList.add("login-page");
	}

	removeLoginForm() {
		const body = document.getElementsByTagName("body")[0];
		body.classList.remove("login-page");
	}

	login(formValues: any) {
		Global.User.loggingIntoLegacyApplication = false;
		this.dataService.ApplicationLoadingMessage(this.componentName + "Logging in User", true);

		this.loginProcessLoading = true;
		console.log("formValues = %O", formValues);
		var time0 = performance.now();
		this.authenticationService
			.loginWithUsernameAndPassword(
				formValues.username,
				formValues.password
			)
			.then(
				(accessToken: any) => {
					var time = performance.now() - time0;
					console.log(
						this.componentName +
							"time for authenticationService to authenticate user with username/password = " +
							time
					);

					if (
						!accessToken ||
						(accessToken && accessToken.length < 10)
					) {
						// -- login was invalid, so fire error about incorrect username / password.
						this.invalidPasswordAlert();
						this.currentUserIsBeingAuthenticated = false;
						this.loginProcessLoading = false;
						this.dataService.ApplicationLoadingMessage("", false);
					} else {
						//-- user was successfully logged in.  Send out the update to the 'isLoggedIn' status.
						console.log(
							this.componentName +
								"user access token value returned from logging in with username/password = " +
								accessToken
						);
						this.currentUserIsLoggedIn = true;
						// --login was valid, so navigate to the main layout.
						this.securityService.processLoggedInUser(accessToken);
					}
				},
				(err: Error) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								`user was not authorized. Error: ${err.message}`
						);
					this.invalidPasswordAlert();
					this.currentUserIsBeingAuthenticated = false;
					this.loginProcessLoading = false;
					this.dataService.ApplicationLoadingMessage("", false);

					this.router.navigate(["user/login"]);
				}
			);
	}

	invalidPasswordAlert() {
		swal.fire({
			title: "Incorrect",
			position: "top",
			text: "Username or Password is not valid."
		});
	}

	loginLegacy(formValues: any, event: Event) {
		console.log(this.componentName + "legacy application login formValues = %O", formValues);
		Global.User.loggingIntoLegacyApplication = true;
		this.authenticationService.loginWithUsernameAndPassword(formValues.username, formValues.password).then((accessToken: any) => {
			if (!accessToken) {
				// -- login was invalid, so fire error about incorrect username / password.
				this.dataService.ApplicationLoadingMessage("", false);

				this.invalidPasswordAlert();
				this.currentUserIsBeingAuthenticated = false;
				this.loginProcessLoading = false;
			} else {
				console.log(this.componentName + "user access token value returned = " + accessToken);
				event.stopPropagation();
				event.preventDefault();

				var requestJSON: any = [
					{
						label: "PersonAndSecurityRecord",
						sqlStatement: "Security.User_Login_PersonAndSecurityRecord @accessToken='" + accessToken + "'"
					}
				];
				console.log(this.componentName + "requestJSON = %O", requestJSON);

				this.dataService.SQLMultiAction(requestJSON, accessToken).then(
					(data: any) => {
						console.log(this.componentName + "SQLMultiAction for requestJSON = %O", data);
						var collections: any = data;
						var user: any = {};

						collections.forEach((collection: any) => {
							switch (collection.label) {
								case "PersonAndSecurityRecord":
									var personRecord = collection.data.first();
									console.log(this.componentName + "personRecord = %O", personRecord);

									for (var field in personRecord) {
										if (field != "OrganizationId" && field != "OrganizationName") {
											//console.log("personRecord field = " + field + " = " + personRecord[field]);
											user[field] = personRecord[field];
										}
									}
									user.Organization = { Id: personRecord["OrganizationId"], Name: personRecord["OrganizationName"] };
									break;
							}
						});
						if (!user.Username) {
							//-- invalid token.  Just redirect to login screen. --Kirk T. Sherer, February 26, 2021.
							this.authenticationService.logout();
							this.router.navigate(["user/login"]);
						} else {
							localStorage.setItem("currentUser", JSON.stringify(user));
							var currentUser = localStorage.getItem("currentUser");
							console.log(this.componentName + "currentUser from localStorage: %O", currentUser);
							// --login was valid, so navigate to the legacy application.
							var url = document.URL.indexOf("localhost") > 0 ? "http://localhost:52656" : window.location.protocol + "//" + window.location.host + "/v1";
							this.loginProcessLoading = false;
							console.log(this.componentName + "redirecting to legacy application...");

							this.routeExternal.routeToExternalUrl(url);
						}
					},
					(error: any) => {
						console.error(this.componentName + "Error in MultiActionCondensed promise: %O", error);
						if (error.status == 403 || error.status == 500) {
							console.log(this.componentName + "access token " + accessToken + " is invalid.  Redirecting to the login page.");
							this.authenticationService.logout();
							this.router.navigate(["user/login"]);
						} else {
							swal.fire({
								title: "Error connecting to Database",
								text: "Your connection was unsucessful connecting to the database for iOPS.  Please reload the application to re-connect to the database. If your problem persists, please contact your iOPS Administrator.",
								showCancelButton: false,
								confirmButtonText: "Reload",
								reverseButtons: false
							}).then(() => {
								window.location.reload();
								// this.authenticationService.logout();
								// this.router.navigate(['user/login']);
							});
						}
					}
				);
			}
		});
	}

	validateUsername() {
		if (!this.username.untouched && this.username.valid) {
			this.usernameHasNotBeenValued = false;
		}
		return this.username.valid || this.username.untouched;
	}

	validatePassword() {
		return this.password.valid || this.password.untouched;
	}

	resetPasswordByUsername(formValues) {
		console.log(this.componentName + "resetPasswordByUsername invoked: formValues = %O", formValues);
		this.sendPasswordToken();
	}

	sendPasswordToken() {
		console.log(this.componentName + "sendPasswordToken invoked...");
		this.changePasswordHasBeenRequested = true;
		console.log(this.componentName + "changePasswordHasBeenRequested = " + this.changePasswordHasBeenRequested);
		var username = this.loginForm.value.username ? this.loginForm.value.username : this.resetPasswordForm.value.resetUsername;
		console.log(this.componentName + "username = " + username);

		if (username && username != "") {
			console.log(this.componentName + "attempting to send reset password token...");
			this.dataService.RequestToResetPassword(username).then((data: any) => {
				console.log(this.componentName + "sendPasswordToken: data.body = %O", data.body);
				var email = data.body.first();
				this.sendAlertToUser(email);
				this.changePasswordHasBeenRequested = false;
				this.usernameHasNotBeenValued = true;
				this.username = new FormControl("", Validators.required);
				this.password = new FormControl("", Validators.required);
				this.resetUsername = new FormControl("", Validators.required);
				this.resetPasswordForm.value.resetUsername = "";
			});
		}
	}

	sendAlertToUser(email: string) {
		if (email == undefined) {
			swal.fire({
				title: "Password Change",
				position: "center",
				html: "Password Email sent to your email address.<br />This password change token can be used only once."
			});
		} else {
			swal.fire({
				title: "Password Change",
				position: "center",
				html: "Password Email sent to <strong>" + email + "</strong><br />This password change token can be used only once."
			});
		}
	}
}
