import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';












@Component({
	selector: 'app-ninja-statistics',
	templateUrl: './ninja-statistics.component.html',
	styleUrls: ['./ninja-statistics.component.scss'],
})
export class NinjaStatisticsComponent implements AfterViewInit {
	ngAfterViewInit(): void {}
}
