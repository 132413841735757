<lib-loading-spinner
	*ngIf="isLoading"
	[loadingMessage]="'Loading Raw Tag Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="!isLoading && widgetObject?.WidgetAssetId"
	class="card card-tasks"
	style="height: 100%"
>
	<div (resized)="onResized($event)" [style.height.%]="100">
		<lib-kendo-grid-parent
			[gridData]="dataService.cache.assetsObject[
			widgetObject.WidgetAssetId
		].Tags"
			[gridSettings]="gridSettings"
			[widgetObject]="widgetObject"
			(callSave)="saveGridSettings()"
			[style.height.%]="100"
			filterable="menu"
			[sortable]="true"
			[reorderable]="true"
			[resizable]="true"
			[gridContextMenuItems]="gridContextMenuItems"
			(onRightClickSelectEmitter)="onRightClickSelect($event)"
			#tagDataGrid

	>
	</lib-kendo-grid-parent>

	</div>
</div>

<div
	*ngIf="
		!isLoading && widgetObject !== undefined && !widgetObject?.WidgetAssetId
	"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select an asset from the widget settings."
	>
	</lib-centered-message>
</div>
