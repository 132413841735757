import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';

@Component({
	selector: 'lib-canvas-popup-settings',
	templateUrl: './canvas-popup-settings.component.html',
	styleUrls: ['./canvas-popup-settings.component.scss'],
})
export class CanvasPopupSettingsComponent implements OnInit {
	assetModelsByOrganization: Array<any>;
	assetModelTemplate: Array<any>;
	currentUserOrganizationId: any;
	showAssetModelTemplateMsg: boolean;
	form: FormGroup;
	templateRetrievalMessage: string;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogModalInputObject: any,
		private dataService: DataService,
		public dialogRef: MatDialogRef<CanvasPopupSettingsComponent>
	) {}

	ngOnInit() {
		console.log(this.dialogRef);
		console.log(this.dialogModalInputObject);

		this.form = new FormGroup({
			assetModelId: new FormControl(''),
		});
		this.initialize();
	}

	initialize() {
		this.currentUserOrganizationId = this.dataService.cache.people.filter(
			(p: any) => {
				return p.UserId == Global.User.currentUser.Id;
			}
		)[0].OrganizationId;
		this.assetModelsByOrganization = [];
		// SQL statement
		let statement = `GetAssetModelIdsByOrganization @OrganizationId =  ${this.currentUserOrganizationId}`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					'API.GetAssetModelIdsByOrganization data = %O',
					data
				);
			this.assetModelsByOrganization = data;
			this.showAssetModelTemplateMsg = false;
		});
	}

	submitForm(form) {
		console.log(form);
		let obj = {
			assetModelId: form.value.assetModelId,
			assetModelTemplate: this.assetModelTemplate,
			currentUserOrganizationId: this.currentUserOrganizationId,
		};
		this.dialogRef.close(obj);
	}

	checkForAssetModelTemplate(event) {
		let assetModelId = event.target.value;
		this.assetModelTemplate = [];
		this.templateRetrievalMessage = 'Searching for existing template...';
		// SQL statement
		let statement = `GetAssetModelTemplateByAssetModelIdAndOrganizationId @OrganizationId =  ${this.currentUserOrganizationId} ,@AssetModelId = ${assetModelId}`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					'API.GetAssetModelTemplateByAssetModelIdAndOrganizationId data = %O',
					data
				);
			this.assetModelTemplate = data;
			if (this.assetModelTemplate.length > 0) {
				this.templateRetrievalMessage =
					'Template exists for asset model. Continue to Edit existing template';
			} else {
				this.templateRetrievalMessage =
					'Template does not exist for asset model. Continue to create new template';
			}
			this.showAssetModelTemplateMsg = true;
		});
	}

	public closeDialogWindow(): void {
		this.closeWindow(false);
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			this.dialogRef.close();
		}
	}
}
