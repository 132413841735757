import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaSummaryComponent } from './pca-summary.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatTabsModule } from '@angular/material/tabs';
import { GridModule } from '@progress/kendo-angular-grid';
import { AngularResizeEventModule } from 'angular-resize-event';
import { PcaQuickViewModule } from '../pca-quick-view/pca-quick-view.module';
import { LastThousandGridModule } from '../last-thousand-grid/last-thousand-grid.module';
import { PcaHeatModule } from '../pca-heat/pca-heat.module';
import { PcaBlowerModule } from '../pca-blower/pca-blower.module';
import { PcaCondPumpModule } from '../pca-cond-pump/pca-cond-pump.module';
import { PcaCondensorfansModule } from '../pca-condensorfans/pca-condensorfans.module';
import { PcaDischargeModeModule } from '../pca-discharge-mode/pca-discharge-mode.module';
import { PcaEstopModule } from '../pca-estop/pca-estop.module';
import { PcaOperationModeModule } from '../pca-operation-mode/pca-operation-mode.module';
import { PcaPressuresModule } from '../pca-pressures/pca-pressures.module';
import { PcaOvernightModeModule } from '../pca-overnight-mode/pca-overnight-mode.module';
import { PcaCompPressureModule } from '../pca-comp-pressure/pca-comp-pressure.module';
import { GridsterModule } from 'angular-gridster2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { MatBadgeModule } from '@angular/material/badge';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [PcaSummaryComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		MatTabsModule,
		GridModule,
		AngularResizeEventModule,
		PcaQuickViewModule,
		LastThousandGridModule,
		PcaHeatModule,
		PcaBlowerModule,
		PcaCondPumpModule,

		PcaCondensorfansModule,
		PcaDischargeModeModule,
		PcaEstopModule,
		PcaOperationModeModule,
		PcaPressuresModule,
		PcaOvernightModeModule,
		PcaCompPressureModule,
		GridsterModule,
		FormsModule,
		CenteredMessageModule,
		ReactiveFormsModule,
		MatBadgeModule,
		DynamicFormModule,
		PipeModule,
		KendoGridParentModule
	],
	exports: [PcaSummaryComponent],
})
export class PcaSummaryModule {}
