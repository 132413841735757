import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadListComponent } from './file-upload-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { DropzoneModule } from '../../_directives/dropzone/dropzone.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [FileUploadListComponent],
	imports: [
		CommonModule,
		MatMenuModule,
		DragDropModule,
		MatProgressBarModule,
		DragDropModule,
		PipeModule,
		DropzoneModule,
		MatButtonModule
	],
	exports: [FileUploadListComponent],
})
export class FileUploadListModule {}
