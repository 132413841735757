<div class="content" style="padding-right: 0px !important; padding-bottom: 0px">
	<div class="col-md-12" style="padding-left: 0; padding-right: 0">
		<div class="card">
			<div class="card-header">
				<h3 style="display: inline" class="card-title">Canvas Tabs By Ranking</h3>
			</div>
			<div class="card-body">

				<div *ngIf="canvasTabs.length > 0" class="table-responsive table-container" >
					<kendo-grid [data]="gridData" [style.height.%]="100" [rowClass]="rowCallback" (dataStateChange)="dataStateChange($event)">
						<kendo-grid-column field="CanvasTemplateTabRanking" title="CanvasTemplateTabRanking" ></kendo-grid-column>
						<kendo-grid-column field="Name" title="Name" ></kendo-grid-column>
						<kendo-grid-column field="CreatorName" title="CreatorName" ></kendo-grid-column>
					</kendo-grid>
				</div>

			</div>
		</div>
	</div>
</div>
