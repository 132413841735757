<div class="card card-tasks" id="card-whole" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header widget-card-header" style=" cursor: move; padding-top: 0px; min-height: 0px;"  cdkDragHandle>
        <div style="padding-top: 15px; display: inline-block">
            <h6 class="title  d-inline" *ngIf="widgetType === 'Standard'">
                {{ localWidgetObject.WidgetName }}


                 <span *ngIf="localWidgetObject.SiteList != null"
                 >:&nbsp;&nbsp;<span style="color: lightseagreen"
                     ><strong>{{ dataService.returnSiteListAsArrayOfNames(localWidgetObject.SiteList) }} </strong></span
                 ></span
             >
                 <span *ngIf="localWidgetObject.WidgetSiteName != null && localWidgetObject.SiteList === null">:&nbsp;&nbsp;<span style="color: lightseagreen"><strong>{{ localWidgetObject.WidgetSiteName }} </strong></span></span>
                 <span *ngIf=" localWidgetObject.WidgetGateSystemName != null && localWidgetObject.WidgetAssetName == null">&nbsp;<strong>Gate&nbsp;&nbsp;<span style="color: lightseagreen">{{ localWidgetObject.WidgetGateSystemName }}</span></strong></span>
				 <span *ngIf="localWidgetObject.WidgetGateSystemName == null && localWidgetObject.WidgetTerminalName != null">&nbsp;<strong>Terminal&nbsp;&nbsp;&nbsp;<span style="color: lightseagreen">{{ localWidgetObject.WidgetTerminalName }}</span></strong></span>
                 <span *ngIf="localWidgetObject.WidgetGateSystemName != null && localWidgetObject.WidgetAssetName != null">&nbsp;<strong>Gate&nbsp;&nbsp;<span style="color: lightseagreen">{{ localWidgetObject.WidgetGateSystemName }}</span>&nbsp;&nbsp;{{ localWidgetObject.WidgetAssetName }}</strong></span>

             </h6>
			 <h6 class="title  d-inline" style="padding-top: 15px; display: inline-block" *ngIf="widgetType === 'Tactical'">
                {{ dataSource.widget.WidgetName }}
                 <span *ngIf="dataSource.widget.WidgetSiteName != null">:&nbsp;&nbsp;
					 <span style="color: lightseagreen">
						 <strong>{{ dataSource.widget.WidgetSiteName }} </strong>
					 </span>
				</span>
				<span *ngIf="dataSource.widget.FleetName != null">:&nbsp;&nbsp;
					<span style="color: lightseagreen">
						<strong>{{ dataSource.widget.FleetName }} </strong>
					</span>
			   </span>
             </h6>
        </div>


        <button mat-button style="display: inline; float: right; padding-top: 5px;" (click)="onCancel()" matTooltip="Close" [matTooltipPosition]="'below'">
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body" style="overflow-y: scroll">
        <div style="margin: 10px">


			<div class="typeContainer" *ngIf="selectTypes === true">
				<h5>Type</h5>

				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="broadcastTypesChange(typesGroup)"
						[(ngModel)]="Type" #typesGroup="matButtonToggleGroup">
						<mat-button-toggle *ngFor="let type of userTypes; let i = index"
								[ngStyle]="{'background-color': evaluateTypesList(type) && theme === 'dark' ? 'rgb(26, 38, 90)' :  evaluateTypesList(type) && theme === 'light' ? 'rgb(217, 217, 217)' : !evaluateTypesList(type) && theme === 'light' ? 'rgb(240, 240, 240)' : !evaluateTypesList(type) && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
								color: theme === 'dark' ? 'white': 'black'}"
								[value]="type"
								class="widgetSettingsButton">
							{{ type }}</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>


			<div class="typeContainer" *ngIf="selectFleets === true">
				<h5>Fleets</h5>

				<div *ngIf="!userFleets">
					<mat-spinner diameter="30"></mat-spinner>
				</div>

				<div *ngIf="userFleets && userFleets.length > 0">
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style"  multiple="true" (change)="broadcastFleetsChange(fleetsGroup)"
						[(ngModel)]="dataSource.widget.Fleets" #fleetsGroup="matButtonToggleGroup">
						<mat-button-toggle *ngFor="let fleet of userFleets" [ngStyle]="{'background-color':  evaluateFleetsList(fleet.FleetId) && theme === 'dark' ? 'rgb(26, 38, 90)' :  evaluateFleetsList(fleet.FleetId) && theme === 'light' ? 'rgb(217, 217, 217)' : !evaluateFleetsList(fleet.FleetId) && theme === 'light' ? 'rgb(240, 240, 240)' : !evaluateFleetsList(fleet.FleetId) && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" [value]="fleet.FleetId"
							 class="widgetSettingsButton">
							{{ fleet.FleetName }}</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>

			<div class="typeContainer" *ngIf="selectFleetAssetType === true && this.Fleet.FleetId">
                <h5>Fleet Asset Types</h5>

                <div>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="dataSource.widget.WidgetAssetTypeId"
                        [(ngModel)]="dataSource.widget.WidgetAssetTypeId" #fleetTypeGroup="matButtonToggleGroup">
                        <mat-button-toggle  (click)="broadcastFleetTypeChange(assetType.Id)" *ngFor="let assetType of FleetTypes"
                            [ngStyle]="{'background-color': assetType.Id == localWidgetObject.WidgetAssetTypeId && theme === 'dark' ? 'rgb(26, 38, 90)' :
															assetType.Id == localWidgetObject.WidgetFleetTypeId && theme === 'light' ? 'rgb(217, 217, 217)' :
															assetType.Id != dataSource.widget.WidgetFleetTypeId && theme === 'light' ? 'rgb(240, 240, 240)' :
															assetType.Id != dataSource.widget.WidgetFleetTypeId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                                    						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
                            {{ assetType.Name }}</mat-button-toggle>
                    </mat-button-toggle-group>
                    <div *ngIf="dataSource.widget.WidgetAssetTypeId !== null && dataSource.widget.WidgetAssetTypeId !== 0">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastFleetTypeChange(null)" [ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                                                            color: theme === 'dark' ? 'white': 'black'}"
                            class="widgetSettingsButton">
                            Clear</mat-button-toggle>
                    </div>

                </div>
            </div>

			<div class="typeContainer" *ngIf="selectFleetAsset === true && this.Fleet.FleetId">
				<h5>Fleet Assets</h5>

				<div *ngIf="!assets">
					<mat-spinner diameter="30"></mat-spinner>
				</div>

				<div *ngIf="assets && assets.length > 0">
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="fleetAssetId"
						[(ngModel)]="fleetAssetId" #assetGroup="matButtonToggleGroup">
						<mat-button-toggle ngDefaultControl [ngStyle]="{'background-color': assetSystem.Id === fleetAssetId && theme === 'dark' ? 'rgb(26, 38, 90)' : assetSystem.Id === fleetAssetId && theme === 'light' ? 'rgb(217, 217, 217)' : assetSystem.Id !== fleetAssetId && theme === 'light' ? 'rgb(240, 240, 240)' : assetSystem.Id !== fleetAssetId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton"
							(click)="broadcastFleetAssetChange(assetSystem.Id)" *ngFor="let assetSystem of assets">{{
							assetSystem.Name }}</mat-button-toggle>
					</mat-button-toggle-group>

					<div *ngIf="dataSource.widget.WidgetAssetId !== null && dataSource.widget.WidgetAssetId !== 0">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastFleetAssetChange(null)"
							[ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
							color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							Clear Asset
						</mat-button-toggle>
                    </div>
				</div>
			</div>


			<div class="typeContainer" *ngIf="selectGlobal === true">
				<h5 (click)="showSites()">Global</h5>

				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="globalScopeSelected"
						[(ngModel)]="globalScopeSelected" #globalGroup="matButtonToggleGroup">
						<mat-button-toggle (click)="turnToGlobalMode(true)"
							[ngStyle]="{'background-color': globalScopeSelected === true && theme === 'dark' ? 'rgb(26, 38, 90)' : globalScopeSelected === true && theme === 'light' ? 'rgb(217, 217, 217)' : globalScopeSelected !== true && theme === 'light' ? 'rgb(240, 240, 240)' : globalScopeSelected !== true && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							True</mat-button-toggle>
                            <mat-button-toggle  (click)="turnToGlobalMode(false)"
							[ngStyle]="{'background-color': globalScopeSelected === false && theme === 'dark' ? 'rgb(26, 38, 90)' : globalScopeSelected === false && theme === 'light' ? 'rgb(217, 217, 217)' : globalScopeSelected !== false && theme === 'light' ? 'rgb(240, 240, 240)' : globalScopeSelected !== false && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							False</mat-button-toggle>



					</mat-button-toggle-group>
				</div>
			</div>
            <div class="typeContainer" *ngIf="selectSites === true">
				<h5 (click)="showSites()">Site</h5>

				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style"  multiple="true" (change)="broadcastSitesChange(sitesGroup)"
						[(ngModel)]="dataSource.widget.SiteList" #sitesGroup="matButtonToggleGroup">
						<mat-button-toggle *ngFor="let site of userSites" [ngStyle]="{'background-color':  evaluateSitesList(site.Id) && theme === 'dark' ? 'rgb(26, 38, 90)' :  evaluateSitesList(site.Id) && theme === 'light' ? 'rgb(217, 217, 217)' : !evaluateSitesList(site.Id) && theme === 'light' ? 'rgb(240, 240, 240)' : !evaluateSitesList(site.Id) && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" [value]="site.Id"
							 class="widgetSettingsButton">
							{{ site.Name }}</mat-button-toggle>


					</mat-button-toggle-group>
				</div>
			</div>
			<div class="typeContainer" *ngIf="selectSite === true">
				<h5 (click)="showSites()">Site</h5>

				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="dataSource.widget.WidgetSiteId"
						[(ngModel)]="dataSource.widget.WidgetSiteId" #siteGroup="matButtonToggleGroup">
						<mat-button-toggle mat-button (click)="broadcastSiteChange(site.Id)" *ngFor="let site of userSites"
							[ngStyle]="{'background-color': site.Id === localWidgetObject.WidgetSiteId && theme === 'dark' ? 'rgb(26, 38, 90)' : site.Id === localWidgetObject.WidgetSiteId && theme === 'light' ? 'rgb(217, 217, 217)' : site.Id !== localWidgetObject.WidgetSiteId && theme === 'light' ? 'rgb(240, 240, 240)' : site.Id !== localWidgetObject.WidgetSiteId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							{{ site.Name }}</mat-button-toggle>


					</mat-button-toggle-group>
                    <div *ngIf="dataSource.widget.WidgetSiteId !== null && dataSource.widget.WidgetSiteId !== 0 && widgetType === 'Tactical'">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastSiteChange(null)"
                        [ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                        color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
                        Clear</mat-button-toggle>
                    </div>

				</div>
			</div>
			<div class="typeContainer" *ngIf="selectGSEAssetType === true && dataSource.widget.WidgetSiteId">
                <h5>Fleet Asset Type</h5>

                <div>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="dataSource.widget.WidgetAssetTypeId"
                        [(ngModel)]="dataSource.widget.WidgetAssetTypeId" #assetTypeGroup="matButtonToggleGroup">
                        <mat-button-toggle  (click)="broadcastAssetTypeChange(assetType.Id)" *ngFor="let assetType of GSEAssetTypes"
                            [ngStyle]="{'background-color': assetType.Id == localWidgetObject.WidgetAssetTypeId && theme === 'dark' ? 'rgb(26, 38, 90)' : assetType.Id == localWidgetObject.WidgetAssetTypeId && theme === 'light' ? 'rgb(217, 217, 217)' : assetType.Id != dataSource.widget.WidgetAssetTypeId && theme === 'light' ? 'rgb(240, 240, 240)' : assetType.Id != dataSource.widget.WidgetAssetTypeId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                                    						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
                            {{ assetType.Name }}</mat-button-toggle>


                    </mat-button-toggle-group>
                    <div *ngIf="dataSource.widget.WidgetAssetTypeId !== null && dataSource.widget.WidgetAssetTypeId !== 0">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastAssetTypeChange(null)" [ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                                                            color: theme === 'dark' ? 'white': 'black'}"
                            class="widgetSettingsButton">
                            Clear</mat-button-toggle>
                    </div>

                </div>
            </div>
            <div class="typeContainer" *ngIf="selectGSAssetType === true && dataSource.widget.WidgetSiteId">
                <h5>GS Asset Type</h5>

				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="dataSource.widget.WidgetAssetTypeId"
						[(ngModel)]="dataSource.widget.WidgetAssetTypeId" #assetTypeGroup="matButtonToggleGroup">
                        <mat-button-toggle  (click)="broadcastAssetTypeChange(assetType.Id)" *ngFor="let assetType of GSAssetTypes" [ngStyle]="{'background-color': assetType.Id == localWidgetObject.WidgetAssetTypeId && theme === 'dark' ? 'rgb(26, 38, 90)' : assetType.Id == localWidgetObject.WidgetAssetTypeId && theme === 'light' ? 'rgb(217, 217, 217)' : assetType.Id != dataSource.widget.WidgetAssetTypeId && theme === 'light' ? 'rgb(240, 240, 240)' : assetType.Id != dataSource.widget.WidgetAssetTypeId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                                                                                color: theme === 'dark' ? 'white': 'black'}"
                            class="widgetSettingsButton">
                            {{ assetType.Name }}</mat-button-toggle>


					</mat-button-toggle-group>
                    <div *ngIf="dataSource.widget.WidgetAssetTypeId !== null && dataSource.widget.WidgetAssetTypeId !== 0">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastAssetTypeChange(null)"
                        [ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                        color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
                        Clear</mat-button-toggle>
                    </div>

				</div>
			</div>
			<div class="typeContainer" *ngIf="selectTerminal === true">
				<h5 *ngIf="dataSource.widget.WidgetSiteId && terminals !== null && terminals.length > 0">Terminal</h5>

				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
						[value]="dataSource.widget.WidgetTerminalSystemId" [(ngModel)]="dataSource.widget.WidgetTerminalSystemId"
						#terminalGroup="matButtonToggleGroup">
						<mat-button-toggle ngDefaultControl (click)="broadcastTerminalChange(terminalSystem.Id)"
							[value]="terminalSystem.Id" *ngFor="let terminalSystem of terminals" [ngStyle]="{'background-color': terminalSystem.Id === localWidgetObject.WidgetTerminalSystemId && theme === 'dark' ? 'rgb(26, 38, 90)' : terminalSystem.Id === localWidgetObject.WidgetTerminalSystemId && theme === 'light' ? 'rgb(217, 217, 217)' : terminalSystem.Id !== localWidgetObject.WidgetTerminalSystemId && theme === 'light' ? 'rgb(240, 240, 240)' : terminalSystem.Id !== localWidgetObject.WidgetTerminalSystemId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
							color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							{{ terminalSystem.Name }}</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>

			<div class="typeContainer" *ngIf="selectGate === true && gates && gates.length > 0">
				<h5 *ngIf="dataSource.widget.WidgetTerminalSystemId && dataSource.widget.AngularDirectiveName != 'centralUnitsSummary'">Gate</h5>
				<h5 *ngIf="dataSource.widget.WidgetTerminalSystemId && dataSource.widget.AngularDirectiveName == 'centralUnitsSummary'">Central 400HZ Converters</h5>
				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
						[value]="dataSource.widget.WidgetGateSystemId" [(ngModel)]="dataSource.widget.WidgetGateSystemId"
						#gateGroup="matButtonToggleGroup">
						<mat-button-toggle ngDefaultControl (click)="broadcastGateChange(gateSystem.Id)"
							*ngFor="let gateSystem of gates" [ngStyle]="{'background-color': gateSystem.Id === localWidgetObject.WidgetGateSystemId && theme === 'dark' ? 'rgb(26, 38, 90)' : gateSystem.Id === localWidgetObject.WidgetGateSystemId && theme === 'light' ? 'rgb(217, 217, 217)' : gateSystem.Id !== localWidgetObject.WidgetGateSystemId && theme === 'light' ? 'rgb(240, 240, 240)' : gateSystem.Id !== localWidgetObject.WidgetGateSystemId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
							color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							{{ gateSystem.Name }}</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
			<div class="typeContainer" *ngIf="selectAsset === true">
				<h5 *ngIf="dataSource.widget.WidgetGateSystemId && dataSource.widget.AngularDirectiveName != 'centralUnitsSummary'">Asset</h5>
				<h5 *ngIf="dataSource.widget.WidgetGateSystemId && dataSource.widget.AngularDirectiveName == 'centralUnitsSummary'">Units</h5>
				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="dataSource.widget.WidgetAssetId"
						[(ngModel)]="dataSource.widget.WidgetAssetId" #assetGroup="matButtonToggleGroup">
						<mat-button-toggle ngDefaultControl [ngStyle]="{'background-color': assetSystem.Id === localWidgetObject.WidgetAssetId && theme === 'dark' ? 'rgb(26, 38, 90)' : assetSystem.Id === localWidgetObject.WidgetAssetId && theme === 'light' ? 'rgb(217, 217, 217)' : assetSystem.Id !== localWidgetObject.WidgetAssetId && theme === 'light' ? 'rgb(240, 240, 240)' : assetSystem.Id !== localWidgetObject.WidgetAssetId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
						color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton"
							(click)="broadcastAssetChange(assetSystem.Id)" *ngFor="let assetSystem of assets">{{
							assetSystem.Name }}</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
			<div class="typeContainer" *ngIf="selectGSEAsset === true && GSEAssets && GSEAssets.length >0">
				<h5>GSE Assets</h5>
				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
						[value]="dataSource.widget.WidgetAssetId" [(ngModel)]="dataSource.widget.WidgetAssetId"
						#gseGroup="matButtonToggleGroup">
						<mat-button-toggle ngDefaultControl (click)="broadcastGSEChange(gse.Id)"
							*ngFor="let gse of GSEAssets" [ngStyle]="{'background-color': gse.Id === localWidgetObject.WidgetAssetId && theme === 'dark' ? 'rgb(26, 38, 90)' : gse.Id === localWidgetObject.WidgetAssetId && theme === 'light' ? 'rgb(217, 217, 217)' : gse.Id !== localWidgetObject.WidgetAssetId && theme === 'light' ? 'rgb(240, 240, 240)' : gse.Id !== localWidgetObject.WidgetAssetId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
							color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							{{ gse.Name }}</mat-button-toggle>
					</mat-button-toggle-group>
					<div *ngIf="dataSource.widget.WidgetAssetId !== null && dataSource.widget.WidgetAssetId !== 0">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastGSEChange(null)"
                        [ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                        color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
                        Clear Asset</mat-button-toggle>
                    </div>
				</div>
			</div>
			<div class="typeContainer" *ngIf="selectGSAsset === true">
				<h5>GS Assets</h5>
				<div>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
						[value]="dataSource.widget.WidgetAssetId" [(ngModel)]="dataSource.widget.WidgetAssetId"
						#gseGroup="matButtonToggleGroup">
						<mat-button-toggle ngDefaultControl (click)="broadcastGSChange(gs.Id)"
							*ngFor="let gs of GSAssets" [ngStyle]="{'background-color': gs.Id === localWidgetObject.WidgetAssetId && theme === 'dark' ? 'rgb(26, 38, 90)' : gs.Id === localWidgetObject.WidgetAssetId && theme === 'light' ? 'rgb(217, 217, 217)' : gs.Id !== localWidgetObject.WidgetAssetId && theme === 'light' ? 'rgb(240, 240, 240)' : gs.Id !== localWidgetObject.WidgetAssetId && theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
							color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
							{{ gs.Site.Name }} {{ gs.ParentSystem.Name }} {{ gs.Name }}</mat-button-toggle>
					</mat-button-toggle-group>
					<div *ngIf="dataSource.widget.WidgetAssetId !== null && dataSource.widget.WidgetAssetId !== 0">
                        <br>
                        <br>
                        <mat-button-toggle mat-button (click)="broadcastGSChange(null)"
                        [ngStyle]="{'background-color':  theme === 'light' ? 'rgb(240, 240, 240)' :  theme === 'dark' ? 'rgb(48, 63, 103)': 'purple',
                        color: theme === 'dark' ? 'white': 'black'}" class="widgetSettingsButton">
                        Clear Asset</mat-button-toggle>
                    </div>
				</div>
			</div>
		</div>
	</div>
</div>
