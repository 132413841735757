import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GseActiveAlarmsComponent } from './gse-active-alarms.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MatTabsModule } from '@angular/material/tabs';
import { GridContextMenuModule } from '../grid-context-menu/grid-context-menu.module';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [GseActiveAlarmsComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		MatTabsModule,
		GridModule,
		GridContextMenuModule,
		CenteredMessageModule,
		KendoGridParentModule,
	],
	exports: [GseActiveAlarmsComponent],
})
export class GseActiveAlarmsModule {}
