<lib-loading-spinner *ngIf="isDataLoading" [loadingMessage]="'Loading Site All Gate Summary Info...'" [vehicle]="'plane'"></lib-loading-spinner>
<div *ngIf="!isDataLoading" class="card card-tasks" id="card-whole">
	<div (resized)="onResized($event)" [style.height.%]="100" style="display: inline-block" style="width: 100%; height: 100%">

		<lib-kendo-grid-parent
				[gridData]="assetDataArray"
				[gridSettings]="gridSettings"
				[widgetObject]="widgetObject"
				(callSave)="saveGridSettings()"
				[style.height.%]="100"
				filterable="menu"
				[sortable]="true"
				[reorderable]="true"
				[resizable]="true"
				[timeZoneType]="timeZoneType"
				[dashboardTimeZoneChanged]="dashboardTimeZoneChanged"
				[assetsWithTags]="assetsWithTags"
				#allGateGrid

						>
						</lib-kendo-grid-parent>

	</div>
</div>
