<div [formGroup]="formGroup">
    <mat-form-field appearance="outline" >
        <input id="name" type="text" aria-label="Number" matInput [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let obs of filteredStates$ | async" [value]="obs">
                {{ obs.Name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
