<div class="card card-tasks">
	<div class="card-header" style="cursor: default; padding-top: 0px; min-height: 0px">
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">Select Widget to Add to Dashboard</h6>
		</div>

		<button mat-button style="display: inline; float: right; padding-top: 5px" (click)="onCancel()" matTooltip="Close" [matTooltipPosition]="'below'">
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body" style="overflow: hidden !important">
		<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading Widgets...'" [vehicle]="'plane'"></lib-loading-spinner>
		<div *ngIf="!isLoading" style="width: 100%; height: 100%">

			<lib-kendo-grid-parent
			(loadVideo)="loadVideo($event)"
			(addWidgetToDashboardEmitter)="addWidgetToDashboard($event)"
			(loadVideoEmitter)="loadVideo($event)"
			[gridData]="widgetTypesInOneArray"
			[gridSettings]="gridSettings"
			[style.height.%]="100"
			[filterable]="true"
			[sortable]="true"
			[reorderable]="true"
			[resizable]="true"
			[groupable]="true"
			[exportable]="false"
			[columnMenu]="false"
			#widgetTypeGrids

		></lib-kendo-grid-parent>

			<!-- <kendo-grid [data]="widgetTypesInOneArray" [style.height.%]="100"        [filterable]="true"
            [groupable]="true" [group]="state.group"
			>
				<kendo-grid-column field="WidgetTypeName" title="Name">
					<ng-template kendoGridCellTemplate let-dataItem
						><div (click)="addWidgetToDashboard(dataItem)" style="cursor: pointer">
							{{ dataItem.Name }}
						</div></ng-template
					>
				</kendo-grid-column>
				<kendo-grid-column field="WidgetType.Description" title="Description"> </kendo-grid-column>
				<ng-template kendoGridCellTemplate let-dataItem
					><div (click)="addWidgetToDashboard(dataItem)" style="cursor: pointer">
						{{ dataItem.Description }}
					</div></ng-template
				>
				<kendo-grid-column title="Training Lesson">
					<ng-template

					kendoGridCellTemplate
					let-dataItem
				>
					<td>
						<button
							style="padding: 3px 6px; height: 25px"
							class="jbt-btn btn-sm"
							(click)="loadVideo(dataItem)"
							*ngIf="dataItem.TrainingCourseLesson?.VideoImageKey"
						>
						<i class="tim-icons icon-triangle-right-17"></i>
						</button>
					</td>
				</ng-template>

				</kendo-grid-column>
				<kendo-grid-column field="Category" title="Category">
					<ng-template kendoGridCellTemplate let-dataItem
						><div>
							{{ dataItem.Category }}
						</div></ng-template
					>
				</kendo-grid-column>
			</kendo-grid> -->

			<!-- <mat-tab-group animationDuration="0ms">
				<mat-tab *ngFor="let widgetGroup of widgetTypeGroups; let index = index" [label]="widgetGroup.Name">
					<kendo-grid [data]="widgetGroup.WidgetTypes" [style.height.%]="100">
						<kendo-grid-column field="WidgetTypeName" title="Name">
							<ng-template kendoGridCellTemplate let-dataItem
								><div (click)="addWidgetToDashboard(dataItem)" style="cursor: pointer">
									{{ dataItem.Name }}
								</div></ng-template
							>
						</kendo-grid-column>
						<kendo-grid-column field="WidgetType.Description" title="Description"> </kendo-grid-column>
						<ng-template kendoGridCellTemplate let-dataItem
							><div (click)="addWidgetToDashboard(dataItem)" style="cursor: pointer">
								{{ dataItem.Description }}
							</div></ng-template
						>
						<kendo-grid-column title="Training Lesson">
							<ng-template

							kendoGridCellTemplate
							let-dataItem
						>
							<td>
								<button
									style="padding: 3px 6px; height: 25px"
									class="jbt-btn btn-sm"
									(click)="loadVideo(dataItem)"
									*ngIf="dataItem.TrainingCourseLesson?.VideoImageKey"
								>
								<i class="tim-icons icon-triangle-right-17"></i>
								</button>
							</td>
						</ng-template>

						</kendo-grid-column>
					</kendo-grid>


				</mat-tab>
			</mat-tab-group> -->
		</div>
	</div>
</div>
