import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule, RouterLinkActive } from "@angular/router";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { HttpClientModule } from "@angular/common/http";
import { UserModule } from "./user/user.module";
import { StorageModule } from "@ngx-pwa/local-storage";
import { SiteLayoutModule } from "./_layout/layout.module";

import { MaterialModule } from './_other-modules/material.module';
// Datepicker module
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularResizeEventModule } from 'angular-resize-event';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { HomeComponent } from './home/home.component';
// Imports the Chart module
import { ChartModule } from '@progress/kendo-angular-charts';

// Imports the Sparkline module
import { SparklineModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AutocompleteOffDirective } from './_directives/autocomplete-off.directive';
import { ServicesModule } from 'projects/shared-lib/src/lib/services/services.module';
import { ReleaseNotesModule } from 'projects/shared-lib/src/lib/components/release-notes/release-notes.module';
import { LoadingSpinnerModule } from 'projects/shared-lib/src/lib/components/loading-spinner/loading-spinner.module';
import { SnackbarBroadcastMessageModule } from 'projects/shared-lib/src/lib/components/snackbar-broadcast-message/snackbar-broadcast-message.module';
import { AssetSummaryViewModule } from "projects/shared-lib/src/lib/components/asset-summary-view/asset-summary-view.module";

import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';
import { CanvasDesignerModule } from "projects/shared-lib/src/lib/components/canvas-designer/canvas-designer.module";


@NgModule({
	declarations: [AppComponent, HomeComponent, AutocompleteOffDirective],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule,

		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: 'toast-bottom-left',
			preventDuplicates: true,
		}),
		UserModule,
		SiteLayoutModule,
		StorageModule.forRoot({ IDBNoWrap: true }),
		ServicesModule,
		MaterialModule,
		BsDatepickerModule.forRoot(),
		AngularResizeEventModule,
		AppRoutingModule,
		ButtonsModule,
		GridModule,
		GaugesModule,
		ChartModule,
		SparklineModule,
		SchedulerModule,
		NgbModule,
		ReleaseNotesModule,
		LoadingSpinnerModule,
		SnackbarBroadcastMessageModule,
		AssetSummaryViewModule,
		CanvasDesignerModule,
		TourMatMenuModule.forRoot(),
	],
	providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, TourService ],
	bootstrap: [AppComponent],
})
export class AppModule {}
