import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { CanvasGsePopupSettingsComponent } from '../../components/canvas-gse-popup-settings/canvas-gse-popup-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { CanvasPopupSettingsComponent } from '../canvas-popup-settings/canvas-popup-settings.component';

@Component({
  selector: 'lib-canvas-designer',
  templateUrl: './canvas-designer.component.html',
  styleUrls: ['./canvas-designer.component.scss']
})
export class CanvasDesignerComponent implements OnInit {
	public selectedTemplate: any = undefined;
	widgetObject: any;

	constructor(private dataService: DataService, public dialog: MatDialog,) { }

  ngOnInit(): void {
  }

	openTemplatePopup() {
		this.selectedTemplate = false;
		const canvasPopupSettingsModal = this.dialog.open(
			CanvasPopupSettingsComponent,
			{
				width: '70%',
				height: '70%',
		}
	);

	  canvasPopupSettingsModal
		  .afterClosed()
		  .subscribe((result) => {
			  if (result) {
				  this.widgetObject = { fromCanvasDesigner: 'modelTemplate' };
				  this.widgetObject.result = result;
				  this.selectedTemplate = true;
			  }
		  });
}

openGSETemplatePopup() {
	this.selectedTemplate = false;
	const canvasGsePopupSettingsModal = this.dialog.open(
		CanvasGsePopupSettingsComponent,
		{
			width: '70%',
			height: '60%',
			data: {
				CanvasTemplateTypeId: 1,
			},
		}
	);

	canvasGsePopupSettingsModal
		.afterClosed()
		.subscribe((result) => {
			if (result) {
				this.widgetObject = { fromCanvasDesigner: 'fleetTemplate' };
				this.widgetObject.AngularDirectiveName = 'canvas';
				this.widgetObject.result = result;
				this.selectedTemplate = true;
			}
		});
}

}
