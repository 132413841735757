import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ChangeDetectorRef,
	EventEmitter,
	NgZone,
} from '@angular/core';
import { GridSettings } from '../../_models/grid-settings.interface';
import { process } from '@progress/kendo-data-query';


import { filter } from 'rxjs/operators';

import { PageChangeEvent } from '@progress/kendo-angular-grid';

import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import _ from 'lodash';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DashboardService } from '../../services/dashboard.service';
import { UtilityService } from '../../services/utility.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

@Component({
	selector: 'lib-wheelchair-overview',
	templateUrl: './wheelchair-overview.component.html',
	styleUrls: ['./wheelchair-overview.component.scss'],
})
export class WheelchairOverviewComponent implements OnInit {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@ViewChild('allGateGrid') allGateGrid: any;

	fullDataCache$: any;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	assetsWithTags: any = [];
	assetIds: any;
	iconHeight: number;
	theme: string;
	pageSize: number;
	assetDataArray = [];
	runtimeData = [];
	siteGates = [];
	componentName: string = 'wheelchair-lift-overview';

	subscriptions: Subscription[] = [];
	tagGraphSingleModalSubscription: Subscription;
	fullDataCacheSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	private displayAllSignalRMessages: boolean = false;

	private powerId = [4768]; // Power
	private eStopId = [12384]; // Estop
	private gateClosedId = [20012]; // GateClosed
	private liftDownId = [20011]; // Lift Down
	private failSafeLimitId = [56491]; // Failsafe Limit
	private failSafeSolenoidId = [56484]; // Failsafe Solenoid
	private allStdIds = [4768, 12384, 20012, 20011, 56491, 56484];

	gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'gate',
				title: 'Gate',
				filterable: true,
				_width: 25,
				minResizableWidth: 25,
			},
			{
				field: 'power',
				title: 'Power',
				filterable: false,
				_width: 25,
				minResizableWidth: 25,
			},
			{
				field: 'eStop',
				title: 'E-Stop',
				filterable: false,
				_width: 25,
				minResizableWidth: 25,
			},
			{
				field: 'gateClosed',
				title: 'Gate Closed',
				filterable: false,
				_width: 25,
				minResizableWidth: 25,
			},
			{
				field: 'liftDown',
				title: 'Lift Down',
				filterable: false,
				_width: 25,
				minResizableWidth: 25,
			},
			{
				field: 'failSafeLimit',
				title: 'Fail Safe Limit',
				filterable: false,
				_width: 25,
				minResizableWidth: 25,
			},
			{
				field: 'failSafeSolenoid',
				title: 'Fail Safe Solonoid',
				filterable: false,
				_width: 25,
				minResizableWidth: 25,
			},
		],
	};
	assetIdsAsNumbers: any;
	public guid: string;

	constructor(
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private kendoSettingsService: KendoSettingsService,
		private ref: ChangeDetectorRef,
		private dashboardService: DashboardService,
		public dialog: MatDialog,
		private utilityService: UtilityService,
		private zone: NgZone
	) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		if (this.widgetResizedEvent) {
			this.subscriptions.push(
				this.widgetResizedEvent.subscribe((data) => {})
			);
		}

		this.subscriptions.push(
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
			})
		);
		
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		this.subscriptions.forEach((subscription: Subscription) =>
			subscription.unsubscribe()
		);
	}

	initialize() {
		Global.User.DebugMode &&
			console.log(this.componentName + ': initialize invoked...');

		if (this.widgetObject.WidgetSiteId == undefined) {
			Global.User.DebugMode &&
				console.log(this.componentName + ': No site Id provided.');
			this.isDataLoading = false;
			return;
		}

		this.theme = Global.Theme;
		this.iconHeight = Global.isMobile ? 15 : 25;

		// get assets for site
		this.assetsWithTags = this.dataService.cache.assets
			.filter((a) => a.SiteId == this.widgetObject.WidgetSiteId)
			.filter((a) => ['WCL'].includes(a.Name))
			.filter((a) => a.ParentSystem.Assets.length > 0)
			.map((a) => ({
				Id: a.Id,
				Type: a.Name,
				Tags: a.Tags,
				AllTagsPopulated: false,
				BaseUnitImageURL: a.BaseUnitImageURL,
				SiteId: a.SiteId,
				SiteName: a.Site.Name,
				GateName: a.ParentSystem && a.ParentSystem.Name,
				GateId: a.ParentSystem && a.ParentSystem.Id,
				ZoneName:
					a.ParentSystem.ParentSystem &&
					a.ParentSystem.ParentSystem.Name,
				ZoneId:
					a.ParentSystem.ParentSystem &&
					a.ParentSystem.ParentSystem.Id,
				TerminalName:
					a.ParentSystem.ParentSystem &&
					a.ParentSystem.ParentSystem.ParentSystem &&
					a.ParentSystem.ParentSystem.ParentSystem.Name,
				TerminalId:
					a.ParentSystem.ParentSystem &&
					a.ParentSystem.ParentSystem.ParentSystem &&
					a.ParentSystem.ParentSystem.ParentSystem.Id,
			}));

		if (this.assetsWithTags.length > 0) {
			let gateNames = this.assetsWithTags.map((a) => a.GateName);
			this.assetIds = this.assetsWithTags
				.map((a) => a.Id.toString())
				.join(',');
			this.assetIdsAsNumbers = this.assetsWithTags.map((a) => a.Id);

			this.subscriptions.push(
				this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
						this.assetIds,
						false,
						this.allStdIds.toString()
					)
					.subscribe((data) => {
						Global.User.DebugMode &&
							console.log(
								this.componentName +
									': GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
								data
							);
						this.sortGateNames(gateNames);
						this.getInitialData(data);
					})
			);

			if (this.widgetObject.WidgetKendoUIJson) {
				let jsonObjectParsed =
					this.kendoSettingsService.parseReturnedSettingsForDates(
						this.widgetObject.WidgetKendoUIJson
					);
				let returnedParsedObject = jsonObjectParsed[0]; // the first item in the array is the gridSettings for the first tab of data for this widget.

				//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
				this.gridSettings = this.mapGridSettings(
					this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
						this.gridSettings,
						returnedParsedObject
					)
				);
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.assetDataArray);
				}

				this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
					[this.gridSettings.columnsConfig],
					this.widgetObject.WidgetId
				);
			} else {
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.assetDataArray);
				}
			}
			this.widgetObject.isDisplayDataLive = true;
		} else this.isDataLoading = false;
	}

	// Get the initial data from the cache
	getInitialData(tags: any[]) {
		Global.User.DebugMode &&
			console.log(this.componentName + ': GetInitialData Invoked');

		this.assetDataArray.forEach((row) => {
			let tagsForGate = tags.filter(
				(t) => t.Asset.ParentSystem.Name == row.gate
			);

			row.assetId = tagsForGate.find(
				(t) => t.Asset.ParentSystem.Name == row.gate
			)?.AssetId;
			row.power = tagsForGate
				.filter((t) =>
					this.powerId.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.eStop = tagsForGate
				.filter((t) =>
					this.eStopId.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '0')
				? true
				: false;
			row.gateClosed = tagsForGate
				.filter((t) =>
					this.gateClosedId.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.liftDown = tagsForGate
				.filter((t) =>
					this.liftDownId.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '0')
				? true
				: false;
			row.failSafeLimit = tagsForGate
				.filter((t) =>
					this.failSafeLimitId.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '0')
				? true
				: false;
			row.failSafeSolenoid = tagsForGate
				.filter((t) =>
					this.failSafeSolenoidId.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '0')
				? true
				: false;
		});

		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.assetDataArray);
		}
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': this.assetDataArray = %O',
				this.assetDataArray
			);

		this.isDataLoading = false;
		this.getSignalRUpdates(tags.map((t) => t.Id));
	}

	mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		Global.User.DebugMode &&
			console.log(this.componentName + ': state = %O', state);
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
			gridData:
				this.dataService.cache !== undefined
					? process(this.assetDataArray, state)
					: emptyArray,
		};
	}

	saveGridSettings() {
		this.kendoSettingsService
			.saveGridSettings(
				[
					{
						gridObject: this.tagDataGrid.gridDataSubject.next(this.assetDataArray),
						gridState: this.gridSettings.state,
					},
				],
				this.widgetObject.WidgetId
			)
			.then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
	}

	openWCLSummary(assetId: number) {
		let asset = this.assetsWithTags.find((a) => assetId == a.Id);

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					': ' +
					`WCL Summary for Asset ${assetId}  was opened.`
			);
		const wclSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				popupTitle: `WheelChair Lift Summary: ${this.widgetObject.WidgetSiteName} | Gate ${asset.GateName}`,
				siteName: this.widgetObject.WidgetSiteName,
				gateSystemName: asset.GateName,
				widgetNameDisplay: 'WheelChair Lift Summary',
				assetObj: asset,
				WidgetName: 'WCL-Summary',
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});

		this.subscriptions.push(
			wclSummaryModal.afterClosed().subscribe((result) => {
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							': ' +
							`Gate ${asset.GateName} | WCL Summary for Asset ${assetId} was closed.`
					);
			})
		);
	}

	onResized(event) {
		this.pageSize = ((event.newRect.height - 120) / 36) * 3;
		Global.User.DebugMode &&
			console.log(this.componentName + ': PageSize = ' + this.pageSize);
	}

	sortGateNames(gateNames) {
		gateNames.forEach((gate) => {
			if (!this.siteGates.includes(gate)) {
				this.siteGates.push(gate);
			}
		});

		this.siteGates = this.siteGates.sort((g1, g2) =>
			this.utilityService
				.GetGateNameSortValue(g1)
				.toString()
				.localeCompare(
					this.utilityService.GetGateNameSortValue(g2),
					'en',
					{ numeric: true }
				)
		);

		this.siteGates.forEach((gate) => {
			let rowObj = {
				gate: gate,
				assetId: undefined,
				gridIndex: undefined,
				power: undefined,
				eStop: undefined,
				gateClosed: undefined,
				liftDown: undefined,
				failSafeLimit: undefined,
				failSafeSolenoid: undefined,
			};

			this.assetDataArray.push(rowObj);
		});
	}

	getSignalRUpdates(tagIds: any) {
		let tagNamePrefixesString = _.uniq(this.assetIdsAsNumbers.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix)).join(",");
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}
		Global.User.DebugMode && console.log("this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: this.widgetObject.WidgetName,						
										 TagNamePrefix: tagNamePrefixesString.split(",") })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTableData(tag);
				});
			});	
	}

	updateTableData(tagObj: any) {
		let gate = tagObj.Asset.ParentSystem.Name;
		let gridIndex = this.assetDataArray.findIndex((a) => a.gate == gate);
		let newValue: any, currentValue: any;

		if (this.powerId.includes(tagObj.JBTStandardObservationId)) {
			currentValue = this.assetDataArray[gridIndex].power;
			newValue = this.getDataFromNewTag(tagObj, 'POWER');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].power = newValue;
			}
		}

		if (this.eStopId.includes(tagObj.JBTStandardObservationId)) {
			currentValue = this.assetDataArray[gridIndex].eStop;
			newValue = this.getDataFromNewTag(tagObj, 'ESTOP');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].eStop = newValue;
			}
		}

		if (this.gateClosedId.includes(tagObj.JBTStandardObservationId)) {
			currentValue = this.assetDataArray[gridIndex].gateClosed;
			newValue = this.getDataFromNewTag(tagObj, 'GATECLOSED');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].gateClosed = newValue;
			}
		}

		if (this.liftDownId.includes(tagObj.JBTStandardObservationId)) {
			currentValue = this.assetDataArray[gridIndex].liftDown;
			newValue = this.getDataFromNewTag(tagObj, 'LIFTDOWN');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].liftDown = newValue;
			}
		}

		if (this.failSafeLimitId.includes(tagObj.JBTStandardObservationId)) {
			currentValue = this.assetDataArray[gridIndex].failSafeLimit;
			newValue = this.getDataFromNewTag(tagObj, 'FAILSAFELIMIT');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].failSafeLimit = newValue;
			}
		}

		if (this.failSafeSolenoidId.includes(tagObj.JBTStandardObservationId)) {
			currentValue = this.assetDataArray[gridIndex].failSafeSolenoid;
			newValue = this.getDataFromNewTag(tagObj, 'FAILSAFESOLENOID');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].failSafeSolenoid = newValue;
			}
		}
	}

	getDataFromNewTag(tagObj: any, code: string): any {
		switch (code) {
			case 'POWER':
				return tagObj?.Value == '1' ? true : false;

			case 'ESTOP':
				return tagObj?.Value == '0' ? true : false;

			case 'GATECLOSED':
				return tagObj?.Value == '1' ? true : false;

			case 'LIFTDOWN':
				return tagObj?.Value == '0' ? true : false;

			case 'FAILSAFELIMIT':
				return tagObj?.Value == '0' ? true : false;

			case 'FAILSAFESOLENOID':
				return tagObj?.Value == '0' ? true : false;
		}
	}
}
