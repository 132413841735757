<lib-loading-spinner *ngIf="isLoading && !noAccessToThisWidget" [loadingMessage]="'Loading Cameras...'" [vehicle]="'plane'"></lib-loading-spinner>

<div *ngIf="noAccessToThisWidget" style="height: 100%; width: 100%" >
	<lib-centered-message *ngIf="noAccessToThisWidget" centeredMessage="You no longer have access to this site for this widget. Please log an issue in iOPS Customer Support if this is not correct."></lib-centered-message>
</div>
<div *ngIf="global.isMobile == false" style="height: 100%; width: 100%" (resized)="onResized($event)">
	<!-- <lib-loading-spinner *ngIf="isLoading"></lib-loading-spinner> -->
	<lib-centered-message *ngIf="!camerasExistForThisSite && !isLoading && widgetSiteId && !noAccessToThisWidget" centeredMessage="No cameras exist for this site."></lib-centered-message>
	<lib-centered-message *ngIf="!widgetSiteId && !noAccessToThisWidget" centeredMessage="Please select a site to view camera feeds."></lib-centered-message>
	<mat-drawer-container class="example-container" autosize>
		<mat-drawer #drawer class="example-sidenav" mode="side">
			<button class="jbt-btn-sm mr-1" type="button" (click)="drawer.toggle()">Toggle Sidenav</button>
			<mat-list>
				<mat-list-item *ngFor="let camera of cameras" class="mat-list-item-padding">
					<div style="white-space: nowrap">
						<button
							class="jbt-btn-sm mr-1 camera-button"
							(click)="changeCamera(camera)"
							[ngClass]="{
								'jbt-btn-sm-primary': camera.Id == selectedCameraId,
								'alarm-active': camera.autoLevelAlarmStatus
							}"
						>
							{{ camera.SelectorButtonLabel }}
						</button>
						<button class="jbt-btn-sm mr-1 calendar-button" *ngIf="camera.potentialAutoLevelNotDeployedAlarmTagId != undefined" type="button" (click)="openTagGraphSingle(camera.potentialAutoLevelNotDeployedAlarmTagId)" aria-label="TagGraph">
							<i class="tim-icons icon-chart-bar-32"></i>
						</button>
						<button class="jbt-btn-sm mr-1 calendar-button" *ngIf="camera.autoLevelMLAlarmTagId != undefined" type="button" (click)="openTagGraphSingle(camera.autoLevelMLAlarmTagId)" aria-label="TagGraph">
							<i class="tim-icons icon-chart-bar-32"></i>
						</button>
						<button *ngIf="currentUserHasElevatedPrivilege" class="jbt-btn-sm mr-1 calendar-button" type="button" (click)="showDateTimeForm(camera)" aria-label="Calendar">
							<i class="tim-icons icon-calendar-60"></i>
						</button>
						<button *ngIf="currentUserHasElevatedPrivilege" class="jbt-btn-sm mr-1 download-button" type="button" (click)="downloadVideo(camera)" aria-label="Download">
							<i class="tim-icons icon-cloud-download-93"></i>
						</button>
					</div>
				</mat-list-item>
			</mat-list>
		</mat-drawer>

		<div class="example-sidenav-content">
			<!-- <button type="button" mat-button (click)="drawer.toggle()">
				Toggle sidenav
			</button> -->
			<p *ngIf="selectedCamera && showIframe && !showHistoricalForm" class="clear-css-styling">
				<iframe class="camera-iframe" id="systemIFrame{{ widgetObject.Id }}" [src]="selectedCamera.trustedSourceURL" [hidden]="hideIFrame"></iframe>
				<lib-centered-message *ngIf="hideIFrame" centeredMessage="Hiding video while resizing for performance. Please wait..."></lib-centered-message>
			</p>

			<form *ngIf="showHistoricalForm" class="form-horizontal">
				<div style="overflow-x: hidden; overflow-y: auto">
					<p></p>
					<p>Start Date/Time:<kendo-datetimepicker [format]="dateTimeFormat" [(value)]="startDateTime"></kendo-datetimepicker></p>
					<p>
						Duration :
						<kendo-numerictextbox [format]="'n'" [(value)]="durationHours" [min]="0" [max]="5"></kendo-numerictextbox>Hrs <kendo-numerictextbox [format]="'n'" [(value)]="durationMinutes" [min]="0" [max]="60"></kendo-numerictextbox>Mins
					</p>
					<p>Download:<kendo-switch [(ngModel)]="checked"></kendo-switch></p>
				</div>
				<div class="card-footer text-center">
					<p>
						<button class="btn btn-primary" (click)="executeDateTimeForm()">Play Video</button>
					</p>
				</div>
			</form>
		</div>
	</mat-drawer-container>
</div>
<div *ngIf="global.isMobile == true" style="height: 100%; width: 100%">
	<lib-centered-message *ngIf="noAccessToThisWidget" centeredMessage="You no longer have access to this site for this widget. Please log an issue in iOPS Customer Support if this is not correct."></lib-centered-message>
	<lib-centered-message *ngIf="!camerasExistForThisSite && !isLoading && widgetObject.WidgetSiteId && !noAccessToThisWidget" centeredMessage="No cameras exist for this site."></lib-centered-message>
	<lib-centered-message *ngIf="!widgetObject.WidgetSiteId && !noAccessToThisWidget" centeredMessage="Please select a site to view camera feeds."></lib-centered-message>
	<ion-content fullscreen="false">
		<mat-drawer-container autosize>
			<mat-drawer #drawer class="example-sidenav" mode="side">
				<ion-button class="camera-button" [color]="'medium'" (click)="drawer.toggle()">Toggle Sidenav</ion-button>
				<mat-list>
					<mat-list-item *ngFor="let camera of cameras" class="mat-list-item-padding">
						<div style="white-space: nowrap">
							<ion-button size="small" id="camera{{ camera.Id }}" class="camera-button" [color]="camera.Id == selectedCameraId ? 'success' : 'medium'" (click)="changeCamera(camera)">{{ camera.SelectorButtonLabel }}</ion-button>
							<ion-button size="small" id="camera{{ camera.Id }}" class="calendar-button" [color]="camera.Id == selectedCameraId ? 'success' : 'medium'" (click)="showDateTimeForm(camera)"><ion-icon name="time-outline" style="height: 30px !important; width: 30px !important"></ion-icon> </ion-button>
						</div>
					</mat-list-item>
				</mat-list>
			</mat-drawer>
			<mat-drawer-content>
				<div slot="fixed">
					<p *ngIf="selectedCamera && showIframe && !showHistoricalForm" class="clear-css-styling">
						<iframe style="min-height: 180px; min-width: 180px; width: fit-content" id="systemIFrame{{ widgetObject.Id }}" [src]="selectedCamera.trustedSourceURL"></iframe>
					</p>
					<!-- <br /><br />Source URL with Safe Pipe as hyperlink:<br />
			  <p *ngIf="selectedCamera && showIframe && !showHistoricalForm" class="clear-css-styling"><a [href]="selectedCamera.sourceUrl | safe">{{selectedCamera.sourceUrl}}</a></p> -->
					<div *ngIf="showHistoricalForm" id="historicalForm" style="font-size: 0.9em !important">
						<form>
							<ion-list lines="full" class="ion-no-margin ion-no-padding">
								<ion-item>
									<ion-label position="stacked">Start</ion-label>
									<ion-datetime displayFormat="MMM DD, YYYY HH:mm" [(ngModel)]="startDateTime" name="startDateTime"></ion-datetime>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Hours</ion-label>
									<ion-input type="number" style="max-width: 10%" [(ngModel)]="durationHours" name="durationHours" min="0" max="5"></ion-input>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Minutes</ion-label>
									<ion-input type="number" style="max-width: 10%" [(ngModel)]="durationMinutes" name="durationMinutes" min="0" max="60"></ion-input>
								</ion-item>
							</ion-list>
							<div>
								<ion-button expand="block" type="submit" style="width: 105px; margin-left: 80px" class="ion-button" (click)="executeDateTimeForm()">Play Video</ion-button>
							</div>
						</form>
					</div>
				</div>
			</mat-drawer-content>
		</mat-drawer-container>
	</ion-content>
</div>
