import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocateAllGseWidgetComponent } from './locate-all-gse-widget.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridContextMenuModule } from '../grid-context-menu/grid-context-menu.module';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { GenericOverviewMapModule } from '../generic-overview-map/generic-overview-map.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [LocateAllGseWidgetComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		GridModule,
		GridContextMenuModule,
		CenteredMessageModule,
		GenericOverviewMapModule,
		KendoGridParentModule,
	],
	exports: [LocateAllGseWidgetComponent],
})
export class LocateAllGseWidgetModule {}
