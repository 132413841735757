<div class="card card-tasks" id="card-whole">
	<div class="card-header widget-card-header"  style="cursor: default; padding-top: 0px; min-height: 0px">
        <div style="padding-top: 10px; display: inline-block">
            <h5 class="title d-inline">
                User Settings for <strong>{{ global.User.currentUser.GivenName + " " + global.User.currentUser.FamilyName }}</strong>
            </h5>
        </div>


        <button mat-button style="display: inline; float: right;" (click)="closeDialog()" matTooltip="Close" [matTooltipPosition]="'below'">
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body user-settings">
		<lib-loading-spinner *ngIf="isFormLoading" [loadingMessage]="'Loading Settings...'" [vehicle]="'plane'"></lib-loading-spinner>
		<div *ngIf="!isFormLoading">
			<div class="form-horizontal" style="overflow-x: hidden; overflow-y: auto;">
				<div class="row col-sm-12 col-md-12">
					<div class="col-sm-3 col-md-3 col-form-label">
						<label for="menuColor">Menu Color</label>
					</div>
					<div id="menuColor" class="col-sm-9 col-md-9">
						<div class="form-group">
							<button
								class="btn btn-dark-gray"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('dark-gray', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'dark-gray'
								}"
							>
								Dark Gray
							</button>
							<button
								class="btn btn-dark-red"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('dark-red', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'dark-red'
								}"
							>
								Dark Red
							</button>
							<button
								class="btn btn-dark-blue"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('dark-blue', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'dark-blue'
								}"
							>
								Dark Blue
							</button>
							<button
								class="btn btn-dark-green"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('dark-green', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'dark-green'
								}"
							>
								Dark Green
							</button>
							<button
								class="btn btn-light-gray"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('light-gray', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'light-gray'
								}"
							>
								Light Gray
							</button>
							<button
								class="btn btn-light-red"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('light-red', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'light-red'
								}"
							>
								Light Red
							</button>
							<button
							class="btn btn-fire-red"
							title="Click this button to change the menu to this color."
							type="button"
							(click)="selectColor('fire-red', $event)"
							[ngClass]="{
								'btn-color-selected': selectedColor == 'fire-red'
							}"
						>
							Fire Red
						</button>
							<button
								class="btn btn-light-blue"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('light-blue', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'light-blue'
								}"
							>
								Light Blue
							</button>
							<button class="btn btn-tan" title="Click this button to change the menu to this color." type="button" (click)="selectColor('tan', $event)" [ngClass]="{ 'btn-color-selected': selectedColor == 'tan' }">
								Tan
							</button>
							<button
								class="btn btn-yellow-gold"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('yellow-gold', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'yellow-gold'
								}"
							>
								Gold
							</button>
							<button
								class="btn btn-bright-green"
								title="Click this button to change the menu to this color."
								type="button"
								(click)="selectColor('bright-green', $event)"
								[ngClass]="{
									'btn-color-selected': selectedColor == 'bright-green'
								}"
							>
								Bright Green
							</button>
						</div>
					</div>
				</div>
				<!-- Slider -->
				<!-- <div class='row col-sm-12 col-md-12'>
        <div class='col-sm-3 col-md-3 col-form-label'>
          <label for="menuColor" >Dark Theme</label>
        </div>
        <div class="col-sm-9 col-md-9">
          <div class="form-group">
            <input type="checkbox" id="darkTheme" [(checked)]="darkTheme">
            <label class="slider-switch" for="darkTheme" title="Click here to change the site theme to dark or light colors." (click)="changeTheme($event)" ></label>
            <div id="darkTheme" class="slider-text" title="Click here to change the site theme to dark or light colors." >
                <div class="slider-text-on" >ON</div>
                <div class="slider-text-off">OFF</div>
            </div>
          </div>
        </div>
      </div> -->
				<div class="row col-sm-12 col-md-12">
					<div class="col-sm-3 col-md-3 col-form-label">
						<label for="menuColor">Sidebar Mini</label>
					</div>
					<div class="col-sm-9 col-md-9">
						<div class="form-group">
							<input class="jbt-slider-bar" type="checkbox" id="sidebarMini" [(ngModel)]="sidebarMini" />
							<label class="slider-switch" for="sidebarMini" title="Click here to use a smaller or larger menu for the website." (click)="changeMenuSize($event)"></label>
							<div class="slider-text" title="Click here to use a smaller or larger menu for the website.">
								<div class="slider-text-on">ON</div>
								<div class="slider-text-off">OFF</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<lib-dynamic-form [questions]="editFields" [options]="formOptions" (submittedValues)="submitForm($event)"></lib-dynamic-form>
				</div>
			</div>
			<div></div>
		</div>
	</div>
</div>
