import {
	Component,
	OnInit,
	Optional,
	Inject,
	ChangeDetectorRef,
	Input,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridSettings } from '../../_models/grid-settings.interface';
import { process, State, GroupDescriptor } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Global } from '../../_constants/global.variables';

import * as $ from 'jquery';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';

@Component({
	selector: 'lib-kendo-ui-generic-popup',
	templateUrl: './kendo-ui-generic-popup.component.html',
	styleUrls: ['./kendo-ui-generic-popup.component.scss'],
})
export class KendoUiGenericPopupComponent implements OnInit, AfterViewInit {
	@ViewChild('calculatedDataRow') calculatedDataRow: ElementRef;
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	theme: string;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';

	isFullScreen = false;
	isDataLoading: boolean;
	rowHeight = 26;
	@Optional() @Input() dataObject: any;
	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
		},

		columnsConfig: [],
	};
	pageSize: number;
	eventDataPageSize: number;
	constructor(
		public dialogRef: MatDialogRef<KendoUiGenericPopupComponent>,
		private ref: ChangeDetectorRef
	) {
		// this.allData = this.allData.bind(this);
	}

	public pointColor = (point): string => {
		console.log(this.dataObject);
		if (this.dataObject.overviewOperator === undefined) {
			return 'green';
		} else if (this.dataObject.overviewOperator === 'less than') {
			if (point.value < this.dataObject.overviewValue) {
				return 'orange';
			} else {
				return 'green';
			}
		} else if (this.dataObject.overviewOperator === 'greater than') {
			if (point.value > this.dataObject.overviewValue) {
				return 'orange';
			} else {
				return 'green';
			}
		} else if (
			this.dataObject.overviewOperator === 'less than or equal to'
		) {
			if (point.value <= this.dataObject.overviewValue) {
				return 'orange';
			} else {
				return 'green';
			}
		} else if (
			this.dataObject.overviewOperator === 'greater than or equal to'
		) {
			if (point.value >= this.dataObject.overviewValue) {
				return 'orange';
			} else {
				return 'green';
			}
		}
	};

	ngOnInit(): void {
		if (this.dataObject && this.dataObject.WidgetName === 'Kendo-Grid') {
			console.log(this.pageSize);
			if (this.dataObject.groupable === true) {
				this.gridSettings.state.group = this.dataObject.groupColumns;
			}
			if (this.dataObject.filterable === true) {
				this.gridSettings.state.filter = this.dataObject.filters;
			}
			if (this.dataObject.sort !== undefined) {
				this.gridSettings.state.sort = this.dataObject.sort;
			}
			this.gridSettings.state.take = 15;
			this.gridSettings.columnsConfig = this.dataObject.tableStructure;

			// console.log("onInit change detection fired");
			// console.log(this.gridSettings.gridData);
		}
	}

	public toggleLegendChange() {
		this.dataObject.legendVisible = !this.dataObject.legendVisible;
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}

	ngAfterViewInit() {
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}
		if (this.dataObject && this.dataObject.WidgetName === 'Kendo-Grid') {
			let mapContainer = $('#parentTableContainerPopup').height();
			this.eventDataPageSize = Math.floor(
				(mapContainer / this.rowHeight) * 3
			);

			// console.log(this.dataObject);
			this.isDataLoading = true;

			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.dataObject.tableData);
			}
			this.isDataLoading = false;
			// console.log("onInit change detection fired");
			// console.log(this.gridSettings.gridData);
		} else if (
			this.dataObject &&
			this.dataObject.WidgetName === 'Kendo-Chart'
		) {
			console.log(this.dataObject);
		}
	}

	onCancel() {
		this.dialogRef.close();
	}

	onResized(event) {
		this.pageSize = ((event.newRect.height - 120) / 36) * 3;
	}

	fullScreen() {
		this.isFullScreen = !this.isFullScreen;
		console.log(this.isFullScreen);
		if (this.isFullScreen) {
			this.dialogRef.updateSize('100%', '100%');
		} else {
			this.dialogRef.updateSize('95%', '80%');
		}
	}
	public labelContent(e: any): string {
		return e.category + ': ' + e.value;
	}

	dataUpdated(collection){
		this.tagDataGrid.gridDataSubject.next(collection);

	}
}
