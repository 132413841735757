import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GpuQuickViewComponent } from './gpu-quick-view.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [GpuQuickViewComponent],
	imports: [CommonModule, ProgressBarModule, ClipboardModule, NgbModule],
	exports: [GpuQuickViewComponent],
})
export class GpuQuickViewModule {}
