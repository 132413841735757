import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardCustomTimescopePopupConfirmationSettingsComponent } from './dashboard-custom-timescope-popup-confirmation-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	DateInputsModule,
	DatePickerModule,
} from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';


@NgModule({
	declarations: [DashboardCustomTimescopePopupConfirmationSettingsComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		DatePickerModule,
		DateInputsModule,
		LabelModule,
	],
})
export class DashboardCustomTimescopePopupConfirmationSettingsModule {}
