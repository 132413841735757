<div class="card card-tasks" id="card-whole"  cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header" cdkDragHandle>
		<h4 class="card-title d-inline" style="padding-left: 15px">
			Tag Settings
		</h4>
		<div
			style="
				padding: 0px;
				float: right;
				margin-right: 20px;
				margin-left: 20px;
			"
			tooltip="Close"
			placement="bottom"
			(click)="closeDialogWindow()"
		>
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div *ngIf="dialogModalInputObject.CanvasWidgetTypeId == 28" class="card-body">
		<mat-button-toggle-group #group="matButtonToggleGroup" value="create">
			<mat-button-toggle value="create" aria-label="Text align center">
				Create a Tag Graph Series
			</mat-button-toggle>
			<mat-button-toggle value="edit" aria-label="Text align left">
				Edit Existing Tag Graph Series
			</mat-button-toggle>
			<mat-button-toggle value="modify" aria-label="Text align left">
				Edit Tag Graph Chart
			</mat-button-toggle>
		</mat-button-toggle-group>

		<div>

				<div
					class="card card-tasks center"
					*ngIf="showNoTagSeriesMsg === true && group.value === 'edit'"
				>
					<h4>There are no Tag Graph Series.Please create One</h4>
				</div>
				<div *ngIf="showNoTagSeriesMsg === false && group.value === 'edit'" class="table-responsive table-container">
					<table class="table" >
						<thead class="text-primary">
							<tr>
								<th>Tag Name</th>
								<!-- <th>Series Type</th> -->
								<th>Y Axis Instance</th>
								<th style="z-index: 2" class="text-middle">Series Color</th>
							</tr>
						</thead>
						<tbody class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
							<tr style="cursor: move;" class="example-box" *ngFor="let tag of tagSeriesExisting; let i = index" cdkDrag>

								<td class="thirdWidth">
									{{ tag.JBTStandardObservationId}} - {{tag.JBTStandardObservationName}}
								</td>
								<!-- <td>
										<mat-select (selectionChange)="chartTypeChange($event, tag)" [ngModel]="tag.SeriesType">
											<mat-option *ngFor="let chartOption of chartOptions" [value]="chartOption">
												<i *ngIf="chartOption === 'area'" class="fa fa-area-chart" aria-hidden="true"> : </i>
												<i *ngIf="chartOption === 'line'" class="fa fa-line-chart" aria-hidden="true"> : </i>
												<i *ngIf="chartOption === 'bar'" class="fa fa-bar-chart" aria-hidden="true"> : </i> {{chartOption}}
											</mat-option>
										</mat-select>
										<mat-radio-button  *ngFor="let chartOption of chartOptions" [value]="chartOption" (change)="chartTypeChange($event, tag)" style="margin-right: 10px">
											<i *ngIf="chartOption === 'area'" class="fa fa-area-chart" aria-hidden="true"></i>
											<i *ngIf="chartOption === 'line'" class="fa fa-line-chart" aria-hidden="true"></i>
											<i *ngIf="chartOption === 'bar'" class="fa fa-bar-chart" aria-hidden="true"></i>
										  </mat-radio-button>

								</td> -->
								<td>
									<mat-form-field appearance="fill">
										<mat-select (selectionChange)="yAxisChanged($event, tag)" [ngModel]="tag.YAxisOrdinal">
										  <mat-option *ngFor="let yAxis of yAxises" [value]="yAxis.YAxisOrdinal">
											{{yAxis.label}}
										  </mat-option>
										</mat-select>
									  </mat-form-field>
								</td>

								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input [(colorPicker)]="tag.HexColor" [style.background]="tag.HexColor" (colorPickerClose)="colorChanged($event, tag)"/>
									<button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDelete(tag)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
						</tbody>

					</table>
					<button class="btn btn-fill btn-primary" type="submit" (click)="addYAxis()" style="padding: 3px 6px; margin: 0px">
						<i style="font-size: 15px">Add Y Axis</i>
					</button>
					<button class="btn btn-fill btn-primary" type="submit" [disabled]="unsavedChanges.length === 0" id="Edit" (click)="saveChanges()">
						<i style="font-size: 15px">Edit Tag Graph Series</i>
					</button>
				</div>
				<div *ngIf="showNoTagSeriesMsg === false && group.value === 'modify'" class="table-responsive table-container">
					<table class="table">
						<thead class="text-primary">
							<tr>
								<th>Chart Type</th>
							</tr>
						</thead>
						<tbody class="example-list">
							<tr>
								<td>
									<mat-select (selectionChange)="chartTypeChange($event)" [ngModel]="tagSeriesExisting[0].SeriesType">
										<mat-option *ngFor="let chartOption of chartOptions" [value]="chartOption">
											 {{chartOption}}
										</mat-option>
									</mat-select>
								</td>
							</tr>
						</tbody>

					</table>

					<button class="btn btn-fill btn-primary" type="submit" id="Edit" (click)="saveChangesChartType()">
						<i style="font-size: 15px">Edit Chart Type </i>
					</button>
				</div>
				<form [formGroup]="widgetTemplateForm" (submit)="submitForm(widgetTemplateForm)">

				<div
					class="row col-sm-12 col-md-12"
					*ngIf="group.value === 'create'"
				>
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Below you can create a new Tag Graph Series.
						</p>
						<br />
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>JBT Standard Observation Id</label
							>
						</div>
						  <div class="col-sm-8 col-md-8">
							<div class="form-group">
								<input style="background-color: #4C74B2;color:white" id="name" type="text" aria-label="Number" matInput [formControlName]="JBTStandardObservationId" [id]="JBTStandardObservationId" [matAutocomplete]="auto"/>
									<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (change)="saveSelectedValue($event)">
										<mat-option *ngFor="let obs of filteredList$ | async" [value]="obs">
											{{ obs.SelectorName }}
										</mat-option>
									</mat-autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div
					class="card-footer text-center"
					*ngIf="group.value === 'create'"
				>
					<button class="btn btn-danger" type="submit" id="Create">
						Create Tag Graph Series
					</button>
				</div>
			</form>
		</div>
	</div>
	<div *ngIf="dialogModalInputObject.CanvasWidgetTypeId == 29" class="card-body">
		<div *ngIf="showNoTagSeriesMsg === true" class="card card-tasks center">
			<form [formGroup]="widgetTemplateForm" (submit)="submitForm(widgetTemplateForm)">
				<div class="row col-sm-12 col-md-12">
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Create a new Tag Graph Series.
						</p>
						<br />
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>JBT Standard Observation Id</label
							>
						</div>
						  <div class="col-sm-8 col-md-8">
							<div class="form-group">
								<input style="background-color: #4C74B2;color:white" id="name" type="text" aria-label="Number" matInput  [formControlName]="JBTStandardObservationId" [id]="JBTStandardObservationId" [matAutocomplete]="auto"/>
									<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (change)="saveSelectedValue($event)">
										<mat-option *ngFor="let obs of filteredList$ | async" [value]="obs">
											{{ obs.SelectorName }}
										</mat-option>
									</mat-autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer text-center">
					<button class="btn btn-danger" type="submit" id="Create">
						Create Tag Graph Series
					</button>
				</div>
			</form>
		</div>

		<div *ngIf="showNoTagSeriesMsg === false" class="table-responsive table-container">
					<table class="table" >
						<thead class="text-primary">
							<tr>
								<th>Tag Name</th>
								<th>Series Type</th>
								<th style="z-index: 2" class="text-middle">Series Color</th>
							</tr>
						</thead>
						<tbody class="example-list">
							<tr  class="example-box" *ngFor="let tag of tagSeriesExisting; let i = index">

								<td class="thirdWidth">
									{{ tag.JBTStandardObservationId}} - {{tag.JBTStandardObservationName}}
								</td>
								<td>
									<mat-select (selectionChange)="seriesChartTypeChange($event, tag)" [ngModel]="tag.SeriesType">
										<mat-option *ngFor="let chartOption of chartOptions" [value]="chartOption">
											<i *ngIf="chartOption === 'area'" class="fa fa-area-chart" aria-hidden="true"> : </i>
											<i *ngIf="chartOption === 'line'" class="fa fa-line-chart" aria-hidden="true"> : </i>
											<i *ngIf="chartOption === 'bar'" class="fa fa-bar-chart" aria-hidden="true"> : </i> {{chartOption}}
										</mat-option>
									</mat-select>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input [(colorPicker)]="tag.HexColor" [style.background]="tag.HexColor" (colorPickerClose)="colorChanged($event, tag)"/>
									<button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDelete(tag)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
						</tbody>

					</table>
					<button class="btn btn-fill btn-primary" type="submit" [disabled]="unsavedChanges.length === 0" id="Edit" (click)="saveChanges()">
						<i style="font-size: 15px">Edit Tag Graph Series</i>
					</button>
		</div>
	</div>
	<div *ngIf="dialogModalInputObject.CanvasWidgetTypeId == 30" class="card-body">
		<div *ngIf="showNoTagSeriesMsg === true" class="card card-tasks center">
			<form [formGroup]="widgetTemplateForm" (submit)="submitForm(widgetTemplateForm)">
				<div class="row col-sm-12 col-md-12">
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Select a Standard Observation
						</p>
						<br />
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>JBT Standard Observation Id</label
							>
						</div>
						  <div class="col-sm-8 col-md-8">
							<div class="form-group">
								<input style="background-color: #4C74B2;color:white" id="name" type="text" aria-label="Number" matInput  [formControlName]="JBTStandardObservationId" [id]="JBTStandardObservationId" [matAutocomplete]="auto"/>
									<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (change)="saveSelectedValue($event)">
										<mat-option *ngFor="let obs of filteredList$ | async" [value]="obs">
											{{ obs.SelectorName }}
										</mat-option>
									</mat-autocomplete>
							</div>
						</div>
					</div>
					<div class="row col-sm-12 col-md-12">
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>Enter Max Value for gauge</label
							>
						</div>
						<div class="col-sm-8 col-md-8">
							<div class="form-group">
								<input class="form-control" type="text" id="MaxValue" formControlName="MaxValue"/>
							</div>
						</div>
					</div>
				</div>








				<div class="card-footer text-center">
					<button class="btn btn-danger" type="submit" id="Create">
						Create
					</button>
				</div>
			</form>
		</div>
		<div *ngIf="showNoTagSeriesMsg === false" class="table-responsive table-container">
					<table class="table" >
						<thead class="text-primary">
							<tr>
								<th>Tag Name</th>
								<th>Max Value</th>
								<th></th>
							</tr>
						</thead>
						<tbody class="example-list">
							<tr class="example-box" *ngFor="let tag of tagSeriesExisting; let i = index">
								<td class="thirdWidth">
									{{ tag.JBTStandardObservationId}} - {{tag.JBTStandardObservationName}}
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input class="form-control" type="text" id="MaxValue" [(ngModel)]="tag.MaxValue" (change)="maxValueChanged($event, tag)"/>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDelete(tag)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
						</tbody>
					</table>


				<div style="margin-top:20px;">

					<p style="display: inline-block" style="font-size: 12px">Gauge Type</p>

					<select class="form-control" title="State" id="gaugeType" [(ngModel)]="selectedGaugeTypeOption" (change)="applyGaugeTypeSelection($event)">
					<option class="form-control" value="1" >
						Radial
					</option>
					<option class="form-control" value="2" >
						Linear
					</option>

				</select>
				</div>

				<div style="margin-top:20px;">
					<p style="display: inline-block" style="font-size: 12px">Gauge Color Ranges</p>
					<table  class="table">
						<thead class="text-primary">
							<tr>
								<th>From</th>
								<th>To</th>
								<th style="z-index: 2" class="text-middle">Color</th>
							</tr>
						</thead>
						<tbody class="example-list" >
							<tr class="example-box" *ngFor="let range of existingGaugeColorRangesFromCache">
								<td>
									<input class="form-control" type="text" id="Value" [(ngModel)]="range.RangeMinValue" (change)="changedExistingRange($event, range)"/>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input class="form-control" type="text" id="Value" [(ngModel)]="range.RangeMaxValue" (change)="changedExistingRange($event, range)"/>
								</td>

								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input [(colorPicker)]="range.HexColor" [style.background]="range.HexColor" (colorPickerClose)="changedExistingRange($event, range)"/>
									<button class="btn action-btn-link-icon" title="Delete this Condition" type="button" (click)="onDeleteExistingRange(range)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
							<tr class="example-box" *ngFor="let range of newScaleRanges">
								<td>
									<input class="form-control" type="text" id="Value" [(ngModel)]="range.RangeMinValue" (change)="valueChangedRange($event, range)"/>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input class="form-control" type="text" id="Value" [(ngModel)]="range.RangeMaxValue" (change)="valueChangedRange($event, range)"/>
								</td>

								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input [(colorPicker)]="range.HexColor" [style.background]="range.HexColor" (colorPickerClose)="colorChangedRange($event, range)"/>
									<button class="btn action-btn-link-icon" title="Delete this Condition" type="button" (click)="onDeleteRange(range)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
							<tr class="example-box" *ngIf="showAddNewRecord">
								<td>
									<input class="form-control" type="text" id="Value" [(ngModel)]="newRangeFrom" (ngModelChange)="newValueChanged($event)"/>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input class="form-control" type="text" id="Value" [(ngModel)]="newRangeTo" (ngModelChange)="newValueChanged($event)"/>
								</td>

								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input [(colorPicker)]="newRangeConditionColor" [style.background]="newRangeConditionColor"/>
									<button class="btn action-btn-link-icon" title="Add this Condition" type="button" (click)="addGaugeColorRangeRecord($event)">
										<i class="tim-icons danger-color icon-simple-add"></i>
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<button *ngIf="!showAddNewRecord" class="btn btn-fill btn-primary" type="submit" (click)="addConditionRecord()">
						<i style="font-size: 15px">Add Scale Range</i>
					</button>
					<!-- <button *ngIf="scaleRanges.length > 0" class="btn btn-fill btn-primary" type="submit" [disabled]="unsavedChanges.length === 0" id="Edit" (click)="saveChanges()">
						<i style="font-size: 15px">Save Scale Ranges</i>
					</button> -->
				</div>
				<!-- <div>
					<kendo-radialgauge   [pointer]="{ value: 0  }">

						<kendo-radialgauge-scale

								[max]=" tagSeriesExisting[0].MaxValue " >
							<kendo-radialgauge-scale-labels [color]=" global.Theme === 'dark' ? '#fff' : '#000' " >
							</kendo-radialgauge-scale-labels>
							<kendo-radialgauge-scale-ranges>

								<kendo-radialgauge-scale-range *ngFor="let range of newScaleRanges"
									[from]="range.RangeMinValue"
									[to]="range.RangeMaxValue"
									[color]="range.HexColor"
								>
								</kendo-radialgauge-scale-range>

							</kendo-radialgauge-scale-ranges>
						</kendo-radialgauge-scale>

					</kendo-radialgauge>
				</div> -->


					<button class="btn btn-fill btn-primary" type="submit" [disabled]="unsavedChanges.length === 0 && newScaleRanges.length == 0 && unsavedChangesRange.length == 0 && selectedGaugeTypeOptionChanged == false" id="Edit" (click)="saveChanges()">
						<i style="font-size: 15px">Save Gauge Settings</i>
					</button>
		</div>
	</div>
	<div *ngIf="dialogModalInputObject.CanvasWidgetTypeId == 31" class="card-body">
		<div *ngIf="showNoTagSeriesMsg === true" class="card card-tasks center">\
			<form [formGroup]="widgetTemplateForm" (submit)="submitForm(widgetTemplateForm)">
				<div class="row col-sm-12 col-md-12">
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Select a Standard Observation
						</p>
						<br />
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId">JBT Standard Observation Id</label>
							<label for="HexColor">Color When Active</label>
						</div>
						  <div class="col-sm-8 col-md-8">
							<div class="form-group">
								<input style="background-color: #4C74B2;color:white" id="name" type="text" aria-label="Number" matInput
										[formControlName]="JBTStandardObservationId" [id]="JBTStandardObservationId" [matAutocomplete]="auto"/>
									<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (change)="saveSelectedValue($event)">
										<mat-option *ngFor="let obs of filteredList$ | async" [value]="obs">
											{{ obs.SelectorName }}
										</mat-option>
									</mat-autocomplete>

								<input style="margin-top:10px;" [(colorPicker)]="HexColor" [style.background]="HexColor" (colorPickerClose)="colorChangedStandardObservation($event)"/>
							</div>
						</div>
					</div>
				</div>
				<div
					class="card-footer text-center"
				>
					<button class="btn btn-danger" type="submit" id="Create">
						Create
					</button>
				</div>
			</form>
		</div>

		<div *ngIf="showNoTagSeriesMsg === false" class="table-responsive table-container">
					<table class="table" >
						<thead class="text-primary">
							<tr>
								<th>Tag Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody class="example-list">
							<tr class="example-box" *ngFor="let tag of tagSeriesExisting; let i = index">

								<td class="thirdWidth">
									{{ tag.JBTStandardObservationId}} - {{tag.JBTStandardObservationName}}
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDelete(tag)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
						</tbody>

					</table>
		</div>
	</div>
	<div *ngIf="dialogModalInputObject.CanvasWidgetTypeId == 32" class="card-body">
		<mat-button-toggle-group #group="matButtonToggleGroup" value="create">
			<mat-button-toggle value="create" aria-label="Text align center">
				Create Configurable Options
			</mat-button-toggle>
			<mat-button-toggle value="edit" aria-label="Text align left">
				Edit Configurable Options
			</mat-button-toggle>
		</mat-button-toggle-group>

		<div>

				<div
					class="card card-tasks center"
					*ngIf="showNoConfigurableOptionsMsg === true && group.value === 'edit'"
				>
					<h4>There are no Configurable Options for Tag Data Grid.Please create One</h4>
				</div>
				<div *ngIf="showNoConfigurableOptionsMsg === false && group.value === 'edit'" class="table-responsive table-container">
					<table class="table" >
						<thead class="text-primary">
							<tr>
								<th>Filter Type</th>
								<th>Operator</th>
								<th>Value</th>
								<th>Field</th>
								<th>&nbsp;</th>
							</tr>
						</thead>
						<tbody class="example-list">
							<tr style="cursor: move;" class="example-box" *ngFor="let options of configurableOptionsExisting; let i = index">

								<td class="thirdWidth">
									{{options.FilterType}}
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input class="form-control" type="text" id="Operator" [(ngModel)]="options.Operator" (change)="operatorChanged($event, options)"/>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<input class="form-control" type="text" id="FieldValue" [(ngModel)]="options.FieldValue" (change)="fieldValueChanged($event, options)"/>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<mat-select (selectionChange)="columnFieldChanged($event, options)" [ngModel]="options.ColumnField">
										<mat-option *ngFor="let filter of filerColumns" [value]="filter.key">
											{{filter.value}}
										</mat-option>
									</mat-select>
								</td>
								<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
									<button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDeleteOption(options)">
										<i class="tim-icons danger-color icon-trash-simple"></i>
									</button>
								</td>
							</tr>
						</tbody>

					</table>
					<button class="btn btn-fill btn-primary" type="submit" [disabled]="unsavedChanges.length === 0" id="Edit" (click)="saveConfigurableOptionChanges()">
						<i style="font-size: 15px">Edit Configurable Options</i>
					</button>
				</div>
				<form [formGroup]="configurableOptionsForm" (submit)="submitConfigurableOptionsForm(configurableOptionsForm)">

				<div
					class="row col-sm-12 col-md-12"
					*ngIf="group.value === 'create'"
				>
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Below you can create a new Configurable Option.
						</p>
						<br />
						<div class="form-group">
							<label for="name"> Please select a Filter Type </label>
							<mat-select (selectionChange)="selectedFilterType($event)">
								<mat-option *ngFor="let filter of filterTypes" [value]="filter">
										{{filter}}
								</mat-option>
							</mat-select>
						</div>
						<div *ngIf="filterTypeSelected === 'Kendo' || filterTypeSelected === 'Regex'" class="form-group">
							<label *ngIf="filterTypeSelected === 'Kendo'" for="name"> Operator: </label>
							<label *ngIf="filterTypeSelected === 'Regex'" for="name"> Expression: </label>
							<input
								type="text"
								class="form-control"
								required
								formControlName="Operator"
								id="Operator"
							/>
						</div>
						<div *ngIf="filterTypeSelected === 'Kendo'|| filterTypeSelected === 'Regex'" class="form-group">
							<label *ngIf="filterTypeSelected === 'Kendo'" for="value">Value:</label>
							<label *ngIf="filterTypeSelected === 'Regex'" for="value"> Description: </label>
							<input
								type="text"
								class="form-control"
								required
								formControlName="FieldValue"
								id="FieldValue"
							/>
						</div>
						<div *ngIf="filterTypeSelected === 'Kendo' || filterTypeSelected === 'Regex'" class="form-group">
							<label for="column">Column:</label>
							<mat-select (selectionChange)="selectedFilterColumn($event)">
								<mat-option *ngFor="let filter of filerColumns" [value]="filter.key">
										{{filter.value}}
								</mat-option>
							</mat-select>
						</div>
					</div>
				</div>
				<div
					class="card-footer text-center"
					*ngIf="group.value === 'create'"
				>
					<button class="btn btn-danger" type="submit" id="Create">
						Create Option
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
