<div
	*ngIf="widgetObject !== undefined"
	class="map-container{{ widgetObject.WidgetId }}"
	style="height: 100%; width: 100%"
>
	<div
		*ngIf="widgetObject !== undefined"
		id="regularMapLocate{{ widgetObject.WidgetId }}"
		style="height: 100%; width: 100%"
	></div>
	<div class="gseLegend" id="legend{{ widgetObject?.WidgetId }}" *ngIf="this.fleetId === undefined">
		<h3 class="gseLegendLabel"
		>Legend</h3>
	</div>
</div>
<div
	*ngIf="widgetObject !== undefined && this.fleetId === undefined"
	id="style-selector-control{{ widgetObject?.WidgetId }}"
	class="map-control gseLegend"
	style="
		border-color: black;
		border: 1px solid black;
		padding: 5px;
		border-radius: 5px;
	"
>
	<input
		type="radio"
		name="show-hide"
		id="hide-poi{{ widgetObject?.WidgetId }}"
		class="selector-control"
	/>
	<label
		class="gseLegendLabel"
		for="hide-poi"
		style=" background-color: transparent !important"
		>Hide Legend</label
	>
	<input
		type="radio"
		name="show-hide"
		id="show-poi{{ widgetObject?.WidgetId }}"
		class="selector-control"
		checked="checked"
		style="margin-left: 10px"
	/>
	<label
	class="gseLegendLabel"

		for="show-poi"
		style="background-color: transparent !important"
		>Show Legend</label
	>
</div>

<div
	*ngIf="widgetObject === undefined"
	class="map-container"
	style="height: 100%; width: 100%"
>
	<div
		*ngIf="widgetObject === undefined"
		id="regularMapLocate"
		style="height: 100%; width: 100%"
	></div>

	<div class="gseLegend" id="legend" *ngIf="this.fleetId === undefined">
		<h3 class="gseLegendLabel"
		>Legend</h3>
	</div>
</div>
<div
	*ngIf="widgetObject === undefined && this.fleetId === undefined"
	id="style-selector-control"
	class="map-control gseLegend"
	style="
		border-color: black;
		border: 1px solid black;
		padding: 5px;
		border-radius: 5px;
	"
>
	<input
		type="radio"
		name="show-hide"
		id="hide-poi"
		class="selector-control"
	/>
	<label
	class="gseLegendLabel"

		for="hide-poi"
		style="background-color: transparent !important"
		>Hide Legend</label
	>
	<input
		type="radio"
		name="show-hide"
		id="show-poi"
		class="selector-control"
		checked="checked"
		style="margin-left: 10px"
	/>
	<label
		for="show-poi"
		class="gseLegendLabel"

		style="background-color: transparent !important"
		>Show Legend</label
	>
</div>
