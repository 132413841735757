import { Injectable, NgZone, ApplicationRef } from "@angular/core";
import { Router } from "@angular/router";
import { IUser } from "../_models/user.model";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UtilityService } from "./utility.service";
import { tap, map, catchError, filter, retry } from "rxjs/operators";
import {
	of,
	Observable,
	forkJoin,
	BehaviorSubject,
	Subject,
	interval,
	concat,
	Subscription,
	first,
	lastValueFrom,
} from "rxjs";
import { SignalRCoreService } from "./signalr-core.service";
import { ISignalRStatistics } from "../_models/signalr-statistics.model";
import _ from "lodash";
import { Global } from "../_constants/global.variables";
import { TestBed } from "@angular/core/testing";
import { ITag } from "../_models/tag.model";
import { IAsset } from "../_models/asset.model";
import { IStandardObservation } from "../_models/standardObservation.model";
import { IObservation } from "../_models/observation.model";
import { IGenericDataItem } from "../_models/generic-data-item.model";
import { DomSanitizer } from "@angular/platform-browser";
import { ISite } from "../_models/site.model";
import { IncomingDataService } from "./incoming-data.service";
import { ITagNamePrefixSubject } from "../_models/tag-name-prefix-subject.model";
import { ISQLServerSubject } from "../_models/sql-server-subject.model";
import { boxSizingIcon } from "@progress/kendo-svg-icons";

const misc: any = {
	sidebar_mini_active: true,
};

@Injectable({ providedIn: "root" })
export class DataService {
	public currentSelectedIssueId: any;
	public subscriptions: Subscription[] = [];
	public currentUserJSONObject: IUser;

	public cache: any;
	public HighestTagChangeDateInMilliseconds: number = 0;
	public dataServerConnected: boolean;
	public signalRMessageCountToLog: number = 0;
	public operationMetadata: any;
	public Statistics: ISignalRStatistics;
	private lastIntervalCheckTime: any;
	private tabInFocus: boolean;
	public cacheStatus: string;
	public cachedObservables: any;
	private multiDataTimeout: any;
	public service: any;
	public ready: boolean;
	public httpHeaders: HttpHeaders;
	private multiDataDeferralList: Array<any>;
	private multiDataPendingList: Array<any>;
	public httpOptions: any;
	public dataSourceIsLocal: boolean = document.URL.indexOf("localhost") > 0;
	public currentSelectedWidget: any;
	public tempTagsToChart: Array<any>;
	public colorChanged$ = new Subject<any>();

	public punchoutTimeScopeChanged$ = new Subject<any>();
	public punchoutTimeZoneChanged$ = new Subject<any>();

	public canvasPopupInitialized$ = new Subject<any>();
	public canvasGsePopupInitialized$ = new Subject<any>();
	public fullDataCacheExists$ = new Subject<boolean>();
	public dashboardList$ = new Subject<any>();
	public createNewDashboardWidget$ = new Subject<any>();
	public sidenavToggle$ = new Subject<any>();

	public themeChanged$: Subject<string> = new Subject<string>();
	public toggleGeofencesForLocateAllGSEChanged$: Subject<any> =
		new Subject<any>();
	public favoriteChange$ = new Subject<any>();
	public iOPSLogoChanged$: Subject<any> = new Subject<any>();
	public iOPSTinyLogoChanged$: Subject<any> = new Subject<any>();
	public dashboardCreatedFromModal$: Subject<any> = new Subject<any>();
	public companyLogoChanged$: Subject<any> = new Subject<any>();
	public companyTinyLogoChanged$: Subject<any> = new Subject<any>();
	public sidebarMiniChanged$: Subject<any> = new Subject<any>();
	public currentUserChanged$: Subject<any> = new Subject<any>();
	public needToBuildMenu$: Subject<boolean> = new Subject<boolean>();
	public tagChange$: Subject<ITag> = new Subject<ITag>();

	public companyLogo$: BehaviorSubject<any> = new BehaviorSubject<any>(
		Global.DefaultThemeForApplication == "dark"
			? Global.LogosForApplicationTheme.Dark.CompanyLogo
			: Global.LogosForApplicationTheme.Light.CompanyLogo
	);
	public iopsLogo$: BehaviorSubject<any> = new BehaviorSubject<any>(
		Global.DefaultThemeForApplication == "dark"
			? Global.LogosForApplicationTheme.Dark.iOPSLogo
			: Global.LogosForApplicationTheme.Light.iOPSLogo
	);

	private tag: any;
	public toggleButton: any;
	public darkTheme: boolean = true;
	public selectedCanvasTemplateId: any;
	public menuColor: string = "blue";
	private debugMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public currentUserIsLoggedIn$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public currentUserInitialLoadIsFinished$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public isIos: boolean;
	public fullCache$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public serviceName: string = "data-service: ";
	applicationLoadingMessageObject: { message: any; shouldExist: any };
	public assetModelMetaData: any = {
		tempest: [],
	};

	public missingTagsObject: any = {};
	public updatedTagsList: Array<ITag> = [];
	public activeAlarmTags: Array<ITag> = [];

	private processingMissingTagsInProgress: boolean = false;

	public potdUrl: string;
	imageKeyURL: string;
	genericDataUrl: string;
	multiDataAPIUrl: string;
	downloadFileUrl: string;
	requestUserAccountURL: string;
	requestToResetPasswordURL: string;
	changePasswordUrl: string;
	cacheImageKeyFilesUrl: string;
	fileUploadUrl: string;
	fileUploadListUrl: string;
	questionControlDataUrl: string;
	public apiUrl: string;
	public loginUrl: string;
	public passwordTokenValidationUrl: string;

	public elevatorRotundaTagJBTStandardObservationIds: Array<any> = [
		13591, 13605, 13607, 13608, 13721, 13723, 13724, 13729, 19069, 55768,
		56751, 56755, 56760, 56768, 56769, 56776, 56779, 56780, 56781, 56782,
		56783, 56784, 56785, 56786, 56787, 56788, 56789, 56790, 56791, 56792,
		56793, 56794, 56795, 56796, 56798,
	];

	public movePreventionTagJBTStandardObservationIds: Array<any> = [
		3782, 13633, 13640, 56266, 56267, 1887, 14993, 54256, 54257, 13378,
		4525, 4527, 14839, 14846, 1879, 13005, 20011, 12465, 56886, 12386,
		13530, 13510, 13491, 15575, 12384, 13358, 13359, 13576, 12467, 13363,
		54299, 15580, 13051, 13368, 12388, 56379, 13488, 12433, 13516, 13553,
		56887, 12405, 12469, 13449, 13535, 56757, 3499, 13503, 12466, 13596,
		13494, 3930, 14633, 12443, 15664, 12453, 12404, 12431, 12455, 12406,
		15666, 12400, 13445, 13548,
	];

	public perfectTurnStandardObservationIds: any = {
		PBB: [12245, 54271, 54283, 54284],
		PCA: [2736, 12374, 15220, 54283, 54284],
		GPU: [1942, 12374, 15166, 15898, 54283, 54284],
	};
	private gsePeakReportJBTStandardObservationIds: Array<any> = [
		19131, 19134, 19133, 19132, 19161, 19125, 55021, 54910, 55231, 55229,
		1968, 54118, 19127, 54911, 54285, 54288, 54286, 54287, 56302, 56303,
		56326, 56327, 56328, 56363, 56364, 56365, 3895, 4504, 54271, 19264,
		55259, 55192, 55185, 19141, 19229, 19230, 54180,
	];
	public activeSubjects: Array<ITagNamePrefixSubject> = [];
	public sqlListenerSubjects: Array<ISQLServerSubject> = [];

	constructor(
		private http: HttpClient,
		private router: Router,
		private utilityService: UtilityService,
		private zone: NgZone,
		private signalRCore: SignalRCoreService,
		private sanitizer: DomSanitizer,
		private incomingDataService: IncomingDataService
	) {
		setInterval(() => {
			this.harvestIncomingData();
		}, 100);

		if (
			document.URL.indexOf("localhost") > 0 ||
			document.URL.indexOf("test") > 0
		) {
			if (Global.Data.dataServerIsLocal == true) {
				//-- this is to run the webAPI locally.  The setting for Global.Data.dataServerUrl and Global.Data.dataServerIsLocal has already been defined in the Global variables.
				Global.SignalR.CoreUrl = Global.Test.signalRCoreServerUrl;
				Global.Data.footerDataSource = "Test";
				Global.Application.Environment.Name = "test";
			} else {
				var useProductionDataOnTest: string = localStorage.getItem(
					"useProductionDataOnTest"
				);
				if (useProductionDataOnTest && useProductionDataOnTest == "1") {
					Global.Data.dataServerUrl = Global.Prod.oDataServerUrl;
					Global.SignalR.CoreUrl = Global.Prod.signalRCoreServerUrl;
					Global.Data.footerDataSource = "Prod";
					Global.Application.Environment.Name = "test"; //-- setting the name in the footer to 'Beta' because essentially it is acting as beta with the data source as Prod and the software as Test. --Kirk T. Sherer, May 11, 2023.
				} else {
					//-- Had to explicitly set the oDataServerUrl here since the relative addressing wasn't working. --Kirk T. Sherer, November 20, 2023.
					Global.Data.dataServerUrl = Global.Test.oDataServerUrl;
					Global.SignalR.CoreUrl = Global.Test.signalRCoreServerUrl;
					Global.Data.footerDataSource = "Test";
					Global.Application.Environment.Name = "test";
				}
			}
		} else {
			//-- if we didn't come in here with Test in the URL or localhost, set the database to Prod.
			Global.Data.dataServerUrl = Global.Prod.oDataServerUrl;
			Global.SignalR.CoreUrl = Global.Prod.signalRCoreServerUrl;
		}

		let localOdataServerUrl = Global.Data.dataServerUrl;

		if (Global.Data.dataServerIsLocal) {
			localOdataServerUrl.replace("/iOPS", "");
		}

		this.apiUrl =
			localOdataServerUrl +
			"/login?$format=application/json;odata.metadata=none";
		this.loginUrl =
			localOdataServerUrl +
			"/api/LoginValidation?$format=application/json;odata.metadata=none";
		this.passwordTokenValidationUrl =
			localOdataServerUrl +
			"/api/PWTCheck?$format=application/json;odata.metadata=none";
		this.potdUrl =
			localOdataServerUrl +
			"/api/POTD?$format=application/json;odata.metadata=none";
		this.imageKeyURL =
			localOdataServerUrl +
			"/FileImageLibraryDownloads/ImageKey?imageKey=";
		this.genericDataUrl = localOdataServerUrl + "/api/GenericDataObject";
		this.multiDataAPIUrl = localOdataServerUrl + "/api/MultiDataCondensed";
		this.downloadFileUrl =
			localOdataServerUrl +
			"/api/SpreadsheetDownloadForCompositeStatement";
		this.requestUserAccountURL =
			localOdataServerUrl + "/api/RequestUserAccount";
		this.requestToResetPasswordURL =
			localOdataServerUrl + "/api/RequestToResetPassword";
		this.changePasswordUrl = localOdataServerUrl + "/api/ChangePassword";
		this.cacheImageKeyFilesUrl =
			localOdataServerUrl +
			"/WriteFileImagesIntoTransientImagesFolderIfNotPresent?$format=application/json;odata.metadata=none";
		this.fileUploadUrl = localOdataServerUrl + "/upload";
		this.fileUploadListUrl = localOdataServerUrl + "/ByImageKeyList";
		this.questionControlDataUrl = localOdataServerUrl + "/api/Data";
		//console.log(this.serviceName + "userAgent = %O", window.navigator.userAgent.toLowerCase());
		this.tempTagsToChart = [];
		this.Statistics = {
			SignalR: {
				MessageCount: 0,
				PreviousMessageCount: 0,
				MessagesPerSecond: 0,
				MessagesPerSecondHistory: [],
			},
		};
		this.multiDataDeferralList = [];

		var currentUser = localStorage.getItem("currentUser");

		this.service = this;
		this.cachedObservables = [];

		this.operationMetadata = {
			tagsWithOneSecondCountdowns: [],
		};

		if (
			!currentUser ||
			!Global.User.isLoggedIn ||
			!Global.User.isBeingAuthenticated
		) {
			Global.User.DebugMode &&
				console.log(
					"rerouting to authenticate user since this current user doesn't exist..."
				);
			this.router.navigate(["authentication"]);
		} else {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
			this.debugMode$ = new BehaviorSubject(Global.User.DebugMode);
			this.debugMode$.subscribe((debugMode: boolean) => {
				Global.User.DebugMode = debugMode;
			});

			this.httpHeaders = new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: this.currentUserJSONObject.ODataAccessToken,
			});

			Global.User.DebugMode && console.log("Global = %O", Global);
		}

		this.httpOptions = {
			headers: this.httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		Global.User.DebugMode &&
			console.log("this.Statistics created = %O", this.Statistics);

		this.signalRCore.broadcastMessages$
			.pipe(filter((msg: any) => msg.code == "dataService.ready"))
			.subscribe(
				(data) => {
					Global.User.DebugMode &&
						console.log(
							"dataService.ready received via SignalR broadcast message."
						);
					if (!this.cache) {
						Global.User.DebugMode &&
							console.log(
								"dataService.cache is not yet populated"
							);
					}
					var service = this;
					Global.User.DebugMode &&
						console.log("service.cache = %O", service.cache);
					this.fullCache$ = new BehaviorSubject(service.cache);
					this.fullCache$.subscribe(
						(cache) => {
							if (cache) {
								Global.User.DebugMode &&
									console.log("fullCache$ cache = %O", cache);
								if (!Global.User.isAdmin) {
									//-- current user isn't an administrator, so only give them their own list of issues. --Kirk T. Sherer, August 8, 2023.
									service.cache.userIssues =
										service.cache.userIssues
											.where((issue: any) => {
												return (
													issue.CreatorUserId ==
													Global.User.currentUser.Id
												);
											})
											.toArray();
								}
								service.cache.ready = true;
								service.ready = true;
								Global.User.DebugMode &&
									console.log("this.cache = %O", {
										...service.cache,
									});
								// for(var propertyName in service.cache){
								//   Global.User.DebugMode && console.log("Property = " + propertyName);
								//   Global.User.DebugMode && console.log("Property Value = %O", service.cache[propertyName]);
								// }

								Global.User.DebugMode &&
									console.log(
										"Global.User.currentUser.SiteList = %O",
										Global.User.currentUser.SiteList
									);
								Global.User.DebugMode &&
									console.log(
										"service.cache.sites = %O",
										service.cache.sites
									);
								var actualPermittedSites = [];
								var permittedSites: any;
								//-- NOTE: Global.User.isAdmin = Global.User.currentUser.IsSystemAdministrator.
								//-- If this is no longer correct, then it should be reset in the authentication component and the login component. --Kirk T. Sherer, September 3, 2020.
								if (Global.User.isAdmin) {
									permittedSites = service.cache.sites;
								} else {
									permittedSites = [];
									service.cache.sites.forEach(
										(site: ISite) => {
											if (
												Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
													site.Id
												)
											) {
												permittedSites.push(site);
											}
										}
									);
								}

								permittedSites.forEach((site: any) => {
									if (site.Name != null && site.Id != null) {
										actualPermittedSites.push(site);
									}
								});
								Global.User.PermittedSites =
									actualPermittedSites;
								// var image = this.GetImage("assets\\img\\default-avatar.png");
								// console.log("GetImage value = " + image);
								Global.User.DebugMode &&
									console.log(
										"Global.User.PermittedSites = %O",
										Global.User.PermittedSites
									);
								Global.User.DebugMode &&
									console.log(
										"Global.User = %O",
										Global.User
									);
								Global.FullDataCacheExists = true;
								service.fullDataCacheExists$.next(true);
								service.fullCache$.unsubscribe();
							} else {
								Global.User.DebugMode &&
									console.log("cache is still undefined...");
							}
						},
						(err) =>
							Global.User.DebugMode &&
							console.log(`fullCache$ error: ${err}`)
					);
				},
				(err) => Global.User.DebugMode && console.log(`${err}`)
			);

		setInterval(() => {
			this.UpdateDataCacheWithMissingTags();
			this.ProcessRecentlyUpdatedTags();
		}, 500);
	}

	public harvestIncomingData() {
		var service = this;
		service.zone.runOutsideAngular(() => {
			while (service.incomingDataService.incomingDataArray.length > 0) {
				//console.log("incoming Data Array: %O", service.incomingDataService.incomingSQLDataArray);
				var signalRMessage =
					this.incomingDataService.incomingDataArray.pop();
				//console.log("signalRMessage = %O", signalRMessage);
				this.UpdateNewObservationFromSignalR(signalRMessage);
			}
		});
	}

	public createSubjectAndSubscribe(configuration: any) {
		Global.User.DebugMode &&
			console.log(
				this.serviceName +
					"<-- Creating and Subscribing to Active Subject -->"
			);
		return new Promise((resolve, reject) => {
			this.activeSubjects.push({
				Id: configuration.Id,
				WidgetName: configuration.WidgetName,
				TagNamePrefix: configuration.TagNamePrefix,
				Subject$: new Subject<any>(),
			});
			resolve({
				Name: configuration.Id,
				WidgetName: configuration.WidgetName,
				TagNamePrefix: configuration.TagNamePrefix,
				Subject$: new Subject<any>(),
			});
		});
	}

	public createSqlListenerSubjectAndSubscribe(configuration: any) {
		Global.User.DebugMode && console.log(this.serviceName +	"<-- Creating and Subscribing to SQL Listener Subject -->");
		return new Promise((resolve, reject) => {
			this.sqlListenerSubjects.push({
				Id: configuration.Id,
				cachedCollectionName: configuration.cachedCollectionName,
				sqlUpdateName: configuration.sqlUpdateName,
				Subject$: new Subject<any>(),
			});
			resolve({
				Name: configuration.Id,
				cachedCollectionName: configuration.cachedCollectionName,
				sqlUpdateName: configuration.sqlUpdateName,
				Subject$: new Subject<any>(),
			});
		});
	}

	public unsubscribeAndLeaveActiveSubjects(guid: string) {
		var activeSubject = this.activeSubjects.firstOrDefault(
			(subject: ITagNamePrefixSubject) => {
				return subject.Id == guid;
			}
		);
		activeSubject && activeSubject.Subject$.unsubscribe();

		this.activeSubjects = this.activeSubjects.where((subject: ITagNamePrefixSubject) => { return subject.Id != guid }).toArray();
		Global.User.DebugMode && console.log(this.serviceName + "<-- Leaving Active Subject -->");
		Global.User.DebugMode && console.log(this.serviceName + "current active subjects: %O", this.activeSubjects);
	}

	public unsubscribeAndLeaveSqlListenerSubjects(guid: string) {
		var sqlListenerSubject = this.sqlListenerSubjects.firstOrDefault(
			(subject: ISQLServerSubject) => {
				return subject.Id == guid;
			}
		);
		sqlListenerSubject && sqlListenerSubject.Subject$.unsubscribe();

		this.sqlListenerSubjects = this.sqlListenerSubjects.where((subject: ISQLServerSubject) => { return subject.Id != guid }).toArray();
		Global.User.DebugMode && console.log(this.serviceName + "<-- Leaving SQL Listener Subject -->");
		Global.User.DebugMode && console.log(this.serviceName + "current SQL Listener Subjects: %O", this.sqlListenerSubjects);
	}

	public returnCorrectActiveSubject(guid: string){
		var activeSubject = this.activeSubjects.firstOrDefault(
			(subject: ITagNamePrefixSubject) => {
				return subject.Id == guid;
			}
		);
		return activeSubject;
	}

	public async GetImage(key: string) {
		var image = this.cache.genericDataObject[key]?.Fields?.firstOrDefault(
			(item: any) => {
				return item.Name == "Base64Content";
			}
		)?.Value;
		if (!image) {
			var currentGenericDataObject = <IGenericDataItem>(
				JSON.parse(localStorage.getItem(key))
			);
			if (
				currentGenericDataObject &&
				currentGenericDataObject.Fields?.firstOrDefault((item: any) => {
					return item.Name == "Base64Content";
				})?.Value != null
			) {
				return currentGenericDataObject.Fields?.firstOrDefault(
					(item: any) => {
						return item.Name == "Base64Content";
					}
				)?.Value;
			}

			const resolution = await this.GetGenericDataObject(key).then(
				(item: IGenericDataItem) => {
					console.log("generic data item = %O", item);
					image = item.Fields?.firstOrDefault((item: any) => {
						return item.Name == "Base64Content";
					})?.Value;
					return image;
				}
			);
			return Promise.resolve(resolution).then((img: string) => {
				return img;
			});
		} else {
			return image;
		}
	}

	public async GetGenericDataObject(key: string, secondsOld?: number) {
		if (secondsOld == undefined) {
			secondsOld = 300;
		}

		var currentGenericDataObject = this.cache.genericDataObject[key];
		if (currentGenericDataObject) {
			if (currentGenericDataObject.ElapsedTimeMS / 1000 < secondsOld) {
				return currentGenericDataObject;
			}
		}
		//-- check to see if we have this in the local storage first. If so, just get it from there.
		currentGenericDataObject = <IGenericDataItem>(
			JSON.parse(localStorage.getItem(key))
		);
		if (currentGenericDataObject) {
			if (currentGenericDataObject.ElapsedTimeMS / 1000 < secondsOld) {
				return currentGenericDataObject;
			}
		}

		//-- if we made it here, then we don't have it in the data cache, nor local storage, or it's too old to use. Go get it from the Redis cache.
		let data = await this.CallGenericDataFunction(key);
		data.DateRetrievedMS = Date.now();
		(data.ElapsedTimeMS = this.DurationInMS(data.DateRetrievedMS)),
			(this.cache.genericDataObject[key] = data);
		this.cache.genericData.push(data);
		localStorage.setItem(key, JSON.stringify(data));
		return data;
	}

	async showAvatar() {
		const response = await fetch("http://example.com/movies.json");
		const movies = await response.json();
	}

	async getImageWorking(key: string) {
		var image = this.cache.genericDataObject[key]?.Fields?.firstOrDefault(
			(item: any) => {
				return item.Name == "Base64Content";
			}
		)?.Value;
		if (!image) {
			var currentGenericDataObject = <IGenericDataItem>(
				JSON.parse(localStorage.getItem(key))
			);
			if (
				currentGenericDataObject &&
				currentGenericDataObject.Fields?.firstOrDefault((item: any) => {
					return item.Name == "Base64Content";
				})?.Value != null
			) {
				let imageinCache =
					currentGenericDataObject.Fields?.firstOrDefault(
						(item: any) => {
							return item.Name == "Base64Content";
						}
					)?.Value;
				let sanitizedImage: any =
					this.sanitizer.bypassSecurityTrustResourceUrl(
						"data:image/png;base64, " + imageinCache
					);
				return sanitizedImage.changingThisBreaksApplicationSecurity;
			}
			let data = await this.CallGenericDataFunction(key);
			data.DateRetrievedMS = Date.now();
			(data.ElapsedTimeMS = this.DurationInMS(data.DateRetrievedMS)),
				(this.cache.genericDataObject[key] = data);
			this.cache.genericData.push(data);
			localStorage.setItem(key, JSON.stringify(data));
			let imageFound = data.Fields?.firstOrDefault((item: any) => {
				return item.Name == "Base64Content";
			})?.Value;
			let sanitizedImage: any =
				this.sanitizer.bypassSecurityTrustResourceUrl(
					"data:image/png;base64, " + imageFound
				);
			return sanitizedImage.changingThisBreaksApplicationSecurity;
		} else {
			let sanitizedImage: any =
				this.sanitizer.bypassSecurityTrustResourceUrl(
					"data:image/png;base64, " + image
				);
			return sanitizedImage.changingThisBreaksApplicationSecurity;
		}
	}

	public async CallThingMethod(url: string, type?: string): Promise<any> {
		this.httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization:
				this.currentUserJSONObject.ODataAccessToken &&
				Global.User.currentUser?.ODataAccessToken,
		});

		const response = await fetch(url, {
			method: type == null ? "GET" : type,
			headers: {
				"Content-Type": "application/json",
					Authorization:
					this.currentUserJSONObject.ODataAccessToken &&
					Global.User.currentUser?.ODataAccessToken,
			},
		});
		return await response.json();
	}

	public async CallGenericDataFunction(key: string): Promise<any> {
		var sqlFullUrl = this.genericDataUrl;

		// if (!this.currentUserJSONObject) {
		// 	this.currentUserJSONObject = <IUser>JSON.parse(localStorage.getItem("currentUser"));
		// }

		this.httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization:
				this.currentUserJSONObject.ODataAccessToken &&
				Global.User.currentUser?.ODataAccessToken,
		});

		const response = await fetch(
			sqlFullUrl + "?key=" + key + "&secondsOld=0",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization:
						this.currentUserJSONObject.ODataAccessToken &&
						Global.User.currentUser?.ODataAccessToken,
				},
			}
		);
		return await response.json();

		// await lastValueFrom(this.http.get(
		// 	sqlFullUrl + '?key=' + key + '&secondsOld=0',
		// 	httpOptions
		// )).then((data: any) => {
		// 	return data.body;
		// });
	}

	public async downloadDatasetAsExcelSpreadsheet(dataset: string) {
		var httpHeaders = new HttpHeaders({
			"Content-Type": "text/plain",
			Authorization: Global.User.currentUser.ODataAccessToken,
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as const,
			responseType: "text" as const,
		};

		Global.User.DebugMode &&
			console.log(
				this.serviceName +
					"dataServerIsLocal = " +
					Global.Data.dataServerIsLocal
			);
		Global.User.DebugMode &&
			console.log(
				this.serviceName +
					"this.downloadFileUrl = " +
					this.downloadFileUrl
			);
		var time0 = performance.now();

		try {
			const httpPost = this.http.post(
				this.downloadFileUrl,
				dataset,
				httpOptions
			);
			var postResponse = await lastValueFrom(httpPost);
			var time = performance.now() - time0;
			console.log(
				this.serviceName +
					"time for webAPI to download the file = " +
					time +
					" milliseconds."
			);

			if (postResponse.body == null) {
				console.log(
					"error downloadDatasetAsExcelSpreadsheet in webAPI: %O",
					postResponse
				);
				return null;
			} else {
				// console.log("postResponse.body = %O", postResponse.body);
				// let data = {
				// 	image: new Blob([postResponse.body], {type: postResponse.headers.get('Content-Type')}),
				// 	filename: "SpreadsheetDownload.xlsx"
				//  }
				// return data;
				return postResponse.body;
			}
		} catch (error) {
			console.log(
				"error in downloadDatasetAsExcelSpreadsheet: " + error.message
			);
			return null;
		}
	}

	UpdateDataCacheWithMissingTags() {
		var service = this;
		var countOfMissingTags = Object.keys(service.missingTagsObject).length;
		if (countOfMissingTags > 0) {
			var missingTagsList = Object.keys(service.missingTagsObject).join(
				","
			);
			if (!this.processingMissingTagsInProgress) {
				this.processingMissingTagsInProgress = true;
				//console.log("Processing " + countOfMissingTags + " missing tags...");

				service
					.SQLActionAsPromise(
						"API.DataCache_GetTagRecordsForTagIdList @tagIds='" +
							missingTagsList +
							"'"
					)
					.then((data: any) => {
						//console.log("API.DataCache_GetTagRecordsForTagIdList @tagIds='" + missingTagsList + "' result: data = %O", data);
						data.forEach((tag: any) => {
							if (tag != undefined) {
								var tagObject = {
									d: tag.D,
								};
								var tag: any =
									service.GetBrokenOutFieldsFromStringTagData(
										tagObject
									);

								var formattedCacheTagObject =
									service.GetStandardCacheTagObjectFromDatabaseFields(
										tag
									);
								//Global.User.DebugMode && console.log(formattedCacheTagObject);
								//if (formattedCacheTagObject == null || formattedCacheTagObject?.TagName == null) {
								// if (tag.Id == 409182415) {
								// 	var formattedCacheTagObject = service.GetStandardCacheTagObjectFromDatabaseFields(tag);
								// }
								//	debugger;
								//}

								var loadedTag =
									service.LoadSignalRObservationToInventory(
										formattedCacheTagObject,
										true
									);

								delete service.missingTagsObject[tag.Id];
							}
						});
						this.processingMissingTagsInProgress = false;
					});
			}
		}
	}

	ProcessRecentlyUpdatedTags() {
		//console.log("this.updatedTagsList = %O", this.updatedTagsList);
		this.updatedTagsList = this.updatedTagsList
			.where((tag: ITag) => {
				var currentDateTimeInMS = Date.now();
				var difference = currentDateTimeInMS - tag.LocalUpdateDateUTCMS;
				//console.log("currentDateTimeInMS = " + currentDateTimeInMS + ", tag.LocalUpdateDateUTCMS = " + tag.LocalUpdateDateUTCMS + ", difference = " + difference);
				var stale =
					currentDateTimeInMS - tag.LocalUpdateDateUTCMS > 2000;
				if (stale) {
					tag.RecentlyUpdated = false; //-- this updates the actual tagsObject.
					return false; //-- this removes it from the updatedTagsList.
				}
				return true; //-- this keeps it on the list.
			})
			.toArray();

		this.activeAlarmTags = this.activeAlarmTags
			.where((tag: ITag) => {
				if (tag.ValueWhenActive != tag.Value) {
					if (tag.Asset == null) {
						tag.Asset = this.cache.assetsObject[tag.AssetId];
					}
					//--Value When Active is already being set to '1' if it's coming in as a null from the database. Severity level != 'Informational' was already decided when placing it on this list.
					this.activeAlarmTags = this.activeAlarmTags
						?.where((alarmTag: ITag) => {
							return alarmTag.Id != tag.Id;
						})
						.toArray(); //--this removes the current tag.Id from the ActiveAlarmTags list since it's no longer equal to its ValueWhenActive.
					tag.Asset.ActiveAlarmTags = this.activeAlarmTags
						.where((alarmTag: ITag) => {
							return alarmTag.AssetId == tag.AssetId;
						})
						.toArray(); //--return all of the Asset.ActiveAlarmTags specific to this asset.
				}
				return true;
			})
			.toArray();
	}

	ApplicationLoadingMessage(message, shouldExist) {
		this.applicationLoadingMessageObject = {
			message: message,
			shouldExist: shouldExist,
		};
	}

	toggleSidenavPanel(widget, side?) {
		if (side !== undefined) {
			this.sidenavToggle$.next({ widget: widget, side: side });
		} else {
			this.sidenavToggle$.next({ widget: widget });
		}
	}

	public async checkAdveezForExistingAssetRecord(
		assetName: string,
		modemNumber?: number
	) {
		var service = this;
		var sql =
			"API.Redis_Adveez_Asset_Lookup @name='" +
			assetName +
			"', @asset_uid=" +
			(modemNumber == null ? "null" : "'" + modemNumber + "'");
		Global.User.DebugMode &&
			console.log(
				service.serviceName +
					"attempting to search for the Adveez asset with this SQL statement: " +
					sql
			);
		return service.SQLActionAsPromise(sql).then((data: any) => {
			if (data.length > 0) {
				Global.User.DebugMode &&
					console.log(
						"data-service: '" +
							assetName +
							"' asset info from Adveez Redis collection: %O",
						data
					);
				return this.formatRedisDataRecordIntoJSONObject(data);
			} else {
				return data;
			}
		});
	}

	private formatRedisDataRecordIntoJSONObject(data: any) {
		var json = {};

		data.forEach((item: any) => {
			json[item.Key] =
				isNaN(item.Value) || item.Value == null
					? item.Value
					: +item.Value;
		});

		return json;
	}

	public async addOrUpdateAdveezRecord(
		assetName: string,
		gse_id?: number,
		energyTypeId?: number,
		modemNumber?: number,
		preferredName?: string
	) {
		var httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		var jsonObject = {};

		if (gse_id != null) {
			//--we're no longer using gse_id, but it does verify that we found an existing asset on their side. --Kirk T. Sherer, November 6,
			jsonObject = {
				name: preferredName != null ? preferredName : assetName,
				model_id: 524,
				energy_id: energyTypeId ?? 2, //-- default to 2 = diesel. Valid energy values are 1 = Electric, 2 = Diesel, 3 = Unknown, 4 = Unpowered, 5 = Gasoline, 6 = Natural Gas.
				site_id: 5952,
				division_id: 1237,
				asset_id: assetName,
				asset_uid: modemNumber == null ? "0" : "" + modemNumber + "",
			};

			var putUrl = Global.GSE.assetPutUrl + "?name=" + assetName;
			var proxyResponse = null;

			try {
				const httpPut = this.http.put(putUrl, jsonObject, httpOptions);
				var putResponse = await lastValueFrom(httpPut);
				if (putResponse.body == null) {
					console.log(
						"error inserting new GSE asset on Adveez' webAPI: %O",
						putResponse
					);
					proxyResponse = null;
				} else {
					proxyResponse = putResponse.body;
				}

				return proxyResponse;
			} catch (error) {
				console.error(
					"error in addOrUpdateAdveezRecord: " + error.message
				);
				return null;
			}
		} else {
			if (modemNumber == null) {
				//-- leave off the asset_uid (aka modem number for us) since creating a null or zero asset_uid will create a duplicate record on their side
				jsonObject = {
					//-- once the vehicle starts actually sending data from its modem since they will create a new record for the same named asset with the real modem number. --Kirk T. Sherer, September 20, 2023.

					name: assetName,
					model_id: 524,
					site_id: 5952,
					division_id: 1237,
					energy_id: energyTypeId ?? 2, //-- default to 2 = diesel. Valid energy values are 1 = Electric, 2 = Diesel, 3 = Unknown, 4 = Unpowered, 5 = Gasoline, 6 = Natural Gas.
					asset_id: assetName,

					comment: "Adding " + assetName + " via Adveez webAPI",
				};
			} else {
				jsonObject = {
					name: assetName,
					model_id: 524,
					site_id: 5952,
					division_id: 1237,
					energy_id: energyTypeId ?? 2, //-- default to 2 = diesel. Valid energy values are 1 = Electric, 2 = Diesel, 3 = Unknown, 4 = Unpowered, 5 = Gasoline, 6 = Natural Gas.
					asset_id: assetName,

					asset_uid: "" + modemNumber + "",
					comment: "Adding " + assetName + " via Adveez webAPI",
				};
			}

			var postUrl = Global.GSE.assetPostUrl;
			try {
				const httpPost = this.http.post(
					postUrl,
					jsonObject,
					httpOptions
				);
				var postResponse = await lastValueFrom(httpPost);
				if (postResponse.body == null) {
					console.log(
						"error inserting new GSE asset on Adveez' webAPI: %O",
						postResponse
					);
					return null;
				} else {
					return postResponse.body;
				}
			} catch (error) {
				console.error(
					"error in addOrUpdateAdveezRecord: " + error.message
				);
				return null;
			}
		}
	}

	public async checkAdveezForExistingAssetRecordViaAdveezAPI(
		assetName: string
	) {
		var httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		var lookupUrl =
			Global.GSE.assetQueryUrl +
			assetName +
			"?apikey=" +
			Global.GSE.apiKey;
		var adveezLookupUrl =
			"https://api.jbt.adveez.com/v1/sites/5952/assets/" +
			assetName +
			"?apikey=" +
			Global.GSE.apiKey;

		try {
			const httpLookup = this.http.get(lookupUrl, httpOptions);
			var lookupResponse = await lastValueFrom(httpLookup);

			if (lookupResponse.body == null) {
				//-- Adveez returned nothing for this GSE Asset.  So we need to add it to their side so we can have Adveez' GSE ID to send to the save stored procedure
				//-- within the 'payload' set of fields. --Kirk T. Sherer, December 21, 2022.
				Global.User.DebugMode &&
					console.log(
						"data-service: Adveez does not have the '" +
							assetName +
							"' asset. Returning null data object..."
					);
				return null;
			} else {
				var data: any = lookupResponse.body;
				//-- go ahead and update Adveez while we're here and return their information back to the calling procedure.
				Global.User.DebugMode &&
					console.log(
						"data-service: '" +
							assetName +
							"' asset info from Adveez API: %O",
						data
					);
				return data;
			}
		} catch (error) {
			console.log(
				"error in checkAdveezForExistingAssetRecord: " + error.message
			);
			//-- Adveez returned with an error during the lookup of this GSE asset.
			return null;
		}
	}

	RequestUserAccount(paramString: string) {
		var httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		var sqlFullUrl =
			this.requestUserAccountURL + "?paramString=" + paramString;

		const http = this.http.get(sqlFullUrl, httpOptions).toPromise();

		return http;
	}

	updateIsLoggedInUserStatus(isLoggedIn: boolean) {
		Global.User.isLoggedIn = isLoggedIn;
		this.currentUserIsLoggedIn$.next(isLoggedIn);
	}

	RequestToResetPassword(username: string, adminUserId?: string) {
		var httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		var url = window.origin;
		console.log("url = " + url);

		var sqlFullUrl =
			this.requestToResetPasswordURL +
			"?username=" +
			username +
			"&url=" +
			url +
			"&adminUserId=" +
			(adminUserId == undefined ? "" : adminUserId);

		const http = this.http.get(sqlFullUrl, httpOptions).toPromise();

		return http;
	}

	ChangePassword(passwordToken: string, newPassword: string) {
		var httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
		});

		let httpOptions = {
			headers: httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		var sqlFullUrl =
			this.changePasswordUrl +
			"?passwordChangeLoginToken=" +
			passwordToken +
			"&newPassword=" +
			encodeURIComponent(newPassword); //-- must encode the typed password to retain special characters. --Kirk T. Sherer, August 8, 2022.
		console.log("sqlFullUrl = " + sqlFullUrl);

		const http = this.http.get(sqlFullUrl, httpOptions).toPromise();

		return http;
	}

	dashboardCreatedFromModal() {
		this.dashboardCreatedFromModal$.next(true);
	}

	setImagesByTheme(theme: string) {
		Global.User.DebugMode && console.log("updating JBT logos...");
		var service = this;
		var imagesUpdated: boolean = false;
		this.themeChanged$.subscribe((theme: string) => {
			service.darkTheme = theme == "dark" ? true : false;
			Global.User.DebugMode &&
				console.log("dataService: Setting Images. theme = " + theme);

			this.updateImages(theme);

			service.iopsLogo$.next(Global.iOPSLogoImage);
			service.companyLogo$.next(Global.CompanyLogoImage);
			imagesUpdated = true;
			Global.Theme = theme;
			service.colorChanged$.next(theme);
			Global.User.DebugMode &&
				console.log(
					"Global.Theme = " +
						Global.Theme +
						", Global.iOPSLogoImage = " +
						Global.iOPSLogoImage +
						", Global.CompanyLogoImage = " +
						Global.CompanyLogoImage
				);
		});

		if (!imagesUpdated) {
			//-- if we haven't sent out a subject observable .next on the themeChanged$ observable, go ahead and set the correct images since this is probably the first time when the user logged in. --Kirk T. Sherer, May 8, 2020.
			this.updateImages(theme);
			service.iOPSLogoChanged$.next(
				theme == "dark"
					? Global.LogosForApplicationTheme.Dark.iOPSLogo
					: Global.LogosForApplicationTheme.Light.iOPSLogo
			);
			service.iOPSTinyLogoChanged$.next(
				theme == "dark"
					? Global.LogosForApplicationTheme.Dark.iOPSTinyLogo
					: Global.LogosForApplicationTheme.Light.iOPSTinyLogo
			);
			service.companyLogoChanged$.next(
				theme == "dark"
					? Global.LogosForApplicationTheme.Dark.CompanyLogo
					: Global.LogosForApplicationTheme.Light.CompanyLogo
			);
			service.companyTinyLogoChanged$.next(
				theme == "dark"
					? Global.LogosForApplicationTheme.Dark.CompanyTinyLogo
					: Global.LogosForApplicationTheme.Light.CompanyTinyLogo
			);
			imagesUpdated = true;
		}
	}

	punchoutTimeZoneChanged(widgets) {
		this.punchoutTimeZoneChanged$.next(widgets);
	}

	punchoutTimeScopeChanged(widgets) {
		this.punchoutTimeScopeChanged$.next(widgets);
	}

	setThemeBackToDefaultSettings() {
		Global.Theme = Global.DefaultThemeForApplication;
		const body = document.getElementsByTagName("body")[0];

		console.log("body.classList = %O", body.classList);
		console.log(
			"Global.Theme = " +
				Global.Theme +
				", Global.DefaultThemeForApplication = " +
				Global.DefaultThemeForApplication
		);

		//-- PLEASE DO NOT COMMENT OUT THESE NEXT FIVE LINES. They are specifically related to insuring we're starting with the correct default theme for the site. --Kirk T. Sherer, August 24, 2022.
		if (Global.Theme == "dark") {
			body.classList.remove("white-content");
		} else {
			body.classList.add("white-content");
		}
		this.setTheme(Global.Theme);
		console.log("Global = %O", Global);
	}

	updateImages(theme: string) {
		//Global.User.DebugMode && console.log("updateImages: theme = " + theme);

		if (theme == "dark") {
			Global.iOPSLogoImage =
				Global.LogosForApplicationTheme.Dark.iOPSLogo;
			Global.iOPSTinyLogoImage =
				Global.LogosForApplicationTheme.Dark.iOPSTinyLogo;
			Global.CompanyLogoImage =
				Global.LogosForApplicationTheme.Dark.CompanyLogo;
			Global.CompanyTinyLogoImage =
				Global.LogosForApplicationTheme.Dark.CompanyTinyLogo;
		} else {
			Global.iOPSLogoImage =
				Global.LogosForApplicationTheme.Light.iOPSLogo;
			Global.iOPSTinyLogoImage =
				Global.LogosForApplicationTheme.Light.iOPSTinyLogo;
			Global.CompanyLogoImage =
				Global.LogosForApplicationTheme.Light.CompanyLogo;
			Global.CompanyTinyLogoImage =
				Global.LogosForApplicationTheme.Light.CompanyTinyLogo;
		}

		this.iOPSLogoChanged$.next(Global.iOPSLogoImage);
		this.companyLogoChanged$.next(Global.CompanyLogoImage);
		this.companyTinyLogoChanged$.next(Global.CompanyTinyLogoImage);
		//Global.User.DebugMode && console.log("Global.iOPSLogoImage = " + Global.iOPSLogoImage);
		//Global.User.DebugMode && console.log("Global.CompanyLogoImageTiny = " + Global.CompanyLogoImageTiny);
		//Global.User.DebugMode && console.log("Global.CompanyLogoImage = " + Global.CompanyLogoImage);
	}

	updateDebugMode(debugMode: boolean, updateFromSettings?: boolean) {
		Global.User.DebugMode = debugMode;
		this.debugMode$.next(Global.User.DebugMode);

		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName " +
					Global.User.currentUser.Id +
					", 'DebugMode', '" +
					(Global.User.DebugMode == true ? 1 : 0) +
					"'"
			).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						"DebugMode updated in user settings. user record: %O",
						data
					);
			});
		}
	}

	ReturnToLastVisitedRoute(
		returnToLastVisitedRoute: boolean,
		updateFromSettings?: boolean
	) {
		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName " +
					Global.User.currentUser.Id +
					", 'ReturnToLastVisitedRoute', '" +
					(returnToLastVisitedRoute == true ? 1 : 0) +
					"'"
			).then((data: any) => {
				if (returnToLastVisitedRoute == true) {
					Global.User.currentUser.ReturnToLastVisitedRoute = true;
				} else {
					Global.User.currentUser.ReturnToLastVisitedRoute = false;
				}

				Global.User.DebugMode &&
					console.log(
						"ReturnToLastVisitedRoute updated in user settings. user record: %O",
						data
					);
			});
		}
	}

	updateUseProductionDataOnTest(
		useProductionDataOnTest: boolean,
		updateFromSettings?: boolean
	) {
		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName @iOPSUserId=" +
					Global.User.currentUser.Id +
					", @FieldName='UseProductionDataOnTest', @FieldValue='" +
					(useProductionDataOnTest == true ? 1 : 0) +
					"'"
			).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						"UseProductionDataOnTest updated in user settings. user record: %O",
						data
					);
			});
		}
	}

	changeSidebarColor(color: string, updateFromSettings?: boolean) {
		//-- these two elements are used in the Sass template to build a selector called 'data'. --Kirk T. Sherer, May 5, 2020.
		const sidebar: any = document.getElementsByClassName("sidebar")[0];
		const mainPanel: any = document.getElementsByClassName("main-panel")[0];

		Global.User.DebugMode &&
			console.log(
				"changing sidebar and mainPanel color to " + color + "..."
			);
		//-- translate the color that was selected to a hex number...

		if (sidebar != undefined) {
			sidebar.setAttribute("data", color);
			Global.User.MenuColor = color;
			//const data = sidebar.getAttribute('data');
			//Global.User.DebugMode && console.log("sidebar data = %O", data);
		}

		if (mainPanel != undefined) {
			mainPanel.setAttribute("data", color);
			Global.User.MenuColor = color;

			// const data = mainPanel.getAttribute('data');
			// Global.User.DebugMode && console.log("mainPanel data = %O", data);
		}

		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName " +
					Global.User.currentUser.Id +
					", 'MenuColor', '" +
					color +
					"'"
			).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						"MenuColor updated in user settings. user record: %O",
						data
					);
			});
		}
	}

	initializeSiteWithUserPreferences() {
		//-- this is the initial setting by the user preference or default if the user hasn't defined a preference. --Kirk T. Sherer, May 5, 2020
		if (
			Global.User.MenuColor &&
			[149, 153, 157, 158].includes(Global.User.currentUser.Organization.Id)
		) {
			Global.User.MenuColor = "fire-red";
		}
		this.changeSidebarColor(Global.User.MenuColor);

		const body = document.getElementsByTagName("body")[0];
		if (Global.User.SidebarMini) {
			body.classList.add("sidebar-mini");
		} else {
			body.classList.remove("sidebar-mini");
		}

		if (Global.Theme == "dark") {
			body.classList.remove("white-content");
		} else {
			body.classList.add("white-content");
		}

		this.updateImages(Global.Theme);
	}

	//-- theming and color changes
	toggleSidebarMini(updateFromSettings?: boolean) {
		Global.User.DebugMode && console.log("sidebarMiniUpdate invoked...");
		const body = document.getElementsByTagName("body")[0];
		Global.User.DebugMode &&
			console.log("body.classList = %O", body.classList);
		if (body.classList.contains("sidebar-mini")) {
			Global.User.DebugMode &&
				console.log(
					"value is false. Removing sidebar-mini class from the body..."
				);
			body.classList.remove("sidebar-mini");
			Global.User.SidebarMini = false;
		} else {
			Global.User.DebugMode &&
				console.log(
					"value is true. Adding sidebar-mini class to the body..."
				);
			body.classList.add("sidebar-mini");
			Global.User.SidebarMini = true;
		}

		this.sidebarMiniChanged$.next(Global.User.SidebarMini);

		// we simulate the window Resize so the charts will get updated in realtime.
		this.zone.runOutsideAngular(() => {
			const simulateWindowResize = setInterval(function () {
				window.dispatchEvent(new Event("resize"));
			}, 180);
			// we stop the simulation of Window Resize after the animations are completed

			setTimeout(function () {
				clearInterval(simulateWindowResize);
			}, 1000);
		});

		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName " +
					Global.User.currentUser.Id +
					", 'SidebarMini', '" +
					(Global.User.SidebarMini ? 1 : 0) +
					"'"
			).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						"SidebarMini updated in user settings. user record: %O",
						data
					);
			});
		}
	}

	initializeCanvasPopup(widgetObject) {
		this.canvasPopupInitialized$.next(widgetObject);
	}

	initializeGseCanvasPopup(widgetObject) {
		this.canvasGsePopupInitialized$.next(widgetObject);
	}

	toggleDarkLightTheme = (
		updateFromSettings?: boolean,
		changeToDarkTheme?: boolean
	) => {
		Global.User.DebugMode && console.log("here... changing theme....");
		const body = document.getElementsByTagName("body")[0];
		Global.User.DebugMode && console.log("body = %O", body);
		Global.User.DebugMode &&
			console.log("this.darkTheme = " + this.darkTheme);
		if (changeToDarkTheme) {
			//-- only need to change to dark since we're explicitly stating that with 'changeToDarkTheme' == true. If we're already on the dark theme then this will keep it that way. --Kirk T. Sherer, May 7, 2020.
			this.toggleWhiteContentOnBody("");
			Global.Theme = "dark";
			this.darkTheme = true;
		} else {
			if (changeToDarkTheme == false) {
				//-- we purposely came into this function indicating to change the theme to a light theme.  No need to check for the white-content class since we would want to keep that class on the body if we're already on the light theme. --Kirk T. Sherer, May 7, 2020.
				this.toggleWhiteContentOnBody("white-content");
				Global.Theme = "light";
				this.darkTheme = false;
			} else {
				//-- since we didn't explicitly tell this function dark or light theme with the 'changeToDarkTheme' field, we'll use the toggle based on whether the white-content CSS class is present or not. --Kirk T. Sherer, May 7, 2020.
				if (body.classList.contains("white-content")) {
					this.toggleWhiteContentOnBody("");
					Global.Theme = "dark";
					this.darkTheme = true;
				} else {
					this.toggleWhiteContentOnBody("white-content");
					Global.Theme = "light";
					this.darkTheme = false;
				}
			}
		}

		Global.User.DebugMode && console.log("Global = %O", Global);
		this.setImagesByTheme(Global.Theme);

		// we simulate the window Resize so the charts will get updated in realtime.
		this.zone.runOutsideAngular(() => {
			const simulateWindowResize = setInterval(function () {
				window.dispatchEvent(new Event("resize"));
			}, 180);

			// we stop the simulation of Window Resize after the animations are completed
			setTimeout(function () {
				clearInterval(simulateWindowResize);
			}, 1000);
		});
		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName " +
					Global.User.currentUser.Id +
					", 'DarkTheme', '" +
					(Global.Theme == "dark" ? 1 : 0) +
					"'"
			).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						"DarkTheme updated in user settings. user record: %O",
						data
					);
			});
		}

		this.themeChanged$.next(Global.Theme);
	};

	toggleGeofencesForLocateAllGSE(widgetObject) {
		this.toggleGeofencesForLocateAllGSEChanged$.next(widgetObject);
	}

	toggleWhiteContentOnBody(color) {
		const body = document.getElementsByTagName("body")[0];
		if (body && color === "white-content") {
			body.classList.add(color);
		} else if (body.classList.contains("white-content")) {
			body.classList.remove("white-content");
		}

		// const companyLogo = document.getElementsByClassName('company-logo')[0];
		// Global.User.DebugMode && console.log("companyLogo = %O", companyLogo);
		// Global.User.DebugMode && console.log("color = " + color);

		// //--if we don't currently have a color JBT logo, but we're changing to white-content (i.e. light theme), then change the logo to the color logo. --Kirk T. Sherer, April 20, 2020.
		// if (companyLogo && color === 'white-content') {
		//   Global.User.DebugMode && console.log("companyLogo: removing the white-company-logo and adding the color-company-logo class...");
		//   companyLogo.classList.remove('white-company-logo');
		//   companyLogo.classList.add('color-company-logo');
		// } else { //-- check to see if we already have a white JBT logo.  If so and we're changing to a light theme, remove the white logo and replace it with the full color logo. --Kirk T. Sherer, April 20, 2020
		//   Global.User.DebugMode && console.log("companyLogo: removing the color-company-logo and adding the white-company-logo class...");
		//   companyLogo.classList.remove('color-company-logo');
		//   companyLogo.classList.add('white-company-logo');

		// }
	}

	setTheme(theme: string, updateFromSettings?: boolean) {
		Global.User.DebugMode &&
			console.log("toggleDarkLightTheme invoked... theme: " + theme);
		//Global.User.DebugMode && console.log("darkTheme = " + darkTheme + ", this.darkTheme = " + this.darkTheme);

		Global.Theme = theme; //-- we're sending either 'light' or 'dark' here. --Kirk T. Sherer, October 16, 2020.
		Global.User.DebugMode && console.log("Global = %O", Global);

		Global.User.DebugMode &&
			console.log(
				"sending out dataService.themeChanged$.next(" +
					Global.Theme +
					")..."
			);

		this.themeChanged$.next(Global.Theme);

		this.setImagesByTheme(Global.Theme);

		//-- next lines are necessary for Kendo UI to toggle dark and light theming correctly... --Kirk T. Sherer, September 6, 2020.
		const body = document.getElementsByTagName("body")[0];
		Global.User.DebugMode && console.log("body = %O", body);

		if (Global.Theme == "dark") {
			body.classList.remove("white-content");
			this.darkTheme = true;
		} else {
			body.classList.add("white-content");
			this.darkTheme = false;
		}

		Global.User.DebugMode &&
			console.log("this.darkTheme = " + this.darkTheme);

		if (updateFromSettings) {
			this.SQLActionAsPromise(
				"API.UserSettings_UpdateRecordByIdAndFieldName " +
					Global.User.currentUser.Id +
					", 'MobileOnlyDarkTheme', '" +
					(Global.Theme == "dark" ? 1 : 0) +
					"'"
			).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						"MobileOnlyDarkTheme updated in user settings. user record: %O",
						data
					);
			});
		}
	}

	changeThemeColor(color: string) {
		const body = document.getElementsByTagName("body")[0];
		if (body && color === "white-content") {
			body.classList.add(color);
		} else if (body.classList.contains("white-content")) {
			body.classList.remove("white-content");
		}

		// const companyLogo = document.getElementsByClassName('company-logo')[0];
		// Global.User.DebugMode && console.log("companyLogo = %O", companyLogo);
		// Global.User.DebugMode && console.log("color = " + color);

		// //--if we don't currently have a color JBT logo, but we're changing to white-content (i.e. light theme), then change the logo to the color logo. --Kirk T. Sherer, April 20, 2020.
		// if (companyLogo && color === 'white-content') {
		//   Global.User.DebugMode && console.log("companyLogo: removing the white-company-logo and adding the color-company-logo class...");
		//   companyLogo.classList.remove('white-company-logo');
		//   companyLogo.classList.add('color-company-logo');
		// } else { //-- check to see if we already have a white JBT logo.  If so and we're changing to a light theme, remove the white logo and replace it with the full color logo. --Kirk T. Sherer, April 20, 2020
		//   Global.User.DebugMode && console.log("companyLogo: removing the color-company-logo and adding the white-company-logo class...");
		//   companyLogo.classList.remove('color-company-logo');
		//   companyLogo.classList.add('white-company-logo');

		// }
	}

	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = (isCollapsed: boolean) => {
		const navbar = document.getElementsByClassName("navbar")[0];
		if (window.innerWidth < 993 && !isCollapsed) {
			navbar.classList.add("bg-white");
			navbar.classList.remove("navbar-transparent");
		} else {
			navbar.classList.remove("bg-white");
			navbar.classList.add("navbar-transparent");
		}
	};

	minimizeSidebar() {
		const body = document.getElementsByTagName("body")[0];
		if (body.classList.contains("sidebar-mini")) {
			misc.sidebar_mini_active = true;
		} else {
			misc.sidebar_mini_active = false;
		}
		if (misc.sidebar_mini_active === true) {
			body.classList.remove("sidebar-mini");
			misc.sidebar_mini_active = false;
			// this.showSidebarMessage("Sidebar mini deactivated...");
		} else {
			body.classList.add("sidebar-mini");
			// this.showSidebarMessage("Sidebar mini activated...");
			misc.sidebar_mini_active = true;
		}

		Global.User.SidebarMini = misc.sidebar_mini_active;
		this.sidebarMiniChanged$.next(Global.User.SidebarMini);
		this.zone.runOutsideAngular(() => {
			// we simulate the window Resize so the charts will get updated in realtime.
			const simulateWindowResize = setInterval(function () {
				window.dispatchEvent(new Event("resize"));
			}, 180);

			// we stop the simulation of Window Resize after the animations are completed
			setTimeout(function () {
				clearInterval(simulateWindowResize);
			}, 1000);
		});
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName("body")[0] as HTMLElement;
		const html = document.getElementsByTagName("html")[0];
		if (window.innerWidth < 991) {
			body.style.position = "fixed";
		}

		setTimeout(function () {
			if (toggleButton && toggleButton.classList != undefined) {
				toggleButton.classList.add("toggled");
			}
		}, 500);

		html.classList.add("nav-open");
		const $layer = document.createElement("div");
		$layer.setAttribute("id", "bodyClick");

		if (html.getElementsByTagName("body")) {
			document.getElementsByTagName("body")[0].appendChild($layer);
		}
		const $toggle = document.getElementsByClassName("navbar-toggler")[0];
		$layer.onclick = function () {
			// asign a function
			html.classList.remove("nav-open");
			setTimeout(function () {
				$layer.remove();
				$toggle.classList.remove("toggled");
			}, 400);
			const mainPanel = document.getElementsByClassName(
				"main-panel"
			)[0] as HTMLElement;

			if (window.innerWidth < 991) {
				setTimeout(function () {
					mainPanel.style.position = "";
				}, 500);
			}
		}.bind(this);

		html.classList.add("nav-open");
	}

	sidebarClose() {
		const html = document.getElementsByTagName("html")[0];

		if (this.toggleButton) {
			this.toggleButton.classList.remove("toggled");
		}

		const body = document.getElementsByTagName("body")[0] as HTMLElement;

		if (window.innerWidth < 991) {
			setTimeout(function () {
				body.style.position = "";
			}, 500);
		}
		const $layer: any = document.getElementById("bodyClick");
		if ($layer) {
			$layer.remove();
		}
		html.classList.remove("nav-open");
	}

	//-- end theming and color changes

	setUserDashboardList(list) {
		this.dashboardList$.next(list);
	}

	buildDashboardAndDashboardObjectAfterEdit(dashboardlist) {
		Global.User.currentUser.Dashboards = dashboardlist;
		Global.User.currentUser.DashboardsObject = {};
		dashboardlist.forEach((dashboard: any) => {
			Global.User.currentUser.DashboardsObject[dashboard.Id] = dashboard;
		});

		console.log(
			"buildDashboardAndDashboardObjectAfterEdit: current user's dashboards after update: %O",
			Global.User.currentUser.Dashboards
		);
		console.log(
			"buildDashboardAndDashboardObjectAfterEdit: current user's dashboardsObject after update: %O",
			Global.User.currentUser.DashboardsObject
		);
	}

	createNewDashboardWidget(object) {
		this.createNewDashboardWidget$.next(object);
	}

	GetImageKeyURL(imageKey: string) {
		const fileImage$ = "API.FileImageLibraryDownloads + '" + imageKey + "'";

		return fileImage$;
	}

	//The email template consists of html and possibly embedded html fragments. This function will fully resolve all embedded fragments.
	GetResolvedEmailTemplateHtml(emailTemplateId: number) {
		var htmlFragments$ = this.GetResource("HTMLFragments");
		var emailTemplate$ = this.SQLAction(
			"API.GetEmailTemplate " + emailTemplateId
		);

		var combined$: any = forkJoin(htmlFragments$, emailTemplate$).pipe(
			map(([htmlFragments, emailTemplate]) => {
				var retrievedEmailTemplate: any = emailTemplate;
				var retrievedHTMLFragments: any = htmlFragments;

				var topFragmentHtml =
					retrievedEmailTemplate.EmailHTMLContent || "";

				var found = false;

				for (var c = 0; c < 100; c++) {
					found = false;
					retrievedHTMLFragments.forEach((fragment) => {
						if (topFragmentHtml.indexOf("@" + fragment.Name) > -1) {
							found = true;
							Global.User.DebugMode &&
								console.log(
									"Replacing " +
										"@" +
										fragment.Name +
										" in content..."
								);
							topFragmentHtml = topFragmentHtml.replace(
								"@" + fragment.Name,
								fragment.Html
							);
						}
					});

					if (!found) {
						break;
					}
				}
				Global.User.DebugMode &&
					console.log(
						"Finished resolving. Content = " + topFragmentHtml
					);
				return topFragmentHtml;
			}),
			tap((data) => {
				Global.User.DebugMode &&
					console.log("GetResolvedEmailTemplateHtml data = %O", data);
			}),
			catchError((err) => {
				throw "Error with GetResolvedEmailTemplateHtml: " + err;
			})
		);

		return combined$;
	}

	//-- Get Resource ---

	GetResourcePromise(collectionName: string, sinceDate?: any) {
		//--returns an observable.  Usage is to call this function with a .subscribe function. --Kirk T. Sherer, February 11, 2020.

		if (!sinceDate) {
			//-- no date was sent, so just go get the collection.  The stored procedure is only getting the records with Deleted = 0, so you don't have to filter for that. --Kirk T. Sherer, February 10, 2020.
			return this.SQLActionAsPromise(
				"API.GetCollectionByName '" + collectionName + "'"
			);
		} else {
			if (sinceDate.isNaN()) {
				//-- date was sent over as a normal date.  convert to UTC Milliseconds.
				var utc = new Date(sinceDate).toISOString();
				var sinceDateinMilliseconds =
					this.utilityService.DateTimeInMilliseconds(utc);
				return this.SQLActionAsPromise(
					"API.GetCollectionByName '" +
						collectionName +
						"', " +
						sinceDateinMilliseconds
				);
			} else {
				//-- sinceDate was sent over as UTC Milliseconds. Stored procedure is expecting that.
				return this.SQLActionAsPromise(
					"API.GetCollectionByName '" +
						collectionName +
						"', " +
						sinceDate
				);
			}
		}
	}

	GetResource(collectionName: string, sinceDate?: any) {
		//--returns an observable.  Usage is to call this function with a .subscribe function. --Kirk T. Sherer, February 11, 2020.

		if (!sinceDate) {
			//-- no date was sent, so just go get the collection.  The stored procedure is only getting the records with Deleted = 0, so you don't have to filter for that. --Kirk T. Sherer, February 10, 2020.
			return this.SQLAction(
				"API.GetCollectionByName '" + collectionName + "'"
			);
		} else {
			if (sinceDate.isNaN()) {
				//-- date was sent over as a normal date.  convert to UTC Milliseconds.
				var utc = new Date(sinceDate).toISOString();
				var sinceDateinMilliseconds =
					this.utilityService.DateTimeInMilliseconds(utc);
				return this.SQLAction(
					"API.GetCollectionByName '" +
						collectionName +
						"', " +
						sinceDateinMilliseconds
				);
			} else {
				//-- sinceDate was sent over as UTC Milliseconds. Stored procedure is expecting that.
				return this.SQLAction(
					"API.GetCollectionByName '" +
						collectionName +
						"', " +
						sinceDate
				);
			}
		}
	}

	GetAuthorizableActivities() {
		return this.GetResource("AuthorizableActivities");
	}

	GetDashboardTimeScopes = function () {
		return this.cache.dashboardTimeScopes && this.cache
			? this.cache.dashboardTimeScopes
			: this.GetResource("DashboardTimeScopes").subscribe(
					(data) => {
						this.cache.dashboardTimeScopes = data;
						return data;
					},
					(err) =>
						Global.User.DebugMode &&
						console.log(`Error with GetDashboardTimeScopes: ${err}`)
			  );
	};

	GetIOPSResourceAsPromise = function (collectionName: string) {
		return this.GetResourcePromise(collectionName);
	};

	GetIOPSResource = function (collectionName: string) {
		return this.GetResource(collectionName);
	};

	GetDashboardsForUser() {
		if (!Global.User.currentUser) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		} else {
			this.currentUserJSONObject = Global.User.currentUser;
		}

		return "API.GetDashboardsForUser " + this.currentUserJSONObject.Id;
	}

	GetCurrentUserSettings() {
		if (!Global.User.currentUser) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		} else {
			this.currentUserJSONObject = Global.User.currentUser;
		}

		return this.SQLActionAsPromise(
			"API.GetUserSettings " + this.currentUserJSONObject.Id
		);
	}

	getDashboardTimeScopes() {
		return Global.User.currentUser.DashboardTimeScopes;
	}

	getDashboard(id: number) {
		return "API.GetDashboard " + id;
	}

	GetTerminalOverviewGraphicsAndTagsForTerminalSystem(systemId: number) {
		if (this.cache != null) {
			return new Observable((subscriber) => {
				var terminalSystemInCache = null;
				terminalSystemInCache =
					this.cache.systemsObject[systemId.toString()];
				if (terminalSystemInCache.TerminalOverviewGraphicsAndTags) {
					subscriber.next(
						terminalSystemInCache.TerminalOverviewGraphicsAndTags
					);
					subscriber.complete();
				} else {
					this.SQLActionAsPromise(
						"API.TerminalOverviewGraphicsAndTags " + systemId
					).then((data: any) => {
						data.forEach(function (tag: any) {
							if (
								+tag.LastObservationTextValue ==
								+(tag.ValueWhenVisible
									? tag.ValueWhenVisible
									: "99999999")
							) {
								tag.showImage = true;
							} else {
								tag.showImage = false;
							}
							if (tag.showImage) {
								//Global.User.DebugMode && console.log("tag graphic set to visible = %O", tag);
							}
						});

						this.PlaceTerminalGraphicsTagsIntoInventory(data);

						var newData = data.toArray();
						if (this.cache) {
							this.cache.systemsObject[
								systemId.toString()
							].TerminalOverviewGraphicsAndTags = newData;
							Global.User.DebugMode &&
								console.log(
									"TerminalOverviewGraphicsAndTags",
									this.cache.systemsObject[
										systemId.toString()
									].TerminalOverviewGraphicsAndTags
								);
						}

						subscriber.next(newData);
						subscriber.complete();
					});
				}
			}).pipe(
				map((data) => {
					return data;
				}),
				tap((data) => {
					Global.User.DebugMode &&
						console.log(
							"GetTerminalOverviewGraphicsAndTagsForTerminalSystem data = %O",
							data
						);
				}),
				catchError((err) => {
					throw (
						"Error with GetTerminalOverviewGraphicsAndTagsForTerminalSystem: " +
						err
					);
				})
			);
		}
	}

	GetExpandedDashboardById(id: number, refreshFromDB?: boolean) {
		Global.User.DebugMode &&
			console.log(
				"data.service.ts: GetExpandedDashboardById id parameter = %O",
				id
			);
		var dashboard$: any = new Observable((subscriber) => {
			var dashboardInCache = null;
			if (!refreshFromDB && this.cache != null) {
				dashboardInCache = this.cache.dashboardsObject[id.toString()];
			}

			if (dashboardInCache) {
				subscriber.next(dashboardInCache);
				subscriber.complete();
			} else {
				this.SQLActionAsPromise(
					"API.GetExpandedDashboardById " + id
				).then((data: any) => {
					var dashboard: any =
						this.SetDashboardDerivedDatesFromDayCount(data);

					if (this.cache && this.cache.dashboardsObject) {
						this.cache.dashboardsObject[id.toString()] = dashboard;
						this.signalRCore.broadcast(
							"cache.dashboardsObject",
							dashboard
						);
					}
					subscriber.next(dashboard);
					subscriber.complete();
				});
			}
		}).pipe(
			map((data) => {
				return data;
			}),
			tap((data) => {
				Global.User.DebugMode &&
					console.log("GetExpandedDashboardById data = %O", data);
			}),
			catchError((err) => {
				throw "Error with GetExpandedDashboardById: " + err;
			})
		);

		return dashboard$;
	}

	SetDashboardDerivedDatesFromDayCount(dashboard: any) {
		//debugger;
		if (dashboard && dashboard.CustomStartDate && dashboard.CustomEndDate) {
			dashboard.derivedStartDate = dashboard.CustomStartDate;
			dashboard.derivedEndDate = dashboard.CustomEndDate;

			dashboard.webApiParameterStartDate =
				this.utilityService.GetUTCQueryDate(dashboard.CustomStartDate);
			dashboard.webApiParameterEndDate =
				this.utilityService.GetUTCQueryDate(dashboard.CustomEndDate);
			dashboard.oDataFilterStartDate =
				this.utilityService.GetUTCQueryDate(dashboard.derivedStartDate);
			dashboard.oDataFilterEndDate = this.utilityService.GetUTCQueryDate(
				dashboard.derivedEndDate
			);
		} else {
			var d = new Date();
			if (dashboard && dashboard.DashboardTimeScopeDays) {
				switch (dashboard.DashboardTimeScopeDays) {
					//Special entry for "Yesterday"
					case -1:
						d.setHours(0, 0, 0, 0);

						dashboard.derivedEndDate = d;
						dashboard.derivedStartDate = new Date(
							new Date(
								new Date().setDate(d.getDate() - 1)
							).setHours(0, 0, 0, 0)
						);
						break;

					//Special entry for "Today since midnight"
					case 0:
						d.setHours(0, 0, 0, 0);
						dashboard.derivedStartDate = d;
						dashboard.derivedEndDate = new Date("1/1/2500");
						break;

					default:
						dashboard.derivedStartDate = new Date(
							new Date().setDate(
								new Date().getDate() -
									dashboard.DashboardTimeScope.Days
							)
						);
						dashboard.derivedEndDate = new Date("1/1/2500");

						break;
				}
			}
		}

		dashboard.webApiParameterStartDate =
			this.utilityService.GetUTCQueryDate(dashboard.derivedStartDate);
		dashboard.webApiParameterEndDate = this.utilityService.GetUTCQueryDate(
			dashboard.derivedEndDate
		);
		dashboard.oDataFilterStartDate = this.utilityService.GetUTCQueryDate(
			dashboard.derivedStartDate
		);
		dashboard.oDataFilterEndDate = this.utilityService.GetUTCQueryDate(
			dashboard.derivedEndDate
		);

		return dashboard;
	}

	CacheImageKeyFilesInTransientImagesFolder(imageKeysList: string) {
		const http$ = this.http
			.post(this.cacheImageKeyFilesUrl, imageKeysList)
			.pipe(
				map((data) => data),
				// tap(data => {
				//   Global.User.DebugMode && console.log("this.CacheImageKeyFilesInTransientImagesFolder data = %O", data);
				// }),
				catchError((err) => of([]))
			);

		return http$;
	}

	GetIOPSWebAPIResource(route: string, obj: any) {
		var url = Global.Data.dataServerUrl + "/api/" + route;

		const http$ = this.http.post(url, obj, this.httpOptions).pipe(
			map((data) => data),
			// tap(data => {
			//   Global.User.DebugMode && console.log("this.CacheImageKeyFilesInTransientImagesFolder data = %O", data);
			// }),
			catchError((err) => of([]))
		);

		return http$;
	}

	LogEvent(userEventTypeId: number, description: string) {
		if (userEventTypeId != undefined && description != undefined) {
			var sql =
				"Security.UserEventLogInsert @UserId=" +
				Global.User.currentUser.Id +
				", @UserEventTypeId=" +
				userEventTypeId +
				", @Description='" +
				description.split("'").join("''") +
				"'";
			this.SQLActionAsPromise(sql).then((data: any) => {
				Global.User.DebugMode &&
					console.log("UserEventLog: " + sql + ", data = %O", data);
			});
		}
	}

	SQLMultiAction(requestJSON: any, accessToken?: string) {
		if (!this.currentUserJSONObject) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		}

		this.httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization:
				Global.User.currentUser?.ODataAccessToken ?? accessToken,
		});

		let httpOptions = {
			headers: this.httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		// var testJSON: any = [
		//    {
		//        label: "AMMR",
		//        sqlStatement: "API.Asset_MaintenanceModeReasons",
		//    },
		//    {
		//        label: "AOOSR",
		//        sqlStatement: "API.Asset_OutOfServiceReasons"
		//    },
		//    {
		//        label: "Countries",
		//        sqlStatement: "API.GetListOfCountries"
		//    },
		//    {
		//        label: "StateAbbreviations",
		//        sqlStatement: "API.GetStateAbbreviations"
		//    }
		// ];

		// requestJSON = testJSON;
		var compositeStatement = requestJSON
			.select((item: any) => {
				return item.label + "^" + item.sqlStatement;
			})
			.toArray()
			.join("~");

		const http = this.http
			.post(this.multiDataAPIUrl, compositeStatement, httpOptions)
			.toPromise()
			.then((data: any) => {
				var returnArray = data.body
					.split("~~!!")
					.select((q) => {
						return q.split("%*");
					})
					.skip(1)
					.where((q: any) => {
						return q.length > 2;
					})
					.select((q: any) => {
						return {
							label: q[0],
							sqlStatement: q[1],
							sqlMS: q[2],
							data:
								q[4] == ""
									? []
									: q[4] == undefined
									? []
									: q[4].startsWith("ERROR")
									? q[4]
									: this.ExpandCondensedTableData(q[3], q[4]),
						};
					})
					.toArray();

				return returnArray;
			});

		return http;
	}

	performMultiDataBatch() {
		//Build a multidata json object to submit
		var queryCounter: number = 1;

		//var testJSON = [
		//          {
		//              label: "AMMR",
		//		sqlStatement: "API.Asset_MaintenanceModeReasons",
		//          },
		//          {
		//              label: "AOOSR",
		//              sqlStatement: "API.Asset_OutOfServiceReasons"
		//          },
		//          {
		//              label: "Countries",
		//              sqlStatement: "API.GetListOfCountries"
		//          },
		//          {
		//              label: "StateAbbreviations",
		//              sqlStatement: "API.GetStateAbbreviations"
		//          }
		//];
		if (this.multiDataDeferralList.length > 0) {
			var jsonGroupedBySubmission = this.multiDataDeferralList
				.select((item) => {
					return {
						label: "Q" + queryCounter++,
						sqlStatement: item.sqlStatement,
						promiseResolve: item.promiseResolve,
						promiseObject: item.promiseObject,
					};
				})
				.groupBy((item) => {
					return item.sqlStatement;
				})
				.select((item) => {
					var output = {
						label: "Q" + queryCounter++,
						sqlStatement: item.key,
						group: item.toArray(),
					};
					if (output.group.length > 1) {
						// console.log(
						// 	"data-service: aggregated multidata found. output = %O",
						// 	output
						// );
					}
					return output;
				})
				.toArray();

			this.multiDataDeferralList = [];
			var t0 = performance.now();

			if (
				jsonGroupedBySubmission.any((item) => {
					return item.group.length > 1;
				})
			) {
				// console.log(
				// 	"data-service: aggregated multidata jsonGroupedBySubmission found. jsonGroupedBySubmission = %O",
				// 	jsonGroupedBySubmission
				// );
			}

			this.SQLMultiAction(jsonGroupedBySubmission).then((multiReturn) => {
				var returnObject: any = multiReturn.body;
				//console.log(performance.now() + " SQLMultiAction Submission = %O", jsonGroupedBySubmission);

				var returnArray = multiReturn
					.select((q: any) => {
						var submissionObject = jsonGroupedBySubmission.first(
							(s: any) => {
								return s.label == q.label;
							}
						);
						return {
							label: q.label,
							sqlStatement: q.sqlStatement,
							sqlMS: q.sqlMS,
							data: q.data,
							group: submissionObject.group,
						};
					})
					.toArray();
				// Global.User.DebugMode &&
				// 	console.log(
				// 		performance.now() +
				// 			" " +
				// 			(performance.now() - t0) +
				// 			"ms SQL MultiData Processed Complex Object: %O",
				// 		returnArray
				// 	);

				t0 = performance.now();
				returnArray.forEach((dataReturn: any) => {
					if (
						!Array.isArray(dataReturn.data) &&
						dataReturn.data.startsWith("ERROR")
					) {
						console.error(
							"Error in SQL MultiData statement: %O",
							dataReturn
						);
						if (dataReturn.data.contains("Expired")) {
							this.SQLActionAsPromise(
								dataReturn.data.sqlStatement
							); //-- running the statement again since we timed out on SQL Server.
						}
					} else {
						dataReturn.group.forEach((sqlQuery) => {
							sqlQuery.promiseResolve(dataReturn.data);
						});
					}
				});

				//console.log(performance.now() + " " + (performance.now() - t0) + "ms SQL MultiData All Promises Resolved");
			});
		}
	}

	ExpandCondensedTableData(cols: string, data: any) {
		var colNumber = 0;

		try {
			var colData = cols.split(",").map((colSet: any) => {
				if (colSet) {
					var colArray = colSet.split("_");

					return {
						type: colArray[0],
						name: colArray[1],
						position: colNumber++,
					};
				}
			});

			var expandedData = data.split("`&").map((row: any) => {
				var rowDataArray = row.split("~!");
				var rowObject = {};
				colData.forEach(function (col) {
					switch (col.type) {
						case "S":
							rowObject[col.name] =
								rowDataArray[col.position] == ""
									? null
									: rowDataArray[col.position];
							break;
						case "N":
							rowObject[col.name] =
								rowDataArray[col.position] == ""
									? null
									: +rowDataArray[col.position];
							break;
						case "D":
							rowObject[col.name] =
								rowDataArray[col.position] == "" ||
								rowDataArray[col.position] == 0
									? null
									: new Date(+rowDataArray[col.position]);
							rowObject[col.name + "MS"] =
								rowDataArray[col.position] == "" ||
								rowDataArray[col.position] == 0
									? null
									: +rowDataArray[col.position];
							break;
						case "B":
							rowObject[col.name] =
								rowDataArray[col.position] == "True" ||
								+rowDataArray[col.position] == 1
									? true
									: false;
							break;
					}
				});
				return rowObject;
			});
			return expandedData;
		} catch (error) {
			console.error(`Error in Expand Condensed Table Data: ${error}`);
		}
	}

	SQLActionAsPromise(sqlStatement: string, immediate?: boolean) {
		var service = this;
		//Global.User.DebugMode && console.log("Global.dataServerIsLocal = " + Global.dataServerIsLocal);

		if (!this.currentUserJSONObject) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		}

		if (
			sqlStatement.substr(0, 4) != "API." &&
			sqlStatement.substr(0, 5) != "URPT." &&
			sqlStatement.substr(0, 9) != "Security."
		) {
			//-- all statements run through this function should be for the API schema in SQL Server.  If we don't have a stored procedure in this schema, then this execution will fail. --Kirk T. Sherer, February 6, 2020.
			sqlStatement = "API." + sqlStatement;
		}

		//console.log(performance.now() + " SQLActionAsPromise Invoked - " + sqlStatement);
		if (!immediate) {
			//Will hold the resolve function reference
			var promiseResolve;

			var listElement = {
				sqlStatement: sqlStatement,
				promiseResolve: null, //Set to null initially
				promiseObject: new Promise(function (resolve) {
					promiseResolve = resolve; //Save the resolution function in the local variable promiseResolve
				}),
			};

			clearTimeout(this.multiDataTimeout);
			this.multiDataTimeout = setTimeout(() => {
				this.performMultiDataBatch();
			}, 500);

			//Set the promiseResolve property of the list element to the saved function reference so we can run it later.
			listElement.promiseResolve = promiseResolve;

			//Set the promiseResolve property of the list element to the saved function reference so we can run it later.
			listElement.promiseResolve = promiseResolve;

			//Add it to the list.
			this.multiDataDeferralList.push(listElement);

			//Return the unresolved (pending) promise back to the caller. We will resolve it later.
			return listElement.promiseObject;
		}

		var methodType = "post";

		//Global.User.DebugMode && console.log("SQLActionAsPromise: sqlStatement = " + sqlStatement + ", type = " + type);

		this.httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization:
				this.currentUserJSONObject.ODataAccessToken &&
				Global.User.currentUser?.ODataAccessToken,
		});

		let httpOptions = {
			headers: this.httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		//Global.User.DebugMode && console.log("sqlStatement before editing for URL: " + sqlStatement);

		// if (sqlStatement.indexOf(" ") > 0 && methodType == "get") {
		// 	sqlStatement = sqlStatement.split(" ").join("+");
		// 	//Global.User.DebugMode && console.log("changing SQL statement spaces into URL-encoded '+' symbols...");
		// }

		// if (sqlStatement.indexOf("'") > 0 && methodType == "get") {
		// 	sqlStatement = sqlStatement.split("'").join("%27");
		// 	//Global.User.DebugMode && console.log("changing SQL statement single quotes into URL-encoded %27 symbols...");
		// }

		// if (sqlStatement.indexOf(",") > 0 && methodType == "get") {
		// 	sqlStatement = sqlStatement.split(",").join("%2C");
		// 	//Global.User.DebugMode && console.log("changing SQL statement commas into URL-encoded %2C symbols...");
		// }

		//Global.User.DebugMode && console.log("sqlStatement after editing for URL: " + sqlStatement);

		var sqlFullUrl = this.apiUrl;

		if (methodType == "get") {
			sqlFullUrl = this.apiUrl + "?storedProcedureName=" + sqlStatement;
		}

		//Global.User.DebugMode && console.log("sqlFullUrl = " + sqlFullUrl);

		//Global.User.DebugMode && console.log("methodType = " + methodType);
		if (methodType == "get") {
			const http = this.http
				.get(sqlFullUrl, httpOptions)
				.toPromise()
				.then((response: any) => {
					console.log("get DataCondensed = %O", response);
					var returnArray = response.body
						.split("\r\n")
						.skip(1)
						.toArray();

					return returnArray[0] == undefined ||
						returnArray[1] == undefined
						? []
						: this.ExpandCondensedTableData(
								returnArray[0],
								returnArray[1]
						  );
				});
			return http;
		} else {
			const http = this.http
				.post(this.apiUrl, sqlStatement, httpOptions)
				.toPromise()
				.then((response: any) => {
					console.log("post DataCondensed = %O", response);
					var returnArray = response.body
						.split("\r\n")
						.skip(1)
						.toArray();
					console.log("returnArray = %O", returnArray);

					return this.ExpandCondensedTableData(
						returnArray[0],
						returnArray[1]
					);
				});
			return http;
		}
	}

	//-- SQL Action function ---
	SQLAction(sqlStatement: string, type?: string) {
		//Global.User.DebugMode && console.log("Global.dataServerIsLocal = " + Global.dataServerIsLocal);
		var methodType = type ?? "post";

		if (!this.currentUserJSONObject) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		}

		this.httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization:
				this.currentUserJSONObject.ODataAccessToken &&
				Global.User.currentUser?.ODataAccessToken,
		});

		if (this.cachedObservables[sqlStatement]) {
			return this.cachedObservables[sqlStatement];
		} else {
			//--returns an observable.  Usage is to call this function with a .subscribe function. --Kirk T. Sherer, February 11, 2020.

			let httpOptions = {
				headers: this.httpHeaders,
				observe: "response" as "response",
				reponseType: "json",
			};

			//Global.User.DebugMode && console.log("sqlStatement before editing for URL: " + sqlStatement);

			if (sqlStatement.indexOf(" ") > 0 && methodType == "get") {
				sqlStatement = sqlStatement.split(" ").join("+");
				//console.log("data.service: changing SQL statement spaces into URL-encoded '+' symbols...");
			}

			if (sqlStatement.indexOf("'") > 0 && methodType == "get") {
				sqlStatement = sqlStatement.split("'").join("%27");
				//console.log("data.service: changing SQL statement single quotes into URL-encoded %27 symbols...");
			}

			if (sqlStatement.indexOf(",") > 0 && methodType == "get") {
				sqlStatement = sqlStatement.split(",").join("%2C");
				//console.log("data.service: changing SQL statement commas into URL-encoded %2C symbols...");
			}

			if (
				sqlStatement.substr(0, 4) != "API." &&
				sqlStatement.substr(0, 5) != "URPT."
			) {
				//-- all statements run through this function should be for the API schema in SQL Server.  If we don't have a stored procedure in this schema, then this execution will fail. --Kirk T. Sherer, February 6, 2020.
				sqlStatement = "API." + sqlStatement;
			}

			//Global.User.DebugMode && console.log("data.service: sqlStatement after editing for URL: " + sqlStatement);

			var sqlFullUrl = this.apiUrl;

			if (methodType == "get") {
				sqlFullUrl =
					this.apiUrl + "?storedProcedureName=" + sqlStatement;
			}

			//Global.User.DebugMode && console.log("data.service: sqlFullUrl = " + sqlFullUrl);
			//Global.User.DebugMode && console.log("data.service: methodType = " + methodType);

			if (methodType == "get") {
				const http$ = this.http.get(sqlFullUrl, httpOptions).pipe(
					map((data) => data.body), //--don't need all the other stuff with the HTTPResponse, so just send back the body that will contain the data from the stored procedure call. --Kirk T. Sherer, January 6, 2020.
					// tap(data => {
					//   Global.User.DebugMode && console.log("this.SQLAction data = %O", {...data});
					// }),
					catchError((err) => of([]))
				);
				this.cachedObservables[sqlStatement] = http$;
				return http$;
			} else {
				const http$ = this.http
					.post(this.apiUrl, sqlStatement, httpOptions)
					.pipe(
						map((data) => data.body), //--don't need all the other stuff with the HTTPResponse, so just send back the body that will contain the data from the stored procedure call. --Kirk T. Sherer, January 6, 2020.
						// tap(data => {
						//   Global.User.DebugMode && console.log("this.SQLAction data = %O", {...data});
						// }),
						catchError((err) => of([]))
					);
				this.cachedObservables[sqlStatement] = http$;
				return http$;
			}

			// const behaviorSubject$ = new BehaviorSubject(null);

			// http$.subscribe({
			//   next: x => behaviorSubject$.next(x),
			//   error: x => behaviorSubject$.error(x),
			//   complete: () => behaviorSubject$.complete()
			// });

			// var newCachedObservable = new ICachedObservable;
			// newCachedObservable.queryText = sqlStatement;
			// newCachedObservable.observable = behaviorSubject$;
			// this.cachedObservables.push(newCachedObservable);
		}
	}

	SQLUniversalReportingAction(sqlStatement: string) {
		if (!this.currentUserJSONObject) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		}

		let httpOptions = {
			headers: this.httpHeaders,
			observe: "response" as "response",
			reponseType: "json",
		};

		//Global.User.DebugMode && console.log("sqlStatement before editing for URL: " + sqlStatement);
		if (sqlStatement.indexOf(" ") > 0) {
			sqlStatement = sqlStatement.split(" ").join("+");
		}

		if (sqlStatement.indexOf("'") > 0) {
			sqlStatement = sqlStatement.split("'").join("%27");
		}

		if (sqlStatement.indexOf(",") > 0) {
			sqlStatement = sqlStatement.split(",").join("%2C");
		}

		if (sqlStatement.substr(0, 3) != "UR.") {
			//-- all statements run through this function should be for the API schema in SQL Server.  If we don't have a stored procedure in this schema, then this execution will fail. --Kirk T. Sherer, February 6, 2020.
			sqlStatement = "UR." + sqlStatement;
		}

		//Global.User.DebugMode && console.log("sqlStatement after editing for URL: " + sqlStatement);

		var sqlFullUrl = this.apiUrl + "?storedProcedureName=" + sqlStatement;
		Global.User.DebugMode &&
			console.log(
				"SQLUniversalReportingAction: sqlFullUrl = " + sqlFullUrl
			);

		const http$ = this.http.get(sqlFullUrl, httpOptions).pipe(
			map((data) => data.body), //--don't need all the other stuff with the HTTPResponse, so just send back the body that will contain the data from the stored procedure call. --Kirk T. Sherer, January 6, 2020.
			// tap(data => {
			//   Global.User.DebugMode && console.log("this.SQLUniversalReportingAction data = %O", {...data});
			// })
			catchError((err) => of([]))
		);

		return http$;
	}

	GetEntityById(collectionName: string, id: number) {
		//Global.User.DebugMode && console.log("OdataService - GetEntityById()  odataSource=" + odataSource + "  collectionName=" + collectionName);
		return this.SQLActionAsPromise(
			"API.GetEntityById '" + collectionName + "', " + id
		);
	}

	GetUserById(id: number) {
		return "API.GetUserProfileRecord " + id;
	}

	GetCollection(
		collectionName: string,
		optionalFilterFieldName?: string,
		optionalFilterFieldValue?: any
	) {
		const http$ = this.SQLAction(
			"API.GetCollection '" +
				collectionName +
				"', '" +
				optionalFilterFieldName +
				"','" +
				optionalFilterFieldValue +
				"'"
		);
		return http$;
	}

	GetIOPSCollection(
		collectionName: string,
		optionalFilterFieldName?: string,
		optionalFilterFieldValue?: any
	) {
		return this.GetCollection(
			collectionName,
			optionalFilterFieldValue,
			optionalFilterFieldValue
		);
	}

	convertObservableToBehaviorSubject<T>(
		observable: Observable<T>,
		initValue: T
	): BehaviorSubject<T> {
		const subject = new BehaviorSubject(initValue);

		observable.subscribe({
			complete: () => subject.complete(),
			error: (x) => subject.error(x),
			next: (x) => subject.next(x),
		});

		return subject;
	}

	GetUser(userId: number) {
		return "API.GetUser " + userId;
	}

	GetStateAbbreviations() {
		return this.GetCollection("StateAbbreviations");
	}

	GetMyDashboards() {
		return this.GetCollection(
			"Dashboards",
			"CreatorUserId",
			Global.User.currentUser.Id
		);
	}

	GetLastXBagTagScans(howMany: number) {
		return "API.GetLastXBagTagScans '" + howMany + "'";
	}

	GetLastXBHSAlarms = function (howMany) {
		return "API.GetLastXBHSAlarms '" + howMany + "'";
	};

	AttachShortTagNameToTagData(tag: any) {
		if (tag.TagName) {
			var tagNameSplit = tag.TagName.split("|");

			if (tagNameSplit.length > 4) {
				var lastOne = tagNameSplit.last().toString();
				tag.ShortTagName = lastOne
					.replace(".PCA.", "")
					.replace(".GPU.", "")
					.replace(".PBB.", "");
			} else {
				tag.ShortTagName = tag.TagName.replace("Airport_", "");
			}
		}
	}

	addOrUpdateDashboardRecord(dashboard: any) {
		if (!dashboard.Id) {
			dashboard.Id = null;
		}

		if (!dashboard.Ordinal) {
			dashboard.Ordinal = null;
		}

		if (!dashboard.TimeScopeId) {
			dashboard.TimeScopeId = null;
		}

		if (!dashboard.TimeZoneId) {
			dashboard.TimeZoneId = null;
		}

		if (!dashboard.DashboardTypeId) {
			dashboard.DashboardTypeId = null;
		}

		if (!dashboard.UpdateIntervalId) {
			dashboard.UpdateIntervalId = null;
		}

		if (!dashboard.CustomStartDateMS) {
			dashboard.CustomStartDateMS = null;
		}

		if (!dashboard.CustomEndDateMS) {
			dashboard.CustomEndDate = null;
		}

		if (!dashboard.IsMobile) {
			dashboard.IsMobile = 0;
		} else {
			if (dashboard.IsMobile == true) {
				dashboard.IsMobile = 1;
			} else {
				dashboard.IsMobile = 0;
			}
		}

		return (
			"API.AddOrUpdateDashboardRecord @Id=" +
			dashboard.Id +
			", @Name='" +
			dashboard.Name +
			"', @Description='" +
			dashboard.Description +
			"', @Ordinal=" +
			dashboard.Ordinal +
			", @CreatorUserId=" +
			this.currentUserJSONObject.Id +
			", @CustomStartDate=" +
			dashboard.CustomStartDateMS +
			", @CustomEndDate=" +
			dashboard.CustomEndDateMS +
			", @TimeScopeId=" +
			dashboard.TimeScopeId +
			", @IsMobile=" +
			dashboard.IsMobile +
			", @TimeZoneId=" +
			dashboard.TimeZoneId +
			", @UpdateIntervalId=" +
			dashboard.UpdateIntervalId +
			", @DashboardTypeId = " +
			dashboard.DashboardTypeId +
			", @tacticalDashboardTimeScopeId = " +
			dashboard.TimeScopeId
		);
	}

	deleteDashboard(id: number) {
		return "API.DeleteDashboard " + id;
	}

	deleteDashboardMobile(id: number) {
		return this.SQLAction("API.DeleteDashboard " + id);
	}

	addOrUpdateDashboardRecordMobile(dashboard: any) {
		Global.User.DebugMode && console.log("dashboard = %O", dashboard);

		if (!dashboard.Id) {
			dashboard.Id = null;
		}

		if (!dashboard.Ordinal) {
			dashboard.Ordinal = null;
		}

		if (!dashboard.TimeScopeId) {
			dashboard.TimeScopeId = null;
		}

		if (!dashboard.CustomStartDate) {
			dashboard.CustomStartDate = null;
		} else {
			dashboard.CustomStartDate = "'" + dashboard.CustomStartDate + "'";
		}

		if (!dashboard.CustomEndDate) {
			dashboard.CustomEndDate = null;
		} else {
			dashboard.CustomEndDate = "'" + dashboard.CustomEndDate + "'";
		}

		if (!dashboard.IsMobile) {
			dashboard.IsMobile = 0;
		} else {
			if (dashboard.IsMobile == true) {
				dashboard.IsMobile = 1;
			} else {
				dashboard.IsMobile = 0;
			}
		}

		return this.SQLAction(
			"AddOrUpdateDashboardRecord @Id=" +
				dashboard.Id +
				", @Name='" +
				dashboard.Name +
				"', @Description='" +
				dashboard.Description +
				"', @Ordinal=" +
				dashboard.Ordinal +
				", @CreatorUserId=" +
				this.currentUserJSONObject.Id +
				", @CustomStartDate=" +
				dashboard.CustomStartDate +
				", @CustomEndDate=" +
				dashboard.CustomEndDate +
				", @TimeScopeId=" +
				dashboard.TimeScopeId +
				", @IsMobile=" +
				dashboard.IsMobile
		);
	}

	GetDashboardsForUserMobile() {
		if (!Global.User.currentUser) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		} else {
			this.currentUserJSONObject = Global.User.currentUser;
		}

		return this.SQLAction(
			"API.GetDashboardsForUser " + this.currentUserJSONObject.Id
		);
	}

	copyDashboardMobile(id: number, userId?: number) {
		if (userId) {
			return this.SQLAction(
				"API.Dashboards_CopyDashboard " + id + ", " + userId
			);
		} else {
			return this.SQLAction("API.Dashboards_CopyDashboard " + id);
		}
	}

	copyDashboard(id: number, userId?: number) {
		if (userId) {
			return "API.Dashboards_CopyDashboard " + id + ", " + userId;
		} else {
			return "API.Dashboards_CopyDashboard " + id;
		}
	}

	PlaceTerminalGraphicsTagsIntoInventory(tags: any) {
		tags.forEach((tag: any) => {
			var site = this.cache.sitesObject[tag.SiteId.toString()];

			var signalRData: any = {
				DataType: "DB",
				PLCUTCDate: !this.dataSourceIsLocal
					? this.utilityService.GetUTCDateFromLocalDate(
							new Date(tag.LastObservationDate)
					  )
					: new Date(tag.LastObservationDate),
				ObservationUTCDate: this.dataSourceIsLocal
					? new Date(tag.LastObservationDate)
					: this.utilityService.GetUTCDateFromLocalDate(
							new Date(tag.LastObservationDate)
					  ),

				AssetId: +tag.AssetId,
				TagId: +tag.TagId,
				SiteId: +tag.SiteId,
				ObservationId: +tag.LastObservationId,
				JBTStandardObservationId: +tag.JBTStandardObservationId,

				SiteName: site ? site.Name : null,
				TagName: tag.TagName,
				GateName: tag.GateName && tag.GateName.replace(".", ""),
				Value: tag.LastObservationTextValue,
				JBTStandardObservation:
					this.cache.jbtStandardObservations.firstOrDefault(
						(s: any) => {
							return s.Id == tag.JBTStandardObservationId;
						}
					),
			};

			signalRData.PLCLocalDate =
				this.utilityService.GetLocalDateFromUTCDate(
					signalRData.PLCUTCDate
				);
			signalRData.ObservationUTCDateMS =
				signalRData.ObservationUTCDate.getTime();
			signalRData.ObservationLocalDate =
				this.utilityService.GetLocalDateFromUTCDate(
					signalRData.ObservationUTCDate
				);

			this.AttachShortTagNameToTagData(signalRData);

			//Global.User.DebugMode && console.log("Pre-Load observation to be added to inventory = %O", signalRData);
			tag.dataServiceTagReference =
				this.LoadSignalRObservationToInventory(signalRData);
		});
	}

	LoadSignalRObservationToInventory(
		incomingTagObject: ITag,
		refresh?: boolean
	) {
		//+Load the tag represented by the observation into the local inventory of tags.
		var tagThatWasInCache: ITag;
		if (incomingTagObject != null) {
			this.UpdateHighestTagChangeDate(
				incomingTagObject.DateInMilliseconds
			);

			if (incomingTagObject.Id || incomingTagObject.Name) {
				//Scan the inventory for it.
				//If we found the tag in the inventory, update it in our cache
				if (
					incomingTagObject.PreviousObservationDateInMilliseconds &&
					incomingTagObject.DateInMilliseconds !=
						incomingTagObject.PreviousObservationDateInMilliseconds
				) {
					incomingTagObject.UpdateCount++;
					tagThatWasInCache = incomingTagObject;

					this.MetadataCounterUpdate(incomingTagObject);

					if (incomingTagObject.Asset) {
						this.MetadataCounterUpdate(incomingTagObject.Asset);
						incomingTagObject.Asset.ParentSystem &&
							this.MetadataCounterUpdate(
								incomingTagObject.Asset.ParentSystem?.Name
							);
						incomingTagObject.Asset.Site &&
							this.MetadataCounterUpdate(
								incomingTagObject.Asset.Site?.Name
							);
					}

					if (
						incomingTagObject.DataType == "signalR" &&
						incomingTagObject.DateInMilliseconds !=
							incomingTagObject.PreviousObservationDateInMilliseconds
					) {
						if (
							incomingTagObject.TagName ==
							"zzzzzzzzSNA|SNA|A|1|A2.|.GPU|.PM_INPUT.PHASEB_V"
						) {
							Global.User.DebugMode &&
								console.log(
									"-----------------------------------------------------------------------------------------------------------"
								);
							Global.User.DebugMode &&
								console.log(
									incomingTagObject.TagName +
										" Tag is from signalR and is historical. Tag from signalR = %O",
									Object.assign({}, incomingTagObject)
								);
						}

						tagThatWasInCache.Metadata.Status.LastValueWasHistorical =
							true;
					}
				} else {
					// +We did not find the tag in the inventory.
					// Add the tag to the cache with an attached metadata object

					if (
						incomingTagObject.TagName ==
						"zzzzzzzzSNA|SNA|A|1|A2.|.GPU|.PM_INPUT.PHASEB_V"
					) {
						Global.User.DebugMode &&
							console.log(
								incomingTagObject.TagName +
									" Tag NOT found in inventory. newObservation = %O",
								Object.assign({}, incomingTagObject)
							);
					}

					var isTag = true;

					this.MetadataCounterUpdate(incomingTagObject, isTag);

					if (incomingTagObject.Asset) {
						this.MetadataCounterUpdate(incomingTagObject.Asset);
						incomingTagObject.Asset.ParentSystem &&
							this.MetadataCounterUpdate(
								incomingTagObject.Asset.ParentSystem?.Name
							);
						incomingTagObject.Asset.Site &&
							this.MetadataCounterUpdate(
								incomingTagObject.Asset.Site?.Name
							);
					}

					incomingTagObject.UpdateCount = 1;

					if (!this.cache.tagsObject[incomingTagObject.Id]) {
						this.cache.tagsObject[incomingTagObject.Id] =
							incomingTagObject;
						this.cache.tags.push(incomingTagObject); //-- this updates the tags collection, but doesn't do anything with the tagsObject.  --Kirk T. Sherer, February 3, 2022.
						var doesTagExistNow =
							this.cache.tagsObject[incomingTagObject.Id] !=
							undefined;
						// if (!doesTagExistNow) {
						// 	console.log("tag still doesn't exist in data cache. incomingTagObject = %O", incomingTagObject);
						// }
						// else {
						// 	console.log("tag exists now. this.cache.tagsObject[" + incomingTagObject.Id + "] = %O", this.cache.tagsObject[incomingTagObject.Id]);
						// }
					}
					// else {
					// 	console.log("tag exists. this.cache.tagsObject[" + incomingTagObject.Id + "] = %O", this.cache.tagsObject[incomingTagObject.Id]);
					// }
					// else {
					// 	console.log("tag exists. this.cache.tagsObject[" + incomingTagObject.Id + "] = %O", this.cache.tagsObject[incomingTagObject.Id]);
					// }
				}
			}

			if (incomingTagObject.DataType == "signalR") {
				this.signalRCore.broadcast(
					"dataService.TagUpdate",
					incomingTagObject
				);
			}

			// if (this.signalRMessageCountToLog > 0) {
			// 	this.signalRMessageCountToLog--;
			// }
		}

		return incomingTagObject;
	}

	MetadataCounterUpdate(obj: any, isTag?: boolean) {
		//Global.User.DebugMode && console.log("MetadataCounterUpdate invoked...");
		if (obj && obj.Metadata && obj.Metadata.Statistics) {
			obj.Metadata.Statistics.ChangeCount++;
			obj.Metadata.Statistics.MessageCount++;

			//Check for an observation metadata object. It is the only one with the ObservationCreationDate property

			obj.Metadata.UpdateCountDowns.OneSecond =
				obj.Metadata.UpdateCountDowns.TenSecond =
				obj.Metadata.UpdateCountDowns.ThirtySecond =
				obj.Metadata.UpdateCountDowns.OneMinute =
				obj.Metadata.UpdateCountDowns.FiveMinute =
				obj.Metadata.UpdateCountDowns.FifteenMinute =
				obj.Metadata.UpdateCountDowns.ThirtyMinute =
				obj.Metadata.UpdateCountDowns.OneHour =
					10000;

			//If this is a tag obect, then place it in the countdown timers area of the operations object
			if (isTag) {
				var tagWithOneSecondCountdowns =
					this.operationMetadata.tagsWithOneSecondCountdowns.filter(
						(tag: any) => tag.TagId == obj.TagId
					);
				if (tagWithOneSecondCountdowns.count == 0) {
					this.operationMetadata.tagsWithOneSecondCountdowns.push(
						obj
					);
				}
			}
		}
	}

	//===========================================================================================================
	//+Attach a metadata object to the given input object.
	//This is used by view controllers to implement
	//color fade outs, removal after inactivity, etc. Since the dataService has to track the data, it will
	//reset these back to 10000 upon any data change anywhere.
	//10000 is used as an the update initial value so that we can get the resulution we need.
	//===========================================================================================================
	AttachBlankMetadataObject(obj: any) {
		obj.Metadata = {
			UpdateCountDowns: {
				OneSecond: 0,
				TenSecond: 0,
				ThirtySecond: 0,
				OneMinute: 0,
				FiveMinute: 0,
				FifteenMinute: 0,
				ThirtyMinute: 0,
				OneHour: 0,
			},
			Statistics: {
				ChangeCount: 0,
				MessageCount: 0,
				PreviousMessageCount: 0,
				MessagesPerSecond: 0,
				IsLastUpdateHistorical: false,
				KepwareToMainDatabaseTimeMS: 0,
				MainDatabaseToBrowserTimeMS: 0,
				KepwareToBrowserTimeMS: 0,
				KepwareSQLTimeDifferenceMSFromCentral: 0,
			},
			Status: {
				LastValueWasHistorical: false,
			},
		};
		return obj;
	}

	GetWidgetTypes() {
		return "API.WidgetTypesWithTabGroups";
	}

	GetSitesWithGSE() {
		return "API.GSEGetSitesWithGSE";
	}

	GetGSEReadingsInfoBySiteId(siteId: number) {
		return "API.GSEGetGSEReadingsInfoBySiteId " + siteId;
	}

	GetGSESummaryByGSEId(id: number) {
		return "API.GSEGetGSESummaryByGSEId " + id;
	}

	GetSummaryByAssetId(id: number) {
		return "API.GetSummaryByAssetId " + id;
	}

	ProcessTagsToGraph(dashboard: any, rawTagsToGraph: any) {
		//$timeout(function() {
		var tagsToGraphObjects = [];
		var keys = Object.keys(rawTagsToGraph);

		keys.forEach(function (key) {
			var jbtStandardObservationName = this.cache
				.jbtStandardObservationsObject[
				this.cache.tagsObject[key].JBTStandardObservationId
			]
				? this.cache.jbtStandardObservationsObject[
						this.cache.tagsObject[key].JBTStandardObservationId
				  ].Name
				: "";

			tagsToGraphObjects.push({
				TagId: +key,
				JBTStandardObservationName: jbtStandardObservationName,
				Enabled: rawTagsToGraph[key],
			});
		});

		//Call the function that the dashboard provided with the collection of tags to add to the possible new widget
		//Global.User.DebugMode && console.log("vm in vm.ProcessTagsToGraph = %O", vm);

		var dashboardtagsToGraph: any = tagsToGraphObjects
			.concat(dashboardtagsToGraph)
			.toArray()
			.filter(
				(thing: any, i: any, arr: any) =>
					arr.findIndex((t) => t.Id === thing.Id) === i
			)
			.where((t: any) => {
				return t && t.Enabled;
			});

		dashboard.tagsToGraph = dashboardtagsToGraph.toArray();

		if (dashboard.tagsToGraph.length > 0) {
			//Global.User.DebugMode && console.log("Tags to Graph = %O", angular.copy(vm.dashboard.tagsToGraph));
			this.signalRCore.broadcast(
				"Dashboard.TagsToGraph",
				dashboard.tagsToGraph
			);
		} else {
			this.signalRCore.broadcast("Dashboard.TagsToGraph", null);
		}

		Global.User.DebugMode &&
			console.log("Tags to Graph Objects = %O", dashboard.tagsToGraph);

		return;
	}

	//+GetCachedCollectionAsArray
	// Return the cacehd collection named as an array from the object reference in cache.xxxxxx.
	// Optional filter objects in the form of { propertyName: "xxx", searchTerm: "xxxxx"} in an array can be attached as a second parameter.
	// If you are already familiar with LINQ for Javascript, it is easier to just do a where clause.
	GetCachedCollectionAsArray(
		cachedCollectionName: string,
		optionalFilterObjects: any
	) {
		var collectionObjectName = cachedCollectionName + "Object";

		var collection = Object.keys(this.cache[collectionObjectName])
			.select((key) => {
				return this.cache[collectionObjectName][key];
			})
			.toArray();

		if (!optionalFilterObjects) {
			return collection;
		}

		optionalFilterObjects.forEach((filterObject) => {
			collection = collection
				.where((item) => {
					return (
						item[filterObject.propertyName] ==
						filterObject.searchTerm
					);
				})
				.toArray();
		});

		return collection;
	}

	IsReady() {
		return this.cache.ready;
	}

	GetCache() {
		return this.cache;
	}

	CachedDataUpdate(type: string, entity: any, collectionName: string) {
		var cacheCollection = this.cache[collectionName];
		var cacheCollectionObject = this.cache[collectionName + "Object"];
		var cacheDataObject =
			entity.Id && cacheCollectionObject[entity.Id.toString()];
		Global.User.DebugMode &&
			console.log(
				"CachedDataUpdate " + type + " " + collectionName + " %O",
				entity
			);

		switch (type) {
			case "Added":
				if (!cacheDataObject) {
					if (cacheCollection) {
						if (entity) {
							cacheCollection.push(entity);
							cacheCollectionObject[entity.Id.toString()] =
								entity;
						}
					}
				}
				break;

			case "Deleted":
				if (cacheDataObject) {
					this.cache[collectionName] = this.cache[collectionName]
						.where((o) => o.Id != entity.Id)
						.toArray();
					cacheCollectionObject[entity.Id.toString()] = null;
				}
				break;

			case "Modified":
				if (cacheDataObject) {
					for (var property in cacheDataObject) {
						if (cacheDataObject.hasOwnProperty(property)) {
							cacheDataObject[property] = entity[property];
						}
					}
				}
				break;
		}

		this.signalRCore.broadcast(
			"dataService.cache." + collectionName + " Modified",
			entity
		);
	}

	GetBrokenOutFieldsFromStringTagData(data: any) {
		try {
			let returnedData = data.map((tstring: any) => {
				return this.ParseDStringIntoDiscreetFields(tstring);
			});

			return returnedData;
		} catch (e) {
			return this.ParseDStringIntoDiscreetFields(data); //-- data didn't have a .map as a function, so just pass data to the same function. --Kirk T. Sherer, May 9, 2023.
		}
	}

	ParseDStringIntoDiscreetFields(data: any) {
		var tarray =
			data.d !== undefined ? data.d.split("~") : data.D.split("~");

		let parsedObservation = {
			Id: +tarray[0],
			Name: tarray[1],
			SiteId: +tarray[2],
			DateInMilliseconds:
				tarray[4] == null || tarray[4] == "" ? null : +tarray[4],
			AssetId: +tarray[5],
			LastObservationId:
				tarray[6] == null || tarray[6] == "" ? null : +tarray[6],
			JBTStandardObservationId: +tarray[7],
			LastObservationTextValue:
				tarray[8] == null ||
				tarray[8] == "" ||
				tarray[8] == "null" ||
				tarray[8] == "NaN"
					? null
					: tarray[8],
			LastObservationQuality:
				tarray[9] == null || tarray[9] == "" ? null : +tarray[9],
			IsAlarm: +tarray[10] == 1,
			IsWarning: +tarray[11] == 1,
			ValueWhenActive:
				tarray[12] == null || tarray[12] == "" ? "1" : tarray[12],
			GateName: tarray[13],
			AssetName: tarray[14],
			IsInformational: +tarray[15] == 1,
			IsCritical: +tarray[16] == 1,
			DataType: "DB",
			Severity: this.convertSeverityToVocationalStandard(tarray[18]),
			ValueTranslation:
				tarray[19] == null || tarray[19] == "" ? null : tarray[19],
			IsAlert: +tarray[20] == 1,
			EffectiveSeverityLevelId:
				tarray[21] == null || tarray[21] == "" ? 4 : +tarray[21],
			RedisKeyName: tarray[22] == null || tarray[22] == "" ? null : tarray[22],
			PreviousObservationDateInMilliseconds:
				tarray[23] == null || tarray[23] == "" ? null : +tarray[23],
			PreviousObservationTextValue:
				tarray[24] == null || tarray[24] == "" ? null : tarray[24],

		};

		return parsedObservation;
	}


	convertSeverityToVocationalStandard(originalSeverityLevel){
		if(Global.User.currentUser.OrganizationUsesAirportSites == false){
			var severityLevelName = "";
			switch(originalSeverityLevel) {
				case 'Alarm':
					severityLevelName = "Amber";
					break;
				case 'Critical':
					severityLevelName = "Red";
					break;
				default:
					severityLevelName = originalSeverityLevel;
					break;
			}
			return severityLevelName;
		}else {
			return originalSeverityLevel;
		}
	}

	AddItemToFavorites(itemObjectToAdd) {
		if (itemObjectToAdd.itemName === "TagId") {
			let sqlStatement =
				"API.UserFavoriteAddFavorite @CreatorUserId = " +
				Global.User.currentUser.Id +
				", @Type = " +
				"'" +
				itemObjectToAdd.itemName +
				"' , @TagId = " +
				itemObjectToAdd.itemId;
			//Global.User.DebugMode && console.log("data.service: sqlStatement = " + sqlStatement);
			this.SQLActionAsPromise(sqlStatement).then((data: any) => {
				Global.User.currentUser.UserFavorites.push(data[0]);
				//need to add item to local favorites, so return the recod that was added, not the entire set
				if (
					this.cache.tagsObject[itemObjectToAdd.itemId] !== undefined
				) {
					this.cache.tagsObject[itemObjectToAdd.itemId].Favorite =
						true;
				}
				let index = this.cache.tags.findIndex((tag) => {
					return tag.TagId === itemObjectToAdd.itemId;
				});
				if (index !== -1) {
					this.cache.tags[index].Favorite = true;
				}
				console.log(Global.User.currentUser.UserFavorites);

				//event emitter here for components to subscribe to
				this.favoriteChange$.next({
					favorite: data[0],
					status: "added",
					type: "TagId",
				});
			});
		}
	}

	RemoveItemFromFavorites(favoriteToRemove) {
		if (favoriteToRemove.TypeToRemove === "TagId") {
			let sqlStatement =
				"API.UserFavoriteRemoveFavorite @CreatorUserId = " +
				Global.User.currentUser.Id +
				", @FavoriteToRemoveId = " +
				favoriteToRemove.FavoriteToRemove.TagId;
			this.SQLActionAsPromise(sqlStatement).then((data: any) => {
				let indexInFavorite =
					Global.User.currentUser.UserFavorites.findIndex(
						(favorite) => {
							return (
								favorite.TagId ===
								favoriteToRemove.FavoriteToRemove.TagId
							);
						}
					);
				let itemRemoved =
					Global.User.currentUser.UserFavorites[indexInFavorite];
				Global.User.currentUser.UserFavorites.splice(
					indexInFavorite,
					1
				);

				if (
					this.cache.tagsObject[
						favoriteToRemove.FavoriteToRemove.TagId
					] !== undefined
				) {
					this.cache.tagsObject[
						favoriteToRemove.FavoriteToRemove.TagId
					].Favorite = false;
				}
				let index = this.cache.tags.findIndex((tag) => {
					return (
						tag.TagId === favoriteToRemove.FavoriteToRemove.TagId
					);
				});
				if (index !== -1) {
					this.cache.tags[index].Favorite = false;
				}
				console.log(Global.User.currentUser.UserFavorites);

				this.favoriteChange$.next({
					favorite: itemRemoved,
					status: "removed",
					type: "TagId",
				});

				//event emitter here for components to subscribe to
			});
		}
	}

	public DurationInMS(dateInMilliseconds: number): number {
		return dateInMilliseconds == null
			? null
			: Date.now() - dateInMilliseconds;
	}

	public guid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				const r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	}

	GetStandardCacheTagObjectFromDatabaseFields(entityFromDatabase) {
		var existingTagRecord = this.cache.tagsObject[entityFromDatabase.Id];

		var retryCount = 0;
		var successful = false;
		while (!successful && retryCount < 5) {
			try {
				var service = this;
				// var site: any = this.cache.sitesObject[entityFromDatabase.SiteId];
				var asset: IAsset =
					this.cache.assetsObject[entityFromDatabase.AssetId];

				var standardObservation: IStandardObservation =
					entityFromDatabase.JBTStandardObservationId != null
						? service.cache.jbtStandardObservationsObject[
								+entityFromDatabase.JBTStandardObservationId
						  ]
						: null;

				//Global.User.DebugMode && console.log("Pre-loaded observation = %O", entityFromDatabase);
				var plcUTCDate = !entityFromDatabase.DateInMilliseconds
					? null
					: new Date(entityFromDatabase.DateInMilliseconds);
				var obsCreatedDate =
					!entityFromDatabase.LastObservationCreationDate
						? null
						: new Date(
								entityFromDatabase.LastObservationCreationDate
						  );

				var tagNameSplit = (
					entityFromDatabase.Name || entityFromDatabase.TagName
				).split("|");
				var lastOne = tagNameSplit.last().toString();
				var tagSimpleName = lastOne
					.replace(".PCA.", "")
					.replace(".GPU.", "")
					.replace(".PBB.", "")
					.replace("Airport_", "")
					.replace(".", "");

				var favoritesArray = Global.User.currentUser?.UserFavorites;
				let valueCastAsNumber = Number(
					entityFromDatabase.LastObservationTextValue
				);
				let valueWhenActiveCastAsNumber = Number(
					entityFromDatabase.ValueWhenActive
				);

				var currentDateTimeInMS = new Date().getTime();

				var tagModelCacheRecord: ITag = {
					Asset: asset,
					AssetId: +entityFromDatabase.AssetId,
					DataType: "DB",
					DateInMilliseconds: entityFromDatabase.DateInMilliseconds,
					DateOfLastChangedValueInMS: entityFromDatabase.DateInMilliseconds,
					ElapsedTimeMS: this.DurationInMS(
						entityFromDatabase.DateInMilliseconds
					),
					Favorite:
						favoritesArray != null
							? favoritesArray.firstOrDefault((fav: any) => {
									return fav.TagId == entityFromDatabase.Id;
							  }) != null
								? true
								: false
							: false,
					Id: +entityFromDatabase.Id,
					IsAlarm: entityFromDatabase.IsAlarm,
					IsAlert: entityFromDatabase.IsAlert,
					IsCritical: entityFromDatabase.IsCritical,
					IsInformational: entityFromDatabase.IsInformational,
					IsWarning: entityFromDatabase.IsWarning,
					JBTStandardObservation: standardObservation,
					JBTStandardObservationId:
						+entityFromDatabase.JBTStandardObservationId,
					JavascriptDate:
						entityFromDatabase.DateInMilliseconds !== null
							? new Date(entityFromDatabase.DateInMilliseconds)
							: null,
					Guid: this.guid(),
					Historical: [],
					LocalUpdateDateUTCMS: Date.now(),
					Metadata: null,
					Name: entityFromDatabase.Name || entityFromDatabase.TagName,
					ObservationLocalDate:
						entityFromDatabase.DateInMilliseconds !== null
							? new Date(entityFromDatabase.DateInMilliseconds)
							: null,
					ObservationUTCDate:
						entityFromDatabase.DateInMilliseconds !== null
							? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
									entityFromDatabase.DateInMilliseconds,
									0
							  )
							: null, // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
					ObservationUTCDateMS: entityFromDatabase.DateInMilliseconds,
					PLCLocalDate: plcUTCDate,
					PLCUTCDate:
						plcUTCDate != null
							? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
									entityFromDatabase.DateInMilliseconds,
									0
							  )
							: null,
					UserDateFull:
						entityFromDatabase.DateInMilliseconds !== null
							? new Date(entityFromDatabase.DateInMilliseconds)
							: null,
					UTCDateFull:
						entityFromDatabase.DateInMilliseconds !== null
							? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
									entityFromDatabase.DateInMilliseconds,
									0
							  )
							: null,
					SiteDateFull:
						entityFromDatabase.DateInMilliseconds !== null
							? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
									entityFromDatabase.DateInMilliseconds,
									this.cache.sitesObject[
										+entityFromDatabase.SiteId
									]?.UTCTimeOffset
							  )
							: null, // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
					PreviousObservationDateInMilliseconds: existingTagRecord
						? existingTagRecord.DateInMilliseconds
						: null, //--these two fields should not come from SQL Server. It's tracking whether or not we've ever received a SignalR update in the application.
					PreviousObservationTextValue: existingTagRecord
						? existingTagRecord.Value
						: null, //--these two fields should not come from SQL Server. It's tracking whether or not we've ever received a SignalR update in the application.
					Quality: entityFromDatabase.LastObservationQuality || 192,
					RecentlyUpdated: true,
					Severity: this.convertSeverityToVocationalStandard(entityFromDatabase.Severity || "Informational"),
					EffectiveSeverityLevelId:
						entityFromDatabase.EffectiveSeverityLevelId != null
							? +entityFromDatabase.EffectiveSeverityLevelId
							: 4,
					ShortTagName:
						standardObservation != null
							? standardObservation.Name
							: tagSimpleName.replace(".", " ").replace("_", " "),
					SimpleName: entityFromDatabase.SimpleName || tagSimpleName,
					SiteId:
						entityFromDatabase.SiteId != null
							? +entityFromDatabase.SiteId
							: service.cache.assetsObject[
									entityFromDatabase.AssetId
							  ].SiteId,
					SiteName:
						entityFromDatabase.SiteId != null
							? service.cache.sitesObject[
									entityFromDatabase.SiteId
							  ]?.Name
							: service.cache.assetsObject[
									entityFromDatabase.AssetId
							  ].Site?.Name,
					TagId: +entityFromDatabase.Id,
					TagName:
						entityFromDatabase.Name || entityFromDatabase.TagName,
					TagNamePrefix: asset?.TagNamePrefix,
					SiteLocalJavascriptDate:
						entityFromDatabase.DateInMilliseconds !== null
							? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
									entityFromDatabase.DateInMilliseconds,
									this.cache.sitesObject[
										+entityFromDatabase.SiteId
									]?.UTCTimeOffset
							  )
							: null, // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
					UTCJavascriptDate:
						entityFromDatabase.DateInMilliseconds !== null
							? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
									entityFromDatabase.DateInMilliseconds,
									0
							  )
							: null, // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
					UpdateCount:
						existingTagRecord != null ? existingTagRecord++ : 0,
					Value: !_.isNaN(valueCastAsNumber)
						? valueCastAsNumber
						: entityFromDatabase.LastObservationTextValue,
					ValueTranslation: entityFromDatabase.ValueTranslation,
					ValueWhenActive: !_.isNaN(valueWhenActiveCastAsNumber)
						? valueWhenActiveCastAsNumber
						: !_.isNil(
								entityFromDatabase.valueWhenActiveCastAsNumber
						  )
						? entityFromDatabase.valueWhenActiveCastAsNumber
						: 1,
					RedisKeyName: entityFromDatabase.RedisKeyName,
					DurationInMS: function () { 
						return tagModelCacheRecord.PreviousObservationTextValue == tagModelCacheRecord.Value.toString() ? Math.abs(Date.now() - tagModelCacheRecord.DateOfLastChangedValueInMS) : Math.abs(Date.now() - tagModelCacheRecord.DateInMilliseconds)
					}
				};


				if (
					entityFromDatabase.PreviousObservationDateInMilliseconds !=
					null
				) {
					//-- this is the start of keeping the historical changes to a tag object's list of changed observations for widgets like Perfect Turn.
					let previousObservation: IObservation = {
						TagId: entityFromDatabase.Id,
						Value: entityFromDatabase.PreviousObservationTextValue,
						DateInMilliseconds:
							entityFromDatabase.PreviousObservationDateInMilliseconds,
					};
					if (tagModelCacheRecord.Historical == undefined) {
						tagModelCacheRecord.Historical = [];
					}
					tagModelCacheRecord.Historical.push(previousObservation);
				}

				if (tagModelCacheRecord.Metadata == null) {
					this.AttachBlankMetadataObject(tagModelCacheRecord);
				}

				if (entityFromDatabase.JBTStandardObservationId != null) {
					//-- we are mapped to a JBT Standard Observation Id.  Find out if it's in the list of tags for the Standard Observation's object.  If not, then add it.  Otherwise, it's already been updated with the Tag data cache.
					var stdObjTag = this.cache.jbtStandardObservationsObject[
						entityFromDatabase.JBTStandardObservationId
					]?.Tags?.firstOrDefault((tag: ITag) => {
						return tag.Id == entityFromDatabase.TagId;
					});
					if (!stdObjTag) {
						this.cache.jbtStandardObservationsObject[
							entityFromDatabase.JBTStandardObservationId
						]?.Tags?.push(tagModelCacheRecord);
					}
				}

				if (asset != null) {
					//-- we are mapped to an asset in the list of assets. Find out if we have a tag in the Asset's list of Tag records.  If not, then add it.  Otherwise, it's already been updated with the Tag data cache.
					var assetTag = asset.Tags?.firstOrDefault((tag: ITag) => {
						return tag.Id == tagModelCacheRecord.Id;
					});
					if (!assetTag) {
						asset.Tags?.push(tagModelCacheRecord);
					}
					//adding tag to ElevatorRotundaTags array on asset if it has the HasElevatorRotunda flag set to true
					if (
						asset.HasElevatorRotunda &&
						this.elevatorRotundaTagJBTStandardObservationIds.includes(
							tagModelCacheRecord.JBTStandardObservationId
						)
					) {
						var rotundaTag =
							asset.ElevatorRotundaTags?.firstOrDefault(
								(tag: ITag) => {
									return tag.Id == tagModelCacheRecord.Id;
								}
							);
						if (!rotundaTag) {
							asset.ElevatorRotundaTags?.push(
								tagModelCacheRecord
							);
						}
					}

					//adding tag to MovePreventionTags array on asset if the tag's jbtStandardObservationId is in the movePreventionTags array
					if (
						this.movePreventionTagJBTStandardObservationIds.includes(
							tagModelCacheRecord.JBTStandardObservationId
						)
					) {
						var movePreventionTag =
							asset.MovePreventionTags?.firstOrDefault(
								(tag: ITag) => {
									return tag.Id == tagModelCacheRecord.Id;
								}
							);
						if (!movePreventionTag) {
							asset.MovePreventionTags?.push(tagModelCacheRecord);
						}
					}

					//adding tag to OptionTags array on asset if the tag's name incldues the word option
					if (
						tagModelCacheRecord.JBTStandardObservation?.Name?.toLowerCase().includes(
							"option"
						)
					) {
						var optionTag = asset.OptionTags?.firstOrDefault(
							(tag: ITag) => {
								return tag.Id == tagModelCacheRecord.Id;
							}
						);
						if (!optionTag) {
							asset.OptionTags?.push(tagModelCacheRecord);
						}
					}

					//adding tag to ActiveAlarmTags array on asset if the tag's Severity is not equal to Informational and valuewhenactive condition is met.
					if (
						tagModelCacheRecord.Severity != "Informational" &&
						tagModelCacheRecord.ValueWhenActive ==
							tagModelCacheRecord.Value
					) {
						//--Value When Active is already being set to '1' if it's coming in as a null from the database.
						var isActiveAlarmOrCriticalAlarmTagRecord =
							tagModelCacheRecord.JBTStandardObservationId ==
								12324 ||
							tagModelCacheRecord.JBTStandardObservationId ==
								12349
								? true
								: false;

						if (!isActiveAlarmOrCriticalAlarmTagRecord) {
							//-- so we don't add the actual 'ALARM_ACTIVE' or 'CRITICAL_ALARM_ACTIVE' to the list of active alarm tags. --Kirk T. Sherer, April 3, 2024.
							var activeAlarmTag =
								asset.ActiveAlarmTags?.firstOrDefault(
									(tag: ITag) => {
										return tag.Id == tagModelCacheRecord.Id;
									}
								);
							if (!activeAlarmTag) {
								asset.ActiveAlarmTags?.push(
									tagModelCacheRecord
								);
								this.activeAlarmTags.push(tagModelCacheRecord);
							}
						}
					}

					//-- Adding the tag to the list of Perfect Turn tags if this asset has a system-type = 3 (gate system) and it's one of the Standard Observations in the list above.
					{
						if (asset.ParentSystem?.TypeId == 3) {
							//-- if it's not a gate system, ignore this part.
							switch (asset.Name) {
								case "PBB":
									if (
										this.perfectTurnStandardObservationIds.PBB.includes(
											tagModelCacheRecord.JBTStandardObservationId
										)
									) {
										var pbbTag =
											asset.ParentSystem.PerfectTurn.PBB?.firstOrDefault(
												(tag: ITag) => {
													return (
														tag.Id ==
														tagModelCacheRecord.Id
													);
												}
											);
										if (!pbbTag) {
											asset.ParentSystem.PerfectTurn.PBB.push(
												tagModelCacheRecord
											);
											//Global.User.DebugMode && console.log(this.serviceName + "asset.ParentSystem.PerfectTurn.PBB tags = %O", asset.ParentSystem.PerfectTurn.PBB);
										}
									}
									break;
								case "PCA":
								case "AHU":
									if (
										this.perfectTurnStandardObservationIds.PCA.includes(
											tagModelCacheRecord.JBTStandardObservationId
										)
									) {
										var pcaTag =
											asset.ParentSystem.PerfectTurn.PCA?.firstOrDefault(
												(tag: ITag) => {
													return (
														tag.Id ==
														tagModelCacheRecord.Id
													);
												}
											);
										if (!pcaTag) {
											asset.ParentSystem.PerfectTurn.PCA.push(
												tagModelCacheRecord
											);
											//Global.User.DebugMode && console.log(this.serviceName + "asset.ParentSystem.PerfectTurn.PCA tags = %O", asset.ParentSystem.PerfectTurn.PCA);
										}
									}
									break;
								case "GPU":
									if (
										this.perfectTurnStandardObservationIds.GPU.includes(
											tagModelCacheRecord.JBTStandardObservationId
										)
									) {
										var gpuTag =
											asset.ParentSystem.PerfectTurn.GPU?.firstOrDefault(
												(tag: ITag) => {
													return (
														tag.Id ==
														tagModelCacheRecord.Id
													);
												}
											);
										if (!gpuTag) {
											asset.ParentSystem.PerfectTurn.GPU.push(
												tagModelCacheRecord
											);
											//Global.User.DebugMode && console.log(this.serviceName + "asset.ParentSystem.PerfectTurn.GPU tags = %O", asset.ParentSystem.PerfectTurn.GPU);
										}
									}
									break;
							}
						}
					}
				}

				if (!tagModelCacheRecord) {
					console.log(
						"tagModelCacheRecord is null",
						entityFromDatabase
					);
				} else {
					this.updatedTagsList.push(tagModelCacheRecord);
					successful = true;
					//console.log(tagModelCacheRecord);
					return tagModelCacheRecord;
				}
			} catch (e) {
				console.error(
					"Error in creating tag object: %O",
					e
				);
				retryCount++;
				if (retryCount > 5) {

					return null;
				}
			}
		}
	}

	determineIfTagIsInActiveAlarmStatus(tag: ITag) {
		if (
			tag.Severity != "Informational" &&
			(tag.ValueWhenActive == tag.Value ||
				(tag.ValueWhenActive == null && tag.Value == "1"))
		) {
			return true;
		}
		return false;
	}

	//***G
	//++SignalR Observation Update - push messages in real-time.
	//+This function is run whenever each signalR message arrives.
	//***G
	UpdateNewObservationFromSignalR(signalRDataRaw: any) {
		if (signalRDataRaw?.object?.includes("\r\n")) {
			var arrayOfObservations = signalRDataRaw.object.split("\r\n");
			//Global.User.DebugMode && console.log(this.serviceName + "UpdateNewObservationFromSignalR: arrayOfObservations.length = " + arrayOfObservations.length + ", %O", arrayOfObservations);
			if (arrayOfObservations.length > 1) {
				arrayOfObservations.forEach((individualMessage: any) => {
					this.processSignalRObservation(individualMessage);
				});
			} else {
				var individualMessage = arrayOfObservations.first(); //-- there's only one since we just checked (above) for the array having more than one record. --Kirk T. Sherer, October 3, 2022.
				this.processSignalRObservation(individualMessage);
			}
		} else {
			this.processSignalRObservation(signalRDataRaw.object);
		}
	}

	processSignalRObservation(signalRDataRaw: any) {
		//Global.User.DebugMode && console.log(this.serviceName + "processSignalRObservation invoked. signalRDataRaw = %O", signalRDataRaw);
		this.Statistics.SignalR.MessageCount++;
		Global.SignalR.countOfObservations++;
		let signalRData = this.GetJsonFromSignalR(signalRDataRaw);
		signalRData.SignalRReceivedMS = new Date().getTime();
		signalRData.SignalRLatencyMS =
			signalRData.SignalRReceivedMS - signalRData.d;

		if (signalRData.i) {
			//Find the Tag object in cache - it SHOULD already be there via a load from the database. -- Dylan Richard, June 20, 2022.
			var tag = this.cache.tagsObject[signalRData.i];
			if (signalRData.i === 11633677) {
				console.log("system 2 liquid received");
			}
			//Add a stub object there if for any reason we do not have the tag in the cache yet.
			//we cannot know the asset id, that will have to wait for a db load.
			/*-----------------------------------------------------------------------------------------------------------------------------------
			 * NOTE: The following 'if' statement was put back in case the tag record DOES NOT exist in the data cache yet.
			 * 		 This is always a possibility, especially in the case of graphing any tags from a data summary widget,
			 * 		 because the user may have selected some tag records that are not yet in the data cache. --Kirk T. Sherer, September 20, 2022.
			 -----------------------------------------------------------------------------------------------------------------------------------*/
			if (!tag) {
				this.addTagIdToMissingTagsList(signalRData);
			} else {
				// if (signalRData.SignalRLatencyMS > 20000) {
				//     console.log("KW to CB Latency = " + signalRData.SignalRLatencyMS + "  TagName = " + tag.TagName + "  TagId = " + tag.TagId + " ObsId = " + tag.ObservationId);
				//     console.log("SignalRData = %O", signalRData);
				// }
				//-- update the existing tag with the latest datetime and value from SignalR... --Kirk T. Sherer, February 10, 2022.
				if (tag.Asset == null || tag.Asset == undefined) {
					tag.Asset = this.cache.assetsObject[tag.AssetId]; //--if this still fails, then the user doesn't have access to this asset since their org doesn't own, operate, manufacture, or maintain it, or the current asset doesn't have any of these set.--Kirk T. Sherer, March 22, 2024.
				}

				tag.TagNamePrefix = tag.Asset?.TagNamePrefix;
				let previousObservationDateInMilliseconds: number = null;
				previousObservationDateInMilliseconds = tag.DateInMilliseconds == null ? null : tag.DateInMilliseconds;

				if (previousObservationDateInMilliseconds != signalRData.d) {
					try {
						tag.PreviousObservationDateInMilliseconds = previousObservationDateInMilliseconds; //--set the previous datetime in milliseconds to what was already in the tag record.
						tag.PreviousObservationTextValue =	tag.Value != null ? tag.Value?.toString() : null;
					} 
					catch (e) {
						console.error("error with tag " + tag.Name + " previous observation. tag = %O",	tag);
					}
				}
				let pvCastAsNumber = Number(tag.Value);
				let previousObservation: IObservation = {
					TagId: tag.Id,
					Value: tag.Value != null ? !_.isNaN(pvCastAsNumber)	? pvCastAsNumber : tag.Value?.toString() : null,
					DateInMilliseconds: +tag.DateInMilliseconds,
				};

				var observation = tag.Historical?.firstOrDefault((observation: IObservation) => { return (observation.DateInMilliseconds == tag.DateInMilliseconds && observation.Value == tag.Value );	});
				
				if (!observation) {
					if (tag.Historical.length >= 500) {
						var earliestRecord = Object.keys(tag.Historical).firstOrDefault((item: any) => { return item; });
						var earliestObservation = tag.Historical[earliestRecord];
						tag.Historical = tag.Historical.where((observation: IObservation) => { 
								return (observation.DateInMilliseconds != earliestObservation.DateInMilliseconds); //-- keep the historical observations that are not equal to the earliest one.  Trying to keep this trimmed down to 500 distinct observations.
							}
						).toArray();
					}
					tag.Historical.push(previousObservation);
				}

				tag.DateInMilliseconds = signalRData.d; //-- this is the new observation date in milliseconds.
				tag.JavascriptDate = signalRData.d ? new Date(signalRData.d) : null;
				tag.ObservationLocalDate = signalRData.d ? new Date(signalRData.d) : null;
				tag.ObservationUTCDate = signalRData.d ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.d, 0) : null; // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
				tag.ObservationUTCDateMS = signalRData.d;
				tag.UTCJavascriptDate = signalRData.d ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.d, 0) : null;
				tag.PLCLocalDate = signalRData.d ? new Date(signalRData.d) : null;
				tag.PLCUTCDate = signalRData.d ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.d, 0) : null;
				tag.UserDateFull = signalRData.d ? new Date(signalRData.d) : null;
				tag.UTCDateFull = signalRData.d	? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.d, 0) : null;
				tag.SiteDateFull = signalRData.d ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.d, this.cache.sitesObject[+tag.SiteId]?.UTCTimeOffset) : null; // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
				let signalRCastAsNumber = Number(signalRData.v);
				tag.Value = !_.isNaN(signalRCastAsNumber) ? signalRCastAsNumber : signalRData.v;
				tag.RecentlyUpdated = true;
				tag.RecentlyUpdatedDate = new Date().getTime();
				tag.UpdateCount++;
				// listening to all sites in a parent system gives us sites we don't care about so only do this for our specific sites
				let site = this.cache.sitesObject[+tag.SiteId];
				if (site != undefined) {
					tag.SiteLocalJavascriptDate = signalRData.d ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(signalRData.d, this.cache.sitesObject[+tag.SiteId]?.UTCTimeOffset) : null; // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
				}

				tag.LatencyMS = signalRData.SignalRLatencyMS > 0 ? signalRData.SignalRLatencyMS	: 0;
				tag.LocalUpdateDateUTCMS = Date.now();
				var isActiveAlarmOrCriticalAlarmTagRecord =	tag.JBTStandardObservationId == 12324 || tag.JBTStandardObservationId == 12349 ? true : false;

				if (tag.Severity != "Informational" && !isActiveAlarmOrCriticalAlarmTagRecord) {
					//-- so we don't add the actual 'ALARM_ACTIVE' or 'CRITICAL_ALARM_ACTIVE' to the list of active alarm tags. --Kirk T. Sherer, April 3, 2024.
					//--Value When Active is already being set to '1' if it's coming in as a null from the database.
					if (tag.ValueWhenActive == tag.Value) {
						var activeAlarmTag = tag.Asset.ActiveAlarmTags?.firstOrDefault((tagToFind: ITag) => { return tag.Id == tagToFind.Id; });
						if (!activeAlarmTag) {
							tag.Asset && tag.Asset.ActiveAlarmTags?.push(tag);
							this.activeAlarmTags.push(tag);
						}
					} 
					else if (tag.ValueWhenActive != tag.Value) {
						this.activeAlarmTags = this.activeAlarmTags?.where((alarmTag: ITag) => { return alarmTag.Id != tag.Id; }).toArray(); //--this removes the current tag.Id from the ActiveAlarmTags list since it's no longer equal to its ValueWhenActive.
						tag.Asset.ActiveAlarmTags = this.activeAlarmTags?.where((alarmTag: ITag) => { return alarmTag.AssetId == tag.AssetId; }).toArray(); //--return all of the Asset.ActiveAlarmTags specific to this asset.
					}
				}
				tag.DateOfLastChangedValueInMS = tag.PreviousObservationTextValue != tag.Value.toString() ? tag.DateInMilliseconds : tag.DateOfLastChangedValueInMS;
				tag.DurationInMS = function () { 
					return tag.PreviousObservationTextValue == tag.Value.toString() ? Math.abs(Date.now() - tag.DateOfLastChangedValueInMS) : Math.abs(Date.now() - tag.DateInMilliseconds)
				};
				this.updatedTagsList.push(tag);
				// var duration = tag.DurationInMS();
				// console.log("Duration: " + duration + ", SignalR update to existing tag = %O", tag); 
				
				this.signalRCore.countOfSignalRObservations$.next(Global.SignalR.countOfObservations);
				//console.log("Global.SignalR.countOfObservations = " + Global.SignalR.countOfObservations);

				if (this.activeSubjects.length > 0) {
					this.activeSubjects.forEach(
						(activeSubject: ITagNamePrefixSubject) => {
							var tagHasAssetIdWeCareAbout = activeSubject.TagNamePrefix.firstOrDefault((tagNamePrefix: string) => { return (tagNamePrefix == tag.Asset.TagNamePrefix); });
							if (tagHasAssetIdWeCareAbout) {
								//Global.User.DebugMode && console.log(this.serviceName + "sending updated tag we care about to active subscribers: %O", tag);
								activeSubject.Subject$.next(tag); //-- send the updated tag to the listening widgets with active subject subscriptions.  If there are none, then this won't do anything.  --Kirk T. Sherer, July 26, 2024.
							}
						}
					);
				}
			}
		}
	}

	processNextArrivalSignalRMessage(signalRDataRaw: any) {
		//Global.User.DebugMode && console.log(this.serviceName + "processNextArrivalSignalRMessage invoked. signalRDataRaw = %O", signalRDataRaw);
		this.Statistics.SignalR.MessageCount++;

		let signalRData = this.GetJsonFromSignalR(signalRDataRaw);
		signalRData.SignalRReceivedMS = new Date().getTime();
		signalRData.SignalRLatencyMS =
			signalRData.SignalRReceivedMS - signalRData.d;

		if (signalRData.SystemId) {
			//Find the Systems object in cache
			var system = this.cache.systemsObject[signalRData.SystemId];

			if (system) {
				//-- if the user doesn't have access to the system record we have in the message, there is no need to update the Next Arrival.  Hence the reason there is no 'else' statement for this 'if' condition.

				system.NextArrival =
					signalRData.NextArrival != null
						? {
								UTC:
									signalRData.NextArrival != null
										? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
												+signalRData.NextArrival,
												0
										  )
										: null,
								Local:
									signalRData.NextArrival != null
										? new Date(signalRData.NextArrival)
										: null,
								Site:
									signalRData.NextArrival != null
										? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
												+signalRData.NextArrival,
												system.Site.UTCTimeOffset
										  )
										: null,
						  }
						: null;
			}
		}
	}

	addTagIdToMissingTagsList(signalRData: any) {
		var foundTagInMissingTagsObject = this.missingTagsObject[signalRData.i];
		var missingTagAlreadyInList =
			foundTagInMissingTagsObject && foundTagInMissingTagsObject != null
				? true
				: false;
		if (!missingTagAlreadyInList) {
			this.missingTagsObject[signalRData.i] = signalRData;
		}
	}

	GetJsonFromSignalR(signalRData: any) {
		var returnObject: any = {};

		var signalRString =
			signalRData?.object != undefined ? signalRData.object : signalRData;

		//Global.User.DebugMode && console.log("signalRString = " + signalRString);
		var stringList: any = signalRString.split("~");
		//Global.User.DebugMode && console.log("stringList = %O", stringList);
		stringList.forEach((item: string) => {
			//Global.User.DebugMode && console.log("item = " + item);
			var splitField: any = item.split("!");
			//Global.User.DebugMode && console.log("splitField = %O", splitField);
			returnObject[splitField[0]] = isNaN(+splitField[1])
				? splitField[1]
				: +splitField[1];
			//Global.User.DebugMode && console.log("returnObject[" + splitField[0] + "] = " + returnObject[splitField[0]]);
		});

		//Global.User.DebugMode && console.log("data-service: GetJsonFromSignalR returnObject = %O", returnObject);

		return returnObject;
	}

	ListenForSignalRChangesAndSetUpIntervalFunctions() {
		this.zone.runOutsideAngular(() => {
			var service = this.service;
			Global.User.DebugMode &&
				console.log(
					"ListenForSignalRChangesAndSetUpIntervalFunctions invoked..."
				);

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "AssetModel"))
				.subscribe((data: any) => {
					//Global.User.DebugMode && console.log("AssetModel change. AssetModel = %O", assetModel);

					this.cache.assetModels = data
						.concat(this.cache.assetModels)
						.toArray()
						.filter(
							(thing: any, i: any, arr: any) =>
								arr.findIndex((t) => t.Id === thing.Id) === i
						);

					var assetModel: any = data;
					assetModel.Assets = this.cache.assets.where(function (a) {
						return a.AssetModelId == assetModel.Id;
					});
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Organization Deleted"))
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"organizations"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Organization Modified"))
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"organizations"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Organization Added"))
				.subscribe((addedEntity) => {
					this.CachedDataUpdate(
						"Added",
						addedEntity,
						"organizations"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter((msg: any) => msg.code == "OrganizationSite Added")
				)
				.subscribe((addedEntity) => {
					this.CachedDataUpdate(
						"Added",
						addedEntity,
						"organizationSites"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter((msg: any) => msg.code == "OrganizationSite Deleted")
				)
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"organizationSites"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) => msg.code == "OrganizationSite Modified"
					)
				)
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"organizationSites"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) => msg.code == "OrganizationSiteSuite Added"
					)
				)
				.subscribe((addedEntity) => {
					this.CachedDataUpdate(
						"Added",
						addedEntity,
						"organizationSiteSuites"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) =>
							msg.code == "OrganizationSiteSuite Deleted"
					)
				)
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"organizationSiteSuites"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) =>
							msg.code == "OrganizationSiteSuite Modified"
					)
				)
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"organizationSiteSuites"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Module Added"))
				.subscribe((addedEntity) => {
					this.CachedDataUpdate("Added", addedEntity, "modules");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Module Deleted"))
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate("Deleted", deletedEntity, "modules");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Module Modified"))
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"modules"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "WidgetType Added"))
				.subscribe((addedEntity) => {
					this.CachedDataUpdate("Added", addedEntity, "widgetTypes");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "WidgetType Deleted"))
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"widgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "WidgetType Modified"))
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"widgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter((msg: any) => msg.code == "CanvasWidgetType Added")
				)
				.subscribe((addedEntity) => {
					this.CachedDataUpdate(
						"Added",
						addedEntity,
						"canvasWidgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter((msg: any) => msg.code == "CanvasWidgetType Deleted")
				)
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"canvasWidgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) => msg.code == "CanvasWidgetType Modified"
					)
				)
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"canvasWidgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter((msg: any) => msg.code == "ModuleWidgetType Added")
				)
				.subscribe((addedEntity) => {
					this.CachedDataUpdate(
						"Added",
						addedEntity,
						"moduleWidgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter((msg: any) => msg.code == "ModuleWidgetType Deleted")
				)
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"moduleWidgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) => msg.code == "ModuleWidgetType Modified"
					)
				)
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"moduleWidgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Suite Added"))
				.subscribe((addedEntity) => {
					this.CachedDataUpdate("Added", addedEntity, "suites");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Suite Deleted"))
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate("Deleted", deletedEntity, "suites");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Suite Modified"))
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate("Modified", modifiedEntity, "suites");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "SuiteModule Added"))
				.subscribe((addedEntity) => {
					this.CachedDataUpdate("Added", addedEntity, "suiteModules");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "SuiteModule Deleted"))
				.subscribe((deletedEntity) => {
					this.CachedDataUpdate(
						"Deleted",
						deletedEntity,
						"suiteModules"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "SuiteModule Modified"))
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"suiteModules"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "o"))
				.subscribe((data) => {
					//Global.User.DebugMode && console.log("SignalR Observation...");
					var signalRData: any = data;

					if (signalRData.code == "o") {
						// if (typeof signalRData.dataObject == "string" && signalRData.dataObject.indexOf("9090287") > 0) {
						//   Global.User.DebugMode && console.log("Broadcasting.code = Observation, DataObject = %O", signalRData.dataObject);
						// }
						this.UpdateNewObservationFromSignalR(signalRData);
					}
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "NextArrival"))
				.subscribe((data) => {
					//Global.User.DebugMode && console.log("SignalR Observation...");
					var signalRData: any = data;

					if (signalRData.code == "NextArrival") {
						// if (typeof signalRData.dataObject == "string" && signalRData.dataObject.indexOf("9090287") > 0) {
						//   Global.User.DebugMode && console.log("Broadcasting.code = Observation, DataObject = %O", signalRData.dataObject);
						// }
						this.UpdateNextArrivalInSystemsObjectFromSignalR(
							signalRData
						);
					}
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "WidgetAdded"))
				.subscribe((addedEntity) => {
					var newWidget: any = addedEntity;

					var dashboard =
						newWidget.ParentDashboardId &&
						this.cache.dashboardsObject[
							newWidget.ParentDashboardId.toString()
						];
					if (dashboard) {
						if (!dashboard.Widgets) {
							dashboard.Widgets = [];
						}

						dashboard.Widgets.push(newWidget);
					}
					this.CachedDataUpdate("Added", newWidget, "widgets");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Widget.Deleted"))
				.subscribe((deletedEntity) => {
					var deletedWidget: any = deletedEntity;
					var dashboard =
						this.cache.dashboardsObject[
							deletedWidget.ParentDashboardId.toString()
						];
					if (dashboard) {
						if (!dashboard.Widgets) {
							dashboard.Widgets = [];
						}

						dashboard.Widgets = dashboard.Widgets.where(
							(w) => w.Id != deletedWidget.Id
						);
					}
					this.CachedDataUpdate("Deleted", deletedEntity, "widgets");
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "WidgetType Modified"))
				.subscribe((modifiedEntity) => {
					this.CachedDataUpdate(
						"Modified",
						modifiedEntity,
						"widgetTypes"
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(filter((msg: any) => msg.code == "Dashboard"))
				.subscribe((newOrModifiedDashboardId) => {
					this.cache.dashboardsObject[
						newOrModifiedDashboardId.toString()
					] = null;
					this.GetExpandedDashboardById(
						+newOrModifiedDashboardId,
						true
					);
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) => msg.code == "System.signalR Disconnected"
					)
				)
				.subscribe((dataObject: any) => {
					this.dataServerConnected = false;
				});

			this.signalRCore.broadcastMessages$
				.pipe(
					filter(
						(msg: any) =>
							msg.code == "JBTStandardObservation.SQLUpdate"
					)
				)
				.subscribe((sqlJBTStandardObservation: any) => {
					Global.User.DebugMode &&
						console.log(
							"dataService: JBTStandardObservation.SQLUpdate. SQL Object = %O",
							sqlJBTStandardObservation
						);

					//Find all tags in the inventory and change the JBTSTandardObservation references.
					var matchingTags = this.cache.tags
						.where(
							(tag: any) =>
								tag.JBTStandardObservationId ==
								sqlJBTStandardObservation.Id
						)
						.toArray();

					Global.User.DebugMode &&
						console.log("Matching Tags = %O", matchingTags);

					//Update the matching tags JBTSTandardObservation data.
					matchingTags.forEach((tag: any) => {
						tag.JBTStandardObservationname =
							sqlJBTStandardObservation.Name;
						Object.keys(sqlJBTStandardObservation).forEach(
							(fieldName: any) => {
								tag.JBTStandardObservation[fieldName] =
									sqlJBTStandardObservation[fieldName];
							}
						);
					});
					Global.User.DebugMode &&
						console.log("Matching Tags updated.");
				});

			setInterval(function () {
				//Global.User.DebugMode && console.log("service.Statistics = %O", service.Statistics);
				if (service.Statistics) {
					//Global.User.DebugMode && console.log("service.Statistics exists... %O", service.Statistics);
					service.Statistics.SignalR.MessagesPerSecond =
						service.Statistics.SignalR.MessageCount -
						service.Statistics.SignalR.PreviousMessageCount;
					service.Statistics.SignalR.PreviousMessageCount =
						service.Statistics.SignalR.MessageCount;
					if (service.cache) {
						service.cache.sites.forEach(function (site) {
							service.UpdateMessagesPerSecondForEntity(site);
						});
					}
				} else {
					Global.User.DebugMode &&
						console.log(
							"service.Statistics does NOT exist... %O",
							service.Statistics
						);
				}
			}, 1000);

			setInterval(function () {
				var lastIntervalMS =
					performance.now() - service.lastIntervalCheckTime;
				if (lastIntervalMS > 600) {
					if (service.tabInFocus) {
						// Global.User.DebugMode && console.log(
						//   "Interval has been modified to 1 second - browser tab is not in focus."
						// );
					}
					service.tabInFocus = false;
				} else {
					if (!service.tabInFocus) {
						//Global.User.DebugMode && console.log("Intervals are at normal rate - browser tab is in focus.");
					}
					service.tabInFocus = true;
				}

				service.lastIntervalCheckTime = performance.now();
			}, 500);

			//***G
			//++Three Times Per Second Interval
			//+Five times per second, update the messages per second on any entity.
			//***G
			setInterval(function () {
				if (service.cache) {
					if (service.cache.tags) {
						service.cache.tags.forEach((tag) => {
							if (tag.Metadata?.UpdateCountDowns.OneSecond > 0) {
								tag.Metadata.UpdateCountDowns.OneSecond -= 3333;
								if (
									tag.Metadata?.UpdateCountDowns.OneSecond < 0
								) {
									tag.Metadata.UpdateCountDowns.OneSecond = 0;
								}
							}
						});
					}
				}
			}, 300);

			//***G
			//++One Minute Interval
			//+Issue any odata query to keep the OData source live and loaded
			//***G
			// setInterval(function () {
			// 	//This is a small collection.
			// 	//It is just a keepalive for the OData source service.
			// 	if (service.cache) {
			// 		return service.GetEntityById("Sites", 1);
			// 	}
			// }, 300000);

			Global.User.DebugMode &&
				console.log(
					"ListenForSignalRChangesAndSetUpIntervalFunctions finished setup."
				);
		});
	}

	UpdateNextArrivalInSystemsObjectFromSignalR(signalRDataRaw: any) {
		if (signalRDataRaw?.object?.includes("\r\n")) {
			var arrayOfMessages = signalRDataRaw.object.split("\r\n");
			//Global.User.DebugMode && console.log(this.serviceName + "UpdateNewObservationFromSignalR: arrayOfMessages.length = " + arrayOfMessages.length + ", %O", arrayOfMessages);
			if (arrayOfMessages.length > 1) {
				arrayOfMessages.forEach((individualMessage: any) => {
					this.processNextArrivalSignalRMessage(individualMessage);
				});
			} else {
				var individualMessage = arrayOfMessages.first(); //-- there's only one since we just checked (above) for the array having more than one record. --Kirk T. Sherer, October 3, 2022.
				this.processNextArrivalSignalRMessage(individualMessage);
			}
		} else {
			this.processNextArrivalSignalRMessage(signalRDataRaw.object);
		}
	}
	//***
	//+Decrement the metadata time window counters for the given entity.
	//***
	UpdateCountDownsForEntity(entity) {
		if (entity) {
			if (entity.Metadata.UpdateCountDowns.OneSecond > 0) {
				entity.Metadata.UpdateCountDowns.OneSecond -= 1000;
			}
			if (entity.Metadata.UpdateCountDowns.OneSecond < 0) {
				entity.Metadata.UpdateCountDowns.OneSecond = 0;
			}

			if (entity.Metadata.UpdateCountDowns.TenSecond > 0) {
				entity.Metadata.UpdateCountDowns.TenSecond -= 100;
			}
			return entity.Metadata.UpdateCountDowns.OneSecond > 0;
		}
	}

	//+Update the messages per second statistical entry for the given entity metadata.
	UpdateMessagesPerSecondForEntity(entity: any) {
		if (entity && entity.Metadata) {
			//Global.User.DebugMode && console.log("updating messages per second for entity... %O", entity.Metadata);
			entity.Metadata.Statistics.MessagesPerSecond =
				entity.Metadata.Statistics.MessageCount -
				entity.Metadata.Statistics.PreviousMessageCount;
			entity.Metadata.Statistics.PreviousMessageCount =
				entity.Metadata.Statistics.MessageCount;
		}
	}

	UpdateHighestTagChangeDate(newDateInMilliseconds?: number) {
		if (this.HighestTagChangeDateInMilliseconds < newDateInMilliseconds) {
			this.HighestTagChangeDateInMilliseconds = newDateInMilliseconds;
		}
	}

	GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
		assetId: number
	) {
		return this.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
			assetId.toString(),
			false
		);
	}

	returnSiteListAsArrayOfNames(siteList) {
		if (!_.isNil(siteList)) {
			if (typeof siteList === "string") {
				siteList = siteList.split(",").map((item) => {
					return parseInt(item);
				});
			}
		}
		let test: any;
		test = siteList.map((siteId) => {
			let site = this.cache.sites.find((site) => {
				if (parseInt(site.Id) === parseInt(siteId)) {
					return site;
				}
			});
			if (site !== undefined) {
				return site.Name;
			}
		});
		return test;
	}

	GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
		assetIdList: any,
		alarmsOnly?: boolean,
		jbtStandardObservationIds?: any
	) {
		//Global.User.DebugMode && console.log("Loading tags into inventory for list of asset ids = " + assetIdList);

		var service = this;

		var getAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds$: any =
			new Observable((subscriber) => {
				//The asset object in the dataService might have already loaded all its tags into the running inventory. If it has, we do nothing.
				// if (notLoadedAssetIds.length > 0) {

				//Global.User.DebugMode && console.log("Data Requested from GSTagsByListOfAssetIdsCondensed");
				let sqlStatement =
					"API." +
					(alarmsOnly
						? "GSAlarmTagsByListOfAssetIdsCondensed"
						: "GSTagsByListOfAssetIdsCondensed") +
					" @assetIds='" +
					assetIdList +
					"'" +
					(jbtStandardObservationIds
						? ", @jbtStandardObservationIds='" +
						  jbtStandardObservationIds +
						  "'"
						: "");
				//Global.User.DebugMode && console.log("data.service: sqlStatement = " + sqlStatement);
				this.SQLActionAsPromise(sqlStatement).then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							"API.GSTagsByListOfAssetIdsCondensed data: %O",
							data
						);
					// Global.User.DebugMode && console.log(data);

					var newData =
						service.GetBrokenOutFieldsFromStringTagData(data);
					let newFormattedCacheTagObjects: Array<ITag> = [];
					// Global.User.DebugMode && console.log("Data from GSTagsByListOfAssetIdsCondensed = %O", {
					//   ...data,
					// });
					// Global.User.DebugMode && console.log("Data Is formatted");
					// Global.User.DebugMode && console.log(
					//   "Loading tags into inventory Data Arrival = " +
					//     newData.length +
					//     " tags"
					// );
					// Global.User.DebugMode && console.log(
					//   "Inventory length prior to loading = " +
					//     service.cache.tags.length
					// );

					if (newData.length > 0) {
						newData.map((t) => {
							var tag: any = t;
							var formattedCacheTagObject =
								service.GetStandardCacheTagObjectFromDatabaseFields(
									tag
								);
							//Global.User.DebugMode && console.log(formattedCacheTagObject);
							if (formattedCacheTagObject) {
								if (
									formattedCacheTagObject &&
									!formattedCacheTagObject.TagName
								) {
									console.error(
										"Tag was not formatted correctly. Tag record = %O",
										formattedCacheTagObject
									);
								}
								var loadedTag =
									this.LoadSignalRObservationToInventory(
										formattedCacheTagObject
									);

								if (
									tag.PreviousObservationId &&
									tag.PreviousObservationId !=
										tag.ObservationId
								) {
									// Global.User.DebugMode && console.log(
									//   "broadcasting tag update for refresh = %O",
									//   tag
									// );
									service.signalRCore.broadcast(
										"dataService.TagUpdate",
										loadedTag
									);
								}
								newFormattedCacheTagObjects.push(
									formattedCacheTagObject
								);
							} else {
								console.error(
									"Tag was not formatted correctly. Tag record = %O",
									t
								);
							}
						});

						//Global.User.DebugMode && console.log("Inventory length after loading = " + cache.tags.length);

						assetIdList.split(",").forEach(function (assetId) {
							var asset: any =
								service.cache.assets.firstOrDefault(
									(a: any) => {
										return a.Id == +assetId;
									}
								);

							//Flag the asset as having all of its tags now loaded if it was not just the alarms loaded.
							if (asset && !alarmsOnly) {
								asset.AllTagsLoaded = true;
							}
							if (asset && alarmsOnly) {
								asset.AllAlarmTagsLoaded = true;
							}
						});

						subscriber.next(newFormattedCacheTagObjects);
						subscriber.complete();
					} else {
						subscriber.next(null);
						subscriber.complete();
					}
				});
				// }
			});

		return getAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds$;
	}

	GetPerfectTurnSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
		assetIdList: any,
		jbtStandardObservationIds?: any
	) {
		//Global.User.DebugMode && console.log("Loading tags into inventory for list of asset ids = " + assetIdList);

		var service = this;

		var getAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds$: any =
			new Observable((subscriber) => {
				//The asset object in the dataService might have already loaded all its tags into the running inventory. If it has, we do nothing.
				// if (notLoadedAssetIds.length > 0) {

				//Global.User.DebugMode && console.log("Data Requested from GSTagsByListOfAssetIdsCondensed");
				let sqlStatement =
					"API.PerfectTurn_TagsWithHistorical @assetIds='" +
					assetIdList +
					"', @jbtStandardObservationIds='" +
					jbtStandardObservationIds +
					"'";

				//Global.User.DebugMode && console.log("data.service: sqlStatement = " + sqlStatement);
				this.SQLActionAsPromise(sqlStatement).then((data: any) => {
					// Global.User.DebugMode && console.log("Data Arrived from GSTagsByListOfAssetIdsCondensed");
					// Global.User.DebugMode && console.log(data);

					var newData =
						service.GetBrokenOutFieldsFromStringTagData(data);
					let newFormattedCacheTagObjects: Array<ITag> = [];
					// Global.User.DebugMode && console.log("Data from GSTagsByListOfAssetIdsCondensed = %O", {
					//   ...data,
					// });
					// Global.User.DebugMode && console.log("Data Is formatted");
					// Global.User.DebugMode && console.log(
					//   "Loading tags into inventory Data Arrival = " +
					//     newData.length +
					//     " tags"
					// );
					// Global.User.DebugMode && console.log(
					//   "Inventory length prior to loading = " +
					//     service.cache.tags.length
					// );

					if (newData.length > 0) {
						newData.map((t) => {
							var tag: any = t;
							var formattedCacheTagObject =
								service.GetStandardCacheTagObjectFromDatabaseFields(
									tag
								);
							//Global.User.DebugMode && console.log(formattedCacheTagObject);
							if (
								formattedCacheTagObject &&
								!formattedCacheTagObject.TagName
							) {
								console.error(
									"Tag was not formatted correctly. Tag record = %O",
									formattedCacheTagObject
								);
							}

							//Debugging
							//formattedCacheTagObject.IsTest = true;

							var loadedTag =
								service.LoadSignalRObservationToInventory(
									formattedCacheTagObject,
									true
								);

							if (
								tag.PreviousObservationId &&
								tag.PreviousObservationId != tag.ObservationId
							) {
								// Global.User.DebugMode && console.log(
								//   "broadcasting tag update for refresh = %O",
								//   tag
								// );
								service.signalRCore.broadcast(
									"dataService.TagUpdate",
									loadedTag
								);
							}
							newFormattedCacheTagObjects.push(
								formattedCacheTagObject
							);
						});

						//Global.User.DebugMode && console.log("Inventory length after loading = " + cache.tags.length);

						assetIdList.split(",").forEach(function (assetId) {
							var asset: any =
								service.cache.assets.firstOrDefault(
									(a: any) => {
										return a.Id == +assetId;
									}
								);

							//Flag the asset as having all of its tags now loaded if it was not just the alarms loaded.
							if (asset) {
								asset.AllTagsLoaded = true;
							}
						});

						subscriber.next(newFormattedCacheTagObjects);
						subscriber.complete();
					} else {
						subscriber.next(null);
						subscriber.complete();
					}
				});
				// }
			});

		return getAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds$;
	}

	GetPerfectHookupDataStructure(siteId: number, widgetId: number) {
		var deferred$: any = new Observable((subscriber) => {
			if (this.cache.sitesObject[siteId.toString()].PerfectHookupData) {
				subscriber.next(
					this.cache.sitesObject[siteId.toString()].PerfectHookupData
				);
				subscriber.complete();
			} else {
				this.SQLActionAsPromise("API.GetTagsBySiteId " + siteId).then(
					(data: any) => {
						Global.User.DebugMode &&
							console.log(
								"SiteId" +
									siteId +
									" data for Aircraft Hookup" +
									data.length +
									" Records"
							);
						subscriber.next(data);
						subscriber.complete();
					}
				);
			}
		});

		return deferred$;
	}

	public async UpdateAnyMissingAssetOrJBTStandardObservationForTagsInDataCacheObject(
		data: any
	) {
		//-- This is a band-aid function to run for initial loading of widgets to insure we're not missing any Asset or JBTStandardObservation information on the tagsObject.
		//-- This is asynchronous, so it might take some time to come back with the data.  So if the widget is already loading the list of tag records from the tagsObject,
		//-- it won't have the updates to the tagsObject until this function is finished, if this function actually had to update anything.
		//--
		//-- I believe the LoadSignalR function earlier has been fixed to insure that not only the tags array is updated, but the tagsObject is updated as well, so
		//-- this function may not be necessary if that fixed the issue of missing Tag Names. --Kirk T. Sherer, February 3, 2022.

		var missingAssetOrJBTStandardObservationForTagsInDataCacheObject = data
			.select((d: any) => {
				var newObject = {
					tag: this.cache.tagsObject[d.T],
					id: d.T,
					missingAsset: this.cache.tagsObject[d.T].Asset == undefined,
					missingJBTStandardObservation:
						this.cache.tagsObject[d.T].JBTStandardObservation ==
						undefined,
				};
				return newObject;
			})
			.where((a: any) => {
				return (
					a.missingAsset == true ||
					a.missingJBTStandardObservation == true
				);
			})
			.select((b: any) => {
				return b.id;
			})
			.distinct()
			.toArray();

		if (
			missingAssetOrJBTStandardObservationForTagsInDataCacheObject.length >
			0
		) {
			console.log(
				"missing asset or JBT Standard Observation in tagsObject in the data cache = %O",
				missingAssetOrJBTStandardObservationForTagsInDataCacheObject
			);
			var tagIdList =
				missingAssetOrJBTStandardObservationForTagsInDataCacheObject.join(
					","
				);
			console.log("tagIdList = " + tagIdList);
			return this.SQLActionAsPromise(
				"API.DataCache_GetMissingAssetAndJBTStandardObservationForTagIdList @tagIds='" +
					tagIdList +
					"'"
			).then((missingData: any) => {
				missingData.forEach((item: any) => {
					var asset = this.cache.assetsObject[item.AssetId];
					if (asset) {
						var missingTagRecord = this.cache.tags?.firstOrDefault(
							(t) => t.AssetId == asset.Id && t.Id == item.TagId
						);
						var assetTag = asset.Tags?.firstOrDefault(
							(tag: ITag) => {
								return tag.Id == item.TagId;
							}
						);
						if (!assetTag) {
							asset.Tags?.push(missingTagRecord);
						}
					}
					this.cache.tagsObject[item.TagId].RedisKeyName = item.RedisKeyName;
					this.cache.tagsObject[item.TagId].Asset = asset;
					this.cache.tagsObject[item.TagId].JBTStandardObservation =
						this.cache.jbtStandardObservationsObject[
							item.JBTStandardObservationId
						];
					return true;
				});
			});
		} else {
			return true;
		}
	}

	updateTacticalDashboardWidgetRecord(widget) {
		return new Promise((resolve, reject) => {
			let statement =
				"API.DashboardUpdateWidgetRecordSettings " +
				"@ParentDashboardId = " +
				widget.Id +
				", @Id = " +
				widget.WidgetId;

			statement =
				statement +
				", @widgetSiteId = " +
				widget.WidgetSiteId +
				", @widgetAssetTypeId = " +
				widget.WidgetAssetTypeId +
				", @selectedTabIndex = " +
				widget.SelectedTabIndex;
			if (_.isNil(widget.SiteList)) {
				statement =
					statement + ", @widgetSiteList = " + widget.SiteList;
			} else if (!_.isNil(widget.SiteList)) {
				if (typeof widget.SiteList === "string") {
					statement =
						statement +
						", @widgetSiteList = '" +
						widget.SiteList +
						"'";
				} else {
					widget.SiteListAsString = widget.SiteList.join();
					statement =
						statement +
						", @widgetSiteList = '" +
						widget.SiteListAsString +
						"'";
				}
			}
			if (!_.isNil(widget.WidgetTerminalSystemId)) {
				statement =
					statement +
					", @widgetTerminalSystemId = " +
					widget.WidgetTerminalSystemId;
			}
			if (!_.isNil(widget.WidgetZoneSystemId)) {
				statement =
					statement +
					", @widgetZoneSystemId = " +
					widget.WidgetZoneSystemId;
			}
			if (!_.isNil(widget.WidgetGateSystemId)) {
				statement =
					statement +
					", @widgetGateSystemId = " +
					widget.WidgetGateSystemId;
			}
			if (!_.isNil(widget.WidgetAssetId)) {
				statement =
					statement +
					", @widgetAssetSystemId = " +
					widget.WidgetAssetId;
			}
			if (
				!_.isNil(widget.TimeZoneId) &&
				Global.User.isLoggedInAsDifferentUser == false
			) {
				statement =
					statement + ", @widgetTimeZoneId = " + widget.TimeZoneId;
			}
			if (!_.isNil(widget.TimeScopeId)) {
				statement =
					statement + ", @widgetTimeScopeId = " + widget.TimeScopeId;
			}
			this.SQLActionAsPromise(statement).then((data: any) => {
				let returnedWidget = data.first();

				// Force Site Time when logged in as another user
				if (Global.User.isLoggedInAsDifferentUser == true) {
					returnedWidget.TimeZoneId = 2;
				}

				resolve(data);
			});
		});
	}

	determineTagEffectiveSeverityLevel(observationIdArray) {
		let fullTagObject = this.cache.tags.find((t) =>
			observationIdArray.includes(t.JBTStandardObservationId)
		);
		if (fullTagObject) {
			if (fullTagObject.Severity === "Warning") {
				return "Warning";
			} else if (fullTagObject.Severity === "Critical" || fullTagObject.Severity === "Red") {
				return fullTagObject.Severity;
			} else if (fullTagObject.Severity === "Alarm" || fullTagObject.Severity === "Amber") {
				return fullTagObject.Severity;
			}
		} else {
			return "";
		}
	}


	GetFleets() {
		var fleets$: any = new Observable((subscriber) => {
			if (this.cache.fleets) {
				Global.User.DebugMode &&
					console.log("GetFleets from cache", this.cache.fleets);
				subscriber.next(this.cache.fleets);
				subscriber.complete();
			} else {
				let statement = "API.GetFleets";
				this.SQLActionAsPromise(statement).then((data: any) => {
					this.cache.fleets = data;
					Global.User.DebugMode &&
						console.log("GetFleets from sql", this.cache.fleets);

					subscriber.next(data);
					subscriber.complete();
				});
			}
		});
		return fleets$;
	}

	GetFleetAssets(fleetId?: any) {
		var fleetAssets$: any = new Observable((subscriber) => {
			if (this.cache.fleetAssets) {
				let assets = fleetId
					? this.cache.fleetAssets.filter(function (a) {
							return a.FleetId == fleetId;
					  })
					: this.cache.fleetAssets;

				Global.User.DebugMode &&
					console.log(this.serviceName + "GetFleetAssets from cache: %O", assets);

				subscriber.next(assets);
				subscriber.complete();
			} else {
				let statement = "API.GetFleetAssets";
				this.SQLActionAsPromise(statement).then((data: any) => {
					this.cache.fleetAssets = data;

					let assets = fleetId
						? this.cache.fleetAssets.filter(function (a) {
								return a.FleetId == fleetId;
						  })
						: this.cache.fleetAssets;

					Global.User.DebugMode &&
						console.log(this.serviceName + "GetFleetAssets from sql: %O", assets);

					subscriber.next(assets);
					subscriber.complete();
				});
			}
		});
		return fleetAssets$;
	}

	evaluateNotesForWidget(widget, type?) {
		// entityLogs: [{ Id: 1, Title: "No Note", Description: "Test", CreatedBy: "System", CreationDate: new Date(), LastModifiedBy: "System", LastModifiedDate: new Date(), EntityId: 963933, EntityLogTypeId: 1  }],

		let notesLength: number = 0;
		var notes = this.filterNotesForWidget(widget, type);
		notesLength = notes ? notes.length : 0;
		return notesLength;
	}

	filterNotesForWidget(widget, type?): any[] {
		var notes = [];
		if (type == "dialog") {
			notes = this.cache.entityLogs?.filter((note) => {
				var logOrganizationId = this.cache.people.firstOrDefault(
					(person: any) => {
						return person.UserId == note.CreatorUserId;
					}
				)?.OrganizationId;

				return (
					(Global.User.currentUser.Organization.Id ==
						logOrganizationId &&
						parseInt(note.EntityId) === parseInt(widget.Id) &&
						parseInt(note.EntityLogTypeId) === 1) ||
					(Global.User.currentUser.Organization.Id ==
						logOrganizationId &&
						note.IsPrivate == 0 &&
						parseInt(note.EntityId) === parseInt(widget.Id) &&
						parseInt(note.EntityLogTypeId) === 1)
				);
			});
		} else if (widget.AssetId) {
			notes = this.cache.entityLogs?.filter((note) => {
				var logOrganizationId = this.cache.people.firstOrDefault(
					(person: any) => {
						return person.UserId == note.CreatorUserId;
					}
				)?.OrganizationId;

				return (
					(Global.User.currentUser.Organization.Id ==
						logOrganizationId &&
						parseInt(note.EntityId) === parseInt(widget.AssetId) &&
						parseInt(note.EntityLogTypeId) === 1) ||
					(Global.User.currentUser.Organization.Id ==
						logOrganizationId &&
						note.IsPrivate == 0 &&
						parseInt(note.EntityId) === parseInt(widget.AssetId) &&
						parseInt(note.EntityLogTypeId) === 1)
				);
			});
		} else if (widget.WidgetSiteId) {
			let siteAssetIds = [];
			let siteAssets = this.cache.assets.filter((asset) => {
				return asset.SiteId === widget.WidgetSiteId;
			});
			if (siteAssets.length > 0) {
				siteAssetIds = siteAssets.map((asset) => {
					return asset.Id;
				});
			}
			if (siteAssetIds.length > 0) {
				notes = this.cache.entityLogs?.filter((note) => {
					var logOrganizationId = this.cache.people.firstOrDefault(
						(person: any) => {
							return person.UserId == note.CreatorUserId;
						}
					)?.OrganizationId;

					return (
						(Global.User.currentUser.Organization.Id ==
							logOrganizationId &&
							siteAssetIds.includes(parseInt(note.EntityId)) &&
							parseInt(note.EntityLogTypeId) === 1) ||
						(Global.User.currentUser.Organization.Id ==
							logOrganizationId &&
							note.IsPrivate == 0 &&
							siteAssetIds.includes(parseInt(note.EntityId)) &&
							parseInt(note.EntityLogTypeId) === 1)
					);
				});
			}
		} else if (widget.SiteList) {
			let siteAssetIds: any;
			let siteAssets = this.cache.assets.filter((asset) => {
				return widget.SiteList.includes(asset.SiteId);
			});
			if (siteAssets.length > 0) {
				siteAssetIds = siteAssets.map((asset) => {
					return asset.Id;
				});
			}
			if (siteAssetIds.length > 0) {
				notes = this.cache.entityLogs?.filter((note) => {
					var logOrganizationId = this.cache.people.firstOrDefault(
						(person: any) => {
							return person.UserId == note.CreatorUserId;
						}
					)?.OrganizationId;

					return (
						(Global.User.currentUser.Organization.Id ==
							logOrganizationId &&
							siteAssetIds.includes(parseInt(note.EntityId)) &&
							parseInt(note.EntityLogTypeId) === 1) ||
						(Global.User.currentUser.Organization.Id ==
							logOrganizationId &&
							note.IsPrivate == 0 &&
							siteAssetIds.includes(parseInt(note.EntityId)) &&
							parseInt(note.EntityLogTypeId) === 1)
					);
				});
			}
		} else if (widget.VocationalSettingsJSON) {
			var VocationalSettings = JSON.parse(widget.VocationalSettingsJSON);

			let fleetId = VocationalSettings.id;

			let assetId = VocationalSettings.child.id;

			if (assetId) {
				notes = this.cache.entityLogs?.filter((note) => {
					var logOrganizationId = this.cache.people.firstOrDefault(
						(person: any) => {
							return person.UserId == note.CreatorUserId;
						}
					)?.OrganizationId;

					return (
						(Global.User.currentUser.Organization.Id ==
							logOrganizationId &&
							parseInt(note.EntityId) === parseInt(assetId) &&
							parseInt(note.EntityLogTypeId) === 1) ||
						(Global.User.currentUser.Organization.Id ==
							logOrganizationId &&
							note.IsPrivate == 0 &&
							parseInt(note.EntityId) === parseInt(assetId) &&
							parseInt(note.EntityLogTypeId) === 1)
					);
				});
			} else if (fleetId) {
				//find assets with that fleetId and collect notes for those assets
				if (this.cache.fleetAssets?.length > 0) {
					let assetIdListArray = new Array();
					this.cache.fleetAssets.forEach((record) => {
						if (record.FleetId == fleetId) {
							assetIdListArray.push(Number(record.AssetId));
						}
					});
					notes = this.cache.entityLogs?.filter((note) => {
						var logOrganizationId =
							this.cache.people.firstOrDefault((person: any) => {
								return person.UserId == note.CreatorUserId;
							})?.OrganizationId;

						return (
							(Global.User.currentUser.Organization.Id ==
								logOrganizationId &&
								assetIdListArray.includes(
									parseInt(note.EntityId)
								) &&
								parseInt(note.EntityLogTypeId) === 1) ||
							(Global.User.currentUser.Organization.Id ==
								logOrganizationId &&
								note.IsPrivate == 0 &&
								assetIdListArray.includes(
									parseInt(note.EntityId)
								) &&
								parseInt(note.EntityLogTypeId) === 1)
						);
					});
				}
			}
		}
		return notes;
	}

	returnHighestSeverityLevel(assetId? :number,alarmsList? ){
		let highestAlarm: any;
		if( (assetId != undefined && this.cache.assetsObject[assetId].ActiveAlarmTags.length > 0) || (alarmsList != undefined && alarmsList.length > 0) ){
			let alarms = alarmsList ? alarmsList: this.cache.assetsObject[assetId].ActiveAlarmTags;
			highestAlarm = alarms.reduce((prev, current) => {


				if (
					(current.Severity === "Critical" || current.Severity === "Red") ||
					((current.Severity === "Alarm" || current.Severity === "Amber") &&
						(prev.Severity == "Warning" || prev.Severity == "Alert"))
				) {
					return current;
				} else {
					return prev;
				}
			})?.Severity;
		}


		return highestAlarm;
	}


	returnSeverityColor(severity) {
		if(severity === "Critical" || severity === "Red"){
			return "#BE1E2D";
		} else if(severity === "Alarm"){
			return "#FFA500";
		} else if(severity === "Amber"){
			return "#FFBF00"
		}
		else if(severity === "Warning" || severity === "Alert"){
			return "#FFD700";
		}

	}

	alertsStatus(activeAlertsData: any) {
		//Critical or Red
		if (activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 3).length > 0)
			return "Critical";
		//Alarm or Amber
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 1).length > 0
		)
			return "Alarm";
		//Warning
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 2).length > 0
		)
			return "Warning";

	}

	//jbtStandardObservationSeverityLevelsObject

	getSeverityNameById(severityId):string {
		return this.cache.jbtStandardObservationSeverityLevelsObject[severityId].Name;
	}


	determineIfFleetUser(){
		if(!Global.User.currentUser.OrganizationUsesAirportSites ) {
			return true;
		} else {
			return false;
		}
	}

}
