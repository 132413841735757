<mat-tab-group animationDuration="0ms" 	*ngIf="

widgetObject !== undefined &&
widgetObject?.WidgetSiteId
">
	<mat-tab label="Current Weather">
	  <lib-current-weather [parentWidgetObject]="widgetObject"></lib-current-weather>
	</mat-tab>
	<mat-tab label="Forecast">
	  <lib-forecast [parentWidgetObject]="widgetObject"></lib-forecast>
	</mat-tab>
	<!--<mat-tab label="UV">
	  <lib-uv [parentWidgetObject]="widgetObject"></lib-uv>
	</mat-tab>-->
  </mat-tab-group>

  <div
	*ngIf="

		widgetObject !== undefined &&
		!widgetObject?.WidgetSiteId
	"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a site from the widget settings."
	>
	</lib-centered-message>
</div>

