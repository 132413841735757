<div  style="text-align: center">
	<button *ngIf="control === 'Pause'" (click)="controlPlayback('Play')" class="btn btn-dark-gray">
		<mat-icon aria-hidden="false" aria-label="Example home icon"
			>play_arrow</mat-icon
		>
	</button>
	<button
		*ngIf="control !== 'Pause'"
		(click)="controlPlayback('Pause')"
		class="btn btn-dark-gray"
	>
		<mat-icon aria-hidden="false" aria-label="Example home icon"
			>pause</mat-icon
		>
	</button>
	<button *ngIf="sliderValue !== 1" (click)="controlPlayback('Rewind')" class="btn  btn-dark-gray">
		<mat-icon aria-hidden="false" aria-label="Example home icon"
			>fast_rewind</mat-icon
		>
	</button>
	<button [matMenuTriggerFor]="options" class="btn
btn-dark-gray" style="float: right;">				<mat-icon aria-hidden="false" aria-label="Example home icon"
		>settings</mat-icon
	>
	</button>
	<mat-menu #options="matMenu">

		<button mat-menu-item [matMenuTriggerFor]="left">Map/Table</button>
		<button mat-menu-item [matMenuTriggerFor]="right">Chart</button>





	</mat-menu>
	<mat-menu #right="matMenu">
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showTooltip" (change)="toggleChange($event, 'tooltip')">Tooltip</mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showLegend" (change)="toggleChange($event, 'legend')">Chart Legend</mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showPlotLines" (change)="toggleChange($event, 'plotLines')">Plot Lines</mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showPlotBands" (change)="toggleChange($event, 'plotBands')">Plot Bands</mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showYAxisTitles" (change)="toggleChange($event, 'yAxisTitles')">Series Titles</mat-checkbox></button>
	</mat-menu>
	<mat-menu #left="matMenu">

		<button (click)="$event.stopPropagation()" mat-menu-item>	<mat-checkbox [checked]="showMap" (change)="toggleChange($event, 'map')" style="margin-right: 10px">Map </mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showTable" (change)="toggleChange($event, 'table')">Table</mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="showMapLegend" (change)="toggleChange($event, 'mapLegend')" [disabled]="!showMap">Map Legend</mat-checkbox></button>
		<button (click)="$event.stopPropagation()" mat-menu-item><mat-checkbox [checked]="show24HrTime" (change)="toggleChange($event, 'show24HrTime')"  >24 Hr Time</mat-checkbox></button>
		</mat-menu>







</div>
<div style="text-align: center" [style.color]="global.Theme === 'light' ? 'black' : 'white'">{{ formatLabel() }}  - Live <mat-icon matTooltip="Red means you are caught up with the stream, grey means you are not and are not in play mode." aria-hidden="false" aria-label="Example home icon" [style.color]="control === 'Play' && (sliderValue === currentDataLength ) && liveStream === true ? 'red' : 'grey'" [style.fontSize.px]="15"
	>check_circle</mat-icon
></div>
<div class="white-content"></div>
<kendo-slider
	*ngIf="currentDataLength > 0"
	style="width: 100% !important"
	[min]="1"
	[max]="currentDataLength"
	[smallStep]="1"
	[(ngModel)]="sliderValue"
	(valueChange)="onSliderChange($event)"
></kendo-slider>
