import {
	Component,
	AfterViewInit,
	Input,
	EventEmitter,
	ViewChild,
	ViewChildren,
	OnInit,
	ElementRef,
} from '@angular/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { UtilityService } from '../../services/utility.service';
import { IGlobal } from '../../_models/global.model';
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';

@Component({
	selector: 'lib-gs-assets-in-use',
	templateUrl: './gs-assets-in-use.component.html',
	styleUrls: ['./gs-assets-in-use.component.scss'],
})
export class GsAssetsInUseComponent implements AfterViewInit, OnInit {
	public graphTypes: string[] = [
		'area',
		'bar',
		'column',
		'donut',
		'horizontalWaterfall',
		'line',
		'verticalArea',
		'verticalLine',
		'waterfall',
	];

	public graphType = this.graphTypes[0];

	@ViewChildren('seriesItems')
	public series: any;

	@ViewChild('chart')
	public chart: any;

	@ViewChild('childOverviewGauge')
	childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
	@Input() widgetObject: any;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	public chartDataIsReady: boolean = false;
	public global: IGlobal = Global;

	fullDataCacheSubscription: any;
	colorChangedSubscription: any;
	dashboardUpdateIntervalSubscription: any;
	dashboardTimeScopeChangedSubscription: any;
	selectedMatTabLabel: string;
	updateInterval: any;
	displayNoTimeScopeMessage: boolean = false;
	displayNoUpdateIntervalMessage: boolean = false;
	secondsAgoInterval: NodeJS.Timeout;
	lastUpdatedDate: Date;
	displayTimeAgoMessage: any;
	updateControllerInterval: NodeJS.Timeout;
	parentContainerSize: any;
	widgetTabsChartConfigurations: any;
	theme: string;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	percentCalculated: boolean = false;
	selectedTabIndex: number = 0;
	summaryArray = [];
	summaryArray1 = [];
	sparklineData = [];
	fontSize1 = 'medium';
	fontSize2 = 'small';
	cardClass1 = 'card-tile-lg';
	cardClass2 = 'card-tile-md';
	cardWideClass2 = 'card-wide-tile-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';
	headerGridTitle = 'grid-title-md';
	gridNumberTitleClass = 'grid-number-title-md';
	holeSize = 30;
	holeCenterTextClass = 'donut-center-text-md';
	donutClass = 'donut-md';
	contentHeight: any;
	public percentAssetsInUse = [];

	widgetResizedSubscription: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;
	isLoading: boolean;

	public timeScopeObject: any;
	public tab: any;
	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: any;

	public widgetTabs: any = [
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Summary',
			rendered: true,
			tooltip:
				'Summary of assets in use out of the total amount of assets within the selected scope.Click on the info to navigate to corresponding tab for details.',
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets In Use Current Time',
			rendered: false,
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed bar chart showing within the current scope settings (site, asset type) the amount of assets in use at the time this report was generated over the last 7 days. ',
			downloadableData: true,
			groupable: true,
			groupColumns: [{ field: 'ProperAssetName' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},

				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'ObservationTextValue',
					title: 'Value',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Minutes On For Day',
			rendered: false,
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed line chart showing within the current scope settings (site, asset type) the total amount of time assets were in use for each day of the trend along with the amount of times they were powered on.',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'ProperAssetName' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'medium',
				},

				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},

				{
					field: 'AssetOnCount',
					title: 'Power On Count',
					_width: 300,
				},
				{
					field: 'AssetOnDurationMinutes',
					title: 'Power On Duration Minutes',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Used/Not Used Each Day',
			tooltip:
				'Stacked area chart showing how many assets were used and not used over the timescope selected.  ',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [
				{ field: 'DateOfObservationDateObject' },
				{ field: 'used' },
			],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'GateName',
					title: 'Gate',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Proper Asset Name',
					_width: 300,
				},
				{
					field: 'used',
					title: 'Was Asset Used',
					_width: 100,
				},
				{
					field: 'AssetOnCount',
					title: 'Times Used',
					_width: 100,
				},
				{
					field: 'AssetOnDurationMinutes',
					title: 'Minutes Used',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Used Each Day by Equipment',
			graphType: this.graphTypes[2],
			tooltip:
				'Stacked column chart showing how many assets were used over the timescope selected by asset type.',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [
				{ field: 'DateOfObservationDateObject' },
				{ field: 'used' },
			],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'GateName',
					title: 'Gate',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Proper Asset Name',
					_width: 300,
				},
				{
					field: 'used',
					title: 'Was Asset Used',
					_width: 100,
				},
				{
					field: 'AssetOnCount',
					title: 'Times Used',
					_width: 100,
				},
				{
					field: 'AssetOnDurationMinutes',
					title: 'Minutes Used',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Not Used Each Day by Equipment',
			graphType: this.graphTypes[2],
			tooltip:
				'Stacked column chart showing how many assets were not used over the timescope selected by asset type.',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [
				{ field: 'DateOfObservationDateObject' },
				{ field: 'used' },
			],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'GateName',
					title: 'Gate',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Proper Asset Name',
					_width: 300,
				},
				{
					field: 'used',
					title: 'Was Asset Used',
					_width: 100,
				},
				{
					field: 'AssetOnCount',
					title: 'Times Used',
					_width: 100,
				},
				{
					field: 'AssetOnDurationMinutes',
					title: 'Minutes Used',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Used Detail',
			tooltip:
				'Bar chart showing the amount of times an asset was In Use total over the timescope selected.',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			graphType: this.graphTypes[1],
			groupColumns: [],
			tableStructure: [
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Seconds In Use',
					_width: 100,
				},
				{
					field: 'DurationInMinutes',
					title: 'Minutes In Use',
					_width: 100,
				},
				{
					field: 'StartDate',
					title: 'Start Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
				{
					field: 'EndDate',
					title: 'End Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
			],
		},
	];
	noDataForSite: boolean = false;
	totalMinutesOnForDayChart: any[];
	totalMinutesOnForDayChartDataIsReady: boolean;
	chartData: any;
	trendDirection: string;
	widgetTabConfigurationsFinishedMapping: boolean = false;
	timeoutToExpire: NodeJS.Timeout;
	WidgetTabSettings: any = [];

	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];

	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		public toastr: ToastrService,
		private utilityService: UtilityService,
		private elem: ElementRef,
		public tourService: TourService,
	) {}

	ngOnInit() {
		this.tab = this.widgetTabs[0];
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		this.buildNavigationOptionsArray();
	}

	ngAfterViewInit() {


		var bounds = this.elem.nativeElement.getBoundingClientRect();
		this.cardResize(bounds);

		if(Global.isMobile == true) {
			this.contentHeight = 'calc(100% - 20px)';
		}
		else {
			this.contentHeight = 'calc(100% - 120px)';
		}

		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
			console.log(this);
		});

		this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId;
		});

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		}

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
				if (this.widgetObject.WidgetId == data.item.WidgetId) {
					this.cardResize(data);
					this.fontResize(data);
				}
			});
		}

		this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.selectedTabIndex = 0
			}
		});

		this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
			}
		});

		this.isLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		if (this.dashboardUpdateIntervalChanged) {
			this.dashboardUpdateIntervalSubscription =
				this.dashboardUpdateIntervalChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.initialize();
					}
				});
		}
		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}
		// this.chartDataIsReady = true;

		this.tab = this.widgetTabs[0];
	}


	private ConditionalColorSummaryTiles() {
		this.percentTextColor[1] = "lightseagreen";

		this.conditionalColorPercentAmount[1] = this.summaryArray[2];


		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.forEach((r) => {

				switch (r.Operator) {
					case 'Is greater than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is less than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded == r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is not equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded != r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
				}


			});
		}

		this.percentAssetsInUse = [
			{
				kind: "assets in use",
				share: this.summaryArray[2]/100,
				color: this.percentTextColor[1],
			},
			{
				kind: "other",
				share: 1 - this.summaryArray[2]/100,
				color: "grey",
			},
		];


	}



	openConditionalShadingSettingsPopup(tileNumber) {
		let tileConditionalShadingRecord =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
			});
		if(tileConditionalShadingRecord.length == 0) {
			tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
			tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
		}

		var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
			width: '50%',
			height: '40%',
			data: tileConditionalShadingRecord,
		});


		DialogRef.afterClosed().subscribe((result) => {
			let tileConditionalShadingRecordLatest =
				this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
					return p.WidgetId == this.widgetObject.WidgetId;
				});
			this.ConditionalShadingRecord = [];
			tileConditionalShadingRecordLatest.forEach((r) => {
				this.ConditionalShadingRecord.push(r);
			});

			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
			this.ConditionalColorSummaryTiles();

		});

	}


	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.fontSize1 = 'small';
			this.fontSize2 = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.fontSize1 = 'medium';
			this.fontSize2 = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.fontSize1 = 'large';
			this.fontSize2 = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.fontSize1 = 'x-large';
			this.fontSize2 = 'large';
	  }
  }


  cardResize(data: any) {

	if (data.height < 400 || data.width < 400) {
		this.cardClass1 = 'card-tile-md';
		this.cardClass2 = 'card-tile-xs';
		this.cardWideClass2 = 'card-wide-tile-sm';

		this.gridNumberTitleClass = 'grid-number-title-md';

		this.holeSize = 55;
		this.holeCenterTextClass = 'donut-center-text-xs';
		this.donutClass = 'donut-sm';

		this.cardTileCenterTitleClass = 'grid-card-title-center-xs';

	}

	else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
		this.cardClass1 = 'card-tile-md';
		this.cardClass2 = 'card-tile-sm';
		this.cardWideClass2 = 'card-wide-tile-md';

		this.gridNumberTitleClass = 'grid-number-title-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';

		this.cardTileCenterTitleClass = 'grid-card-title-center-sm';

	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
		this.cardClass1 = 'card-tile-md';
		this.cardClass2 = 'card-tile-sm';
		this.cardWideClass2 = 'card-wide-tile-md';

		this.gridNumberTitleClass = 'grid-number-title-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';

		this.cardTileCenterTitleClass = 'grid-card-title-center-sm';

	}
	else if (data.height > 700 || data.width > 800) {
		this.cardClass1 = 'card-tile-lg';
		this.cardClass2 = 'card-tile-md';
		this.cardWideClass2 = 'card-wide-tile-lg';

		this.gridNumberTitleClass = 'grid-number-title-lg';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';

	}

	if (data.width < 400) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 400 && data.width < 600) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 600 && data.width < 800) {
		this.headerGridTitle = 'grid-title-lg';
	}
	else if (data.width > 800) {
		this.headerGridTitle = 'grid-title-lg';
	}

}

	startIntervals() {
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);

		if (this.updateInterval === undefined) {
			this.isLoading = false;
			this.displayNoUpdateIntervalMessage = true;
			return;
		} else {
			this.isLoading = true;
			this.displayNoUpdateIntervalMessage = false;
		}
		let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
		if (this.secondsAgoInterval !== undefined) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
		this.retrieveData();

		this.secondsAgoInterval = setInterval(() => {
			if (this.lastUpdatedDate !== undefined) {
				this.displayTimeAgoMessage =
					this.dashboardService.calculateTimeAgo(
						this.lastUpdatedDate
					);
				this.widgetObject.displayTimeAgoMessage =
					this.displayTimeAgoMessage;
			}
		}, 1000);
		if (this.updateControllerInterval !== undefined) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		this.updateControllerInterval = setInterval(() => {
			this.retrieveData();
		}, intervalUpdate);
	}

	destroyIntervals() {
		if (this.updateControllerInterval) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		if (this.secondsAgoInterval) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
	}

	initialize() {
		if (_.isNil(this.widgetObject.SiteList)) {
			this.isLoading = false;
			return;
		}
		let statement =
			'API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.widgetTabs.forEach((tab, index) => {
				if (this.widgetTabsChartConfigurations.length > 0) {
					let foundWidgetTabConfiguration =
						this.widgetTabsChartConfigurations.find(
							(configurationrecord) => {
								return (
									configurationrecord.WidgetTabIndex === index
								);
							}
						);
					if (foundWidgetTabConfiguration !== undefined) {
						tab.graphType = Global.tacticalDashboardGraphTypes.find(
							(graphType) =>
								graphType.id ==
								foundWidgetTabConfiguration.ChartTypeId
						);
					}
				}
			});
			this.widgetTabConfigurationsFinishedMapping = true;

			this.selectedTabIndex =
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0;
			this.selectedMatTabLabel =
				this.widgetTabs[
					this.widgetObject.SelectedTabIndex !== undefined
						? this.widgetObject.SelectedTabIndex
						: 0
				].textLabel;
			this.widgetTabs[
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0
			].tabHasBeenLoaded = true;


			this.tab = this.widgetTabs[this.selectedTabIndex];

			var bounds = this.elem.nativeElement.getBoundingClientRect();
			this.cardResize(bounds);

			this.startIntervals();


		});
	}

	showDialogModalPopupGrid(tab) {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				groupable: tab.groupable,
				groupColumns: tab.groupable === true ? tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: tab.tableStructure,
				tableData: tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	retrieveData() {
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		if (this.timeScopeObject === undefined) {
			this.displayNoTimeScopeMessage = true;
			this.isLoading = false;
		} else {
			this.displayNoTimeScopeMessage = false;
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[2].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[2].isLoading =
					true;
				this.totalMinutesOnForDayChartDataIsReady = false;
				let secondstatement =
					'API.TacticalDashboard_GS_Asset_PoweredOn_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					secondstatement =
						secondstatement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					secondstatement =
						secondstatement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				this.dataService
					.SQLActionAsPromise(secondstatement)
					.then((data: any) => {
						console.log(data);
						if (data.length === 0) {
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = data;
							this.widgetTabs[2].chartDataIsReady = true;
							this.widgetTabs[2].isLoading = false;
							this.widgetTabs[0].isLoading = false;
						}
						data.forEach((record) => {
							record.ProperAssetName =
								record.SiteName +
								' ' +
								record.GateName +
								' ' +
								record.AssetName;

							record.DateOfObservationDateObject = new Date(
								record.DateOfObservation
							);
							if (record.AssetOnDurationMinutes !== null) {
								record.AssetOnDurationMinutes = parseInt(
									record.AssetOnDurationMinutes
								);
							} else {
								record.AssetOnDurationMinutes = 0;
							}
						});
						this.widgetTabs[2].tableData = data;
						this.widgetTabs[2].chartData = groupBy(data, [
							{ field: 'ProperAssetName' },
						]) as GroupResult[];
						if (this.widgetTabs[2].chartData.length > 0) {
							this.widgetTabs[2].chartData.push({
								value: 'Toggle All Series',
							});
						}

						let sortByAssetOnDurationMinutes = data;
						sortByAssetOnDurationMinutes.sort(
							(a, b) =>
								b.AssetOnDurationMinutes -
								a.AssetOnDurationMinutes
						);
						if (sortByAssetOnDurationMinutes.length > 0) {
							this.summaryArray1[0] =
								sortByAssetOnDurationMinutes[0].AssetOnDurationMinutes; //Highest AssetOnDuration Minutes
							this.summaryArray1[1] =
								sortByAssetOnDurationMinutes[0].DateOfObservation; //Highest AssetOnDuration DateOfObservation
							this.summaryArray1[2] =
								sortByAssetOnDurationMinutes[0].ProperAssetName; //Highest AssetOnDuration Asset Name
						}
						this.widgetTabs[2].chartDataIsReady = true;
						this.widgetTabs[0].isLoading =
							this.widgetTabs[2].isLoading = false;
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[1].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading =
					true;
				let statement =
					"API.TacticalDashboard_GS_AssetStatus_PoweredOn_ForSpecificMomentInTime @accessToken = '" +
					Global.User.currentUser.ODataAccessToken +
					"', @startDateTimeMS=" +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate;
				if (!_.isNil(this.widgetObject.SiteList)) {
					statement =
						statement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement =
						statement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}

				console.log(statement);

				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						console.log(data);
						if (data.length === 0) {
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
							this.widgetTabs[1].tableData = [];
							this.widgetTabs[1].chartData = [];
							this.noDataForSite = true;
							return;
						} else {
							data.forEach((record) => {
								record.ObservationValue = parseInt(
									record.ObservationTextValue
								);
							});
							this.chartData = _.uniqBy(data, (e: any) => {
								return e.DateOfObservation;
							});

							// this.h1Number =
							// 	this.chartData[
							// 		this.chartData.length - 1
							// 	].CountOfPoweredOn;
							// this.totalAssetLength =
							// 	this.chartData[
							// 		this.chartData.length - 1
							// 	].CountOfAssets;
							// if (this.h1Number === 0) {
							// 	this.h4Percent = 0;
							// } else {
							// 	this.h4Percent = parseFloat(
							// 		(
							// 			(this.h1Number / this.totalAssetLength) *
							// 			100
							// 		).toFixed(1)
							// 	);
							// }
							console.log(this.chartData);
							this.percentCalculated = true;

							// this.trendDirection =
							// 	this.chartData.length >= 1
							// 		? 'even'
							// 		: this.h1Number >
							// 			this.chartData[this.chartData.length - 2]
							// 				.CountOfPoweredOn
							// 			? 'up'
							// 			: this.h1Number <
							// 				this.chartData[this.chartData.length - 2]
							// 					.CountOfPoweredOn
							// 				? 'down'
							// 				: this.h1Number ===
							// 					this.chartData[this.chartData.length - 2]
							// 						.CountOfPoweredOn
							// 					? 'even'
							// 					: '';
							// this.gaugeDisplayData = {
							// 	h1Number: this.h1Number,
							// 	h4Percent: this.h4Percent,
							// 	totalAssetLength: this.totalAssetLength,
							// };
							data.forEach((record) => {
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;

								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
							});
							this.widgetTabs[1].tableData = data;
							let groupedData = groupBy(data, [
								{ field: 'DateOfObservation' },
							]) as GroupResult[];
							if (groupedData.length > 0) {
								this.widgetTabs[0].tableData =
									groupedData[groupedData.length - 1].items;
							}
							this.widgetTabs[1].chartData = _.uniqBy(
								data,
								(e: any) => {
									return e.DateOfObservation;
								}
							);


							this.summaryArray[0] =
								this.chartData[
									this.chartData.length - 1
								].CountOfAssets; // Count Of Assets
							this.summaryArray[1] =
								this.chartData[
									this.chartData.length - 1
								].CountOfPoweredOn; // Count Of PoweredOn
							if (this.summaryArray[1] === 0) {
								this.summaryArray[2] = 0.0; // Percent Usage
							} else {
								let percentUsage = parseFloat(
									(
										(this.summaryArray[1] /
											this.summaryArray[0]) *
										100
									).toFixed(1)
								);
								this.summaryArray[2] = percentUsage; //Percent Usage
							}

							this.ConditionalColorSummaryTiles();


							let sortByCountOfPoweredOn = this.chartData;
							sortByCountOfPoweredOn.sort(
								(a, b) =>
									b.CountOfPoweredOn - a.CountOfPoweredOn
							);
							this.summaryArray[3] =
								sortByCountOfPoweredOn[0].CountOfPoweredOn; // Highest Count of Powered On
							this.summaryArray[4] =
								sortByCountOfPoweredOn[0].DateOfObservation; //Day with Highest Count of Powered On

							let sortbyDateDesc = this.chartData;
							sortbyDateDesc.sort(
								(a, b) =>
									new Date(b.DateOfObservation).getTime() -
									new Date(a.DateOfObservation).getTime()
							);
							this.createSparkLineChart(sortbyDateDesc); //Sparkline Data

							this.widgetTabs[1].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						}
					});
			}
			if (
				this.widgetTabs[3].tabHasBeenLoaded === true ||
				this.widgetTabs[4].tabHasBeenLoaded === true ||
				this.widgetTabs[5].tabHasBeenLoaded === true
			) {
				this.widgetTabs[3].isLoading =
					this.widgetTabs[4].isLoading =
					this.widgetTabs[5].isLoading =
						true;
				let statement3 =
					'API.TacticalDashboard_GS_Asset_PoweredOn_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					statement3 =
						statement3 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement3 =
						statement3 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}

				this.dataService
					.SQLActionAsPromise(statement3)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = data;
							this.widgetTabs[3].isLoading = false;
							this.widgetTabs[4].tableData = data;
							this.widgetTabs[4].chartData = data;
							this.widgetTabs[4].isLoading = false;
							this.widgetTabs[5].tableData = data;
							this.widgetTabs[5].chartData = data;
							this.widgetTabs[5].isLoading = false;
						} else {
							console.log(data);
							data.forEach((record) => {
								record.count = 1;
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;

								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
							});
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = [];
							this.widgetTabs[3].chartData.used = [];
							this.widgetTabs[3].chartData.notused = [];
							data.forEach((record) => {
								record.properDate = new Date(
									record.DateOfObservation
								);
								if (record.AssetOnCount !== 0) {
									record.used = true;
									this.widgetTabs[3].chartData.used.push(
										record
									);
								} else {
									record.used = false;
									this.widgetTabs[3].chartData.notused.push(
										record
									);
								}
							});

							let used = this.widgetTabs[3].chartData.used;
							let notused = this.widgetTabs[3].chartData.notused;
							this.widgetTabs[4].tableData = used;
							this.widgetTabs[5].tableData = notused;

							used = groupBy(used, [{ field: 'AssetName' }]);
							notused = groupBy(notused, [
								{ field: 'AssetName' },
							]);
							this.widgetTabs[4].chartData = used;
							this.widgetTabs[5].chartData = notused;

							this.widgetTabs[3].chartDataIsReady =
								this.widgetTabs[4].chartDataIsReady =
								this.widgetTabs[5].chartDataIsReady =
									true;
							this.widgetTabs[3].isLoading =
								this.widgetTabs[4].isLoading =
								this.widgetTabs[5].isLoading =
									false;
						}
					});
			}
			if (this.widgetTabs[6].tabHasBeenLoaded === true) {
				this.widgetTabs[6].isLoading = true;
				let statement3 =
					'API.TacticalDashboard_GS_Asset_InUse_Detail ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					statement3 =
						statement3 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement3 =
						statement3 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}

				this.dataService
					.SQLActionAsPromise(statement3)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[6].tableData = data;
							this.widgetTabs[6].chartData = data;
							this.widgetTabs[6].isLoading = false;
						} else {
							data.forEach((record) => {
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;

								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);

								record.DurationInMinutes = (
									record.DurationInSeconds / 60
								).toFixed(2);
								record.count = 1;
							});

							let assetAggregated = groupBy(data, [
								{ field: 'ProperAssetName' },
							]) as any[];
							assetAggregated.forEach((group) => {
								group.inUseLength = group.items.length;
							});

							this.widgetTabs[6].chartData = assetAggregated;
							this.widgetTabs[6].chartDataIsReady = true;
							this.widgetTabs[6].tableData = data;

							this.widgetTabs[6].isLoading = false;
						}
					});
			}

			this.lastUpdatedDate = new Date();

			this.isLoading = false;
		}
	}



	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "140px" : "160px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "In Use Current",
						action: () => { service.navigateToTab(1, "Assets In Use Current Time");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Minutes On",
						action: () => { service.navigateToTab(2, "Total Minutes On For Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Used Each Day",
						action: () => { service.navigateToTab(3, "Assets Used/Not Used Each Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Used by Equipment",
						action: () => { service.navigateToTab(4, "Assets Used Each Day by Equipment");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Not Used by Equipment",
						action: () => { service.navigateToTab(5, "Assets Not Used Each Day by Equipment");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Used Detail",
						action: () => { service.navigateToTab(6, "Assets Used Detail");  this.navigationOpened=false; }
					},

					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "In Use Current",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Minutes On",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Used Each Day",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Used by Equipment",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[4]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Not Used by Equipment",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[5]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Used Detail",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[6]);  this.navigationOpened=false; }
					// 		},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}

	private buildNavigationOptionsArrayDesktop() {
		var service = this;

		var navigationWidth = "160px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "In Use Current",
						action: () => { service.navigateToTab(1, "Assets In Use Current Time");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Minutes On",
						action: () => { service.navigateToTab(2, "Total Minutes On For Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Used Each Day",
						action: () => { service.navigateToTab(3, "Assets Used/Not Used Each Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Used by Equipment",
						action: () => { service.navigateToTab(4, "Assets Used Each Day by Equipment");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Not Used by Equipment",
						action: () => { service.navigateToTab(5, "Assets Not Used Each Day by Equipment");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Used Detail",
						action: () => { service.navigateToTab(6, "Assets Used Detail");  this.navigationOpened=false; }
					},

					{
						id: 11,
						width: navigationWidth,
						name: "Raw Data",
						children: [
							{
								id: 12,
								width: navigationWidth,
								name: "In Use Current",
								action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
							},
							{
								id: 12,
								width: navigationWidth,
								name: "Minutes On",
								action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
							},
							{
								id: 12,
								width: navigationWidth,
								name: "Used Each Day",
								action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
							},
							{
								id: 12,
								width: navigationWidth,
								name: "Used by Equipment",
								action: () => { service.showDialogModalPopupGrid(service.widgetTabs[4]);  this.navigationOpened=false; }
							},
							{
								id: 12,
								width: navigationWidth,
								name: "Not Used by Equipment",
								action: () => { service.showDialogModalPopupGrid(service.widgetTabs[5]);  this.navigationOpened=false; }
							},
							{
								id: 12,
								width: navigationWidth,
								name: "Used Detail",
								action: () => { service.showDialogModalPopupGrid(service.widgetTabs[6]);  this.navigationOpened=false; }
							},

						],


					}

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},
					{
						id: 22,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
						action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false; }
					},
					{
						id: 21,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
						action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false; }
					},
					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item:any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item:any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item:any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}


	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.CountOfPoweredOn,
					countOfAssets: a.CountOfAssets,
					dateOfObs: a.DateOfObservation,
				})),
			},
		];
	}

	toggleLegendHidden(tab, index) {
		tab.legendVisible = !tab.legendVisible;
		let foundSetting = this.WidgetTabSettings.find((widgetTabSetting) => {
			return widgetTabSetting.TabIndex === index;
		});

		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId +
			', @TabIndex = ' +
			index +
			', @IsHidden = ' +
			!tab.legendVisible;
		if (!_.isNil(foundSetting)) {
			statement = statement + ', @Id = ' + foundSetting.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
		});
	}

	animationRedraw() {
		// if (this.selectedMatTabLabel === "Map") {
		// 	this.loadCorrectTabData(true)
		// }
	}

	navigateToTab(index, textLabel)  {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);

		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}


	tabHasChanged(event) {
		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = event.tab.textLabel;
		this.selectedTabIndex = event.index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}

	onResized(event) {
		// this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		// this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
		if (this.childOverviewGauge !== undefined) {
			this.childOverviewGauge.reFlow();
		}

		// Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
		// this.newHeight = event.newRect.height;
		// this.oldHeight = event.oldHeight;
		// this.newWidth = event.newRect.width;
		// this.oldWidth = event.oldWidth;
	}

	ngOnDestroy() {
		this.destroyIntervals();
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	showChartAsPopup(tab) {
		var popupData;
		var field;
		var canIterate;
		var titleXAxis;
		var categoryField;
		if (tab.textLabel == 'Assets In Use Current Time') {
			field = 'CountOfPoweredOn';
			canIterate = false;
			titleXAxis = 'Total Assets In Use For Day';
			categoryField = 'DateOfObservationDateObject';
		}
		else if (tab.textLabel == 'Total Minutes On For Day') {
			field = 'AssetOnDurationMinutes';
			canIterate = true;
			titleXAxis = 'Total Minutes On For Day';
			categoryField = 'DateOfObservationDateObject';
		}
		else if (tab.textLabel == 'Assets Used Detail') {
			field = 'inUseLength';
			canIterate = false;
			titleXAxis = 'Used Count';
			categoryField = 'value';
		}
		if (tab.textLabel == 'Assets In Use Current Time' || tab.textLabel == 'Total Minutes On For Day' || tab.textLabel == 'Assets Used Detail')
			popupData = {
				categoryField: categoryField,
				field: field,
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: titleXAxis,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: canIterate,
			}

		if (tab.textLabel == 'Assets Used Each Day by Equipment' || tab.textLabel == 'Assets Not Used Each Day by Equipment') {
			popupData = {
				categoryField: 'DateOfObservationDateObject',
				field: 'count',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				aggregate: true,
				canIterate: true,
			}
		}
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}
	public labelContent(e: any): string {
		return e.category + ' - ' + e.value;
	}

	public toggleSeries(item: any): void {
		let i = item.series.index;

		this.series._results[i].toggleVisibility();
	}

	public toggleAllSeries(e) {
		if (e.text === 'Toggle All Series') {
			this.series._results.forEach((series, index) => {
				this.series._results[index].toggleVisibility();
			});
		}
	}

	public labelContentShort(e: any): string {
		return e.value;
	}

	initializeTour(): void {
		this.tourService.initialize([
			{
                anchorId: this.widgetObject.WidgetId + '-1',
                content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
                title: 'Settings',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-2',
                content: 'Navigate to different tabs & Widget Settings',
                title: 'Navigation',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-3',
                content: 'Click on various tiles for more information',
                title: 'Summary Info',
            },
		]);

		this.tourService.start();
	}

}
