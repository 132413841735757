import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridContextMenuModule } from '../grid-context-menu/grid-context-menu.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { PlaybackControllerModule } from '../playback-controller/playback-controller.module';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { MatTabsModule } from '@angular/material/tabs';

import { MatTableModule } from '@angular/material/table';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { MatStepperModule } from '@angular/material/stepper';
import { SassHelperModule } from '../../_helpers/sass-helper/sass-helper.module';
import { GridsterModule } from 'angular-gridster2';
import { GenericGridsterParentModule } from '../generic-gridster-parent/generic-gridster-parent.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
	declarations: [BreadcrumbsComponent],
	imports: [
		CommonModule,
		AngularResizeEventModule,
		LoadingSpinnerModule,
		FormsModule,
		MatExpansionModule,
		DatePickerModule,
		GridModule,
		GridContextMenuModule,
		DateInputsModule,
		ReactiveFormsModule,
		MatSidenavModule,
		MatButtonModule,
		MatIconModule,
		CenteredMessageModule,
		KendoGridParentModule,
		PlaybackControllerModule,
		GaugesModule,
		PipeModule,
		MatTabsModule,
		MatRadioModule,
		MatTableModule,
		SliderModule,
		MatStepperModule,
		SassHelperModule,
		GridsterModule,
		GenericGridsterParentModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatCheckboxModule

	],
	exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
