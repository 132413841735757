import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { SignalRCoreService } from 'projects/shared-lib/src/lib/services/signalr-core.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { filter } from 'rxjs/operators';
import { UtilityService } from '../../services/utility.service';
import { QuestionBase } from '../../_models/dynamic-fields/questions/question-base';
import { DateTimeQuestion } from '../../_models/dynamic-fields/questions/question-datetime';
import { TextboxQuestion } from '../../_models/dynamic-fields/questions/question-textbox';
import { UibButtonQuestion } from '../../_models/dynamic-fields/questions/question-uib-button';
import { IGlobal } from '../../_models/global.model';


@Component({
	selector: 'lib-test-observation-delay',
	templateUrl: './test-observation-delay.component.html',
	styleUrls: ['./test-observation-delay.component.scss']
})
export class TestObservationDelayComponent implements OnInit, OnDestroy {

	@HostListener('window:resize', ['$event'])

	public timeSent: number;
	public timeReceived: number;
	public totalTime: number;
	public numberOfTests: number = 50;
	public componentName: string = "test-observation-delay: ";
	public arrayOfObservations: Array<any>;

	public countOfObservations: number = 0;
	public signalRTestMessageGroups: Array<any> = [];
	public currentIndex: string;
	public global: IGlobal = Global;

	public screenHeight: number;
	public screenWidth: number;
	public dbTransmitterTableWidth: number = 770;
	public signalRContainerWidth: number = 840;
	public statisticalRow: any;
	public dataTable: any;
	public isLoading: boolean = false;
	public recordCount: number = 0;
	
	public editingForm: boolean = false;
	public editQuestions: QuestionBase<any>[];
	public editFormOptions: any;
	public loadingEditForm: boolean = false;
	private parameterListAsString: string = "";
	public editHeader: string; 
	public editSubHeader: string; 
	public currentTestTag: string; 

	constructor(private signalRCore: SignalRCoreService, public dataService: DataService, public dialogRef: MatDialogRef<TestObservationDelayComponent>, public utilityService: UtilityService) {
		this.onResize();
	}

	ngOnInit() {
		this.startTest();
	}

	ngOnDestroy() {
		this.signalRCore.leaveAdditionalGroup("TestPattern");
		Global.SignalR.testInProgress = false;
		this.signalRCore.signalRTestInProgress$.next(Global.SignalR.testInProgress); //-notifying the silent SignalR test in the SignalR service that the testing is complete. --Kirk T. Sherer, September 27, 2022. 
	}

	startTest(sql?: string) {
		this.isLoading = true;
		if (sql == undefined) {
			sql = "API.Observation_LagTime";
		}

		this.dataService.SQLActionAsPromise(sql).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + "API.Observation_LagTime data = %O", data);
			this.statisticalRow = data.last();
			Global.User.DebugMode && console.log(this.componentName + "statisticalRow = %O", this.statisticalRow);
			this.statisticalRow.CurrentDate = new Date();
			this.dataTable = data.where((d:any) => { return d.Id != this.statisticalRow.TotalCount + 1 }).toArray();
			Global.User.DebugMode && console.log(this.componentName + "dataTable = %O", this.dataTable);
			this.recordCount = this.dataTable.length;
			this.isLoading = false;
		});
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	restartTest() {
		this.startTest();
	}

	onResize(event?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	edit() {
		this.editingForm = true;
		this.loadingEditForm = true;
		this.editHeader = "Change Test Parameters";
		this.editSubHeader = "Please edit the fields listed here to change the test to your choice of the test tag and the date/times for the test.";

		this.editFormOptions = {
			submitButtonText: "Save Changes",
			saveValuesPerField: false,
			saveStoredProcedureName: "API.Observation_LagTime",
			cancelButtonText: "Cancel",
		};

		this.editQuestions = [
			new TextboxQuestion({
				//-- select the list of test tags from a stored procedure and place in a button group. 
				key: "TestTag",
				label: "Test Tag",
				title: "Choose the Tag Name that should be tested.",
				type: "text",
				value: this.statisticalRow.TestTag,
				order: 1
			}),
			new DateTimeQuestion({
				key: "FromDate",
				label: "From Date",
				title: "The From Date and Time that you want to start the test.",
				value: this.statisticalRow.FromDate,
				order: 2
			}),
			new DateTimeQuestion({
				key: "ToDate",
				label: "To Date",
				title: "The To Date and Time this test should end. You can leave this blank and the test will start from the 'From Date' and go to the current date/time.",
				value: this.statisticalRow.ToDate,
				order: 3
			})
		];

		this.loadingEditForm = false;
	}

	submitChangesAndExecuteTest(submittedValues) {
		console.log("edited issue change submitted...");
		var submittedValuesObject = JSON.parse(submittedValues);
		//console.log("submittedValuesObject = %O", submittedValuesObject);
		// console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		this.parameterListAsString = "";
		var countOfParameters = 1;
		keys.forEach((key: any) => {
			var questions = this.editQuestions;
			questions.forEach((question: any) => {
				if (key == question.key && question.controlType != "read-only") {
					var value = submittedValuesObject[key];
					console.log("key: " + key + ", value: " + value);
					this.parameterListAsString += "@" + key + "=";
					if (isNaN(submittedValuesObject[key])) {
						this.parameterListAsString += "'" + this.utilityService.updateStringToSQLSyntax(value) + "'";
					} else {
						this.parameterListAsString += value;
					}
					if (countOfParameters < keys.length) {
						this.parameterListAsString += ", ";
					}
					countOfParameters++;
				}
			});
		});

		console.log("this.parameterListAsString = " + this.parameterListAsString);
	
		this.editingForm = false;
		this.isLoading = true;
		this.startTest("API.Observation_LagTime " + this.parameterListAsString);
	}
}
