import { AfterViewInit, Component } from "@angular/core";
import { DataService } from "../../services/data.service";

import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { DropdownQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-dropdown";
import { ReadOnlyQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-read-only";
import { ReadOnlyTextareaQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-read-only-textarea";
import { SliderYesNoQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-slider-yes-no";
import { TextareaQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-textarea";
import { GridSettings } from "../../_models/grid-settings.interface";
import { CompositeFilterDescriptor, process, State } from "@progress/kendo-data-query";
import * as moment from "moment";
import { UibButtonQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-uib-button";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { UtilityService } from "../../services/utility.service";
import swal from "sweetalert2";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FileUploadListComponent } from "projects/shared-lib/src/lib/components/file-upload-list/file-upload-list.component";
import { GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { FormControl, FormGroup } from "@angular/forms";
import { TextboxQuestion } from "../../_models/dynamic-fields/questions/question-textbox";

@Component({
	selector: "lib-customer-issues-item",
	templateUrl: "./customer-issues-item.component.html",
	styleUrls: ["./customer-issues-item.component.scss"]
})
export class CustomerIssuesItemComponent implements AfterViewInit {
	selectedIssue: any;
	isLoadingEditScreen: boolean = true;
	currentlyEditingIssueId: number;
	editingIssue: boolean = true;
	executingIssue: boolean;
	displayIssue: boolean;
	loadingEditForm: boolean = true;
	addingNewIssue: boolean;
	editFieldsAndValues: any[];
	subjects: any;
	editHeader: string;
	editSubHeader: string;
	editFormOptions: any;
	editQuestions: (DropdownQuestion | TextareaQuestion | ReadOnlyQuestion)[];
	public global: IGlobal = Global;
	public CountOfFilesUploaded: number = 0;

	discussion: any;
	public gridView: GridDataResult;
	public discussionIssueFormGroup: FormGroup;
	public state: State = { skip: 0 };
	public currentUserIsDeveloper: boolean = false;
	private componentName: string = "customer-issues-item: ";

	public issuesGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			group: [],
			take: 15,
			sort: []
		},
		columnsConfig: [
			{
				field: "Id",
				title: "Id",
				filterable: true,
				_width: 100
			},
			{
				field: "CreationDate",
				title: "Creation Date",
				filterable: true,
				filter: "date",
				_width: 80
			},
			{
				field: "Subject",
				title: "Subject",
				filterable: true,
				_width: 80
			},
			{
				field: "CreatedBy",
				title: "Created By",
				filterable: true,
				_width: 80
			},
			{
				field: "AssignedTo",
				title: "Rep Name",
				filterable: true,
				_width: 80
			},
			{
				field: "Description",
				title: "Description",
				filterable: true,
				_width: 135
			},
			{
				field: "LastModifiedBy",
				title: "Last Modified By",
				filterable: true,
				_width: 80
			},
			{
				field: "LastModifiedDate",
				title: "Last Modified Date",
				filterable: true,
				filter: "date",
				_width: 80
			},
			{
				field: "Resolved",
				title: "Resolved",
				filterable: true,
				_width: 50
			},
			{
				field: "Actions",
				title: "Actions",
				filterable: false,
				_width: 150
			},
			{
				field: "NotifyAssignedUser",
				title: "Notify Assigned User",
				filterable: true,
				_width: 30,
				hidden: true
			},
			{
				field: "NotifyCreatorUser",
				title: "Notify Creator User",
				filterable: true,
				_width: 30,
				hidden: true
			}
		]
	};

	public discussionGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			take: 15
		},

		columnsConfig: [
			{
				field: "Date",
				title: "Date",
				filterable: true,
				filter: "date",
				_width: 30
			},
			{
				field: "Person",
				title: "Person",
				filterable: true,
				_width: 30
			},
			{
				field: "Comments",
				title: "Comments",
				filterable: true,
				_width: 250,
				encoded: false
			}
		]
	};
	AdminDevelopmentTeam: any;
	fileImageData: any;
	FileImageLibraryControlObject: {
		imageKeys: any;
		//---B
		removeUploadFunction(deletedImageKey: string): void;
		//---B
		addUploadFunction(newImageKey: string): void;
		mode: any;
		listTitle: string;
		entityId: any;
		entityType: string;
		closeUploadWindowFunction(): void;
	};
	swalWithBootstrapButtons: any;
	parameterListAsString: string;
	skip: number = 0;
	issueData: any;
	public issueHeader: any;

	constructor(
		public dataService: DataService,

		private signalRCore: SignalRCoreService,
		private utilityService: UtilityService,
		public dialogRef: MatDialogRef<CustomerIssuesItemComponent>,
		public dialog: MatDialog
	) {}

	ngAfterViewInit(): void {
		this.discussionIssueFormGroup = new FormGroup({
			response: new FormControl()
		});
		this.selectedIssue = this.dataService.cache.userIssues.find((ui) => {
			return ui.Id == this.dataService.currentSelectedIssueId;
		});
		if (Global.User.isAdmin) {
			this.dataService.SQLActionAsPromise("API.GetListOfDevelopers").then((data: any) => {
				this.AdminDevelopmentTeam = data;
				Global.User.DebugMode && console.log(this.componentName + "this.AdminDevelopmentTeam = %O", this.AdminDevelopmentTeam);
				this.currentUserIsDeveloper =
					data.firstOrDefault((u: any) => {
						return u.Id == Global.User.currentUser.Id;
					}) != null
						? true
						: false;
				this.edit();
				console.log(this.selectedIssue);
			});
		} else {
			this.edit();

		}
	}
	edit(issueIds?: any) {
		let issueId: any = this.dataService.currentSelectedIssueId === "newIssue" ? this.dataService.currentSelectedIssueId : parseInt(this.dataService.currentSelectedIssueId);
		var service = this;
		service.isLoadingEditScreen = true;

		service.currentlyEditingIssueId = +this.dataService.currentSelectedIssueId;
		service.executingIssue = false;
		service.displayIssue = false;

		service.loadingEditForm = true;
		service.addingNewIssue = false;

		service.editFieldsAndValues = [];

		var subjectOptionsList = [];
		this.subjects = this.dataService.cache.userIssueSubjects;

		service.subjects.forEach((subject: any) => {
			var obj: any = {};
			obj.key = subject.Id;
			obj.value = subject.Name;
			subjectOptionsList.push(obj);
		});

		if (issueId == "newIssue") {
			service.editHeader = "Add New Issue";
			service.editSubHeader = "Please edit the fields listed here for your new issue. To attach an image to the issue, please create the issue here first then visit the issue in the issue list to attach an image.";
			Global.User.DebugMode && console.log(service.componentName + "adding new issue...");
			service.selectedIssue = null;
			service.addingNewIssue = true;

			service.editFormOptions = {
				submitButtonText: "Submit Issue",
				saveValuesPerField: false,
				saveStoredProcedureName: "API.User_SubmitIssue ",
				cancelButtonText: "Cancel",
				signalRGroupToNotify: "User Issues",
				signalRNotifyCode: "Issue Updated"
			};
			var questions = [
				new DropdownQuestion({
					key: "subject",
					label: "Subject",
					title: "Select the correct Subject where this issue is occurring.",
					options: subjectOptionsList,
					order: 1
				}),
				new TextareaQuestion({
					key: "Description",
					label: "Description",
					title: "Please enter a detailed description of the Issue.",
					order: 2,
					resize: "vertical"
				})
			];

			if (this.currentUserIsDeveloper) {
				questions.push(
					new TextboxQuestion({
						key: "createdBy",
						label: "Created By",
						title: "The person who is logging the issue. This may be the current user, but this selector allows you to choose the correct person that first reported the issue.",
						type: "generic-selector",
						setName: "iOPSUsers",
						required: true,
						validateObject: true,
						allowBlankValue: false,
						order: 3,
						value: Global.User.currentUser.Id
					})
				);
			} else {
				questions.push(
					new ReadOnlyQuestion({
						key: "createdBy",
						label: "Created By",
						value: Global.User.currentUser.GivenName + " " + Global.User.currentUser.FamilyName,
						order: 3
					})
				);
			}
			service.editQuestions = questions;
			service.loadingEditForm = false;
		} else {
			service.editFormOptions = {
				saveValuesPerField: true,
				saveStoredProcedureName: "API.Issues_UpdateRecordByIdAndFieldName " + issueId + ", " + service.global.User.currentUser.Id,
				signalRGroupToNotify: "User Issues",
				signalRNotifyCode: "Issue Updated",
				dataCacheObject: service.dataService.cache.userIssuesObject[issueId]
			};

			service.editHeader = "Editing Issue #";
			service.editSubHeader = "Please edit the fields listed here for this specific issue.";
			Global.User.DebugMode && console.log(service.componentName + "editing issue...%O", issueId);

			//-- NOTE: 	This is the only stored procedure we're using to retrieve information about the issue since the UserIssue record is in the data cache. So we're
			//--		only auditing the editing of the record via this stored procedure, and it also is the one that will reset the Notify fields if the person editing
			//--		the record is the creator user or the assigned to user. --Kirk T. Sherer, August 10, 2023.
			service.dataService.SQLActionAsPromise("API.Issues_GetIssueDiscussion @IssueId=" + issueId + ", @UserId=" + Global.User.currentUser.Id).then((data: any) => {
				var discussion = data;
				discussion.forEach((d: any) => {
					d.Date = new Date(d.DateMS);
					//d.Comments = this.stringToHTML(d.Comments); //--this.stringToHTML(d.Comments);
				});
				service.discussion = discussion;
				service.discussionGridSettings.gridData = process(discussion, service.discussionGridSettings.state);
				Global.User.DebugMode && console.log(service.componentName + "this.discussionGridSettings.gridData = %O", service.discussionGridSettings.gridData);
				//-- now build out the read-only fields so they will also show up on the edit form. --Kirk T. Sherer, April 8, 2020.
				service.selectedIssue = service.dataService.cache.userIssuesObject[issueId];

				var questions = [
					new ReadOnlyQuestion({
						key: "Id",
						label: "Issue Id #",
						value: service.dataService.cache.userIssuesObject[issueId].Id,
						order: 1
					}),
					new ReadOnlyQuestion({
						key: "Subject",
						label: "Subject",
						value: service.dataService.cache.userIssuesObject[issueId].Subject,
						order: 2
					}),
					new ReadOnlyTextareaQuestion({
						key: "Description",
						label: "Description",
						value: service.dataService.cache.userIssuesObject[issueId].Description,
						resize: "vertical",
						order: 3
					}),
					new ReadOnlyQuestion({
						key: "Organization",
						label: "Organization",
						value: service.dataService.cache.userIssuesObject[issueId].Organization,
						order: 5
					}),
					new ReadOnlyQuestion({
						key: "Email",
						label: "Email",
						value: service.dataService.cache.userIssuesObject[issueId].Email,
						order: 6
					}),
					new ReadOnlyQuestion({
						key: "Phone",
						label: "Phone",
						value: service.dataService.cache.userIssuesObject[issueId].Phone,
						order: 7
					}),
					new ReadOnlyQuestion({
						key: "CreationDate",
						label: "Creation Date",
						value: moment(new Date(service.dataService.cache.userIssuesObject[issueId].CreationDateMS)).format("MM/DD/YYYY hh:mm A"),
						title: "This is when the issue was initially created.",
						order: 8
					}),
					new ReadOnlyQuestion({
						key: "LastModifiedBy",
						label: "Last Modified By",
						value: service.dataService.cache.userIssuesObject[issueId].LastModifiedBy + " - " + moment(new Date(service.dataService.cache.userIssuesObject[issueId].LastModifiedDateMS)).format("MM/DD/YYYY hh:mm A"),
						title: "This is the last person that modified this issue and the date/time they last modified the issue.",
						order: 9
					}),
					new UibButtonQuestion({
						key: "AssignedToUserId",
						label: "Assigned Representative",
						title: "Choose the team member to handle this issue.",
						order: 10,
						value: service.dataService.cache.userIssuesObject[issueId].AssignedToUserId,
						listOfValues: this.AdminDevelopmentTeam,
						visible: Global.User.isAdmin
					}),
					new ReadOnlyQuestion({
						key: "AssignedToUserId",
						label: "Assigned Representative",
						title: "The support representative that has been assigned to this issue.",
						order: 10,
						value: service.dataService.cache.userIssuesObject[issueId].AssignedTo,
						visible: !Global.User.isAdmin
					}),
					new TextareaQuestion({
						key: "Resolution",
						label: "Resolution",
						resize: "vertical",
						value: service.dataService.cache.userIssuesObject[issueId].Resolution,
						title: "Enter the resolution of this issue here.",
						allowBlankValue: true,
						order: 11
					}),
					new SliderYesNoQuestion({
						key: "Resolved",
						label: "Resolved",
						value: service.dataService.cache.userIssuesObject[issueId].Resolved,
						onChangeFunction: () => service.warningFunctionWhenReopeningAnIssue(service.dataService.cache.userIssuesObject[issueId].Resolved, issueId),
						title: "Select 'Yes' if this issue has been resolved.  Please enter the resolution if it has been resolved.",
						order: 12
					}),
					new SliderYesNoQuestion({
						key: "NotifyCreatorUser",
						label: "Notify Creator User",
						value: discussion.length === 0 ? (service.dataService.cache.userIssuesObject[issueId].CreatorUserId == Global.User.currentUser.Id ? service.dataService.cache.userIssuesObject[issueId].NofityCreatorUser == false : service.dataService.cache.userIssuesObject[issueId].NofityCreatorUser) : discussion.first().NotifyCreatorUser, //--we may have updated this field when retrieving the discussion, so just get it from that recordset rather than the data cache.
						title: "Should the original creator be notified?",
						order: 13,
						visible: Global.User.isAdmin
					}),
					new SliderYesNoQuestion({
						key: "NotifyAssignedUser",
						label: "Notify Assigned User",
						value: discussion.length === 0 ? (service.dataService.cache.userIssuesObject[issueId].AssignedToUserId == Global.User.currentUser.Id ? service.dataService.cache.userIssuesObject[issueId].NotifyAssignedUser == false : service.dataService.cache.userIssuesObject[issueId].NotifyAssignedUser) : discussion.first().NotifyAssignedUser, //--we may have updated this field when retrieving the discussion, so just get it from that recordset rather than the data cache.
						title: "Should the Assigned To person be notified?",
						order: 14,
						visible: Global.User.isAdmin
					})
				];

				if (this.currentUserIsDeveloper) {
					questions.push(
						new TextboxQuestion({
							key: "CreatorUserId",
							label: "Created By",
							title: "The person who is logging the issue. This may be the current user, but this selector allows you to choose the correct person that first reported the issue.",
							type: "generic-selector",
							setName: "iOPSUsers",
							required: true,
							validateObject: true,
							allowBlankValue: false,
							order: 4,
							value: service.dataService.cache.userIssuesObject[issueId].CreatorUserId
						})
					);
				}
				else {
					questions.push(
						new ReadOnlyQuestion({
							key: "createdBy",
							label: "Created By",
							value: service.dataService.cache.userIssuesObject[issueId].CreatedBy,
							order: 4
						})
					);
				}

				service.editQuestions = questions;
				service.setFileImageUploadSettings(service.dataService.cache.userIssuesObject[issueId]);

				service.loadingEditForm = false;
				service.isLoadingEditScreen = false;
			});
		}
	}

	setFileImageUploadSettings(issue: any) {
		var service = this;
		Global.User.DebugMode && console.log(service.componentName + ": setting up File Image Upload functions. Issue = %O", issue);
		var entityId = issue.Id;
		var entityType = "UserIssue";
		var title: string = "Issue #" + issue.Id + " Documentation";
		service.CountOfFilesUploaded = issue.CountOfFilesUploaded;

		issue.openFileImageUploadWindow = function () {
			var sqlStatement = "API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
			service.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				Global.User.DebugMode && console.log(service.componentName + sqlStatement + " = %O", data);
				service.fileImageData = data;
				service.FileImageLibraryControlObject = {
					imageKeys: service.fileImageData.filter((i: any) => {
						return i.ImageKey;
					}),
					//---B
					removeUploadFunction(deletedImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((remainingFileImageKeyList: any) => {
							Global.User.DebugMode && console.log(service.componentName + "remainingFileImageKeyList after deletion: %O", remainingFileImageKeyList);
							service.FileImageLibraryControlObject.imageKeys = remainingFileImageKeyList;
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject.imageKeys.length = " + service.FileImageLibraryControlObject.imageKeys.length);
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject = %O", service.FileImageLibraryControlObject);
							service.signalRCore.notifyOtherClientsInGroup(service.editFormOptions.signalRGroupToNotify, "Files Changed", service.FileImageLibraryControlObject);
							service.fileImageData = remainingFileImageKeyList;
						});
					},
					//---B
					addUploadFunction(newImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((updatedFileImageKeyList: any) => {
							Global.User.DebugMode && console.log(service.componentName + "updatedFileImageKeyList after new file added: %O", updatedFileImageKeyList);
							service.FileImageLibraryControlObject.imageKeys = updatedFileImageKeyList;
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject.imageKeys.length = " + service.FileImageLibraryControlObject.imageKeys.length);
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject = %O", service.FileImageLibraryControlObject);
							service.signalRCore.notifyOtherClientsInGroup(service.editFormOptions.signalRGroupToNotify, "Files Uploaded", service.FileImageLibraryControlObject);
							service.fileImageData = updatedFileImageKeyList;
						});
					},
					mode: null,
					listTitle: title,
					entityId: entityId,
					entityType: entityType,
					closeUploadWindowFunction() {
						service.FileImageLibraryControlObject = null;
					}
				};

				service.openDialog("fileUpload");
			});
		};
	}

	openDialog(type: string): void {
		var innerWidth = window.innerWidth;
		if (type == "fileUpload") {
			const dialogRef = this.dialog.open(FileUploadListComponent, {
				width: "70%",
				height: "70%"
			});

			dialogRef.componentInstance.fileImageLibraryControlObject = this.FileImageLibraryControlObject;

			dialogRef.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log(this.componentName + ": The dialog was closed");
				Global.User.DebugMode && console.log(this.componentName + ": this.FileImageLibraryControlObject.imageKeys.length = " + this.FileImageLibraryControlObject.imageKeys.length);
				this.CountOfFilesUploaded = this.FileImageLibraryControlObject.imageKeys.length;
			});
		}
	}

	warningFunctionWhenReopeningAnIssue(resolvedStatus: boolean, issueId: number) {
		var service = this;
		if (!resolvedStatus) {
			//-- the resolved status is not yet true, so this is the warning to the user that they are trying to resolve it.
			service.swalWithBootstrapButtons = swal.mixin({
				customClass: {
					confirmButton: "btn btn-danger",
					cancelButton: "btn btn-success"
				},
				buttonsStyling: false
			});
			console.log("Marking Customer Issue #" + service.dataService.cache.userIssuesObject[issueId].Id + " as resolved...");

			service.swalWithBootstrapButtons
				.fire({
					title: "Are you sure?",
					html: "You are trying to resolve the <strong>'" + service.dataService.cache.userIssuesObject[issueId].Id + "</strong>' issue. Cancel if you would rather keep it open.",
					showCancelButton: true,
					confirmButtonText: "Resolve Issue",
					cancelButtonText: "Cancel",
					reverseButtons: false
				})
				.then((result: any) => {
					if (result.value) {
						// logic for resolving issue goes here.
						service.dataService.SQLActionAsPromise("API.Issues_SetResolveField @IssueId=" + service.dataService.cache.userIssuesObject[issueId].Id + ", @UserId=" + Global.User.currentUser.Id + ", @IsResolved=1").then((data: any) => {
							console.log("data = %O", data);
							service.utilityService.showToastMessageShared({
								type: "info",
								message: "Customer Issue #" + service.dataService.cache.userIssuesObject[issueId].Id + " has been resolved.",
								title: "Customer Issues"
							});
							
							service.signalRCore.notifyOtherClientsInGroup(service.editFormOptions.signalRGroupToNotify, service.editFormOptions.signalRNotifyCode, service.dataService.cache.userIssuesObject[issueId]);
							service.dialogRef.close();
							// service.issueList(true); //-- go get list of issues again.
						});
					} else {
						service.utilityService.showToastMessageShared({
							type: "info",
							message: "Customer Issue #" + service.dataService.cache.userIssuesObject[issueId].Id + " has NOT been resolved.",
							title: "Customer Issues"
						});
					}
				});
		} else {
			//-- the resolved status is true.  User must have a reason for re-opening the issue, so just allow the update.
			var requestJSON = [
				{
					label: "Resolved",
					sqlStatement: "API.Issues_UpdateRecordByIdAndFieldName @UserIssueId=" + service.dataService.cache.userIssuesObject[issueId].Id + ", @iOPSUserId=" + Global.User.currentUser.Id + ", @FieldName='Resolution', @FieldValue=null"
				},
				{
					label: "IsResolved",
					sqlStatement: "API.Issues_SetResolveField @IssueId=" + service.dataService.cache.userIssuesObject[issueId].Id + ", @UserId=" + Global.User.currentUser.Id + ", @IsResolved=0"
				}
			];
			service.dataService.SQLMultiAction(requestJSON, Global.User.currentUser.ODataAccessToken).then((data: any) => {
				console.log("data = %O", data);
				service.utilityService.showToastMessageShared({
					type: "info",
					message: "Customer Issue #" + service.dataService.cache.userIssuesObject[issueId].Id + " has been re-opened.",
					title: "Customer Issues"
				});
				service.signalRCore.notifyOtherClientsInGroup(service.editFormOptions.signalRGroupToNotify, service.editFormOptions.signalRNotifyCode, service.dataService.cache.userIssuesObject[issueId]);
			});
		}
	}

	submitEditedIssue(submittedValues: string) {
		var service = this;
		console.log("edited issue change submitted...");
		var submittedValuesObject = JSON.parse(submittedValues);
		//console.log("submittedValuesObject = %O", submittedValuesObject);
		// console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		service.parameterListAsString = "";
		var countOfParameters = 1;
		keys.forEach((key: any) => {
			var questions = service.editQuestions;
			questions.forEach((question: any) => {
				if (key == question.key && question.controlType != "read-only") {
					var value = submittedValuesObject[key];
					console.log("key: " + key + ", value: " + value);
					switch (key) {
						case "createdBy":
							//-- since this is using a person-selector, the value coming back is a JSON object.
							//-- Get the UserId from the value object that was returned. --Kirk T. Sherer, April 22, 2021.
							var UserId = value?.UserId != null ? value.UserId : value; // -- if there is no value object, then the value is the UserId. --Kirk T. Sherer, February 23, 2024.
							service.parameterListAsString += "@UserId=" + UserId;
							break;
						case "subject":
							service.parameterListAsString += "@SubjectId=" + value;
							break;
						default:
							service.parameterListAsString += "@" + key + "=";
							if (isNaN(submittedValuesObject[key])) {
								service.parameterListAsString += "'" + service.utilityService.updateStringToSQLSyntax(value) + "'";
							} else {
								service.parameterListAsString += value;
							}
							break;
					}
					if (countOfParameters < keys.length) {
						service.parameterListAsString += ", ";
					}
					countOfParameters++;
				} else {
					if (key == "createdBy" && question.controlType == "read-only") {
						//-- the non-admin user is trying to log another issue from their list of existing issues since their 'createdBy' field
						//-- is listed as a read-only field. So we have to send their UserId as the @UserId parameter so we'll know this issue
						//-- was submitted by them. --Kirk T. Sherer, April 26, 2021.
						service.parameterListAsString += "@UserId=" + Global.User.currentUser.Id;
						if (countOfParameters < keys.length) {
							service.parameterListAsString += ", ";
						}
						countOfParameters++;
					}
				}
			});
		});

		console.log("this.parameterListAsString = " + service.parameterListAsString);
		var newParameterListAsString = (service.parameterListAsString += ", @CreatorUserId=" + Global.User.currentUser.Id);
		newParameterListAsString = newParameterListAsString.replace(", ,", ",");

		console.log("newParamterListAsString = " + newParameterListAsString);
		service.dataService.SQLActionAsPromise("API.User_SubmitIssue " + newParameterListAsString).then((data: any) => {
			console.log("data = %O", data);
			var issueId = data?.first().Id;
			service.signalRCore.notifyOtherClientsInGroup("User Issues", "Issue Updated", service.dataService.cache.userIssuesObject[issueId]);

			//--this updates the Kendo Grid Data with whatever changed in SignalR for the main User Issue structure in the data cache.
			this.dialogRef.close(); //-- return back to the issue list after inserting/updating the issue.
		});
	}

	public submitResponse(issue: any) {
		var service = this;
		console.log("this.editFormOptions.saveStoredProcedureName = " + this.editFormOptions.saveStoredProcedureName + ", issue = " + issue);
		console.log("this.discussionIssueFormGroup = %O", this.discussionIssueFormGroup);
		var newDiscussion = service.stringToHTML(this.discussionIssueFormGroup.controls.response.value);
		var discussion = newDiscussion.split("'").join("''");
		var sql = this.editFormOptions.saveStoredProcedureName + ", 'Comments', '" + discussion + "'";
		console.log("sql = " + sql);
		this.dataService.SQLActionAsPromise(sql).then((data: any) => {
			console.log("data = %O", data);
			this.discussionIssueFormGroup.controls.response.reset();
			console.log("issue = " + issue + ", data = %O", data);
			console.log("this.discussion = %O", this.discussion);
			this.getIssueDiscussion(issue, data, this.discussion);
			this.signalRCore.notifyOtherClientsInGroup("User Issues", "Issue Discussion Updated", data);
		});
	}

	stringToHTML(textString: string) {
		var parser = new DOMParser();
		var doc = parser.parseFromString(textString, "text/html");
		var text = doc.body.innerHTML;
		//console.log(this.componentName + "stringToHTML(" + textString + ") = " + text);
		var returnText = textString != undefined ? text.split("<br>").join("\n").split("<p>").join("").split("</p>").join("").split("&lt;").join("<").split("&gt;").join(">").split("<p>").join("").split("</p>").join("") : "";
		return returnText;
	}

	getIssueDiscussion(issue: any, lastDiscussion?: any, discussion?: any) {
		if (!this.discussion || (!lastDiscussion && !discussion)) {
			//-- if we don't have deicer events populated yet, or we didn't send a current deicing event, then go get the list of deicer events again from SQL Server. --Kirk T. Sherer, October 12, 2020.
			this.dataService.SQLActionAsPromise("API.Issues_GetIssueDiscussion " + issue).then((data: any) => {
				var discussion = data;
				this.discussionGridSettings.gridData = process(discussion, this.discussionGridSettings.state);
				Global.User.DebugMode && console.log(this.componentName + "this.discussionGridSettings.gridData = %O", this.discussionGridSettings.gridData);
			});
		} else {
			if (discussion && !lastDiscussion) {
				//-- we arrived at this function with the list of discussion records, but no new ones to append to the list. Just display the same list again. --Kirk T. Sherer, April 19, 2021.
				this.discussionGridSettings.gridData = process(discussion, this.discussionGridSettings.state);
				Global.User.DebugMode && console.log(this.componentName + "this.discussionGridSettings.gridData = %O", this.discussionGridSettings.gridData);
			} else {
				//-- we are arriving here with a list of discussion records, as well as a new record to add to the list. --Kirk T. Sherer, April 19, 2021.
				Global.User.DebugMode && console.log(this.componentName + "this.discussion = %O", this.discussion);
				Global.User.DebugMode && console.log(this.componentName + "lastDiscussion = %O", lastDiscussion);

				var discussion = this.discussion;
				lastDiscussion.forEach((d: any) => {
					d.Date = new Date(d.DateMS);
					d.Comments = this.stringToHTML(d.Comments);
				});
				discussion.push(lastDiscussion.first());
				var newDiscussionList = discussion
					.orderByDescending((d: any) => {
						return d.DateMS;
					})
					.toArray();
				this.discussion = newDiscussionList;
				this.discussionGridSettings.gridData = process(newDiscussionList, this.discussionGridSettings.state);
				$("#response").trigger("click"); //--should set focus back to textarea after submission of text. --Kirk T. Sherer, April 20, 2021.

				Global.User.DebugMode && console.log(this.componentName + "this.discussionGridSettings.gridData after adding new discussion record = %O", this.discussionGridSettings.gridData);
			}
		}
	}

	public filterChange(filter: CompositeFilterDescriptor): void {
		this.state.filter = filter;
		this.gridView = process(this.issueData, this.state);
	}

	public sortChange(sort: any): void {
		this.state.sort = sort;
		this.gridView = process(this.issueData, this.state);
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.state.skip = event.skip;
		this.state.take = event.take;
		this.gridView = process(this.issueData, this.state);
	}

	updateRowColor(context: RowClassArgs) {
		let rowUpdated = context.dataItem.RecentlyUpdated;
		return {
			recentlyUpdated: rowUpdated
		};
	}
}
