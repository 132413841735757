<div *ngIf="displayIssue " class="content" style="margin-top: 2px; margin-bottom: 2px">
	<div class="card">
		<div class="card-header">
			<div class="col-md-4 text-left">

			</div>
			<h4 class="card-title col-md-4 text-center">
				<strong>{{ selectedIssue.IssueName }}</strong>
			</h4>
			<div class="col-md-4 text-right">
				<button class="btn btn-icon btn-simple btn-twitter pull-right">
					<i class="tim-icons icon-minimal-left" (click)="dialogRef.close()"> </i>
				</button>
			</div>
		</div>
		<div class="card-body">
			<div class="table-responsive table-container">
				<kendo-grid [data]="gridView" [columnMenu]="true" [skip]="issuesGridSettings.state.skip" [navigable]="true" [filter]="issuesGridSettings.state.filter" [style.height.%]="100" [filterable]="true" [sort]="issuesGridSettings.state.sort" [sortable]="true" [reorderable]="true" [resizable]="true" [pageable]="{ pageSizes: true }" [pageSize]="issuesGridSettings.state.take" (filterChange)="filterChange($event)" (pageChange)="pageChange($event)" [groupable]="true" [group]="issuesGridSettings.state.group"  (sortChange)="sortChange($event)" #grid>
					<ng-template kendoGridColumnMenuTemplate let-service="service">
						<kendo-grid-columnmenu-sort [service]="service"> </kendo-grid-columnmenu-sort>
						<kendo-grid-columnmenu-lock [service]="service"> </kendo-grid-columnmenu-lock>
						<kendo-grid-columnmenu-chooser [service]="service"> </kendo-grid-columnmenu-chooser>
						<kendo-grid-columnmenu-filter [service]="service"> </kendo-grid-columnmenu-filter>
						<kendo-grid-columnmenu-item icon="k-icon k-i-filter-clear" text="Clear Filter" (itemClick)="filterChange({ logic: 'and', filters: [] }); service.close()"> </kendo-grid-columnmenu-item>
					</ng-template>

					<kendo-grid-column *ngFor="let header of issueHeader" field="{{ header.name }}" title="{{ header.name }}"></kendo-grid-column>

					<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
						<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
						<kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
						<kendo-pager-next-buttons></kendo-pager-next-buttons>
						<kendo-pager-info></kendo-pager-info>
						<kendo-pager-page-sizes [pageSizes]="[15, 30, 50]"></kendo-pager-page-sizes>
					</ng-template>
				</kendo-grid>
			</div>
		</div>
	</div>
</div>
<div *ngIf="editingIssue" style="height: 100%">
	<div class="card" *ngIf="!loadingEditForm" style="height: 100%">
		<div class="card-header">
			<h4 style="display: inline; vertical-align: bottom" class="card-title">
				{{ editHeader
				}}<span *ngIf="selectedIssue != null"
					>&nbsp;&nbsp;<strong>{{ selectedIssue.Id }}</strong></span
				>
			</h4>
			<button class="btn btn-icon btn-simple btn-twitter pull-right" *ngIf="selectedIssue != null" (click)="selectedIssue.openFileImageUploadWindow()">
				<div style="float: right; padding-right: 5px; padding-top: 10px">
					&nbsp;&nbsp;&nbsp;<i
						[ngClass]="{
							'object-contains-files': CountOfFilesUploaded >= 1
						}"
						class="tim-icons icon-notes clipboard-larger"
						aria-hidden="true"
						style="cursor: pointer"
						title="View files attached to this Issue.  {{ CountOfFilesUploaded > 0 ? CountOfFilesUploaded + ' file' + (CountOfFilesUploaded > 1 ? 's' : '') + ' uploaded currently.' : '' }}"
					>
					</i>
				</div>
			</button>
			<button class="btn btn-icon btn-simple btn-twitter pull-right" (click)="dialogRef.close()">
				<i class="tim-icons icon-minimal-left" title="Click here to go back to the Issues List."> </i>
			</button>
			<h5 class="card-title">{{ editSubHeader }}</h5>
		</div>
		<div class="card-body" style="overflow-y: scroll;">
			<div>
				<div
					[ngStyle]="{
						'margin-top': addingNewIssue ? '10px' : '0px'
					}"
				>
					<lib-dynamic-form [questions]="editQuestions" [options]="editFormOptions" (submittedValues)="submitEditedIssue($event)"></lib-dynamic-form>
				</div>
				<div *ngIf="!addingNewIssue">
					<hr />
					<div class="row" style="padding-top: 10px">
						<div class="col-sm-1 col-md-1 col-form-label pull-right">
							<label for="response">Response</label>
						</div>
						<div class="col-sm-9 col-md-9" style="padding-right: 2px; margin-top: 4px">
							<div [formGroup]="discussionIssueFormGroup" class="form-group" style="float: left">
								<textarea class="form-control" rows="2" cols="320" formControlName="response" id="response" style="resize: vertical"> </textarea>
							</div>
						</div>
						<div class="col-sm-2 col-md-2" style="padding-left: 0; margin-bottom: 3px; padding-right: 3px; float: left; max-width: 150px">
							<button class="btn btn-icon btn-simple btn-twitter pull-left" title="Click here to send your message." style="height: 86%; width: 85%; padding-top: 0" [disabled]="discussionIssueFormGroup.controls.response.value == null || discussionIssueFormGroup.controls.response.value == ''" (click)="submitResponse(selectedIssue.Id)">
								<i class="tim-icons icon-send"></i>
							</button>
						</div>
					</div>
					<div [style.height.%]="42" style="overflow-y: auto">
						<kendo-grid [hidden]="loadingEditForm || addingNewIssue" [rowClass]="updateRowColor" [data]="discussionGridSettings.gridData" [filter]="discussionGridSettings.state.filter" [scrollable]="'scrollable'" [navigable]="true" [sort]="discussionGridSettings.state.sort" [skip]="discussionGridSettings.state.skip" [columnMenu]="true" [style.height.%]="100" filterable="menu" [sortable]="true" [reorderable]="true" [resizable]="true" [pageable]="{ pageSizes: true }" [pageSize]="discussionGridSettings.state.take" (pageChange)="pageChange($event)" (filterChange)="filterChange($event)" (sortChange)="sortChange($event)" #discussionGrid>
							<ng-template kendoGridColumnMenuTemplate let-service="service">
								<kendo-grid-columnmenu-sort [service]="service"> </kendo-grid-columnmenu-sort>
								<kendo-grid-columnmenu-lock [service]="service"> </kendo-grid-columnmenu-lock>
								<kendo-grid-columnmenu-chooser [service]="service"> </kendo-grid-columnmenu-chooser>
								<kendo-grid-columnmenu-filter [service]="service"> </kendo-grid-columnmenu-filter>
								<kendo-grid-columnmenu-item
									icon="k-icon k-i-filter-clear"
									text="Clear Filter"
									(itemClick)="
										filterChange({
											logic: 'and',
											filters: []
										});
										service.close()
									"
								>
								</kendo-grid-columnmenu-item>
							</ng-template>
							<kendo-grid-column *ngFor="let column of discussionGridSettings.columnsConfig" title="{{ column.title }}" field="{{ column.field }}" [width]="column._width" [filter]="column.filter" [hidden]="column.hidden" [locked]="column.locked">
								<ng-template *ngIf="column.field === 'Date'" kendoGridCellTemplate let-dataItem>
									{{ dataItem.Date | date : "MM/dd/yy HH:mm:ss" }}
								</ng-template>
								<ng-template kendoGridNoRecordsTemplate> No discussion about this issue yet. </ng-template>
							</kendo-grid-column>
							<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
								<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
								<kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
								<kendo-pager-next-buttons></kendo-pager-next-buttons>
								<kendo-pager-info></kendo-pager-info>
								<kendo-pager-page-sizes [pageSizes]="[15, 30, 50]"></kendo-pager-page-sizes>
							</ng-template>
						</kendo-grid>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card" style="height: 100%" *ngIf="loadingEditForm">

		<div class="card-header">
			<h4 style="display: inline; vertical-align: bottom" class="card-title">
				Loading Issue
			</h4>

			<button class="btn btn-icon btn-simple btn-twitter pull-right" (click)="dialogRef.close()">
				<i class="tim-icons icon-minimal-left" title="Click here to go back to the Issues List."> </i>
			</button>
			<!-- <h5 class="card-title">{{ editSubHeader }}</h5> -->
		</div>
		<div class="card-body">
			<lib-loading-spinner  [loadingMessage]="'Loading Issue...'" [vehicle]="'plane'"></lib-loading-spinner>

		</div>
	</div>
</div>
