<div style="overflow-y: scroll; height: calc(100%);">
	<mat-accordion multi>
	  <mat-expansion-panel *ngFor="let l of forecast.list">
		<mat-expansion-panel-header>
		  <mat-panel-title>
			<div class="container">
			  <div class="row">
				<div class="col-4">
				  <h4 style="margin-bottom: 0px;">
					<strong>{{ l.dt * 1000 | date: "EEE, MMM d" }}</strong>
				  </h4>
				</div>
				<div class="col-4">
				  <h4 style="margin-bottom: 0px;">
					<i class="wi wi-owm-{{ l.weather[0].id }}"></i>
					&nbsp;
					<strong
					  >{{ l.temp.max * (9 / 5) - 459.67 | number: "1.0-0" }} /
					  {{
						l.temp.min * (9 / 5) - 459.67 | number: "1.0-0"
					  }}</strong
					>
					<i class="wi wi-fahrenheit"></i>
				  </h4>
				</div>
				<div class="col-4">
				  <strong>{{ l.weather[0].description }}</strong>
				</div>
			  </div>
			</div>
		  </mat-panel-title>
		  <!-- <mat-panel-description>
		  <i class="wi wi-owm-{{ l.weather[0].id }}"></i>
		  {{ l.temp.max * (9 / 5) - 459.67 | number: "1.0-0" }} /
		  {{ l.temp.min * (9 / 5) - 459.67 | number: "1.0-0" }}
		  <i class="wi wi-fahrenheit"></i> ({{ l.weather[0].description }})
		</mat-panel-description> -->
		</mat-expansion-panel-header>
		<table>
		  <tr>
			<th></th>
			<th>
			  Morning
			</th>
			<th>
			  Afternoon
			</th>
			<th>
			  Evening
			</th>
			<th>
			  Night
			</th>
		  </tr>
		  <tr>
			<td>
			  <strong>Temperature</strong>
			</td>
			<td>
			  {{ l.temp.morn * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
			<td>
			  {{ l.temp.day * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
			<td>
			  {{ l.temp.eve * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
			<td>
			  {{ l.temp.night * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Feels Like</strong>
			</td>
			<td>
			  {{ l.feels_like.morn * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
			<td>
			  {{ l.feels_like.day * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
			<td>
			  {{ l.feels_like.eve * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
			<td>
			  {{ l.feels_like.night * (9 / 5) - 459.67 | number: "1.0-0" }}
			  <i class="wi wi-fahrenheit"></i>
			</td>
		  </tr>
		</table>
		<br />
		<table>
		  <tr>
			<td>
			  <strong>Wind:</strong>
			</td>
			<td>
			  {{ l.speed }} mph
			  {{ weatherService.getCardinalDirectionFromDegrees(l.deg) }}
			  <i
				class="wi wi-wind-direction"
				[ngStyle]="{
				  transform: 'rotate(' + l.deg + 'deg)'
				}"
			  ></i>
			</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Rain:</strong>
			</td>
			<td>{{ l.rain / 25.4 | number: "1.0-2" }} (in/h)</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Humidity:</strong>
			</td>
			<td>{{ l.humidity }} %</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Clouds:</strong>
			</td>
			<td>{{ l.clouds }}%</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Pressure:</strong>
			</td>
			<td>{{ l.pressure }}mb</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Sunrise:</strong>
			</td>
			<td>{{ l.sunrise * 1000 | date: "shortTime" }}</td>
		  </tr>
		  <tr>
			<td>
			  <strong>Sunset:</strong>
			</td>
			<td>{{ l.sunset * 1000 | date: "shortTime" }}</td>
		  </tr>
		</table>
	  </mat-expansion-panel>
	</mat-accordion>
  </div>
