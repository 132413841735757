import {
	Component,
	Input,
	EventEmitter,
	ViewChild,
	OnDestroy,
	NgZone,
	Optional,
	AfterViewInit,
	OnInit,
} from "@angular/core";

import { filter } from "rxjs/operators";
import * as moment from "moment";
// import * as Highcharts from "highcharts/highstock";

import _ from "lodash";

import { SassHelperComponent } from "../../_helpers/sass-helper/sass-helper.component";

import Swal from "sweetalert2";
import { UtilityService } from "../../services/utility.service";
import { DataService } from "../../services/data.service";
import { Global } from "../../_constants/global.variables";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DashboardService } from "../../services/dashboard.service";
import * as $ from "jquery";
import { theme } from "highcharts";
import { DecimalPlacesPipe } from "../../_helpers/pipes/decimal-places.pipe";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { GridSettings } from "../../_models/grid-settings.interface";
import { Subscription } from "rxjs";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ChartService } from "../../services/chart.service";
import { ITag } from "../../_models/tag.model";
import { IAsset } from "../../_models/asset.model";

declare var Highcharts: any;

let componentContext;

@Component({
	selector: "lib-tag-graph",
	templateUrl: "./tag-graph.component.html",
	styleUrls: ["./tag-graph.component.scss"],
})
export class TagGraphComponent implements AfterViewInit, OnInit, OnDestroy {
	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: "Name",
				title: "Name",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "jbtStandardObservationName",
				title: "Short Name",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "site",
				title: "Site",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "asset",
				title: "Asset Name",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "JavascriptDate",
				title: "Date",
				filterable: true,
				filter: "date",
				includeInChooser: true,
				_width: 100,
			},
			{
				field: "SiteLocalJavascriptDate",
				title: "Date",
				filterable: true,
				filter: "date",
				includeInChooser: false,
				_width: 100,
			},
			{
				field: "UTCJavascriptDate",
				title: "Date",
				filterable: true,
				filter: "date",
				includeInChooser: false,
				_width: 100,
			},
			{
				field: "Value",
				title: "Value",
				filterable: true,
				_width: 50,
				minResizableWidth: 60,
			},
		],
	};
	public widgetTabs = [
		{ textLabel: "Chart", rendered: true },
		// { textLabel: 'Table', rendered: false },
	];
	materialTabSelected = 0;

	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	Highcharts: typeof Highcharts = Highcharts;
	GraphTagsData: any;
	AggregateObservations: any;
	pointCount: number;
	private swalWithBootstrapButtons: any;

	marginalAndPreAggregatedObservations: any[];
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() tagModalInputObject: any;

	@Input() dashboard: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() private tagGraphCreatedEvent: EventEmitter<any>;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	@Optional()
	@Input()
	private timeZoneChangedOnSingleTagTrendEvent: EventEmitter<any>;

	allDataIsDigital: any;
	chartMetaData: {
		seriesData: { analog: any; digital: any; all: any };
		pointCount: any;
		yAxisInstances: any[];
	};
	chart: any;
	isDataLoading: boolean = true;
	dashboardTimeScopes = [];
	colorChangedSubscription: any;
	addedNewTagToChartSubscription: any;
	widgetResizedSubscription: any;
	tagGraphCreatedSubscription: any;
	signalRSubscription: any;
	dashboardTimeScopeChangedSubscription: any;
	signalRTagUpdateSubscription: any;
	tagIdArray: any;
	tagIdArrayUnique: any[];
	private debugMode: boolean = false;
	fullDataCacheSubscription: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;

	displayTimeScopeMessage: boolean = false;
	timeScopeMessage: string;
	gates: any = [];
	widgetTags: any;
	timeZoneType: any;
	dashboardTimeZoneChangedSubscription: any;
	tagGraphPopup: boolean;
	timeZoneChangedOnSingleTagTrendSubscription: any;
	public componentName: string = "tag-graph: ";
	chartSingleSeries: any[];
	updateSingleChartOnIntervalInterval: NodeJS.Timeout;
	chartWidgetSeries: any[];
	updateWidgetChartOnIntervalInterval: NodeJS.Timeout;
	recentInsertions: boolean = false;
	rawGridData: any[];
	tableDataBetweenExtremes: any[];
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	punchoutTimeZoneChangedSubscription: Subscription;
	punchoutTimeScopeChangedSubscription: Subscription;
	minExtreme: any;
	maxExtreme: any;
	average: number;
	max: number;
	min: number;
	stateChanges: number;
	diff: number;
	public guid: string;
	public assetObjects: Array<IAsset> = [];

	constructor(
		private utilityService: UtilityService,
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private dashboardService: DashboardService,
		private zone: NgZone,
		private _decimalPlacesPipe: DecimalPlacesPipe,
		private chartService: ChartService
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger",
			},
			buttonsStyling: false,
		});
	}

	ngOnInit(): void {
		this.guid = this.dataService.guid();
	}

	ngAfterViewInit() {
		componentContext = this;
		this.tagGraphPopup = !this.widgetObject ? true : false;
		if (Global.User.currentUser.Username == "dylan") {
			this.debugMode = true;
		}
		this.tagIdArray = [];
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject !== undefined ? this.widgetObject : undefined
		);
		if (
			this.tagGraphPopup &&
			this.timeZoneChangedOnSingleTagTrendEvent != undefined
		) {
			this.timeZoneChangedOnSingleTagTrendSubscription =
				this.timeZoneChangedOnSingleTagTrendEvent.subscribe(
					(timeZoneType) => {
						this.timeZoneType = timeZoneType;
						this.initialize();
					}
				);
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === "light") {
					this.CreateChart(false);
				} else {
					this.CreateChart(true);
				}
			});
		this.addedNewTagToChartSubscription =
			this.dashboardService.addingNewTagToChart$.subscribe((widgetId) => {
				if (this.widgetObject.WidgetId === widgetId) {
					//add tag to chart since it belongs to this widget.
					this.initialize();
				}
			});
		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe(
				(data) => {
					if (
						this.chart &&
						this.widgetObject.WidgetId == data.item.WidgetId
					) {
						if (Global.isMobile) {
							let tagGraph = $(
								"#tagGraph" + this.widgetObject.WidgetId
							);
							this.chart.setSize(
								tagGraph.width(),
								tagGraph.height(),
								false
							);
						} else {
							this.chart.setSize(
								data.width,
								data.height - 43,
								false
							);
						}
					}
				}
			);
		}

		if (this.tagGraphCreatedEvent) {
			this.tagGraphCreatedSubscription =
				this.tagGraphCreatedEvent.subscribe((data) => {
					if (data.WidgetId === this.widgetObject.WidgetId) {
						this.dashboardTimeScopes = data.DashboardTimeScopes;
						this.initialize();
					}
				});
		}

		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					console.log(data);
					let foundWidget = data.find((widgetThatWasChanged) => {
						return (
							widgetThatWasChanged.WidgetId ===
							this.widgetObject.WidgetId
						);
					});

					if (!_.isNil(foundWidget)) {
						Global.User.DebugMode &&
							console.log(
								this.componentName + "Widget Time Scope Changed"
							);
						this.initialize();
					}
				});
		}

		this.punchoutTimeScopeChangedSubscription =
			this.dataService.punchoutTimeScopeChanged$.subscribe(
				(data: any) => {
					if (
						this.dashboardService.currentDashboard.Name ==
						"PunchOut Screen"
					) {
						this.initialize();
					}
				}
			);
		this.punchoutTimeZoneChangedSubscription =
			this.dataService.punchoutTimeZoneChanged$.subscribe((data: any) => {
				if (
					this.dashboardService.currentDashboard.Name ==
					"PunchOut Screen"
				) {
					this.timeZoneType =
						this.dashboardService.determineTimeZoneType(
							this.widgetObject
						);
					this.configureDateColumnsForTimezoneType();
					this.initialize();
				}
			});

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								"Dashboard time zone changed: %O",
							data
						);
					console.log(data);
					let foundWidget = data.find((widgetThatWasChanged) => {
						return (
							widgetThatWasChanged.WidgetId ===
							this.widgetObject.WidgetId
						);
					});

					if (!_.isNil(foundWidget)) {
						Global.User.DebugMode &&
							console.log(
								this.componentName + "Widget Time Zone Changed"
							);
						this.timeZoneType =
							this.dashboardService.determineTimeZoneType(
								foundWidget
							);
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.configureDateColumnsForTimezoneType();
						this.initialize();
					}
				});
		}
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (this.debugMode) {
				console.log(
					this.componentName +
						"going to initialize since cache exists..."
				);
			}
			this.initialize();
		}
	}

	configureDateColumnsForTimezoneType() {
		let utcIndex = this.gridSettings.columnsConfig.findIndex((column) => {
			return column.field === "UTCJavascriptDate";
		});
		let siteLocalIndex = this.gridSettings.columnsConfig.findIndex(
			(column) => {
				return column.field === "SiteLocalJavascriptDate";
			}
		);
		let userLocalIndex = this.gridSettings.columnsConfig.findIndex(
			(column) => {
				return column.field === "JavascriptDate";
			}
		);

		if (this.timeZoneType === "UTC Time") {
			this.gridSettings.columnsConfig[userLocalIndex].hidden = true;
			this.gridSettings.columnsConfig[siteLocalIndex].hidden = true;
			this.gridSettings.columnsConfig[utcIndex].hidden = false;
		} else if (this.timeZoneType === "Site Time") {
			this.gridSettings.columnsConfig[userLocalIndex].hidden = true;
			this.gridSettings.columnsConfig[siteLocalIndex].hidden = false;
			this.gridSettings.columnsConfig[utcIndex].hidden = true;
		} else if (this.timeZoneType === "User Time") {
			this.gridSettings.columnsConfig[userLocalIndex].hidden = false;
			this.gridSettings.columnsConfig[siteLocalIndex].hidden = true;
			this.gridSettings.columnsConfig[utcIndex].hidden = true;
		}
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.getTableDataBetweenExtremes(this.rawGridData));
		}
	}

	animationRedraw() {
		console.log("hit");
		// if (this.selectedMatTabLabel === 'Map') {
		// 	this.loadCorrectTabData(true);
		// }
	}

	tabHasChanged(event) {
		console.log("hit");
		this.materialTabSelected = event.index;
		this.recentInsertions = true;
		if (!_.isNil(this.widgetObject) && this.materialTabSelected === 0) {
			this.updateWidgetChartOnInterval();

		} else if (
			_.isNil(this.widgetObject) &&
			this.materialTabSelected === 0
		) {
			this.updateSingleChartOnInterval();
		} else {
			if (this.tagDataGrid) {
				let filteredInformation = this.getTableDataBetweenExtremes(this.rawGridData)
				this.calculateMetadata(filteredInformation)

				this.tagDataGrid.gridDataSubject.next(filteredInformation);
			}
		}

	}

	calculateMetadata(filteredInformation){
		let pointsOnlyValues = filteredInformation.map((point) => {
			return parseFloat(point.Value);
		});
		this.average = pointsOnlyValues.reduce((a, b) => a + b) / pointsOnlyValues.length;
		this.max = Math.max(...pointsOnlyValues);
		this.min = Math.min(...pointsOnlyValues);
		this.stateChanges = 0
		pointsOnlyValues.forEach((point, index) => {
			if (index !== 0 && point !== pointsOnlyValues[index - 1]) {
				this.stateChanges++;
			}
		});
		this.diff = this.max - this.min;
	}

	retrieveObservationValues(statement) {
		if (this.debugMode) {
			console.log(
				this.componentName +
					"retrieveObservationValues statement = " +
					statement
			);
		}

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			if (data.length === 0) {
				if (!this.tagGraphPopup) {
					this.displayTimeScopeMessage = true;
				} else {
					this.tagModalInputObject.isDisplayDataLive = false;

					this.swalWithBootstrapButtons
						.fire({
							title: "No Data",
							text: "There aren't any observations for this tag in this timescope.",
							showCancelButton: false,
							confirmButtonText: "Ok",
							reverseButtons: false,
						})
						.then(() => {});
				}
				this.isDataLoading = false;

				return;
			} else {
				if (!this.tagGraphPopup) {
					let listOfAssets = [];
					let listOfObservationIds = [];
					this.assetObjects = [];

					data.forEach((dataInArray) => {
						listOfAssets.push(dataInArray.AssetId);
						var existingAsset = this.assetObjects.firstOrDefault((asset:IAsset) => asset.Id == dataInArray.AssetId);

						if (!existingAsset) {
							this.assetObjects.push(this.dataService.cache.assetsObject[dataInArray.AssetId]);
						}

						listOfObservationIds.push(
							dataInArray.JBTStandardObservationId
						);
					});

					let tagNamePrefixesString = this.assetObjects.map((a: any) => a.TagNamePrefix).join(",");
					Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

					this.dataService
						.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
							listOfAssets.toString(),
							false,
							listOfObservationIds.toString()
						)
						.subscribe(() => {
							this.displayTimeScopeMessage = false;
							data.forEach((tagItem) => {
								this.tagIdArray.push(tagItem.TagId);
							});

							this.tagIdArrayUnique = Array.from(
								new Set(this.tagIdArray)
							);

							let tagNamePrefixes = _.uniq(
								listOfAssets.map(
									(a: any) =>
										this.dataService.cache.assetsObject[a]
											.TagNamePrefix
								)
							);
							let tagNamePrefixesString = tagNamePrefixes
								.map((a: any) => a.toString())
								.join();

							this.widgetGroupSettings = {
								WidgetId: this.widgetObject.WidgetId,
								GroupList: tagNamePrefixesString,
								IsPopup: false,
							};

							Global.User.DebugMode &&
								console.log(
									"this.widgetGroupSettings = %O",
									this.widgetGroupSettings
								);

							var seriesCounter = 0;

							this.GraphTagsData = data.reduce((o, cur) => {
								// Get the index of the key-value pair.
								var occurs = o.reduce((n, item, i) => {
									return item.Tag.Id === cur.TagId ? i : n;
								}, -1); // If the name is found,
								if (occurs >= 0) {
									// append the current value to its list of values.
									o[occurs].TagValues = o[
										occurs
									].TagValues.concat(cur.TagValues); // Otherwise,
								} else {
									// add the current item to o (but make sure the value is an array).
									var obj = {
										Tag: {
											Name: cur.tagName,
											Id: cur.TagId,
											JBTStandardObservationId:
												cur.JBTStandardObservationId,
											Asset: {
												Name: cur.AssetName,
												Site: {
													Name: cur.SiteName,
												},
											},
										},
										JBTStandardObservationName:
											cur.JBTStandardObservationName,
										GateName: cur.GateName,
										TagValues: cur.TagValues,
									};
									o = o.concat([obj]);
								}
								return o;
							}, []);
							if (this.widgetObject.TagGraphWidgetTags) {
								this.GraphTagsData.forEach((series) => {
									this.widgetObject.TagGraphWidgetTags.forEach(
										(tagGraphConfiguration) => {
											if (
												tagGraphConfiguration.TagId ===
												series.Tag.Id
											) {
												series.SeriesType = _.isNil(
													tagGraphConfiguration.SeriesType
												)
													? "line"
													: tagGraphConfiguration.SeriesType;
												tagGraphConfiguration.SeriesType =
													series.SeriesType;
												series.YAxisOrdinal = _.isNil(
													tagGraphConfiguration.YAxisOrdinal
												)
													? 0
													: tagGraphConfiguration.YAxisOrdinal;
												tagGraphConfiguration.YAxisOrdinal =
													series.YAxisOrdinal;
												if (
													!_.isNil(
														tagGraphConfiguration.Ordinal
													)
												) {
													series.OrdinalFromDb =
														tagGraphConfiguration.Ordinal;
												}
												if (
													!_.isNil(
														tagGraphConfiguration.HexColor
													)
												) {
													series.HexColor =
														tagGraphConfiguration.HexColor;
												}
											}
										}
									);
								});
								this.GraphTagsData = _.orderBy(
									this.GraphTagsData,
									["OrdinalFromDb"],
									["desc"]
								);
							}

							if (this.debugMode) {
								console.log(
									this.componentName +
										"this.GraphTagsData = %O",
									this.GraphTagsData
								);
							}
							_.forEach(this.GraphTagsData, (graphTag) => {
								graphTag.AggregateObservations =
									graphTag.TagValues.split("|")
										.filter((v) => {
											return v.length > 5;
										})
										.map((valuePair) => {
											var numericValuePair =
												valuePair.split(",");
											return [
												+numericValuePair[0],
												+numericValuePair[1] || 0,
											];
										});
							});
							if (this.debugMode) {
								console.log(
									this.componentName +
										"this.GraphTagsData = %O",
									this.GraphTagsData
								);
							}
							let seriesData = this.GraphTagsData.filter((gt) => {
								return (
									gt.MarginObservations ||
									gt.AggregateObservations
								);
							}).map((gt) => {
								var lastMarginDataPair;
								if (gt.AggregateObservations) {
									lastMarginDataPair = _.last(
										gt.AggregateObservations
									);
								}

								var trimmedMarginDataPairs;
								if (lastMarginDataPair) {
									trimmedMarginDataPairs =
										gt.MarginObservations
											? gt.MarginObservations.skipWhile(
													(m) => {
														return (
															m[0] <=
															lastMarginDataPair[0]
														);
													}
											  )
											: [];
								} else {
									trimmedMarginDataPairs =
										gt.MarginObservations;
								}

								var dataPairs;
								if (gt.AggregateObservations) {
									dataPairs = gt.AggregateObservations.concat(
										trimmedMarginDataPairs
									);
								} else {
									dataPairs = trimmedMarginDataPairs;
								}

								gt.isDigital = this.IsDataDigital(dataPairs);

								let nameString = "";
								if (gt.Tag.Asset.Site.Name === null) {
									if (
										this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
										] !== undefined
									) {
										nameString =
											gt.Tag.Asset.Site.Name +
											" " +
											gt.GateName +
											" " +
											gt.Tag.Asset.Name +
											" " +
											this.dataService.cache
												.jbtStandardObservationsObject[
												gt.Tag.JBTStandardObservationId
											].Name;
									} else {
										nameString = gt.Tag.Name;
									}
								} //Display Gate Name only if Gate System is not GSE
								else if (
									gt.GateName != null &&
									!gt.GateName.includes("GSE")
								) {
									if (
										this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
										] !== undefined
									) {
										nameString =
											gt.Tag.Asset.Site.Name +
											" " +
											gt.GateName +
											" " +
											gt.Tag.Asset.Name +
											" " +
											this.dataService.cache
												.jbtStandardObservationsObject[
												gt.Tag.JBTStandardObservationId
											].Name;
									} else {
										nameString = gt.Tag.Name;
									}
								} else {
									if (
										this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
										] !== undefined
									) {
										nameString =
											gt.Tag.Asset.Site.Name +
											" " +
											gt.GateName +
											" " +
											gt.Tag.Asset.Name +
											" " +
											this.dataService.cache
												.jbtStandardObservationsObject[
												gt.Tag.JBTStandardObservationId
											].Name;
									} else {
										nameString = gt.Tag.Name;
									}
								}
								// If Fleet user display only VIN# and tagname
								if(this.dataService.determineIfFleetUser())
									nameString = gt.Tag.Name;
								//SiteLocalJavascriptDate: this.utilityService.convertFromUtcToLocalToSite(+tarray[4], this.cache.sitesObject[+tarray[2]].UTCTimeOffset), // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
								let site = gt.Tag.Asset.Site.Name;
								let siteObject: any;
								if (site !== undefined) {
									siteObject =
										this.dataService.cache.sites.find(
											(ste) => {
												return ste.Name === site;
											}
										);
								}

								let dataArray = (gt.AggregateObservations || [])
									.concat(gt.MarginObservations)
									// .orderBy(function(vp) {
									//   return vp[0];
									// })
									.filter((o) => {
										return o;
									})
									.map((point) => {
										this.pointCount++;
										return [
											point[0],
											gt.isDigital
												? point && (point[1] || 0)
												: this._decimalPlacesPipe.transform(
														point[1],
														1,
														gt.Tag
															.JBTStandardObservationId
												  ),
										];
									});
								var seriesObject: any = {
									seriesNumber: seriesCounter++,
									connectNulls: true,
									tagId: gt.Tag.Id,
									Asset: gt.Tag.Asset.Name,
									longTagName: gt.Tag.Name,
									isDigital: gt.isDigital,
									//groupPixelWidth: 5,
									name: nameString,
									type: gt.SeriesType,
									JBTStandardObservationId:
										this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
										] !== undefined
											? this.dataService.cache
													.jbtStandardObservationsObject[
													gt.Tag
														.JBTStandardObservationId
											  ].Id
											: "",
									jbtStandardObservationName:
										this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
										] !== undefined
											? this.dataService.cache
													.jbtStandardObservationsObject[
													gt.Tag
														.JBTStandardObservationId
											  ].Name
											: "",
									data: dataArray,
									color: !_.isNil(gt.HexColor)
										? gt.HexColor
										: Highcharts.getOptions().colors[
												seriesCounter
										  ],
									//siteTimeData:
								};
								if (!_.isNil(siteObject)) {
									seriesObject.siteObject = siteObject;
								}
								let colors = Highcharts.getOptions().colors;
								if (this.debugMode) {
									console.log(
										this.componentName + "colors = %O",
										colors
									);
									console.log(
										this.componentName +
											"seriesObject = %O",
										seriesObject
									);
								}

								if (this.debugMode) {
									console.log(
										this.componentName +
											"Series Object = %O",
										seriesObject
									);
								}
								seriesObject["step"] = "left";
								return seriesObject;
							});
							this.allDataIsDigital = this.GraphTagsData.every(
								(gt) => {
									return gt.isDigital;
								}
							);

							var pointCount = 0;

							this.chartMetaData = {
								seriesData: {
									analog: seriesData.filter((d) => {
										return !d.isDigital;
									}),
									digital: seriesData.filter((d) => {
										return d.isDigital;
									}),
									all: seriesData,
								},
								pointCount: this.pointCount,
								yAxisInstances: [],
							};

							//Calculate the proportional height for each pane based on the number of readings from each.

							var genericHeightPercentage =
								100 / seriesData.length;
							var rollingTopPercentage = 100;

							//Place the digital axis onto the chart plot first if there are digital entries.
							var yAxisNumber = 0;

							//++Digital yAxis
							this.chartMetaData.seriesData.digital.forEach(
								(sd) => {
									let titleVarR = sd.name.split("(");
									let titleVar = _.first(titleVarR);
									rollingTopPercentage -=
										genericHeightPercentage;
									let TagGraphWidgetTag =
										this.widgetObject.TagGraphWidgetTags.find(
											(tag) => {
												return tag.TagId === sd.tagId;
											}
										);
									if (TagGraphWidgetTag.YAxisOrdinal !== 0) {
										let foundYaxisInstanceIndex =
											this.chartMetaData.yAxisInstances.findIndex(
												(yaxisInstance) => {
													return (
														TagGraphWidgetTag.YAxisOrdinal ===
														yaxisInstance.YAxisOrdinal
													);
												}
											);
										if (foundYaxisInstanceIndex === -1) {
											this.chartMetaData.yAxisInstances.push(
												{
													iOPSType: "digital",
													type: "linear",
													offset: 30,
													min: -0.2,
													tagIds: [sd.tagId],
													max: 1.2,
													minRange: 1.4,
													//tickInterval: .5,
													top:
														rollingTopPercentage +
														"%",
													height:
														genericHeightPercentage *
															0.9 +
														"%",
													//visible: yAxisNumber == 0,
													labels: {
														// enabled: false,
														formatter: function () {
															return this.value;
														},
														style: {
															color: sd.color,
														},
													},
													resize: {
														enabled: false,
													},
													title: {
														text: titleVar,
														rotation:
															Global.isMobile
																? 90
																: 0,
														margin: Global.isMobile
															? 0
															: 40,
														offset: Global.isMobile
															? undefined
															: 50,
														style: {
															color: sd.color,
															fontSize: "1em",
														},
													},
													gridLineWidth: 0,
													minorGridLineWidth: 0,
													plotLines: [
														{
															value: 0,
															width: 2,
														},
													],
													gridLineColor: "#424242",
													ridLineWidth: 1,
													minorGridLineColor:
														"#424242",
													inoGridLineWidth: 0.5,
													tickColor: "#424242",
													minorTickColor: "#424242",
													lineColor: "#424242",
												}
											);
										} else {
											this.chartMetaData.yAxisInstances[
												foundYaxisInstanceIndex
											].tagIds.push(sd.tagId);
										}
									} else {
										this.chartMetaData.yAxisInstances.push({
											iOPSType: "digital",
											type: "linear",
											offset: 30,
											min: -0.2,
											tagIds: [sd.tagId],
											max: 1.2,
											minRange: 1.4,
											tickInterval: 0.5,
											top: rollingTopPercentage + "%",
											height:
												genericHeightPercentage * 0.9 +
												"%",
											//visible: yAxisNumber == 0,
											labels: {
												// enabled: false,
												formatter: function () {
													return this.value;
												},
												style: {
													color: sd.color,
												},
											},
											resize: {
												enabled: false,
											},
											title: {
												text: titleVar,
												rotation: Global.isMobile
													? 90
													: 0,
												margin: Global.isMobile
													? 0
													: 40,
												offset: Global.isMobile
													? undefined
													: 50,
												style: {
													color: sd.color,
													fontSize: "1em",
												},
											},
											gridLineWidth: 0,
											minorGridLineWidth: 0,
											plotLines: [
												{
													value: 0,
													width: 2,
												},
											],
											gridLineColor: "#424242",
											ridLineWidth: 1,
											minorGridLineColor: "#424242",
											inoGridLineWidth: 0.5,
											tickColor: "#424242",
											minorTickColor: "#424242",
											lineColor: "#424242",
										});
									}

									sd.yAxis = yAxisNumber++;
								}
							);
							this.chartMetaData.seriesData.analog.forEach(
								(sd) => {
									let titleVarR = sd.name.split("(");
									let titleVar = _.first(titleVarR);
									rollingTopPercentage -=
										genericHeightPercentage;
									let TagGraphWidgetTag =
										this.widgetObject.TagGraphWidgetTags.find(
											(tag) => {
												return tag.TagId === sd.tagId;
											}
										);
									if (TagGraphWidgetTag.YAxisOrdinal !== 0) {
										//this means we are going to be grouping. check to see if it doesn't already exist in the yaxisinstances
										let foundYaxisInstanceIndex =
											this.chartMetaData.yAxisInstances.findIndex(
												(yaxisInstance) => {
													return (
														TagGraphWidgetTag.YAxisOrdinal ===
														yaxisInstance.YAxisOrdinal
													);
												}
											);
										if (foundYaxisInstanceIndex === -1) {
											this.chartMetaData.yAxisInstances.push(
												{
													YAxisOrdinal:
														TagGraphWidgetTag.YAxisOrdinal,
													visible: true,
													iOPSType:
														"analog - " + sd.name,
													tagIds: [sd.tagId],
													type: "linear",
													offset: 30,
													top:
														rollingTopPercentage +
														"%",
													height:
														genericHeightPercentage *
															0.9 +
														"%",
													labels: {
														// enabled: false,
														formatter: function () {
															return this.value;
														},
														style: {
															color: sd.color,
														},
													},
													resize: {
														enabled: false,
													},
													title: {
														text: titleVar,
														rotation:
															Global.isMobile
																? 90
																: 0,
														margin: Global.isMobile
															? 0
															: 40,
														style: {
															color: sd.color,
															fontSize: "1em",
														},
													},
													plotLines: [
														{
															value: 0,
															width: 2,
														},
													],
												}
											);
										} else {
											this.chartMetaData.yAxisInstances[
												foundYaxisInstanceIndex
											].tagIds.push(sd.tagId);
										}
									} else {
										this.chartMetaData.yAxisInstances.push({
											visible: true,
											YAxisOrdinal:
												TagGraphWidgetTag.YAxisOrdinal,
											iOPSType: "analog - " + sd.name,
											tagIds: [sd.tagId],
											type: "linear",
											offset: 30,
											top: rollingTopPercentage + "%",
											height:
												genericHeightPercentage * 0.9 +
												"%",
											labels: {
												// enabled: false,
												formatter: function () {
													return this.value;
												},
												style: {
													color: sd.color,
												},
											},
											resize: {
												enabled: false,
											},
											title: {
												text: titleVar,
												rotation: Global.isMobile
													? 90
													: 0,
												margin: Global.isMobile
													? 0
													: 40,
												style: {
													color: sd.color,
													fontSize: "1em",
												},
											},
											plotLines: [
												{
													value: 0,
													width: 2,
												},
											],
										});
									}

									sd.yAxis = yAxisNumber++;
								}
							);

							//debugger;
							var interpolatedData = this.GetInterpolatedData(
								this.chartMetaData.seriesData.all
							);

							this.chartMetaData.seriesData.all.forEach(
								(series) => {
									series.data = interpolatedData.map(
										(item) => {

											return [item.dateMS, item[series?.tagId]?.replace ? parseFloat(item[series?.tagId]?.replace(/,/g, '')) : item[series?.tagId]];

										}
									);
									if (series.siteObject !== undefined) {
										let siteDateDataArray = series.data.map(
											(point) => {
												return [
													this.utilityService.convertFromUtcToLocalToSite(
														point[0],
														series.siteObject
															.UTCTimeOffset,
														true
													),
													point[1],
												];
											}
										);
										let utcDateDataArray = series.data.map(
											(point) => {
												return [
													this.utilityService.convertFromUtcToLocalToSite(
														point[0],
														0,
														true
													),
													point[1],
												];
											}
										);
										series.siteDateDataArray =
											siteDateDataArray;
										series.utcDateDataArray =
											utcDateDataArray;
									}
								}
							);

							this.chartMetaData.seriesData.all.forEach((sd) => {
								pointCount += sd.data.length;
							});
							this.pointCount = pointCount;
							if (Global.Theme === "light") {
								this.CreateChart(false);
							} else {
								this.CreateChart(true);
							}

							if (this.debugMode) {
								console.log(
									this.componentName +
										"this.GraphTagsData = %O",
									this.GraphTagsData
								);
							}
							if (
								!_.isNil(
									this.dashboardService.currentDashboard
										?.CustomStartDate
								)
							) {
								this.widgetObject.isDisplayDataLive = false;
							} else {
								this.widgetObject.isDisplayDataLive = true;
								this.updateWidgetChartOnIntervalInterval =
									setInterval(() => {
										this.updateWidgetChartOnInterval();
									}, 5000);
							}

							//Allowing them to switch gates should be evaluated each time a setting has changed, as we re-init the component.
						});
				} else if (this.tagGraphPopup === true) {
					data.forEach((tagItem) => {
						this.tagIdArray.push(tagItem.TagId);
					});
					this.tagIdArrayUnique = Array.from(
						new Set(this.tagIdArray)
					);
					Global.User.DebugMode &&
						console.log(
							this.componentName + "this.tagIdArray = %O",
							this.tagIdArray
						);
					Global.User.DebugMode &&
						console.log(
							this.componentName + "this.tagIdArrayUnique = %O",
							this.tagIdArrayUnique
						);

					Global.User.DebugMode &&
						console.log(this.componentName + "data = %O", data);
					var seriesCounter = 0;
					Global.User.DebugMode &&
						console.log(this.componentName + "this = %O", this);
					this.GraphTagsData = data.reduce((o, cur) => {
						var occurs = o.reduce((n, item, i) => {
							return item.Tag.Id === cur.TagId ? i : n;
						}, -1); // If the name is found,
						if (occurs >= 0) {
							o[occurs].TagValues = o[occurs].TagValues.concat(
								cur.TagValues
							); // Otherwise,
						} else {
							var obj = {
								Tag: {
									Name: cur.tagName,
									Id: cur.TagId,
									JBTStandardObservationId:
										cur.JBTStandardObservationId,
									Asset: {
										Name: cur.AssetName,
										Site: {
											Name: cur.SiteName,
										},
									},
								},
								JBTStandardObservationName:
									cur.JBTStandardObservationName,
								GateName: cur.GateName,
								TagValues: cur.TagValues,
							};
							o = o.concat([obj]);
						}
						return o;
					}, []);
					Global.User.DebugMode &&
						console.log(
							this.componentName + "this.GraphTagsData = %O",
							this.GraphTagsData
						);
					_.forEach(this.GraphTagsData, (graphTag) => {
						graphTag.AggregateObservations =
							graphTag.TagValues.split("|")
								.filter((v) => {
									return v.length > 5;
								})
								.map((valuePair) => {
									var numericValuePair = valuePair.split(",");
									return [
										+numericValuePair[0],
										+numericValuePair[1] || 0,
									];
								});
					});
					Global.User.DebugMode &&
						console.log(
							this.componentName + "this.GraphTagsData = %O",
							this.GraphTagsData
						);
					let seriesData = this.GraphTagsData.filter((gt) => {
						return (
							gt.MarginObservations || gt.AggregateObservations
						);
					}).map((gt) => {
						var lastMarginDataPair;
						if (gt.AggregateObservations) {
							lastMarginDataPair = _.last(
								gt.AggregateObservations
							);
						}

						var trimmedMarginDataPairs;
						if (lastMarginDataPair) {
							trimmedMarginDataPairs = gt.MarginObservations
								? gt.MarginObservations.skipWhile((m) => {
										return m[0] <= lastMarginDataPair[0];
								  })
								: [];
						} else {
							trimmedMarginDataPairs = gt.MarginObservations;
						}

						var dataPairs;
						if (gt.AggregateObservations) {
							dataPairs = gt.AggregateObservations.concat(
								trimmedMarginDataPairs
							);
						} else {
							dataPairs = trimmedMarginDataPairs;
						}

						gt.isDigital = this.IsDataDigital(dataPairs);

						let nameString = "";
						if (gt.Tag.Asset.Site.Name === null) {
							if (
								this.dataService.cache
									.jbtStandardObservationsObject[
									gt.Tag.JBTStandardObservationId
								] !== undefined
							) {
								nameString =
									gt.Tag.Asset.Site.Name +
									" " +
									gt.GateName +
									" " +
									gt.Tag.Asset.Name +
									" " +
									this.dataService.cache
										.jbtStandardObservationsObject[
										gt.Tag.JBTStandardObservationId
									].Name;
							} else {
								nameString = gt.Tag.Name;
							}
						} else {
							if (
								this.dataService.cache
									.jbtStandardObservationsObject[
									gt.Tag.JBTStandardObservationId
								] !== undefined
							) {
								nameString =
									gt.Tag.Asset.Site.Name +
									" " +
									gt.GateName +
									" " +
									gt.Tag.Asset.Name +
									" " +
									this.dataService.cache
										.jbtStandardObservationsObject[
										gt.Tag.JBTStandardObservationId
									].Name;
							} else {
								nameString = gt.Tag.Name;
							}
						}
						// If Fleet user display only VIN# and tagname
						if(this.dataService.determineIfFleetUser())
							nameString = gt.Tag.Name;	
						let site = gt.Tag.Asset.Site.Name;
						let siteObject: any;
						if (site !== undefined) {
							siteObject = this.dataService.cache.sites.find(
								(ste) => {
									return ste.Name === site;
								}
							);
						}
						var seriesObject: any = {
							seriesNumber: seriesCounter++,
							connectNulls: true,
							tagId: gt.Tag.Id,
							Asset: gt.Tag.Asset.Name,
							longTagName: gt.Tag.Name,
							isDigital: gt.isDigital,
							//groupPixelWidth: 5,
							name: nameString,
							JBTStandardObservationId:
								this.dataService.cache
									.jbtStandardObservationsObject[
									gt.Tag.JBTStandardObservationId
								] !== undefined
									? this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
									  ].Id
									: "",
							jbtStandardObservationName:
								this.dataService.cache
									.jbtStandardObservationsObject[
									gt.Tag.JBTStandardObservationId
								] !== undefined
									? this.dataService.cache
											.jbtStandardObservationsObject[
											gt.Tag.JBTStandardObservationId
									  ].Name
									: "",
							data: (gt.AggregateObservations || [])
								.concat(gt.MarginObservations)
								// .orderBy(function(vp) {
								//   return vp[0];
								// })
								.filter((o) => {
									return o;
								})
								.map((point) => {
									this.pointCount++;
									return [
										point[0],
										gt.isDigital
											? point && (point[1] || 0)
											: this._decimalPlacesPipe.transform(
													point[1],
													1,
													gt.Tag
														.JBTStandardObservationId
											  ),
									];
								}),
							color: Highcharts.getOptions().colors[
								seriesCounter
							],
						};

						if (!_.isNil(siteObject)) {
							seriesObject.siteObject = siteObject;
						}
						let colors = Highcharts.getOptions().colors;
						console.log(colors);
						console.log(seriesObject);

						console.log("Series Object = %O", seriesObject);
						seriesObject["step"] = "left";
						return seriesObject;
					});
					console.log(seriesData);
					this.allDataIsDigital = this.GraphTagsData.every((gt) => {
						return gt.isDigital;
					});

					var pointCount = 0;

					this.chartMetaData = {
						seriesData: {
							analog: seriesData.filter((d) => {
								return !d.isDigital;
							}),
							digital: seriesData.filter((d) => {
								return d.isDigital;
							}),
							all: seriesData,
						},
						pointCount: this.pointCount,
						yAxisInstances: [],
					};

					//Calculate the proportional height for each pane based on the number of readings from each.

					var genericHeightPercentage = 100 / seriesData.length;
					var rollingTopPercentage = 100;

					//Place the digital axis onto the chart plot first if there are digital entries.
					var yAxisNumber = 0;

					//++Digital yAxis
					this.chartMetaData.seriesData.digital.forEach((sd) => {
						let titleVarR = sd.name.split("(");
						let titleVar = _.first(titleVarR);
						rollingTopPercentage -= genericHeightPercentage;
						this.chartMetaData.yAxisInstances.push({
							iOPSType: "digital",
							type: "linear",
							offset: 30,
							min: -0.2,
							max: 1.2,
							minRange: 1.4,
							tickInterval: 0.5,
							top: rollingTopPercentage + "%",
							height: genericHeightPercentage * 0.9 + "%",
							//visible: yAxisNumber == 0,
							labels: {
								// enabled: false,
								formatter: function () {
									return this.value;
								},
								style: {
									color: sd.color,
								},
							},
							resize: {
								enabled: false,
							},
							title: {
								text: titleVar,
								rotation: Global.isMobile ? 90 : 0,
								margin: Global.isMobile ? 0 : 40,
								offset: Global.isMobile ? undefined : 50,
								style: {
									color: sd.color,
									fontSize: "1em",
								},
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							plotLines: [
								{
									value: 0,
									width: 2,
								},
							],
							gridLineColor: "#424242",
							ridLineWidth: 1,
							minorGridLineColor: "#424242",
							inoGridLineWidth: 0.5,
							tickColor: "#424242",
							minorTickColor: "#424242",
							lineColor: "#424242",
						});
						sd.yAxis = yAxisNumber++;
					});

					//++Analog yAxis
					//Place all other axes onto the array for all other analog values.
					this.chartMetaData.seriesData.analog.forEach((sd) => {
						let titleVarR = sd.name.split("(");
						let titleVar = _.first(titleVarR);
						rollingTopPercentage -= genericHeightPercentage;
						this.chartMetaData.yAxisInstances.push({
							visible: true,
							iOPSType: "analog - " + sd.name,
							type: "linear",
							offset: 30,
							top: rollingTopPercentage + "%",
							height: genericHeightPercentage * 0.9 + "%",
							labels: {
								// enabled: false,
								formatter: function () {
									return this.value;
								},
								style: {
									color: sd.color,
								},
							},
							resize: {
								enabled: false,
							},
							title: {
								text: titleVar,
								rotation: Global.isMobile ? 90 : 0,
								margin: Global.isMobile ? 0 : 40,
								offset: Global.isMobile ? undefined : 50,
								style: {
									color: sd.color,
									fontSize: "1em",
								},
							},
							plotLines: [
								{
									value: 0,
									width: 2,
								},
							],
						});
						sd.yAxis = yAxisNumber++;
					});

					//debugger;
					var interpolatedData = this.GetInterpolatedData(
						this.chartMetaData.seriesData.all
					);

					this.chartMetaData.seriesData.all.forEach((series) => {
						series.data = interpolatedData.map((item) => {


							return [item.dateMS, item[series?.tagId]?.replace ? parseFloat(item[series?.tagId]?.replace(/,/g, '')) : item[series.tagId]];


						});
						if (series.siteObject !== undefined) {
							let siteDateDataArray = series.data.map((point) => {
								return [
									this.utilityService.convertFromUtcToLocalToSite(
										point[0],
										series.siteObject.UTCTimeOffset,
										true
									),
									point[1],
								];
							});
							let utcDateDataArray = series.data.map((point) => {
								return [
									this.utilityService.convertFromUtcToLocalToSite(
										point[0],
										0,
										true
									),
									point[1],
								];
							});
							series.siteDateDataArray = siteDateDataArray;
							series.utcDateDataArray = utcDateDataArray;
						}
					});

					this.chartMetaData.seriesData.all.forEach((sd) => {
						pointCount += sd.data.length;
					});
					this.pointCount = pointCount;
					//console.log("Number of points in chart = " + pointCount);
					// this.widget.displaySettings.headingExtraTitle =
					//   " - " +
					//   this.utilityService.FormatNumberWithCommas(pointCount) +
					//   " Points in Chart";

					if (Global.Theme === "light") {
						this.CreateChartSingleTag(false);
					} else {
						this.CreateChartSingleTag(true);
					}
					console.log(this.GraphTagsData);

					if (
						!_.isNil(
							this.dashboardService.currentDashboard
								.CustomStartDate
						)
					) {
					} else {
						this.updateSingleChartOnIntervalInterval = setInterval(
							() => {
								this.updateSingleChartOnInterval();
							},
							5000
						);
					}
				}
			}
		});
	}

	processTagPopupSignalRObservations(newTag) {
		if (this.GraphTagsData && this.chart) {
			if (this.tagModalInputObject?.TagId == newTag.Id || this.GraphTagsData.firstOrDefault((tag:any) => { return tag.Id == newTag.Id })) {
				//The chart series might have been removed by the user. Check for a non null value before proceding.
				if (this.timeZoneType === "User Time") {
					if (
						this.evaluateIfPointIsNew(
							newTag.DateInMilliseconds,
							this.chartSingleSeries[0].data
						)
					) {
						this.chartSingleSeries[0].data.push([
							newTag.DateInMilliseconds,
							+newTag.Value,
						]);
					}

					// this.chart.series[0].addPoint(
					// 	[newTag.DateInMilliseconds, +newTag.Value],
					// 	true,
					// 	true
					// );
				} else if (this.timeZoneType === "Site Time") {
					if (!_.isNil(this.chart.series[0].options.siteObject)) {
						let changedTime =
							this.utilityService.convertFromUtcToLocalToSite(
								newTag.DateInMilliseconds,
								this.chart.series[0].options.siteObject
									.UTCTimeOffset,
								true
							);
						if (
							this.evaluateIfPointIsNew(
								changedTime,
								this.chartSingleSeries[0].data
							)
						) {
							this.chartSingleSeries[0].data.push([
								changedTime,
								+newTag.Value,
							]);
						}

						// this.chart.series[0].addPoint(
						// 	[changedTime, newTag.Value],
						// 	true,
						// 	true
						// );
					}
				} else if (this.timeZoneType === "UTC Time") {
					let changedTime =
						this.utilityService.convertFromUtcToLocalToSite(
							newTag.DateInMilliseconds,
							0,
							true
						);
					if (
						this.evaluateIfPointIsNew(
							changedTime,
							this.chartSingleSeries[0].data
						)
					) {
						this.chartSingleSeries[0].data.push([
							changedTime,
							+newTag.Value,
						]);
					}

					// this.chart.series[0].addPoint(
					// 	[changedTime, newTag.Value],
					// 	true,
					// 	true
					// );
				}
			}
		}
	}

	getTableDataBetweenExtremes(rawData){
		if(this.maxExtreme && this.minExtreme){
			this.tableDataBetweenExtremes = rawData.filter((data) => {
				if(this.timeZoneType === "User Time"){
					return new Date(data.JavascriptDate).valueOf() >= this.minExtreme && new Date(data.JavascriptDate).valueOf() <= this.maxExtreme;
				} else if (this.timeZoneType === "Site Time") {
					return new Date(data.SiteLocalJavascriptDate).valueOf() >= this.minExtreme && new Date(data.SiteLocalJavascriptDate).valueOf() <= this.maxExtreme;

				} else if (this.timeZoneType === "UTC Time") {
					return new Date(data.UTCJavascriptDate).valueOf() >= this.minExtreme && new Date(data.UTCJavascriptDate).valueOf() <= this.maxExtreme;

				}
			});
			return this.tableDataBetweenExtremes;
		} else {
			return rawData;
		}
	}

	updateWidgetChartOnInterval() {
		let service = this;
		if (
			this.chart &&
			this.chart.series?.length > 0 &&
			this.recentInsertions
		) {
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.getTableDataBetweenExtremes(this.rawGridData));
			}
			if (this.materialTabSelected === 0) {
				this.chartWidgetSeries.forEach((series, index) => {
					console.log(service);
					let foundActualChartSeriesIndex =
						service.chart.series.findIndex((chartSeries) => {
							return chartSeries.options.tagId === series.tagId;
						});
					if (foundActualChartSeriesIndex !== -1) {
						if (index != service.chartWidgetSeries.length - 1) {
							service.chart.series[
								foundActualChartSeriesIndex
							].setData(series.data, false);
						} else {
							service.chart.series[
								foundActualChartSeriesIndex
							].setData(series.data, true);
						}
					}
					// //we need to find which y axis on the yaxis instances this tag id belongs to and grab all the current values of the tag ids that the y axis has and recreate the html string.
					let yaxisInstancesIndex =
						service.chartMetaData.yAxisInstances.findIndex(
							(yaxisInstance) => {
								return yaxisInstance.tagIds.includes(
									series.tagId
								);
							}
						);
					if (yaxisInstancesIndex !== -1) {
						let concatSeries =
							service.chartMetaData.seriesData.analog.concat(
								service.chartMetaData.seriesData.digital
							);
						let currentTagValuesAndColors = [];
						let allConcatSeriesOnThisYAxis = [];
						service.chartMetaData.yAxisInstances[
							yaxisInstancesIndex
						].tagIds.forEach((tagId) => {
							// let service.chart.series[chartSeriesIndexForTag].yData[service.chart.series[chartSeriesIndexForTag].yData.length - 1]
							let concatSeriesIndex = concatSeries.findIndex(
								(cs) => {
									return tagId === cs.tagId;
								}
							);
							if (concatSeriesIndex !== -1) {
								allConcatSeriesOnThisYAxis.push(
									concatSeries[concatSeriesIndex]
								);
							}
							let tagObject =
								service.dataService.cache.tagsObject[tagId];
							if (tagObject != undefined) {
								let formattedTagObject = {
									TagId: tagId,
									Value: series.data[
										series.data.length - 1
									][1],
									HexColor: "",
									JBTStandardObservationId:
										tagObject.JBTStandardObservationId,
								};
								let foundColorSetting =
									service.widgetObject.TagGraphWidgetTags.find(
										(tgwt) => {
											return tgwt.TagId === tagId;
										}
									);
								formattedTagObject.HexColor =
									foundColorSetting.HexColor;
								currentTagValuesAndColors.push(
									formattedTagObject
								);
							}
						});
						let htmlString = "";
						let smallestFont: any = 45;
						currentTagValuesAndColors.forEach((tagObject) => {
							let mathRoundIndividualSeries: any =
								service._decimalPlacesPipe.transform(
									tagObject.Value,
									1,
									tagObject.JBTStandardObservationId
								);
							let decimals = String(
								Math.abs(mathRoundIndividualSeries)
							).length;
							if (decimals === 1) {
								// smallestFont = 45
							} else {
								let calculatedFont = 45 / decimals + 22.5;
								if (calculatedFont < smallestFont) {
									smallestFont = calculatedFont;
								}
							}
						});
						currentTagValuesAndColors.forEach((tagObject) => {
							// let fontSize = 45 / ((currentTagValuesAndColors.length + 1) / 2)
							let htmlColorString = "";
							if (
								tagObject.HexColor !== null &&
								tagObject.HexColor !== ""
							) {
								htmlColorString =
									"color: " +
									tagObject.HexColor +
									"!important;";
							} else {
								let color = allConcatSeriesOnThisYAxis.find(
									(yaxisseries) => {
										return (
											yaxisseries.tagId ===
											tagObject.TagId
										);
									}
								).color;
								htmlColorString =
									"color: " + color + "!important;";
							}
							let mathRoundIndividualSeries =
								service._decimalPlacesPipe.transform(
									tagObject.Value,
									1,
									tagObject.JBTStandardObservationId
								);
							htmlString =
								htmlString +
								'<div style="display: block"><p style="font-size: ' +
								smallestFont +
								"px; font-family: Orbitron; font-weight: 800;" +
								htmlColorString +
								'">' +
								mathRoundIndividualSeries +
								"</p></div><br><br>";
						});
						if (!Global.isMobile) {
							service.chart.yAxis[yaxisInstancesIndex].setTitle(
								{ text: htmlString },
								true
							);
						}
						this.recentInsertions = false;
					}
				});
			}
		}
	}

	updateSingleChartOnInterval() {
		if (this.chartSingleSeries.length > 0 && this.recentInsertions) {
			if (this.materialTabSelected === 1) {
				this.chartSingleSeries.forEach((cs, index) => {
					let tagRecordInstance = {
						asset: cs.Asset,
						jbtStandardObservationName:
							cs.JBTStandardObservationName,
						site: cs.siteObject?.Name,
						JavascriptDate: new Date(
							cs.data[cs.data.length - 1][0]
						),

						SiteLocalJavascriptDate: new Date(
							this.utilityService.convertFromUtcToLocalToSite(
								cs.data[cs.data.length - 1][0],
								this.chartSingleSeries[index].siteObject
									.UTCTimeOffset,
								true
							)
						),
						UTCJavascriptDate: new Date(
							this.utilityService.convertFromUtcToLocalToSite(
								cs.data[cs.data.length - 1][0],
								0,
								true
							)
						),
						Name: cs.longTagName,
						Value: cs.data[cs.data.length - 1][1],
					};
					this.rawGridData.push(tagRecordInstance);
				});
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.getTableDataBetweenExtremes(this.rawGridData));
				}
			}

			if (this.materialTabSelected === 0) {
				this.chart.series[0].setData(
					this.chartSingleSeries[0].data,
					true
				);
				let htmlString = "";
				let smallestFont: any = 45;
				console.log(
					this.chartSingleSeries[0].data[
						this.chartSingleSeries[0].data.length - 1
					][1]
				);

				let mathRoundIndividualSeries: any =
					this._decimalPlacesPipe.transform(
						this.chartSingleSeries[0].data[
							this.chartSingleSeries[0].data.length - 1
						][1],
						1,
						this.chartSingleSeries[0].JBTStandardObservationId
					);
				let decimals = String(
					Math.abs(mathRoundIndividualSeries)
				).length;

				if (decimals === 1) {
					// smallestFont = 45
				} else {
					let calculatedFont = 45 / decimals + 22.5;
					if (calculatedFont < smallestFont) {
						smallestFont = calculatedFont;
					}
				}
				htmlString =
					htmlString +
					'<div style="display: block"><p style="font-size: ' +
					smallestFont +
					'px; font-family: Orbitron; font-weight: 800;">' +
					mathRoundIndividualSeries +
					"</p></div><br><br>";
				if (!Global.isMobile) {
					this.chart.yAxis[0].setTitle({ text: htmlString }, true);
				}
			}
			this.recentInsertions = false;
		}
	}

	processTagWidgetSignalRObservations(newTag) {
		var service = this;
		let signalRData = service.dataService.GetJsonFromSignalR(newTag);
		let tagObject = service.dataService.cache.tagsObject[signalRData.i];

		if (tagObject == undefined) {
			this.dataService.addTagIdToMissingTagsList(signalRData);
		}

		if (
			service.GraphTagsData &&
			service.chart &&
			service.chart.series != undefined
		) {
			if (
				service.GraphTagsData.some((t) => {
					return t.Tag.Id == signalRData.i;
				})
			) {
				if (service.debugMode) {
					console.log("Tag Update from the Dataservice = %O", newTag);
					console.log(service.chart.series);
				}
				var chartSeriesIndexForTag = this.chartWidgetSeries.findIndex(
					(s) => {
						return s.tagId == signalRData.i;
					}
				);
				//The chart series might have been removed by the user. Check for a non null value before proceding.
				if (chartSeriesIndexForTag !== -1) {
					service.timeZoneType =
						service.dashboardService.determineTimeZoneType(
							service.widgetObject
						);
					if (service.timeZoneType === "Site Time") {
						if (
							!_.isNil(
								this.chartWidgetSeries[chartSeriesIndexForTag]
									.siteObject
							)
						) {
							let changedTime =
								service.utilityService.convertFromUtcToLocalToSite(
									signalRData.d,
									this.chartWidgetSeries[
										chartSeriesIndexForTag
									].siteObject.UTCTimeOffset,
									true
								);
							// console.log(changedTime);
							if (
								this.evaluateIfPointIsNew(
									changedTime,
									this.chartWidgetSeries[
										chartSeriesIndexForTag
									].data
								)
							) {
								this.chartWidgetSeries[
									chartSeriesIndexForTag
								].data.push([changedTime, +signalRData.v]);
								this.chartWidgetSeries.forEach(
									(series, index) => {
										if (index !== chartSeriesIndexForTag) {
											//find the offset time based off the tag.
											series.data.push([
												service.utilityService.convertFromUtcToLocalToSite(
													signalRData.d,
													series.siteObject
														.UTCTimeOffset,
													true
												),
												series.data[
													series.data.length - 1
												][1],
											]);

											// service.chart.series[index].addPoint(
											// 	[
											// 		service.utilityService.convertFromUtcToLocalToSite(
											// 			signalRData.d,
											// 			series.options.siteObject
											// 				.UTCTimeOffset,
											// 			true
											// 		),
											// 		service.chart.series[index].yData[
											// 			service.chart.series[index].yData
											// 				.length - 1
											// 		],
											// 	],
											// 	true,
											// 	true
											// );
										}
									}
								);
							}

							// service.chart.series[
							// 	chartSeriesIndexForTag
							// ].addPoint(
							// 	[changedTime, signalRData.v],
							// 	true,
							// 	true
							// );
						}
						//now that we have added the point for the data that was changed, we need to do it for all the series with their offsets.
					} else if (service.timeZoneType === "UTC Time") {
						let changedTime =
							service.utilityService.convertFromUtcToLocalToSite(
								signalRData.d,
								0,
								true
							);
						if (
							this.evaluateIfPointIsNew(
								changedTime,
								this.chartWidgetSeries[chartSeriesIndexForTag]
									.data
							)
						) {
							this.chartWidgetSeries[
								chartSeriesIndexForTag
							].data.push([changedTime, +signalRData.v]);

							this.chartWidgetSeries.forEach((series, index) => {
								if (index !== chartSeriesIndexForTag) {
									//find the offset time based off the tag.
									series.data.push([
										service.utilityService.convertFromUtcToLocalToSite(
											signalRData.d,
											0,
											true
										),
										series.data[series.data.length - 1][1],
									]);
								}
							});
						}
					} else {
						if (
							this.evaluateIfPointIsNew(
								signalRData.d,
								this.chartWidgetSeries[chartSeriesIndexForTag]
									.data
							)
						) {
							this.chartWidgetSeries[
								chartSeriesIndexForTag
							].data.push([signalRData.d, signalRData.v]);
							let tagRecordInstance = {
								asset: this.chartWidgetSeries[
									chartSeriesIndexForTag
								].Asset,
								jbtStandardObservationName:
									this.chartWidgetSeries[
										chartSeriesIndexForTag
									].JBTStandardObservationName,
								site: this.chartWidgetSeries[
									chartSeriesIndexForTag
								].siteObject?.Name,
								JavascriptDate: new Date(signalRData.d),
								SiteLocalJavascriptDate: new Date(
									service.utilityService.convertFromUtcToLocalToSite(
										signalRData.d,
										this.chartWidgetSeries[
											chartSeriesIndexForTag
										].siteObject.UTCTimeOffset,
										true
									)
								),
								UTCJavascriptDate: new Date(
									service.utilityService.convertFromUtcToLocalToSite(
										signalRData.d,
										0,
										true
									)
								),
								Name: this.chartWidgetSeries[
									chartSeriesIndexForTag
								].longTagName,
								Value: signalRData.v,
							};
							this.rawGridData.push(tagRecordInstance);
							this.chartWidgetSeries.forEach((series, index) => {
								if (index !== chartSeriesIndexForTag) {
									series.data.push([
										signalRData.d,
										series.data[series.data.length - 1][1],
									]);
								}

								let tagRecordInstance = {
									asset: this.chartWidgetSeries[
										chartSeriesIndexForTag
									].Asset,
									jbtStandardObservationName:
										this.chartWidgetSeries[
											chartSeriesIndexForTag
										].JBTStandardObservationName,
									site: this.chartWidgetSeries[
										chartSeriesIndexForTag
									].siteObject?.Name,
									JavascriptDate: new Date(signalRData.d),
									SiteLocalJavascriptDate: new Date(
										service.utilityService.convertFromUtcToLocalToSite(
											signalRData.d,
											this.chartWidgetSeries[
												chartSeriesIndexForTag
											].siteObject.UTCTimeOffset,
											true
										)
									),
									UTCJavascriptDate: new Date(
										service.utilityService.convertFromUtcToLocalToSite(
											signalRData.d,
											0,
											true
										)
									),
									Name: this.chartWidgetSeries[
										chartSeriesIndexForTag
									].longTagName,
									Value: series.data[
										series.data.length - 1
									][1],
								};
								this.rawGridData.push(tagRecordInstance);
							});
						}
					}

					// service.chart.redraw();
					// vm.chartDataUpdatePending = true;
				}
				//console.log("chartSeriesForTag = %O", chartSeriesForTag);
				//The chart series might have been removed by the user. Check for a non null value before proceding.
			}
		}
	}

	private evaluateIfPointIsNew(newPointTime: any, data: any[]): boolean {
		if (
			data.filter((point) => point[0] === newPointTime).length > 0 ||
			data[data.length - 1][0] >= newPointTime
		) {
			if (this.debugMode) {
				console.info(
					"Rejection",
					newPointTime,
					data[data.length - 1][0]
				);
			}
			return false;
		} else {
			this.recentInsertions = true;
			return true;
		}
	}

	initialize() {
		this.configureDateColumnsForTimezoneType();
		if (!_.isNil(this.widgetObject)) {
			if (
				this.widgetObject.WidgetId === null ||
				this.widgetObject.WidgetId === ""
			) {
				return;
			}
			this.isDataLoading = true;

			if (this.debugMode) {
				console.log("widget", this.widgetObject);
				console.log("dashboard", this.dashboard);
			}
			this.marginalAndPreAggregatedObservations = [];
			this.pointCount = 0;
			var timedifference = new Date().getTimezoneOffset();
			Highcharts.setOptions({
				global: {
					timezoneOffset: timedifference,
				},
				colors: ["#EC2515", "#0083F5", "#00D6B4", "#D451D9", "#FF8379"],
			});

			let retrievalDates =
				this.dashboardService.determineProperTimeScopeForWidget({
					widgetObject: this.widgetObject,
					UTCConfiguration: true,
				});

			let statementToRetrieveTags = "";
			let statement = "";
			this.timeScopeMessage =
				"No data available for selected time scope.";
			if (this.widgetObject.canvasWidgetHasTagSeries == false) {
				this.isDataLoading = false;
				this.displayTimeScopeMessage = true;
				this.timeScopeMessage =
					"Please select atleast one JBTStandardObservation from the Canvas Widget Settings";
			} else {
				if (this.widgetObject.canvasWidgetHasTagSeries) {
					statementToRetrieveTags =
						"API.GetTagIdsForCanvasWidgetTagGraph @CanvasWidgetTemplateId = " +
						parseInt(this.widgetObject.WidgetId) +
						", @AssetId = " +
						this.widgetObject.WidgetAssetId;

					statement =
						"API.GetObservationAggregatedHighChartValuesWithEpochTime " +
						"@CanvasWidgetTemplateId = " +
						this.widgetObject.WidgetId +
						", @AssetId = " +
						this.widgetObject.WidgetAssetId +
						", @startDateTimeEpoch = '" +
						retrievalDates.queryStartDate +
						"', @endDateTimeEpoch = '" +
						retrievalDates.queryEndDate +
						"'";
				} else {
					statementToRetrieveTags =
						"API.GetTagIdsForWidgetTagGraph @WidgetId = " +
						parseInt(this.widgetObject.WidgetId);

					statement =
						"API.GetObservationAggregatedHighChartValuesWithEpochTime " +
						"@widgetId = " +
						this.widgetObject.WidgetId +
						", @startDateTimeEpoch = '" +
						retrievalDates.queryStartDate +
						"', @endDateTimeEpoch = '" +
						retrievalDates.queryEndDate +
						"'";
				}
				this.dataService
					.SQLActionAsPromise(statementToRetrieveTags)
					.then((widgetTags: any) => {
						let gseAssetTypeIds = [
							86292, 86293, 86294, 86295, 86296, 86297, 86298,
							86299, 86300,
						];
						let assetIds = [];
						let gateSystemIds = [];
						let assetTypeIds = [];
						this.assetObjects = [];

						this.widgetObject.TagGraphWidgetTags = widgetTags;
						widgetTags.forEach((stReturnedTag) => {
							assetIds.push(stReturnedTag.AssetId);
							gateSystemIds.push(
								stReturnedTag.GateSystemId.toString()
							);
							assetTypeIds.push(
								stReturnedTag.AssetTypeId.toString()
							);
							var existingAsset = this.assetObjects.firstOrDefault((asset:IAsset) => asset.Id == stReturnedTag.AssetId);
							if (!existingAsset) {
								this.assetObjects.push(this.dataService.cache.assetsObject[stReturnedTag.AssetId]);
							}
						});
						if (
							assetIds.every((i) => i === assetIds[0]) &&
							assetTypeIds.every(
								(iteration) => iteration === assetTypeIds[0]
							) &&
							parseInt(assetTypeIds[0]) > 0 &&
							gseAssetTypeIds.includes(parseInt(assetTypeIds[0]))
						) {
							this.widgetObject.AllowSettingsSelection = {
								allow: true,
								type: "gse",
								assetTypeId: parseInt(assetTypeIds[0]),
								assetId: parseInt(assetIds[0]),
							};
						} else if (
							gateSystemIds.every((tagIteration) => {
								return tagIteration === gateSystemIds[0];
							}) &&
							parseInt(gateSystemIds[0]) > 0
						) {
							//do stuff
							console.log("All the same");
							this.widgetObject.WidgetGateSystemId = parseInt(
								gateSystemIds[0]
							);
							this.widgetObject.AllowSettingsSelection = {
								allow: true,
								type: "gate",
								gateSystemId:
									this.widgetObject.WidgetGateSystemId,
							};
						} else {
							//dont allow them to switch gates since not all tags belong to the same gate.
							this.widgetObject.AllowSettingsSelection = false;
						}
						//finish initializing widget
						this.retrieveObservationValues(statement);
					});
			}
		} else {
			console.log(Global.Theme);
			this.isDataLoading = true;

			// console.log("widget", this.widget);
			// console.log("dashboard", this.dashboard);
			this.pointCount = 0;

			// this.loadingMessageArray = [];
			var timedifference = new Date().getTimezoneOffset();
			Highcharts.setOptions({
				global: {
					timezoneOffset: timedifference,
				},
				colors: ["#EC2515", "#0083F5", "#00D6B4", "#D451D9", "#FF8379"],
			});

			let retrievalDates =
				this.dashboardService.determineProperTimeScopeForWidget({
					UTCConfiguration: true,
				});

			let asset =
				this.dataService.cache.tagsObject[
					this.tagModalInputObject.TagId
				].Asset;
			let assetObjectInCache =
				this.dataService.cache.assetsObject[asset.Id];
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: assetObjectInCache.TagNamePrefix,
				IsPopup: true,
			};

			Global.User.DebugMode &&
				console.log(
					this.componentName + "this.widgetGroupSettings = %O",
					this.widgetGroupSettings
				);
			//this.signalRCore.joinParentSystemGroupsThroughSignalR(this.widgetGroupSettings);

			let statement =
				"API.GetObservationAggregatedHighChartValuesWithEpochTime " +
				"@tagId = " +
				this.tagModalInputObject.TagId +
				", @startDateTimeEpoch = '" +
				retrievalDates.queryStartDate +
				"', @endDateTimeEpoch = '" +
				retrievalDates.queryEndDate +
				"'";
			this.retrieveObservationValues(statement);
		}
		this.getSignalRUpdates();
	}

	getSignalRUpdates() {
		var tagNamePrefixesString = "";
		if (this.widgetGroupSettings != undefined) {
			tagNamePrefixesString = this.widgetGroupSettings.GroupList;
		}
		else 
		{
			tagNamePrefixesString = this.dataService.cache.assetsObject[+this.widgetObject.AssetId].TagNamePrefix;
		}

		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		this.signalRCore.joinGroups();

		Global.User.DebugMode && console.log(this.componentName + "this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid,
										 WidgetName: this.widgetObject.WidgetName, 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.processTagPopupSignalRObservations(tag);
				});
			});
	}

	GatePanel(graphTagsData) {
		var gate;
		var gateIds = [];
		graphTagsData.forEach((tag, index) => {
			// let asset = this.dataService.cache.assets.find(asset => {
			//     return graphTagsData[index].AssetName == asset.
			// });

			//tag.ParentSystemId
			gateIds[index] = graphTagsData[index].ParentSystemId;
		});
		if (gateIds.every((val, i, arr) => val === arr[0])) {
			let result = this.dataService.cache.systems.filter((gs) => {
				return gs.Id == gateIds[0];
			});

			this.gates = result.map((g) => {
				let outputObject = {
					Id: g.Id,
					Name: g.Name,
					Assets: g.Assets,
				};
				return outputObject;
			});
			gate = gateIds[0];
			this.widgetObject.WidgetTerminalSystemId = result[0].ParentSystemId;
		} else {
			gate = 0;
		}

		return gate;
	}

	CreateChartSingleTag(darkTheme) {
		console.log("CreateChart called");
		this.rawGridData = [];
		this.chartSingleSeries = this.chartMetaData.seriesData.analog.concat(
			this.chartMetaData.seriesData.digital
		);

		console.log(this.chartSingleSeries);

		if (this.timeZoneType === "Site Time") {
			this.chartSingleSeries.forEach((se) => {
				se.data = se.siteDateDataArray;
			});
		} else if (this.timeZoneType === "UTC Time") {
			this.chartSingleSeries.forEach((se) => {
				se.data = se.utcDateDataArray;
			});
		}
		this.chartSingleSeries.forEach((cs, csIndex) => {
			cs.data.forEach((dataPoint, index) => {
				let tagRecordInstance = {
					asset: cs.Asset,
					jbtStandardObservationName: cs.jbtStandardObservationName,
					site: cs.siteObject?.Name,

					JavascriptDate: new Date(dataPoint[0]),
					SiteLocalJavascriptDate: new Date(
						this.utilityService.convertFromUtcToLocalToSite(
							dataPoint[0],
							this.chartSingleSeries[csIndex].siteObject
								.UTCTimeOffset,
							true
						)
					),
					UTCJavascriptDate: new Date(
						this.utilityService.convertFromUtcToLocalToSite(
							dataPoint[0],
							0,
							true
						)
					),
					// JavascriptDate: new Date(dataPoint[0]),
					Name: cs.longTagName,
					Value: dataPoint[1],
				};
				this.rawGridData.push(tagRecordInstance);
			});
		});
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.getTableDataBetweenExtremes(this.rawGridData));
		}
		let chartSeries = this.chartSingleSeries;
		var chartOptions = {
			chart: {
				zoomType: "x",
				backgroundColor: darkTheme === true ? "rgb(39,41,61)" : "white",
				style: {
					fontFamily: "Poppins, sans-serif",
					color:
						darkTheme === true
							? this.sassHelper.readProperty(
									"body-text-color-light"
							  )
							: this.sassHelper.readProperty(
									"body-text-color-dark"
							  ),
				},
				events: {
					load: this.chartService.updateHighchartLegendLabel,
				},
			},
			rangeSelector: {
				labelStyle: {
					color:
						darkTheme === true
							? this.sassHelper.readProperty(
									"body-text-color-light"
							  )
							: this.sassHelper.readProperty(
									"body-text-color-dark"
							  ),
				},
				enabled: true,
				allButtonsEnabled: true,
				buttons: [
					{
						type: "all",
						text: "All",
					},
				],
				buttonTheme: {
					width: 60,
				},
				selected: 5,
			},
			title: {
				align: "left",
				style: {
					fontFamily: "Poppins, sans-serif",
					fontWeight: "bold",
				},
			},
			subtitle: {
				align: "left",
				style: {
					fontFamily: "Roboto Condensed",
				},
			},
			navigator: {
				enabled: true,
				handles: {
					// backgroundColor: "yellow",
					borderColor: "red",
				},
				adaptToUpdatedData: true,
			},
			legend: {
				enabled: true,
				itemStyle: {
					color:
						darkTheme === true
							? this.sassHelper.readProperty(
									"body-text-color-light"
							  )
							: this.sassHelper.readProperty(
									"body-text-color-dark"
							  ),
				},
			},
			credits: { enabled: false },
			yAxis: this.chartMetaData.yAxisInstances,
			xAxis: {
				type: "datetime",
				dateTimeLabelFormats: {
					// millisecond: "%H:%M:%S.%L",
					// second: "%H:%M:%S",
					// minute: "%H:%M",
					// hour: "%e %b %H:%M",
					// day: "%e %b %H:%M",
					// week: "%e %b",
					month: "%b '%y",
					year: "%Y",
					gridLineColor: "#424242",
					gridLineWidth: 1,
					minorGridLineColor: "#424242",
					minoGridLineWidth: 0.5,
					tickColor: "#424242",
					minorTickColor: "#424242",
					lineColor: "#424242",
				},
				labels: {
					style: {
						color:
							darkTheme === true
								? this.sassHelper.readProperty(
										"body-text-color-light"
								  )
								: this.sassHelper.readProperty(
										"body-text-color-dark"
								  ),
					},
				},
				ordinal: false,
				// tickInterval: 8000,
				minRange: 20000,
				events: {
					setExtremes: (function (component) {
						return function (e: any) {
							component.minExtreme = e.min;
							component.maxExtreme = e.max;
						};
					})(this),
					afterSetExtremes: this.chartService.updateHighchartLegendLabel,
				},
				// plotBands: [
				//   {
				//     // mark the weekend
				//     //color: '#FCFFC5', light yellw
				//     color: "#f8f8f8",
				//     from: Date.UTC(2000, 0, 2),
				//     to: Date.UTC(2999, 0, 4)
				//   }
				// ]
			},
			plotOptions: {
				series: {
					turboThreshold: 1000,
					showInNavigator: true,
					marker: {
						enabled: false,
					},
					states: {
						hover: {
							enabled: false,
						},
					},
					dataGrouping: {
						approximation: "high",
					},
				},
				line: {
					events: {
						legendItemClick: function () {
							console.log("%O", this);
							if (this.visible) {
								this.AskUserCheckForDeleteSeries(this);
							}
						},
					},
					showInLegend: true,
					lineWidth: 3,
				},
			},
			tooltip: {
				pointFormatter: (function (component) {
					return function (e: any) {
						let tooltipText = "";
						if (Global.User.DebugMode) {
							let chartSeriesFound = chartSeries.find(
								(x, index) => index === this.series.index
							);

							tooltipText +=
								'<span style="color:' +
								this.color +
								'"><strong>TagId:</strong> ' +
								chartSeriesFound.tagId +
								" <strong>LongTagName:</strong> " +
								chartSeriesFound.longTagName +
								" </span><br/>";
						}
						tooltipText +=
							'<span style="color:' +
							this.color +
							'">' +
							this.series.name +
							"</span>: <b>" +
							(this.series.options.isDigital
								? component._decimalPlacesPipe.transform(
										this.y,
										0,
										this.series.options
											.JBTStandardObservationId
								  )
								: component._decimalPlacesPipe.transform(
										this.y,
										1,
										this.series.options
											.JBTStandardObservationId
								  )) +
							"</b><br/></span>";
						return tooltipText;
					};
				})(this),
				valueDecimals: 2,
				split: true,
				shared: false,
				followPointer: true,
			},
			series: this.chartSingleSeries,
		};

		// this.isDataLoading = false;
		this.zone.runOutsideAngular(() => {
			if (this.materialTabSelected === 0) {
				let concatSeries = this.chartMetaData.seriesData.analog.concat(
					this.chartMetaData.seriesData.digital
				);
				this.chart = Highcharts.stockChart(
					"tagGraphSingleTag" + this.tagModalInputObject.TagId,
					chartOptions
				);
				this.chart.yAxis.forEach((axisSeries, index) => {
					if (
						this.chartMetaData.yAxisInstances[index] !== undefined
					) {
						let htmlString = "";
						let smallestFont: any = 45;
						concatSeries.forEach((series) => {
							let mathRoundIndividualSeries =
								this._decimalPlacesPipe.transform(
									series.data[series.data.length - 1][1],
									1,
									series.JBTStandardObservationId
								);
							let decimals = String(
								mathRoundIndividualSeries
							).length;

							if (decimals === 1) {
								// smallestFont = 45
							} else {
								let calculatedFont = 45 / decimals + 22.5;
								if (calculatedFont < smallestFont) {
									smallestFont = calculatedFont;
								}
							}
						});

						concatSeries.forEach((series) => {
							let mathRoundIndividualSeries =
								this._decimalPlacesPipe.transform(
									series.data[series.data.length - 1][1],
									1,
									series.JBTStandardObservationId
								);

							htmlString =
								htmlString +
								'<div style="display: block"><p style="font-size: ' +
								smallestFont +
								'px; font-family: Orbitron; font-weight: 800;">' +
								mathRoundIndividualSeries +
								"</p></div><br><br>";
						});
						let offset = 0;
						if (concatSeries.length === 1) {
							offset = 50;
						} else {
							offset = 0;
						}
						if (!Global.isMobile) {
							this.chart.yAxis[index].setTitle(
								{ text: htmlString, offset: offset },
								true
							);
						}
					}
				});
				if (!Global.isMobile) {
					this.chart.update({
						chart: {
							margin: [50, 200, 100, 50],
						},
					});
				}
			}
		});
		this.isDataLoading = false;
	}



	CreateChart(darkTheme) {
		//vm.allDataIsDigital = true;
		if (this.debugMode) {
			console.log("CreateChart called");
		}

		this.chartWidgetSeries = this.chartMetaData.seriesData.analog.concat(
			this.chartMetaData.seriesData.digital
		);
		// if (this.widget.TagGraphWidgetTags) {
		//     chartSeries.forEach(series => {
		//         this.widget.TagGraphWidgetTags.forEach(tagGraphConfiguration => {
		//             if (tagGraphConfiguration.TagId === series.tagId && !_.isNil(tagGraphConfiguration.Ordinal)) {
		//                 series.OrdinalFromDb = tagGraphConfiguration.Ordinal;
		//             }
		//         })
		//     })
		//     chartSeries = _.orderBy(chartSeries, ['OrdinalFromDb'], ['desc'])
		//     chartSeries.forEach(seriesFinal => {
		//         seriesFinal.seriesNumber = seriesFinal.OrdinalFromDb - 1;
		//     })
		// }
		// Global.User.DebugMode && console.log(chartSeries);
		this.rawGridData = [];
		this.chartWidgetSeries.forEach((cs) => {
			let yAxisIndexFound = this.chartMetaData.yAxisInstances.findIndex(
				(yAxisIndex) => {
					return yAxisIndex.tagIds.includes(cs.tagId);
				}
			);
			if (yAxisIndexFound !== -1) {
				cs.yAxis = yAxisIndexFound;
			}

			//creating table data
			cs.data.forEach((dataPoint, index) => {
				let tagRecordInstance = {
					asset: cs.Asset,
					jbtStandardObservationName: cs.jbtStandardObservationName,
					site: cs.siteObject?.Name,
					JavascriptDate: new Date(dataPoint[0]),
					SiteLocalJavascriptDate: new Date(
						cs.siteDateDataArray[index][0]
					),
					UTCJavascriptDate: new Date(cs.utcDateDataArray[index][0]),
					Name: cs.longTagName,
					Value: dataPoint[1],
				};
				this.rawGridData.push(tagRecordInstance);
			});
		});

		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.getTableDataBetweenExtremes(this.rawGridData));
		}

		//here we manipulate the chartSeries object's data property to show either the browser time points or site time timezone points.
		if (this.timeZoneType === "Site Time") {
			this.chartWidgetSeries.forEach((se) => {
				// let arrayOfArraysForDebugging =
				se.data = se.siteDateDataArray;
			});
		} else if (this.timeZoneType === "UTC Time") {
			this.chartWidgetSeries.forEach((se) => {
				// let arrayOfArraysForDebugging =
				se.data = se.utcDateDataArray;
			});
		}
		let chartSeries = this.chartWidgetSeries;
		// this.chartMetaData.yAxisInstances.forEach((yaxisInstance, index) => {
		//     let chartSeriesIndex = chartSeries.findIndex(cs => {
		//         return yaxisInstance.tagIds.includes(cs.tagId);
		//     })
		//     if (chartSeriesIndex !== -1) {
		//         chartSeries[chartSeriesIndex].yAxis = index;
		//     }
		// })

		var genericHeightPercentage =
			100 / this.chartMetaData.yAxisInstances.length;
		var rollingTopPercentage = 100;
		this.chartMetaData.yAxisInstances.forEach((yAxisInstance) => {
			rollingTopPercentage -= genericHeightPercentage;
			yAxisInstance.top = rollingTopPercentage + "%";
			yAxisInstance.height = genericHeightPercentage * 0.9 + "%";
		});
		// let modifiedYAxises = []
		// modifiedYAxises.push(this.chartMetaData.yAxisInstances[0])
		// modifiedYAxises.push(this.chartMetaData.yAxisInstances[1])
		let color = this.sassHelper.readProperty("body-text-color-light");
		console.log("color", color);
		var chartOptions;
		if (this.widgetObject.IsSimpleTagGraph == true) {
			chartOptions = {
				chart: {
					width: 450,
					height: 250,
					spacing: 0,
					backgroundColor:
						darkTheme === true ? "rgb(39,41,61)" : "white",
					style: {
						fontFamily: "Poppins, sans-serif",
						color:
							darkTheme === true
								? this.sassHelper.readProperty(
										"body-text-color-light"
								  )
								: this.sassHelper.readProperty(
										"body-text-color-dark"
								  ),
					},
					events: {
						load: this.chartService.updateHighchartLegendLabel,
					},
				},
				credits: {
					enabled: false,
				},
				xAxis: {
					visible: false,
					events: {
						setExtremes: (function (component) {
							return function (e: any) {
								component.minExtreme = e.min;
								component.maxExtreme = e.max;
							};
						})(this),
						afterSetExtremes: this.chartService.updateHighchartLegendLabel,
					},
				},
				subtitle: {
					text:
						this.chartWidgetSeries[0].jbtStandardObservationName +
						"-" +
						this.chartWidgetSeries[0].JBTStandardObservationId,
					floating: true,
					align: "left",
					x: 25,
					y: 70,
					style: {
						fontWeight: "bold",
						color: "green",
						fontSize: 14,
					},
				},
				tooltip: {
					formatter: function () {
						return this.y;
					},
				},
				yAxis: this.chartMetaData.yAxisInstances,
				legend: {
					enabled: false,
				},
				//series: this.chartWidgetSeries,
				series: [
					{
						type: "areaspline",
						fillColor: this.chartWidgetSeries[0].color,
						color: "green",
						width: 2,
						marker: {
							radius: 0,
						},
						data: this.chartWidgetSeries[0].data,
					},
				],
				annotations: [
					{
						labelOptions: {
							allowOverlap: true,
							borderWidth: 0,
							backgroundColor: "none",
							verticalAlign: "top",
							x: 410,
							y: 220,
						},
						labels: [
							{
								point: {},
								style: {
									fontSize: 14,
									color: "#b2b2b2",
									fontWeight: "bold",
								},
								text: "Today",
							},
							{
								point: {
									y: 25,
								},
								style: {
									fontSize: 16,
									color: "#fbfbfb",
									fontWeight: "bold",
								},
								text: "+180",
							},
						],
					},
				],
			};
		} else {
			chartOptions = {
				chart: {
					zoomType: "x",
					backgroundColor:
						darkTheme === true ? "rgb(39,41,61)" : "white",
					style: {
						fontFamily: "Poppins, sans-serif",
						color:
							darkTheme === true
								? this.sassHelper.readProperty(
										"body-text-color-light"
								  )
								: this.sassHelper.readProperty(
										"body-text-color-dark"
								  ),
					},
					events: {
						load: this.chartService.updateHighchartLegendLabel,
					},
				},

				rangeSelector: {
					labelStyle: {
						color:
							darkTheme === true
								? this.sassHelper.readProperty(
										"body-text-color-light"
								  )
								: this.sassHelper.readProperty(
										"body-text-color-dark"
								  ),
					},
					enabled: true,
					allButtonsEnabled: true,
					buttons: [
						{
							type: "all",
							text: "All",
						},
					],
					buttonTheme: {
						width: 60,
					},
					selected: 5,
				},
				title: {
					align: "left",
					style: {
						fontFamily: "Poppins, sans-serif",
						fontWeight: "bold",
					},
				},
				subtitle: {
					align: "left",
					style: {
						fontFamily: "Roboto Condensed",
					},
				},
				exporting: {
					sourceWidth: 1800,
					sourceHeight: 1200,
					allowHTML: true,
					scale: 1,
				},

				navigator: {
					enabled: true,
					handles: {
						// backgroundColor: darkTheme === true ? "",
						borderColor: "red",
					},
					adaptToUpdatedData: true,
				},
				legend: {
					enabled: true,
					itemStyle: {
						color:
							darkTheme === true
								? this.sassHelper.readProperty(
										"body-text-color-light"
								  )
								: this.sassHelper.readProperty(
										"body-text-color-dark"
								  ),
					},
				},
				credits: { enabled: false },
				yAxis: this.chartMetaData.yAxisInstances,

				xAxis: {
					type: "datetime",
					dateTimeLabelFormats: {
						// millisecond: "%H:%M:%S.%L",
						// second: "%H:%M:%S",
						// minute: "%H:%M",
						// hour: "%e %b %H:%M",
						// day: "%e %b %H:%M",
						// week: "%e %b",
						month: "%b '%y",
						year: "%Y",
						gridLineColor: "#424242",
						gridLineWidth: 1,
						minorGridLineColor: "#424242",
						minoGridLineWidth: 0.5,
						tickColor: "#424242",
						minorTickColor: "#424242",
						lineColor: "#424242",
					},
					labels: {
						style: {
							color:
								darkTheme === true
									? this.sassHelper.readProperty(
											"body-text-color-light"
									  )
									: this.sassHelper.readProperty(
											"body-text-color-dark"
									  ),
						},
					},
					ordinal: false,
					// tickInterval: 1000,
					minRange: 20000,
					events: {
						setExtremes: (function (component) {
							return function (e: any) {
								component.minExtreme = e.min;
								component.maxExtreme = e.max;
							};
						})(this),
						afterSetExtremes: this.chartService.updateHighchartLegendLabel,
					},

					// plotBands: [
					//   {
					//     // mark the weekend
					//     //color: '#FCFFC5', light yellw
					//     color: "#f8f8f8",
					//     from: Date.UTC(2000, 0, 2),
					//     to: Date.UTC(2999, 0, 4)
					//   }
					// ]
				},

				plotOptions: {
					series: {
						turboThreshold: 1000,
						showInNavigator: true,
						marker: {
							enabled: false,
						},
						states: {
							hover: {
								enabled: false,
							},
						},
						dataGrouping: {
							approximation: "high",
						},
					},
					line: {
						events: {
							legendItemClick: (line) => {
								if (this.debugMode) {
								}
								// if (this.visible) {
								this.AskUserCheckForDeleteSeries(line);
								// }
							},
						},
						showInLegend: true,
						lineWidth: 3,
					},
				},

				tooltip: {
					//pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
					pointFormatter: (function (component) {
						return function (e: any) {
							// 	console.log(component);
							// 	console.log(this);
							// 	if (Global.User.DebugMode) {
							// 	tooltipText +=
							// 		'<span style="color:' +
							// 		this.color +
							// 		'"><strong>TagId:</strong> ' +
							// 		hoverPoint.series.options.tagId +
							// 		' <strong>LongTagName:</strong> ' +
							// 		hoverPoint.series.options.longTagName +
							// 		' </span><br/>';
							// }
							// tooltipText +=
							// 	'<span style="color:' +
							// 	hoverPoint.color +
							// 	'">' +
							// 	hoverPoint.series.options.name +
							// 	'</span>: <b>' +
							// 	this._decimalPlacesPipe.transform(
							// 		hoverPoint.y,
							// 		1,
							// 		hoverPoint.series.options.jbtStandardObservationId
							// 	) +
							// 	'</b><br/></span>';

							let tooltipText = "";
							if (Global.User.DebugMode) {
								let chartSeriesFound = chartSeries.find(
									(x, index) => index === this.series.index
								);

								tooltipText +=
									'<span style="color:' +
									this.color +
									'"><strong>TagId:</strong> ' +
									chartSeriesFound.tagId +
									" <strong>LongTagName:</strong> " +
									chartSeriesFound.longTagName +
									" </span><br/>";
							}
							tooltipText +=
								'<span style="color:' +
								this.color +
								'">' +
								this.series.name +
								"</span>: <b>" +
								(this.series.options.isDigital
									? component._decimalPlacesPipe.transform(
											this.y,
											0,
											this.series.options
												.JBTStandardObservationId
									  )
									: component._decimalPlacesPipe.transform(
											this.y,
											1,
											this.series.options
												.JBTStandardObservationId
									  )) +
								"</b><br/></span>";
							return tooltipText;
						};
					})(this),
					split: true,
					shared: true,
					followPointer: true,
				},

				series: chartSeries,
			};
		}

		this.widgetObject.chartOptions = chartOptions;
		// color: limegreen;
		// font-family: 'Orbitron', sans-serif;
		// font-weight: 800;
		// this.isDataLoading = false;
		this.zone.runOutsideAngular(() => {
			let concatSeries = this.chartMetaData.seriesData.analog.concat(
				this.chartMetaData.seriesData.digital
			);

			let element = document.getElementById(
				"tagGraph" + this.widgetObject.WidgetId
			);
			console.log(element);
			this.chart = Highcharts.stockChart(
				"tagGraphWidget" + this.widgetObject.WidgetId,
				chartOptions
			);
			this.isDataLoading = false;

			let smallestFont = 45;
			this.chart.yAxis.forEach((axisSeries, index) => {
				if (this.chartMetaData.yAxisInstances[index] !== undefined) {
					let allConcatSeriesOnThisYAxis = [];
					this.chartMetaData.yAxisInstances[index].tagIds.forEach(
						(tagId) => {
							let concatSeriesIndex = concatSeries.findIndex(
								(cs) => {
									return tagId === cs.tagId;
								}
							);
							if (concatSeriesIndex !== -1) {
								allConcatSeriesOnThisYAxis.push(
									concatSeries[concatSeriesIndex]
								);
							}
						}
					);

					allConcatSeriesOnThisYAxis.forEach((series) => {
						let mathRoundIndividualSeries =
							this._decimalPlacesPipe.transform(
								series.data[series.data.length - 1][1],
								1,
								series.JBTStandardObservationId
							);
						let decimals = String(mathRoundIndividualSeries).length;

						if (decimals === 1) {
							// smallestFont = 45
						} else {
							let calculatedFont = 45 / decimals + 22.5;
							if (calculatedFont < smallestFont) {
								smallestFont = calculatedFont;
							}
						}
					});
				}
			});
			this.chart.yAxis.forEach((axisSeries, index) => {
				if (this.chartMetaData.yAxisInstances[index] !== undefined) {
					let allConcatSeriesOnThisYAxis = [];
					this.chartMetaData.yAxisInstances[index].tagIds.forEach(
						(tagId) => {
							let concatSeriesIndex = concatSeries.findIndex(
								(cs) => {
									return tagId === cs.tagId;
								}
							);
							if (concatSeriesIndex !== -1) {
								allConcatSeriesOnThisYAxis.push(
									concatSeries[concatSeriesIndex]
								);
							}
						}
					);
					let htmlString = "";

					allConcatSeriesOnThisYAxis.forEach((series) => {
						let foundColorSetting =
							this.widgetObject.TagGraphWidgetTags.find(
								(tgwt) => {
									return tgwt.TagId === series.tagId;
								}
							);
						let foundColorSettingHex = foundColorSetting.HexColor;
						let htmlColorString = "";
						if (!_.isNil(foundColorSettingHex)) {
							htmlColorString =
								htmlColorString +
								"color: " +
								foundColorSettingHex +
								" !important;";
						} else {
							htmlColorString =
								htmlColorString +
								"color: " +
								series.color +
								" !important;";
						}
						let mathRoundIndividualSeries =
							this._decimalPlacesPipe.transform(
								series.data[series.data.length - 1][1],
								1,
								series.JBTStandardObservationId
							);

						htmlString =
							htmlString +
							'<div style="display: block"><p style="font-size: ' +
							smallestFont +
							"px; font-family: Orbitron; font-weight: 800;" +
							htmlColorString +
							'">' +
							mathRoundIndividualSeries +
							"</p></div><br><br>";
					});
					let offset = 0;
					if (allConcatSeriesOnThisYAxis.length === 1) {
						offset = 50;
					} else {
						offset = 0;
					}
					if (!Global.isMobile) {
						this.chart.yAxis[index].setTitle(
							{ text: htmlString, offset: offset },
							true
						);
					}
				}
			});
			if (!Global.isMobile) {
				this.chart.update({
					chart: {
						margin: [50, 200, 100, 50],
					},
				});
			}
		});

		//vm.chart = Highcharts.chart('tagGraph' + vm.widget.Id, chartOptions);
		// console.log("vm.chart = %O", vm.chart);
		//show the Gate Panel after the selected Gate is loaded
		// if (this.flag == true) vm.showGatePanel = true;
	}

	GetInterpolatedData(seriesData) {
		var cronologicalListRough = seriesData.flatMap((series) => {
			return series.data.map((dataItem) => {
				return {
					tagId: series.tagId,
					dateMS: dataItem[0],
					value: dataItem[1],
				};
			});
		});
		var cronologicalList = _.sortBy(
			cronologicalListRough,
			(item) => item.dateMS
		);

		// .orderBy(function (item) { return item.dateMS });
		var currentBin = null;

		var tagIdsList = seriesData.map((item) => {
			return item.tagId;
		});
		var isRepeatDateMS = false;

		var bins = cronologicalList
			.map((clItem) => {
				isRepeatDateMS = false;

				if (!currentBin) {
					currentBin = {
						dateMS: clItem.dateMS,
					};
					tagIdsList.forEach((tagId) => {
						currentBin[tagId] =
							clItem.tagId == tagId ? clItem.value : null;
					});
				} else {
					if (currentBin.dateMS < clItem.dateMS) {
						currentBin = { ...currentBin };
						currentBin.dateMS = clItem.dateMS;
					} else {
						isRepeatDateMS = true;
					}
					tagIdsList.forEach((tagId) => {
						if (clItem.tagId == tagId) {
							currentBin[tagId] = clItem.value;
						}
					});
				}

				currentBin.isRepeatDateMS = isRepeatDateMS;

				return isRepeatDateMS ? null : currentBin;
				//return currentBin;
			})
			.filter((item) => {
				return item;
			});

		return bins;
	}

	IsDataDigital(observations) {
		//This will do a distinct on the data, and see if it consists entirely of 0 and 1
		var distinctDataRough = _.take(observations, 500);

		let distinctData = _.uniqBy(distinctDataRough, (a, b) => {
			return (a && (a[1] || 0)) == (b && (b[1] || 0));
		});

		//If there are two distinct values, and their sum = 1 then they have to be a zero and a one.
		if (
			distinctData.length == 2 &&
			distinctData[0][1] + distinctData[1][1] == 1
		) {
			if (this.debugMode) {
				console.log("Series is digital");
			}
			return true;
		} else {
			if (this.debugMode) {
				console.log("Series is analog");
			}
			return false;
		}
	}

	AskUserCheckForDeleteSeries(line) {
		this.swalWithBootstrapButtons
			.fire({
				title: "Do you want to remove this series from this chart?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Yes, delete it!",
				cancelButtonText: "No, cancel!",
				reverseButtons: true,
				animation: false,
			})
			.then((result) => {
				if (result.value) {
					let tagIdAsString = line.target.options.tagId.toString();
					//create stored procedure where yue pass the widget id, and the tag id to have that tag id removed from that widget, then after that promise is fulfilled, run the initialize() function again.
					this.dataService
						.SQLActionAsPromise(
							"API.RemoveTagsFromTagGraphByWidgetId @widgetId = " +
								this.widgetObject.WidgetId +
								", @tagIdList = '" +
								tagIdAsString +
								"'"
						)
						.then((data: any) => {
							this.initialize();
						});
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
					this.swalWithBootstrapButtons.fire({
						title: "Cancelled",
						text: "Your widget has not been deleted",

						animation: false,
					});
				}
			});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log("ngOnDestroy invoked...");
		if (this.updateSingleChartOnIntervalInterval !== undefined) {
			clearInterval(this.updateSingleChartOnIntervalInterval);
		}
		if (this.updateWidgetChartOnIntervalInterval !== undefined) {
			clearInterval(this.updateWidgetChartOnIntervalInterval);
		}
		if (!_.isNil(this.widgetGroupSettings)) {
			////this.signalRCore.leaveParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
		}
		if (this.signalRTagUpdateSubscription !== undefined) {
			this.signalRTagUpdateSubscription.unsubscribe();
		}
		if (this.colorChangedSubscription !== undefined) {
			this.colorChangedSubscription.unsubscribe();
		}

		if (this.widgetResizedSubscription !== undefined) {
			this.widgetResizedSubscription.unsubscribe();
		}

		if (this.tagGraphCreatedSubscription !== undefined) {
			this.tagGraphCreatedSubscription.unsubscribe();
		}

		if (this.signalRSubscription !== undefined) {
			this.signalRSubscription.unsubscribe();
		}

		if (this.dashboardTimeScopeChangedSubscription !== undefined) {
			this.dashboardTimeScopeChangedSubscription.unsubscribe();
		}
		if (this.punchoutTimeZoneChangedSubscription !== undefined) {
			this.punchoutTimeZoneChangedSubscription.unsubscribe();
		}
		if (this.punchoutTimeScopeChangedSubscription !== undefined) {
			this.punchoutTimeScopeChangedSubscription.unsubscribe();
		}
	}
}
