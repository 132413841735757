import { Component, OnInit, ElementRef, OnDestroy, EventEmitter } from "@angular/core";

import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";
import { UserSettingsComponent } from "../../../app/_layout/navbar/user-settings/user-settings.component";
import { IssuesComponent } from "./issues/issues.component";

import swal from "sweetalert2";
import * as moment from "moment";
import { RegistrationComponent } from "../../../app/user/registration/registration.component";
import { PersonalInformationComponent } from "../../../app/user/personal-information/personal-information.component";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { SystemService } from "projects/shared-lib/src/lib/services/system.service";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { ReleaseNotesComponent } from "projects/shared-lib/src/lib/components/release-notes/release-notes.component";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { FileUploadListComponent } from "projects/shared-lib/src/lib/components/file-upload-list/file-upload-list.component";
import { SecurityService } from "projects/shared-lib/src/lib/services/security.service";
import { PersonalAlertEscalationComponent } from "../../../app/user/personal-alert-escalation/personal-alert-escalation.component";
import { AlertControlPanelComponent } from "projects/shared-lib/src/lib/components/alert-control-panel/alert-control-panel.component";

const misc: any = {
	sidebar_mini_active: true
};
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
	public global: IGlobal = Global;
	private listTitles: any[];
	location: Location;

	private toggleButton: any;
	public isCollapsed: boolean = true;
	public CompanyTinyLogoImage: string;

	// -- fields for original fixed-plugin component --
	public sidebarColor = 'blue';
	public state = true;
	public dashboardColor = true;
	public firstName: string;
	public formattedTime: string;
	public hours: number;
	// -- end fields for original fixed-plugin component --

	public changePasswordHasBeenRequested: boolean = false;
	timestampInterval: NodeJS.Timeout;

	public fileImageData: any;
	public FileImageLibraryControlObject: any;
	public countOfTrainingVideosUploaded: number = 0;
	private componentName: string = 'navbar';
	public broadcastMessage: any;
	checkBroadcastMessagesInterval: NodeJS.Timeout;
	fullDataCacheSubscription: any;
	issuesForUser: any;

	constructor(
		location: Location,
		private element: ElementRef,
		private router: Router,
		public toastr: ToastrService,
		public dataService: DataService,
		public systemService: SystemService,
		public utilityService: UtilityService,
		public dialog: MatDialog,
		public securityService: SecurityService
	) {
		this.location = location;
		systemService.themeChange$.subscribe((t) => {
			this.CompanyTinyLogoImage = t;
			Global.User.DebugMode && console.log('theme changed = %O', t);
			this.CompanyTinyLogoImage = Global.CompanyTinyLogoImage;
		});
	}

	ngOnInit() {
		this.timestampInterval = setInterval(() => {
			let time = new Date();
			this.formattedTime = moment(
				time,
				'ddd DD-MMM-YYYY, hh:mm A'
			).format('hh:mm A');
			this.hours = parseInt(this.formattedTime.substring(0, 2));
		}, 1000);

		this.firstName = Global.User.currentUser.GivenName;
		this.CompanyTinyLogoImage = Global.CompanyTinyLogoImage;
		Global.User.DebugMode &&
			console.log(
				'CompanyTinyLogoImage = ' +
				this.CompanyTinyLogoImage +
				', Global.CompanyTinyLogoImage = ' +
				Global.CompanyTinyLogoImage
			);
		this.dataService.companyTinyLogoChanged$.subscribe((image: any) => {
			this.CompanyTinyLogoImage = Global.CompanyTinyLogoImage;
		});

		this.dataService.currentUserChanged$.subscribe((currentUser: any) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName + ' current user changed: %O',
					currentUser
				);
			this.firstName = currentUser.GivenName;
		});

		this.checkBroadcastMessages(true);
		this.checkBroadcastMessagesInterval = setInterval(() => {
			this.checkBroadcastMessages(false);
		}, 300000);

		window.addEventListener('resize', this.updateColor);
		// this.listTitles = ROUTES.filter(listTitle => listTitle);
		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
		this.router.events.subscribe((event) => {
			this.dataService.sidebarClose();
		});

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.determineIssuesNotification();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.determineIssuesNotification();
		}
	}

	determineIssuesNotification() {
		Global.User.issueNotificationCount = this.dataService.cache.userIssues.where((i: any) => {
			if(Global.User.currentUser.GivenName === 'Dylan' && Global.User.currentUser.FamilyName === 'Richard' && Global.User.isAdmin) {
				return (
					((i.AssignedToUserId === 0 || i.AssignedToUserId === null) && (i.Resolved === false || i.Resolved === null)) ||
					(i.CreatorUserId === Global.User.currentUser.Id &&
						i.NotifyCreatorUser === true) ||
					(i.AssignedToUserId === Global.User.currentUser.Id &&
						i.NotifyAssignedUser === true)
				);
			}
			if (Global.User.isAdmin) {
				return (
					(i.CreatorUserId === Global.User.currentUser.Id &&
						i.NotifyCreatorUser === true) ||
					(i.AssignedToUserId === Global.User.currentUser.Id &&
						i.NotifyAssignedUser === true)
				);
			} else {
				return (
					i.CreatorUserId === Global.User.currentUser.Id &&
					i.NotifyCreatorUser === true
				);
			}
		}).toArray().length;
	}

	checkBroadcastMessages(initialCheck: any) {
		if (Global.User.isLoggedIn && Global.User.currentUser?.Organization) {
			var currentDateMS = this.utilityService.DateTimeInMilliseconds(
				new Date()
			);
			this.dataService
				.SQLActionAsPromise(
					'API.RDN_GetApplicationBroadcastMessages @CurrentDateMS=' +
						currentDateMS +
						', @OrganizationId=' +
						Global.User.currentUser.Organization.Id
				)
				.then((data: any) => {
					console.log(
						'total list of broadcast messsages for the current timeframe: %O',
						data
					);
					this.broadcastMessage =
						data.length > 0 ? data.first() : null;
					if (this.broadcastMessage !== null && initialCheck) {
						this.securityService.openSnackBarMessage(
							this.broadcastMessage
						);
						console.log(
							'broadcast messsage to display: %O',
							this.broadcastMessage
						);
					}
				});
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		window.removeEventListener('resize', this.updateColor);
		if (this.timestampInterval) {
			clearInterval(this.timestampInterval);
		}
	}

	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		this.dataService.updateColor(this.isCollapsed);
	};

	minimizeSidebar() {
		this.dataService.minimizeSidebar();
	}

	showSidebarMessage(message) {
		this.utilityService.showToastMessageShared({
			type: 'info',
			message:
				'<span data-notify="icon" class="tim-icons icon-bell-55"></span>',
			title: message,
			override: {
				timeOut: 4000,
				closeButton: true,
				enableHtml: true,
				toastClass: 'alert alert-danger alert-with-icon',
				positionClass: 'toast-top-right',
			},
		});
		// this.toastr.show('<span data-notify="icon" class="tim-icons icon-bell-55"></span>', message, {
		//   timeOut: 4000,
		//   closeButton: true,
		//   enableHtml: true,
		//   toastClass: "alert alert-danger alert-with-icon",
		//   positionClass: "toast-top-right",
		// });
	}

	getTitle() {
		return Global.CurrentMenuItem == null ? 'Dashboard'	: Global.CurrentMenuItem;
	}

	sidebarOpen() {
		this.dataService.sidebarOpen();
	}

	openDialog(type: string): void {
		var innerWidth = window.innerWidth;

		if (type == 'userSettings') {
			const dialogRef = this.dialog.open(UserSettingsComponent, {
				width: innerWidth > 1920 ? '38vw' : '48vw',
				height: '42vh', //,
				// data: {name: this.name, animal: this.animal}
			});
			dialogRef.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log('The dialog was closed');
				//this.animal = result;
			});
		}

		if (type == 'issues') {
			this.dataService
				.SQLActionAsPromise(
					'API.Issues_GetListOfIssues @ViewAllIssues=null, @UserIssueId=null, @UserId=' +
						Global.User.currentUser.Id
				)
				.then((data: any) => {
					if (data.length > 0) {
						//-- user has already has at least one issue outstanding.  Send them to the Issues list. If they are not an administrator, they will see their list of
						//-- outstanding issues.  If they are an administrator, they will see all outstanding issues.  In either case, there is a '+' button at the top, right-hand
						//-- corner of the list to add a new issue if the problem the user is having has not already been logged. --Kirk T. Sherer, April 23, 2021.
						this.router.navigate(['layout/issues']);
					} else {
						//-- just bring up a dialog for logging an issue.
						const dialogRef = this.dialog.open(IssuesComponent, {
							width: innerWidth > 1920 ? '50vw' : '60vw',
							//height: '75vh'//,
							// data: {name: this.name, animal: this.animal}
						});
						dialogRef.afterClosed().subscribe((result) => {
							Global.User.DebugMode &&
								console.log('The dialog was closed');
							//this.animal = result;
						});
					}
				});
		}

		if (type == 'personalInformation') {
			const dialogRef = this.dialog.open(PersonalInformationComponent, {
				width: '45%',
				height: '60%',
			});
			dialogRef.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log('The dialog was closed');
			});
		}

		if (type == 'PersonalAlertEscalation') {
			const dialogRef = this.dialog.open(
				PersonalAlertEscalationComponent,
				{
					width: '45%',
					height: '60%',
				}
			);
			dialogRef.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log('The dialog was closed');
			});
		}

		if (type == 'AlertSettings') {
			const dialogRef = this.dialog.open(
				AlertControlPanelComponent,
				{
					width: '70%',
					height: '70%',
				}
			);
			dialogRef.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log('The dialog was closed');
			});
		}

		if (type == 'trainingVideos') {
			this.openFileImageUploadWindow();
		}

		if (type == 'releaseNotes') {

			this.router.navigate(['layout/releaseNotes']);


		}
	}

	openFileImageUploadWindow() {
		var service = this;
		var entityId = 1; // entityId = 1 for Application documentation is the Training ApplicationFileType. --Kirk T. Sherer, January 15, 2021.
		var entityType = 'Application';
		var title: string = 'Training Videos';

		var sqlStatement = "API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
		service.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log(service.componentName + ": " + sqlStatement + " = %O", data);
			service.fileImageData = data;
			service.FileImageLibraryControlObject = {
				imageKeys: service.fileImageData.filter((i: any) => {
					return i.ImageKey;
				}),
				//---B
				removeUploadFunction(deletedImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((deletedEntity: any) => {
						service.fileImageData.splice(deletedEntity);
					});
				},
				//---B
				addUploadFunction(newImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((newEntity: any) => {
						service.fileImageData.push(newEntity);
					});
				},
				mode: null,
				listTitle: title,
				entityId: entityId,
				entityType: entityType,
				closeUploadWindowFunction() {
					service.FileImageLibraryControlObject = null;
				}
			};

			service.openFileUploadDialog();
		});
	}

	openFileUploadDialog(): void {
		var innerWidth = window.innerWidth;
		const dialogRef = this.dialog.open(FileUploadListComponent, {
			width: '70%',
			height: '70%',
		});

		dialogRef.componentInstance.fileImageLibraryControlObject =
			this.FileImageLibraryControlObject;

		dialogRef.afterClosed().subscribe((result) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName + ': The File Upload dialog was closed'
				);
			this.getCountOfFilesUploaded(
				this.FileImageLibraryControlObject.entityId,
				this.FileImageLibraryControlObject.entityType
			);
		});
	}

	getCountOfFilesUploaded(entityId: number, entityType: string) {
		var sqlStatement = "API.FileImageManagement @Type='Count', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var listOfFiles = data;
			this.countOfTrainingVideosUploaded = listOfFiles.length > 0 ? listOfFiles.first().CountOfFilesUploaded : 0;
		});
	}

	sendPasswordToken() {
		console.log('sendPasswordToken invoked...');
		this.changePasswordHasBeenRequested = true;
		console.log(
			'changePasswordHasBeenRequested = ' +
				this.changePasswordHasBeenRequested
		);
		var username = Global.User.currentUser.Username;
		console.log('username = ' + username);

		if (username) {
			console.log('attempting to send reset password token...');
			this.dataService
				.RequestToResetPassword(username)
				.then((data: any) => {
					console.log('sendPasswordToken: data.body = %O', data.body);
					var email = data.body.first();
					this.sendAlertToUser(email);
					this.changePasswordHasBeenRequested = false;
				});
		}
	}

	sendAlertToUser(email: string) {
		if (email == undefined) {
			swal.fire({
				title: 'Password Change',
				position: 'center',
				html: 'A Change Password email has been sent to your email address.<br />This password change token can be used only once and it will expire in one (1) hour.',
			});
		} else {
			swal.fire({
				title: 'Password Change',
				position: 'center',
				html:
					'A Change Password email has been sent to <strong>' +
					email +
					'</strong>.<br />This password change token can be used only once and it will expire in one (1) hour.',
			});
		}
	}
}
