import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Router } from "@angular/router";
import swal from "sweetalert2";
import { Observable, Subject } from "rxjs";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { QuestionBase } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-base";

@Component({
	selector: "registration",
	templateUrl: "./registration.component.html",
	styleUrls: ["./registration.component.scss"]
})
export class RegistrationComponent implements OnInit {
	public registrationForm: FormGroup;
	public firstName: FormControl = null;
	public lastName: FormControl = null;
	public emailAddress: FormControl = null;
	public claimedOrganization: FormControl = null;
	public mobilePhone: FormControl = null;

	public global: IGlobal = Global;
	public dataCache: any;

	public emailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$";
	public mobilePhonePattern: string = "^[0-9]*$"
	public mouseoverRegistration = false;
	public isLoading: boolean = true;

	public userSettings: any;
	public form: FormGroup;
	public editFields: QuestionBase<any>[];
	public stateAbbreviations: any;
	public formOptions: any;
	public isFormLoading: boolean = true;
	public organizations: any;

	//public state: boolean = true;
	public dashboardColor: boolean = true;
	public isCollapsed: boolean = true;
	public darkTheme: boolean = true;
	public lastSliderSelection: boolean = false;
	public darkTheme$: Subject<any>;
	public selectedColor: string = "dark-blue";
	public sidebarMini: boolean = false;

	constructor(private dataService: DataService, private router: Router) {}

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		this.firstName = new FormControl("", [Validators.required, this.noWhitespaceValidator]);
		this.lastName = new FormControl("", [Validators.required, this.noWhitespaceValidator]);
		this.emailAddress = new FormControl("", [Validators.required, this.noWhitespaceValidator, Validators.pattern(this.emailPattern)]);
		this.claimedOrganization = new FormControl("", [Validators.required, this.noWhitespaceValidator]);
		this.mobilePhone = new FormControl("", [Validators.required, this.noWhitespaceValidator, Validators.pattern(this.mobilePhonePattern)]);
		this.setUpForm();
	}

	setUpForm() {
		this.registrationForm = new FormGroup({
			firstName: this.firstName,
			lastName: this.lastName,
			emailAddress: this.emailAddress,
			claimedOrganization: this.claimedOrganization,
			mobilePhone: this.mobilePhone
		});

		this.isLoading = false;
	}

	public noWhitespaceValidator(control: FormControl) {
		return (control.value || '').trim().length ? null : { 'whitespace': true };       
	}

	register(values: any) {
		Global.User.isRequestingAccount = true;
		console.log("registrationFormValues: %O", values);
		if (values.firstName != null && values.firstName != "" && values.lastName != null && values.lastName != "" && values.emailAddress != null && values.emailAddress != "" ) {
			var paramString = "@FirstName='" + values.firstName.trim() + "', @LastName='" + values.lastName.trim() + "', @EmailAddress='" + values.emailAddress + "'";
			if (values.claimedOrganization) {
				paramString += ", @ClaimedOrganization='" + values.claimedOrganization.trim() + "'";
			}
			if (values.mobilePhone) {
				paramString += ", @MobilePhone='" + values.mobilePhone.trim() + "'";
			}
			console.log("paramString: %O", paramString);
			this.dataService.RequestUserAccount(paramString).then((data: any) => {
				if (data) {
					console.log("dataService.RequestUserAccount: data.body = %O", data.body);
					this.sendNotificationToUser();
					this.router.navigate(["/login"]);
				}
			});
		}
		else {
			//-- user got around the form requirements to enter something for the first name, last name, and email address, so just send them back to the login screen. --Kirk T. Sherer, March 19, 2024. 
			Global.User.isRequestingAccount = false;
			this.router.navigate(["/login"]);
		}
	}

	validateFirstName() {
		//console.log("this.firstName = %O", this.firstName);
		return this.firstName.valid || this.firstName.untouched;
	}

	validateLastName() {
		//console.log("this.lastName = %O", this.lastName);
		return this.lastName.valid || this.lastName.untouched;
	}

	checkForEmptyEmailAddress() {
		return this.emailAddress.untouched;
	}

	validateEmailAddress() {
		return this.emailAddress.valid || this.emailAddress.untouched;
	}

	validateOrganization() {
		return this.claimedOrganization.valid || this.claimedOrganization.untouched;
	}

	validateMobilePhone() {
		return this.mobilePhone.valid || this.mobilePhone.untouched;
	}

	sendNotificationToUser() {
		swal.fire({
			title: "Request Sent",

			position: "center",
			html: "Your request for an iOPS account has been submitted. Please check your <strong>" + this.emailAddress.value + "</strong> email for further communication about your access.",
			showCancelButton: false,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Ok",
			reverseButtons: false
		}).then((result) => {
			Global.User.isRequestingAccount = false;
			this.router.navigate(["user/login"]);
		});
	}

	cancel() {
		swal.fire({
			position: "center",
			title: "Are You Sure?",
			html: "Are you sure you wish to cancel your request for an iOPS account?",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			reverseButtons: false
		}).then((result) => {
			if (result.value) {
				Global.User.isRequestingAccount = false;
				this.router.navigate(["user/login"]);
			}
		});
	}
}
