<div [formGroup]="formGroup" >
    <mat-form-field appearance="outline" [ngClass]="addingNewRecord ? 'opaque-backdrop' : '' ">
        <input id="name" type="text" aria-label="Number" matInput [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (change)="saveSelectedValue($event)">
            <mat-option *ngFor="let obs of filteredList$ | async" [value]="obs">
                {{ obs.SelectorName }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>&nbsp;
    <span *ngIf="showAddForm" class="add-button" [ngClass]="addingNewRecord ? 'opaque-backdrop' : '' " (click)="addNewRecord()" ><i style="font-size: 10px" class="tim-icons white-color icon-simple-add" (click)="addNewRecord()" > </i></span>
    <div *ngIf="addingNewRecord" class="card add-new-record" style="opacity:1 !important">
        <div class="html-modal-backdrop"></div>
        <div class="html-modal-holder" autoscroll="false">
            <div class="card-header" style=" min-height: 65px; padding-bottom:2px;">
                <h4 style="display: inline; vertical-align: bottom;" class="card-title">Adding new {{currentSet.tableDisplay.entityDisplayName}} record</h4>
                <button class="btn btn-icon btn-simple btn-twitter pull-right" (click)="closeRecordAdd()">
                    <i class="tim-icons icon-minimal-left" (click)="closeRecordAdd()" title="Click here to cancel."> </i>
                </button>
            </div>
            <div class="card-body" style="padding-top:2px;">
                <lib-dynamic-form [questions]="currentSet.editor.fields" [options]="editFormOptions" [isInternalDialog]=true (submittedValues)="submitEditForm($event)"></lib-dynamic-form>
            </div>
        </div>
    </div>
</div>
