import {
	Component,
	EventEmitter,
	OnDestroy,
	ViewChild,
	OnInit,
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
} from '@angular/material/tree';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { DataService } from '../../services/data.service';
import _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription, fromEvent } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import {
	DisplayGrid,
	GridsterConfig,
	GridsterItem,
	GridsterItemComponentInterface,
	GridType,
} from 'angular-gridster2';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
/**
 * GSESiteAndAssets data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface GSESiteAndAssets {
	Name: string;
	Id: bigint;
	Assets?: GSESiteAndAssets[];
	Site?: Site;
}

interface Site {
	Name: string;
}

/** Flat node with expandable and level information */
interface FlatNode {
	expandable: boolean;
	name: string;
	Id: bigint;
	level: number;
	siteName: string;
}

/**
 * @title Tree with flat nodes
 */
@Component({
	selector: 'lib-asset-summary',
	templateUrl: './asset-summary-view.component.html',
	styleUrls: ['./asset-summary-view.component.scss'],
})
export class AssetSummaryViewComponent implements OnInit, OnDestroy {
	@ViewChild('parentContentPunchout') parentContentPunchout: any;
	userSites: Array<any>;
	dataObject: any;
	activeNode: any;
	summaryVisible: boolean = false;
	public gseSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	searchString: any;
	isMobile: boolean = false;sub: Subscription;
	currentRoutedAsset: any;
;
	colorChangedSubscription: Subscription;
	//templatesExistingForUser: Array<any>;
	theme: string;
	templateForm: FormGroup;
	options: GridsterConfig;
	navigationOpen: boolean = true;
	initializeTemplate: boolean = false;
	DashboardTimeZones: any;
	DashboardTimeScopes: Array<any>;
	public currentDashboard: any;
	public dashboardTimeScopeChanged: EventEmitter<any> = new EventEmitter();
	public dashboardTimeZoneChanged: EventEmitter<any> = new EventEmitter();
	constructor(
		public dataService: DataService,
		public dashboardService: DashboardService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		this.sub = this.route.params.subscribe((params) => {
			console.log(params.id);
			console.log(this.router)
			if(this.router.url.includes("/layout/assets")) {
				let route = this.router.url.split("/")[3];
				console.log(route);
				if(route !== undefined){

					this.currentRoutedAsset = this.dataService.cache.assets.find(asset => {
						return asset.Id == route
					});
					console.log(this.currentRoutedAsset)

				} else {
					this.currentRoutedAsset = undefined;
				}
			}
			// this.dashboardService.closeAllOpenPopups();
		});
		this.currentDashboard = {};
		this.currentDashboard.TimeZoneId = 1;
		this.currentDashboard.TimeScopeId = 4;
		this.currentDashboard.Name = 'PunchOut Screen';
		this.dashboardService.currentDashboard = this.currentDashboard;

		this.DashboardTimeZones = Global.User.currentUser.DashboardTimeZones;
		this.DashboardTimeScopes = Global.User.currentUser.DashboardTimeScopes;
		this.isMobile = Global.isMobile ? true : false;
		this.initializeTemplate = true;
		// console.log(this.parentContentPunchout.nativeElement.clientHeight);
		this.userSites = [];
		let sites = this.dataService.cache.sites.toArray();
		//GSE,Deicer,Loader,Pushback,PCA-Portable,GPU-Portable,Fire Truck,Refuse Truck
		let assetTypeIds = [
			86292, 86293, 86294, 86295, 86296, 86297, 86298, 86299, 86300, 86313, 86314
		];
		//Filter Sites with GSE Assets
		sites.forEach((site) => {
			site.Assets = site.Assets.filter(function (asset) {
				return assetTypeIds.includes(asset.AssetTypeId);
			});
		});

		sites = sites.filter((site) => {
			return (
				site.Active == true &&
				site.Name !== undefined &&
				site.Assets.length > 0
			);
		});
		sites = _.orderBy(sites, 'Name', 'asc');

		if (Global.User.isAdmin == false) {
			this.userSites = sites.filter((site) => {
				return Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
					site.Id
				);
			});
		} else {
			this.userSites = sites;
		}
		this.theme = Global.Theme;
		this.dataSource.data = this.userSites;
		const TREE_DATA: GSESiteAndAssets[] = this.userSites;
		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				this.theme = data;
			});
	}

	onClick() {
		this.navigationOpen = !this.navigationOpen;
	}
	changeDashboardTimeZone() {
		this.dashboardService.currentDashboard = this.currentDashboard;
		this.dataService.punchoutTimeZoneChanged(this.dashboardService.canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope)
	}
	changeDashboardTimeScope() {
		this.dashboardService.currentDashboard = this.currentDashboard;
		this.dataService.punchoutTimeScopeChanged(this.dashboardService.canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope)
	}
	onClickShowSummary(node) {
		this.activeNode = node;
		this.router.navigate(['layout/assets', node.Id]);
		this.currentRoutedAsset = this.dataService.cache.assets.find(asset => {
			return asset.Id == node.Id
		});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.colorChangedSubscription !== undefined) {
			this.colorChangedSubscription.unsubscribe();
		}
	}

	searchThis(searchword) {
		console.log(searchword);
		this.searchString = searchword;
	}

	// search filter logic start
	filterLeafNode(node: FlatNode): boolean {

		if (!this.searchString) {
			return false;
		}
		//If the search String matches the SiteName then show all the assets for that Site
		if (node.siteName.toLowerCase().indexOf(this.searchString?.toLowerCase()) !== -1) {
			return false;
		}

		return (
			node.name
				.toLowerCase()
				.indexOf(this.searchString?.toLowerCase()) === -1
		);
	}

	filterParentNode(node: FlatNode): boolean {
		if (
			!this.searchString ||
			node.name
				.toLowerCase()
				.indexOf(this.searchString?.toLowerCase()) !== -1
		) {
			return false;
		}
		const descendants = this.treeControl.getDescendants(node);

		if (
			descendants.some(
				(descendantNode) =>
					descendantNode.name
						.toLowerCase()
						.indexOf(this.searchString?.toLowerCase()) !== -1
			)
		) {
			return false;
		}

		return true;
	}

	private _transformer = (node: GSESiteAndAssets, level: number) => {
		return {
			expandable: !!node.Assets && node.Assets.length > 0,
			name: node.Name,
			Id: node.Id,
			level: level,
			siteName: node.Assets ? node.Name : node.Site.Name,
		};
	};

	treeControl = new FlatTreeControl<FlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.Assets
	);

	dataSource = new MatTreeFlatDataSource(
		this.treeControl,
		this.treeFlattener
	);

	hasChild = (_: number, node: FlatNode) => node.expandable;
}
