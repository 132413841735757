import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GseOutOfServiceOrMaintenanceModeComponent } from './gse-out-of-service-or-maintenance-mode.component';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MatTabsModule } from '@angular/material/tabs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { TacticalDashboardOverviewGaugeModule } from '../tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TacticalNavigationModule } from '../tactical-navigation/tactical-navigation.module';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { MaterialModule } from 'projects/desktop/src/app/_other-modules/material.module';
import { ChartTypeSliderModule } from '../chart-type-slider/chart-type-slider.module';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@NgModule({
	declarations: [GseOutOfServiceOrMaintenanceModeComponent],
	imports: [
		CommonModule,
		CenteredMessageModule,
		MatTooltipModule,
		AngularResizeEventModule,
		MatTabsModule,
		ChartsModule,
		LoadingSpinnerModule,
		TacticalDashboardOverviewGaugeModule,
		ProgressBarModule,
		TacticalNavigationModule,
		GaugesModule,
		MaterialModule,
		ChartTypeSliderModule,
		TourMatMenuModule,
	],
	exports: [GseOutOfServiceOrMaintenanceModeComponent],
})
export class GseOutOfServiceOrMaintenanceModeModule {}
