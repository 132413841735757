<div class="card" class="drop-area" id="pasteTarget" (click)="pasteTargetOnClickEvent($event)" (drop)="onDropEvent($event)" dropZone (filesDropped)="onDropEvent($event)" (filesHovered)="dropzoneState($event)">
	<div class="card-header drop-area-field">
		<div style="padding-top: 0px; display: inline-block">
			<h3 class="card-subtitle" [ngClass]="{ 'drop-area-hover': dropzoneActive }" (click)="onClick()">Drag and Drop files here.&nbsp;&nbsp;&nbsp; Click here to open File Explorer.</h3>
		</div>
	</div>
	<div class="card-body file-upload" nv-file-drop="" uploader="uploader">
		<div class="table-responsive">
			<table class="table tablesorter">
				<thead class="text-primary text-header">
					<tr>
						<th class="text-center file-upload-field-header">Type/Thumbnail</th>
						<th class="file-upload-field-header">Last Modified Date</th>
						<th class="file-upload-field-header">File Name</th>
						<th class="file-upload-field-header">Description</th>
						<th class="file-upload-field-header">Progress</th>
						<th class="text-right file-upload-field-header">Size</th>
						<th class="text-right file-upload-field-header" style="padding-right: 10px;">Actions</th>
					</tr>
				</thead>
				<tbody cdkDropList #new="cdkDropList" [cdkDropListData]="newFiles">
					<input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display: none;" />
					<tr *ngFor="let file of files" (click)="viewFile(file)" class="file-upload-field">
						<td class="text-center">
							<div class="photo">
								<div *ngIf="file.iconFile">
									<img alt="photo" [src]="file.iconFile" title="{{ file.name }}" />
								</div>
								<div *ngIf="file.thumbnailImage">
									<img alt="photo" [src]="file.thumbnailImage" title="{{file.name}}" />
								</div>
								<div *ngIf="file.thumbnailUrl && !file.thumbnailImage">
									<img alt="photo" [src]="file.thumbnailUrl" title="{{file.name}}" />
								</div>
							</div>
						</td>
						<td style="white-space:nowrap">{{ file.data.lastModifiedDate | date: "MM/dd/yyyy hh:mm a z" }}</td>
						<td *ngIf="!file.edit">{{ file.data.name }}</td>
						<td *ngIf="file.edit">
							<input type="text" [id]="file.data.name" [value]="file.data.name" title="Enter a name for this file." (change)="updateFileName(file, $event)" />
						</td>
						<td *ngIf="!file.edit">
							{{ file.data.description }}
						</td>
						<td *ngIf="file.edit">
							<input type="text" [id]="file.data.description" [value]="file.data.description" title="Enter a description for this file." (change)="updateFileDescription(file, $event)" />
						</td>
						<td class="text-center">
							<div style="font-size: 0.6rem" *ngIf="file.uploading">
								sent:
								<mat-progress-bar mode="determinate" color="primary" [value]="file.uploadProgress"></mat-progress-bar>
							</div>
							<div style="font-size: 0.6rem" *ngIf="file.uploading">
								received:
								<mat-progress-bar mode="determinate" color="warn" [value]="file.downloadProgress"></mat-progress-bar>
							</div>
						</td>
						<td class="text-right" style="white-space:nowrap;">
							{{ file.data.size | fileSize }}
						</td>
						<td class="text-right" style="white-space:nowrap">
							<button style="z-index: 1;padding:0" class="btn btn-link btn-sm" tooltip="Edit this file" type="button" (click)="editFile(file, $event)">
								<i class="tim-icons icon-pencil"></i>
							</button>
							<button style="z-index: 1;padding:0" class="btn btn-danger btn-link btn-sm" tooltip="Delete" type="button" (click)="deleteFile(file)">
								<i class="tim-icons danger-color icon-simple-remove"> </i>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
