<div *ngIf="!isLoading && displayNoUpdateIntervalMessage === true && displayNoTimeScopeMessage === false"
  class="card card-tasks center" id="card-whole">
  <lib-centered-message centeredMessage="Please select an update interval for the dashboard to see data.">
  </lib-centered-message>
</div>
<div *ngIf="!isLoading && displayNoUpdateIntervalMessage === false && displayNoTimeScopeMessage === true"
  class="card card-tasks center" id="card-whole">
  <lib-centered-message centeredMessage="Please select a time scope for the dashboard to see data.">
  </lib-centered-message>
</div>
<div *ngIf="!isLoading && displayNoUpdateIntervalMessage === true && displayNoTimeScopeMessage === true"
  class="card card-tasks center" id="card-whole">
  <lib-centered-message
    centeredMessage="Please select a time scope and an update interval for the dashboard to see data.">
  </lib-centered-message>
</div>
<div *ngIf="!widgetObject?.SiteList">
	<div class="card-header tactical-widget-card-header-short drag-handler">
		<span *ngIf="global.isMobile == false" style="display: flex; justify-content: center; align-items: center;">
			{{ widgetObject.WidgetName }}
		</span>
	</div>

  <div class="flex-container" style="position: relative;">
	<lib-tactical-navigation [options]="optionsSettingsOnly" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-tactical-navigation>
	<div style="padding-top: 200px;" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">
		<lib-centered-message centeredMessage="Please select a Site from the widget settings."> </lib-centered-message>
	</div>
  </div>
</div>

<div
*ngIf="!isLoading && displayNoUpdateIntervalMessage === false && displayNoTimeScopeMessage === false && widgetObject?.SiteList"
  class="card card-tasks" id="card-whole"
  >

<div
  *ngIf="!isLoading && displayNoUpdateIntervalMessage === false && displayNoTimeScopeMessage === false && widgetObject?.SiteList"
  class="card-header tactical-widget-card-header drag-handler">

  <div style="width:100%">
	<span *ngIf="global.isMobile == false" style="display: flex; justify-content: center; align-items: center;">
		{{ widgetObject.WidgetName }} {{ tab.textLabel }}

		<span class="material-symbols-outlined" (click)="initializeTour()" style="cursor: pointer;" title="Start Tour">
			info
		</span>
	</span>
  </div>

  <mat-card class="card-top">
	<div class="grid-container-top" [tourAnchor]="widgetObject.WidgetId + '-1'">

		<div class="grid-title-1" style="cursor: pointer;" (click)="editWidgetSettings()" title="click to edit widget settings">
			<div >
				<span [ngClass]="headerGridTitle">{{ (dataService.returnSiteListAsArrayOfNames(widgetObject.SiteList).length > 6)?
					(dataService.returnSiteListAsArrayOfNames(widgetObject.SiteList) | slice:0:6)+'...':
					(dataService.returnSiteListAsArrayOfNames(widgetObject.SiteList)) }}
				</span>
			</div>
			<div >
				<p class="grid-subtitle">site</p>
			</div>

		</div>

		<div *ngIf="!widgetObject.WidgetAssetName && widgetObject.WidgetAssetTypeName"  class="grid-title-2" style="cursor: pointer;" (click)="editWidgetSettings()" title="click to edit widget settings">
			<div >
				<span [ngClass]="headerGridTitle">{{widgetObject.WidgetAssetTypeName}}</span>
			</div>
			<div >
				<p class="grid-subtitle">asset type</p>
			</div>

		</div>

		<div *ngIf="widgetObject.WidgetAssetName"  class="grid-title-2" style="cursor: pointer;" (click)="editWidgetSettings()" title="click to edit widget settings">
			<div >
				<span [ngClass]="headerGridTitle">{{widgetObject.WidgetAssetName}}</span>
			</div>
			<div >
				<p class="grid-subtitle">asset</p>
			</div>

		</div>


		<div class="grid-title-3" style="cursor: pointer;" (click)="refreshWidget()" title="click to refresh">
			<div >
				<span [ngClass]="headerGridTitle">{{displayTimeAgoMessage}}</span>
			</div>
			<div>
				<p class="grid-subtitle">last update</p>
			</div>

		</div>

		<div class="grid-title-4" style="cursor: pointer;" (click)="changeWidgetInterval()" title="click to change update interval">
			<div >
				<span [ngClass]="headerGridTitle">{{ updateInterval.Description }}</span>
			</div>
			<div >
				<p class="grid-subtitle">update interval</p>
			</div>

		</div>

		<div class="grid-title-5" style="cursor: pointer;" (click)="changeWidgetTimescope()" title="click to change timescope">
			<div>
				<span [ngClass]="headerGridTitle">{{ timeScopeObject.queryStartDate | date :'shortDate'  }} - {{ timeScopeObject.queryEndDate | date :'shortDate'  }}</span>
			</div>
			<div>
				<p class="grid-subtitle">timescope</p>
			</div>

		</div>
	</div>

  </mat-card>
</div>

  <div (resized)="onResized($event)" style="display: inline-block" style="height: 100%; width: 100%; overflow:auto;">

	<div class="flex-container" style="position: relative; height: 100%;">
		<div [tourAnchor]="widgetObject.WidgetId + '-2'">
			<lib-tactical-navigation [navigationOpened]="navigationOpened" [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-tactical-navigation>
		</div>
		<div
		[ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">

		<div style="height: 100%;">

			<lib-loading-spinner *ngIf="tab.isLoading" [loadingMessage]="'Loading ' + tab.textLabel" [vehicle]="'truck'">
			</lib-loading-spinner>

		<div *ngIf="tab.textLabel != 'Summary'  &&  tab.chartData?.length > 0 " style="height: 50px !important; display: block">
			<lib-chart-type-slider *ngIf="widgetTabConfigurationsFinishedMapping === true"
			(newGraphTypeEvent)="changeGraphType($event)" [graphType]="tab.graphType" [tab] = "tab"
			(toggleLegendChangeEvent)="toggleLegendChange($event)" [legendVisible]="tab.legendVisible">
			</lib-chart-type-slider>
		</div>
        <div *ngIf="tab.textLabel === 'Summary'" style="height: 100%;">
          <div
            *ngIf="displayNoUpdateIntervalMessage === false && summaryArray?.length === 0 && summaryArray1?.length === 0 && summaryArray2?.length === 0 && tab.isLoading === false"
            class="card card-tasks center" id="card-whole">
            <lib-centered-message centeredMessage="No data for currently selected configuration.">
            </lib-centered-message>
          </div>


			<div *ngIf="summaryArray.length > 0" class="tactical-comms-loss-grid-container" [tourAnchor]="widgetObject.WidgetId + '-3'">


				<mat-card class="tactical-comms-loss-card-left">

					<div style="z-index: 1; position: absolute; top: 0px; right: 0px;">
						<button class="btn btn-info btn-link btn-sm" type="button" (click)="openConditionalShadingSettingsPopup(1)">
							<i class="tim-icons icon-settings-gear-63" title="Settings"></i>
						</button>
					</div>

					<div  style="cursor: pointer;" (click)="navigateToTab(1, 'Current Assets In ComsLoss')" title="Click to Navigate to Current Assets In Comms Loss">
						<div class="grid-card-container-center">
							<div [ngClass]="cardTileCenterTitleClass">

								<div style="display: flex; place-items: center;">
									<div style="margin: 0 auto;">

										<kendo-chart [ngClass]="donutClass">
											<ng-template kendoChartDonutCenterTemplate>
											<span [ngStyle]="{ color: percentTextColor[1] }" [ngClass]="holeCenterTextClass">{{ summaryArray[2]/100 | percent }}</span>
											</ng-template>
											<kendo-chart-area background="transparent" [margin]="0"> </kendo-chart-area>
											<kendo-chart-series>
												<kendo-chart-series-item
												type="donut"
												[holeSize]="holeSize"
												[data]="percentAssetsInCommsLoss"
												categoryField="kind"
												field="share"
												colorField="color"
												background="none"
												>
												</kendo-chart-series-item>
											</kendo-chart-series>
											<kendo-chart-legend [visible]="false"></kendo-chart-legend>
										</kendo-chart>

									</div>
								</div>

							</div>

							<div class="grid-card-subtitle-center">
								<p  [style.fontSize] = "fontSize2">{{summaryArray[0]}} out of {{totalAssetLength}} in Comms Loss</p>
							</div>
						</div>
					</div>
				</mat-card>

				<mat-card class="tactical-comms-loss-card-right">

					<div style="z-index: 1; position: absolute; top: 0px; right: 0px;">
						<button class="btn btn-info btn-link btn-sm" type="button" (click)="openConditionalShadingSettingsPopup(2)">
							<i class="tim-icons icon-settings-gear-63" title="Settings"></i>
						</button>
					</div>

					<div  style="cursor: pointer;" (click)="navigateToTab(1, 'Current Assets In ComsLoss')" title="Click to Navigate to Current Assets In Comms Loss">
						<div class="grid-card-container-center">
							<div [ngClass]="cardTileCenterTitleClass">

								<div style="display: flex; place-items: center;">
									<div style="margin: 0 auto;">

										<kendo-chart [ngClass]="donutClass">
											<ng-template kendoChartDonutCenterTemplate>
											<span [ngStyle]="{ color: percentTextColor[2] }" [ngClass]="holeCenterTextClass">{{ summaryArray[3]/100 | percent }}</span>
											</ng-template>
											<kendo-chart-area background="transparent" [margin]="0"> </kendo-chart-area>
											<kendo-chart-series>
												<kendo-chart-series-item
												type="donut"
												[holeSize]="holeSize"
												[data]="percentAssetsNotInCommsLoss"
												categoryField="kind"
												field="share"
												colorField="color"
												background="none"
												>
												</kendo-chart-series-item>
											</kendo-chart-series>
											<kendo-chart-legend [visible]="false"></kendo-chart-legend>
										</kendo-chart>

									</div>
								</div>

							</div>

							<div class="grid-card-subtitle-center">
								<p  [style.fontSize] = "fontSize2">{{summaryArray[1]}} out of {{totalAssetLength}} not in Comms Loss</p>
							</div>
						</div>
					</div>
				</mat-card>

				<mat-card class="tactical-comms-loss-card-middle">
					<div  style="cursor: pointer;" (click)="navigateToTab(4, 'Top Comms Loss by Asset')" title="Click to Navigate to Top Comms Loss by Asset">
						<div class="grid-card-container-center">
							<div [ngClass]="cardWideTileCenterTitleClass">
								<span>{{summaryArray1[2]}}</span>
							</div>
							<div class="grid-card-subtitle-center">
								<p> highest comms loss </p>
							</div>
							<div class="grid-card-footer-center">
								<p [style.fontSize] = "fontSize1"> {{summaryArray1[0]}} on {{summaryArray1[1]}} </p>
							</div>
						</div>
					</div>
				</mat-card>



				<mat-card class="tactical-comms-loss-card-footer">
					<div  style="cursor: pointer;" (click)="navigateToTab(2, 'Total Time in ComsLoss For Day')" title="Click to Navigate to Total Time in Comms Loss For Day">
						<div class="grid-card-container-center">
							<div [ngClass]="cardWideTileCenterTitleClass">
								<span>{{summaryArray2[2]}}</span>
							</div>
							<div class="grid-card-subtitle-center">
								<p> highest comms loss </p>
							</div>
							<div class="grid-card-footer-center">
								<p [style.fontSize] = "fontSize1"> {{summaryArray2[0]}} with total {{summaryArray2[1]}} mins </p>
							</div>
						</div>
					</div>
				</mat-card>




			</div>
        </div>
        <div *ngIf="tab.textLabel === 'Current Assets In ComsLoss'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div style="height: 100%">
            <div
              *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
              class="card card-tasks center" id="card-whole">
              <lib-centered-message centeredMessage="No data for currently selected configuration.">
              </lib-centered-message>
            </div>
            <kendo-chart [style.height.%]="100" [transitions]="false"
              *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
              <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>
			  <kendo-chart-value-axis>
				<kendo-chart-value-axis-item [color]="axisItemColor">
				</kendo-chart-value-axis-item>
			  </kendo-chart-value-axis>
              <kendo-chart-series>
                <kendo-chart-series-item [type]="tab.graphType?.name" [data]="tab.chartData" categoryField="dataType" field="total"
                  [autoFit]="true">
                  <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                      <div>
                        <span><strong> {{ dataItem.dataType }}: {{ dataItem.total }}</strong></span><br />
                        <span>
                          <strong> Asset List: </strong>
                          <div *ngFor="let asset of dataItem.tooltip">
                            <strong>{{ asset }}</strong>
                          </div>
                        </span>
                      </div>
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                  <kendo-chart-series-item-labels *ngIf="tab.graphType?.name == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                  [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
              </kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>
        <div *ngIf="tab.textLabel === 'Total Time in ComsLoss For Day'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div
            *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
            class="card card-tasks center" id="card-whole">
            <lib-centered-message centeredMessage="No data for currently selected configuration.">
            </lib-centered-message>
          </div>
          <kendo-chart #chart (legendItemClick)="toggleAllSeries($event)" (seriesClick)="toggleSeries($event)"
            [style.height.%]="100" *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
            <kendo-chart-value-axis>
              <kendo-chart-value-axis-item [title]="{ text: 'Total Time In Comms Loss (Mins)' }" [color]="axisItemColor">
              </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

            <kendo-chart-series>
              <kendo-chart-series-item *ngFor="let item of tab.chartData" [data]="item.items" [name]="item.value"
                field="TotalTimeInAlarmStatusMinutes" categoryField="DateOfObservationDateObject" [type]="tab.graphType?.name" #seriesItems>
                <kendo-chart-series-item-tooltip>
                  <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                    <div>
                      <span><strong> Asset Name: {{ dataItem.ProperAssetName }}</strong></span><br />
                      <span><strong> Fault Duration: {{ dataItem.TotalTimeInAlarmStatusMinutes }} mins</strong></span><br />
                      <span><strong> Fault Count: {{ dataItem.AlarmCount }}</strong></span><br />
                    </div>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }"> </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
            </kendo-chart-legend>
          </kendo-chart>
        </div>
        <div *ngIf="tab.textLabel === 'Detailed Comms Loss Report'" class="parent-table-container" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
          <div
            *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
            class="card card-tasks center" id="card-whole">
            <lib-centered-message centeredMessage="No data for currently selected configuration.">
            </lib-centered-message>
          </div>
          <kendo-chart [style.height.%]="100" [transitions]="false"
            *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
            <kendo-chart-title text="Minutes Assets Were In Comms Loss For TimeScope" [color]="axisItemColor">
            </kendo-chart-title>
			<kendo-chart-value-axis>
				<kendo-chart-value-axis-item [color]="axisItemColor">
				</kendo-chart-value-axis-item>
			  </kendo-chart-value-axis>
            <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

            <kendo-chart-series>
              <kendo-chart-series-item [type]="tab.graphType?.name" [data]="tab.chartData" categoryField="value"
                field="TotalTimeInAlarmStatusMinutes" [autoFit]="true">
                <kendo-chart-series-item-tooltip>
                  <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                    <div>
                      <span><strong> Total Time Asset Was In Fault Status (Minutes): {{ dataItem.TotalTimeInAlarmStatusMinutes }}</strong></span><br />
                      <span>
                        <strong> Fault List: (Note: These may have occured more than once.) </strong>
                        <div *ngFor="let alarm of dataItem.ListOfAlarmTypesUnique">
                          <strong>{{ alarm }}</strong>
                        </div>
                      </span>
                    </div>
                  </ng-template>
                </kendo-chart-series-item-tooltip>
                <kendo-chart-series-item-labels *ngIf="tab.graphType?.name == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}">
            </kendo-chart-legend>

            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
                [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }"> </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
          </kendo-chart>
        </div>
		<div *ngIf="tab.textLabel === 'Top Comms Loss by Asset'" style="height: calc(100% - 50px);cursor: pointer" matTooltip="Click to show chart as a Popup" (click)="showChartAsPopup(tab)">
			<div
			  *ngIf="displayNoUpdateIntervalMessage === false && tab.tableData?.length === 0 && tab.isLoading === false"
			  class="card card-tasks center" id="card-whole">
			  <lib-centered-message centeredMessage="No data for currently selected configuration.">
			  </lib-centered-message>
			</div>
			<kendo-chart [style.height.%]="100" [transitions]="false"
			  *ngIf="tab.chartDataIsReady === true && tab.chartData?.length > 0">
			  <kendo-chart-value-axis>
				<kendo-chart-value-axis-item [color]="axisItemColor"> </kendo-chart-value-axis-item>
			  </kendo-chart-value-axis>
			  <kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>

			  <kendo-chart-series>
				<kendo-chart-series-item [type]="tab.graphType?.name" [data]="tab.chartData" categoryField="value" field="alarmLength"
				  [autoFit]="true">
				  <kendo-chart-series-item-labels *ngIf="tab.graphType?.name == 'donut'" position="outsideEnd" color="#000" [content]="labelContentShort">
				  </kendo-chart-series-item-labels>
				</kendo-chart-series-item>
			  </kendo-chart-series>
			  <kendo-chart-category-axis>
				<kendo-chart-category-axis-item [maxDivisions]="15" [majorGridLines]="{ visible: true, color: gridLineColor }"
				  [color]="axisItemColor" [labels]="{ format: 'd', rotation: 'auto' }"> </kendo-chart-category-axis-item>
			  </kendo-chart-category-axis>
			  <kendo-chart-legend [visible]="tab.legendVisible" [labels]="{color: axisItemColor}"></kendo-chart-legend>
			</kendo-chart>
		</div>

  </div>
</div>
