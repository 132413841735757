import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SabelDataComponent } from './sabel-data.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import {
	GridModule,
	ExcelModule,
	PDFModule,
} from '@progress/kendo-angular-grid';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
	declarations: [SabelDataComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		GridModule,
		ExcelExportModule,
		PDFExportModule,
		ExcelModule,
		PDFModule,
		ButtonModule,
		CenteredMessageModule,
		PipeModule,
		KendoGridParentModule,
		NavigationModule
	],
	exports: [SabelDataComponent],
})
export class SabelDataModule { }
