<router-outlet></router-outlet>

<tour-step-template>
    <ng-template let-step="step">
        <mat-card class="card card-tasks center"  (click)="$event.stopPropagation()">

            <mat-card-title>
                <h4 class="title-text">
					{{step?.title}}
                	<!-- <mat-icon class="title-close close" (click)="tourService.end()">close</mat-icon> -->
					<button class="btn action-btn-link-icon title-close close" title="" type="button" (click)="tourService.end()">
						<i class="tim-icons icon-simple-remove"></i>
					</button>
				</h4>
            </mat-card-title>

			<p>
            <mat-card-content [innerHTML]="step?.content"></mat-card-content>
			</p>

			<p>
            <mat-card-actions align="end">
                <button
                    mat-button
                    class="prev"
                    [disabled]="!tourService.hasPrev(step)"
                    (click)="tourService.prev()"
                >
                    <mat-icon>chevron_left</mat-icon> {{step?.prevBtnTitle}}
                </button>
                <button
                    mat-button
                    class="next"
                    *ngIf="tourService.hasNext(step)"
                    (click)="tourService.next()"
                >
                    {{step?.nextBtnTitle}} <mat-icon>chevron_right</mat-icon>
                </button>
                <button
                    mat-button
                    (click)="tourService.end()"
                    *ngIf="!tourService.hasNext(step)"
                >
                    {{step?.endBtnTitle}}
                </button>
            </mat-card-actions>
			</p>
        </mat-card>
    </ng-template>
</tour-step-template>

<div style="height: calc(100vh); width: calc(100vh)">

	<div
		style="
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		"
	>

		<div *ngIf="dataService.applicationLoadingMessageObject && dataService.applicationLoadingMessageObject.shouldExist">
			<lib-loading-spinner
				[loadingMessage]="
					dataService.applicationLoadingMessageObject.message
				"
				[vehicle]="'plane'"
			></lib-loading-spinner>
		</div>
	</div>
</div>
