import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DashboardEditComponent } from 'projects/shared-lib/src/lib/components/dashboard-edit/dashboard-edit.component';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';



@Component({
  selector: "dashboard-demo",
  templateUrl: "dashboard-demo.component.html",
  styleUrls: ["dashboard-demo.component.scss"],
})
export class DashboardDemoComponent {
  public canvas: any;
  public ctx;
  public datasets: any;
  public data: any;
  public myChartData;
  public clicked = true;
  public clicked1 = false;
  public clicked2 = false;

  constructor(private dialog: MatDialog, private dataService: DataService, private router: Router) { }

  addNewDashboardModal(): void {
    const dashboardEditModal = this.dialog.open(DashboardEditComponent, {
      data: 0,
      panelClass: "no-overflow",
    });

    dashboardEditModal.afterClosed().subscribe((result) => {
      //Global.User.DebugMode && console.log("The modal was closed");
      //if not submitted, then result is undefined
      //Global.User.DebugMode && console.log("addNewDashboardModal result = %O", result);
      if (result !== undefined) {
        this.dataService.dashboardCreatedFromModal();
        Global.CurrentMenuItem = result[0].Name;
        this.router.navigate(["layout/dashboard/" + result[0].Id]);
      }
    });
  }
}
