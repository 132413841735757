<div class="content">
	<div class="row">
		<div class="col-md-12">
			<div class="card card-plain" id="workArea">
				<div class="card-header" id="workAreaCardHeader">
					<h4 class="card-title" style="display: inline-block">Training Courses</h4>
					<p style="display: inline-block" style="font-size: 12px;">iOPS is an incredibly powerful application
						that we have tried to make as easy to use as possible. We created these training courses so that
						you could learn as much as possible about all of the controls iOPS provides, on demand. Each
						course has a set of video lessons available. Click the + symbol to expand each course and see
						the available lessons for that course. </p>



				</div>
				<div class="card-body" id="workAreaCardBody">
					<div class="resizeContainer">
						<kendo-grid [data]="gridSettings.gridData" [loading]="isLoading" [style.height.%]="100"
							[navigable]="true">
							<kendo-grid-column field="Ordinal" [width]="100" title="Course">
								<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"> {{rowIndex +
									1}}</ng-template>
							</kendo-grid-column>
							<kendo-grid-column field="Name" [width]="300" title="Training Course"></kendo-grid-column>
							<kendo-grid-column field="Description"> </kendo-grid-column>
							<div *kendoGridDetailTemplate="let dataItem">
								<lib-training-lessons [category]="dataItem"></lib-training-lessons>
							</div>
						</kendo-grid>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
