import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GsEquipmentNotUsedLastComponent } from './gs-equipment-not-used-last.component';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MatTabsModule } from '@angular/material/tabs';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartTypeSliderModule } from '../chart-type-slider/chart-type-slider.module';
import { TacticalDashboardOverviewGaugeModule } from '../tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { SparklineModule } from '@progress/kendo-angular-charts';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { MaterialModule } from 'projects/desktop/src/app/_other-modules/material.module';
import { TacticalNavigationModule } from '../tactical-navigation/tactical-navigation.module';
import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';

@NgModule({
  declarations: [
    GsEquipmentNotUsedLastComponent
  ],
  imports: [
    CommonModule,
    CenteredMessageModule,
    AngularResizeEventModule,
    MatTabsModule,
    LoadingSpinnerModule,
    MatTooltipModule,
    ChartTypeSliderModule,
    TacticalDashboardOverviewGaugeModule,
    ChartsModule,
	  SparklineModule,
	  ProgressBarModule,
	  MaterialModule,
	  TacticalNavigationModule,
	  TourMatMenuModule,
  ],
  exports: [GsEquipmentNotUsedLastComponent]
})
export class GsEquipmentNotUsedLastModule { }
