<div *ngIf="fullDataCacheExists" class="spacer recursive-data-navigator" >
	<table *ngIf="currentStatus != 'edit-record'" id="t1" style="border-bottom-style: solid; padding:0; margin:0; border-collapse: collapse;" >
		<tr class="rdn-header-row">
			<td class="typeheader" colspan="20" style="padding-left:5px; padding-right:5px;">
				{{ currentSet.tableDisplay.label }} ({{ currentSetDataCountOfRecords > 0 ? currentSetDataCountOfRecords + ' item' + (currentSetDataCountOfRecords > 1 ? 's' : '') : '0 items' }})
				<input class="rdn-input" type='text' [ngModel]='searchText' placeholder="Enter text to search..." (ngModelChange)="searchForText($event)" style="background-color: white !important; color:black !important;" >
				<div *ngIf="allowAddRecord" class='pull-right' (click)="editRecord({type: 'add'})">
					<button class="btn btn-primary btn-round btn-icon mr-1 pull-right" title="Add a new {{currentSet.tableDisplay.entityName != null ? currentSet.tableDisplay.entityName + ' ' : ''}}record for this table." placement="left" (click)="editRecord({type: 'add'})">
						<i style="font-size: 10px" class="tim-icons white-color icon-simple-add" (click)="editRecord({type: 'add'})" > </i>
					</button>
				</div>
				<div *ngIf="allowDownloadList" class='pull-right' >
					<button [disabled]="isLoadingList" class="btn btn-success btn-round btn-icon mr-1 pull-right" title="Download the {{currentSet.tableDisplay.entityName != null ? currentSet.tableDisplay.entityName + ' ' : ''}} list into a text file." placement="left" >
						<i style="font-size: 20px" class="tim-icons white-color icon-cloud-download-93" (click)="downloadList()" > </i>
					</button>
				</div>
				<div *ngIf="allowCreateTagsForAsset" class='pull-right' >
					<button [disabled]="isLoadingList" class="btn mr-1 pull-right" (click)="updateTags()" title="Create / update tags for the current asset." placement="left" >Add/Update Tags</button>
				</div>
			</td>
		</tr>
		<thead>
			<tr style="display: table-row" class="rdn-header-row">
				<th *ngFor="let column of visibleColumns" [title]="column.title" [ngClass]="column.type" [style.width.px]="column.widthUnits == 'px' ? column.width : ''" [style.width.%]="column.widthUnits == '%' || column.widthUnits == null ? column.width : ''" >{{ column.label }}</th>
			</tr>
		</thead>
		<tbody *ngIf="isLoadingList">
			<tr style="display: table-row" ngClass="even-numbered-row-for-table">
				<td [attr.colspan]="numberOfColumns" style="padding: 5px; text-align:center">
					<div class="loading-spinner"></div>
				</td>
			</tr>
		</tbody>
		<tbody *ngIf="currentSetDataCountOfRecords == 0 && !isLoadingList">
			<tr style="display: table-row" ngClass="even-numbered-row-for-table">
				<td [attr.colspan]="numberOfColumns" style="padding: 5px; text-align:center">No records found for <strong>{{currentSet.tableDisplay.label}}</strong>.</td>
			</tr>
		</tbody>
		<tbody *ngIf="currentSetDataCountOfRecords != 0" [ngClass]="{ 'ordinal-list': allowDragAndDrop }" cdkDropList [cdkDropListDisabled]="!allowDragAndDrop" (cdkDropListDropped)="drop($event, currentSetData)">
			<tr id="{{currentSet.tableDisplay.entityName + '_' + i}}" style="display: table-row " *ngFor="let row of currentSetData ; let i = index" [ngClass]="{ 'even-numbered-row-for-table' : i % 2 == 0, 'selected-row' : currentIndex == currentSet.tableDisplay.entityName + '_' + i, 'ordinal-list-box' : allowDragAndDrop } " cdkDrag [cdkDragDisabled]="!allowDragAndDrop">
				<td [attr.colspan]="numberOfColumns">
					<table style="padding:0; margin:0; border-collapse: collapse;" >
						<!-- <tr class="clickable-row" (click)="editRecord(row, 'edit')" > -->
						<tr >
							<td class="entity" *ngFor="let column of visibleColumns" [title]="column.title" [ngClass]="column.type" [style.width.px]="column.widthUnits == 'px' ? column.width : ''" [style.width.%]="column.widthUnits == '%' || column.widthUnits == null ? column.width : ''" >
								<div *ngIf="column.type == 'number'" class="n">
									{{ row[column.name].value }}
								</div>
								<div *ngIf="column.type == 'link'" class="c6">
									<a class="collection" (click)="openInNewWindow(row[column.name].value, $event)" href="">{{ column.linkName }}</a>
								</div>
								<!-- <div *ngIf="column.type == 'list'" class="c7">
									<a class="collection" (click)="window.external.RunUserAction(0,false);" href="">{{ row[column.name].value }}</a>
								</div> -->
								<div *ngIf="column.type == 'set'">
									<div *ngIf="column.name == 'PersonRecord' || column.name == 'AuthorizedSites'" >
										<div *ngIf="currentSet.tableDisplay.entityName == 'Security.iOPSUser'">
											<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value == 1">
												<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="goToSet(row[column.name], $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
											</div>
											<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value != 1" style="z-index: 1" class="btn btn-link-icon" >
												&nbsp;
											</div>
										</div>
									</div>
									<div *ngIf="column.name == 'RecipeConditions' || column.name == 'RuleConditions'">
										<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="goToSet(row[column.name], $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
										<div *ngIf="row['ConditionsInEnglish'].value != null" style="color: rgb(80, 151, 148); white-space: pre;" [title]="row['ConditionsWithDescriptionsInEnglish'].value">{{row['ConditionsInEnglish'].value}}</div>
									</div>
									<div *ngIf="column.name == 'ConditionWatches'">
										<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="goToSet(row[column.name], $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
										<div *ngIf="row['ConditionWatchInEnglish'].value != null" style="color: rgb(80, 151, 148); white-space: pre;">{{row['ConditionWatchInEnglish'].value}}</div>
									</div>
									<div *ngIf="column.name != 'PersonRecord' && column.name != 'AuthorizedSites' && column.name != 'RecipeConditions' && column.name != 'RuleConditions' && column.name != 'ConditionWatches'">
										<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="goToSet(row[column.name], $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
									</div>
								</div>
								<div *ngIf="column.type == 'sendChangePasswordToken'">
									<div *ngIf="currentSet.tableDisplay.entityName == 'Security.iOPSUser'">
										<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value == 1">
											<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="sendChangePasswordToken(row[column.field].value, $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
										</div>
										<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value != 1">
											&nbsp;
										</div>
									</div>
									<div *ngIf="currentSet.tableDisplay.entityName != 'Security.iOPSUser'">
										<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="sendChangePasswordToken(row[column.field].value, $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
									</div>
								</div>
								<div *ngIf="column.type == 'logInAs'">
									<button *ngIf="(column.showButtonField && row[column.showButtonField].value == true) || !column.showButtonField" class="btn mr-1" (click)="logInAs(row[column.field].value, $event)">{{column.countOfRecordsField ? column.countOfRecordsFieldLabel + ' (' + (row[column.countOfRecordsField].value != null ? row[column.countOfRecordsField].value : 0) + ')' : ''}}{{!column.countOfRecordsField ? (column.buttonLabelField != null ? row[column.buttonLabelField].value : column.label + (column.additionalLabelField != null ? ': ' + row[column.additionalLabelField].value : '')) : ''}}</button>
								</div>
								<div *ngIf="column.type == 'creator-user'" class="t form-control">
									{{ row[column.name].value }}
									<div *ngIf="row['CreationDate']?.value != null || row['creationDate']?.value != null" style="color: rgb(149, 194, 192);">{{ (row['CreationDate']?.value != null ? row['CreationDate']?.value : row['creationDate']?.value) | date:'MM/dd/yyyy HH:mm:ss.SSS'}}</div>
								</div>
								<div *ngIf="column.type == 'last-modified-user'" class="t form-control">
									{{ row[column.name].value }}
									<div *ngIf="row['LastModifiedDate']?.value != null || row['lastModifiedDate']?.value != null" style="color: rgb(149, 194, 192);">{{ (row['LastModifiedDate']?.value != null ? row['LastModifiedDate'].value : row['lastModifiedDate'].value) | date:'MM/dd/yyyy HH:mm:ss.SSS'}}</div>
								</div>
								<div *ngIf="column.type == 'requestor-user' && row['RequestedDateMS'] != null" class="t form-control">
									{{ row[column.name].value }}
									<div style="color: rgb(149, 194, 192);">{{row['RequestedDateMS'] | date:'MM/dd/yyyy HH:mm:ss.SSS'}}</div>
								</div>
								<div *ngIf="column.type == 'approver-user' && row['ApprovedDateMS'] != null" class="t form-control">
									{{ row[column.name].value }}
									<div style="color: rgb(149, 194, 192);">{{row['ApprovedDateMS'] | date:'MM/dd/yyyy HH:mm:ss.SSS'}}</div>
								</div>
								<div *ngIf="column.type == 'moved-to-production-user' && row['DateMovedToProductionMS'] != null" class="t form-control">
									{{ row[column.name].value }}
									<div style="color: rgb(149, 194, 192);">{{row['DateMovedToProductionMS'] | date:'MM/dd/yyyy HH:mm:ss.SSS'}}</div>
								</div>
								<div *ngIf="column.type == 'text'" class="t form-control">
									{{ row[column.name].value }}
									<div *ngIf="column.name == 'RecipeName' && row['SeverityLevel'].value != null && row['SeverityLevel'].value == 'Informational'" style="color: rgb(149, 194, 192);">{{row['SeverityLevel'].value}}</div>
									<div *ngIf="column.name == 'RecipeName' && row['SeverityLevel'].value != null && (row['SeverityLevel'].value == 'Alarm' || row['SeverityLevel'].value == 'Critical')" style="color: rgb(226, 53, 30);">{{row['SeverityLevel'].value}}</div>
									<div *ngIf="column.name == 'RecipeName' && row['SeverityLevel'].value != null && row['SeverityLevel'].value == 'Warning'" style="color: rgb(224, 214, 70);">{{row['SeverityLevel'].value}}</div>
								</div>
								<div *ngIf="column.type == 'email'" class="t form-control">
									<div *ngIf="currentSet.tableDisplay.entityName == 'Person'">
										<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value == 1">
											{{ row[column.name].value }}
										</div>
										<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value != 1">
											&nbsp;
										</div>
									</div>
									<div *ngIf="currentSet.tableDisplay.entityName != 'Person'">
										{{ row[column.name].value }}
									</div>
								</div>
								<div *ngIf="column.type == 'file-image'" (click)="viewFile(row[column.name].fileImage)" style="cursor:pointer" class="t form-control">
									<div *ngIf="row[column.name].fileImage.iconFile">
										<img alt="photo" class="photo" src="{{ row[column.name].fileImage.iconFile }}" title="{{row[column.name].fileImage.name}}" />&nbsp;{{row[column.name].fileImage.name}}
									</div>
									<div *ngIf="row[column.name].fileImage.thumbnailImage">
										<img alt="photo" class="photo" [src]="row[column.name].fileImage.thumbnailImage" title="{{row[column.name].fileImage.name}}" />&nbsp;{{row[column.name].fileImage.name}}
									</div>
									<div *ngIf="row[column.name].fileImage.thumbnailUrl && !row[column.name].fileImage.thumbnailImage">
										<img alt="photo" class="photo" [src]="row[column.name].fileImage.thumbnailUrl" title="{{row[column.name].fileImage.name}}" />&nbsp;{{row[column.name].fileImage.name}}
									</div>
								</div>
								<div *ngIf="column.type == 'date'" class="t form-control">
									{{ row[column.name].value | date: 'MM/dd/yyyy HH:mm:ss.SSS'}}
								</div>
								<div *ngIf="column.type == 'boolean'" class="t">
									<div *ngIf="row[column.name].value == true"><span class='true-value'>Yes</span></div>
									<div *ngIf="row[column.name].value == false"><span class='false-value'>No</span></div>
								</div>
								<div *ngIf="column.type == 'money'" class="t form-control">
									{{ row[column.name].value | currency }}
								</div>
								<div *ngIf="column.name == 'actions' && (currentSet.tableDisplay.allowModification || currentSet.tableDisplay.allowDelete)" class="t form-control" style="white-space: nowrap !important;">
									<div *ngIf="currentSet.tableDisplay.entityName == 'Security.iOPSUser' || currentSet.tableDisplay.entityName == 'Person'">
										<button *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value == 1" style="z-index: 1" class="btn btn-link-icon" title="Edit this record." type="button" (click)="editRecord({row: row, type: 'edit', event: $event, indexReference:  i})">
											<i class="tim-icons icon-pencil"></i>
										</button>
										<div *ngIf="currentSet.tableDisplay.allowModification && row['CanEditRecord'].value != 1" style="z-index: 1" class="btn btn-link-icon" >
											&nbsp;
										</div>
										<button *ngIf="currentSet.tableDisplay.allowCopy && row['CanEditRecord'].value == 1" style="z-index: 1;" class="btn btn-link-icon" title="Copy this record." type="button" (click)="copyRecord(row)">
											<i class="tim-icons icon-single-copy-04"></i>
										</button>
										<div *ngIf="currentSet.tableDisplay.allowCopy && row['CanEditRecord'].value != 1" style="z-index: 1" class="btn btn-link-icon" >
											&nbsp;
										</div>
										<button *ngIf="currentSet.tableDisplay.allowDelete && row['CanEditRecord'].value == 1" class="btn btn-link-icon" title="Delete this record." type="button" (click)="deleteRecord(row)">
											<i class="tim-icons danger-color icon-simple-remove"></i>
										</button>
										<div *ngIf="currentSet.tableDisplay.allowDelete && row['CanEditRecord'].value != 1" style="z-index: 1" class="btn btn-link-icon" >
											&nbsp;
										</div>
									</div>
									<div *ngIf="currentSet.tableDisplay.entityName != 'Security.iOPSUser' && currentSet.tableDisplay.entityName != 'Person'">
										<button mat-button *ngIf="currentSet.tableDisplay.hasFileImageTable" style="border:none;z-index: 1" class="btn btn-link-icon">
											<i
												(click)="openFileImageUploadWindow(row['Name'].value, row['Name'].value + ' File Uploads')"
												[ngClass]="{
													'object-contains-files': countOfFilesUploaded >= 1
												}"
												class="tim-icons icon-notes clipboard-larger"
												aria-hidden="true"
												style="cursor: pointer"
												title="View files attached to this Asset.  {{ countOfFilesUploaded > 0 ? countOfFilesUploaded + ' file' + (countOfFilesUploaded > 1 ? 's' : '') + ' uploaded currently.' : '' }}"
											>
											</i>
										</button>
										<button *ngIf="currentSet.tableDisplay.allowModification" style="z-index: 1" class="btn btn-link-icon" title="Edit this record." type="button" (click)="editRecord({row: row, type: 'edit', event: $event, indexReference:  i})">
											<i class="tim-icons icon-pencil"></i>
										</button>
										<button *ngIf="currentSet.tableDisplay.allowCopy" style="z-index: 1;" class="btn btn-link-icon" title="Copy this record." type="button" (click)="copyRecord(row)">
											<i class="tim-icons icon-single-copy-04"></i>
										</button>
										<button *ngIf="currentSet.tableDisplay.allowDelete" class="btn btn-link-icon" title="Delete this record." type="button" (click)="deleteRecord(row)">
											<i class="tim-icons danger-color icon-simple-remove"></i>
										</button>
									</div>
								</div>
							</td>
						</tr>
						<ng-container *ngFor="let columnName of recursiveSetColumnNamesForThisInstance">
							<tr [id]="'rdnRecursionSetContainingDiv_Id' + row[columnName].rdnRecursionSet.containingDivId"  >
								<td *ngIf="selectedRecursiveSet && selectedRecursiveSet.trElement == 'rdnRecursionSetContainingDiv_Id' + row[columnName].rdnRecursionSet.containingDivId" [attr.colspan]="numberOfColumns">
									<lib-recursive-data-navigator [options]="selectedRecursiveSet"></lib-recursive-data-navigator>
								</td>
							</tr>
						</ng-container>
					</table>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<!-- Edit Form -->
<div class="spacer editing-record" *ngIf="currentStatus == 'edit-record'" >
	<div *ngIf="!loadingEditForm" class="card" >
		<div class="card-header">
			<h4 style="display: inline; vertical-align: bottom;" class="card-title">{{editFormTitle}}</h4><br />
			<h6 style="display: inline; vertical-align: bottom; font-style: italic;" class="card-title">Required fields are marked with an asterisk.</h6>
			<button class="btn btn-icon btn-simple btn-twitter pull-right" (click)="backToList()">
				<i class="tim-icons icon-minimal-left" (click)="backToList()" title="Click here to go back to the list."> </i>
			</button>
		</div>
		<div class="card-body" style="margin-top: 5px;">
			<lib-dynamic-form [questions]="currentSet.editor.fields" [options]="editFormOptions" [entityName]="currentSet.tableDisplay.entityName" [hasDynamicPropertyFields]="currentSet.editor.HasDynamicProperties" (notifyParentQuestionsHaveChanged)="reloadRDNQuestions($event)" (submittedValues)="submitEditForm($event)"></lib-dynamic-form>
		</div>
	</div>
</div>
