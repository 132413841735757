import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardCopyComponent } from './dashboard-copy.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
@NgModule({
	declarations: [DashboardCopyComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatTooltipModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatOptionModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatFormFieldModule,
		DynamicFormModule
	],
	exports: [DashboardCopyComponent],
})
export class DashboardCopyModule {}
