<div class="login-page" style="margin-top: 7px;">
	<div class="content">
		<div class="container">
			<div class="card card-login card-white" id="card-whole">
				<div class="card-header registration-card-header login-form">
					<img alt="" [src]="global.imagesUrl + 'assets/img/iOPSProLogo.png'" width="150px" height="100px" style="margin-left: 485px;" />
				</div>
				<div class="card-body login-form" id="registration">
					<span class="fa fa-spinner spinner" *ngIf="isLoading"></span>
					<div *ngIf="!isLoading">
						<div class="row col-sm-12 col-md-12 col-lg-12"><h2 class="title text-center" style="margin-left: 405px; color: black;">Request Account</h2></div>
						<form autocomplete-off [formGroup]="registrationForm" (ngSubmit)="register(registrationForm.value)">
							<!-- First Name -->
							<div class="row col-sm-12 col-md-12">
								<div class="col-sm-2 col-md-2 col-form-label">
									<label for="firstName">First Name*</label>
								</div>
								<div class="col-sm-10 col-md-10">
									<div class="form-group" style="margin-bottom:0 !important">
										<input autocomplete-off autofill="off" class="form-control" title="Please enter your First Name." formControlName="firstName" id="firstName" type="text" required placeholder="{{ !validateFirstName() || mouseoverRegistration ? 'First Name is required' : '' }}" />
									</div>
									<div *ngIf="(firstName.invalid && firstName.touched) || firstName.dirty" >
										<!--question mark(?) is a safe navigation operator-->
										<small *ngIf="firstName.hasError('whitespace')" class="text-danger">Please provide a valid first name.</small>
									</div>
								</div>
							</div>
							<!-- Last Name -->
							<div class="row col-sm-12 col-md-12">
								<div class="col-sm-2 col-md-2 col-form-label">
									<label for="lastName">Last Name*</label>
								</div>
								<div class="col-sm-10 col-md-10">
									<div class="form-group" style="margin-bottom:0 !important">
										<input autocomplete-off class="form-control" title="Please enter your Last Name." formControlName="lastName" id="lastName" type="text" required placeholder="{{ !validateLastName() || mouseoverRegistration ? 'Last Name is required' : '' }}" />
										<div *ngIf="(lastName.invalid && lastName.touched) || lastName.dirty" >
											<!--question mark(?) is a safe navigation operator-->
											<small *ngIf="lastName.hasError('whitespace')" class="text-danger">Please provide a valid last name.</small>
										</div>
	
									</div>
								</div>
							</div>
							<!-- Email Address -->
							<div class="row col-sm-12 col-md-12">
								<div class="col-sm-2 col-md-2 col-form-label">
									<label for="emailAddress">Email Address*</label>
								</div>
								<div class="col-sm-10 col-md-10">
									<div class="form-group" style="margin-bottom:0 !important">
										<input autocomplete-off class="form-control" title="Please enter your valid Email Address. This is how you will be contacted with regard to the new account." formControlName="emailAddress" id="emailAddress" type="text" required placeholder="{{ !validateEmailAddress() || mouseoverRegistration ? 'Email Address is required' : '' }}" />
										<div *ngIf="(emailAddress.invalid && emailAddress.touched) || emailAddress.dirty" >
											<!--question mark(?) is a safe navigation operator-->
											<small *ngIf="emailAddress.hasError('whitespace')" class="text-danger">Please provide your valid email address.</small>
										</div>
									</div>
								</div>
							</div>
							<!-- Claimed Organization -->
							<div class="row col-sm-12 col-md-12">
								<div class="col-sm-2 col-md-2 col-form-label">
									<label for="claimedOrganization">Organization*</label>
								</div>
								<div class="col-sm-10 col-md-10">
									<div class="form-group" style="margin-bottom:0 !important">
										<input autocomplete-off class="form-control" title="Please enter the Organization name where you work." formControlName="claimedOrganization" id="claimedOrganization" type="text" required placeholder="{{ !validateOrganization() || mouseoverRegistration ? 'Organization is required' : '' }}" />
										<div *ngIf="(claimedOrganization.invalid && claimedOrganization.touched) || claimedOrganization.dirty" >
											<!--question mark(?) is a safe navigation operator-->
											<small *ngIf="claimedOrganization.hasError('whitespace')" class="text-danger">Please provide your valid Organization name.</small>
										</div>
									</div>
								</div>
							</div>
							<!-- Mobile Phone -->
							<div class="row col-sm-12 col-md-12">
								<div class="col-sm-2 col-md-2 col-form-label">
									<label for="mobilePhone">Mobile Phone*</label>
								</div>
								<div class="col-sm-10 col-md-10" style="margin-bottom:0 !important">
									<div class="form-group">
										<input autocomplete-off class="form-control" title="Please enter your Mobile Phone. This number will be used for two-factor authentication into the application at a later date." formControlName="mobilePhone" id="mobilePhone" type="text" required placeholder="{{ !validateMobilePhone() || mouseoverRegistration ? 'Mobile Phone is required' : '' }}" />
										<div *ngIf="(mobilePhone.invalid && mobilePhone.touched) || mobilePhone.dirty" >
											<!--question mark(?) is a safe navigation operator-->
											<small *ngIf="mobilePhone.hasError('whitespace')" class="text-danger">Please provide a valid mobile number.</small>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer text-center">
								<div class="link footer-link">{{ !validateEmailAddress() || mouseoverRegistration ? "Email Address is invalid" : "" }}</div>
								<button class="btn btn-danger" type="submit" [disabled]="registrationForm.invalid">Submit Request</button>
								<button class="btn" type="button" (click)="cancel()">Cancel</button>
							</div>
						</form>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
