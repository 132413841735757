import { Component, EventEmitter, Input, OnInit, Optional, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from "rxjs/operators";

import swal from "sweetalert2";

import { ToastrService } from "ngx-toastr";
import { DataService } from "../../../services/data.service";
import { RecursiveDataNavigatorService } from "../../../services/recursive-data-navigator.service";
import { SelectorService } from "../../../services/selector.service";
import { Global } from "../../../_constants/global.variables";

@Component({
	selector: "lib-generic-selector",
	templateUrl: "./generic-selector.component.html",
	styleUrls: ["./generic-selector.component.scss"]
})
export class GenericSelectorComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public question: any;
	@Input() public setName: string;
	@Optional() public options: any;
	@Output() public returnValue: any;
	@Output() public notifyParent: EventEmitter<any> = new EventEmitter();

	public componentName: string = "generic-selector: ";
	public staticList: any;
	public filteredList$: Observable<any[]>;
	public itemSelected$: Observable<any[]>;
	public itemSelected: boolean = false;

	public lastSearchTerm: any;

	public addingNewRecord: boolean = false;
	public currentSet: any;
	public editFormOptions: any;
	public showAddForm: boolean = false;
	
	constructor(private dataService: DataService, private rdnService: RecursiveDataNavigatorService, private selectorService: SelectorService, private toastr: ToastrService) {}

	ngOnInit() {
		var service = this;
		Global.User.DebugMode && console.log(this.componentName + "this.formGroup = %O", this.formGroup);
		Global.User.DebugMode && console.log(this.componentName + "this.question = %O", this.question);

		this.currentSet = this.rdnService.structure.first((s: any) => {
			return s.setName == this.setName;
		});
		Global.User.DebugMode && console.log(this.componentName + "this.currentSet = %O", this.currentSet);

		this.itemSelected$ = this.formGroup.controls[this.question.key].valueChanges.pipe(filter((val) => typeof val === "object" && val !== null));

		this.itemSelected$.subscribe((value) => {
			if (typeof value === "object" && this.itemSelected == false) {
				// So this is where I notify the parent
				this.itemSelected = true;
				this.notifyParent.emit(value); //-- selecting a value from the selector doesn't trigger a change event, so we have to update the dynamic form question component so it can notify the overall form. --Kirk T. Sherer, August 19, 2021.
			}
		});

		(this.filteredList$ = this.formGroup.controls[this.question.key].valueChanges.pipe(
			debounceTime(300),
			startWith(""),
			tap((value) => {
				this.lastSearchTerm = value;
				this.showAddForm = !this.itemSelected && typeof this.lastSearchTerm !== "object" && this.lastSearchTerm.length > 2 && this.currentSet.selector.allowAdd;
			}),
			switchMap((name: any) => {
				if (typeof name === "string") {
					this.itemSelected = false;
					return this.search(this.lastSearchTerm);
				} else {
					return this.itemSelected$;
				}
			}),
			catchError((_) => {
				return of(null);
			})
		)),
			(err: Error) => Global.User.DebugMode && console.log(`generic-selector: ${err}`);

		if (this.question.value != null) {
			//-- this is bringing up an edited record, so get the record that matches on the Id field. --Kirk T. Sherer, May 26, 2021.
			var sql = this.currentSet.selector.sqlStoredProcedure + " @SearchText=null, @Id=" + this.question.value;

			if (this.currentSet.selector.sqlStoredProcedure.indexOf("Username") != -1 ) {
				sql += ", @Username='" + Global.User.currentUser.Username + "'";
			}

			Global.User.DebugMode && console.log(this.componentName + "sql = " + sql);
			this.dataService.SQLActionAsPromise(sql).then((data: any) => {
				if (data.length > 0) {
					var selectedRecord = data.first();
					this.formGroup.controls[this.question.key].setValue(selectedRecord);
				}
			});
		}
	}

	public saveSelectedValue(event: Event) {
		console.log("saveSelectedEvent: %O", event);
	}

	public displayFn(jbtStandardObservation: any): string {
		return jbtStandardObservation && jbtStandardObservation.SelectorName; //Name ? jbtStandardObservation.Id + ' - ' + jbtStandardObservation.Name + ' (' + jbtStandardObservation.TagCount + ' Tag Refs)' : '';
	}

	search(value: string): Observable<any> {
		return this.getList(value).pipe(
			// map the data.body as our return object
			map((returnedData) => {
				Global.User.DebugMode && console.log(this.componentName + "returnedData from getList = %O", returnedData);
				return returnedData;
			}),
			// catch errors
			catchError((_) => {
				return of(null);
			})
		);
	}

	getList(searchText?: any): Observable<any> {
		var sql = this.currentSet.selector.sqlStoredProcedure + " @SearchText=" + (searchText != null ? "'" + searchText + "'" : "null");

		if (this.currentSet.selector.sqlStoredProcedure.indexOf("Username") != -1 ) {
			sql += ", @Username='" + Global.User.currentUser.Username + "'";
		}

		if (searchText.Id != undefined) {
			const returnValue = new Observable((observer: any) => {
				return observer.next(searchText);
			});
			return returnValue;
		} else {
			Global.User.DebugMode && console.log(this.componentName + "sql = " + sql);
			return this.selectorService.SQLActionAsObservable(sql);
		}
	}

	ToPascalCase(str: string) {
		return str.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
	}

	public addNewRecord() {
		Global.User.DebugMode && console.log(this.componentName + "lastSearchTerm attempting to be added: " + this.lastSearchTerm);
		Global.User.DebugMode && console.log(this.componentName + "currentSet = %O", this.currentSet);
		this.editFormOptions = {
			submitButtonText: "Add New " + this.currentSet.tableDisplay.entityDisplayName,
			saveValuesPerField: false,
			saveStoredProcedureName: this.currentSet.editor.saveStoredProcedureName,
			cancelButtonText: "Cancel"
		};

		var informationalSeverityLevelId = this.dataService.cache.jbtStandardObservationSeverityLevels.first((lvl: any) => {
			return lvl.Name == "Informational";
		}).Id;
		var booleanValueTypeId = this.dataService.cache.jbtStandardObservationValueTypes.first((typ: any) => {
			return typ.Name == "Boolean";
		}).Id;

		this.currentSet.editor.fields.forEach((field: any) => {
			//-- set up default values for the JBT Standard Observation fields.
			if (field.key == "Id") {
				field.visible = false;
			}
			if (field.key == "Active") {
				field.value = true;
			}
			if ((field.key == "Name" || field.key == "Description" || field.key == "Mnemonic") && this.currentSet.tableDisplay.entityDisplayName != "Site") {
				if (this.currentSet.tableDisplay.entityName != "ComponentJBTStandardObservationGroup") {
					field.value = this.ToPascalCase(this.lastSearchTerm);
				} else {
					field.value = this.lastSearchTerm;
				}
			}

			switch (this.setName) {
				case "JBTStandardObservation":
					if (field.key == "JBTStandardObservationSeverityLevelId") {
						field.value = informationalSeverityLevelId;
					}
					if (field.key == "JBTStandardObservationValueTypeId") {
						field.value = booleanValueTypeId;
					}
					break;
				case "Sites":
					if (field.key == "UsesDaylightSavingTime") {
						field.value = true;
					}
					break;
	
			}
		});
		Global.RDN.internalDialog = true;
		this.rdnService.internalDialogIsVisible$.next(Global.RDN.internalDialog);
		this.addingNewRecord = true;
	}

	public closeRecordAdd() {
		Global.RDN.internalDialog = false;
		this.rdnService.internalDialogIsVisible$.next(Global.RDN.internalDialog);
		this.addingNewRecord = false;
	}

	submitEditForm(submittedValues: any) {
		var service = this;
		Global.User.DebugMode && console.log(this.componentName + "submitEditForm: submittedValues = %O", submittedValues);
		var submittedValuesObject = JSON.parse(submittedValues);
		//Global.User.DebugMode && console.log("submittedValuesObject = %O", submittedValuesObject);
		// Global.User.DebugMode && console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);
		var questions = this.currentSet.editor.fields;
		var fieldListArray = [];
		//-- this is adding a new record, not editing an existing record.  Use the API.RDN_InsertRecordIntoSQLTable with the following parameters: @tableName, @fieldList (comma-separated list of field names), @fieldValues (comma-separated list with appropriate quotations for values in same field order), @UserId (user that is adding the record.)
		keys.forEach((key: any) => {
			questions.forEach((question: any) => {
				if (key == question.key && question.controlType != "read-only") {
					fieldListArray.push(key);
				}
			});
		});
		var fieldList = fieldListArray.join(","); //-- don't get any read-only fields since we won't be sending read-only values to the stored procedure. --Kirk T. Sherer, June 14, 2021.

		Global.User.DebugMode && console.log(this.componentName + "field list = " + fieldList);

		var fieldValuesArray = [];
		var fieldValues = "";
		keys.forEach((key: any) => {
			var questions = this.currentSet.editor.fields;
			questions.forEach((question: any) => {
				if (key == question.key && question.controlType != "read-only") {
					var value = submittedValuesObject[key];
					if (typeof value === "object" && value != null) {
						//--if we made it here with an entire object, then get the Id value of the object since it came from a selector. --Kirk T. Sherer, May 25, 2021.
						Global.User.DebugMode && console.log(this.componentName + "value = %O", value);
						var idFieldValue = value["Id"];
						fieldValuesArray.push(idFieldValue); //submittedValuesObject[key].Id;
					} else {
						if (isNaN(value) || value == "" || value == null) {
							if (value == "" || value == null) {
								fieldValuesArray.push("null");
							} else {
								fieldValuesArray.push("'" + value.split("'").join("''") + "'");
							}
						} else {
							if (value == "true" || value == "false" || value == true || value == false) {
								//-- this is a boolean field.  Set it as 1 for true or 0 for false.
								fieldValuesArray.push(value == "true" || value == true ? "1" : "0");
							} else {
								if (value == 0 || value == "0") {
									fieldValuesArray.push("null");
								} else {
									fieldValuesArray.push(value);
								}
							}
						}
					}
				}
			});
		});
		fieldValues = fieldValuesArray.join(",");
		Global.User.DebugMode && console.log(this.componentName + "field values = " + fieldValues);

		var sqlStoredProcedure = "API.RDN_InsertRecordIntoSQLTable";
		var newParameterListAsString = "@tableName='" + this.currentSet.tableDisplay.entityName + "', @fieldList='" + fieldList + "', @fieldValues='" + fieldValues.split("'").join("''") + "', @sqlStoredProcedure='" + this.currentSet.selector.sqlStoredProcedure + "', @UserId=" + Global.User.currentUser.Id;
		sqlStoredProcedure = sqlStoredProcedure + " " + newParameterListAsString;
		Global.User.DebugMode && console.log(this.componentName + "sqlStoredProcedure = " + sqlStoredProcedure);
		var service = this;
		this.dataService.SQLActionAsPromise(sqlStoredProcedure).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + "data was submitted.");
			var possibleErrorMessage = data.firstOrDefault((d: any) => {
				return d.ErrorMessage;
			});
			if (possibleErrorMessage) {
				var actualErrorMessage = data.first((d: any) => {
					return d;
				}).ErrorMessage;
				swal.fire({
					title: "<div><p style='color: rgb(84, 84, 84); font-size: 18px; font-weight: 400; text-align: left; margin-bottom: 10px;'>Error inserting record into " + service.currentSet.tableDisplay.entityName + ".</p></div>",
					html: "<p style='text-align: left; color: rgb(84, 84, 84);'>" + actualErrorMessage + (Global.User.isAdmin ? "<br /><br />" + sqlStoredProcedure : "") + "</p>"
				});
			} else {
				//-- this is bringing back the added record, so set the value equal to this data record.  UPDATE: This will happen in the observable in the ngOnInit function above. --Kirk T. Sherer, May 26, 2021.
				Global.User.DebugMode && console.log(this.componentName + "new record added = %O", data);
				service.formGroup.controls[this.question.key].setValue(data.first());
				service.toastr.info(service.currentSet.tableDisplay.entityDisplayName + " '" + data.first().SelectorName + "' added.");
				service.notifyParent.emit(data.first()); //-- selecting a value from the selector doesn't trigger a change event, so we have to update the dynamic form question component so it can notify the overall form. --Kirk T. Sherer, August 19, 2021.
				service.closeRecordAdd();
			}
		});
	}
}
