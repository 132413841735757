<div class="d-inline-block">
  <div class="row" >
      <div class="col">
          <span [ngStyle]="{'color': live === false ? 'grey': 'default'}">
              <s *ngIf="live===false">Live</s>
              {{live === true ?  'Live': ''}}
          </span>


      </div>

  </div>
  <div class="row">
      <div class="col">
          <mat-progress-bar *ngIf="live === true" class="liveMode" mode="indeterminate"></mat-progress-bar>

          <mat-progress-bar *ngIf="live === false" class="liveMode" mode="determinate" value="20"></mat-progress-bar>
      </div>

  </div>
</div>

