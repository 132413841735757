<div *ngIf="isMobile === true" class="content" style="height: calc(100%)">

			<div  id="cardPunchout"  *ngIf="initializeTemplate === true" style="height: 100%;">
				<div class="card-header">
					<div><button class="btn btn-link" style="padding: 0px; float: left; margin-right: 20px; " (click)="onClick()">
						<i *ngIf="!navigationOpen" class="tim-icons icon-bullet-list-67"></i>
						<i *ngIf="navigationOpen" class="tim-icons icon-simple-remove"></i>
					</button>
				</div>
					<h3 class="card-title col-md-4 text-center">{{currentRoutedAsset?.Name ? 'Current Asset: ' + currentRoutedAsset.Name : 'GSE Assets'}}</h3>
				</div>
				<div class="card-body" style="height: 100%">
					<div class="d-flex flex-row" style="height: 100%">
						<div *ngIf="navigationOpen" class="d-flex flex-column">

							<lib-material-input
								devLabel="Search"
								devPlaceholder="C30I"
								(searchcriteria)="searchThis($event)"
							></lib-material-input>
							<div id="treeParent">
								<mat-tree
								[dataSource]="dataSource"
								[treeControl]="treeControl"
								style="height: 100%; overflow-y: auto;"
							>
								<mat-tree-node
									*matTreeNodeDef="let node; when: hasChild"
									[style.display]="filterParentNode(node) ? 'none' : 'flex'"
									matTreeNodePadding
									matTreeNodePaddingIndent="20"
									[ngStyle]="{
										'background-color': theme === 'light' ? 'white' : '#27304c',
										'color': theme === 'light' ? '#27304c' : 'white'
									}"
								>
									<button
										[ngStyle]="{
											'background-color': theme === 'light' ? 'white' : '#27304c',
											'color': theme === 'light' ? '#27304c' : 'white'
										}"
										style="border-style: none !important;"
										matTreeNodeToggle
										[attr.aria-label]="'Toggle ' + node.name"
									>
										<mat-icon class="mat-icon-rtl-mirror"
											>{{
												treeControl.isExpanded(node)
													? "expand_more"
													: "chevron_right"
											}}
										</mat-icon>
									</button>
									{{ node.name }}
								</mat-tree-node>
								<mat-tree-node
									*matTreeNodeDef="let node"
									matTreeNodePadding
									matTreeNodePaddingIndent="20"
									matTreeNodeToggle
									(click)="activeNode = node"
									[ngStyle]="{
										'background-color': theme === 'light' ? 'white' : '#27304c',
										'color': theme === 'light' ? '#27304c' : 'white'
									}"
									[style.display]="
									filterLeafNode(node) ? 'none' : 'flex'
									"
								>
									<div
										style="cursor: pointer;text-align: center;"
										(click)="onClickShowSummary(node)"
										[ngStyle]="{
											'color': activeNode == node ?  theme === 'light' ? 'white' : '#27304c': theme === 'light' ? '#27304c' : 'white',
											'background-color': activeNode == node ? theme === 'light' ? '#27304c' : 'white': theme === 'light' ? 'white' : '#27304c'
										}"
									>
									{{ node.name }}
								</div>
								</mat-tree-node>
							</mat-tree>
							</div>

						</div>
						<div class="d-flex flex-column basis scroll" style="width: 100%; height: 100%">
							<div [style.height.%]="100">
								<router-outlet></router-outlet>

							</div>
						</div>
					</div>
				</div>

			</div>
</div>
<div *ngIf="isMobile === false" class="content" style="height: calc(100%);">
	<div  class="card" [style.height]="'calc(100vh - 95px)'">
		<div class="card-header">
			<div>
				<button class="btn btn-link" style="padding: 0px; float: left; margin-right: 20px; " (click)="onClick()">
					<i *ngIf="!navigationOpen" class="tim-icons icon-bullet-list-67"></i>
					<i *ngIf="navigationOpen" class="tim-icons icon-simple-remove"></i>
				</button>
			</div>
			<h3 class="card-title col-md-4 text-center" style="padding-right: 500">{{currentRoutedAsset?.Name ? 'Current Asset: ' + currentRoutedAsset.Name : 'GSE Assets'}}</h3>
			<div class="pull-right" style="padding-left: 500px; display: inline" *ngIf="dashboardService?.canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope?.length > 0">
				<mat-form-field appearance="fill" class="pull-right">
					<mat-label>Select a Dashboard Timescope</mat-label>
					<mat-select [(value)]="currentDashboard.TimeScopeId" (selectionChange)="changeDashboardTimeScope()">
						<mat-option *ngFor="let timeWindow of DashboardTimeScopes" [value]="timeWindow.Id">
							{{ timeWindow.Description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="pull-right" style="display: inline" *ngIf="dashboardService?.canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope?.length > 0">
				<mat-form-field appearance="fill" class="pull-right">
					<mat-label>Select a Dashboard Timezone</mat-label>
					<mat-select [(value)]="currentDashboard.TimeZoneId" (selectionChange)="changeDashboardTimeZone()">
						<mat-option *ngFor="let timeZone of DashboardTimeZones" [value]="timeZone.Id">
							{{ timeZone.Description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

		</div>
		<div class="card-body" style="height: 75%">
			<div class="d-flex flex-row" style="height: 100%">
				<div *ngIf="navigationOpen" class="d-flex flex-column">

					<lib-material-input
						devLabel="Search"
						devPlaceholder="C30I"
						(searchcriteria)="searchThis($event)"
					></lib-material-input>
					<div id="treeParent">
						<mat-tree
						[dataSource]="dataSource"
						[treeControl]="treeControl"
						style="height: 100%; overflow-y: auto;"
						[ngStyle]="{
							'background-color': theme === 'light' ? 'white' : '#27304c'
						}"
					>
						<mat-tree-node
							*matTreeNodeDef="let node; when: hasChild"
							[style.display]="filterParentNode(node) ? 'none' : 'flex'"
							matTreeNodePadding
							matTreeNodePaddingIndent="20"
							[ngStyle]="{
								'background-color': theme === 'light' ? 'white' : '#27304c',
								'color': theme === 'light' ? '#27304c' : 'white'
							}"
						>
							<button
								[ngStyle]="{
									'background-color': theme === 'light' ? 'white' : '#27304c',
									'color': theme === 'light' ? '#27304c' : 'white'
								}"
								style="border-style: none !important;"
								matTreeNodeToggle
								[attr.aria-label]="'Toggle ' + node.name"
							>
								<mat-icon class="mat-icon-rtl-mirror"
									>{{
										treeControl.isExpanded(node)
											? "expand_more"
											: "chevron_right"
									}}
								</mat-icon>
							</button>
							{{ node.name }}
						</mat-tree-node>
						<mat-tree-node
							*matTreeNodeDef="let node"
							matTreeNodePadding
							matTreeNodePaddingIndent="20"
							matTreeNodeToggle
							(click)="activeNode = node"
							[ngStyle]="{
								'background-color': theme === 'light' ? 'white' : '#27304c',
								'color': theme === 'light' ? '#27304c' : 'white'
							}"
							[style.display]="
							filterLeafNode(node) ? 'none' : 'flex'
							"
						>
							<div
								style="cursor: pointer;text-align: center;"
								(click)="onClickShowSummary(node)"
								[ngStyle]="{
									'color': activeNode == node ?  theme === 'light' ? 'white' : '#27304c': theme === 'light' ? '#27304c' : 'white',
									'background-color': activeNode == node ? theme === 'light' ? '#27304c' : 'white': theme === 'light' ? 'white' : '#27304c'
								}"
							>
							{{ node.name }}
						</div>
						</mat-tree-node>
					</mat-tree>
					</div>

				</div>
				<div class="d-flex flex-column basis scroll" style="width: 100%; height: 100%">
					<div [style.height.%]="100">
						<router-outlet></router-outlet>

					</div>
				</div>
			</div>
		</div>

	</div>
</div>
