import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTemplateToCanvasComponent } from './add-template-to-canvas.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { GridModule } from '@progress/kendo-angular-grid';

@NgModule({
	declarations: [AddTemplateToCanvasComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatTooltipModule,
		LoadingSpinnerModule,
		MatDialogModule,
		MatTabsModule,
		MatTableModule,
		GridModule,
	],
	exports: [AddTemplateToCanvasComponent],
})
export class AddTemplateToCanvasModule { }
