import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagGraphPopupSettingsComponent } from './tag-graph-popup-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
	declarations: [TagGraphPopupSettingsComponent],
	imports: [
		CommonModule,
		DragDropModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		ColorPickerModule,
		MatRadioModule,
	],
	exports: [TagGraphPopupSettingsComponent],
})
export class TagGraphPopupSettingsModule {}
