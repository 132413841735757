import { Routes } from "@angular/router";
import { LoginComponent } from "../user/login.component";
import { LogoutComponent } from "../user/logout.component";
import { AuthenticationComponent } from "../user/authentication/authentication.component";

import { DashboardComponent } from "../_layout/dashboard/dashboard.component";
// import { WidgetComponent } from '../_layout/dashboard/widget/widget.component';
import { RecipesComponent } from "../_layout/advanced/recipes/recipes.component";

import { LayoutComponent } from "../_layout/layout.component";
import { DashboardListComponent } from '../_layout/dashboard/dashboard-list/dashboard-list.component';
import { DashboardDemoComponent } from '../_layout/dashboard/dashboard-demo/dashboard-demo.component';

import { LocateAllGSEComponent } from '../_layout/advanced/maps/locate-all-gse/locate-all-gse.component';

import { IssuesComponent } from '../_layout/navbar/issues/issues.component';
import { ForgotPasswordComponent } from '../user/forgot-password/forgot-password.component';

import { AuthGuard } from '../user/authentication/authorization.guard';
import { RegistrationComponent } from '../user/registration/registration.component';
import { HomeComponent } from '../home/home.component';
import { CustomerReportsComponent } from '../_layout/advanced/customer-reports/customer-reports.component';
import { AdminReportsComponent } from '../_layout/advanced/admin-reports/admin-reports.component';
import { CustomerIssuesListComponent } from 'projects/shared-lib/src/lib/components/customer-issues-list/customer-issues-list.component';
import { SystemTablesComponent } from '../_layout/advanced/system-tables/system-tables.component';
import { RdnDestinationComponent } from '../_layout/advanced/system-tables/rdn-destination/rdn-destination.component';
import { GoToV1Component } from '../user/go-to-v1/go-to-v1.component';
import { InjectionToken } from '@angular/core';
import { NinjaStatisticsComponent } from '../_layout/shared/widgets/ninja-statistics/ninja-statistics.component';
import { AssetSummaryViewComponent } from 'projects/shared-lib/src/lib/components/asset-summary-view/asset-summary-view.component';
import { PotdComponent } from 'projects/shared-lib/src/lib/_other-template-components/potd/potd.component';
import { DashboardEditComponent } from 'projects/shared-lib/src/lib/components/dashboard-edit/dashboard-edit.component';
import { TestObservationDelayComponent } from "projects/shared-lib/src/lib/components/test-observation-delay/test-observation-delay.component";
import { TrainingCoursesComponent } from 'projects/shared-lib/src/lib/components/training-courses/training-courses.component';
import { AssetSummaryParentComponent } from 'projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.component';
import { PunchoutGuard } from 'projects/shared-lib/src/lib/guards/punchout.guard';
import { DialogEntryComponent } from 'projects/shared-lib/src/lib/components/dialog-entry/dialog-entry.component';
import { CustomerIssueGuard } from 'projects/shared-lib/src/lib/guards/customer-issue.guard';
import { ReleaseNotesComponent } from 'projects/shared-lib/src/lib/components/release-notes/release-notes.component';
import { DashboardAccessGuard } from "projects/shared-lib/src/lib/guards/dashboard-access.guard";
import { UserEngagementReportComponent } from "projects/shared-lib/src/lib/components/user-engagement-report/user-engagement-report.component";
import { CanvasComponent } from "projects/shared-lib/src/public-api";
import { CanvasDesignerComponent } from "projects/shared-lib/src/lib/components/canvas-designer/canvas-designer.component";

import { LogbookComponent } from "projects/shared-lib/src/lib/components/logbook/logbook.component";
import { CanvasTabsListComponent } from "../../../../shared-lib/src/lib/components/canvas-tabs-list/canvas-tabs-list.component";

export const detailedRoutes: Routes = [
	{
		path: "home",
		canActivate: [AuthGuard],
		component: HomeComponent,
	},
	{
		path: "user",
		children: [
			{ path: "authentication", component: AuthenticationComponent },
			{ path: "login", component: LoginComponent },
			{ path: "logout", component: LogoutComponent },
		],
	},
	{
		path: "forgot-password/:pwt",
		component: ForgotPasswordComponent,
	},
	{
		path: "registration",
		component: RegistrationComponent,
	},
	{
		path: "layout",
		canActivate: [AuthGuard],
		component: LayoutComponent,
		children: [
			{
				path: "",
				children: [
					{
						path: "dashboard-demo",
						component: DashboardDemoComponent,
					},
					{
						path: "dashboard-list",
						component: DashboardListComponent,
						children: [
							{
								path: "dashboard-edit/:id",
								component: DashboardEditComponent,
							},
						],
					},
					{
						path: "dashboard/:id",
						component: DashboardComponent,
						canActivate: [DashboardAccessGuard],

						// children: [
						//     {   path: 'widget/:id', component: WidgetComponent }
						// ]
					},
				],
			},
			//-- do not delete this next one.  This is for the Recipes.  See the AngularJS version for details. --Kirk T. Sherer, April 21, 2020.
			{
				path: "",
				children: [
					{
						path: "recipes",
						component: RecipesComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "potd",
						component: PotdComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "user-engagement-report",
						component: UserEngagementReportComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "trainingCourses",
						component: TrainingCoursesComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "testObs",
						component: TestObservationDelayComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "issues",
						component: CustomerIssuesListComponent,
						children: [
							{
								path: ":id",
								component: DialogEntryComponent,
								canActivate: [CustomerIssueGuard],
							},
						],
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "logbook",
						component: LogbookComponent,
						// children: [
						// 	{
						// 		path: ":id",
						// 		component: DialogEntryComponent,
						// 		canActivate: [CustomerIssueGuard],
						// 	},
						// ],
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "releaseNotes",
						component: ReleaseNotesComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "tables",
						component: SystemTablesComponent,
					},
					{
						path: "tables/:setName",
						component: RdnDestinationComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "adminReports",
						component: AdminReportsComponent,
					},
					{
						path: "customerReports",
						component: CustomerReportsComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "userIssues",
						component: IssuesComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "ninjaProcessorData",
						component: NinjaStatisticsComponent,
					},
				],
			},
			//-- do not delete these next two.  This is for the All Tags Graph and the All Tag updates.  See the AngularJS version for what they do. --Kirk T. Sherer, April 21, 2020.

			{
				path: "",
				children: [
					{
						path: "locateAllGSE/:id",
						component: LocateAllGSEComponent,
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "assets",
						component: AssetSummaryViewComponent,
						children: [
							{
								path: ":id",
								component: AssetSummaryParentComponent,
								canActivate: [PunchoutGuard],
							},
						],
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "canvas-designer",
						component: CanvasDesignerComponent,
						children: [
							{
								path: ":id",
								component: CanvasComponent,
							},
						],
					},
				],
			},
			{
				path: "",
				children: [
					{
						path: "canvas-tabs-list",
						component: CanvasTabsListComponent,


					},
				],
			},


		],
	},
	{
		path: "go-to-v1",
		canActivate: [AuthGuard],
		component: GoToV1Component,
	},
	{
		path: "",
		redirectTo: "user/authentication",
		pathMatch: "full",
	},
	{
		path: "**",
		redirectTo: "user/authentication",
		pathMatch: "full",
	},
];
