<div class="card card-tasks" id="card-whole">

<div class="card-header widget-card-header" style="height:25px;">
	<h6 class="title d-inline">{{data.name}}</h6>
	<button class="btn btn-link" style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" (click)="onCancel()">
		<i class="tim-icons icon-simple-remove"></i>
	</button>
</div>
<div class="card-body">

	<div *ngIf="data.name== 'PBB'" (click)="openTagGraphSingle('12245')"
		>


		<mat-card
			[ngClass]="
			(data.state?.pbb == 'Docked'
				? 'hookup-details-good'
				: 'hookup-details') + (data.theme === 'dark' ? '' : '-light')
			"
		>

		<div class="pt-card-title">

			<span  [ngClass]="
					data.theme === 'dark' ? 'DockedLight' : 'Docked'
					" title="PBB Status">
				{{ data.state?.pbb }}
			</span>

		</div>

		<div class="pt-grid-container">
			<div class="left">
				<div
					id="progress-medium"
					[ngStyle]="{
						background: data.pbb.progressBackground
					}"
				>
					<span
						id="progress-value-medium"
						title="pbb.duration"
						>
						{{data.pbb.duration.totalMS | date : "mm:ss"}}
					</span>
				</div>
			</div>


			<div class="main">
				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PBBMoveStart != null"
						title="PBB MoveStart"
						>
						Move Start
					</span>

				</p>

				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PBBDocked != null"
						title="PBB Docked"
						>
						Docked
					</span>


				</p>

				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PBBUndocked != null"
						title="PBB UNDocked"
						>
						Undocked
					</span>
				</p>

				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PBBMoveAway != null"
						title="PBB Move Away"
						>
						Move Away
					</span>

				</p>
			</div>

			<div class="time">
				<p>
					<span
						*ngIf="data.currentTurn.PBBMoveStart != null"
						[ngClass]="
							data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="PBB MoveStart"
						>
						{{ data.currentTurn.PBBMoveStart | date : "hh:mm:ss a" }}

					</span>
				</p>

				<p>

					<span
						*ngIf="data.currentTurn.PBBDocked != null"
						[ngClass]="
							data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="PBB Docked"
						>
						{{ data.currentTurn.PBBDocked | date : "hh:mm:ss a" }}
					</span>
				</p>

				<p>

					<span
						*ngIf="data.currentTurn.PBBUndocked != null"
						[ngClass]="
							data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="PBB UNDocked"
						>
						{{ data.currentTurn.PBBUndocked | date : "hh:mm:ss a" }}
					</span>
				</p>

				<p>
					<span
						*ngIf="data.currentTurn.PBBMoveAway != null"
						[ngClass]="
							data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="PBB PBBMoveAway"
						>
						{{ data.currentTurn.PBBMoveAway | date : "hh:mm:ss a" }}

					</span>
				</p>

			</div>

			<div class="right">
				<p>
					<span
						*ngIf="data.timers.docked != null"
						[ngClass]="
							data.theme === 'dark'
								? 'DurationLight'
								: 'Duration'
						"
						title="pbb.duration"
						>
						{{data.pbb.duration.negative}}{{ data.pbb.duration.hours }}:{{ data.pbb.duration.minutes }}:{{ data.pbb.duration.seconds }}
					</span>
				</p>


			</div>
		</div>
		</mat-card>



	</div>


	<div *ngIf="data.name== 'GPU'" (click)="openTagGraphSingle('1942')"
		>

		<mat-card
			[ngClass]="
			(data.state.gpu == 'ON' ? 'hookup-details-caution' :
			data.state.gpu == 'Switchover' ? 'hookup-details-good' :
			'hookup-details') + (data.theme === 'dark' ? '' : '-light')
			"
		>

		<div class="pt-card-title">
			<span [ngClass]="
					data.theme === 'dark' ? 'DockedLight' : 'Docked'
					" title="GPU Status">
				{{ data.state.gpu }}
			</span>

			<div style="margin-left: auto;">
				<span class="TitleLight" title="Avg Amps Out">
					Avg Amps Out
				</span>
				<span [ngClass]="
						data.theme === 'dark' ? 'DockedLight' : 'Docked'
						" title="Avg Amps Out">
					{{ data.headerValue | number : "1.1-1" }}
				</span>
			</div>
		</div>

		<div class="pt-grid-container">
			<div class="left">
				<div
					id="progress-medium"
					[ngStyle]="{
						background: data.gpu.progressBackground
					}"
				>
					<span
						id="progress-value-medium"
						title="gpu.duration+gpuon.duration"
						>
						{{(data.gpu.duration.totalMS+data.gpuon.duration.totalMS) | date : "mm:ss"}}
						</span
					>
				</div>
			</div>

			<div class="main">

				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PBBDocked != null"
						title="PBB Docked"
						>
						Docked
					</span>

				</p>

				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.GPUOn != null"
						title="GPU On"
					>
						ON
					</span>

				</p>

				<p *ngIf="data.currentTurn.GPUSwitchover != null">
					<span
						title="AmpsOutAverage"
					>
					Switchover
					</span>

				</p>
			</div>

			<div class="time">

				<p>

					<span
						*ngIf="data.currentTurn.PBBDocked != null"
						[ngClass]="
							data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="GPU On"
						>
						{{ data.currentTurn.PBBDocked | date : "hh:mm:ss a" }}
					</span>
				</p>

				<p>

					<span
						*ngIf="data.currentTurn.GPUOn != null"
						[ngClass]="
							data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="GPU On"
					>
					{{ data.currentTurn.GPUOn | date : "hh:mm:ss a" }}

					</span>
				</p>

				<p>

					<span
						*ngIf="data.currentTurn.GPUSwitchover != null"
						[ngClass]=" data.theme === 'dark' ? 'TitleLight' : 'Title' "
						title=""
					>
					{{ data.currentTurn.GPUSwitchover | date : "hh:mm:ss a" }}
					</span>
				</p>
			</div>

			<div class="right">

				<p>
					<span
						*ngIf="data.currentTurn.PBBDocked != null"
						[ngClass]="
							data.theme === 'dark'
								? 'DurationLight'
								: 'Duration'
						"
						title="gpu.duration"
						>
						{{data.gpu.duration.negative}}{{ data.gpu.duration.hours }}:{{ data.gpu.duration.minutes }}:{{ data.gpu.duration.seconds }}
					</span>
				</p>

				<p>
					<span
						*ngIf="data.currentTurn.GPUOn != null"
						[ngClass]="
							data.theme === 'dark'
								? 'DurationLight'
								: 'Duration'
						"
						title="gpuon.duration"
						>
						{{data.gpuon.duration.negative}}{{ data.gpuon.duration.hours }}:{{ data.gpuon.duration.minutes }}:{{ data.gpuon.duration.seconds }}
					</span>
				</p>




			</div>
		</div>

	</mat-card>
	</div>


	<div *ngIf="data.name== 'PCA' || data.name== 'AHU'" (click)="openTagGraphSingle('2736')"
		>

		<mat-card
		[ngClass]="
			(data.turn.pcaUnitOn?.Value=='1' && data.turn.BridgeMode?.Value == '0'
				? 'hookup-details-good'
				: 'hookup-details') + (data.theme === 'dark' ? '' : '-light')
			"
		>

		<div class="pt-card-title">
			<span  [ngClass]="
					data.theme === 'dark' ? 'DockedLight' : 'Docked'
					" title="pcaUnitOn">
				{{ data.turn.pcaUnitOn?.Value=='1' && data.turn.BridgeMode?.Value == '0' ? 'Aircraft' : 'OFF' }}
			</span>

			<div style="margin-left: auto;">
				<span class="TitleLight" title="Discharge Temp (&deg;F)">
					Disch Temp
				</span>
				<span  [ngClass]="
						data.theme === 'dark' ? 'DockedLight' : 'Docked'
						" title="Discharge Temp (&deg;F)">
					{{ data.headerValue  | number : "1.1-1" }} &deg;F
				</span>
			</div>
		</div>

		<div class="pt-grid-container">
			<div class="left">
				<div
					id="progress-medium"
					[ngStyle]="{
						background: data.pca.progressBackground
					}"
				>
					<span
						id="progress-value-medium"
						title="pcatotal.duration"
						>
						{{data.pca.duration.negative}}{{data.pca.duration.minutes}}:{{data.pca.duration.seconds}}
						</span
					>
				</div>
			</div>


			<div class="main">
				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PBBDocked != null"
						title="PBB Docked"
						>
						Docked
					</span>

				</p>

				<p class="pt-label">
					<span
						*ngIf="data.currentTurn.PCAVentilatingAircraft != null"
						title="{{ data.siteAirUnitName }} Ventilating Aircraft"
						>
						Connected
					</span>

				</p>
			</div>

			<div class="time">
				<p>

					<span
						*ngIf=" data.currentTurn.PBBDocked != null "
						[ngClass]=" data.theme === 'dark' ? 'TitleLight' : 'Title' "
						title="PBB Docked"
						>
						{{ data.currentTurn.PBBDocked | date : "hh:mm:ss a" }}
					</span>
				</p>

				<p>

					<span
						*ngIf="data.currentTurn.PCAVentilatingAircraft != null"
						[ngClass]="
						data.theme === 'dark' ? 'TitleLight' : 'Title'
						"
						title="{{ data.siteAirUnitName }} Connected"
					>
						{{	data.currentTurn.PCAVentilatingAircraft | date : "hh:mm:ss a" }}
					</span>
				</p>
			</div>



			<div class="right">
				<p *ngIf="data.currentTurn.PBBDocked != null">
					<span
						[ngClass]=" data.theme === 'dark' ? 'DurationLight' : 'Duration' "
						title="pca.duration"
						>
						{{data.pca.duration.negative}}{{ data.pca.duration.hours }}:{{ data.pca.duration.minutes }}:{{ data.pca.duration.seconds }}
					</span>
				</p>
			</div>
		</div>
	</mat-card>



	</div>


</div>
</div>
