<div style="height: 100%; width: 100%" (resized)="onResized($event)">
	<div *ngIf="options.length > 0" class="flex-container" style="position: relative">
		<lib-navigation [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-navigation>
		<div [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">
			<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading Sabel Data Info...'" [vehicle]="'plane'"></lib-loading-spinner>
			<div *ngIf="!isLoading" class="card card-tasks" style="max-height: 900px; height:100%">
				<div (resized)="onResized($event)" [style.max-height.px]="900" [style.height.%]="100">
					<lib-kendo-grid-parent [gridData]="dataService.cache.sabelData" [gridSettings]="gridSettings" [widgetObject]="widgetObject" (callSave)="saveGridSettings()" [style.height.%]="100" filterable="menu" [sortable]="true" [reorderable]="true" [resizable]="true" *ngIf="dataService.cache.sabelData !== undefined" #tagDataGrid> </lib-kendo-grid-parent>
				</div>
			</div>
		</div>
	</div>
</div>
