<lib-loading-spinner *ngIf="isDataLoading" [loadingMessage]="'Loading Central Units Overview Info...'" [vehicle]="'plane'"></lib-loading-spinner>
<div *ngIf="!isDataLoading" class="flex-container" style="position: relative" >
	<lib-navigation [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-navigation>
	<div class="units-list" [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'" id="units-display">
		<div *ngFor="let obj of assetObj" class="display-unit" >
			<lib-central-unit [parentAsset]="obj" [widgetObject]="widgetObject"></lib-central-unit>
		</div>
		<div *ngIf="!isDataLoading  && !widgetObject?.WidgetSiteName" class="card card-tasks center" id="card-whole">
			<lib-centered-message centeredMessage="Please select a Site from the widget settings."></lib-centered-message>
		</div>
	</div>
</div>
