import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { table } from "console";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { BehaviorSubject, Subject } from "rxjs";
import { SignalRCoreService } from "projects/shared-lib/src/lib/services/signalr-core.service";

@Component({
	selector: "system-tables",
	templateUrl: "./system-tables.component.html",
	styleUrls: ["./system-tables.component.scss"]
})
export class SystemTablesComponent implements OnInit, OnDestroy {
	public global: IGlobal = Global;
	public columns: any;
	public componentName: string = "system-tables: ";

	// public selectedEntity: any;
	// public selectedEntity$: Subject<any> = new Subject();

	constructor(private dataService: DataService, private changeDetector: ChangeDetectorRef, private router: Router, private signalR: SignalRCoreService) {}

	ngOnInit() {
		// this.selectedEntity = { label: "RDN Starting Point", setName: "RDN Starting Point" };
		// this.selectedEntity$.subscribe((entity: any) => {
		// 	this.selectedEntity = entity;
		// 	console.log("selectedEntity$.subscription invoked: this.selectedEntity = %O", this.selectedEntity);
		// });
		// if (Global.User.systemTableStructure != null) {
		// 	this.columns = Global.User.systemTableStructure; //-- if we've already been to System Tables, then the Global.User.systemTableStructure has already been built.  Just use that instead of rebuilding the list.
		// }
		// else {
			this.initialize(); //-- this builds out the System Table list and assigns the result to Global.User.systemTableStructure. --Kirk T. Sherer, December 7, 2023. 
		//}

	}

	ngOnDestroy(): void {
		
	}

	initialize() {
		var systemTableList = Global.AdminTableEditorStates.orderBy((tableEntry: any) => {
			return tableEntry.label;
		}).toArray();

		var global: IGlobal = Global;
		var typeOfAdmin = global.User.isAdmin ? "System" : global.User.isOrgAdmin ? "Organization" : global.User.isSiteAdmin ? "Site" : null;
		var isRockstar = global.User.currentUser.Rockstar ?? false;

		var listOfPermittedTablesToEdit = [];

		systemTableList.forEach((tableEntry: any) => {

			//-- list of system tables without children --
			var authorizedRolesArray = tableEntry.role?.split(",");
			var authorizedPrivilegesArray = tableEntry.privilege?.split(",");
			if (authorizedRolesArray != undefined || authorizedPrivilegesArray != undefined) {
				if (authorizedRolesArray != undefined) {
					authorizedRolesArray.forEach((role: string) => {
						// if (tableEntry.setName == "Person" || tableEntry.setName == "PersonBySingleOrganization") {
						// 	console.log("Person");
						// }
						switch (role) {
							case "OrganizationAdministrator":
								if (typeOfAdmin == "Organization") {
									var existingEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
										return arrayEntry.setName == tableEntry.setName;
									});
									if (existingEntry == null) {
										listOfPermittedTablesToEdit.push(tableEntry);
									}
								}
								break;
							case "SystemAdministrator":
								if (typeOfAdmin == "System") {
									var existingEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
										return arrayEntry.setName == tableEntry.setName;
									});
									if (existingEntry == null) {
										listOfPermittedTablesToEdit.push(tableEntry);
									}
								}
								break;
							case "SiteAdministrator":
								if (typeOfAdmin == "Site") {
									var existingEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
										return arrayEntry.setName == tableEntry.setName;
									});
									if (existingEntry == null) {
										listOfPermittedTablesToEdit.push(tableEntry);
									}
								}
								break;
							case "Rockstar":
								if (isRockstar) {
									var existingEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
										return arrayEntry.setName == tableEntry.setName;
									});
									if (existingEntry == null) {
										listOfPermittedTablesToEdit.push(tableEntry);
									}
								}
								break;
							}
					});
				}
				if (authorizedPrivilegesArray != undefined) {
					authorizedPrivilegesArray.forEach((tablePrivilege: string) => {
						if (global.User.currentUser.Menu.Privileges[tablePrivilege] == true && !typeOfAdmin) {
							var existingEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
								return arrayEntry.setName == tableEntry.setName;
							});
							if (existingEntry == null) {
								listOfPermittedTablesToEdit.push(tableEntry);
							}
						}
					});
				}
			} else {
				if (typeOfAdmin == "System") {
					listOfPermittedTablesToEdit.push(tableEntry); //-- if there are no defined roles or privileges for the System Table, then only grant it to System Administrators. --Kirk T. Sherer, December 8, 2023. 
				}
			}

			//-- list of system tables with children --
			if (tableEntry.children != null) {
				var parentTableEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
					return arrayEntry.label == tableEntry.label;
				});
				if (parentTableEntry == null) {
					listOfPermittedTablesToEdit.push(tableEntry);
					parentTableEntry = listOfPermittedTablesToEdit.firstOrDefault((arrayEntry: any) => {
						return arrayEntry.label == tableEntry.label;
					});
				}
				var childEntries = [];

				tableEntry.children.forEach((child:any) => {
					var authorizedRolesArray = child.role?.split(",");
					var authorizedPrivilegesArray = child.privilege?.split(",");
		
					if (authorizedRolesArray != undefined || authorizedPrivilegesArray != undefined) {
						if (authorizedRolesArray != undefined) {
							authorizedRolesArray.forEach((role: string) => {
								switch (role) {
									case "OrganizationAdministrator":
										if (typeOfAdmin == "Organization") {
											var existingEntry = childEntries.firstOrDefault((arrayEntry: any) => {
												return arrayEntry.setName == child.setName;
											});
											if (existingEntry == null) {
												childEntries.push(child);
											}
										}
										break;
									case "SystemAdministrator":
										if (typeOfAdmin == "System") {
											var existingEntry = childEntries.firstOrDefault((arrayEntry: any) => {
												return arrayEntry.setName == child.setName;
											});
											if (existingEntry == null) {
												childEntries.push(child);
											}
										}
										break;
									case "SiteAdministrator":
										if (typeOfAdmin == "Site") {
											var existingEntry = childEntries.firstOrDefault((arrayEntry: any) => {
												return arrayEntry.setName == child.setName;
											});
											if (existingEntry == null) {
												childEntries.push(child);
											}
										}
										break;
								}
							});
						}
						if (authorizedPrivilegesArray != undefined) {
							authorizedPrivilegesArray.forEach((tablePrivilege: string) => {
								if (Global.User.currentUser.Menu.Privileges[tablePrivilege] == true && !typeOfAdmin) {
									var existingEntry = childEntries.firstOrDefault((arrayEntry: any) => {
										return arrayEntry.setName == child.setName;
									});
									if (existingEntry == null) {
										childEntries.push(child);
									}
								}
							});
						}
					} else {
						if (typeOfAdmin == "System") {
							childEntries.push(child); //-- if there are no defined roles or privileges for the System Table, then only grant it to System Administrators. --Kirk T. Sherer, December 8, 2023. 
						}
					}
	
				});

				parentTableEntry.children = childEntries.orderBy((child: any) => { return child.label }).toArray();
				//console.log("parentTableEntry = %O", parentTableEntry);
			}

		});

		//console.log("listOfPermittedTablesToEdit = %O", listOfPermittedTablesToEdit);

		var countOfSystemTables = listOfPermittedTablesToEdit.length;
		var arrayOfChildRecords = listOfPermittedTablesToEdit.where((tableEntry:any) => { return tableEntry.children != undefined	}).toArray();
		arrayOfChildRecords.forEach((item:any) => {
			countOfSystemTables += item.children.length;
		})
		var countOfGroups = arrayOfChildRecords.length;
		countOfSystemTables += countOfGroups;
		var splitCount = countOfSystemTables > 10 ? countOfSystemTables / 2 : countOfSystemTables;
		var counter: number = 1;
		Global.User.DebugMode && console.log("system-tables: count of System Tables = " + countOfSystemTables + ", splitCount = " + splitCount);
		listOfPermittedTablesToEdit.forEach((tableEntry: any) => {
			tableEntry.count = counter;
			if (counter < splitCount + 1) {
				tableEntry.column = 1;
			} else {
				tableEntry.column = 2;
			}
			if (tableEntry.children != undefined) {
				//-- the following keeps all the children together and puts them in the second column if the length of the children array plus the counter is greater than the splitCount of all entries + 1. 
				if ((counter + tableEntry.children.length) < (splitCount + 1)) {
					tableEntry.column = 1;
				}
				else {
					tableEntry.column = 2;
				}

				tableEntry.children.forEach((child:any) => {
					child.count = counter;
					child.column = tableEntry.column; //-- keep all the children in the same column as the parent.
					counter++;
				})
			}
			counter++;
		});
		Global.User.DebugMode && console.log("system-tables: systemTableList = %O", listOfPermittedTablesToEdit);

		this.columns = [
			{
				items: listOfPermittedTablesToEdit
					.where((tableEntry: any) => {
						return tableEntry.column == 1;
					})
					.select((tableEntry: any) => {
						return { label: tableEntry.label, setName: tableEntry.setName, role: tableEntry.role, privilege: tableEntry.privilege, children: tableEntry.children };
					})
					.toArray()
			},
			{
				items: listOfPermittedTablesToEdit
					.where((tableEntry: any) => {
						return tableEntry.column == 2;
					})
					.select((tableEntry: any) => {
						return { label: tableEntry.label, setName: tableEntry.setName, role: tableEntry.role, privilege: tableEntry.privilege, children: tableEntry.children };
					})
					.toArray()
			}
		];

		Global.User.DebugMode && console.log("system-tables: this.columns = %O", this.columns);
		Global.User.systemTableStructure = this.columns; //-- this is so we don't have to rebuild this structure every time we return to the System Table list. 
		this.signalR.LogActivity("Accessed System Tables.");

	}

	checkAuthorization(tableEntry: any): boolean {
		var authorizedRolesArray = tableEntry.role.split(",");
		var isAuthorized = false;
		var validatingUserAccess = true;
		if (authorizedRolesArray != undefined) {
			authorizedRolesArray.forEach((role: string) => {
				switch (role) {
					case "OrganizationAdministrator":
						if (validatingUserAccess && Global.User.isOrgAdmin) {
							isAuthorized = true;
							validatingUserAccess = false;
						}
						break;
					case "SystemAdministrator":
						if (validatingUserAccess && Global.User.isAdmin) {
							isAuthorized = true;
							validatingUserAccess = false;
						}
						break;
					case "SiteAdministrator":
						if (validatingUserAccess && Global.User.isSiteAdmin) {
							isAuthorized = true;
							validatingUserAccess = false;
						}
						break;

					default:
						if (validatingUserAccess) {
							isAuthorized = false;
							validatingUserAccess = true;
						}
						break;
				}
			});
		} else {
			isAuthorized = true; //-- it's ok to display this system table since no role has been defined for it.
		}
		console.log(this.componentName + "checkAuthorization(" + tableEntry.label + "): " + isAuthorized + ". tableEntry = %O", tableEntry);
		return isAuthorized;
	}

	selectEntity(item: any) {
		if (this.checkAuthorization(item)) {
			Global.User.DebugMode && console.log(this.componentName + "selectEntity function invoked: item = %O", item);
			Global.User.DebugMode && console.log(this.componentName + "Routing user to /layout/tables/" + item.setName);

			// this.selectedEntity$.next(item);
			this.router.navigate(["/layout/tables/" + item.setName]).then((data: boolean) => {
					if (!data) {
						//-- weren't able to route to the setName. 
						Global.User.DebugMode && console.log(this.componentName + "Routing user to /layout/tables/" + item.setName + " was unsuccessful.");
					} else {
						//-- routing to the release notes was successful. 
						Global.User.DebugMode && console.log(this.componentName + "Routing user to /layout/tables/" + item.setName + " was successful.");
					}
				})
				.catch((e) => {
					//-- Error in routing to the release notes, so route the user to the last visited route. 
					Global.User.DebugMode && console.log(this.componentName + "Error in routing user to /layout/tables/" + item.setName + ": " + e);
				});

			// var sqlStatement = "API.RDN_JSONObjectForSQLEntity @setName='" + item.setName + "'";
			// console.log("sqlStatement = " + sqlStatement);
			// this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			// 	console.log("data = %O", data);
			// 	this.selectedEntity = data;
			// });
		}
	}

	openTableEditor(item: any) {}
}
