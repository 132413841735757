<div class="card card-tasks" id="card-whole">
	<div class="card-header">
		<h4 class="card-title d-inline" style="padding-left: 15px">
			Template Selection
		</h4>
		<!-- <h4 class="card-title" style="display: inline-block">Training Courses</h4> -->

		<div
			style="
				padding: 0px;
				float: right;
				margin-right: 20px;
				margin-left: 20px;
			"
			tooltip="Close"
			placement="bottom"
			(click)="closeDialogWindow()"
		>
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div class="card-body">
		<mat-button-toggle-group #group="matButtonToggleGroup" value="create">
			<mat-button-toggle value="create" aria-label="Text align center">
				Create Template
			</mat-button-toggle>
			<mat-button-toggle value="edit" aria-label="Text align left">
				Edit Template
			</mat-button-toggle>
		</mat-button-toggle-group>

		<div>
			<form [formGroup]="gseForm" (submit)="submitForm(gseForm)">
				<div
					class="card card-tasks center"
					*ngIf="showNoAssetTemplateMsg === true"
				>
					<h4>There is no Fleet Summary Template. Please create one.</h4>
				</div>
				<div
					class="row col-sm-12 col-md-12"
					*ngIf="
						showNoAssetTemplateMsg === false &&
						group.value === 'edit'
					"
				>
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Below you can edit an existing Fleet Summary template, which will be shown in the Fleet Summary page on the overview tab as a selectable option. Once you select the template you want to edit and click the submit button, you can edit the template in the Canvas.
						</p>
						<br />

						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>Please select from existing Template</label
							>
						</div>

						<div class="col-sm-8 col-md-8">
							<div class="form-group">
								<select
									required
									class="form-control"
									id="templateId"
									formControlName="templateId"
									(change)="
										checkForTemplateSelected($event)
									"
								>
									<option
										class="form-control"
										*ngFor="
											let model of templatesExistingForUser
										"
										[value]="model.Id"
									>
										{{ model.Name }}
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div
					class="card-footer text-center"
					*ngIf="group.value === 'edit'"
				>
					<button
						class="btn btn-danger"
						type="submit"
						*ngIf="templateSelected === true"
						id="Edit"
					>
						Edit Template
					</button>&nbsp;
					<button
						class="btn btn-danger"
						type="button"
						(click)="canvasTemplateToDelete()"
						*ngIf="templateSelected === true"
						id="Delete"
					>
						Delete Template
					</button>&nbsp;
					<button
						class="btn btn-danger"
						type="button"
						(click)="canvasTemplateToRename()"
						*ngIf="templateSelected === true"
						id="Delete"
					>
						Re-Name Template
					</button>
				</div>

				<div
					class="row col-sm-12 col-md-12"
					*ngIf="group.value === 'create'"
				>
					<div class="row col-sm-12 col-md-12">
						<br />
						<p style="display: inline-block" style="font-size: 12px">
							Below you can configure a new Fleet Summary template, which will be shown in the Fleet Summary page on the overview tab as a selectable option. Once you click the submit button, you can edit the template in the Canvas.
						</p>
						<br />
						<div class="row col-sm-12 col-md-12">
							<div class="col-sm-4 col-md-4 col-form-label">
								<label for="subjectId"
									>Enter a name for the template*</label
								>
							</div>
							<div class="col-sm-8 col-md-8">
								<div class="form-group">
									<input
										class="form-control"
										type="text"
										id="templateName"
										formControlName="templateName"
										(change)="
										checkForCanvasTemplateName($event)
										"
									/>
								</div>
							</div>
						</div>
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>Select An Asset for the
								template(Optional)</label
							>
						</div>
						<div class="col-sm-8 col-md-8">
							<div class="form-group">
								<select
									required
									class="form-control"
									id="assetId"
									formControlName="assetId"
								>
									<option
										class="form-control"
										*ngFor="let a of assets"
										[value]="a.Id"
									>
										{{ a.Name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>Select An Asset model for the
								template(Optional)</label
							>
						</div>
						<div class="col-sm-8 col-md-8">
							<div class="form-group">
								<select
									required
									class="form-control"
									id="assetModelId"
									formControlName="assetModelId"
								>
									<option
										class="form-control"
										*ngFor="let a of assetModels"
										[value]="a.Id"
									>
										{{ a.Model }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-sm-4 col-md-4 col-form-label">
							<label for="subjectId"
								>Select An Asset type for the
								template(Optional)</label
							>
						</div>
						<div class="col-sm-8 col-md-8">
							<div class="form-group">
								<select
									required
									class="form-control"
									id="assetTypeId"
									formControlName="assetTypeId"
								>
									<option
										class="form-control"
										*ngFor="let a of assetTypes"
										[value]="a.Id"
									>
										{{ a.Name }}
									</option>
								</select>
							</div>
						</div>

					</div>

				</div>
				<div
					class="card-footer text-center"
					*ngIf="group.value === 'create'"
				>
					<button class="btn btn-danger" type="submit" id="Create" *ngIf="canvasTemplateName?.length > 0">
						Create New Template
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
