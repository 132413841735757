<div
	class="card card-tasks widget-card"
	id="card-whole"
	cdkDrag
	cdkDragRootElement=".cdk-overlay-pane"
>
	<div
		class="card-header widget-card-header"
		style="cursor: default; padding-top: 0px; min-height: 0px"
		cdkDragHandle
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6
				class="title d-inline"
				style="padding-top: 15px; display: inline-block"
			>
				Time Scope Settings Confirmation
			</h6>

		</div>

		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body">
		<div style="height: 85%; width: 100%">

			<kendo-grid
				[data]="inputObject.dashboardWidgets"
				[style.height.%]="100"
				#dataGrid
			>
				<kendo-grid-column
					title="Change to {{
						inputObject.selectedTimescopeObject.Description
					}}"
					field="isTimeScopeSelected"
				>
					<ng-template kendoGridCellTemplate
					let-dataItem>
						<mat-checkbox
							class="example-margin"
							[(ngModel)]="dataItem.isTimeScopeSelected"
							[disabled]="
							dataItem.isTimeScopeSelected &&
							dataItem.TimeScopeId ===
									inputObject.selectedTimescopeObject.Id
							"
						></mat-checkbox>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Name"
					field="WidgetName"
				>

				</kendo-grid-column>
				<kendo-grid-column
				title="Time Scope Selected"
				field="TimeScopeDescription"
			>

			</kendo-grid-column>
			</kendo-grid>
		</div>
		<div>
			<button
			class="btn btn-fill btn-primary"
			type="button"

			(click)="submitAndClose()"
			style=" margin: 0px 10px; float: right;"
		>
			<i style="font-size: 10px">Submit and Close</i>
		</button>
		<button
			class="btn btn-fill btn-primary"
			type="button"

			(click)="selectAllAndClose()"
			style=" margin: 0px; float: right;"
		>
			<i style="font-size: 10px">Select All and Close</i>
		</button>
		</div>

	</div>
</div>
