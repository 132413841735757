<div *ngIf="question.visible" [formGroup]="form" class="row col-sm-12 col-md-12" [ngClass]="((internalDialogIsVisible$ | async) || global.RDN.internalDialog) && !isPartOfInternalDialog ? 'disable-field' : ''">
	<div class=" col-form-label">
		<label [attr.for]="question.key" [title]="question.title" style="vertical-align: middle !important; padding: 2px">
			{{ question.label }}{{ question.required ? "*" : "" }}
			<button *ngIf="question.controlType == 'uib-button-multi-select' && question.listOfValues?.length > 1" class="btn mr-1" [ngClass]="{ 'btn-primary': !question.multiSelectedAll }" (click)="multiSelectAllButton($event, question)" title="Select all that are available.">Select {{ !question.multiSelectedAll ? "All" : "None" }}</button>
		</label>
	</div>
	<div [ngClass]="formHasDynamicPropertyFields ? 'col-sm-7 col-md-7' : 'col-sm-8 col-md-8'">
		<div [ngSwitch]="question.controlType">
			<!-- Read-only (no input field) -->
			<div *ngSwitchCase="'read-only'" class="form-group">
				<p class="form-control-static">{{ question.value }}</p>
			</div>
			<!-- Textbox -->
			<div *ngSwitchCase="'textbox'" style="padding-top: 1px; padding-bottom: 1px">
				<div [ngSwitch]="question.type">
					<div *ngSwitchCase="'generic-selector'">
						<!-- Allow the generic-selector directive to set up the formControlName and other aspects of the setName type input field. -->
						<lib-generic-selector [formGroup]="form" [question]="question" [setName]="question.setName" (notifyParent)="getChangedEventObject($event, question)"></lib-generic-selector>
					</div>
					<div *ngSwitchCase="'cell-phone-carrier-selector'">
						<lib-cell-phone-carrier-selector [formGroup]="form" [question]="question" (change)="saveValue($event, question)"></lib-cell-phone-carrier-selector>
					</div>
					<div *ngSwitchCase="'state-selector'">
						<lib-state-selector [formGroup]="form" [question]="question" (change)="saveValue($event, question)"></lib-state-selector>
					</div>
					<div *ngSwitchCase="'phone-number'">
						<!-- This will use the phone-number directive to format the number being entered into the (xxx) xxx-xxxx format. -->
						<input class="form-control" phone-number [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" (change)="saveValue($event, question)" />
					</div>
					<div *ngSwitchCase="'email'">
						<!-- This will validate the email against the regular expression mask. -->
						<input class="form-control" [class.is-invalid]="emailAddressText.invalid && emailAddressText.touched" [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" email (change)="checkPersonEmailAddress($event, question)" />
						<div *ngIf="(emailAddressText.invalid && emailAddressText.touched) || emailAddressText.dirty" style="margin-top: 5px">
							<small *ngIf="emailAddressText.errors?.required" class="text-danger">Email address is required.</small>
							<!--question mark(?) is a safe navigation operator-->
							<small *ngIf="emailAddressText.errors?.email && lastUsernameEntered != null" class="text-danger">Please provide a valid email address.</small>
						</div>
					</div>
					<div *ngSwitchCase="'username'">
						<!-- This will validate that the Username does not exist yet. -->
						<input class="form-control" [class.is-invalid]="usernameExists" [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" username />
						<div style="text-align: right !important; margin-top: 5px !important">
							<small *ngIf="usernameExists" class="text-danger"
								>Please enter a valid username. '<strong>{{ lastUsernameEntered }}</strong
								>' already exists.</small
							>
						</div>
					</div>
					<div *ngSwitchCase="'asset'">
						<!-- This will validate that the Asset Name does not exist yet. -->
						<input class="form-control" [class.is-invalid]="assetNameExists" [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" asset />
						<div style="text-align: right !important; margin-top: 5px !important">
							<small *ngIf="assetNameExists" class="text-danger"
								>Please enter a valid asset name. '<strong>{{ lastAssetNameEntered }}</strong
								>' already exists.</small
							>
						</div>
					</div>

					<div style="padding: 2px" *ngSwitchDefault>
						<input class="form-control" [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" (change)="saveValue($event, question)" />
					</div>
				</div>
			</div>
			<!-- File Upload -->
			<div *ngSwitchCase="'file-upload'" class="form-group" style="padding-top: 7px; width: 680px !important">
				<lib-file-upload-field [question]="question" [saveOnChange]="formOptions.saveValuesPerField" [saveStoredProcedureName]="formOptions.saveStoredProcedureName" [formGroup]="form" (notifyParent)="getChangedEventObject($event, question)"></lib-file-upload-field>
			</div>
			<!-- Dropdown List -->
			<div *ngSwitchCase="'dropdown'" class="form-group">
				<!-- <mat-select [id]="question.key" [formControlName]="question.key" [value]="question.selected" >
                    <mat-option *ngFor="let opt of question.options" value="opt.key">{{opt.value}}</mat-option>
                </mat-select> -->
				<select class="form-control" [title]="question.title" [id]="question.key" [formControlName]="question.key" (change)="saveValue($event, question)">
					<option class="form-control" *ngFor="let opt of question.options" [value]="opt.key">{{ opt.value }}</option>
				</select>
			</div>
			<!-- Checkbox -->
			<div *ngSwitchCase="'checkbox'" class="form-group">
				<input class="form-control" [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" (click)="saveValue($event, question)" />
			</div>
			<!-- True / False Button Group -->
			<div *ngSwitchCase="'true-false-button'" class="form-group">
				<button class="btn mr-1" [title]="question.title" [ngClass]="{ 'btn-primary': question.value == '1' }" (click)="selectTrueFalseButton($event, '1')">Yes</button>
				<button class="btn mr-1" [title]="question.title" [ngClass]="{ 'btn-primary': question.value == '0' || question.value == undefined }" (click)="selectTrueFalseButton($event, '0')">No</button>
			</div>
			<!-- Slider ON/OFF -->
			<div *ngSwitchCase="'slider'">
				<input type="checkbox" [id]="question.key" [formControlName]="question.key" class="jbt-slider-bar" />
				<label class="dynamic-form-slider-switch" [for]="question.key" [title]="question.title" (click)="sliderSelect($event, question)"></label>
				<div class="dynamic-form-slider-text" [title]="question.title">
					<div class="slider-text-on">ON</div>
					<div class="slider-text-off">OFF</div>
				</div>
			</div>
			<!-- Slider YES/NO -->
			<div *ngSwitchCase="'slider-yes-no'">
				<input type="checkbox" [id]="question.key" [formControlName]="question.key" class="jbt-slider-bar" />
				<label class="dynamic-form-slider-switch" [for]="question.key" [title]="question.title" (click)="sliderSelect($event, question)"></label>
				<div class="dynamic-form-slider-text" style="vertical-align: bottom" [title]="question.title">
					<div class="slider-text-on">Yes</div>
					<div class="slider-text-off">No</div>
				</div>
			</div>
			<!-- Radio button -->
			<div *ngSwitchCase="'radio'" class="form-group">
				<input class="form-control" [title]="question.title" [formControlName]="question.key" [id]="question.key" [type]="question.type" (click)="saveValue($event, question)" />
			</div>
			<!-- Number -->
			<div *ngSwitchCase="'number'" class="form-group">
				<input class="form-control" [title]="question.title" [formControlName]="question.key" [id]="question.key" type="number" [min]="question.min" [max]="question.max" (change)="saveValue($event, question)" />
			</div>
			<!-- Read-Only Textarea -->
			<div *ngSwitchCase="'read-only-textarea'" class="form-group" style="float: left">
				<textarea readonly class="form-control" rows="7" cols="500" [ngStyle]="{ resize: question.resize === 'vertical' ? 'vertical' : question.resize === 'horizontal' ? 'horizontal' : 'none' }">{{ question.value }}</textarea>
			</div>
			<!-- Read-Only Textarea Large -->
			<div *ngSwitchCase="'read-only-textarea-large'" class="form-group" style="float: left">
				<textarea readonly class="form-control-large" rows="15" cols="500" [ngStyle]="{ resize: question.resize === 'vertical' ? 'vertical' : question.resize === 'horizontal' ? 'horizontal' : 'none' }">{{ question.value }}</textarea>
			</div>
			<!-- Read-Only HTML -->
			<div *ngSwitchCase="'read-only-html'" class="form-group" style="float: left">
				<div readonly  style="width: 100%; height: 100%" [innerHTML]="question.value"></div>
			</div>
			<!-- Text Differences Comparison -->
			<div *ngSwitchCase="'text-differences-comparison'" class="form-group" style="float: left">
				<div class="form-control-large">
					<td-ngx-text-diff [left]="question.left" [right]="question.right" format="LineByLine" (compareResults)="onCompareResults($event)"></td-ngx-text-diff>
				</div>
			</div>
			<!-- Textarea -->
			<div *ngSwitchCase="'textarea'" class="form-group" style="float: left">
				<textarea class="form-control" rows="7" cols="500" [title]="question.title" [formControlName]="question.key" [id]="question.key" (keydown)="keydown($event)" (contextmenu)="cancelMenu()" (change)="saveValue($event, question)" rows="7" cols="500" [ngStyle]="{ resize: question.resize === 'vertical' ? 'vertical' : question.resize === 'horizontal' ? 'horizontal' : 'none' }"></textarea>
			</div>
			<!-- Textarea Large -->
			<div *ngSwitchCase="'textarea-large'" class="form-group" style="float: left">
				<textarea class="form-control-large" rows="15" cols="500" [title]="question.title" [formControlName]="question.key" [id]="question.key" (keydown)="keydown($event)" (contextmenu)="cancelMenu()" (change)="saveValue($event, question)" rows="7" cols="500" [ngStyle]="{ resize: question.resize === 'vertical' ? 'vertical' : question.resize === 'horizontal' ? 'horizontal' : 'none' }"></textarea>
			</div>
			<!-- Textarea Fancy (Kendo Toolbar)-->
			<div *ngSwitchCase="'textarea-fancy'" class="form-group" style="float: left" style="width: 100%">
				<kendo-editor [formControlName]="question.key" [id]="question.key" [title]="question.title" style="height: 100%; width: 100%">
					<kendo-toolbar [overflow]="true">
						<kendo-toolbar-buttongroup >
							<kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
						</kendo-toolbar-buttongroup>
						<kendo-toolbar-buttongroup>
							<kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
						</kendo-toolbar-buttongroup>
						<kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
						<kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
						<kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
						<kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
						<kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
						<kendo-toolbar-buttongroup>
							<kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
						</kendo-toolbar-buttongroup>
						<kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
						<kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
						<kendo-toolbar-buttongroup>
							<kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
						</kendo-toolbar-buttongroup>
						<kendo-toolbar-buttongroup>
							<kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
							<kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
						</kendo-toolbar-buttongroup>
					</kendo-toolbar>
				</kendo-editor>
			</div>
			<!-- Date Field using Kendo UI date picker -->
			<div *ngSwitchCase="'date'" class="form-group">
				<kendo-datepicker ngDefaultControl format="MM/dd/yyyy" [title]="question.title" [id]="question.key" [formControlName]="question.key" (change)="saveValue($event, question)"></kendo-datepicker>
			</div>
			<!-- Date Time Field using Kendo UI date picker -->
			<div *ngSwitchCase="'datetime'" class="form-group">
				<kendo-datepicker ngDefaultControl format="MM/dd/yyyy HH:mm" [title]="question.title" [id]="question.key" [formControlName]="question.key" (change)="saveValue($event, question)"></kendo-datepicker>
			</div>
			<!-- Site Field -->
			<div *ngSwitchCase="'site'" class="form-group">
				<button *ngFor="let site of permittedSites" class="btn mr-1" [ngClass]="{ 'btn-primary': question.value == site.Id }" (click)="selectSite($event, site)" title="{{ site.Description }}">
					{{ site.Name }}
				</button>
			</div>
			<!-- UIB Button Field -->
			<div *ngSwitchCase="'uib-button'" class="form-group">
				
				<button *ngFor="let button of question.listOfValues" class="btn mr-1" [ngClass]="{ 'btn-primary': question.value == button.Id }" (click)="selectButton($event, button)" [title]="question.title">
					{{ button.Name }}
				</button>
			</div>
			<!-- UIB Button Multi-Select Field -->
			<div *ngSwitchCase="'uib-button-multi-select'" class="form-group">
				<div *ngIf="question.listOfValues == null && !asyncProcessingInProgress" style="margin-top: 20px !important">
					<strong>No selectable values were found. Please log an issue if this is a mistake in the application.</strong>
				</div>
				<div *ngIf="question.listOfValues != null" [ngSwitch]="question.type" style="margin-top: 10px !important">
					<div *ngSwitchCase="'gate-system'">
						<button *ngFor="let button of question.listOfValues" class="btn mr-1" [ngClass]="{ 'btn-primary': button.IsSelected == 1, 'btn-small': question.buttonSize == 'small' }" (click)="multiSelectButton($event, button)" [title]="button.Title">
							<span style="color: seagreen">{{ button.SiteName }}</span> {{ button.Name }}
						</button>
					</div>
					<div *ngSwitchDefault>
						<button *ngFor="let button of question.listOfValues" class="btn mr-1" [ngClass]="{ 'btn-primary': button.IsSelected == 1, 'btn-small': question.buttonSize == 'small' }" (click)="multiSelectButton($event, button)" [title]="button.Title">
							{{ button.Name }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="formHasDynamicPropertyFields" class="col-sm-1 col-md-1" style="vertical-align: middle; padding: 0px">
		<div *ngIf="question.isDynamicProperty == true && question.key != 'OriginalEntityId'">
			<button class="btn btn-icon btn-simple pull-left" (click)="deleteProperty($event, question)">
				<i class="tim-icons icon-trash-simple" (click)="deleteProperty($event, question)" title="Click here delete this property and stop tracking it for this record."> </i>
			</button>
		</div>
	</div>
</div>
