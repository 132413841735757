import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { ISite } from '../../_models/site.model';
import { DashboardService } from '../../services/dashboard.service';
import { ISystem } from '../../_models/system.model';

@Component({
    selector: 'lib-centralunits-overview',
    templateUrl: './centralunits-overview.component.html',
    styleUrls: ['./centralunits-overview.component.scss'],
})
export class CentralunitsOverviewComponent implements OnInit {
	@Input() widgetObject: any;
	private fullDataCacheSubscription: any;
	public isDataLoading: boolean;
	assetObj: any;
	public selectedSiteId: number;
	public options: Array<any>; 
	private componentName: string = "central-units-overview: ";
	public navigationOpened: boolean = false;

	constructor(public dataService: DataService, private dashboardService: DashboardService) {}

	ngOnInit() {
		this.isDataLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe(() => {
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				});
		} else {
			Global.User.DebugMode &&
				console.log(
					'this.dataService.cache = %O',
					this.dataService.cache
				);
			this.initialize();
		}
	}

	initialize() {
		this.buildNavigationOptionsArray();
		this.getCentralUnits();
	}

	getCentralUnits() {
		if (this.widgetObject.WidgetSiteName !== undefined) {
			this.assetObj = this.dataService.cache.assets
				.where((a:any) => a.SiteId == this.widgetObject.WidgetSiteId)
				.where((a:any) => a.ParentSystem?.TypeId === 15)
				.where((a:any) => a.ParentSystem?.Assets.length > 0)
				.select((a:any) => {
					var newAsset = {
						Id: a.Id,
						Name: a.Name,
						Type: 'Central Units',
						Tags: a.Tags,
						WidgetAssetName: a.Name,
						AllTagsPopulated: false,
						BaseUnitImageURL: a.BaseUnitImageURL,
						SiteId: a.SiteId,
						SiteName: a.Site.Name,
						GateName: a.ParentSystem.Name,
						GateId: a.ParentSystem.Id
					}
					return newAsset
				})
				.orderBy((a:any) => { return a.Name })
				.toArray();
			this.widgetObject.isDisplayDataLive = true;
		}
		this.isDataLoading = false;
	}

	public changeSite(site: ISite) {
		this.widgetObject.WidgetSiteName = site.Name;
		this.widgetObject.WidgetSiteId = site.Id;
		this.selectedSiteId = site.Id; 
		this.saveWidgetObject("SiteId", site.Id.toString());
		this.getCentralUnits();
	}

	saveWidgetObject(fieldName: string, fieldValue: string) {
		var sqlStatement = "API.Widget_UpdateRecordByIdAndFieldName @WidgetId=" + this.widgetObject.WidgetId + ", @FieldName='" + fieldName + "', @FieldValue='" + fieldValue + "'";
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log("data updated: %O", data);
		});
	}

	private buildNavigationOptionsArray() {
		var service = this;
		var navigationWidth = Global.isMobile ? "90px" : "105px";
		service.selectedSiteId = service.widgetObject.WidgetSiteId;
	
		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: service.dataService.cache.sites
					.where((site: ISite) => {
						return site.Systems.length > 0 && site.Active == true && site.Systems.any((system:ISystem) => { return system.TypeId == 15 });
					})
					.orderBy((site: ISite) => {
						return site.Name;
					})
					.select((site: ISite) => {
						var newSite = {
							id: 10,
							width: navigationWidth,
							name: site.Name,
							selected: service.selectedSiteId == site.Id,
							action: () => service.changeSite(site)
						};
						return newSite;
					})
					.toArray(),
				root: true,
				opened: service.selectedSiteId != null ? false : true 
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => service.widgetObject.editWidgetName(service.widgetObject)
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => service.dashboardService._reloadedWidget.next(service.widgetObject)
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => service.widgetObject.deleteFunction()
					}
				],
				root: true,
				opened: false
			}
		];

		if (Global.isMobile) {
			service.options = service.options.where((item:any) => { return item.id != 2 }).toArray(); //--removing Settings tab for mobile. 
		}

		console.log(this.componentName + "navigation = %O", this.options);
	}

	public checkNavigation(opened: any) {
		console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
	}

	public checkSelectedItem(selected: any) {
		console.log("checkSelectedItem invoked. selected = %O", selected);
	}
	
}


