<div class="card card-tasks">
	<div class="card-header" style="min-height: 50px;">
		<h4 class="card-title d-inline" style="padding-left: 15px">
			Alert Settings {{alertObject.fleetName}} {{alertObject.siteName}} {{alertObject.gateSystemName}} {{alertObject.category}}
		</h4>
		<div
			style="
				padding: 0px;
				float: right;
				margin-right: 20px;
				margin-left: 20px;
			"
			tooltip="Close"
			placement="bottom"
			(click)="closeDialogWindow()"
		>
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div class="card-body" style="overflow: auto">

		<h6 style="margin-top: 5px; margin-left: 10px;" *ngFor=" let message of messages">{{message}}</h6>

        <div style="padding: 20px;">

			<h6>Fault Type</h6>
			<p *ngIf="alertLevel == 'tag'" style="margin-left: 10px;">Set a fault on the selected tag or the stardard observation record for the site.</p>
			<div style="padding: 10px;">
			<mat-button-toggle-group  *ngIf="alertLevel != 'tag'"  multiple #group="matButtonToggleGroup"  [(ngModel)] = "alertObject.alarmType" (change)="OnToggleChanged(group.value)">
				<mat-button-toggle value="criticalOnly" title="set the alert notifications for critical only" >
					{{this.dataService.getSeverityNameById(3)}}</mat-button-toggle>
				<mat-button-toggle value="allAlarms" title="set the alert notifications for fault only" >
					{{this.dataService.getSeverityNameById(1)}}</mat-button-toggle>

				<mat-button-toggle *ngIf="alertObject.fleetId != null" value="alerts" title="set the alert notifications for alert only" >
					Alert</mat-button-toggle>
			</mat-button-toggle-group>

			<mat-button-toggle-group  *ngIf="alertLevel == 'tag'" #group="matButtonToggleGroup"  [(ngModel)] = "alertObject.alarmTagType">

				<mat-button-toggle value="tag" title="set the alert notifications for the tag" >
					{{alertObject.fleetId == null ? 'Tag' : 'Asset' }}</mat-button-toggle>
				<mat-button-toggle value="stdObs" title="set the alert notifications for the Standard Observation" >
					{{alertObject.fleetId == null ? 'Standard Observation' : 'Fleet' }}
				</mat-button-toggle>

			</mat-button-toggle-group>

			</div>

			<h6>Delivery</h6>
			<div style="padding: 10px;">
				<p><input class="form-check-input" [(ngModel)]="alertObject.Email" type="checkbox" title="Email" /> Email </p>
				<p><input class="form-check-input" [(ngModel)]="alertObject.TextMessage" type="checkbox" title="text" /> Text </p>

			</div>

				<div class="form-group" style="display:flex; flex-direction: row;">
					<div class="col-sm-2 col-md-2 col-form-label">
						<label for="minutestowait">Alert Wait (minutes)</label>
					</div>
					<div class="col-sm-10 col-md-10">
						<input type="text" class="form-control" id="minutestowait" name="minutestowait" maxlength="4" size="4" pattern="^[0-9]$"
								placeholder="Minutes Between Notification (minimum 15)"
								title="Time to wait between notifications in minutes (minimum 15)"
								[(ngModel)]="alertObject.MinutesBetweenNotification">
						<div>
							<span style="color:'red';">
								{{alertObject.MinutesBetweenNotification != null &&
									alertObject.MinutesBetweenNotification != '' &&
									alertObject.MinutesBetweenNotification < 15 ? 'Minimum of 15 minutes is required.' : ''}}
							</span>
						</div>
					</div>
				</div>



			<h6>Days</h6>
			<div style="padding: 10px;">
				<mat-button-toggle-group #group="matButtonToggleGroup"  [(ngModel)] = "alertObject.DaysSelected">
					<mat-button-toggle value="workWeekDays" title="Select Days" > Select Days </mat-button-toggle>
					<mat-button-toggle value="allDays" title="all days" > All Days </mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div *ngIf="alertObject.DaysSelected == 'workWeekDays'"  style="padding: 20px;">

				<p style="margin-bottom: 15px;">Select the days of the week to receive these alerts.</p>

				<div *ngFor=" let dayOfWeek of dataCollections.daysOfWeek " class="form-check-inline" >
					<div  id="ck-button" >
						<label>
							<input type="checkbox" [(ngModel)]="alertObject.days[dayOfWeek]" title="dayOfWeek" (click)="clickDay(dayOfWeek)"/>
							<span>{{ dayOfWeek }}</span>
						</label>
					</div>
				</div>
			</div>

			<h6>Hours</h6>
			<div style="padding: 10px;">
				<mat-button-toggle-group #group="matButtonToggleGroup"  [(ngModel)] = "alertObject.HoursSelected" >
					<mat-button-toggle value="workHours" title="Select the Hours to be alerted in user time" > Select Hours </mat-button-toggle>
					<mat-button-toggle value="allHours" title="all" > All Hours </mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div *ngIf="alertObject.HoursSelected == 'workHours'"  style="padding: 20px;">

				<p style="margin-bottom: 15px;">Select the hours in the day to receive these alerts.</p>

				<div *ngFor=" let hourOfDay of dataCollections.hoursOfDay " class="form-check-inline" >
					<div  id="ck-button" >
						<label>
							<input type="checkbox" [(ngModel)]="alertObject.hours[hourOfDay]" title="hourOfDay" (click)="clickHour(hourOfDay)"/>
							<span>{{ hourOfDay }}</span>
						</label>
					</div>
				</div>
			</div>

		</div>

		<button class="btn btn-fill btn-info" type="submit"
			[disabled]="(alertObject.alarmType.length == 0 && alertObject.alarmTagType == '')
				|| (alertObject.MinutesBetweenNotification != null &&
									alertObject.MinutesBetweenNotification != '' &&
									alertObject.MinutesBetweenNotification < 15)
				|| !alertObject.DaysSelected
				|| (alertObject.DaysSelected == 'workWeekDays'
					&& (alertObject.days['Mon'] == undefined || alertObject.days['Mon'] == false)
					&& (alertObject.days['Tue'] == undefined || alertObject.days['Tue'] == false)
					&& (alertObject.days['Wed'] == undefined || alertObject.days['Wed'] == false)
					&& (alertObject.days['Thu'] == undefined || alertObject.days['Thu'] == false)
					&& (alertObject.days['Fri'] == undefined || alertObject.days['Fri'] == false)
					&& (alertObject.days['Sat'] == undefined || alertObject.days['Sat'] == false)
					&& (alertObject.days['Sun'] == undefined || alertObject.days['Sun'] == false))
				|| !alertObject.HoursSelected
				|| (alertObject.HoursSelected == 'workHours'
					&& (alertObject.hours[0] == undefined || alertObject.hours[0] == false)
					&& (alertObject.hours[1] == undefined || alertObject.hours[1] == false)
					&& (alertObject.hours[2] == undefined || alertObject.hours[2] == false)
					&& (alertObject.hours[3] == undefined || alertObject.hours[3] == false)
					&& (alertObject.hours[4] == undefined || alertObject.hours[4] == false)
					&& (alertObject.hours[5] == undefined || alertObject.hours[5] == false)
					&& (alertObject.hours[6] == undefined || alertObject.hours[6] == false)
					&& (alertObject.hours[7] == undefined || alertObject.hours[7] == false)
					&& (alertObject.hours[8] == undefined || alertObject.hours[8] == false)
					&& (alertObject.hours[9] == undefined || alertObject.hours[9] == false)
					&& (alertObject.hours[10] == undefined || alertObject.hours[10] == false)
					&& (alertObject.hours[11] == undefined || alertObject.hours[11] == false)
					&& (alertObject.hours[12] == undefined || alertObject.hours[12] == false)
					&& (alertObject.hours[13] == undefined || alertObject.hours[13] == false)
					&& (alertObject.hours[14] == undefined || alertObject.hours[14] == false)
					&& (alertObject.hours[15] == undefined || alertObject.hours[15] == false)
					&& (alertObject.hours[16] == undefined || alertObject.hours[16] == false)
					&& (alertObject.hours[17] == undefined || alertObject.hours[17] == false)
					&& (alertObject.hours[18] == undefined || alertObject.hours[18] == false)
					&& (alertObject.hours[19] == undefined || alertObject.hours[19] == false)
					&& (alertObject.hours[20] == undefined || alertObject.hours[20] == false)
					&& (alertObject.hours[21] == undefined || alertObject.hours[21] == false)
					&& (alertObject.hours[22] == undefined || alertObject.hours[22] == false)
					&& (alertObject.hours[23] == undefined || alertObject.hours[23] == false))
				"
			(click)="saveChanges()" style=" margin-left: 10px; padding: 3px 6px; margin-top:  0px; margin-right: 0px; margin-bottom: 0px;">
			<i style="font-size: 10px">Save Changes</i>
		</button>

		<button class="btn btn-fill btn-danger" type="submit"
			[disabled]="alertObject.alarmType.length == 0 && alertObject.alarmTagType == ''"
			(click)="removeAlert()" style=" margin-left: 10px; padding: 3px 6px; margin-top:  0px; margin-right: 0px; margin-bottom: 0px;">
			<i style="font-size: 10px">Remove Alert</i>
		</button>

	</div>


</div>
