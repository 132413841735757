import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Global } from '../_constants/global.variables';
import _ from 'lodash';
import { DataService } from '../../lib/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class PunchoutGuard implements CanActivate {
	constructor(
		public dataService: DataService
	) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	  console.log('Asset Id selected = %O', route.params.id);
	  let selectedAssetId = route.params.id;
	  let assetIsAllowed = false;

	  let asset = this.dataService.cache.assets.find((a) => a.Id == parseInt(selectedAssetId));
	  if (asset != undefined)
		  assetIsAllowed = true;

	  return assetIsAllowed;
  }
}
