<div class="card card-tasks" id="card-whole">
	<div class="card-header">
		<h4 class="card-title d-inline" style="padding-left: 15px">
			Summary Tile Settings
		</h4>
		<div
			style="
				padding: 0px;
				float: right;
				margin-right: 20px;
				margin-left: 20px;
			"
			tooltip="Close"
			placement="bottom"
			(click)="closeDialogWindow()"
		>
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div class="card-body">
		<div class="table-container">
			<table *ngIf="inputObject != undefined" class="table">
				<thead class="text-primary">
					<tr>
						<th>Operator</th>
						<th>Value</th>
						<th style="z-index: 2" class="text-middle">Color</th>
					</tr>
				</thead>
				<tbody class="example-list" >
					<tr class="example-box" *ngFor="let i of inputObject">
						<td>
							<mat-form-field appearance="fill">
								<mat-select (selectionChange)="operatorChanged($event, i)" [(ngModel)]="i.Operator">
								<mat-option *ngFor="let o of operators" [value]="o">
									{{o}}
								</mat-option>
								</mat-select>
							</mat-form-field>
						</td>
						<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
							<input class="form-control" type="text" id="Value" [(ngModel)]="i.Value" (change)="valueChanged($event, i)"/>
						</td>

						<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
							<input [(colorPicker)]="i.HexColor" [style.background]="i.HexColor" (colorPickerClose)="colorChanged($event, i)"/>
							<button class="btn action-btn-link-icon" title="Delete this Condition" type="button" (click)="onDelete(i)">
								<i class="tim-icons danger-color icon-trash-simple"></i>
							</button>
						</td>
					</tr>
					<tr class="example-box" *ngIf="showAddNewRecord">
						<td>
							<mat-form-field appearance="fill">
								<mat-select [(ngModel)]="conditionOperator">
								<mat-option *ngFor="let o of operators" [value]="o">
									{{o}}
								</mat-option>
								</mat-select>
							</mat-form-field>
						</td>
						<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
							<input class="form-control" type="text" id="Value" [(ngModel)]="conditionValue"/>
						</td>

						<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
							<input [(colorPicker)]="conditionColor" [style.background]="conditionColor"/>
							<button class="btn action-btn-link-icon" title="Add this Condition" type="button" (click)="addConditionRecordtoDB()">
								<i class="tim-icons danger-color icon-simple-add"></i>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<button *ngIf="!showAddNewRecord" class="btn btn-fill btn-primary" type="submit" (click)="addConditionRecord()">
				<i style="font-size: 15px">Add Condition</i>
			</button>
			<button *ngIf="inputObject.length > 0" class="btn btn-fill btn-primary" type="submit" [disabled]="unsavedChanges.length === 0" id="Edit" (click)="saveChanges()">
				<i style="font-size: 15px">Save Conditions</i>
			</button>
		</div>
	</div>


</div>
