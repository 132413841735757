<div style="display: flex;">
  <!-- <i *ngIf="graphTypes[graphType? graphType.id : currentGraphTypeId] !== undefined" class="tim-icons icon-notes" [matTooltip]="graphTypes[graphType? graphType.id : currentGraphTypeId].description"
  [matTooltipPosition]="'below'" style="margin-right: 5px"></i>-->
  <i
			(click)=" showDialogModalPopupGrid()"
			class="tim-icons icon-notes clipboard-larger"
			aria-hidden="true"
			style="cursor: pointer;margin-right: 5px;margin-top: 10px"
			title="View Raw Data"
		>
		</i>
  <mat-form-field style="flex: 100" appearance="fill">
      <mat-label>Select a Chart Type</mat-label>

      <mat-select [value]="graphType? graphType.id : currentGraphTypeId" (selectionChange)="graphTypeChange($event)">
          <mat-option *ngFor="let graphType of graphTypes" [value]="graphType.id" >
              {{ graphType.properName }}
          </mat-option>
      </mat-select>

  </mat-form-field>
  <mat-slide-toggle style="margin: 10px" [checked]="legendVisible" (change)="toggleLegendChange($event)">Legend</mat-slide-toggle>
</div>
