import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PbbSummaryComponent } from './pbb-summary.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatTabsModule } from '@angular/material/tabs';
import { PbbQuickViewModule } from '../pbb-quick-view/pbb-quick-view.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { AngularResizeEventModule } from 'angular-resize-event';
import { LastThousandGridModule } from '../last-thousand-grid/last-thousand-grid.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { MatBadgeModule } from '@angular/material/badge';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { CameraViewModule } from "../camera-view/camera-view.module";

@NgModule({
    declarations: [PbbSummaryComponent],
    exports: [PbbSummaryComponent],
    imports: [
        CommonModule,
        LoadingSpinnerModule,
        MatTabsModule,
        PbbQuickViewModule,
        GridModule,
        AngularResizeEventModule,
        LastThousandGridModule,
        FormsModule,
        CenteredMessageModule,
        ReactiveFormsModule,
        MatBadgeModule,
        DynamicFormModule,
        PipeModule,
        KendoGridParentModule,
        CameraViewModule
    ]
})
export class PbbSummaryModule {}
