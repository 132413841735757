import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PbbQuickViewComponent } from './pbb-quick-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [PbbQuickViewComponent],
	imports: [CommonModule, MatTooltipModule, ClipboardModule, NgbModule],
	exports: [PbbQuickViewComponent],
})
export class PbbQuickViewModule {}
