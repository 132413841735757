import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { filter } from 'rxjs/operators';
import { DisplaySetupService } from '../../services/display-setup.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { Global } from '../../_constants/global.variables';



@Component({
	selector: 'lib-adveez-site',
	templateUrl: './adveez-site.component.html',
	styleUrls: ['./adveez-site.component.scss'],
})
export class AdveezSiteComponent implements OnInit, OnDestroy {
	@Input() widgetObject: any;
	public adveezURL: any;

	constructor(
		private sanitizer: DomSanitizer,
		private displaySetupService: DisplaySetupService,
		private signalRCore: SignalRCoreService
	) {
		this.adveezURL = sanitizer.bypassSecurityTrustResourceUrl(
			'https://localeez.adveez.com/.?&output=embed'
		);
	}

	ngOnInit() {
		this.widgetObject.displaySettings = {
			headingBackground: 'linear-gradient(to bottom,#3eff3e, #eefeee)',
			tagDataSortField: '-LastObservationDate',
			headingExtraTitle: '',
			obscureGraphics: true,
		};

		this.displaySetupService.setPanelBodyWithIdHeight(this.widgetObject.Id);
		this.displaySetupService.setWidgetIFrameHeight(this.widgetObject.Id);

		this.displaySetupService.setWidgetPanelBodyDimensions(
			this.widgetObject.Id
		);

		// setTimeout(() => {
		// 	this.displaySetupService.setPanelBodyWithIdHeight(
		// 		this.widgetObject.Id
		// 	);
		// 	this.displaySetupService.setWidgetIFrameHeight(
		// 		this.widgetObject.Id
		// 	);
		// }, 250);

		// this.signalRCore.broadcastMessages$
		// 	.pipe(filter((msg: any) => msg.code == 'WidgetResize'))
		// 	.subscribe(
		// 		(data: any) => {
		// 			var resizedWidgetId = data.object;
		// 			if (
		// 				this.widgetObject.Id == resizedWidgetId ||
		// 				resizedWidgetId == 0
		// 			) {
		// 				this.displaySetupService.setPanelBodyWithIdHeight(
		// 					this.widgetObject.Id
		// 				);
		// 				this.displaySetupService.setWidgetIFrameHeight(
		// 					this.widgetObject.Id
		// 				);
		// 			}
		// 		},
		// 		(err) => console.log(`${err}`)
		// 	);

		// this.signalRCore.broadcastMessages$
		// 	.pipe(filter((msg: any) => msg.code == 'WidgetResize.Stop'))
		// 	.subscribe(
		// 		(data: any) => {
		// 			var resizedWidgetId = data.object;
		// 			setInterval(
		// 				() => {
		// 					this.displaySetupService.setPanelBodyWithIdHeight(
		// 						this.widgetObject.Id
		// 					);
		// 					this.displaySetupService.setWidgetIFrameHeight(
		// 						this.widgetObject.Id
		// 					);
		// 				},
		// 				50,
		// 				20
		// 			);
		// 		},
		// 		(err) => console.log(`${err}`)
		// 	);
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
	}
}
