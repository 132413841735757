import { Component, OnInit, Input, Optional } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';



@Component({
  selector: 'lib-pca-pressures',
  templateUrl: './pca-pressures.component.html',
  styleUrls: ['./pca-pressures.component.scss']
})
export class PcaPressuresComponent implements OnInit {

  @Optional() @Input() summaryInputTagId: any;
  @Optional() @Input() widgetInputTagId: any;
  @Optional() @Input() jbtStandardObservationIdInput: any;
  tagId: any;
  jbtStandardObservationId: any;
  fullDataCacheSubscription: any;
  theme: string;
  tagObject: any;
  onImage: any;
  offImage: any;

  constructor(public dataService: DataService) { }

  ngOnInit() {
    if (this.summaryInputTagId !== undefined) {
      this.tagId = this.summaryInputTagId;
    } else if (this.widgetInputTagId !== undefined) {
      this.tagId = this.widgetInputTagId;
    } else if (this.jbtStandardObservationIdInput !== undefined) {
      this.jbtStandardObservationId = this.jbtStandardObservationIdInput;
    }

    if (!Global.FullDataCacheExists) {
      this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
        if (data === true) {
          if (Global.Theme === "light") {
            this.theme = "light";
          } else if (Global.Theme === "dark") {
            this.theme = "dark";
          }
          this.initialize();
          this.fullDataCacheSubscription.unsubscribe();
        }
      });
    } else {
      if (Global.Theme === "light") {
        this.theme = "light";
      } else if (Global.Theme === "dark") {
        this.theme = "dark";
      }
      this.initialize();
    }

  }

  initialize() {
    if (this.tagId != undefined)
      this.tagObject = this.dataService.cache.tagsObject[this.tagId];

    //Compressor 1
    if (
		(this.tagId != undefined &&
			this.tagObject.JBTStandardObservationId == 3967) ||
		this.jbtStandardObservationId == 3967
	) {
		this.onImage = Global.imagesUrl + 'assets/img/gs-images/Comp-1-on.svg';
		this.offImage =
			Global.imagesUrl + 'assets/img/gs-images/Comp-1-off.svg';
	}
	//Compressor 2
	if (
		(this.tagId != undefined &&
			this.tagObject.JBTStandardObservationId == 3598) ||
		this.jbtStandardObservationId == 3598
	) {
		this.onImage = Global.imagesUrl + 'assets/img/gs-images/Comp-2-on.svg';
		this.offImage =
			Global.imagesUrl + 'assets/img/gs-images/Comp-2-off.svg';
	}
	//Compressor 3
	if (
		(this.tagId != undefined &&
			this.tagObject.JBTStandardObservationId == 3968) ||
		this.jbtStandardObservationId == 3968
	) {
		this.onImage = Global.imagesUrl + 'assets/img/gs-images/Comp-3-on.svg';
		this.offImage =
			Global.imagesUrl + 'assets/img/gs-images/Comp-3-off.svg';
	}
	//Compressor 4
	if (
		(this.tagId != undefined &&
			this.tagObject.JBTStandardObservationId == 3969) ||
		this.jbtStandardObservationId == 3969
	) {
		this.onImage = Global.imagesUrl + 'assets/img/gs-images/Comp-4-on.svg';
		this.offImage =
			Global.imagesUrl + 'assets/img/gs-images/Comp-4-off.svg';
	}
  }

}
