import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartTypeSliderComponent } from './chart-type-slider.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
	declarations: [ChartTypeSliderComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatTooltipModule,
		MatSlideToggleModule,
	],
	exports: [ChartTypeSliderComponent],
})
export class ChartTypeSliderModule {}
