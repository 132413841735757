import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	ViewChild,
} from '@angular/core';


import { process } from '@progress/kendo-data-query';
import { GridSettings } from '../../_models/grid-settings.interface';
import { filter } from 'rxjs/operators';

import _ from 'lodash';

import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { Global } from '../../_constants/global.variables';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { DashboardService } from '../../services/dashboard.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';
import { ITag } from '../../_models/tag.model';

@Component({
	selector: 'lib-gse-operational-alarms',
	templateUrl: './gse-operational-alarms.component.html',
	styleUrls: ['./gse-operational-alarms.component.scss'],
})
export class GseOperationalAlarmsComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	public geofencingStandardObservationIds: any = [
		56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451,
		56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461,
	];
	@Optional() @Input() widgetObject: any;
	@ViewChild('tagDataRowForCalculation') tagDataRowForCalculation: ElementRef;
	@ViewChild('operationalActiveAlarmsContainer')
	operationalActiveAlarmsContainer: any;
	@ViewChild('dataGrid') dataGrid: any;
	fullDataCacheSubscription: Subscription;
	siteList: any;
	sites: any[];
	signalRObservationFormattedTagsSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	isDataLoading: boolean = true;
	timeZoneType: any;
	selectedMatTabIndex = 0;
	isWidgetInView: boolean = false;
	tagDataRowHeight: any = 26;
	tagDataPageSize: number;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	public gridSettings: any = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'Asset.Name',
				title: 'Asset',
				filterable: true,
				_width: 300,
			},
			{
				field: 'SiteName',
				title: 'Site Name',
				filterable: true,
				_width: 100,
			},

			{
				field: 'Value',
				title: 'Geofence',
				filterable: true,
				_width: 150,
			},
			{
				field: 'JBTStandardObservation.Name',
				title: 'Violation',
				filterable: true,
				_width: 300,
			},
			{
				field: 'Severity',
				title: 'Severity',
				filterable: true,
				_width: 150,
			},
			{
				field: 'JavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 300,
			},
			{
				field: 'SiteLocalJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 300,
			},
			{
				field: 'UTCJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 300,
			},
		],
	};
	newHeight: any;
	oldHeight: any;
	newWidth: any;
	oldWidth: any;
	assetTagIds: any = [];
	widgetGroupSettings: IWidgetSignalRGroupObject;
	signalRTagUpdateSubscription: any;
	gridData: any = [];
	unfilteredTagsForAssets: any;
	widgetIsInViewSubscription: any;
	alarmTypeCounts = { Alarms: 0, Warnings: 0, Criticals: 0 };

	bodyTextColor: string;
	widgetResizedSubscription: any;
	siteAssetIds: any;
	displaySiteSelectionMessage: boolean = false;
	gseSummaryModalSubscription: any;
	componentName: string = "gse-operational-alarms: ";
	public guid: string;
	constructor(
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private dashboardService: DashboardService,
		public kendoSettingsService: KendoSettingsService,
		public dialog: MatDialog
	) {}

	ngAfterViewInit() {
		this.isWidgetInView = this.widgetObject.isWidgetInView;
		this.widgetIsInViewSubscription =
			this.dashboardService.widgetInViewChanged$.subscribe(
				(data: any) => {
					if (data.widgetId === this.widgetObject.WidgetId) {
						if (this.tagDataRowForCalculation !== undefined) {
							this.tagDataRowHeight =
								this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
						}

						console.log(this.isWidgetInView);
						console.log(this.widgetObject.isWidgetInView);
						if (
							this.isWidgetInView === false &&
							data.isInView === true
						) {
							this.loadDataForTable();
						}
						this.isWidgetInView = data.isInView;
					}
				}
			);

		console.log(this.widgetObject);
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.startInitializingComponent();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
			//console.log("CAcheeee",this.dataService);
		} else {
			this.startInitializingComponent();
		}

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					this.timeZoneType =
						this.dashboardService.determineTimeZoneType(
							foundWidgetWithSameWidgetId
						);
					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.changeColumnsForTimeZone();
					}
				});
		}
	}
	ngOnInit(): void {
		this.guid = this.dataService.guid();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);
		
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
	}

	startInitializingComponent() {
		if (!_.isNil(this.widgetObject.SiteList)) {
			if (typeof this.widgetObject.SiteList === 'string') {
				this.siteList = this.widgetObject.SiteList.split(',').map(
					(item) => {
						return parseInt(item);
					}
				);
			} else {
				this.siteList = this.widgetObject.SiteList;
			}
		}
		else if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
		}

		if (
			(_.isNil(this.widgetObject.SiteList) ||
			this.widgetObject.SiteList.length < 1) &&
			_.isNil(this.widgetObject.VocationalSettingsJSON)
		) {
			this.displaySiteSelectionMessage = true;
			this.isDataLoading = false;
			return;
		} else {
			this.displaySiteSelectionMessage = false;
		}
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);
		this.changeColumnsForTimeZone();

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe(
				(data) => {
					// if (this.Chart && this.widgetObject.WidgetId == data.item.WidgetId) {
					// this.Chart.setSize($(".alert-chart").width(), $(".alert-chart").height(), false);
					if (this.widgetObject.WidgetId == data.item.WidgetId) {
						// Resize the major and minor divs
					}
				}
			);
		}


		if (!_.isNil(this.widgetObject.SiteList)) {

			let assetListForUserSites = [];
			this.sites = [];
			var sqlStatement = this.dataService.GetSitesWithGSE();
			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				data.forEach((element) => {
					if (this.siteList.includes(element.iopsSiteId)) {
						this.sites.push(element);
					}
				});

				assetListForUserSites = this.dataService.cache.assets.filter(
					(asset) => {
						if (asset.Site !== undefined) {
							const allGSEAssetIds = [
								86292, 86293, 86294, 86295, 86296, 86297,
							];
							return (
								this.siteList.includes(asset.Site.Id) &&
								allGSEAssetIds.includes(asset.AssetTypeId)
							);
						}
					}
				);

				if (this.widgetObject.WidgetAssetId != undefined) {
					assetListForUserSites = assetListForUserSites.filter((a) => {
						return a.Id == this.widgetObject.WidgetAssetId;
					});
				}

				let siteAssetIdsAsString = assetListForUserSites
					.map((a) => a.Id.toString())
					.join();
				this.siteAssetIds = assetListForUserSites.map((a) => a.Id);

				// Global.User.DebugMode && console.log(siteAssetIds);
				// need a list of all the asset id's for all teh sites as a string, comma seperated.
				this.signalRObservationFormattedTagsSubscription = this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
						siteAssetIdsAsString,
						false,
						this.geofencingStandardObservationIds
					)
					.subscribe((data: any) => {
						if(data) {
							this.formatTagDataForGrid(data);
						}
						Global.User.DebugMode && console.log(data);
					});
			});
		}
		else if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
			var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
			let fleetId = VocationalSettings.id;

			let assetListForUserFleet = [];

			this.dataService.GetFleetAssets(fleetId).subscribe((data: any) => {

				let myFleetAssets = new Array();
				data.forEach(record => {
					myFleetAssets.push(Number(record.AssetId));
				});

				assetListForUserFleet = this.dataService.cache.assets.filter((asset) => {
					return myFleetAssets.includes(asset.Id)
				});

				let fleetAssetIdsAsString = assetListForUserFleet
					.map((a) => a.Id.toString())
					.join();

				// get the name of the Fleet
				this.widgetObject.FleetName = data.length > 0 ? data[0].FleetName : "";

				this.signalRObservationFormattedTagsSubscription = this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
						fleetAssetIdsAsString,
						false,
						this.geofencingStandardObservationIds
					)
					.subscribe((data: any) => {
						if(data) {
							this.formatTagDataForGrid(data);
						}
						Global.User.DebugMode && console.log(data);
					});

			});

		}
	}

	changeColumnsForTimeZone() {
		const localDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'JavascriptDate'
		);
		const siteDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'SiteLocalJavascriptDate'
		);
		const utcDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'UTCJavascriptDate'
		);

		localDateCol.hidden = this.timeZoneType !== 'User Time';
		siteDateCol.hidden = this.timeZoneType !== 'Site Time';
		utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
		localDateCol.includeInChooser = !localDateCol.hidden;
		siteDateCol.includeInChooser = !siteDateCol.hidden;
		utcDateCol.includeInChooser = !utcDateCol.hidden;
	}

	filterTableData(type: string, dataGrid) {
		if (type === 'All') {
			this.gridSettings.state.filter = {
				filters: [
					{
						filters: [],
						logic: 'and',
					},
				],
				logic: 'and',
			};
		} else {
			this.gridSettings.state.filter = {
				filters: [
					{
						filters: [
							{
								field: 'Severity',
								operator: 'contains',
								value: type,
							},
						],
						logic: 'and',
					},
				],
				logic: 'and',
			};
		}
		if (this.tagDataGrid !== undefined) {
			this.tagDataGrid.gridDataSubject.next(this.gridData);
		}
	}

	formatTagDataForGrid(tags) {
		let cacheTagArray = [];
		tags.forEach((tag) => {
			let tagObj = this.dataService.cache.tagsObject[tag.Id];
			cacheTagArray.push(tagObj);
		});
		console.log(cacheTagArray);

		this.gridData = _.cloneDeep(cacheTagArray);

		this.assetTagIds = cacheTagArray.map((t) => t.TagId);
		this.getSignalRUpdates();
		this.finishInitializingComponent();
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = _.uniq(this.siteAssetIds.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix )).join();
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: this.widgetObject.WidgetName, 
										 TagNamePrefix: tagNamePrefixesString.split(",")  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);
				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					if (this.geofencingStandardObservationIds.includes(tag.JBTStandardObservationId)) {
						if (tag.Value != tag.ValueWhenActive) {
							let foundTagIfExists = this.gridData.find(
								(t) => t.TagId === tag.TagId
							);
							if (foundTagIfExists) {
								this.gridData = this.gridData.filter(
									(t) => t.TagId !== tag.TagId
								);
							}
							this.loadDataForTable();
						} else {
							let foundTagIfExists = this.gridData.find(
								(t) => t.TagId === tag.TagId
							);
							if (!foundTagIfExists) {
								this.gridData.push(tag);
							}
							this.loadDataForTable();
						}
					}
				});
			});
	}

	evaluateSelectedFilters(): any {
		if (
			this.gridSettings.state.filter.filters.length > 0 &&
			this.gridSettings.state.filter.filters[0].filters.length > 0
		) {
			let arrayToReturn = [];
			this.gridSettings.state.filter.filters[0].filters.forEach(
				(filter) => {
					return arrayToReturn.push(filter.value);
				}
			);

			return arrayToReturn;
		} else {
			return [];
		}
	}

	finishInitializingComponent() {
		this.isDataLoading = false;
		if (this.widgetObject.WidgetKendoUIJson) {
			let jsonDataObjectParsed =
				this.kendoSettingsService.parseReturnedSettingsForDates(
					this.widgetObject.WidgetKendoUIJson
				);

			//the first item in the array is the gridSettings for the first tab of data for GSE-Overview, second item is alarms grid, third item is last 1000 events, 4th is last 1000 Alarms, 5th is deicer grid
			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.

			let dataTabGrid = jsonDataObjectParsed[0];
			let test =
				this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
					this.gridSettings,
					dataTabGrid
				);
			this.gridSettings = this.mapGridSettings(test);
		}
		this.gridData = this.gridData.filter((row) => {
			return row.Value === row.ValueWhenActive;
		});

		this.loadDataForTable();
	}

	public mapGridSettings(gridSettings: GridSettings): GridSettings {
		const state = gridSettings.state;
		// this.mapDateFilter(state.filter);
		let example = gridSettings.columnsConfig.sort(
			(a, b) => a.orderIndex - b.orderIndex
		);

		console.log(example);
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
		};
	}

	loadDataForTable() {
		if (this.tagDataGrid !== undefined) {
			this.tagDataGrid.gridDataSubject.next(this.gridData);
		}
		this.alarmTypeCounts = { Alarms: 0, Warnings: 0, Criticals: 0 };
		this.gridData.forEach((row) => {
			if (row.Severity === 'Warning') {
				this.alarmTypeCounts.Warnings =
					this.alarmTypeCounts.Warnings + 1;
			}
			if (row.Severity === 'Alarm') {
				this.alarmTypeCounts.Alarms = this.alarmTypeCounts.Alarms + 1;
			}
			if (row.Severity === 'Critical') {
				this.alarmTypeCounts.Criticals =
					this.alarmTypeCounts.Criticals + 1;
			}
		});

		this.widgetObject.isDisplayDataLive = true;
		this.isDataLoading = false;
	}

	saveGridSettings() {
		if (this.widgetObject !== undefined) {
			this.kendoSettingsService
				.saveGridSettings(
					[
						{
							gridObject: this.tagDataGrid.kendoGridParent,
							gridState: this.gridSettings.state,
						},
					],
					this.widgetObject.WidgetId
				)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	public onRightClickSelect({ dataItem, item }) {
		if (item === 'Open Summary for Asset') {
			this.openSummaryWidget(dataItem, false);
		} else if (item === 'Show Location of Asset') {
			this.openSummaryWidget(dataItem, true);
		}
	}

	private openSummaryWidget(dataItem, showMap) {
		// Global.User.DebugMode && console.log(widgetObject);
		const gseSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '95%' : '60%',
			height: Global.isMobile ? '95%' : '80%',
			data: {
				widgetObject: dataItem.Asset,
				shouldShowMap: showMap,
				WidgetName: 'GSE-Summary',
			},

			maxWidth: '100vw',
			maxHeight: '100vh',
			// height: '100%',
			// width: '100%'
		});
		this.gseSummaryModalSubscription = gseSummaryModal
			.afterClosed()
			.subscribe((result) => {
				Global.User.DebugMode && console.log('The modal was closed');
				this.gseSummaryModalSubscription.unsubscribe();
			});
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(
			((event.newRect.height - 120) / this.tagDataRowHeight) * 3
		);

		this.newHeight = event.newRect.height;
		this.oldHeight = event.oldHeight;
		this.newWidth = event.newRect.width;
		this.oldWidth = event.oldWidth;
	}
}
