import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GpuSummaryComponent } from './gpu-summary.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MatTabsModule } from '@angular/material/tabs';
import { GpuQuickViewModule } from '../gpu-quick-view/gpu-quick-view.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { LinearGaugeModule } from '@progress/kendo-angular-gauges';
import { LastThousandGridModule } from '../last-thousand-grid/last-thousand-grid.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { MatBadgeModule } from '@angular/material/badge';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [GpuSummaryComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		AngularResizeEventModule,
		MatTabsModule,
		GpuQuickViewModule,
		GridModule,
		LinearGaugeModule,
		LastThousandGridModule,
		FormsModule,
		CenteredMessageModule,
		ReactiveFormsModule,
		MatBadgeModule,
		DynamicFormModule,
		PipeModule,
		KendoGridParentModule
	],
	exports: [GpuSummaryComponent],
})
export class GpuSummaryModule {}
