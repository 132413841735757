import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CenteredMessageComponent } from './centered-message.component';
// import { ServicesModule } from '../../services/services.module';



@NgModule({
  declarations: [
    CenteredMessageComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [CenteredMessageComponent]
})
export class CenteredMessageModule { }
