<div class="content">
    <div style="font-size: 34px;" [style.color]="global.Theme == 'light'? '#212427' : '#ffffff'">System Tables</div>
    <div style="margin-left: 40px;">
        <ul class="unorderedList">
            <div class="row">
                <div *ngFor="let column of columns" class="col-sm-{{12/columns.length}}">
                    <ul>
                        <li *ngFor="let item of column.items">
							<div *ngIf="item.children != null">
								<div class="parent-title">{{item.label}}</div>
								<ul class="child-table">
									<li *ngFor="let child of item.children">
										<div style="padding-left: 10px">
											<a (click)="selectEntity(child)">{{child.label}}</a>
										</div>
									</li>
								</ul>
							</div>
							<div *ngIf="item.children == null">
								<a (click)="selectEntity(item)">{{item.label}}</a>
							</div>
                        </li>
                    </ul>
                </div>
            </div>
        </ul>
    </div>
</div>
