import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";

import { QuestionBase } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-base";
import { SiteQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-site";
import { DateQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-date";
import { NumberQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-number";
import { TextboxQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-textbox";
import { DropdownQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-dropdown";
import { ReadOnlyQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-read-only";
import * as moment from "moment";
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { process, State, CompositeFilterDescriptor, distinct } from "@progress/kendo-data-query";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { UibButtonQuestion } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-uib-button";

@Component({
	selector: "app-admin-reports",
	templateUrl: "./admin-reports.component.html",
	styleUrls: ["./admin-reports.component.scss"]
})
export class AdminReportsComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	public reports: any;
	public viewReportsList: boolean = true;
	public editingReport: boolean = false;
	public enterExecuteParameters: boolean = false;
	public executingReport: boolean = false;
	public deletingReport: boolean = false;
	public displayReport: boolean = false;

	public parameterList: any;
	public permittedSites: any = Global.User.PermittedSites;
	public selectedReport: any;
	public today: Date = new Date();
	public yesterday: Date;
	public selectedSite: any;
	public selectedStoredProcedure: any;
	public storedProcedureList: any;
	public settings: any;
	public editForm: FormBuilder;
	public editReportFormGroup: FormGroup;
	public executeReportFormGroup: FormGroup;

	public newStoredProceduresExist: boolean = true;
	public addingNewReport: boolean = false;
	public loadingEditForm: boolean = false;
	public isLoadingParameters: boolean = false;

	public reportData: any;
	public reportHeader: any;
	private parameterListAsString: string = "";
	public isLoading: boolean = true;
	public parameterCount: number = 0;

	public payLoad: string = "";
	public executeFieldsAndValues: Array<any>;
	public editFieldsAndValues: Array<any>;

	public editQuestions: QuestionBase<any>[];
	public parameterQuestions: QuestionBase<any>[];
	public editFormOptions: any;
	public executeFormOptions: any;

	private swalWithBootstrapButtons: any;
	public editHeader: string;
	public editSubHeader: string;
	//Material table Properties
	public dataSource: MatTableDataSource<any>;
	//Kendo UI properties
	public skip = 0;
	public reportsSkip = 0;
	public gridView: GridDataResult;
	public gridViewReports: any;
	public filterT: CompositeFilterDescriptor;

	public pageSize = 100;

	public pageSizeReports = 100;
	public displayedColumns: string[];
	public filteredGridData: any;

	public state: State = { skip: 0 };
	public reportsState: State = { skip: 0 };

	constructor(private dataService: DataService, public formBuilder: FormBuilder, private toastr: ToastrService, private utilityService: UtilityService) { }

	ngOnInit() {
		this.yesterday = this.getYesterdaysDate();

		console.log("this.today = " + this.today + ", this.yesterday = " + this.getYesterdaysDate());

		this.dataService.SQLActionAsPromise("URPT.GetListOfReportDefinitions").then((data: any) => {
			this.reports = data;

			this.gridViewReports = process(this.reports, this.reportsState);
			console.log("this.reports = %O", this.reports);
			this.isLoading = false;
		});

		this.editReportFormGroup = new FormGroup({
			storedProcedure: new FormControl()
		});
	}

	public distinctPrimitive(fieldName: string): any {
		return distinct(this.reports, fieldName).map((item) => item[fieldName]);
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.state.skip = event.skip;
		this.state.take = event.take;
		this.gridView = process(this.reportData, this.state);
	}

	public pageChangeReports(event: PageChangeEvent): void {
		this.reportsSkip = event.skip;
		this.reportsState.skip = event.skip;
		this.reportsState.take = event.take;
		this.gridViewReports = process(this.reports, this.reportsState);
	}

	public filterChangeReports(filter: CompositeFilterDescriptor): void {
		this.filterT = filter;
		this.reportsState.filter = filter;
		this.gridViewReports = process(this.reports, this.reportsState);
	}

	public filterChange(filter: CompositeFilterDescriptor): void {
		this.state.filter = filter;
		this.gridView = process(this.reportData, this.state);
	}

	public sortChange(sort: any): void {
		this.state.sort = sort;
		this.gridView = process(this.reportData, this.state);
	}

	public sortChangeReports(sort: any): void {
		this.reportsState.sort = sort;
		this.gridViewReports = process(this.reports, this.reportsState);
	}

	getYesterdaysDate() {
		var days = 1; // Days you want to subtract
		var date = new Date();
		var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
		return last;
	}

	getLatestReportsList() {
		this.dataService.SQLActionAsPromise("URPT.GetListOfReportDefinitions").then((data: any) => {
			this.reports = data.then;
			console.log("this.reports = %O", this.reports);
			this.isLoading = false;
		});
	}

	edit(report?: any) {
		this.viewReportsList = false;
		this.editingReport = true;
		this.executingReport = false;
		this.displayReport = false;

		this.loadingEditForm = true;
		this.addingNewReport = false;

		this.editFieldsAndValues = [];

		this.editFormOptions = {
			submitButtonText: "Save Report",
			cancelButtonText: "Cancel"
		};

		if (!report) {
			this.editHeader = "Add New Report";
			this.editSubHeader = "Please edit the fields listed here for your new report.";
			console.log("adding new report...");
			this.selectedReport = null;
			this.addingNewReport = true;
			//-- only get the stored procedures that don't currently have a report definition (hence the reason for 'true' as a parameter value when getting the list of stored procedures.)  Will display a message if none exist. --Kirk T. Sherer, March 30, 2020.

			this.dataService.SQLActionAsPromise("URPT.ListOfAllStoredProcedures @WithoutReportDefinition=1").then((data: any) => {
				console.log("stored procedures: %O", data);
				if (data.length != 0) {
					this.storedProcedureList = data;
					this.newStoredProceduresExist = true;
					//-- build out the options list for the dynamic form.
					var optionsList = [];
					data.forEach((sp: any) => {
						var obj: any = {};
						obj.key = sp.ObjectId;
						obj.value = sp.Name;
						optionsList.push(obj);
					});
					console.log("optionsList = %O", optionsList);
					//-- create the dropdown question structure for the stored procedure.
					var field = new DropdownQuestion({
						key: "storedProcedure",
						label: "Stored Procedure Name",
						options: optionsList,
						order: 1
					});

					this.editFieldsAndValues.push(field);

					console.log("this.editFieldsAndValues = %O", this.editFieldsAndValues);
					this.editQuestions = this.editFieldsAndValues;
					this.parameterList = data;
				} else {
					this.newStoredProceduresExist = false;
					this.storedProcedureList = null;
				}
				this.loadingEditForm = false;
			});
		} else {
			this.editHeader = "Edit Report: ";
			this.editSubHeader = "Please edit the fields listed here for this specific report.";
			console.log("editing report...%O", report);
			this.selectedReport = report;
			this.dataService.SQLActionAsPromise("URPT.ListOfAllStoredProcedures " + "").then((data: any) => {
				console.log("stored procedures: %O", data);
				if (data.length != 0) {
					this.selectedStoredProcedure = this.selectedReport && data.first((d: any) => d.ObjectId == this.selectedReport.ObjectId);
					this.storedProcedureList = data;
					//-- build out the options list for the dynamic form.
					var optionsList = [];
					data.forEach((sp: any) => {
						var obj: any = {};
						obj.key = sp.ObjectId;
						obj.value = sp.Name;
						optionsList.push(obj);
					});
					//-- create the dropdown question structure for the stored procedure.
					var field = new DropdownQuestion({
						key: "storedProcedure",
						label: "Stored Procedure Name",
						selected: this.selectedReport.ObjectId,
						options: optionsList,
						order: 1
					});
					this.editFieldsAndValues.push(field);

					//-- now build out the read-only fields so they will also show up on the edit form. --Kirk T. Sherer, April 8, 2020.
					if (this.selectedReport) {
						var category = new ReadOnlyQuestion({
							key: "category",
							label: "Category",
							value: this.selectedReport.Category,
							order: 2
						});
						this.editFieldsAndValues.push(category);

						var reportName = new ReadOnlyQuestion({
							key: "reportName",
							label: "Report Name",
							value: this.selectedReport.ReportName,
							order: 3
						});
						this.editFieldsAndValues.push(reportName);

						var createdBy = new ReadOnlyQuestion({
							key: "createdBy",
							label: "Created By",
							value: this.selectedReport.CreatedBy + " - " + moment(this.selectedReport.CreationDate).format("MM/DD/YYYY hh:mm A"),
							order: 4
						});
						this.editFieldsAndValues.push(createdBy);

						var lastModifiedBy = new ReadOnlyQuestion({
							key: "lastModifiedBy",
							label: "Last Modified By",
							value: this.selectedReport.LastModifiedBy + " - " + moment(this.selectedReport.LastModifiedDate).format("MM/DD/YYYY hh:mm A"),
							order: 5
						});
						this.editFieldsAndValues.push(lastModifiedBy);
					}
					console.log("this.editFieldsAndValues = %O", this.editFieldsAndValues);
					this.editQuestions = this.editFieldsAndValues;
					this.loadingEditForm = false;
					this.parameterList = data;
				}
			});
		}
	}

	submitEditedReport(submittedValues: string) {
		console.log("edited report change submitted...");
		var submittedValuesObject = JSON.parse(submittedValues);
		//console.log("submittedValuesObject = %O", submittedValuesObject);
		// console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.
		this.parameterListAsString = "";
		var countOfParameters = 1;
		keys.forEach((key: any) => {
			var questions = this.editQuestions;
			questions.forEach((question: any) => {
				if (key == question.key && question.controlType != "read-only") {
					var value = submittedValuesObject[key];
					console.log("key: " + key + ", value: " + value);
					this.parameterListAsString += key + "=";
					if (isNaN(submittedValuesObject[key])) {
						this.parameterListAsString += "'" + value + "'";
					} else {
						this.parameterListAsString += value;
					}
					if (countOfParameters < keys.length) {
						this.parameterListAsString += ", ";
					}
					countOfParameters++;
				}
			});
		});

		console.log("this.parameterListAsString = " + this.parameterListAsString);
		var newParameterListAsString = this.parameterListAsString.replace("storedProcedure", "@ObjectId");
		newParameterListAsString += ",@UserId=" + Global.User.currentUser.Id;
		newParameterListAsString = newParameterListAsString.replace(", ,", ",");

		console.log("newParameterListAsString = " + newParameterListAsString);
		this.dataService.SQLActionAsPromise("URPT.AddOrUpdateReportDefinition " + newParameterListAsString).then((data: any) => {
			console.log("data = %O", data);
			this.reportList(true); //-- return back to the report list after inserting/updating the report.
		});
	}
	delete(report: any) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
		console.log("Marking " + report.ObjectId + " report for deletion...");
		console.log("report = %O", report);

		this.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				text: "You are trying to delete the '" + report.ReportName + "' report. Cancel if you would rather keep it.",
				showCancelButton: true,
				confirmButtonText: "Delete Report",
				cancelButtonText: "Cancel",
				reverseButtons: false
			})
			.then((result: any) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					this.dataService.SQLActionAsPromise("URPT.MarkReportForDeletion " + report.Id + ", @UserId=" + Global.User.currentUser.Id).then((data: any) => {
						console.log("data = %O", data);
						this.utilityService.showToastMessageShared({
							type: "info",
							message: "'" + report.ReportName + "' has been DELETED.",
							title: "Universal Reporting"
						});
						// this.toastr.info("'" + report.ReportName + "' has been DELETED.", "Universal Reporting");
						this.reportList(true); //-- go get list of reports again.
					});
				} else {
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "'" + report.ReportName + "' has NOT been deleted.",
						title: "Universal Reporting"
					});
					//   this.toastr.info("'" + report.ReportName + "' has NOT been deleted.", "Universal Reporting");
				}
			});
	}

	execute(report: any) {
		console.log("executing " + report.ObjectId + " report...");
		this.editingReport = false;
		this.displayReport = false;
		this.executingReport = true;
		this.isLoadingParameters = true;
		this.reportData = null;
		this.reportHeader = null;

		this.selectedReport = report;
		console.log("report.ObjectId = " + report.ObjectId);
		this.dataService.SQLActionAsPromise("URPT.GetReportListOfParameters " + report.ObjectId).then((data: any) => {
			console.log("report parameters: %O", data);
			this.parameterList = data;
			if (data.length != 0) {
				console.log("display list of parameters. %O", data);
				this.executeFieldsAndValues = [];
				let questions: QuestionBase<string>[] = [];

				data.forEach((d: any) => {
					var field: QuestionBase<string>;
					console.log("d = %O", d);
					if (d.Name.indexOf("Site") > 0) {
						field = 
						new UibButtonQuestion({
							key: d.Name,
							label: d.DisplayName,
							title: "Please select the Site for this report.",
							listOfValues: this.dataService.cache.sites,
							required: d.IsRequired,
							order: 1
						});
					} else {
						switch (d.Type) {
							case "datetime":
								field = new DateQuestion({
									key: d.Name,
									label: d.DisplayName,
									value: d.DefaultValue,
									required: d.IsRequired,
									order: 2
								});
								break;

							case "numeric":
							case "decimal":
							case d.Type.indexOf("int") > 0:
								field = new NumberQuestion({
									key: d.Name,
									label: d.DisplayName,
									value: d.DefaultValue,
									required: d.IsRequired,

									order: 3
								});
								break;

							default:
								field = new TextboxQuestion({
									key: d.Name,
									label: d.DisplayName,
									value: d.DefaultValue == null ? "" : d.DefaultValue,
									required: d.IsRequired,
									order: 4
								});
								break;
						}
					}

					this.executeFieldsAndValues.push(field);

					//var fieldAndValue: any = { Name: d.Name, Value: d.DefaultValue };
					//this.executeFieldsAndValues.push(fieldAndValue);
				});

				//console.log("this.executeFields = %O", this.executeFields);
				console.log("this.executeFieldsAndValues = %O", this.executeFieldsAndValues);
				this.parameterQuestions = this.executeFieldsAndValues;
				this.executeFormOptions = {
					submitButtonText: "Execute Report",
					cancelButtonText: "Cancel"
				};

				this.viewReportsList = false;
				this.isLoadingParameters = false;
			} else {
				//-- no parameters found for this stored procedure.  Just execute the stored procedure.

				this.executingReport = false;
				this.viewReportsList = false;
				this.isLoadingParameters = false;
				this.parameterList = null;
				this.parameterListAsString = "";
				console.log("run stored procedure.");
				this.runStoredProcedure(report);
			}
		});
	}

	submitParametersAndExecute(submittedValues: string) {
		//console.log("reports.component.ts: submitDynamicForm: submittedValues = %O", submittedValues);
		// var parameterListAsString = values.replace('{','').replace('}','').replace(/:/g,'=').replace(/\"/g,'');
		// console.log("reports.component.ts: parameterListAsString = " + parameterListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		//console.log("submittedValuesObject = %O", submittedValuesObject);
		// console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);
		//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.
		this.parameterListAsString = "";
		var countOfParameters = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			this.parameterListAsString += key + "=";
			if (isNaN(submittedValuesObject[key])) {
				this.parameterListAsString += "'" + value + "'";
			} else {
				this.parameterListAsString += value;
			}
			if (countOfParameters < keys.length) {
				this.parameterListAsString += ", ";
			}
			countOfParameters++;
		});

		//console.log("this.parameterListAsString = " + this.parameterListAsString);
		this.runStoredProcedure(this.selectedReport); //-- this runs the stored procedure and appends this parameter list as string to the end of the stored procedure. --Kirk T. Sherer, April 7, 2020.
	}

	reportList(refresh?: boolean) {
		//console.log("going back to reports list...");
		this.viewReportsList = true;
		this.editingReport = false;
		this.executingReport = false;
		this.displayReport = false;
		this.addingNewReport = false;
		this.isLoadingParameters = false;
		this.loadingEditForm = false;

		if (refresh) {
			this.getLatestReportsList();
		}
	}
	runStoredProcedure(report: any) {
		console.log("running report...%O", report);
		this.displayReport = true;
		this.addingNewReport = false;
		this.viewReportsList = false;
		this.editingReport = false;
		this.executingReport = false;
		this.isLoadingParameters = false;
		this.loadingEditForm = false;

		this.dataSource = new MatTableDataSource<any>();

		console.log("this.dataSource = %O", this.dataSource);
		this.isLoading = true;

		var sqlString = "URPT." + report.StoredProcedureName + " " + this.parameterListAsString;
		this.dataService.SQLActionAsPromise(sqlString).then((data: any) => {
			let returnedData = data;
			console.log("data returned from stored procedure: %O", returnedData);

			console.log("this.dataSource = %O", this.dataSource);
			var fieldNames: string = "";
			var reportData: any = [];

			for (let row in returnedData) {
				var record: any = returnedData[row];
				for (let propertyName in record) {
					if (+row == 0) {
						fieldNames += propertyName + ",";
					} else {
						break;
					}
				}
			}

			var reportHeader: any = fieldNames
				.split(",")
				.where((f: any) => f != "")
				.select((f: string) => {
					var outputObject = {};
					outputObject = {
						name: f,
						label: f.replace(/_/g, " ")
					};
					return outputObject;
				})
				.toArray();

			//console.log("reportHeader = %O", reportHeader);

			this.reportHeader = reportHeader;
			this.reportData = returnedData;

			this.displayedColumns = reportHeader.map((column: any) => column.name);
			this.gridView = process(this.reportData, this.state);
			this.dataSource = new MatTableDataSource<any>(returnedData);
			this.dataSource.sort = this.sort;

			console.log("this.dataSource = %O", this.dataSource);
			console.log("this.dataSource.filteredData.length = " + this.dataSource.filteredData.length);
			// if (this.dataSource.filteredData.length > 0) {
			// 	this.isLoading = false;
			// }
			this.isLoading = false;
			//console.log("this.isLoading = " + this.isLoading);
		});
	}

	selectSite(event: Event, site: any): void {
		this.selectedSite = site;
		console.log("selectedSite = %O", site);
		this.parameterListAsString = "@Site=" + site.Id;
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim().toLowerCase();
		this.dataSource.filter = filterValue;
	}
}
