import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcaOvernightModeComponent } from './pca-overnight-mode.component';



@NgModule({
  declarations: [
    PcaOvernightModeComponent
  ],
  imports: [
    CommonModule
  ], exports: [PcaOvernightModeComponent]
})
export class PcaOvernightModeModule { }
