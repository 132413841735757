import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import { DataService } from '../../services/data.service';

@Component({
	selector: 'lib-widget-split-percent-popup',
	templateUrl: './widget-split-percent-popup.component.html',
	styleUrls: ['./widget-split-percent-popup.component.scss'],
})
export class WidgetSplitPercentPopupComponent implements OnInit {
	public split1Percent: number = 50;

	public split2Percent: number = 50;
	foundTabSettings: any;
	constructor(
		@Inject(MAT_DIALOG_DATA) public localWidgetObject: any,
		public dialogRef: MatDialogRef<WidgetSplitPercentPopupComponent>,
		private dataService: DataService,
		private dashboardService: DashboardService
	) {}

	public arrayonethroughonehundred: number[] = Array.from(Array(100).keys());

	ngOnInit(): void {
		// this.split1Percent = this.localWidgetObject.Split1Percent;
		// this.split1Percent = this.localWidgetObject.split1Percent;
		console.log(this.localWidgetObject);
		if (this.localWidgetObject.tabSettings !== undefined) {
			this.foundTabSettings =
				this.localWidgetObject.tabSettings[
					this.localWidgetObject.SelectedTabIndex
				];
			this.split1Percent = this.foundTabSettings.SplitPanel1Percent;
			this.split2Percent = this.foundTabSettings.SplitPanel2Percent;
		}
	}

	onCancel() {
		this.dialogRef.close();
	}

	submitAndClose() {
		this.split2Percent = 100 - this.split1Percent;
		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.localWidgetObject.WidgetId +
			', @TabIndex = ' +
			this.localWidgetObject.SelectedTabIndex +
			" , @SplitPanel1Percent = '" +
			this.split1Percent +
			"', @SplitPanel2Percent = '" +
			this.split2Percent +
			"'";
		if (this.foundTabSettings !== undefined) {
			statement = statement + ', @id = ' + this.foundTabSettings.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.localWidgetObject.refreshComponent = true;
			setTimeout(() => {
				this.localWidgetObject.refreshComponent = false;
				this.dashboardService._reloadedWidget.next(
					this.localWidgetObject
				);
			}, 500);
			this.dialogRef.close();
			// this.toastr.success("Chart type changed to " + event.properName);
		});
	}
}
