import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { Global } from '../../_constants/global.variables';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';

@Component({
  selector: 'lib-chart-type-slider',
  templateUrl: './chart-type-slider.component.html',
  styleUrls: ['./chart-type-slider.component.scss']
})
export class ChartTypeSliderComponent implements OnInit {
  public currentGraphTypeId: number = 0;
  public graphTypes: any = Global.tacticalDashboardGraphTypes;
  @Input() @Optional() graphTypesLimitations: any = []

  @Input() graphType: any;
	@Input() tab: any;
  @Input() legendVisible: any;

  @Output() newGraphTypeEvent = new EventEmitter<string>();
  @Output() toggleLegendChangeEvent = new EventEmitter<string>();
  public graphShow: boolean = true;
	constructor(
		private dialog: MatDialog,
	) { }

  ngOnInit() {
    if (this.graphTypesLimitations.length > 0) {
      console.log(this.graphTypesLimitations);
      console.log(this.graphTypes);
      this.graphTypes = this.graphTypes.filter(graphType => {
        return this.graphTypesLimitations.includes(graphType.id);
      })
    }
    console.log(this.graphType)
    if (this.graphType !== undefined) {
      this.currentGraphTypeId = this.graphType.id;
    }
  }

  public graphTypeChange(event: any) {
    this.graphType = this.graphTypes.find(graphtype => {
      return event.value === graphtype.id;
    })
    this.newGraphTypeEvent.emit(this.graphType);

    // force re-render (necessary due to *ngFor)
    this.graphShow = !this.graphShow;
    setTimeout(() => this.graphShow = !this.graphShow, 10);
  }

  public toggleLegendChange(event: any) {
	this.toggleLegendChangeEvent.emit();
  }

	showDialogModalPopupGrid() {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				groupable: this.tab.groupable,
				groupColumns: this.tab.groupable === true ? this.tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + this.tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: this.tab.tableStructure,
				tableData: this.tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}
}
