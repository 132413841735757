import { Component, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChild } from "@angular/core";

import { ActivatedRoute } from '@angular/router';
import { ResizedEvent } from 'angular-resize-event';

import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';

import { ToastrService } from 'ngx-toastr';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { DisplaySetupService } from 'projects/shared-lib/src/lib/services/display-setup.service';
import { GseService } from 'projects/shared-lib/src/lib/services/gse.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';



@Component({
  selector: "locate-all-gse",
  templateUrl: "./locate-all-gse.component.html",
  styleUrls: ["./locate-all-gse.component.scss"],
})
export class LocateAllGSEComponent implements AfterViewInit, OnDestroy {
  @ViewChild('locateAllGSEChild') locateAllGSEChild: any;

  private route$: any;
  public mapDiv: string = "map";

  private zoomLevel: number = 18;
  public fullScreen: boolean = true;
  public mapOptions: any;
  private gse: any;
  public width: number;
  public height: number;
  fullDataCache$: BehaviorSubject<false>;
  tagGraphSingleModalSubscription: any;
  gseIdList = [];
  infoWindowContent: string;
  intervalId: NodeJS.Timeout;
  tagOptions: any[];
  markers: Array<any>;
  defaultLeftWidth = 20;
  defaultRightWidth = 80;
  isDataLoading: boolean;
  gseFiltered: Array<any>;



  constructor(private route: ActivatedRoute, private dataService: DataService, private displaySetup: DisplaySetupService, public dialog: MatDialog, private ref: ChangeDetectorRef, public gseService: GseService, private toastr: ToastrService) {

  }

  ngAfterViewInit() {
    this.isDataLoading = true;


  }


  toggleZonesOnMap() {
    this.locateAllGSEChild.toggleZonesOnMap();


  }



  ngOnDestroy() {
    Global.User.DebugMode && console.log("ngOnDestroy invoked...");

  }




}
