import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { MatSort } from '@angular/material/sort';

import { MatTableDataSource } from '@angular/material/table';
import {
	GridDataResult,
	MultipleSortSettings,
	PageChangeEvent,
	RowClassArgs,
} from '@progress/kendo-angular-grid';
import {
	CompositeFilterDescriptor,
	process,
	State,
} from '@progress/kendo-data-query';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter, map, startWith, switchMapTo } from 'rxjs/operators';

import { QuestionBase } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-base';
import { DropdownQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-dropdown';
import { ReadOnlyQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-read-only';
import { ReadOnlyTextareaQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-read-only-textarea';
import { SliderYesNoQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-slider-yes-no';
import { TextareaQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-textarea';
import { TextboxQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-textbox';
import { GridSettings } from 'projects/shared-lib/src/lib/_models/grid-settings.interface';

import swal from 'sweetalert2';
import * as $ from 'jquery';
import { Observable } from 'rxjs';

import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UibButtonQuestion } from 'projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-uib-button';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';

import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { SignalRCoreService } from 'projects/shared-lib/src/lib/services/signalr-core.service';
import { UtilityService } from 'projects/shared-lib/src/lib/services/utility.service';
import { IGlobal } from 'projects/shared-lib/src/lib/_models/global.model';
import { FileUploadListComponent } from 'projects/shared-lib/src/lib/components/file-upload-list/file-upload-list.component';
import { IUserIssue } from 'projects/shared-lib/src/lib/_models/user-issue.model';
import { Router } from '@angular/router';

@Component({
	selector: 'lib-customer-issues-list',
	templateUrl: './customer-issues-list.component.html',
	styleUrls: ['./customer-issues-list.component.scss'],
})
export class CustomerIssuesListComponent implements OnInit, OnDestroy {
	@ViewChild('issuesGrid') private issuesGridViewChild;

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	public issuesGroups: any;
	public viewIssuesList: boolean = true;
	public editingIssue: boolean = false;
	public enterExecuteParameters: boolean = false;
	public executingIssue: boolean = false;
	public deletingIssue: boolean = false;
	public displayIssue: boolean = false;

	public parameterList: any;
	public permittedSites: any = Global.User.PermittedSites;
	public selectedIssue: any;
	public today: Date = new Date();
	public yesterday: Date;
	public selectedSite: any;
	public selectedStoredProcedure: any;
	public storedProcedureList: any;
	public settings: any;
	public editForm: FormBuilder;
	public editIssueFormGroup: FormGroup;
	public discussionIssueFormGroup: FormGroup;

	public addingNewIssue: boolean = false;
	public loadingEditForm: boolean = false;
	public isLoadingParameters: boolean = false;

	public issueData: any;
	public issueHeader: any;
	private parameterListAsString: string = '';
	public isLoading: boolean = true;
	public parameterCount: number = 0;

	public payLoad: string = '';
	public discussionFieldsAndValues: Array<any>;
	public editFieldsAndValues: Array<any>;

	public editQuestions: QuestionBase<any>[];
	public parameterQuestions: QuestionBase<any>[];
	public editFormOptions: any;
	public discussionFormOptions: any;

	private swalWithBootstrapButtons: any;
	public editHeader: string;
	public editSubHeader: string;
	//Material table Properties
	public dataSource: MatTableDataSource<any>;
	//Kendo UI properties
	public skip = 0;
	public issuesSkip = 0;
	public gridView: GridDataResult;
	public gridViewIssues: any = [];
	public filterT: CompositeFilterDescriptor;

	public pageSize = 100;

	public pageSizeIssues = 100;
	public displayedColumns: string[];
	public filteredGridData: any;
	public popupTitle: string = 'Issues List';
	public state: State = { skip: 0 };
	public issuesState: State = { skip: 0 };
	public theme: string;
	private fullDataCache$: any;
	public subjects: Array<any> = [];
	private componentName: string = 'customer-issues: ';
	public discussion: any;
	private issueUpdated$: any;
	private issueAdded$: any;
	private listUpdated$: any;
	private discussionUpdated$: any;
	private currentlyEditingIssueId: number;
	public listOfPeople: any;
	public filteredPeople: Observable<string[]>;
	public myControl = new FormControl();

	public CountOfFilesUploaded: number = 0;
	public fileImageData: any;
	public FileImageLibraryControlObject: any;
	public issuesListTitle: string;
	public currentLoggedInUserDisplay: string =
		Global.User.currentUser.GivenName +
		' ' +
		Global.User.currentUser.FamilyName +
		' (' +
		Global.User.currentUser.Username +
		')';
	public global: IGlobal = Global;
	private isLoadingEditScreen: boolean = true;

	public issuesGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			group: [],
			take: 15,
			sort: [],
		},
		columnsConfig: [
			{
				field: 'Id',
				title: 'Id',
				filterable: true,
				_width: 100,
			},
			{
				field: 'CreationDate',
				title: 'Creation Date',
				filterable: true,
				filter: 'date',
				_width: 80,
			},
			{
				field: 'Subject',
				title: 'Subject',
				filterable: true,
				_width: 80,
			},
			{
				field: 'CreatedBy',
				title: 'Created By',
				filterable: true,
				_width: 80,
			},
			{
				field: 'AssignedTo',
				title: 'Rep Name',
				filterable: true,
				_width: 80,
			},
			{
				field: 'Description',
				title: 'Description',
				filterable: true,
				_width: 135,
			},
			{
				field: 'LastModifiedBy',
				title: 'Last Modified By',
				filterable: true,
				_width: 80,
			},
			{
				field: 'LastModifiedDate',
				title: 'Last Modified Date',
				filterable: true,
				filter: 'date',
				_width: 80,
			},
			{
				field: 'Resolved',
				title: 'Resolved',
				filterable: true,
				_width: 50,
			},
			{
				field: 'Actions',
				title: 'Actions',
				filterable: false,
				_width: 150,
			},
			{
				field: 'NotifyAssignedUser',
				title: 'Notify Assigned User',
				filterable: true,
				_width: 30,
				hidden: true,
			},
			{
				field: 'NotifyCreatorUser',
				title: 'Notify Creator User',
				filterable: true,
				_width: 30,
				hidden: true,
			},
		],
	};
	public sortSettings: MultipleSortSettings = {
		mode: 'multiple',
		initialDirection: 'desc',
		allowUnsort: true,
		showIndexes: true,
	};

	public discussionGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},

		columnsConfig: [
			{
				field: 'Date',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 30,
			},
			{
				field: 'Person',
				title: 'Person',
				filterable: true,
				_width: 30,
			},
			{
				field: 'Comments',
				title: 'Comments',
				filterable: true,
				_width: 250,
				encoded: false,
			},
		],
	};
	AdminDevelopmentTeam: any;

	constructor(
		public dataService: DataService,
		public formBuilder: FormBuilder,
		private toastr: ToastrService,
		private signalRCore: SignalRCoreService,
		private changeDetector: ChangeDetectorRef,
		public dialog: MatDialog,
		private utilityService: UtilityService,
		private router: Router
	) {}

	ngOnInit() {
		this.utilityService.updateCurrentMenuItem('Customer Issues');
		if (Global.User.isAdmin) {
			this.issuesGridSettings.state.sort = [
				{ dir: 'asc', field: 'Resolved' },
				{ dir: 'desc', field: 'LastModifiedDate' },
			];
			this.dataService
				.SQLActionAsPromise('API.GetListOfDevelopers')
				.then((data: any) => {
					this.AdminDevelopmentTeam = data;
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								'this.AdminDevelopmentTeam = %O',
							this.AdminDevelopmentTeam
						);
				});
		} else {
			this.issuesGridSettings.state.sort = [
				{ dir: 'desc', field: 'LastModifiedDate' },
			];
		}

		this.yesterday = this.getYesterdaysDate();
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					'this.today = ' +
					this.today +
					', this.yesterday = ' +
					this.getYesterdaysDate()
			);

		this.editIssueFormGroup = new FormGroup({
			subject: new FormControl(),
			description: new FormControl(),
			createdBy: new FormControl(),
			resolved: new FormControl(),
		});

		this.discussionIssueFormGroup = new FormGroup({
			response: new FormControl(),
		});
	}

	navigate(whereToGo){
		const whereToGoUrl = '/layout/issues/' + whereToGo;
		this.router.navigate([whereToGoUrl]);
	}

	public groupChangeIssues(groups: any): void {
		this.issuesGridSettings.state.group = groups;
		this.gridViewIssues = process(
			this.dataService.cache.userIssues,
			this.issuesGridSettings.state
		);
	}

	ngAfterViewInit() {
		var service = this;
		if (!Global.User.isAdmin) {
			var currentUserOrganizationId =
				this.dataService.cache.people.filter((p: any) => {
					return p.UserId == Global.User.currentUser.Id;
				})[0].OrganizationId;

			this.listOfPeople = this.dataService.cache.people.filter(
				(p: any) => {
					return p.OrganizationId == currentUserOrganizationId;
				}
			);
		} else {
			//-- since this user is a system administrator, let them see all people. --Kirk T. Sherer, November 16, 2020.
			this.listOfPeople = this.dataService.cache.people;
		}

		this.filteredPeople = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => this.peopleFilter(value))
		);

		//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//-- NOTE -- 
		//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//-- We are already joining the User Issues group and the System Updates group in the SignalR service.  There is no need to join or unjoin these groups in this or any other component. --Kirk T. Sherer, July 15, 2024. 
		//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		service.issueUpdated$ = service.signalRCore.broadcastMessages$
			.pipe(filter((msg: any) => msg.code == 'Issue Updated' || msg.code == 'SQL.UserIssue.Update'))
			.subscribe((data: any) => {
				Global.User.DebugMode &&
					console.log(
						service.componentName +
							': SignalR Message received. Code: ' +
							data.code +
							', data.object received via SignalR: %O',
						data?.object
					);

				service.gridViewIssues = process(
					this.dataService.cache.userIssues,
					this.issuesGridSettings.state
				);
				//--this updates the Kendo Grid Data with whatever changed in SignalR for the main User Issue structure in the data cache.
			});


		service.discussionUpdated$ = service.signalRCore.broadcastMessages$
			.pipe(filter((msg: any) => msg.code == 'Issue Discussion Updated'))
			.subscribe((data: any) => {
				Global.User.DebugMode &&
					console.log(
						service.componentName +
							': SignalR Message received. Code: ' +
							data.code +
							', data.object received via SignalR: %O',
						data?.object
					);

				if (data.object) {
					//-- this section is to get the latest discussion record and append it to the list of discussion records if we're currently editing the same issue.
					var updatedCurrentlyEditingIssue = data.object?.first(
						(i: any) => {
							return (
								i.UserIssueId == service.currentlyEditingIssueId
							);
						}
					);
					if (updatedCurrentlyEditingIssue) {
						//-- the issue I'm editing just got updated by someone else on the application. Update my discussion panel in place. --Kirk T. Sherer, April 19, 2021.
						var newDiscussionId = data.object.first((d: any) => {
							return d.Id;
						}).Id;
						Global.User.DebugMode &&
							console.log(
								this.componentName +
									'newDiscussionId = ' +
									newDiscussionId
							);
						var existingDiscussionContainingSignalRDiscussionRecord =
							service.discussion
								.where((disc: any) => {
									return disc.Id == newDiscussionId;
								})
								.toArray();
						Global.User.DebugMode &&
							console.log(
								this.componentName +
									'existingDiscussionContainingSignalRDiscussionRecord = %O',
								existingDiscussionContainingSignalRDiscussionRecord
							);
						if (
							existingDiscussionContainingSignalRDiscussionRecord.length ==
							0
						) {
							//-- if we made it here, the discussion record we received from SignalR does not exist in the current discussion list.  Add it since we're editing the
							//-- same User Issue. --Kirk T. Sherer, April 27, 2021.
							Global.User.DebugMode &&
								console.log(
									this.componentName +
										'service.currentlyEditingIssueId = ' +
										service.currentlyEditingIssueId
								);
							Global.User.DebugMode &&
								console.log(
									this.componentName + 'data.object = %O',
									data.object
								);
							Global.User.DebugMode &&
								console.log(
									this.componentName +
										'service.discussion = %O',
									service.discussion
								);

							service.getIssueDiscussion(
								service.currentlyEditingIssueId,
								data.object,
								service.discussion
							);
						}
					}
				}
			});

		service.issueAdded$ = service.signalRCore.broadcastMessages$
			.pipe(filter((msg: any) => msg.code == 'UserIssue Inserted'))
			.subscribe((data: any) => {
				Global.User.DebugMode &&
					console.log(
						service.componentName +
							': SignalR Message received. Code: ' +
							data.code
					);
				service.gridViewIssues = process(
					this.dataService.cache.userIssues,
					this.issuesGridSettings.state
				); //--this updates the Kendo Grid Data with whatever changed in SignalR for the main User Issue structure in the data cache.
			});

		if (!Global.FullDataCacheExists) {
			this.fullDataCache$ =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.getLatestIssuesList();
						this.fullDataCache$.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.getLatestIssuesList();
		}
	}

	ngOnDestroy() {
		this.issueUpdated$ && this.issueUpdated$.unsubscribe();
		this.listUpdated$ && this.listUpdated$.unsubscribe();
	}

	public fetchData(): Observable<IUserIssue[]> {
		return new Observable<IUserIssue[]>((observer) => {
			observer.next(this.dataService.cache.userIssues);
		});
	}

	private peopleFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.listOfPeople
			.map((x: any) => x.FullNameWithUsername)
			.filter((option: string) => {
				if (option != undefined) {
					return option.toLowerCase().includes(filterValue);
				}
			});
	}

	public distinctPrimitive(fieldName: string): any {
		//return distinct(this.issues, fieldName).map((item) => item[fieldName]);
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.state.skip = event.skip;
		this.state.take = event.take;
		this.gridView = process(this.issueData, this.state);
	}

	public pageChangeIssues(event: PageChangeEvent): void {
		this.issuesSkip = event.skip;
		this.issuesGridSettings.state.skip = event.skip;
		this.issuesGridSettings.state.take = event.take;
		this.gridViewIssues = process(
			this.dataService.cache.userIssues,
			this.issuesGridSettings.state
		);
	}

	public filterChangeIssues(filter: CompositeFilterDescriptor): void {
		this.filterT = filter;
		this.issuesGridSettings.state.filter = filter;
		this.gridViewIssues = process(
			this.dataService.cache.userIssues,
			this.issuesGridSettings.state
		);
	}

	public filterChange(filter: CompositeFilterDescriptor): void {
		this.state.filter = filter;
		this.gridView = process(this.issueData, this.state);
	}

	public sortChange(sort: any): void {
		this.state.sort = sort;
		this.gridView = process(this.issueData, this.state);
	}

	public sortChangeIssues(sort: any): void {
		this.issuesGridSettings.state.sort = sort;
		this.gridViewIssues = process(
			this.dataService.cache.userIssues,
			this.issuesGridSettings.state
		);
	}

	getYesterdaysDate() {
		var days = 1; // Days you want to subtract
		var date = new Date();
		var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
		return last;
	}

	getLatestIssuesList() {
		this.subjects = this.dataService.cache.userIssueSubjects;
		this.gridViewIssues = process(
			this.dataService.cache.userIssues,
			this.issuesGridSettings.state
		);

		if (Global.User.isAdmin) {
			//this.issues = this.dataService.cache.userIssues.where((issue:IUserIssue) => { return issue.Resolved == false }).toArray(); //-- this will get all open issues.
			//sql = "API.Issues_GetListOfIssues @viewAllIssues = 1"; //-- this will get all open issues. <-- this is wrong.  @ViewAllIssues=1 means 'true', I want to View ALL issues.
			this.issuesListTitle = 'Customer Issues';
		} else {
			this.issuesListTitle = 'Issues List for ';
		}

		this.issuesGridViewChild?.collapseGroup('1');
		this.isLoading = false;
	}

	getIssueDiscussion(issue: any, lastDiscussion?: any, discussion?: any) {
		if (!this.discussion || (!lastDiscussion && !discussion)) {
			//-- if we don't have deicer events populated yet, or we didn't send a current deicing event, then go get the list of deicer events again from SQL Server. --Kirk T. Sherer, October 12, 2020.
			this.dataService
				.SQLActionAsPromise('API.Issues_GetIssueDiscussion ' + issue)
				.then((data: any) => {
					var discussion = data;
					this.discussionGridSettings.gridData = process(
						discussion,
						this.discussionGridSettings.state
					);
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								'this.discussionGridSettings.gridData = %O',
							this.discussionGridSettings.gridData
						);
				});
		} else {
			if (discussion && !lastDiscussion) {
				//-- we arrived at this function with the list of discussion records, but no new ones to append to the list. Just display the same list again. --Kirk T. Sherer, April 19, 2021.
				this.discussionGridSettings.gridData = process(
					discussion,
					this.discussionGridSettings.state
				);
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'this.discussionGridSettings.gridData = %O',
						this.discussionGridSettings.gridData
					);
			} else {
				//-- we are arriving here with a list of discussion records, as well as a new record to add to the list. --Kirk T. Sherer, April 19, 2021.
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'this.discussion = %O',
						this.discussion
					);
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'lastDiscussion = %O',
						lastDiscussion
					);

				var discussion = this.discussion;
				lastDiscussion.forEach((d: any) => {
					d.Date = new Date(d.DateMS);
					d.Comments = this.stringToHTML(d.Comments);
				});
				discussion.push(lastDiscussion.first());
				var newDiscussionList = discussion
					.orderByDescending((d: any) => {
						return d.DateMS;
					})
					.toArray();
				this.discussion = newDiscussionList;
				this.discussionGridSettings.gridData = process(
					newDiscussionList,
					this.discussionGridSettings.state
				);
				$('#response').trigger('click'); //--should set focus back to textarea after submission of text. --Kirk T. Sherer, April 20, 2021.

				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'this.discussionGridSettings.gridData after adding new discussion record = %O',
						this.discussionGridSettings.gridData
					);
				this.changeDetector.detectChanges();
			}
		}
	}

	stringToHTML(textString: string) {
		var parser = new DOMParser();
		var doc = parser.parseFromString(textString, 'text/html');
		var text = doc.body.innerHTML;
		//console.log(this.componentName + "stringToHTML(" + textString + ") = " + text);
		var returnText =
			textString != undefined
				? text
						.split('<br>')
						.join('\n')
						.split('<p>')
						.join('')
						.split('</p>')
						.join('')
						.split('&lt;')
						.join('<')
						.split('&gt;')
						.join('>')
						.split('<p>')
						.join('')
						.split('</p>')
						.join('')
				: '';
		return returnText;
	}

	warningFunctionWhenReopeningAnIssue(
		resolvedStatus: boolean,
		issueId: number
	) {
		var service = this;
		if (!resolvedStatus) {
			//-- the resolved status is not yet true, so this is the warning to the user that they are trying to resolve it.
			service.swalWithBootstrapButtons = swal.mixin({
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-success',
				},
				buttonsStyling: false,
			});
			console.log(
				'Marking Customer Issue #' +
					service.dataService.cache.userIssuesObject[issueId].Id +
					' as resolved...'
			);

			service.swalWithBootstrapButtons
				.fire({
					title: 'Are you sure?',
					html:
						"You are trying to resolve the <strong>'" +
						service.dataService.cache.userIssuesObject[issueId].Id +
						"</strong>' issue. Cancel if you would rather keep it open.",
					showCancelButton: true,
					confirmButtonText: 'Resolve Issue',
					cancelButtonText: 'Cancel',
					reverseButtons: false,
				})
				.then((result: any) => {
					if (result.value) {
						// logic for resolving issue goes here.
						service.dataService
							.SQLActionAsPromise(
								'API.Issues_SetResolveField @IssueId=' +
									service.dataService.cache.userIssuesObject[
										issueId
									].Id +
									', @UserId=' +
									Global.User.currentUser.Id +
									', @IsResolved=1'
							)
							.then((data: any) => {
								console.log('data = %O', data);
								service.utilityService.showToastMessageShared({
									type: 'info',
									message:
										'Customer Issue #' +
										service.dataService.cache
											.userIssuesObject[issueId].Id +
										' has been resolved.',
									title: 'Customer Issues',
								});
								this.signalRCore.notifyOtherClientsInGroup(
									'User Issues',
									'Issue Updated',
									service.dataService.cache.userIssuesObject[
										issueId
									]
								);
								service.issueList(true); //-- go get list of issues again.
							});
					} else {
						service.utilityService.showToastMessageShared({
							type: 'info',
							message:
								'Customer Issue #' +
								service.dataService.cache.userIssuesObject[
									issueId
								].Id +
								' has NOT been resolved.',
							title: 'Customer Issues',
						});
					}
				});
		} else {
			//-- the resolved status is true.  User must have a reason for re-opening the issue, so just allow the update.
			var requestJSON = [
				{
					label: 'Resolved',
					sqlStatement:
						'API.Issues_UpdateRecordByIdAndFieldName @UserIssueId=' +
						service.dataService.cache.userIssuesObject[issueId].Id +
						', @iOPSUserId=' +
						Global.User.currentUser.Id +
						", @FieldName='Resolution', @FieldValue=null",
				},
				{
					label: 'IsResolved',
					sqlStatement:
						'API.Issues_SetResolveField @IssueId=' +
						service.dataService.cache.userIssuesObject[issueId].Id +
						', @UserId=' +
						Global.User.currentUser.Id +
						', @IsResolved=0',
				},
			];
			service.dataService
				.SQLMultiAction(
					requestJSON,
					Global.User.currentUser.ODataAccessToken
				)
				.then((data: any) => {
					console.log('data = %O', data);
					service.utilityService.showToastMessageShared({
						type: 'info',
						message:
							'Customer Issue #' +
							service.dataService.cache.userIssuesObject[issueId]
								.Id +
							' has been re-opened.',
						title: 'Customer Issues',
					});
					this.signalRCore.notifyOtherClientsInGroup(
						'User Issues',
						'Issue Updated',
						service.dataService.cache.userIssuesObject[issueId]
					);
				});
		}
	}

	submitEditedIssue(submittedValues: string) {
		var service = this;
		console.log('edited issue change submitted...');
		var submittedValuesObject = JSON.parse(submittedValues);
		//console.log("submittedValuesObject = %O", submittedValuesObject);
		// console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		service.parameterListAsString = '';
		var countOfParameters = 1;
		keys.forEach((key: any) => {
			var questions = service.editQuestions;
			questions.forEach((question: any) => {
				if (
					key == question.key &&
					question.controlType != 'read-only'
				) {
					var value = submittedValuesObject[key];
					console.log('key: ' + key + ', value: ' + value);
					switch (key) {
						case 'createdBy':
							//-- since this is using a person-selector, the value coming back is a JSON object.
							//-- Get the UserId from the value object that was returned. --Kirk T. Sherer, April 22, 2021.
							var UserId = value.UserId;
							service.parameterListAsString +=
								'@UserId=' + UserId;
							break;
						case 'subject':
							service.parameterListAsString +=
								'@SubjectId=' + value;
							break;
						default:
							service.parameterListAsString += '@' + key + '=';
							if (isNaN(submittedValuesObject[key])) {
								service.parameterListAsString +=
									"'" +
									service.utilityService.updateStringToSQLSyntax(
										value
									) +
									"'";
							} else {
								service.parameterListAsString += value;
							}
							break;
					}
					if (countOfParameters < keys.length) {
						service.parameterListAsString += ', ';
					}
					countOfParameters++;
				} else {
					if (
						key == 'createdBy' &&
						question.controlType == 'read-only'
					) {
						//-- the non-admin user is trying to log another issue from their list of existing issues since their 'createdBy' field
						//-- is listed as a read-only field. So we have to send their UserId as the @UserId parameter so we'll know this issue
						//-- was submitted by them. --Kirk T. Sherer, April 26, 2021.
						service.parameterListAsString +=
							'@UserId=' + Global.User.currentUser.Id;
						if (countOfParameters < keys.length) {
							service.parameterListAsString += ', ';
						}
						countOfParameters++;
					}
				}
			});
		});

		console.log(
			'this.parameterListAsString = ' + service.parameterListAsString
		);
		var newParameterListAsString = (service.parameterListAsString +=
			', @CreatorUserId=' + Global.User.currentUser.Id);
		newParameterListAsString = newParameterListAsString.replace(', ,', ',');

		console.log('newParamterListAsString = ' + newParameterListAsString);
		service.dataService
			.SQLActionAsPromise(
				'API.User_SubmitIssue ' + newParameterListAsString
			)
			.then((data: any) => {
				console.log('data = %O', data);
				var issueId = data?.first().Id;
				service.signalRCore.notifyOtherClientsInGroup(
					'User Issues',
					'Issue Updated',
					service.dataService.cache.userIssuesObject[issueId]
				);
				service.gridViewIssues = process(
					this.dataService.cache.userIssues,
					this.issuesGridSettings.state
				);
				//--this updates the Kendo Grid Data with whatever changed in SignalR for the main User Issue structure in the data cache.
				service.issueList(true); //-- return back to the issue list after inserting/updating the issue.
			});
	}

	public submitResponse(issue: any) {
		var service = this;
		console.log(
			'this.editFormOptions.saveStoredProcedureName = ' +
				this.editFormOptions.saveStoredProcedureName +
				', issue = ' +
				issue
		);
		console.log(
			'this.discussionIssueFormGroup = %O',
			this.discussionIssueFormGroup
		);
		var newDiscussion = service.stringToHTML(
			this.discussionIssueFormGroup.controls.response.value
		);
		var discussion = newDiscussion.split("'").join("''");
		var sql =
			this.editFormOptions.saveStoredProcedureName +
			", 'Comments', '" +
			discussion +
			"'";
		console.log('sql = ' + sql);
		this.dataService.SQLActionAsPromise(sql).then((data: any) => {
			console.log('data = %O', data);
			this.discussionIssueFormGroup.controls.response.reset();
			console.log('issue = ' + issue + ', data = %O', data);
			console.log('this.discussion = %O', this.discussion);
			this.getIssueDiscussion(issue, data, this.discussion);
			this.signalRCore.notifyOtherClientsInGroup(
				'User Issues',
				'Issue Discussion Updated',
				data
			);
		});
	}

	updateRowColor(context: RowClassArgs) {
		let rowUpdated = context.dataItem.RecentlyUpdated;
		return {
			recentlyUpdated: rowUpdated,
		};
	}

	issueList(refresh?: boolean) {
		//console.log("going back to issues list...");
		this.viewIssuesList = true;
		this.editingIssue = false;
		this.executingIssue = false;
		this.displayIssue = false;
		this.addingNewIssue = false;
		this.isLoadingParameters = false;
		this.loadingEditForm = false;

		if (refresh) {
			this.getLatestIssuesList();
		}
	}

	selectSite(event: Event, site: any): void {
		this.selectedSite = site;
		console.log('selectedSite = %O', site);
		this.parameterListAsString = '@Site=' + site.Id;
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim().toLowerCase();
		this.dataSource.filter = filterValue;
	}

	setFileImageUploadSettings(issue: any) {
		var service = this;
		Global.User.DebugMode &&
			console.log(
				service.componentName +
					': setting up File Image Upload functions. Issue = %O',
				issue
			);
		var entityId = issue.Id;
		var entityType = 'UserIssue';
		var title: string = 'Issue #' + issue.Id + ' Documentation';
		service.CountOfFilesUploaded = issue.CountOfFilesUploaded;

		issue.openFileImageUploadWindow = function () {
			var sqlStatement = "API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
			service.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				Global.User.DebugMode && console.log(service.componentName + sqlStatement + " = %O", data);
				service.fileImageData = data;
				service.FileImageLibraryControlObject = {
					imageKeys: service.fileImageData.filter((i: any) => {
						return i.ImageKey;
					}),
					//---B
					removeUploadFunction(deletedImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((remainingFileImageKeyList: any) => {
							Global.User.DebugMode && console.log(service.componentName + "remainingFileImageKeyList after deletion: %O", remainingFileImageKeyList);
							service.FileImageLibraryControlObject.imageKeys = remainingFileImageKeyList;
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject.imageKeys.length = " + service.FileImageLibraryControlObject.imageKeys.length);
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject = %O", service.FileImageLibraryControlObject);
							service.signalRCore.notifyOtherClientsInGroup("User Issues", "Files Changed", service.FileImageLibraryControlObject);
							service.fileImageData = remainingFileImageKeyList;
						});
					},
					//---B
					addUploadFunction(newImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((updatedFileImageKeyList: any) => {
							Global.User.DebugMode && console.log(service.componentName + "updatedFileImageKeyList after new file added: %O", updatedFileImageKeyList);
							service.FileImageLibraryControlObject.imageKeys = updatedFileImageKeyList;
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject.imageKeys.length = " + service.FileImageLibraryControlObject.imageKeys.length);
							Global.User.DebugMode && console.log(service.componentName + "service.FileImageLibraryControlObject = %O", service.FileImageLibraryControlObject);
							service.signalRCore.notifyOtherClientsInGroup("User Issues", "Files Uploaded", service.FileImageLibraryControlObject);
							service.fileImageData = updatedFileImageKeyList;
						});
					},
					mode: null,
					listTitle: title,
					entityId: entityId,
					entityType: entityType,
					closeUploadWindowFunction() {
						service.FileImageLibraryControlObject = null;
					}
				};

				service.openDialog("fileUpload");
			});
		};
	}

	openDialog(type: string): void {
		var innerWidth = window.innerWidth;
		if (type == 'fileUpload') {
			const dialogRef = this.dialog.open(FileUploadListComponent, {
				width: '70%',
				height: '70%',
			});

			dialogRef.componentInstance.fileImageLibraryControlObject =
				this.FileImageLibraryControlObject;

			dialogRef.afterClosed().subscribe((result) => {
				Global.User.DebugMode &&
					console.log(this.componentName + ': The dialog was closed');
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							': this.FileImageLibraryControlObject.imageKeys.length = ' +
							this.FileImageLibraryControlObject.imageKeys.length
					);
				this.CountOfFilesUploaded =
					this.FileImageLibraryControlObject.imageKeys.length;
			});
		}
	}
}
