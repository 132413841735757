import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericGridsterParentComponent } from './generic-gridster-parent.component';
import { GridsterModule } from 'angular-gridster2';

@NgModule({
	declarations: [GenericGridsterParentComponent],
	imports: [CommonModule, GridsterModule],
	exports: [GenericGridsterParentComponent],
})
export class GenericGridsterParentModule {}
