<gridster [options]="options" style="height: 100%;">
	<gridster-item [item]="widget" *ngFor="let widget of widgets">
		<div class="card card-tasks widget-card" id="widget-{{ widget.Id }}">
			<div *ngIf="widget.AngularDirectiveName != 'singleTagValueDisplay'" style="display: flex; flex-direction: row" class="card-header widget-card-header" id="widget-header-{{ widget.WidgetId }}" style="padding-top: 0px">
				<h6 class="title" style="padding-top: 15px; display: inline-block">
					{{ widget.Name }}
				</h6>
				<h6 *ngIf="widget.AngularDirectiveName == 'tagGrid'" class="title" style="padding-top: 15px; display: inline-block">
					{{ widget.regEx }}
			   </h6>
				<div *ngIf="sameUser && (widget.AngularDirectiveName == 'tagGraph' || widget.AngularDirectiveName == 'tagGraphSimple' || widget.AngularDirectiveName == 'gaugeDataDisplay')" style="float: right; padding-left: 10px;">
					<button class="btn btn-info btn-link btn-sm" type="button" (click)="openSettingsPopup(widget)">
						<i class="tim-icons icon-settings-gear-63" title="Settings"></i>
					</button>
				</div>
			</div>
			<div class="card-body widget-card-body" id="widget-body-{{ widget.Id }}">
				<div style="height: 100%; width: 100%" id="{{ widget.AngularDirectiveName }}{{ widget.WidgetId }}">
					<ng-template #canvasWidgetsLazyLoadingSelector></ng-template>
				</div>
			</div>
		</div>
	</gridster-item>
</gridster>
