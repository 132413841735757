<div class="card card-tasks" id="card-whole" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="card-header user-settings" style="cursor: default; padding-top: 0px; min-height: 0px"   cdkDragHandle>
        <div class="card-header user-settings">
			<h5 class="title d-inline" style="font-size: x-large">Personal Alert Escalation Information for <strong>{{ global.User.currentUser.GivenName + " " + global.User.currentUser.FamilyName }}</strong></h5>
			<div style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" tooltip="Close" placement="bottom" (click)="closeDialog()">
				<i style="cursor:pointer" class="tim-icons icon-simple-remove"></i>
			</div>
		</div>
	</div>
	<div class="card-body user-settings" id="personal-alert-escalation" style="overflow-y: auto;">
		<span class="fa fa-spinner spinner" *ngIf="isLoading"></span>
		<div *ngIf="!isLoading">
			<div>
				<mat-form-field appearance="outline" style="width:500px;" >
					<label>Person to Add</label>
						<input type="text" matInput [formControl]="inputPerson" [matAutocomplete]="auto" placeholder="Start typing Name here...">
						<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
							<mat-option *ngFor="let p of filteredAssociatedPeople$ | async" [value]="p">
								{{p.FullNameWithUsername}}
							</mat-option>
						</mat-autocomplete>
				</mat-form-field>

				<button mat-fab style="background-color:green" type="submit"
								title="Add Person to My Escalations"
								[disabled]="!inputPerson"
								(click)="clickAddEscalationPerson()">
					<mat-icon>add</mat-icon>
				</button>
			</div>

			<h2>My Escalations</h2>
			<section>
				<div *ngFor=" let person of alternateEmailForwards"
							[@fadeSlide]>
					<div style="width:500px; display:inline-block">
						<label>{{person.text}}</label>
					</div>
						<button mat-fab style="background-color:firebrick" type="submit"
								(click)="clickRemoveEscalationPerson(person)"
								title="Remove person from my escalations.">
							<mat-icon>delete</mat-icon>
						</button>
					<mat-divider></mat-divider>
				</div>
			</section>

		</div>

	</div>
</div>
