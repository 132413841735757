import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { QuestionBase } from "projects/shared-lib/src/lib/_models/dynamic-fields/questions/question-base";

import { Observable } from "rxjs";
import { startWith, map, filter } from "rxjs/operators";
import swal from "sweetalert2";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { DashboardEditComponent } from "projects/shared-lib/src/lib/components/dashboard-edit/dashboard-edit.component";
import { TextboxQuestion } from "../../_models/dynamic-fields/questions/question-textbox";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";

@Component({
	selector: "lib-dashboard-copy",
	templateUrl: "./dashboard-copy.component.html",
	styleUrls: ["./dashboard-copy.component.scss"]
})
export class DashboardCopyComponent implements OnInit {
	public title: string;
	public subTitle: string;
	private name: FormControl;
	public copyForm: FormGroup;
	public fieldList: QuestionBase<any>[];
	public isLoading: boolean;
	public editFields: QuestionBase<any>[];
	public formOptions: any;
	public coworkers: any;
	public options: any;
	public myControl = new FormControl();
	filteredOptions: Observable<string[]>;
	lastSearchTerm: any;
	searchResults: any;
	selectedUser: any;
	private swalWithBootstrapButtons: any;
	fullDataCacheSubscription: any;
	public editQuestions: any;

	constructor(public dialogRef: MatDialogRef<DashboardEditComponent>, @Inject(MAT_DIALOG_DATA) public dashboard: any, private dataService: DataService, private utilityService: UtilityService) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});

		
	}

	ngOnInit() {
		this.formOptions = {
			submitButtonText: "Copy Dashboard",
			saveValuesPerField: false,
			saveStoredProcedureName: "API.Dashboards_CopyDashboard @UserId=" + Global.User.currentUser.Id,
		};

		this.initialize();

		this.isLoading = true;
		Global.User.DebugMode && console.log("this.dashboard = " + this.dashboard);
		Global.User.DebugMode && console.log("this.dataService.cache.people = %O", this.dataService.cache.people);
		this.myControl.setValue(Global.User.currentUser.GivenName + " " + Global.User.currentUser.FamilyName + " (" + Global.User.currentUser.Username + ")");

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			this.initialize();
		}
	}

	public closeWindow(): void {
		this.dialogRef.close(999999);
	}

	public closeDialogWindow(): void {
		this.closeWindow();
	}

	initialize() {

		Global.User.DebugMode && console.log("dashboard-copy: initialize invoked...");
		if (Global.User.isLoggedIn) {
			console.log("dashboard-copy: Global.User.currentUser = %O", Global.User.currentUser);
			this.setUpDynamicFormQuestions();
		}
	}

	setUpDynamicFormQuestions() {
		this.isLoading = true;
		this.editQuestions = [
			new TextboxQuestion({
				key: "Person1",
				label: "Person",
				title: "The Person receiving the copied dashboard.  This will default to your name since the normal copy function is usually to make a copy of your own dashboard to another name.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: true,
				validateObject: true,
				allowBlankValue: false,
				order: 1,
				value: Global.User.currentUser.Id
			}),
			new TextboxQuestion({
				key: "Person2",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 2,
				visibleFields: [
					{
						field: "Person1",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person1",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person3",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 3,
				visibleFields: [
					{
						field: "Person2",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person2",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person4",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 4,
				visibleFields: [
					{
						field: "Person3",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person3",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person5",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 5,
				visibleFields: [
					{
						field: "Person4",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person4",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person6",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 6,
				visibleFields: [
					{
						field: "Person5",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person5",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person7",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 7,
				visibleFields: [
					{
						field: "Person6",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person6",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person8",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 8,
				visibleFields: [
					{
						field: "Person7",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person7",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person9",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 9,
				visibleFields: [
					{
						field: "Person8",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person8",
						operator: "!=",
						value: ""
					}
				],
			}),
			new TextboxQuestion({
				key: "Person10",
				label: "Person",
				title: "The Person receiving the copied dashboard.",
				type: "generic-selector",
				setName: "PersonWithUsername",
				required: false,
				validateObject: true,
				allowBlankValue: true,
				order: 9,
				visibleFields: [
					{
						field: "Person9",
						operator: "!=",
						value: null
					}, // AND
					{
						field: "Person9",
						operator: "!=",
						value: ""
					}
				],
			}),
		]
		this.isLoading = false;
		this.title = "Copy Dashboard: " + this.dashboard.Name;
		this.subTitle = this.dashboard.Description != "null" ? this.dashboard.Description : "Copying this dashboard";
	}

	setUpForm() {
		this.setUpDynamicFormQuestions();
	}

	submitForm(submittedValues: any) {
		Global.User.DebugMode && console.log("dashboard-copy.component.ts: submitForm: submittedValues = %O", submittedValues);
		Global.User.DebugMode && console.log("dashboard-copy.component.ts: dashboard to copy = %O", this.dashboard);
		var submittedValuesObject = JSON.parse(submittedValues);
		var keys: Array<any> = Object.keys(submittedValuesObject);
		var arrayOfUserIds: Array<any> = [];
		keys.forEach((key:string) => {
			if (submittedValuesObject[key] != null) {
				arrayOfUserIds.push(submittedValuesObject[key].toString());
			}
		});

		var listOfUserIds = arrayOfUserIds.join(",");

		var singleCopyToCurrentLoggedInUser = arrayOfUserIds.firstOrDefault((id:any) => { return id }) == Global.User.currentUser.Id && arrayOfUserIds.length == 1;
		Global.User.DebugMode && console.log("dashboard-copy: singleCopyToCurrentLoggedInUser = " + singleCopyToCurrentLoggedInUser);
		if (!singleCopyToCurrentLoggedInUser) {
			this.swalWithBootstrapButtons
				.fire({
					title: "Are you sure?",
					html: "Copy the <strong>" + this.dashboard.Name + (this.dashboard.Description != "null" ? " : " + this.dashboard.Description : "") + "</strong> to the list of people selected?",
					showCancelButton: true,
					confirmButtonText: "Yes",
					cancelButtonText: "No",
					reverseButtons: false
				})
				.then((result) => {
					if (result.value) {
						// Send back the list of user Ids for copying this dashboard. 
						this.dialogRef.close(listOfUserIds);
					} else if (result.dismiss === swal.DismissReason.cancel) {
						Global.User.DebugMode && console.log("User simply cancelled the dashboard copy.");
						this.utilityService.showToastMessageShared({
							type: "info",
							message: "'" + this.dashboard.Name + (this.dashboard.Description != "null" ? " : " + this.dashboard.Description : "") + "' was NOT copied.",
							title: "Dashboards"
						}).then((data:any) => {
							this.dialogRef.close();
						});

					}
				});
		} else {
			//-- the user is trying to copy the dashboard to themselves. --Kirk T. Sherer, September 2, 2020.
			this.dialogRef.close(listOfUserIds);
		}
	}
}
