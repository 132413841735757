<sass-helper></sass-helper>
<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Faults / Alerts...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="
		((widgetObject.AngularDirectiveName === 'siteActiveAlarms' &&
			widgetObject?.WidgetSiteId) ||
		(widgetObject.AngularDirectiveName === 'gseActiveAlarms' &&
			siteList?.length > 0 || widgetObject?.VocationalSettingsJSON)) && !isDataLoading
	"
	class="container-fluid"
	style="height: 100%"
>
	<div class="row" style="height: 35%">
		<div class="alert-count">
			<div #major
				[ngClass]="
					evaluateSelectedFilters().length === 0
						? 'selectedFilter'
						: ''
				"
				class="major-alert-container"
				(click)="filterTableData('All', alertGrid)"
			>
				<span
					[style.color]="bodyTextColor"
					[style.font-size]="majorFontSize"
					class="alert-count-number"
					title="Click to see All"
					*ngIf="alertGrid?.processedRawDataForGridWithoutPaging?.data"
					>{{ alertGrid.processedRawDataForGridWithoutPaging.data.length }}</span
				>
			</div>
			<div
				[ngClass]="
					evaluateSelectedFilters().length === 0
						? ''
						: evaluateSelectedFilters().includes(this.dataService.getSeverityNameById(3)) ===
						  false
						? 'selectedFilter'
						: ''
				"
				style="background-color: rgba(255, 0, 0, 0.5)"
				class="minor-alert-container"
				(click)="filterTableData(this.dataService.getSeverityNameById(3), alertGrid)"
			>
				<span
					[style.color]="bodyTextColor"
					[style.font-size]="minorFontSize"
					class="alert-count-number"
					[title]="
						evaluateSelectedFilters().includes(this.dataService.getSeverityNameById(3)) === false
							? 'Click to hide ' + this.dataService.getSeverityNameById(3) + ' Fault series on chart and filter out on table'
							: this.dataService.getSeverityNameById(3) + ' Faults Hidden'
					"
				>
					{{ alarmTypeCounts.Criticals }} {{this.dataService.getSeverityNameById(3)}}</span
				>
			</div>
			<div
				[ngClass]="
					evaluateSelectedFilters().length === 0
						? ''
						: evaluateSelectedFilters().includes('Alarm') === false
						? 'selectedFilter'
						: ''
				"
				style="background-color: rgba(219, 45, 45, 0.8)"
				class="minor-alert-container"
				(click)="filterTableData(this.dataService.getSeverityNameById(1), alertGrid)"
			>
				<span
					[style.color]="bodyTextColor"
					[style.font-size]="minorFontSize"
					class="alert-count-number"
					[title]="
						evaluateSelectedFilters().includes(this.dataService.getSeverityNameById(1)) === false
							? 'Click to hide ' + this.dataService.getSeverityNameById(1) + ' Fault series on chart and filter out on table'
							: this.dataService.getSeverityNameById(1) + ' Faults Hidden'
					"
				>
					{{ alarmTypeCounts.Alarms }} {{this.dataService.getSeverityNameById(1)}}</span
				>
			</div>





			<div
				[ngClass]="
					evaluateSelectedFilters().length === 0
						? ''
						: evaluateSelectedFilters().includes('Alert') === false
						? 'selectedFilter'
						: ''
				"
				style="background-color: rgba(219, 70, 45, 0.8)"
				class="minor-alert-container"
				(click)="filterTableData('Alert', alertGrid)"
			>
				<span
					[style.color]="bodyTextColor"
					[style.font-size]="minorFontSize"
					class="alert-count-number"
					[title]="
						evaluateSelectedFilters().includes('Alert') === false
							? 'Click to hide Alert series on chart and filter out on table'
							: 'Alerts Hidden'
					"
				>
					{{ alarmTypeCounts.Alerts }} Alerts</span
				>
			</div>




		</div>
		<div class="alert-chart" *ngIf="chartIsUsable">
			<div
				id="siteActiveAlerts{{ widgetObject.WidgetId }}"
				style="width: 100%"
			></div>
		</div>
		<div class="alert-chart" *ngIf="!chartIsUsable">
			<p style="display: flex; justify-content: center; flex-direction: column;">Chart is not viewable since you have selected multiple sites with different time zones.</p>
		</div>
	</div>
	<div class="row" style="height: 65%">
		<div class="alert-list" [style.height.%]="100" [style.width.%]="100">
			<div
				(resized)="onResized($event)"
				[style.height.%]="100"
				[style.width.%]="100"
			>
				<mat-tab-group
					[(selectedIndex)]="valuePicked"
					(animationDone)="animationRedraw()"
					animationDuration="0ms"
					style="width: 100%"
				>
					<mat-tab label="Alarm">
						<div
							class="parent-table-container"
							[style.height.%]="100"
							[style.width.%]="100"
						>
							<lib-kendo-grid-parent
								[gridData]="alertData"
								[gridSettings]="alertGridSettings"
								[widgetObject]="widgetObject"
								(callSave)="saveGridSettings()"
								[style.height.%]="100"
								filterable="menu"
								[sortable]="true"
								[reorderable]="true"
								[resizable]="true"
								[gridContextMenuItems]="gridContextMenuItems"
								(onRightClickSelectEmitter)="onRightClickSelect($event)"
								*ngIf="!isDataLoading && alertData !== undefined"
								#alertGrid
							>
							</lib-kendo-grid-parent>
						</div>
					</mat-tab>
					<mat-tab label="Map" *ngIf="widgetObject.AngularDirectiveName === 'gseActiveAlarms'">
						<div
							*ngIf="widgetObject !== undefined"
							class="map-container{{ widgetObject.WidgetId }}"
							style="height: 100%"
						>
							<div id="regularMap{{ widgetObject.WidgetId }}"></div>
							<div
								class="gseLegend"
								id="legend{{ widgetObject?.WidgetId }}"
							>
								<h3 class="gseLegendLabel">Legend</h3>
							</div>
							<div
								id="style-selector-control{{
									widgetObject?.WidgetId
								}}"
								class="map-control gseLegend"
								style="
									border-color: black;
									border: 1px solid black;
									padding: 5px;
									border-radius: 5px;
								"
							>
								<input
									type="radio"
									name="show-hide"
									id="hide-poi{{ widgetObject?.WidgetId }}"
									class="selector-control"
								/>
								<label class="gseLegendLabel" for="hide-poi"
									>Hide Legend</label
								>
								<input
									type="radio"
									name="show-hide"
									id="show-poi{{ widgetObject?.WidgetId }}"
									class="selector-control"
									checked="checked"
									style="margin-left: 10px"
								/>
								<label class="gseLegendLabel" for="show-poi"
									>Show Legend</label
								>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="
		(widgetObject.AngularDirectiveName === 'siteActiveAlarms' &&
			!isDataLoading &&
			!widgetObject?.WidgetSiteId) ||
		(widgetObject.AngularDirectiveName === 'gseActiveAlarms' &&
			!isDataLoading &&
			!widgetObject?.SiteList &&
			!widgetObject?.VocationalSettingsJSON)
	"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Site or Fleet from the Widget Settings."
	></lib-centered-message>
</div>
