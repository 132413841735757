import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { LoginComponent } from "./login.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { LogoutComponent } from "./logout.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RegistrationComponent } from './registration/registration.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { GoToV1Component } from './go-to-v1/go-to-v1.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicFormModule } from "../../../../shared-lib/src/lib/components/dynamic-form/dynamic-form.module";
import { PersonalAlertEscalationComponent } from './personal-alert-escalation/personal-alert-escalation.component';
import { MaterialModule } from '../_other-modules/material.module';

@NgModule({
    declarations: [
        AuthenticationComponent,
        LoginComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        RegistrationComponent,
        PersonalInformationComponent,
        GoToV1Component,
		PersonalAlertEscalationComponent,
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatDialogModule,
        MatTooltipModule,
        DynamicFormModule,
		MaterialModule,
    ]
})
export class UserModule {}
