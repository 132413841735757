import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CanvasTabsListComponent } from './canvas-tabs-list.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		GridModule,
		MatMenuModule,
		MatButtonModule,
	],
	declarations: [CanvasTabsListComponent],
})
export class CanvasTabsListModule { }
