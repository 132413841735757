<div class="card card-tasks">
	<lib-centered-message
		*ngIf="!widgetObject.WidgetSiteId && !fleetId"
		centeredMessage="Please select a site or fleet to continue."
	></lib-centered-message>
	<div
		(resized)="onResized($event)"
		style="display: inline-block"
		class="card-body"
		id="card-body-id"
		*ngIf="this.widgetObject.WidgetSiteId || this.fleetId"
	>
		<lib-loading-spinner
			*ngIf="isLoading"
			[loadingMessage]="'Loading Geofencing'"
			[vehicle]="'truck'"
		></lib-loading-spinner>
		<div class="resizeContainerMap">
			<div
				class="left-geofencing-column"
				[style.width.%]="defaultLeftWidth"
			>
				<div class="card-header">
					<button
						mat-icon-button
						[matMenuTriggerFor]="menuZone"
						aria-label="Example icon-button with a menu"
					>
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menuZone="matMenu">
						<button mat-menu-item (click)="createNewZone()">
							<mat-icon>add</mat-icon>
							<span>Create New Zone</span>
						</button>
					</mat-menu>
					<h3 style="display: inline" class="card-title">Zones</h3>

					<!-- <button class="btn cardHeaderButton float-right" tooltip="Create New Zone" placement="left" (click)="createNewZone()">
						<i style="font-size: 10px" class="tim-icons icon-simple-add iconInsideCardHeaderButton"> </i>
					</button> -->
				</div>
				<br />
				<mat-accordion [multi]="true">
					<mat-expansion-panel
						*ngFor="let zone of zonesAndZonesInstances"
					>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<button
									mat-icon-button
									[matMenuTriggerFor]="menu"
									aria-label="Example icon-button with a menu"
								>
									<mat-icon>more_vert</mat-icon>
								</button>
								<mat-menu #menu="matMenu">
									<button
										mat-menu-item
										(click)="createNewZoneType(zone)"
									>
										<mat-icon>add</mat-icon>
										<span>Add Zone Type</span>
									</button>
									<button
										mat-menu-item
										(click)="
											createGeographyZoneFromExistingZone(
												zone,
												'right'
											)
										"
									>
										<mat-icon>arrow_forward</mat-icon>
										<span>Copy Zone to Right</span>
									</button>
									<button
										mat-menu-item
										(click)="
											createGeographyZoneFromExistingZone(
												zone,
												'bottom'
											)
										"
									>
										<mat-icon>arrow_downward</mat-icon>
										<span>Copy Zone to Bottom</span>
									</button>
									<button
										mat-menu-item
										(click)="
											createGeographyZoneFromExistingZone(
												zone,
												'left'
											)
										"
									>
										<mat-icon>arrow_back</mat-icon>
										<span>Copy Zone to Left</span>
									</button>
									<button
										mat-menu-item
										(click)="
											createGeographyZoneFromExistingZone(
												zone,
												'top'
											)
										"
									>
										<mat-icon>arrow_upward</mat-icon>
										<span>Copy Zone to Top</span>
									</button>
								</mat-menu>
								<p
									(click)="
										loadGeographyZoneAndEdit(zone, false)
									"
									tooltip="Show and Edit Zone"
								>
									{{ zone.GeographyZoneName }} ({{
										zone.Types !== undefined
											? zone.Types.length
											: ""
									}})
								</p>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<p
							*ngFor="let zoneTypeInstance of zone.Types"
							(click)="
								loadGeographyZoneTypeAndEdit(
									zoneTypeInstance,
									zone
								)
							"
							style="text-align: right; cursor: pointer"
							tooltip="Edit Zone Type"
							placement="right"
						>
							{{
								zoneTypeInstance.GeographyZoneToGeographyZoneTypeName
							}}
						</p>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
			<div
				class="middle-geofencing-column"
				[style.width.%]="defaultMiddleWidth"
			>
				<div class="card-header">
					<button
						[ngClass]="
							theme === 'light'
								? 'mapButtonLight btn mr-1'
								: 'mapButton btn mr-1'
						"
						type="button"
						(click)="toggleShowingAssetsOnMap()"
					>
						{{
							shouldShowAssets === false
								? "Show Assets For Fleet/Site"
								: "Hide Assets For Fleet/Site"
						}}
					</button>
					<!-- <h3 style="display: inline" class="card-title">Zones</h3> -->

					<!-- <button class="btn cardHeaderButton float-right" tooltip="Create New Zone" placement="left" (click)="createNewZone()">
						<i style="font-size: 10px" class="tim-icons icon-simple-add iconInsideCardHeaderButton"> </i>
					</button> -->
				</div>
				<div style="height: calc(100% - 56px)">
					<div
						style="width: 100%; height: 100%"
						id="geoMap{{ widgetObject.WidgetId }}"
					></div>
				</div>
			</div>
			<div
				*ngIf="showEditingPanel"
				class="right-geofencing-column"
				[style.width.%]="defaultRightWidth"
			>
				<div *ngIf="editingZone" style="height: calc(100%)">
					<div class="card-header">
						<h3
							*ngIf="!editingExistingZone"
							style="display: inline"
							class="card-title"
						>
							Add New Zone
						</h3>
						<h3
							*ngIf="editingExistingZone"
							style="display: inline"
							class="card-title"
						>
							Editing Zone
						</h3>
						<button
							*ngIf="editingExistingZone"
							(click)="deleteZone()"
							class="btn cardHeaderDeleteButton float-right"
							tooltip="Delete Zone"
							placement="left"
						>
							<i
								style="font-size: 10px"
								class="tim-icons icon-trash-simple iconInsideCardHeaderButton"
							></i>
						</button>
						<button
							(click)="cancelZoneEditing()"
							class="btn cardHeaderDeleteButton float-right"
							tooltip="Cancel Zone Editing"
							placement="left"
						>
							Cancel
						</button>
						<h5
							*ngIf="editingExistingZone"
							style="display: block"
							class="card-title"
						>
							{{ currentlySelectedZone.GeographyZoneName }}
						</h5>
					</div>

					<div class="card-body">
						<div class="container" style="height: 100%">
							<div *ngIf="showZoneForm">
								<form
									*ngIf="zoneFormIsPopulated"
									[formGroup]="zoneForm"
									(ngSubmit)="submitZoneForm(zoneForm.value)"
									style="height: 100%"
								>
									<div class="container" style="height: 40%">
										<div class="form-group">
											<label for="name"> Name: </label>
											<input
												type="text"
												class="form-control"
												required
												formControlName="name"
												id="name"
											/>
										</div>
										<div class="form-group">
											<label for="type">
												Description:
											</label>
											<input
												type="text"
												class="form-control"
												required
												formControlName="description"
												id="description"
											/>
										</div>

										<!-- <mat-slider
                                            thumbLabel
                                            [displayWith]="formatLabel"
                                            tickInterval="1"
                                            min="1"
                                            max="359"></mat-slider> -->
										<div class="form-group">
											<label for="rotation">
												Degrees to Rotate Polygon:
											</label>
											<input
												type="number"
												class="form-control"
												formControlName="rotation"
												id="rotation"
											/>
											<label
												for="backgroundColor"
												[style.padding-right]="'5px'"
											>
												Background Color:
											</label>
											<input
												[(colorPicker)]="
													zoneForm.value
														.backgroundColor
												"
												[cpPosition]="'left'"
												[style.background]="
													zoneForm.value
														.backgroundColor
												"
												(colorPickerClose)="
													colorChanged(
														$event,
														zoneForm
													)
												"
											/>

											<button
												[disabled]="
													zoneForm.value.rotation <
														-359 ||
													zoneForm.value.rotation >
														359 ||
													polygonAnchorPoint ===
														undefined
												"
												type="button"
												class="btn"
												(click)="
													rotatePolygon(
														zoneForm.value
													)
												"
											>
												Rotate Polygon
											</button>
											<span
												tooltip="Set an anchor point to rotate around by selecting hand tool and right clicking an anchor point on the selected zone"
												><mat-icon>info</mat-icon></span
											>
										</div>
										<mat-accordion
											[multi]="true"
											*ngIf="
												currentlySelectedZone !==
													undefined &&
												currentlySelectedZone.Types &&
												currentlySelectedZone.Types
													.length > 0
											"
										>
											<mat-expansion-panel>
												<mat-expansion-panel-header>
													<mat-panel-title
														>Zone
														Types</mat-panel-title
													>
												</mat-expansion-panel-header>

												<p
													*ngFor="
														let zoneTypeInstance of currentlySelectedZone.Types
													"
													(click)="
														loadGeographyZoneTypeAndEdit(
															zoneTypeInstance,
															currentlySelectedZone
														)
													"
													style="
														text-align: right;
														cursor: pointer;
													"
												>
													{{
														zoneTypeInstance.GeographyZoneToGeographyZoneTypeName
													}}
												</p>
											</mat-expansion-panel>
										</mat-accordion>

										<h4>
											{{
												polygonSelectedConfirmationObject.message
											}}
										</h4>
									</div>

									<div class="container" style="height: 10%">
										<div class="text-center">
											<button
												class="btn btn-danger"
												type="submit"
												[disabled]="
													zoneForm.invalid ||
													polygonSelectedConfirmationObject.isOkToSubmit ===
														false ||
													zoneForm.value
														.backgroundColor ===
														null ||
													zoneForm.value
														.backgroundColor ===
														undefined
												"
											>
												{{
													polygonSelectedConfirmationObject.submissionButtonMessage
												}}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="editingZoneType" style="height: 100%">
					<div class="card-header">
						<h3
							*ngIf="!editingExistingZoneType"
							style="display: inline"
							class="card-title"
						>
							Adding New Zone Type
						</h3>
						<h3
							*ngIf="editingExistingZoneType"
							style="display: inline"
							class="card-title"
						>
							Editing Zone Type
						</h3>
						<button
							*ngIf="editingExistingZoneType"
							(click)="deleteZoneType()"
							class="btn cardHeaderDeleteButton float-right"
							tooltip="Delete Zone Type"
							placement="left"
						>
							<i
								style="font-size: 10px"
								class="tim-icons icon-trash-simple iconInsideCardHeaderButton"
							></i>
						</button>

						<button
							(click)="cancelZoneTypeEditing()"
							class="btn cardHeaderDeleteButton float-right"
							tooltip="Cancel Zone Type Editing"
							placement="left"
						>
							Cancel
						</button>
						<h5 style="display: block" class="card-title">
							Current Zone:
							{{ currentlySelectedZone.GeographyZoneName }}
						</h5>
					</div>

					<div class="card-body">
						<div *ngIf="showZoneTypeForm" style="height: 100%">
							<form
								*ngIf="zoneTypeFormIsPopulated"
								[formGroup]="zoneTypeForm"
								style="height: 100%"
							>
								<div
									class="container"
									style="height: 40%"
									style="
										overflow-y: scroll;
										overflow-x: scroll;
									"
								>
									<div class="form-group">
										<label for="zoneTypeName">
											Name:
										</label>
										<input
											type="text"
											class="form-control"
											required
											formControlName="zoneTypeName"
											id="zoneTypeName"
										/>
									</div>
									<div class="form-group">
										<label for="typeDropdown">
											Type:
										</label>

										<select
											required
											(change)="evaluateZoneTypeForm()"
											formControlName="type"
											class="form-control"
											id="typeDropdown"
										>
											<option
												*ngFor="
													let zoneType of availableZoneTypesForCurrentZone
												"
												[ngValue]="zoneType"
											>
												{{ zoneType.Name }}
											</option>
										</select>
										<label
											*ngIf="
												zoneTypeForm.value.type.Id === 1
											"
											for="typeDropdown"
										>
											If assets that are assigned to this
											zone type leave this area, they will
											be in the alert state chosen.
										</label>
										<label
											*ngIf="
												zoneTypeForm.value.type.Id === 2
											"
											for="typeDropdown"
										>
											If assets that are assigned to this
											zone type speed above the limit
											declared while inside this area,
											they will be in the alert state
											chosen.</label
										>
										<label
											*ngIf="
												zoneTypeForm.value.type.Id === 3
											"
											for="typeDropdown"
										>
											If assets that are assigned to this
											zone enter this area, they will be
											in the alert state chosen.</label
										>

										<label
											*ngIf="
												zoneTypeForm.value.type.Id === 4
											"
											for="typeDropdown"
										>
											If assets that are assigned to this
											zone enter this area, they will be
											in the alert state chosen.</label
										>

										<label
											*ngIf="
												zoneTypeForm.value.type.Id === 5
											"
											for="typeDropdown"
										>
											If assets that are assigned to this
											zone enter this area, they will be
											in the alert state chosen.</label
										>

										<label
											*ngIf="
												zoneTypeForm.value.type.Id === 6
											"
											for="typeDropdown"
										>
											If assets that are assigned to this
											zone enter this area, they will be
											in the alert state chosen.</label
										>
									</div>
									<div
										*ngIf="
											this.zoneTypeForm.value.type.Id ===
											2
										"
										class="form-group"
									>
										<label for="speedLimit"> Limit: </label>
										<input
											type="number"
											class="form-control"
											required
											formControlName="speedLimit"
											id="speedLimit"
										/>
									</div>
									<div
										*ngIf="
											this.zoneTypeForm.value.type.Id ===
											2
										"
										class="form-group"
									>
										<label for="speedLimitUnits">
											Units
										</label>
										<select
											required
											formControlName="speedLimitUnits"
											class="form-control"
											id="speedLimitUnits"
										>
											<option
												*ngFor="
													let unit of speedLimitUnits
												"
												[ngValue]="unit.name"
											>
												{{ unit.name }}
											</option>
										</select>
									</div>
									<div class="form-group">
										<label for="severityLevel">
											Severity Level for Fault
										</label>
										<select
											required
											formControlName="severityLevel"
											class="form-control"
											id="severityLevel"
										>
											<option
												*ngFor="
													let level of jbtStandardObservationSeverityLevels
												"
												[ngValue]="level.Name"
											>
												{{ level.Name }}
											</option>
										</select>
									</div>
								</div>
								<div class="container" style="height: 50%">
									<div class="parent-table-container">
										<lib-kendo-grid-parent
											[gridData]="siteAssets"
											[gridSettings]="gridSettings"
											[widgetObject]="widgetObject"
											(toggleAssetInFormListEmitter)="toggleAssetInFormList($event)"
											[assetsForSelectedZoneType]="assetsForSelectedZoneType"
											[style.height.%]="100"
											filterable="menu"
											[sortable]="true"
											[reorderable]="true"
											[resizable]="true"
											#dataGrid

										>
										</lib-kendo-grid-parent>


									</div>
									<!-- <mat-form-field hideRequiredMarker appearance="fill" class="phase" style="width: 100% !important">
										<mat-label>GSEs ({{ zoneTypeForm.value.assets.length }} / {{ assetTotalLength }})</mat-label>
										<mat-select multiple formControlName="assets">
											<mat-optgroup *ngFor="let group of assetListForUserSites" [label]="group.type">
												<mat-option *ngFor="let asset of group.assets" [value]="asset">{{ asset.Name }}</mat-option>
											</mat-optgroup>
										</mat-select>
									</mat-form-field> -->
								</div>
								<div class="container" style="height: 10%">
									<div class="text-center">
										<button
											class="btn btn-danger"
											type="submit"
											(click)="
												submitZoneTypeForm(
													zoneTypeForm.value
												)
											"
											[disabled]="zoneTypeForm.invalid"
										>
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
